"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAverageActionItemTimeString = exports.getAverageTimeBetweenCallsString = exports.getAverageActualCallDurationString = exports.getAverageBillableCallCurationString = exports.getTotalBillableCallDurationString = exports.getTotalActualCallDurationString = exports.sortAllJillsHighLevelStatsesByNumberField = exports.sortAllJillsHighLevelStatsesByStringFieldThatBeginsWithNumber = exports.beginsWithNumber = exports.sortAllJillsHighLevelStatsesByFullName = exports.sortAllJillsHighLevelStatses = exports.getSortedJillHighLevelStatses = void 0;
const utilities_1 = require("../../../services/utilities");
function getSortedJillHighLevelStatses(jillHighLevelStatses, column) {
    if (column === 'NOT_SET' ||
        column.sortFieldName === undefined ||
        column.sortDirection === undefined) {
        return jillHighLevelStatses;
    }
    if (column.sortDirection === 'NOT_SET') {
        return sortAllJillsHighLevelStatses(jillHighLevelStatses, column.sortFieldName, 'DESC');
    }
    return sortAllJillsHighLevelStatses(jillHighLevelStatses, column.sortFieldName, column.sortDirection);
}
exports.getSortedJillHighLevelStatses = getSortedJillHighLevelStatses;
function sortAllJillsHighLevelStatses(allJillsHighLevelStatses, field, order) {
    if (order === 'NOT_SET') {
        return allJillsHighLevelStatses;
    }
    if (field === 'name') {
        return sortAllJillsHighLevelStatsesByFullName(allJillsHighLevelStatses, order);
    }
    const firstValueAtField = allJillsHighLevelStatses[0]?.highLevelStatses.highLevelStats[field];
    if (typeof firstValueAtField === 'string' && typeof beginsWithNumber(firstValueAtField)) {
        return sortAllJillsHighLevelStatsesByStringFieldThatBeginsWithNumber(allJillsHighLevelStatses, field, order);
    }
    if (typeof firstValueAtField === 'number') {
        return sortAllJillsHighLevelStatsesByNumberField(allJillsHighLevelStatses, field, order);
    }
    return order === 'ASC'
        ? [...allJillsHighLevelStatses].sort()
        : [...allJillsHighLevelStatses].sort().reverse();
}
exports.sortAllJillsHighLevelStatses = sortAllJillsHighLevelStatses;
function sortAllJillsHighLevelStatsesByFullName(allJillsHighLevelStatses, order) {
    return [...allJillsHighLevelStatses].sort((a, b) => {
        if (a.firstName === undefined || b.firstName === undefined) {
            return 0;
        }
        const name1 = a.firstName + a.lastName;
        const name2 = b.firstName + b.lastName;
        if (name1 > name2) {
            return order === 'DESC' ? -1 : 1;
        }
        if (name1 < name2) {
            return order === 'DESC' ? 1 : -1;
        }
        return 0;
    });
}
exports.sortAllJillsHighLevelStatsesByFullName = sortAllJillsHighLevelStatsesByFullName;
function beginsWithNumber(value) {
    return /\d+/.test(value);
}
exports.beginsWithNumber = beginsWithNumber;
function sortAllJillsHighLevelStatsesByStringFieldThatBeginsWithNumber(allJillsHighLevelStatses, field, order) {
    return [...allJillsHighLevelStatses].sort((a, b) => {
        const aValue = parseInt(a.highLevelStatses.highLevelStats[field]);
        const bValue = parseInt(b.highLevelStatses.highLevelStats[field]);
        return order === 'ASC' ? aValue - bValue : bValue - aValue;
    });
}
exports.sortAllJillsHighLevelStatsesByStringFieldThatBeginsWithNumber = sortAllJillsHighLevelStatsesByStringFieldThatBeginsWithNumber;
function sortAllJillsHighLevelStatsesByNumberField(allJillsHighLevelStatses, field, order) {
    return [...allJillsHighLevelStatses].sort((a, b) => {
        const aValue = a.highLevelStatses.highLevelStats[field];
        const bValue = b.highLevelStatses.highLevelStats[field];
        return order === 'ASC' ? aValue - bValue : bValue - aValue;
    });
}
exports.sortAllJillsHighLevelStatsesByNumberField = sortAllJillsHighLevelStatsesByNumberField;
function getTotalActualCallDurationString(jillHighLevelStatses) {
    return `${(0, utilities_1.millisecondsToMinutes)(parseInt(jillHighLevelStatses.highLevelStatses.highLevelStats.actualCallDuration))} (${(0, utilities_1.millisecondsToHoursMinutesSeconds)(parseInt(jillHighLevelStatses.highLevelStatses.highLevelStats.actualCallDuration))})`;
}
exports.getTotalActualCallDurationString = getTotalActualCallDurationString;
function getTotalBillableCallDurationString(jillHighLevelStatses) {
    return `${(0, utilities_1.millisecondsToMinutes)(parseInt(jillHighLevelStatses.highLevelStatses.highLevelStats.billableCallDuration))} (${(0, utilities_1.millisecondsToHoursMinutesSeconds)(parseInt(jillHighLevelStatses.highLevelStatses.highLevelStats.billableCallDuration))})`;
}
exports.getTotalBillableCallDurationString = getTotalBillableCallDurationString;
function getAverageBillableCallCurationString(jillHighLevelStatses) {
    return `${(0, utilities_1.millisecondsToMinutes)(jillHighLevelStatses.highLevelStatses.highLevelStats.averageBillableCallDuration)} (${(0, utilities_1.millisecondsToHoursMinutesSeconds)(jillHighLevelStatses.highLevelStatses.highLevelStats.averageBillableCallDuration)})`;
}
exports.getAverageBillableCallCurationString = getAverageBillableCallCurationString;
function getAverageActualCallDurationString(jillHighLevelStatses) {
    return `${(0, utilities_1.millisecondsToMinutes)(jillHighLevelStatses.highLevelStatses.highLevelStats.averageActualCallDuration)} (${(0, utilities_1.millisecondsToHoursMinutesSeconds)(jillHighLevelStatses.highLevelStatses.highLevelStats.averageActualCallDuration)})`;
}
exports.getAverageActualCallDurationString = getAverageActualCallDurationString;
function getAverageTimeBetweenCallsString(jillHighLevelStatses) {
    return `${(0, utilities_1.millisecondsToMinutes)(jillHighLevelStatses.highLevelStatses.highLevelStats.averageTimeBetweenCalls)} (${(0, utilities_1.millisecondsToHoursMinutesSeconds)(jillHighLevelStatses.highLevelStatses.highLevelStats.averageTimeBetweenCalls)})`;
}
exports.getAverageTimeBetweenCallsString = getAverageTimeBetweenCallsString;
function getAverageActionItemTimeString(jillHighLevelStatses) {
    return `${(0, utilities_1.millisecondsToMinutes)(jillHighLevelStatses.highLevelStatses.highLevelStats.averageActionItemTime)} (${(0, utilities_1.millisecondsToHoursMinutesSeconds)(jillHighLevelStatses.highLevelStatses.highLevelStats.averageActionItemTime)})`;
}
exports.getAverageActionItemTimeString = getAverageActionItemTimeString;
