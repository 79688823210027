"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stylesForJoJillPods = void 0;
const lit_html_1 = require("lit-html");
const css_utils_1 = require("../../../utilities/css-utils");
const cssName = (0, css_utils_1.cssPrefixer)('jo-jill-pods');
exports.stylesForJoJillPods = (() => {
    return (0, lit_html_1.html) `
        <style>
            .${cssName('main-container')} {
                height: 100%;
                width: 100%;
                box-sizing: border-box;
                background-color: white;
            }

            .${cssName('main-container')}:hover {
                background-color: var(--jo-primary-light);
            }

            .${cssName('row')} {
                display: flex;
                flex-wrap: wrap;
                box-sizing: border-box;
                padding: 1rem 0rem;
                align-items: center;
                border-top: 1px solid grey;
                border-bottom: 1px solid gray;
                box-shadow: 0 1px 8px rgb(0, 0, 0, 0.3);
                padding: 1rem;
            }

            .${cssName('pods-container')} {
                display: flex;
                flex-wrap: wrap;
            }

            .${cssName('pod-container')} {
                padding-right: 1rem;
                padding-bottom: 0.5rem;
            }

            .${cssName('profile-picture-img')} {
                max-height: 10rem;
                padding: 0rem 2rem;
            }

            .${cssName('shift-hours')} {
                font-size: 0.9rem;
                font-family: sans-serif;
            }

            .${cssName('list-items-text')} {
                font-size: 0.9rem;
            }

            .${cssName('list-items-text')} > li {
                padding-top: 0.5rem;
            }

            .${cssName('last-call-info-text')} {
                padding-top: 0rem;
                font-size: 0.935rem;
            }
        </style>
    `;
})();
