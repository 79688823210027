"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const css_utils_1 = require("../../../utilities/css-utils");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const space_and_capitalize_1 = require("../../../utilities/strings/space-and-capitalize");
require("../jo-button.element");
require("../jo-input");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    permissions: {
        can_delete_invoices: false,
        has_chat_access: false,
        can_view_incoming_queue_box: true,
    },
    readonly: false,
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-user-permissions');
class JOUserPermissions extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    valueChanged(key, value) {
        this.updatePermissions(key, value);
        (0, utilities_1.raiseCustomEvent)(this, 'permissionsValuesChanged', this.store.permissions);
    }
    updatePermissions(key, value) {
        this.store.permissions = {
            ...this.store.permissions,
            [key]: value,
        };
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .${cssName('main-container')} {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }
            </style>

            <div class="${cssName('main-container')}">
                <header>
                    <h2>Permissions</h2>
                </header>

                <section>
                    ${Object.keys(state.permissions).map(key => {
            const permissionName = key;
            return (0, lit_html_1.html) `
                            <jo-input
                                .type=${'CHECKBOX'}
                                .label=${(0, space_and_capitalize_1.spaceAndCapitalize)(permissionName)}
                                .readonly=${state.readonly}
                                .checkedValue=${state.permissions[permissionName]}
                                @valueChanged=${(e) => {
                this.valueChanged(permissionName, e.detail.checkedValue);
            }}
                            ></jo-input>
                            <br />
                        `;
        })}
                </section>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-user-permissions', JOUserPermissions);
