"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../../services/utilities");
const css_utils_1 = require("../../../../utilities/css-utils");
const format_date_for_date_input_1 = require("../../../../utilities/dates-and-times/format-date-for-date-input");
const get_start_of_date_1 = require("../../../../utilities/dates-and-times/get-start-of-date");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
const get_table_columns_1 = require("./get-table-columns");
const helper_utils_1 = require("./helper-utils");
const queries_1 = require("./queries");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    companyId: 'NOT_SET',
    endDate: (0, get_start_of_date_1.getStartOfTomorrow)(),
    filteredUserStatusEvents: [],
    selectedEventType: 'All',
    selectedPersonName: 'All',
    startDate: (0, utilities_1.getStartOfToday)(),
    userStatusEvents: [],
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-company-user-activity-report');
class JOCompanyUserActivityReport extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set companyId(companyId) {
        if (companyId === 'NOT_SET' || this.store.companyId === companyId) {
            return;
        }
        this.store.companyId = companyId;
        this.fetchAndSetUserStatusEvents();
    }
    async fetchAndSetUserStatusEvents() {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'user-activity-report > companyId');
        const statusEventsResult = await (0, queries_1.fetchUserStatusEventsForCompany)(this.store.companyId, this.store.startDate, this.store.endDate);
        (0, utilities_1.assertSucceeded)(statusEventsResult, utilities_1.handleError, this, 'updateUserStatusEventTable');
        this.store.userStatusEvents = statusEventsResult.value;
        this.filterUserStatusEvents();
    }
    filterUserStatusEvents() {
        this.store.filteredUserStatusEvents = this.store.userStatusEvents
            .filter(event => (0, helper_utils_1.shouldPersonBeIncluded)(this.store.selectedPersonName, event))
            .filter(event => (0, helper_utils_1.shouldEventTypeBeIncluded)(this.store.selectedEventType, event));
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .${cssName('main-container')} {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .${cssName('filters')} {
                    display: flex;
                    padding-bottom: 1rem;
                }

                .${cssName('date-label')} {
                    font-size: 0.9rem;
                }

                .${cssName('filter-select')} {
                    padding-left: 1rem;
                    padding-top: 0.2rem;
                }
            </style>

            <div class="${cssName('main-container')}">
                <div class="jo-global--card" style="margin-left: 1rem; margin-right: 1rem;">
                    <section class="${cssName('filters')}">
                        <div style="padding-right: 1rem;">
                            <jo-input
                                .type=${'DATE'}
                                .label=${'Start Date'}
                                .dateValue=${(0, format_date_for_date_input_1.formatDateForDateInput)(state.startDate)}
                                @valueChanged=${(e) => {
            this.store.startDate = e.detail.date;
            this.fetchAndSetUserStatusEvents();
        }}
                            ></jo-input>
                        </div>

                        <jo-input
                            .type=${'DATE'}
                            .label=${'End Date'}
                            .dateValue=${(0, format_date_for_date_input_1.formatDateForDateInput)(state.endDate)}
                            @valueChanged=${(e) => {
            this.store.endDate = e.detail.date;
            this.fetchAndSetUserStatusEvents();
        }}
                        ></jo-input>

                        <div div class="${cssName('filter-select')}">
                            <jo-input
                                .type=${'SELECT'}
                                .label=${'User Name'}
                                .selectOptions=${(0, helper_utils_1.getPersonNamesList)(this.store.userStatusEvents)}
                                .selectedValue=${state.selectedPersonName}
                                @inputchange=${(e) => {
            this.store.selectedPersonName = e.detail.selectedValue;
            this.filterUserStatusEvents();
        }}
                            ></jo-input>
                        </div>

                        <div div class="${cssName('filter-select')}">
                            <jo-input
                                .type=${'SELECT'}
                                .label=${'Event'}
                                .selectOptions=${(0, helper_utils_1.getEventTypesList)(this.store.userStatusEvents)}
                                .selectedValue=${state.selectedEventType}
                                @inputchange=${(e) => {
            this.store.selectedEventType = e.detail
                .selectedValue;
            this.filterUserStatusEvents();
        }}
                            ></jo-input>
                        </div>
                    </section>

                    <section>
                        <jo-table
                            .tableName=${'Company User Activity'}
                            .maxItemsPerPage=${100}
                            .columns=${(0, get_table_columns_1.getTableColumns)()}
                            .areItemsSuppliedByParent=${true}
                            .items=${state.filteredUserStatusEvents}
                            .totalItems=${state.filteredUserStatusEvents.length}
                            .showSearchBar=${false}
                        ></jo-table>
                    </section>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-user-activity-report', JOCompanyUserActivityReport);
