"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateTotalPurchasedMinutesForAllCompanies = exports.calculateTotalPaidByCompany = exports.calculateTotalChargedForCompany = exports.determineCompanyPaymentStatus = exports.getTotalToCollectForCompaniesInDollsars = exports.getTotalAmountToCollectForCompanies = exports.getTotalInvoiceAmountForCompany = exports.getTotalAmountPaidForCompaniesInDollars = exports.getTotalAmountPaidForCompanies = exports.getTotalAmountChargedForCompaniesInDollars = exports.getTotalAmountChargedForCompanies = exports.getTotalAmountDueInDollars = exports.getTotalAmountDueForCompanies = void 0;
const calculate_total_charged_for_invoices_1 = require("../../../../utilities/billing-calculations/calculate-total-charged-for-invoices");
const calculate_total_paid_for_invoices_1 = require("../../../../utilities/billing-calculations/calculate-total-paid-for-invoices");
const calculate_total_purchased_minutes_for_company_1 = require("../../../../utilities/billing-calculations/calculate-total-purchased-minutes-for-company");
const constants_1 = require("../constants");
function getTotalAmountDueForCompanies(companies) {
    return companies.reduce((result, company) => {
        return result + getTotalInvoiceAmountForCompany(company);
    }, 0);
}
exports.getTotalAmountDueForCompanies = getTotalAmountDueForCompanies;
function getTotalAmountDueInDollars(companies) {
    return convertToDollars(getTotalAmountDueForCompanies(companies));
}
exports.getTotalAmountDueInDollars = getTotalAmountDueInDollars;
function getTotalAmountChargedForCompanies(companies) {
    return companies.reduce((total, company) => {
        return total + (0, calculate_total_charged_for_invoices_1.calculateTotalChargedForInvoices)(company.invoices);
    }, 0);
}
exports.getTotalAmountChargedForCompanies = getTotalAmountChargedForCompanies;
function getTotalAmountChargedForCompaniesInDollars(companies) {
    return convertToDollars(getTotalAmountChargedForCompanies(companies));
}
exports.getTotalAmountChargedForCompaniesInDollars = getTotalAmountChargedForCompaniesInDollars;
function getTotalAmountPaidForCompanies(companies) {
    return companies.reduce((total, company) => {
        return total + (0, calculate_total_paid_for_invoices_1.calculateTotalPaidForInvoices)(company.invoices);
    }, 0);
}
exports.getTotalAmountPaidForCompanies = getTotalAmountPaidForCompanies;
function getTotalAmountPaidForCompaniesInDollars(companies) {
    return convertToDollars(getTotalAmountPaidForCompanies(companies));
}
exports.getTotalAmountPaidForCompaniesInDollars = getTotalAmountPaidForCompaniesInDollars;
function convertToDollars(cents) {
    return (cents / 100).toFixed(2);
}
function getTotalInvoiceAmountForCompany(company) {
    return company.invoices.reduce((total, invoice) => {
        return total + getInvoiceTotal(invoice);
    }, 0);
}
exports.getTotalInvoiceAmountForCompany = getTotalInvoiceAmountForCompany;
function getTotalAmountToCollectForCompanies(companies) {
    return getTotalAmountDueForCompanies(companies) - getTotalAmountPaidForCompanies(companies);
}
exports.getTotalAmountToCollectForCompanies = getTotalAmountToCollectForCompanies;
function getTotalToCollectForCompaniesInDollsars(companies) {
    return convertToDollars(getTotalAmountToCollectForCompanies(companies));
}
exports.getTotalToCollectForCompaniesInDollsars = getTotalToCollectForCompaniesInDollsars;
function getInvoiceTotal(invoice) {
    if (!isInvoice(invoice)) {
        throw new Error(`carrot: invoices was not defined ${invoice}`);
    }
    if (invoice.invoice_line_items === null || invoice.invoice_line_items === undefined) {
        throw new Error('invoice_line_items was not defined');
    }
    const totalInvoiceAmountForCompany = getTotalAmountOnInvoice(invoice);
    return totalInvoiceAmountForCompany;
}
function getTotalAmountOnInvoice(invoice) {
    return invoice.invoice_line_items.reduce((result, invoiceLineItem) => {
        return result + (invoiceLineItem.amount ?? 0);
    }, 0);
}
function determineCompanyPaymentStatus(company) {
    if (!company.invoices || company.invoices.length === 0) {
        return constants_1.BillingReportStatus.UNKNOWN;
    }
    if (company.invoices[0]?.manually_assigned_status) {
        return constants_1.BillingReportStatus[company.invoices[0].manually_assigned_status];
    }
    if (company.invoices.some(invoice => !totalPaidEqualsInvoice(company, invoice))) {
        return constants_1.BillingReportStatus.UNPAID;
    }
    if (company.invoices.some(invoice => !totalChargedEqualsInvoice(company, invoice))) {
        return constants_1.BillingReportStatus.DISCREPANT;
    }
    if (company.invoices.every(invoice => totalPaidEqualsInvoice(company, invoice))) {
        return constants_1.BillingReportStatus.PAID;
    }
    return constants_1.BillingReportStatus.UNKNOWN;
}
exports.determineCompanyPaymentStatus = determineCompanyPaymentStatus;
function isInvoice(invoice) {
    return invoice !== null && invoice !== undefined;
}
function totalChargedEqualsInvoice(company, invoice) {
    return getTotalInvoiceAmountForCompany(company) == invoice.stripe_invoice?.totalCharged;
}
function totalPaidEqualsInvoice(company, invoice) {
    return (company.invoices.length > 0 &&
        getTotalInvoiceAmountForCompany(company) === invoice.stripe_invoice?.totalPaid);
}
function calculateTotalChargedForCompany(company) {
    const invoices = company.invoices.map(invoice => invoice.stripe_invoice).flat();
    return calculateTotalCharged(invoices);
}
exports.calculateTotalChargedForCompany = calculateTotalChargedForCompany;
function calculateTotalCharged(stripeInvoices) {
    return stripeInvoices.reduce((total, stripeInvoice) => {
        return total + (stripeInvoice?.totalCharged ?? 0);
    }, 0);
}
function calculateTotalPaidByCompany(company) {
    const invoices = company.invoices.map(invoice => invoice.stripe_invoice).flat();
    return calculateTotalPaid(invoices);
}
exports.calculateTotalPaidByCompany = calculateTotalPaidByCompany;
function calculateTotalPaid(stripeInvoices) {
    return stripeInvoices.reduce((total, stripeInvoice) => {
        return total + (stripeInvoice?.totalPaid ?? 0);
    }, 0);
}
function calculateTotalPurchasedMinutesForAllCompanies(companies) {
    return companies.reduce((total, company) => {
        return total + (0, calculate_total_purchased_minutes_for_company_1.calculateTotalPurchasedMinutesForCompany)(company);
    }, 0);
}
exports.calculateTotalPurchasedMinutesForAllCompanies = calculateTotalPurchasedMinutesForAllCompanies;
