"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildCSV = void 0;
function buildCSV(items, columnOptions) {
    const header = columnOptions.map(c => c.header).join(',');
    const rows = items.map(item => buildRow(item, columnOptions));
    const result = header + '\n' + rows.join('\n');
    return result.trim();
}
exports.buildCSV = buildCSV;
function buildRow(item, columnOptions) {
    return columnOptions.map(column => column.getter(item)).join(',');
}
