"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const element_vir_1 = require("element-vir");
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const store_1 = require("../../services/store");
const utilities_1 = require("../../services/utilities");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const replace_not_set_with_undefined_1 = require("../../utilities/type-helpers/replace-not-set-with-undefined/replace-not-set-with-undefined");
const url_1 = require("../../utilities/url");
const jo_company_logs_container_page_1 = require("../main-pages/billing-logs/logs-container-page/jo-company-logs-container-page");
require("./jo-company-call-instructions/jo-company-call-instructions-general-setup");
require("./jo-customer/jo-customer-bottom-bar");
require("./jo-customer/jo-customer-sidebar");
require("./jo-customer/jo-customer-top-bar");
require("./jo-outbound/jo-customer-outbound-dashboard/jo-customer-outbound-dashboard");
const jo_grant_oauth_access_element_1 = require("./oauth/jo-grant-oauth-access.element");
const common_1 = require("@augment-vir/common");
const InitialState = {
    authenticatedUser: store_1.InitialGlobalState.authenticatedUser,
    isMobileDevice: store_1.InitialGlobalState.isMobileDevice,
    loading: true,
    mobileTopBarTitle: store_1.InitialGlobalState.mobileTopBarTitle,
    route: store_1.InitialGlobalState.route,
    showSidebar: store_1.InitialGlobalState.showSidebar,
};
class JORouterCustomer extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    async connectedCallback() {
        // this is so the material icons do not flash to the user while the user is waiting for the app to load
        await window.document.fonts.ready;
        this.store.loading = false;
    }
    render(state) {
        const shouldHideSidebar = state.isMobileDevice === true && state.showSidebar === false;
        const shouldHideTopBar = state.isMobileDevice === false;
        const companyId = getCompanyIdFromAuthenticatedUser(state.authenticatedUser);
        const customerTemplate = (0, element_vir_1.html) `
            <div ?hidden=${shouldHideTopBar} class="jo-router-customer--top-bar-container">
                <jo-customer-top-bar
                    .props=${{
            mobileTopBarTitle: state.mobileTopBarTitle,
        }}
                ></jo-customer-top-bar>
            </div>

            <div style="display: flex; height: 100%">
                <div
                    class="${state.isMobileDevice === true
            ? 'jo-router-customer--sidebar-container-mobile'
            : 'jo-router-customer--sidebar-container-desktop'} ${shouldHideSidebar ===
            true
            ? 'jo-router-customer--sidebar-container-closed'
            : 'jo-router-customer--sidebar-container-open'}"
                >
                    <jo-customer-sidebar
                        .isMobileDevice=${state.isMobileDevice}
                        .authenticatedUser=${state.authenticatedUser}
                        .companyId=${companyId}
                        .selectedMainOption=${state.route.selectedCustomerSideBarMainOption}
                    ></jo-customer-sidebar>
                </div>

                <div
                    class="${state.isMobileDevice === true
            ? 'jo-router-customer--content-container-mobile'
            : 'jo-router-customer--content-container-desktop'}"
                    @click=${() => {
            if (state.isMobileDevice === true && state.showSidebar === true) {
                (0, utilities_1.raiseSetterAction)(this, 'showSidebar', false);
            }
        }}
                >
                    ${window.customElements.get('jo-graphql-playground')
            ? (0, element_vir_1.html) `
                              <div
                                  style="height:100%"
                                  ?hidden=${state.route.pathname !== '/graphql'}
                              >
                                  <jo-graphql-playground></jo-graphql-playground>
                              </div>
                          `
            : ''}
                    ${window.customElements.get('jo-customer-dashboard')
            ? (0, element_vir_1.html) `
                              <div
                                  style="${state.isMobileDevice === true
                ? 'padding: 0'
                : 'padding: 2rem'}"
                                  ?hidden=${state.route.pathname !== '/' &&
                state.route.pathname !== '/customer/dashboard'}
                              >
                                  <jo-customer-dashboard
                                      .authenticatedUser=${state.authenticatedUser}
                                      .companyId=${companyId}
                                      .isMobileDevice=${state.isMobileDevice}
                                  ></jo-customer-dashboard>
                              </div>
                          `
            : ''}
                    ${window.customElements.get('jo-customer-call-instructions-general-setup')
            ? (0, element_vir_1.html) `
                              <div
                                  style="${state.isMobileDevice === true
                ? 'padding: 0'
                : 'padding: 2rem'}"
                                  ?hidden=${state.route.pathname !==
                '/customer/call-instructions/general-setup'}
                              >
                                  <jo-customer-call-instructions-general-setup
                                      .authenticatedUser=${state.authenticatedUser}
                                      .companyId=${companyId}
                                      .isMobileDevice=${state.isMobileDevice}
                                  ></jo-customer-call-instructions-general-setup>
                              </div>
                          `
            : ''}
                    ${window.customElements.get('jo-customer-call-instructions-services-pricing')
            ? (0, element_vir_1.html) `
                              <div
                                  style="${state.isMobileDevice === true
                ? 'padding: 0'
                : 'padding: 2rem'}"
                                  ?hidden=${state.route.pathname !==
                '/customer/call-instructions/services-pricing'}
                              >
                                  <jo-customer-call-instructions-services-pricing
                                      .authenticatedUser=${state.authenticatedUser}
                                      .companyId=${companyId}
                                      .isMobileDevice=${state.isMobileDevice}
                                  ></jo-customer-call-instructions-services-pricing>
                              </div>
                          `
            : ''}
                    ${window.customElements.get('jo-company-call-instruction')
            ? (0, element_vir_1.html) `
                              <div
                                  style=${state.isMobileDevice
                ? 'padding-bottom: 4rem'
                : 'padding: 0rem 2rem 2rem 2rem'}
                                  ?hidden=${state.route.pathname !== '/company/call-instruction'}
                              >
                                  <jo-company-call-instruction
                                      .authenticatedUser=${state.authenticatedUser}
                                      .companyId=${state.route.companyId}
                                      .callInstructionId=${state.route.callInstructionId}
                                  ></jo-company-call-instruction>
                              </div>
                          `
            : ''}
                    ${window.customElements.get('jo-company-quick-notes')
            ? (0, element_vir_1.html) `
                              <div
                                  style="${state.isMobileDevice === true
                ? 'padding: 0'
                : 'padding: 2rem'}"
                                  ?hidden=${state.route.pathname !==
                '/customer/call-instructions/quick-notes'}
                              >
                                  <jo-company-quick-notes
                                      .companyId=${state.route.companyId}
                                      .authenticatedUser=${state.authenticatedUser}
                                  ></jo-company-quick-notes>
                              </div>
                          `
            : ''}
                    ${window.customElements.get('jo-customer-call-instructions-script-pages')
            ? (0, element_vir_1.html) `
                              <div
                                  style="${state.isMobileDevice === true
                ? 'padding: 0'
                : 'padding: 2rem'}"
                                  ?hidden=${state.route.pathname !==
                '/customer/call-instructions/script-pages'}
                              >
                                  <jo-customer-call-instructions-script-pages
                                      .authenticatedUser=${state.authenticatedUser}
                                      .companyId=${companyId}
                                      .isMobileDevice=${state.isMobileDevice}
                                  ></jo-customer-call-instructions-script-pages>
                              </div>
                          `
            : ''}
                    ${window.customElements.get('jo-customer-action-items')
            ? (0, element_vir_1.html) `
                              <div
                                  style="${state.isMobileDevice === true
                ? 'padding: 0'
                : 'padding: 2rem'}"
                                  ?hidden=${state.route.pathname !== '/customer/action-items'}
                              >
                                  <jo-customer-action-items
                                      .authenticatedUser=${state.authenticatedUser}
                                      .companyId=${companyId}
                                      .isMobileDevice=${state.isMobileDevice}
                                  ></jo-customer-action-items>
                              </div>
                          `
            : ''}
                    ${window.customElements.get('jo-customer-action-item')
            ? (0, element_vir_1.html) `
                              <div
                                  style="${state.isMobileDevice === true
                ? 'padding: 0'
                : 'padding: 2rem'}"
                                  ?hidden=${state.route.pathname !== '/customer/action-item'}
                              >
                                  <jo-customer-action-item
                                      .props=${{
                authenticatedUser: state.authenticatedUser,
                actionItemId: state.route.actionItemId,
                isMobileDevice: state.isMobileDevice,
                companyId,
                actionItemMode: state.route.customerActionItemMode,
            }}
                                  ></jo-customer-action-item>
                              </div>
                          `
            : ''}
                    ${window.customElements.get('jo-chat-widget-settings') &&
            state.route.pathname === '/customer/chat-widget-script-generator' &&
            state.isMobileDevice === false
            ? (0, element_vir_1.html) `
                              <div>
                                  <jo-chat-widget-settings
                                      .props=${{
                authenticatedUser: state.authenticatedUser,
                companyId: state.route.companyId,
                isMobileDevice: state.isMobileDevice,
            }}
                                  ></jo-chat-widget-settings>
                              </div>
                          `
            : ''}
                    ${window.customElements.get('jo-customer-contacts')
            ? (0, element_vir_1.html) `
                              <div
                                  style="${state.isMobileDevice === true
                ? 'padding: 0'
                : 'padding: 2rem'}"
                                  ?hidden=${state.route.pathname !== '/customer/contact-list'}
                              >
                                  <jo-customer-contacts
                                      .authenticatedUser=${state.authenticatedUser}
                                      .companyId=${companyId}
                                      .isMobileDevice=${state.isMobileDevice}
                                  ></jo-customer-contacts>
                              </div>
                          `
            : ''}
                    ${window.customElements.get('jo-contact-save')
            ? (0, element_vir_1.html) `
                              <div
                                  style="${state.isMobileDevice === true
                ? 'padding: 0'
                : 'padding: 2rem'}"
                                  ?hidden=${state.route.pathname !== '/customer/contact'}
                              >
                                  <jo-contact-save
                                      .authenticatedUser=${state.authenticatedUser}
                                      .companyId=${state.route.companyId}
                                      .contactId=${state.route.contactId}
                                      .isMobileDevice=${state.isMobileDevice}
                                  ></jo-contact-save>
                              </div>
                          `
            : ''}
                    ${window.customElements.get('jo-customer-company-profile')
            ? (0, element_vir_1.html) `
                              <div
                                  style="${state.isMobileDevice ? 'padding: 0' : 'padding: 2rem'}"
                                  ?hidden=${state.route.pathname !== '/customer/company-profile'}
                              >
                                  <jo-customer-company-profile
                                      .authenticatedUser=${state.authenticatedUser}
                                      .companyId=${companyId}
                                      .isMobileDevice=${state.isMobileDevice}
                                  ></jo-customer-company-profile>
                              </div>
                          `
            : ''}
                    ${window.customElements.get('jo-customer-employees')
            ? (0, element_vir_1.html) `
                              <div
                                  style="${state.isMobileDevice === true
                ? 'padding: 0'
                : 'padding: 2rem'}"
                                  ?hidden=${state.route.pathname !== '/customer/users'}
                              >
                                  <jo-customer-employees
                                      .authenticatedUser=${state.authenticatedUser}
                                      .companyId=${companyId}
                                      .isMobileDevice=${state.isMobileDevice}
                                  ></jo-customer-employees>
                              </div>
                          `
            : ''}
                    ${window.customElements.get('jo-company-employee')
            ? (0, element_vir_1.html) `
                              <div
                                  style="${state.isMobileDevice === true
                ? 'padding: 0'
                : 'padding: 2rem'}"
                                  ?hidden=${!state.route.pathname.includes('/customer/employee')}
                              >
                                  <jo-company-employee
                                      .props=${{
                authenticatedUser: state.authenticatedUser,
                companyId: companyId,
                employeeId: state.route.employeeId,
            }}
                                      .isMobileDevice=${state.isMobileDevice}
                                  ></jo-company-employee>
                              </div>
                          `
            : ''}
                    ${window.customElements.get('jo-customer-billing')
            ? (0, element_vir_1.html) `
                              <div
                                  style="${state.isMobileDevice === true
                ? 'padding: 0'
                : 'padding: 2rem'}"
                                  ?hidden=${state.route.pathname !== '/customer/billing'}
                              >
                                  <jo-customer-billing
                                      .authenticatedUser=${state.authenticatedUser}
                                      .companyId=${companyId}
                                      .isMobileDevice=${state.isMobileDevice}
                                  ></jo-customer-billing>
                              </div>
                          `
            : ''}
                    ${window.customElements.get('jo-invoice')
            ? (0, element_vir_1.html) `
                              <div
                                  style="${state.isMobileDevice === true
                ? 'padding: 0'
                : 'padding: 2rem'}"
                                  ?hidden=${state.route.pathname !== '/customer/invoice'}
                              >
                                  <jo-invoice
                                      .props=${{
                authenticatedUser: state.authenticatedUser,
                companyId: companyId,
            }}
                                      .isMobileDevice=${state.isMobileDevice}
                                  ></jo-invoice>
                              </div>
                          `
            : ''}
                    ${window.customElements.get(jo_company_logs_container_page_1.JoCompanyLogsContainerPage.tagName)
            ? (0, element_vir_1.html) `
                              <div
                                  style="${state.isMobileDevice === true
                ? 'padding: 0'
                : 'padding: 2rem'}"
                                  ?hidden=${state.route.pathname !== '/customer/logs'}
                              >
                                  <jo-company-logs-container-page
                                      ${(0, element_vir_1.assign)(jo_company_logs_container_page_1.JoCompanyLogsContainerPage, {
                authenticatedUser: (0, replace_not_set_with_undefined_1.replaceNotSetWithUndefined)(state.authenticatedUser),
                companyId: (0, replace_not_set_with_undefined_1.replaceNotSetWithUndefined)(companyId),
                isMobileDevice: state.isMobileDevice,
            })}
                                  ></jo-company-logs-container-page>
                              </div>
                          `
            : ''}
                    ${window.customElements.get('jo-google-calendar-auth')
            ? (0, element_vir_1.html) `
                              <div
                                  style="${state.isMobileDevice === true
                ? 'padding: 0'
                : 'padding: 2rem'}"
                                  ?hidden=${state.route.pathname !== '/customer/company-calendars'}
                              >
                                  <jo-google-calendar-auth
                                      .companyId=${companyId}
                                      .authenticatedUser=${state.authenticatedUser}
                                  ></jo-google-calendar-auth>
                              </div>
                          `
            : ''}
                    ${window.customElements.get('jo-customer-crew-cal-management')
            ? (0, element_vir_1.html) `
                              <div
                                  style="${state.isMobileDevice === true
                ? 'padding: 0'
                : 'padding: 2rem'}"
                                  ?hidden=${state.route.pathname !==
                '/customer/crew-cal-management'}
                              >
                                  <jo-customer-crew-cal-management
                                      .companyId=${companyId}
                                  ></jo-customer-crew-cal-management>
                              </div>
                          `
            : ''}
                    ${window.customElements.get('jo-customer-outbound-dashboard')
            ? (0, element_vir_1.html) `
                              <div
                                  style="${state.isMobileDevice === true
                ? 'padding: 0'
                : 'padding: 2rem'}"
                                  ?hidden=${state.route.pathname !== '/customer/outbound' ||
                state.isMobileDevice}
                              >
                                  <jo-customer-outbound-dashboard
                                      .authenticatedUser=${state.authenticatedUser}
                                      .companyId=${companyId}
                                  ></jo-customer-outbound-dashboard>
                              </div>
                          `
            : ''}
                    ${window.customElements.get('jo-company-voicemails')
            ? (0, element_vir_1.html) `
                              <div
                                  style="${state.isMobileDevice === true
                ? 'padding: 0'
                : 'padding: 2rem'}"
                                  ?hidden=${state.route.pathname !== '/customer/voicemail'}
                              >
                                  <jo-company-voicemails
                                      .props=${{
                authenticatedUser: state.authenticatedUser,
                companyId: companyId,
            }}
                                      .isMobileDevice=${state.isMobileDevice}
                                  ></jo-company-voicemails>
                              </div>
                          `
            : ''}
                </div>
            </div>

            <div ?hidden=${shouldHideTopBar} class="jo-router-customer--top-bar-container">
                <jo-customer-bottom-bar
                    .props=${{
            authenticatedUser: state.authenticatedUser,
            companyId: companyId,
            selectedOption: state.route.selectedCustomerSideBarMainOption,
        }}
                ></jo-customer-bottom-bar>
            </div>
        `;
        return (0, element_vir_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .jo-router-customer--main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                }

                .jo-router-customer--sidebar-container-mobile {
                    position: fixed;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    flex: none;
                    z-index: 1000;
                    overflow-y: auto;
                    transition: width 0.25s;
                }

                .jo-router-customer--sidebar-container-desktop {
                    flex: 1;
                }

                .jo-router-customer--sidebar-container-open {
                    width: 70%;
                }

                .jo-router-customer--sidebar-container-closed {
                    width: 0;
                }

                .jo-router-customer--content-container-mobile {
                    flex: 1;
                    padding-top: calc(40px + 3vh);
                }

                .jo-router-customer--content-container-desktop {
                    flex: 8;
                    overflow: auto;
                }
            </style>

            <div class="jo-router-customer--main-container">
                <jo-loading
                    .props=${{
            loading: state.loading,
        }}
                ></jo-loading>

                ${(0, element_vir_1.renderIf)(state.route.pathname === '/oauth', (0, element_vir_1.html) `
                        <${jo_grant_oauth_access_element_1.JoGrantOauthAccess}
                            ${(0, element_vir_1.assign)(jo_grant_oauth_access_element_1.JoGrantOauthAccess, {
            url: (0, url_1.getLocationAsUrl)(),
        })}
                        ></${jo_grant_oauth_access_element_1.JoGrantOauthAccess}>
                    `, customerTemplate)}
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-router-customer', JORouterCustomer);
function getCompanyIdFromAuthenticatedUser(authenticatedUser) {
    if (authenticatedUser === 'NOT_SET' || !(0, common_1.isLengthAtLeast)(authenticatedUser.companies, 1)) {
        return 'NOT_SET';
    }
    return authenticatedUser.companies[0].id;
}
