"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shouldEventTypeBeIncluded = exports.shouldPersonBeIncluded = exports.getUniqueEventTypes = exports.getEventTypesList = exports.getUniquePersonNamesFromStatusEvents = exports.getPersonNamesList = exports.prependAllOptionToList = void 0;
const utilities_1 = require("../../../../services/utilities");
const prepend_to_list_1 = require("../../../../utilities/data-structures/lists/prepend-to-list");
const get_full_name_1 = require("../../../../utilities/get-full-name");
function prependAllOptionToList(list) {
    return (0, prepend_to_list_1.prependToList)(list, 'All');
}
exports.prependAllOptionToList = prependAllOptionToList;
function getPersonNamesList(statusEvents) {
    return prependAllOptionToList(getUniquePersonNamesFromStatusEvents(statusEvents));
}
exports.getPersonNamesList = getPersonNamesList;
function getUniquePersonNamesFromStatusEvents(statusEvents) {
    const companyUserStatusEvents = statusEvents.filter(event => !(0, utilities_1.userIsJill)(event.user));
    const allUserNames = companyUserStatusEvents.map(event => (0, get_full_name_1.getFullName)(event.user));
    const uniqueUserNames = [...new Set(allUserNames)];
    return uniqueUserNames;
}
exports.getUniquePersonNamesFromStatusEvents = getUniquePersonNamesFromStatusEvents;
function getEventTypesList(statusEvents) {
    return prependAllOptionToList(getUniqueEventTypes(statusEvents));
}
exports.getEventTypesList = getEventTypesList;
function getUniqueEventTypes(statusEvents) {
    const companyUserStatusEvents = statusEvents.filter(event => !(0, utilities_1.userIsJill)(event.user));
    const allEventTypes = companyUserStatusEvents.map(event => event.type);
    const uniqueEventTypes = [...new Set(allEventTypes)];
    return uniqueEventTypes;
}
exports.getUniqueEventTypes = getUniqueEventTypes;
function shouldPersonBeIncluded(selectedPersonName, event) {
    if ((0, utilities_1.userIsJill)(event.user)) {
        return false;
    }
    return selectedPersonName === 'All' || (0, get_full_name_1.getFullName)(event.user) === selectedPersonName;
}
exports.shouldPersonBeIncluded = shouldPersonBeIncluded;
function shouldEventTypeBeIncluded(selectedEventType, event) {
    return selectedEventType === 'All' || event.type === selectedEventType;
}
exports.shouldEventTypeBeIncluded = shouldEventTypeBeIncluded;
