"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BusinessHourTimeField = exports.DayOfWeek = void 0;
var DayOfWeek;
(function (DayOfWeek) {
    DayOfWeek["SUNDAY"] = "SUNDAY";
    DayOfWeek["MONDAY"] = "MONDAY";
    DayOfWeek["TUESDAY"] = "TUESDAY";
    DayOfWeek["WEDNESDAY"] = "WEDNESDAY";
    DayOfWeek["THURSDAY"] = "THURSDAY";
    DayOfWeek["FRIDAY"] = "FRIDAY";
    DayOfWeek["SATURDAY"] = "SATURDAY";
})(DayOfWeek = exports.DayOfWeek || (exports.DayOfWeek = {}));
var BusinessHourTimeField;
(function (BusinessHourTimeField) {
    BusinessHourTimeField["OPEN"] = "open";
    BusinessHourTimeField["CLOSE"] = "close";
})(BusinessHourTimeField = exports.BusinessHourTimeField || (exports.BusinessHourTimeField = {}));
