"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getJOJillStyles = void 0;
const lit_html_1 = require("lit-html");
const css_utils_1 = require("../../../utilities/css-utils");
const cssName = (0, css_utils_1.cssPrefixer)('jo-jill');
function getJOJillStyles() {
    return (0, lit_html_1.html) `
        <style>
            .jo-jill--container {
                padding-left: calc(25px + 1vmin);
                padding-right: calc(25px + 1vmin);
                padding-top: calc(1px + 1vmin);
                padding-bottom: 2rem;
            }

            .jo-jill--stats-container {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                align-items: center;
            }

            .jo-jill--stats-header {
                font-weight: bold;
                padding-bottom: calc(5px + 1vmin);
                padding-left: 15;
                padding-right: 15px;
            }

            .jo-jill--stats-item {
                padding-bottom: calc(5px + 1vmin);
                padding-left: 15px;
                padding-right: 15px;
            }

            .jo-jill--calls-container {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            }

            .jo-jill--calls-header {
                font-weight: bold;
                padding-bottom: calc(5px + 1vmin);
            }

            .jo-jill--calls-item {
                padding-bottom: calc(5px + 1vmin);
            }

            .jo-jill--bold {
                font-weight: bold;
            }

            .jo-jill--pointer {
                cursor: pointer;
            }

            .jo-jill--inlcude-test-stats-checkbox {
                margin-top: 1rem;
            }

            .jo-jill--dates-and-charts {
                display: grid;
                grid-template-columns: 1fr 5fr;
            }

            .jo-jill--charts {
                display: flex;
                overflow-x: scroll;
            }

            .jo-jill--row:hover > div {
                background-color: var(--jo-primary-light);
            }

            .jo-jill--row {
                display: contents;
            }

            .jo-jill--dates-container {
                display: flex;
                justify-content: space-between;
            }

            .jo-jill--assign-missing-calls-button-container {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }

            .jo-jill--playIcon {
                color: var(--jo-secondary);
            }

            .jo-jill--playIcon:hover {
                cursor: pointer;
                color: var(--jo-secondary-light);
            }

            .jo-jill--flag-table-container {
                padding-top: 3rem;
            }

            .jo-jill--total-stats-container {
                display: flex;
                flex-direction: row;
                gap: 1rem;
                box-sizing: border-box;
                height: 100%;
                width: 100%;
                min-width: 350px;
                box-shadow: var(--jo-card-shadow-1);
                border-radius: var(--jo-narrow-border-radius-1);
                font-family: sans-serif;
                color: var(--jo-text-default);
                font-weight: 200;
                background-color: white;
                padding: 1rem;
            }

            .jo-jill--underline {
                text-decoration: underline;
                padding-left: 1rem;
            }

            .${cssName('red-cell')} {
                background-color: red;
                color: white;
                height: 100%;
                width: 100%;
                box-shadow: 0 0 20px red;
            }
            .jo-jill-stats {
                margin-bottom: 0.5rem;
                box-sizing: border-box;
                border-top: 1px solid rgb(215, 215, 215);
                padding: 0.5rem;
                font-size: 0.8rem;
                display: flex;
                justify-content: space-between;
            }

            .jo-jill-stats:hover {
                background-color: var(--jo-accent-background-light);
            }
        </style>
    `;
}
exports.getJOJillStyles = getJOJillStyles;
