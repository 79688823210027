"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BillingReportStatus = void 0;
var BillingReportStatus;
(function (BillingReportStatus) {
    BillingReportStatus["ALL"] = "All";
    BillingReportStatus["PAID"] = "Paid";
    BillingReportStatus["UNPAID"] = "Unpaid";
    BillingReportStatus["DISCREPANT"] = "Discrepant";
    BillingReportStatus["UNKNOWN"] = "Unknown";
    BillingReportStatus["MANUALLY_CHARGED"] = "Manually Charged";
    BillingReportStatus["DEFAULTED"] = "Defaulted";
    BillingReportStatus["BAD_DEBT"] = "Bad Debt";
})(BillingReportStatus = exports.BillingReportStatus || (exports.BillingReportStatus = {}));
