"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPodNames = exports.doesCompanyMatchCompanyStatus = exports.getCompanyVipStatus = exports.filterCompanies = exports.getIndustries = void 0;
const is_string_1 = require("../../../../utilities/data-structures/strings/is-string");
const is_defined_1 = require("../../../../utilities/is-defined");
const calculations_1 = require("./calculations");
function getIndustries(companies) {
    const industries = getAllIndustryNames(companies);
    const uniqueIndustries = Array.from(new Set(industries));
    const sortedUniqueIndustries = [
        ...uniqueIndustries.sort((a, b) => a.localeCompare(b)),
    ];
    return sortedUniqueIndustries;
}
exports.getIndustries = getIndustries;
function getAllIndustryNames(companies) {
    return companies.map(company => company.industry).filter(is_string_1.isString);
}
function filterCompanies(params) {
    try {
        const { companies, paymentStatus, podName, industry, niche, referralSource, referralTag, companyStatus, companyType, vipStatus, } = params;
        return companies.filter(company => doesCompanyMatch(company, { fieldName: 'industry', value: industry }) &&
            doesCompanyMatch(company, { fieldName: 'niche', value: niche }) &&
            doesCompanyMatch(company, {
                fieldName: 'referral_source',
                value: referralSource,
            }) &&
            doesCompanyMatch(company, { fieldName: 'referral_tag', value: referralTag }) &&
            doesCompanyMatch(company, {
                fieldName: [
                    'pod',
                    'name',
                ],
                value: podName,
            }) &&
            doesCompanyMatch(company, {
                modifierFn: calculations_1.determineCompanyPaymentStatus,
                value: paymentStatus,
            }) &&
            doesCompanyMatch(company, { modifierFn: getCompanyStatus, value: companyStatus }) &&
            doesCompanyMatch(company, { modifierFn: getCompanyType, value: companyType }) &&
            doesCompanyMatch(company, { modifierFn: getCompanyVipStatus, value: vipStatus }));
    }
    catch (error) {
        throw new Error(`filterCompanies error: ${error}`);
    }
}
exports.filterCompanies = filterCompanies;
function doesCompanyMatch(company, options) {
    try {
        const { modifierFn, fieldName, value } = options;
        if (value.toLowerCase() === 'all') {
            return true;
        }
        if ((0, is_defined_1.isDefined)(modifierFn)) {
            return modifierFn(company) === value;
        }
        if (!fieldName) {
            throw new Error(`fieldName is not defined, ${JSON.stringify({ company, options })}`);
        }
        return doesCompanyMatchFieldName(company, fieldName, value);
    }
    catch (error) {
        throw new Error(`doesCompanyMatch error: ${error}`);
    }
}
function doesCompanyMatchFieldName(company, fieldName, value) {
    if (typeof fieldName === 'string') {
        return company[fieldName] === value;
    }
    const [key1, key2,] = fieldName;
    return company[key1] && company[key1][key2] === value;
}
function getCompanyType(company) {
    if (!(0, is_defined_1.isDefined)(company.is_test_company))
        return 'Unknown';
    if (company.is_test_company)
        return 'Test';
    return 'Real';
}
function getCompanyStatus(company) {
    if (!(0, is_defined_1.isDefined)(company.canceled))
        return 'Unknown';
    if (company.canceled)
        return 'Canceled';
    return 'Active';
}
function getCompanyVipStatus(company) {
    if (!(0, is_defined_1.isDefined)(company.is_vip))
        return 'All';
    if (company.is_vip)
        return 'VIP';
    if (!company.is_vip)
        return 'Not VIP';
    return 'All';
}
exports.getCompanyVipStatus = getCompanyVipStatus;
function doesCompanyMatchCompanyStatus(company, companyStatus) {
    if (companyStatus === 'Active') {
        return company.canceled === false;
    }
    if (companyStatus === 'Canceled') {
        return company.canceled === true;
    }
    return true;
}
exports.doesCompanyMatchCompanyStatus = doesCompanyMatchCompanyStatus;
function getPodNames(companies) {
    const podNames = getAllPodNames(companies);
    const uniquePodNames = Array.from(new Set(podNames));
    const sortedUniquePodNames = [
        ...uniquePodNames.sort((a, b) => a.localeCompare(b)),
    ];
    return sortedUniquePodNames;
}
exports.getPodNames = getPodNames;
function getAllPodNames(companies) {
    return companies
        .map(company => company.pod?.name)
        .filter((podName) => podName !== undefined && podName !== null);
}
