"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const jo_company_employees_1 = require("./jo-company-employees");
const utilities_1 = require("../../../services/utilities");
require("../jo-button.element");
require("../jo-input");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const InitialState = {
    item: {
        id: 0,
        first_name: '',
        last_name: '',
        company_name: '',
        created_at: '',
        phone_number: '',
        phone_numbers: [{ id: 0, number: '', created_at: '' }],
        updated_at: '',
        isCompanyUser: false,
        companyId: 0,
        auth_role: 'NO_ROLE',
    },
};
class JOCompanyEmployeesMobileTemplate extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }
                .jo-mobile--table-container {
                    box-sizing: border-box;
                    width: 100%;
                    font-size: 0.9rem;
                    padding: 0.5rem;
                    background-color: white;
                    box-shadow: var(--jo-card-shadow-1);
                    border-radius: var(--jo-wide-border-radius-1);
                    margin: 0.75rem 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }

                .contact-name {
                    font-size: 1rem;
                    font-weight: bold;
                    padding: 0.5rem;
                    display: block;
                    text-decoration: none;
                    color: black;
                }
                .role-row {
                    padding: 0.5rem;
                }
                .table-link {
                    text-decoration: none;
                    color: black;
                }
            </style>

            <div class="main-container">
                <a
                    class="table-link"
                    href=${state.item.isCompanyUser
            ? `/customer/employee/edit?employeeId=${state.item.id}&companyId=${state.item.companyId}`
            : `/company/employee/edit?employeeId=${state.item.id}&companyId=${state.item.companyId}`}
                >
                    <div class="jo-mobile--table-container">
                        <div class="contact-name">
                            ${`${state.item.first_name} ${state.item.last_name}`}
                        </div>
                        <div class="role-row">${(0, jo_company_employees_1.setRoleFriendlyDisplay)(state.item.auth_role)}</div>
                    </div>
                </a>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-employees-mobile-template', JOCompanyEmployeesMobileTemplate);
