"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOJill = void 0;
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../../services/graphql");
const utilities_1 = require("../../../services/utilities");
const format_date_for_datetime_local_input_1 = require("../../../utilities/dates-and-times/format-date-for-datetime-local-input");
const personal_admin_1 = require("../../../utilities/personal-admin");
require("../jo-action-item-flags-table/jo-action-item-flags-table");
require("../jo-account-help-table/jo-account-help-table.element");
require("../jo-button.element");
const helper_utils_1 = require("../jo-call-screen/jo-action-item-flag-card/helper-utils");
require("../jo-jill-card");
require("../jo-table");
const css_1 = require("./css");
const helper_utils_2 = require("./helper-utils");
const mutations_1 = require("./mutations");
const queries_1 = require("./queries");
const redux_1 = require("./redux");
const calculate_time_spent_manually_unavailable_1 = require("./utils/calculate-time-spent-manually-unavailable");
const calculate_total_chats_completed_1 = require("./utils/calculate-total-chats-completed");
const get_number_of_likely_ignored_calls_1 = require("./utils/get-number-of-likely-ignored-calls");
const get_number_of_likely_pushed_calls_1 = require("./utils/get-number-of-likely-pushed-calls");
const get_number_of_new_appointments_1 = require("./utils/get-number-of-new-appointments");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
const element_vir_1 = require("element-vir");
const jo_account_help_table_element_1 = require("../jo-account-help-table/jo-account-help-table.element");
const was_call_an_outbound_call_1 = require("../../../utilities/calls/was-call-an-outbound-call");
const auth_rules_1 = require("../../../utilities/auth-rules");
const extract_status_events_of_type_1 = require("../../../utilities/user-status-events/extract-status-events-of-type");
const dates_and_times_1 = require("../../../utilities/dates-and-times/dates-and-times");
const call_duration_1 = require("../../../utilities/calls/call-stats/call-duration");
const calculate_user_status_event_duration_1 = require("./utils/calculate-user-status-event-duration");
const startOfToday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
const startOfTomorrow = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1);
const InitialState = {
    actionItemFlags: [],
    authenticatedUser: 'NOT_SET',
    jillId: 'NOT_SET',
    jill: 'NOT_SET',
    calls: [],
    chatDurationEvents: [],
    jillAnswerAttempts: [],
    jillHighLevelStatses: 'NOT_SET',
    highLevelStatsForMonthsCollapsed: [],
    includeTestCompaniesAndPods: false,
    activityLogStartDate: startOfToday.toISOString(),
    activityLogEndDate: startOfTomorrow.toISOString(),
    statsStartDate: startOfToday.toISOString(),
    statsEndDate: startOfTomorrow.toISOString(),
    callsAndStatusEvents: [],
    statusEvents: [],
    loadingCalls: true,
    loadingStats: true,
    loadingJillAnswerAttempts: false,
    fetchCallsAbortController: 'NOT_SET',
    fetchStatsAbortController: 'NOT_SET',
    shifts: [],
    showAccountHelpTable: false,
    showCalls: false,
    showDatesAndCharts: true,
    showJillName: true,
    showJillUserInfo: true,
    showFlagsTable: false,
    assigningMissingUrlsToCalls: false,
};
const seconds25 = dates_and_times_1.oneSecondInMilliseconds * 25;
const minutes10 = dates_and_times_1.oneMinuteInMilliseconds * 10;
class JOJill extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, redux_1.RootReducer);
    }
    set jill(jill) {
        // TODO keep an eye on this equality check, could be a source of strange issues
        if (jill === this.store.jill) {
            return;
        }
        this.store.jill = jill;
        if (jill === 'NOT_SET') {
            return;
        }
        this.store.jillId = jill.id;
        this.store.statsStartDate = startOfToday.toISOString();
        this.store.statsEndDate = startOfTomorrow.toISOString();
        this.store.activityLogStartDate = startOfToday.toISOString();
        this.store.activityLogEndDate = startOfTomorrow.toISOString();
        if (this.store.showCalls === true) {
            this.fetchAndSetAllData();
        }
        else {
            this.fetchAndSetStats();
        }
    }
    set jillId(jillId) {
        if (jillId === 'NOT_SET' || jillId === this.store.jillId) {
            return;
        }
        this.store.jillId = jillId;
        this.resetStartAndEndDates();
        if (this.store.showCalls === true) {
            this.fetchAndSetAllData();
        }
        else {
            this.fetchAndSetJill();
            this.fetchAndSetStats();
        }
    }
    async fetchAndSetAllData() {
        await Promise.all([
            this.fetchAndSetJill(),
            this.fetchAndSetStats(),
            this.fetchAndSetInfoForActivityLog(),
            this.fetchAndSetActionItemFlags(),
        ]);
    }
    async fetchAndSetActionItemFlags() {
        (0, utilities_1.assertIsSet)(this.store.jillId);
        const actionItemFlagsResult = await (0, queries_1.fetchFlaggedActionItemsForJill)({
            jillId: this.store.jillId,
            startDate: this.store.activityLogStartDate,
            endDate: this.store.activityLogEndDate,
        });
        (0, utilities_1.assertSucceeded)(actionItemFlagsResult);
        this.store.actionItemFlags = actionItemFlagsResult.value;
    }
    resetStartAndEndDates() {
        this.store.statsStartDate = startOfToday.toISOString();
        this.store.statsEndDate = startOfTomorrow.toISOString();
        this.store.activityLogStartDate = startOfToday.toISOString();
        this.store.activityLogEndDate = startOfTomorrow.toISOString();
    }
    set statsStartDate(statsStartDate) {
        this.store.statsStartDate = statsStartDate;
        this.fetchAndSetStats();
    }
    set statsEndDate(statsEndDate) {
        this.store.statsEndDate = statsEndDate;
        this.fetchAndSetStats();
    }
    async fetchAndSetJill() {
        (0, utilities_1.assertIsSet)(this.store.jillId, () => {
            (0, utilities_1.joAlert)('Error', 'Jill ID is not set');
        });
        const result = await (0, queries_1.fetchJill)(this.store.jillId);
        (0, utilities_1.assertSucceeded)(result, utilities_1.handleError);
        const jill = result.value;
        this.store.jill = jill;
    }
    async fetchAndSetStats() {
        this.store.loadingStats = true;
        const jillId = this.store.jillId;
        if (jillId === 'NOT_SET') {
            return;
        }
        const currentFetchStatsAbortController = this.store.fetchStatsAbortController;
        if (currentFetchStatsAbortController !== 'NOT_SET') {
            currentFetchStatsAbortController.abort();
        }
        const { abortController, execute } = (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint);
        this.store.fetchStatsAbortController = abortController;
        const jillHighLevelStatses = await (0, queries_1.fetchJillHighLevelStatses)(execute, jillId, this.store.statsStartDate, this.store.statsEndDate, this.store.includeTestCompaniesAndPods);
        this.store.fetchStatsAbortController = 'NOT_SET';
        this.store.dispatch({
            type: 'SET_JILL_HIGH_LEVEL_STATSES',
            jillHighLevelStatses,
        });
        this.store.loadingStats = false;
        this.loadCharts();
    }
    async fetchAndSetCalls() {
        this.store.loadingCalls = true;
        const jillId = this.store.jillId;
        if (jillId === 'NOT_SET') {
            return;
        }
        const currentFetchCallsAbortController = this.store.fetchCallsAbortController;
        if (currentFetchCallsAbortController !== 'NOT_SET') {
            currentFetchCallsAbortController.abort();
        }
        const { abortController, execute } = (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint);
        this.store.fetchCallsAbortController = abortController;
        const calls = await (0, queries_1.fetchCallsForJillStats)(execute, jillId, this.store.activityLogStartDate, this.store.activityLogEndDate);
        this.store.fetchCallsAbortController = 'NOT_SET';
        if (this.store.includeTestCompaniesAndPods) {
            this.store.calls = calls;
        }
        else {
            this.store.calls = calls.filter(call => {
                if (call.company?.is_test_company === false && call.company.pod?.type === 'REGULAR')
                    return call;
            });
        }
        this.store.loadingCalls = false;
    }
    async fetchAndSetStatusEvents() {
        if (this.store.jillId === 'NOT_SET') {
            return;
        }
        this.store.loadingCalls = true;
        await this.fetchAndSetUserStatusEventsForJill(this.store.jillId);
        await this.fetchAndSetChatDurationEventsForJill(this.store.jillId);
        this.store.loadingCalls = false;
    }
    async fetchAndSetUserStatusEventsForJill(jillId) {
        const statusEventsResult = await (0, queries_1.fetchJillStatusEvents)({
            jillId,
            startDate: this.store.activityLogStartDate,
            endDate: this.store.activityLogEndDate,
        });
        (0, utilities_1.assertSucceeded)(statusEventsResult, utilities_1.handleError, this, 'jo-jill > fetchAndSetStatusEvents() > fetchAndSetUserStatusEventsForJill() > statusEventResult');
        this.store.statusEvents = statusEventsResult.value;
    }
    async fetchAndSetChatDurationEventsForJill(jillId) {
        const chatDurationEventsResult = await (0, queries_1.fetchChatDurationEventsForJill)(jillId, this.store.activityLogStartDate, this.store.activityLogEndDate);
        (0, utilities_1.assertSucceeded)(chatDurationEventsResult, utilities_1.handleError, this, 'jo-jill > fetchAndSetStatusEvents() > fetchAndSetChatDurationEventsForJill() > chatDurationEventsResult');
        this.store.chatDurationEvents = chatDurationEventsResult.value;
    }
    async tryFetchAndSetShifts() {
        try {
            await this.fetchAndSetShifts();
        }
        catch (error) {
            (0, utilities_1.handleError)(`fetchAndSetShifts error: ${error}`);
        }
    }
    async fetchAndSetShifts() {
        (0, utilities_1.assertIsSet)(this.store.jillId, utilities_1.handleError, 'jo-jill > fetchAndSetShifts > this.store.jillId');
        this.store.shifts = await (0, queries_1.fetchShifts)(this.store.jillId, new Date(this.store.activityLogStartDate));
    }
    async fetchAndSetInfoForActivityLog() {
        await Promise.all([
            this.fetchAndSetCalls(),
            this.fetchAndSetStatusEvents(),
            this.tryFetchAndSetShifts(),
        ]);
        this.store.callsAndStatusEvents = (0, helper_utils_2.combineCallsAndStatusEvents)(this.store.calls, (0, helper_utils_2.filterStatusEvents)(this.store.statusEvents), this.store.chatDurationEvents);
    }
    async activityLogStartDateInputChanged(e) {
        const newStartDate = new Date(e.target.value).toISOString();
        this.store.activityLogStartDate = newStartDate;
        this.fetchAndSetInfoForActivityLog();
    }
    async activityLogEndDateInputChanged(e) {
        const newEndDate = new Date(e.target.value).toISOString();
        this.store.activityLogEndDate = newEndDate;
        this.fetchAndSetInfoForActivityLog();
    }
    async statsStartDateInputChanged(e) {
        const newStartDate = new Date(e.target.value).toISOString();
        this.store.statsStartDate = newStartDate;
        await this.fetchAndSetStats();
    }
    async statsEndDateInputChanged(e) {
        const newEndDate = new Date(e.target.value).toISOString();
        this.store.statsEndDate = newEndDate;
        await this.fetchAndSetStats();
    }
    async includeTestCompaniesAndPodsInputChanged(e) {
        this.store.includeTestCompaniesAndPods = e.target.checked;
        await this.fetchAndSetStats();
        await this.fetchAndSetInfoForActivityLog();
    }
    loadCharts() {
        const jillHighLevelStatses = this.store.jillHighLevelStatses;
        if (jillHighLevelStatses === 'NOT_SET') {
            return;
        }
        (0, utilities_1.loadChart)(this, jillHighLevelStatses.highLevelStatses, 'chart-total-calls', 'Total calls', 'numCalls');
        (0, utilities_1.loadChart)(this, jillHighLevelStatses.highLevelStatses, 'chart-total-actual-call-duration', 'Total actual call duration', 'actualCallDuration');
        (0, utilities_1.loadChart)(this, jillHighLevelStatses.highLevelStatses, 'chart-total-billable-call-duration', 'Total billable call duration', 'billableCallDuration');
        (0, utilities_1.loadChart)(this, jillHighLevelStatses.highLevelStatses, 'chart-average-efficiency-per-day', 'Average efficiency per day', 'averageEfficiencyPerDay');
        (0, utilities_1.loadChart)(this, jillHighLevelStatses.highLevelStatses, 'chart-average-billable-call-duration', 'Average billable call duration', 'averageBillableCallDuration');
        (0, utilities_1.loadChart)(this, jillHighLevelStatses.highLevelStatses, 'chart-average-actual-call-duration', 'Average actual call duration', 'averageActualCallDuration');
        (0, utilities_1.loadChart)(this, jillHighLevelStatses.highLevelStatses, 'chart-average-time-between-calls', 'Average time between calls', 'averageTimeBetweenCalls');
        (0, utilities_1.loadChart)(this, jillHighLevelStatses.highLevelStatses, 'chart-average-action-item-time', 'Average action item time', 'averageActionItemTime');
    }
    toggleHighLevelStatsForMonthCollapsed(highLevelStatsForMonth) {
        this.store.dispatch({
            type: 'TOGGLE_HIGH_LEVEL_STATS_FOR_MONTH_COLLAPSED',
            highLevelStatsForMonth,
        });
    }
    csvButtonClicked() {
        (0, utilities_1.assertIsSet)(this.store.jillHighLevelStatses, utilities_1.handleError);
        const startDate = (0, utilities_1.formatISODateForFileName)(this.store.statsStartDate);
        const endDate = (0, utilities_1.formatISODateForFileName)(this.store.statsEndDate);
        if (startDate == null)
            throw new Error('startDate is null');
        if (endDate == null)
            throw new Error('endDate is null');
        const fileName = `jill-stats-${startDate}-through-${endDate}.csv`;
        (0, utilities_1.generateAndDownloadCSVForHighLevelStatses)(this.store.jillHighLevelStatses.highLevelStatses, this.store.highLevelStatsForMonthsCollapsed, fileName);
    }
    async handleAssignMissingUrlsToCallsForJill() {
        this.store.assigningMissingUrlsToCalls = true;
        const incomingCallsWithoutRecordingUrls = this.store.calls.filter(call => call.new_direction === 'INCOMING' && call.recording_url === '');
        const callRecordingsInfo = (0, mutations_1.createCallRecordingsinfoFromCalls)(incomingCallsWithoutRecordingUrls);
        const assignUrlsToCallsResult = await (0, mutations_1.assignBulkRecordingUrlsToCalls)(callRecordingsInfo);
        (0, utilities_1.assertSucceeded)(assignUrlsToCallsResult, utilities_1.handleError);
        await this.fetchAndSetCalls();
        this.store.assigningMissingUrlsToCalls = false;
    }
    createCallStatsItems() {
        const callStatsData = [
            {
                name: 'Total Calls',
                data: this.store.calls.length,
            },
            {
                name: 'Scheduled Appointments',
                data: (0, get_number_of_new_appointments_1.getNumberOfNewAppointments)(this.store.calls),
            },
            {
                name: 'Likely Ignored Calls',
                data: (0, get_number_of_likely_ignored_calls_1.getNumberOfLikelyIgnoredCalls)(this.store.callsAndStatusEvents),
            },
            {
                name: 'Likely Pushed Calls',
                data: (0, get_number_of_likely_pushed_calls_1.getNumberOfLikelyPushedCalls)(this.store.callsAndStatusEvents),
            },
            {
                name: 'Callbacks Under 10 Seconds',
                data: (0, helper_utils_2.getCallbacksThatEndedWithinXMilliseconds)(this.store.calls).length,
            },
            {
                name: 'Calls Under 25 Seconds',
                data: (0, call_duration_1.filterCallsByDuration)(seconds25, call_duration_1.ComparisonOperationEnum.LTE, this.store.calls).length,
            },
            {
                name: 'Calls Over 10 Minutes',
                data: (0, call_duration_1.filterCallsByDuration)(minutes10, call_duration_1.ComparisonOperationEnum.GTE, this.store.calls).length,
            },
            {
                name: 'Time Available',
                data: (0, utilities_1.millisecondsToHoursMinutesSeconds)((0, calculate_user_status_event_duration_1.calculateTotalTimeSpentAvailable)(this.store.callsAndStatusEvents)),
            },
            {
                name: ' Manually Unavailable',
                data: (0, utilities_1.millisecondsToHoursMinutesSeconds)((0, calculate_time_spent_manually_unavailable_1.calculateTimeSpentManuallyUnavailable)(this.store.callsAndStatusEvents)),
            },
            {
                name: 'Time Logged Out',
                data: (0, utilities_1.millisecondsToHoursMinutesSeconds)((0, helper_utils_2.calculateTimeSpentLoggedOut)(this.store.callsAndStatusEvents)),
            },
            {
                name: 'Time on Calls',
                data: (0, utilities_1.millisecondsToHoursMinutesSeconds)((0, helper_utils_2.calculateTimeSpentOnCalls)(this.store.calls)),
            },
            {
                name: 'Break Time',
                data: (0, utilities_1.millisecondsToHoursMinutesSeconds)((0, calculate_user_status_event_duration_1.calculateTimeSpentOnBreak)(this.store.callsAndStatusEvents)),
            },
            {
                name: 'Lunch Time',
                data: (0, utilities_1.millisecondsToHoursMinutesSeconds)((0, calculate_user_status_event_duration_1.calculateTimeSpentOnLunch)(this.store.callsAndStatusEvents)),
            },
            {
                name: 'Huddle/1:1 Time',
                data: (0, utilities_1.millisecondsToHoursMinutesSeconds)((0, calculate_user_status_event_duration_1.calculateTimeSpentInTeamHuddle)(this.store.callsAndStatusEvents)),
            },
            {
                name: 'Training Time',
                data: (0, utilities_1.millisecondsToHoursMinutesSeconds)((0, calculate_user_status_event_duration_1.calculateTimeSpentInOffCallTraining)(this.store.callsAndStatusEvents)),
            },
        ];
        return (0, element_vir_1.html) `
            ${callStatsData.map(statsItem => {
            return (0, element_vir_1.html) `
                    <div class="jo-jill-stats">
                        ${statsItem.name}:
                        <span class="jo-jill--underline">${statsItem.data}</span>
                    </div>
                `;
        })}
        `;
    }
    render(state) {
        if (state.jillId === 'NOT_SET') {
            return (0, element_vir_1.html) `
                Loading...
            `;
        }
        const numTimesWentToLunchOutsideOfScheduledTime = (0, helper_utils_2.getNumTimesJillWentToLunchOutsideOfScheduledTime)(state);
        return (0, element_vir_1.html) `
            ${(0, css_1.getJOJillStyles)()}

            <div class="jo-jill--container">
                ${state.showJillName === true
            ? (0, element_vir_1.html) `
                          ${state.showJillUserInfo === true && state.jill !== 'NOT_SET'
                ? (0, element_vir_1.html) `
                                    <h2>${state.jill.first_name} ${state.jill.last_name}</h2>
                                `
                : ''}
                      `
            : ''}

                <h2>Stats</h2>

                <div class="jo-jill--dates-and-charts">
                    <div>
                        <div>Start date</div>
                        <div>
                            <input
                                type="datetime-local"
                                class="jo-global--input"
                                .value=${(0, format_date_for_datetime_local_input_1.formatDateForDatetimeLocalInput)(state.statsStartDate)}
                                @change=${(e) => this.statsStartDateInputChanged(e)}
                            />
                        </div>
                        <br />
                        <div>End date</div>
                        <div>
                            <input
                                type="datetime-local"
                                class="jo-global--input"
                                .value=${(0, format_date_for_datetime_local_input_1.formatDateForDatetimeLocalInput)(state.statsEndDate)}
                                @change=${(e) => this.statsEndDateInputChanged(e)}
                            />
                        </div>
                        <div class="jo-jill--inlcude-test-stats-checkbox">
                            <input
                                type="checkbox"
                                .checked=${state.includeTestCompaniesAndPods}
                                @change=${async (e) => {
            await this.includeTestCompaniesAndPodsInputChanged(e);
        }}
                            />
                            &nbsp;Include Test Pods & Companies
                        </div>
                    </div>

                    <div class="jo-jill--charts">
                        <div id="chart-total-calls-div">
                            <canvas id="chart-total-calls"></canvas>
                        </div>

                        <div id="chart-total-actual-call-duration-div">
                            <canvas id="chart-total-actual-call-duration"></canvas>
                        </div>

                        <div id="chart-total-billable-call-duration-div">
                            <canvas id="chart-total-billable-call-duration"></canvas>
                        </div>

                        <div id="chart-average-efficiency-per-day-div">
                            <canvas id="chart-average-efficiency-per-day"></canvas>
                        </div>

                        <div id="chart-average-billable-call-duration-div">
                            <canvas id="chart-average-billable-call-duration"></canvas>
                        </div>

                        <div id="chart-average-actual-call-duration-div">
                            <canvas id="chart-average-actual-call-duration"></canvas>
                        </div>

                        <div id="chart-average-time-between-calls-div">
                            <canvas id="chart-average-time-between-calls"></canvas>
                        </div>

                        <div id="chart-average-action-item-time-div">
                            <canvas id="chart-average-action-item-time"></canvas>
                        </div>
                    </div>
                </div>

                <br />

                ${state.loadingStats === true || state.jillHighLevelStatses === 'NOT_SET'
            ? (0, element_vir_1.html) `
                          <div>Loading...</div>
                      `
            : (0, element_vir_1.html) `
                          <div class="jo-global--high-level-statses-csv-button-container">
                              <jo-button
                                  .text=${'Download as CSV'}
                                  .type=${'ACTION_BUTTON_2'}
                                  @joButtonClick=${() => this.csvButtonClicked()}
                              ></jo-button>
                          </div>
                          <div class="jo-jill--stats-container">
                              <div class="jo-jill--stats-header">Date</div>
                              <div class="jo-jill--stats-header">Total Calls</div>
                              <div title="Total Actual Call Duration" class="jo-jill--stats-header">
                                  Total ACD
                              </div>
                              <div
                                  title="Total Billable Call Duration"
                                  class="jo-jill--stats-header"
                              >
                                  Total BCD
                              </div>
                              <div title="Average Efficiency Per Day" class="jo-jill--stats-header">
                                  Avg. EPD
                              </div>
                              <div
                                  title="Average Billable Call Duration"
                                  class="jo-jill--stats-header"
                              >
                                  Avg. BCD
                              </div>
                              <div
                                  title="Average Actual Call Duration"
                                  class="jo-jill--stats-header"
                              >
                                  Avg. ACD
                              </div>
                              <div title="Average Time Between Calls" class="jo-jill--stats-header">
                                  Avg. TBC
                              </div>
                              <div title="Average Action Item Time" class="jo-jill--stats-header">
                                  Avg. AI Time
                              </div>

                              <div class="jo-jill--row">
                                  <div class="jo-jill--stats-item jo-jill--bold">Total Range</div>
                                  <div class="jo-jill--stats-item">
                                      ${state.jillHighLevelStatses.highLevelStatses.highLevelStats
                .numCalls}
                                  </div>
                                  <div
                                      title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(parseInt(state.jillHighLevelStatses.highLevelStatses
                .highLevelStats.actualCallDuration))}"
                                      class="jo-jill--stats-item"
                                  >
                                      ${(0, utilities_1.millisecondsToMinutes)(parseInt(state.jillHighLevelStatses.highLevelStatses
                .highLevelStats.actualCallDuration))}
                                  </div>
                                  <div
                                      title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(parseInt(state.jillHighLevelStatses.highLevelStatses
                .highLevelStats.billableCallDuration))}"
                                      class="jo-jill--stats-item"
                                  >
                                      ${(0, utilities_1.millisecondsToMinutes)(parseInt(state.jillHighLevelStatses.highLevelStatses
                .highLevelStats.billableCallDuration))}
                                  </div>
                                  <div class="jo-jill--stats-item">
                                      ${state.jillHighLevelStatses.highLevelStatses.highLevelStats.averageEfficiencyPerDay.toFixed(2)}%
                                  </div>
                                  <div
                                      title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(state.jillHighLevelStatses.highLevelStatses.highLevelStats
                .averageBillableCallDuration)}"
                                      class="jo-jill--stats-item"
                                  >
                                      ${(0, utilities_1.millisecondsToMinutes)(state.jillHighLevelStatses.highLevelStatses.highLevelStats
                .averageBillableCallDuration)}
                                  </div>
                                  <div
                                      title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(state.jillHighLevelStatses.highLevelStatses.highLevelStats
                .averageActualCallDuration)}"
                                      class="jo-jill--stats-item"
                                  >
                                      ${(0, utilities_1.millisecondsToMinutes)(state.jillHighLevelStatses.highLevelStatses.highLevelStats
                .averageActualCallDuration)}
                                  </div>
                                  <div
                                      title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(state.jillHighLevelStatses.highLevelStatses.highLevelStats
                .averageTimeBetweenCalls)}"
                                      class="jo-jill--stats-item"
                                  >
                                      ${(0, utilities_1.millisecondsToMinutes)(state.jillHighLevelStatses.highLevelStatses.highLevelStats
                .averageTimeBetweenCalls)}
                                  </div>
                                  <div
                                      title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(state.jillHighLevelStatses.highLevelStatses.highLevelStats
                .averageActionItemTime)}"
                                      class="jo-jill--stats-item"
                                  >
                                      ${(0, utilities_1.millisecondsToMinutes)(state.jillHighLevelStatses.highLevelStatses.highLevelStats
                .averageActionItemTime)}
                                  </div>
                              </div>

                              ${state.jillHighLevelStatses.highLevelStatses.highLevelStatsForMonths.map((highLevelStatsForMonth) => {
                return (0, element_vir_1.html) `
                                          <div class="jo-jill--row">
                                              <div
                                                  class="jo-jill--stats-item jo-jill--bold jo-jill--pointer"
                                                  @click=${() => this.toggleHighLevelStatsForMonthCollapsed(highLevelStatsForMonth)}
                                              >
                                                  ${new Date(highLevelStatsForMonth.dateInMonth).toLocaleString('en-us', {
                    month: 'long',
                    year: 'numeric',
                })}
                                              </div>
                                              <div class="jo-jill--stats-item">
                                                  ${highLevelStatsForMonth.highLevelStats.numCalls}
                                              </div>
                                              <div
                                                  title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(parseInt(highLevelStatsForMonth.highLevelStats
                    .actualCallDuration))}"
                                                  class="jo-jill--stats-item"
                                              >
                                                  ${(0, utilities_1.millisecondsToMinutes)(parseInt(highLevelStatsForMonth.highLevelStats
                    .actualCallDuration))}
                                              </div>
                                              <div
                                                  title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(parseInt(highLevelStatsForMonth.highLevelStats
                    .billableCallDuration))}"
                                                  class="jo-jill--stats-item"
                                              >
                                                  ${(0, utilities_1.millisecondsToMinutes)(parseInt(highLevelStatsForMonth.highLevelStats
                    .billableCallDuration))}
                                              </div>
                                              <div class="jo-jill--stats-item">
                                                  ${highLevelStatsForMonth.highLevelStats.averageEfficiencyPerDay.toFixed(2)}%
                                              </div>
                                              <div
                                                  title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(highLevelStatsForMonth.highLevelStats
                    .averageBillableCallDuration)}"
                                                  class="jo-jill--stats-item"
                                              >
                                                  ${(0, utilities_1.millisecondsToMinutes)(highLevelStatsForMonth.highLevelStats
                    .averageBillableCallDuration)}
                                              </div>
                                              <div
                                                  title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(highLevelStatsForMonth.highLevelStats
                    .averageActualCallDuration)}"
                                                  class="jo-jill--stats-item"
                                              >
                                                  ${(0, utilities_1.millisecondsToMinutes)(highLevelStatsForMonth.highLevelStats
                    .averageActualCallDuration)}
                                              </div>
                                              <div
                                                  title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(highLevelStatsForMonth.highLevelStats
                    .averageTimeBetweenCalls)}"
                                                  class="jo-jill--stats-item"
                                              >
                                                  ${(0, utilities_1.millisecondsToMinutes)(highLevelStatsForMonth.highLevelStats
                    .averageTimeBetweenCalls)}
                                              </div>
                                              <div
                                                  title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(highLevelStatsForMonth.highLevelStats
                    .averageActionItemTime)}"
                                                  class="jo-jill--stats-item"
                                              >
                                                  ${(0, utilities_1.millisecondsToMinutes)(highLevelStatsForMonth.highLevelStats
                    .averageActionItemTime)}
                                              </div>
                                          </div>
                                          ${(() => {
                    const highLevelStatsForMonthCollapsed = state.highLevelStatsForMonthsCollapsed.find(highLevelStatsForMonthCollapsed => {
                        return (new Date(highLevelStatsForMonthCollapsed.highLevelStatsForMonth.dateInMonth).getTime() ===
                            new Date(highLevelStatsForMonth.dateInMonth).getTime());
                    });
                    if (highLevelStatsForMonthCollapsed?.collapsed ===
                        true) {
                        return '';
                    }
                    return highLevelStatsForMonth.highLevelStatsForDays.map((highLevelStatsForDay) => {
                        return (0, element_vir_1.html) `
                                                          <div class="jo-jill--row">
                                                              <div class="jo-jill--stats-item">
                                                                  ${new Date(highLevelStatsForDay.dateInDay).toLocaleDateString()}
                                                              </div>
                                                              <div class="jo-jill--stats-item">
                                                                  ${highLevelStatsForDay
                            .highLevelStats.numCalls}
                                                              </div>
                                                              <div
                                                                  title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(parseInt(highLevelStatsForDay
                            .highLevelStats
                            .actualCallDuration))}"
                                                                  class="jo-jill--stats-item"
                                                              >
                                                                  ${(0, utilities_1.millisecondsToMinutes)(parseInt(highLevelStatsForDay
                            .highLevelStats
                            .actualCallDuration))}
                                                              </div>
                                                              <div
                                                                  title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(parseInt(highLevelStatsForDay
                            .highLevelStats
                            .billableCallDuration))}"
                                                                  class="jo-jill--stats-item"
                                                              >
                                                                  ${(0, utilities_1.millisecondsToMinutes)(parseInt(highLevelStatsForDay
                            .highLevelStats
                            .billableCallDuration))}
                                                              </div>
                                                              <div class="jo-jill--stats-item">
                                                                  ${highLevelStatsForDay.highLevelStats.averageEfficiencyPerDay.toFixed(2)}%
                                                              </div>
                                                              <div
                                                                  title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(highLevelStatsForDay
                            .highLevelStats
                            .averageBillableCallDuration)}"
                                                                  class="jo-jill--stats-item"
                                                              >
                                                                  ${(0, utilities_1.millisecondsToMinutes)(highLevelStatsForDay
                            .highLevelStats
                            .averageBillableCallDuration)}
                                                              </div>
                                                              <div
                                                                  title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(highLevelStatsForDay
                            .highLevelStats
                            .averageActualCallDuration)}"
                                                                  class="jo-jill--stats-item"
                                                              >
                                                                  ${(0, utilities_1.millisecondsToMinutes)(highLevelStatsForDay
                            .highLevelStats
                            .averageActualCallDuration)}
                                                              </div>
                                                              <div
                                                                  title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(highLevelStatsForDay
                            .highLevelStats
                            .averageTimeBetweenCalls)}"
                                                                  class="jo-jill--stats-item"
                                                              >
                                                                  ${(0, utilities_1.millisecondsToMinutes)(highLevelStatsForDay
                            .highLevelStats
                            .averageTimeBetweenCalls)}
                                                              </div>
                                                              <div
                                                                  title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(highLevelStatsForDay
                            .highLevelStats
                            .averageActionItemTime)}"
                                                                  class="jo-jill--stats-item"
                                                              >
                                                                  ${(0, utilities_1.millisecondsToMinutes)(highLevelStatsForDay
                            .highLevelStats
                            .averageActionItemTime)}
                                                              </div>
                                                          </div>
                                                      `;
                    });
                })()}
                                      `;
            })}
                          </div>
                      `}
                ${state.showCalls === true
            ? (0, element_vir_1.html) `
                          <hr />
                          <br />
                          <h2>Activity</h2>

                          <div>
                              <button @click=${() => this.fetchAndSetInfoForActivityLog()}>
                                  Refresh Activity Log
                              </button>
                          </div>
                          <br />
                          <br />

                          <div class="jo-jill--dates-container">
                              <div>
                                  <div>Start date</div>
                                  <div>
                                      <input
                                          type="datetime-local"
                                          class="jo-global--input"
                                          .value=${(0, format_date_for_datetime_local_input_1.formatDateForDatetimeLocalInput)(state.activityLogStartDate)}
                                          @change=${(e) => this.activityLogStartDateInputChanged(e)}
                                      />
                                  </div>
                                  <br />
                                  <div>End date</div>
                                  <div>
                                      <input
                                          type="datetime-local"
                                          class="jo-global--input"
                                          .value=${(0, format_date_for_datetime_local_input_1.formatDateForDatetimeLocalInput)(state.activityLogEndDate)}
                                          @change=${(e) => this.activityLogEndDateInputChanged(e)}
                                      />
                                  </div>
                              </div>

                              <div class="jo-jill--assign-missing-calls-button-container">
                                  <jo-button
                                      .type=${'ACTION_BUTTON_2'}
                                      .text=${state.assigningMissingUrlsToCalls === true
                ? 'Assigning Missing Recordings...'
                : 'Assign Missing Recordings'}
                                      .disabled=${state.assigningMissingUrlsToCalls === true}
                                      @joButtonClick=${() => {
                this.handleAssignMissingUrlsToCallsForJill();
            }}
                                  ></jo-button>
                              </div>
                          </div>

                          <br />

                          <div ?hidden=${!state.loadingCalls}>Loading...</div>

                          <section class="jo-jill--total-stats-container">
                              <div>
                                  ${this.createCallStatsItems()}
                                  ${(0, personal_admin_1.isPersonalAdmin)(state.jill)
                ? (0, element_vir_1.html) `
                                            <div>
                                                ${(0, helper_utils_2.makeTotalAdminTimeForAllCompaniesHTML)(state.callsAndStatusEvents)}
                                            </div>
                                        `
                : ''}
                                  ${numTimesWentToLunchOutsideOfScheduledTime > 0
                ? (0, element_vir_1.html) `
                                            <div>
                                                # of times went to lunch outside of scheduled time:
                                                ${numTimesWentToLunchOutsideOfScheduledTime}
                                            </div>
                                        `
                : ''}
                              </div>
                              <div>
                                  <div class="jo-jill-stats">
                                      Total Chats:
                                      <span class="jo-jill--underline">
                                          ${(0, calculate_total_chats_completed_1.calculateTotalChatsCompleted)(state.callsAndStatusEvents)}
                                      </span>
                                  </div>
                                  <div class="jo-jill-stats">
                                      # Flagged Action Items:
                                      <span class="jo-jill--underline">
                                          ${state.actionItemFlags.length}
                                      </span>
                                  </div>
                                  <div class="jo-jill-stats">
                                      # Outbound Calls (not callbacks):
                                      <span class="jo-jill--underline">
                                          ${state.calls.filter(was_call_an_outbound_call_1.wasCallAnOutboundCall).length}
                                      </span>
                                  </div>

                                  ${(0, element_vir_1.renderIf)((0, auth_rules_1.isJillAtOrAboveAuthRole)(state.jill, 'JILL_OUTBOUND'), (0, element_vir_1.html) `
                                          <div class="jo-jill-stats">
                                              # Action Items Manually Resolved:
                                              <span class="jo-jill--underline">
                                                  ${(0, extract_status_events_of_type_1.extractUserStatusEventsOfType)(state.statusEvents, 'RESOLVED_ACTION_ITEM').length}
                                              </span>
                                          </div>
                                      `)}
                              </div>
                          </section>

                          <jo-table
                              .tableName=${''}
                              .showSearchBar=${false}
                              .columns=${(0, helper_utils_2.getColumns)(state)}
                              .items=${(0, helper_utils_2.filterActivityLogItems)(state.callsAndStatusEvents)}
                              .totalItems=${this.store.callsAndStatusEvents.length}
                              .showPaginationInfo=${false}
                          ></jo-table>

                          <br />
                          <br />
                          <div>
                              <button @click=${() => this.fetchAndSetInfoForActivityLog()}>
                                  Refresh Activity Log
                              </button>
                          </div>
                      `
            : ''}
                ${state.showFlagsTable
            ? (0, element_vir_1.html) `
                          <section class="jo-jill--flag-table-container">
                              <div class="jo-global--card">
                                  <jo-action-item-flags-table
                                      .props=${{
                authenticatedUser: state.authenticatedUser,
                hideMistakenlyFlaggedItems: true,
                jillId: state.jillId,
                csvTitle: `Flagged Action Items -- ${(0, helper_utils_1.fullName)(state.jill)}`,
            }}
                                  ></jo-action-item-flags-table>
                              </div>
                          </section>
                      `
            : ''}
                ${(0, element_vir_1.renderIf)(state.showAccountHelpTable === true, (0, element_vir_1.html) `
                        <section class="jo-jill--flag-table-container">
                            <div class="jo-global--card">
                                <${jo_account_help_table_element_1.JoAccountHelpTable}
                                    ${(0, element_vir_1.assign)(jo_account_help_table_element_1.JoAccountHelpTable, {
            userId: state.jillId,
        })}
                                ></${jo_account_help_table_element_1.JoAccountHelpTable}>
                            </div>
                        </section>
                    `)}
            </div>
        `;
    }
}
exports.JOJill = JOJill;
(0, define_custom_element_1.defineCustomElement)('jo-jill', JOJill);
