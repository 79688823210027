"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStartDateForScheduleItem = void 0;
const day_of_week_to_number_1 = require("./day-of-week-to-number");
function getStartDateForScheduleItem(date, scheduleItem) {
    const startDate = new Date(date);
    const startDay = (0, day_of_week_to_number_1.dayOfWeekToNumber)(scheduleItem.start_day_of_week);
    while (startDate.getDay() < startDay) {
        startDate.setDate(startDate.getDate() + 1);
    }
    while (startDate.getDay() > startDay) {
        startDate.setDate(startDate.getDate() - 1);
    }
    startDate.setHours(scheduleItem.start_hour);
    startDate.setMinutes(scheduleItem.start_minute);
    startDate.setSeconds(0);
    return startDate;
}
exports.getStartDateForScheduleItem = getStartDateForScheduleItem;
