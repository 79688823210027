"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPriorityLevelKeyValueAlreadyUsed = void 0;
function isPriorityLevelKeyValueAlreadyUsed(value, key, inputPriorityLevel, queuePriorityLevels) {
    const result = queuePriorityLevels.some(priorityLevel => {
        const levelValue = priorityLevel[key];
        if (inputPriorityLevel.id === priorityLevel.id)
            return false;
        return levelValue === value;
    });
    return result;
}
exports.isPriorityLevelKeyValueAlreadyUsed = isPriorityLevelKeyValueAlreadyUsed;
