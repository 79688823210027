"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNumberOfGroupsInList = void 0;
function getNumberOfGroupsInList(items, identifierCallback) {
    let count = 0;
    let currentlyInSeriesOfGroup = false;
    for (let i = 0; i < items.length; i++) {
        if (identifierCallback(items, i)) {
            if (!currentlyInSeriesOfGroup) {
                count += 1;
                currentlyInSeriesOfGroup = true;
            }
        }
        else {
            currentlyInSeriesOfGroup = false;
        }
    }
    return count;
}
exports.getNumberOfGroupsInList = getNumberOfGroupsInList;
