"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.statsForHourTemplate = exports.calculateStats = exports.getIncomingCallTypesAsArray = exports.getColumns = exports.getAfterHoursIntervalArray = exports.getRegularBusinessHoursIntervalArray = exports.getTotalHoursIntervalArray = exports.totalStatsForBusinessHoursIntervalTemplate = void 0;
const lit_html_1 = require("lit-html");
const utilities_1 = require("../../../services/utilities");
const jo_incoming_queue_report_1 = require("./jo-incoming-queue-report");
function totalStatsForBusinessHoursIntervalTemplate(statsForCurrentInterval, hoursInterval) {
    if (statsForCurrentInterval === null) {
        return (0, lit_html_1.html) ``;
    }
    let totals = {
        numItems: 0,
        minutes: 0,
        billableMinutes: 0,
        numItemsUnder20Seconds: 0,
        numItemsBetween20SecondsAnd40Seconds: 0,
        numItemsOver40Seconds: 0,
        averageWaitTime: 0,
    };
    hoursInterval.forEach(hour => {
        totals.numItems += statsForCurrentInterval[hour].numItems;
        totals.minutes += statsForCurrentInterval[hour].minutes;
        totals.billableMinutes += statsForCurrentInterval[hour].billableMinutes;
        totals.numItemsUnder20Seconds += statsForCurrentInterval[hour].numItemsUnder20Seconds;
        totals.numItemsBetween20SecondsAnd40Seconds +=
            statsForCurrentInterval[hour].numItemsBetween20SecondsAnd40Seconds;
        totals.numItemsOver40Seconds += statsForCurrentInterval[hour].numItemsOver40Seconds;
        totals.averageWaitTime += !isNaN(statsForCurrentInterval[hour].averageWaitTime)
            ? statsForCurrentInterval[hour].averageWaitTime
            : 0;
    });
    return (0, lit_html_1.html) `
        <div>${totals.numItems}</div>
        <div>${totals.minutes}</div>
        <div>${totals.billableMinutes}</div>
        <div>${totals.numItemsUnder20Seconds}</div>
        <div>${totals.numItemsBetween20SecondsAnd40Seconds}</div>
        <div>${totals.numItemsOver40Seconds}</div>
        <div>${Math.floor(totals.averageWaitTime / hoursInterval.length)}</div>
    `;
}
exports.totalStatsForBusinessHoursIntervalTemplate = totalStatsForBusinessHoursIntervalTemplate;
function getTotalHoursIntervalArray() {
    return [
        '12-am-to-1-am',
        '1-am-to-2-am',
        '2-am-to-3-am',
        '3-am-to-4-am',
        '4-am-to-5-am',
        '5-am-to-6-am',
        '6-am-to-7-am',
        '7-am-to-8-am',
        '8-am-to-9-am',
        '9-am-to-10-am',
        '10-am-to-11-am',
        '11-am-to-12-pm',
        '12-pm-to-1-pm',
        '1-pm-to-2-pm',
        '2-pm-to-3-pm',
        '3-pm-to-4-pm',
        '4-pm-to-5-pm',
        '5-pm-to-6-pm',
        '6-pm-to-7-pm',
        '7-pm-to-8-pm',
        '8-pm-to-9-pm',
        '9-pm-to-10-pm',
        '10-pm-to-11-pm',
        '11-pm-to-12-am',
    ];
}
exports.getTotalHoursIntervalArray = getTotalHoursIntervalArray;
function getRegularBusinessHoursIntervalArray() {
    return [
        '6-am-to-7-am',
        '7-am-to-8-am',
        '8-am-to-9-am',
        '9-am-to-10-am',
        '10-am-to-11-am',
        '11-am-to-12-pm',
        '12-pm-to-1-pm',
        '1-pm-to-2-pm',
        '2-pm-to-3-pm',
        '3-pm-to-4-pm',
        '4-pm-to-5-pm',
        '5-pm-to-6-pm',
    ];
}
exports.getRegularBusinessHoursIntervalArray = getRegularBusinessHoursIntervalArray;
function getAfterHoursIntervalArray() {
    return [
        '6-pm-to-7-pm',
        '7-pm-to-8-pm',
        '8-pm-to-9-pm',
        '9-pm-to-10-pm',
        '10-pm-to-11-pm',
        '11-pm-to-12-am',
        '12-am-to-1-am',
        '1-am-to-2-am',
        '2-am-to-3-am',
        '3-am-to-4-am',
        '4-am-to-5-am',
        '5-am-to-6-am',
    ];
}
exports.getAfterHoursIntervalArray = getAfterHoursIntervalArray;
function getColumns(state) {
    const columns = [
        {
            title: 'Name',
            getCellData: (incomingQueueItem) => incomingQueueItem.pod.name,
            width: 0.5,
        },
        {
            title: 'Company name',
            getCellData: (incomingQueueItem) => incomingQueueItem.call.company?.name ?? '',
            width: 0.5,
        },
        {
            title: 'Callback',
            getCellData: (incomingQueueItem) => incomingQueueItem.incoming_call_type === 'CALLBACK' ? 'Yes' : 'No',
            width: 0.3,
        },
        {
            title: 'From',
            getCellData: (incomingQueueItem) => (0, utilities_1.formatE164PhoneNumberForDisplay)(incomingQueueItem.call.origin_phone),
            width: 0.2,
        },
        {
            title: 'To',
            getCellData: (incomingQueueItem) => (0, utilities_1.formatE164PhoneNumberForDisplay)(incomingQueueItem.call.destination_phone),
            width: 0.5,
        },
        {
            title: 'Started',
            getCellData: (incomingQueueItem) => new Date(incomingQueueItem.created_at).toLocaleString(),
            width: 0.5,
        },
        {
            title: 'Wait time so far',
            getCellData: (incomingQueueItem) => (0, utilities_1.millisecondsToHoursMinutesSeconds)(new Date().getTime() - new Date(incomingQueueItem.created_at).getTime()),
            width: 0.5,
        },
    ];
    return columns;
}
exports.getColumns = getColumns;
function getIncomingCallTypesAsArray(incomingCallType) {
    if (incomingCallType === 'NOT_SET')
        return [
            'USER',
            'CALLBACK',
        ];
    return [incomingCallType];
}
exports.getIncomingCallTypesAsArray = getIncomingCallTypesAsArray;
function calculateStats(incomingQueueItems) {
    // TODO make sure 0 indexing isn't messing up our hours
    return {
        '12-am-to-1-am': calculateStatsForHour(24, incomingQueueItems),
        '1-am-to-2-am': calculateStatsForHour(1, incomingQueueItems),
        '2-am-to-3-am': calculateStatsForHour(2, incomingQueueItems),
        '3-am-to-4-am': calculateStatsForHour(3, incomingQueueItems),
        '4-am-to-5-am': calculateStatsForHour(4, incomingQueueItems),
        '5-am-to-6-am': calculateStatsForHour(5, incomingQueueItems),
        '6-am-to-7-am': calculateStatsForHour(6, incomingQueueItems),
        '7-am-to-8-am': calculateStatsForHour(7, incomingQueueItems),
        '8-am-to-9-am': calculateStatsForHour(8, incomingQueueItems),
        '9-am-to-10-am': calculateStatsForHour(9, incomingQueueItems),
        '10-am-to-11-am': calculateStatsForHour(10, incomingQueueItems),
        '11-am-to-12-pm': calculateStatsForHour(11, incomingQueueItems),
        '12-pm-to-1-pm': calculateStatsForHour(12, incomingQueueItems),
        '1-pm-to-2-pm': calculateStatsForHour(13, incomingQueueItems),
        '2-pm-to-3-pm': calculateStatsForHour(14, incomingQueueItems),
        '3-pm-to-4-pm': calculateStatsForHour(15, incomingQueueItems),
        '4-pm-to-5-pm': calculateStatsForHour(16, incomingQueueItems),
        '5-pm-to-6-pm': calculateStatsForHour(17, incomingQueueItems),
        '6-pm-to-7-pm': calculateStatsForHour(18, incomingQueueItems),
        '7-pm-to-8-pm': calculateStatsForHour(19, incomingQueueItems),
        '8-pm-to-9-pm': calculateStatsForHour(20, incomingQueueItems),
        '9-pm-to-10-pm': calculateStatsForHour(21, incomingQueueItems),
        '10-pm-to-11-pm': calculateStatsForHour(22, incomingQueueItems),
        '11-pm-to-12-am': calculateStatsForHour(23, incomingQueueItems),
    };
}
exports.calculateStats = calculateStats;
function calculateStatsForHour(hour, incomingQueueItems) {
    // TODO getHours returns the hours in the timezone of the client...so the reporting will be slightly off if you are looking at it from another timezone
    // TODO Do we want to hardcode this to the Mountain timezones? Daylight time and Standard time?
    const incomingQueueItemsForHour = incomingQueueItems.filter((queueItem) => {
        return (new Date(queueItem.created_at).getUTCHours() ===
            (0, jo_incoming_queue_report_1.convertUTCDateToMountainTime)(new Date((0, jo_incoming_queue_report_1.nowInMountainTime)().getFullYear(), (0, jo_incoming_queue_report_1.nowInMountainTime)().getMonth(), (0, jo_incoming_queue_report_1.nowInMountainTime)().getDate(), hour)).getUTCHours());
    });
    const numItems = incomingQueueItemsForHour.length;
    const milliseconds = incomingQueueItemsForHour.reduce((result, incomingQueueItem) => {
        if (incomingQueueItem.call === null ||
            incomingQueueItem.call === undefined ||
            incomingQueueItem.call.duration === null ||
            incomingQueueItem.call.duration === undefined) {
            return result;
        }
        return result + incomingQueueItem.call.duration;
    }, 0);
    const minutes = Math.floor(milliseconds / 60000);
    const billableMilliseconds = incomingQueueItemsForHour.reduce((result, incomingQueueItem) => {
        if (incomingQueueItem.call === null ||
            incomingQueueItem.call === undefined ||
            incomingQueueItem.call.billable_duration === null ||
            incomingQueueItem.call.billable_duration === undefined) {
            return result;
        }
        return result + incomingQueueItem.call.billable_duration;
    }, 0);
    const billableMinutes = Math.floor(billableMilliseconds / 60000);
    const incomingQueueItemsUnder20Seconds = incomingQueueItemsForHour.filter((queueItem) => {
        if (queueItem.handled_at === null || queueItem.handled_at === undefined) {
            const message = `handled_at is not defined on queueItem ${queueItem.id}`;
            alert(message);
            throw new Error(message);
        }
        const timeDifference = new Date(queueItem.handled_at).getTime() - new Date(queueItem.created_at).getTime();
        return timeDifference < 20000;
    });
    const numItemsUnder20Seconds = incomingQueueItemsUnder20Seconds.length;
    const incomingQueueItemsBetween20SecondsAnd40Seconds = incomingQueueItemsForHour.filter((queueItem) => {
        if (queueItem.handled_at === null || queueItem.handled_at === undefined) {
            const message = `handled_at is not defined on queueItem ${queueItem.id}`;
            alert(message);
            throw new Error(message);
        }
        const timeDifference = new Date(queueItem.handled_at).getTime() - new Date(queueItem.created_at).getTime();
        return timeDifference >= 20000 && timeDifference < 40000;
    });
    const numItemsBetween20SecondsAnd40Seconds = incomingQueueItemsBetween20SecondsAnd40Seconds.length;
    const incomingQueueItemsOver40Seconds = incomingQueueItemsForHour.filter((queueItem) => {
        if (queueItem.handled_at === null || queueItem.handled_at === undefined) {
            const message = `handled_at is not defined on queueItem ${queueItem.id}`;
            alert(message);
            throw new Error(message);
        }
        const timeDifference = new Date(queueItem.handled_at).getTime() - new Date(queueItem.created_at).getTime();
        return timeDifference >= 40000;
    });
    const numItemsOver40Seconds = incomingQueueItemsOver40Seconds.length;
    const totalWaitTime = incomingQueueItemsForHour.reduce((result, queueItem) => {
        if (queueItem.handled_at === null || queueItem.handled_at === undefined) {
            const message = `handled_at is not defined on queueItem ${queueItem.id}`;
            alert(message);
            throw new Error(message);
        }
        const timeDifference = new Date(queueItem.handled_at).getTime() - new Date(queueItem.created_at).getTime();
        return result + timeDifference;
    }, 0);
    const averageWaitTimeInMilliseconds = Math.floor(totalWaitTime / numItems);
    const averageWaitTime = Math.floor(averageWaitTimeInMilliseconds / 1000);
    return {
        numItems,
        minutes,
        billableMinutes,
        numItemsUnder20Seconds,
        numItemsBetween20SecondsAnd40Seconds,
        numItemsOver40Seconds,
        averageWaitTime,
    };
}
function statsForHourTemplate(statsForCurrentInterval, hourString) {
    if (statsForCurrentInterval === null) {
        return (0, lit_html_1.html) ``;
    }
    return (0, lit_html_1.html) `
        <div>${statsForCurrentInterval[hourString].numItems}</div>
        <div>${statsForCurrentInterval[hourString].minutes}</div>
        <div>${statsForCurrentInterval[hourString].billableMinutes}</div>
        <div>${statsForCurrentInterval[hourString].numItemsUnder20Seconds}</div>
        <div>${statsForCurrentInterval[hourString].numItemsBetween20SecondsAnd40Seconds}</div>
        <div>${statsForCurrentInterval[hourString].numItemsOver40Seconds}</div>
        <div>
            ${!isNaN(statsForCurrentInterval[hourString].averageWaitTime)
        ? statsForCurrentInterval[hourString].averageWaitTime
        : 0}
        </div>
    `;
}
exports.statsForHourTemplate = statsForHourTemplate;
