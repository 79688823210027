"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lineItemDescriptionsForbiddenFromBeingEdited = void 0;
exports.lineItemDescriptionsForbiddenFromBeingEdited = [
    'minutes package',
    'monthly fee',
    'usage',
    'base plan',
    'additional usage',
    'bank of minutes',
];
