"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../../services/utilities");
const auth_rules_1 = require("../../../../utilities/auth-rules");
const get_previous_date_1 = require("../../../../utilities/dates-and-times/get-previous-date");
const get_start_of_billing_cycle_1 = require("../../../../utilities/dates-and-times/get-start-of-billing-cycle");
const get_start_of_next_billing_cycle_1 = require("../../../../utilities/dates-and-times/get-start-of-next-billing-cycle");
const merge_weekly_line_items_on_invoice_1 = require("../../../../utilities/invoices/merge-weekly-line-items-on-invoice");
require("../../jo-button.element");
require("../../jo-input");
const constants_1 = require("./constants");
const helper_utils_1 = require("./helper-utils");
require("./jo-invoice-line-item");
const queries_and_mutations_1 = require("./queries-and-mutations");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
const InitialState = {
    addingNewLineItem: false,
    authenticatedUser: 'NOT_SET',
    billingCycleType: 'MONTHLY',
    companyId: 'NOT_SET',
    endOfBillingCycle: new Date(),
    invoice: 'NOT_SET',
    invoiceLineItems: [],
    isMobileDevice: false,
    loading: false,
    newInvoiceLineItem: 'NOT_SET',
    selectedDate: new Date(),
    selectedStatus: 'NOT_SET',
    startOfBillingCycle: new Date(),
};
class JOInvoice extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        this.handleFetchAndSetInvoice();
    }
    async handleFetchAndSetInvoice() {
        if (this.store.companyId === 'NOT_SET') {
            return;
        }
        this.store.loading = true;
        await this.fetchAndSetInvoice();
        this.setBillingcycleInfo();
        this.store.loading = false;
    }
    async fetchAndSetInvoice() {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        const invoiceResult = await (0, queries_and_mutations_1.fetchInvoice)(this.store.companyId, this.store.selectedDate);
        (0, utilities_1.assertSucceeded)(invoiceResult, utilities_1.handleError);
        this.setInvoice(invoiceResult.value);
    }
    setInvoice(invoice) {
        if (invoice === 'NOT_FOUND')
            this.store.invoice = 'NOT_FOUND';
        else {
            this.store.invoice = (0, merge_weekly_line_items_on_invoice_1.mergeWeeklyLineItemsOnInvoice)(invoice);
            this.setSelectedStatusIfNecessary(this.store.invoice);
        }
    }
    setSelectedStatusIfNecessary(invoice) {
        if (invoice.manually_assigned_status)
            this.store.selectedStatus = invoice.manually_assigned_status;
        else
            this.store.selectedStatus = 'NOT_SET';
    }
    setBillingcycleInfo() {
        if (this.store.invoice !== 'NOT_SET' && this.store.invoice !== 'NOT_FOUND') {
            this.store.startOfBillingCycle = new Date(this.store.invoice.start_date);
            this.store.endOfBillingCycle = new Date(this.store.invoice.end_date);
            this.store.billingCycleType = this.store.invoice.billing_cycle_type;
        }
        else {
            this.store.startOfBillingCycle = (0, get_start_of_billing_cycle_1.getStartOfBillingCycleInMountainTime)(this.store.selectedDate, this.store.billingCycleType);
            this.store.endOfBillingCycle = (0, get_start_of_next_billing_cycle_1.getStartOfNextBillingCycleInMountainTime)(this.store.selectedDate, this.store.billingCycleType);
        }
    }
    async handleUpdateOrCreateInvoiceLineItem(e) {
        const { invoiceLineItemId, description, amountInCents } = e.detail;
        if (description === '') {
            (0, utilities_1.joAlert)('Notice', 'Description cannot be blank');
            return;
        }
        if (invoiceLineItemId === -1) {
            await this.handleCreateInvoiceLineItem(description, amountInCents);
        }
        else {
            await this.handleUpdateInvoiceLineItem(invoiceLineItemId, description, amountInCents);
        }
        await this.handleFetchAndSetInvoice();
        (0, utilities_1.joAlert)('Success!', 'Invoice Updated!');
    }
    async handleCreateInvoiceLineItem(description, amountInCent) {
        (0, utilities_1.assertIsSet)(this.store.invoice, utilities_1.handleError, 'this.store.invoice');
        const createInvoiceLineItemResult = await (0, queries_and_mutations_1.createInvoiceLineItem)(this.store.invoice.id, description, amountInCent);
        (0, utilities_1.assertSucceeded)(createInvoiceLineItemResult, utilities_1.handleError);
        this.store.newInvoiceLineItem = 'NOT_SET';
        this.store.addingNewLineItem = false;
    }
    async handleUpdateInvoiceLineItem(invoiceLineItemId, description, amountInCent) {
        const createInvoiceLineItemResult = await (0, queries_and_mutations_1.updateInvoiceLineItem)(invoiceLineItemId, description, amountInCent);
        (0, utilities_1.assertSucceeded)(createInvoiceLineItemResult, utilities_1.handleError);
    }
    async handleDeleteInvoiceLineItem(invoiceLineItemId) {
        const shouldContinue = await (0, utilities_1.joConfirm)('HOLD UP RIGHT THERE', 'Are you SURE you want to delete this invoice line item?');
        if (shouldContinue === false) {
            return;
        }
        const deleteInvoiceLineItemResult = await (0, queries_and_mutations_1.deleteInvoiceLineItem)(invoiceLineItemId);
        (0, utilities_1.assertSucceeded)(deleteInvoiceLineItemResult, utilities_1.handleError);
        await this.handleFetchAndSetInvoice();
        (0, utilities_1.joAlert)('Success!', 'Line item was successfully deleted');
    }
    handleAddNewLineItemClicked() {
        this.store.newInvoiceLineItem = (0, helper_utils_1.createEmptyInvoiceLineItem)();
        this.store.addingNewLineItem = true;
    }
    async saveInvoiceStatus(state) {
        if (state.selectedStatus !== 'NOT_SET' &&
            state.invoice !== 'NOT_FOUND' &&
            state.invoice !== 'NOT_SET') {
            const result = await (0, queries_and_mutations_1.updateManuallyAssignedInvoiceStatus)(state.selectedStatus, state.invoice.id);
            if (result.succeeded) {
                (0, utilities_1.joAlert)('Success!', 'Invoice status was successfully updated');
                this.handleFetchAndSetInvoice();
            }
        }
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .jo-invoice--main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    background-color: white;
                    border-radius: var(--jo-wide-border-radius-1);
                    box-shadow: var(--jo-card-shadow-1);
                    padding: 1rem;
                }

                .jo-invoice--title {
                    color: var(--jo-text-default);
                }

                .jo-invoice--info-header {
                    padding-bottom: 1.5rem;
                    font-weight: bold;
                    text-decoration: underline;
                }

                .jo-invoice--info-column {
                    flex: ${state.isMobileDevice ? `0 0 33%` : `0 0 250px`};
                    font-size: ${state.isMobileDevice ? `0.75rem` : `1rem`};
                }
                .jo-invoice--info-column > div {
                    text-align: ${state.isMobileDevice ? `center` : `left`};
                }

                .jo-invoice--line-item-container-parent {
                    display: flex;
                    align-items: center;
                    padding: 1rem 0rem;
                }

                .jo-invoice--line-item-container-parent:hover {
                    background-color: var(--jo-primary-light);
                }

                .jo-invoice--invoice-not-found-container {
                    padding: 2rem 0rem;
                }

                .jo-invoice--save-status-button {
                    margin-top: 1rem;
                }
            </style>

            <main class="jo-invoice--main-container">
                <div class="flex justify-between" style="display: flex;">
                    <div ?hidden=${state.isMobileDevice} class="jo-invoice--title">
                        <h2>INVOICE - ${(0, helper_utils_1.getInvoiceStatusString)(state.invoice)}</h2>
                    </div>
                    ${state.loading
            ? (0, lit_html_1.html) `
                              <div style="padding-left: 3rem;">
                                  <jo-loading-text></jo-loading-text>
                              </div>
                          `
            : ''}
                </div>

                <section style="padding-top: 1rem;">
                    <div style="font-weight: bold">
                        Billing Cycle: ${state.startOfBillingCycle.toLocaleDateString()} -
                        ${(0, get_previous_date_1.getPreviousDate)(state.endOfBillingCycle).toLocaleDateString()}
                    </div>

                    <br />

                    <input
                        type="date"
                        class="jo-global--date-input"
                        .value=${formatDateForDateInput(state.invoice === 'NOT_FOUND' || state.invoice === 'NOT_SET'
            ? (0, get_start_of_billing_cycle_1.getStartOfBillingCycleInMountainTime)(state.selectedDate, state.billingCycleType)
            : new Date(state.invoice.start_date))}
                        @change=${(e) => {
            this.store.selectedDate = new Date(e.target.value.replace(/-/g, '/'));
            this.handleFetchAndSetInvoice();
        }}
                    />
                </section>

                <div
                    ?hidden=${state.invoice !== 'NOT_FOUND'}
                    class="jo-invoice--invoice-not-found-container"
                >
                    Invoice Not Found
                </div>

                <section
                    ?hidden=${state.invoice === 'NOT_FOUND'}
                    style="display: flex; padding-top: 2rem;"
                >
                    <div class="jo-invoice--info-column">
                        <div class="jo-invoice--info-header">Amount Due</div>
                        <div>${(0, helper_utils_1.getAmountDueString)(state.invoice)}</div>
                    </div>

                    <div class="jo-invoice--info-column">
                        <div class="jo-invoice--info-header">Status</div>

                        ${(0, auth_rules_1.isCompanyUser)(this.store.authenticatedUser)
            ? (0, lit_html_1.html) `
                                  <div>${(0, helper_utils_1.getInvoiceStatusString)(state.invoice)}</div>
                              `
            : (0, lit_html_1.html) `
                                  <jo-input
                                      .type=${'SELECT'}
                                      .selectedValue=${this.store.selectedStatus}
                                      .defaultOption=${constants_1.defaultManualStatusOption}
                                      .disableDefaultOption=${true}
                                      .selectOptions=${constants_1.manualStatusOptions}
                                      @inputchange=${(e) => {
                this.store.selectedStatus = e.detail
                    .selectedValue;
            }}
                                  ></jo-input>

                                  <div class="jo-invoice--save-status-button">
                                      ${this.store.selectedStatus === 'NOT_SET'
                ? ``
                : (0, lit_html_1.html) `
                                                <jo-button
                                                    .type=${'ACTION_BUTTON_1'}
                                                    .text=${'Save'}
                                                    @joButtonClick=${() => {
                    this.saveInvoiceStatus(state);
                }}
                                                ></jo-button>
                                            `}
                                  </div>
                              `}
                    </div>
                </section>

                <section
                    class="pt-4 hover:bg-primary-light"
                    ?hidden=${state.invoice === 'NOT_FOUND'}
                    style="padding-top: 5rem;"
                >
                    <div style="display: flex;">
                        <div class="jo-invoice--info-column jo-invoice--info-header">
                            Description
                        </div>
                        <div class="jo-invoice--info-column jo-invoice--info-header">Amount</div>
                    </div>

                    ${state.invoice !== 'NOT_FOUND' && state.invoice !== 'NOT_SET'
            ? state.invoice.invoice_line_items.map(lineItem => (0, lit_html_1.html) `
                                  <div class="jo-invoice--line-item-container-parent">
                                      <jo-invoice-line-item
                                          .props=${{
                authenticatedUser: state.authenticatedUser,
                invoiceLineItem: lineItem,
                mode: 'READ',
            }}
                                          .isMobileDevice=${state.isMobileDevice}
                                          @invoiceLineItemSaveClicked=${(e) => {
                this.handleUpdateOrCreateInvoiceLineItem(e);
            }}
                                          @invoiceLineItemDeleteClicked=${(e) => {
                this.handleDeleteInvoiceLineItem(e.detail.invoiceLineItemId);
            }}
                                      ></jo-invoice-line-item>
                                  </div>
                              `)
            : ''}
                    ${state.addingNewLineItem === true && state.newInvoiceLineItem !== 'NOT_SET'
            ? (0, lit_html_1.html) `
                              <div class="jo-invoice--line-item-container-parent">
                                  <jo-invoice-line-item
                                      .props=${{
                authenticatedUser: state.authenticatedUser,
                invoiceLineItem: state.newInvoiceLineItem,
                mode: 'CREATE',
            }}
                                      .isMobileDevice=${state.isMobileDevice}
                                      @invoiceLineItemCancelClicked=${() => {
                this.store.addingNewLineItem = false;
                this.store.newInvoiceLineItem = 'NOT_SET';
            }}
                                      @invoiceLineItemSaveClicked=${(e) => {
                this.handleUpdateOrCreateInvoiceLineItem(e);
            }}
                                  ></jo-invoice-line-item>
                              </div>
                          `
            : ''}

                    <div
                        ?hidden=${!(0, utilities_1.isInAuthRole)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser)}
                        class="pt-8"
                    >
                        <jo-button
                            ?hidden=${state.addingNewLineItem === true ||
            state.invoice === 'NOT_SET' ||
            state.invoice === 'NOT_FOUND'}
                            .type=${'ICON'}
                            .text=${'add'}
                            @joButtonClick=${() => this.handleAddNewLineItemClicked()}
                        ></jo-button>
                    </div>
                </section>
            </main>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-invoice', JOInvoice);
function formatDateForDateInput(date) {
    const localeDateString = date.toLocaleDateString();
    const [month, day, year,] = localeDateString.split('/');
    if (month == null) {
        throw new Error('month is null');
    }
    if (day == null) {
        throw new Error('day is null');
    }
    return year + '-' + formatMonthOrDayNumber(month) + '-' + formatMonthOrDayNumber(day);
}
function formatMonthOrDayNumber(monthOrDayNumber) {
    return String(monthOrDayNumber).length === 1
        ? `0${monthOrDayNumber}`
        : String(monthOrDayNumber);
}
