"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateTotalChargedForInvoices = void 0;
const get_stripe_total_for_invoice_1 = require("./get-stripe-total-for-invoice");
function calculateTotalChargedForInvoices(invoices) {
    return invoices.reduce((total, invoice) => {
        return total + (0, get_stripe_total_for_invoice_1.getStripeTotalForInvoice)(invoice, 'totalCharged');
    }, 0);
}
exports.calculateTotalChargedForInvoices = calculateTotalChargedForInvoices;
