"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
require("./jo-billing-overview");
require("./jo-billing-minutes-package/jo-billing-minutes-package");
require("./jo-billing-payment-info/jo-billing-payment-info");
require("./jo-billing-invoice-history");
require("./jo-billing-call-log-summary/jo-billing-call-log-summary");
require("./jo-billing-mobile/jo-billing-mobile");
const graphql_1 = require("../../../services/graphql");
require("../jo-customer-update-payment-details/jo-customer-update-payment-details");
const error_handling_1 = require("../../../utilities/error-handling");
const css_utils_1 = require("../../../utilities/css-utils");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    companyId: 'NOT_SET',
    currentMinutesPackageForCompany: 'NOT_SET',
    isMobileDevice: false,
    loading: true,
    saving: false,
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-billing');
class JOBilling extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        if (this.store.companyId !== 'NOT_SET') {
            this.fetchAndSetCurrentMinutesPackageForCompany();
        }
    }
    async fetchAndSetCurrentMinutesPackageForCompany() {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        this.store.loading = true;
        const minutesPackageResult = await getCurrentMinutesPackageForCompany(this.store.companyId);
        (0, utilities_1.assertSucceeded)(minutesPackageResult, utilities_1.handleError);
        this.store.currentMinutesPackageForCompany =
            minutesPackageResult.value === 'NOT_FOUND' ? 'NOT_SET' : minutesPackageResult.value;
        this.store.loading = false;
    }
    render(state) {
        const isAllowedToUpdate = (0, utilities_1.isInAuthRole)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser);
        if (state.isMobileDevice) {
            return (0, lit_html_1.html) `
                <jo-billing-mobile
                    .props=${{
                authenticatedUser: state.authenticatedUser,
                companyId: state.companyId,
                currentMinutesPackageForCompany: state.currentMinutesPackageForCompany,
            }}
                    .isMobileDevice=${state.isMobileDevice}
                ></jo-billing-mobile>
            `;
        }
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-cssName('css')}" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

            <style>
                .${cssName('mainContainer')} {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    display: flex;
                }

                .${cssName('column1')} {
                    flex: 1;
                    padding-right: var(--padding-large);
                }

                .${cssName('column2')} {
                    flex: 1;
                    padding-left: var(--padding-large);
                }

                .${cssName('paddedItem')} {
                    padding-bottom: var(--padding-large);
                }

                @media screen and (max-width: 1600px) {
                    .${cssName('mainContainer')} {
                        flex-direction: column;
                    }

                    .${cssName('column1')},
                    .${cssName('column2')} {
                        padding: 0rem;
                    }
                }
            </style>

            <div class="${cssName('mainContainer')}">
                <div class="${cssName('column1')}">
                    <div class="${cssName('paddedItem')}">
                        <div class="jo-global--card">
                            <jo-billing-overview
                                .props=${{
            authenticatedUser: state.authenticatedUser,
            companyId: state.companyId,
            currentMinutesPackageForCompany: state.currentMinutesPackageForCompany,
        }}
                                .isMobileDevice=${state.isMobileDevice}
                            ></jo-billing-overview>
                        </div>
                    </div>

                    <div class="${cssName('paddedItem')}">
                        <div class="jo-global--card">
                            <jo-billing-minutes-package
                                .props=${{
            authenticatedUser: state.authenticatedUser,
            companyId: state.companyId,
            currentMinutesPackageForCompany: this.store.currentMinutesPackageForCompany,
        }}
                                .isMobileDevice=${state.isMobileDevice}
                                @currentminutespackageupdated=${() => this.fetchAndSetCurrentMinutesPackageForCompany()}
                            ></jo-billing-minutes-package>
                        </div>
                    </div>

                    <div class="${cssName('paddedItem')}">
                        <div ?hidden=${isAllowedToUpdate === false} class="jo-global--card">
                            <jo-billing-payment-info
                                .props=${{
            authenticatedUser: state.authenticatedUser,
            companyId: state.companyId,
        }}
                                .isMobileDevice=${state.isMobileDevice}
                            ></jo-billing-payment-info>
                        </div>
                    </div>

                    <div class="${cssName('paddedItem')}">
                        <div class="jo-global--card">
                            <jo-customer-update-payment-details
                                .props=${{
            companyId: state.companyId,
        }}
                                .isMobileDevice=${state.isMobileDevice}
                            ></jo-customer-update-payment-details>
                        </div>
                    </div>
                </div>

                <div class="${cssName('column2')}">
                    <div class="${cssName('paddedItem')}">
                        <div class="jo-global--card">
                            <jo-billing-invoice-history
                                .props=${{
            companyId: state.companyId,
            authenticatedUser: state.authenticatedUser,
        }}
                                .isMobileDevice=${state.isMobileDevice}
                            ></jo-billing-invoice-history>
                        </div>
                    </div>

                    <div class="jo-global--card">
                        <jo-billing-call-log-summary
                            .props=${{
            authenticatedUser: state.authenticatedUser,
            companyId: state.companyId,
        }}
                            .isMobileDevice=${state.isMobileDevice}
                        ></jo-billing-call-log-summary>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-billing', JOBilling);
async function getCurrentMinutesPackageForCompany(companyId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!) {
                    getCompanies(id: $companyId) {
                        id
                        current_minutes_package {
                            id
                            base_fee
                            billing_cycle_type
                            name
                            num_minutes_included
                            price_per_minute
                            recurring_fee
                            type
                            user_display_name
                            minutes_calculation_option {
                                id
                                max_billable_action_item_milliseconds
                                max_billable_milliseconds
                                min_billable_milliseconds
                                min_milliseconds_call_needs_to_be_billable
                            }
                        }
                    }
                }
            `, {
            companyId,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const company = gqlResult.value.data.getCompanies;
        if (!company.current_minutes_package) {
            return {
                succeeded: true,
                value: 'NOT_FOUND',
            };
        }
        return {
            succeeded: true,
            value: company.current_minutes_package,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('getCurrentMinutesPackageForCompany', error);
    }
}
