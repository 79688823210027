"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchFlaggedActionItemsForJill = exports.fetchChatDurationEventsForJill = exports.fetchJillStatusEvents = exports.fetchShifts = exports.fetchJill = exports.fetchJillBaseInfo = exports.fetchJillHighLevelStatses = exports.fetchCallsForJillStats = void 0;
const graphql_1 = require("../../../services/graphql");
const utilities_1 = require("../../../services/utilities");
const error_handling_1 = require("../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function fetchCallsForJillStats(execute, jillId, startDate, endDate) {
    try {
        const gqlResult = await execute((0, graphql_1.gql) `
                query ($jillId: Int!, $startDate: String!, $endDate: String!) {
                    calls: callsForJillStats(
                        jillId: $jillId
                        startDate: $startDate
                        endDate: $endDate
                    ) {
                        id
                        created_at
                        company {
                            id
                            name
                            is_test_company
                            pod {
                                type
                            }
                        }
                        origin_phone
                        destination_phone
                        duration
                        billable_duration
                        twilio_parent_call_sid
                        action_item {
                            id
                            title
                        }
                        duration_events {
                            id
                            description
                            timestamp
                            type
                        }
                        new_direction
                        recording_url
                        was_personal_admin_call
                        twilio_reason_conference_ended
                    }
                }
            `, {
            jillId: jillId,
            startDate: startDate,
            endDate: endDate,
        });
        if (gqlResult.data === null || gqlResult.data === undefined) {
            throw new Error('There was a problem');
        }
        const calls = gqlResult.data.calls;
        return calls;
    }
    catch (error) {
        (0, utilities_1.joAlert)('Error', 'There was an error');
        throw new Error(`fetchCallsForJillStats error: ${error}`);
    }
}
exports.fetchCallsForJillStats = fetchCallsForJillStats;
async function fetchJillHighLevelStatses(execute, jillId, startDate, endDate, includeTestCompaniesAndPods) {
    const gqlResult = await execute((0, graphql_1.gql) `
            query (
                $jillId: Int!
                $startDate: String!
                $endDate: String!
                $includeTestCompaniesAndPods: Boolean
            ) {
                jillHighLevelStatses(
                    jillId: $jillId
                    startDate: $startDate
                    endDate: $endDate
                    includeTestCompaniesAndPods: $includeTestCompaniesAndPods
                ) {
                    jillId
                    highLevelStatses {
                        highLevelStats {
                            numCalls
                            numCallsUnder25Seconds
                            numChats
                            numScheduledAppointments
                            actualCallDuration
                            billableCallDuration
                            averageActualCallDuration
                            averageBillableCallDuration
                            averageEfficiencyPerDay
                            averageTimeBetweenCalls
                            averageActionItemTime
                            numFlaggedActionItems
                        }
                        highLevelStatsForMonths {
                            dateInMonth
                            highLevelStats {
                                numCalls
                                actualCallDuration
                                billableCallDuration
                                averageActualCallDuration
                                averageBillableCallDuration
                                averageEfficiencyPerDay
                                averageTimeBetweenCalls
                                averageActionItemTime
                            }
                            highLevelStatsForDays {
                                dateInDay
                                highLevelStats {
                                    numCalls
                                    actualCallDuration
                                    billableCallDuration
                                    averageActualCallDuration
                                    averageBillableCallDuration
                                    averageEfficiencyPerDay
                                    averageTimeBetweenCalls
                                    averageActionItemTime
                                }
                            }
                        }
                    }
                }
            }
        `, {
        jillId,
        startDate,
        endDate,
        includeTestCompaniesAndPods,
    });
    if (gqlResult.data === null || gqlResult.data === undefined) {
        throw new Error(`There was a problem`);
    }
    return gqlResult.data.jillHighLevelStatses;
}
exports.fetchJillHighLevelStatses = fetchJillHighLevelStatses;
async function fetchJillBaseInfo(jillId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($jillId: Int!) {
                    getUsers(id: $jillId) {
                        id
                        first_name
                        last_name
                        is_personal_admin
                    }
                }
            `, {
            jillId,
        });
        return gqlResult.data.getUsers;
    }
    catch (error) {
        throw new Error(`fetchJillBaseInfo error: ${error}`);
    }
}
exports.fetchJillBaseInfo = fetchJillBaseInfo;
async function fetchJill(jillId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($jillId: Int!) {
                    getUsers(id: $jillId) {
                        id
                        first_name
                        last_name
                        is_personal_admin
                        auth_role
                    }
                }
            `, {
            jillId,
        });
        return {
            succeeded: true,
            value: gqlResult.data.getUsers,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchJill', error);
    }
}
exports.fetchJill = fetchJill;
async function fetchShifts(userId, cutOffDate) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($userId: Int!, $cutOffDate: DateTime!) {
                    findUser_shifts(
                        filter: {
                            user_id: {eq: $userId}
                            or: [{archived_at: {eq: null}}, {archived_at: {gt: $cutOffDate}}]
                        }
                    ) {
                        items {
                            id
                            archived_at
                            created_at
                            start_day_of_week
                            end_day_of_week
                            start_hour
                            end_hour
                            start_minute
                            end_minute
                            scheduled_breaks {
                                id
                                archived_at
                                created_at
                                start_day_of_week
                                start_hour
                                start_minute
                                end_day_of_week
                                end_hour
                                end_minute
                            }
                            lunch_breaks {
                                id
                                archived_at
                                created_at
                                start_day_of_week
                                start_hour
                                start_minute
                                end_day_of_week
                                end_hour
                                end_minute
                            }
                        }
                    }
                }
            `, {
            userId,
            cutOffDate: cutOffDate.toISOString(),
        });
        return gqlResult.data.findUser_shifts.items;
    }
    catch (error) {
        throw new Error(`fetchShifts error: ${error}`);
    }
}
exports.fetchShifts = fetchShifts;
async function fetchJillStatusEvents(params) {
    try {
        const { jillId, startDate, endDate } = params;
        const filter = {
            and: [
                {
                    user_id: {
                        eq: jillId,
                    },
                },
                {
                    timestamp: {
                        ge: startDate,
                    },
                },
                {
                    timestamp: {
                        lt: endDate,
                    },
                },
            ],
        };
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($filter: User_status_eventsFilter) {
                    findUser_status_events(
                        filter: $filter
                        orderBy: {field: "timestamp", order: DESC}
                    ) {
                        items {
                            id
                            timestamp
                            type
                            call {
                                id
                            }
                            company {
                                id
                                name
                            }
                            user {
                                id
                                email
                                auth_role
                            }
                            action_item {
                                id
                            }
                        }
                    }
                }
            `, {
            filter,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        return {
            succeeded: true,
            value: gqlResult.value.data.findUser_status_events.items,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchJillStatusEvents()', error);
    }
}
exports.fetchJillStatusEvents = fetchJillStatusEvents;
async function fetchChatDurationEventsForJill(jillId, startDate, endDate) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($jillId: Int!, $startDate: DateTime!, $endDate: DateTime!) {
                    findChat_duration_events(
                        filter: {
                            and: [
                                {user_id: {eq: $jillId}}
                                {timestamp: {ge: $startDate}}
                                {timestamp: {lt: $endDate}}
                            ]
                        }
                        orderBy: {field: "timestamp", order: DESC}
                    ) {
                        items {
                            id
                            chat {
                                id
                                created_at
                                contact {
                                    id
                                    first_name
                                    last_name
                                }
                                action_items {
                                    id
                                }
                                company {
                                    id
                                    name
                                }
                                messages(orderBy: {field: "created_at", order: ASC}) {
                                    id
                                    body
                                    source
                                    created_at
                                    from_origin
                                }
                            }
                            timestamp
                            type
                        }
                    }
                }
            `, {
            jillId,
            startDate,
            endDate,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        return {
            succeeded: true,
            value: gqlResult.value.data.findChat_duration_events.items,
        };
    }
    catch (error) {
        throw new Error(`/jo-jill/queries.ts --> fetchChatDurationEventsForJill() : ${error}`);
    }
}
exports.fetchChatDurationEventsForJill = fetchChatDurationEventsForJill;
async function fetchFlaggedActionItemsForJill({ jillId, startDate, endDate, }) {
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query ($jillId: Int!, $startDate: DateTime!, $endDate: DateTime!) {
                findAction_item_flags(
                    filter: {
                        and: [
                            {user_id: {eq: $jillId}}
                            {created_at: {ge: $startDate}}
                            {created_at: {lt: $endDate}}
                        ]
                    }
                ) {
                    items {
                        id
                        created_at
                    }
                }
            }
        `, {
        jillId,
        startDate,
        endDate,
    });
    if (gqlResult.succeeded === false) {
        return gqlResult;
    }
    return {
        succeeded: true,
        value: gqlResult.value.data.findAction_item_flags.items,
    };
}
exports.fetchFlaggedActionItemsForJill = fetchFlaggedActionItemsForJill;
