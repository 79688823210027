"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatAs12HourTimeString = void 0;
function formatAs12HourTimeString(date, 
/**
 * Override the time zone. This defaults to the current user's time zone and thus shouldn't need
 * to be set very often, perhaps only in tests.
 */
timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone) {
    const formatOption = {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
        timeZone,
    };
    return date.toLocaleTimeString('en-US', formatOption);
}
exports.formatAs12HourTimeString = formatAs12HourTimeString;
