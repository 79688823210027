"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateScheduledDownTime = exports.deleteScheduledDownTime = exports.createScheduledDownTime = void 0;
const graphql_1 = require("../../../services/graphql");
const error_handling_1 = require("../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function createScheduledDownTime(startTime, endTime) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($startTime: DateTime!, $endTime: DateTime!) {
                    createScheduled_down_times(
                        input: {start_time: $startTime, end_time: $endTime}
                    ) {
                        id
                    }
                }
            `, {
            startTime: startTime.toISOString(),
            endTime: endTime.toISOString(),
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('createScheduledDownTime', error);
    }
}
exports.createScheduledDownTime = createScheduledDownTime;
async function deleteScheduledDownTime(downTimeId) {
    try {
        const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($downTimeId: Int!) {
                    deleteScheduled_down_times(input: {id: $downTimeId}) {
                        id
                    }
                }
            `, {
            downTimeId,
        });
        if (result.succeeded === false)
            return result;
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('deleteScheduledDownTime', error);
    }
}
exports.deleteScheduledDownTime = deleteScheduledDownTime;
async function updateScheduledDownTime(downTimeId, startTime, endTime) {
    try {
        const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($downTimeId: Int!, $startTime: DateTime!, $endTime: DateTime!) {
                    updateScheduled_down_times(
                        input: {id: $downTimeId, start_time: $startTime, end_time: $endTime}
                    ) {
                        id
                    }
                }
            `, {
            downTimeId,
            startTime: startTime.toISOString(),
            endTime: endTime.toISOString(),
        });
        if (result.succeeded === false)
            return result;
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('updateScheduledDownTime', error);
    }
}
exports.updateScheduledDownTime = updateScheduledDownTime;
