"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
require("../jo-button.element");
require("../jo-input");
const InitialState = {
    item: {
        id: 0,
        first_name: '',
        last_name: '',
        company_name: '',
        created_at: '',
        phone_number: '',
        phone_numbers: [{ id: 0, number: '', created_at: '' }],
        updated_at: '',
        isCompanyUser: true,
        companyId: 0,
        addresses: [],
    },
};
class JOCompanyContactsMobileTemplate extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    buildAddressString(address) {
        if (!address)
            return '';
        const addressString = `${address.line_1} ${address.line_2} ${address.city} ${address.state}, ${address.postal_code}`;
        if (addressString.trim() === ',')
            return '';
        return addressString;
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }
                .jo-mobile--table-container {
                    box-sizing: border-box;
                    width: 100%;
                    font-size: 0.9rem;
                    padding: 0.5rem;
                    background-color: white;
                    box-shadow: var(--jo-card-shadow-1);
                    border-radius: var(--jo-wide-border-radius-1);
                    margin: 0.75rem 0;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }
                .info-box {
                    padding: 0 1rem;
                }
                .contact-name {
                    font-size: 1rem;
                    font-weight: bold;
                    padding-bottom: 0.5rem;
                    display: block;
                    text-decoration: none;
                    color: black;
                }
                .contact-phone {
                    text-decoration: none;
                    color: var(--jo-primary);
                }
                .contact-address {
                    padding: 0.25rem 0;
                }
            </style>

            <div class="main-container">
                <div
                    class="jo-mobile--table-container"
                    @click=${() => {
            (0, utilities_1.navigate)(`/customer/contact?companyId=${state.item.companyId}&contactId=${state.item.id}`);
        }}
                >
                    <div class="info-box">
                        <div
                            class="contact-name"
                            @click=${() => {
            (0, utilities_1.navigate)(`/customer/contact?companyId=${state.item.companyId}&contactId=${state.item.id}`);
        }}
                        >
                            ${`${state.item.first_name} ${state.item.last_name}`}
                        </div>
                        <a href="tel:${state.item.phone_number}" class="contact-phone">
                            ${(0, utilities_1.formatE164PhoneNumberForDisplay)(state.item?.phone_number)}
                        </a>
                        <span class="clickArea"></span>
                        <div
                            class="contact-address"
                            @click=${() => {
            (0, utilities_1.navigate)(`/customer/contact?companyId=${state.item.companyId}&contactId=${state.item.id}`);
        }}
                        >
                            ${this.buildAddressString(state.item.addresses[0])}
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-contacts-mobile-template', JOCompanyContactsMobileTemplate);
