"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const auth_rules_1 = require("../../../utilities/auth-rules");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const fetch_global_config_1 = require("../../../utilities/global-configs/fetch-global-config");
require("../jo-button.element");
require("../jo-input");
const helper_utils_1 = require("./helper-utils");
require("./jo-company-billing-info/jo-company-billing-info");
require("./jo-company-contact-info/jo-company-contact-info");
require("./jo-company-jilln-info/jo-company-jilln-info");
const mutations_1 = require("./mutations");
const queries_1 = require("./queries");
const InitialState = {
    accountManagers: [],
    authenticatedUser: 'NOT_SET',
    calendars: [],
    company: 'NOT_SET',
    companyId: 'NOT_SET',
    globalConfig: null,
    inputValues: {
        companyBillingInfoInputValues: 'NOT_SET',
        companyContactInfoInputValues: 'NOT_SET',
        companyJillnInfoInputValues: 'NOT_SET',
    },
    internalNotes: '',
    isMobileDevice: false,
    loading: true,
    personalAdmins: [],
    phoneServices: [],
    previousSelectedPhoneService: 'NOT_SET',
    pods: [],
    saveClicked: false,
    saving: false,
    showAllFormErrors: false,
    selectedVoicemailAudioFile: null,
    selectedQueueAudioFile: null,
};
class JOCompanyDetails extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        const previousCompanyId = this.store.companyId;
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        if (this.shouldFetchAndSetData(previousCompanyId)) {
            this.fetchAndSetData();
        }
    }
    shouldFetchAndSetData(previousCompanyId) {
        return (previousCompanyId !== this.store.companyId &&
            this.store.companyId !== 'NOT_SET' &&
            this.store.authenticatedUser !== 'NOT_SET');
    }
    async fetchAndSetData() {
        this.store.loading = true;
        await this.setData();
        this.store.loading = false;
    }
    async setData() {
        this.removeSelectedAudioFiles();
        await Promise.all([
            this.fetchAndSetCompany(),
            this.fetchAndSetPods(),
            this.fetchAndSetAccountManagers(),
            this.fetchAndSetPersonalAdmins(),
            this.fetchAndSetPhoneServices(),
            this.fetchAndSetCalendars(),
            this.fetchAndSetGlobalConfig(),
        ]);
    }
    set company(company) {
        if (this.store.company === company) {
            return;
        }
        (0, utilities_1.raiseCustomEvent)(this, 'companydetailscompanyabouttochange');
        this.store.company = company;
    }
    set inputValues(inputValues) {
        this.store.inputValues = inputValues;
        (0, utilities_1.raiseCustomEvent)(this, 'companydetailsinputvalueschanged', this.store.inputValues);
    }
    removeSelectedAudioFiles() {
        this.store.selectedQueueAudioFile = null;
        this.store.selectedVoicemailAudioFile = null;
        this.removeValuesFromAudioInputs();
    }
    removeValuesFromAudioInputs() {
        const audioInputIds = [
            'voicemail-audio-input',
            'queue-audio-input',
        ];
        audioInputIds.forEach(audioInputId => {
            this.removeAudioInputValue(audioInputId);
        });
    }
    removeAudioInputValue(audioInputId) {
        const audioInput = this.querySelector(`#${audioInputId}`);
        if (audioInput) {
            audioInput.value = '';
        }
    }
    async fetchAndSetCompany() {
        (0, utilities_1.assertIsSet)(this.store.authenticatedUser, utilities_1.handleError, 'this.store.authenticatedUser');
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        const companyResult = await (0, helper_utils_1.fetchCompany)(this.store.companyId, this.store.authenticatedUser);
        (0, utilities_1.assertSucceeded)(companyResult, utilities_1.handleError);
        this.company = companyResult.value;
        this.store.internalNotes = companyResult.value.internal_notes || '';
    }
    async fetchAndSetPods() {
        const podsResult = await (0, queries_1.fetchPods)();
        (0, utilities_1.assertSucceeded)(podsResult, utilities_1.handleError);
        this.store.pods = podsResult.value;
    }
    async fetchAndSetPhoneServices() {
        const phoneServicesResult = await (0, queries_1.fetchPhoneServices)();
        (0, utilities_1.assertSucceeded)(phoneServicesResult, utilities_1.handleError);
        this.store.phoneServices = phoneServicesResult.value;
    }
    async fetchAndSetCalendars() {
        const calendarsResult = await (0, queries_1.fetchCalendars)();
        (0, utilities_1.assertSucceeded)(calendarsResult, utilities_1.handleError);
        this.store.calendars = calendarsResult.value;
    }
    async fetchAndSetAccountManagers() {
        const accountManagersResult = await (0, queries_1.fetchAccountManagers)();
        (0, utilities_1.assertSucceeded)(accountManagersResult, utilities_1.handleError);
        this.store.accountManagers = accountManagersResult.value;
    }
    async fetchAndSetPersonalAdmins() {
        const personalAdminsResult = await (0, queries_1.fetchPersonalAdmins)();
        (0, utilities_1.assertSucceeded)(personalAdminsResult, utilities_1.handleError);
        this.store.personalAdmins = personalAdminsResult.value;
    }
    async fetchAndSetGlobalConfig() {
        this.store.globalConfig = await (0, fetch_global_config_1.fetchGlobalConfig)([
            'id',
            'niches',
            'referral_sources',
            'referral_tags',
        ]);
    }
    async saveButtonClicked() {
        this.store.saveClicked = true;
        (0, utilities_1.assertIsSet)(this.store.inputValues.companyBillingInfoInputValues, utilities_1.handleError, 'this.store.inputValues.companyBillingInfoInputValues');
        (0, utilities_1.assertIsSet)(this.store.inputValues.companyContactInfoInputValues, utilities_1.handleError, 'this.store.inputValues.companyContactInfoInputValues');
        (0, utilities_1.assertIsSet)(this.store.inputValues.companyJillnInfoInputValues, utilities_1.handleError, 'this.store.inputValues.companyJillnInfoInputValues');
        if ((0, helper_utils_1.areThereAnyFormErrors)(this.store.inputValues.companyBillingInfoInputValues, this.store.inputValues.companyContactInfoInputValues, this.store.inputValues.companyJillnInfoInputValues)) {
            this.store.showAllFormErrors = true;
            (0, utilities_1.joAlert)('Error', 'Please correct form errors');
            return;
        }
        const shouldContinue = await (0, utilities_1.joConfirm)('Notice', 'Are you sure you want to update company details?');
        if (shouldContinue === false) {
            return;
        }
        this.store.saving = true;
        await this.updateInfo();
        await (0, utilities_1.joAlert)('Success!', 'Company successfully updated');
        window.location.reload();
    }
    async updateInfo() {
        const uploadQueueAudioResult = await this.updateAudioRecordingIfNecessary('QUEUE');
        (0, utilities_1.assertSucceeded)(uploadQueueAudioResult, utilities_1.handleError, this, 'uploadQueueAudioResult');
        const uploadVoicemailAudioResult = await this.updateAudioRecordingIfNecessary('VOICEMAIL');
        (0, utilities_1.assertSucceeded)(uploadVoicemailAudioResult, utilities_1.handleError, this, 'uploadVoicemailAudioResult');
        await this.handleUpdateCompany();
    }
    async handleUpdateCompany() {
        (0, utilities_1.assertIsSet)(this.store.authenticatedUser, utilities_1.handleError, 'this.store.authenticatedUser');
        (0, utilities_1.assertIsSet)(this.store.company, utilities_1.handleError, 'this.store.company');
        (0, utilities_1.assertIsSet)(this.store.inputValues.companyJillnInfoInputValues, utilities_1.handleError, 'this.store.inputValues.companyJillnInfoInputValues');
        (0, utilities_1.assertIsSet)(this.store.inputValues.companyBillingInfoInputValues, utilities_1.handleError, 'this.store.inputValues.companyBillingInfoInputValues');
        (0, utilities_1.assertIsSet)(this.store.inputValues.companyContactInfoInputValues, utilities_1.handleError, 'this.store.inputValues.companyContactInfoInputValues');
        const phoneServiceId = await (0, helper_utils_1.getPhoneServiceId)(this.store.inputValues.companyJillnInfoInputValues.selectedPhoneServiceName, this.store.inputValues.companyJillnInfoInputValues.otherPhoneServiceName, this.store.phoneServices);
        const calendarId = await (0, helper_utils_1.getCalendarId)(this.store.inputValues.companyJillnInfoInputValues.selectedCalendarName, this.store.inputValues.companyJillnInfoInputValues.otherCalendarName, this.store.inputValues.companyJillnInfoInputValues.otherCalendarUrl, this.store.calendars);
        const updateCompanyResult = await (0, helper_utils_1.updateCompany)({
            authenticatedUser: this.store.authenticatedUser,
            company: this.store.company,
            accountManagerId: this.getSelectedAccountManagerId(),
            podId: this.getPodIdToConnect(),
            personalAdminId: this.getSelectedPersonalAdminId(),
            phoneServiceIdToConnect: phoneServiceId,
            phoneServiceIdToDisconnect: this.getPhoneServiceIdToDisconnect(phoneServiceId),
            calendarIdToConnect: calendarId,
            calendarIdToDisconnect: this.getCalendarIdToDisconnect(calendarId),
            companyBillingInfoInputValues: this.store.inputValues.companyBillingInfoInputValues,
            companyContactInfoInputValues: this.store.inputValues.companyContactInfoInputValues,
            companyJillnInfoInputValues: this.store.inputValues.companyJillnInfoInputValues,
            internalNotes: this.store.internalNotes,
        });
        (0, utilities_1.assertSucceeded)(updateCompanyResult, utilities_1.handleError);
    }
    getPhoneServiceIdToDisconnect(phoneServiceId) {
        (0, utilities_1.assertIsSet)(this.store.company, utilities_1.handleError, 'this.store.company');
        return phoneServiceId === null ? this.store.company.phone_service?.id : undefined;
    }
    getPodIdToConnect() {
        (0, utilities_1.assertIsSet)(this.store.inputValues.companyJillnInfoInputValues, utilities_1.handleError, 'this.store.inputValues.companyJillnInfoInputValues');
        return ((0, helper_utils_1.getPod)(this.store.inputValues.companyJillnInfoInputValues.selectedPodName, this.store.pods)?.id ?? null);
    }
    getCalendarIdToDisconnect(calendarId) {
        (0, utilities_1.assertIsSet)(this.store.company, utilities_1.handleError, 'this.store.company');
        return calendarId === null ? this.store.company.calendar?.id : undefined;
    }
    getSelectedAccountManagerId() {
        (0, utilities_1.assertIsSet)(this.store.inputValues.companyJillnInfoInputValues, utilities_1.handleError, 'this.store.inputValues.companyJillnInfoInputValues');
        return ((0, helper_utils_1.getAccountManagerFromName)(this.store.inputValues.companyJillnInfoInputValues.selectedAccountManagerName, this.store.accountManagers)?.id ?? null);
    }
    getSelectedPersonalAdminId() {
        (0, utilities_1.assertIsSet)(this.store.inputValues.companyJillnInfoInputValues, utilities_1.handleError, 'this.store.inputValues.companyJillnInfoInputValues');
        return ((0, helper_utils_1.getAccountManagerFromName)(this.store.inputValues.companyJillnInfoInputValues.selectedPersonalAdminName, this.store.personalAdmins)?.id ?? null);
    }
    cancelButtonClicked() {
        const originalCompany = this.store.company;
        this.company = 'NOT_SET';
        this.company = originalCompany;
        this.removeSelectedAudioFiles();
    }
    async handleEnrollInCrewCalEvent() {
        (0, utilities_1.assertIsSet)(this.store.company, utilities_1.handleError, 'jo-company-details > this.store.company');
        const updateCompanyStatusEventResult = await (0, mutations_1.updateCompanyStatusEvent)(this.store.company.id, 'STARTED_USING_CREW_CAL');
        (0, utilities_1.assertSucceeded)(updateCompanyStatusEventResult, utilities_1.handleError);
        this.fetchAndSetData();
    }
    async handleOptOutOfCrewCalEvent() {
        (0, utilities_1.assertIsSet)(this.store.company, utilities_1.handleError, 'jo-company-details > this.store.company');
        const updateCompanyStatusEventResult = await (0, mutations_1.updateCompanyStatusEvent)(this.store.company.id, 'STOPPED_USING_CREW_CAL');
        (0, utilities_1.assertSucceeded)(updateCompanyStatusEventResult, utilities_1.handleError);
        this.fetchAndSetData();
    }
    async handleEnrollInChatEvent() {
        (0, utilities_1.assertIsSet)(this.store.company, utilities_1.handleError, 'jo-company-details > this.store.company');
        const updateCompanyStatusEventResult = await (0, mutations_1.updateCompanyStatusEvent)(this.store.company.id, 'STARTED_USING_CHAT');
        (0, utilities_1.assertSucceeded)(updateCompanyStatusEventResult, utilities_1.handleError);
        if (this.store.company.chat_widget_settings.length === 0) {
            const updateChatSettingsResult = await (0, mutations_1.setDefaultChatSettingsForCompany)(this.store.company.id);
            (0, utilities_1.assertSucceeded)(updateChatSettingsResult, utilities_1.handleError);
        }
        this.fetchAndSetData();
    }
    async handleOptOutOfChatEvent() {
        (0, utilities_1.assertIsSet)(this.store.company, utilities_1.handleError, 'jo-company-details > this.store.company');
        const updateCompanyStatusEventResult = await (0, mutations_1.updateCompanyStatusEvent)(this.store.company.id, 'STOPPED_USING_CHAT');
        (0, utilities_1.assertSucceeded)(updateCompanyStatusEventResult, utilities_1.handleError);
        this.fetchAndSetData();
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .jo-company-details--main-container {
                    font-family: sans-serif;
                    color: var(--jo-text-default);
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;
                }

                .jo-company-details--info-container {
                    display: flex;
                    justify-content: space-between;
                }

                .jo-company-details--half-container-contact-info,
                .jo-company-details--half-container-jilln-info {
                    width: 49.25%;
                    height: 100%;
                }

                .jo-company-details--save-cancel-container {
                    box-sizing: border-box;
                    padding-top: 2rem;
                    display: flex;
                    justify-content: flex-start;
                    width: 100%;
                }

                .jo-company-details--info-card {
                    padding: ${state.isMobileDevice ? '0' : '1rem'};
                }

                .jo-company-details--info-card-body-content {
                    padding: ${state.isMobileDevice ? '0.5rem 0 0.5rem 0.5rem' : '0 1rem 2rem'};
                }

                .jo-company-details--info-card-row input[type='text'],
                .jo-company-details--info-card-row select,
                .jo-company-details--info-card-row textarea {
                    width: ${state.isMobileDevice ? '95%' : '85%'};
                }

                .jo-company-details--info-card-header {
                    font-size: ${state.isMobileDevice ? 'calc(15px + 1vmin)' : ''};
                }

                .jo-company-details--internalNotes {
                    padding: 1rem;
                    margin-top: 1rem;
                }

                @media screen and (max-width: 1200px) {
                    .jo-company-details--info-container {
                        flex-direction: column;
                    }

                    .jo-company-details--half-container-contact-info,
                    .jo-company-details--half-container-jilln-info {
                        width: 100%;
                    }

                    .jo-company-details--half-container-jilln-info {
                        margin-top: 1rem;
                    }
                }
            </style>

            <jo-loading
                .props=${{
            loading: state.loading,
        }}
            ></jo-loading>

            <div class="jo-company-details--main-container">
                ${(0, helper_utils_1.makeTriggerButtonHtmlForAdminModeForCompany)(state.authenticatedUser, state.companyId)}

                <div class="jo-company-details--info-container">
                    <div class="jo-company-details--half-container-contact-info">
                        <jo-company-contact-info
                            .authenticatedUser=${state.authenticatedUser}
                            .company=${state.company}
                            .globalConfig=${state.globalConfig}
                            .showAllFormErrors=${state.showAllFormErrors}
                            @companycontactinfoinputvalueschanged=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                companyContactInfoInputValues: e.detail,
            };
        }}
                            @businessnumberverified=${() => this.fetchAndSetCompany()}
                            .isMobileDevice=${state.isMobileDevice}
                        ></jo-company-contact-info>

                        ${(0, utilities_1.authorizedRender)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser, (0, lit_html_1.html) `
                                <br />

                                <jo-company-billing-info
                                    ?hidden=${(0, utilities_1.isInAuthRole)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser) === false}
                                    .company=${state.company}
                                    .showAllFormErrors=${state.showAllFormErrors}
                                    @companybillinginfoinputvalueschanged=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                companyBillingInfoInputValues: e.detail,
            };
        }}
                                    @enrollInCrewCal=${() => this.handleEnrollInCrewCalEvent()}
                                    @optOutOfCrewCal=${() => this.handleOptOutOfCrewCalEvent()}
                                    @enrollInChat=${() => this.handleEnrollInChatEvent()}
                                    @optOutOfChat=${() => this.handleOptOutOfChatEvent()}
                                ></jo-company-billing-info>
                            `)}
                    </div>

                    <div class="jo-company-details--half-container-jilln-info">
                        <jo-company-jilln-info
                            .authenticatedUser=${state.authenticatedUser}
                            .company=${state.company}
                            .isMobileDevice=${state.isMobileDevice}
                            .pods=${state.pods}
                            .accountManagers=${state.accountManagers}
                            .personalAdmins=${state.personalAdmins}
                            .phoneServices=${state.phoneServices}
                            .calendars=${state.calendars}
                            .showAllFormErrors=${state.showAllFormErrors}
                            .selectedVoicemailAudioFile=${state.selectedVoicemailAudioFile}
                            .selectedQueueAudioFile=${state.selectedQueueAudioFile}
                            @companyjillninfoinputvalueschanged=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                companyJillnInfoInputValues: e.detail,
            };
        }}
                            @voicemailAudioFileSelected=${(e) => {
            this.store.selectedVoicemailAudioFile = e.detail;
        }}
                            @queueAudioFileSelected=${(e) => {
            this.store.selectedQueueAudioFile = e.detail;
        }}
                        ></jo-company-jilln-info>

                        ${(0, auth_rules_1.isJillAtOrAboveAuthRole)(state.authenticatedUser, 'JILL_MANAGER')
            ? (0, lit_html_1.html) `
                                  <div class="jo-global--card jo-company-details--internalNotes">
                                      <jo-input
                                          .type=${'TEXT_AREA'}
                                          .label=${'Internal Notes'}
                                          .rows=${8}
                                          .inputValue=${this.store.internalNotes}
                                          @valueChanged=${(e) => {
                this.store.internalNotes = e.detail.inputValue;
            }}
                                      ></jo-input>
                                  </div>
                              `
            : (0, lit_html_1.html) ``}
                    </div>
                </div>

                ${(0, utilities_1.authorizedRender)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser, (0, lit_html_1.html) `
                        <div class="jo-company-details--save-cancel-container">
                            <jo-button
                                style="padding-right: 1rem"
                                .type=${'ACTION_BUTTON_1'}
                                .text=${state.saving === true ? 'Saving...' : 'Save'}
                                .disabled=${state.saving === true}
                                @joButtonClick=${() => this.saveButtonClicked()}
                            ></jo-button>

                            <jo-button
                                .type=${'SECONDARY_BUTTON_1'}
                                .text=${'Cancel'}
                                @joButtonClick=${() => this.cancelButtonClicked()}
                            ></jo-button>
                        </div>
                    `)}
                <div ?hidden=${!state.isMobileDevice} class="mobile-bottom-filler"></div>
            </div>
        `;
    }
    async updateAudioRecordingIfNecessary(type) {
        const audioFile = this.getCorrectSelectedAudioFile(type);
        if (!audioFile) {
            return { succeeded: true };
        }
        const audioFileWithFormattedName = (0, helper_utils_1.formatFileName)(audioFile);
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'updateVoicemailAudioIfNecessary -> companyId');
        const uploadAudioResult = await (0, helper_utils_1.uploadAudioRecording)(this.store.companyId, audioFileWithFormattedName, type);
        return uploadAudioResult;
    }
    getCorrectSelectedAudioFile(type) {
        return type === 'QUEUE'
            ? this.store.selectedQueueAudioFile
            : this.store.selectedVoicemailAudioFile;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-details', JOCompanyDetails);
