"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOTeam = void 0;
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../services/graphql");
const utilities_1 = require("../../services/utilities");
const error_handling_1 = require("../../utilities/error-handling");
const null_if_not_set_1 = require("../../utilities/null-if-not-set");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../utilities/environment/mapped-env-variables");
require("./jo-action-item-flags-table/jo-action-item-flags-table");
require("./jo-button.element");
require("./jo-jill/jo-jill.element");
const startOfToday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
const startOfTomorrow = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1);
const InitialState = {
    teamId: 'NOT_SET',
    team: 'NOT_SET',
    teamHighLevelStatses: 'NOT_SET',
    highLevelStatsForMonthsCollapsed: [],
    statsStartDate: startOfToday.toISOString(),
    statsEndDate: startOfTomorrow.toISOString(),
    fetchStatsAbortController: 'NOT_SET',
    loadingStats: true,
    showJillCards: true,
    showJillStats: true,
    showFlaggedActionItemsTable: false,
    showDatesAndCharts: true,
    authenticatedUser: 'NOT_SET',
};
function RootReducer(state, action) {
    if (action.type === 'TOGGLE_HIGH_LEVEL_STATS_FOR_MONTH_COLLAPSED') {
        return {
            ...state,
            highLevelStatsForMonthsCollapsed: state.highLevelStatsForMonthsCollapsed.map((highLevelStatsForMonthCollapsed) => {
                if (new Date(highLevelStatsForMonthCollapsed.highLevelStatsForMonth.dateInMonth).getTime() ===
                    new Date(action.highLevelStatsForMonth.dateInMonth).getTime()) {
                    return {
                        ...highLevelStatsForMonthCollapsed,
                        collapsed: !highLevelStatsForMonthCollapsed.collapsed,
                    };
                }
                return highLevelStatsForMonthCollapsed;
            }),
        };
    }
    return state;
}
class JOTeam extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, RootReducer);
    }
    set teamId(teamId) {
        if (teamId === 'NOT_SET' || teamId === this.store.teamId) {
            return;
        }
        this.store.teamId = teamId;
        this.store.statsStartDate = startOfToday.toISOString();
        this.store.statsEndDate = startOfTomorrow.toISOString();
        this.fetchAndSetStats();
        this.fetchAndSetTeam();
    }
    set statsStartDate(statsStartDate) {
        this.store.statsStartDate = statsStartDate;
        this.fetchAndSetStats();
    }
    set statsEndDate(statsEndDate) {
        this.store.statsEndDate = statsEndDate;
        this.fetchAndSetStats();
    }
    async fetchAndSetStats() {
        this.store.loadingStats = true;
        if (this.store.teamId === 'NOT_SET') {
            return;
        }
        const currentFetchStatsAbortController = this.store.fetchStatsAbortController;
        if (currentFetchStatsAbortController !== 'NOT_SET') {
            currentFetchStatsAbortController.abort();
            this.store.fetchStatsAbortController = 'NOT_SET';
        }
        const { abortController, execute } = (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint);
        this.store.fetchStatsAbortController = abortController;
        const fetchTeamHighLevelStatsesResult = await fetchTeamHighLevelStatses(execute, this.store.teamId, this.store.statsStartDate, this.store.statsEndDate);
        if (fetchTeamHighLevelStatsesResult.succeeded === false) {
            return;
        }
        const teamHighLevelStatses = fetchTeamHighLevelStatsesResult.value;
        this.store.teamHighLevelStatses = teamHighLevelStatses;
        this.store.loadingStats = false;
        this.loadCharts();
    }
    async fetchAndSetTeam() {
        const teamId = this.store.teamId;
        if (teamId === 'NOT_SET') {
            return;
        }
        const fetchTeamResult = await fetchTeam(teamId);
        (0, utilities_1.assertSucceeded)(fetchTeamResult, utilities_1.handleError);
        const team = fetchTeamResult.value;
        this.store.team = team;
    }
    async statsStartDateInputChanged(e) {
        const newStartDate = new Date(`${e.target.value}T00:00`).toISOString();
        this.store.statsStartDate = newStartDate;
        await this.fetchAndSetStats();
    }
    async statsEndDateInputChanged(e) {
        const newEndDate = new Date(`${e.target.value}T00:00`).toISOString();
        this.store.statsEndDate = newEndDate;
        await this.fetchAndSetStats();
    }
    loadCharts() {
        const teamHighLevelStatses = this.store.teamHighLevelStatses;
        if (teamHighLevelStatses === 'NOT_SET') {
            return;
        }
        (0, utilities_1.loadChart)(this, teamHighLevelStatses.highLevelStatses, 'chart-total-calls', 'Total calls', 'numCalls');
        (0, utilities_1.loadChart)(this, teamHighLevelStatses.highLevelStatses, 'chart-total-actual-call-duration', 'Total actual call duration', 'actualCallDuration');
        (0, utilities_1.loadChart)(this, teamHighLevelStatses.highLevelStatses, 'chart-total-billable-call-duration', 'Total billable call duration', 'billableCallDuration');
        (0, utilities_1.loadChart)(this, teamHighLevelStatses.highLevelStatses, 'chart-average-efficiency-per-day', 'Average efficiency per day', 'averageEfficiencyPerDay');
        (0, utilities_1.loadChart)(this, teamHighLevelStatses.highLevelStatses, 'chart-average-billable-call-duration', 'Average billable call duration', 'averageBillableCallDuration');
        (0, utilities_1.loadChart)(this, teamHighLevelStatses.highLevelStatses, 'chart-average-actual-call-duration', 'Average actual call duration', 'averageActualCallDuration');
        (0, utilities_1.loadChart)(this, teamHighLevelStatses.highLevelStatses, 'chart-average-time-between-calls', 'Average time between calls', 'averageTimeBetweenCalls');
        (0, utilities_1.loadChart)(this, teamHighLevelStatses.highLevelStatses, 'chart-average-action-item-time', 'Average action item time', 'averageActionItemTime');
    }
    toggleHighLevelStatsForMonthCollapsed(highLevelStatsForMonth) {
        this.store.dispatch({
            type: 'TOGGLE_HIGH_LEVEL_STATS_FOR_MONTH_COLLAPSED',
            highLevelStatsForMonth,
        });
    }
    csvButtonClicked() {
        (0, utilities_1.assertIsSet)(this.store.teamHighLevelStatses, utilities_1.handleError);
        const startDate = (0, utilities_1.formatISODateForFileName)(this.store.statsStartDate);
        const endDate = (0, utilities_1.formatISODateForFileName)(this.store.statsEndDate);
        const fileName = `team-stats-${startDate}-through-${endDate}.csv`;
        (0, utilities_1.generateAndDownloadCSVForHighLevelStatses)(this.store.teamHighLevelStatses.highLevelStatses, this.store.highLevelStatsForMonthsCollapsed, fileName);
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .jo-team--container {
                    padding: calc(25px + 1vmin);
                }

                .jo-team--stats-container {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                    align-items: center;
                }

                .jo-team--stats-header {
                    font-weight: bold;
                    padding-bottom: calc(5px + 1vmin);
                    padding-left: 15;
                    padding-right: 15px;
                }

                .jo-team--stats-item {
                    padding-bottom: calc(5px + 1vmin);
                    padding-left: 15px;
                    padding-right: 15px;
                }

                .jo-team--dates-and-charts {
                    display: grid;
                    grid-template-columns: 1fr 5fr;
                }

                .jo-team--charts {
                    display: flex;
                    overflow-x: scroll;
                }

                .jo-team--bold {
                    font-weight: bold;
                }

                .jo-team--row:hover > div {
                    background-color: var(--jo-primary-light);
                }

                .jo-team--row {
                    display: contents;
                }

                .jo-team--pointer {
                    cursor: pointer;
                }

                .jo-team--clickable:hover {
                    cursor: pointer;
                    color: var(--jo-primary);
                }

                .jo-team--jill-cards-container {
                    display: grid;
                    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
                }

                .jo-team--jill-card-container {
                    background-color: white;
                    padding: calc(5px + 1vmin);
                    margin: calc(5px + 1vmin);
                    box-shadow: 0px 0px 1px black;
                    overflow-x: hidden;
                    box-shadow: var(--jo-card-shadow-1);
                }

                .jo-team--flags-table-container {
                    padding-top: 3rem;
                }
            </style>

            <div class="jo-team--container">
                ${state.showJillCards
            ? (0, lit_html_1.html) `
                          <div class="jo-team--jill-cards-container">
                              ${state.team === 'NOT_SET'
                ? ''
                : state.team.members?.map((jill) => {
                    return (0, lit_html_1.html) `
                                            <div class="jo-team--jill-card-container">
                                                <jo-jill-card
                                                    .jillId=${jill.id}
                                                    .authenticatedUser=${state.authenticatedUser}
                                                ></jo-jill-card>
                                            </div>
                                        `;
                })}
                          </div>
                      `
            : ''}
                ${state.showDatesAndCharts
            ? (0, lit_html_1.html) `
                          <h2>Team ${state.teamId} Stats</h2>

                          <div class="jo-team--dates-and-charts">
                              <div>
                                  <div>Start date</div>
                                  <div>
                                      <input
                                          type="date"
                                          .value=${state.statsStartDate.split('T')[0]}
                                          @change=${(e) => this.statsStartDateInputChanged(e)}
                                      />
                                  </div>
                                  <br />
                                  <div>End date</div>
                                  <div>
                                      <input
                                          type="date"
                                          .value=${state.statsEndDate.split('T')[0]}
                                          @change=${(e) => this.statsEndDateInputChanged(e)}
                                      />
                                  </div>
                              </div>

                              <div class="jo-team--charts">
                                  <div id="chart-total-calls-div">
                                      <canvas id="chart-total-calls"></canvas>
                                  </div>

                                  <div id="chart-total-actual-call-duration-div">
                                      <canvas id="chart-total-actual-call-duration"></canvas>
                                  </div>

                                  <div id="chart-total-billable-call-duration-div">
                                      <canvas id="chart-total-billable-call-duration"></canvas>
                                  </div>

                                  <div id="chart-average-efficiency-per-day-div">
                                      <canvas id="chart-average-efficiency-per-day"></canvas>
                                  </div>

                                  <div id="chart-average-billable-call-duration-div">
                                      <canvas id="chart-average-billable-call-duration"></canvas>
                                  </div>

                                  <div id="chart-average-actual-call-duration-div">
                                      <canvas id="chart-average-actual-call-duration"></canvas>
                                  </div>

                                  <div id="chart-average-time-between-calls-div">
                                      <canvas id="chart-average-time-between-calls"></canvas>
                                  </div>

                                  <div id="chart-average-action-item-time-div">
                                      <canvas id="chart-average-action-item-time"></canvas>
                                  </div>
                              </div>
                          </div>

                          <br />
                      `
            : ''}
                ${state.loadingStats === true || state.teamHighLevelStatses === 'NOT_SET'
            ? (0, lit_html_1.html) `
                          <div>Loading...</div>
                      `
            : (0, lit_html_1.html) `
                          <div class="jo-global--high-level-statses-csv-button-container">
                              <jo-button
                                  .text=${'Download as CSV'}
                                  .type=${'ACTION_BUTTON_2'}
                                  @joButtonClick=${() => this.csvButtonClicked()}
                              ></jo-button>
                          </div>
                          <div class="jo-team--stats-container">
                              <div class="jo-team--stats-header">Date</div>
                              <div class="jo-team--stats-header">Total Calls</div>
                              <div title="Total Actual Call Duration" class="jo-team--stats-header">
                                  Total ACD
                              </div>
                              <div
                                  title="Total Billable Call Duration"
                                  class="jo-team--stats-header"
                              >
                                  Total BCD
                              </div>
                              <div title="Average Efficiency" class="jo-team--stats-header">
                                  Avg. Efficiency
                              </div>
                              <div
                                  title="Average Billable Call Duration"
                                  class="jo-team--stats-header"
                              >
                                  Avg. BCD
                              </div>
                              <div
                                  title="Average Actual Call Duration"
                                  class="jo-team--stats-header"
                              >
                                  Avg. ACD
                              </div>
                              <div title="Average Time Between Calls" class="jo-team--stats-header">
                                  Avg. TBC
                              </div>
                              <div title="Average Action Item Time" class="jo-team--stats-header">
                                  Avg. AI Time
                              </div>

                              <div class="jo-team--row">
                                  <div class="jo-team--stats-item jo-team--bold">Total Range</div>
                                  <div class="jo-team--stats-item">
                                      ${state.teamHighLevelStatses.highLevelStatses.highLevelStats
                .numCalls}
                                  </div>
                                  <div
                                      title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(parseInt(state.teamHighLevelStatses.highLevelStatses
                .highLevelStats.actualCallDuration))}"
                                      class="jo-team--stats-item"
                                  >
                                      ${(0, utilities_1.millisecondsToMinutes)(parseInt(state.teamHighLevelStatses.highLevelStatses
                .highLevelStats.actualCallDuration))}
                                  </div>
                                  <div
                                      title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(parseInt(state.teamHighLevelStatses.highLevelStatses
                .highLevelStats.billableCallDuration))}"
                                      class="jo-team--stats-item"
                                  >
                                      ${(0, utilities_1.millisecondsToMinutes)(parseInt(state.teamHighLevelStatses.highLevelStatses
                .highLevelStats.billableCallDuration))}
                                  </div>
                                  <div class="jo-team--stats-item">
                                      ${state.teamHighLevelStatses.highLevelStatses.highLevelStats.averageEfficiencyPerDay.toFixed(2)}%
                                  </div>
                                  <div
                                      title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(state.teamHighLevelStatses.highLevelStatses.highLevelStats
                .averageBillableCallDuration)}"
                                      class="jo-team--stats-item"
                                  >
                                      ${(0, utilities_1.millisecondsToMinutes)(state.teamHighLevelStatses.highLevelStatses.highLevelStats
                .averageBillableCallDuration)}
                                  </div>
                                  <div
                                      title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(state.teamHighLevelStatses.highLevelStatses.highLevelStats
                .averageActualCallDuration)}"
                                      class="jo-team--stats-item"
                                  >
                                      ${(0, utilities_1.millisecondsToMinutes)(state.teamHighLevelStatses.highLevelStatses.highLevelStats
                .averageActualCallDuration)}
                                  </div>
                                  <div
                                      title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(state.teamHighLevelStatses.highLevelStatses.highLevelStats
                .averageTimeBetweenCalls)}"
                                      class="jo-team--stats-item"
                                  >
                                      ${(0, utilities_1.millisecondsToMinutes)(state.teamHighLevelStatses.highLevelStatses.highLevelStats
                .averageTimeBetweenCalls)}
                                  </div>
                                  <div
                                      title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(state.teamHighLevelStatses.highLevelStatses.highLevelStats
                .averageActionItemTime)}"
                                      class="jo-team--stats-item"
                                  >
                                      ${(0, utilities_1.millisecondsToMinutes)(state.teamHighLevelStatses.highLevelStatses.highLevelStats
                .averageActionItemTime)}
                                  </div>
                              </div>

                              ${state.teamHighLevelStatses.highLevelStatses.highLevelStatsForMonths.map((highLevelStatsForMonth) => {
                return (0, lit_html_1.html) `
                                          <div class="jo-team--row">
                                              <div
                                                  class="jo-team--stats-item jo-team--bold jo-team--pointer"
                                                  @click=${() => this.toggleHighLevelStatsForMonthCollapsed(highLevelStatsForMonth)}
                                              >
                                                  ${new Date(highLevelStatsForMonth.dateInMonth).toLocaleString('en-us', {
                    month: 'long',
                    year: 'numeric',
                })}
                                              </div>
                                              <div class="jo-team--stats-item">
                                                  ${highLevelStatsForMonth.highLevelStats.numCalls}
                                              </div>
                                              <div
                                                  title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(parseInt(highLevelStatsForMonth.highLevelStats
                    .actualCallDuration))}"
                                                  class="jo-team--stats-item"
                                              >
                                                  ${(0, utilities_1.millisecondsToMinutes)(parseInt(highLevelStatsForMonth.highLevelStats
                    .actualCallDuration))}
                                              </div>
                                              <div
                                                  title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(parseInt(highLevelStatsForMonth.highLevelStats
                    .billableCallDuration))}"
                                                  class="jo-team--stats-item"
                                              >
                                                  ${(0, utilities_1.millisecondsToMinutes)(parseInt(highLevelStatsForMonth.highLevelStats
                    .billableCallDuration))}
                                              </div>
                                              <div class="jo-team--stats-item">
                                                  ${highLevelStatsForMonth.highLevelStats.averageEfficiencyPerDay.toFixed(2)}%
                                              </div>
                                              <div
                                                  title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(highLevelStatsForMonth.highLevelStats
                    .averageBillableCallDuration)}"
                                                  class="jo-team--stats-item"
                                              >
                                                  ${(0, utilities_1.millisecondsToMinutes)(highLevelStatsForMonth.highLevelStats
                    .averageBillableCallDuration)}
                                              </div>
                                              <div
                                                  title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(highLevelStatsForMonth.highLevelStats
                    .averageActualCallDuration)}"
                                                  class="jo-team--stats-item"
                                              >
                                                  ${(0, utilities_1.millisecondsToMinutes)(highLevelStatsForMonth.highLevelStats
                    .averageActualCallDuration)}
                                              </div>
                                              <div
                                                  title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(highLevelStatsForMonth.highLevelStats
                    .averageTimeBetweenCalls)}"
                                                  class="jo-team--stats-item"
                                              >
                                                  ${(0, utilities_1.millisecondsToMinutes)(highLevelStatsForMonth.highLevelStats
                    .averageTimeBetweenCalls)}
                                              </div>
                                              <div
                                                  title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(highLevelStatsForMonth.highLevelStats
                    .averageActionItemTime)}"
                                                  class="jo-team--stats-item"
                                              >
                                                  ${(0, utilities_1.millisecondsToMinutes)(highLevelStatsForMonth.highLevelStats
                    .averageActionItemTime)}
                                              </div>
                                          </div>
                                          ${(() => {
                    const highLevelStatsForMonthCollapsed = state.highLevelStatsForMonthsCollapsed.find(highLevelStatsForMonthCollapsed => {
                        return (new Date(highLevelStatsForMonthCollapsed.highLevelStatsForMonth.dateInMonth).getTime() ===
                            new Date(highLevelStatsForMonth.dateInMonth).getTime());
                    });
                    if (highLevelStatsForMonthCollapsed?.collapsed ===
                        true) {
                        return '';
                    }
                    return highLevelStatsForMonth.highLevelStatsForDays.map((highLevelStatsForDay) => {
                        return (0, lit_html_1.html) `
                                                          <div class="jo-team--row">
                                                              <div class="jo-team--stats-item">
                                                                  ${new Date(highLevelStatsForDay.dateInDay).toLocaleDateString()}
                                                              </div>
                                                              <div class="jo-team--stats-item">
                                                                  ${highLevelStatsForDay
                            .highLevelStats.numCalls}
                                                              </div>
                                                              <div
                                                                  title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(parseInt(highLevelStatsForDay
                            .highLevelStats
                            .actualCallDuration))}"
                                                                  class="jo-team--stats-item"
                                                              >
                                                                  ${(0, utilities_1.millisecondsToMinutes)(parseInt(highLevelStatsForDay
                            .highLevelStats
                            .actualCallDuration))}
                                                              </div>
                                                              <div
                                                                  title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(parseInt(highLevelStatsForDay
                            .highLevelStats
                            .billableCallDuration))}"
                                                                  class="jo-team--stats-item"
                                                              >
                                                                  ${(0, utilities_1.millisecondsToMinutes)(parseInt(highLevelStatsForDay
                            .highLevelStats
                            .billableCallDuration))}
                                                              </div>
                                                              <div class="jo-team--stats-item">
                                                                  ${highLevelStatsForDay.highLevelStats.averageEfficiencyPerDay.toFixed(2)}%
                                                              </div>
                                                              <div
                                                                  title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(highLevelStatsForDay
                            .highLevelStats
                            .averageBillableCallDuration)}"
                                                                  class="jo-team--stats-item"
                                                              >
                                                                  ${(0, utilities_1.millisecondsToMinutes)(highLevelStatsForDay
                            .highLevelStats
                            .averageBillableCallDuration)}
                                                              </div>
                                                              <div
                                                                  title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(highLevelStatsForDay
                            .highLevelStats
                            .averageActualCallDuration)}"
                                                                  class="jo-team--stats-item"
                                                              >
                                                                  ${(0, utilities_1.millisecondsToMinutes)(highLevelStatsForDay
                            .highLevelStats
                            .averageActualCallDuration)}
                                                              </div>
                                                              <div
                                                                  title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(highLevelStatsForDay
                            .highLevelStats
                            .averageTimeBetweenCalls)}"
                                                                  class="jo-team--stats-item"
                                                              >
                                                                  ${(0, utilities_1.millisecondsToMinutes)(highLevelStatsForDay
                            .highLevelStats
                            .averageTimeBetweenCalls)}
                                                              </div>
                                                              <div
                                                                  title="${(0, utilities_1.millisecondsToHoursMinutesSeconds)(highLevelStatsForDay
                            .highLevelStats
                            .averageActionItemTime)}"
                                                                  class="jo-team--stats-item"
                                                              >
                                                                  ${(0, utilities_1.millisecondsToMinutes)(highLevelStatsForDay
                            .highLevelStats
                            .averageActionItemTime)}
                                                              </div>
                                                          </div>
                                                      `;
                    });
                })()}
                                      `;
            })}
                          </div>
                      `}
                ${state.showJillStats === false || state.team === 'NOT_SET'
            ? ''
            : state.team.members?.map((jill) => {
                return (0, lit_html_1.html) `
                              <h3
                                  class="jo-team--clickable"
                                  @click=${() => (0, utilities_1.navigate)(`/jill?jillId=${jill.id}`)}
                              >
                                  ${jill.first_name} ${jill.last_name}
                              </h3>
                              <jo-jill
                                  .showJillName=${false}
                                  .showCalls=${false}
                                  .showDatesAndCharts=${false}
                                  .showJillCard=${false}
                                  .statsStartDate=${state.statsStartDate}
                                  .statsEndDate=${state.statsEndDate}
                                  .jillId=${jill.id}
                              ></jo-jill>
                              <hr />
                              <br />
                          `;
            })}
                ${state.showFlaggedActionItemsTable
            ? (0, lit_html_1.html) `
                          <div class="jo-team--flags-table-container">
                              <div class="jo-global--card">
                                  <jo-action-item-flags-table
                                      .props=${{
                authenticatedUser: state.authenticatedUser,
                teamId: (0, null_if_not_set_1.nullIfNotSet)(state.teamId),
                csvTitle: `Flagged Action Items -- ${getTeamName(state)}`,
            }}
                                  ></jo-action-item-flags-table>
                              </div>
                          </div>
                      `
            : ''}
            </div>
        `;
    }
}
exports.JOTeam = JOTeam;
(0, define_custom_element_1.defineCustomElement)('jo-team', JOTeam);
function getTeamName(state) {
    return state.team === 'NOT_SET' ? '' : `Team ${state.teamId}`;
}
async function fetchTeamHighLevelStatses(execute, teamId, startDate, endDate) {
    try {
        const gqlResult = await execute((0, graphql_1.gql) `
                query ($teamId: Int!, $startDate: String!, $endDate: String!) {
                    highLevelStatses(startDate: $startDate, endDate: $endDate, teamId: $teamId) {
                        highLevelStats {
                            numCalls
                            actualCallDuration
                            billableCallDuration
                            averageActualCallDuration
                            averageBillableCallDuration
                            averageEfficiencyPerDay
                            averageTimeBetweenCalls
                            averageActionItemTime
                        }
                        highLevelStatsForMonths {
                            dateInMonth
                            highLevelStats {
                                numCalls
                                actualCallDuration
                                billableCallDuration
                                averageActualCallDuration
                                averageBillableCallDuration
                                averageEfficiencyPerDay
                                averageTimeBetweenCalls
                                averageActionItemTime
                            }
                            highLevelStatsForDays {
                                dateInDay
                                highLevelStats {
                                    numCalls
                                    actualCallDuration
                                    billableCallDuration
                                    averageActualCallDuration
                                    averageBillableCallDuration
                                    averageEfficiencyPerDay
                                    averageTimeBetweenCalls
                                    averageActionItemTime
                                }
                            }
                        }
                    }
                }
            `, {
            teamId,
            startDate,
            endDate,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        const highLevelStatses = gqlResult.value.data.highLevelStatses;
        const teamHighLevelStatses = {
            teamId,
            highLevelStatses,
        };
        return {
            succeeded: true,
            value: teamHighLevelStatses,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchTeamHighLevelStatses', error);
    }
}
async function fetchTeam(teamId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($teamId: Int!) {
                    getTeams(id: $teamId) {
                        id
                        members {
                            id
                            first_name
                            last_name
                        }
                    }
                }
            `, {
            teamId,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        const team = gqlResult.value.data.getTeams;
        return {
            succeeded: true,
            value: team,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchTeam', error);
    }
}
