"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../../services/utilities");
const mutations_1 = require("../../jo-company-details/mutations");
require("../../jo-input");
const helper_utils_1 = require("./helper-utils");
const queries_and_mutations_1 = require("./queries-and-mutations");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
const is_defined_1 = require("../../../../utilities/is-defined");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    companyId: 'NOT_SET',
    company: 'NOT_SET',
    createdAtSelectedValue: '',
    canceledAtDateSelectedValue: '',
    canceledSelectedValue: false,
    invoiceEmailInputValue: '',
    onHoldSelectedValue: false,
    stripeIdInputValue: '',
    saving: false,
    isMobileDevice: false,
};
class JOBillingPaymentInfo extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        this.handleFetchAndSetBillingPaymentInfo();
    }
    async handleFetchAndSetBillingPaymentInfo() {
        if ((0, utilities_1.isInAuthRole)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], this.store.authenticatedUser)) {
            await this.fetchAndSetBillingPaymentInfo();
        }
    }
    async fetchAndSetBillingPaymentInfo() {
        if (this.store.companyId === 'NOT_SET') {
            return;
        }
        const companyResult = await (0, queries_and_mutations_1.fetchCompanyForPaymentInfo)(this.store.companyId);
        (0, utilities_1.assertSucceeded)(companyResult, utilities_1.handleError);
        this.store.company = companyResult.value;
        this.handleSetInitialInputValues(companyResult.value);
    }
    handleSetInitialInputValues(company) {
        this.store.canceledSelectedValue = company.canceled;
        this.store.invoiceEmailInputValue = company.invoice_email || '';
        this.store.onHoldSelectedValue = company.on_hold;
        this.store.stripeIdInputValue = company.credit_card_key ?? '';
        this.store.createdAtSelectedValue = company.created_at || '';
        this.store.canceledAtDateSelectedValue = company.canceled_at || '';
    }
    async handleSaveButtonClick() {
        const shouldContinue = await this.getShouldContinueWithSave();
        if (!shouldContinue) {
            return;
        }
        await this.handleUpdatePaymentInfo();
    }
    async getShouldContinueWithSave() {
        (0, utilities_1.assertIsSet)(this.store.company, utilities_1.handleError, 'this.store.company');
        if (!(0, helper_utils_1.hasStripeIdInputChanged)(this.store.company, this.store.stripeIdInputValue)) {
            return true;
        }
        const userConfirmedShoulContinue = await (0, utilities_1.joConfirm)('NOTICE', 'You have changed the Stripe Id. Are you sure you want to continue?');
        return userConfirmedShoulContinue;
    }
    async handleUpdatePaymentInfo() {
        this.store.saving = true;
        await this.updatePaymentInfo();
        (0, utilities_1.joAlert)('Success', 'Payment info updated!');
        this.store.saving = false;
    }
    async updatePaymentInfo() {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        const updatePaymentInfoResult = await (0, queries_and_mutations_1.updateCompanyPaymentInfo)({
            id: this.store.companyId,
            canceled: this.store.canceledSelectedValue,
            invoice_email: this.store.invoiceEmailInputValue,
            on_hold: this.store.onHoldSelectedValue,
            credit_card_key: this.store.stripeIdInputValue,
            created_at: this.store.createdAtSelectedValue,
            canceled_at: !this.store.canceledAtDateSelectedValue
                ? undefined
                : this.store.canceledAtDateSelectedValue,
        });
        (0, utilities_1.assertSucceeded)(updatePaymentInfoResult, utilities_1.handleError);
    }
    getActivationFee() {
        if (this.store.company !== 'NOT_SET' && this.store.company.activation_fee) {
            return (0, utilities_1.convertCentsToUSDollarsString)(this.store.company.activation_fee);
        }
        return 'Unknown';
    }
    async handleCancelCompanyButtonClick() {
        const response = await (0, utilities_1.joConfirm)('Are you sure?', 'Are you sure you want to cancel this company?');
        if (response === false) {
            return;
        }
        if (this.store.company === 'NOT_SET') {
            return;
        }
        const result = await (0, mutations_1.cancelCompany)(this.store.company.id);
        (0, utilities_1.assertSucceeded)(result, utilities_1.handleError, undefined, 'jo-company-billing-info -> cancelCompany()');
        window.location.reload();
        (0, utilities_1.joAlert)('Company Canceled', 'Company has successfully been canceled.');
    }
    shouldHideCancelCompanyButton() {
        if (this.store.company === 'NOT_SET') {
            return false;
        }
        return this.store.company.canceled;
    }
    async handleOptOutOfCrewCal() {
        const shouldContinue = await (0, utilities_1.joConfirm)('Notice', 'Are you sure you want to opt out of CrewCal?');
        if (shouldContinue === false) {
            return;
        }
        (0, utilities_1.raiseCustomEvent)(this, 'optOutOfCrewCal');
    }
    shouldHideCancelCompanyTimeStamp() {
        if (this.store.company === 'NOT_SET') {
            return false;
        }
        return !this.store.company.canceled;
    }
    getHtmlDatePicker(type) {
        return (0, lit_html_1.html) `
            <style>
                .jo-billing-payment-info--created-at-date-picker {
                    display: flex;
                    flex-direction: column;
                }
            </style>

            <div class="jo-billing-payment-info--created-at-date-picker">
                <label for="companyCreatedAtDatePicker">Select Date:</label>
                <input
                    id="companyCreatedAtDatePicker"
                    type="date"
                    @change=${(inputEvent) => {
            this.updateDateValuesInState(inputEvent.target, type);
        }}
                    .value=${(0, utilities_1.formatISODateForFileName)(type === 'createdAt'
            ? this.store.createdAtSelectedValue
            : this.store.canceledAtDateSelectedValue)}
                />
            </div>
        `;
    }
    updateDateValuesInState(inputElement, type) {
        /**
         * We need the input value to be parsed in the current users time zone not UTC so we added
         * the T12:00 here. This forces the date constructor to treat it as a local time.
         */
        const date = new Date(inputElement.value + 'T12:00').toISOString();
        if (type === 'createdAt')
            this.store.createdAtSelectedValue = date;
        else
            this.store.canceledAtDateSelectedValue = date;
    }
    async uncancelCompanyClicked() {
        (0, utilities_1.assertIsSet)(this.store.company);
        await (0, queries_and_mutations_1.uncancelCompany)(this.store.company.id);
        await (0, utilities_1.joAlert)('Success', 'Company has been uncanceled');
        await this.fetchAndSetBillingPaymentInfo();
    }
    render(state) {
        if (!(0, utilities_1.isInAuthRole)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser)) {
            return (0, lit_html_1.html) ``;
        }
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .jo-billing-payment-info--main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .jo-billing-payment-info--info-container {
                    width: 100%;
                    padding-top: 2rem;
                    ${state.isMobileDevice
            ? `
                        display: flex;
                        flex-direction: column;
                    `
            : null};
                }

                .jo-billing-payment-info--row {
                    display: flex;
                    justify-content: space-between;
                    ${state.isMobileDevice
            ? `
                        flex-direction: column;
                        margin: 0.5rem;
                    `
            : null};
                }

                .jo-billing-payment-info--row div > div {
                    width: 100%;
                }

                .jo-billing-payment-info--input-container {
                    width: ${state.isMobileDevice ? `100%` : `45%`};
                    margin: 0.75rem 0;
                }
                .jo-activation-fee{
                    font-weight:bold;
                    margin-top:2rem;
                    color: var(--jo-text-default)
                 }

                 .canceled-since-text-container {
                    color: var(--jo-danger);
                    font-weight: bold;
                    font-size: 1.1rem;
                    margin-bottom:2rem;
                }
                .jo-global--align-self-end {
                    margin-bottom:.75rem;
                    margin-top:2rem;
                }
                .jo-hold-checkbox{
                    margin-bottom: 2rem;
                    margin-top:.75rem;
                }
                .customer-since-text-container{
                    font-weight:bold;
                    color: var(--jo-text-default);
                    margin-top:.75rem;
                    display: flex;
                    flex-direction: row;
                    gap: 1rem;
                }
            </style>

            <div class="jo-billing-payment-info--main-container">
                <div ?hidden=${state.isMobileDevice} class="jo-global--card-title">
                    PAYMENT INFO
                </div>
                <div class="jo-activation-fee">Activation Fee: ${this.getActivationFee()}</div>

                <div class="jo-billing-payment-info--info-container">
                    <div class="jo-billing-payment-info--row">
                        <div class="jo-billing-payment-info--input-container">
                            <jo-input
                                .type=${'TEXT_INPUT'}
                                .label=${'Stripe Id'}
                                .inputValue=${state.stripeIdInputValue}
                                @inputchange=${(e) => (this.store.stripeIdInputValue = e.detail.inputValue)}
                            ></jo-input>
                        </div>

                        <div class="jo-billing-payment-info--input-container">
                            <jo-input
                                .type=${'EMAIL'}
                                .label=${'Invoice Email'}
                                .inputValue=${state.invoiceEmailInputValue}
                                @inputchange=${(e) => (this.store.invoiceEmailInputValue = e.detail.inputValue)}
                            ></jo-input>
                        </div>
                    </div>

                    <div class="jo-activation-fee">Activation Fee: ${this.getActivationFee()}</div>
                    <br />
                    <br />

                    <div
                        class="customer-since-text-container"
                        ?hidden=${isCompanyCanceled(state.company) === false}
                    >
                        <div class="canceled-since-text-container">
                            Canceled Since:
                            ${state.canceledAtDateSelectedValue !== ''
            ? (0, utilities_1.formatISODateForFileName)(this.store.canceledAtDateSelectedValue)
            : 'Error'}
                        </div>

                        <jo-button
                            .type=${'ICON'}
                            .icon=${'edit'}
                            @click=${async () => {
            await (0, utilities_1.joPopUp)({
                title: 'Select New Date',
                htmlComponents: this.getHtmlDatePicker('canceledAt'),
            });
        }}
                        ></jo-button>

                        <jo-button
                            .text=${'Un-cancel Company'}
                            .type=${'DELETE_BUTTON_1'}
                            @joButtonClick=${async () => {
            await this.uncancelCompanyClicked();
        }}
                        ></jo-button>
                    </div>

                    <div class="customer-since-text-container">
                        Customer Since:
                        ${state.createdAtSelectedValue !== ''
            ? (0, utilities_1.formatISODateForFileName)(this.store.createdAtSelectedValue)
            : 'Error'}

                        <jo-button
                            .type=${'ICON'}
                            .icon=${'edit'}
                            @click=${async () => {
            await (0, utilities_1.joPopUp)({
                title: 'Select New Date',
                htmlComponents: this.getHtmlDatePicker('createdAt'),
            });
        }}
                        ></jo-button>
                    </div>

                    <div class="jo-billing-payment-info--row">
                        <div class="jo-global--align-self-end">
                            <jo-button
                                .type=${'DELETE_BUTTON_1'}
                                .text=${'Cancel Company'}
                                ?hidden=${this.shouldHideCancelCompanyButton()}
                                @joButtonClick=${() => this.handleCancelCompanyButtonClick()}
                            ></jo-button>
                        </div>
                    </div>

                    <div class="jo-hold-checkbox">
                        <jo-input
                            .type=${'CHECKBOX'}
                            .label=${'On Hold'}
                            .checkedValue=${state.onHoldSelectedValue}
                            @inputchange=${(e) => (this.store.onHoldSelectedValue = e.detail.checkedValue)}
                        ></jo-input>
                    </div>

                    <div class="jo-billing-payment-info--row">
                        <jo-button
                            .type=${'ACTION_BUTTON_1'}
                            .text=${state.saving ? 'Saving...' : 'Save'}
                            .inlineStyle=${'font-size: 0.9rem; padding: 0.5rem 0.75rem'}
                            .disabled=${state.saving === true}
                            @joButtonClick=${() => this.handleSaveButtonClick()}
                        ></jo-button>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-billing-payment-info', JOBillingPaymentInfo);
function isCompanyCanceled(company) {
    return company !== 'NOT_SET' && (0, is_defined_1.isDefined)(company.canceled_at);
}
