"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoQueueStatusBox = exports.QueueStatusBoxStatNamesEnum = void 0;
const element_vir_1 = require("element-vir");
const queue_status_priority_level_crud_operations_1 = require("../../main-pages/settings-pages/queue-status-box-settings-page/queue-status-priority-level-crud-operations");
const helper_function_queue_status_box_1 = require("./helper-function-queue-status-box");
var QueueStatusBoxStatNamesEnum;
(function (QueueStatusBoxStatNamesEnum) {
    QueueStatusBoxStatNamesEnum["TOTAL_IN_QUEUE"] = "Total In Queue";
    QueueStatusBoxStatNamesEnum["HIGHEST_POD_COUNT"] = "Highest Pod Count";
    QueueStatusBoxStatNamesEnum["WAITING"] = "Waiting";
    QueueStatusBoxStatNamesEnum["CALLBACKS"] = "Callbacks";
    QueueStatusBoxStatNamesEnum["ACTIVE_CHATS"] = "Active Chats";
    QueueStatusBoxStatNamesEnum["UNASSIGNED_CHATS"] = "Unassigned Chats";
    QueueStatusBoxStatNamesEnum["ASSIGNED_TO_ME"] = "Assigned To Me";
})(QueueStatusBoxStatNamesEnum = exports.QueueStatusBoxStatNamesEnum || (exports.QueueStatusBoxStatNamesEnum = {}));
exports.JoQueueStatusBox = (0, element_vir_1.defineElement)()({
    tagName: 'jo-queue-status-box',
    styles: (0, element_vir_1.css) `
        .status-box {
            cursor: help;
            padding: 0.3rem 0.6rem 0.3rem 1rem;
            box-sizing: border-box;
            border-radius: 1rem;
            font-size: 0.9rem;
            display: flex;
        }

        .clock-icon {
            position: relative;
            top: 1rem;
        }

        @keyframes LOW-BLINK {
            80% {
                opacity: 0.5;
            }
        }

        @keyframes HIGH-BLINK {
            50% {
                opacity: 0;
            }
        }
    `,
    stateInit: {
        queuePriorityLevels: (0, element_vir_1.asyncState)(),
    },
    renderCallback({ state, inputs, updateState }) {
        updateState({
            queuePriorityLevels: {
                createPromise: async () => {
                    if (inputs.priorityLevelOverrides)
                        return inputs.priorityLevelOverrides;
                    return await (0, queue_status_priority_level_crud_operations_1.fetchPriorityLevels)(inputs.type);
                },
                trigger: [
                    inputs.priorityLevelOverrides,
                    inputs.type,
                ],
            },
        });
        return (0, element_vir_1.html) `
            ${(0, element_vir_1.renderAsyncState)(state.queuePriorityLevels, 'Loading...', queuePriorityLevels => {
            return (0, element_vir_1.html) `
                    <link rel="stylesheet" href="/styles/jo-styles.css" />
                    <link
                        href="https://fonts.googleapis.com/icon?family=Material+Icons"
                        rel="stylesheet"
                    />

                    <div
                        class="status-box"
                        style=${(0, helper_function_queue_status_box_1.getCurrentPriorityLevelStyle)(inputs.type, queuePriorityLevels, inputs.queueStatItems)}
                        title=${inputs.containerTitle}
                    >
                        <div>
                            ${inputs.queueStatItems.map(statItem => {
                if (statItem.name !== QueueStatusBoxStatNamesEnum.HIGHEST_POD_COUNT)
                    return (0, element_vir_1.html) `
                                        <div>${statItem.name}: ${statItem.value}</div>
                                    `;
            })}
                        </div>

                        ${(0, element_vir_1.renderIf)(inputs.renderClockIconIf, (0, element_vir_1.html) `
                                <div class="material-icons clock-icon">alarm</div>
                            `)}
                    </div>
                `;
        })}
        `;
    },
});
