"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchDeadIncomingQueueItems = void 0;
const helper_utils_1 = require("../helper-utils");
const queryLimit = 10000;
async function fetchDeadIncomingQueueItems({ incomingCallType, totalNumberOfIncomingQueueItemsForInterval, execute, startDate, endDate, podId, }) {
    const incomingCallTypes = (0, helper_utils_1.getIncomingCallTypesAsArray)(incomingCallType);
    const incomingQueueItems = [];
    for (let i = 0; i < totalNumberOfIncomingQueueItemsForInterval; i += queryLimit) {
        const sectionOfIncomingQueueItems = await fetchSectionOfIncomingQueueItems({
            execute,
            startDate,
            endDate,
            incomingCallTypes,
            limit: queryLimit,
            offset: i,
            podId,
        });
        if (sectionOfIncomingQueueItems == null)
            throw new Error('sectionOfIncomingQueueItems is null');
        for (const incomingQueueItem of sectionOfIncomingQueueItems) {
            incomingQueueItems.push(incomingQueueItem);
        }
    }
    return incomingQueueItems;
}
exports.fetchDeadIncomingQueueItems = fetchDeadIncomingQueueItems;
async function fetchSectionOfIncomingQueueItems({ execute, startDate, endDate, incomingCallTypes, limit, offset, podId, }) {
    const queryName = podId
        ? 'fetchIncomingQueueForIntervalSpecificPod'
        : 'fetchIncomingQueueForIntervalAllPods';
    const query = makeQueryToFetchIncomingQueueItems(podId, queryName);
    const gqlResult = await execute(query, {
        startDate,
        endDate,
        incomingCallTypes,
        limit,
        offset,
        podId,
    });
    return gqlResult.data[queryName];
}
function makeQueryToFetchIncomingQueueItems(podId, queryName) {
    const query = `
        query (
            $startDate: DateTime!
            $endDate: DateTime!
            $incomingCallTypes: [INCOMING_QUEUE_REPORT_CALL_TYPE!]!
            $limit: Int!
            $offset: Int!
            ${podId ? '$podId: Int!' : ''}
        ) {
            ${queryName}(
                startDate: $startDate
                endDate: $endDate
                incomingCallTypes: $incomingCallTypes
                limit: $limit
                offset: $offset
                ${podId ? 'podId: $podId' : ''}
            ) {
                created_at
                handled_at
                pod {
                    name
                }
                call {
                    id
                    origin_phone
                    destination_phone
                    duration
                    billable_duration
                }
            }
        }
    `;
    return query;
}
