"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateUnusedMilliseconds = void 0;
const dates_and_times_1 = require("../../dates-and-times/dates-and-times");
function calculateUnusedMilliseconds(billableDurationForIntervalInMilliseconds, numMinutesIncludedInPackage) {
    const millisecondsIncludedInPackage = numMinutesIncludedInPackage * dates_and_times_1.oneMinuteInMilliseconds;
    const rawUnUsedMilliseconds = millisecondsIncludedInPackage - billableDurationForIntervalInMilliseconds;
    return Math.max(rawUnUsedMilliseconds, 0);
}
exports.calculateUnusedMilliseconds = calculateUnusedMilliseconds;
