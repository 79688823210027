"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../../services/utilities");
const fetch_personal_admin_1 = require("../../../../utilities/action-items/fetch-personal-admin");
const get_default_contact_input_values_1 = require("../../../../utilities/contacts/get-default-contact-input-values");
const user_status_event_mutations_1 = require("../../../../utilities/logging-and-monitoring/user-status-event-mutations");
require("../../jo-chat/jo-chat-transcript/jo-chat-transcript");
require("../../jo-contact/jo-contact");
require("../jo-customer-contact-search");
const css_1 = require("./css");
const helper_utils_1 = require("./helper-utils");
const mutations_1 = require("./mutations");
const queries_1 = require("./queries");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
const test_id_directive_1 = require("../../../directives/test-id.directive");
const InitialState = {
    actionItem: 'NOT_SET',
    actionItemId: 'NOT_SET',
    actionItemMode: 'NOT_SET',
    authenticatedUser: 'NOT_SET',
    companyId: 'NOT_SET',
    contact: Symbol('NOT_SET'),
    contactId: 'NOT_SET',
    contactInputValues: (0, get_default_contact_input_values_1.getDefaultContactInputValues)(),
    contactMode: 'NOT_SET',
    internalNotes: '',
    isMobileDevice: false,
    loading: true,
    newNoteText: '',
    personalAdmin: null,
    title: '',
};
class JOCustomerActionItem extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        if (this.store.actionItemId != 'NOT_SET' && this.store.actionItemMode === 'EDIT') {
            this.handleFetchAndSetActionItem(this.store.actionItemId);
            this.fetchAndSetPersonalAdmin();
            this.store.contactMode = 'EXISTING_CONTACT';
        }
        if (this.store.actionItemId === 'NOT_SET' && this.store.actionItemMode === 'CREATE') {
            this.fetchAndSetPersonalAdmin();
            this.resetStateFields();
        }
    }
    async handleFetchAndSetActionItem(actionItemId) {
        this.store.loading = true;
        await this.fetchAndSetActionItemAndContact(actionItemId);
        this.store.loading = false;
    }
    async fetchAndSetActionItemAndContact(actionItemId) {
        const actionItemResult = await (0, queries_1.fetchActionItem)(actionItemId);
        (0, utilities_1.assertSucceeded)(actionItemResult, utilities_1.handleError);
        this.store.actionItem = actionItemResult.value;
        this.store.internalNotes = actionItemResult.value.company_internal_notes ?? '';
        this.getAndSetContactFromActionItem(this.store.actionItem);
    }
    getAndSetContactFromActionItem(actionItem) {
        if (actionItem.contact) {
            const contact = (0, helper_utils_1.getContactFromActionItem)(actionItem);
            this.setContactInState(contact);
        }
    }
    async fetchAndSetPersonalAdmin() {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        const result = await (0, fetch_personal_admin_1.fetchPersonalAdmin)(this.store.companyId);
        (0, utilities_1.assertSucceeded)(result, utilities_1.handleError);
        this.store.personalAdmin = result.value;
    }
    getJoContactComponentDisplayMode() {
        return 'CONTACT';
        //Keep this until readonly is implemented.
        // if(this.store.contactId === 'NOT_SET'){
        //     return 'CONTACT';
        // }
        // return 'CONTACT_READ_ONLY';
    }
    handleContactSelected(selectedContact) {
        this.setContactInState(selectedContact);
        this.store.contactMode = 'EXISTING_CONTACT';
    }
    async handleUpsertContact() {
        (0, utilities_1.assertIsSet)(this.store.contactInputValues, utilities_1.handleError, 'this.store.contactInputValues');
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        const saveContactResult = await (0, mutations_1.upsertContact)(this.store.contactInputValues, this.store.companyId);
        (0, utilities_1.assertSucceeded)(saveContactResult, utilities_1.handleError);
        this.store.contactId = saveContactResult.value;
    }
    async handleUpsertActionItem(whoToSendTo) {
        const upsertActionItemInput = (0, mutations_1.makeUpsertActionItemInput)(this.store.getState(), whoToSendTo);
        const upsertActionItemResult = await (0, mutations_1.upsertActionItem)(upsertActionItemInput);
        (0, utilities_1.assertSucceeded)(upsertActionItemResult, utilities_1.handleError);
        this.store.actionItemId = upsertActionItemResult.value;
    }
    doActionItemNotesExist() {
        if (this.store.actionItem != 'NOT_SET') {
            return this.store.actionItem.action_item_notes.length > 0 ? true : false;
        }
        return false;
    }
    setContactInState(contact) {
        this.store.contact = contact;
        this.store.contactId = contact.id;
    }
    isContactSet() {
        if (this.store.contact.toString() === 'Symbol(NOT_SET)') {
            return false;
        }
        return true;
    }
    resetStateFields() {
        this.store.contact = Symbol('NOT_SET');
        this.store.actionItem = 'NOT_SET';
        this.store.contactId = 'NOT_SET';
        this.store.contactInputValues = (0, get_default_contact_input_values_1.getDefaultContactInputValues)();
        this.store.newNoteText = '';
        this.store.contactMode = 'NOT_SET';
        this.store.actionItemMode = 'CREATE';
    }
    async createUserStatusEventsForActionItem(whoToSendTo, actionItemId, actionItemMode) {
        if (this.store.companyId === 'NOT_SET' ||
            this.store.actionItemId === 'NOT_SET' ||
            actionItemId === 'NOT_SET') {
            return;
        }
        await (0, user_status_event_mutations_1.createUserStatusEvent)({
            userId: this.store.authenticatedUser.id,
            eventType: (0, helper_utils_1.getEventType)(whoToSendTo, actionItemMode),
            companyId: this.store.companyId,
            actionItemId: actionItemId,
        });
    }
    async updateCompanyInternalNotes() {
        if (this.store.actionItemId !== 'NOT_SET' && this.store.companyId !== 'NOT_SET') {
            const updateInternalNotesResult = await (0, mutations_1.updateCompanyInternalNotesOnActionItem)(this.store.actionItemId, this.store.internalNotes, this.store.companyId);
            (0, utilities_1.assertSucceeded)(updateInternalNotesResult, utilities_1.handleError);
            if (updateInternalNotesResult.succeeded === true)
                (0, utilities_1.joAlert)('Success', 'Internal notes successfully saved.');
        }
    }
    async resetChangesToCompanyInternalNotes() {
        if (this.store.actionItemId !== 'NOT_SET')
            await this.handleFetchAndSetActionItem(this.store.actionItemId);
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

            ${(0, css_1.getStyles)(state)}

            <div class="main-container">
                <div class="jo-customer-action-item--col">
                    <div class="jo-customer-action-item--container jo-global--card">
                        <div ?hidden=${(0, helper_utils_1.shouldHideSearchComponents)(state)}>
                            <div class="jo-customer-action-item--container-title">Customer</div>

                            <div class="jo-customer-action-item--search-bar">
                                <jo-customer-contact-search
                                    .props=${{
            companyId: this.store.companyId,
        }}
                                    @contactSelected=${(e) => {
            this.handleContactSelected(e.detail);
        }}
                                ></jo-customer-contact-search>
                            </div>

                            <div
                                class="jo-customer-action-item--new-customer-link"
                                @click=${(e) => {
            this.newCustomerClicked();
        }}
                            >
                                + New Customer
                            </div>
                        </div>

                        <div
                            class="jo-customer-action-item--contact-container"
                            ?hidden=${state.contactMode === 'NOT_SET'}
                        >
                            <jo-button
                                class="jo-customer-action-item--search-icon"
                                ?hidden=${state.actionItemMode === 'EDIT'}
                                .type=${'ICON'}
                                .icon=${'close'}
                                @joButtonClick=${() => {
            this.resetStateFields();
        }}
                            ></jo-button>

                            <div class="jo-customer-action-item--container-title">Contact</div>

                            <jo-contact
                                .props=${{
            mode: this.getJoContactComponentDisplayMode(),
            contact: state.contact,
            useEmailVerifier: false,
            authenticatedUser: state.authenticatedUser,
            serviceAreaDescriptionAppear: false,
            isMobileDevice: state.isMobileDevice,
        }}
                                @inputvalueschanged=${(e) => (this.store.contactInputValues = e.detail)}
                                @contactchanged=${(e) => (this.store.contact = e.detail)}
                            ></jo-contact>
                        </div>
                    </div>

                    <div class="jo-customer-action-item--message-containers-container">
                        <div
                            class="jo-customer-action-item--container jo-global--card jo-customer-action-item--col"
                            ?hidden=${state.contactMode === 'NOT_SET'}
                        >
                            <div class="jo-customer-action-item--top-action-buttons-container">
                                <div
                                    class="jo-customer-action-item--handle-action-item-buttons-container"
                                    ?hidden=${state.actionItemMode != 'EDIT'}
                                >
                                    <jo-button
                                        .type=${'ACTION_BUTTON_2'}
                                        .text=${'Close Action Item'}
                                        @joButtonClick=${() => (0, helper_utils_1.closeActionItemAndNavigateBack)(state)}
                                    ></jo-button>

                                    <jo-button
                                        .type=${'ACTION_BUTTON_2'}
                                        .text=${'Mark As Unread'}
                                        @joButtonClick=${() => (0, helper_utils_1.markAIAsUnreadAndNavigateBack)(state)}
                                    ></jo-button>
                                </div>

                                <h3
                                    ?hidden=${!(0, helper_utils_1.isInEditActionItemMode)(state.actionItemMode)}
                                    class="
                                        jo-customer-action-item--container-title 
                                        jo-cutomer-action-item--title-center
                                    "
                                >
                                    ${this.store.actionItem?.title}
                                </h3>

                                <div class="jo-customer-action-item--container-title">
                                    Send Message to Jill
                                </div>
                            </div>

                            <jo-input
                                ?hidden=${(0, helper_utils_1.isInEditActionItemMode)(state.actionItemMode)}
                                class="jo-customer-action-item--title"
                                .label=${'Title:'}
                                .type=${'SELECT'}
                                .selectOptions=${[
            'FYI',
            'Hooray, New Appointment!',
            'New Lead!',
            'Other',
            'Urgent, Needs Immediate Attention!',
        ]}
                                @inputchange=${(e) => (this.store.title = e.detail.selectedValue)}
                            ></jo-input>

                            <jo-input
                                class="jo-customer-action-item--textarea"
                                .placeholder=${'New Message...'}
                                .type=${'TEXT_AREA'}
                                .inputValue=${state.newNoteText}
                                @inputchange=${(e) => (this.store.newNoteText = e.detail.inputValue)}
                            ></jo-input>

                            <div
                                class="jo-customer-action-item--send-action-item-buttons-container"
                            >
                                <div
                                    class="jo-customer-action-item--send-action-item-button-container"
                                >
                                    <jo-button
                                        ?hidden=${state.personalAdmin === null ||
            state.personalAdmin === undefined}
                                        .type=${'ACTION_BUTTON_1'}
                                        .text=${'Send to Personal Admin'}
                                        @joButtonClick=${() => this.handleSendActionItemClicked('PERSONAL_ADMIN')}
                                    ></jo-button>
                                </div>

                                <div
                                    class="jo-customer-action-item--send-action-item-button-container"
                                >
                                    <jo-button
                                        ${(0, test_id_directive_1.testId)('jo-send-to-jill-button')}
                                        .type=${'ACTION_BUTTON_1'}
                                        .text=${'Send to Jill'}
                                        @joButtonClick=${() => this.handleSendActionItemClicked('JILL')}
                                    ></jo-button>
                                </div>
                            </div>
                        </div>

                        <div
                            class="jo-customer-action-item--container jo-global--card jo-customer-action-item--col"
                            ?hidden=${state.actionItemMode !== 'EDIT'}
                        >
                            <div class="jo-customer-action-item--container-title">
                                Internal Notes
                                <div class="jo-internal-notes-message">
                                    (Not Viewable to Jill's)
                                </div>
                            </div>
                            <div class="jo-customer-action-item--internal-notes">
                                <jo-input
                                    .type=${'TEXT_AREA'}
                                    .label=${'Notes'}
                                    .rows=${8}
                                    .inputValue=${this.store.internalNotes}
                                    @valueChanged=${(e) => {
            this.store.internalNotes = e.detail.inputValue;
        }}
                                ></jo-input>

                                <div class="jo-customer-action-item--internal-notes-buttons">
                                    <jo-button
                                        .type=${'ACTION_BUTTON_1'}
                                        .text=${'Save'}
                                        @joButtonClick=${() => {
            this.updateCompanyInternalNotes();
        }}
                                    ></jo-button>

                                    <jo-button
                                        class="jo-customer-action-item--internal-note-cancel-button"
                                        .type=${'SECONDARY_BUTTON_1'}
                                        .text=${'Cancel'}
                                        @joButtonClick=${() => {
            this.resetChangesToCompanyInternalNotes();
        }}
                                    ></jo-button>
                                </div>
                            </div>
                        </div>

                        <div
                            class="jo-customer-action-item--container jo-global--card jo-customer-action-item--col"
                            ?hidden=${state.actionItemMode != 'EDIT'}
                        >
                            <div class="jo-customer-action-item--container-title">Messages</div>
                            <div class="jo-customer-action-item--container-messages">
                                ${this.store.actionItem.chat
            ? (0, lit_html_1.html) `
                                          <div
                                              class="jo-customer-action-item--chat-transcript-container"
                                          >
                                              <jo-chat-transcript
                                                  .props=${{
                authenticatedUser: this.store.authenticatedUser,
                actionItemContact: this.store.contact,
                chat: this.store.actionItem.chat,
                openButtonType: 'ACTION_BUTTON_1',
                displayButtonOnRight: true,
            }}
                                              ></jo-chat-transcript>
                                          </div>
                                      `
            : ``}
                                <div class="action-item-container">
                                    <div class="action-item">
                                        <jo-action-item-messages
                                            .props=${{
            authenticatedUser: this.store.authenticatedUser,
            isMobileDevice: this.store.isMobileDevice,
            actionItem: state.actionItem,
        }}
                                        ></jo-action-item-messages>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="margin:2rem;"></div>
                </div>
            </div>
        `;
    }
    async handleSendActionItemClicked(whoToSendTo) {
        if (this.store.actionItemMode === 'CREATE') {
            this.store.actionItemId = -1;
        }
        if (this.store.actionItemMode === 'EDIT') {
            this.store.title = this.store.actionItem.title;
        }
        await this.handleUpsertContact();
        await this.handleUpsertActionItem(whoToSendTo);
        this.createUserStatusEventsForActionItem(whoToSendTo, this.store.actionItemId, this.store.actionItemMode);
        this.resetStateFields();
        (0, utilities_1.navigate)('../customer/action-items');
    }
    newCustomerClicked() {
        this.resetStateFields();
        this.store.contactMode = 'NEW_CONTACT';
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-customer-action-item', JOCustomerActionItem);
