"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
require("../jo-button.element");
require("../jo-input");
require("./jo-scheduled-down-time");
const mutations_1 = require("./mutations");
const queries_1 = require("./queries");
const scheduled_down_time_adapter_1 = require("./scheduled-down-time-adapter");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    scheduledDownTimes: [],
    addingNewDownTime: false,
};
class JOScheduledDownTimes extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        if (this.store.scheduledDownTimes.length === 0) {
            this.fetchAndSetScheduledDownTimes();
        }
    }
    async fetchAndSetScheduledDownTimes() {
        const result = await (0, queries_1.fetchScheduledDownTimes)();
        (0, utilities_1.assertSucceeded)(result);
        this.store.scheduledDownTimes = result.value.map(downTime => new scheduled_down_time_adapter_1.ScheduledDownTimeAdapter(downTime));
    }
    addNewDownTimeClicked() {
        this.store.addingNewDownTime = true;
        this.store.scheduledDownTimes = [
            new scheduled_down_time_adapter_1.ScheduledDownTimeAdapter(),
            ...this.store.scheduledDownTimes,
        ];
    }
    cancelNewDownTimeClicked(e) {
        this.store.scheduledDownTimes = this.store.scheduledDownTimes.filter(downTime => downTime.id !== e.detail.id);
        this.store.addingNewDownTime = false;
    }
    async saveDownTimeClicked(e) {
        await this.createOrUpdateDownTime(e);
        (0, utilities_1.joAlert)('Success', 'Scheduled down time saved successfully');
        await this.fetchAndSetScheduledDownTimes();
        this.store.addingNewDownTime = false;
    }
    async createOrUpdateDownTime(e) {
        const { id, startTime, endTime, mode } = e.detail;
        if (mode === 'NEW') {
            const result = await (0, mutations_1.createScheduledDownTime)(startTime, endTime);
            (0, utilities_1.assertSucceeded)(result);
        }
        else {
            const result = await (0, mutations_1.updateScheduledDownTime)(id, startTime, endTime);
            (0, utilities_1.assertSucceeded)(result);
        }
    }
    async deleteDownTimeClicked(e) {
        const result = await (0, mutations_1.deleteScheduledDownTime)(e.detail.id);
        (0, utilities_1.assertSucceeded)(result);
        (0, utilities_1.joAlert)('Deleted', 'Scheduled down time deleted');
        this.fetchAndSetScheduledDownTimes();
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .section {
                    padding-top: 1rem;
                }

                .down-time-container {
                    padding-top: 1.5rem;
                }
            </style>

            <div class="main-container jo-global--card">
                <section>
                    <h1>Scheduled Down Times</h1>
                </section>

                <section class="section">
                    <jo-button
                        ?hidden=${state.addingNewDownTime}
                        .type=${'ICON'}
                        .text=${'add'}
                        @joButtonClick=${() => {
            this.addNewDownTimeClicked();
        }}
                    ></jo-button>
                </section>

                <section class="section">
                    ${state.scheduledDownTimes.map(downTime => (0, lit_html_1.html) `
                            <div class="down-time-container">
                                <jo-scheduled-down-time
                                    class="down-time"
                                    .props=${{
            startTime: downTime.startTime,
            endTime: downTime.endTime,
            mode: downTime.mode,
            id: downTime.id,
        }}
                                    @SaveDownTimeClicked=${async (e) => {
            await this.saveDownTimeClicked(e);
        }}
                                    @CancelDownTimeClicked=${(e) => {
            this.cancelNewDownTimeClicked(e);
        }}
                                    @DeleteDownTimeClicked=${async (e) => {
            await this.deleteDownTimeClicked(e);
        }}
                                ></jo-scheduled-down-time>
                            </div>
                        `)}
                </section>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-scheduled-down-times', JOScheduledDownTimes);
