"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StateManager = void 0;
const common_1 = require("@augment-vir/common");
class StateManager {
    constructor(initialState, stateChangeCallback = () => { }) {
        this.state = { ...initialState };
        this.stateChangeCallback = stateChangeCallback;
    }
    getState() {
        return { ...this.state };
    }
    updateState(newState) {
        // these as any types are necessary because the state types are not strict enough
        if ((0, common_1.areJsonEqual)(newState, this.state)) {
            return;
        }
        this.state = {
            ...this.state,
            ...newState,
        };
        this.stateChangeCallback(this.state);
    }
}
exports.StateManager = StateManager;
