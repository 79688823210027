"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateCallDuration = exports.filterCallsByDuration = exports.ComparisonOperationEnum = void 0;
const diff_time_1 = require("../../../utilities/dates-and-times/diff-time");
var ComparisonOperationEnum;
(function (ComparisonOperationEnum) {
    ComparisonOperationEnum["LTE"] = "<";
    ComparisonOperationEnum["GTE"] = ">";
})(ComparisonOperationEnum = exports.ComparisonOperationEnum || (exports.ComparisonOperationEnum = {}));
function filterCallsByDuration(relativeDuration, operation, calls) {
    return calls.filter(call => {
        const callDuration = calculateCallDuration(call);
        if (callDuration == undefined) {
            return false;
        }
        if (operation === ComparisonOperationEnum.GTE) {
            return callDuration >= relativeDuration;
        }
        else {
            return callDuration <= relativeDuration;
        }
    });
}
exports.filterCallsByDuration = filterCallsByDuration;
function calculateCallDuration(call) {
    const callStartEvent = findCallDurationEvent(call, 'CALL_START');
    const callEndEvent = findCallDurationEvent(call, 'CALL_END');
    if (!callStartEvent || !callEndEvent) {
        return undefined;
    }
    return (0, diff_time_1.diffTime)(callStartEvent.timestamp, callEndEvent.timestamp);
}
exports.calculateCallDuration = calculateCallDuration;
function findCallDurationEvent(call, eventName) {
    return call.duration_events.find(event => event.description === eventName);
}
