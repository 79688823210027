"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateTotalUsageDiffForCompaniesInMilliseconds = void 0;
const calculate_total_overage_milliseconds_for_company_1 = require("./calculate-total-overage-milliseconds-for-company");
const calculate_total_unused_milliseconds_for_company_1 = require("./calculate-total-unused-milliseconds-for-company");
function calculateTotalUsageDiffForCompaniesInMilliseconds(companies, calculationType) {
    const diffCalculator = getDiffCalculator(calculationType);
    return companies.reduce((total, company) => total + diffCalculator(company), 0);
}
exports.calculateTotalUsageDiffForCompaniesInMilliseconds = calculateTotalUsageDiffForCompaniesInMilliseconds;
function getDiffCalculator(calculcationType) {
    return calculcationType === 'UNUSED'
        ? calculate_total_unused_milliseconds_for_company_1.calculateTotalUnusedMillisecondsForCompany
        : calculate_total_overage_milliseconds_for_company_1.calculateTotalOverageMillisecondsForCompany;
}
