"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateMonthsBetweenDates = void 0;
const milliseconds_between_timestamps_1 = require("./milliseconds-between-timestamps");
const milliSecondsInOneMonth = 2.628e9;
/**
 * @param startDate ISOString
 * @param endDate Optional parameter, if not provided the current date will be used.
 * @returns Number of months between the dates rounded to the nearest whole number.
 */
function calculateMonthsBetweenDates(startDate, endDate) {
    if (!startDate)
        return '0 Months';
    if (!endDate)
        endDate = new Date().toISOString();
    const numbMonths = Math.round((0, milliseconds_between_timestamps_1.millisecondsBetweenTimeStamps)(endDate, startDate) / milliSecondsInOneMonth);
    if (numbMonths === 1) {
        return `${numbMonths} Month`;
    }
    return `${numbMonths} Months`;
}
exports.calculateMonthsBetweenDates = calculateMonthsBetweenDates;
