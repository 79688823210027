"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeActionTextHTML = void 0;
const element_vir_1 = require("element-vir");
const jo_help_ticket_status_element_1 = require("../../jo-account-help-table/jo-help-ticket-status.element");
function makeActionTextHTML(helpTicketUpdate) {
    if (helpTicketUpdate.original === true) {
        return 'Created Ticket';
    }
    return (0, element_vir_1.html) `
        <${jo_help_ticket_status_element_1.JoHelpTicketStatus}
            ${(0, element_vir_1.assign)({
        status: helpTicketUpdate.status,
    })}
        ></${jo_help_ticket_status_element_1.JoHelpTicketStatus}>
    `;
}
exports.makeActionTextHTML = makeActionTextHTML;
