"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../../services/utilities");
require("../../jo-button.element");
require("../../jo-input");
const store_1 = require("../../../../services/store");
const styles_1 = require("./styles");
const helper_utils_1 = require("./helper-utils");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
const sort_by_and_apply_order_number_to_items_1 = require("../../../../utilities/data-structures/lists/sort-by-and-apply-order-number-to-items");
const shift_items_at_order_number_position_1 = require("../../../../utilities/data-structures/lists/shift-items-at-order-number-position");
const InitialState = {
    authenticatedUser: store_1.InitialGlobalState.authenticatedUser,
    company: 'NOT_SET',
    inputValues: {
        customFieldsInputValues: [],
    },
    isMobileDevice: false,
    loading: true,
};
class JOCompanyServicesPricingCustomFields extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, (state, action) => {
            if (action.type === 'RESET_STATE') {
                return {
                    ...InitialState,
                    isMobileDevice: state.isMobileDevice,
                };
            }
            return state;
        });
    }
    set company(company) {
        if (this.store.company === company) {
            return;
        }
        this.store.dispatch({
            type: 'RESET_STATE',
        });
        this.store.company = company;
        if (this.store.company !== 'NOT_SET') {
            this.inputValues = {
                ...this.store.inputValues,
                customFieldsInputValues: this.store.company.custom_fields.map(customField => {
                    return {
                        symbol: Symbol(),
                        id: customField.id,
                        archived: customField.archived,
                        call_screen_appear: customField.call_screen_appear,
                        call_screen_require: customField.call_screen_require,
                        order: customField.order,
                        select_values: customField.select_values.map(selectValue => {
                            return {
                                symbol: Symbol(),
                                id: selectValue.id,
                                archived: selectValue.archived,
                                text_value: selectValue.text_value,
                            };
                        }),
                        select_value_ids_to_disconnect: [],
                        service_ids_to_connect: [
                            ...new Set(customField.services.map(service => service.id)),
                        ],
                        service_ids_to_disconnect: [],
                        text_value: customField.text_value,
                        type: customField.type,
                    };
                }),
            };
        }
    }
    set inputValues(inputValues) {
        this.store.loading = true;
        this.store.inputValues = {
            ...inputValues,
            customFieldsInputValues: (0, sort_by_and_apply_order_number_to_items_1.sortByAndApplyOrderNumberToItems)(inputValues.customFieldsInputValues),
        };
        (0, utilities_1.raiseCustomEvent)(this, 'inputvalueschanged', this.store.inputValues);
        this.store.loading = false;
    }
    addBlankField() {
        this.inputValues = {
            ...this.store.inputValues,
            customFieldsInputValues: [
                ...this.store.inputValues.customFieldsInputValues,
                {
                    symbol: Symbol(),
                    id: 'NOT_SET',
                    archived: false,
                    call_screen_appear: false,
                    call_screen_require: false,
                    order: null,
                    select_values: [],
                    select_value_ids_to_disconnect: [],
                    service_ids_to_connect: [],
                    service_ids_to_disconnect: [],
                    text_value: '',
                    type: 'TEXT',
                },
            ],
        };
    }
    addDropdown() {
        this.inputValues = {
            ...this.store.inputValues,
            customFieldsInputValues: [
                ...this.store.inputValues.customFieldsInputValues,
                {
                    symbol: Symbol(),
                    id: 'NOT_SET',
                    archived: false,
                    call_screen_appear: false,
                    call_screen_require: false,
                    select_values: [],
                    select_value_ids_to_disconnect: [],
                    service_ids_to_connect: [],
                    service_ids_to_disconnect: [],
                    text_value: '',
                    type: 'SELECT',
                },
            ],
        };
    }
    async deleteCustomField(customFieldInputValuesToDelete) {
        const confirmed = await (0, utilities_1.joConfirm)('Confirm', 'Are you sure you want to delete this custom field?');
        if (confirmed === false) {
            return;
        }
        if (customFieldInputValuesToDelete.id === 'NOT_SET') {
            this.inputValues = {
                ...this.store.inputValues,
                customFieldsInputValues: this.store.inputValues.customFieldsInputValues.filter((customFieldInputValues) => {
                    return (customFieldInputValuesToDelete.symbol !== customFieldInputValues.symbol);
                }),
            };
        }
        else {
            this.inputValues = {
                ...this.store.inputValues,
                customFieldsInputValues: this.store.inputValues.customFieldsInputValues.map((customFieldInputValues) => {
                    if (customFieldInputValuesToDelete.symbol === customFieldInputValues.symbol) {
                        return {
                            ...customFieldInputValues,
                            archived: true,
                        };
                    }
                    return customFieldInputValues;
                }),
            };
        }
    }
    async deleteCustomFieldSelectValue(customFieldInputValuesToDelete, selectValueInputValuesToDelete) {
        const confirmed = await (0, utilities_1.joConfirm)('Confirm', 'Are you sure you want to delete this option?');
        if (confirmed === false) {
            return;
        }
        if (selectValueInputValuesToDelete.id === 'NOT_SET') {
            this.inputValues = {
                ...this.store.inputValues,
                customFieldsInputValues: this.store.inputValues.customFieldsInputValues.map((customFieldInputValues) => {
                    if (customFieldInputValuesToDelete.symbol === customFieldInputValues.symbol) {
                        return {
                            ...customFieldInputValues,
                            select_values: customFieldInputValues.select_values.filter((selectValueInputValues) => {
                                return (selectValueInputValuesToDelete.symbol !==
                                    selectValueInputValues.symbol);
                            }),
                        };
                    }
                    else {
                        return customFieldInputValues;
                    }
                }),
            };
        }
        else {
            this.inputValues = {
                ...this.store.inputValues,
                customFieldsInputValues: this.store.inputValues.customFieldsInputValues.map((customFieldInputValues) => {
                    if (customFieldInputValuesToDelete.symbol === customFieldInputValues.symbol) {
                        return {
                            ...customFieldInputValues,
                            select_values: customFieldInputValues.select_values.map((selectValueInputValues) => {
                                if (selectValueInputValuesToDelete.symbol ===
                                    selectValueInputValues.symbol) {
                                    return {
                                        ...selectValueInputValues,
                                        archived: true,
                                    };
                                }
                                else {
                                    return selectValueInputValues;
                                }
                            }),
                        };
                    }
                    else {
                        return customFieldInputValues;
                    }
                }),
            };
        }
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

            ${styles_1.JOCompanyServicesPricingCustomFieldsStyles}

            <style>
                .add-button {
                    padding-right: ${state.isMobileDevice ? `0` : `1rem`};
                }

                .row {
                    display: flex;
                    flex-direction: ${state.isMobileDevice ? `column` : `row`};
                    padding-top: 0.75rem;
                    padding-bottom: 0.75rem;
                }
                .col-1 {
                    flex: 2;
                    margin-right: ${state.isMobileDevice ? `0` : `1rem`};
                }
            </style>

            <div class="main-container">
                <jo-loading
                    .props=${{
            loading: state.loading,
        }}
                ></jo-loading>

                <div ?hidden=${state.isMobileDevice} class="jo-global--card-title">
                    CUSTOM FIELDS
                </div>

                <div
                    class="add-buttons-container"
                    ?hidden=${(0, utilities_1.shouldHideFromCompanyUser)(this.store.authenticatedUser)}
                >
                    <jo-button
                        class="add-button"
                        .type=${'ACTION_BUTTON_2'}
                        .text=${'Add blank field'}
                        @joButtonClick=${() => this.addBlankField()}
                    ></jo-button>

                    <jo-button
                        class="add-button"
                        .type=${'ACTION_BUTTON_2'}
                        .text=${'Add dropdown'}
                        @joButtonClick=${() => this.addDropdown()}
                    ></jo-button>
                    <jo-button
                        class="add-button"
                        .type=${'ACTION_BUTTON_2'}
                        .text=${state.isMobileDevice ? 'Delete all' : 'Delete all custom fields'}
                        @joButtonClick=${() => (0, utilities_1.raiseCustomEvent)(this, 'deleteAllCustomFieldsClicked')}
                    ></jo-button>
                </div>

                <div>
                    ${state.inputValues.customFieldsInputValues.map((customFieldInputValues) => {
            return (0, lit_html_1.html) `
                                <div class="row">
                                    <div class="col-1">
                                        <jo-input
                                            .readonly=${(0, utilities_1.shouldHideFromCompanyUser)(this.store.authenticatedUser)}
                                            .type=${'TEXT_INPUT'}
                                            .inputValue=${customFieldInputValues.text_value}
                                            @inputchange=${(e) => {
                this.inputValues = {
                    ...this.store.inputValues,
                    customFieldsInputValues: this.store.inputValues.customFieldsInputValues.map((innerCustomFieldInputValues) => {
                        if (customFieldInputValues.symbol ===
                            innerCustomFieldInputValues.symbol) {
                            return {
                                ...innerCustomFieldInputValues,
                                text_value: e.detail.inputValue,
                            };
                        }
                        else {
                            return innerCustomFieldInputValues;
                        }
                    }),
                };
            }}
                                        ></jo-input>
                                    </div>

                                    <div
                                        class="checkbox-container"
                                        ?hidden=${(0, utilities_1.shouldHideFromCompanyUser)(this.store.authenticatedUser)}
                                    >
                                        <div class="flex-1">
                                            <div class="header-text">Appear</div>
                                            <input
                                                type="checkbox"
                                                .checked=${customFieldInputValues.call_screen_appear}
                                                @change=${(e) => {
                this.inputValues = {
                    ...this.store.inputValues,
                    customFieldsInputValues: this.store.inputValues.customFieldsInputValues.map((innerCustomFieldInputValues) => {
                        if (customFieldInputValues.symbol ===
                            innerCustomFieldInputValues.symbol) {
                            return {
                                ...innerCustomFieldInputValues,
                                call_screen_appear: e.target.checked,
                            };
                        }
                        else {
                            return innerCustomFieldInputValues;
                        }
                    }),
                };
            }}
                                            />
                                        </div>

                                        <div class="flex-1">
                                            <div class="flex-1 header-text">Require</div>
                                            <input
                                                type="checkbox"
                                                .checked=${customFieldInputValues.call_screen_require}
                                                @change=${(e) => {
                this.inputValues = {
                    ...this.store.inputValues,
                    customFieldsInputValues: this.store.inputValues.customFieldsInputValues.map((innerCustomFieldInputValues) => {
                        if (customFieldInputValues.symbol ===
                            innerCustomFieldInputValues.symbol) {
                            return {
                                ...innerCustomFieldInputValues,
                                call_screen_require: e.target.checked,
                            };
                        }
                        else {
                            return innerCustomFieldInputValues;
                        }
                    }),
                };
            }}
                                            />
                                        </div>

                                        <div class="flex-1">
                                            <jo-button
                                                .type=${'ICON'}
                                                .text=${'keyboard_arrow_up'}
                                                @joButtonClick=${() => {
                this.shiftCustomFieldPosition(customFieldInputValues, shift_items_at_order_number_position_1.ShiftDirection.UP);
            }}
                                            ></jo-button>
                                        </div>

                                        <div class="flex-1">
                                            <jo-button
                                                .type=${'ICON'}
                                                .text=${'keyboard_arrow_down'}
                                                @joButtonClick=${() => {
                this.shiftCustomFieldPosition(customFieldInputValues, shift_items_at_order_number_position_1.ShiftDirection.DOWN);
            }}
                                            ></jo-button>
                                        </div>

                                        <div
                                            class="flex-1"
                                            ?hidden=${(0, utilities_1.shouldHideFromCompanyUser)(this.store.authenticatedUser)}
                                        >
                                            <jo-button
                                                .type=${'ICON'}
                                                .icon=${'delete'}
                                                @joButtonClick=${() => this.deleteCustomField(customFieldInputValues)}
                                            ></jo-button>
                                        </div>
                                    </div>
                                </div>

                                <div ?hidden=${customFieldInputValues.type !== 'SELECT'}>
                                    <div class="options-text">Options</div>

                                    <div style="padding-left: 1rem">
                                        ${customFieldInputValues.select_values.map((selectValueInputValues) => {
                return (0, lit_html_1.html) `
                                                    <div
                                                        class="options-row"
                                                        style="align-items: center"
                                                    >
                                                        <div style="padding-right: 1rem">
                                                            <jo-input
                                                                .readonly=${(0, utilities_1.shouldHideFromCompanyUser)(this.store.authenticatedUser)}
                                                                .type=${'TEXT_INPUT'}
                                                                .inputValue=${selectValueInputValues.text_value}
                                                                @inputchange=${(e) => {
                    this.inputValues = {
                        ...this.store.inputValues,
                        customFieldsInputValues: this.store.inputValues.customFieldsInputValues.map((innerCustomFieldInputValues) => {
                            if (customFieldInputValues.symbol ===
                                innerCustomFieldInputValues.symbol) {
                                return {
                                    ...innerCustomFieldInputValues,
                                    select_values: innerCustomFieldInputValues.select_values.map((innerSelectValue) => {
                                        if (selectValueInputValues.symbol ===
                                            innerSelectValue.symbol) {
                                            return {
                                                ...innerSelectValue,
                                                text_value: e
                                                    .detail
                                                    .inputValue,
                                            };
                                        }
                                        else {
                                            return innerSelectValue;
                                        }
                                    }),
                                };
                            }
                            else {
                                return innerCustomFieldInputValues;
                            }
                        }),
                    };
                }}
                                                            ></jo-input>
                                                        </div>

                                                        <div
                                                            ?hidden=${(0, utilities_1.shouldHideFromCompanyUser)(this.store.authenticatedUser)}
                                                        >
                                                            <jo-button
                                                                .type=${'ICON'}
                                                                .icon=${'delete'}
                                                                @joButtonClick=${() => this.deleteCustomFieldSelectValue(customFieldInputValues, selectValueInputValues)}
                                                            ></jo-button>
                                                        </div>
                                                    </div>
                                                `;
            })}

                                        <jo-button
                                            ?hidden=${(0, utilities_1.shouldHideFromCompanyUser)(this.store.authenticatedUser)}
                                            .type=${'ACTION_BUTTON_2'}
                                            .text=${'Add option'}
                                            @click=${() => {
                this.inputValues = {
                    ...this.store.inputValues,
                    customFieldsInputValues: this.store.inputValues.customFieldsInputValues.map((innerCustomFieldInputValues) => {
                        if (customFieldInputValues.symbol ===
                            innerCustomFieldInputValues.symbol) {
                            return {
                                ...innerCustomFieldInputValues,
                                select_values: [
                                    ...innerCustomFieldInputValues.select_values,
                                    {
                                        symbol: Symbol(),
                                        id: 'NOT_SET',
                                        archived: false,
                                        text_value: '',
                                    },
                                ],
                            };
                        }
                        else {
                            return innerCustomFieldInputValues;
                        }
                    }),
                };
            }}
                                        ></jo-button>
                                    </div>
                                </div>

                                <div>
                                    <div class="services-text">Services</div>

                                    ${state.company === 'NOT_SET'
                ? ''
                : state.company.services.map(service => {
                    return (0, lit_html_1.html) `
                                                  <div class="services-row">
                                                      <input
                                                          ?disabled=${(0, utilities_1.shouldHideFromCompanyUser)(this.store.authenticatedUser)}
                                                          type="checkbox"
                                                          .checked=${customFieldInputValues.service_ids_to_connect.includes(service.id)}
                                                          @change=${(e) => {
                        this.inputValues = {
                            ...this.store.inputValues,
                            customFieldsInputValues: (0, helper_utils_1.getCustomFieldsInputValuesForServiceCheckbox)(e, this.store.company, service, customFieldInputValues, this.store.inputValues
                                .customFieldsInputValues),
                        };
                    }}
                                                      />
                                                      <div class="service-name-text">
                                                          ${service.name}
                                                      </div>
                                                  </div>
                                              `;
                })}
                                </div>

                                <hr style="width: 100%" />
                            `;
        })}
                </div>

                <div
                    class="jo-global--emptyIconContainer"
                    ?hidden=${(0, helper_utils_1.isInputValuesEmpty)(this.store.inputValues) === false}
                >
                    <i class="material-icons emptyIcon">support_agent</i>
                    <h3>Custom fields have not been added.</h3>
                </div>
            </div>
        `;
    }
    shiftCustomFieldPosition(customFieldInputValues, shiftDirection) {
        const shiftedCustomFieldsInputValues = (0, shift_items_at_order_number_position_1.shiftItemsAtOrderNumber)({
            items: (0, sort_by_and_apply_order_number_to_items_1.sortByAndApplyOrderNumberToItems)(this.store.inputValues.customFieldsInputValues),
            orderNumber: customFieldInputValues.order,
            shiftDirection,
        });
        this.inputValues = {
            ...this.store.inputValues,
            customFieldsInputValues: shiftedCustomFieldsInputValues,
        };
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-services-pricing-custom-fields', JOCompanyServicesPricingCustomFields);
