"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoCustomerUpdatePaymentDetails = void 0;
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
require("../jo-button.element");
require("../jo-input");
require("../jo-table");
const helper_utils_1 = require("./helper-utils");
const mutations_1 = require("./mutations");
const queries_1 = require("./queries");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const InitialState = {
    companyId: 'NOT_SET',
    company: 'NOT_SET',
    loading: false,
    isMobileDevice: false,
    showCardEntryForm: false,
    cardNumber: 'NOT_SET',
    expiry: 'NOT_SET',
    cvc: 'NOT_SET',
};
class JoCustomerUpdatePaymentDetails extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
        this.getPaymentMethodsForCustomer = (async (offset, limit) => {
            (0, utilities_1.assertIsSet)(this.store.company, utilities_1.handleError, 'this.store.company');
            if (!this.store.company.credit_card_key) {
                return {
                    items: [],
                    totalItems: 0,
                };
            }
            const stripePaymentMethods = await this.getPaymentMethods(this.store.company.credit_card_key);
            const stripeCustomer = await this.getCustomer(this.store.company.credit_card_key);
            const defaultPaymentSourceForCustomer = stripeCustomer.default_source
                ? stripeCustomer.default_source
                : '';
            const result = {
                items: getStripePaymentMethodItems(stripePaymentMethods, defaultPaymentSourceForCustomer),
                totalItems: stripePaymentMethods.length,
            };
            return result;
        }).bind(this);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        const prevCompanyId = this.store.companyId;
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        if (prevCompanyId === this.store.companyId) {
            return;
        }
        if (this.store.companyId !== 'NOT_SET') {
            this.fetchAndSetCompany(this.store.companyId);
        }
    }
    async fetchAndSetCompany(companyId) {
        this.store.loading = true;
        const companyResult = await (0, queries_1.fetchCompanyForPaymentDetails)(companyId);
        (0, utilities_1.assertSucceeded)(companyResult, utilities_1.handleError);
        this.store.company = companyResult.value;
        this.store.loading = false;
        this.triggerTableReload();
    }
    async getPaymentMethods(customerStripeId) {
        (0, utilities_1.assertIsSet)(customerStripeId, utilities_1.handleError, 'customerStripeId');
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        const paymentMethodsForCustomerResult = await (0, queries_1.getCustomerPaymentMethods)(customerStripeId, this.store.companyId);
        (0, utilities_1.assertSucceeded)(paymentMethodsForCustomerResult, utilities_1.handleError);
        return paymentMethodsForCustomerResult.value;
    }
    async getCustomer(customerStripeId) {
        (0, utilities_1.assertIsSet)(customerStripeId, utilities_1.handleError, 'customerStripeId');
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        const stripeCustomerResult = await (0, queries_1.getCustomerFromStipe)(customerStripeId, this.store.companyId);
        (0, utilities_1.assertSucceeded)(stripeCustomerResult, utilities_1.handleError);
        return stripeCustomerResult.value;
    }
    async makeDefaultPaymentForCustomer(cardId) {
        (0, utilities_1.assertIsSet)(this.store.company, utilities_1.handleError, 'this.store.company');
        (0, utilities_1.assertIsSet)(cardId, utilities_1.handleError, 'cardId');
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        const customerStripeId = this.store.company.credit_card_key;
        (0, utilities_1.assertIsSet)(customerStripeId, utilities_1.handleError, 'customerStripeId');
        const result = await (0, mutations_1.updateDefaultPaymentInStripe)(cardId, customerStripeId, this.store.companyId);
        (0, utilities_1.assertSucceeded)(result, utilities_1.handleError);
        this.triggerTableReload();
        const successMessage = 'Default Payment updated successfully';
        await (0, utilities_1.joAlert)('Success', successMessage);
    }
    async deletePaymentMethodForCustomer(cardId) {
        (0, utilities_1.assertIsSet)(this.store.company, utilities_1.handleError, 'this.store.company');
        (0, utilities_1.assertIsSet)(cardId, utilities_1.handleError, 'cardId');
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        const customerStripeId = this.store.company.credit_card_key;
        (0, utilities_1.assertIsSet)(customerStripeId, utilities_1.handleError, 'customerStripeId');
        const shouldContinue = await (0, utilities_1.joConfirm)('Notice', 'Are you sure you want to delete this card?');
        if (shouldContinue === false) {
            this.triggerTableReload();
            return;
        }
        const result = await (0, mutations_1.deletePaymentMethodInStripe)(cardId, customerStripeId, this.store.companyId);
        (0, utilities_1.assertSucceeded)(result, utilities_1.handleError);
        this.triggerTableReload();
        const successMessage = 'Card deleted successfully';
        await (0, utilities_1.joAlert)('Success', successMessage);
    }
    getCardDetailsObject() {
        (0, utilities_1.assertIsSet)(this.store.cardNumber, utilities_1.handleError, 'this.store.cardNumber');
        (0, utilities_1.assertIsSet)(this.store.expiry, utilities_1.handleError, 'this.store.expiry');
        (0, utilities_1.assertIsSet)(this.store.cvc, utilities_1.handleError, 'this.store.cvc');
        const [expiryMonth, expiryYear,] = (0, helper_utils_1.getExpiryMonthAndYearFromExpiry)(this.store.expiry);
        return {
            cardNumber: this.store.cardNumber,
            exp_month: expiryMonth,
            exp_year: expiryYear,
            cvc: this.store.cvc,
        };
    }
    async addPaymentMethodForCustomer() {
        (0, utilities_1.assertIsSet)(this.store.company, utilities_1.handleError, 'this.store.company');
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        (0, utilities_1.assertIsSet)(this.store.company.credit_card_key, utilities_1.handleError, 'this.store.company.credit_card_key');
        const cardDetails = this.getCardDetailsObject();
        const addPaymentMethodInStripeResult = await (0, mutations_1.addPaymentMethodInStripe)(cardDetails, this.store.company.credit_card_key, this.store.companyId);
        if (addPaymentMethodInStripeResult.succeeded === false) {
            const failureMessage = 'Invalid Card Details';
            await (0, utilities_1.joAlert)('Failed', failureMessage);
            return;
        }
        const successMessage = 'Card Added successfully';
        await (0, utilities_1.joAlert)('Success', successMessage);
        this.triggerTableReload();
    }
    triggerTableReload() {
        this.getPaymentMethodsForCustomer = this.getPaymentMethodsForCustomer.bind(this);
        this.store.companyId = this.store.companyId;
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }
                .jo-customer-payment-method--add-button {
                    display: flex;
                    justify-content: right;
                    padding: 2rem;
                }
                .jo-customer-payment-method--submit-button-container {
                    display: flex;
                    justify-content: right;
                    padding: 2rem;
                }
                .jo-customer-payment-method--add-new-payment-container {
                    width: 80%;
                    margin: 0 auto;
                }
                .jo-customer-payment-method--credit-card-number-input {
                    width: 50%;
                    padding: 0.5rem;
                }
                .jo-customer-payment-method--credit-card-expiry-cvv-container {
                    display: flex;
                    justify-content: left;
                }
                .jo-customer-payment-method--credit-card-expiry-cvv-input {
                    width: 20%;
                    padding: 0.5rem;
                }
            </style>
            <div id="main-container">
                <jo-table
                    .tableName=${'Payment Methods'}
                    .maxItemsPerPage=${15}
                    .columns=${(0, helper_utils_1.getColumns)(this, state)}
                    .getItems=${state.company === 'NOT_SET'
            ? 'NOT_SET'
            : this.getPaymentMethodsForCustomer}
                    .isMobileDevice=${state.isMobileDevice}
                    .showSearchBar=${false}
                    @itemsfetched=${() => (this.store.loading = false)}
                ></jo-table>

                <div class="jo-customer-payment-method--add-button">
                    <jo-button
                        ?hidden=${this.store.showCardEntryForm === true}
                        .type=${'ACTION_BUTTON_1'}
                        .text=${'Add a Payment Method'}
                        .inlineStyle=${'font-size: 0.9rem; padding: 0.5rem 0.75rem'}
                        @click=${() => (this.store.showCardEntryForm = true)}
                    ></jo-button>
                </div>

                ${this.store.showCardEntryForm
            ? (0, lit_html_1.html) `
                          <div class="jo-customer-payment-method--add-new-payment-container">
                              <div class="jo-customer-payment-method--credit-card-number-input">
                                  <jo-input
                                      .type=${'CREDIT_CARD_NUMBER'}
                                      .label=${'Credit Card Number'}
                                      .placeholder=${'Credit Card Number'}
                                      @inputchange=${(e) => {
                this.store.cardNumber = e.detail.inputValue;
            }}
                                  ></jo-input>
                              </div>

                              <div
                                  class="jo-customer-payment-method--credit-card-expiry-cvv-container"
                              >
                                  <jo-input
                                      class="jo-customer-payment-method--credit-card-expiry-cvv-input"
                                      .type=${'CREDIT_CARD_EXPIRY'}
                                      .label=${'Expiry'}
                                      .placeholder=${'MM/YY'}
                                      @inputchange=${(e) => {
                this.store.expiry = e.detail.inputValue;
            }}
                                  ></jo-input>

                                  <jo-input
                                      class="jo-customer-payment-method--credit-card-expiry-cvv-input"
                                      .type=${'CREDIT_CARD_CVV'}
                                      .label=${'CVV'}
                                      .placeholder=${''}
                                      @inputchange=${(e) => {
                this.store.cvc = e.detail.inputValue;
            }}
                                  ></jo-input>
                              </div>

                              <div class="jo-customer-payment-method--submit-button-container">
                                  <jo-button
                                      style="padding:0.5rem;"
                                      .type=${'ALERT_BUTTON_ACCEPT'}
                                      .text=${'Submit'}
                                      .inlineStyle=${'font-size: 0.9rem; padding: 0.5rem 0.75rem'}
                                      @click=${async () => {
                await this.addPaymentMethodForCustomer();
                this.store.showCardEntryForm = false;
            }}
                                  ></jo-button>

                                  <jo-button
                                      style="padding:0.5rem;"
                                      .type=${'ALERT_BUTTON_CANCEL'}
                                      .text=${'Cancel'}
                                      .inlineStyle=${'font-size: 0.9rem; padding: 0.5rem 0.75rem'}
                                      @click=${() => (this.store.showCardEntryForm = false)}
                                  ></jo-button>
                              </div>
                          </div>
                      `
            : (0, lit_html_1.html) ``}
            </div>
        `;
    }
}
exports.JoCustomerUpdatePaymentDetails = JoCustomerUpdatePaymentDetails;
(0, define_custom_element_1.defineCustomElement)('jo-customer-update-payment-details', JoCustomerUpdatePaymentDetails);
function getStripePaymentMethodItems(stripePaymentMethods, defaultPaymentSourceForCustomer) {
    return stripePaymentMethods.map(card => {
        return {
            ...card,
            isDefault: card.id === defaultPaymentSourceForCustomer,
        };
    });
}
