"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoCompanyEditableBusinessHour = void 0;
const element_vir_1 = require("element-vir");
const types_for_business_hours_1 = require("../../../utilities/business-hours/types-for-business-hours");
exports.JoCompanyEditableBusinessHour = (0, element_vir_1.defineElement)()({
    tagName: 'jo-company-editable-business-hour',
    styles: (0, element_vir_1.css) `
        :host {
            display: block;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
            border-top: 1px solid black;
        }

        :host(:hover) {
            background-color: var(--jo-accent-background-light);
        }

        .day-name {
            font-weight: bold;
            font-size: 1.2rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }

        .time-input-row {
            display: flex;
            gap: 2rem;
        }

        .time-input-container {
            display: flex;
            gap: 0.5rem;
            align-items: center;
        }
    `,
    events: {
        hoursChange: (0, element_vir_1.defineElementEvent)(),
    },
    renderCallback({ dispatch, events, inputs }) {
        function changeHours(event, field) {
            const inputElement = event.currentTarget;
            if (!(inputElement instanceof HTMLInputElement)) {
                throw new Error('Failed to find input element from event');
            }
            dispatch(new events.hoursChange({
                field,
                value: inputElement.value,
            }));
        }
        return (0, element_vir_1.html) `
            <div class="day-name">${inputs.businessHours.day_of_week}</div>
            <div class="time-input-row">
                <div class="time-input-container">
                    <div>Open:</div>
                    <div>
                        <input
                            type="time"
                            class="jo-global--input"
                            value=${inputs.businessHours.open}
                            ${(0, element_vir_1.listen)('input', event => {
            changeHours(event, types_for_business_hours_1.BusinessHourTimeField.OPEN);
        })}
                        />
                    </div>
                </div>

                <div class="time-input-container">
                    <div>Close:</div>
                    <div>
                        <input
                            type="time"
                            class="jo-global--input"
                            value=${inputs.businessHours.close}
                            ${(0, element_vir_1.listen)('input', event => {
            changeHours(event, types_for_business_hours_1.BusinessHourTimeField.CLOSE);
        })}
                        />
                    </div>
                </div>
            </div>
        `;
    },
});
