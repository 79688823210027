"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatDateForDatetimeLocalInput = void 0;
function formatDateForDatetimeLocalInput(date) {
    const newDate = new Date(date);
    newDate.setTime(newDate.getTime() - getTimezoneOffset(newDate));
    return newDate.toISOString().replace(/:\d\d.\d\d\dZ/i, '');
}
exports.formatDateForDatetimeLocalInput = formatDateForDatetimeLocalInput;
function getTimezoneOffset(date) {
    return date.getTimezoneOffset() * 60 * 1000;
}
