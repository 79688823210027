"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchTeams = exports.fetchAllPods = exports.fetchJills = void 0;
const graphql_1 = require("../../../services/graphql");
const error_handling_1 = require("../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function fetchJills() {
    try {
        const filter = makeJillsFilter();
        const [jillsWithBaseInfo, jillsWithShiftInfo,] = await Promise.all([
            fetchJillsWithBaseInfo(filter),
            fetchJillsWithShiftInfo(filter),
        ]);
        const jills = mergeJills(jillsWithBaseInfo, jillsWithShiftInfo);
        return {
            succeeded: true,
            value: jills,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchJills', error);
    }
}
exports.fetchJills = fetchJills;
function mergeJills(jillsWithBaseInfo, jillsWithShiftInfo) {
    let resultJills = [];
    for (let i = 0; i < jillsWithBaseInfo.length; i++) {
        const jillWithBaseInfo = jillsWithBaseInfo[i];
        const jillWithShiftInfo = jillsWithShiftInfo[i];
        if (jillWithBaseInfo == null)
            throw new Error('jillWithBaseInfo is null');
        if (jillWithShiftInfo == null)
            throw new Error('jillWithShiftInfo is null');
        const jill = {
            ...jillWithBaseInfo,
            ...jillWithShiftInfo,
        };
        resultJills.push(jill);
    }
    return resultJills;
}
function makeJillsFilter() {
    return {
        and: [
            {
                is_active: {
                    eq: true,
                },
            },
            {
                auth_role: {
                    in: [
                        'JILL',
                        'JILL_OUTBOUND',
                        'JILL_MANAGER',
                        'JILL_BUSINESS_DEVELOPMENT',
                        'JILL_EXECUTIVE',
                    ],
                },
            },
        ],
    };
}
async function fetchJillsWithBaseInfo(filter) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($filter: UsersFilter) {
                    findUsers(filter: $filter) {
                        items {
                            id
                            auth_role
                            first_name
                            last_name
                            last_call_completed_at
                            last_call_started_at
                            last_seen_on_a_call
                            last_seen_logged_in
                            last_seen_in_chat_mode
                            last_seen_actively_chatting_at
                            profile_picture_url
                            pods {
                                id
                                name
                            }
                            float_pods {
                                id
                                name
                            }
                            shift_hours
                            is_available
                            team {
                                id
                            }
                            work_status
                        }
                    }
                }
            `, {
            filter,
        });
        const jills = gqlResult.data.findUsers.items;
        const sortedJills = sortJills(jills);
        return sortedJills;
    }
    catch (error) {
        throw new Error(`fetchJillsWithBaseInfo error: ${error}`);
    }
}
async function fetchJillsWithShiftInfo(filter) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($filter: UsersFilter) {
                    findUsers(filter: $filter) {
                        items {
                            id
                            first_name
                            last_name
                            shifts(
                                filter: {archived_at: {eq: null}}
                                orderBy: {field: "id", order: ASC}
                            ) {
                                id
                                start_day_of_week
                                start_hour
                                start_minute
                                end_day_of_week
                                end_hour
                                end_minute
                                lunch_breaks {
                                    id
                                    start_day_of_week
                                    start_hour
                                    start_minute
                                    end_day_of_week
                                    end_hour
                                    end_minute
                                }
                                scheduled_breaks {
                                    id
                                    start_day_of_week
                                    start_hour
                                    start_minute
                                    end_day_of_week
                                    end_hour
                                    end_minute
                                }
                            }
                        }
                    }
                }
            `, {
            filter,
        });
        const jills = gqlResult.data.findUsers.items;
        const sortedJills = sortJills(jills);
        return sortedJills;
    }
    catch (error) {
        throw new Error(`fetchJillsWithShiftInfo error: ${error}`);
    }
}
function sortJills(jills) {
    return [...jills]
        .sort((a, b) => (a.last_name ?? '').localeCompare(b.last_name ?? ''))
        .sort((a, b) => (a.first_name ?? '').localeCompare(b.first_name ?? ''));
}
async function fetchAllPods() {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query {
                findPods(orderBy: {field: "name", order: ASC}) {
                    items {
                        id
                        name
                    }
                }
            }
        `);
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        const pods = gqlResult.value.data.findPods.items;
        return {
            succeeded: true,
            value: pods,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('', error);
    }
}
exports.fetchAllPods = fetchAllPods;
async function fetchTeams() {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query {
                findTeams(orderBy: {field: "id", order: ASC}) {
                    items {
                        id
                        members {
                            id
                            first_name
                            last_name
                            team_role
                        }
                    }
                }
            }
        `);
        if (gqlResult.succeeded === false)
            throw new Error(gqlResult.developerMessage);
        return {
            succeeded: true,
            value: gqlResult.value.data.findTeams.items,
        };
    }
    catch (error) {
        throw new Error(`fetchTeams error: ${error}`);
    }
}
exports.fetchTeams = fetchTeams;
