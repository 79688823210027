"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteInvoice = void 0;
const graphql_1 = require("../../services/graphql");
const utilities_1 = require("../../services/utilities");
const mapped_env_variables_1 = require("../environment/mapped-env-variables");
async function deleteInvoice(invoiceId) {
    try {
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($invoiceId: Int!) {
                    deleteInvoice(invoiceId: $invoiceId)
                }
            `, {
            invoiceId,
        });
    }
    catch (error) {
        (0, utilities_1.handleError)(`deleteInvoice error: ${error}`);
    }
}
exports.deleteInvoice = deleteInvoice;
