"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../../services/utilities");
const css_utils_1 = require("../../../../utilities/css-utils");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
require("../../jo-button.element");
require("../../jo-input");
require("../../jo-quick-text/jo-quick-text");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    companyId: 'NOT_SET',
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-company-quick-notes');
class JOCompanyQuickNotes extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .${cssName('main-container')} {
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;
                    display: flex;
                    flex-wrap: wrap;
                }

                .${cssName('sub-container')} {
                    width: 50%;
                }

                @media (max-width: 768px) {
                    .${cssName('sub-container')} {
                        width: 100%;
                    }
                }
            </style>

            <div class="${cssName('main-container')}">
                <div class="${cssName('sub-container')}">
                    ${state.companyId !== 'NOT_SET'
            ? (0, lit_html_1.html) `
                              <jo-quick-text
                                  .props=${{
                authenticatedUser: state.authenticatedUser,
                title: 'Chat Notes',
                textType: 'LONG',
                companyId: state.companyId,
                companyColumnName: 'chat_quick_notes',
            }}
                              ></jo-quick-text>
                          `
            : ``}
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-quick-notes', JOCompanyQuickNotes);
