"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../../../services/graphql");
const utilities_1 = require("../../../../services/utilities");
const css_utils_1 = require("../../../../utilities/css-utils");
const dates_and_times_1 = require("../../../../utilities/dates-and-times/dates-and-times");
const diff_time_1 = require("../../../../utilities/dates-and-times/diff-time");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
const chat_info_for_session_storage_1 = require("../../jo-call-screen/utilities/chat-info-for-session-storage");
const publish_chat_queue_updated_event_1 = require("../../jo-call-screen/utilities/publish-chat-queue-updated-event");
const chat_sidebar_subscriptions_manager_1 = require("../chat-sidebar-subscriptions-manager");
const chat_duration_event_utils_1 = require("../chat-utils/chat-duration-event-utils");
const get_all_chats_with_most_recent_message_sent_by_jill_1 = require("./get-all-chats-with-most-recent-message-sent-by-jill/get-all-chats-with-most-recent-message-sent-by-jill");
const helper_utils_1 = require("./utils/helper-utils");
const queries_and_mutations_1 = require("./queries-and-mutations");
const styles_1 = require("./styles");
const create_element_bound_interval_1 = require("../../../../utilities/timeouts/create-element-bound-interval");
const add_to_twilio_conversation_1 = require("../../../../utilities/chats/add-to-twilio-conversation");
const common_1 = require("@augment-vir/common");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    availableChats: [],
    assignedChats: [],
    chatQueueUpdateSubscription: 'NOT_SET',
    selectedChat: 'NOT_SET',
    selectedChatStartTimeStamp: 'NOT_SET',
    podIds: [],
    chatCreatedSubscription: null,
    chatsToFollowUpOn: new Set(),
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-chat-sidebar');
const cssNames = (classNames) => classNames.split(' ').map(cssName).join(' ');
class JOChatSidebar extends HTMLElement {
    constructor() {
        super(...arguments);
        this.subscriptionsManager = new chat_sidebar_subscriptions_manager_1.ChatSidebarSubscriptionsManager();
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, (state, action) => {
            if (action.type === 'SET_PROPS') {
                return (0, utilities_1.setPropsReducer)(state, action);
            }
            return state;
        });
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        this.fetchAndSetChats();
    }
    connectedCallback() {
        if (this.store.chatQueueUpdateSubscription === 'NOT_SET') {
            this.subscribeToChatQueueUpdateEvent();
        }
        (0, create_element_bound_interval_1.createElementBoundInterval)({
            callback: () => {
                this.fetchAndSetChats();
                this.addFollowUpIndicatorToChatMessagesIfNecessary();
            },
            element: this,
            intervalMs: 3 * dates_and_times_1.oneSecondInMilliseconds,
            intervalName: 'fetch chats',
        });
    }
    disconnectedCallback() {
        if (this.store.chatQueueUpdateSubscription !== 'NOT_SET') {
            this.store.chatQueueUpdateSubscription.close();
        }
        if (this.store.chatCreatedSubscription) {
            this.store.chatCreatedSubscription.close();
        }
        (0, helper_utils_1.changeFavicon)(false);
    }
    async subscribeToChatQueueUpdateEvent() {
        this.store.chatQueueUpdateSubscription =
            (await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
                    subscription {
                        chatQueueUpdated
                    }
                `, () => {
                this.fetchAndSetChats();
            })) ?? 'NOT_SET';
    }
    async fetchAndSetChats() {
        (0, utilities_1.assertIsSet)(this.store.authenticatedUser);
        //Just commenting this out for now because its likely they will want it again in the near future.
        //this helps to sort the chats by pod so only the jills assigned to the specific pod can see the chat
        // if (this.store.podIds.length === 0) {
        //     this.store.podIds = await this.getUserAssignedPodIds();
        // }
        const openChats = await this.fetchOpenChats(this.store.podIds);
        this.store.availableChats = (0, helper_utils_1.getAvailableChatsFromOpenChatList)(openChats);
        this.store.assignedChats = (0, helper_utils_1.getAssignedChatsFromOpenChatList)(openChats, this.store.authenticatedUser.id);
        this.updateLastSeenActivelyChatting(this.store.assignedChats);
        this.addSubscriptionsForAudio();
        this.updateChatNewMessageIndicators();
    }
    async updateLastSeenActivelyChatting(assignedChats) {
        if (assignedChats.length > 0 && this.store.authenticatedUser !== 'NOT_SET') {
            await (0, queries_and_mutations_1.updateLastSeenActivelyChattingTimeStamp)(this.store.authenticatedUser.id, new Date());
        }
    }
    addFollowUpIndicatorToChatMessagesIfNecessary() {
        const openChats = [
            ...new Set([
                ...this.store.assignedChats,
                ...this.store.availableChats,
            ]),
        ];
        const chatsWithMostRecentMessageFromJill = (0, get_all_chats_with_most_recent_message_sent_by_jill_1.getAllChatsWithMostRecentMessageSentByJill)(openChats);
        for (const chat of chatsWithMostRecentMessageFromJill) {
            this.addUnresponsiveChatsToFollowUpQueue(chat);
        }
    }
    addUnresponsiveChatsToFollowUpQueue(chat) {
        const mostRecentMessage = chat.messages[0];
        if (!mostRecentMessage)
            return;
        this.addChatsToFollowUpQueueIfNecessary(mostRecentMessage, chat);
    }
    addChatsToFollowUpQueueIfNecessary(mostRecentMessage, chat) {
        const timeSinceLastMessageSentByJill = (0, helper_utils_1.getTimeSinceMessageWasCreated)(mostRecentMessage);
        const fiveMinutesInMilliseconds = 5 * dates_and_times_1.oneMinuteInMilliseconds;
        if (timeSinceLastMessageSentByJill > fiveMinutesInMilliseconds) {
            this.store.chatsToFollowUpOn.add(chat.id);
        }
    }
    addSubscriptionsForAudio() {
        for (const chat of this.store.assignedChats) {
            const createWebsocketCallback = (0, helper_utils_1.generateSubscriptionCallbackForNewMessageFromContact)(chat.id);
            this.subscriptionsManager.addSubscription(chat.id, createWebsocketCallback);
        }
    }
    async fetchOpenChats(podIds) {
        const openChatsResult = await (0, queries_and_mutations_1.fetchOpenChatsForPods)(podIds);
        (0, utilities_1.assertSucceeded)(openChatsResult, utilities_1.handleError, this, 'jo-chat-sidebar > fetchOpenChats > fetchOpenChatsForPods()');
        const openChats = openChatsResult.value;
        return openChats;
    }
    async getUserAssignedPodIds() {
        (0, utilities_1.assertIsSet)(this.store.authenticatedUser, utilities_1.handleError);
        const userAssignedPods = await (0, queries_and_mutations_1.getUserAssignedPods)(this.store.authenticatedUser.id);
        if (userAssignedPods.succeeded === false) {
            return [];
        }
        return [
            ...userAssignedPods.value.pods.map(p => {
                return p.id;
            }),
            ...userAssignedPods.value.float_pods.map(fp => {
                return fp.id;
            }),
        ];
    }
    async assignChatToJill(chat) {
        await this.fetchAndSetChats();
        if (this.isChatAlreadyTaken(chat.id)) {
            (0, utilities_1.joAlert)('Chat Already Taken', 'This chat has already been taken by another Jill.');
            return;
        }
        if (this.store.authenticatedUser !== 'NOT_SET') {
            const updateChatResult = await (0, queries_and_mutations_1.updateCurrentlyChattingJill)(this.store.authenticatedUser.id, chat.id);
            (0, utilities_1.assertSucceeded)(updateChatResult, utilities_1.handleError, this, 'Failed to Assign Chat');
            if (chat.status === 'RELEASED_BY_JILL' && chat.temporary_chat_state) {
                (0, chat_info_for_session_storage_1.saveTemporaryChatStateInSessionStorage)(chat.id, chat.temporary_chat_state);
            }
            if (!chat.source) {
                throw new Error(`Chat source is undefined can't add participant`);
            }
            if ((0, helper_utils_1.doesChatComeFromCustomerSMS)(chat.source)) {
                if (!chat.twilio_conversation_sid) {
                    throw new Error('Chat was not assigned to a twilio conversation');
                }
                await (0, add_to_twilio_conversation_1.addJillAsParticipantToTwilioConversation)(chat.twilio_conversation_sid, this.store.authenticatedUser.id);
            }
            await (0, chat_duration_event_utils_1.createChatDurationEvent)({
                type: 'ASSIGNED_TO_JILL',
                userId: this.store.authenticatedUser.id,
                chatId: chat.id,
            });
        }
        this.store.selectedChat = chat;
        await (0, publish_chat_queue_updated_event_1.publishChatQueueUpdatedMessage)();
        (0, utilities_1.raiseCustomEvent)(this, 'newSelectedChat', chat);
    }
    isChatAlreadyTaken(chatId) {
        return !this.store.availableChats.find(c => c.id === chatId);
    }
    updateChatNewMessageIndicators() {
        for (const chat of this.store.assignedChats) {
            const mostRecentMessage = chat?.messages[(0, helper_utils_1.getMostRecentMessageIndex)(chat.messages)];
            if (mostRecentMessage?.status === 'NEW_MESSAGE' &&
                (mostRecentMessage.source === 'CUSTOMER_WIDGET' ||
                    mostRecentMessage.source === 'CUSTOMER_SMS')) {
                this.sendNewMessageIndicators();
                return;
            }
        }
        this.resetNewMessageIndicators();
        return;
    }
    resetNewMessageIndicators() {
        (0, helper_utils_1.changeFavicon)(false);
        (0, utilities_1.raiseCustomEvent)(this, 'newMessage', false);
    }
    sendNewMessageIndicators() {
        (0, helper_utils_1.changeFavicon)(true);
        (0, utilities_1.raiseCustomEvent)(this, 'newMessage', true);
    }
    async handleAssignedChatClickEvent(chat) {
        this.store.selectedChat = chat;
        this.fetchAndSetSelectedChatStartTimeStamp(chat.id);
        const mostRecentMessage = (0, helper_utils_1.getMostRecentChatMessage)(chat);
        (0, utilities_1.assertIsDefined)(mostRecentMessage, 'mostRecentMessage');
        const chatId = await (0, queries_and_mutations_1.markChatMessageAsRead)(mostRecentMessage.id);
        (0, utilities_1.assertSucceeded)(chatId, utilities_1.handleError, this, 'chat-sidebar > handleAssignedChatClickEvent > markChatAsRead()');
        this.fetchAndSetChats();
        (0, utilities_1.raiseCustomEvent)(this, 'newSelectedChat', chat);
    }
    async fetchAndSetSelectedChatStartTimeStamp(chatId) {
        const durationEvents = await (0, chat_duration_event_utils_1.findChatAssignedToJillDurationEventsBasedOnChatId)(chatId);
        if (!durationEvents || durationEvents.length === 0) {
            return;
        }
        (0, common_1.assertLengthAtLeast)(durationEvents, 1);
        this.store.selectedChatStartTimeStamp = durationEvents[0].timestamp;
    }
    isChatSelected(chat) {
        if (this.store.selectedChat !== 'NOT_SET' && this.store.selectedChat !== undefined) {
            if (chat.id === this.store.selectedChat.id) {
                return true;
            }
        }
        return false;
    }
    async releaseChatsButtonClicked(assignedChats, userId) {
        if (this.store.selectedChat !== 'NOT_SET') {
            await (0, utilities_1.raiseCustomEvent)(this, 'saveSelectedChatInSession');
        }
        this.releaseChatsAssignedToJill(assignedChats, userId);
    }
    async releaseChatsAssignedToJill(assignedChats, userId) {
        const shouldContinue = await (0, utilities_1.joConfirm)('Notice', 'Are you sure you want to send all your assigned chats back to the queue?');
        if (shouldContinue === false) {
            return;
        }
        (0, helper_utils_1.releaseAllAssignedChats)(assignedChats, userId);
        await (0, publish_chat_queue_updated_event_1.publishChatQueueUpdatedMessage)();
        this.store.selectedChat = 'NOT_SET';
        (0, utilities_1.raiseCustomEvent)(this, 'newSelectedChat', 'NOT_SET');
    }
    getChatDurationTime() {
        if (this.store.selectedChatStartTimeStamp === 'NOT_SET') {
            return '00:00:00';
        }
        return (0, dates_and_times_1.millisecondsToHoursMinutesSecondsInSemicolonFormat)((0, diff_time_1.diffTime)(new Date(), new Date(this.store.selectedChatStartTimeStamp)));
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

            ${styles_1.stylesForChatSideBar}

            <div class="${cssNames('main-container')}">
                <section class="${cssNames('upperSection')}">
                    <div class="${cssNames('close-button-container')}">
                        <div class="jo-global--card-title ${cssNames('upper-section-title')}">
                            Your Active Chats
                        </div>

                        <i
                            class="material-icons ${cssNames('close-icon')}"
                            @click=${() => {
            (0, utilities_1.raiseCustomEvent)(this, 'closeButtonClicked');
        }}
                        >
                            close
                        </i>
                    </div>

                    <div
                        class="${cssNames('release-button-container')}"
                        ?hidden=${state.assignedChats.length === 0}
                    >
                        <jo-button
                            .type=${'DELETE_BUTTON_2'}
                            .text=${'Release All Chats'}
                            @joButtonClick=${() => {
            if (this.store.authenticatedUser !== 'NOT_SET') {
                this.releaseChatsButtonClicked(this.store.assignedChats, this.store.authenticatedUser.id);
            }
        }}
                        ></jo-button>

                        <div class="${cssNames('chat-duration-container')}">
                            <span class="${cssNames('chat-duration-container-title')}">
                                Chat Duration:
                            </span>
                            <div
                                title="Chat Duration Time"
                                class="${cssNames('chat-duration-time')}"
                            >
                                ${this.getChatDurationTime()}
                            </div>
                        </div>
                    </div>

                    <div class="${cssNames('chat-card-container')}">
                        ${this.store.assignedChats.length > 0
            ? this.store.assignedChats.map(chat => {
                if (chat !== undefined) {
                    return (0, lit_html_1.html) `
                                          <div
                                              class="jo-global--card ${cssNames('chat-card ')} ${this.isChatSelected(chat)
                        ? cssNames('selected-chat-card')
                        : ''}"
                                              @click=${() => {
                        this.handleAssignedChatClickEvent(chat);
                    }}
                                          >
                                              <div class="${cssNames('chat-card-title')}">
                                                  <div
                                                      class="${cssNames('chat-alert')}"
                                                      ?hidden="${(0, helper_utils_1.shouldHideNewMessageNotification)(chat)}"
                                                  >
                                                      New Message
                                                  </div>
                                                  <div
                                                      class="${cssNames('chat-alert')}"
                                                      ?hidden=${state.chatsToFollowUpOn.has(chat.id) === false}
                                                  >
                                                      <span class="material-icons">more_time</span>
                                                  </div>
                                                  <div class="${cssNames('date-time')}">
                                                      ${(0, helper_utils_1.getDateTimeFormatForCard)(chat.messages[(0, helper_utils_1.getMostRecentMessageIndex)(chat.messages)]?.updated_at)}
                                                      <br />
                                                      Chat Id: ${chat.id}
                                                  </div>

                                                  <div
                                                      class="material-icons ${cssNames('sms-icon')}"
                                                      ?hidden=${!chat.twilio_conversation_sid}
                                                  >
                                                      signal_cellular_alt
                                                  </div>
                                              </div>
                                              <div class="${cssNames('contact-name')}">
                                                  ${chat.contact?.first_name}
                                                  ${chat.contact?.last_name}
                                              </div>
                                              <div class="${cssNames('chat-message')}">
                                                  ${(0, helper_utils_1.getMostRecentMessageSummary)(chat)}
                                              </div>
                                          </div>
                                      `;
                }
                return (0, lit_html_1.html) ``;
            })
            : (0, lit_html_1.html) `
                                  <div class="${cssNames('empty-chat-container')}">
                                      <i class="material-icons-outlined" style="font-size: 2rem;">
                                          forum
                                      </i>
                                      <div class="${cssNames('empty-chat-message')}">
                                          Your Have No Active Chats
                                      </div>
                                  </div>
                              `}
                    </div>
                </section>

                <section class="${cssNames('lowerSection')}">
                    <div class="jo-global--card-title">Chats In Queue</div>
                    <div class="${cssNames('chat-card-container')}">
                        ${this.store.availableChats.length > 0
            ? this.store.availableChats.map(chat => {
                if (chat !== undefined) {
                    return (0, lit_html_1.html) `
                                          <div
                                              class="jo-global--card ${cssNames('chat-card')}"
                                              @click=${() => {
                        this.assignChatToJill(chat);
                    }}
                                          >
                                              <div class="${cssNames('chat-card-title')}">
                                                  ${chat.status === 'RELEASED_BY_JILL'
                        ? (0, lit_html_1.html) `
                                                            <i
                                                                class="material-icons ${cssNames('returned-alert')}"
                                                            >
                                                                error
                                                            </i>
                                                        `
                        : (0, lit_html_1.html) ``}

                                                  <div
                                                      class="${cssNames('chat-alert')}"
                                                      ?hidden=${state.chatsToFollowUpOn.has(chat.id) === false}
                                                  >
                                                      <span class="material-icons">more_time</span>
                                                  </div>

                                                  <div class="${cssNames('company-name')}">
                                                      ${chat.company.name.substring(0, 24)}
                                                  </div>
                                                  <div class="${cssNames('date-time')}">
                                                      ${(0, helper_utils_1.getDateTimeFormatForCard)(chat.updated_at)}
                                                  </div>
                                              </div>
                                              <div class="${cssNames('contact-name')}">
                                                  ${chat.contact?.first_name}
                                                  ${chat.contact?.last_name}
                                              </div>
                                              <div class="${cssNames('chat-message')}">
                                                  ${(0, helper_utils_1.getMostRecentMessageSummary)(chat)}
                                              </div>
                                          </div>
                                      `;
                }
            })
            : (0, lit_html_1.html) `
                                  <div class="${cssNames('empty-chat-container')}">
                                      <i class="material-icons-outlined" style="font-size: 2rem;">
                                          forum
                                      </i>
                                      <div class="${cssNames('empty-chat-message')}">
                                          No Available Chats At This Time
                                      </div>
                                  </div>
                              `}
                    </div>
                </section>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-chat-sidebar', JOChatSidebar);
