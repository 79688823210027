"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOCompanyServicesPricingPricingStyles = void 0;
const lit_html_1 = require("lit-html");
exports.JOCompanyServicesPricingPricingStyles = (0, lit_html_1.html) `
    <style>
        .main-container {
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
        }
        .col-1 {
            flex: 2;
            padding: 0.25rem;
            align-self: center;
            width: 100%;
        }

        .col-2 {
            flex: 2;
            padding: 0.25rem;
        }

        .col-3 {
            flex: 0.75;
            flex-direction: column;
            padding: 0.25rem;
            display: flex;
            align-items: center;
            align-self: center;
        }

        .col-4 {
            flex: 0.75;
            flex-direction: column;
            padding: 0.25rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .col-5 {
            flex: 0.5;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .card-title {
            padding-bottom: 1rem;
        }

        .add-button {
            padding-right: 1rem;
        }

        .add-line-item-container {
            display: flex;
            justify-content: space-around;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
        .price-check-delete-container {
            display: flex;
        }
        .service-divider-border {
            border-bottom: 1px solid orange;
            height: 2px;
            width: 90%;
            margin: 1rem auto;
        }
        .emptyIcon {
            padding-top: 2rem;
            font-size: 5rem;
        }
    </style>
`;
