"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchActionItem = void 0;
const graphql_1 = require("../../../../services/graphql");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function fetchActionItem(actionItemId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($id: Int!) {
                    getAction_items(id: $id) {
                        id
                        title
                        chat {
                            id
                            messages(orderBy: {field: "id", order: ASC}) {
                                id
                                source
                                body
                                created_at
                                from_origin
                                user {
                                    first_name
                                    last_name
                                }
                            }
                        }
                        contact {
                            id
                            first_name
                            last_name
                            company_name
                            email
                            where_did_you_hear_about_us
                            phone_numbers {
                                id
                                created_at
                                number
                            }
                            addresses {
                                id
                                line_1
                                line_2
                                city
                                state
                                zip_code
                                county
                                postal_code
                                province
                            }
                            company {
                                id
                            }
                            sales
                            status
                        }
                        action_item_notes(orderBy: {field: "created_at", order: DESC}) {
                            id
                            author {
                                id
                                first_name
                                last_name
                                email
                                auth_role
                                profile_picture_url
                            }
                            body
                            created_at
                        }
                        user {
                            email
                        }
                        company_internal_notes
                    }
                }
            `, {
            id: actionItemId,
        });
        const actionItem = gqlResult.data.getAction_items;
        return {
            succeeded: true,
            value: actionItem,
        };
    }
    catch (error) {
        return {
            succeeded: false,
            userMessage: 'There was an error.',
            developerMessage: `jo-customer -> jo-customer-action-item -> queries -> fetchActionItem Error: ${error}`,
        };
    }
}
exports.fetchActionItem = fetchActionItem;
