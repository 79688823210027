"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setAdminModeForUser = void 0;
const graphql_1 = require("../../services/graphql");
const mapped_env_variables_1 = require("../environment/mapped-env-variables");
async function setAdminModeForUser(params) {
    const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($userId: Int!, $shouldBeInAdminMode: Boolean!) {
                updateUsers(input: {id: $userId, in_personal_admin_mode: $shouldBeInAdminMode}) {
                    id
                }
            }
        `, params);
}
exports.setAdminModeForUser = setAdminModeForUser;
