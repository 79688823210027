"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNumberOfNewAppointments = void 0;
const constants_1 = require("../../../../services/constants");
function getNumberOfNewAppointments(calls) {
    return calls.filter(call => {
        if (call.action_item?.title?.includes(constants_1.NEW_APPOINTMENT_ACTION_ITEM_TITLE)) {
            return call;
        }
    }).length;
}
exports.getNumberOfNewAppointments = getNumberOfNewAppointments;
