"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../../services/graphql");
const utilities_1 = require("../../../services/utilities");
const error_handling_1 = require("../../../utilities/error-handling");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
require("../jo-input");
require("../jo-table");
const InitialState = {
    companyId: 'NOT_SET',
    searchText: '',
    errorMessage: '',
    contacts: [],
    showDropDown: false,
    fontSize: '0.9rem',
};
class JOCustomerContactSearch extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    searchTextChange(searchText) {
        if (searchText === this.store.searchText) {
            return;
        }
        this.store.searchText = searchText;
        if (searchText === '') {
            this.store.contacts = [];
            this.store.showDropDown = false;
            return;
        }
        else {
            this.getAndSetContacts();
            this.store.showDropDown = true;
        }
    }
    async getAndSetContacts() {
        const getContactsResult = await getContacts(this.store.companyId, this.store.searchText);
        //Need to handle errors in a different way.
        (0, utilities_1.assertSucceeded)(getContactsResult, utilities_1.handleError);
        this.store.contacts = getContactsResult.value;
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link type="css" rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .jo-customer-dropdown-container {
                    position: relative;
                    display: inline-block;
                }

                .jo-customer-dropdown-content {
                    position: absolute;
                    min-width: 19rem;
                    box-shadow: var(--jo-box-shadow-1);
                    background-color: white;
                    z-index: 99;
                }

                .jo-customer-dropdown-item {
                    padding: 0.5rem;
                    border-bottom: 1px solid var(--jo-accent-background-light);
                    display: flex;
                    font-size: ${state.fontSize};
                }

                .jo-customer-dropdown-item:hover {
                    background-color: var(--jo-accent-background-dark);
                }

                .jo-customer-dropdown-item-column {
                    flex: 1;
                }
            </style>

            <div class="main-container">
                <jo-input
                    .type=${'SEARCH'}
                    .debounce=${300}
                    .inputValue=${this.store.searchText}
                    @inputchange=${(e) => {
            this.searchTextChange(e.detail.inputValue);
        }}
                ></jo-input>

                <div
                    class="jo-customer-dropdown-container"
                    ?hidden=${this.store.showDropDown === false}
                >
                    <div class="jo-customer-dropdown-content">
                        ${this.store.contacts.map(contact => (0, lit_html_1.html) `
                                <div
                                    class="jo-customer-dropdown-item"
                                    @click=${() => {
            this.searchTextChange('');
            (0, utilities_1.raiseCustomEvent)(this, 'contactSelected', contact);
        }}
                                >
                                    <div class="jo-customer-dropdown-item-column">
                                        ${contact.first_name} ${contact.last_name}
                                    </div>
                                    <div class="jo-customer-dropdown-item-column">
                                        ${getPrimaryPhoneNumberForContact(contact)}
                                    </div>
                                </div>
                            `)}
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-customer-contact-search', JOCustomerContactSearch);
async function getContacts(companyId, searchText) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!, $searchText: String!) {
                    searchContacts(
                        companyId: $companyId
                        page: {offset: 0, limit: 5}
                        searchText: $searchText
                        ignoreSearch: false
                    ) {
                        contacts {
                            id
                            first_name
                            last_name
                            phone_number
                            phone_numbers {
                                id
                                created_at
                                number
                            }
                            addresses {
                                id
                                city
                                county
                                line_1
                                line_2
                                postal_code
                                province
                                state
                                zip_code
                            }
                            email
                        }
                    }
                }
            `, {
            ignoreSearch: searchText === '',
            searchText: searchText,
            companyId: companyId,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        const contacts = gqlResult.value.data.searchContacts.contacts;
        return {
            succeeded: true,
            value: contacts,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('', error);
    }
}
function getPrimaryPhoneNumberForContact(contact) {
    const phoneNumber = contact.phone_numbers[0];
    if (phoneNumber == null)
        return (0, utilities_1.formatE164PhoneNumberForDisplay)(contact.phone_number);
    return (0, utilities_1.formatE164PhoneNumberForDisplay)(phoneNumber.number);
}
