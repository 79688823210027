"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../services/graphql");
const utilities_1 = require("../../services/utilities");
require("./jo-button.element");
require("./jo-input");
const form_error_manager_1 = require("../../classes/form-error-manager");
const error_handling_1 = require("../../utilities/error-handling");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const test_id_directive_1 = require("../directives/test-id.directive");
const mapped_env_variables_1 = require("../../utilities/environment/mapped-env-variables");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    inputValues: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    },
    loading: true,
    saving: false,
    showAllFormErrors: false,
    formErrorManager: new form_error_manager_1.FormErrorManager(),
};
class JOChangeUserPassword extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set inputValues(inputValues) {
        this.store.inputValues = inputValues;
    }
    async saveButtonClicked() {
        if (this.store.inputValues.currentPassword === '') {
            (0, utilities_1.joAlert)('Alert', 'Current password cannot be blank');
            return;
        }
        if (this.store.formErrorManager.getFormErrors().length > 0) {
            this.store.showAllFormErrors = true;
            (0, utilities_1.joAlert)('Alert', 'Please correct errors');
            return;
        }
        (0, utilities_1.assertIsSet)(this.store.inputValues.currentPassword, utilities_1.handleError, 'this.store.inputValues.currentPassword');
        (0, utilities_1.assertIsSet)(this.store.inputValues.newPassword, utilities_1.handleError, 'this.store.inputValues.newPassword');
        (0, utilities_1.assertIsSet)(this.store.inputValues.confirmPassword, utilities_1.handleError, 'this.store.inputValues.confirmPassword');
        const shouldContinue = await (0, utilities_1.joConfirm)('Notice', 'Are you sure you want to update your password?');
        if (shouldContinue === false) {
            return;
        }
        this.store.saving = true;
        await this.handleUpdatePassword();
    }
    async handleUpdatePassword() {
        (0, utilities_1.assertIsSet)(this.store.authenticatedUser, utilities_1.handleError, 'this.store.authenticatedUser');
        (0, utilities_1.assertIsSet)(this.store.inputValues.newPassword, utilities_1.handleError, 'this.store.inputValues.newPassword');
        const updatePasswordResult = await updatePassword(this.store.authenticatedUser.id, this.store.inputValues.currentPassword, this.store.inputValues.newPassword);
        (0, utilities_1.assertSucceeded)(updatePasswordResult, utilities_1.handleError, this, 'updatePassword() Failed');
        if (updatePasswordResult.value === 'FAILED_CURRENT_PASSWORD_INVALID') {
            (0, utilities_1.joAlert)('Error', 'The current password you entered does not match our records');
            return;
        }
        this.store.saving = false;
        await (0, utilities_1.joAlert)('Success!', 'Password successfully updated');
        (0, utilities_1.navigate)(`/`);
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .jo-change-password--main-container {
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;

                    font-family: sans-serif;
                    color: var(--jo-text-default);
                    font-weight: 200;
                }

                .jo-change-password--info-card {
                    box-sizing: border-box;
                    width: 100%;
                    box-shadow: 0 0 3px rgb(0, 0, 0, 0.4);
                    background-color: white;
                    border-radius: 6px;
                    padding: 1rem;
                }

                .jo-change-password--info-card-header-container {
                    padding: 0.5rem;
                    box-sizing: border-box;
                    padding-bottom: 0rem;
                }

                .jo-change-password--info-card-header {
                    margin: 0rem;
                    color: rgb(0, 0, 0, 0.6);
                    box-sizing: border-box;
                    font-weight: bold;
                }

                .jo-change-password-info-card-body-content {
                    box-sizing: border-box;
                    padding: 0 1rem 2rem;
                    width: 100%;
                }

                .jo-change-password--info-card-row {
                    box-sizing: border-box;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    justify-items: space-between;
                    margin-top: 1.25rem;
                }

                .jo-change-password--info-card-row input[type='text'] {
                    width: 85%;
                }

                .jo-change-password--save-cancel-container {
                    box-sizing: border-box;
                    padding-top: 2rem;
                    display: flex;
                    width: 100%;
                }
            </style>

            <div class="jo-change-password--main-container">
                <div class="jo-change-password--info-card">
                    <div class="jo-change-password--info-card-header-container">
                        <h3 class="jo-change-password--info-card-header">CHANGE PASSWORD</h3>
                    </div>

                    <div class="jo-change-password-info-card-body-content">
                        <div class="jo-change-password--info-card-row">
                            <jo-input
                                ${(0, test_id_directive_1.testId)('change-user-password-current-input')}
                                .label=${'Current Password'}
                                .type=${'PASSWORD'}
                                .inputValue=${state.inputValues.currentPassword}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                currentPassword: e.detail.inputValue,
            };
        }}
                                @inputErrorChanged=${(e) => this.store.formErrorManager.addOrRemoveFormError(e.detail)}
                                .shouldGenerateError=${false}
                            ></jo-input>
                        </div>

                        <div class="jo-change-password--info-card-row">
                            <jo-input
                                ${(0, test_id_directive_1.testId)('change-user-password-new-input')}
                                .label=${'New Password (12 character minimum)'}
                                .type=${'PASSWORD'}
                                .inputValue=${state.inputValues.newPassword}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                newPassword: e.detail.inputValue,
            };
        }}
                                @inputErrorChanged=${(e) => this.store.formErrorManager.addOrRemoveFormError(e.detail)}
                                .showError=${state.showAllFormErrors}
                            ></jo-input>
                        </div>

                        <div class="jo-change-password--info-card-row ">
                            <jo-input
                                ${(0, test_id_directive_1.testId)('change-user-password-confirm-input')}
                                .label=${'Confirm Password'}
                                .type=${'PASSWORD_CONFIRM'}
                                @inputErrorChanged=${(e) => this.store.formErrorManager.addOrRemoveFormError(e.detail)}
                                .inputValue=${state.inputValues.confirmPassword}
                                .required=${true}
                                .passwordToCompareTo=${state.inputValues.newPassword}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                confirmPassword: e.detail.inputValue,
            };
        }}
                                .showError=${state.showAllFormErrors}
                            ></jo-input>
                        </div>

                        <div class="jo-change-password--save-cancel-container">
                            <jo-button
                                ${(0, test_id_directive_1.testId)('change-user-password-save-button')}
                                style="padding-right: 1rem"
                                .type=${'ACTION_BUTTON_1'}
                                .text=${state.saving === true ? 'Saving...' : 'Save'}
                                .disabled=${state.saving === true}
                                @joButtonClick=${() => this.saveButtonClicked()}
                            ></jo-button>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-change-user-password', JOChangeUserPassword);
async function updatePassword(userId, currentPassword, newPassword) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($userId: Int!, $currentPassword: String!, $newPassword: String!) {
                    changeOwnPassword(
                        userId: $userId
                        currentPassword: $currentPassword
                        newPassword: $newPassword
                    )
                }
            `, {
            userId: userId,
            newPassword: newPassword,
            currentPassword: currentPassword,
        });
        if (gqlResult.succeeded === false) {
            return {
                ...gqlResult,
                developerMessage: `updatePassword error: ${gqlResult.developerMessage}`,
            };
        }
        const changePasswordResult = gqlResult.value.data.changeOwnPassword;
        return {
            succeeded: true,
            value: changePasswordResult,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('updatePassword', error);
    }
}
