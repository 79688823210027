"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../../services/utilities");
require("../../jo-button.element");
require("../../jo-input");
const store_1 = require("../../../../services/store");
const styles_1 = require("./styles");
const css_utils_1 = require("../../../../utilities/css-utils");
const get_sorted_service_input_values_1 = require("./helper-utils/get-sorted-service-input-values");
const is_input_values_empty_1 = require("./helper-utils/is-input-values-empty");
const swap_items_in_array_1 = require("../../../../utilities/data-structures/arrays/swap-items-in-array");
const no_scroll_on_focus_1 = require("../../../../utilities/input-event-listeners/no-scroll-on-focus");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
const InitialState = {
    authenticatedUser: store_1.InitialGlobalState.authenticatedUser,
    company: 'NOT_SET',
    inputValues: {
        servicesInputValues: [],
        servicesNotProvided: '',
    },
    isMobileDevice: false,
    loading: true,
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-company-services-pricing-services');
class JOCompanyServicesPricingServices extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, (state, action) => {
            if (action.type === 'RESET_STATE') {
                return {
                    ...InitialState,
                    isMobileDevice: state.isMobileDevice,
                };
            }
            return state;
        });
    }
    set company(company) {
        if (this.store.company === company) {
            return;
        }
        this.store.dispatch({
            type: 'RESET_STATE',
        });
        this.store.company = company;
        if (this.store.company !== 'NOT_SET') {
            this.inputValues = {
                ...this.store.inputValues,
                servicesInputValues: (0, get_sorted_service_input_values_1.getSortedServiceInputValues)(this.store.company.services.map(service => {
                    return {
                        symbol: Symbol(),
                        id: service.id,
                        archived: service.archived,
                        description: service.description,
                        display_position: service.display_position,
                        estimates: service.estimates,
                        forms: service.forms,
                        jobs: service.jobs,
                        name: service.name,
                        pricing: service.pricing,
                        price_minimum: service.price_minimum,
                        save_without_scheduling: service.save_without_scheduling,
                    };
                })),
                servicesNotProvided: this.store.company.services_not_provided,
            };
        }
    }
    set inputValues(inputValues) {
        this.store.loading = true;
        this.store.inputValues = inputValues;
        (0, utilities_1.raiseCustomEvent)(this, 'inputvalueschanged', this.store.inputValues);
        this.store.loading = false;
    }
    addService() {
        this.inputValues = {
            ...this.store.inputValues,
            servicesInputValues: [
                ...this.store.inputValues.servicesInputValues,
                {
                    symbol: Symbol(),
                    id: 'NOT_SET',
                    archived: false,
                    description: '',
                    display_position: this.store.inputValues.servicesInputValues.length,
                    estimates: false,
                    forms: false,
                    jobs: false,
                    name: '',
                    pricing: false,
                    price_minimum: 0,
                    save_without_scheduling: false,
                },
            ],
        };
    }
    async deleteService(serviceInputValuesToDelete) {
        const confirmed = await (0, utilities_1.joConfirm)('Confirm', 'Are you sure you want to delete this service?');
        if (confirmed === false) {
            return;
        }
        if (serviceInputValuesToDelete.id === 'NOT_SET') {
            this.inputValues = {
                ...this.store.inputValues,
                servicesInputValues: this.store.inputValues.servicesInputValues.filter((serviceInputValues) => {
                    return serviceInputValues.symbol !== serviceInputValuesToDelete.symbol;
                }),
            };
        }
        else {
            this.inputValues = {
                ...this.store.inputValues,
                servicesInputValues: this.store.inputValues.servicesInputValues.map((serviceInputValues) => {
                    if (serviceInputValues.symbol === serviceInputValuesToDelete.symbol) {
                        return {
                            ...serviceInputValues,
                            archived: true,
                        };
                    }
                    return serviceInputValues;
                }),
            };
        }
    }
    moveServiceItem(index, direction) {
        let serviceInputValues = [...this.store.inputValues.servicesInputValues];
        (0, swap_items_in_array_1.swapItemsInArray)(serviceInputValues, index, direction);
        this.setServiceInputValuesInState(serviceInputValues);
        (0, utilities_1.raiseCustomEvent)(this, 'inputvalueschanged', this.store.inputValues);
    }
    setServiceInputValuesInState(serviceInputValues) {
        this.inputValues = {
            ...this.store.inputValues,
            servicesInputValues: [...serviceInputValues],
        };
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

            ${styles_1.JOCompanyServicesPricingServicesStyles}

            <style>
                .${cssName('col-1')} {
                    flex: ${state.isMobileDevice ? `33%` : `1`};
                    flex-direction: column;
                    display: flex;
                    align-items: center;
                    padding: 0.5rem 0;
                }

                .${cssName('new-service-title-input')} {
                    width: ${state.isMobileDevice ? `85%` : `50%`};
                }

                .${cssName('add-button')} {
                    padding-right: ${state.isMobileDevice ? `1%` : `1rem`};
                    width: ${state.isMobileDevice ? `50%` : `200px`};
                }

                .${cssName('column-title')} {
                    color: var(--jo-primary);
                    font-weight: bold;
                    text-align: center;
                    font-size: ${state.isMobileDevice ? `0.8rem` : `1rem`};
                }

                .${cssName('service-description')} {
                    width: ${state.isMobileDevice ? `85%` : `50%`};
                    resize: none;
                    height: 4rem;
                }
            </style>

            <div class="${cssName('main-container')}">
                <jo-loading
                    .props=${{
            loading: state.loading,
        }}
                ></jo-loading>
                <div ?hidden=${state.isMobileDevice} class="jo-global--card-title">SERVICES</div>
                <div
                    class="${cssName('add-service-button-container')}"
                    ?hidden=${(0, utilities_1.shouldHideFromCompanyUser)(this.store.authenticatedUser)}
                >
                    <jo-button
                        class="${cssName('add-button')}"
                        .type=${'ACTION_BUTTON_2'}
                        .text=${'Add service'}
                        @joButtonClick=${() => this.addService()}
                    ></jo-button>

                    <jo-button
                        class="${cssName('add-button')}"
                        .type=${'ACTION_BUTTON_2'}
                        .text=${'Delete all services'}
                        @joButtonClick=${() => (0, utilities_1.raiseCustomEvent)(this, 'deleteAllServicesClicked')}
                    ></jo-button>
                </div>

                ${state.inputValues.servicesInputValues.map((serviceInputValues, outerIndex) => {
            return (0, lit_html_1.html) `
                            <div class="${cssName('row')}">
                                <div class="${cssName('new-service-title-input')}">
                                    <jo-input
                                        .readonly=${(0, utilities_1.shouldHideFromCompanyUser)(this.store.authenticatedUser)}
                                        style="width: 100%"
                                        .type=${'TEXT_INPUT'}
                                        .inputValue=${serviceInputValues.name}
                                        @inputchange=${(e) => {
                this.inputValues = {
                    ...this.store.inputValues,
                    servicesInputValues: this.store.inputValues.servicesInputValues.map((serviceInputValues, innerIndex) => {
                        if (outerIndex === innerIndex) {
                            return {
                                ...serviceInputValues,
                                name: e.detail.inputValue,
                            };
                        }
                        return serviceInputValues;
                    }),
                };
            }}
                                    ></jo-input>
                                </div>
                                <div
                                    class="${cssName('delete-icon-wrapper')}"
                                    ?hidden=${(0, utilities_1.shouldHideFromCompanyUser)(this.store.authenticatedUser)}
                                >
                                    <jo-button
                                        .type=${'ICON'}
                                        .icon=${'delete'}
                                        @click=${() => this.deleteService(serviceInputValues)}
                                    ></jo-button>

                                    <i
                                        class="material-icons ${cssName('arrow')}"
                                        @click=${(e) => {
                this.moveServiceItem(outerIndex, 'Up');
            }}
                                    >
                                        keyboard_arrow_up
                                    </i>

                                    <i
                                        class="material-icons ${cssName('arrow')}"
                                        @click=${() => {
                this.moveServiceItem(outerIndex, 'Down');
            }}
                                    >
                                        keyboard_arrow_down
                                    </i>
                                </div>
                            </div>

                            <textarea
                                class="jo-global--input ${cssName('service-description')} jo-global--is-disabled"
                                placeholder="${serviceInputValues.name} Service Description"
                                .value=${serviceInputValues.description}
                                ?disabled=${(0, utilities_1.shouldHideFromCompanyUser)(this.store.authenticatedUser)}
                                @input=${(e) => {
                this.inputValues = {
                    ...this.store.inputValues,
                    servicesInputValues: this.store.inputValues.servicesInputValues.map((serviceInputValues, innerIndex) => {
                        if (outerIndex === innerIndex) {
                            return {
                                ...serviceInputValues,
                                description: e.target.value,
                            };
                        }
                        return serviceInputValues;
                    }),
                };
            }}
                            ></textarea>
                            <div class="${cssName('row')}">
                                <div class="${cssName('col-1')}">
                                    <div class="${cssName('column-title')}">Estimates</div>
                                    <input
                                        ?disabled=${(0, utilities_1.shouldHideFromCompanyUser)(this.store.authenticatedUser)}
                                        type="checkbox"
                                        .checked=${serviceInputValues.estimates}
                                        @change=${(e) => {
                this.inputValues = {
                    ...this.store.inputValues,
                    servicesInputValues: this.store.inputValues.servicesInputValues.map((serviceInputValues, innerIndex) => {
                        if (outerIndex === innerIndex) {
                            return {
                                ...serviceInputValues,
                                estimates: e.target.checked,
                            };
                        }
                        return serviceInputValues;
                    }),
                };
            }}
                                    />
                                </div>
                                <div class="${cssName('col-1')}">
                                    <div class="${cssName('column-title')}">Jobs</div>
                                    <input
                                        ?disabled=${(0, utilities_1.shouldHideFromCompanyUser)(this.store.authenticatedUser)}
                                        type="checkbox"
                                        .checked=${serviceInputValues.jobs}
                                        @change=${(e) => {
                this.inputValues = {
                    ...this.store.inputValues,
                    servicesInputValues: this.store.inputValues.servicesInputValues.map((serviceInputValues, innerIndex) => {
                        if (outerIndex === innerIndex) {
                            return {
                                ...serviceInputValues,
                                jobs: e.target.checked,
                            };
                        }
                        return serviceInputValues;
                    }),
                };
            }}
                                    />
                                </div>
                                <div class="${cssName('col-1')}">
                                    <div class="${cssName('column-title')}">Pricing</div>
                                    <input
                                        ?disabled=${(0, utilities_1.shouldHideFromCompanyUser)(this.store.authenticatedUser)}
                                        type="checkbox"
                                        .checked=${serviceInputValues.pricing}
                                        @change=${(e) => {
                this.inputValues = {
                    ...this.store.inputValues,
                    servicesInputValues: this.store.inputValues.servicesInputValues.map((serviceInputValues, innerIndex) => {
                        if (outerIndex === innerIndex) {
                            return {
                                ...serviceInputValues,
                                pricing: e.target.checked,
                            };
                        }
                        return serviceInputValues;
                    }),
                };
            }}
                                    />
                                </div>
                                <div
                                    class="${cssName('col-1')}"
                                    ?hidden=${(0, utilities_1.shouldHideFromCompanyUser)(this.store.authenticatedUser)}
                                >
                                    <div class="${cssName('column-title')}">
                                        Save without Scheduling
                                    </div>
                                    <input
                                        type="checkbox"
                                        .checked=${serviceInputValues.save_without_scheduling}
                                        @change=${(e) => {
                this.inputValues = {
                    ...this.store.inputValues,
                    servicesInputValues: this.store.inputValues.servicesInputValues.map((serviceInputValues, innerIndex) => {
                        if (outerIndex === innerIndex) {
                            return {
                                ...serviceInputValues,
                                save_without_scheduling: e.target.checked,
                            };
                        }
                        return serviceInputValues;
                    }),
                };
            }}
                                    />
                                </div>
                                <div class="${cssName('col-1')}">
                                    <div class="${cssName('column-title')}">Forms</div>
                                    <input
                                        ?disabled=${(0, utilities_1.shouldHideFromCompanyUser)(this.store.authenticatedUser)}
                                        type="checkbox"
                                        .checked=${serviceInputValues.forms}
                                        @change=${(e) => {
                this.inputValues = {
                    ...this.store.inputValues,
                    servicesInputValues: this.store.inputValues.servicesInputValues.map((serviceInputValues, innerIndex) => {
                        if (outerIndex === innerIndex) {
                            return {
                                ...serviceInputValues,
                                forms: e.target.checked,
                            };
                        }
                        return serviceInputValues;
                    }),
                };
            }}
                                    />
                                </div>
                                <div class="${cssName('col-1')}">
                                    <div class="${cssName('column-title')}">Minimum</div>
                                    <input
                                        ?disabled=${(0, utilities_1.shouldHideFromCompanyUser)(this.store.authenticatedUser)}
                                        class="${cssName('price-minimum-input')}"
                                        type="number"
                                        .value=${(serviceInputValues.price_minimum / 100).toString()}
                                        min="0"
                                        @focus=${(e) => {
                (0, no_scroll_on_focus_1.noScrollOnFocus)(e);
            }}
                                        @change=${(e) => {
                this.inputValues = {
                    ...this.store.inputValues,
                    servicesInputValues: this.store.inputValues.servicesInputValues.map((serviceInputValues, innerIndex) => {
                        if (outerIndex === innerIndex) {
                            return {
                                ...serviceInputValues,
                                price_minimum: (0, utilities_1.formatMoneyInUSDCents)(Number(e.target.value)),
                            };
                        }
                        return serviceInputValues;
                    }),
                };
            }}
                                    />
                                </div>
                            </div>
                            <br />
                            <div class="${cssName('service-divider-border')}"></div>
                        `;
        })}

                <div
                    class="${cssName('services-not-provided-container')}"
                    ?hidden=${(0, is_input_values_empty_1.isInputValuesEmpty)(this.store.inputValues)}
                >
                    <div class="${cssName('services-not-provided-text')}">
                        Services not provided
                    </div>
                    <div class="${cssName('services-not-provided-textarea-container')}">
                        <textarea
                            ?disabled=${(0, utilities_1.shouldHideFromCompanyUser)(this.store.authenticatedUser)}
                            class="${cssName('services-not-provided-textarea')} jo-global--input jo-global--is-disabled"
                            .value=${state.inputValues.servicesNotProvided}
                            @input=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                servicesNotProvided: e.target.value,
            };
        }}
                        ></textarea>
                    </div>
                </div>

                <div
                    class="jo-global--emptyIconContainer"
                    ?hidden=${(0, is_input_values_empty_1.isInputValuesEmpty)(this.store.inputValues) === false}
                >
                    <i class="material-icons ${cssName('emptyIcon')}">support_agent</i>
                    <h3>Services have not been added.</h3>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-services-pricing-services', JOCompanyServicesPricingServices);
