"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.storedPathBeforeLogin = void 0;
const local_storage_vir_1 = require("local-storage-vir");
const pathsToIgnore = [
    '/login',
];
exports.storedPathBeforeLogin = (0, local_storage_vir_1.createLocalStorageAccessor)({
    key: 'stored-path-before-login',
    setSanitizer: path => {
        if (path === '/' ||
            pathsToIgnore.find(pathToIgnore => pathToIgnore === path || path.startsWith(`${pathToIgnore}?`))) {
            return undefined;
        }
        return path;
    },
});
