"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getColumns = void 0;
const lit_html_1 = require("lit-html");
const twilio_1 = require("../../../services/twilio");
const utilities_1 = require("../../../services/utilities");
const auth_rules_1 = require("../../../utilities/auth-rules");
const user_status_event_mutations_1 = require("../../../utilities/logging-and-monitoring/user-status-event-mutations");
const helper_utils_1 = require("./helper-utils");
function getColumns(element, state) {
    const isCompanyUser = (0, utilities_1.getIsCompanyUser)(state.authenticatedUser, state.companyId);
    const columns = [
        {
            title: 'Actions',
            getCellData: (actionItem) => getActionsHtml(isCompanyUser, actionItem, state, element),
            width: 1,
        },
        {
            title: 'Title',
            getCellData: (actionItem) => getTitleHtml(actionItem, state),
            width: 1.5,
            sortFieldName: 'TITLE',
        },
        {
            title: 'Contact First Name',
            getCellData: (actionItem) => actionItem.contact?.first_name,
            width: 1,
            sortFieldName: 'CONTACT_FIRST_NAME',
        },
        {
            title: 'Contact Last Name',
            getCellData: (actionItem) => actionItem.contact?.last_name,
            width: 1,
            sortFieldName: 'CONTACT_LAST_NAME',
        },
        {
            title: 'Contact Company Name',
            getCellData: (actionItem) => actionItem.contact?.company_name,
            width: 1,
            sortFieldName: 'CONTACT_COMPANY_NAME',
        },
        {
            title: 'Contact Phone Number',
            getCellData: (actionItem) => (0, utilities_1.formatE164PhoneNumberForDisplay)(actionItem.contact?.phone_number),
            width: 1,
            sortFieldName: 'CONTACT_PHONE_NUMBER',
        },
        {
            title: 'Category',
            getCellData: (actionItem) => actionItem.action_item_status_type?.name,
            width: 1,
            sortFieldName: 'ACTION_ITEM_STATUS_TYPE_NAME',
        },
        {
            title: 'Updated',
            getCellData: (actionItem) => new Date(actionItem.updated_at).toLocaleString(),
            width: 1,
            sortFieldName: 'UPDATED_AT',
        },
        {
            title: 'Created',
            getCellData: (actionItem) => new Date(actionItem.created_at).toLocaleString(),
            width: 1,
            sortFieldName: 'CREATED_AT',
        },
        {
            title: (0, lit_html_1.html) `
                <input
                    type="checkbox"
                    @change=${(e) => {
                element.areAllActionItemsSelected = e.target.checked;
            }}
                    .checked=${state.selectedActionItems.length === 0
                ? false
                : state.areAllActionItemsSelected}
                />
                Select All
            `,
            getCellData: (actionItem) => {
                // TODO making checkboxes inside of jo input would solve the issue of the change event not firing when set programmatically
                const actionItemIsSelected = state.selectedActionItems.some((actionItemInState) => {
                    return actionItemInState.id === actionItem.id;
                });
                return (0, lit_html_1.html) `
                    <input
                        type="checkbox"
                        .checked=${actionItemIsSelected}
                        @change=${(e) => {
                    element.actionItemCheckboxSelected(e, actionItem);
                }}
                    />
                `;
            },
            width: 0.75,
        },
    ];
    return columns;
}
exports.getColumns = getColumns;
function getTitleHtml(actionItem, state) {
    return actionItem.chat
        ? (0, lit_html_1.html) `
              <div
                  style="
            display:flex;
            flex-direction:row;
            gap:0.5rem;
        "
              >
                  <i
                      class="material-icons-outlined"
                      style="
                    font-size:1rem;
                "
                  >
                      forum
                  </i>
                  CHAT: ${actionItem.title}
              </div>
          `
        : (0, lit_html_1.html) `
              ${actionItem.title}
          `;
}
function getActionsHtml(isCompanyUser, actionItem, state, element) {
    return (0, lit_html_1.html) `
        <div class="jo-action-items--actions">
            <div
                ?hidden=${(0, helper_utils_1.shouldHideUnreadActionItemIndicator)(actionItem, state.authenticatedUser)}
                style="padding: 0.5rem"
            >
                <div
                    style="
                    width: 10px; 
                    height: 10px; 
                    border-radius: 50%; 
                    background-color: var(--jo-secondary);     
                "
                ></div>
            </div>

            <jo-button
                class="jo-action-items--red-eye-button"
                .type=${'ICON'}
                .icon=${'remove_red_eye'}
                .href=${isCompanyUser
        ? `/customer/action-item?actionItemId=${actionItem.id}&customerActionItemMode=EDIT`
        : `/action-item?actionItemId=${actionItem.id}&companyId=${state.companyId}&callScreenMode=ACTION_ITEM`}
                .inlineStyle=${'font-size: 1.3rem'}
                style="padding-right: 0.5rem"
                @click=${() => {
        markActionItemAsOpenedForCompanyUser(state.authenticatedUser, actionItem.id, state.companyId, element);
    }}
            ></jo-button>

            <jo-button
                class="jo-action-items--action"
                .type=${'ICON'}
                .icon=${'phone'}
                .inlineStyle=${'font-size: 1.3rem'}
                @joButtonClick=${() => {
        (0, utilities_1.assertIsSet)(state.companyId, utilities_1.handleError, 'state.companyId');
        (0, utilities_1.assertIsDefined)(actionItem.contact, 'actionItem.contact', utilities_1.handleError);
        const phoneNumberResult = (0, utilities_1.getPhoneNumberForOutgoingCallForActionItem)(actionItem);
        (0, utilities_1.assertSucceeded)(phoneNumberResult, utilities_1.handleError);
        const phoneNumber = phoneNumberResult.value;
        (0, utilities_1.assertIsSet)(state.authenticatedUser, utilities_1.handleError, 'state.authenticatedUser');
        (0, twilio_1.startOutgoingCall)(actionItem.id, actionItem.contact.id, state.companyId, phoneNumber, state.authenticatedUser.id);
    }}
            ></jo-button>

            <div class="jo-action-items--action-item-preview-main-container">
                <jo-button
                    ?hidden=${shouldHideCloseButton(element.store.authenticatedUser) === true}
                    class="jo-action-items--action"
                    .type=${'ICON'}
                    .icon=${'preview'}
                    .inlineStyle=${'font-size: 1.3rem'}
                    @joButtonClick=${() => {
        if (element.store.selectedPreviewActionItemId === actionItem.id) {
            element.store.selectedPreviewActionItemId = 'NOT_SET';
        }
        else {
            element.store.selectedPreviewActionItemId = actionItem.id;
        }
    }}
                ></jo-button>

                <jo-fade-in-overlay
                    .child=${(0, lit_html_1.html) `
                        <div class="jo-global--action-item-preview-container">
                            <jo-action-item-preview
                                .props=${{
        actionItem,
        authenticatedUser: state.authenticatedUser,
        quickNotes: state.quickNotes,
    }}
                                @saved=${() => (element.store.selectedPreviewActionItemId = 'NOT_SET')}
                                @senttocompany=${() => (element.store.selectedPreviewActionItemId = 'NOT_SET')}
                            ></jo-action-item-preview>
                        </div>
                    `}
                    .showChild=${actionItem.id === state.selectedPreviewActionItemId}
                    @closeoverlay=${() => (element.store.selectedPreviewActionItemId = 'NOT_SET')}
                ></jo-fade-in-overlay>
            </div>

            ${actionItem.urgent === true
        ? (0, lit_html_1.html) `
                      <div class="jo-action-items--action">
                          <a class="material-icons jo-action-items--urgent-icon">error</a>
                      </div>
                  `
        : ''}
        </div>
    `;
}
function shouldHideCloseButton(authUser) {
    if (authUser === 'NOT_SET') {
        return false;
    }
    return (0, auth_rules_1.isCompanyUser)(authUser);
}
async function markActionItemAsOpenedForCompanyUser(user, actionItemId, companyId, element) {
    if (user === 'NOT_SET' || companyId === 'NOT_SET') {
        throw new Error('markActionItemAsReadForUser error');
    }
    if ((0, utilities_1.userIsJill)(user)) {
        return;
    }
    await (0, user_status_event_mutations_1.createUserStatusEvent)({
        userId: user.id,
        eventType: 'OPENED_ACTION_ITEM',
        companyId: companyId,
        actionItemId,
    });
    element.triggerTableReload();
}
