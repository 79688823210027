"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getActionItemsForAdminTable = void 0;
const common_1 = require("@augment-vir/common");
const graphql_1 = require("../../../services/graphql");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function getActionItemsForAdminTable(authenticatedUser, offset, limit) {
    const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query ($offset: Int!, $limit: Int!, $filter: Action_itemsFilter!) {
                findAction_items(
                    filter: $filter
                    page: {offset: $offset, limit: $limit}
                    orderBy: {field: "updated_at", order: ASC}
                ) {
                    items {
                        id
                        action_item_notes(orderBy: {field: "created_at", order: DESC}) {
                            id
                            action_item {
                                id
                            }
                            author {
                                id
                                auth_role
                                email
                                first_name
                            }
                            body
                            created_at
                        }
                        call {
                            id
                            new_direction
                            origin_phone
                            destination_phone
                            recording_url
                        }
                        company {
                            id
                            name
                            pod {
                                id
                                name
                            }
                            timezone
                        }
                        contact {
                            id
                            first_name
                            last_name
                            phone_number
                            phone_numbers(orderBy: {field: "created_at", order: ASC}) {
                                id
                                number
                                created_at
                            }
                        }
                        title
                        created_at
                        updated_at
                    }
                    total
                }
            }
        `, {
        offset,
        limit,
        filter: makeFilter(authenticatedUser),
    });
    const actionItems = gqlResult.data.findAction_items.items;
    const totalItems = gqlResult.data.findAction_items.total;
    return {
        items: actionItems,
        totalItems,
    };
}
exports.getActionItemsForAdminTable = getActionItemsForAdminTable;
function makeFilter(user) {
    if (user.user_status_events !== undefined &&
        (0, common_1.isLengthAtLeast)(user.user_status_events, 1) &&
        user.user_status_events[0].type === 'BEGAN_ADMIN_MODE' &&
        user.user_status_events[0].company !== undefined &&
        user.user_status_events[0].company !== null) {
        return {
            and: [
                {
                    action_item_status_type_id: {
                        eq: 5, // Sent to personal admin
                    },
                },
                {
                    company_id: {
                        eq: user.user_status_events[0].company.id,
                    },
                },
            ],
        };
    }
    return {
        action_item_status_type_id: {
            eq: 5, // Sent to personal admin
        },
    };
}
