"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tryExecute = void 0;
function tryExecute(errorHandler, fn, ...args) {
    try {
        return fn(...args);
    }
    catch (error) {
        errorHandler(error);
        return 'FAILED';
    }
}
exports.tryExecute = tryExecute;
