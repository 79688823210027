"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOCompanyServicesPricingCustomFieldsStyles = void 0;
const lit_html_1 = require("lit-html");
exports.JOCompanyServicesPricingCustomFieldsStyles = (0, lit_html_1.html) `
    <style>
        .main-container {
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            padding: 0.5rem;
        }

        .add-buttons-container {
            display: flex;
            justify-content: space-around;
            padding-top: 1rem;
        }

        .options-row {
            display: flex;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
        .services-row {
            display: flex;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }

        .checkbox-container {
            display: flex;
            flex: 1;
            flex-direction: row;
            padding-top: 0.5rem;
        }

        .flex-1 {
            flex: 1;
        }

        .header-text {
            color: var(--jo-primary);
            font-weight: bold;
        }

        .options-text {
            color: var(--jo-text-default);
            font-weight: bold;
            font-size: 0.9rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }

        .services-text {
            color: var(--jo-text-default);
            font-weight: bold;
            font-size: 0.9rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }

        .service-name-text {
            color: var(--jo-text-default);
            padding-left: 0.5rem;
        }

        .emptyIcon {
            padding-top: 2rem;
            font-size: 5rem;
        }
    </style>
`;
