"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateSumOfObjectsAtProperty = void 0;
const is_defined_1 = require("../../is-defined");
function calculateSumOfObjectsAtProperty(objects, key) {
    try {
        return objects.reduce((sum, object) => aggregateSum(object, key, sum), 0);
    }
    catch (error) {
        throw new Error(`calculateSumOfObjectsAtProperty error: ${error}`);
    }
}
exports.calculateSumOfObjectsAtProperty = calculateSumOfObjectsAtProperty;
function aggregateSum(object, key, sum) {
    if (!(0, is_defined_1.isDefined)(object)) {
        return sum;
    }
    const value = object[key];
    if (isValidNumber(value)) {
        return sum + value;
    }
    return sum;
}
function isValidNumber(value) {
    return (0, is_defined_1.isDefined)(value) && typeof value === 'number';
}
