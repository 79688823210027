"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stylesForjoCreateOutboundList = void 0;
const lit_html_1 = require("lit-html");
const css_utils_1 = require("../../../../utilities/css-utils");
const cssName = (0, css_utils_1.cssPrefixer)('jo-create-outbound-list');
exports.stylesForjoCreateOutboundList = (() => {
    return (0, lit_html_1.html) `
        <style>
            .${cssName('main-container')} {
                height: 100%;
                width: 100%;
                box-sizing: border-box;
            }
            .${cssName('popUp-background')} {
                position: fixed;
                top: 0;
                left: 0;
                background-color: var(--jo-darkened-background);
                width: 100%;
                height: 100%;
            }
            .${cssName('popUp-container')} {
                position: fixed;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: var(--z-index-3);
                background-color: var(--jo-white);
                border-radius: var(--jo-wide-border-radius-1);
                box-shadow: var(--jo-card-shadow-1);
            }
            .${cssName('close')} {
                position: absolute;
                padding: 0.3rem;
                color: var(--jo-white);
                cursor: pointer;
            }
            .${cssName('close')}:hover {
                color: var(--jo-text-default);
            }
            .${cssName('popUp-title')} {
                background: var(--jo-primary);
                color: white;
                font-weight: bold;
                padding: 0.5rem;
                border-bottom: 1px solid var(--jo-accent-background-light);
                border-top-left-radius: var(--jo-wide-border-radius-1);
                border-top-right-radius: var(--jo-wide-border-radius-1);
                box-sizing: border-box;
                text-align: center;
            }
            .${cssName('popUp-input')} {
                display: flex;
                flex-direction: column;
                padding: 1rem;
                align-items: center;
            }
        </style>
    `;
})();
