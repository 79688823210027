"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStartOfWeek = exports.getStartOfNextWeek = exports.getStartOfNextBillingCycleInMountainTime = void 0;
const dates_and_times_1 = require("./dates-and-times");
function getStartOfNextBillingCycleInMountainTime(anyDateInCycle, type) {
    if (type === 'WEEKLY')
        return getStartOfNextWeekInMountainTime(anyDateInCycle);
    else
        return (0, dates_and_times_1.getStartOfNextMonthForDateInMountainTime)(anyDateInCycle);
}
exports.getStartOfNextBillingCycleInMountainTime = getStartOfNextBillingCycleInMountainTime;
function getStartOfNextWeekInMountainTime(anyDateInWeek) {
    return (0, dates_and_times_1.addMountainTimeOffset)(getStartOfNextWeek(anyDateInWeek));
}
function getStartOfNextWeek(anyDateInWeek) {
    const startOfWeek = getStartOfWeek(anyDateInWeek);
    let date = startOfWeek;
    date.setDate(date.getDate() + 7);
    return date;
}
exports.getStartOfNextWeek = getStartOfNextWeek;
function getStartOfWeek(anyDateInWeek) {
    try {
        if (anyDateInWeek.getDay() === 1) {
            return new Date(anyDateInWeek.getFullYear(), anyDateInWeek.getMonth(), anyDateInWeek.getDate());
        }
        const numDaysBack = anyDateInWeek.getDay() === 0 ? 6 : anyDateInWeek.getDay() - 1;
        const startOfWeek = new Date(anyDateInWeek.getFullYear(), anyDateInWeek.getMonth(), anyDateInWeek.getDate() - numDaysBack);
        return startOfWeek;
    }
    catch (error) {
        throw new Error(`getStartOfWeek error: ${error}`);
    }
}
exports.getStartOfWeek = getStartOfWeek;
