"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeTableColumns = void 0;
const element_vir_1 = require("element-vir");
const utilities_1 = require("../../../services/utilities");
const get_full_name_1 = require("../../../utilities/get-full-name");
const jo_help_ticket_status_element_1 = require("./jo-help-ticket-status.element");
function makeTableColumns({ viewHistoryClicked, updateTicketClicked, }) {
    return [
        {
            title: 'Company',
            getCellData: (helpTicket) => helpTicket.company?.name,
            width: 2,
            sortFieldName: 'COMPANY_NAME',
        },
        {
            title: 'Created by',
            getCellData: (helpTicket) => (0, get_full_name_1.getFullName)(helpTicket.user),
            width: 2,
            sortFieldName: 'USER_FIRST_NAME',
        },
        {
            title: 'Title',
            getCellData: (helpTicket) => helpTicket.title,
            width: 2,
            sortFieldName: 'TITLE',
        },
        {
            title: 'Description',
            getCellData: (helpTicket) => helpTicket.description,
            width: 2,
        },
        {
            title: 'Last Comment',
            getCellData: (helpTicket) => {
                const lastUpdate = getLastUpdate(helpTicket);
                return lastUpdate?.comment ?? '';
            },
            width: 2,
        },
        {
            title: 'Last Updated By',
            getCellData: (helpTicket) => {
                const lastUpdate = getLastUpdate(helpTicket);
                return lastUpdate ? (0, get_full_name_1.getFullName)(lastUpdate.user) : '';
            },
            width: 2,
        },
        {
            title: 'History',
            getCellData: (helpTicket) => {
                return (0, element_vir_1.html) `
                    <a
                        id="view-history"
                        style="cursor: pointer; color: blue; text-decoration: underline"
                        @click=${() => {
                    viewHistoryClicked(helpTicket);
                }}
                    >
                        View History
                    </a>
                `;
            },
            width: 1,
        },
        {
            title: 'Current Status',
            getCellData: (helpTicket) => (0, element_vir_1.html) `
                <${jo_help_ticket_status_element_1.JoHelpTicketStatus}
                    ${(0, element_vir_1.assign)({
                status: helpTicket.current_status,
            })}
                ></${jo_help_ticket_status_element_1.JoHelpTicketStatus}>
            `,
            width: 2,
        },
        {
            title: 'Action Item',
            getCellData: (helpTicket) => {
                if (!helpTicket.action_item || !helpTicket.company)
                    return 'No Action Item';
                return (0, element_vir_1.html) `
                    <a
                        href="${`/action-item?actionItemId=${helpTicket.action_item.id}&companyId=${helpTicket.company.id}&callScreenMode=ACTION_ITEM`}"
                        target="_blank"
                    >
                        View Action Item
                    </a>
                `;
            },
            width: 1,
        },
        {
            title: '',
            getCellData: (helpTicket) => {
                return (0, element_vir_1.html) `
                    <jo-button
                        .text=${'Update'}
                        .type=${'ACTION_BUTTON_2'}
                        @joButtonClick=${() => {
                    updateTicketClicked(helpTicket);
                }}
                    ></jo-button>
                `;
            },
            width: 1,
        },
        {
            title: 'Account Manager',
            getCellData: (helpTicket) => {
                const accountManager = helpTicket.company?.account_manager;
                if (!accountManager)
                    return 'No Account Manager';
                return (0, get_full_name_1.getFullName)(accountManager);
            },
            width: 2,
        },
        {
            title: 'Created',
            getCellData: (helpTicket) => new Date(helpTicket.created_at).toLocaleDateString(),
            width: 1,
            sortFieldName: 'CREATED_AT',
        },
        {
            title: 'Updated',
            getCellData: (helpTicket) => new Date(helpTicket.updated_at).toLocaleDateString(),
            width: 1,
            sortFieldName: 'UPDATED_AT',
        },
    ];
}
exports.makeTableColumns = makeTableColumns;
function getLastUpdate(helpTicket) {
    return (0, utilities_1.sortItemsByTimeStamp)(helpTicket.updates, 'CREATED_AT', 'DESC')[0];
}
