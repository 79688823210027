"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.wasJillSeenOnACallWithinTenSecondsAgo = void 0;
function wasJillSeenOnACallWithinTenSecondsAgo(jill) {
    if (!jill.last_seen_on_a_call)
        return false;
    return jillWasSeenOnACallWithinCertainMillisecondsAgo(jill, 10000);
}
exports.wasJillSeenOnACallWithinTenSecondsAgo = wasJillSeenOnACallWithinTenSecondsAgo;
function jillWasSeenOnACallWithinCertainMillisecondsAgo(jill, milliseconds) {
    return new Date().getTime() - new Date(jill.last_seen_on_a_call).getTime() <= milliseconds;
}
