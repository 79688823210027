"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const constants_1 = require("../../services/constants");
const utilities_1 = require("../../services/utilities");
const css_utils_1 = require("../../utilities/css-utils");
const define_custom_element_1 = require("../../utilities/define-custom-element");
require("./jo-button.element");
require("./jo-input");
const helper_utils_1 = require("./jo-user-shifts/helper-utils");
const InitialState = {
    inputValues: (0, helper_utils_1.makeDefaultNewScheduleItemValues)(),
    mode: 'READ',
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-schedule-selector');
class JOScheduleSelector extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    set inputValues(inputValues) {
        this.store.inputValues = inputValues;
    }
    raiseInputChangedEvent() {
        (0, utilities_1.raiseCustomEvent)(this, 'inputChanged', this.store.inputValues);
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .${cssName('main-container')} {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .${cssName('row')} {
                    padding-top: 1rem;
                    display: flex;
                    gap: 4rem;
                    flex-wrap: wrap;
                }
            </style>

            <div class="${cssName('main-container')}">
                <section ?hidden=${state.mode === 'READ'}>
                    <div class="${cssName('row')}">
                        <div>
                            <jo-input
                                .label=${'Start Day'}
                                .type=${'SELECT'}
                                .selectOptions=${constants_1.daysOfWeek}
                                .selectedValue=${state.inputValues.start_day_of_week}
                                @valueChanged=${(e) => {
            this.store.inputValues.start_day_of_week = e.detail
                .selectedValue;
            this.raiseInputChangedEvent();
        }}
                            ></jo-input>
                        </div>

                        <div>
                            <label>Start Time</label>
                            <input
                                class="jo-global--input"
                                type="time"
                                .value=${getTimeValueForTimeInput(state.inputValues.start_hour, state.inputValues.start_minute)}
                                @change=${(e) => {
            this.startTimeChanged(e.target.value);
        }}
                            />
                        </div>
                    </div>

                    <div class="${cssName('row')}">
                        <div>
                            <jo-input
                                .label=${'End Day'}
                                .type=${'SELECT'}
                                .selectOptions=${constants_1.daysOfWeek}
                                .selectedValue=${state.inputValues.end_day_of_week}
                                @valueChanged=${(e) => {
            this.store.inputValues.end_day_of_week = e.detail
                .selectedValue;
            this.raiseInputChangedEvent();
        }}
                            ></jo-input>
                        </div>

                        <div>
                            <label>End Time</label>
                            <input
                                class="jo-global--input"
                                type="time"
                                .value=${getTimeValueForTimeInput(state.inputValues.end_hour, state.inputValues.end_minute)}
                                @change=${(e) => {
            this.endTimeChanged(e.target.value);
        }}
                            />
                        </div>
                    </div>
                </section>
            </div>
        `;
    }
    endTimeChanged(rawTargetValue) {
        const [endHour, endMinute,] = getHourAndMinute(rawTargetValue);
        if (endHour == null) {
            throw new Error('endHour is null');
        }
        if (endMinute == null) {
            throw new Error('endMinute is null');
        }
        this.store.inputValues.end_hour = endHour;
        this.store.inputValues.end_minute = endMinute;
        this.raiseInputChangedEvent();
    }
    startTimeChanged(rawTargetValue) {
        const [startHour, startMinute,] = getHourAndMinute(rawTargetValue);
        if (startHour == null) {
            throw new Error('startHour is null');
        }
        if (startMinute == null) {
            throw new Error('startMinute is null');
        }
        this.store.inputValues.start_hour = startHour;
        this.store.inputValues.start_minute = startMinute;
        this.raiseInputChangedEvent();
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-schedule-selector', JOScheduleSelector);
function getHourAndMinute(rawTargetValue) {
    return rawTargetValue.split(':').map(Number);
}
function getTimeValueForTimeInput(hour, minute) {
    return getTwoDigitValue(hour) + ':' + getTwoDigitValue(minute);
}
function getTwoDigitValue(value) {
    return value < 10 ? `0${value}` : `${value}`;
}
