"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOChatsSimpleDisplay = void 0;
const lit_html_1 = require("lit-html");
const utilities_1 = require("../../../services/utilities");
const css_utils_1 = require("../../../utilities/css-utils");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const cssName = (0, css_utils_1.cssPrefixer)('jo-chats-simple-display');
class JOChatsSimpleDisplay extends HTMLElement {
    set props(props) {
        const rows = constructRowData(props);
        (0, lit_html_1.render)((0, lit_html_1.html) `
                <style>
                    .${cssName('chat-stats-table')} {
                        max-width: 70%;
                    }

                    .${cssName('chat-stats-table')} > tr {
                        max-width: 70%;
                    }

                    .${cssName('chat-stats-table')} > tr:nth-child(even) {
                        background-color: white;
                    }

                    .${cssName('chat-stats-table')} > tr:nth-child(odd) {
                        background-color: lightgray;
                    }

                    .${cssName('chat-stats-table')} > tr:hover {
                        background-color: var(--jo-primary);
                    }
                </style>

                <table class="${cssName('chat-stats-table')}">
                    ${rows.map(row => (0, lit_html_1.html) `
                            <tr>
                                <td>${row.title}</td>
                                <td>${row.value}</td>
                            </tr>
                        `)}
                </table>
            `, this);
    }
}
exports.JOChatsSimpleDisplay = JOChatsSimpleDisplay;
(0, define_custom_element_1.defineCustomElement)('jo-chats-simple-display', JOChatsSimpleDisplay);
function constructRowData(props) {
    return [
        {
            title: 'Start Date',
            value: props.startDate.toLocaleString(),
        },
        {
            title: 'End Date',
            value: props.endDate.toLocaleString(),
        },
        {
            title: 'Jill',
            value: props.jill ? (0, utilities_1.getUserFullName)(props.jill) : 'All',
        },
        {
            title: 'Company',
            value: props.company?.name ?? 'All',
        },
        {
            title: 'Pod',
            value: props.pod?.name ?? 'All',
        },
        {
            title: 'Total Chats Completed',
            value: props.chatStats.count,
        },
        {
            title: 'Avg. Time Until Assigned to Jill',
            value: (0, utilities_1.millisecondsToHoursMinutesSeconds)(props.chatStats.avgTimeTillAssignedToJill ?? 0),
        },
        {
            title: 'Avg. Total Time',
            value: (0, utilities_1.millisecondsToHoursMinutesSeconds)(props.chatStats.avgTotalTime ?? 0),
        },
        {
            title: 'Avg. Time Between First Assigned to Jill and Completed',
            value: (0, utilities_1.millisecondsToHoursMinutesSeconds)(props.chatStats.avgTimeBetweenFirstAssignedAndCompleted ?? 0),
        },
        {
            title: 'Total Messages From Contact(s)',
            value: props.chatStats.totalMessagesFromContacts ?? 0,
        },
        {
            title: 'Total Messages From Jill(s)',
            value: props.chatStats.totalMessagesFromJills ?? 0,
        },
        {
            title: 'Avg. # Messages From Contact(s)',
            value: props.chatStats.avgNumMessagesFromContacts ?? 0,
        },
        {
            title: 'Avg. # Messages From Jill(s)',
            value: props.chatStats.avgNumMessagesFromJills ?? 0,
        },
        {
            title: '# New Appointments',
            value: props.chatStats.totalNewAppointments ?? 0,
        },
    ];
}
