"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setDefaultChatSettingsForCompany = exports.updateCompanyStatusEvent = exports.cancelCompany = exports.createCalendar = exports.createPhoneService = exports.updateCompanyBillingInfo = exports.removePersonalAdminFromCompany = exports.updateCompanyMainInfo = exports.updateOwnerPhoneNumber = exports.addNewAddressToCompany = exports.createAddress = exports.createAddressAndAssignToCompany = exports.updateCompanyAddress = void 0;
const graphql_1 = require("../../../services/graphql");
const utilities_1 = require("../../../services/utilities");
const fetch_personal_admin_1 = require("../../../utilities/action-items/fetch-personal-admin");
const error_handling_1 = require("../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function updateCompanyAddress(company, line_1, line_2, city, state, zipCode) {
    try {
        if (company.address === null || company.address === undefined) {
            const newAddressResult = await createAddressAndAssignToCompany(line_1, line_2, city, state, zipCode, company.id);
            return newAddressResult;
        }
        else {
            const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                    mutation (
                        $addressId: Int!
                        $line_1: String!
                        $line_2: String!
                        $city: String!
                        $state: String!
                        $zipCode: String!
                    ) {
                        updateAddresses(
                            input: {
                                id: $addressId
                                line_1: $line_1
                                line_2: $line_2
                                city: $city
                                state: $state
                                zip_code: $zipCode
                            }
                        ) {
                            id
                        }
                    }
                `, {
                addressId: company.address.id,
                line_1,
                line_2,
                city,
                state,
                zipCode,
            });
            if (gqlResult.succeeded === false) {
                return {
                    ...gqlResult,
                    developerMessage: `updateCompanyAddress error: ${gqlResult.developerMessage}`,
                };
            }
            return { succeeded: true };
        }
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('updateCompanyAddress', error);
    }
}
exports.updateCompanyAddress = updateCompanyAddress;
async function createAddressAndAssignToCompany(line_1, line_2, city, state, zipCode, companyId) {
    try {
        const newAddressResult = await createAddress(line_1, line_2, city, state, zipCode);
        if (newAddressResult.succeeded === false) {
            return {
                succeeded: false,
                userMessage: newAddressResult.userMessage,
                developerMessage: newAddressResult.developerMessage,
            };
        }
        const addNewAddressToCompanyResult = await addNewAddressToCompany(companyId, newAddressResult.value.id);
        if (addNewAddressToCompanyResult.succeeded === false) {
            return {
                succeeded: false,
                userMessage: addNewAddressToCompanyResult.userMessage,
                developerMessage: addNewAddressToCompanyResult.developerMessage,
            };
        }
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('createAddressAndAssignToCompany', error);
    }
}
exports.createAddressAndAssignToCompany = createAddressAndAssignToCompany;
async function createAddress(line_1, line_2, city, state, zipCode) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation (
                    $line_1: String!
                    $line_2: String!
                    $city: String!
                    $state: String!
                    $zipCode: String!
                ) {
                    createAddresses(
                        input: {
                            line_1: $line_1
                            line_2: $line_2
                            city: $city
                            county: ""
                            postal_code: ""
                            province: ""
                            state: $state
                            zip_code: $zipCode
                        }
                    ) {
                        id
                        line_1
                        line_2
                        city
                        state
                    }
                }
            `, {
            line_1,
            line_2,
            city,
            state,
            zipCode,
        });
        if (gqlResult.succeeded === false) {
            return {
                ...gqlResult,
                developerMessage: `createAddress error: ${gqlResult.developerMessage}`,
            };
        }
        return {
            succeeded: true,
            value: gqlResult.value.data.createAddresses,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('createAddress', error);
    }
}
exports.createAddress = createAddress;
async function addNewAddressToCompany(companyId, addressId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($companyId: Int!, $addressId: Int!) {
                    updateCompanies(input: {id: $companyId, address: {connect: {id: $addressId}}}) {
                        id
                    }
                }
            `, {
            companyId,
            addressId,
        });
        if (gqlResult.succeeded === false) {
            return {
                ...gqlResult,
                developerMessage: `addNewAddressToCompany error: ${gqlResult.developerMessage}`,
            };
        }
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('addNewAddressToCompany', error);
    }
}
exports.addNewAddressToCompany = addNewAddressToCompany;
async function updateOwnerPhoneNumber(company, newOwnerPhoneNumber) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($ownerId: Int!, $newOwnerPhoneNumber: String) {
                    updateUsers(input: {id: $ownerId, phone_number: $newOwnerPhoneNumber}) {
                        id
                    }
                }
            `, {
            ownerId: company.organization?.owner?.id,
            newOwnerPhoneNumber: newOwnerPhoneNumber,
        });
        if (gqlResult.succeeded === false) {
            return {
                ...gqlResult,
                developerMessage: `updateOwnerPhoneNumber error: ${gqlResult.developerMessage}`,
            };
        }
        return {
            succeeded: true,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('updateOwnerPhoneNumber', error);
    }
}
exports.updateOwnerPhoneNumber = updateOwnerPhoneNumber;
async function updateCompanyMainInfo(params) {
    try {
        const { company, accountManagerId, personalAdminId, phoneServiceIdToConnect, phoneServiceIdToDisconnect, calendarIdToConnect, calendarIdToDisconnect, podId, companyContactInfoInputValues, companyJillnInfoInputValues, internalNotes, } = params;
        if (personalAdminId === null) {
            const removePersonalAdminResult = await removePersonalAdminFromCompany(company.id);
            (0, utilities_1.assertSucceeded)(removePersonalAdminResult, utilities_1.handleError);
        }
        const input = {
            id: company.id,
            account_manager: {
                connect: {
                    id: accountManagerId,
                },
            },
            action_item_creation_email: companyJillnInfoInputValues.actionItemCreationEmailInputValue,
            business_number: companyContactInfoInputValues.companyPhoneNumberInputValue,
            callbacks_allowed: companyJillnInfoInputValues.callbacksAllowedInputValue,
            fax: companyContactInfoInputValues.faxInputValue,
            industry: companyContactInfoInputValues.industrySelectedValue,
            mailing_address: companyContactInfoInputValues.mailingAddressInputValue,
            name: companyContactInfoInputValues.companyNameInputValue,
            niche: companyContactInfoInputValues.companyNicheInputValue,
            personal_admin: {
                connect: {
                    id: personalAdminId,
                },
            },
            phone_service: {
                connect: { id: phoneServiceIdToConnect },
                disconnect: { id: phoneServiceIdToDisconnect },
            },
            calendar: {
                connect: { id: calendarIdToConnect },
                disconnect: { id: calendarIdToDisconnect },
            },
            pod: {
                connect: {
                    id: podId,
                },
            },
            public_email: companyContactInfoInputValues.publicEmailInputValue,
            referral_source: companyContactInfoInputValues.referralSource,
            referral_tag: companyContactInfoInputValues.referralTag,
            same_caller_id: companyJillnInfoInputValues.sameCallerIdInputValue,
            timezone: companyContactInfoInputValues.timeZoneSelectedValue,
            twilio_phone_number: companyJillnInfoInputValues.twilioNumberInputValue,
            website_url: companyContactInfoInputValues.websiteInputValue,
            uses_outbound_services: companyJillnInfoInputValues.outboundServicesEnabled,
            hours_type: companyJillnInfoInputValues.hoursType,
            internal_notes: internalNotes,
            is_test_company: companyJillnInfoInputValues.isTestCompany,
            is_vip: companyJillnInfoInputValues.isVip,
            preferred_communication: companyJillnInfoInputValues.preferredCommunication,
        };
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($input: UpdateCompaniesInput!) {
                    updateCompanies(input: $input) {
                        id
                    }
                }
            `, {
            input,
        });
        if (gqlResult.succeeded === false) {
            return {
                ...gqlResult,
                developerMessage: `upsertCompanyMainInfo error: ${gqlResult.developerMessage}`,
            };
        }
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('updateCompanyMainInfo', error);
    }
}
exports.updateCompanyMainInfo = updateCompanyMainInfo;
async function removePersonalAdminFromCompany(companyId) {
    try {
        const existingAdminResult = await (0, fetch_personal_admin_1.fetchPersonalAdmin)(companyId);
        (0, utilities_1.assertSucceeded)(existingAdminResult, utilities_1.handleError);
        const existingAdmin = existingAdminResult.value;
        if (!existingAdmin) {
            return { succeeded: true };
        }
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($companyId: Int!, $existingPersonalAdminId: Int!) {
                    updateCompanies(
                        input: {
                            id: $companyId
                            personal_admin: {disconnect: {id: $existingPersonalAdminId}}
                        }
                    ) {
                        id
                    }
                }
            `, {
            companyId,
            existingPersonalAdminId: existingAdmin.id,
        });
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('removePersonalAdminFromCompany', error);
    }
}
exports.removePersonalAdminFromCompany = removePersonalAdminFromCompany;
async function updateCompanyBillingInfo(authenticatedUser, companyId, companyBillingInfoInputValues) {
    try {
        if ((0, utilities_1.isInAuthRole)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], authenticatedUser) === false) {
            return {
                succeeded: false,
                userMessage: 'There was an error',
                developerMessage: `updateCompanyBillingInfoResult error: not authorized`,
            };
        }
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation (
                    $companyId: Int!
                    $canceled: Boolean!
                    $creditCardKey: String!
                    $onHold: Boolean!
                    $invoiceEmail: String!
                ) {
                    updateCompanies(
                        input: {
                            id: $companyId
                            canceled: $canceled
                            credit_card_key: $creditCardKey
                            on_hold: $onHold
                            invoice_email: $invoiceEmail
                        }
                    ) {
                        id
                    }
                }
            `, {
            companyId: companyId,
            canceled: companyBillingInfoInputValues.canceledSelectedValue,
            creditCardKey: companyBillingInfoInputValues.stripeIdInputValue,
            onHold: companyBillingInfoInputValues.onHoldSelectedValue,
            invoiceEmail: companyBillingInfoInputValues.invoiceEmailInputValue,
        });
        if (gqlResult.succeeded === false) {
            return {
                ...gqlResult,
                developerMessage: `updateCompanyBillingInfoResult error: ${gqlResult.developerMessage}`,
            };
        }
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('updateCompanyBillingInfo', error);
    }
}
exports.updateCompanyBillingInfo = updateCompanyBillingInfo;
async function createPhoneService(phoneServiceName) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($type: PHONE_SERVICE_TYPE!, $name: String!) {
                    createPhone_services(input: {type: $type, name: $name}) {
                        id
                    }
                }
            `, {
            type: 'OTHER',
            name: phoneServiceName,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        const phoneServiceResultId = gqlResult.value.data.createPhone_services.id;
        return {
            succeeded: true,
            value: phoneServiceResultId,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('createPhoneService', error);
    }
}
exports.createPhoneService = createPhoneService;
async function createCalendar(calendarName, calendarUrl) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($type: CALENDAR_TYPE!, $name: String!, $url: String!) {
                    createCalendars(input: {type: $type, name: $name, url: $url}) {
                        id
                    }
                }
            `, {
            type: 'OTHER',
            name: calendarName,
            url: calendarUrl,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        const calendarResultId = gqlResult.value.data.createCalendars.id;
        return {
            succeeded: true,
            value: calendarResultId,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('createCalendar', error);
    }
}
exports.createCalendar = createCalendar;
async function cancelCompany(companyID) {
    try {
        if (!companyID) {
            return {
                succeeded: false,
                userMessage: 'Error canceling company',
                developerMessage: 'cancelCompany companyID is not set.',
            };
        }
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($companyId: Int!, $timeStamp: DateTime!) {
                    updateCompanies(
                        input: {id: $companyId, canceled: true, canceled_at: $timeStamp}
                    ) {
                        id
                    }
                }
            `, {
            companyId: companyID,
            timeStamp: new Date().toISOString(),
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const resultingID = gqlResult.value.data.updateCompanies.id;
        return {
            succeeded: true,
            value: resultingID,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('cancelCompany', error);
    }
}
exports.cancelCompany = cancelCompany;
async function updateCompanyStatusEvent(companyId, statusEventType) {
    try {
        const input = getUpdateCompanyStatusEventInput(companyId, statusEventType);
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($input: UpdateCompaniesInput!) {
                    updateCompanies(input: $input) {
                        id
                    }
                }
            `, {
            input,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const resultingCompanyID = gqlResult.value.data.updateCompanies.id;
        return {
            succeeded: true,
            value: resultingCompanyID,
        };
    }
    catch (error) {
        throw new Error(`updateCompanyStatusEvent error: ${error}`);
    }
}
exports.updateCompanyStatusEvent = updateCompanyStatusEvent;
function getUpdateCompanyStatusEventInput(companyId, statusEventType) {
    return {
        id: companyId,
        status_events: {
            create: {
                type: statusEventType,
                timestamp: new Date().toISOString(),
            },
        },
    };
}
async function setDefaultChatSettingsForCompany(companyId) {
    try {
        const openingMessage = 'Thank you {customerName}! How may I be of assistance?';
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($companyId: Int!, $openingMessage: String!) {
                    createChat_widget_settings(
                        input: {
                            company: {connect: {id: $companyId}}
                            auto_responses: {create: {body: $openingMessage, position: 0}}
                        }
                    ) {
                        id
                    }
                }
            `, {
            companyId,
            openingMessage,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const resultingId = gqlResult.value.data.createChat_widget_settings.id;
        return {
            succeeded: true,
            value: resultingId,
        };
    }
    catch (error) {
        throw new Error(`setDefaultChatSettingsForCompany() error: ${error}`);
    }
}
exports.setDefaultChatSettingsForCompany = setDefaultChatSettingsForCompany;
