"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.wasLikelyPartOfAnIgnoredCall = void 0;
const diff_time_1 = require("../../../../utilities/dates-and-times/diff-time");
const is_chat_duration_event_1 = require("./is-chat-duration-event");
const is_user_status_event_1 = require("./is-user-status-event");
const requiredSequence = [
    'CALL_SENT_TO_USER',
    'INCOMING_CALL_RECEIVED_BY_USER',
    'INCOMING_CALL_LOADED_ON_CALL_SCREEN',
    'INCOMING_CALL_CANCELED_BEFORE_USER_ANSWERED',
];
/**
 * @param callsAndStatusEvents
 * @param index
 * @returns True if the item at the index is INCOMING_CALL_CANCELED_BEFORE_USER_ANSWERED and was at
 *   least 25 seconds after the previous event of the call loading on the call screen
 */
function wasLikelyPartOfAnIgnoredCall(callsAndStatusEvents, index) {
    const item = callsAndStatusEvents[index];
    if (!item || !(0, is_user_status_event_1.isUserStatusEvent)(item) || (0, is_chat_duration_event_1.isChatDurationEvent)(item))
        return false;
    let j = requiredSequence.indexOf(item.type);
    if (j === -1)
        return false;
    for (; j < requiredSequence.length; j++, index++) {
        const item = callsAndStatusEvents[index];
        const requiredSequenceEvent = requiredSequence[j];
        if (item === undefined ||
            !(0, is_user_status_event_1.isUserStatusEvent)(item, requiredSequenceEvent) ||
            item.type !== requiredSequenceEvent) {
            return false;
        }
        if (item.type === 'INCOMING_CALL_CANCELED_BEFORE_USER_ANSWERED') {
            return wasPreviousItemAtLeastXMillisecondsAgo({
                callsAndStatusEvents,
                index,
                item,
                milliseconds: 25000,
            });
        }
    }
    return false;
}
exports.wasLikelyPartOfAnIgnoredCall = wasLikelyPartOfAnIgnoredCall;
function wasPreviousItemAtLeastXMillisecondsAgo(params) {
    const { callsAndStatusEvents, index, item, milliseconds } = params;
    const previousItem = callsAndStatusEvents[index - 1];
    if (previousItem === undefined)
        return false;
    const timeDiff = (0, diff_time_1.diffTime)(item.timestamp, previousItem.timestamp);
    return timeDiff >= milliseconds;
}
