"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stylesForJoChatStrictHours = void 0;
const lit_html_1 = require("lit-html");
const css_utils_1 = require("../../../../utilities/css-utils");
const cssName = (0, css_utils_1.cssPrefixer)('jo-chat-strict-hours');
exports.stylesForJoChatStrictHours = (() => {
    return (0, lit_html_1.html) `
        <style>
            .${cssName('main-container')} {
                width: 100%;
                gap: 1rem;
            }
            .${cssName('update-button')} {
                margin-left: 3rem;
            }
            .${cssName('column')} {
                display: flex;
                flex-direction: column;
                flex: 1;
            }
            .${cssName('time-input-container')} {
                margin-bottom: 0.5rem;
                display: flex;
                flex-direction: row;
            }
            .${cssName('time-input')} {
                width: 6rem;
                margin-left: 0.5rem;
            }
        </style>
    `;
})();
