"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const form_error_manager_1 = require("../../../classes/form-error-manager");
const utilities_1 = require("../../../services/utilities");
const css_utils_1 = require("../../../utilities/css-utils");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const queries_1 = require("../jo-billing/jo-billing-minutes-package/queries");
require("../jo-button.element");
const queries_2 = require("../jo-company-details/queries");
require("../jo-input");
const mutations_1 = require("./mutations");
const queries_3 = require("./queries");
const test_id_directive_1 = require("../../directives/test-id.directive");
const InitialState = {
    accountManagers: [],
    authenticatedUser: 'NOT_SET',
    billingCycle: 'WEEKLY',
    calendars: [],
    companyInputValues: {
        account_managerId: -1,
        activation_fee: undefined,
        address1: '',
        address2: '',
        business_number: '',
        calendarId: -1,
        city: '',
        fax: '',
        industry: '',
        mailing_address: '',
        minutes_packageId: -1,
        name: '',
        niche: '',
        organizationId: -1,
        phone_serviceId: -1,
        podId: -1,
        public_email: '',
        referral_sources: '',
        referral_tags: '',
        state: '',
        timezone: '',
        twilioNumber: '',
        userId: -1,
        website_url: '',
        zipcode: '',
    },
    selectionOptions: {
        industries: [],
        niches: [],
        referral_sources: [],
        referral_tags: [],
    },
    showAllFormErrors: false,
    userInputValues: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        crypted_password: '',
        time_zone: '',
    },
    tempPassword: '',
    allMinutesPackages: [],
    phoneServices: [],
    pods: [],
    loading: false,
    selectedMinutesPackage: 'NOT_SET',
    selectedPhoneServiceName: 'NOT_SET',
    otherPhoneServiceName: '',
    selectedCalendarName: 'NOT_SET',
    otherCalendarName: '',
    otherCalendarURL: '',
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-register-company');
const cssNames = (classNames) => classNames.split(' ').map(cssName).join(' ');
class JORegisterCompany extends HTMLElement {
    constructor() {
        super(...arguments);
        this.formErrorManager = new form_error_manager_1.FormErrorManager();
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        this.fetchAndSetData();
    }
    async fetchAndSetData() {
        await this.fetchAndSetAllMinutesPackages();
        await this.fetchAndSetPods();
        await this.fetchAndSetAccountManagers();
        await this.fetchAndSetPhoneServices();
        await this.fetchAndSetCalendars();
        await this.fetchAndSetGlobalConfigOptions();
    }
    async fetchAndSetGlobalConfigOptions() {
        const optionsResult = await (0, queries_3.getGlobalConfigOptions)();
        (0, utilities_1.assertSucceeded)(optionsResult, utilities_1.handleError, this, 'fetchAndSetGlobalConfigOptions');
        this.store.selectionOptions = optionsResult.value;
    }
    async handleCreateCompanyClicked() {
        this.store.loading = true;
        await this.createCompany(this.store);
        this.store.loading = false;
    }
    async fetchAndSetPods() {
        const podsResult = await (0, queries_2.fetchPods)();
        (0, utilities_1.assertSucceeded)(podsResult, utilities_1.handleError, this, 'fetchAndSetPods');
        this.store.pods = podsResult.value;
    }
    async fetchAndSetPhoneServices() {
        const phoneServicesResult = await (0, queries_2.fetchPhoneServices)();
        (0, utilities_1.assertSucceeded)(phoneServicesResult, utilities_1.handleError, this, 'fetchAndSetPhoneServices');
        this.store.phoneServices = phoneServicesResult.value;
    }
    async fetchAndSetCalendars() {
        const calendarsResult = await (0, queries_2.fetchCalendars)();
        (0, utilities_1.assertSucceeded)(calendarsResult, utilities_1.handleError, this, 'fetchAndSetCalendars');
        this.store.calendars = calendarsResult.value;
    }
    async fetchAndSetAccountManagers() {
        const accountManagersResult = await (0, queries_2.fetchAccountManagers)();
        (0, utilities_1.assertSucceeded)(accountManagersResult, utilities_1.handleError, this, 'fetchAndSetAccountManagers');
        this.store.accountManagers = accountManagersResult.value;
    }
    async fetchAndSetAllMinutesPackages() {
        const allMinutesPackagesResult = await (0, queries_1.getAllMinutesPackages)();
        (0, utilities_1.assertSucceeded)(allMinutesPackagesResult, utilities_1.handleError, this, 'fetchAndSetAllMinutesPackages');
        this.store.allMinutesPackages = allMinutesPackagesResult.value;
    }
    async setPhoneServiceIdForUniqueInput(state) {
        if (state.selectedPhoneServiceName === 'Other') {
            const resultingPhoneServiceId = await (0, mutations_1.createNewPhoneService)(state.otherPhoneServiceName);
            if (!resultingPhoneServiceId.succeeded) {
                return;
            }
            this.store.companyInputValues.phone_serviceId = resultingPhoneServiceId.value;
        }
        if (state.selectedPhoneServiceName === 'None') {
            state.companyInputValues.phone_serviceId = -1;
        }
    }
    async setCalendarIdForUniqueInput(state) {
        if (state.selectedCalendarName === 'Other') {
            const resultingCalendarId = await (0, mutations_1.createNewCalendar)(state.otherCalendarName, state.otherCalendarURL);
            if (!resultingCalendarId.succeeded) {
                return;
            }
            this.store.companyInputValues.calendarId = resultingCalendarId.value;
        }
        if (state.selectedCalendarName === 'None') {
            state.companyInputValues.calendarId = -1;
        }
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />


            <style>
                .${cssNames('row')} {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                    gap: 3rem;
                }

                .${cssNames('main-container')} {
                    padding: 2rem;
                }

                .${cssNames('section')} {
                    padding-top: 1.5rem;
                    padding-bottom: 1.5rem;
                }

                .${cssNames('save-buttons-section')} {
                    display: flex;
                    justify-content: flex-end;
                    gap: 1rem;
                }

            </style>

                <div class="jo-global--card ${cssNames('main-container')}">
                    <section class="${cssNames('section')}">
                        <div class="jo-global--card-title">Company Owner:</div>
                        <hr />
                        <div class="${cssNames('row')}">
                            <div>
                                <jo-input
                                    ${(0, test_id_directive_1.testId)('jo-register-company-first-name')}
                                    .label=${'First Name'}
                                    .type=${'TEXT_INPUT'}
                                    @inputErrorChanged=${(e) => {
            this.formErrorManager.addOrRemoveFormError(e.detail);
        }}
                                    .showError=${state.showAllFormErrors}
                                    .showRequiredIcon=${true}
                                    .required=${true}
                                    .inputValue=${state.userInputValues.first_name}
                                    @inputchange=${(e) => {
            this.store.userInputValues = {
                ...this.store.userInputValues,
                first_name: e.detail.inputValue,
            };
        }}
                                ></jo-input>
                            </div>

                            <div>
                                <jo-input
                                    ${(0, test_id_directive_1.testId)('jo-register-company-last-name')}
                                    .type=${'TEXT_INPUT'}
                                    .label=${'Last Name'}
                                    @inputErrorChanged=${(e) => this.formErrorManager.addOrRemoveFormError(e.detail)}
                                    .inputValue=${state.userInputValues.last_name}
                                    .showRequiredIcon=${true}
                                    .required=${true}
                                    @inputchange=${(e) => {
            this.store.userInputValues = {
                ...this.store.userInputValues,
                last_name: e.detail.inputValue,
            };
        }}
                                    .showError=${state.showAllFormErrors}
                                ></jo-input>
                            </div>
                        </div>

                        <div class="${cssNames('row')}">
                            <div>
                                <jo-input
                                    ${(0, test_id_directive_1.testId)('jo-register-company-owner-email')}
                                    .label=${'Email'}
                                    .type=${'EMAIL'}
                                    @inputErrorChanged=${(e) => this.formErrorManager.addOrRemoveFormError(e.detail)}
                                    .inputValue=${state.userInputValues.email}
                                    .showRequiredIcon=${true}
                                    .required=${true}
                                    @inputchange=${(e) => {
            this.store.userInputValues = {
                ...this.store.userInputValues,
                email: e.detail.inputValue,
            };
        }}
                                    .showError=${state.showAllFormErrors}
                                ></jo-input>
                            </div>
                            <div>
                                <jo-input
                                    ${(0, test_id_directive_1.testId)('jo-register-company-owner-phone-number')}
                                    .label=${'Phone Number'}
                                    .type=${'PHONE_NUMBER'}
                                    @inputErrorChanged=${(e) => this.formErrorManager.addOrRemoveFormError(e.detail)}
                                    .inputValue=${state.userInputValues.phone_number}
                                    .showRequiredIcon=${true}
                                    .required=${true}
                                    @inputchange=${(e) => {
            this.store.userInputValues = {
                ...this.store.userInputValues,
                phone_number: e.detail.inputValue,
            };
        }}
                                    .showError=${state.showAllFormErrors}
                                ></jo-input>
                            </div>
                        </div>
                    </section>

                    <section class="${cssNames('section')}">
                        <div class="jo-global--card-title">Service Package:</div>
                        <hr />
                        <div class="${cssNames('row')}">
                            <div class="m-4">
                                <jo-input
                                    ${(0, test_id_directive_1.testId)('jo-register-company-billing-cycle')}
                                    .type=${'SELECT'}
                                    .label=${'Select Billing Cycle'}
                                    @inputErrorChanged=${(e) => this.formErrorManager.addOrRemoveFormError(e.detail)}
                                    .showRequiredIcon=${true}
                                    .required=${true}
                                    .selectOptions=${[
            'WEEKLY',
            'MONTHLY',
        ]}
                                    .selectedValue=${state.billingCycle === 'NOT_SET' ? '' : state.billingCycle}
                                    .defaultOption=${'Please select billing cycle'}
                                    .disableDefaultOption=${true}
                                    @inputchange=${(e) => {
            this.store.billingCycle = e.detail.selectedValue;
        }}
                                    .showError=${state.showAllFormErrors}
                                ></jo-input>
                            </div>

                            <div>
                                <jo-input
                                    ${(0, test_id_directive_1.testId)('jo-register-company-minutes-package')}
                                    .type=${'SELECT'}
                                    .label=${'Select Minutes Package'}
                                    @inputErrorChanged=${(e) => this.formErrorManager.addOrRemoveFormError(e.detail)}
                                    .selectOptions=${getMinutesPackageSelectOptions(state)}
                                    .selectedValue=${state.selectedMinutesPackage}
                                    .showRequiredIcon=${true}
                                    .required=${true}
                                    .defaultOption=${'Select minutes package...'}
                                    .disableDefaultOption=${true}
                                    @inputchange=${(e) => {
            this.store.companyInputValues.minutes_packageId =
                this.store.allMinutesPackages.find(mP => mP.name === e.detail.selectedValue)?.id;
        }}
                                    .showError=${state.showAllFormErrors}
                                ></jo-input>
                            </div>

                            <div>
                                <jo-input
                                    ${(0, test_id_directive_1.testId)('jo-register-company-activation-fee')}
                                    .type=${'NUMBER'}
                                    .label=${'Activation Fee'}
                                    @inputErrorChanged=${(e) => this.formErrorManager.addOrRemoveFormError(e.detail)}                                                                        
                                    .showRequiredIcon=${true}
                                    .required=${true}  
                                    .inputValue=${0}                                                                     
                                    @inputchange=${(e) => {
            this.store.companyInputValues = {
                ...this.store.companyInputValues,
                activation_fee: (0, utilities_1.formatMoneyInUSDCents)(Number(e.detail.inputValue)),
            };
        }}
                                    .showError=${state.showAllFormErrors}
                                ></jo-input>                                    
                            </div>
                        </div>
                    </section>

                    <section class="${cssNames('section')}">
                        <div class="jo-global--card-title">Company Info:</div>
                        <hr />
                        <div class="${cssNames('row')}">
                            <div>
                                <jo-input
                                    ${(0, test_id_directive_1.testId)('jo-register-company-company-name')}
                                    .label=${'Company Name'}
                                    .type=${'TEXT_INPUT'}
                                    @inputErrorChanged=${(e) => this.formErrorManager.addOrRemoveFormError(e.detail)}
                                    .inputValue=${state.companyInputValues.name}
                                    .showRequiredIcon=${true}
                                    .required=${true}
                                    @inputchange=${(e) => {
            this.store.companyInputValues = {
                ...this.store.companyInputValues,
                name: e.detail.inputValue,
            };
        }}
                                    .showError=${state.showAllFormErrors}
                                ></jo-input>
                            </div>

                            <div>
                                <jo-input
                                    ${(0, test_id_directive_1.testId)('jo-register-company-company-number')}
                                    .label=${'Company Phone #'}
                                    .type=${'PHONE_NUMBER'}
                                    @inputErrorChanged=${(e) => this.formErrorManager.addOrRemoveFormError(e.detail)}
                                    .inputValue=${state.companyInputValues.business_number}
                                    .showRequiredIcon=${true}
                                    .required=${true}
                                    @inputchange=${(e) => {
            this.store.companyInputValues = {
                ...this.store.companyInputValues,
                business_number: e.detail.inputValue,
            };
        }}
                                    .showError=${state.showAllFormErrors}
                                ></jo-input>
                            </div>

                            <div>
                                <jo-input
                                    ${(0, test_id_directive_1.testId)('jo-register-company-phone-service')}
                                    .label=${'Phone Service'}
                                    .type=${'SELECT'}
                                    @inputErrorChanged=${(e) => this.formErrorManager.addOrRemoveFormError(e.detail)}
                                    .selectedValue=${state.phoneServices.find(p => p.id === state.companyInputValues.phone_serviceId)?.name || state.selectedPhoneServiceName}
                                    .defaultOption=${'Select Service...'}
                                    .disableDefaultOption=${true}
                                    .selectOptions=${[
            ...state.phoneServices.map((phoneService) => phoneService.name),
            'Other',
        ]}
                                    @inputchange=${(e) => {
            this.store.selectedPhoneServiceName =
                e.detail.selectedValue;
            this.store.companyInputValues = {
                ...this.store.companyInputValues,
                phone_serviceId: state.phoneServices.find(p => p.name === e.detail.selectedValue)?.id,
            };
        }}
                                    .showError=${state.showAllFormErrors}
                                ></jo-input>
                            </div>

                            <div>
                                <jo-input
                                    ${(0, test_id_directive_1.testId)('jo-register-company-phone-service-name')}
                                    ?hidden=${!(this.store.selectedPhoneServiceName === 'Other')}
                                    .label=${'Phone Service Name'}
                                    @inputErrorChanged=${(e) => this.formErrorManager.addOrRemoveFormError(e.detail)}
                                    .inputValue=${state.otherPhoneServiceName}
                                    .showRequiredIcon=${true}
                                    .required=${this.store.selectedPhoneServiceName === 'Other'}
                                    .placeholder=${'Enter Name...'}
                                    .type=${'TEXT_INPUT'}
                                    @inputchange=${(e) => {
            this.store.otherPhoneServiceName = e.detail.inputValue;
        }}
                                    .showError=${state.showAllFormErrors}
                                    }
                                ></jo-input>
                            </div>
                        </div>

                        <div class="${cssNames('row')}">
                            <div>
                                <jo-input
                                    ${(0, test_id_directive_1.testId)('jo-register-company-address1')}
                                    .label=${'Address 1'}
                                    .type=${'TEXT_INPUT'}
                                    @inputErrorChanged=${(e) => this.formErrorManager.addOrRemoveFormError(e.detail)}
                                    .inputValue=${state.companyInputValues.address1}
                                    .showRequiredIcon=${true}
                                    .required=${true}
                                    @inputchange=${(e) => {
            this.store.companyInputValues = {
                ...this.store.companyInputValues,
                address1: e.detail.inputValue,
            };
        }}
                                    .showError=${state.showAllFormErrors}
                                ></jo-input>
                            </div>

                            <div>
                                <jo-input
                                    ${(0, test_id_directive_1.testId)('jo-register-company-address2')}
                                    .label=${'Address 2'}
                                    .type=${'TEXT_INPUT'}
                                    .inputValue=${state.companyInputValues.address2}
                                    @inputchange=${(e) => {
            this.store.companyInputValues = {
                ...this.store.companyInputValues,
                address2: e.detail.inputValue,
            };
        }}
                                    .showError=${state.showAllFormErrors}
                                ></jo-input>
                            </div>

                            <div>
                                <jo-input
                                    ${(0, test_id_directive_1.testId)('jo-register-company-fax')}
                                    .label=${'Fax'}
                                    .type=${'PHONE_NUMBER'}
                                    .inputValue=${state.companyInputValues.fax}
                                    @inputchange=${(e) => {
            this.store.companyInputValues = {
                ...this.store.companyInputValues,
                fax: e.detail.inputValue,
            };
        }}
                                ></jo-input>
                            </div>
                        </div>

                        <div class="${cssNames('row')}">
                            <div>
                                <jo-input
                                    .label=${'City'}
                                    .type=${'TEXT_INPUT'}
                                    ${(0, test_id_directive_1.testId)('jo-register-company-city')}
                                    @inputErrorChanged=${(e) => this.formErrorManager.addOrRemoveFormError(e.detail)}
                                    .inputValue=${state.companyInputValues.city}
                                    .showRequiredIcon=${true}
                                    .required=${true}
                                    @inputchange=${(e) => {
            this.store.companyInputValues = {
                ...this.store.companyInputValues,
                city: e.detail.inputValue,
            };
        }}
                                    .showError=${state.showAllFormErrors}
                                ></jo-input>
                            </div>

                            <div>
                                <jo-input
                                    ${(0, test_id_directive_1.testId)('jo-register-company-state')}
                                    .label=${'State'}
                                    .type=${'TEXT_INPUT'}
                                    @inputErrorChanged=${(e) => this.formErrorManager.addOrRemoveFormError(e.detail)}
                                    .inputValue=${state.companyInputValues.state}
                                    .showRequiredIcon=${true}
                                    .required=${true}
                                    @inputchange=${(e) => {
            this.store.companyInputValues = {
                ...this.store.companyInputValues,
                state: e.detail.inputValue,
            };
        }}
                                    .showError=${state.showAllFormErrors}
                                ></jo-input>
                            </div>

                            <div>
                                <jo-input
                                    ${(0, test_id_directive_1.testId)('jo-register-company-zip')}
                                    .label=${'Zip Code'}
                                    .type=${'TEXT_INPUT'}
                                    @inputErrorChanged=${(e) => this.formErrorManager.addOrRemoveFormError(e.detail)}
                                    .inputValue=${state.companyInputValues.zipcode}
                                    .showRequiredIcon=${true}
                                    .required=${true}
                                    @inputchange=${(e) => {
            this.store.companyInputValues = {
                ...this.store.companyInputValues,
                zipcode: e.detail.inputValue,
            };
        }}
                                    .showError=${state.showAllFormErrors}
                                ></jo-input>
                            </div>
                        </div>

                        <div class="${cssNames('row')}">
                            <div>
                                <jo-input
                                    ${(0, test_id_directive_1.testId)('jo-register-company-public-email')}
                                    .label=${'Public Email'}
                                    .type=${'EMAIL'}
                                    .inputValue=${state.companyInputValues.public_email}
                                    @inputchange=${(e) => {
            this.store.companyInputValues = {
                ...this.store.companyInputValues,
                public_email: e.detail.inputValue,
            };
        }}
                                ></jo-input>
                            </div>

                            <div>
                                <jo-input
                                    ${(0, test_id_directive_1.testId)('jo-register-company-industry')}
                                    .label=${'Industry'}
                                    .type=${'SELECT'}
                                    @inputErrorChanged=${(e) => this.formErrorManager.addOrRemoveFormError(e.detail)}
                                    .selectedValue=${state.companyInputValues.industry}
                                    .showRequiredIcon=${true}
                                    .required=${true}
                                    .defaultOption=${'Select Industry...'}
                                    .disableDefaultOption=${true}
                                    .selectOptions=${state.selectionOptions.industries}
                                    @inputchange=${(e) => {
            this.store.companyInputValues = {
                ...this.store.companyInputValues,
                industry: e.detail.selectedValue,
            };
        }}
                                    .showError=${state.showAllFormErrors}
                                ></jo-input>
                            </div>
                            <div>
                                <jo-input
                                    ${(0, test_id_directive_1.testId)('jo-register-company-timezone')}
                                    .label=${'Timezone'}
                                    .type=${'TIMEZONE_SELECT'}
                                    .selectedValue=${state.companyInputValues.timezone}
                                    .showRequiredIcon=${true}
                                    .required=${true}
                                    @inputchange=${(e) => {
            this.setTimeZoneInState(e);
        }}
                                    .showError=${state.showAllFormErrors}
                                ></jo-input>
                            </div>
                        </div>

                        <div class="${cssNames('row')}">
                            <div>
                                <jo-input
                                    ${(0, test_id_directive_1.testId)('jo-register-company-pod')}
                                    .label=${'Pod'}
                                    @inputErrorChanged=${(e) => this.formErrorManager.addOrRemoveFormError(e.detail)}
                                    .type=${'SELECT'}
                                    .selectedValue=${state.pods.find(p => p.id === state.companyInputValues.podId)?.name}                                   
                                    .showRequiredIcon=${true}
                                    .required=${true}
                                    .selectOptions=${[...state.pods.map(pod => pod.name)]}
                                    @inputchange=${(e) => {
            this.store.companyInputValues = {
                ...this.store.companyInputValues,
                podId: state.pods.find(p => p.name === e.detail.selectedValue)?.id,
            };
        }}
                                    .showError=${state.showAllFormErrors}
                                ></jo-input>
                            </div>

                            <div>
                                <jo-input
                                    ${(0, test_id_directive_1.testId)('jo-register-company-calendar')}
                                    .label=${'Calendar'}
                                    @inputErrorChanged=${(e) => this.formErrorManager.addOrRemoveFormError(e.detail)}
                                    .type=${'SELECT'}
                                    .selectedValue=${state.calendars.find(cal => cal.id === state.companyInputValues.calendarId)?.name || state.selectedCalendarName}
                                    .defaultOption=${'Select Calendar...'}
                                    .disableDefaultOption=${true}
                                    .selectOptions=${[
            'None',
            ...state.calendars.map((cal) => cal.name),
            'Other',
        ]}
                                    @inputchange=${(e) => {
            this.store.selectedCalendarName = e.detail.selectedValue;
            this.store.companyInputValues = {
                ...this.store.companyInputValues,
                calendarId: state.calendars.find(cal => cal.name === e.detail.selectedValue)?.id,
            };
        }}
                                    .showError=${state.showAllFormErrors}
                                ></jo-input>
                            </div>

                            <div>
                                <jo-input
                                    ${(0, test_id_directive_1.testId)('jo-register-company-calendar-name')}
                                    ?hidden=${!(this.store.selectedCalendarName === 'Other')}
                                    .label=${'Calendar Name'}
                                    @inputErrorChanged=${(e) => this.formErrorManager.addOrRemoveFormError(e.detail)}
                                    .inputValue=${state.otherCalendarName}
                                    .showRequiredIcon=${true}
                                    .required=${this.store.selectedCalendarName === 'Other'}
                                    .placeholder=${'Enter Name...'}
                                    .type=${'TEXT_INPUT'}
                                    @inputchange=${(e) => {
            this.store.otherCalendarName = e.detail.inputValue;
        }}
                                    .showError=${state.showAllFormErrors}
                                    }
                                ></jo-input>
                            </div>

                            <div>
                                <jo-input
                                    ${(0, test_id_directive_1.testId)('jo-register-company-calendar-url')}
                                    ?hidden=${!(this.store.selectedCalendarName === 'Other')}
                                    .label=${'Calendar URL'}
                                    @inputErrorChanged=${(e) => this.formErrorManager.addOrRemoveFormError(e.detail)}
                                    .inputValue=${state.otherCalendarURL}
                                    .showRequiredIcon=${true}
                                    .required=${this.store.selectedCalendarName === 'Other'}
                                    .placeholder=${'Enter Url of the Calendar'}
                                    .type=${'URL'}
                                    @inputchange=${(e) => {
            this.store.otherCalendarURL = e.detail.inputValue;
        }}
                                    .showError=${state.showAllFormErrors}
                                    }
                                ></jo-input>
                            </div>

                            <div>
                                <jo-input
                                    ${(0, test_id_directive_1.testId)('jo-register-company-account-manager')}
                                    .label=${'Account Manager'}
                                    .type=${'SELECT'}
                                    @inputErrorChanged=${(e) => this.formErrorManager.addOrRemoveFormError(e.detail)}
                                    .selectedValue=${(0, utilities_1.getUserFullName)(state.accountManagers.find(am => am.id ===
            state.companyInputValues.account_managerId))}
                                    .showRequiredIcon=${true}
                                    .required=${true}
                                    .defaultOption=${'Select Account Manager...'}
                                    .selectOptions=${state.accountManagers.map(manager => (0, utilities_1.getUserFullName)(manager))}
                                    @inputchange=${(e) => {
            this.store.companyInputValues = {
                ...this.store.companyInputValues,
                account_managerId: state.accountManagers.find(am => (0, utilities_1.getUserFullName)(am) === e.detail.selectedValue)?.id,
            };
        }}
                                    .showError=${state.showAllFormErrors}
                                ></jo-input>
                            </div>
                        </div>

                        <div class="${cssNames('row')}">
                            <div>
                                <jo-input
                                    ${(0, test_id_directive_1.testId)('jo-register-company-mailing-address')}
                                    .label=${'Mailing Address'}
                                    .type=${'TEXT_AREA'}
                                    @inputErrorChanged=${(e) => this.formErrorManager.addOrRemoveFormError(e.detail)}
                                    .inputValue=${state.companyInputValues.mailing_address}                                                                       
                                    @inputchange=${(e) => {
            this.store.companyInputValues = {
                ...this.store.companyInputValues,
                mailing_address: e.detail.inputValue,
            };
        }}
                                    .showError=${state.showAllFormErrors}
                                ></jo-input>
                            </div>

                            <div>
                                <jo-input
                                    ${(0, test_id_directive_1.testId)('jo-register-company-niche')}
                                    .label=${'Niche'}
                                    .type=${'SELECT'}
                                    .selectedValue=${state.companyInputValues.niche}
                                    .defaultOption=${'Select Niche...'}
                                    .disableDefaultOption=${true}
                                    .selectOptions=${state.selectionOptions.niches}
                                    @inputErrorChanged=${(e) => this.formErrorManager.addOrRemoveFormError(e.detail)}
                                    @inputchange=${(e) => {
            this.store.companyInputValues = {
                ...this.store.companyInputValues,
                niche: e.detail.selectedValue,
            };
        }}
                                    .showError=${state.showAllFormErrors}
                                ></jo-input>
                            </div>

                            <div>
                                <jo-input
                                    ${(0, test_id_directive_1.testId)('jo-register-company-website-url')}
                                    .label=${'Website URL'}
                                    .type=${'TEXT_INPUT'}
                                    .inputValue=${state.companyInputValues.website_url}
                                    @inputchange=${(e) => {
            this.store.companyInputValues = {
                ...this.store.companyInputValues,
                website_url: e.detail.inputValue,
            };
        }}
                                    .showError=${state.showAllFormErrors}
                                ></jo-input>
                            </div>                         
                        </div>

                        <div class="${cssNames('row')}">
                            <div>
                                <jo-input
                                    .label=${'Referral Source'}
                                    .type=${'SELECT'}
                                    ${(0, test_id_directive_1.testId)('jo-register-company-referral-source')}
                                    @inputErrorChanged=${(e) => this.formErrorManager.addOrRemoveFormError(e.detail)}                                    
                                    .selectedValue=${state.companyInputValues.referral_sources}
                                    .defaultOption=${'Select Source...'}
                                    .disableDefaultOption=${true}
                                    .selectOptions=${state.selectionOptions.referral_sources}
                                    @inputchange=${(e) => {
            this.store.companyInputValues = {
                ...this.store.companyInputValues,
                referral_sources: e.detail.selectedValue,
            };
        }}
                                    .showError=${state.showAllFormErrors}
                                ></jo-input>
                            </div>

                            <div>
                                <jo-input
                                    .label=${'Referral Tags'}
                                    .type=${'SELECT'}
                                    ${(0, test_id_directive_1.testId)('jo-register-company-referral-tags')}
                                    .selectedValue=${state.companyInputValues.referral_tags}
                                    .defaultOption=${'Select Tag...'}
                                    .disableDefaultOption=${true}
                                    .selectOptions=${state.selectionOptions.referral_tags}
                                    @inputErrorChanged=${(e) => this.formErrorManager.addOrRemoveFormError(e.detail)}                                   
                                    @inputchange=${(e) => {
            this.store.companyInputValues = {
                ...this.store.companyInputValues,
                referral_tags: e.detail.selectedValue,
            };
        }}
                                    .showError=${state.showAllFormErrors}
                                ></jo-input>
                            </div>
                        </div>
                    </section>

                    <section class="${cssNames('section save-buttons-section')}">
                        <jo-button
                            ${(0, test_id_directive_1.testId)('jo-register-company-create-button')}
                            .disabled=${this.store.loading}
                            .type=${'ACTION_BUTTON_1'}
                            .text=${this.store.loading ? 'Creating Company...' : 'Create Company'}
                            @click=${async () => {
            if (this.formErrorManager.getFormErrors().length > 0) {
                this.store.showAllFormErrors = true;
                (0, utilities_1.joAlert)('Alert', 'Please correct errors');
                return;
            }
            await this.handleCreateCompanyClicked();
        }}
                        ></jo-button>
                        <jo-button
                            .type=${'SECONDARY_BUTTON_1'}
                            .text=${'Cancel'}
                            @click=${() => {
            window.location.reload();
        }}
                        ></jo-button>
                    </section>
                </div>
            </div>
        `;
    }
    setTimeZoneInState(e) {
        this.store.companyInputValues = {
            ...this.store.companyInputValues,
            timezone: e.detail.selectedValue,
        };
        this.store.userInputValues = {
            ...this.store.userInputValues,
            time_zone: e.detail.selectedValue,
        };
    }
    async createCompany(state) {
        this.setPhoneServiceIdForUniqueInput(state);
        this.setCalendarIdForUniqueInput(state);
        const newCompanyAccountResult = await createNewCompanyAccount(state.userInputValues, state.companyInputValues);
        if (newCompanyAccountResult.succeeded === false) {
            return;
        }
        await (0, mutations_1.populateMissingCompanyChatKeys)();
        if (state.userInputValues.email) {
            (0, utilities_1.navigate)(`/company/company-details?companyId=${newCompanyAccountResult.value}`);
            await (0, utilities_1.joAlert)('Success', 'Account was created successfully');
        }
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-register-company', JORegisterCompany);
function getMinutesPackageSelectOptions(state) {
    return state.allMinutesPackages
        .filter(minutesPackage => {
        if (state.billingCycle === 'WEEKLY') {
            return minutesPackage.billing_cycle_type === 'WEEKLY';
        }
        if (state.billingCycle === 'MONTHLY') {
            return minutesPackage.billing_cycle_type === 'MONTHLY';
        }
        return true;
    })
        .map(minutesPackage => minutesPackage.name)
        .sort();
}
async function createNewCompanyAccount(userInputValues, companyInputValues) {
    const isUserEmailTaken = await (0, queries_3.doesUserEmailExist)(userInputValues.email);
    if (isUserEmailTaken.succeeded === false) {
        return isUserEmailTaken;
    }
    if (isUserEmailTaken.value === true) {
        await (0, utilities_1.joAlert)('User Email Taken', 'The provided user account email is already being used.');
        return {
            succeeded: false,
            userMessage: 'User Email Taken',
            developerMessage: 'The provided user account email is already being used.',
        };
    }
    const createUserResult = await (0, mutations_1.createNewUserAccount)(userInputValues);
    if (!createUserResult.succeeded)
        return createUserResult;
    const createOrganizationResult = await (0, mutations_1.createNewOrganization)(createUserResult.value.id);
    if (!createOrganizationResult.succeeded)
        return createOrganizationResult;
    const registerTwilioNumberResult = await (0, mutations_1.registerTwilioNumber)(companyInputValues.business_number, companyInputValues.name, companyInputValues.state);
    if (!registerTwilioNumberResult.succeeded)
        return registerTwilioNumberResult;
    const createCompanyResult = await (0, mutations_1.createNewCompany)({
        ...companyInputValues,
        organizationId: createOrganizationResult.value,
        userId: createUserResult.value.id,
        twilioNumber: registerTwilioNumberResult.value,
    });
    if (!createCompanyResult.succeeded)
        return createCompanyResult;
    return {
        succeeded: true,
        value: createCompanyResult.value.id,
    };
}
