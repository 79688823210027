"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const element_vir_1 = require("element-vir");
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../../services/graphql");
const utilities_1 = require("../../../services/utilities");
const auth_rules_1 = require("../../../utilities/auth-rules");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
const error_handling_1 = require("../../../utilities/error-handling");
const personal_admin_1 = require("../../../utilities/personal-admin");
const test_id_directive_1 = require("../../directives/test-id.directive");
require("../jo-button.element");
const common_1 = require("@augment-vir/common");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    globalPods: [],
    inputValues: {
        formErrors: [],
        is_24_7: false,
        is_test_user: false,
        isAccountManagerSelectedValue: false,
        isActiveSelectedValue: false,
        isPersonalAdminSelectedValue: false,
        profilePictureManagementPrivilege: 'NONE',
        selectedPods: [],
        selectedFloatPods: [],
    },
    isAvailable: false,
    loading: true,
    mode: 'EDIT',
    showAllFormErrors: false,
    user: 'NOT_SET',
};
class JOUserJillnInfo extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    set user(user) {
        if (user === this.store.user) {
            return;
        }
        this.store.user = user;
        if (user === 'NOT_SET' || user === 'NEW_USER') {
            this.inputValues = InitialState.inputValues;
            this.store.loading = false;
        }
        else {
            this.store.user = user;
            this.store.isAvailable = user.is_available;
            this.inputValues = {
                formErrors: this.store.inputValues.formErrors,
                is_24_7: user.is_24_7,
                is_test_user: user.is_test_user,
                isAccountManagerSelectedValue: user.is_account_manager,
                isActiveSelectedValue: user.is_active,
                isPersonalAdminSelectedValue: user.is_personal_admin,
                profilePictureManagementPrivilege: user.profile_picture_management_privileges,
                selectedPods: user.pods,
                selectedFloatPods: user.float_pods,
            };
        }
        this.store.loading = false;
    }
    set inputValues(inputValues) {
        this.store.inputValues = inputValues;
        (0, utilities_1.raiseCustomEvent)(this, 'userjillninfoinputvalueschanged', inputValues);
    }
    handlePodCheckboxInputEvent(event, pod, podType) {
        const checked = event.target.checked;
        const inputValueName = podType === 'REGULAR_POD' ? 'selectedPods' : 'selectedFloatPods';
        if (checked) {
            this.inputValues = {
                ...this.store.inputValues,
                [inputValueName]: [
                    ...this.store.inputValues[inputValueName],
                    pod,
                ],
            };
        }
        else {
            this.inputValues = {
                ...this.store.inputValues,
                [inputValueName]: this.store.inputValues[inputValueName].filter(chosenPod => chosenPod.id !== pod.id),
            };
        }
    }
    async voidJillClicked() {
        if (this.store.user === 'NOT_SET') {
            (0, utilities_1.joAlert)('Error', 'The user is not defined');
            throw new Error(`The user is not defined`);
        }
        if (this.store.user === 'NEW_USER') {
            (0, utilities_1.joAlert)('Error', 'Cannot void a user that has not been created');
            throw new Error(`Cannot void a user that has not been created`);
        }
        const confirmation = await (0, utilities_1.joConfirm)('Confirm', 'This user will be lost forever. Are you sure you want to do this?');
        if (confirmation === false) {
            return;
        }
        const jillId = this.store.user.id;
        const voidJillResult = await voidJill(jillId);
        if (voidJillResult.succeeded === true) {
            (0, utilities_1.joAlert)('Success', 'The user was voided successfully');
        }
        else {
            (0, utilities_1.joAlert)('Error', voidJillResult.userMessage);
            throw new Error(voidJillResult.developerMessage);
        }
    }
    render(state) {
        const allowedToUpdateJill = (0, utilities_1.isAllowedToUpdateUser)(state.authenticatedUser, state.user);
        return (0, lit_html_1.html) `
            <style>
                .jo-user-jilln-info--main-container {
                    box-sizing: border-box;
                    font-family: sans-serif;
                    color: var(--jo-text-default);

                    height: 100%;
                    width: 100%;

                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                }

                .jo-user-jilln-info--card {
                    box-sizing: border-box;
                    height: 100%;
                    width: 100%;
                    border-radius: 7px;
                    background-color: white;
                    box-shadow: 0 0 3px var(--jo-text-default);
                    box-sizing: border-box;
                    padding: 1rem;
                }

                .jo-user-jilln-info--card-header {
                    margin-top: 0rem;
                    color: rgb(110, 110, 110);
                }

                .jo-user-jilln-info--card-row {
                    box-sizing: border-box;
                    padding-bottom: 1rem;
                }

                .jo-user-jilln-info--main-container > div input[type='text'],
                select,
                input[type='password'] {
                    width: 80%;
                }

                .jo-user-jilln-info--availability-green {
                    color: green;
                    cursor: pointer;
                }

                .jo-user-jilln-info--availability-green:hover {
                    text-shadow: 0 0 12px green;
                }

                .jo-user-jilln-info--availability-red {
                    color: red;
                    cursor: pointer;
                }

                .jo-user-jilln-info--availability-red:hover {
                    text-shadow: 0 0 12px red;
                }

                .jo-user-jilln-info--save-cancel-buttons-container {
                    margin-top: 2rem;
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                }

                .jo-user-jilln-info--pods-container {
                    display: flex;
                    flex-wrap: wrap;
                }

                .jo-user-jilln-info--pod {
                    margin-right: 1.5rem;
                    margin-bottom: 0.5rem;
                }

                .jo-user-jilln-info--void-jill-button {
                    padding: 1rem;
                }
            </style>

            <div class="jo-user-jilln-info--main-container">
                <div class="jo-user-jilln-info--card">
                    <h2 class="jo-user-jilln-info--card-header">
                        ${state.user !== 'NOT_SET' ? `JILL'N INFO` : 'Loading...'}
                    </h2>

                    ${state.user !== 'NEW_USER' && state.user !== 'NOT_SET' && state.mode === 'EDIT'
            ? (0, lit_html_1.html) `
                              <div
                                  ?hidden=${allowedToUpdateJill === false}
                                  class="jo-user-jilln-info--card-row ${state.user.is_available ===
                true
                ? 'jo-user-jilln-info--availability-green'
                : 'jo-user-jilln-info--availability-red'}"
                                  @click=${() => {
                if (state.user === 'NOT_SET' || state.user === 'NEW_USER') {
                    return;
                }
                toggleAvailability(state.user.id, state.user.is_available);
            }}
                              >
                                  ${state.user.is_available === true
                ? 'Available'
                : 'Not Available'}
                              </div>

                              ${(0, personal_admin_1.isInPersonalAdminMode)(state.user)
                ? (0, lit_html_1.html) `
                                        <div class="jo-user-jilln-info--card-row">
                                            <div style="color: green">In Personal Admin Mode</div>
                                        </div>
                                    `
                : ''}
                              ${isAllowedToVoidJill(state.authenticatedUser, state.user)
                ? (0, lit_html_1.html) `
                                        <div class="jo-user-jilln-info--card-row">
                                            <jo-button
                                                .type=${'ACTION_BUTTON_2'}
                                                .text=${'Void jill'}
                                                @joButtonClick=${() => this.voidJillClicked()}
                                            ></jo-button>
                                        </div>
                                    `
                : ''}
                          `
            : ''}
                    ${(0, utilities_1.authorizedRender)(['JILL_EXECUTIVE'], state.authenticatedUser, (0, lit_html_1.html) `
                            ${(0, element_vir_1.renderIf)(state.mode !== 'CREATE', (0, lit_html_1.html) `
                                    <div class="jo-user-jilln-info--card-row">
                                        <input
                                            type="checkbox"
                                            .checked=${state.inputValues.isActiveSelectedValue ===
            true}
                                            @change=${(event) => {
            this.inputValues = {
                ...this.store.inputValues,
                isActiveSelectedValue: event.target.checked,
            };
        }}
                                        />
                                        &nbsp;Currently Active
                                    </div>
                                `)}
                            <div class="jo-user-jilln-info--card-row">
                                <input
                                    ${(0, test_id_directive_1.testId)('jo-user-jilln-account-manager-checkbox')}
                                    type="checkbox"
                                    .checked=${state.inputValues.isAccountManagerSelectedValue ===
            true}
                                    @change=${(event) => {
            this.inputValues = {
                ...this.store.inputValues,
                isAccountManagerSelectedValue: event.target.checked,
            };
        }}
                                />
                                &nbsp;Account Manager
                            </div>
                            <div class="jo-user-jilln-info--card-row">
                                <input
                                    ${(0, test_id_directive_1.testId)('jo-user-jilln-personal-admin-checkbox')}
                                    type="checkbox"
                                    .checked=${state.inputValues.isPersonalAdminSelectedValue}
                                    @change=${(event) => {
            this.inputValues = {
                ...this.store.inputValues,
                isPersonalAdminSelectedValue: event.target.checked,
            };
        }}
                                />
                                &nbsp;Personal Admin
                            </div>
                            <div class="jo-user-jilln-info--card-row">
                                <input
                                    ${(0, test_id_directive_1.testId)('jo-user-jilln-twentyfourseven-checkbox')}
                                    type="checkbox"
                                    .checked=${state.inputValues.is_24_7 === true}
                                    @change=${(event) => {
            this.inputValues = {
                ...this.store.inputValues,
                is_24_7: event.target.checked,
            };
        }}
                                />
                                &nbsp;24/7
                            </div>
                            <div class="jo-user-jilln-info--card-row">
                                <input
                                    ${(0, test_id_directive_1.testId)('jo-user-jilln-test-user-checkbox')}
                                    type="checkbox"
                                    .checked=${state.inputValues.is_test_user === true}
                                    @change=${(event) => {
            this.inputValues = {
                ...this.store.inputValues,
                is_test_user: event.target
                    .checked,
            };
        }}
                                />
                                &nbsp;Test User
                            </div>
                        `)}
                    <div class="jo-user-jilln-info--card-row" style="width: 50%">
                        <jo-input
                            .label=${'Profile Picture Management Privileges'}
                            .type=${'SELECT'}
                            .readonly=${!(0, utilities_1.hasPermissionToManageJillProfilePictures)(state.authenticatedUser) &&
            !(0, auth_rules_1.isJillAtOrAboveAuthRole)(state.authenticatedUser, 'JILL_EXECUTIVE')}
                            .selectedValue=${state.inputValues.profilePictureManagementPrivilege}
                            .selectOptions=${[
            'NONE',
            'ALL_PRIVILEGES',
            'JILL_PROFILE_PICTURES_ONLY',
        ]}
                            @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                profilePictureManagementPrivilege: e.detail
                    .selectedValue,
            };
        }}
                        ></jo-input>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-user-jilln-info', JOUserJillnInfo);
async function toggleAvailability(userId, currentAvailability) {
    if (currentAvailability === true) {
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($userId: Int!, $available: Boolean!) {
                    updateUsers(input: {id: $userId, is_available: $available}) {
                        id
                    }

                    kickOffQueue
                }
            `, {
            userId,
            available: false,
        });
    }
    else {
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($userId: Int!, $available: Boolean!, $availableSince: DateTime!) {
                    updateUsers(
                        input: {
                            id: $userId
                            is_available: $available
                            available_since: $availableSince
                        }
                    ) {
                        id
                    }
                    kickOffQueue
                }
            `, {
            userId,
            available: true,
            availableSince: new Date().toISOString(),
        });
    }
}
function isInCall(user) {
    if (user.duration_events === undefined ||
        user.duration_events === null ||
        !(0, common_1.isLengthAtLeast)(user.duration_events, 1) ||
        user.duration_events[0].description === 'ACTION_ITEM_SAVED') {
        return false;
    }
    return true;
}
async function voidJill(jillId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($userId: Int!) {
                    voidUser(userId: $userId)
                }
            `, {
            userId: jillId,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        if (gqlResult.value.data.voidUser === true) {
            return {
                succeeded: true,
            };
        }
        else {
            return {
                succeeded: false,
                userMessage: 'There was a problem voiding the user',
                developerMessage: 'voidJill: There was a problem voiding the user',
            };
        }
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('voidJill', error);
    }
}
function isAllowedToVoidJill(authenticatedUser, user) {
    if (authenticatedUser === 'NOT_SET' || user === 'NEW_USER' || user === 'NOT_SET') {
        return false;
    }
    if (authenticatedUser.auth_role === 'JILL_EXECUTIVE' && user.auth_role !== 'JILL_EXECUTIVE') {
        return true;
    }
    return false;
}
