"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGlobalConfigOptions = exports.doesUserEmailExist = void 0;
const graphql_1 = require("../../../services/graphql");
const error_handling_1 = require("../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function doesUserEmailExist(userEmail) {
    try {
        if (!userEmail) {
            return {
                succeeded: false,
                userMessage: 'Error Missing Email',
                developerMessage: 'doesUserEmailExist userEmail is not set.',
            };
        }
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($userEmail: String!) {
                    totalFound: findUsers(filter: {email: {eq: $userEmail}}) {
                        total
                    }
                }
            `, {
            userEmail: userEmail.trim(),
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const resultingUserTotal = gqlResult.value.data.totalFound.total;
        return {
            succeeded: true,
            value: resultingUserTotal > 0,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('doesUserEmailExist', error);
    }
}
exports.doesUserEmailExist = doesUserEmailExist;
async function getGlobalConfigOptions() {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query {
                getGlobal_configs(id: 1) {
                    niches
                    referral_sources
                    referral_tags
                    industries
                }
            }
        `);
        if (gqlResult.succeeded === false)
            return gqlResult;
        const globalConfig = gqlResult.value.data.getGlobal_configs;
        const selectionSet = {
            industries: globalConfig['industries'].split('~'),
            niches: globalConfig['niches'].split('~'),
            referral_sources: globalConfig['referral_sources'].split('~'),
            referral_tags: globalConfig['referral_tags'].split('~'),
        };
        return {
            succeeded: true,
            value: selectionSet,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchGlobalTextList', error);
    }
}
exports.getGlobalConfigOptions = getGlobalConfigOptions;
