"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchPersonalAdmin = void 0;
const graphql_1 = require("../../services/graphql");
const error_handling_1 = require("../error-handling");
const mapped_env_variables_1 = require("../environment/mapped-env-variables");
async function fetchPersonalAdmin(companyId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!) {
                    getCompanies(id: $companyId) {
                        id
                        personal_admin {
                            id
                            email
                            first_name
                            last_name
                        }
                    }
                }
            `, {
            companyId,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const personalAdmin = gqlResult.value.data.getCompanies.personal_admin;
        return {
            succeeded: true,
            value: personalAdmin,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchPersonalAdmin', error);
    }
}
exports.fetchPersonalAdmin = fetchPersonalAdmin;
