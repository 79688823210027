"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchJills = void 0;
const graphql_1 = require("../../services/graphql");
const mapped_env_variables_1 = require("../environment/mapped-env-variables");
async function fetchJills() {
    const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
        query {
            findUsers(
                filter: {auth_role: {like: "JILL"}}
                orderBy: {field: "last_name", order: ASC}
            ) {
                items {
                    id
                    first_name
                    last_name
                    permissions {
                        has_chat_access
                    }
                }
            }
        }
    `);
    return gqlResult.data.findUsers.items;
}
exports.fetchJills = fetchJills;
