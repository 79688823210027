"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../../services/utilities");
require("../../jo-button.element");
require("../../jo-input");
const store_1 = require("../../../../services/store");
const helper_utils_1 = require("./helper-utils");
const styles_1 = require("./styles");
const no_scroll_on_focus_1 = require("../../../../utilities/input-event-listeners/no-scroll-on-focus");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
const InitialState = {
    authenticatedUser: store_1.InitialGlobalState.authenticatedUser,
    company: 'NOT_SET',
    inputValues: {
        serviceLineItemsInputValues: [],
    },
    isMobileDevice: false,
    loading: true,
};
class JOCompanyServicesPricingPricing extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, (state, action) => {
            if (action.type === 'RESET_STATE') {
                return {
                    ...InitialState,
                    isMobileDevice: state.isMobileDevice,
                };
            }
            return state;
        });
    }
    set company(company) {
        if (this.store.company === company) {
            return;
        }
        this.store.dispatch({
            type: 'RESET_STATE',
        });
        this.store.company = company;
        if (this.store.company !== 'NOT_SET') {
            this.inputValues = {
                ...this.store.inputValues,
                serviceLineItemsInputValues: (0, utilities_1.getCompanyServiceLineItems)(this.store.company.services).map((serviceLineItem) => {
                    return {
                        symbol: Symbol(),
                        id: serviceLineItem.id,
                        archived: serviceLineItem.archived,
                        calculate: serviceLineItem.calculate,
                        description: serviceLineItem.description,
                        price: serviceLineItem.price,
                        service: {
                            id: serviceLineItem.service.id,
                            name: serviceLineItem.service.name,
                        },
                        formErrors: [],
                    };
                }),
            };
        }
    }
    set inputValues(inputValues) {
        this.store.loading = true;
        this.store.inputValues = inputValues;
        (0, utilities_1.raiseCustomEvent)(this, 'inputvalueschanged', this.store.inputValues);
        this.store.loading = false;
    }
    addLineItem() {
        this.inputValues = {
            ...this.store.inputValues,
            serviceLineItemsInputValues: [
                ...this.store.inputValues.serviceLineItemsInputValues,
                {
                    symbol: Symbol(),
                    id: 'NOT_SET',
                    archived: false,
                    calculate: false,
                    description: '',
                    price: 0,
                    service: {
                        id: 'NOT_SET',
                        name: '',
                    },
                    formErrors: [],
                },
            ],
        };
    }
    async deleteLineItem(serviceLineItemInputValuesToDelete) {
        const confirmed = await (0, utilities_1.joConfirm)('Confirm', 'Are you sure you want to delete this line item?');
        if (confirmed === false) {
            return;
        }
        if (serviceLineItemInputValuesToDelete.id === 'NOT_SET') {
            this.inputValues = {
                ...this.store.inputValues,
                serviceLineItemsInputValues: this.store.inputValues.serviceLineItemsInputValues.filter((serviceLineItemInputValues) => {
                    return (serviceLineItemInputValuesToDelete.symbol !==
                        serviceLineItemInputValues.symbol);
                }),
            };
        }
        else {
            this.inputValues = {
                ...this.store.inputValues,
                serviceLineItemsInputValues: this.store.inputValues.serviceLineItemsInputValues.map((serviceLineItemInputValues) => {
                    if (serviceLineItemInputValuesToDelete.symbol ===
                        serviceLineItemInputValues.symbol) {
                        return {
                            ...serviceLineItemInputValues,
                            archived: true,
                        };
                    }
                    return serviceLineItemInputValues;
                }),
            };
        }
    }
    render(state) {
        const serviceNames = state.company === 'NOT_SET' ? [] : state.company.services.map(service => service.name);
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

            ${styles_1.JOCompanyServicesPricingPricingStyles}

            <style>
                .row {
                    display: flex;
                    flex-direction: ${state.isMobileDevice ? `column` : `row`};
                }

                .column-title {
                    color: var(--jo-primary);
                    font-weight: bold;
                    font-size: ${state.isMobileDevice ? `0.8rem` : `1rem`};
                }
            </style>

            <div class="main-container">
                <jo-loading
                    .props=${{
            loading: state.loading,
        }}
                ></jo-loading>
                <div ?hidden=${state.isMobileDevice} class="jo-global--card-title card-title">
                    PRICING
                </div>

                <div
                    class="add-line-item-container"
                    ?hidden=${(0, utilities_1.shouldHideFromCompanyUser)(this.store.authenticatedUser)}
                >
                    <jo-button
                        class="add-button"
                        .type=${'ACTION_BUTTON_2'}
                        .text=${'Add line item'}
                        @joButtonClick=${() => this.addLineItem()}
                    ></jo-button>
                    <jo-button
                        class="add-button"
                        .type=${'ACTION_BUTTON_2'}
                        .text=${'Delete all pricing line items'}
                        @joButtonClick=${() => (0, utilities_1.raiseCustomEvent)(this, 'deleteAllPricingLineItemsClicked')}
                    ></jo-button>
                </div>

                ${state.inputValues.serviceLineItemsInputValues.map((serviceLineItemInputValues, outerIndex) => {
            return (0, lit_html_1.html) `
                            <div class="row">
                                <div class="col-1">
                                    <div class="column-title">Service</div>
                                    <jo-input
                                        .readonly=${(0, utilities_1.shouldHideFromCompanyUser)(this.store.authenticatedUser)}
                                        .type=${'SELECT'}
                                        .selectOptions=${serviceNames}
                                        .selectedValue=${(0, utilities_1.trimExtraWhiteSpace)(serviceLineItemInputValues.service.name)}
                                        .defaultOption=${'Select...'}
                                        .formErrors=${serviceLineItemInputValues.formErrors}
                                        .required=${true}
                                        @inputchange=${(e) => {
                // TODO we need to figure out a way to abstract all of this code for setting the input values
                // TODO this seems to be somewhat of a bottleneck now, we're repeating this code a lot
                this.inputValues = {
                    ...this.store.inputValues,
                    serviceLineItemsInputValues: this.store.inputValues.serviceLineItemsInputValues.map((serviceLineItemInputValue, innerIndex) => {
                        return (0, helper_utils_1.getUpdatedServiceLineItemInputValue)(serviceLineItemInputValue, this.store.company, outerIndex, innerIndex, e);
                    }),
                };
            }}
                                    ></jo-input>
                                </div>

                                <div class="col-2">
                                    <div class="column-title">Description</div>
                                    <textarea
                                        class="jo-global--input jo-global--is-disabled"
                                        rows="4"
                                        style="white-space:  pre-line"
                                        ?disabled=${(0, utilities_1.shouldHideFromCompanyUser)(this.store.authenticatedUser)}
                                        .value=${serviceLineItemInputValues.description}
                                        @input=${(e) => {
                this.inputValues = {
                    ...this.store.inputValues,
                    serviceLineItemsInputValues: this.store.inputValues.serviceLineItemsInputValues.map((serviceLineItemInputValues, innerIndex) => {
                        if (outerIndex === innerIndex) {
                            return {
                                ...serviceLineItemInputValues,
                                description: e.target.value,
                            };
                        }
                        return serviceLineItemInputValues;
                    }),
                };
            }}
                                    ></textarea>
                                </div>

                                <div class="price-check-delete-container">
                                    <div class="col-3">
                                        <div class="column-title">Price</div>
                                        <input
                                            style="width: 100%"
                                            class="jo-global--input jo-global--is-disabled"
                                            type="number"
                                            min="0"
                                            ?disabled=${(0, utilities_1.shouldHideFromCompanyUser)(this.store.authenticatedUser)}
                                            .value=${(serviceLineItemInputValues.price / 100).toString()}
                                            @focus=${(e) => {
                (0, no_scroll_on_focus_1.noScrollOnFocus)(e);
            }}
                                            @change=${(e) => {
                this.inputValues = {
                    ...this.store.inputValues,
                    serviceLineItemsInputValues: this.store.inputValues.serviceLineItemsInputValues.map((serviceLineItemInputValues, innerIndex) => {
                        if (outerIndex === innerIndex) {
                            return {
                                ...serviceLineItemInputValues,
                                price: (0, utilities_1.formatMoneyInUSDCents)(Number(e.target.value)),
                            };
                        }
                        return serviceLineItemInputValues;
                    }),
                };
            }}
                                        />
                                    </div>

                                    <div
                                        class="col-4"
                                        ?hidden=${(0, utilities_1.shouldHideFromCompanyUser)(this.store.authenticatedUser)}
                                    >
                                        <div class="column-title">Calculate</div>
                                        <input
                                            type="checkbox"
                                            .checked=${serviceLineItemInputValues.calculate}
                                            @change=${(e) => {
                this.inputValues = {
                    ...this.store.inputValues,
                    serviceLineItemsInputValues: this.store.inputValues.serviceLineItemsInputValues.map((serviceLineItemInputValues, innerIndex) => {
                        if (outerIndex === innerIndex) {
                            return {
                                ...serviceLineItemInputValues,
                                calculate: e.target.checked,
                            };
                        }
                        return serviceLineItemInputValues;
                    }),
                };
            }}
                                        />
                                    </div>

                                    <div
                                        class="col-5"
                                        ?hidden=${(0, utilities_1.shouldHideFromCompanyUser)(this.store.authenticatedUser)}
                                    >
                                        <jo-button
                                            .type=${'ICON'}
                                            .icon=${'delete'}
                                            @joButtonClick=${() => this.deleteLineItem(serviceLineItemInputValues)}
                                        ></jo-button>
                                    </div>
                                </div>
                            </div>
                            <div
                                ?hidden=${!state.isMobileDevice}
                                class="service-divider-border"
                            ></div>
                        `;
        })}
            </div>

            <div
                class="jo-global--emptyIconContainer jo-global--card"
                ?hidden=${(0, helper_utils_1.isInputValuesEmpty)(this.store.inputValues) === false}
            >
                <i class="material-icons emptyIcon">support_agent</i>
                <h3>Pricing has not been added.</h3>
                <br />
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-services-pricing-pricing', JOCompanyServicesPricingPricing);
