"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../../../services/utilities");
const define_custom_element_1 = require("../../../../../utilities/define-custom-element");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    companyId: 'NOT_SET',
    callInstructions: [],
};
class JOCustomerCallInstructionsPagesMobile extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <style>
                .title-container {
                    font-size: 1.5rem;
                    padding: 1rem;
                    text-align: center;
                }
                .card {
                    margin-bottom: 1rem;
                }
                .main-container {
                    padding-bottom: 4rem;
                }
            </style>
            <div class="main-container">
                ${state.callInstructions.map(item => {
            return (0, lit_html_1.html) `
                        <div
                            class="jo-global--card card"
                            @click=${() => (0, utilities_1.navigate)(`/company/call-instruction?companyId=${state.companyId}&callInstructionId=${item.id}`)}
                        >
                            <div class="title-container">${item.title}</div>
                        </div>
                    `;
        })}
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-customer-call-instructions-pages-mobile', JOCustomerCallInstructionsPagesMobile);
