"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVoicemails = void 0;
const graphql_1 = require("../../../services/graphql");
const error_handling_1 = require("../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function getVoicemails(companyId, offset, limit) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!, $offset: Int!, $limit: Int!) {
                    findVoicemails(
                        filter: {company_id: {eq: $companyId}, archived: {eq: false}}
                        page: {offset: $offset, limit: $limit}
                        orderBy: {field: "created_at", order: DESC}
                    ) {
                        items {
                            id
                            recording_url
                            created_at
                            listened
                            archived
                            contact {
                                id
                                first_name
                                last_name
                                phone_number
                                company {
                                    id
                                    name
                                }
                            }
                        }
                        total
                    }
                }
            `, {
            companyId,
            offset,
            limit,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        const voicemailsForCompany = gqlResult.value.data.findVoicemails.items;
        const totalItems = gqlResult.value.data.findVoicemails.total;
        return {
            succeeded: true,
            value: {
                items: voicemailsForCompany,
                totalItems,
            },
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('getVoicemails', error);
    }
}
exports.getVoicemails = getVoicemails;
