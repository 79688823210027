"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortBusinessHoursByDayOfWeek = void 0;
const types_for_business_hours_1 = require("./types-for-business-hours");
const sortOrder = [
    types_for_business_hours_1.DayOfWeek.SUNDAY,
    types_for_business_hours_1.DayOfWeek.MONDAY,
    types_for_business_hours_1.DayOfWeek.TUESDAY,
    types_for_business_hours_1.DayOfWeek.WEDNESDAY,
    types_for_business_hours_1.DayOfWeek.THURSDAY,
    types_for_business_hours_1.DayOfWeek.FRIDAY,
    types_for_business_hours_1.DayOfWeek.SATURDAY,
];
function sortBusinessHoursByDayOfWeek(businessHours) {
    return [...businessHours].sort((a, b) => {
        const dayOfWeekA = sortOrder.indexOf(a.day_of_week);
        const dayOfWeekB = sortOrder.indexOf(b.day_of_week);
        return dayOfWeekA - dayOfWeekB;
    });
}
exports.sortBusinessHoursByDayOfWeek = sortBusinessHoursByDayOfWeek;
