"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getColumnsForUserType = exports.getColumns = void 0;
const lit_1 = require("lit");
const utilities_1 = require("../../../services/utilities");
const auth_rules_1 = require("../../../utilities/auth-rules");
const get_html_to_show_who_ended_call_1 = require("../jo-jill/get-html-to-show-who-ended-call");
const calculate_call_wait_time_1 = require("./calculate-call-wait-time");
const helper_utils_1 = require("./helper-utils");
const types_constants_1 = require("./types-constants");
function getColumns() {
    const columns = [
        {
            title: 'id',
            getCellData: (call) => call.id,
            width: 0.3,
        },
        {
            title: 'Date',
            getCellData: (call) => new Date(call.created_at).toLocaleString(),
            width: 1,
        },
        {
            title: 'Billable Duration',
            getCellData: (call) => {
                if (call.was_personal_admin_call) {
                    return `${call.billable_duration} (Admin Call)`;
                }
                return (0, utilities_1.millisecondsToHoursMinutesSeconds)(call.billable_duration);
            },
            width: 1,
        },
        {
            title: 'Handle Duration',
            getCellData: (call) => (0, utilities_1.millisecondsToHoursMinutesSeconds)(call.duration || 0),
            width: 1,
        },
        {
            title: 'Action Item Duration',
            getCellData: (call) => (0, utilities_1.millisecondsToHoursMinutesSeconds)((0, helper_utils_1.calculateCallActionItemDurationFromDurationEvents)(call.duration_events)),
            width: 1,
        },
        {
            title: 'Wait Time',
            getCellData: (call) => {
                const result = (0, calculate_call_wait_time_1.calculateCallWaitTime)(call);
                return (0, utilities_1.millisecondsToHoursMinutesSeconds)(result);
            },
            width: 1,
        },
        {
            title: 'Direction',
            getCellData: (call) => makeCallDirectionHTML(call),
            getCellDataForCSV: (call) => getFriendlyCallDirection(call),
            width: 1,
        },
        {
            title: 'Phone Number',
            getCellData: (call) => (0, helper_utils_1.getPhoneNumberForCallLogTable)(call),
            width: 1,
        },
        {
            title: 'Jill',
            getCellData: (call) => {
                return getJillNameOrCallStatus(call);
            },
            width: 1,
        },
        {
            title: 'Twilio Console',
            getCellData: (call) => (0, lit_1.html) `
                <a href="${makeTwilioConsoleLink(call.twilio_parent_call_sid)}" target="_blank">
                    <img src="/images/logo-twilio-mark_red.png" style="height: 2.4rem" />
                </a>
            `,
            getCellDataForCSV: (call) => makeTwilioConsoleLink(call.twilio_parent_call_sid),
            width: 0.5,
        },
        {
            title: 'Recording',
            getCellData: (call) => {
                return call.recording_url
                    ? (0, lit_1.html) `
                          <div>
                              <a
                                  class="jo-company-call-log--play-button-icon"
                                  @click=${() => (0, utilities_1.joAudioPopUp)(call.recording_url)}
                              >
                                  <span class="material-icons">play_circle_outline</span>
                              </a>
                          </div>
                      `
                    : '';
            },
            getCellDataForCSV: (call) => call.recording_url,
            width: 0.5,
        },
    ];
    return columns;
}
exports.getColumns = getColumns;
function makeTwilioConsoleLink(twilioParentCallSid) {
    if (!twilioParentCallSid) {
        return 'N/A';
    }
    return `https://www.twilio.com/console/voice/calls/logs/${twilioParentCallSid}`;
}
function getColumnsForUserType(user) {
    if ((0, auth_rules_1.isCompanyUser)(user)) {
        return getColumns().filter(col => {
            return (col.title !== 'Twilio Console' &&
                col.title !== 'Recording' &&
                col.title !== 'Direction' &&
                col.title !== 'Action Item Duration' &&
                col.title !== 'Wait Time');
        });
    }
    return getColumns();
}
exports.getColumnsForUserType = getColumnsForUserType;
function makeCallDirectionHTML(call) {
    const direction = getFriendlyCallDirection(call);
    return (0, get_html_to_show_who_ended_call_1.getHtmlToShowWhoEndedCall)(direction, call.twilio_reason_conference_ended);
}
function getFriendlyCallDirection(call) {
    const wasCallBackInitiated = call.duration_events.some(event => event.description === 'CALLBACK_INITIATED');
    if (wasCallBackInitiated) {
        return 'OUTGOING (CALLBACK)';
    }
    return call.direction === 0 ? 'INCOMING' : 'OUTGOING';
}
function getJillNameOrCallStatus(call) {
    const durationEventDescription = 'CALL_ADDED_TO_CALLBACK_QUEUE';
    if (call?.marchex_recommendation === 'BLOCK') {
        return types_constants_1.CallStatus.BlockedByTwilio;
    }
    if (call?.contact?.status === 3) {
        return types_constants_1.CallStatus.CallerBlocked;
    }
    if (call?.duration_events?.filter(event => {
        if (event.description !== durationEventDescription) {
            return event;
        }
    }).length === 0) {
        return types_constants_1.CallStatus.CallerDisconnected;
    }
    if (call?.marchex_recommendation === 'ERROR') {
        return types_constants_1.CallStatus.MarchexError;
    }
    return call.user?.first_name;
}
