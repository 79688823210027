"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOauthAppInfoFromClientId = void 0;
const zapier_oauth_app_info_1 = require("./oauth-app-info/zapier-oauth-app-info");
const allOauthAppInfo = [
    zapier_oauth_app_info_1.zapierOauthAppInfo,
];
function createOauthAppInfoMap(oauthApps) {
    const appMap = Object.fromEntries(oauthApps.map(oauthApp => [
        oauthApp.clientId,
        oauthApp,
    ]));
    return appMap;
}
const oauthAppInfoMap = createOauthAppInfoMap(allOauthAppInfo);
function getOauthAppInfoFromClientId(clientId) {
    return oauthAppInfoMap[clientId];
}
exports.getOauthAppInfoFromClientId = getOauthAppInfoFromClientId;
