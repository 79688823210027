"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRightTickIconHTML = exports.getBlockIconHTML = exports.getColumnsToShowForJills = exports.getColumnsToShowForCompanyOwner = void 0;
const lit_html_1 = require("lit-html");
function getColumnsToShowForCompanyOwner(element) {
    const columns = [
        {
            title: 'Name',
            getCellData: (googleCalendar) => {
                if (!googleCalendar) {
                    return '';
                }
                return googleCalendar.google_calendar_name;
            },
            width: 0.3,
        },
        {
            title: 'Can Create Event',
            getCellData: (googleCalendar) => {
                return googleCalendar.is_visible
                    ? (0, lit_html_1.html) `
                          <jo-button
                              .type=${'DELETE_BUTTON_1'}
                              .text=${'Remove Access'}
                              .inlineStyle=${'font-size: 0.9rem; padding: 0.5rem 0.75rem'}
                              @joButtonClick=${async () => {
                        await element.hideGoogleCalendar(googleCalendar.id);
                        element.triggerTableReload();
                    }}
                          ></jo-button>
                      `
                    : (0, lit_html_1.html) `
                          <jo-button
                              .type=${'ACTION_BUTTON_GREEN'}
                              .text=${'Give Access'}
                              .inlineStyle=${'font-size: 0.9rem; padding: 0.5rem 0.75rem'}
                              @joButtonClick=${async () => {
                        await element.makeGoogleCalendarVisible(googleCalendar.id);
                        element.triggerTableReload();
                    }}
                          ></jo-button>
                      `;
            },
            width: 0.3,
        },
    ];
    return columns;
}
exports.getColumnsToShowForCompanyOwner = getColumnsToShowForCompanyOwner;
function getColumnsToShowForJills(element) {
    const columns = [
        {
            title: 'Name',
            getCellData: (googleCalendar) => {
                if (!googleCalendar) {
                    return '';
                }
                return googleCalendar.google_calendar_name;
            },
            width: 0.3,
        },
        {
            title: 'Can Jill Create Event',
            getCellData: (googleCalendar) => googleCalendar.is_visible ? getRightTickIconHTML() : getBlockIconHTML(),
            width: 0.2,
        },
    ];
    return columns;
}
exports.getColumnsToShowForJills = getColumnsToShowForJills;
function getBlockIconHTML() {
    return (0, lit_html_1.html) `
        <span class="material-icons" style="color: var(--jo-danger);">cancel</span>
    `;
}
exports.getBlockIconHTML = getBlockIconHTML;
function getRightTickIconHTML() {
    return (0, lit_html_1.html) `
        <span class="material-icons" style="color: var(--jo-success);">check_circle</span>
    `;
}
exports.getRightTickIconHTML = getRightTickIconHTML;
