"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
require("../jo-button.element");
require("../jo-input");
const mutations_1 = require("./mutations");
const InitialState = {
    authenticatedUser: 'NOT_SET',
};
class JOToggleAdminMode extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    async adminModeToggleButtonClicked() {
        (0, utilities_1.assertIsSet)(this.store.authenticatedUser, utilities_1.handleError, 'this.store.authenticatedUser');
        await this.togglePersonalAdminMode();
        if (this.store.authenticatedUser.in_personal_admin_mode) {
            this.setJillUnavailable();
        }
    }
    async togglePersonalAdminMode() {
        (0, utilities_1.assertIsSet)(this.store.authenticatedUser, utilities_1.handleError, 'this.store.authenticatedUser');
        const updateResult = await (0, mutations_1.setPersonalAdminMode)(this.store.authenticatedUser.id, !this.store.authenticatedUser.in_personal_admin_mode);
        (0, utilities_1.assertSucceeded)(updateResult, utilities_1.handleError);
    }
    async setJillUnavailable() {
        (0, utilities_1.assertIsSet)(this.store.authenticatedUser, utilities_1.handleError, 'this.store.authenticatedUser');
        const result = await (0, mutations_1.setJillUnavailable)(this.store.authenticatedUser.id);
        (0, utilities_1.assertSucceeded)(result, utilities_1.handleError);
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }
            </style>

            <div class="main-container">
                <jo-button
                    .type=${getToggleAdminModeButtonType(state.authenticatedUser)}
                    .text=${getToggleAdminModeButtonTitle(state.authenticatedUser)}
                    @joButtonClick=${() => this.adminModeToggleButtonClicked()}
                ></jo-button>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-toggle-admin-mode', JOToggleAdminMode);
function getToggleAdminModeButtonTitle(authenticatedUser) {
    (0, utilities_1.assertIsSet)(authenticatedUser, utilities_1.handleError, 'authenticatedUser');
    if (!authenticatedUser.in_personal_admin_mode)
        return 'Start Personal Admin Mode';
    else
        return 'Stop Personal Admin Mode';
}
function getToggleAdminModeButtonType(authenticatedUser) {
    (0, utilities_1.assertIsSet)(authenticatedUser, utilities_1.handleError, 'authenticatedUser');
    if (!authenticatedUser.in_personal_admin_mode)
        return 'ACTION_BUTTON_GREEN';
    else
        return 'DELETE_BUTTON_1';
}
