"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.companyQueryShape = exports.fontFamily = exports.fontSize = exports.cssName = exports.InitialState = void 0;
const common_1 = require("@augment-vir/common");
const css_utils_1 = require("../../../../utilities/css-utils");
const typed_gql_query_input_1 = require("../../../../utilities/gql/typed-gql-query/typed-gql-query-input");
exports.InitialState = {
    authenticatedUser: 'NOT_SET',
    autoResponses: [],
    companyId: 'NOT_SET',
    isMobileDevice: false,
    newAutoMessageInput: '',
    saving: false,
    scriptSettings: undefined,
};
exports.cssName = (0, css_utils_1.cssPrefixer)('jo-chat-widget-settings');
exports.fontSize = [
    '9px',
    '10px',
    '11px',
    '12px',
    '14px',
    '16px',
    '18px',
    '20px',
];
exports.fontFamily = [
    'Arial',
    'Bookman',
    'Calibri',
    'Cambria',
    'Courier New',
    'Cursive',
    'Garamond',
    'Georgia',
    'Sans-serif',
    'Monospace',
    'Verdana',
];
exports.companyQueryShape = (0, common_1.wrapNarrowTypeWithTypeCheck)()({
    id: true,
    chat_key: true,
    chat_widget_settings: [
        {
            fields: {
                id: true,
                font_family: true,
                font_size: true,
                auto_responses: [
                    {
                        fields: {
                            id: true,
                            body: true,
                            position: true,
                            created_at: true,
                        },
                        orderBy: {
                            field: 'position',
                            order: typed_gql_query_input_1.SortOrderEnum.Ascending,
                        },
                    },
                ],
                primary_color: true,
                secondary_color: true,
                title: true,
            },
        },
    ],
});
