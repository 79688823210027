"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../../services/graphql");
const utilities_1 = require("../../../services/utilities");
require("../jo-table");
require("../jo-button.element");
require("../jo-loading");
const store_1 = require("../../../services/store");
require("../jo-customer/jo-customer-call-instructions/jo-customer-call-instructions-script-pages-mobile/jo-customer-call-instructions-pages-mobile");
const auth_rules_1 = require("../../../utilities/auth-rules");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
const InitialState = {
    authenticatedUser: store_1.InitialGlobalState.authenticatedUser,
    isMobileDevice: false,
    callInstructionCreatedSubscription: 'NOT_SET',
    callInstructionUpdatedSubscription: 'NOT_SET',
    callInstructions: [],
    companyId: 'NOT_SET',
    loading: true,
};
class JOCompanyCallInstructionsPages extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
        this.callGetCallInstructions = (async (offset, limit, searchText) => {
            return await getCallInstructions(offset, limit, searchText, this.store.companyId);
        }).bind(this);
        this.reRenderTable = (() => {
            this.callGetCallInstructions = this.callGetCallInstructions.bind(this);
            this.store.companyId = this.store.companyId;
        }).bind(this);
    }
    set companyId(companyId) {
        if (companyId === this.store.companyId) {
            return;
        }
        this.callGetCallInstructions = this.callGetCallInstructions.bind(this);
        this.store.companyId = companyId;
        if (this.store.companyId !== 'NOT_SET') {
            this.closeSubscriptionsIfNecessary();
            this.createSubscriptionsIfNecessary();
        }
    }
    async changeCallInstructionOrder(callInstruction, upOrDown) {
        const callInstructionIds = this.store.callInstructions.map((callInstruction) => {
            return callInstruction.id;
        });
        const callInstructionIndex = callInstructionIds.indexOf(callInstruction.id);
        if (upOrDown === 'UP') {
            const previousCallInstruction = this.store.callInstructions[callInstructionIndex - 1];
            if (previousCallInstruction !== undefined &&
                previousCallInstruction.title !== 'Outbound') {
                const updateCallInstructionResult = await updateCallInstructionOrder(callInstruction.id, previousCallInstruction.order);
                const updatePreviousCallInstructionResult = await updateCallInstructionOrder(previousCallInstruction.id, callInstruction.order);
                (0, utilities_1.assertSucceeded)(updateCallInstructionResult, utilities_1.handleError);
                (0, utilities_1.assertSucceeded)(updatePreviousCallInstructionResult, utilities_1.handleError);
            }
        }
        else {
            const nextCallInstruction = this.store.callInstructions[callInstructionIndex + 1];
            if (nextCallInstruction !== undefined) {
                const updateCallInstructionResult = await updateCallInstructionOrder(callInstruction.id, nextCallInstruction.order);
                const updateNextCallInstructionResult = await updateCallInstructionOrder(nextCallInstruction.id, callInstruction.order);
                (0, utilities_1.assertSucceeded)(updateCallInstructionResult, utilities_1.handleError);
                (0, utilities_1.assertSucceeded)(updateNextCallInstructionResult, utilities_1.handleError);
            }
        }
        this.reRenderTable();
    }
    getColumns(reRenderTableCallBack) {
        const columns = [
            {
                title: 'Actions',
                getCellData: (callInstruction) => {
                    return (0, lit_html_1.html) `
                        <div class="jo-global-table--actions">
                            <jo-button
                                class="jo-global-table--action"
                                .type=${'ICON'}
                                .icon=${(0, auth_rules_1.isCompanyUser)(this.store.authenticatedUser)
                        ? 'remove_red_eye'
                        : 'edit'}
                                .href=${`/company/call-instruction?companyId=${this.store.companyId}&callInstructionId=${callInstruction.id}`}
                            ></jo-button>
                        </div>
                    `;
                },
                width: 1,
            },
            {
                title: 'Title',
                getCellData: (callInstruction) => {
                    return (0, lit_html_1.html) `
                        ${callInstruction.title}
                    `;
                },
                width: 2,
            },
            {
                title: 'Appear in Call Screen',
                getCellData: (callInstruction) => {
                    if (callInstruction.title === 'Inbound Instructions' ||
                        callInstruction.title === 'Company Info') {
                        return '';
                    }
                    else {
                        return (0, lit_html_1.html) `
                            <input
                                type="checkbox"
                                .checked=${callInstruction.appear_in_call_screen}
                                @change=${async (e) => {
                            const result = await updateCallInstructionAppearInCallScreen(callInstruction.id, e.target.checked);
                            (0, utilities_1.assertSucceeded)(result, utilities_1.handleError);
                        }}
                            />
                        `;
                    }
                },
                width: 1,
            },
            {
                title: 'Order',
                getCellData: (callInstruction) => {
                    if (callInstruction.title === 'Inbound Instructions' ||
                        callInstruction.title === 'Company Info' ||
                        callInstruction.title === 'FAQs' ||
                        callInstruction.title === 'Outbound') {
                        return '';
                    }
                    return (0, lit_html_1.html) `
                        <div class="jo-global-table--actions">
                            <jo-button
                                class="jo-global-table--action"
                                .type=${'ICON'}
                                .icon=${'keyboard_arrow_up'}
                                @joButtonClick=${() => this.changeCallInstructionOrder(callInstruction, 'UP')}
                            ></jo-button>

                            <jo-button
                                class="jo-global-table--action"
                                .type=${'ICON'}
                                .icon=${'keyboard_arrow_down'}
                                @joButtonClick=${() => this.changeCallInstructionOrder(callInstruction, 'DOWN')}
                            ></jo-button>
                        </div>
                    `;
                },
                width: 1,
            },
            {
                title: '',
                getCellData: (callInstruction) => {
                    return (0, lit_html_1.html) `
                        <jo-button
                            .type=${'ICON'}
                            .icon=${'delete'}
                            @click=${async () => {
                        const confirm = await (0, utilities_1.joConfirm)('Confirm', 'Are you sure you want to delete these instructions?');
                        if (confirm === true) {
                            const result = await deleteCallInstruction(callInstruction.id);
                            (0, utilities_1.assertSucceeded)(result, utilities_1.handleError);
                            reRenderTableCallBack();
                        }
                    }}
                        ></jo-button>
                    `;
                },
                width: 1,
            },
        ];
        if ((0, auth_rules_1.isCompanyUser)(this.store.authenticatedUser)) {
            return filterTableColumnsForcompanyUser(columns);
        }
        return columns;
    }
    closeSubscriptionsIfNecessary() {
        this.closeCallInstructionCreatedSubscriptionIfNecessary();
        this.closeCallInstructionUpdatedSubscriptionIfNecessary();
    }
    closeCallInstructionCreatedSubscriptionIfNecessary() {
        if (this.store.callInstructionCreatedSubscription !== 'NOT_SET' &&
            this.store.callInstructionCreatedSubscription.companyId !== this.store.companyId) {
            this.store.callInstructionCreatedSubscription.webSocket?.close();
            this.store.callInstructionCreatedSubscription = 'NOT_SET';
        }
    }
    closeCallInstructionUpdatedSubscriptionIfNecessary() {
        if (this.store.callInstructionUpdatedSubscription !== 'NOT_SET' &&
            this.store.callInstructionUpdatedSubscription.companyId !== this.store.companyId) {
            this.store.callInstructionUpdatedSubscription.webSocket?.close();
            this.store.callInstructionUpdatedSubscription = 'NOT_SET';
        }
    }
    async createSubscriptionsIfNecessary() {
        await this.createCallInstructionCreatedSubscriptionIfNecessary();
        await this.createCallInstructionUpdatedSubscriptionIfNecessary();
    }
    async createCallInstructionCreatedSubscriptionIfNecessary() {
        if (this.store.callInstructionCreatedSubscription === 'NOT_SET') {
            this.store.callInstructionCreatedSubscription = {
                companyId: this.store.companyId,
                webSocket: await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
                        subscription ($companyId: Int!) {
                            callInstructionCreatedForCompany(companyId: $companyId)
                        }
                    `, () => {
                    // We do not know if this.store.companyId will be set when this callback runs
                    // Therefore we store the companyId with the subscription, and we temporarily set this.store.companyId to the stored value
                    // TODO if we could send in the companyId to callGetActionItems somehow, we could get around this hack
                    const originalCompanyId = this.store.companyId;
                    const temporaryCompanyId = this.store.callInstructionCreatedSubscription === 'NOT_SET'
                        ? originalCompanyId
                        : this.store.callInstructionCreatedSubscription.companyId;
                    this.callGetCallInstructions = this.callGetCallInstructions.bind(this);
                    this.store.companyId = temporaryCompanyId;
                    this.store.companyId = originalCompanyId;
                }, {
                    companyId: this.store.companyId,
                }),
            };
        }
    }
    async createCallInstructionUpdatedSubscriptionIfNecessary() {
        if (this.store.callInstructionUpdatedSubscription === 'NOT_SET') {
            this.store.callInstructionUpdatedSubscription = {
                companyId: this.store.companyId,
                webSocket: await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
                        subscription ($companyId: Int!) {
                            callInstructionUpdatedForCompany(companyId: $companyId)
                        }
                    `, () => {
                    // We do not know if this.store.companyId will be set when this callback runs
                    // Therefore we store the companyId with the subscription, and we temporarily set this.store.companyId to the stored value
                    // TODO if we could send in the companyId to callGetActionItems somehow, we could get around this hack
                    const originalCompanyId = this.store.companyId;
                    const temporaryCompanyId = this.store.callInstructionUpdatedSubscription === 'NOT_SET'
                        ? originalCompanyId
                        : this.store.callInstructionUpdatedSubscription.companyId;
                    this.callGetCallInstructions = this.callGetCallInstructions.bind(this);
                    this.store.companyId = temporaryCompanyId;
                    this.store.companyId = originalCompanyId;
                }, {
                    companyId: this.store.companyId,
                }),
            };
        }
    }
    render(state) {
        if (state.isMobileDevice) {
            return (0, lit_html_1.html) `
                <jo-customer-call-instructions-pages-mobile
                    .props=${{
                authenticatedUser: state.authenticatedUser,
                callInstructions: state.callInstructions,
            }}
                ></jo-customer-call-instructions-pages-mobile>
            `;
        }
        else {
            return (0, lit_html_1.html) `
                <style>
                    .jo-company-call-instructions-pages--main-container {
                        height: 100%;
                        width: 100%;
                        box-sizing: border-box;
                    }
                </style>

                <div class="jo-company-call-instructions-pages--main-container">
                    <jo-button
                        ?hidden=${(0, auth_rules_1.isCompanyUser)(state.authenticatedUser)}
                        .type=${'ACTION_BUTTON_1'}
                        .text=${'New'}
                        .href=${`/company/call-instruction?companyId=${state.companyId}`}
                    ></jo-button>

                    <br />

                    <div class="jo-global--card">
                        <jo-table
                            .tableName=${'Scripts'}
                            .columns=${this.getColumns(this.reRenderTable)}
                            .maxItemsPerPage=${100}
                            .getItems=${this.callGetCallInstructions}
                            .searchDebounce=${200}
                            .showSearchBar=${false}
                            @itemsfetched=${(e) => {
                if (!(0, utilities_1.entityArraysEqual)(e.detail, this.store.callInstructions)) {
                    this.store.callInstructions = e.detail;
                }
                if (this.store.loading === true) {
                    this.store.loading = false;
                }
            }}
                        ></jo-table>
                    </div>
                </div>
            `;
        }
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-call-instructions-pages', JOCompanyCallInstructionsPages);
function filterTableColumnsForcompanyUser(columns) {
    return columns.filter(col => col.title !== 'Appear in Call Screen' && col.title !== 'Order' && col.title !== '');
}
async function getCallInstructions(offset, limit, searchText, companyId) {
    if (companyId === 'NOT_SET') {
        return {
            items: [],
            totalItems: 0,
        };
    }
    const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            query ($companyId: Int!) {
                findCall_instructions(
                    filter: {company_id: {eq: $companyId}}
                    orderBy: {field: "order", order: ASC}
                ) {
                    items {
                        id
                        appear_in_call_screen
                        instructions
                        order
                        title
                    }
                }
            }
        `, {
        companyId,
    });
    // TODO the jo-table getItems function should return a GQLResult
    if (result.succeeded === false) {
        (0, utilities_1.joAlert)('Error', result.userMessage);
        throw new Error(result.developerMessage);
    }
    const callInstructions = result.value.data.findCall_instructions.items;
    return {
        items: callInstructions,
        totalItems: callInstructions.length,
    };
}
async function updateCallInstructionOrder(callInstructionId, order) {
    const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($callInstructionId: Int!, $order: Int!) {
                updateCall_instructions(input: {id: $callInstructionId, order: $order}) {
                    id
                }
            }
        `, {
        callInstructionId,
        order,
    });
    if (result.succeeded === false) {
        return result;
    }
    return {
        succeeded: true,
    };
}
async function updateCallInstructionAppearInCallScreen(callInstructionId, appearInCallScreen) {
    const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($callInstructionId: Int!, $appearInCallScreen: Boolean!) {
                updateCall_instructions(
                    input: {id: $callInstructionId, appear_in_call_screen: $appearInCallScreen}
                ) {
                    id
                }
            }
        `, {
        callInstructionId,
        appearInCallScreen,
    });
    if (result.succeeded === false) {
        return result;
    }
    return {
        succeeded: true,
    };
}
async function deleteCallInstruction(callInstructionId) {
    const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($callInstructionId: Int!) {
                deleteCall_instructions(input: {id: $callInstructionId}) {
                    id
                }
            }
        `, {
        callInstructionId,
    });
    if (result.succeeded === false) {
        return result;
    }
    return {
        succeeded: true,
    };
}
