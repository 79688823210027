"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isDateInScheduleItem = void 0;
const get_end_date_for_schedule_item_1 = require("./get-end-date-for-schedule-item");
const get_start_date_for_schedule_item_1 = require("./get-start-date-for-schedule-item");
function isDateInScheduleItem(scheduleItem, date) {
    const startDate = (0, get_start_date_for_schedule_item_1.getStartDateForScheduleItem)(date, scheduleItem);
    const endDate = (0, get_end_date_for_schedule_item_1.getEndDateForScheduleItem)(date, scheduleItem);
    return date.getTime() >= startDate.getTime() && date.getTime() <= endDate.getTime();
}
exports.isDateInScheduleItem = isDateInScheduleItem;
