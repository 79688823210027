"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shiftItemsAtOrderNumber = exports.ShiftDirection = void 0;
const is_defined_1 = require("../../is-defined");
const sort_by_and_apply_order_number_to_items_1 = require("./sort-by-and-apply-order-number-to-items");
var ShiftDirection;
(function (ShiftDirection) {
    ShiftDirection["UP"] = "UP";
    ShiftDirection["DOWN"] = "DOWN";
})(ShiftDirection = exports.ShiftDirection || (exports.ShiftDirection = {}));
function shiftItemsAtOrderNumber({ items, orderNumber, shiftDirection, }) {
    if (!(0, is_defined_1.isDefined)(orderNumber) || !isOrderNumberInItems(items, orderNumber)) {
        return items;
    }
    const adjacentOrderNumber = getAdjacentOrderNumber(orderNumber, shiftDirection);
    const highestOrderNumber = getHighestOrderNumber(items);
    if (adjacentOrderNumber < 1 || adjacentOrderNumber > highestOrderNumber) {
        return items;
    }
    const modifiedItems = swapOrderNumbers(items, orderNumber, adjacentOrderNumber);
    return (0, sort_by_and_apply_order_number_to_items_1.sortByAndApplyOrderNumberToItems)(modifiedItems);
}
exports.shiftItemsAtOrderNumber = shiftItemsAtOrderNumber;
function isOrderNumberInItems(items, orderNumber) {
    return items.some(item => item.order === orderNumber);
}
function getAdjacentOrderNumber(orderNumber, shiftDirection) {
    return shiftDirection === ShiftDirection.UP ? orderNumber - 1 : orderNumber + 1;
}
function getHighestOrderNumber(items) {
    return Math.max(...items.map(item => item.order));
}
function swapOrderNumbers(items, orderNumber, adjacentOrderNumber) {
    return items.map(item => {
        if (item.order === orderNumber) {
            return {
                ...item,
                order: adjacentOrderNumber,
            };
        }
        if (item.order === adjacentOrderNumber) {
            return {
                ...item,
                order: orderNumber,
            };
        }
        return item;
    });
}
