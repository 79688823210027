"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAndClickHiddenLinkToDownloadCSVBlobUrl = exports.createCSVBlobUrl = exports.generateAndDownloadCSV = void 0;
const utilities_1 = require("../../../../services/utilities");
const calculate_total_charged_for_invoices_1 = require("../../../../utilities/billing-calculations/calculate-total-charged-for-invoices");
const calculate_total_paid_for_invoices_1 = require("../../../../utilities/billing-calculations/calculate-total-paid-for-invoices");
const calculate_total_purchased_minutes_for_company_1 = require("../../../../utilities/billing-calculations/calculate-total-purchased-minutes-for-company");
const calculate_total_overage_milliseconds_for_company_1 = require("../../../../utilities/billing-calculations/usage/calculate-total-overage-milliseconds-for-company");
const calculate_total_unused_milliseconds_for_company_1 = require("../../../../utilities/billing-calculations/usage/calculate-total-unused-milliseconds-for-company");
const build_csv_1 = require("../../../../utilities/csv/build-csv");
const calculate_sum_of_object_at_property_1 = require("../../../../utilities/data-structures/lists/calculate-sum-of-object-at-property");
const has_value_at_key_1 = require("../../../../utilities/data-structures/objects/has-value-at-key");
const calculate_months_between_dates_1 = require("../../../../utilities/dates-and-times/calculate-months-between-dates");
const get_full_name_1 = require("../../../../utilities/get-full-name");
const helper_utils_1 = require("../../jo-company-call-log/helper-utils");
const calculations_1 = require("./calculations");
const make_minutes_packages_summary_for_company_1 = require("./make-minutes-packages-summary-for-company");
function generateAndDownloadCSV(companies, startDate, endDate) {
    const blobURL = createCSVBlobUrl(companies, startDate, endDate);
    createAndClickHiddenLinkToDownloadCSVBlobUrl(blobURL, startDate, endDate);
}
exports.generateAndDownloadCSV = generateAndDownloadCSV;
function createCSVBlobUrl(companies, startDate, endDate) {
    const csv = createCSV(companies, startDate, endDate);
    const blob = new Blob([csv]);
    const blobURL = window.URL.createObjectURL(blob);
    return blobURL;
}
exports.createCSVBlobUrl = createCSVBlobUrl;
function createCSV(companies, startDate, endDate) {
    const getValue = (val) => val || 'N/A';
    const csv = (0, build_csv_1.buildCSV)(companies, [
        {
            header: 'Name',
            getter: company => company.name,
        },
        {
            header: 'VIP',
            getter: company => (company.is_vip ? 'Yes' : 'No'),
        },
        {
            header: 'Pod',
            getter: company => getValue(company.pod?.name),
        },
        {
            header: 'Created_at',
            getter: company => new Date(company.created_at).toLocaleDateString(),
        },
        {
            header: 'Canceled_at',
            getter: company => company.canceled ? new Date(company.canceled_at).toLocaleDateString() : 'N/A',
        },
        {
            header: 'Customer Duration',
            getter: company => (0, calculate_months_between_dates_1.calculateMonthsBetweenDates)(company.created_at, company.canceled_at),
        },
        {
            header: 'Calendar',
            getter: company => getValue(company.calendar?.name),
        },
        {
            header: 'Industry',
            getter: company => getValue(company.industry),
        },
        {
            header: 'Niche',
            getter: company => getValue(company.niche),
        },
        {
            header: 'Account Manager',
            getter: company => getValue((0, get_full_name_1.getFullName)(company.account_manager)),
        },
        {
            header: 'Owner Phone Number',
            getter: company => getValue(company.organization?.owner?.phone_number),
        },
        {
            header: 'Owner Email',
            getter: company => getValue(company.organization?.owner?.email),
        },
        {
            header: 'Preferred Communication',
            getter: company => getValue(company.preferred_communication),
        },
        {
            header: 'Minutes Package(s)',
            getter: company => (0, make_minutes_packages_summary_for_company_1.makeMinutesPackagesSummaryForCompany)({
                company,
                startDate,
                endDate,
                propertyToIncludeInSummary: 'name',
            }),
        },
        {
            header: 'Price Per Minute',
            getter: company => (0, utilities_1.formatUSDCentsForDisplay)(Number((0, make_minutes_packages_summary_for_company_1.makeMinutesPackagesSummaryForCompany)({
                company,
                startDate,
                endDate,
                propertyToIncludeInSummary: 'price_per_minute',
            }))),
        },
        {
            header: 'Amount Due',
            getter: company => formatCentsToDollars((0, calculations_1.getTotalInvoiceAmountForCompany)(company)),
        },
        {
            header: 'Amount Paid',
            getter: company => formatCentsToDollars((0, calculate_total_paid_for_invoices_1.calculateTotalPaidForInvoices)(company.invoices)),
        },
        {
            header: 'Billable Minutes',
            getter: company => (0, helper_utils_1.millisecondsToMinutes)((0, calculate_sum_of_object_at_property_1.calculateSumOfObjectsAtProperty)(company.company_logs, 'billable_duration')),
        },
        {
            header: 'Purchased Minutes',
            getter: company => (0, calculate_total_purchased_minutes_for_company_1.calculateTotalPurchasedMinutesForCompany)(company),
        },
        {
            header: 'Unused Minutes',
            getter: company => (0, helper_utils_1.millisecondsToMinutes)((0, calculate_total_unused_milliseconds_for_company_1.calculateTotalUnusedMillisecondsForCompany)(company)),
        },
        {
            header: 'Overage Minutes',
            getter: company => (0, helper_utils_1.millisecondsToMinutes)((0, calculate_total_overage_milliseconds_for_company_1.calculateTotalOverageMillisecondsForCompany)(company)),
        },
        {
            header: 'Referral Source',
            getter: company => getValue(company.referral_source),
        },
        {
            header: 'Referral Tag',
            getter: company => getValue(company.referral_tag),
        },
        {
            header: 'Amount Charged',
            getter: company => formatCentsToDollars((0, calculate_total_charged_for_invoices_1.calculateTotalChargedForInvoices)(company.invoices)),
        },
        {
            header: 'Payment Status',
            getter: company => (0, calculations_1.determineCompanyPaymentStatus)(company),
        },
        {
            header: 'Has Stripe Id?',
            getter: company => (0, has_value_at_key_1.hasValueAtKey)(company, 'credit_card_key'),
        },
        {
            header: 'Company Status',
            getter: company => getCompanyStatus(company),
        },
    ]);
    return csv;
}
function getCompanyStatus(company) {
    return company.canceled ? 'Canceled' : 'Active';
}
function createAndClickHiddenLinkToDownloadCSVBlobUrl(blobURL, startDate, endDate) {
    const fileName = `billing-report-${startDate.toLocaleDateString()}-${endDate.toLocaleDateString()}.csv`;
    let link = document.createElement('a');
    link.setAttribute('href', blobURL);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
exports.createAndClickHiddenLinkToDownloadCSVBlobUrl = createAndClickHiddenLinkToDownloadCSVBlobUrl;
function formatCentsToDollars(cents) {
    return convertCentsToDollars(cents).toFixed(2);
}
function convertCentsToDollars(cents) {
    return cents / 100;
}
