"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.displaySaveResults = void 0;
const utilities_1 = require("../../../../services/utilities");
function displaySaveResults(result) {
    if (result) {
        (0, utilities_1.joAlert)('Success', 'New chat widget settings have been successfully saved.');
    }
    else {
        (0, utilities_1.joAlert)('Failed', 'New chat widget settings were not able to save.');
    }
}
exports.displaySaveResults = displaySaveResults;
