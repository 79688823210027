"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isDateWithinXMillisecondsOfStartDate = void 0;
const get_start_date_for_schedule_item_1 = require("./get-start-date-for-schedule-item");
function isDateWithinXMillisecondsOfStartDate(date, scheduleItem, milliseconds) {
    const startDateDiff = getStartDateDiff(date, scheduleItem);
    return startDateDiff <= milliseconds;
}
exports.isDateWithinXMillisecondsOfStartDate = isDateWithinXMillisecondsOfStartDate;
function getStartDateDiff(date, scheduleItem) {
    const startDate = (0, get_start_date_for_schedule_item_1.getStartDateForScheduleItem)(date, scheduleItem);
    const startDateDiff = Math.abs(date.getTime() - startDate.getTime());
    return startDateDiff;
}
