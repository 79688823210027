"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dayOfWeekToNumber = void 0;
const daysOfWeek = [
    'SUNDAY',
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
];
function dayOfWeekToNumber(dayOfWeek) {
    return daysOfWeek.indexOf(dayOfWeek);
}
exports.dayOfWeekToNumber = dayOfWeekToNumber;
