"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../services/graphql");
const utilities_1 = require("../../services/utilities");
require("./jo-input");
const error_handling_1 = require("../../utilities/error-handling");
const css_utils_1 = require("../../utilities/css-utils");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const test_id_directive_1 = require("../directives/test-id.directive");
const mapped_env_variables_1 = require("../../utilities/environment/mapped-env-variables");
const InitialState = {
    companies: [],
    companyId: 'NOT_SET',
    includeCanceledCompanies: false,
    loading: true,
    selectedCompany: 'NOT_SET',
    searchText: '',
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-companies-sidebar--');
const cssNames = (classNames) => classNames.split(' ').map(cssName).join(' ');
class JOCompaniesSidebar extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    set companyId(companyId) {
        this.store.companyId = companyId;
        if (companyId === 'NOT_SET') {
            this.store.selectedCompany = 'NOT_SET';
        }
        else {
            this.fetchAndSetSelectedCompany(companyId);
        }
    }
    async fetchAndSetSelectedCompany(companyId) {
        const companyResult = await fetchSelectedCompany(companyId);
        (0, utilities_1.assertSucceeded)(companyResult, utilities_1.handleError);
        this.store.selectedCompany = companyResult.value;
    }
    set searchText(searchText) {
        if (searchText === this.store.searchText) {
            return;
        }
        this.store.searchText = searchText;
        if (searchText === '') {
            this.store.companies = [];
            return;
        }
        else {
            this.getAndSetCompanies();
        }
    }
    async getAndSetCompanies() {
        const getCompaniesResult = await getCompanies({
            offset: 0,
            limit: 20,
            searchText: this.store.searchText,
            includeCanceled: this.store.includeCanceledCompanies,
        }); // TODO decide if 20 is the right limit
        (0, utilities_1.assertSucceeded)(getCompaniesResult, utilities_1.handleError);
        this.store.companies = getCompaniesResult.value.items;
    }
    navigateToCompanyDetails(companyId) {
        this.searchText = '';
        (0, utilities_1.navigate)(`/company/company-details?companyId=${companyId}`);
    }
    includeCanceledCompaniesClicked(checkedValue) {
        this.store.includeCanceledCompanies = checkedValue;
        if (this.store.searchText !== '') {
            this.getAndSetCompanies();
        }
    }
    navigateToNewActionItemForCompany(company) {
        if (company === 'NOT_SET') {
            return;
        }
        (0, utilities_1.navigate)(`/action-item?actionItemId=NOT_SET&companyId=${company.id}&callScreenMode=ACTION_ITEM`);
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .${cssName('main-container')} {
                    font-family: sans-serif;
                    color: var(--jo-text-default);
                    font-weight: 200;
                    height: 100%;
                    width: 100%;
                    border-right: 1px solid var(--jo-accent-background-light);
                    box-shadow: 0 0 4px var(--jo-accent-background-dark);
                }

                .${cssName('main-container')} * {
                    word-wrap: break-word;
                }

                .${cssName('upper-container')} {
                    padding-bottom: 1rem;
                    border-bottom: 2px solid var(--jo-accent-background-light);
                }

                .${cssName('header-container')} {
                    padding: 1rem;
                }

                .${cssName('header')} {
                    margin: 0rem;
                    font-size: 1.2rem;
                    font-weight: bold;
                    color: var(--jo-text-default);
                }

                .${cssName('search-container')} {
                    box-sizing: border-box;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    width: 100%;
                }

                .${cssName('search-input')} {
                    width: 100%;
                }

                .${cssName('companies-container')} {
                    height: 87%;
                    overflow: auto;
                }

                .${cssName('company-container')} {
                    box-sizing: border-box;
                    font-size: 0.9rem;
                    padding-bottom: 1rem;
                    box-shadow: var(--jo-heavy-shadow-2);
                    margin-bottom: 1rem;
                }

                .${cssName('company-container')}:hover {
                    background-color: var(--jo-accent-background-light);
                    cursor: pointer;
                }

                .${cssName('selected')} {
                    background-color: var(--jo-accent-background-light);
                    box-shadow: var(--jo-heavy-shadow-1);
                    border: 1px solid grey;
                }

                .${cssName('selected')}:hover {
                    cursor: auto;
                }

                .${cssName('company-content-container')} {
                    box-sizing: border-box;
                    padding-left: 0.5rem;
                }

                .${cssName('company-header-container')} {
                    display: flex;
                    justify-content: space-between;
                }

                .${cssName('company-header')} {
                    box-sizing: border-box;
                    display: flex;
                    margin: 0;
                    padding: 1rem 0rem;
                }

                .${cssName('include-canceled-container')} {
                    font-size: 0.9rem;
                    box-sizing: border-box;
                    padding-top: 0.5rem;
                }

                .${cssName('canceled-icon')} {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: red;
                    font-size: 1.75rem;
                }

                .${cssName('new-action-item-icon')} {
                    font-size: 1.8rem;
                    padding-top: 0.8rem;
                    padding-right: 1rem;
                    padding-left: 0.5rem;
                }

                .${cssName('new-action-item-icon')}:hover {
                    color: var(--jo-secondary);
                    cursor: pointer;
                }

                .${cssName('info-row')} {
                    padding-bottom: 0.5rem;
                }

                @media screen and (max-width: 1500px) {
                    .${cssName('company-container')} {
                        font-size: 0.85rem;
                    }
                    .${cssName('company-header')} {
                        font-size: 17px;
                        padding-bottom: 0.6rem;
                    }
                    .${cssName('include-canceled-container')} {
                        font-size: calc(0.3rem + 1vmin);
                    }
                    .header-container {
                        padding: 0.5rem;
                        padding-top: 0.7rem;
                    }
                }
            </style>

            <div class="${cssName('main-container')}">
                <div class="${cssName('upper-container')}">
                    <div class="${cssName('header-container')}">
                        <div class="${cssName('header')}">Companies</div>
                    </div>
                    <div class="${cssName('search-container')}">
                        <jo-input
                            ${(0, test_id_directive_1.testId)('companies-sidebar-search-input')}
                            @searchTextChanged=${(e) => (this.searchText = e.detail.searchText)}
                            .props=${{
            type: 'SEARCH',
            inputValue: this.store.searchText,
            debounce: 200,
        }}
                        ></jo-input>

                        <div class="${cssName('include-canceled-container')}">
                            <input
                                type="checkbox"
                                .checked=${state.includeCanceledCompanies}
                                @change=${(e) => this.includeCanceledCompaniesClicked(e.target.checked)}
                            />
                            &nbsp;Include Canceled Companies
                        </div>
                    </div>
                </div>

                <div class="${cssName('companies-container')}">
                    ${state.selectedCompany !== 'NOT_SET' && state.searchText === ''
            ? (0, lit_html_1.html) `
                              <div class="${cssNames('company-container selected')}">
                                  <div class="${cssName('company-content-container')}">
                                      <div class="${cssName('company-header-container')}">
                                          <h2 class="${cssName('company-header')}">
                                              ${state.selectedCompany.name}
                                              ${state.selectedCompany.canceled
                ? (0, lit_html_1.html) `
                                                        &nbsp;
                                                        <i
                                                            class="material-icons ${cssName('canceled-icon')}"
                                                        >
                                                            cancel
                                                        </i>
                                                    `
                : ''}
                                          </h2>
                                          <a
                                              @click=${() => this.navigateToNewActionItemForCompany(state.selectedCompany)}
                                          >
                                              <span
                                                  class="material-icons ${cssName('new-action-item-icon')}"
                                              >
                                                  post_add
                                              </span>
                                          </a>
                                      </div>
                                      <div class="${cssName('info-row')}">
                                          <strong>Owner Name:</strong>
                                          ${state.selectedCompany.organization?.owner?.first_name}
                                          ${state.selectedCompany.organization?.owner?.last_name}
                                      </div>
                                      <div class="${cssName('info-row')}">
                                          <strong>Owner Number:</strong>
                                          ${(0, utilities_1.formatE164PhoneNumberForDisplay)(state.selectedCompany.organization?.owner
                ?.phone_number)}
                                      </div>
                                      <div class="${cssName('info-row')}">
                                          <strong>Owner Email:</strong>
                                          ${state.selectedCompany.organization?.owner?.email}
                                      </div>
                                      <div class="${cssName('info-row')}">
                                          <strong>Company Number:</strong>
                                          ${(0, utilities_1.formatE164PhoneNumberForDisplay)(state.selectedCompany.business_number)}
                                      </div>
                                      <div class="${cssName('info-row')}">
                                          <strong>Twilio Number:</strong>
                                          ${(0, utilities_1.formatE164PhoneNumberForDisplay)(state.selectedCompany.twilio_phone_number)}
                                      </div>
                                  </div>
                              </div>
                          `
            : ''}
                    ${state.companies.map((company) => (0, lit_html_1.html) `
                            <div class="${cssName('company-container')}">
                                <div class="${cssName('company-content-container')}">
                                    <div class="${cssName('company-header-container')}">
                                        <div
                                            ${(0, test_id_directive_1.testId)('open-company-sidebar-item')}
                                            @click=${() => this.navigateToCompanyDetails(company.id)}
                                        >
                                            <h2 class="${cssName('company-header')}">
                                                ${company.name}
                                                ${company.canceled
            ? (0, lit_html_1.html) `
                                                          &nbsp;
                                                          <span
                                                              class="material-icons ${cssName('canceled-icon')}"
                                                          >
                                                              cancel
                                                          </span>
                                                      `
            : ''}
                                            </h2>
                                        </div>
                                        <a
                                            @click=${() => this.navigateToNewActionItemForCompany(company)}
                                        >
                                            <span
                                                class="material-icons ${cssName('new-action-item-icon')}"
                                            >
                                                post_add
                                            </span>
                                        </a>
                                    </div>
                                    <div @click=${() => this.navigateToCompanyDetails(company.id)}>
                                        <div class="${cssName('info-row')}">
                                            <strong>Owner Name:</strong>
                                            ${company.organization?.owner?.first_name}
                                            ${company.organization?.owner?.last_name}
                                        </div>
                                        <div class="${cssName('info-row')}">
                                            <strong>Owner Number:</strong>
                                            ${(0, utilities_1.formatE164PhoneNumberForDisplay)(company.organization?.owner?.phone_number)}
                                        </div>
                                        <div class="${cssName('info-row')}">
                                            <strong>Owner Email:</strong>
                                            ${company.organization?.owner?.email}
                                        </div>
                                        <div class="${cssName('info-row')}">
                                            <strong>Company Number:</strong>
                                            ${(0, utilities_1.formatE164PhoneNumberForDisplay)(company.business_number)}
                                        </div>
                                        <div class="${cssName('info-row')}">
                                            <strong>Twilio Number:</strong>
                                            ${(0, utilities_1.formatE164PhoneNumberForDisplay)(company.twilio_phone_number)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        `)}
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-companies-sidebar', JOCompaniesSidebar);
async function getCompanies(params) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query (
                    $offset: Int!
                    $limit: Int!
                    $ignoreSearch: Boolean!
                    $includeCanceled: Boolean
                    $searchText: String!
                ) {
                    searchCompanies(
                        page: {offset: $offset, limit: $limit}
                        ignoreSearch: $ignoreSearch
                        includeCanceled: $includeCanceled
                        searchText: $searchText
                    ) {
                        companies {
                            id
                            name
                            canceled
                            created_at
                            organization {
                                id
                                owner {
                                    id
                                    first_name
                                    last_name
                                    phone_number
                                    email
                                }
                            }
                            business_number
                            twilio_phone_number
                        }
                        total
                    }
                }
            `, {
            offset: params.offset,
            limit: params.limit,
            ignoreSearch: false,
            includeCanceled: params.includeCanceled,
            searchText: params.searchText,
        });
        // TODO the jo-table getItems function should return a GQLResult
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        const companies = gqlResult.value.data.searchCompanies.companies;
        const getItemsResult = {
            items: companies,
            totalItems: gqlResult.value.data.searchCompanies.total,
        };
        return {
            succeeded: true,
            value: getItemsResult,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('getCompanies', error);
    }
}
async function fetchSelectedCompany(companyId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!) {
                    getCompanies(id: $companyId) {
                        id
                        name
                        business_number
                        canceled
                        twilio_phone_number
                        organization {
                            owner {
                                id
                                first_name
                                last_name
                                phone_number
                                email
                            }
                        }
                    }
                }
            `, {
            companyId,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        else {
            return {
                succeeded: true,
                value: gqlResult.value.data.getCompanies,
            };
        }
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchSelectedCompany', error);
    }
}
