"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getJillProfilePictureUrl = void 0;
const constants_1 = require("../../services/constants");
function getJillProfilePictureUrl(jill) {
    if (jill === 'NOT_SET' || jill === 'NEW_USER' || !jill.profile_picture_url?.length)
        return constants_1.defaultJillProfilePicutreUrl;
    return jill.profile_picture_url;
}
exports.getJillProfilePictureUrl = getJillProfilePictureUrl;
