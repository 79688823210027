"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const css_utils_1 = require("../../../utilities/css-utils");
const last_seen_logged_in_1 = require("../../../utilities/last-seen-logged-in");
const format_schedule_item_for_display_1 = require("../../../utilities/schedule-items/format-schedule-item-for-display");
require("../jo-button.element");
require("../jo-input");
const helper_utils_1 = require("./helper-utils");
const mutations_1 = require("./mutations");
const styles_1 = require("./styles");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const InitialState = {
    jill: 'NOT_SET',
    saving: true,
    allPods: [],
    selectedMainPods: [],
    selectedFloatPods: [],
    selectingPods: false,
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-jill-pods');
class JOJillPods extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    set jill(newJillObj) {
        this.setSelectedPods(newJillObj);
        this.store.jill = newJillObj;
        this.store.saving = false;
    }
    setSelectedPods(newJillObj) {
        if (this.store.selectingPods) {
            return;
        }
        this.store.selectedMainPods = newJillObj.pods;
        this.store.selectedFloatPods = newJillObj.float_pods;
    }
    addOrRemovePodFromSelectedPods(pod, type) {
        const selectedPods = this.getSelectedPodsStateName(type);
        if (this.doesPodExistsInSelectedPods(selectedPods, pod)) {
            this.removePod(selectedPods, pod);
        }
        else {
            this.addPod(selectedPods, pod);
        }
    }
    getSelectedPodsStateName(type) {
        return type === 'MAIN_PODS' ? 'selectedMainPods' : 'selectedFloatPods';
    }
    doesPodExistsInSelectedPods(selectedPods, pod) {
        return this.store[selectedPods].some(selectedPod => pod.id === selectedPod.id);
    }
    addPod(selectedPods, pod) {
        this.store[selectedPods] = [
            ...this.store[selectedPods],
            pod,
        ];
    }
    removePod(selectedPods, pod) {
        this.store[selectedPods] = this.store[selectedPods].filter(selectedPod => pod.id !== selectedPod.id);
    }
    async handleUpdateJillPods() {
        (0, utilities_1.assertIsSet)(this.store.jill, utilities_1.handleError, 'this.store.jill');
        this.store.selectingPods = false;
        this.store.saving = true;
        const updateJillPodsResult = await (0, mutations_1.updateJillPods)(this.store.jill, this.store.selectedMainPods, this.store.selectedFloatPods);
        (0, utilities_1.assertSucceeded)(updateJillPodsResult, utilities_1.handleError);
        (0, utilities_1.joAlert)('Success!', `\n\n${this.store.jill.first_name} ${this.store.jill.last_name}'s pods were successfully updated!`);
        this.store.saving = false;
        (0, utilities_1.raiseCustomEvent)(this, 'jillPodsUpdated', { jillId: this.store.jill.id });
    }
    render(state) {
        const jill = state.jill;
        if (jill === 'NOT_SET')
            return (0, lit_html_1.html) `
                <div>loading...</div>
            `;
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

            ${styles_1.stylesForJoJillPods}

            <div class="${cssName('main-container')}">
                <div class="${cssName('row')}">
                    <div style="flex: 1">
                        <div style="display: flex; gap: 1rem;">
                            <div>
                                <strong>${jill.first_name} ${jill.last_name}</strong>
                            </div>
                            <!-- <div>
                                <jo-button
                                    .type=${'ICON'}
                                    .icon=${'edit'}
                                    @joButtonClick=${() => (0, utilities_1.navigate)(`/user?userId=${jill.id}`)}
                                ></jo-button>
                            </div> -->
                        </div>
                        <div>
                            <ul class="${cssName('list-items-text')}">
                                <li>
                                    <pre><span class="${cssName('shift-hours')}">${jill.shift_hours === ''
            ? 'Shift Not Assigned'
            : jill.shift_hours}</span></pre>
                                </li>
                                <li>
                                    ${(0, last_seen_logged_in_1.wasJillSeenLoggedInWithinXMillisecondsAgo)(jill) === true
            ? (0, lit_html_1.html) `
                                              <span style="color: green;">Online</span>
                                          `
            : (0, lit_html_1.html) `
                                              <span style="color: red;">Offline</span>
                                          `}
                                </li>
                                <li>${(0, helper_utils_1.makeWorkStatusTextHTML)(jill.work_status)}</li>
                                <li>
                                    ${jill.is_available === true
            ? (0, lit_html_1.html) `
                                              <span style="color: green;">Available</span>
                                          `
            : (0, lit_html_1.html) `
                                              <span style="color: red;">Unavailable</span>
                                          `}
                                </li>
                                <li>
                                    ${(0, helper_utils_1.wasJillSeenOnACallWithinTenSecondsAgo)(jill)
            ? (0, lit_html_1.html) `
                                              <span style="color: green;">On a call</span>
                                          `
            : (0, lit_html_1.html) `
                                              <span style="color: red;">Not on a call</span>
                                          `}
                                </li>
                                <li>
                                    ${(0, helper_utils_1.makeHTMLForChatStatus)(jill.last_seen_in_chat_mode, jill.last_seen_actively_chatting_at)}
                                </li>
                                <li class="${cssName('last-call-info-text')}">
                                    <em><strong>Last Call Started At:</strong></em>
                                    ${(0, helper_utils_1.formatTime)(jill.last_call_started_at)}
                                </li>
                                <li class="${cssName('last-call-info-text')}">
                                    <em><strong>Last Call Completed At:</strong></em>
                                    ${(0, helper_utils_1.formatTime)(jill.last_call_completed_at)}
                                </li>
                                <li>${(0, helper_utils_1.makeScheduleStatusTextHTML)(jill.shifts)}</li>
                                <li>
                                    <a href="${window.location.origin}/jill?jillId=${jill.id}">
                                        View Call Log
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div style="flex: 2; font-size: 0.8rem;">
                        <div
                            style="font-size: 0.9rem; display: flex; justify-content: center; gap: 1rem;"
                        >
                            <div>
                                <strong>
                                    <em><u>Shifts</u></em>
                                </strong>
                            </div>
                            <div>
                                <jo-button
                                    .type=${'ICON'}
                                    .icon=${'edit'}
                                    @joButtonClick=${() => (0, utilities_1.navigate)(`/user?userId=${jill.id}`)}
                                ></jo-button>
                            </div>
                        </div>
                        ${jill.shifts?.map(shift => (0, lit_html_1.html) `
                                <div>
                                    <ul>
                                        <li>
                                            <strong>${(0, format_schedule_item_for_display_1.formatScheduleItemForDisplay)(shift)}</strong>
                                        </li>
                                        <ul>
                                            ${shift.scheduled_breaks.map(scheduledBreak => (0, lit_html_1.html) `
                                                    <li>
                                                        Scheduled Break:
                                                        ${(0, format_schedule_item_for_display_1.formatScheduleItemForDisplay)(scheduledBreak, shift)}
                                                    </li>
                                                `)}
                                            ${shift.lunch_breaks.map(lunchBreak => (0, lit_html_1.html) `
                                                    <li>
                                                        Lunch:
                                                        ${(0, format_schedule_item_for_display_1.formatScheduleItemForDisplay)(lunchBreak, shift)}
                                                    </li>
                                                `)}
                                        </ul>
                                    </ul>
                                </div>
                            `)}
                    </div>

                    <div style="flex: 1">
                        <div ?hidden=${jill.profile_picture_url === ''}>
                            <img
                                src="${jill.profile_picture_url}"
                                class="${cssName('profile-picture-img')}"
                            />
                        </div>
                    </div>

                    <div style="flex: 2">
                        <div style="padding-bottom: 1rem;">
                            <u><strong>Main Pods:</strong></u>
                        </div>

                        <div class="${cssName('pods-container')}">
                            ${state.allPods.map(pod => (0, lit_html_1.html) `
                                    <div
                                        class="${cssName('pod-container')} jo-global--pointer"
                                        @click=${() => this.addOrRemovePodFromSelectedPods(pod, 'MAIN_PODS')}
                                    >
                                        <input
                                            type="checkbox"
                                            class="jo-global--pointer"
                                            .checked=${state.selectedMainPods.some(selectedMainPod => selectedMainPod.id === pod.id)}
                                            @input=${() => {
            this.store.selectingPods = true;
        }}
                                        />
                                        ${pod.name}
                                    </div>
                                `)}
                        </div>
                    </div>

                    <div style="flex: 2">
                        <div style="padding-bottom: 1rem;">
                            <u><strong>Float Pods:</strong></u>
                        </div>

                        <div class="${cssName('pods-container')}">
                            ${state.allPods.map(pod => (0, lit_html_1.html) `
                                    <div
                                        class="${cssName('pod-container')} jo-global--pointer"
                                        @click=${() => this.addOrRemovePodFromSelectedPods(pod, 'FLOAT_PODS')}
                                    >
                                        <input
                                            type="checkbox"
                                            .checked=${state.selectedFloatPods.some(selectedFloatPod => selectedFloatPod.id === pod.id)}
                                            @input=${() => {
            this.store.selectingPods = true;
        }}
                                        />
                                        ${pod.name}
                                    </div>
                                `)}
                        </div>
                    </div>

                    <div style="flex: 0.5;">
                        <jo-button
                            .inlineStyle=${'font-size: 0.8rem;'}
                            .text=${state.saving === true ? 'LOADING' : 'UPDATE PODS'}
                            .disabled=${state.saving === true}
                            @joButtonClick=${() => this.handleUpdateJillPods()}
                        ></jo-button>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-jill-pods', JOJillPods);
