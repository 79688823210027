"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const graphql_1 = require("../../services/graphql");
require("./jo-jill-card");
require("./jo-jill/jo-jill.element");
const utilities_1 = require("../../services/utilities");
const reduxular_1 = require("reduxular");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../utilities/environment/mapped-env-variables");
const startOfToday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
const startOfTomorrow = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1);
const InitialState = {
    jills: [],
    startDate: startOfToday.toISOString(),
    endDate: startOfTomorrow.toISOString(),
    authenticatedUser: 'NOT_SET',
};
class JOJills extends HTMLElement {
    constructor() {
        super();
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
        this.fetchSetAndSubscribeToJills();
    }
    async fetchSetAndSubscribeToJills() {
        const jills = await fetchJills();
        this.store.jills = jills;
    }
    async setAndRefreshAllJillsAvailability(availability, jills) {
        await setAllJillsAvailability(availability);
        this.store.jills = [];
        this.store.jills = jills;
    }
    async startDateInputChanged(e) {
        const newStartDate = new Date(`${e.target.value}T00:00`).toISOString();
        this.store.startDate = newStartDate;
    }
    async endDateInputChanged(e) {
        const newEndDate = new Date(`${e.target.value}T00:00`).toISOString();
        this.store.endDate = newEndDate;
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .jo-jills--container {
                    padding: calc(25px + 1vmin);
                }

                .jo-jill-cards-container {
                    display: grid;
                    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
                }

                .jo-jill-card-container {
                    padding: calc(5px + 1vmin);
                    margin: calc(5px + 1vmin);
                    box-shadow: 0px 0px 1px black;
                    overflow-x: scroll;
                    background-color: white;
                    box-shadow: var(--jo-card-shadow-1);
                }
            </style>

            <div class="jo-jills--container">
                <h1>Jills</h1>

                <div>
                    <button
                        @click=${() => this.setAndRefreshAllJillsAvailability(true, state.jills)}
                    >
                        Set all Jills available
                    </button>
                </div>

                <br />

                <div>
                    <button
                        @click=${() => this.setAndRefreshAllJillsAvailability(false, state.jills)}
                    >
                        Set all Jills unavailable
                    </button>
                </div>

                <br />

                <div class="jo-jill-cards-container">
                    ${state.jills.map((jill) => {
            return (0, lit_html_1.html) `
                            <div class="jo-jill-card-container">
                                <jo-jill-card
                                    .jill=${jill}
                                    .authenticatedUser=${state.authenticatedUser}
                                ></jo-jill-card>
                            </div>
                        `;
        })}
                </div>

                <div>Start date</div>
                <div>
                    <input
                        type="date"
                        .value=${state.startDate.split('T')[0]}
                        @change=${(e) => this.startDateInputChanged(e)}
                    />
                </div>
                <br />
                <div>End date</div>
                <div>
                    <input
                        type="date"
                        .value=${state.endDate.split('T')[0]}
                        @change=${(e) => this.endDateInputChanged(e)}
                    />
                </div>
                <br />

                <div>
                    ${state.jills.map((jill) => {
            return (0, lit_html_1.html) `
                            <h3
                                style="cursor: pointer"
                                @click=${() => (0, utilities_1.navigate)(`/jill?jillId=${jill.id}`)}
                            >
                                ${jill.first_name} ${jill.last_name}
                            </h3>
                            <jo-jill
                                .jill=${jill}
                                .showJillCard=${false}
                                .showDatesAndCharts=${false}
                                .statsStartDate=${state.startDate}
                                .statsEndDate=${state.endDate}
                            ></jo-jill>
                            <hr />
                        `;
        })}
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-jills', JOJills);
async function fetchJills() {
    const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
        query {
            findUsers(
                filter: {
                    auth_role: {
                        in: [
                            "JILL"
                            "JILL_OUTBOUND"
                            "JILL_MANAGER"
                            "JILL_BUSINESS_DEVELOPMENT"
                            "JILL_EXECUTIVE"
                        ]
                    }
                }
                orderBy: {field: "first_name", order: ASC}
            ) {
                items {
                    id
                    email
                    first_name
                    last_name
                    is_available
                    pods {
                        name
                    }
                }
            }
        }
    `);
    const jills = await Promise.all(gqlResult.data.findUsers.items.map(async (jill) => {
        const durationEvents = await (0, utilities_1.fetchDurationEventsForJill)(jill.id);
        return {
            ...jill,
            duration_events: durationEvents,
        };
    }));
    return jills;
}
async function setAllJillsAvailability(availability) {
    const confirmation = confirm('Are you sure you want to set the availability for all of the Jills?');
    if (confirmation === false) {
        return;
    }
    await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($availability: Boolean!) {
                setAllJillsAvailability(availability: $availability)

                kickOffQueue
            }
        `, {
        availability,
    });
}
