"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../../services/utilities");
const authenticate_1 = require("../../../../services/auth/authenticate");
const get_default_contact_input_values_1 = require("../../../../utilities/contacts/get-default-contact-input-values");
const css_utils_1 = require("../../../../utilities/css-utils");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
require("../../jo-button.element");
const jo_contact_1 = require("../../jo-contact/jo-contact");
require("../../jo-input");
require("../jo-chat-sidebar/jo-chat-sidebar");
const helper_utils_1 = require("./helper-utils");
const mutations_1 = require("./mutations");
const styles_1 = require("./styles");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    hideSideBar: false,
    selectedChat: 'NOT_SET',
    twilioDevice: 'NOT_SET',
    pathname: 'NOT_SET',
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-chat-dashboard');
const cssNames = (classNames) => classNames.split(' ').map(cssName).join(' ');
let hideNewMessageIcon = false;
class JOChatDashboard extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, (state, action) => {
            if (action.type === 'SET_PROPS') {
                return (0, utilities_1.setPropsReducer)(state, action);
            }
            return state;
        });
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    async viewChatInCallScreen(chat) {
        this.store.selectedChat = chat;
    }
    getActionItemIdForCallScreen() {
        if (this.store.selectedChat !== 'NOT_SET') {
            if (this.store.selectedChat.action_items !== undefined &&
                this.store.selectedChat.action_items.length > 0) {
                const actionItem = this.store.selectedChat.action_items[0];
                if (actionItem == null) {
                    throw new Error('actionItem is null');
                }
                return actionItem.id;
            }
        }
        return 'NOT_SET';
    }
    getCompanyIdForCallScreen() {
        if (this.store.selectedChat !== 'NOT_SET') {
            return this.store.selectedChat.company.id;
        }
        return 'NOT_SET';
    }
    getContactIdForCallScreen() {
        if (this.store.selectedChat !== 'NOT_SET' && this.store.selectedChat.contact) {
            return this.store.selectedChat.contact.id;
        }
        return 'NOT_SET';
    }
    getContactForCallScreen() {
        if (this.store.selectedChat !== 'NOT_SET' && this.store.selectedChat.contact) {
            return { ...this.store.selectedChat.contact };
        }
        return Symbol('NOT_SET');
    }
    getContactInputValuesForCallScreen() {
        if (this.store.selectedChat !== 'NOT_SET') {
            const contact = this.store.selectedChat.contact;
            if (!contact) {
                return (0, get_default_contact_input_values_1.getDefaultContactInputValues)();
            }
            return {
                contactId: contact.id,
                addressesInputValues: (0, jo_contact_1.getAddressesInputValues)(contact.addresses, ''),
                addressIdsToDisconnect: [],
                businessNameInputValue: contact.company_name ?? '',
                emailInputValue: contact.email ?? '',
                firstNameInputValue: contact.first_name ?? '',
                formErrors: [],
                lastNameInputValue: contact.last_name ?? '',
                phoneNumbersInputValues: (0, jo_contact_1.getPhoneNumbersInputValues)(contact.phone_numbers, contact.phone_number),
                phoneNumberIdsToDisconnect: [],
                sales: contact.sales,
                status: contact.status === 3 ? 'BLOCKED' : 'UNBLOCKED',
                whereDidYouHearAboutUsInputValue: contact.where_did_you_hear_about_us,
            };
        }
        return 'NOT_SET';
    }
    async changeChatMode(chatMode) {
        if (this.store.authenticatedUser !== 'NOT_SET') {
            const userId = this.store.authenticatedUser.id;
            await (0, mutations_1.updateUserChatMode)(userId, chatMode);
            await (0, helper_utils_1.updateLastSeenInChatModeIfNeeded)(userId, chatMode);
            await (0, helper_utils_1.sendUserStatusEvent)(chatMode, userId);
            (0, authenticate_1.authenticate)();
        }
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

            ${styles_1.stylesForChatDashboard}
            ${this.store.authenticatedUser !== 'NOT_SET' &&
            this.store.authenticatedUser.chat_mode === 'NONE'
            ? (0, lit_html_1.html) `
                      <h2 class="${cssNames('jo-chat-dashboard--chat-mode-title')}">
                          Select Chat Mode
                      </h2>
                      <div class="jo-chat-dashboard--chat-mode-selector-container">
                          <div
                              tabindex="1"
                              class="jo-chat-dashboard--chat-mode-selector"
                              title="Strict Mode means you won't be interrupted by a call during your chat sessions."
                              @click=${() => {
                this.changeChatMode('STRICT');
            }}
                          >
                              Strict Mode
                          </div>

                          <div
                              tabindex="2"
                              class="jo-chat-dashboard--chat-mode-selector"
                              title="Flexible Mode means you will be doing both calls and chats."
                              @click=${() => {
                this.changeChatMode('FLEXIBLE');
            }}
                          >
                              Flexible Mode
                          </div>
                      </div>
                  `
            : (0, lit_html_1.html) `
                      <div
                          @click=${() => {
                this.changeChatMode('NONE');
            }}
                          class="${cssNames('end-chat-mode-button')}"
                      >
                          End Chat Mode
                      </div>

                      <div class="${cssNames('main-container')}">
                          ${this.store.pathname === '/chat-dashboard'
                ? (0, lit_html_1.html) `
                                    <section
                                        class="${cssNames('chat-sidebar')}"
                                        ?hidden=${this.store.hideSideBar}
                                    >
                                        <jo-chat-sidebar
                                            .props=${{
                    authenticatedUser: state.authenticatedUser,
                    selectedChat: state.selectedChat,
                }}
                                            @newSelectedChat=${(e) => {
                    this.viewChatInCallScreen(e.detail);
                }}
                                            @newMessage=${(e) => {
                    hideNewMessageIcon = !e.detail;
                }}
                                            @closeButtonClicked=${(e) => {
                    this.store.hideSideBar = true;
                }}
                                            @saveSelectedChatInSession=${(e) => {
                    (0, helper_utils_1.triggerSaveSelectedChatOnJoCallScreen)();
                }}
                                        ></jo-chat-sidebar>
                                    </section>
                                `
                : ``}

                          <section
                              ?hidden=${!this.store.hideSideBar}
                              @click=${() => {
                this.store.hideSideBar = false;
            }}
                              style="padding-left: 0.5rem; padding-top: 0.5rem;"
                          >
                              <div class="${cssNames('icon-container')}">
                                  <i ?hidden=${!hideNewMessageIcon} class="material-icons">
                                      chat_bubble_outline
                                  </i>

                                  <i ?hidden=${hideNewMessageIcon} class="material-icons">
                                      textsms
                                  </i>
                              </div>
                          </section>

                          <section class="${cssNames('call-screen-container')}">
                              ${state.selectedChat !== 'NOT_SET'
                ? (0, lit_html_1.html) `
                                        <jo-call-screen
                                            class="${cssNames('call-screen')}"
                                            .props=${{
                    actionItemId: this.getActionItemIdForCallScreen(),
                    authenticatedUser: state.authenticatedUser,
                    callScreenMode: 'CHAT',
                    chatId: this.store.selectedChat !== 'NOT_SET'
                        ? this.store.selectedChat.id
                        : 'NOT_SET',
                    companyId: this.getCompanyIdForCallScreen(),
                    contactId: this.getContactIdForCallScreen(),
                    contact: this.getContactForCallScreen(),
                    contactInputValues: this.getContactInputValuesForCallScreen(),
                    jillInteractionMode: 'CHAT',
                }}
                                            @chatConversationClosed=${(e) => {
                    this.store.selectedChat = 'NOT_SET';
                }}
                                        ></jo-call-screen>
                                    `
                : (0, lit_html_1.html) `
                                        <div class="${cssNames('no-chat-selected')}">
                                            <i
                                                class="material-icons-outlined"
                                                style="font-size:4rem"
                                            >
                                                question_answer
                                            </i>
                                            Select an Active Chat to View It
                                        </div>
                                    `}
                          </section>
                      </div>
                  `}}
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-chat-dashboard', JOChatDashboard);
