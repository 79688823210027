"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.markActionItemAsUnReadForCompanyUser = exports.getEventType = exports.markAIAsUnreadAndNavigateBack = exports.closeActionItemAndNavigateBack = exports.isInEditActionItemMode = exports.getContactFromActionItem = exports.shouldHideSearchComponents = void 0;
const utilities_1 = require("../../../../services/utilities");
const user_status_event_mutations_1 = require("../../../../utilities/logging-and-monitoring/user-status-event-mutations");
const mutations_1 = require("./mutations");
function shouldHideSearchComponents(state) {
    return state.actionItemMode === 'EDIT' || state.contactMode != 'NOT_SET';
}
exports.shouldHideSearchComponents = shouldHideSearchComponents;
function getContactFromActionItem(actionItem) {
    (0, utilities_1.assertIsDefined)(actionItem.contact, 'actionItem.contact', utilities_1.handleError);
    return {
        id: actionItem.contact.id,
        first_name: actionItem.contact.first_name,
        last_name: actionItem.contact.last_name,
        company_name: actionItem.contact.company_name,
        email: actionItem.contact.email,
        addresses: actionItem.contact.addresses,
        phone_numbers: actionItem.contact.phone_numbers,
        sales: actionItem.contact.sales,
        status: actionItem.contact.status,
        where_did_you_hear_about_us: actionItem.contact.where_did_you_hear_about_us,
    };
}
exports.getContactFromActionItem = getContactFromActionItem;
function isInEditActionItemMode(actionItemMode) {
    return actionItemMode === 'EDIT';
}
exports.isInEditActionItemMode = isInEditActionItemMode;
async function closeActionItemAndNavigateBack(state) {
    await (0, mutations_1.closeActionItem)(state.actionItem.id);
    (0, utilities_1.navigate)('../customer/action-items');
}
exports.closeActionItemAndNavigateBack = closeActionItemAndNavigateBack;
async function markAIAsUnreadAndNavigateBack(state) {
    await markActionItemAsUnReadForCompanyUser(state.authenticatedUser, state.actionItemId, state.companyId);
    if (state.companyId !== 'NOT_SET') {
        (0, mutations_1.publishActionItemsMovedForCompany)(state.companyId);
    }
    (0, utilities_1.navigate)('/customer/action-items');
}
exports.markAIAsUnreadAndNavigateBack = markAIAsUnreadAndNavigateBack;
function getEventType(whoToSendTo, mode) {
    if (mode === 'CREATE') {
        return 'CREATED_ACTION_ITEM';
    }
    return whoToSendTo === 'JILL' ? 'SENT_ACTION_ITEM_TO_JILL' : 'SENT_ACTION_ITEM_TO_ADMIN';
}
exports.getEventType = getEventType;
async function markActionItemAsUnReadForCompanyUser(user, actionItemId, companyId) {
    if (user === 'NOT_SET' || companyId === 'NOT_SET' || actionItemId === 'NOT_SET') {
        throw new Error('jo-customer-action-item -> markActionItemAsUnReadForCompanyUser error function arguments are "NOT_SET"');
    }
    if ((0, utilities_1.userIsJill)(user)) {
        return;
    }
    await (0, user_status_event_mutations_1.createUserStatusEvent)({
        userId: user.id,
        eventType: 'MARKED_ACTION_ITEM_AS_UNREAD',
        companyId: companyId,
        actionItemId,
    });
}
exports.markActionItemAsUnReadForCompanyUser = markActionItemAsUnReadForCompanyUser;
