"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const time_format_1 = require("../../../utilities/date/time-format");
require("../jo-button.element");
require("../jo-input");
const InitialState = {
    item: {
        id: 0,
        archived: false,
        contact: { id: 0, first_name: '', last_name: '', phone_number: '' },
        created_at: '',
        updated_at: '',
        isCompanyUser: false,
        companyId: 0,
        listened: false,
        recording_url: '',
    },
    sentDelete: false,
};
class JOCompanyVoicemailsMobileTemplate extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    render(state) {
        const audio = this.shadowRoot?.getElementById(state.item.id.toString());
        if (audio?.getAttribute('id') === state.item.id.toString() && !this.store.sentDelete) {
            audio.addEventListener('play', () => (0, utilities_1.raiseCustomEvent)(this, 'handleMarkVoicemailListened', state.item.id));
            this.store.sentDelete = true;
        }
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }
                .jo-mobile--table-container {
                    box-sizing: border-box;
                    width: 100%;
                    font-size: 0.9rem;
                    padding: 0.5rem;
                    background-color: white;
                    box-shadow: var(--jo-card-shadow-1);
                    border-radius: var(--jo-wide-border-radius-1);
                    margin: 0.75rem 0;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }
                .info-box {
                    padding: 0 1rem;
                    width: 100%;
                }
                .contact-name {
                    font-size: 1rem;
                    font-weight: bold;
                    padding-bottom: 0.5rem;
                    display: block;
                    text-decoration: none;
                    color: black;
                }
                .contact-phone {
                    text-decoration: none;
                    color: var(--jo-primary);
                }
                .contact-address {
                    padding: 0.25rem 0;
                }
                .top-row-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }
                .small-text {
                    font-size: 0.75rem;
                }
                .listened-indicator {
                    width: 1rem;
                }
                .indicator {
                    background-color: var(--jo-primary);
                    height: 0.8rem;
                    width: 0.8rem;
                    border-radius: 50%;
                    margin: 0 auto;
                }
                .delete-button .jo-button--main-container button {
                    border: none;
                    color: var(--jo-accent-background-light);
                    font-size: 1.5rem !important;
                }
                audio {
                    background-color: lightgray;
                    height: 1.75rem;
                    width: 85%;
                }
                audio::-webkit-media-controls-panel {
                    background-color: lightgray;
                }
                audio::-webkit-media-controls-play-button {
                    background-color: lightgray;
                }
                audio::-webkit-media-controls-mute-button {
                    display: none;
                }
                audio::-webkit-media-controls-volume-slider {
                    display: none;
                }
                .audio-row-container {
                    display: flex;
                    flex-direction: row;
                    padding-top: 1rem;
                    justify-content: space-between;
                }
            </style>

            <div class="main-container">
                <div class="jo-mobile--table-container">
                    <div class="listened-indicator">
                        <div class="indicator" ?hidden=${state.item?.listened}></div>
                    </div>
                    <div class="info-box">
                        <div class="top-row-container">
                            <div class="contact-name">
                                ${state.item?.contact?.first_name}
                                ${state.item?.contact?.first_name}
                            </div>
                            <div>
                                <div class="small-text">
                                    ${(0, utilities_1.makeDateTwoChar)(state.item.created_at)}
                                </div>
                                <div class="small-text">
                                    ${(0, time_format_1.formatAs12HourTimeString)(new Date(state.item.created_at))}
                                </div>
                            </div>
                        </div>
                        <div>
                            <a
                                href="tel:${state.item?.contact?.phone_number}"
                                class="contact-phone"
                            >
                                ${(0, utilities_1.formatE164PhoneNumberForDisplay)(state.item?.contact?.phone_number)}
                            </a>
                        </div>
                        <div class="audio-row-container">
                            <audio id="${state.item.id}" controls controlsList="nodownload">
                                <source src=${state.item?.recording_url} />
                            </audio>
                            <jo-button
                                class="delete-button"
                                .type=${'ICON'}
                                .icon=${'delete'}
                                @click=${() => (0, utilities_1.raiseCustomEvent)(this, 'handleDeleteVoicemail', state.item.id)}
                            ></jo-button>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-voicemails-mobile-template', JOCompanyVoicemailsMobileTemplate);
