"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getQueueItemStatus = exports.getCompanyName = exports.getActionButtonsHtml = exports.getHtmlForCompletedAttempt = exports.getHtmlForIncompleteAttempt = exports.getHtmlForCallAttemptBasedOnType = exports.getCallAttemptsHtml = exports.makeCloseButtonHTML = exports.getHtmlOutboundScript = void 0;
const lit_html_1 = require("lit-html");
const utilities_1 = require("../../../../../services/utilities");
const is_defined_1 = require("../../../../../utilities/is-defined");
const manually_close_outbound_queue_item_1 = require("../../../../../utilities/outbound-queue-items/manually-close-outbound-queue-item");
const jo_call_screen_call_instructions_1 = require("../../../jo-call-screen/jo-call-screen-call-instructions");
const publish_outbound_queue_item_updated_event_1 = require("../../../jo-call-screen/utilities/publish-outbound-queue-item-updated-event");
const queries_1 = require("../queries");
const styles_1 = require("../styles");
const helper_utils_1 = require("./helper-utils");
async function getHtmlOutboundScript(companyId) {
    if (companyId) {
        const resultScript = await (0, queries_1.getOutboundScriptFromCompany)(companyId);
        (0, utilities_1.assertSucceeded)(resultScript, utilities_1.handleError, undefined, 'getOutboundScriptFromCompany');
        return (0, lit_html_1.html) `
            ${(0, jo_call_screen_call_instructions_1.renderCallInstructions)(resultScript.value)}
        `;
    }
    return (0, lit_html_1.html) `
        <div>No Script Found</div>
    `;
}
exports.getHtmlOutboundScript = getHtmlOutboundScript;
function makeCloseButtonHTML(queueItem) {
    return (0, lit_html_1.html) `
        <jo-button
            .type=${'ACTION_BUTTON_1'}
            .text=${'Close'}
            @joButtonClick=${async () => {
        await (0, manually_close_outbound_queue_item_1.manuallyCloseOutboundQueueItem)(queueItem.id);
        await (0, publish_outbound_queue_item_updated_event_1.publishOutboundQueueUpdatedMessage)();
    }}
        ></jo-button>
    `;
}
exports.makeCloseButtonHTML = makeCloseButtonHTML;
function getCallAttemptsHtml(queueItem, state) {
    const attemptsNeeded = queueItem.num_attempts_to_make;
    const actionItems = getActionItemIdsOfCompletedAttempts(attemptsNeeded, queueItem);
    const companyId = queueItem.company?.id;
    return (0, lit_html_1.html) `
        ${styles_1.stylesForjoOutboundTableCallAttemptsColumn}

        <div class="jo-outbound-table-columns-attempts-container">
            ${actionItems.map((actionItem, index) => {
        return getHtmlForCallAttemptBasedOnType({
            actionItem,
            state,
            companyId,
            index,
        });
    })}
        </div>
    `;
}
exports.getCallAttemptsHtml = getCallAttemptsHtml;
function getHtmlForCallAttemptBasedOnType(params) {
    if (params.actionItem !== 'NOT_SET' &&
        params.actionItem !== 'CALL_ATTEMPT_ERROR' &&
        (0, is_defined_1.isDefined)(params.actionItem) &&
        (0, is_defined_1.isDefined)(params.actionItem.id) &&
        params.companyId !== undefined) {
        return getHtmlForCompletedAttempt({
            ...params,
            actionItem: params.actionItem,
        });
    }
    else {
        return getHtmlForIncompleteAttempt(params.index);
    }
}
exports.getHtmlForCallAttemptBasedOnType = getHtmlForCallAttemptBasedOnType;
function getHtmlForIncompleteAttempt(index) {
    return (0, lit_html_1.html) `
        <div title="Attempt Not Completed" class="jo-outbound-table-columns-attempt-circle">
            ${index + 1}
        </div>
    `;
}
exports.getHtmlForIncompleteAttempt = getHtmlForIncompleteAttempt;
function getHtmlForCompletedAttempt(params) {
    return (0, lit_html_1.html) `
        <div
            title="View Action Item"
            class="
                jo-outbound-table-columns-completed-attempt-circle 
                ${params.state.selectedActionItemTitle === params.actionItem.title
        ? 'jo-outbound-table-columns-matching-filter'
        : ''}                                    
            "
            @click=${() => {
        (0, utilities_1.joPopUp)({
            title: 'Action Item Notes',
            htmlComponents: getHTMLForActionItemNotesPopUp(params.actionItem.action_item_notes, params.state.authenticatedUser),
        });
    }}
        >
            ${params.index + 1}
        </div>
    `;
}
exports.getHtmlForCompletedAttempt = getHtmlForCompletedAttempt;
function getActionItemIdsOfCompletedAttempts(attemptsNeeded, queueItem) {
    let actionItems = new Array(attemptsNeeded);
    for (let i = 0; i < attemptsNeeded; i++) {
        const outboundAttempt = queueItem.outbound_attempts[i];
        if (outboundAttempt && outboundAttempt.action_item !== null) {
            actionItems[i] = outboundAttempt.action_item;
        }
        else if (outboundAttempt && outboundAttempt.action_item === null) {
            actionItems[i] = 'CALL_ATTEMPT_ERROR';
        }
        else {
            actionItems[i] = 'NOT_SET';
        }
    }
    actionItems = putErrorAttemptsToBackOfArray(actionItems);
    return actionItems;
}
function putErrorAttemptsToBackOfArray(actionItems) {
    actionItems = actionItems.sort((a, b) => {
        if (a !== 'CALL_ATTEMPT_ERROR' && b === 'CALL_ATTEMPT_ERROR') {
            return -1;
        }
        if (a === 'CALL_ATTEMPT_ERROR' && b === 'CALL_ATTEMPT_ERROR') {
            return 0;
        }
        return 1;
    });
    return actionItems;
}
function getActionButtonsHtml(queueItem, authenticatedUser) {
    return (0, lit_html_1.html) `
        <style>
            .outbound-table-action-buttons-container {
                display: flex;
                flex-direction: row;
                gap: 0.2rem;
            }
        </style>

        <div class="outbound-table-action-buttons-container">
            <jo-button
                .type=${'ICON'}
                .icon=${'phone'}
                @joButtonClick=${() => {
        (0, helper_utils_1.startCall)(queueItem, authenticatedUser);
    }}
            ></jo-button>

            <jo-button
                .type=${'ICON'}
                .icon=${'edit'}
                .href=${`/company/contact?companyId=${queueItem.company?.id}&contactId=${queueItem.contact?.id}`}
            ></jo-button>
        </div>
    `;
}
exports.getActionButtonsHtml = getActionButtonsHtml;
function getCompanyName(queueItem) {
    return (0, lit_html_1.html) `
        <style>
            .outbound-script {
                cursor: pointer;
                color: var(--jo-text-default);
            }
            .outbound-script:hover {
                color: var(--jo-primary);
            }
        </style>

        <span
            class="outbound-script"
            @click=${async () => {
        (0, utilities_1.joPopUp)({
            title: 'Outbound Script',
            htmlComponents: await getHtmlOutboundScript(queueItem.company?.id),
        });
    }}
        >
            ${queueItem.company?.name}
        </span>
    `;
}
exports.getCompanyName = getCompanyName;
function getQueueItemStatus(status) {
    switch (status) {
        case 'NOT_STARTED':
        case 'IN_PROGRESS':
            return (0, lit_html_1.html) `
                <span style="color: var(--jo-primary)">In Progress</span>
            `;
        case 'COMPLETED':
        case 'MANUALLY_CLOSED':
            return (0, lit_html_1.html) `
                <span style="color: var(--jo-success)">Completed</span>
            `;
    }
}
exports.getQueueItemStatus = getQueueItemStatus;
function getHTMLForActionItemNotesPopUp(actionItemNotes, authUser) {
    return (0, lit_html_1.html) `
        <style>
            .note-sub-information {
                font-size: 0.7rem;
                display: flex;
                flex-direction: row;
                padding-bottom: 1rem;
            }

            .note-body-row {
                display: flex;
                gap: 0.5rem;
                flex-direction: column;
            }
        </style>

        <div class="note-body-row">
            ${actionItemNotes.map(note => {
        return (0, lit_html_1.html) `
                    <div>${note.body}</div>
                    <div class="note-sub-information">
                        <div>${new Date(note.created_at).toDateString()}</div>
                        <div>${note.author?.first_name}</div>
                        <div>${note.author?.last_name}</div>
                    </div>
                `;
    })}
        </div>
    `;
}
