"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../../../services/utilities");
const css_utils_1 = require("../../../../../utilities/css-utils");
const define_custom_element_1 = require("../../../../../utilities/define-custom-element");
require("../../../jo-button.element");
require("../../../jo-input");
require("../jo-google-calendars-for-company/jo-google-calendars-for-company");
const mutation_1 = require("./mutation");
const queries_1 = require("./queries");
const InitialState = {
    companyId: 'NOT_SET',
    isGoogleAuthTokenValid: false,
    authenticatedUser: 'NOT_SET',
    googleAuthURL: '',
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-google-calendar-auth');
class JOGoogleCalendarAuth extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set companyId(companyId) {
        this.store.companyId = companyId;
        if (companyId !== 'NOT_SET') {
            this.fetchAndSetIsGoogleAuthTokenValid();
        }
    }
    async fetchAndSetIsGoogleAuthTokenValid() {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'fetchAndSetIsGoogleAuthTokenValid -> this.store.companyId');
        const isGoogleTokenValidResult = await (0, queries_1.checkRefreshTokenValidity)(this.store.companyId);
        (0, utilities_1.assertSucceeded)(isGoogleTokenValidResult, utilities_1.handleError);
        this.store.isGoogleAuthTokenValid = isGoogleTokenValidResult.value;
        if (this.store.isGoogleAuthTokenValid === false) {
            await (0, mutation_1.removeGoogleCalendarAccess)(this.store.companyId);
            this.fetchAndSetGoogleAuthURL();
        }
    }
    async fetchAndSetGoogleAuthURL() {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'fetchAndSetGoogleAuthURL -> this.store.companyId');
        const authorizationUrlQueryResult = await (0, queries_1.getGoogleAuthUrl)(this.store.companyId);
        (0, utilities_1.assertSucceeded)(authorizationUrlQueryResult, utilities_1.handleError);
        this.store.googleAuthURL = authorizationUrlQueryResult.value;
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .${cssName('main-container')} {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }
                .${cssName('google-auth-button-container')} {
                    padding: 1rem;
                }
            </style>
            <div class="${cssName('main-container')}">
                <div class="${cssName('google-auth-button-container')}">
                    ${state.isGoogleAuthTokenValid === false
            ? (0, lit_html_1.html) `
                              <div class="jo-global--card">
                                  <a href="${state.googleAuthURL}">
                                      <img
                                          src="../../../../images/btn_google_signin_dark_normal_web.png"
                                      />
                                  </a>
                              </div>
                          `
            : (0, lit_html_1.html) `
                              <div class="jo-global--card">
                                  <jo-button
                                      .type=${'DELETE_BUTTON_1'}
                                      .inlineStyle=${'text-decoration: none;'}
                                      .text=${'Remove Google Account Access'}
                                      .href=${'https://myaccount.google.com/permissions'}
                                      .target=${'_blank'}
                                  ></jo-button>
                              </div>
                          `}
                </div>

                ${state.isGoogleAuthTokenValid
            ? (0, lit_html_1.html) `
                          <div>
                              <jo-google-calendars-for-company
                                  .companyId=${this.store.companyId}
                                  .authenticatedUser=${state.authenticatedUser}
                              ></jo-google-calendars-for-company>
                          </div>
                      `
            : ''}
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-google-calendar-auth', JOGoogleCalendarAuth);
