"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchActionItems = void 0;
const graphql_1 = require("../../../services/graphql");
const utilities_1 = require("../../../services/utilities");
const error_handling_1 = require("../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function fetchActionItems(state) {
    try {
        (0, utilities_1.assertIsSet)(state.companyId, utilities_1.handleError, 'state.companyId');
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query (
                    $offset: Int!
                    $limit: Int!
                    $ignoreSearch: Boolean!
                    $companyId: Int!
                    $actionItemStatusTypeNames: [ACTION_ITEM_STATUS_TYPE_NAME!]
                    $searchText: String
                ) {
                    searchActionItems(
                        page: {offset: $offset, limit: $limit}
                        ignoreSearch: $ignoreSearch
                        ignoreFollowupRequestDate: true
                        companyId: $companyId
                        actionItemStatusTypeNames: $actionItemStatusTypeNames
                        sortFieldName: UPDATED_AT
                        sortDirection: DESC
                        searchText: $searchText
                    ) {
                        action_items {
                            id
                            title
                            contact {
                                id
                                first_name
                                last_name
                            }
                            company {
                                id
                            }
                            action_item_notes {
                                id
                                author {
                                    id
                                    auth_role
                                }
                                body
                                created_at
                            }
                            action_item_status_type {
                                id
                            }
                            updated_at
                            created_at
                            user_status_events {
                                id
                                type
                                timestamp
                                user {
                                    id
                                }
                            }
                        }
                        total
                    }
                }
            `, {
            offset: state.pageOffset,
            limit: state.pageLimit,
            ignoreSearch: state.searchText === '',
            companyId: state.companyId,
            actionItemStatusTypeNames: [state.category],
            searchText: state.searchText,
        });
        const result = gqlResult.data.searchActionItems;
        return {
            succeeded: true,
            value: result,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchActionItems', error);
    }
}
exports.fetchActionItems = fetchActionItems;
