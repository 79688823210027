"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const element_vir_1 = require("element-vir");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const auth_rules_1 = require("../../../utilities/auth-rules");
const is_company_currently_using_crewCal_1 = require("../../../utilities/company-status-events/is-company-currently-using-crewCal");
const get_company_status_events_1 = require("../../../utilities/company-status-events/get-company-status-events");
const is_company_currently_using_chat_1 = require("../../../utilities/company-status-events/is-company-currently-using-chat");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const test_id_directive_1 = require("../../directives/test-id.directive");
const element_vir_2 = require("element-vir");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    companyId: 'NOT_SET',
    selectedMainOption: 'NOT_SET',
    selectedSecondaryOption: 'NOT_SET',
    isCompanyEnrolledInCrewCal: false,
    isCompanyEnrolledInChat: false,
};
function getMainOptionSelectedCSSClassIfNecessary({ optionToMarkAsSelected, actualSelectedOption, }) {
    return actualSelectedOption === optionToMarkAsSelected ? 'selected' : '';
}
// TODO this could be made more generic and declarative similar to the jo-table
// TODO we might use bars like this elsewhere, so imagine having a nav-bar component
// TODO that you simply pass an array of objects into. The object will have everything
// TODO necessary to construct the bar and any subbars
class JOCompanyNavBar extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    set companyId(companyId) {
        this.store.companyId = companyId;
        if (companyId !== 'NOT_SET') {
            this.fetchCompanyStatusEventsAndSetCrewCalVisibility();
        }
    }
    async fetchCompanyStatusEventsAndSetCrewCalVisibility() {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        const companyStatusEventResult = await (0, get_company_status_events_1.getCompanyStatusEvents)(this.store.companyId);
        (0, utilities_1.assertSucceeded)(companyStatusEventResult, utilities_1.handleError);
        this.store.isCompanyEnrolledInCrewCal = (0, is_company_currently_using_crewCal_1.isCompanyCurrentlyUsingCrewCal)(companyStatusEventResult.value);
        this.store.isCompanyEnrolledInChat = (0, is_company_currently_using_chat_1.isCompanyCurrentlyUsingChat)(companyStatusEventResult.value);
    }
    render(state) {
        return (0, element_vir_1.html) `
            <style>
                .jo-company-nav-bar--main-container {
                    box-sizing: border-box;
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: 1.25rem;
                    width: 100%;
                    box-shadow: 0 0 2px rgb(0, 0, 0, 0.25);
                    overflow-x: auto;
                }

                .jo-company-nav-bar--company-bar-container {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    align-items: center;
                    background-color: var(--jo-primary);
                }

                .jo-company-nav-bar--item {
                    box-sizing: border-box;
                    color: white;
                    padding: 0.5rem 1rem;
                    cursor: pointer;
                }

                .jo-company-nav-bar--item > span {
                    vertical-align: text-bottom;
                    font-size: 1.3rem;
                }

                .jo-company-nav-bar--item:hover {
                    background-color: var(--jo-secondary);
                }

                .selected {
                    background-color: var(--jo-secondary);
                }

                .jo-company-nav-bar--contacts-bar-container {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    background-color: var(--jo-secondary);
                    font-size: 1rem;
                    flex-wrap: wrap;
                }

                .sub-item:hover {
                    background-color: var(--jo-secondary-dark);
                }

                .sub-item-selected {
                    background-color: var(--jo-secondary-dark);
                }
            </style>

            <div class="jo-company-nav-bar--main-container">
                <div class="jo-company-nav-bar--company-bar-container">
                    <div
                        class="jo-company-nav-bar--item ${state.selectedMainOption === 'DETAILS'
            ? 'selected'
            : ''}"
                        @click=${() => {
            const navigateResult = (0, utilities_1.navigate)(`/company/company-details?companyId=${state.companyId}`);
            if (navigateResult === true) {
                this.store.selectedMainOption = 'DETAILS';
            }
        }}
                    >
                        <span ${(0, test_id_directive_1.testId)('company-navbar-details-tab')} class="material-icons">
                            search
                        </span>
                        Details
                    </div>

                    <div
                        class="jo-company-nav-bar--item ${state.selectedMainOption ===
            'ACTION_ITEMS'
            ? 'selected'
            : ''}"
                        @click=${() => {
            const navigateResult = (0, utilities_1.navigate)(`/company/action-items?companyId=${state.companyId}`);
            if (navigateResult === true) {
                this.store.selectedMainOption = 'ACTION_ITEMS';
            }
        }}
                    >
                        <span ${(0, test_id_directive_1.testId)('company-navbar-action-items-tab')} class="material-icons">
                            fact_check
                        </span>
                        Action Items
                    </div>

                    ${(0, utilities_1.authorizedRender)([
            'JILL_MANAGER',
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser, (0, element_vir_1.html) `
                            <div
                                class="jo-company-nav-bar--item ${state.selectedMainOption ===
            'CALL_INSTRUCTIONS'
            ? 'selected'
            : ''}"
                                @click=${() => {
            const navigateResult = (0, utilities_1.navigate)(`/company/call-instructions/general-setup?companyId=${state.companyId}`);
            if (navigateResult === true) {
                this.store.selectedMainOption = 'CALL_INSTRUCTIONS';
            }
        }}
                            >
                                <span
                                    ${(0, test_id_directive_1.testId)('company-navbar-call-instructions-tab')}
                                    class="material-icons"
                                >
                                    description
                                </span>
                                Call Instructions
                            </div>
                        `)}
                    ${(0, utilities_1.authorizedRender)([
            'JILL_OUTBOUND',
            'JILL_MANAGER',
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
            'COMPANY_EMPLOYEE',
            'COMPANY_OWNER',
        ], state.authenticatedUser, (0, element_vir_1.html) `
                            <div
                                class="jo-company-nav-bar--item ${state.selectedMainOption ===
            'EMPLOYEES'
            ? 'selected'
            : ''}"
                                @click=${() => {
            const navigateResult = (0, utilities_1.navigate)(`/company/employees?companyId=${state.companyId}`);
            if (navigateResult === true) {
                this.store.selectedMainOption = 'EMPLOYEES';
            }
        }}
                            >
                                <span class="material-icons">people</span>
                                Employees
                            </div>
                        `)}

                    <div
                        class="jo-company-nav-bar--item ${getSelectedStyleIfNecessary('CONTACTS', state.selectedMainOption)}"
                        ${(0, test_id_directive_1.testId)('jo-company-nav-bar-contacts-tab')}
                        @click=${() => {
            this.navigateToNewCompanyPage(`/company/contact-list?companyId=${state.companyId}`, 'CONTACTS');
        }}
                    >
                        <span class="material-icons">emoji_people</span>
                        Contacts
                    </div>

                    <div
                        class="jo-company-nav-bar--item ${getSelectedStyleIfNecessary('BILLING', state.selectedMainOption)}"
                        ?hidden=${!(0, utilities_1.isInAuthRole)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser)}
                        ${(0, test_id_directive_1.testId)('jo-company-nav-bar-billing-tab')}
                        @click=${() => {
            this.navigateToNewCompanyPage(`/company/billing?companyId=${state.companyId}`, 'BILLING');
        }}
                    >
                        <span class="material-icons">credit_card</span>
                        Billing
                    </div>

                    <div
                        class="jo-company-nav-bar--item ${getSelectedStyleIfNecessary('VOICEMAILS', state.selectedMainOption)}"
                        ?hidden=${!(0, utilities_1.isInAuthRole)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser)}
                        @click=${() => {
            this.navigateToNewCompanyPage(`/company/voicemails?companyId=${state.companyId}`, 'VOICEMAILS');
        }}
                    >
                        <span class="material-icons">voicemail</span>
                        Voicemails
                    </div>

                    <div
                        ${(0, test_id_directive_1.testId)('jo-company-navbar-user-activity')}
                        class="jo-company-nav-bar--item ${getSelectedStyleIfNecessary('USER_ACTIVITY', state.selectedMainOption)}"
                        ?hidden=${!(0, auth_rules_1.isJillAtOrAboveAuthRole)(state.authenticatedUser, 'JILL_BUSINESS_DEVELOPMENT')}
                        @click=${() => {
            this.navigateToNewCompanyPage(`/company/user-activity-report?companyId=${state.companyId}`, 'USER_ACTIVITY');
        }}
                    >
                        <span class="material-icons">recent_actors</span>
                        User Activity
                    </div>

                    <div
                        ${(0, test_id_directive_1.testId)('jo-company-navbar-flagged-actions-tab')}
                        class="jo-company-nav-bar--item ${getSelectedStyleIfNecessary('FLAGGED_ACTION_ITEMS', state.selectedMainOption)}"
                        ?hidden=${!(0, auth_rules_1.isJillAtOrAboveAuthRole)(state.authenticatedUser, 'JILL_OUTBOUND')}
                        @click=${() => {
            this.navigateToNewCompanyPage(`/company/flagged-action-items?companyId=${state.companyId}`, 'FLAGGED_ACTION_ITEMS');
        }}
                    >
                        <span class="material-icons">flag</span>
                        Flagged Action Items
                    </div>

                    ${(0, element_vir_2.renderIf)((0, auth_rules_1.isJillAtOrAboveAuthRole)(state.authenticatedUser, 'JILL_BUSINESS_DEVELOPMENT'), (0, element_vir_1.html) `
                            <div
                                class="jo-company-nav-bar--item ${getMainOptionSelectedCSSClassIfNecessary({
            optionToMarkAsSelected: 'CUSTOM_HOURS',
            actualSelectedOption: state.selectedMainOption,
        })}"
                                @click=${() => {
            const navigateResult = (0, utilities_1.navigate)(`/company/custom-hours?companyId=${state.companyId}`);
            if (navigateResult === true) {
                this.store.selectedMainOption = 'CUSTOM_HOURS';
            }
        }}
                            >
                                <span class="material-icons">schedule</span>
                                Custom Hours
                            </div>
                        `)}
                    ${(0, element_vir_2.renderIf)((0, auth_rules_1.isJillAtOrAboveAuthRole)(state.authenticatedUser, 'JILL_OUTBOUND'), (0, element_vir_1.html) `
                            <div
                                ${(0, test_id_directive_1.testId)('jo-company-navbar-flagged-actions-tab')}
                                class="jo-company-nav-bar--item ${getSelectedStyleIfNecessary('ACCOUNT_HELP_TICKETS', state.selectedMainOption)}"
                                @click=${() => {
            this.navigateToNewCompanyPage(`/company/account-help?companyId=${state.companyId}`, 'ACCOUNT_HELP_TICKETS');
        }}
                            >
                                <span class="material-icons">flag</span>
                                Account Help
                            </div>
                        `)}

                    <div
                        class="jo-company-nav-bar--item ${getSelectedStyleIfNecessary('CREW_CAL', state.selectedMainOption)}"
                        ?hidden=${!(0, auth_rules_1.isJillAtOrAboveAuthRole)(state.authenticatedUser, 'JILL_MANAGER') || state.isCompanyEnrolledInCrewCal === false}
                        @click=${() => {
            this.navigateToNewCompanyPage(`/company/crew-cal/calendars?companyId=${state.companyId}`, 'CREW_CAL');
        }}
                    >
                        <span class="material-icons">event</span>
                        CrewCal
                    </div>
                </div>

                ${state.selectedMainOption === 'CONTACTS'
            ? (0, element_vir_1.html) `
                          <div ?hidden=${true} class="jo-company-nav-bar--contacts-bar-container">
                              <div
                                  class="jo-company-nav-bar--item sub-item ${state.selectedSecondaryOption ===
                'NOT_SET' || state.selectedSecondaryOption === 'CONTACTS'
                ? 'sub-item-selected'
                : ''}"
                              >
                                  <div
                                      @click=${() => (0, utilities_1.navigate)(`/company/contact-list?companyId=${state.companyId}`)}
                                  >
                                      Contacts
                                  </div>
                              </div>

                              <div
                                  class="jo-company-nav-bar--item sub-item ${state.selectedSecondaryOption ===
                'CUSTOM_FIELDS'
                ? 'sub-item-selected'
                : ''}"
                              >
                                  <div @click=${() => { }}>Custom Fields</div>
                              </div>

                              <div
                                  class="jo-company-nav-bar--item sub-item ${state.selectedSecondaryOption ===
                'IMPORT_CONTACTS'
                ? 'sub-item-selected'
                : ''}"
                              >
                                  <div @click=${() => { }}>Import Contacts</div>
                              </div>

                              <div
                                  class="jo-company-nav-bar--item sub-item ${state.selectedSecondaryOption ===
                'EXPORT_CONTACTS'
                ? 'sub-item-selected'
                : ''}"
                              >
                                  <div @click=${() => { }}>Export Contacts</div>
                              </div>
                          </div>
                      `
            : ''}
                ${state.selectedMainOption === 'CALL_INSTRUCTIONS'
            ? (0, element_vir_1.html) `
                          <div class="jo-company-nav-bar--contacts-bar-container">
                              <div
                                  class="jo-company-nav-bar--item sub-item ${state.selectedSecondaryOption ===
                'NOT_SET' || state.selectedSecondaryOption === 'GENERAL_SETUP'
                ? 'sub-item-selected'
                : ''}"
                              >
                                  <div
                                      @click=${() => (0, utilities_1.navigate)(`/company/call-instructions/general-setup?companyId=${state.companyId}`)}
                                  >
                                      General Setup
                                  </div>
                              </div>

                              <div
                                  class="jo-company-nav-bar--item sub-item ${state.selectedSecondaryOption ===
                'CALL_INSTRUCTION_PAGES'
                ? 'sub-item-selected'
                : ''}"
                              >
                                  <div
                                      @click=${() => (0, utilities_1.navigate)(`/company/call-instructions/call-instruction-pages?companyId=${state.companyId}`)}
                                  >
                                      Call Instruction Pages
                                  </div>
                              </div>

                              <div
                                  class="jo-company-nav-bar--item sub-item ${state.selectedSecondaryOption ===
                'SERVICES_PRICING'
                ? 'sub-item-selected'
                : ''}"
                              >
                                  <div
                                      @click=${() => (0, utilities_1.navigate)(`/company/call-instructions/services-pricing?companyId=${state.companyId}`)}
                                  >
                                      Services/Pricing
                                  </div>
                              </div>

                              <div
                                  class="jo-company-nav-bar--item sub-item ${state.selectedSecondaryOption ===
                'CALL_SECTION_NOTES'
                ? 'sub-item-selected'
                : ''}"
                              >
                                  <div
                                      ${(0, test_id_directive_1.testId)('jo-company-nav-bar-call-section-notes')}
                                      @click=${() => (0, utilities_1.navigate)(`/company/call-instructions/call-section-notes?companyId=${state.companyId}`)}
                                  >
                                      Call Section Notes
                                  </div>
                              </div>
                              <div
                                  ${(0, test_id_directive_1.testId)('jo-company-nav-bar-quick-notes')}
                                  ?hidden=${!state.isCompanyEnrolledInChat}
                                  class="jo-company-nav-bar--item sub-item ${state.selectedSecondaryOption ===
                'QUICK_NOTES'
                ? 'sub-item-selected'
                : ''}"
                              >
                                  <div
                                      @click=${() => (0, utilities_1.navigate)(`/company/call-instructions/quick-notes?companyId=${state.companyId}`)}
                                  >
                                      Quick Notes
                                  </div>
                              </div>

                              <div
                                  ${(0, test_id_directive_1.testId)('company-nav-bar-chat-widget-tab')}
                                  ?hidden=${!state.isCompanyEnrolledInChat}
                                  class="jo-company-nav-bar--item sub-item ${state.selectedSecondaryOption ===
                'CHAT_WIDGET'
                ? 'sub-item-selected'
                : ''}"
                              >
                                  <div
                                      @click=${() => (0, utilities_1.navigate)(`/company/call-instructions/chat-widget-script-generator?companyId=${state.companyId}`)}
                                  >
                                      Chat Widget
                                  </div>
                              </div>
                              <!--Do Not Delete This We will need it when we make the transfers thing-->
                              <!-- <div
                                  class="jo-company-nav-bar--item sub-item ${state.selectedSecondaryOption ===
                'TRANSFERS'
                ? 'sub-item-selected'
                : ''}"
                              >
                                  <div
                                      @click=${() => (0, utilities_1.navigate)(`/company/call-instructions/transfers?companyId=${state.companyId}`)}
                                  >
                                      Transfers
                                  </div>
                              </div> -->
                          </div>
                      `
            : ''}
                ${state.selectedMainOption === 'BILLING' &&
            (0, utilities_1.isInAuthRole)([
                'JILL_BUSINESS_DEVELOPMENT',
                'JILL_EXECUTIVE',
            ], this.store.authenticatedUser)
            ? (0, element_vir_1.html) `
                          <div class="jo-company-nav-bar--contacts-bar-container">
                              <div
                                  class="jo-company-nav-bar--item sub-item ${state.selectedSecondaryOption ===
                'NOT_SET' ||
                state.selectedSecondaryOption === 'MINUTES_PACKAGE'
                ? 'sub-item-selected'
                : ''}"
                              >
                                  <div
                                      @click=${() => (0, utilities_1.navigate)(`/company/billing?companyId=${state.companyId}`)}
                                  >
                                      Overview
                                  </div>
                              </div>

                              <div
                                  class="jo-company-nav-bar--item sub-item ${state.selectedSecondaryOption ===
                'LOGS'
                ? 'sub-item-selected'
                : ''}"
                              >
                                  <div
                                      ${(0, test_id_directive_1.testId)('jo-company-nav-bar-billing-call-log-tab')}
                                      @click=${() => (0, utilities_1.navigate)(`/company/billing/logs?companyId=${state.companyId}`)}
                                  >
                                      Logs
                                  </div>
                              </div>

                              <div
                                  class="jo-company-nav-bar--item sub-item ${state.selectedSecondaryOption ===
                'INVOICE'
                ? 'sub-item-selected'
                : ''}"
                              >
                                  <div
                                      ${(0, test_id_directive_1.testId)('jo-company-nav-bar-invoice-tab')}
                                      @click=${() => (0, utilities_1.navigate)(`/company/billing/invoice?companyId=${state.companyId}`)}
                                  >
                                      Invoices
                                  </div>
                              </div>
                          </div>
                      `
            : ''}
                ${state.selectedMainOption === 'CREW_CAL' &&
            (0, utilities_1.isInAuthRole)([
                'JILL_MANAGER',
                'JILL_BUSINESS_DEVELOPMENT',
                'JILL_EXECUTIVE',
            ], this.store.authenticatedUser)
            ? (0, element_vir_1.html) `
                          <div class="jo-company-nav-bar--contacts-bar-container">
                              <div
                                  class="jo-company-nav-bar--item sub-item ${state.selectedSecondaryOption ===
                'NOT_SET' ||
                state.selectedSecondaryOption === 'COMPANY_CALENDARS'
                ? 'sub-item-selected'
                : ''}"
                              >
                                  <div
                                      @click=${() => (0, utilities_1.navigate)(`/company/crew-cal/calendars?companyId=${state.companyId}`)}
                                  >
                                      Calendars
                                  </div>
                              </div>

                              <div
                                  class="jo-company-nav-bar--item sub-item ${state.selectedSecondaryOption ===
                'CREW_CAL_MANAGEMENT'
                ? 'sub-item-selected'
                : ''}"
                              >
                                  <div
                                      @click=${() => {
                (0, utilities_1.navigate)(`/company/crew-cal/crew-cal-management?companyId=${state.companyId}`);
            }}
                                  >
                                      Crew Cal Management
                                  </div>
                              </div>
                          </div>
                      `
            : ''}
            </div>
        `;
    }
    navigateToNewCompanyPage(newPath, newSelectedMainNavOption) {
        const navigateResult = (0, utilities_1.navigate)(newPath);
        if (navigateResult === true) {
            this.store.selectedMainOption = newSelectedMainNavOption;
        }
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-nav-bar', JOCompanyNavBar);
function getSelectedStyleIfNecessary(option, actuallySelectedOption) {
    return actuallySelectedOption === option ? 'selected' : '';
}
