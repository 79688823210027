"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSlotsForCompanyAudioS3Bucket = exports.getRecordingUrl = void 0;
function getRecordingUrl(params) {
    const { type, companyId, audioFileName } = params;
    const env = getBucketEnvironment();
    const companiesFolder = env === 'production' ? 'companies' : `${env}_companies`;
    const audioFolder = type === 'VOICEMAIL' ? 'voicemail_audios' : 'queue_audios';
    const transformedCompanyId = getSlotsForCompanyAudioS3Bucket(companyId);
    const url = `https://jillsoffice.s3.us-west-2.amazonaws.com/${companiesFolder}/${audioFolder}/${transformedCompanyId.slot1}/${transformedCompanyId.slot2}/${transformedCompanyId.slot3}/original/${audioFileName}`;
    return url;
}
exports.getRecordingUrl = getRecordingUrl;
function getSlotsForCompanyAudioS3Bucket(companyId) {
    const numZerosToPad = 9 - companyId.toString().length;
    const paddedId = `${new Array(numZerosToPad).fill(0).join('')}${companyId}`;
    return {
        slot1: paddedId.slice(0, 3),
        slot2: paddedId.slice(3, 6),
        slot3: paddedId.slice(6, 9),
    };
}
exports.getSlotsForCompanyAudioS3Bucket = getSlotsForCompanyAudioS3Bucket;
function getBucketEnvironment() {
    const hostname = window.location.hostname;
    if (hostname.includes('localhost'))
        return 'development';
    if (hostname.includes('staging'))
        return 'staging';
    return 'production';
}
