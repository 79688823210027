"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchAccountManagers = exports.fetchAccountHelpTickets = void 0;
const graphql_1 = require("../../../services/graphql");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function fetchAccountHelpTickets({ accountManagerId, companyId, currentStatus, dateField, endDate, limit, offset, searchText, sortDirection, sortField, startDate, userId, }) {
    const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query (
                $accountManagerId: Int
                $companyId: Int
                $currentStatus: ACCOUNT_HELP_TICKET_STATUS
                $dateField: ACCOUNT_HELP_TICKET_DATE_FIELD
                $endDate: DateTime
                $limit: Int!
                $offset: Int!
                $searchText: String
                $sortDirection: SortDirectionEnum
                $sortField: ACCOUNT_HELP_TICKET_SORT_FIELD
                $startDate: DateTime
                $userId: Int
            ) {
                searchAccountHelpTickets(
                    accountManagerId: $accountManagerId
                    companyId: $companyId
                    currentStatus: $currentStatus
                    dateField: $dateField
                    endDate: $endDate
                    limit: $limit
                    offset: $offset
                    searchText: $searchText
                    sortDirection: $sortDirection
                    sortField: $sortField
                    startDate: $startDate
                    userId: $userId
                ) {
                    account_help_tickets {
                        id
                        action_item {
                            id
                        }
                        company {
                            id
                            name
                            account_manager {
                                id
                                first_name
                                last_name
                            }
                        }
                        current_status
                        created_at
                        description
                        title
                        updated_at
                        updates {
                            id
                            status
                            comment
                            created_at
                            user {
                                id
                                first_name
                                last_name
                            }
                        }
                        user {
                            id
                            first_name
                            last_name
                        }
                    }
                    total
                }
            }
        `, {
        accountManagerId,
        companyId,
        currentStatus,
        dateField,
        endDate,
        limit,
        offset,
        searchText,
        sortDirection,
        sortField,
        startDate,
        userId,
    });
    return gqlResult.data.searchAccountHelpTickets;
}
exports.fetchAccountHelpTickets = fetchAccountHelpTickets;
async function fetchAccountManagers() {
    const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
        query {
            findUsers(filter: {is_account_manager: {eq: true}}) {
                items {
                    id
                    first_name
                    last_name
                }
            }
        }
    `);
    return gqlResult.data.findUsers.items;
}
exports.fetchAccountManagers = fetchAccountManagers;
