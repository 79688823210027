"use strict";
/**
 * This function is duplicated in
 * jills-office-chat-widget/src/utilities/replace-name-tag-in-message.ts because we don't have
 * monorepo yet.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.replaceCustomerNameTagInMessage = void 0;
/**
 * @param message String with {customerName} tag in it
 * @param contactName The name to use in place of {customerName}
 * @returns The message with the the {customerName} tag either removed entirely or replaced with the
 *   customer name.
 */
function replaceCustomerNameTagInMessage(message, contactName) {
    if (contactName) {
        return replaceTagWithContactName(message, contactName);
    }
    else {
        return replaceTagWithEmptyStringAndRemoveLeadingSpace(message);
    }
}
exports.replaceCustomerNameTagInMessage = replaceCustomerNameTagInMessage;
function replaceTagWithContactName(message, contactName) {
    return message.replaceAll('{customerName}', contactName);
}
function replaceTagWithEmptyStringAndRemoveLeadingSpace(message) {
    const regex = / \{customerName\}|\{customerName\} /g;
    const result = message.replaceAll(regex, '');
    return result;
}
