"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stylesForJoActionItemFlagsTable = void 0;
const lit_html_1 = require("lit-html");
const css_utils_1 = require("../../../utilities/css-utils");
const cssName = (0, css_utils_1.cssPrefixer)('jo-action-item-flags-table');
exports.stylesForJoActionItemFlagsTable = (() => {
    return (0, lit_html_1.html) `
        <style>
            .${cssName('mainContainer')} {
                height: 100%;
                width: 100%;
                box-sizing: border-box;
                font-size: 0.9rem;
            }

            .${cssName('dates')} {
                display: flex;
                padding-bottom: 1rem;
                gap: 1rem;
            }

            .${cssName('playCircle')} {
                color: var(--jo-primary);
            }

            .${cssName('playCircle')}:hover {
                cursor: pointer;
            }
        </style>
    `;
})();
