"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
require("../jo-button.element");
require("../jo-input");
require("../jo-table");
const mutations_1 = require("./mutations");
const queries_1 = require("./queries");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
require("./jo-company-voicemails-mobile-template");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    companyId: 'NOT_SET',
    loading: false,
    isMobileDevice: false,
    isVoiceMailBoxEmpty: false,
};
class JOCompanyVoicemails extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
        this.triggerTableLoad = (() => {
            this.getVoicemailsForCompany = this.getVoicemailsForCompany.bind(this);
            this.store.companyId = this.store.companyId;
        }).bind(this);
        this.getVoicemailsForCompany = (async (offset, limit) => {
            (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
            const getVoicemailResult = await (0, queries_1.getVoicemails)(this.store.companyId, offset, limit);
            (0, utilities_1.assertSucceeded)(getVoicemailResult, utilities_1.handleError);
            if (getVoicemailResult.value.totalItems === 0) {
                this.store.isVoiceMailBoxEmpty = true;
            }
            return getVoicemailResult.value;
        }).bind(this);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        const prevCompanyId = this.store.companyId;
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        if (prevCompanyId === this.store.companyId) {
            return;
        }
        if (this.store.companyId !== 'NOT_SET') {
            this.triggerTableLoad();
        }
    }
    getColumns(state) {
        const columns = [
            {
                title: '',
                getCellData: (voicemail) => {
                    return voicemail.listened === false
                        ? (0, lit_html_1.html) `
                              <div>
                                  <span class="material-icons" style="color: red">info</span>
                              </div>
                          `
                        : '';
                },
                width: 0.25,
            },
            {
                title: 'Actions',
                getCellData: (voicemail) => {
                    return (0, lit_html_1.html) `
                        <div>
                            <jo-button
                                .type=${'ICON'}
                                .icon=${'delete'}
                                @click=${() => this.handleDeleteVoicemail(voicemail.id)}
                            ></jo-button>
                        </div>
                    `;
                },
                width: 0.5,
            },
            {
                title: 'Contact Name',
                getCellData: getContactName,
                width: 1,
            },
            {
                title: 'Phone Number',
                getCellData: (voicemail) => (0, utilities_1.formatE164PhoneNumberForDisplay)(voicemail.contact?.phone_number),
                width: 1,
            },
            {
                title: 'Created at',
                getCellData: (voicemail) => new Date(voicemail.created_at).toLocaleString(),
                width: 1,
            },
            {
                title: 'Recording',
                getCellData: (voicemail) => {
                    return (0, lit_html_1.html) `
                        <div>
                            <a
                                class="jo-company-voicemails--play-audio-icon"
                                @click=${() => {
                        (0, utilities_1.joAudioPopUp)(voicemail.recording_url || '');
                        this.handleMarkVoicemailListened(voicemail.id, state.companyId, state.authenticatedUser);
                    }}
                            >
                                <span class="material-icons-outlined">play_circle_outline</span>
                            </a>
                        </div>
                    `;
                },
                width: 1,
            },
        ];
        return columns;
    }
    async handleDeleteVoicemail(voicemailId) {
        (0, utilities_1.assertIsSet)(voicemailId, utilities_1.handleError, 'voicemailId');
        const shouldDelete = await (0, utilities_1.joConfirm)('Confirm', 'Are you sure you want to delete this voicemail?');
        if (shouldDelete === false) {
            return;
        }
        const deleteVoicemailResult = await (0, mutations_1.deleteVoicemail)(voicemailId);
        (0, utilities_1.assertSucceeded)(deleteVoicemailResult, utilities_1.handleError);
        (0, utilities_1.joAlert)('Success', 'Voicemail deleted successfully');
        this.triggerTableLoad();
    }
    async handleMarkVoicemailListened(voicemailId, companyId, currentUser) {
        (0, utilities_1.assertIsSet)(voicemailId, utilities_1.handleError, 'voicemailId');
        (0, utilities_1.assertIsSet)(companyId, utilities_1.handleError, 'companyId');
        (0, utilities_1.assertIsSet)(currentUser, utilities_1.handleError, 'currentUser');
        if ((0, utilities_1.getIsCompanyUser)(currentUser, companyId) === false) {
            return;
        }
        const markVoicemailListenedResult = await (0, mutations_1.markVoicemailListened)(voicemailId);
        (0, utilities_1.assertSucceeded)(markVoicemailListenedResult, utilities_1.handleError);
        this.triggerTableLoad();
    }
    getMobileTemplate(item) {
        return (0, lit_html_1.html) `
            <jo-company-voicemails-mobile-template
                .item=${item}
                @handleMarkVoicemailListened=${(e) => this.handleMarkVoicemailListened(e.detail, this.store.companyId, this.store.authenticatedUser)}
                @handleDeleteVoicemail=${(e) => this.handleDeleteVoicemail(e.detail)}
            ></jo-company-voicemails-mobile-template>
        `;
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .jo-company-voicemails--main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .jo-company-voicemails--play-audio-icon {
                    color: var(--jo-secondary);
                }

                .jo-company-voicemails--play-audio-icon:hover {
                    cursor: pointer;
                }

                .jo-company-voicemails--voicemail-icon {
                    padding-top: 2rem;
                    font-size: 5rem;
                    flex: 1;
                }

                .jo-company-voicemails--emptyVoicemail-container {
                    display: flex;
                    align-items: center;
                    flex-flow: column;
                    color: var(--jo-accent-background-dark);
                }
            </style>

            <div class="jo-company-voicemails--main-container">
                ${state.isVoiceMailBoxEmpty === true
            ? (0, lit_html_1.html) `
                          <div class="jo-company-voicemails--emptyVoicemail-container">
                              <span class="material-icons jo-company-voicemails--voicemail-icon">
                                  voicemail
                              </span>
                              <h3>Your voicemail box is empty</h3>
                          </div>
                      `
            : (0, lit_html_1.html) `
                          <div class="jo-global--card">
                              <jo-table
                                  .tableName=${'Voicemails'}
                                  .maxItemsPerPage=${15}
                                  .columns=${this.getColumns(state)}
                                  .getItems=${state.companyId === 'NOT_SET'
                ? 'NOT_SET'
                : this.getVoicemailsForCompany}
                                  .isMobileDevice=${state.isMobileDevice}
                                  .mobileTemplate=${(item) => this.getMobileTemplate(item)}
                                  .customActionTemplate=${getCustomActionTemplate}
                                  .showSearchBar=${false}
                                  @itemsfetched=${() => (this.store.loading = false)}
                              ></jo-table>
                          </div>
                      `}
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-voicemails', JOCompanyVoicemails);
function getCustomActionTemplate() {
    return (0, lit_html_1.html) `
        <div></div>
    `;
}
function getContactName(voicemail) {
    const contact = voicemail.contact;
    if (!contact)
        return '';
    return (contact.first_name ?? '') + ' ' + (contact.last_name ?? '');
}
