"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEndDateForScheduleItem = void 0;
const day_of_week_to_number_1 = require("./day-of-week-to-number");
function getEndDateForScheduleItem(date, scheduleItem) {
    const endDate = new Date(date);
    const endDay = (0, day_of_week_to_number_1.dayOfWeekToNumber)(scheduleItem.end_day_of_week);
    while (endDate.getDay() < endDay) {
        endDate.setDate(endDate.getDate() + 1);
    }
    while (endDate.getDay() > endDay) {
        endDate.setDate(endDate.getDate() - 1);
    }
    endDate.setHours(scheduleItem.end_hour);
    endDate.setMinutes(scheduleItem.end_minute);
    endDate.setSeconds(0);
    return endDate;
}
exports.getEndDateForScheduleItem = getEndDateForScheduleItem;
