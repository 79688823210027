"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchTotalNumberOfDeadIncomingQueueItems = void 0;
const helper_utils_1 = require("../helper-utils");
async function fetchTotalNumberOfDeadIncomingQueueItems({ execute, startDate, endDate, incomingCallType, podId, }) {
    try {
        const queryName = podId
            ? 'fetchIncomingQueueForIntervalSpecificPodTotal'
            : 'fetchIncomingQueueForIntervalAllPodsTotal';
        const query = makeQueryToFetchTotalIncomingQueueItems(podId, queryName);
        const gqlResult = await execute(query, {
            startDate,
            endDate,
            incomingCallTypes: (0, helper_utils_1.getIncomingCallTypesAsArray)(incomingCallType),
            podId,
        });
        const result = gqlResult.data[queryName];
        if (result == undefined) {
            throw new Error('result is undefined');
        }
        return result;
    }
    catch (error) {
        throw new Error(`fetchTotalNumberOfDeadIncomingQueueItems error: ${error}`);
    }
}
exports.fetchTotalNumberOfDeadIncomingQueueItems = fetchTotalNumberOfDeadIncomingQueueItems;
function makeQueryToFetchTotalIncomingQueueItems(podId, queryName) {
    return `
        query (
            $startDate: DateTime!
            $endDate: DateTime!
            $incomingCallTypes: [INCOMING_QUEUE_REPORT_CALL_TYPE!]!
            ${podId ? '$podId: Int!' : ''}
        ) {
            ${queryName}(
                startDate: $startDate
                endDate: $endDate
                incomingCallTypes: $incomingCallTypes
                ${podId ? 'podId: $podId' : ''}
            )
        }
    `;
}
