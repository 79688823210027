"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchJills = void 0;
const lit_html_1 = require("lit-html");
const graphql_1 = require("../../services/graphql");
const utilities_1 = require("../../services/utilities");
const reduxular_1 = require("reduxular");
const define_custom_element_1 = require("../../utilities/define-custom-element");
require("./jo-button.element");
const test_id_directive_1 = require("../directives/test-id.directive");
const mapped_env_variables_1 = require("../../utilities/environment/mapped-env-variables");
const InitialState = {
    teamId: 'NOT_SET',
    team: 'NOT_SET',
    jills: [],
    managers: [],
    authenticatedUser: 'NOT_SET',
};
class JOTeamCard extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    set teamId(teamId) {
        this.store.teamId = teamId;
        this.fetchAndSetTeam();
    }
    connectedCallback() {
        this.fetchAndSetManagers();
        this.fetchAndSetJills();
    }
    async fetchAndSetTeam() {
        const teamId = this.store.teamId;
        if (teamId === 'NOT_SET') {
            return;
        }
        const team = await fetchTeam(teamId);
        this.store.team = team;
    }
    async fetchAndSetManagers() {
        const managers = await fetchManagers();
        this.store.managers = managers;
    }
    async fetchAndSetJills() {
        const jills = await fetchJills();
        this.store.jills = jills;
    }
    async newManagerSelected(e, oldManager) {
        const teamId = this.store.teamId;
        if (teamId === 'NOT_SET') {
            return;
        }
        const newManagerId = parseInt(e.target.value);
        if (isNaN(newManagerId)) {
            return;
        }
        if (oldManager !== undefined) {
            await removeMember(teamId, oldManager.id);
        }
        await setManagerForTeam(teamId, newManagerId);
        await this.fetchAndSetTeam();
        this.dispatchEvent(new CustomEvent('teamchanged'));
    }
    async newMemberSelected(e) {
        const teamId = this.store.teamId;
        if (teamId === 'NOT_SET') {
            return;
        }
        const memberId = parseInt(e.target.value);
        if (isNaN(memberId)) {
            return;
        }
        await setMemberForTeam(teamId, memberId);
        await this.fetchAndSetTeam();
        this.dispatchEvent(new CustomEvent('teamchanged'));
    }
    async removeMemberClicked(memberId) {
        const teamId = this.store.teamId;
        if (teamId === 'NOT_SET') {
            return;
        }
        await removeMember(teamId, memberId);
        await this.fetchAndSetTeam();
        this.dispatchEvent(new CustomEvent('teamchanged'));
    }
    render(state) {
        if (state.teamId === 'NOT_SET' || state.team === 'NOT_SET') {
            return (0, lit_html_1.html) `
                <div>Loading...</div>
            `;
        }
        const manager = state.team.members?.find((member) => {
            return member.team_role === 'MANAGER';
        });
        const members = state.team.members?.filter((member) => {
            return member.team_role === 'MEMBER';
        });
        const userIsBusinessDevelopmentOrExecutive = state.authenticatedUser !== 'NOT_SET' &&
            (state.authenticatedUser.auth_role === 'JILL_BUSINESS_DEVELOPMENT' ||
                state.authenticatedUser.auth_role === 'JILL_EXECUTIVE');
        return (0, lit_html_1.html) `
            <style>
                .jo-team-card--main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    background-color: white;
                    overflow: hidden;
                }

                .jo-team-card--bold {
                    font-weight: bold;
                }

                .jo-team-name {
                    font-size: 1.5rem;
                    text-align: center;
                }

                .jo-team-name:hover {
                    color: var(--jo-primary);
                    cursor: pointer;
                }

                .jo-team-card--member-container {
                    display: flex;
                    justify-content: space-between;
                }

                .jo-team-card--member-container:hover {
                    background-color: var(--jo-accent-background-light);
                }

                .jo-team-member-name:hover {
                    cursor: pointer;
                    color: var(--jo-primary);
                }

                .jo-team-member-remove-icon {
                    color: var(--jo-secondary);
                }

                .jo-team-member-remove-icon:hover {
                    color: var(--jo-secondary-light);
                    transform: scale(1.1);
                    cursor: pointer;
                }

                .jo-team-select {
                    overflow: hidden;
                }
            </style>

            <div class="jo-team-card--main-container">
                <div
                    ${(0, test_id_directive_1.testId)('team-name')}
                    class="jo-team-card--bold jo-team-name"
                    @click=${() => (0, utilities_1.navigate)(`/team?teamId=${state.teamId}`)}
                >
                    Team ${state.teamId}
                </div>
                <br />
                <div>
                    <div class="jo-team-card--bold">Manager:</div>
                    <br />
                    ${manager === undefined
            ? ''
            : (0, lit_html_1.html) `
                              <div
                                  class="jo-team-member-name"
                                  @click=${() => (0, utilities_1.navigate)(`/jill?jillId=${manager.id}`)}
                              >
                                  ${manager.first_name} ${manager.last_name}
                              </div>
                          `}
                    <br />
                    <select
                        @change=${(e) => this.newManagerSelected(e, manager)}
                        ?hidden=${!userIsBusinessDevelopmentOrExecutive}
                    >
                        <option value="">Select a manager</option>
                        ${state.managers.map((manager) => {
            return (0, lit_html_1.html) `
                                <option value="${manager.id}">
                                    ${manager.first_name} ${manager.last_name}
                                </option>
                            `;
        })}
                    </select>
                </div>
                <br />
                <div>
                    <div class="jo-team-card--bold">Members:</div>
                    <br />
                    ${members?.map((member) => {
            return (0, lit_html_1.html) `
                            <div class="jo-team-card--member-container">
                                <a
                                    class="jo-team-member-name"
                                    @click=${() => (0, utilities_1.navigate)(`/jill?jillId=${member.id}`)}
                                >
                                    ${member.first_name} ${member.last_name}&nbsp;
                                </a>
                                <a
                                    class="jo-team-member-remove-icon"
                                    ?hidden=${!userIsBusinessDevelopmentOrExecutive}
                                    @click=${() => this.removeMemberClicked(member.id)}
                                >
                                    <span
                                        ${(0, test_id_directive_1.testId)('team-member-remove-icon')}
                                        class="material-icons-outlined"
                                    >
                                        remove_circle_outline
                                    </span>
                                </a>
                            </div>
                            <br />
                        `;
        })}
                    <br />
                    <select
                        ${(0, test_id_directive_1.testId)('add-new-team-member-input')}
                        class="jo-team-select"
                        @change=${(e) => this.newMemberSelected(e)}
                        ?hidden=${!userIsBusinessDevelopmentOrExecutive}
                    >
                        <option value="">Add a member</option>
                        ${state.jills.map((jill) => {
            return (0, lit_html_1.html) `
                                <option value="${jill.id}">
                                    ${jill.first_name} ${jill.last_name}
                                </option>
                            `;
        })}
                    </select>
                </div>
                <br />
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-team-card', JOTeamCard);
async function fetchTeam(teamId) {
    const { execute } = (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint);
    const gqlResult = await execute((0, graphql_1.gql) `
            query ($teamId: Int!) {
                getTeams(id: $teamId) {
                    id
                    members {
                        id
                        team_role
                        first_name
                        last_name
                    }
                }
            }
        `, {
        teamId,
    });
    if (gqlResult.data === null || gqlResult.data === undefined) {
        throw new Error(`There is a problem`);
    }
    return gqlResult.data.getTeams;
}
async function fetchManagers() {
    const { execute } = (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint);
    const gqlResult = await execute((0, graphql_1.gql) `
        query {
            findUsers(
                filter: {
                    auth_role: {in: ["JILL_MANAGER", "JILL_BUSINESS_DEVELOPMENT", "JILL_EXECUTIVE"]}
                }
            ) {
                items {
                    id
                    first_name
                    last_name
                }
            }
        }
    `);
    if (gqlResult.data === null || gqlResult.data === undefined) {
        throw new Error('There was a problem');
    }
    return gqlResult.data.findUsers.items;
}
async function fetchJills() {
    const { execute } = (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint);
    const gqlResult = await execute((0, graphql_1.gql) `
        query {
            findUsers(
                filter: {
                    auth_role: {
                        in: ["JILL", "JILL_OUTBOUND", "JILL_BUSINESS_DEVELOPMENT", "JILL_EXECUTIVE"]
                    }
                }
            ) {
                items {
                    id
                    first_name
                    last_name
                }
            }
        }
    `);
    if (gqlResult.data === null || gqlResult.data === undefined) {
        throw new Error('There was a problem');
    }
    return gqlResult.data.findUsers.items;
}
exports.fetchJills = fetchJills;
async function setManagerForTeam(teamId, managerId) {
    const { execute } = (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint);
    await execute((0, graphql_1.gql) `
            mutation ($teamId: Int!, $managerId: Int!) {
                updateUsers(
                    input: {id: $managerId, team_role: MANAGER, team: {connect: {id: $teamId}}}
                ) {
                    id
                }
            }
        `, {
        teamId,
        managerId,
    });
}
async function setMemberForTeam(teamId, memberId) {
    const { execute } = (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint);
    await execute((0, graphql_1.gql) `
            mutation ($teamId: Int!, $memberId: Int!) {
                updateUsers(
                    input: {id: $memberId, team_role: MEMBER, team: {connect: {id: $teamId}}}
                ) {
                    id
                }
            }
        `, {
        teamId,
        memberId,
    });
}
async function removeMember(teamId, memberId) {
    const { execute } = (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint);
    await execute((0, graphql_1.gql) `
            mutation ($memberId: Int!, $teamId: Int!) {
                updateUsers(
                    input: {id: $memberId, team_role: NO_ROLE, team: {disconnect: {id: $teamId}}}
                ) {
                    id
                }
            }
        `, {
        memberId,
        teamId,
    });
}
