"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../../services/graphql");
const utilities_1 = require("../../../services/utilities");
require("../jo-input");
require("../jo-button.element");
const error_handling_1 = require("../../../utilities/error-handling");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
const InitialState = {
    areFormInputsReady: false,
    authenticatedUser: 'NOT_SET',
    callTransferSituationInputValue: '',
    companyId: 'NOT_SET',
    createNewPassword: false,
    emailInputValue: '',
    employee: 'NOT_SET',
    employeeFullName: '',
    employeeId: 'NOT_SET',
    firstNameInputValue: '',
    formErrors: [],
    hoursToReceiveTransferCalls: '',
    isMobileDevice: false,
    lastNameInputValue: '',
    loading: true,
    passwordConfirmationErrorMessage: '',
    passwordConfirmationInputValue: '',
    passwordErrorMessage: '',
    passwordInputValue: '',
    phoneNumberInputValue: '',
    preferredActionItemEmailType: 'STANDARD',
    receiveInvoicesInputValue: false,
    receiveTransferCalls: false,
    receiveVoicemailEmails: false,
    roleSelectedValue: 'NO_ROLE',
    saving: false,
    shouldAlertOnActionItemsInputValue: false,
    showAllFormErrors: false,
    timeZoneSelectedValue: '',
    titleInputValue: '',
    visibleOnCallScreenInputValue: false,
};
function RootReducer(state = InitialState, action) {
    if (action.type === 'CLEAR_INPUT_VALUES') {
        return {
            ...state,
            areFormInputsReady: false,
            callTransferSituationInputValue: '',
            emailInputValue: '',
            employee: 'NOT_SET',
            employeeFullName: '',
            firstNameInputValue: '',
            hoursToReceiveTransferCalls: '',
            lastNameInputValue: '',
            passwordConfirmationInputValue: '',
            passwordInputValue: '',
            phoneNumberInputValue: '',
            receiveInvoicesInputValue: false,
            receiveTransferCalls: false,
            receiveVoicemailEmails: false,
            roleSelectedValue: 'NO_ROLE',
            shouldAlertOnActionItemsInputValue: false,
            timeZoneSelectedValue: '',
            titleInputValue: '',
            visibleOnCallScreenInputValue: false,
        };
    }
    if (action.type === 'SET_INPUT_VALUES') {
        const employee = action.employee;
        return {
            ...state,
            callTransferSituationInputValue: employee.call_transfer_situation ?? '',
            emailInputValue: employee.email ?? '',
            employee: employee,
            employeeFullName: employee.first_name + ' ' + employee.last_name,
            employeeId: employee.id,
            firstNameInputValue: employee.first_name ?? '',
            hoursToReceiveTransferCalls: employee.hours_to_receive_transfer_calls ?? '',
            lastNameInputValue: employee.last_name ?? '',
            phoneNumberInputValue: employee.phone_number ?? '',
            preferredActionItemEmailType: employee.preferred_action_item_email_type ?? 'STANDARD',
            receiveInvoicesInputValue: employee.should_receive_invoices,
            receiveTransferCalls: employee.can_receive_call_transfer,
            receiveVoicemailEmails: employee.should_receive_voicemail_emails,
            roleSelectedValue: employee.auth_role,
            shouldAlertOnActionItemsInputValue: employee.should_alert_action_items,
            timeZoneSelectedValue: employee.time_zone ?? '',
            titleInputValue: employee.title,
            visibleOnCallScreenInputValue: employee.visible_on_call_screen,
        };
    }
    if (action.type === 'SET_PROPS') {
        return {
            ...state,
            authenticatedUser: action.props.authenticatedUser ?? state.authenticatedUser,
            companyId: action.props.companyId ?? state.companyId,
            employeeId: action.props.employeeId ?? state.employeeId,
        };
    }
    return state;
}
class JOCompanyEmployee extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, RootReducer);
    }
    missingElementError(text) {
        (0, utilities_1.joAlert)('Error', text);
        throw new Error(text);
    }
    set props(props) {
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        this.clearInputValues();
        if (this.store.employeeId === 'NOT_SET') {
            this.store.loading = true;
            this.store.createNewPassword = true;
            this.store.loading = false;
        }
        else {
            (async () => {
                (0, utilities_1.assertIsSet)(this.store.employeeId, utilities_1.handleError, 'this.store.employeeId');
                this.store.loading = true;
                this.store.createNewPassword = false;
                await this.fetchAndSetEmployee(this.store.employeeId);
                this.store.loading = false;
            })();
        }
    }
    clearInputValues() {
        this.store.dispatch({
            type: 'CLEAR_INPUT_VALUES',
        });
    }
    async fetchAndSetEmployee(employeeId) {
        const employeeResult = await fetchEmployee(employeeId);
        if (employeeResult.succeeded === false) {
            (0, utilities_1.joAlert)('Error', employeeResult.userMessage);
            throw new Error(employeeResult.developerMessage);
        }
        const employee = employeeResult.value;
        this.store.employee = employee;
        this.setInputValues(employee);
    }
    setInputValues(employee) {
        this.store.dispatch({
            type: 'SET_INPUT_VALUES',
            employee,
        });
    }
    updatePasswordErrors() {
        if (this.store.createNewPassword === false) {
            this.clearPasswordErrorMessages();
            return;
        }
        const passwordInput = this.querySelector('#password-input');
        (0, utilities_1.assertIsDefined)(passwordInput, 'passwordInput', this.missingElementError);
        const confirmationPasswordInput = this.querySelector('#password-confirmation-input');
        (0, utilities_1.assertIsDefined)(confirmationPasswordInput, 'confirmationPasswordInput', this.missingElementError);
        this.generateAndSetPasswordErrors(passwordInput, confirmationPasswordInput);
    }
    generateAndSetPasswordErrors(passwordInput, confirmationPasswordInput) {
        const passwordErrorMessage = generatePasswordErrorMessage(passwordInput.inputValue);
        this.store.passwordErrorMessage = passwordErrorMessage;
        this.updateFormErrors('Password', passwordErrorMessage);
        const passwordConfirmationErrorMessage = generatePasswordConfirmationErrorMessage(passwordInput.inputValue, confirmationPasswordInput.inputValue);
        this.store.passwordConfirmationErrorMessage = passwordConfirmationErrorMessage;
        this.updateFormErrors('Confirm Password', passwordConfirmationErrorMessage);
    }
    clearPasswordErrorMessages() {
        this.store.passwordErrorMessage = '';
        this.store.passwordConfirmationErrorMessage = '';
        const formErrorsMinusPasswordErrors = this.store.formErrors.filter((formError) => formError.label.includes('Password') === false);
        this.store.formErrors = formErrorsMinusPasswordErrors;
    }
    async saveButtonClicked() {
        if (this.store.companyId === 'NOT_SET') {
            (0, utilities_1.joAlert)('Error', 'Something went wrong');
            throw new Error('companyId was not set upon save attempt');
        }
        if (this.areThereAnyFormErrors() === true) {
            this.displayAllFormErrorsAndAlert();
            return;
        }
        const shouldContinue = await (0, utilities_1.joConfirm)('Notice', 'Are you sure you want to update employee info?');
        if (shouldContinue === false) {
            return;
        }
        this.store.saving = true;
        await this.handleCreateOrUpdateEmployee();
        this.store.saving = false;
    }
    areThereAnyFormErrors() {
        this.updatePasswordErrors();
        return this.store.formErrors.length > 0;
    }
    displayAllFormErrorsAndAlert() {
        this.store.showAllFormErrors = true;
        (0, utilities_1.joAlert)('Error', 'Please correct form errors');
    }
    displaySuccessMessage() {
        this.store.showAllFormErrors = false;
        const successMessage = this.store.employeeId === 'NOT_SET'
            ? 'Employee was successfully created'
            : 'Employee was successfully updated';
        (0, utilities_1.joAlert)('Success!', successMessage);
    }
    async handleCreateOrUpdateEmployee() {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        const upsertEmployeeInput = {
            id: this.store.employeeId === 'NOT_SET' ? null : this.store.employeeId,
            auth_role: this.store.roleSelectedValue,
            call_transfer_situation: this.store.callTransferSituationInputValue,
            can_receive_call_transfer: this.store.receiveTransferCalls,
            company_id: this.store.companyId,
            email: this.store.emailInputValue,
            first_name: this.store.firstNameInputValue,
            hours_to_receive_transfer_calls: this.store.hoursToReceiveTransferCalls,
            last_name: this.store.lastNameInputValue,
            phone_number: this.store.phoneNumberInputValue,
            preferred_action_item_email_type: this.store.preferredActionItemEmailType,
            raw_new_password: this.store.passwordInputValue,
            should_alert_action_items: this.store.shouldAlertOnActionItemsInputValue,
            should_receive_invoices: this.store.receiveInvoicesInputValue,
            should_receive_voicemail_emails: this.store.receiveVoicemailEmails,
            time_zone: this.store.timeZoneSelectedValue,
            title: this.store.titleInputValue,
            user_token: '',
            visible_on_call_screen: this.store.visibleOnCallScreenInputValue,
        };
        await this.createOrUpdateEmployee(upsertEmployeeInput);
    }
    async createOrUpdateEmployee(upsertEmployeeInput) {
        const crudFunctionToExecute = this.store.employeeId === 'NOT_SET' ? createCompanyEmployee : updateCompanyEmployee;
        const nameOfActionTaken = this.store.employeeId === 'NOT_SET' ? 'created' : 'updated';
        const crudResult = await crudFunctionToExecute(upsertEmployeeInput, this.store.createNewPassword);
        if (crudResult.succeeded === false &&
            crudResult.developerMessage.includes('EMAIL_ALREADY_TAKEN')) {
            await (0, utilities_1.joAlert)('Email taken', 'This email address is already taken. Please use a different email address.');
            return;
        }
        (0, utilities_1.assertSucceeded)(crudResult);
        await (0, utilities_1.joAlert)('Success', `Employee successfully ${nameOfActionTaken}!`);
        (0, utilities_1.navigate)(`company/employees?companyId=${this.store.companyId}`);
    }
    backOrCancelButtonClicked() {
        (0, utilities_1.navigate)(`/company/employees?companyId=${this.store.companyId}`);
        this.store.showAllFormErrors = false;
        this.store.createNewPassword = false;
        this.store.employee = 'NOT_SET';
        this.store.employeeId = 'NOT_SET';
        this.clearInputValues();
    }
    updateFormErrors(label, errorMessage) {
        const formErrorsMinusCurrentFormError = this.store.formErrors.filter((error) => error.label !== label);
        if (errorMessage === '') {
            this.store.formErrors = formErrorsMinusCurrentFormError;
        }
        else {
            const newError = { label, errorMessage };
            const newErrors = [
                ...formErrorsMinusCurrentFormError,
                newError,
            ];
            this.store.formErrors = newErrors;
        }
    }
    render(state) {
        if (state.authenticatedUser === 'NOT_SET') {
            return (0, lit_html_1.html) ``;
        }
        const isAllowedToUpdate = (0, utilities_1.isInAuthRole)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser);
        return (0, lit_html_1.html) `
            <style>
                .jo-company-employee--main-container {
                    font-family: sans-serif;
                    color: var(--jo-text-default);
                    font-weight: 200;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    box-sizing: border-box;
                }

                .jo-company-employee--card-contact-info,
                .jo-company-employee--card-jilln-info {
                    background-color: white;
                    border-radius: 5px;
                    width: 49.25%;
                    box-shadow: 0 0 4px rgb(0, 0, 0, 0.2);
                    padding: ${state.isMobileDevice ? '0.5rem 0.2rem 0.5rem 0.5rem' : '1rem'};
                    box-sizing: border-box;
                    padding-bottom: ${state.isMobileDevice ? '0.5rem' : '2rem'};
                }

                .jo-company-employee--card-header {
                    box-sizing: border-box;
                    color: rgb(115, 115, 115);
                    margin: 0;
                    padding-bottom: 1rem;
                    font-size: ${state.isMobileDevice ? 'calc(15px + 1vmin)' : ''};
                }

                .jo-company-employee--row {
                    box-sizing: border-box;
                    padding-top: 1.5rem;
                    display: grid;
                    grid-template-columns: ${state.isMobileDevice ? `1fr` : `1fr 1fr`};
                }

                .jo-company-employee--input {
                    width: ${state.isMobileDevice ? '97%' : '90%'};
                    padding: ${state.isMobileDevice ? `0.5rem 0` : `0`};
                }

                .jo-company-employee--cancel-new-password-container {
                    box-sizing: border-box;
                    padding-top: 1.5rem;
                    display: flex;
                    justify-content: flex-end;
                    align-content: center;
                }

                .jo-company-employee--cancel-new-password-button {
                    box-sizing: border-box;
                    padding-right: 1.75rem;
                }

                .jo-company-employee--save-buttons-container {
                    box-sizing: border-box;
                    padding-top: 2rem;
                    display: flex;
                    justify-content: flex-start;
                    width: 100%;
                }

                .jo-company-employee--jilln-info-card-row {
                    box-sizing: border-box;
                    padding-top: 1.5rem;
                    display: flex;
                    align-items: center;
                }

                .jo-company-employee--select-email-type {
                    padding-left: 3rem;
                }

                .jo-company-employee--text-area {
                    box-sizing: border-box;
                    width: ${state.isMobileDevice ? 'calc(100% - 0.5rem)' : 'calc(100% - 1rem)'};
                }

                @media screen and (max-width: 1200px) {
                    .jo-company-employee--main-container {
                        flex-direction: column;
                    }

                    .jo-company-employee--card-contact-info,
                    .jo-company-employee--card-jilln-info {
                        width: 100%;
                    }

                    .jo-company-employee--card-jilln-info {
                        margin-top: 1rem;
                    }
                }
                .mobile-bottom-filler {
                    height: 5rem;
                    width: 100%;
                }
            </style>

            <jo-loading
                .props=${{
            loading: state.loading,
        }}
            ></jo-loading>

            <div class="jo-company-employee--main-container">
                <div class="jo-company-employee--card-contact-info">
                    ${state.employee === 'NOT_SET'
            ? (0, lit_html_1.html) `
                              <h2 class="jo-company-employee--card-header">NEW EMPLOYEE</h2>
                          `
            : (0, lit_html_1.html) `
                              <h2 class="jo-company-employee--card-header">
                                  CONTACT INFO: ${state.employeeFullName}
                              </h2>
                          `}

                    <div class="jo-company-employee--row">
                        <div class="jo-company-employee--input">
                            <jo-input
                                .readonly=${isAllowedToUpdate === false}
                                .label=${'First Name'}
                                .type=${'TEXT_INPUT'}
                                .required=${true}
                                .showRequiredIcon=${true}
                                .inputValue=${state.firstNameInputValue}
                                @inputchange=${(e) => {
            this.store.firstNameInputValue = e.detail.inputValue;
            this.updateFormErrors(e.detail.label, e.detail.errorMessage);
        }}
                                .showError=${state.showAllFormErrors}
                            ></jo-input>
                        </div>

                        <div class="jo-company-employee--input">
                            <jo-input
                                .readonly=${isAllowedToUpdate === false}
                                .label=${'Last Name'}
                                .type=${'TEXT_INPUT'}
                                .required=${true}
                                .showRequiredIcon=${true}
                                .inputValue=${state.lastNameInputValue}
                                @inputchange=${(e) => {
            this.store.lastNameInputValue = e.detail.inputValue;
            this.updateFormErrors(e.detail.label, e.detail.errorMessage);
        }}
                                .showError=${state.showAllFormErrors}
                            ></jo-input>
                        </div>
                    </div>

                    <div class="jo-company-employee--row">
                        <div class="jo-company-employee--input">
                            <jo-input
                                .readonly=${isAllowedToUpdate === false}
                                .label=${'Role'}
                                .type=${'SELECT'}
                                .required=${true}
                                .showRequiredIcon=${true}
                                .selectedValue=${getFriendlyRole(state.roleSelectedValue)}
                                .defaultOption=${'Please select a role...'}
                                .selectOptions=${[
            'Employee',
            'Owner',
        ]}
                                @inputchange=${(e) => {
            this.store.roleSelectedValue = getRole(e.detail.selectedValue);
            this.updateFormErrors(e.detail.label, e.detail.errorMessage);
        }}
                                .showError=${state.showAllFormErrors}
                            ></jo-input>
                        </div>

                        <div class="jo-company-employee--input">
                            <jo-input
                                .readonly=${isAllowedToUpdate === false}
                                .label=${'Title/Position'}
                                .type=${'TEXT_INPUT'}
                                .required=${true}
                                .showRequiredIcon=${true}
                                .inputValue=${state.titleInputValue}
                                @inputchange=${(e) => {
            this.store.titleInputValue = e.detail.inputValue;
            this.updateFormErrors(e.detail.label, e.detail.errorMessage);
        }}
                                .showError=${state.showAllFormErrors}
                            ></jo-input>
                        </div>
                    </div>

                    <div class="jo-company-employee--row">
                        <div class="jo-company-employee--input">
                            <jo-input
                                .readonly=${isAllowedToUpdate === false}
                                .label=${'Phone Number'}
                                .type=${'PHONE_NUMBER'}
                                .required=${false}
                                .inputValue=${state.phoneNumberInputValue}
                                @inputchange=${(e) => {
            this.store.phoneNumberInputValue = e.detail.inputValue;
            this.updateFormErrors(e.detail.label, e.detail.errorMessage);
        }}
                                .showError=${state.showAllFormErrors}
                            ></jo-input>
                        </div>

                        <div class="jo-company-employee--input">
                            <jo-input
                                .readonly=${isAllowedToUpdate === false}
                                .label=${'Email'}
                                .type=${'EMAIL'}
                                .required=${true}
                                .showRequiredIcon=${true}
                                .inputValue=${state.emailInputValue}
                                @inputchange=${(e) => {
            this.store.emailInputValue = e.detail.inputValue;
            this.updateFormErrors(e.detail.label, e.detail.errorMessage);
        }}
                                .showError=${state.showAllFormErrors}
                            ></jo-input>
                        </div>
                    </div>

                    <div class="jo-company-employee--row">
                        <div class="jo-company-employee--input">
                            <jo-input
                                .readonly=${isAllowedToUpdate === false}
                                .label=${'Timezone'}
                                .type=${'TIMEZONE_SELECT'}
                                .selectedValue=${state.timeZoneSelectedValue}
                                @inputchange=${(e) => {
            this.store.timeZoneSelectedValue = e.detail.selectedValue;
            this.updateFormErrors(e.detail.label, e.detail.errorMessage);
        }}
                                .showError=${state.showAllFormErrors}
                            ></jo-input>
                        </div>
                    </div>

                    ${(0, utilities_1.authorizedRender)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser, (0, lit_html_1.html) `
                            <div ?hidden=${state.createNewPassword === true}>
                                <div class="jo-company-employee--row">
                                    <jo-button
                                        ?hidden=${state.createNewPassword === true}
                                        .type=${'ACTION_BUTTON_2'}
                                        .text=${'New Password'}
                                        @joButtonClick=${() => (this.store.createNewPassword = true)}
                                    ></jo-button>
                                </div>
                            </div>

                            <div ?hidden=${state.createNewPassword === false}>
                                <div class="jo-company-employee--row">
                                    <div class="jo-company-employee--input">
                                        <jo-input
                                            id="password-input"
                                            .label=${state.employeeId === 'NOT_SET'
            ? 'Password (12 character min.)'
            : 'New Password (12 character min.)'}
                                            .type=${'PASSWORD'}
                                            .required=${state.createNewPassword === true}
                                            .showRequiredIcon=${state.createNewPassword === true}
                                            .inputValue=${state.passwordInputValue}
                                            @inputchange=${(e) => {
            this.store.passwordInputValue = e.detail.inputValue;
            this.updatePasswordErrors();
        }}
                                            .errorMessage=${state.passwordErrorMessage}
                                            .showError=${state.showAllFormErrors}
                                        ></jo-input>
                                    </div>

                                    <div class="jo-company-employee--input">
                                        <jo-input
                                            id="password-confirmation-input"
                                            .label=${'Confirm Password'}
                                            .type=${'PASSWORD'}
                                            .required=${state.createNewPassword === true}
                                            .showRequiredIcon=${state.createNewPassword === true}
                                            .inputValue=${state.passwordConfirmationInputValue}
                                            @inputchange=${(e) => {
            this.store.passwordConfirmationInputValue =
                e.detail.inputValue;
            this.updatePasswordErrors();
        }}
                                            .errorMessage=${state.passwordConfirmationErrorMessage}
                                            .showError=${state.showAllFormErrors}
                                        ></jo-input>
                                    </div>
                                </div>

                                <div
                                    ?hidden=${state.employeeId === 'NOT_SET'}
                                    class="jo-company-employee--cancel-new-password-container"
                                >
                                    <jo-button
                                        ?hidden=${state.employeeId === 'NOT_SET' ||
            state.createNewPassword === false}
                                        .type=${'ACTION_BUTTON_2'}
                                        .text=${'Cancel New Password'}
                                        @joButtonClick=${() => (this.store.createNewPassword = false)}
                                    ></jo-button>
                                </div>
                            </div>
                        `)}
                </div>

                <!-- Begin Checkboxes -->
                <div class="jo-company-employee--card-jilln-info">
                    <h2 class="jo-company-employee--card-header">JILL'N INFO</h2>

                    <div class="jo-company-employee--jilln-info-card-row">
                        <input
                            .disabled=${isAllowedToUpdate === false}
                            type="checkbox"
                            .checked=${state.visibleOnCallScreenInputValue}
                            @change=${(e) => (this.store.visibleOnCallScreenInputValue = e.target.checked)}
                        />
                        &nbsp;Visible on Call Screen
                    </div>

                    <div class="jo-company-employee--jilln-info-card-row">
                        <input
                            .disabled=${isAllowedToUpdate === false}
                            type="checkbox"
                            .checked=${state.shouldAlertOnActionItemsInputValue}
                            @change=${(e) => (this.store.shouldAlertOnActionItemsInputValue = e.target.checked)}
                        />
                        &nbsp;Email Action Item Notifications

                        <jo-input
                            class="jo-company-employee--select-email-type"
                            .hidden=${state.shouldAlertOnActionItemsInputValue === false}
                            .readonly=${isAllowedToUpdate === false}
                            .type=${'SELECT'}
                            .required=${true}
                            .selectedValue=${getFriendlyEmailType(state.preferredActionItemEmailType)}
                            .defaultOption=${'Please select email type...'}
                            .selectOptions=${[
            'Standard',
            'Plain',
        ]}
                            @inputchange=${(e) => {
            this.store.preferredActionItemEmailType =
                getEmailTypeFromFriendlyName(e.detail.selectedValue);
            this.updateFormErrors(e.detail.label, e.detail.errorMessage);
        }}
                            .showError=${state.showAllFormErrors}
                        ></jo-input>
                    </div>

                    <div class="jo-company-employee--jilln-info-card-row">
                        <input
                            .disabled=${isAllowedToUpdate === false}
                            type="checkbox"
                            .checked=${state.receiveInvoicesInputValue}
                            @change=${(e) => (this.store.receiveInvoicesInputValue = e.target.checked)}
                        />
                        &nbsp;Receive Invoices
                    </div>

                    <div class="jo-company-employee--jilln-info-card-row">
                        <input
                            .disabled=${isAllowedToUpdate === false}
                            type="checkbox"
                            .checked=${state.receiveTransferCalls === true}
                            @change=${(e) => (this.store.receiveTransferCalls = e.target.checked)}
                        />
                        &nbsp;Can Receive Transfer Calls
                    </div>

                    <div class="jo-company-employee--jilln-info-card-row">
                        <input
                            .disabled=${isAllowedToUpdate === false}
                            type="checkbox"
                            .checked=${state.receiveVoicemailEmails === true}
                            @change=${(e) => (this.store.receiveVoicemailEmails = e.target.checked)}
                        />
                        &nbsp;Can Receive Voicemail Emails
                    </div>

                    <div ?hidden=${state.receiveTransferCalls === false}>
                        <br />
                        Call Transfer Situation:
                        <br />
                        <textarea
                            .disabled=${isAllowedToUpdate === false}
                            class="jo-global--text-area jo-company-employee--text-area jo-global--is-disabled"
                            rows="4"
                            .value=${state.callTransferSituationInputValue}
                            @input=${(e) => (this.store.callTransferSituationInputValue = e.target.value)}
                        ></textarea>
                    </div>

                    <div ?hidden=${state.receiveTransferCalls === false}>
                        <jo-input
                            .readonly=${isAllowedToUpdate === false}
                            .label=${'Call Transfer Hours'}
                            .type=${'TEXT_INPUT'}
                            .required=${true}
                            .showRequiredIcon=${true}
                            .inputValue=${state.hoursToReceiveTransferCalls}
                            @inputchange=${(e) => {
            this.store.hoursToReceiveTransferCalls = e.detail.inputValue;
        }}
                        ></jo-input>
                    </div>
                </div>

                ${isAllowedToUpdate
            ? (0, lit_html_1.html) `
                          <div class="jo-company-employee--save-buttons-container">
                              <jo-button
                                  .type=${'ACTION_BUTTON_1'}
                                  .text=${state.saving === true ? 'Saving...' : 'Save'}
                                  .disabled=${state.saving === true}
                                  @joButtonClick=${() => this.saveButtonClicked()}
                              ></jo-button>

                              <jo-button
                                  style="padding-left: 1rem"
                                  .type=${'SECONDARY_BUTTON_1'}
                                  .text=${'Cancel'}
                                  @joButtonClick=${() => this.backOrCancelButtonClicked()}
                              ></jo-button>
                          </div>
                      `
            : ''}
                <div ?hidden=${!state.isMobileDevice} class="mobile-bottom-filler"></div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-employee', JOCompanyEmployee);
async function fetchEmployee(employeeId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($employeeId: Int!) {
                    getUsers(id: $employeeId) {
                        id
                        auth_role
                        call_transfer_situation
                        can_receive_call_transfer
                        companies {
                            id
                        }
                        email
                        first_name
                        hours_to_receive_transfer_calls
                        is_active
                        last_name
                        phone_number
                        preferred_action_item_email_type
                        should_alert_action_items
                        should_receive_invoices
                        should_receive_voicemail_emails
                        time_zone
                        title
                        visible_on_call_screen
                    }
                }
            `, {
            employeeId,
        });
        if (gqlResult.data.getUsers === null) {
            return {
                succeeded: false,
                userMessage: 'There was an error getting employee data',
                developerMessage: 'There was an error in the getUsers query',
            };
        }
        return {
            succeeded: true,
            value: gqlResult.data.getUsers,
        };
    }
    catch (error) {
        return {
            succeeded: false,
            userMessage: 'Something went wrong',
            developerMessage: `fetchEmployee error: ${error}`,
        };
    }
}
function getRole(role) {
    if (role === 'Employee')
        return 'COMPANY_EMPLOYEE';
    if (role === 'Owner')
        return 'COMPANY_OWNER';
    return 'NO_ROLE';
}
function getEmailTypeFromFriendlyName(type) {
    if (type === 'Plain')
        return 'PLAIN';
    return 'STANDARD';
}
function getFriendlyRole(role) {
    if (role === 'COMPANY_EMPLOYEE')
        return 'Employee';
    if (role === 'COMPANY_OWNER')
        return 'Owner';
    return 'NO_ROLE';
}
function getFriendlyEmailType(type) {
    if (type === 'PLAIN')
        return 'Plain';
    return 'Standard';
}
function generatePasswordErrorMessage(password) {
    if (isValidPassword(password) === false)
        return 'Password must be at least 12 characters';
    return '';
}
function generatePasswordConfirmationErrorMessage(password, confirmationPassword) {
    if (password !== confirmationPassword)
        return 'Passwords must match';
    return '';
}
function isValidPassword(password) {
    return password.length >= 12;
}
async function createCompanyEmployee(employeeInput) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation (
                    $authRole: AUTH_ROLE!
                    $callTransferSituation: String!
                    $canReceiveCallTransfer: Boolean!
                    $companyId: Int!
                    $email: String!
                    $firstName: String!
                    $hoursToReceiveTransferCalls: String!
                    $lastName: String!
                    $phoneNumber: String!
                    $preferredActionItemEmailType: ACTION_ITEM_EMAIL_TYPE!
                    $rawNewPassword: String
                    $role: Int!
                    $shouldAlertOnActionItems: Boolean!
                    $shouldReceiveInvoices: Boolean!
                    $shouldReceiveVoicemailEmails: Boolean!
                    $timezone: String!
                    $title: String!
                    $type: String!
                    $userToken: String!
                    $visibleOnCallScreen: Boolean!
                ) {
                    createUsers(
                        input: {
                            auth_role: $authRole
                            call_transfer_situation: $callTransferSituation
                            can_receive_call_transfer: $canReceiveCallTransfer
                            companies: {connect: {id: $companyId}}
                            crypted_password: $rawNewPassword
                            email: $email
                            first_name: $firstName
                            hours_to_receive_transfer_calls: $hoursToReceiveTransferCalls
                            last_name: $lastName
                            phone_number: $phoneNumber
                            preferred_action_item_email_type: $preferredActionItemEmailType
                            role: $role
                            should_alert_action_items: $shouldAlertOnActionItems
                            should_receive_invoices: $shouldReceiveInvoices
                            should_receive_voicemail_emails: $shouldReceiveVoicemailEmails
                            time_zone: $timezone
                            title: $title
                            type: $type
                            user_token: $userToken
                            visible_on_call_screen: $visibleOnCallScreen
                        }
                    ) {
                        id
                    }
                }
            `, {
            authRole: employeeInput.auth_role,
            callTransferSituation: employeeInput.call_transfer_situation,
            canReceiveCallTransfer: employeeInput.can_receive_call_transfer,
            companyId: employeeInput.company_id,
            email: employeeInput.email,
            firstName: employeeInput.first_name,
            hoursToReceiveTransferCalls: employeeInput.hours_to_receive_transfer_calls,
            lastName: employeeInput.last_name,
            phoneNumber: employeeInput.phone_number,
            preferredActionItemEmailType: employeeInput.preferred_action_item_email_type,
            rawNewPassword: employeeInput.raw_new_password,
            role: getLegacyRoleFromAuthRole(employeeInput.auth_role),
            shouldAlertOnActionItems: employeeInput.should_alert_action_items,
            shouldReceiveInvoices: employeeInput.should_receive_invoices,
            shouldReceiveVoicemailEmails: employeeInput.should_receive_voicemail_emails,
            timezone: employeeInput.time_zone,
            title: employeeInput.title,
            type: 'CompanyUser',
            userToken: '',
            visibleOnCallScreen: employeeInput.visible_on_call_screen,
        }, {
            retryOnFailedMutation: false,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('createCompanyEmployee', error);
    }
}
function getLegacyRoleFromAuthRole(authRole) {
    if (authRole === 'COMPANY_OWNER') {
        return 3;
    }
    return 1;
}
async function updateCompanyEmployee(employeeInput, shouldCreateNewPassword) {
    try {
        if (employeeInput.id === null || employeeInput.id === undefined) {
            return {
                succeeded: false,
                userMessage: 'There was an error',
                developerMessage: 'updateCompanyEmployee error: employeeId is not defined',
            };
        }
        const updateEmployeeMainInfoResult = await updateCompanyEmployeeMainInfo(employeeInput);
        if (updateEmployeeMainInfoResult.succeeded === false)
            return updateEmployeeMainInfoResult;
        if (shouldCreateNewPassword === true) {
            const updateEmployeePasswordResult = await updateCompanyEmployeePassword(employeeInput.id, employeeInput.raw_new_password);
            if (updateEmployeePasswordResult.succeeded === false)
                return updateEmployeePasswordResult;
        }
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('updateCompanyEmployee', error);
    }
}
async function updateCompanyEmployeeMainInfo(employeeInput) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation (
                    $employeeId: Int!
                    $authRole: AUTH_ROLE!
                    $callTransferSituation: String!
                    $canReceiveCallTransfer: Boolean!
                    $email: String!
                    $firstName: String!
                    $hoursToReceiveTransferCalls: String!
                    $lastName: String!
                    $phoneNumber: String!
                    $preferredActionItemEmailType: ACTION_ITEM_EMAIL_TYPE!
                    $role: Int!
                    $shouldAlertOnActionItems: Boolean!
                    $shouldReceiveInvoices: Boolean!
                    $shouldReceiveVoicemailEmails: Boolean!
                    $timezone: String!
                    $title: String!
                    $type: String!
                    $userToken: String!
                    $visibleOnCallScreen: Boolean!
                ) {
                    updateUsers(
                        input: {
                            id: $employeeId
                            auth_role: $authRole
                            call_transfer_situation: $callTransferSituation
                            can_receive_call_transfer: $canReceiveCallTransfer
                            email: $email
                            first_name: $firstName
                            hours_to_receive_transfer_calls: $hoursToReceiveTransferCalls
                            last_name: $lastName
                            phone_number: $phoneNumber
                            preferred_action_item_email_type: $preferredActionItemEmailType
                            role: $role
                            should_alert_action_items: $shouldAlertOnActionItems
                            should_receive_invoices: $shouldReceiveInvoices
                            should_receive_voicemail_emails: $shouldReceiveVoicemailEmails
                            time_zone: $timezone
                            title: $title
                            type: $type
                            user_token: $userToken
                            visible_on_call_screen: $visibleOnCallScreen
                        }
                    ) {
                        id
                    }
                }
            `, {
            employeeId: employeeInput.id,
            authRole: employeeInput.auth_role,
            callTransferSituation: employeeInput.call_transfer_situation,
            canReceiveCallTransfer: employeeInput.can_receive_call_transfer,
            companyId: employeeInput.company_id,
            email: employeeInput.email,
            firstName: employeeInput.first_name,
            hoursToReceiveTransferCalls: employeeInput.hours_to_receive_transfer_calls,
            lastName: employeeInput.last_name,
            phoneNumber: employeeInput.phone_number,
            preferredActionItemEmailType: employeeInput.preferred_action_item_email_type,
            role: getLegacyRoleFromAuthRole(employeeInput.auth_role),
            shouldAlertOnActionItems: employeeInput.should_alert_action_items,
            shouldReceiveInvoices: employeeInput.should_receive_invoices,
            shouldReceiveVoicemailEmails: employeeInput.should_receive_voicemail_emails,
            timezone: employeeInput.time_zone,
            title: employeeInput.title,
            type: 'CompanyUser',
            userToken: '',
            visibleOnCallScreen: employeeInput.visible_on_call_screen,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('updateCompanyEmployeeMainInfo', error);
    }
}
async function updateCompanyEmployeePassword(employeeId, rawNewPassword) {
    try {
        if (rawNewPassword === null || rawNewPassword === undefined) {
            return {
                succeeded: false,
                userMessage: 'There was an error',
                developerMessage: `updateCompanyEmployeePassword error: rawNewPassword is not defined`,
            };
        }
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($employeeId: Int!, $rawNewPassword: String!) {
                    updateUsers(
                        input: {
                            id: $employeeId
                            crypted_password: $rawNewPassword #Note, this encrypts in in the updateUsers resolver. Probably a bad design choice. Would be better to make a specific resolver to update passwords.
                        }
                    ) {
                        id
                    }
                }
            `, {
            employeeId,
            rawNewPassword,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('updateCompanyEmployeePassword', error);
    }
}
