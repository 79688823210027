"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../services/graphql");
const utilities_1 = require("../../services/utilities");
require("./jo-button.element");
require("./jo-input");
const error_handling_1 = require("../../utilities/error-handling");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../utilities/environment/mapped-env-variables");
const InitialState = {
    year: getCurrentYear(),
    loading: false,
    limit: 1000,
    offset: 0,
    csv: 'month,name,canceled,pod,industry,niche,duration,billable duration,package name,invoice usage,invoice monthly fee,invoice total,stripe invoice total charged,stripe invoice total paid,has invoice mismatch\n',
};
class JOYearlyReport extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, (state, action) => {
            if (action.type === 'RESET_STATE') {
                return InitialState;
            }
            return state;
        });
    }
    resetLimitAndOffset() {
        this.store.limit = 1000;
        this.store.offset = 0;
    }
    async twoYearReportClicked() {
        this.resetLimitAndOffset();
        this.store.loading = true;
        await this.generateTwoYearReport();
        this.store.loading = false;
    }
    async generateTwoYearReport() {
        const csvResult = await fetchTwoYearReportCSV(this.store.year, this.store.offset, this.store.limit);
        (0, utilities_1.assertSucceeded)(csvResult, utilities_1.handleError);
        const csv = csvResult.value;
        if (csv === '') {
            (0, utilities_1.downloadCSV)(this.store.csv, `yearly-report-${this.store.year}.csv`);
            return;
        }
        this.store.csv += csv;
        this.store.offset += this.store.limit;
        await this.generateTwoYearReport();
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .main-container {
                    box-sizing: border-box;
                }
            </style>

            <div class="main-container">
                <jo-input
                    .inlineStyle=${'display: block'}
                    .type=${'SELECT'}
                    .label=${'Year'}
                    .selectOptions=${getYears()}
                    .selectedValue=${this.store.year.toString()}
                    @valueChanged=${(e) => {
            this.store.year = Number(e.detail.selectedValue);
        }}
                ></jo-input>

                <br />

                <button @click=${() => this.twoYearReportClicked()}>Generate yearly report</button>

                <br />

                <div ?hidden=${state.loading === false}>
                    <div>
                        Loading report rows ${state.offset} to ${state.offset + state.limit}...
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-yearly-report', JOYearlyReport);
async function fetchTwoYearReportCSV(year, offset, limit) {
    try {
        const forMonths = getForMonthsForYear(year);
        const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                query ($offset: Int!, $limit: Int!, $forMonths: [String!]!) {
                    findMonthly_company_reports(
                        filter: {for_month: {in: $forMonths}}
                        orderBy: {field: "id", order: ASC}
                        page: {offset: $offset, limit: $limit}
                    ) {
                        items {
                            id
                            billable_duration
                            company {
                                id
                                canceled
                                industry
                                name
                                niche
                                pod {
                                    id
                                    name
                                }
                            }
                            invoice {
                                id
                                minutes_package {
                                    name
                                }
                            }
                            duration
                            for_month
                            has_dirty_calls
                            has_invoice_mismatch
                            invoice_monthly_fee
                            invoice_total
                            invoice_usage
                            stripe_invoice_charged
                            stripe_invoice_paid
                        }
                    }
                }
            `, {
            offset,
            limit,
            forMonths: getForMonthsForYear(year),
        });
        if (result.succeeded === false) {
            return result;
        }
        const monthlyCompanyReports = result.value.data.findMonthly_company_reports.items;
        const csv = monthlyCompanyReports.reduce((result, monthlyCompanyReport) => {
            const month = monthlyCompanyReport.for_month;
            const name = monthlyCompanyReport.company.name;
            const canceled = monthlyCompanyReport.company.canceled;
            const pod = monthlyCompanyReport.company.pod?.name || 'No pod';
            const industry = monthlyCompanyReport.company.industry || 'No industry';
            const niche = monthlyCompanyReport.company.niche || 'No niche';
            const duration = monthlyCompanyReport.duration;
            const billableDuration = monthlyCompanyReport.billable_duration;
            const packageName = monthlyCompanyReport.invoice?.minutes_package?.name ?? 'N/A';
            const invoiceUsage = monthlyCompanyReport.invoice_usage ?? 'No invoice usage';
            const invoiceMonthlyFee = monthlyCompanyReport.invoice_monthly_fee ?? 'No invoice monthly fee';
            const invoiceTotal = monthlyCompanyReport.invoice_total ?? 'No invoice total';
            const stripeInvoiceTotalCharged = monthlyCompanyReport.stripe_invoice_charged ?? 'No stripe invoice';
            const stripeInvoiceTotalPaid = monthlyCompanyReport.stripe_invoice_paid ?? 'No stripe invoice';
            const hasInvoiceMismatch = monthlyCompanyReport.has_invoice_mismatch;
            return `${result}${month},${name},${canceled},${pod},${industry},${niche},${duration},${billableDuration},${packageName},${invoiceUsage},${invoiceMonthlyFee},${invoiceTotal},${stripeInvoiceTotalCharged},${stripeInvoiceTotalPaid},${hasInvoiceMismatch}\n`;
        }, '');
        return {
            succeeded: true,
            value: csv,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchTwoYearReportCSV', error);
    }
}
function getCurrentYear() {
    return new Date().getFullYear();
}
function getYears() {
    return [
        2019,
        2020,
        2021,
    ];
}
function getForMonthsForYear(year) {
    return [
        `january-${year}`,
        `february-${year}`,
        `march-${year}`,
        `april-${year}`,
        `may-${year}`,
        `june-${year}`,
        `july-${year}`,
        `august-${year}`,
        `september-${year}`,
        `october-${year}`,
        `november-${year}`,
        `december-${year}`,
    ];
}
