"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.oneHundredCharPreview = void 0;
function oneHundredCharPreview(str) {
    if (str.length <= 97)
        return str;
    const first97Chars = str.slice(0, 97).trim();
    return first97Chars + '...';
}
exports.oneHundredCharPreview = oneHundredCharPreview;
