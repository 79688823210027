"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMinutesUsedForCallType = exports.formatDateForDateInput = exports.getMobileTemplate = exports.getPhoneNumberForLink = exports.getPhoneNumberForCallLogTable = exports.getMaximumBillableMilliseconds = exports.getMiniumBillableMilliseconds = exports.getMinimumMillisecondsCallNeedsToBeBillable = exports.getMinimumSecondsCallNeedsToBeBillable = exports.getAdditionalMinutesUsedText = exports.getNumMinutesRemainingText = exports.getNumMinutesUsedText = exports.getNumMinutesInPackageText = exports.millisecondsToMinutes = exports.getForMonth = exports.calculateCallActionItemDurationFromDurationEvents = void 0;
const lit_html_1 = require("lit-html");
const constants_1 = require("../../../services/constants");
const utilities_1 = require("../../../services/utilities");
const dates_and_times_1 = require("../../../utilities/dates-and-times/dates-and-times");
const round_to_x_decimal_places_1 = require("../../../utilities/math/round-to-x-decimal-places");
function calculateCallActionItemDurationFromDurationEvents(durationEvents) {
    const callEndEvent = durationEvents.find(durationEvent => durationEvent.description === 'CALL_END');
    if (callEndEvent === undefined)
        return 0;
    const actionItemSavedEvent = durationEvents.find(durationEvent => durationEvent.description === 'ACTION_ITEM_SAVED');
    if (actionItemSavedEvent === undefined)
        return 0;
    const diff = getMillisecondsDifferenceBetweenTwoTimes({
        startTime: callEndEvent.timestamp,
        endTime: actionItemSavedEvent.timestamp,
    });
    return diff > 0 ? diff : 0;
}
exports.calculateCallActionItemDurationFromDurationEvents = calculateCallActionItemDurationFromDurationEvents;
function getMillisecondsDifferenceBetweenTwoTimes(params) {
    const startMilliseconds = new Date(params.startTime).getTime();
    const endMilliseconds = new Date(params.endTime).getTime();
    return endMilliseconds - startMilliseconds;
}
function getForMonth(selectedMonth, selectedYear) {
    return `${selectedMonth.toLowerCase()}-${selectedYear}`;
}
exports.getForMonth = getForMonth;
function millisecondsToMinutes(milliseconds) {
    return (0, round_to_x_decimal_places_1.roundToXDecimal)(milliseconds / dates_and_times_1.oneMinuteInMilliseconds, 3);
}
exports.millisecondsToMinutes = millisecondsToMinutes;
function getNumMinutesInPackageText(minutesPackage, receptionistService) {
    if (minutesPackage) {
        return minutesPackage.num_minutes_included;
    }
    if (receptionistService) {
        if (receptionistService.minutes_per_month === null ||
            receptionistService.minutes_per_month === undefined) {
            return 'NOT FOUND';
        }
        else
            return receptionistService.minutes_per_month;
    }
    return '';
}
exports.getNumMinutesInPackageText = getNumMinutesInPackageText;
function getNumMinutesUsedText(companyLog) {
    if (!companyLog) {
        return 'NOT FOUND';
    }
    return millisecondsToMinutes(companyLog.billable_duration);
}
exports.getNumMinutesUsedText = getNumMinutesUsedText;
function getNumMinutesRemainingText(minutesPackage, receptionistService, companyLog) {
    if (!companyLog) {
        return 'NOT FOUND';
    }
    if (minutesPackage) {
        return getNumMinutesRemaining(minutesPackage.num_minutes_included, companyLog);
    }
    if (receptionistService) {
        if (receptionistService.minutes_per_month !== null &&
            receptionistService.minutes_per_month !== undefined) {
            return getNumMinutesRemaining(receptionistService.minutes_per_month, companyLog);
        }
        else
            return 'NOT FOUND';
    }
    return '';
}
exports.getNumMinutesRemainingText = getNumMinutesRemainingText;
function getNumMinutesRemaining(numMinutesIncluded, companyLog) {
    const minutesUsed = millisecondsToMinutes(companyLog.billable_duration);
    const difference = numMinutesIncluded - minutesUsed;
    return difference > 0 ? difference : 0;
}
function getAdditionalMinutesUsedText(minutesPackage, receptionistService, companyLog) {
    if (!companyLog) {
        return 'NOT FOUND';
    }
    if (minutesPackage) {
        return getAdditionalMinutesUSed(minutesPackage.num_minutes_included, companyLog);
    }
    if (receptionistService) {
        if (receptionistService.minutes_per_month === null ||
            receptionistService.minutes_per_month === undefined) {
            return 'NOT FOUND';
        }
        return getAdditionalMinutesUSed(receptionistService.minutes_per_month, companyLog);
    }
    return 'NOT FOUND';
}
exports.getAdditionalMinutesUsedText = getAdditionalMinutesUsedText;
function getAdditionalMinutesUSed(numMinutesIncluded, companyLog) {
    const minutesUsed = millisecondsToMinutes(companyLog.billable_duration);
    const difference = minutesUsed - numMinutesIncluded;
    return difference > 0 ? difference : 0;
}
function getMinimumSecondsCallNeedsToBeBillable(minutesPackage, selectedDate) {
    return getMinimumMillisecondsCallNeedsToBeBillable(minutesPackage, selectedDate) / 60;
}
exports.getMinimumSecondsCallNeedsToBeBillable = getMinimumSecondsCallNeedsToBeBillable;
function getMinimumMillisecondsCallNeedsToBeBillable(minutesPackage, selectedDate) {
    if (minutesPackage !== 'NOT_FOUND' &&
        selectedDate >= new Date(constants_1.newBillingSystemEffectiveMonth)) {
        return minutesPackage.minutes_calculation_option.min_milliseconds_call_needs_to_be_billable;
    }
    return 25000;
}
exports.getMinimumMillisecondsCallNeedsToBeBillable = getMinimumMillisecondsCallNeedsToBeBillable;
function getMiniumBillableMilliseconds(minutesPackage, selectedDate) {
    if (minutesPackage !== 'NOT_FOUND' &&
        selectedDate >= new Date(constants_1.newBillingSystemEffectiveMonth)) {
        return minutesPackage.minutes_calculation_option.min_billable_milliseconds;
    }
    return 60000;
}
exports.getMiniumBillableMilliseconds = getMiniumBillableMilliseconds;
function getMaximumBillableMilliseconds(minutesPackage, selectedDate) {
    if (selectedDate >= new Date(constants_1.newBillingSystemEffectiveMonth)) {
        if (minutesPackage !== 'NOT_FOUND') {
            return minutesPackage.minutes_calculation_option.max_billable_milliseconds;
        }
        return 600000;
    }
    return 420000;
}
exports.getMaximumBillableMilliseconds = getMaximumBillableMilliseconds;
function getPhoneNumberForCallLogTable(call) {
    if (call.new_direction === 'INCOMING') {
        return (0, utilities_1.formatE164PhoneNumberForDisplay)(call.origin_phone);
    }
    return (0, utilities_1.formatE164PhoneNumberForDisplay)(call.destination_phone);
}
exports.getPhoneNumberForCallLogTable = getPhoneNumberForCallLogTable;
function getPhoneNumberForLink(call) {
    let number = getPhoneNumberForCallLogTable(call).toString();
    return '+1' + number.replace(/-/g, '');
}
exports.getPhoneNumberForLink = getPhoneNumberForLink;
function getMobileTemplate(item) {
    return (0, lit_html_1.html) `
        <jo-company-call-log-mobile-template .item=${item}></jo-company-call-log-mobile-template>
    `;
}
exports.getMobileTemplate = getMobileTemplate;
function formatDateForDateInput(date) {
    const localeDateString = date.toLocaleDateString();
    const [month, day, year,] = localeDateString.split('/');
    if (month == null)
        throw new Error('month is null');
    if (day == null)
        throw new Error('day is null');
    if (year == null)
        throw new Error('year is null');
    return year + '-' + formatMonthOrDayNumber(month) + '-' + formatMonthOrDayNumber(day);
}
exports.formatDateForDateInput = formatDateForDateInput;
function formatMonthOrDayNumber(monthOrDayNumber) {
    return String(monthOrDayNumber).length === 1
        ? `0${monthOrDayNumber}`
        : String(monthOrDayNumber);
}
function getMinutesUsedForCallType(calls) {
    return calls.reduce((totalBillableDuration, call) => totalBillableDuration + call.billable_duration, 0);
}
exports.getMinutesUsedForCallType = getMinutesUsedForCallType;
