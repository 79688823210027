"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setNewMinutesPackageOnInvoice = exports.updateLineItemAmount = exports.calculateAndApplyUsageForInvoice = exports.createInvoiceForCompany = exports.setCurrentMinutesPackageForCompany = exports.setNewEndDateForCompanyLog = exports.setNewEndDateForInvoice = void 0;
const graphql_1 = require("../../../../services/graphql");
const get_start_of_billing_cycle_1 = require("../../../../utilities/dates-and-times/get-start-of-billing-cycle");
const get_start_of_next_billing_cycle_1 = require("../../../../utilities/dates-and-times/get-start-of-next-billing-cycle");
const error_handling_1 = require("../../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function setNewEndDateForInvoice(invoiceId, newEndDate) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($invoiceId: Int!, $newEndDate: DateTime) {
                    updateInvoices(input: {id: $invoiceId, end_date: $newEndDate}) {
                        id
                    }
                }
            `, {
            invoiceId,
            newEndDate,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('setNewEndDateForInvoice', error);
    }
}
exports.setNewEndDateForInvoice = setNewEndDateForInvoice;
async function setNewEndDateForCompanyLog(companyLogId, endDate) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($companyLogId: Int!, $newEndDate: DateTime) {
                    updateCompany_logs(input: {id: $companyLogId, end_date: $newEndDate}) {
                        id
                    }
                }
            `, {
            companyLogId,
            endDate,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('setNewEndDateForInvoice', error);
    }
}
exports.setNewEndDateForCompanyLog = setNewEndDateForCompanyLog;
async function setCurrentMinutesPackageForCompany(companyId, minutesPackageId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($companyId: Int!, $minutesPackageId: Int!) {
                    updateCompanies(
                        input: {
                            id: $companyId
                            current_minutes_package: {connect: {id: $minutesPackageId}}
                        }
                    ) {
                        id
                    }
                }
            `, {
            companyId,
            minutesPackageId,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('setCurrentMinutesPackageForCompany', error);
    }
}
exports.setCurrentMinutesPackageForCompany = setCurrentMinutesPackageForCompany;
async function createInvoiceForCompany(params) {
    try {
        const input = buildCreateInvoiceInput(params);
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($input: CreateInvoicesInput!) {
                    createInvoices(input: $input) {
                        id
                    }
                }
            `, {
            input,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const newInvoice = gqlResult.value.data.createInvoices;
        return { succeeded: true, value: newInvoice };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('createInvoiceForCompany', error);
    }
}
exports.createInvoiceForCompany = createInvoiceForCompany;
function buildCreateInvoiceInput(params) {
    try {
        const { companyId, minutesPackage, customStartDate } = params;
        const now = new Date();
        const billingCycleType = minutesPackage.billing_cycle_type;
        const input = {
            company: {
                connect: {
                    id: companyId,
                },
            },
            minutes_package: {
                connect: {
                    id: minutesPackage.id,
                },
            },
            billing_cycle_type: minutesPackage.billing_cycle_type,
            start_date: customStartDate?.toISOString() ??
                (0, get_start_of_billing_cycle_1.getStartOfBillingCycleInMountainTime)(now, billingCycleType).toISOString(),
            end_date: (0, get_start_of_next_billing_cycle_1.getStartOfNextBillingCycleInMountainTime)(now, billingCycleType),
            invoice_line_items: {
                createMany: buildCreateManyInvoiceLineItemsInput(billingCycleType, minutesPackage),
            },
        };
        return input;
    }
    catch (error) {
        throw new Error(`buildCreateInvoiceInput error: ${error}`);
    }
}
function buildCreateManyInvoiceLineItemsInput(billingCycleType, minutesPackage) {
    try {
        if (billingCycleType === 'WEEKLY') {
            return [
                {
                    description: 'Base Plan',
                    amount: minutesPackage.base_fee,
                },
                {
                    description: 'Bank of Minutes',
                    amount: minutesPackage.num_minutes_included * minutesPackage.price_per_minute,
                },
                {
                    description: 'Additional Usage',
                    amount: 0,
                },
            ];
        }
        else {
            return [
                {
                    description: 'Monthly Fee',
                    amount: minutesPackage.recurring_fee,
                },
                {
                    description: 'Usage',
                    amount: 0,
                },
            ];
        }
    }
    catch (error) {
        throw new Error(`buildCreateManyInvoiceLineItemsInput error: ${error}`);
    }
}
async function calculateAndApplyUsageForInvoice(invoiceId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($invoiceId: Int!) {
                    calculateAndApplyUsageForInvoice(invoiceId: $invoiceId)
                }
            `, {
            invoiceId,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('calculateAndApplyUsageForInvoice', error);
    }
}
exports.calculateAndApplyUsageForInvoice = calculateAndApplyUsageForInvoice;
async function updateLineItemAmount(lineItemId, amount) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($lineItemId: Int!, $amount: Int!) {
                    updateInvoice_line_items(input: {id: $lineItemId, amount: $amount}) {
                        id
                    }
                }
            `, {
            lineItemId,
            amount,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('updateLineItemAmount', error);
    }
}
exports.updateLineItemAmount = updateLineItemAmount;
async function setNewMinutesPackageOnInvoice(invoiceId, minutesPackageId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($invoiceId: Int!, $minutesPackageId: Int!) {
                    updateInvoices(
                        input: {id: $invoiceId, minutes_package: {connect: {id: $minutesPackageId}}}
                    ) {
                        id
                    }
                }
            `, {
            invoiceId,
            minutesPackageId,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('setNewMinutesPackageOnInvoice', error);
    }
}
exports.setNewMinutesPackageOnInvoice = setNewMinutesPackageOnInvoice;
