"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasValueAtKey = void 0;
const is_defined_1 = require("../../is-defined");
const is_valid_object_1 = require("./is-valid-object");
function hasValueAtKey(obj, key) {
    if (!(0, is_valid_object_1.isValidObject)(obj)) {
        return false;
    }
    /* @ts-ignore */
    return (0, is_defined_1.isDefined)(obj[key]) && obj[key] !== '';
}
exports.hasValueAtKey = hasValueAtKey;
