"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
require("../jo-button.element");
require("../jo-input");
const helper_utils_1 = require("./helper-utils");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const time_format_1 = require("../../../utilities/date/time-format");
const InitialState = {
    item: {
        billable_duration: 0,
        created_at: '',
        duration: 0,
        origin_phone: '',
        destination_phone: '',
        new_direction: 'INCOMING',
    },
};
class JOCompanyCompanyCallLogMobileTemplate extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }
                .jo-mobile--table-container {
                    box-sizing: border-box;
                    background-color: white;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                }

                .row-data {
                    padding: 0.5rem;
                    font-size: 0.8rem;
                    display: flex;
                    flex-grow: 1;
                    flex-basis: 3rem;
                    justify-content: center;
                    text-align: center;
                }
                .row-data-small {
                    padding: 0.5rem;
                    font-size: 0.8rem;
                    display: flex;
                    flex-grow: 1;
                    flex-basis: 2rem;
                    justify-content: center;
                    text-align: center;
                }
                .table-link {
                    text-decoration: none;
                    color: var(--jo-primary);
                }
                .date-container {
                    display: flex;
                    flex-direction: column;
                }
            </style>

            <div class="main-container">
                <div class="jo-mobile--table-container">
                    <div class="row-data-small">
                        <div class="date-container">
                            <div>${(0, utilities_1.makeDateTwoChar)(state.item.created_at)}</div>
                            <div>${(0, time_format_1.formatAs12HourTimeString)(new Date(state.item.created_at))}</div>
                        </div>
                    </div>
                    <a href="tel:${(0, helper_utils_1.getPhoneNumberForLink)(state.item)}" class="row-data table-link">
                        ${(0, helper_utils_1.getPhoneNumberForCallLogTable)(state.item)}
                    </a>
                    <div class="row-data-small">
                        ${(0, utilities_1.millisecondsToHoursMinutesSeconds)(state.item.duration || 0)}
                    </div>
                    <div class="row-data-small">
                        ${(0, utilities_1.millisecondsToHoursMinutesSeconds)(state.item.billable_duration)}
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-call-log-mobile-template', JOCompanyCompanyCallLogMobileTemplate);
