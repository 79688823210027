"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractEventTarget = void 0;
function extractEventTarget(event, expectedConstructor) {
    const target = event.currentTarget;
    if (!(target instanceof expectedConstructor)) {
        throw new Error(`Failed to find '${expectedConstructor.name}' event target in '${event.type}' event.`);
    }
    return target;
}
exports.extractEventTarget = extractEventTarget;
