"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchIncomingQueueForInterval = void 0;
const fetch_dead_incoming_queue_items_1 = require("./fetch-dead-incoming-queue-items");
const fetch_total_number_of_dead_incoming_queue_items_1 = require("./fetch-total-number-of-dead-incoming-queue-items");
async function fetchIncomingQueueForInterval({ execute, startDate, endDate, incomingCallType, podId, }) {
    try {
        const totalNumberOfIncomingQueueItemsForInterval = await (0, fetch_total_number_of_dead_incoming_queue_items_1.fetchTotalNumberOfDeadIncomingQueueItems)({
            execute,
            startDate,
            endDate,
            incomingCallType,
            podId,
        });
        return await (0, fetch_dead_incoming_queue_items_1.fetchDeadIncomingQueueItems)({
            incomingCallType,
            totalNumberOfIncomingQueueItemsForInterval,
            execute,
            startDate,
            endDate,
            podId,
        });
    }
    catch (error) {
        throw new Error(`fetchIncomingQueueForInterval error: ${error}`);
    }
}
exports.fetchIncomingQueueForInterval = fetchIncomingQueueForInterval;
