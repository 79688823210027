"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getChatsForChatLogTable = void 0;
const graphql_1 = require("../../../../services/graphql");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
const handle_gql_result_failure_1 = require("../../../../utilities/error-handling/handle-gql-result-failure");
const chat_table_filters_1 = require("./chat-table-filters");
async function getChatsForChatLogTable(filters) {
    if (filters.companyId == undefined) {
        return [];
    }
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
            query ($companyId: Int!, $chatSources: [String!]) {
                findChats(
                    filter: {company_id: {eq: $companyId}, source: {in: $chatSources}}
                    orderBy: {field: "created_at", order: ASC}
                ) {
                    items {
                        id
                        created_at
                        status
                        source
                        messages {
                            id
                            source
                            body
                            created_at
                        }
                        duration_events(orderBy: {field: "timestamp", order: ASC}) {
                            id
                            timestamp
                            type
                        }
                        company {
                            id
                        }
                        contact {
                            id
                            first_name
                            last_name
                            phone_numbers {
                                id
                                number
                            }
                        }
                        action_items {
                            id
                        }
                        currently_chatting_user {
                            first_name
                        }
                    }
                }
            }
        `, {
        companyId: filters.companyId,
        chatSources: getChatSourcesForFilter(filters.selectedChatType),
    });
    if (gqlResult.succeeded === false) {
        (0, handle_gql_result_failure_1.handleGqlResultFailure)(gqlResult);
        return [];
    }
    const chats = gqlResult.value.data.findChats.items;
    return chats;
}
exports.getChatsForChatLogTable = getChatsForChatLogTable;
function getChatSourcesForFilter(selectedChatType) {
    if (selectedChatType === chat_table_filters_1.ChatTypeEnum.SMS)
        return [
            'CUSTOMER_SMS',
        ];
    if (selectedChatType === chat_table_filters_1.ChatTypeEnum.WebsiteWidget)
        return [
            'CUSTOMER_WIDGET',
        ];
    return [
        'CUSTOMER_SMS',
        'CUSTOMER_WIDGET',
        'SYSTEM',
    ];
}
