"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAndClickHiddenLinkToDownloadCSVBlobUrl = exports.createCSVBlobUrl = exports.generateAndDownloadCSV = void 0;
const common_1 = require("@augment-vir/common");
const build_csv_1 = require("../../../../utilities/csv/build-csv");
const create_csv_columns_1 = require("./create-csv-columns");
function generateAndDownloadCSV(outboundQueueItems) {
    const blobURL = createCSVBlobUrl(outboundQueueItems);
    createAndClickHiddenLinkToDownloadCSVBlobUrl(blobURL);
}
exports.generateAndDownloadCSV = generateAndDownloadCSV;
function createCSVBlobUrl(outboundQueueItems) {
    const csv = createCSV(outboundQueueItems);
    const blob = new Blob([csv]);
    const blobURL = window.URL.createObjectURL(blob);
    return blobURL;
}
exports.createCSVBlobUrl = createCSVBlobUrl;
function createCSV(outboundQueueItems) {
    (0, common_1.assertLengthAtLeast)(outboundQueueItems, 1);
    const csv = (0, build_csv_1.buildCSV)(outboundQueueItems, (0, create_csv_columns_1.createColumnsForCSV)(outboundQueueItems[0].num_attempts_to_make));
    return csv;
}
function createAndClickHiddenLinkToDownloadCSVBlobUrl(blobURL) {
    const fileName = `outbound_queue_${getCurrentDateString()}.csv`;
    const hiddenLink = document.createElement('a');
    setHiddenLinkAttributes(hiddenLink, blobURL, fileName);
    addLinkToPageBody(hiddenLink);
    clickLink(hiddenLink);
    removeLinkFromPageBody(hiddenLink);
}
exports.createAndClickHiddenLinkToDownloadCSVBlobUrl = createAndClickHiddenLinkToDownloadCSVBlobUrl;
function setHiddenLinkAttributes(hiddenLink, blobURL, fileName) {
    hiddenLink.setAttribute('href', blobURL);
    hiddenLink.setAttribute('download', fileName);
}
function removeLinkFromPageBody(hiddenLink) {
    document.body.removeChild(hiddenLink);
}
function clickLink(hiddenLink) {
    hiddenLink.click();
}
function addLinkToPageBody(hiddenLink) {
    document.body.appendChild(hiddenLink);
}
function getCurrentDateString() {
    return new Date().toLocaleDateString();
}
