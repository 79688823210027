"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const css_utils_1 = require("../../../utilities/css-utils");
const update_item_in_list_of_items_1 = require("../../../utilities/data-structures/update-item-in-list-of-items");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const format_schedule_item_for_display_1 = require("../../../utilities/schedule-items/format-schedule-item-for-display");
require("../jo-button.element");
require("../jo-input");
require("../jo-schedule-selector");
const helper_utils_1 = require("./helper-utils");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    numScheduledBreaksToAdd: 0,
    shift: null,
    editableShift: null,
    mode: 'EDIT',
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-user-shift');
class JOUserShift extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set shift(shift) {
        if (this.store.shift === shift) {
            return;
        }
        this.store.shift = shift;
        this.store.editableShift = { ...shift };
        this.store.mode = shift.mode;
    }
    addLunchBreak() {
        if (this.store.editableShift === null) {
            return;
        }
        this.store.editableShift = {
            ...this.store.editableShift,
            lunch_breaks: [
                ...this.store.editableShift.lunch_breaks,
                (0, helper_utils_1.makeDefaultLunchBreak)(this.store.editableShift),
            ],
        };
    }
    addScheduledBreak() {
        if (this.store.editableShift === null) {
            return;
        }
        this.store.editableShift = {
            ...this.store.editableShift,
            scheduled_breaks: [
                ...this.store.editableShift.scheduled_breaks,
                (0, helper_utils_1.makeDefaultScheduledBreak)(this.store.editableShift),
            ],
        };
    }
    updateShift(updatedShift) {
        if (this.store.editableShift === null) {
            return;
        }
        this.store.editableShift = {
            ...this.store.editableShift,
            ...updatedShift,
        };
    }
    updateScheduledBreaks(updatedScheduledBreak) {
        if (this.store.editableShift === null) {
            return;
        }
        this.store.editableShift = {
            ...this.store.editableShift,
            scheduled_breaks: (0, update_item_in_list_of_items_1.updateItemInListOfItems)(this.store.editableShift.scheduled_breaks, updatedScheduledBreak),
        };
    }
    updateLunchBreaks(updatedLunchBreak) {
        if (this.store.editableShift === null) {
            return;
        }
        this.store.editableShift = {
            ...this.store.editableShift,
            lunch_breaks: (0, update_item_in_list_of_items_1.updateItemInListOfItems)(this.store.editableShift.lunch_breaks, updatedLunchBreak),
        };
    }
    removeLunchBreak(index) {
        if (this.store.editableShift === null) {
            return;
        }
        this.store.editableShift = {
            ...this.store.editableShift,
            lunch_breaks: this.store.editableShift.lunch_breaks.filter((_, i) => i !== index),
        };
    }
    removeScheduledBreak(index) {
        if (this.store.editableShift === null) {
            return;
        }
        this.store.editableShift = {
            ...this.store.editableShift,
            scheduled_breaks: this.store.editableShift.scheduled_breaks.filter((_, i) => i !== index),
        };
    }
    render(state) {
        const shift = state.shift;
        if (shift === null) {
            return (0, lit_html_1.html) ``;
        }
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .${cssName('main-container')} {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .${cssName('row')} {
                    padding-top: 1rem;
                }

                .${cssName('readonly-shift')} {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .${cssName('readonly-shift')}:hover {
                    cursor: pointer;
                    background-color: var(--jo-primary-light);
                }

                .${cssName('shift-confirm-container')} {
                    padding-top: 4rem;
                    display: flex;
                    justify-content: flex-end;
                    gap: 1rem;
                }

                .${cssName('editable-section')} {
                    border-bottom: 1px solid lightgray;
                    padding-bottom: 1rem;
                }

                .${cssName('break-scheduler-container')} {
                    padding-bottom: 2rem;
                    padding-top: 1rem;
                }
            </style>

            <div class="${cssName('main-container')}">
                <section ?hidden=${state.mode !== 'READ'} class="${cssName('readonly-shift')}">
                    <div>
                        <strong>${(0, format_schedule_item_for_display_1.formatScheduleItemForDisplay)(shift)}</strong>
                        <ul>
                            ${shift.scheduled_breaks?.map(scheduledBreak => (0, lit_html_1.html) `
                                    <li>
                                        Scheduled Break:
                                        ${(0, format_schedule_item_for_display_1.formatScheduleItemForDisplay)(scheduledBreak, shift)}
                                    </li>
                                `)}
                            ${shift.lunch_breaks?.map(lunchBreak => (0, lit_html_1.html) `
                                    <li>
                                        Lunch Break:
                                        ${(0, format_schedule_item_for_display_1.formatScheduleItemForDisplay)(lunchBreak, shift)}
                                    </li>
                                `)}
                        </ul>
                    </div>

                    <div style="display: flex; gap: 1rem;">
                        <jo-button
                            .type=${'ICON'}
                            .icon=${'edit'}
                            @joButtonClick=${() => {
            this.store.mode = 'EDIT';
        }}
                        ></jo-button>

                        <jo-button
                            .type=${'ICON'}
                            .icon=${'delete'}
                            @joButtonClick=${() => {
            (0, utilities_1.raiseCustomEvent)(this, 'deleteShiftClicked', shift.id);
        }}
                        ></jo-button>
                    </div>
                </section>

                ${state.mode !== 'READ'
            ? (0, lit_html_1.html) `
                          <hr />
                          <section class="${cssName('editable-section')}">
                              <h3>${state.mode === 'CREATE' ? 'New Shift' : 'Edit Shift'}</h3>
                              <jo-schedule-selector
                                  .mode=${state.mode}
                                  .inputValues=${{
                ...(state.editableShift ?? (0, helper_utils_1.makeNewDefaultShift)()),
            }}
                                  @inputChanged=${(e) => {
                this.updateShift(e.detail);
            }}
                              ></jo-schedule-selector>
                          </section>

                          <section class="${cssName('editable-section')}">
                              <h3>Scheduled Breaks</h3>

                              ${this.store.editableShift?.scheduled_breaks?.map((scheduledBreak, index) => (0, lit_html_1.html) `
                                      <div class="${cssName('break-scheduler-container')}">
                                          <div>
                                              <jo-button
                                                  .type=${'ACTION_BUTTON_2'}
                                                  .text=${'Remove'}
                                                  @joButtonClick=${() => {
                this.removeScheduledBreak(index);
            }}
                                              ></jo-button>
                                          </div>

                                          <jo-schedule-selector
                                              .mode=${state.mode}
                                              .inputValues=${{ ...scheduledBreak }}
                                              @inputChanged=${(e) => {
                this.updateScheduledBreaks(e.detail);
            }}
                                          ></jo-schedule-selector>
                                      </div>
                                  `)}

                              <div style="padding-top: 2rem;">
                                  <jo-button
                                      .text=${'Add Scheduled Break'}
                                      @joButtonClick=${() => {
                this.addScheduledBreak();
            }}
                                  ></jo-button>
                              </div>
                          </section>

                          <section class="${cssName('editable-section')}">
                              <h3>Lunch Breaks</h3>

                              ${this.store.editableShift?.lunch_breaks?.map((lunchBreak, index) => (0, lit_html_1.html) `
                                      <div class="${cssName('break-scheduler-container')}">
                                          <div>
                                              <jo-button
                                                  .type=${'ACTION_BUTTON_2'}
                                                  .text=${'Remove'}
                                                  @joButtonClick=${() => {
                this.removeLunchBreak(index);
            }}
                                              ></jo-button>
                                          </div>

                                          <jo-schedule-selector
                                              .mode=${state.mode}
                                              .inputValues=${{ ...lunchBreak }}
                                              @inputChanged=${(e) => {
                this.updateLunchBreaks(e.detail);
            }}
                                          ></jo-schedule-selector>
                                      </div>
                                  `)}

                              <div style="padding-top: 2rem;">
                                  <jo-button
                                      .text=${'Add Lunch Break'}
                                      @joButtonClick=${() => {
                this.addLunchBreak();
            }}
                                  ></jo-button>
                              </div>
                          </section>

                          <section class="${cssName('shift-confirm-container')}">
                              <jo-button
                                  .text=${'CONFIRM SHIFT'}
                                  @joButtonClick=${() => {
                if (this.store.editableShift === null) {
                    return;
                }
                this.store.editableShift = {
                    ...this.store.editableShift,
                    mode: 'READ',
                };
                (0, utilities_1.raiseCustomEvent)(this, 'confirmClicked', this.store.editableShift);
            }}
                              ></jo-button>

                              <jo-button
                                  .type=${'SECONDARY_BUTTON_1'}
                                  .text=${'Cancel'}
                                  @joButtonClick=${() => {
                if (shift !== null) {
                    this.store.editableShift = {
                        ...shift,
                    };
                }
                this.store.mode = 'READ';
                (0, utilities_1.raiseCustomEvent)(this, 'cancelClicked', shift.id);
            }}
                              ></jo-button>
                          </section>
                          <hr />
                      `
            : ''}
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-user-shift', JOUserShift);
