"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const calculate_total_purchased_minutes_for_company_1 = require("../../../utilities/billing-calculations/calculate-total-purchased-minutes-for-company");
const aggregate_total_billable_duration_for_companies_in_milliseconds_1 = require("../../../utilities/billing-calculations/usage/aggregate-total-billable-duration-for-companies-in-milliseconds");
const calculate_total_overage_milliseconds_for_company_1 = require("../../../utilities/billing-calculations/usage/calculate-total-overage-milliseconds-for-company");
const calculate_total_unused_milliseconds_for_company_1 = require("../../../utilities/billing-calculations/usage/calculate-total-unused-milliseconds-for-company");
const calculate_total_usage_diff_for_companies_1 = require("../../../utilities/billing-calculations/usage/calculate-total-usage-diff-for-companies");
const calculate_sum_of_object_at_property_1 = require("../../../utilities/data-structures/lists/calculate-sum-of-object-at-property");
const calculate_months_between_dates_1 = require("../../../utilities/dates-and-times/calculate-months-between-dates");
const format_date_for_date_input_1 = require("../../../utilities/dates-and-times/format-date-for-date-input");
const get_previous_date_1 = require("../../../utilities/dates-and-times/get-previous-date");
const get_start_of_billing_cycle_1 = require("../../../utilities/dates-and-times/get-start-of-billing-cycle");
const get_start_of_next_billing_cycle_1 = require("../../../utilities/dates-and-times/get-start-of-next-billing-cycle");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const build_try_execute_1 = require("../../../utilities/error-handling/build-try-execute");
const build_try_execute_async_1 = require("../../../utilities/error-handling/build-try-execute-async");
const fetch_global_config_1 = require("../../../utilities/global-configs/fetch-global-config");
const get_global_config_array_values_1 = require("../../../utilities/global-configs/get-global-config-array-values");
const is_defined_1 = require("../../../utilities/is-defined");
require("../jo-input");
const constants_1 = require("./constants");
const calculations_1 = require("./utils/calculations");
const csv_1 = require("./utils/csv");
const filters_1 = require("./utils/filters");
const make_minutes_packages_summary_for_company_1 = require("./utils/make-minutes-packages-summary-for-company");
const queries_1 = require("./utils/queries");
const InitialState = {
    billingCycleType: 'MONTHLY',
    dateInStartBillingCycle: new Date(),
    dateInEndBillingCycle: new Date(),
    companies: [],
    filteredCompanies: [],
    filters: {
        industry: 'all',
        niche: 'all',
        podName: 'all',
        referralSource: 'all',
        referralTag: 'all',
        paymentStatus: constants_1.BillingReportStatus.ALL,
        companyStatus: 'All',
        companyType: 'Real',
        vipStatus: 'All',
    },
    globalConfig: null,
    loading: false,
    offset: 0,
    limit: 100,
};
const executeSync = (0, build_try_execute_1.buildtryExecute)(utilities_1.handleError);
const executeAsync = (0, build_try_execute_async_1.buildtryExecuteAsync)(utilities_1.handleError);
class JOBillingReport extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    async connectedCallback() {
        await this.fetchAndSetGLobalConfig();
    }
    async fetchAndSetGLobalConfig() {
        this.store.globalConfig = await executeAsync(fetch_global_config_1.fetchGlobalConfig, [
            'id',
            'niches',
            'referral_sources',
            'referral_tags',
        ]);
    }
    async getAndSetCompanyInfo() {
        this.store.loading = true;
        await this.loadAndfilterCompanies();
        this.store.loading = false;
    }
    async loadAndfilterCompanies() {
        this.store.companies = [];
        this.store.offset = InitialState.offset;
        await this.loadCompanies();
        this.filterCompanies();
    }
    async loadButtonClicked() {
        await this.getAndSetCompanyInfo();
    }
    filterCompanies(filterName, value) {
        if (filterName && value) {
            this.updatefilter(filterName, value);
        }
        this.getAndSetFilteredCompanies();
    }
    updatefilter(filterName, value) {
        this.store.filters[filterName] = value;
    }
    getAndSetFilteredCompanies() {
        this.store.loading = true;
        this.store.filteredCompanies = this.getFilteredCompanies();
        this.store.loading = false;
    }
    getFilteredCompanies() {
        return executeSync(filters_1.filterCompanies, {
            companies: this.store.companies,
            ...this.store.filters,
        });
    }
    csv() {
        try {
            (0, csv_1.generateAndDownloadCSV)(this.store.filteredCompanies, this.getStartDate(), this.getEndDate());
        }
        catch (error) {
            (0, utilities_1.handleError)(error);
            alert(error);
        }
    }
    getStartDate() {
        return (0, get_start_of_billing_cycle_1.getStartOfBillingCycleInMountainTime)(this.store.dateInStartBillingCycle, this.store.billingCycleType === 'ALL' ? 'MONTHLY' : this.store.billingCycleType);
    }
    getEndDate() {
        return (0, get_start_of_next_billing_cycle_1.getStartOfNextBillingCycleInMountainTime)(this.store.dateInEndBillingCycle, this.store.billingCycleType === 'ALL' ? 'MONTHLY' : this.store.billingCycleType);
    }
    async loadCompanies() {
        const companies = await executeAsync(queries_1.fetchCompanies, {
            billingCycleType: this.store.billingCycleType,
            dateInStartBillingCycle: this.store.dateInStartBillingCycle,
            dateInEndBillingCycle: this.store.dateInEndBillingCycle,
            offset: this.store.offset,
            limit: this.store.limit,
        });
        if (companies.length === 0) {
            return;
        }
        this.store.companies = [
            ...this.store.companies,
            ...companies,
        ];
        this.store.offset += this.store.limit;
        await this.loadCompanies();
    }
    render(state) {
        const podNames = (0, filters_1.getPodNames)(state.companies);
        const industries = (0, filters_1.getIndustries)(state.companies);
        const niches = (0, get_global_config_array_values_1.getGlobalConfigArrayValues)(state.globalConfig, 'niches');
        const referralSources = (0, get_global_config_array_values_1.getGlobalConfigArrayValues)(state.globalConfig, 'referral_sources');
        const referralTags = (0, get_global_config_array_values_1.getGlobalConfigArrayValues)(state.globalConfig, 'referral_tags');
        return (0, lit_html_1.html) `
            <style>
                .jo-billing-report--container {
                    padding: calc(25px + 1vmin);
                }

                .jo-billing-report--grid {
                    display: grid;
                    row-gap: calc(5px + 1vmin);
                    margin: calc(10px + 1vmin);
                    grid-template-columns: repeat(24, 1fr);
                }

                .jo-billing-report--grid-item {
                    padding: 0.25rem;
                }

                .jo-billing-report--row-title {
                    font-weight: bold;
                    padding: 0.25rem;
                }

                .jo-billing-report-filters-container {
                    display: flex;
                    flex-wrap: wrap;
                }

                .jo-billing-report-filter-container {
                    display: flex;
                    flex-direction: column;
                    padding: 10px;
                }

                .jo-billing-report-filter-item {
                    padding: 5px;
                }
            </style>

            <div class="jo-billing-report--container">
                <div class="jo-billing-report-filters-container">
                    <div class="jo-billing-report-filter-container">
                        <div class="jo-billing-report-filter-item">Billing Cycle Type</div>

                        <div class="jo-billing-report-filter-item">
                            <select
                                class="jo-global--input"
                                @change=${(e) => {
            this.store.billingCycleType = e.target.value;
        }}
                            >
                                <option value="MONTHLY">Monthly</option>
                                <option value="WEEKLY">Weekly</option>
                                <option value="ALL">All</option>
                            </select>
                        </div>
                    </div>

                    <div class="jo-billing-report-filter-container">
                        <div class="jo-billing-report-filter-item">
                            Start Billing Cycle:
                            <strong>
                                ${makeDateRangeText(state.dateInStartBillingCycle, state.billingCycleType)}
                            </strong>
                        </div>
                        <div class="jo-billing-report-filter-item">
                            <input
                                class="jo-global--input"
                                type="date"
                                .value=${(0, format_date_for_date_input_1.formatDateForDateInput)(state.dateInStartBillingCycle)}
                                @change=${(e) => {
            this.store.dateInStartBillingCycle = new Date(e.target.value.replace(/-/g, '/'));
        }}
                            />
                        </div>
                    </div>

                    <div class="jo-billing-report-filter-container">
                        <div class="jo-billing-report-filter-item">
                            End Billing Cycle:
                            <strong>
                                ${makeDateRangeText(state.dateInEndBillingCycle, state.billingCycleType)}
                            </strong>
                        </div>
                        <div class="jo-billing-report-filter-item">
                            <input
                                class="jo-global--input"
                                type="date"
                                .value=${(0, format_date_for_date_input_1.formatDateForDateInput)(state.dateInEndBillingCycle)}
                                @change=${(e) => {
            this.store.dateInEndBillingCycle = new Date(e.target.value.replace(/-/g, '/'));
        }}
                            />
                        </div>
                    </div>

                    <div class="jo-billing-report-filter-container">
                        <div class="jo-billing-report-filter-item">Payment Status</div>
                        <div class="jo-billing-report-filter-item">
                            <select
                                class="jo-global--input"
                                id="status-type-filter-select"
                                @change=${(e) => {
            this.store.filters.paymentStatus =
                constants_1.BillingReportStatus[e.target.value];
            this.getAndSetFilteredCompanies();
        }}
                                ?disabled=${state.loading === true}
                            >
                                ${Object.entries(constants_1.BillingReportStatus).map(pair => {
            return (0, lit_html_1.html) `
                                        <option value=${pair[0]}>${pair[1]}</option>
                                    `;
        })}                           
                            </select>
                        </div>
                    </div>

                    <div class="jo-billing-report-filter-container">
                        <div class="jo-billing-report-filter-item">Pod</div>
                        <div class="jo-billing-report-filter-item">
                            <select
                                class="jo-global--input"
                                id="pod-filter-select"
                                @change=${(e) => {
            this.filterCompanies('podName', e.target.value);
        }}
                                ?disabled=${state.loading === true}
                            >
                                <option value="all">All</option>
                                ${podNames.map((podName) => {
            return (0, lit_html_1.html) `
                                        <option value=${podName}>${podName}</option>
                                    `;
        })}
                            </select>
                        </div>
                    </div>

                    <div class="jo-billing-report-filter-container">
                        <div class="jo-billing-report-filter-item">Industry</div>
                        <div class="jo-billing-report-filter-item">
                            <select
                                class="jo-global--input"
                                id="industry-filter-select"
                                @change=${(e) => {
            this.filterCompanies('industry', e.target.value);
        }}
                                ?disabled=${state.loading === true}
                            >
                                <option value="all">All</option>
                                ${industries.map((industry) => {
            return (0, lit_html_1.html) `
                                        <option value=${industry}>${industry}</option>
                                    `;
        })}
                            </select>
                        </div>
                    </div>

                    <div class="jo-billing-report-filter-container">
                        <div class="jo-billing-report-filter-item">Niche</div>
                        <div class="jo-billing-report-filter-item">
                            <select
                                class="jo-global--input"
                                id="industry-filter-select"
                                @change=${(e) => {
            this.filterCompanies('niche', e.target.value);
        }}
                                ?disabled=${state.loading === true}
                            >
                                <option value="all">All</option>
                                ${niches.map(niche => (0, lit_html_1.html) `
                                            <option value=${niche}>${niche}</option>
                                        `)}
                            </select>
                        </div>
                    </div>

                    <div class="jo-billing-report-filter-container">
                        <div class="jo-billing-report-filter-item">Referral Source</div>
                        <div class="jo-billing-report-filter-item">
                            <select
                                class="jo-global--input"
                                id="industry-filter-select"
                                @change=${(e) => {
            this.filterCompanies('referralSource', e.target.value);
        }}
                                ?disabled=${state.loading === true}
                            >
                                <option value="all">All</option>
                                ${referralSources.map(referralSource => (0, lit_html_1.html) `
                                        <option value=${referralSource}>${referralSource}</option>
                                    `)}
                            </select>
                        </div>
                    </div>

                    <div class="jo-billing-report-filter-container">
                        <div class="jo-billing-report-filter-item">Referral Tag</div>
                        <div class="jo-billing-report-filter-item">
                            <select
                                class="jo-global--input"
                                id="industry-filter-select"
                                @change=${(e) => this.filterCompanies('referralTag', e.target.value)}
                                ?disabled=${state.loading === true}
                            >
                                <option value="all">All</option>
                                ${referralTags.map(referralTag => (0, lit_html_1.html) `
                                        <option value=${referralTag}>${referralTag}</option>
                                    `)}
                            </select>
                        </div>
                    </div>

                    <div class="jo-billing-report-filter-container">
                        <div class="jo-billing-report-filter-item">Company Status</div>
                        <div class="jo-billing-report-filter-item">
                            <jo-input
                                .type=${'SELECT'}
                                .selectOptions=${[
            'All',
            'Active',
            'Canceled',
        ]}
                                @valueChanged=${(e) => {
            this.filterCompanies('companyStatus', e.detail.selectedValue);
        }}
                            ></jo-input>
                        </div>
                    </div>

                    <div class="jo-billing-report-filter-container">
                        <div class="jo-billing-report-filter-item">Company Type</div>
                        <div class="jo-billing-report-filter-item">
                            <jo-input
                                .type=${'SELECT'}
                                .selectOptions=${[
            'Real',
            'Test',
            'All',
        ]}
                                @valueChanged=${(e) => {
            this.filterCompanies('companyType', e.detail.selectedValue);
        }}
                            ></jo-input>
                        </div>
                    </div>

                    <div class="jo-billing-report-filter-container">
                        <div class="jo-billing-report-filter-item">VIP Status</div>
                        <div class="jo-billing-report-filter-item">
                            <jo-input
                                .type=${'SELECT'}
                                .selectOptions=${[
            'All',
            'VIP',
            'Not VIP',
        ]}
                                @valueChanged=${(e) => {
            this.filterCompanies('vipStatus', e.detail.selectedValue);
        }}
                            ></jo-input>
                        </div>
                    </div>
                </div>

                <hr />

                <button
                    @click=${() => this.loadButtonClicked()}
                    ?disabled=${state.loading === true}
                >
                    Load
                </button>
                <button @click=${() => this.csv()} ?disabled=${state.loading === true}>CSV</button>

                <div ?hidden=${!state.loading}>
                    Loading companies ${this.store.offset} - ${this.store.offset + this.store.limit}
                </div>

                <div ?hidden=${state.loading}>
                    <br />

                    <div>
                        Total Amount Due: $${(0, calculations_1.getTotalAmountDueInDollars)(state.filteredCompanies)}
                    </div>
                    <div>
                        Total Amount Charged:
                        $${(0, calculations_1.getTotalAmountChargedForCompaniesInDollars)(state.filteredCompanies)}
                    </div>
                    <div>
                        Total Amount Paid:
                        $${(0, calculations_1.getTotalAmountPaidForCompaniesInDollars)(state.filteredCompanies)}
                    </div>
                    <div>
                        Total to Collect:
                        $${(0, calculations_1.getTotalToCollectForCompaniesInDollsars)(state.filteredCompanies)}
                    </div>
                    <div>
                        Total Billable Minutes:
                        ${(0, utilities_1.millisecondsToMinutes)((0, aggregate_total_billable_duration_for_companies_in_milliseconds_1.aggregateTotalBillableDurationInMillisecondsForCompanies)(state.filteredCompanies))}
                    </div>
                        Total Unused Minutes:
                        ${(0, utilities_1.millisecondsToMinutes)((0, calculate_total_usage_diff_for_companies_1.calculateTotalUsageDiffForCompaniesInMilliseconds)(state.filteredCompanies, 'UNUSED'))}
                    </div>
                    <div>
                        Total Overage Minutes:
                        ${(0, utilities_1.millisecondsToMinutes)((0, calculate_total_usage_diff_for_companies_1.calculateTotalUsageDiffForCompaniesInMilliseconds)(state.filteredCompanies, 'OVERAGE'))}
                    </div>
                    <div>
                        Total Purchased Minutes:
                        ${(0, calculations_1.calculateTotalPurchasedMinutesForAllCompanies)(state.filteredCompanies)}
                    </div>

                    <div class="jo-billing-report--grid">
                        <div class="jo-billing-report--row-title">Name</div>
                        <div class="jo-billing-report--row-title">VIP</div>
                        <div class="jo-billing-report--row-title">Pod</div>
                        <div class="jo-billing-report--row-title">Date Created</div>
                        <div class="jo-billing-report--row-title">Date Canceled</div>
                        <div title="Number of months the customer has been with Jill's Office" class="jo-billing-report--row-title">Customer Duration</div>
                        <div class="jo-billing-report--row-title">Calendar</div>
                        <div class="jo-billing-report--row-title">Industry</div>
                        <div class="jo-billing-report--row-title">Niche</div>
                        <div class="jo-billing-report--row-title">Account Manager</div>
                        <div class="jo-billing-report--row-title">Owner Phone number</div>
                        <div class="jo-billing-report--row-title">Owner Email</div>
                        <div class="jo-billing-report--row-title">Preferred Communication</div>
                        <div class="jo-billing-report--row-title">Amount due</div>
                        <div class="jo-billing-report--row-title">Amount charged</div>
                        <div class="jo-billing-report--row-title">Amount paid</div>
                        <div class="jo-billing-report--row-title">Billable Minutes</div>
                        <div class="jo-billing-report--row-title">Purchased Minutes</div>
                        <div class="jo-billing-report--row-title">Unused Minutes</div>
                        <div class="jo-billing-report--row-title">Overage Minutes</div>
                        <div class="jo-billing-report--row-title">Status</div>
                        <div class="jo-billing-report--row-title">Has Stripe Id?</div>
                        <div class="jo-billing-report--row-title">Minutes Package(s)</div>
                        <div class="jo-billing-report--row-title">Current Price Per Minute</div>

                        ${state.filteredCompanies.map((company) => {
            if (company.invoices === null || company.invoices === undefined) {
                throw new Error('jo-billing-report/jo-billing-report.ts filteredCompanies: invoices was not defined');
            }
            if (company.organization === null ||
                company.organization === undefined) {
                return (0, lit_html_1.html) `
                                    organization was not defined
                                `;
            }
            if (company.organization.owner === null ||
                company.organization.owner === undefined) {
                return (0, lit_html_1.html) `
                                    owner was not defined
                                `;
            }
            const amountDue = (0, calculations_1.getTotalInvoiceAmountForCompany)(company);
            return (0, lit_html_1.html) `
                                <div class="jo-billing-report--grid-item">${company.name}</div>
                                <div class="jo-billing-report--grid-item">
                                    ${company.is_vip ? 'Yes' : 'No'}
                                </div>
                                <div class="jo-billing-report--grid-item">
                                    ${company.pod?.name !== undefined && company.pod?.name !== null
                ? company.pod.name
                : 'No Pod'}
                                </div>
                                <div class="jo-billing-report--grid-item">
                                    ${(0, is_defined_1.isDefined)(company.created_at)
                ? new Date(company.created_at).toLocaleDateString()
                : 'No created at timestamp'}
                                </div>
                                <div class="jo-billing-report--grid-item">
                                    ${(0, is_defined_1.isDefined)(company.canceled_at)
                ? new Date(company.canceled_at).toLocaleDateString()
                : 'Active Company'}
                                </div>
                                <div class="jo-billing-report--grid-item">
                                    ${(0, calculate_months_between_dates_1.calculateMonthsBetweenDates)(company.created_at, company.canceled_at)}
                                </div>
                                <div class="jo-billing-report--grid-item">
                                    ${company.calendar?.name !== undefined
                ? company.calendar.name
                : 'No Calendar'}
                                </div>
                                <div class="jo-billing-report--grid-item">
                                    ${company.industry !== undefined && company.industry !== null
                ? company.industry
                : 'No Industry'}
                                </div>
                                <div class="jo-billing-report--grid-item">
                                    ${company.niche !== null && company.niche !== undefined
                ? company.niche
                : 'No Niche'}
                                </div>
                                <div class="jo-billing-report--grid-item">
                                    ${company.account_manager !== null &&
                company.account_manager !== undefined
                ? company.account_manager?.first_name
                : 'No Account Manager'}
                                </div>
                                <div class="jo-billing-report--grid-item">
                                    ${company.organization.owner.phone_number}
                                </div>
                                <div class="jo-billing-report--grid-item">
                                    ${company.organization.owner.email}
                                </div>
                                <div class="jo-billing-report--grid-item">
                                    ${company.preferred_communication}
                                </div>
                                <div class="jo-billing-report--grid-item">
                                    $${(amountDue / 100).toFixed(2)}
                                </div>
                                <div class="jo-billing-report--grid-item">
                                    ${makeAmountChargedTextForCompany(company)}
                                </div>
                                <div class="jo-billing-report--grid-item">
                                    ${makeAmountPaidTextForCompany(company)}
                                </div>
                                <div class="jo-billing-report--grid-item">
                                    ${(0, utilities_1.millisecondsToMinutes)((0, calculate_sum_of_object_at_property_1.calculateSumOfObjectsAtProperty)(company.company_logs, 'billable_duration'))}
                                </div>
                                <div class="jo-billing-report--grid-item">
                                    ${(0, calculate_total_purchased_minutes_for_company_1.calculateTotalPurchasedMinutesForCompany)(company)}
                                </div>
                                <div class="jo-billing-report--grid-item">
                                    ${(0, utilities_1.millisecondsToMinutes)((0, calculate_total_unused_milliseconds_for_company_1.calculateTotalUnusedMillisecondsForCompany)(company))}
                                </div>
                                <div class="jo-billing-report--grid-item">
                                    ${(0, utilities_1.millisecondsToMinutes)((0, calculate_total_overage_milliseconds_for_company_1.calculateTotalOverageMillisecondsForCompany)(company))}
                                </div>
                                <div class="jo-billing-report--grid-item">
                                    ${(0, calculations_1.determineCompanyPaymentStatus)(company)}
                                </div>
                                <div class="jo-billing-report--grid-item">
                                    ${company.credit_card_key ? 'Yes' : 'No'}
                                </div>
                                <div class="jo-billing-report--grid-item">
                                    ${(0, make_minutes_packages_summary_for_company_1.makeMinutesPackagesSummaryForCompany)({
                company,
                startDate: this.getStartDate(),
                endDate: this.getEndDate(),
                propertyToIncludeInSummary: 'name',
            })}
                                </div>
                                <div class="jo-billing-report--grid-item">
                                    ${(0, utilities_1.formatUSDCentsForDisplay)(Number((0, make_minutes_packages_summary_for_company_1.makeMinutesPackagesSummaryForCompany)({
                company,
                startDate: this.getStartDate(),
                endDate: this.getEndDate(),
                propertyToIncludeInSummary: 'price_per_minute',
            })))}
                                </div>
                            `;
        })}
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-billing-report', JOBillingReport);
function makeAmountPaidTextForCompany(company) {
    if (company.invoices.some(invoice => !invoice.stripe_invoice)) {
        return 'No Stripe Invoice';
    }
    return (0, calculations_1.calculateTotalPaidByCompany)(company).toFixed(2);
}
function makeAmountChargedTextForCompany(company) {
    if (company.invoices.some(invoice => !invoice.stripe_invoice)) {
        return 'No Stripe invoice';
    }
    return (0, calculations_1.calculateTotalChargedForCompany)(company).toFixed(2);
}
function makeDateRangeText(date, billingCycleType) {
    const cycleType = billingCycleType === 'ALL' ? 'MONTHLY' : billingCycleType;
    return `
        ${(0, get_start_of_billing_cycle_1.getStartOfBillingCycleInMountainTime)(date, cycleType).toLocaleDateString()}
        -
        ${(0, get_previous_date_1.getPreviousDate)((0, get_start_of_next_billing_cycle_1.getStartOfNextBillingCycleInMountainTime)(date, cycleType)).toLocaleDateString()}
    `;
}
