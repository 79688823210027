"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOPersonalAdminActionItemsTable = void 0;
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
require("../jo-button.element");
require("../jo-input");
const get_table_columns_1 = require("./get-table-columns");
const queries_1 = require("./queries");
require("../jo-table");
const subscriptions_1 = require("./subscriptions");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    selectedPreviewActionItemId: 'NOT_SET',
    subscriptions: {
        actionItemAssignedToAdminCreated: 'NOT_SET',
        actionItemAssignedToAdminUpdated: 'NOT_SET',
        actionItemAssignedToAdminMoved: 'NOT_SET',
    },
};
class JOPersonalAdminActionItemsTable extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
        this.triggerTableReload = (() => {
            this.handleGetActionItems = this.handleGetActionItems.bind(this);
            this.store.authenticatedUser = this.store.authenticatedUser;
        }).bind(this);
        this.handleGetActionItems = (async (offset, limit) => {
            (0, utilities_1.assertIsSet)(this.store.authenticatedUser, utilities_1.handleError, 'this.store.authenticatedUser');
            return await (0, queries_1.getActionItemsForAdminTable)(this.store.authenticatedUser, offset, limit);
        }).bind(this);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        const previousAuthenticatedUser = this.store.authenticatedUser;
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        if (previousAuthenticatedUser === 'NOT_SET' && props.authenticatedUser !== 'NOT_SET') {
            this.closeSubscriptionsIfNecessary();
            this.handleCreateSubscriptions();
        }
    }
    set authenticatedUser(authenticatedUser) {
        const previousAuthenticatedUser = this.store.authenticatedUser;
        this.store.authenticatedUser = authenticatedUser;
        if (previousAuthenticatedUser === 'NOT_SET' && authenticatedUser !== 'NOT_SET') {
            this.closeSubscriptionsIfNecessary();
            this.handleCreateSubscriptions();
        }
    }
    closeSubscriptionsIfNecessary() {
        Object.values(this.store.subscriptions).forEach(subscription => {
            if (subscription !== 'NOT_SET')
                subscription.close();
        });
        this.store.subscriptions = InitialState.subscriptions;
    }
    async handleCreateSubscriptions() {
        this.store.subscriptions = {
            actionItemAssignedToAdminCreated: (await this.createActionItemCreatedSubscription()) ?? 'NOT_SET',
            actionItemAssignedToAdminUpdated: (await this.createActionItemUpdatedSubscription()) ?? 'NOT_SET',
            actionItemAssignedToAdminMoved: (await this.createActionItemMovedSubscription()) ?? 'NOT_SET',
        };
    }
    async createActionItemCreatedSubscription() {
        return await (0, subscriptions_1.createActionItemCreatedSubscription)(this.triggerTableReload.bind(this));
    }
    async createActionItemUpdatedSubscription() {
        return await (0, subscriptions_1.createActionItemUpdatedSubscription)(this.triggerTableReload.bind(this));
    }
    async createActionItemMovedSubscription() {
        return await (0, subscriptions_1.createActionItemMovedSubscription)(this.triggerTableReload.bind(this));
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    font-size: 0.9rem;
                }
            </style>

            <div class="main-container">
                <div class="jo-global--card">
                    <jo-table
                        .tableName=${`Action Items Assigned to Personal Admins`}
                        .maxItemsPerPage=${15}
                        .columns=${(0, get_table_columns_1.getTableColumns)(state, this.store)}
                        .getItems=${this.handleGetActionItems}
                        .showSearchBar=${false}
                        @itemsfetched=${() => (0, utilities_1.raiseCustomEvent)(this, 'loaded', true)}
                    ></jo-table>
                </div>
            </div>
        `;
    }
}
exports.JOPersonalAdminActionItemsTable = JOPersonalAdminActionItemsTable;
(0, define_custom_element_1.defineCustomElement)('jo-personal-admin-action-items-table', JOPersonalAdminActionItemsTable);
