"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoCompanyChatLog = void 0;
const element_vir_1 = require("element-vir");
const get_chat_log_table_columns_1 = require("./get-chat-log-table-columns");
const chat_table_filters_1 = require("./chat-table-filters");
require("../../../elements/jo-table");
const get_chats_for_chat_log_table_1 = require("./get-chats-for-chat-log-table");
require("../chat-logs/jo-company-chat-log-mobile-template");
const jo_company_chat_log_mobile_template_1 = require("../chat-logs/jo-company-chat-log-mobile-template");
require("../../../elements/jo-input");
exports.JoCompanyChatLog = (0, element_vir_1.defineElement)()({
    tagName: 'jo-company-chat-log',
    styles: (0, element_vir_1.css) `
        :host {
            display: block;
        }
        .filters-container {
            margin-bottom: 4rem;
            display: flex;
            flex-direction: row;
            gap: 2rem;
        }
    `,
    stateInit: {
        selectedChatType: chat_table_filters_1.ChatTypeEnum.All,
        chats: [],
    },
    initCallback: async ({ state, updateState, inputs }) => {
        await fetchAndSetChats(state, updateState, inputs.companyId, inputs.shouldBypassFetch);
    },
    renderCallback: ({ state, updateState, inputs }) => {
        return (0, element_vir_1.html) `
            <section class="filters-container">
                <!-- TODO: ADD BILLING CYCLE FILTER HERE -->
                <!--https://jillsoffice.atlassian.net/browse/JWA-1885-->
                <jo-input
                    .type=${'SELECT'}
                    .label=${'Chat Type:'}
                    .selectOptions=${Object.values(chat_table_filters_1.ChatTypeEnum)}
                    .selectedValue=${state.selectedChatType}
                    @valueChanged=${async (event) => {
            updateState({ selectedChatType: event.detail.selectedValue });
            await fetchAndSetChats(state, updateState, inputs.companyId, inputs.shouldBypassFetch);
        }}
                ></jo-input>
            </section>
            <section>
                <jo-table
                    .tableName=${'Chats'}
                    .maxItemsPerPage=${100}
                    .columns=${(0, get_chat_log_table_columns_1.getChatLogTableColumns)(inputs.authenticatedUser)}
                    .items=${state.chats}
                    .totalItems=${state.chats.length}
                    .showCSVButton=${false}
                    .showSearchBar=${false}
                    .isMobileDevice=${inputs.isMobileDevice}
                    .mobileTemplate=${(item) => getMobileTemplate(item)}
                    .mobileTableHeader=${getMobileTableHeader}
                ></jo-table>
            </section>
        `;
    },
});
async function fetchAndSetChats(state, updateState, companyId, shouldBypassFetch) {
    if (shouldBypassFetch) {
        return;
    }
    if (companyId == undefined) {
        throw new Error('Company Id is undefined, cannot fetch chats');
    }
    const chatsArray = await (0, get_chats_for_chat_log_table_1.getChatsForChatLogTable)({
        companyId: companyId,
        selectedChatType: state.selectedChatType,
    });
    updateState({
        chats: chatsArray,
    });
}
function getMobileTemplate(item) {
    return (0, element_vir_1.html) `
        <${jo_company_chat_log_mobile_template_1.JoCompanyChatLogMobileTemplate}
            ${(0, element_vir_1.assign)(jo_company_chat_log_mobile_template_1.JoCompanyChatLogMobileTemplate, {
        item,
    })}
        ></${jo_company_chat_log_mobile_template_1.JoCompanyChatLogMobileTemplate}>
    `;
}
function getMobileTableHeader() {
    return (0, element_vir_1.html) `
        <style>
            .table-header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;
            }
            .table-cell {
                width: 25%;
                font-weight: bold;
                text-align: center;
            }
        </style>
        <div class="table-header">
            <div class="table-cell">Date</div>
            <div class="table-cell">Phone Number</div>
            <div class="table-cell">Duration</div>
        </div>
    `;
}
