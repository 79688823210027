"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stylesForJoJillsPods = void 0;
const lit_html_1 = require("lit-html");
const css_utils_1 = require("../../../utilities/css-utils");
const cssName = (0, css_utils_1.cssPrefixer)('jo-jills-pods');
exports.stylesForJoJillsPods = (() => {
    return (0, lit_html_1.html) `
        <style>
            .${cssName('main-container')} {
                height: 100%;
                width: 100%;
                box-sizing: border-box;
            }
            .${cssName('filter-inputs-container')} {
                display: flex;
                flex-direction: column;
                padding-bottom: 2rem;
            }
            .${cssName('filter-container-top-row')} {
                display: flex;
                justify-content: space-between;
                gap: 1rem;
                padding-bottom: 1rem;
            }
            .${cssName('filter-container-bottom-row')} {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
            .${cssName('drop-down-container')} {
                position: relative;
                display: inline-block;
                padding-top: 1rem;
            }
            .${cssName('drop-down-container')}:hover .${cssName('update-all-pods-container')} {
                display: block;
            }
            .${cssName('update-all-pods-container')} {
                width: 15rem;
                height: 22rem;
                z-index: 1;
                position: absolute;
                box-shadow: 0px 3px 18px 1px rgba(0, 0, 0, 0.2);
                display: none;
                overflow-y: auto;
            }
            .${cssName('pod-title')} {
                font-weight: bold;
                text-decoration: underline;
            }
            .${cssName('dropDown-buttons')} {
                display: flex;
                flex-direction: row;
                gap: 1rem;
                padding-top: 1rem;
                overflow-x: auto;
            }
        </style>
    `;
})();
