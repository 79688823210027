"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOContactsUploadErrorPopUp = void 0;
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
require("../jo-button.element");
require("../jo-table");
const InitialState = {
    invalidCSVRows: [],
};
class JOContactsUploadErrorPopUp extends HTMLElement {
    constructor(invalidCSVRows) {
        super();
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
        this.handleGetInvalidCSVRecords = (() => {
            return {
                items: this.store.invalidCSVRows,
                totalItems: this.store.invalidCSVRows.length,
            };
        }).bind(this);
        this.store.invalidCSVRows = invalidCSVRows;
    }
    cancelButtonClicked() {
        this.dispatchEvent(new CustomEvent('cancel'));
    }
    async csvButtonClicked() {
        const csv = await (0, utilities_1.generateCSVForJoTable)({
            columns: getColumnsForCSV(this.store),
            getItems: 'NOT_SET',
            items: this.store.invalidCSVRows,
            offset: 0,
            limit: 10,
            searchString: '',
            selectedColumn: 'NOT_SET',
        });
        (0, utilities_1.downloadCSV)(csv, 'Invalid CSV Records.csv');
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .main-container {
                    font-family: sans-serif;
                    max-height: 100vh;
                }

                .darken-screen {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.65);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: var(--z-index-3);
                }

                @media (max-width: 768px) {
                    .darken-screen {
                        align-items: flex-start;
                    }
                }

                .fade-in {
                    opacity: 0;
                    animation-name: fade-in;
                    animation-duration: 0.125s;
                    animation-fill-mode: forwards;
                }

                .window {
                    width: 35%;
                    background: white;
                    border-radius: 5px;
                    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
                    overflow: auto;
                }

                @media (max-width: 1280px) {
                    .window {
                        width: 50%;
                    }
                }

                @media (max-width: 768px) {
                    .window {
                        margin-top: 40%;
                        width: 75%;
                    }
                }

                .window-popup {
                    opacity: 0;
                    transform: scale(0.5);
                    animation-name: window-popup;
                    animation-duration: 0.15s;
                    animation-fill-mode: forwards;
                    overflow: auto;
                }

                .input-container {
                    padding: 1.5rem;
                    border-bottom: 1px solid var(--jo-accent-background-light);
                    box-sizing: border-box;
                    margin-left: auto;
                    margin-right: auto;
                }

                .buttons-row {
                    background: var(--jo-accent-background-light);
                    padding: 1rem;
                    display: flex;
                    justify-content: flex-end;
                }

                @keyframes fade-in {
                    to {
                        opacity: 1;
                    }
                }

                @keyframes window-popup {
                    to {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            </style>

            <div class="main-container">
                <div class="darken-screen fade-in" style="max-height:100%">
                    <div class="window window-popup" style="max-height:75%">
                        <div class="input-container" style="max-height:50%">
                            <jo-table
                                .tableName=${'the following contacts were not created'}
                                .maxItemsPerPage=${10}
                                .manuallySuppliedItems=${this.store.invalidCSVRows}
                                .totalItems=${this.store.invalidCSVRows.length}
                                .columns=${getColumns(state)}
                                .showSearchBar=${false}
                                .showCSVButton=${true}
                                @downloadCSVClicked=${() => {
            this.csvButtonClicked();
        }}
                            ></jo-table>
                        </div>
                        <div class="buttons-row">
                            <jo-button
                                .type=${'ACTION_BUTTON_1'}
                                .text=${'Okay'}
                                @joButtonClick=${() => this.cancelButtonClicked()}
                            ></jo-button>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}
exports.JOContactsUploadErrorPopUp = JOContactsUploadErrorPopUp;
(0, define_custom_element_1.defineCustomElement)('jo-company-contacts-upload-error-popup', JOContactsUploadErrorPopUp);
function getColumns(state) {
    const columns = [
        {
            title: 'Row',
            getCellData: (invalidCSVRecord) => invalidCSVRecord.rowNumber,
            width: 0.5,
        },
        {
            title: 'Reason',
            getCellData: (invalidCSVRecord) => invalidCSVRecord.reason,
            width: 1,
        },
        {
            title: 'Value',
            getCellData: (invalidCSVRecord) => invalidCSVRecord.record,
            width: 1,
        },
    ];
    return columns;
}
function getColumnsForCSV(state) {
    const columns = [
        {
            title: 'first_name,last_name,email,company_name,where_did_you_hear_about_us,phone_number1,phone_number_2,address_line_1,address_line_2,address_city,address_state,address_zip_code',
            getCellData: (invalidCSVRecord) => invalidCSVRecord.csvRecord,
            width: 0.5,
        },
    ];
    return columns;
}
