"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../services/graphql");
const utilities_1 = require("../../services/utilities");
const error_handling_1 = require("../../utilities/error-handling");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../utilities/environment/mapped-env-variables");
require("./jo-button.element");
require("./jo-input");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    loading: true,
    mode: 'READ',
    nameInputValue: '',
    pod: 'NOT_SET',
    podType: 'REGULAR',
    saving: false,
};
class JOPod extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    set pod(pod) {
        if (pod !== 'NOT_SET') {
            this.store.nameInputValue = pod.name || '';
        }
        this.store.pod = pod;
        this.store.loading = false;
    }
    set mode(mode) {
        this.store.mode = mode;
        this.store.loading = false;
    }
    editButtonClicked() {
        this.store.mode = 'EDIT';
    }
    async saveButtonClicked() {
        if (this.store.pod === 'NOT_SET' && this.store.mode !== 'CREATE') {
            return;
        }
        this.store.loading = true;
        const newName = this.store.nameInputValue;
        if (newName === '') {
            (0, utilities_1.joAlert)('Notice', 'Pod name cannot be blank');
            this.store.loading = false;
            return;
        }
        (0, utilities_1.raiseCustomEvent)(this, 'podchanged', {
            newName,
            podId: this.store.pod === 'NOT_SET' ? 'NEW_POD' : this.store.pod.id,
            podType: this.store.podType,
        });
        this.store.mode = 'READ';
        this.store.loading = false;
    }
    cancelButtonClicked() {
        this.dispatchEvent(new CustomEvent('podcanceled'));
        this.store.mode = 'READ';
    }
    deleteButtonClicked() {
        if (this.store.pod === 'NOT_SET') {
            throw new Error('Error: cannot delete pod that is not set');
        }
        (0, utilities_1.raiseCustomEvent)(this, 'poddeleted', {
            podId: this.store.pod.id,
            podName: this.store.pod.name,
        });
    }
    async handleRemoveAllUsersFromPod(type) {
        (0, utilities_1.assertIsSet)(this.store.pod, utilities_1.handleError, 'this.store.pod');
        const shouldContinue = await (0, utilities_1.joConfirm)('WAIT', `Are you sure you want to remove all ${type === 'MAIN_USERS' ? 'main' : 'float'} users from pod: ${this.store.pod.name}?`);
        if (shouldContinue === false) {
            return;
        }
        const removeAllusersFromPodResult = await removeAllUsersFromPod(this.store.pod.id, type);
        (0, utilities_1.assertSucceeded)(removeAllusersFromPodResult, utilities_1.handleError);
        (0, utilities_1.raiseCustomEvent)(this, 'bulkusersremovedfrompod');
        (0, utilities_1.joAlert)('Success', `All ${type === 'MAIN_USERS' ? 'main' : 'float'} users have been remove from pod: ${this.store.pod.name}`);
    }
    render(state) {
        const mainJillsString = state.pod === 'NOT_SET'
            ? ''
            : state.pod.users.map(user => `${user.first_name} ${user.last_name}`).join('\n');
        const floatJillsString = state.pod === 'NOT_SET'
            ? ''
            : state.pod.float_users
                .map(floatJill => `${floatJill.first_name} ${floatJill.last_name}`)
                .join('\n');
        if (state.loading) {
            return (0, lit_html_1.html) `
                <div>Loading...</div>
            `;
        }
        return (0, lit_html_1.html) `
            <style>
                .jo-pod--main-container {
                    box-sizing: border-box;
                    height: 100%;
                    width: 100%;
                    display: flex;
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                    justify-content: space-between;
                    align-items: center;
                }

                .jo-pod--main-container:hover {
                    background-color: var(--jo-primary-light);
                }

                .jo-pod--name {
                    margin-right: 1rem;
                }

                .jo-pod--type {
                    font-weight: bold;
                    margin-left: 3rem;
                    font-size: 0.9rem;
                }
            </style>

            <div class="jo-pod--main-container">
                ${state.mode === 'EDIT' || state.mode === 'CREATE'
            ? (0, lit_html_1.html) `
                          <div>
                              <input
                                  class="jo-global--input"
                                  style="width: 200px"
                                  type="text"
                                  id="nameInput"
                                  .value=${state.nameInputValue}
                                  placeholder="Enter pod name"
                                  @input=${(e) => (this.store.nameInputValue = e.target.value)}
                              />
                          </div>

                          <div>
                              <jo-input
                                  .label=${'Type'}
                                  .type=${'SELECT'}
                                  .selectOptions=${[
                'TESTING',
                'TRAINING',
                'REGULAR',
            ]}
                                  .selectedValue=${state.podType}
                                  @valueChanged=${(e) => {
                this.store.podType = e.detail.selectedValue;
            }}
                              ></jo-input>
                          </div>

                          <div style="display: flex">
                              <jo-button
                                  data-test-id=${'jo-pod--saveButton'}
                                  .type=${'ACTION_BUTTON_1'}
                                  .text=${state.saving === true ? 'Saving...' : 'Save'}
                                  .disabled=${state.saving === true}
                                  @joButtonClick=${() => this.saveButtonClicked()}
                              ></jo-button>
                              <div>&nbsp;&nbsp;</div>
                              <jo-button
                                  data-test-id=${'jo-pod--cancelButton'}
                                  .type=${'SECONDARY_BUTTON_1'}
                                  .text=${'Cancel'}
                                  @joButtonClick=${() => this.cancelButtonClicked()}
                              ></jo-button>
                          </div>
                      `
            : ''}
                ${state.mode === 'READ' && state.pod !== 'NOT_SET'
            ? (0, lit_html_1.html) `
                          <div class="jo-pod--name">
                              <strong>${state.pod.name}</strong>
                              <span class="jo-pod--type" title="Pod Type">${state.pod.type}</span>
                          </div>

                          <div
                              title="${mainJillsString.length === 0
                ? 'No Main Jills Assigned'
                : mainJillsString}"
                              style="cursor: help;"
                          >
                              <strong># Main Jills: ${state.pod.users.length}</strong>
                          </div>

                          <div
                              title="${floatJillsString.length === 0
                ? 'No Float Jills Assigned'
                : floatJillsString}"
                              style="cursor: help;"
                          >
                              <strong># Float Jills: ${state.pod.float_users.length}</strong>
                          </div>

                          <div style="display: flex">
                              <jo-button
                                  .type=${'SECONDARY_BUTTON_1'}
                                  .text=${'Edit'}
                                  @joButtonClick=${() => this.editButtonClicked()}
                              ></jo-button>

                              ${(0, utilities_1.authorizedRender)([
                'JILL_BUSINESS_DEVELOPMENT',
                'JILL_EXECUTIVE',
            ], state.authenticatedUser, (0, lit_html_1.html) `
                                      <div>&nbsp;&nbsp;</div>

                                      <jo-button
                                          .type=${'ACTION_BUTTON_1'}
                                          .text=${'Remove all main users from pod'}
                                          @joButtonClick=${() => this.handleRemoveAllUsersFromPod('MAIN_USERS')}
                                      ></jo-button>

                                      <div>&nbsp;&nbsp;</div>

                                      <jo-button
                                          .type=${'ACTION_BUTTON_1'}
                                          .text=${'Remove all float users from pod'}
                                          @joButtonClick=${() => this.handleRemoveAllUsersFromPod('FLOAT_USERS')}
                                      ></jo-button>

                                      <div>&nbsp;&nbsp;</div>

                                      <jo-button
                                          .type=${'DELETE_BUTTON_1'}
                                          .text=${'Delete Pod'}
                                          @joButtonClick=${() => this.deleteButtonClicked()}
                                      ></jo-button>
                                  `)}
                          </div>
                      `
            : ''}
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-pod', JOPod);
async function removeAllUsersFromPod(podId, type) {
    try {
        const gqlResult = type === 'MAIN_USERS'
            ? await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                          mutation ($podId: Int!) {
                              removeAllMainUsersFromPod(podId: $podId)
                          }
                      `, {
                podId,
            })
            : await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                          mutation ($podId: Int!) {
                              removeAllFloatUsersFromPod(podId: $podId)
                          }
                      `, {
                podId,
            });
        if (gqlResult.succeeded === false)
            return gqlResult;
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('removeAllUsersFromPod', error);
    }
}
