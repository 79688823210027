"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeCreatedAtAndUpdatedAt = void 0;
function removeCreatedAtAndUpdatedAt(item) {
    if (isNotObject(item)) {
        return item;
    }
    const mutableItem = makeDuplicate(item);
    removeFieldsAsNecessary(mutableItem);
    return mutableItem;
}
exports.removeCreatedAtAndUpdatedAt = removeCreatedAtAndUpdatedAt;
function removeFieldsAsNecessary(mutableItem) {
    if (hasCreatedAtProperty(mutableItem)) {
        removeProperty(mutableItem, 'created_at');
    }
    if (hasUpdatedAtProperty(mutableItem)) {
        removeProperty(mutableItem, 'updated_at');
    }
}
function isNotObject(item) {
    return typeof item !== 'object' || Array.isArray(item);
}
function makeDuplicate(item) {
    return { ...item };
}
function hasCreatedAtProperty(item) {
    return item.hasOwnProperty('created_at');
}
function hasUpdatedAtProperty(item) {
    return item.hasOwnProperty('updated_at');
}
function removeProperty(item, propertyName) {
    delete item[propertyName];
}
