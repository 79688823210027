"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildCallLogSummaries = exports.getStartOfNextMonth = exports.getStartOfMonth = exports.getColumns = void 0;
const lit_html_1 = require("lit-html");
const utilities_1 = require("../../../../services/utilities");
const get_previous_date_1 = require("../../../../utilities/dates-and-times/get-previous-date");
function getColumns(state) {
    const isAllowedToUpdate = (0, utilities_1.isInAuthRole)([
        'JILL_BUSINESS_DEVELOPMENT',
        'JILL_EXECUTIVE',
    ], state.authenticatedUser);
    const columns = [
        {
            title: 'Billing Cycle Dates',
            getCellData: (callLogSummary) => {
                const companyLog = callLogSummary.companyLog;
                const startDateString = new Date(companyLog.start_date).toLocaleDateString();
                const endDateString = (0, get_previous_date_1.getPreviousDate)(companyLog.end_date).toLocaleDateString();
                return `${startDateString} - ${endDateString}`;
            },
            width: 1.5,
        },
        {
            title: '# Minutes in Package',
            getCellData: (callLogSummary) => getNumMinutesInPackage(callLogSummary.package),
            width: 1,
        },
        {
            title: 'Minutes Used',
            getCellData: (callLogSummary) => callLogSummary.companyLog.billable_duration / 1000 / 60,
            width: 1,
        },
        {
            title: 'Total Calls',
            getCellData: (callLogSummary) => callLogSummary.totalCallsForBillingCycle,
            width: 1,
        },
        {
            title: '',
            getCellData: (callLogSummary) => getViewCallLogButtonHTML(callLogSummary, state.companyId, isAllowedToUpdate),
            width: 0.5,
        },
    ];
    return columns;
}
exports.getColumns = getColumns;
function getNumMinutesInPackage(minutesPackage) {
    if (minutesPackage === 'NOT_FOUND')
        return 'Not Found';
    if (minutesPackage.hasOwnProperty('num_minutes_included'))
        return minutesPackage.num_minutes_included;
    if (minutesPackage.hasOwnProperty('minutes_per_month'))
        return minutesPackage.minutes_per_month ?? 'Not Found';
    return 'Unknown';
}
function getViewCallLogButtonHTML(callLogSummary, companyId, isAllowedToUpdate) {
    return (0, lit_html_1.html) `
        <div>
            <span
                class="material-icons jo-global--action-icon"
                @click=${() => isAllowedToUpdate === false
        ? (0, utilities_1.navigate)(`/customer/call-log?companyId=${companyId}&forMonth=${callLogSummary.companyLog.for_month}`)
        : (0, utilities_1.navigate)(`/company/billing/logs?companyId=${companyId}&forMonth=${callLogSummary.companyLog.for_month}`)}
            >
                remove_red_eye
            </span>
        </div>
    `;
}
function getStartOfMonth(forMonth) {
    return new Date(forMonth).toISOString();
}
exports.getStartOfMonth = getStartOfMonth;
function getStartOfNextMonth(dateInMonthISOString) {
    const dateInMonthDate = new Date(dateInMonthISOString);
    const startOfNextMonthDate = new Date(dateInMonthDate.getFullYear(), dateInMonthDate.getMonth() + 1);
    return startOfNextMonthDate.toISOString();
}
exports.getStartOfNextMonth = getStartOfNextMonth;
function buildCallLogSummaries(companyLogs, callTotalsForMonthsResults, packagesForMonhtsResults) {
    let result = [];
    for (let i = 0; i < companyLogs.length; i++) {
        const companyLog = companyLogs[i];
        if (companyLog == null) {
            throw new Error('companyLog is null');
        }
        const callTotalForMonthResult = callTotalsForMonthsResults[i];
        if (callTotalForMonthResult?.succeeded === false)
            return callTotalForMonthResult;
        const numCalls = callTotalForMonthResult?.value;
        if (numCalls == null) {
            throw new Error('numCalls is null');
        }
        const packageForMonthResult = packagesForMonhtsResults[i];
        if (packageForMonthResult == null) {
            throw new Error('packageForMonthResult is null');
        }
        if (packageForMonthResult?.succeeded === false)
            return packageForMonthResult;
        const packageForMonth = packageForMonthResult?.value;
        const callLogSummary = createCallLogSummary(companyLog, numCalls, packageForMonth);
        result.push(callLogSummary);
    }
    return {
        succeeded: true,
        value: result,
    };
}
exports.buildCallLogSummaries = buildCallLogSummaries;
function createCallLogSummary(companyLog, numCalls, packageForMonth) {
    return {
        companyLog: companyLog,
        totalCallsForBillingCycle: numCalls,
        package: packageForMonth,
    };
}
