"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOActionItemFlagsTable = void 0;
const dequal_1 = require("dequal");
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const jo_table_manager_1 = require("../../../classes/jo-table-manager");
const utilities_1 = require("../../../services/utilities");
const format_date_for_date_input_1 = require("../../../utilities/dates-and-times/format-date-for-date-input");
const get_start_of_current_month_1 = require("../../../utilities/dates-and-times/get-start-of-current-month");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const did_any_fields_change_1 = require("../../../utilities/did-any-fields-change");
require("../jo-button.element");
require("../jo-input");
require("../jo-table");
const get_table_columns_1 = require("./get-table-columns");
const queries_1 = require("./queries");
const css_utils_1 = require("../../../utilities/css-utils");
const styles_1 = require("./styles");
const helper_utils_1 = require("./helper-utils");
const get_start_of_date_1 = require("../../../utilities/dates-and-times/get-start-of-date");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    companyId: null,
    csvTitle: '',
    endDate: (0, get_start_of_date_1.getStartOfTomorrow)(),
    flagAddressalFormType: 'Addressed',
    hideMistakenlyFlaggedItems: false,
    jillId: null,
    noteText: '',
    searchText: '',
    selectedFlag: null,
    selectedFlagFilter: 'ALL',
    showFlagAddressalForm: false,
    startDate: (0, get_start_of_current_month_1.getStartOfCurrentMonth)(),
    tableManager: new jo_table_manager_1.JOTableManager(),
    teamId: null,
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-action-item-flags-table');
class JOActionItemFlagsTable extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, dequal_1.dequal)(props, this.store.getState())) {
            return;
        }
        const propsChanged = this.getDidAnyFieldsChange(props);
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        if (propsChanged) {
            this.setupNewTableManager();
            this.fetchAndSetActionItemFlags();
        }
    }
    setupNewTableManager() {
        this.store.tableManager = new jo_table_manager_1.JOTableManager({
            maxItemsPerPage: 15,
            columnsGetter: get_table_columns_1.getColumns,
            csvFileName: 'some report',
        });
    }
    getDidAnyFieldsChange(props) {
        return (0, did_any_fields_change_1.didAnyFieldsChange)(props, this.store.getState(), [
            'authenticatedUser',
            'companyId',
            'teamId',
            'jillId',
        ]);
    }
    async markFlagAsAddressedClicked(selectedFlag) {
        this.store.selectedFlag = selectedFlag;
        this.store.flagAddressalFormType = 'Addressed';
        this.store.showFlagAddressalForm = true;
        this.store.noteText = '';
    }
    async markActionItemFlagAsMistake(selectedFlag) {
        this.store.selectedFlag = selectedFlag;
        this.store.flagAddressalFormType = 'Mistake';
        this.store.showFlagAddressalForm = true;
        this.store.noteText = '';
    }
    async fetchAndSetActionItemFlags() {
        const result = await this.getActionItemFlags();
        this.store.tableManager.setItems(result.items);
        this.store.tableManager.setTotalItems(result.totalItems);
        this.store.dispatch({
            type: 'RENDER',
        });
    }
    async getActionItemFlags(params) {
        const actionItemFlagsResult = await (0, queries_1.getActionItemFlags)({
            offset: params?.offset ?? this.store.tableManager.offset,
            limit: this.store.tableManager.maxItemsPerPage,
            teamId: this.store.teamId,
            jillId: this.store.jillId,
            startDate: this.store.startDate,
            endDate: this.store.endDate,
            searchText: this.store.searchText,
            column: this.store.tableManager.selectedColumn,
            companyId: this.store.companyId,
            flagType: this.store.selectedFlagFilter,
        });
        if (actionItemFlagsResult.succeeded === false) {
            return {
                items: [],
                totalItems: 0,
            };
        }
        return actionItemFlagsResult.value;
    }
    async getActionItemFlagsForCSV(params) {
        const actionItemFlagsResult = await (0, queries_1.getActionItemFlags)({
            offset: params?.offset ?? this.store.tableManager.offset,
            limit: this.store.tableManager.maxItemsPerPage,
            teamId: this.store.teamId,
            jillId: this.store.jillId,
            startDate: this.store.startDate,
            endDate: this.store.endDate,
            searchText: this.store.searchText,
            column: this.store.tableManager.selectedColumn,
            companyId: this.store.companyId,
            flagType: this.store.selectedFlagFilter,
        });
        if (actionItemFlagsResult.succeeded === false) {
            return {
                items: [],
                totalItems: 0,
            };
        }
        let resultTotal = actionItemFlagsResult.value.totalItems;
        const resultItems = actionItemFlagsResult.value.items.filter(item => {
            if (item.mistakenly_flagged_action_items.length === 0) {
                return item;
            }
            else {
                resultTotal--;
            }
        });
        return {
            items: resultItems,
            totalItems: resultTotal,
        };
    }
    async csvButtonClicked() {
        await this.store.tableManager.downloadCSV({
            columns: this.store.tableManager.getColumns(this, { forCSV: true }).filter(column => {
                if (column.title !== 'Mistake Addressal') {
                    return column.title;
                }
            }),
            itemsGetter: async (offset) => await this.getActionItemFlagsForCSV.bind(this)({ offset }),
            searchText: this.store.searchText,
            fileName: this.store.csvTitle + ' -- ' + this.getDateRangeForCSVTitle() + '.csv',
        });
    }
    getDateRangeForCSVTitle() {
        return (this.store.startDate.toDateString() + ' through ' + this.store.endDate.toDateString());
    }
    getItems(state) {
        if (this.store.hideMistakenlyFlaggedItems) {
            return state.tableManager.items.filter(helper_utils_1.removeFlagsMarkedAsMistake);
        }
        return state.tableManager.items;
    }
    getTotalItems(state) {
        if (this.store.hideMistakenlyFlaggedItems) {
            return state.tableManager.items.filter(helper_utils_1.removeFlagsMarkedAsMistake).length;
        }
        return state.tableManager.totalItems;
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

            ${styles_1.stylesForJoActionItemFlagsTable}

            <div class="${cssName('mainContainer')}">
                <section class="${cssName('dates')}">
                    <div>
                        <label for="start-date">Start Date</label>
                        <input
                            id="start-date"
                            class="jo-global--input"
                            type="date"
                            .value=${(0, format_date_for_date_input_1.formatDateForDateInput)(state.startDate)}
                            @change=${(e) => {
            this.store.startDate = new Date(e.target.value);
            this.fetchAndSetActionItemFlags();
        }}
                        />
                    </div>

                    <div>
                        <label for="end-date">End Date</label>
                        <input
                            id="end-date"
                            class="jo-global--input"
                            type="date"
                            .value=${(0, format_date_for_date_input_1.formatDateForDateInput)(state.endDate)}
                            @change=${(e) => {
            this.store.endDate = new Date(e.target.value);
            this.fetchAndSetActionItemFlags();
        }}
                        />
                    </div>

                    <div>
                        <jo-input
                            .type=${'SELECT'}
                            .label=${'Select Flag Type'}
                            .selectOptions=${[
            'ALL',
            'MISTAKENLY_FLAGGED',
            'CORRECTLY_FLAGGED',
        ]}
                            .selectedValue=${state.selectedFlagFilter}
                            @valueChanged=${(e) => {
            this.store.selectedFlagFilter = e.detail
                .selectedValue;
            this.fetchAndSetActionItemFlags();
        }}
                        ></jo-input>
                    </div>
                </section>

                <section>
                    <jo-table
                        .tableName=${'Flagged Action Items'}
                        .maxItemsPerPage=${state.tableManager.maxItemsPerPage}
                        .totalItems=${this.getTotalItems(state)}
                        .columns=${state.tableManager.getColumns(this, {
            authenticatedUser: state.authenticatedUser,
        })}
                        .items=${this.getItems(state)}
                        .showCSVButton=${true}
                        @tablePageClicked=${(e) => {
            this.store.tableManager.setOffset(e.detail.newOffset);
            this.fetchAndSetActionItemFlags();
        }}
                        .searchDebounce=${200}
                        @searchTextChanged=${(e) => {
            this.store.searchText = e.detail.searchText;
            this.fetchAndSetActionItemFlags();
        }}
                        @columnHeaderClicked=${(e) => {
            this.store.tableManager.setSelectedColumn(e.detail);
            this.fetchAndSetActionItemFlags();
        }}
                        @downloadCSVClicked=${() => {
            this.csvButtonClicked();
        }}
                    ></jo-table>
                </section>

                <section>
                    <jo-fade-in-overlay
                        .props=${{
            child: (0, lit_html_1.html) `
                                <div class="jo-global--width-50-vw">
                                    <jo-flag-addressal-form
                                        .props=${{
                authenticatedUser: state.authenticatedUser,
                addressalType: state.flagAddressalFormType,
                flagId: state.selectedFlag?.id || 'NOT_SET',
                noteText: state.noteText,
            }}
                                        @flagAddressed=${() => {
                this.fetchAndSetActionItemFlags();
                this.store.showFlagAddressalForm = false;
                this.store.noteText = '';
            }}
                                        @addressalNoteTextChanged=${(e) => {
                this.store.noteText = e.detail;
            }}
                                    ></jo-flag-addressal-form>
                                </div>
                            `,
            showChild: this.store.showFlagAddressalForm,
        }}
                        @closeoverlay=${() => {
            this.store.showFlagAddressalForm = false;
            this.store.selectedFlag = null;
        }}
                    ></jo-fade-in-overlay>
                </section>
            </div>
        `;
    }
}
exports.JOActionItemFlagsTable = JOActionItemFlagsTable;
(0, define_custom_element_1.defineCustomElement)('jo-action-item-flags-table', JOActionItemFlagsTable);
