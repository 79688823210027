"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createActionItemMovedSubscription = exports.createActionItemUpdatedSubscription = exports.createActionItemCreatedSubscription = void 0;
const graphql_1 = require("../../../services/graphql");
async function createActionItemCreatedSubscription(callback) {
    return await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
            subscription {
                actionItemAssignedToPersonalAdminCreated
            }
        `, data => {
        callback();
    });
}
exports.createActionItemCreatedSubscription = createActionItemCreatedSubscription;
async function createActionItemUpdatedSubscription(callback) {
    return await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
            subscription {
                actionItemAssignedToPersonalAdminUpdated
            }
        `, data => {
        callback();
    });
}
exports.createActionItemUpdatedSubscription = createActionItemUpdatedSubscription;
async function createActionItemMovedSubscription(callback) {
    return await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
            subscription {
                actionItemsMovedInCategory(actionItemStatusTypeId: 5) # 5: Assigned to Personal Admin
            }
        `, data => {
        callback();
    });
}
exports.createActionItemMovedSubscription = createActionItemMovedSubscription;
