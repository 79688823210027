"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateOverageUsageInMilliseconds = void 0;
const dates_and_times_1 = require("../../dates-and-times/dates-and-times");
function calculateOverageUsageInMilliseconds(billableDurationForIntervalInMilliseconds, numMinutesIncludedInPackage) {
    const numMillisecondsIncludedInPackage = numMinutesIncludedInPackage * dates_and_times_1.oneMinuteInMilliseconds;
    const rawResult = billableDurationForIntervalInMilliseconds - numMillisecondsIncludedInPackage;
    return Math.max(rawResult, 0);
}
exports.calculateOverageUsageInMilliseconds = calculateOverageUsageInMilliseconds;
