"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isInputValuesEmpty = exports.getCustomFieldInputValuesForServiceCheckboxIfNotChecked = exports.getCustomFieldInputValuesForServiceCheckboxIfChecked = exports.getCustomFieldsInputValuesForServiceCheckbox = void 0;
function getCustomFieldsInputValuesForServiceCheckbox(e, company, service, customFieldInputValues, customFieldsInputValues) {
    return customFieldsInputValues.map((innerCustomFieldInputValues) => {
        const foundCorrectInnerCustomFieldInputValues = customFieldInputValues.symbol === innerCustomFieldInputValues.symbol;
        if (foundCorrectInnerCustomFieldInputValues === true) {
            const checkboxChecked = e.target.checked === true;
            if (checkboxChecked === true) {
                return getCustomFieldInputValuesForServiceCheckboxIfChecked(service, innerCustomFieldInputValues);
            }
            else {
                return getCustomFieldInputValuesForServiceCheckboxIfNotChecked(company, service, customFieldInputValues, innerCustomFieldInputValues);
            }
        }
        else {
            return innerCustomFieldInputValues;
        }
    });
}
exports.getCustomFieldsInputValuesForServiceCheckbox = getCustomFieldsInputValuesForServiceCheckbox;
function getCustomFieldInputValuesForServiceCheckboxIfChecked(service, innerCustomFieldInputValues) {
    return {
        ...innerCustomFieldInputValues,
        service_ids_to_connect: addServiceIdToConnect(innerCustomFieldInputValues, service),
    };
}
exports.getCustomFieldInputValuesForServiceCheckboxIfChecked = getCustomFieldInputValuesForServiceCheckboxIfChecked;
function getUniqueServiceIdsToDisconnect(innerCustomFieldInputValues, service) {
    const serviceIdsToDisconnect = innerCustomFieldInputValues.service_ids_to_disconnect.filter(serviceId => serviceId !== service.id);
    return [
        ...new Set(serviceIdsToDisconnect),
        service.id,
    ];
}
function addServiceIdToConnect(innerCustomFieldInputValues, service) {
    const serviceIdsWithNewServiceIdAdded = [
        ...innerCustomFieldInputValues.service_ids_to_connect,
        service.id,
    ];
    return [...new Set(serviceIdsWithNewServiceIdAdded)];
}
function getCustomFieldInputValuesForServiceCheckboxIfNotChecked(company, service, customFieldInputValues, innerCustomFieldInputValues) {
    const customField = company === 'NOT_SET'
        ? undefined
        : company.custom_fields.find(customField => {
            return customField.id === customFieldInputValues.id;
        });
    const serviceWasAlreadyPresentInCustomField = customField?.services.some(customFieldService => {
        return service.id === customFieldService.id;
    }) ?? false;
    if (serviceWasAlreadyPresentInCustomField === true) {
        return {
            ...innerCustomFieldInputValues,
            service_ids_to_connect: removeServiceIdFromServiceIdsToConnect(innerCustomFieldInputValues, service),
            service_ids_to_disconnect: getUniqueServiceIdsToDisconnect(innerCustomFieldInputValues, service),
        };
    }
    else {
        return {
            ...innerCustomFieldInputValues,
            service_ids_to_connect: getUniqueServiceIdsToDisconnect(innerCustomFieldInputValues, service),
        };
    }
}
exports.getCustomFieldInputValuesForServiceCheckboxIfNotChecked = getCustomFieldInputValuesForServiceCheckboxIfNotChecked;
function removeServiceIdFromServiceIdsToConnect(innerCustomFieldInputValues, service) {
    return [
        ...new Set(innerCustomFieldInputValues.service_ids_to_connect.filter((serviceId) => serviceId !== service.id)),
    ];
}
function isInputValuesEmpty(inputValues) {
    if (inputValues.customFieldsInputValues.length === 0) {
        return true;
    }
    return false;
}
exports.isInputValuesEmpty = isInputValuesEmpty;
