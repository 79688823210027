"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStartOfTomorrow = exports.getStartOfToday = exports.getStartOfDate = void 0;
function getStartOfDate(date) {
    const startOfDate = new Date(date.getTime());
    startOfDate.setHours(0, 0, 0, 0);
    return startOfDate;
}
exports.getStartOfDate = getStartOfDate;
function getStartOfToday() {
    return getStartOfDate(new Date());
}
exports.getStartOfToday = getStartOfToday;
function getStartOfTomorrow() {
    const today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    return getStartOfDate(tomorrow);
}
exports.getStartOfTomorrow = getStartOfTomorrow;
