"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLocationAsUrl = exports.extractPostOriginUrlString = void 0;
/**
 * Get everything in a URL/Location after the origin. Usually you'll want to pass in
 * globalThis.location as the input.
 *
 * @example
 *     extractPostOriginUrlString(globalThis.location);
 *
 * @example
 *     With a full URL of "https://example.org:8080/foo/bar?q=baz#bang", this function will output
 *     "/foo/bar?q=baz#bang".
 */
function extractPostOriginUrlString(locationObject) {
    return locationObject.href.split(locationObject.origin)[1] ?? '';
}
exports.extractPostOriginUrlString = extractPostOriginUrlString;
/**
 * Converts a Location object (such as window.location) into a URL object. This doesn't really do
 * much but it makes the types simpler as TS doesn't think Location can be an input to the URL
 * constructor but it DOES work.
 */
function getLocationAsUrl(location = globalThis.location) {
    return new URL(location);
}
exports.getLocationAsUrl = getLocationAsUrl;
