"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateItemInListOfItems = void 0;
function updateItemInListOfItems(breaks, updatedBreak) {
    return breaks.map(break_ => {
        if (break_.id === updatedBreak.id) {
            return updatedBreak;
        }
        return break_;
    });
}
exports.updateItemInListOfItems = updateItemInListOfItems;
