"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../../services/utilities");
require("../../jo-input");
const queries_and_mutations_1 = require("./queries-and-mutations");
require("../../jo-table");
const helper_utils_1 = require("./helper-utils");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    companyId: 'NOT_SET',
    isMobileDevice: false,
};
class JOBillingCallLogSummary extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
        this.handleGetCallLogSummaries = (async (offset, limit) => {
            if (this.store.companyId === 'NOT_SET') {
                return {
                    items: [],
                    totalItems: 0,
                };
            }
            const gqlResults = await Promise.all([
                (0, queries_and_mutations_1.fetchCallLogSummariesForCompany)(this.store.companyId, limit, offset),
                (0, queries_and_mutations_1.fetchTotalNumCompanyLogsForCompany)(this.store.companyId),
            ]);
            const [callLogSummariesResult, totalCallLogsResult,] = gqlResults;
            (0, utilities_1.assertSucceeded)(callLogSummariesResult, utilities_1.handleError);
            (0, utilities_1.assertSucceeded)(totalCallLogsResult, utilities_1.handleError);
            const callLogSummaries = callLogSummariesResult.value;
            const totalNumCallLogs = totalCallLogsResult.value;
            return {
                items: callLogSummaries,
                totalItems: totalNumCallLogs,
            };
        }).bind(this);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        this.triggerTableReload();
    }
    triggerTableReload() {
        this.handleGetCallLogSummaries = this.handleGetCallLogSummaries.bind(this);
        this.store.companyId = this.store.companyId;
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .jo-billing-call-log-summary--main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }
            </style>

            <div class="jo-billing-call-log-summary--main-container">
                <jo-table
                    .tableName=${'Call Log Summary'}
                    .columns=${(0, helper_utils_1.getColumns)(state)}
                    .getItems=${this.handleGetCallLogSummaries}
                    .showSearchBar=${false}
                ></jo-table>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-billing-call-log-summary', JOBillingCallLogSummary);
