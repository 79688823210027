"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../../services/graphql");
const utilities_1 = require("../../../services/utilities");
require("../jo-button.element");
require("../jo-input");
require("./services/jo-company-services-pricing-services");
require("./pricing/jo-company-services-pricing-pricing");
require("./custom-fields/jo-company-services-pricing-custom-fields");
require("../jo-loading");
const store_1 = require("../../../services/store");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
const InitialState = {
    authenticatedUser: store_1.InitialGlobalState.authenticatedUser,
    company: 'NOT_SET',
    companyId: 'NOT_SET',
    inputValues: {
        customFieldsInputValues: 'NOT_SET',
        servicesInputValues: 'NOT_SET',
        pricingInputValues: 'NOT_SET',
    },
    loading: true,
    saving: false,
    navigationTab: 'SERVICES',
    isMobileDevice: false,
};
class JOCompanyServicesPricing extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, (state, action) => {
            if (action.type === 'RESET_STATE') {
                return {
                    ...InitialState,
                    isMobileDevice: state.isMobileDevice,
                };
            }
            return state;
        });
    }
    async handleSaveError(joFaiure) {
        (0, utilities_1.joAlert)('Error', joFaiure.userMessage || 'There was an error');
        await this.fetchAndSetCompany();
        throw new Error(joFaiure.developerMessage);
    }
    set companyId(companyId) {
        if (this.store.companyId === companyId) {
            return;
        }
        this.store.dispatch({
            type: 'RESET_STATE',
        });
        this.store.companyId = companyId;
        if (this.store.companyId !== 'NOT_SET') {
            // TODO I believe we should implement this functionality in most of our components...consider ways of
            // TODO reusing this functionality in components without having to type it out all of the time
            (async () => {
                this.store.loading = true;
                await this.fetchAndSetCompany();
                this.store.loading = false;
            })();
        }
    }
    set company(company) {
        if (this.store.company === company) {
            return;
        }
        (0, utilities_1.raiseCustomEvent)(this, 'servicespricingcompanyabouttochange');
        this.store.company = company;
    }
    set inputValues(inputValues) {
        this.store.inputValues = inputValues;
        if (!this.store.isMobileDevice) {
            (0, utilities_1.raiseCustomEvent)(this, 'servicespricinginputvalueschanged', this.store.inputValues);
        }
    }
    async fetchAndSetCompany() {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError);
        const result = await fetchCompany(this.store.companyId);
        (0, utilities_1.assertSucceeded)(result, utilities_1.handleError);
        const company = result.value;
        this.company = company;
    }
    async save() {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError);
        (0, utilities_1.assertIsSet)(this.store.inputValues.servicesInputValues, utilities_1.handleError);
        (0, utilities_1.assertIsSet)(this.store.inputValues.pricingInputValues, utilities_1.handleError);
        (0, utilities_1.assertIsSet)(this.store.inputValues.customFieldsInputValues, utilities_1.handleError);
        this.store.saving = true;
        const servicesResult = await saveServices(this.store.companyId, this.store.inputValues.servicesInputValues);
        const pricingResult = await savePricing(this.store.inputValues.pricingInputValues);
        const customFieldsResult = await saveCustomFields(this.store.companyId, this.store.inputValues.customFieldsInputValues);
        this.store.saving = false;
        (0, utilities_1.assertSucceeded)(servicesResult, this.handleSaveError);
        (0, utilities_1.assertSucceeded)(pricingResult, this.handleSaveError);
        (0, utilities_1.assertSucceeded)(customFieldsResult, this.handleSaveError);
        await this.fetchAndSetCompany();
        await deleteAllDuplicateJoinTableRows();
        (0, utilities_1.joAlert)('Success', 'Updated successfully');
    }
    async servicesInputValuesChanged(e) {
        const servicesInputValues = e.detail;
        this.inputValues = {
            ...this.store.inputValues,
            servicesInputValues,
        };
        const shouldArchive = servicesInputValues.servicesInputValues.some((serviceInputValues) => serviceInputValues.archived === true);
        if (shouldArchive === true) {
            await this.save();
        }
    }
    async customFieldsInputValuesChanged(e) {
        const customFieldsInputValues = e.detail;
        this.inputValues = {
            ...this.store.inputValues,
            customFieldsInputValues,
        };
        const shouldArchive = customFieldsInputValues.customFieldsInputValues.some((customFieldInputValues) => {
            return (customFieldInputValues.archived === true ||
                customFieldInputValues.select_values.some((selectValueInputValues) => {
                    return selectValueInputValues.archived === true;
                }));
        });
        if (shouldArchive === true) {
            await this.save();
        }
    }
    async pricingInputValuesChanged(e) {
        const pricingInputValues = e.detail;
        this.inputValues = {
            ...this.store.inputValues,
            pricingInputValues,
        };
        const shouldArchive = pricingInputValues.serviceLineItemsInputValues.some((serviceLineItemInputValues) => serviceLineItemInputValues.archived === true);
        if (shouldArchive === true) {
            await this.save();
        }
    }
    cancelButtonClicked() {
        const originalCompany = this.store.company;
        this.company = 'NOT_SET';
        this.company = originalCompany;
    }
    async getDeleteConfirmation(message) {
        const confirmed = await (0, utilities_1.joConfirm)('Confirm', message);
        if (confirmed === false) {
            return false;
        }
        const againConfirm = await (0, utilities_1.joConfirm)('Confirm', message);
        return againConfirm;
    }
    async showSuccessMessage(message) {
        await (0, utilities_1.joAlert)('Success', message);
    }
    async handleDeleteAllServices() {
        const conformationMessage = 'Are you sure you want to delete all the services for the company?';
        const shouldDelete = await this.getDeleteConfirmation(conformationMessage);
        if (shouldDelete === false) {
            return;
        }
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError);
        const archiveAllServicesResult = await archiveAllServicesForCompany(this.store.companyId);
        (0, utilities_1.assertSucceeded)(archiveAllServicesResult, utilities_1.handleError);
        const successMessage = 'Deleted all services successfully';
        await this.showSuccessMessage(successMessage);
        await this.fetchAndSetCompany();
    }
    async handleDeleteAllCustomFields() {
        const conformationMessage = 'Are you sure you want to delete all the custom fields for the company?';
        const shouldDelete = await this.getDeleteConfirmation(conformationMessage);
        if (shouldDelete === false) {
            return;
        }
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError);
        const archiveCustomFieldsResult = await archiveAllCustomFieldsForCompany(this.store.companyId);
        (0, utilities_1.assertSucceeded)(archiveCustomFieldsResult, utilities_1.handleError);
        const successMessage = 'Deleted all custom fields successfully';
        await this.showSuccessMessage(successMessage);
        await this.fetchAndSetCompany();
    }
    async handleDeleteAllPricingLineItems() {
        const conformationMessage = 'Are you sure you want to delete all the pricing line items for the company?';
        const shouldDelete = await this.getDeleteConfirmation(conformationMessage);
        if (shouldDelete === false) {
            return;
        }
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError);
        const archiveAllPricingLineItemsResult = await archiveAllPricingLineItemsForCompany(this.store.companyId);
        (0, utilities_1.assertSucceeded)(archiveAllPricingLineItemsResult, utilities_1.handleError);
        const successMessage = 'Deleted all pricing line items successfully';
        await this.showSuccessMessage(successMessage);
        await this.fetchAndSetCompany();
    }
    updateNav(value) {
        this.store.navigationTab = value;
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: ${state.isMobileDevice ? `column` : `row`};
                }

                .column-1 {
                    flex: 2;
                    padding-right: ${state.isMobileDevice ? `0` : `1rem`};
                }

                .column-2 {
                    flex: 2;
                    padding-right: ${state.isMobileDevice ? `0` : `1rem`};
                }

                .column-3 {
                    flex: 0.5;
                }

                .component-container {
                    padding-bottom: 1rem;
                }

                .button-container {
                    padding-bottom: 1rem;
                }

                .buttons-container {
                    display: flex;
                    justify-content: flex-start;
                }
                .nav-bar-container {
                    display: flex;
                    flex-direction: row;
                    border-top: 1px solid black;
                    border-bottom: 1px solid black;
                    margin-bottom: 1rem;
                }
                .nav-bar-button {
                    display: flex;
                    align-items: center;
                    padding: 0.5rem;
                    font-size: 0.8rem;
                    width: 33.3%;
                    justify-content: center;
                }
                .active {
                    background-color: var(--jo-primary);
                    color: white;
                }
            </style>
            <div class="main-container">
                <div ?hidden=${!state.isMobileDevice} class="nav-bar-container">
                    <div
                        class=${`nav-bar-button ${state.navigationTab === 'SERVICES' ? 'active' : ''}`}
                        @click=${() => this.updateNav('SERVICES')}
                    >
                        Services
                    </div>
                    <div
                        class=${`nav-bar-button ${state.navigationTab === 'CUSTOM' ? 'active' : ''}`}
                        @click=${() => this.updateNav('CUSTOM')}
                    >
                        Custom Fields
                    </div>
                    <div
                        class=${`nav-bar-button ${state.navigationTab === 'PRICING' ? 'active' : ''}`}
                        @click=${() => this.updateNav('PRICING')}
                    >
                        Pricing
                    </div>
                </div>
                <div class="column-1">
                    ${(state.isMobileDevice && state.navigationTab === 'SERVICES') ||
            !state.isMobileDevice
            ? (0, lit_html_1.html) `
                              <div class="component-container">
                                  <div class="jo-global--card">
                                      <jo-company-services-pricing-services
                                          .authenticatedUser=${state.authenticatedUser}
                                          .company=${state.company}
                                          .isMobileDevice=${state.isMobileDevice}
                                          @inputvalueschanged=${(e) => this.servicesInputValuesChanged(e)}
                                          @deleteAllServicesClicked=${() => this.handleDeleteAllServices()}
                                      ></jo-company-services-pricing-services>
                                  </div>
                              </div>
                          `
            : (0, lit_html_1.html) ``}
                    ${(state.isMobileDevice && state.navigationTab === 'CUSTOM') ||
            !state.isMobileDevice
            ? (0, lit_html_1.html) `
                              <div class="component-container ">
                                  <div class="jo-global--card">
                                      <jo-company-services-pricing-custom-fields
                                          .authenticatedUser=${state.authenticatedUser}
                                          .company=${state.company}
                                          .isMobileDevice=${state.isMobileDevice}
                                          @inputvalueschanged=${(e) => this.customFieldsInputValuesChanged(e)}
                                          @deleteAllCustomFieldsClicked=${() => this.handleDeleteAllCustomFields()}
                                      ></jo-company-services-pricing-custom-fields>
                                  </div>
                              </div>
                          `
            : (0, lit_html_1.html) ``}
                </div>

                ${(state.isMobileDevice && state.navigationTab === 'PRICING') ||
            !state.isMobileDevice
            ? (0, lit_html_1.html) `
                          <div class="column-2">
                              <div class="component-container">
                                  <div class="jo-global--card">
                                      <jo-company-services-pricing-pricing
                                          .authenticatedUser=${state.authenticatedUser}
                                          .company=${state.company}
                                          .isMobileDevice=${state.isMobileDevice}
                                          @inputvalueschanged=${(e) => this.pricingInputValuesChanged(e)}
                                          @deleteAllPricingLineItemsClicked=${() => this.handleDeleteAllPricingLineItems()}
                                      ></jo-company-services-pricing-pricing>
                                  </div>
                              </div>
                              <div
                                  ?hidden=${(0, utilities_1.shouldHideFromCompanyUser)(this.store.authenticatedUser)}
                                  class="buttons-container"
                              >
                                  <div class="button-container" style="padding-right: 1rem;">
                                      <jo-button
                                          .type=${'ACTION_BUTTON_1'}
                                          .text=${state.saving === true ? 'Saving...' : 'Save'}
                                          .disabled=${state.saving === true}
                                          @joButtonClick=${() => this.save()}
                                      ></jo-button>
                                  </div>

                                  <div class="button-container">
                                      <jo-button
                                          .type=${'SECONDARY_BUTTON_1'}
                                          .text=${'Cancel'}
                                          .disabled=${state.saving === true}
                                          @joButtonClick=${() => this.cancelButtonClicked()}
                                      ></jo-button>
                                  </div>
                              </div>
                          </div>
                      `
            : (0, lit_html_1.html) ``}
                <div ?hidden=${!state.isMobileDevice} class="mobile-bottom-filler"></div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-services-pricing', JOCompanyServicesPricing);
async function fetchCompany(companyId) {
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query ($companyId: Int!) {
                fetchCompanyForServicesPricingPage(companyId: $companyId) {
                    id
                    custom_fields(
                        filter: {archived: {eq: false}}
                        orderBy: {field: "created_at", order: ASC}
                    ) {
                        id
                        archived
                        call_screen_appear
                        call_screen_require
                        order
                        select_values(
                            filter: {archived: {eq: false}}
                            orderBy: {field: "created_at", order: ASC}
                        ) {
                            id
                            archived
                            text_value
                        }
                        services(
                            filter: {archived: {eq: false}}
                            orderBy: {field: "created_at", order: ASC}
                        ) {
                            id
                            archived
                            name
                        }
                        text_value
                        type
                    }
                    services(
                        filter: {archived: {eq: false}}
                        orderBy: {field: "created_at", order: ASC}
                    ) {
                        id
                        archived
                        description
                        display_position
                        estimates
                        forms
                        jobs
                        name
                        pricing
                        price_minimum
                        save_without_scheduling
                        line_items(
                            filter: {archived: {eq: false}}
                            orderBy: {field: "created_at", order: ASC}
                        ) {
                            id
                            archived
                            calculate
                            description
                            price
                            service {
                                id
                                archived
                                name
                            }
                        }
                    }
                    services_not_provided
                }
            }
        `, {
        companyId,
    });
    if (gqlResult.succeeded === false) {
        return gqlResult;
    }
    const company = gqlResult.value.data.fetchCompanyForServicesPricingPage;
    return {
        succeeded: true,
        value: company,
    };
}
async function saveServices(companyId, inputValues) {
    const servicesNotProvidedResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($companyId: Int!, $servicesNotProvided: String!) {
                updateCompanies(
                    input: {id: $companyId, services_not_provided: $servicesNotProvided}
                ) {
                    id
                }
            }
        `, {
        companyId,
        servicesNotProvided: inputValues.servicesNotProvided,
    });
    if (servicesNotProvidedResult.succeeded === false) {
        return servicesNotProvidedResult;
    }
    for (let i = 0; i < inputValues.servicesInputValues.length; i++) {
        const serviceInputValues = inputValues.servicesInputValues[i];
        if (serviceInputValues == null)
            throw new Error('serviceInputValues is null');
        const gqlResult = await saveService(companyId, serviceInputValues, i);
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
    }
    return { succeeded: true };
}
async function saveService(companyId, serviceInputValues, displayPosition) {
    if (serviceInputValues.id === 'NOT_SET') {
        const servicesResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation (
                    $companyId: Int!
                    $estimates: Boolean!
                    $forms: Boolean!
                    $jobs: Boolean!
                    $name: String!
                    $pricing: Boolean!
                    $priceMinimum: Int!
                    $saveWithoutScheduling: Boolean!
                    $description: String!
                    $displayPosition: Int!
                ) {
                    createCompany_services(
                        input: {
                            archived: false
                            company: {connect: {id: $companyId}}
                            estimates: $estimates
                            forms: $forms
                            jobs: $jobs
                            name: $name
                            pricing: $pricing
                            price_minimum: $priceMinimum
                            save_without_scheduling: $saveWithoutScheduling
                            description: $description
                            display_position: $displayPosition
                        }
                    ) {
                        id
                    }
                }
            `, {
            companyId,
            estimates: serviceInputValues.estimates,
            forms: serviceInputValues.forms,
            jobs: serviceInputValues.jobs,
            name: serviceInputValues.name,
            pricing: serviceInputValues.pricing,
            priceMinimum: serviceInputValues.price_minimum,
            saveWithoutScheduling: serviceInputValues.save_without_scheduling,
            description: serviceInputValues.description,
            displayPosition,
        });
        if (servicesResult.succeeded === false) {
            return servicesResult;
        }
    }
    else {
        const servicesResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation (
                    $serviceId: Int!
                    $archived: Boolean!
                    $estimates: Boolean!
                    $forms: Boolean!
                    $jobs: Boolean!
                    $name: String!
                    $pricing: Boolean!
                    $priceMinimum: Int!
                    $saveWithoutScheduling: Boolean!
                    $description: String!
                    $displayPosition: Int!
                ) {
                    updateCompany_services(
                        input: {
                            id: $serviceId
                            archived: $archived
                            estimates: $estimates
                            forms: $forms
                            jobs: $jobs
                            name: $name
                            pricing: $pricing
                            price_minimum: $priceMinimum
                            save_without_scheduling: $saveWithoutScheduling
                            description: $description
                            display_position: $displayPosition
                        }
                    ) {
                        id
                        description
                    }
                }
            `, {
            serviceId: serviceInputValues.id,
            archived: serviceInputValues.archived,
            estimates: serviceInputValues.estimates,
            forms: serviceInputValues.forms,
            jobs: serviceInputValues.jobs,
            name: serviceInputValues.name,
            pricing: serviceInputValues.pricing,
            priceMinimum: serviceInputValues.price_minimum,
            saveWithoutScheduling: serviceInputValues.save_without_scheduling,
            description: serviceInputValues.description,
            displayPosition,
        });
        if (servicesResult.succeeded === false) {
            return servicesResult;
        }
    }
    return {
        succeeded: true,
    };
}
async function savePricing(pricingInputValue) {
    const formErrors = pricingInputValue.serviceLineItemsInputValues.reduce((result, serviceLineItemInputValue) => {
        return [
            ...result,
            ...serviceLineItemInputValue.formErrors,
        ];
    }, []);
    if (formErrors.length > 0) {
        const firstFormError = formErrors[0];
        if (firstFormError == null)
            throw new Error('firstFormError is null');
        if (firstFormError.errorMessage === ' must have a selected value') {
            return {
                succeeded: false,
                userMessage: `Service${firstFormError.errorMessage}`,
                developerMessage: `Service${firstFormError.errorMessage}`,
            };
        }
        else {
            return {
                succeeded: false,
                userMessage: firstFormError.errorMessage,
                developerMessage: firstFormError.errorMessage,
            };
        }
    }
    for (let i = 0; i < pricingInputValue.serviceLineItemsInputValues.length; i++) {
        const serviceLineItemInputValue = pricingInputValue.serviceLineItemsInputValues[i];
        if (serviceLineItemInputValue == null)
            throw new Error('serviceLineItemInputValue is null');
        if (serviceLineItemInputValue.id === 'NOT_SET') {
            const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                    mutation (
                        $calculate: Boolean!
                        $description: String!
                        $price: Int!
                        $serviceId: Int!
                    ) {
                        createCompany_service_line_items(
                            input: {
                                archived: false
                                calculate: $calculate
                                description: $description
                                price: $price
                                service: {connect: {id: $serviceId}}
                            }
                        ) {
                            id
                        }
                    }
                `, {
                calculate: serviceLineItemInputValue.calculate,
                description: serviceLineItemInputValue.description,
                price: serviceLineItemInputValue.price,
                serviceId: serviceLineItemInputValue.service.id,
            });
            if (result.succeeded === false) {
                return result;
            }
        }
        else {
            const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                    mutation (
                        $serviceLineItemId: Int!
                        $archived: Boolean!
                        $calculate: Boolean!
                        $description: String!
                        $price: Int!
                        $serviceId: Int!
                    ) {
                        updateCompany_service_line_items(
                            input: {
                                id: $serviceLineItemId
                                archived: $archived
                                calculate: $calculate
                                description: $description
                                price: $price
                                service: {connect: {id: $serviceId}}
                            }
                        ) {
                            id
                        }
                    }
                `, {
                serviceLineItemId: serviceLineItemInputValue.id,
                archived: serviceLineItemInputValue.archived,
                calculate: serviceLineItemInputValue.calculate,
                description: serviceLineItemInputValue.description,
                price: serviceLineItemInputValue.price,
                serviceId: serviceLineItemInputValue.service.id,
            });
            if (result.succeeded === false) {
                return result;
            }
        }
    }
    return { succeeded: true };
}
async function saveCustomFields(companyId, customFieldsInputValues) {
    const resultPromises = customFieldsInputValues.customFieldsInputValues.map((customFieldInputValues) => {
        return saveCustomField(companyId, customFieldInputValues);
    });
    const results = await Promise.all(resultPromises);
    const failedResult = results.find(result => {
        return result.succeeded === false;
    });
    if (failedResult !== undefined) {
        return failedResult;
    }
    return {
        succeeded: true,
    };
}
async function saveCustomField(companyId, customFieldInputValues) {
    if (customFieldInputValues.id === 'NOT_SET') {
        const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation (
                    $callScreenAppear: Boolean!
                    $callScreenRequire: Boolean!
                    $companyId: Int!
                    $order: Int
                    $serviceIds: [Int!]!
                    $textValue: String!
                    $type: COMPANY_CUSTOM_FIELD_TYPE!
                ) {
                    createCompany_custom_fields(
                        input: {
                            archived: false
                            call_screen_appear: $callScreenAppear
                            call_screen_require: $callScreenRequire
                            company: {connect: {id: $companyId}}
                            order: $order
                            services: {connect: {ids: $serviceIds}}
                            text_value: $textValue
                            type: $type
                        }
                    ) {
                        id
                    }
                }
            `, {
            callScreenAppear: customFieldInputValues.call_screen_appear,
            callScreenRequire: customFieldInputValues.call_screen_require,
            companyId,
            order: customFieldInputValues.order,
            serviceIds: [...new Set(customFieldInputValues.service_ids_to_connect)],
            textValue: customFieldInputValues.text_value,
            type: customFieldInputValues.type,
        });
        if (result.succeeded === false) {
            return result;
        }
        const customField = result.value.data.createCompany_custom_fields;
        const selectValuesResult = await saveSelectValues(customField.id, customFieldInputValues.select_values);
        if (selectValuesResult.succeeded === false) {
            return selectValuesResult;
        }
    }
    else {
        const mainResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation (
                    $customFieldId: Int!
                    $archived: Boolean!
                    $callScreenAppear: Boolean!
                    $callScreenRequire: Boolean!
                    $order: Int
                    $serviceIds: [Int!]!
                    $textValue: String!
                ) {
                    updateCompany_custom_fields(
                        input: {
                            id: $customFieldId
                            archived: $archived
                            call_screen_appear: $callScreenAppear
                            call_screen_require: $callScreenRequire
                            order: $order
                            services: {connect: {ids: $serviceIds}}
                            text_value: $textValue
                        }
                    ) {
                        id
                    }
                }
            `, {
            customFieldId: customFieldInputValues.id,
            archived: customFieldInputValues.archived,
            callScreenAppear: customFieldInputValues.call_screen_appear,
            callScreenRequire: customFieldInputValues.call_screen_require,
            order: customFieldInputValues.order,
            serviceIds: [...new Set(customFieldInputValues.service_ids_to_connect)],
            textValue: customFieldInputValues.text_value,
        });
        if (mainResult.succeeded === false) {
            return mainResult;
        }
        const disconnectServicesResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($customFieldId: Int!, $serviceIds: [Int!]!) {
                    updateCompany_custom_fields(
                        input: {id: $customFieldId, services: {disconnect: {ids: $serviceIds}}}
                    ) {
                        id
                    }
                }
            `, {
            customFieldId: customFieldInputValues.id,
            serviceIds: [...new Set(customFieldInputValues.service_ids_to_disconnect)],
        });
        if (disconnectServicesResult.succeeded === false) {
            return disconnectServicesResult;
        }
        const selectValuesResult = await saveSelectValues(customFieldInputValues.id, customFieldInputValues.select_values);
        if (selectValuesResult.succeeded === false) {
            return selectValuesResult;
        }
    }
    return {
        succeeded: true,
    };
}
async function saveSelectValues(customFieldId, selectValuesInputValues) {
    const resultPromises = selectValuesInputValues.map((selectValueInputValues) => {
        return saveSelectValue(customFieldId, selectValueInputValues);
    });
    const results = await Promise.all(resultPromises);
    const failedResult = results.find(result => {
        return result.succeeded === false;
    });
    if (failedResult !== undefined) {
        return failedResult;
    }
    return {
        succeeded: true,
    };
}
async function saveSelectValue(customFieldId, selectValueInputValues) {
    if (selectValueInputValues.id === 'NOT_SET') {
        const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($customFieldId: Int!, $textValue: String!) {
                    createCompany_custom_field_select_values(
                        input: {
                            archived: false
                            custom_field: {connect: {id: $customFieldId}}
                            text_value: $textValue
                        }
                    ) {
                        id
                    }
                }
            `, {
            customFieldId,
            textValue: selectValueInputValues.text_value,
        });
        if (result.succeeded === false) {
            return result;
        }
    }
    else {
        const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation (
                    $selectValueId: Int!
                    $archived: Boolean!
                    $customFieldId: Int!
                    $textValue: String!
                ) {
                    updateCompany_custom_field_select_values(
                        input: {
                            id: $selectValueId
                            archived: $archived
                            custom_field: {connect: {id: $customFieldId}}
                            text_value: $textValue
                        }
                    ) {
                        id
                    }
                }
            `, {
            selectValueId: selectValueInputValues.id,
            archived: selectValueInputValues.archived,
            customFieldId,
            textValue: selectValueInputValues.text_value,
        });
        if (result.succeeded === false) {
            return result;
        }
    }
    return {
        succeeded: true,
    };
}
async function archiveAllServicesForCompany(companyId) {
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($companyId: Int!) {
                archiveAllServicesForCompany(companyId: $companyId)
            }
        `, {
        companyId,
    });
    if (gqlResult.succeeded === false) {
        return gqlResult;
    }
    return {
        succeeded: true,
    };
}
async function archiveAllCustomFieldsForCompany(companyId) {
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($companyId: Int!) {
                archiveAllCustomFieldsForCompany(companyId: $companyId)
            }
        `, {
        companyId,
    });
    if (gqlResult.succeeded === false) {
        return gqlResult;
    }
    return {
        succeeded: true,
    };
}
async function archiveAllPricingLineItemsForCompany(companyId) {
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($companyId: Int!) {
                archiveAllPricingLineItemsForCompany(companyId: $companyId)
            }
        `, {
        companyId,
    });
    if (gqlResult.succeeded === false) {
        return gqlResult;
    }
    return {
        succeeded: true,
    };
}
async function deleteAllDuplicateJoinTableRows() {
    try {
        await (0, graphql_1.gqlMutation)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint, (0, graphql_1.gql) `
                mutation {
                    deleteDuplicateJoinTableRows
                }
            `);
    }
    catch (error) {
        throw new Error(`deleteAllDuplicateJoinTables error: ${error}`);
    }
}
