"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoAccountHelpUpdateForm = void 0;
const element_vir_1 = require("element-vir");
const update_help_tickets_1 = require("./update-help-tickets");
const store_1 = require("../../../services/store");
const utilities_1 = require("../../../services/utilities");
const account_help_ticket_status_1 = require("../../../utilities/account-help-tickets/account-help-ticket-status");
exports.JoAccountHelpUpdateForm = (0, element_vir_1.defineElement)()({
    tagName: 'jo-account-help-update-form',
    styles: (0, element_vir_1.css) `
        :host {
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            background-color: white;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2rem;
        }

        .text-area {
            width: 60%;
        }

        .buttons-row {
            display: flex;
            justify-content: space-around;
            width: 100%;
        }
    `,
    events: {
        submitted: (0, element_vir_1.defineElementEvent)(),
    },
    stateInit: {
        comment: '',
    },
    renderCallback({ state, dispatch, events, updateState, inputs }) {
        async function submitUpdate(selectedStatus) {
            if (state.comment === '') {
                await (0, utilities_1.joAlert)('Notice', 'You must leave a comment');
                return;
            }
            (0, utilities_1.assertIsDefined)(inputs.accountHelpTicket);
            (0, utilities_1.assertIsSet)(store_1.GlobalStore.authenticatedUser);
            await (0, update_help_tickets_1.updateHelpTicket)({
                accountHelpTicketId: inputs.accountHelpTicket.id,
                comment: state.comment,
                userId: store_1.GlobalStore.authenticatedUser.id,
                status: selectedStatus,
            });
            dispatch(new events.submitted());
        }
        return (0, element_vir_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />

            <h1>Update Account Help Ticket</h1>

            <div class="text-area">
                <jo-input
                    .type=${'TEXT_AREA'}
                    .rows=${5}
                    .inputValue=${state.comment}
                    @inputchange=${(event) => {
            updateState({
                comment: event.detail.inputValue,
            });
        }}
                ></jo-input>
            </div>

            <div class="buttons-row">
                <jo-button
                    .type=${'SECONDARY_BUTTON_1'}
                    .text=${'Mark as "In Review"'}
                    @joButtonClick=${async () => {
            await submitUpdate(account_help_ticket_status_1.AccountHelpTicketStatus.IN_REVIEW);
        }}
                ></jo-button>

                <jo-button
                    .type=${'ACTION_BUTTON_GREEN'}
                    .text=${'Resolve'}
                    @joButtonClick=${async () => {
            await submitUpdate(account_help_ticket_status_1.AccountHelpTicketStatus.CLOSED_RESOLVED);
        }}
                ></jo-button>

                <jo-button
                    .type=${'DELETE_BUTTON_1'}
                    .text=${'Mark as Unresolvable'}
                    @joButtonClick=${async () => {
            await submitUpdate(account_help_ticket_status_1.AccountHelpTicketStatus.CLOSED_NO_RESOLUTION);
        }}
                ></jo-button>

                <jo-button
                    .type=${'ACTION_BUTTON_2'}
                    .text=${'Mark as Mistake'}
                    @joButtonClick=${async () => {
            await submitUpdate(account_help_ticket_status_1.AccountHelpTicketStatus.CLOSED_AS_MISTAKE);
        }}
                ></jo-button>
            </div>
        `;
    },
});
