"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNotAllowedToViewVerifyPhoneNumber = exports.isPhoneNumberAlreadyVerified = exports.isAllowedToVerifyPhoneNumber = void 0;
function isAllowedToVerifyPhoneNumber(state) {
    return (state.company !== 'NOT_SET' &&
        state.company.business_number === state.inputValues.companyPhoneNumberInputValue);
}
exports.isAllowedToVerifyPhoneNumber = isAllowedToVerifyPhoneNumber;
function isPhoneNumberAlreadyVerified(state) {
    return (state.company !== 'NOT_SET' &&
        state.company.verified_business_number !== null &&
        state.company.verified_business_number !== undefined &&
        state.company.verified_business_number !== '' &&
        state.company.business_number === state.company.verified_business_number &&
        state.company.business_number === state.inputValues.companyPhoneNumberInputValue);
}
exports.isPhoneNumberAlreadyVerified = isPhoneNumberAlreadyVerified;
function isNotAllowedToViewVerifyPhoneNumber(state) {
    if (state.phoneNumberVerificationStatus === 'FAILED') {
        return false;
    }
    return (state.inputValues.setCompanyPhoneAsCallerIdCheckBoxValue === false ||
        state.validationCode !== '' ||
        isPhoneNumberAlreadyVerified(state) === true ||
        isAllowedToVerifyPhoneNumber(state) === false);
}
exports.isNotAllowedToViewVerifyPhoneNumber = isNotAllowedToViewVerifyPhoneNumber;
