"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getExpiryMonthAndYearFromExpiry = exports.getColumns = void 0;
const lit_html_1 = require("lit-html");
const common_1 = require("@augment-vir/common");
function getColumns(element, state) {
    const columns = [
        {
            title: 'Actions',
            getCellData: card => {
                return card.isDefault
                    ? (0, lit_html_1.html) ``
                    : (0, lit_html_1.html) `
                          <jo-button
                              .type=${'ICON'}
                              .icon=${'delete'}
                              @joButtonClick=${async () => {
                        await element.deletePaymentMethodForCustomer(card.id);
                    }}
                          ></jo-button>
                      `;
            },
            width: 0.2,
        },
        {
            title: 'Last 4 Digits',
            getCellData: card => card.last4,
            width: 0.2,
        },
        {
            title: 'Card Type',
            getCellData: card => card.brand,
            width: 0.2,
        },
        {
            title: 'Expiry',
            getCellData: card => getFormattedCardsExpiryDetails(card.exp_month, card.exp_year),
            width: 0.2,
        },
        {
            title: 'Default',
            getCellData: card => {
                return card.isDefault
                    ? (0, lit_html_1.html) `
                          <jo-button .type=${'ICON'} .icon=${'check'} .disabled=${true}></jo-button>
                      `
                    : (0, lit_html_1.html) `
                          <jo-button
                              .type=${'ACTION_BUTTON_1'}
                              .text=${'Make Default'}
                              .inlineStyle=${'font-size: 0.9rem; padding: 0.5rem 0.75rem'}
                              @joButtonClick=${async () => {
                        await element.makeDefaultPaymentForCustomer(card.id);
                    }}
                          ></jo-button>
                      `;
            },
            width: 0.2,
        },
    ];
    return columns;
}
exports.getColumns = getColumns;
function getFormattedCardsExpiryDetails(expMonth, expYear) {
    const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'July',
        'Aug',
        'Sept',
        'Oct',
        'Nov',
        'Dec',
    ];
    return `${monthNames[expMonth - 1]} ${expYear}`;
}
function getExpiryMonthAndYearFromExpiry(expiry) {
    const dateParts = expiry.split('/');
    (0, common_1.assertLengthAtLeast)(dateParts, 2);
    const month = Number(dateParts[0]);
    const year = dateParts[1];
    const fullYear = Number(20 + year);
    return [
        month,
        fullYear,
    ];
}
exports.getExpiryMonthAndYearFromExpiry = getExpiryMonthAndYearFromExpiry;
