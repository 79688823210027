"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createEmptyInvoiceLineItem = exports.getAmountDueForInvoiceLineItemString = exports.calculateTotalAmountDueInCents = exports.getAmountDueString = exports.getInvoiceStatusString = void 0;
const utilities_1 = require("../../../../services/utilities");
const is_defined_1 = require("../../../../utilities/is-defined");
const constants_1 = require("../../jo-billing-report/constants");
function getInvoiceStatusString(invoice) {
    if (invoice === 'NOT_SET')
        return 'Loading...';
    if (invoice === 'NOT_FOUND')
        return constants_1.BillingReportStatus.UNKNOWN;
    if (invoice.stripe_invoice) {
        if (invoice.stripe_invoice.totalPaid === invoice.stripe_invoice.totalCharged)
            return constants_1.BillingReportStatus.PAID;
    }
    if ((0, is_defined_1.isDefined)(invoice.manually_assigned_status))
        return constants_1.BillingReportStatus[invoice.manually_assigned_status];
    return constants_1.BillingReportStatus.UNPAID;
}
exports.getInvoiceStatusString = getInvoiceStatusString;
function getAmountDueString(invoice) {
    if (invoice === 'NOT_SET')
        return '';
    if (invoice === 'NOT_FOUND')
        return 'Unknown';
    const totalDueInCents = calculateTotalAmountDueInCents(invoice);
    return (0, utilities_1.convertCentsToUSDollarsString)(totalDueInCents);
}
exports.getAmountDueString = getAmountDueString;
function calculateTotalAmountDueInCents(invoice) {
    return invoice.invoice_line_items.reduce((sum, lineItem) => sum + (lineItem.amount || 0), 0);
}
exports.calculateTotalAmountDueInCents = calculateTotalAmountDueInCents;
function getAmountDueForInvoiceLineItemString(invoiceLineItem) {
    if (invoiceLineItem.amount === null || invoiceLineItem.amount === undefined) {
        return 'Uknown';
    }
    return (0, utilities_1.convertCentsToUSDollarsString)(invoiceLineItem.amount);
}
exports.getAmountDueForInvoiceLineItemString = getAmountDueForInvoiceLineItemString;
function createEmptyInvoiceLineItem() {
    return {
        id: -1,
        amount: 0,
        description: '',
    };
}
exports.createEmptyInvoiceLineItem = createEmptyInvoiceLineItem;
