"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeHTMLForChatStatus = exports.makeScheduleStatusTextHTML = exports.makeWorkStatusTextHTML = exports.formatTime = exports.getPodIdsToDisconnect = exports.getPodIdsToConnect = exports.jillWasSeenOnACallWithinCertainMillisecondsAgo = exports.wasJillSeenOnACallWithinTenSecondsAgo = void 0;
const lit_html_1 = require("lit-html");
const is_user_scheduled_to_be_on_break_1 = require("../../../utilities/schedule-items/is-user-scheduled-to-be-on-break");
const is_user_scheduled_to_be_on_lunch_1 = require("../../../utilities/schedule-items/is-user-scheduled-to-be-on-lunch");
const is_user_scheduled_to_be_working_1 = require("../../../utilities/schedule-items/is-user-scheduled-to-be-working");
const make_chat_status_1 = require("./utils/make-chat-status");
function wasJillSeenOnACallWithinTenSecondsAgo(jill) {
    if (!jill.last_seen_on_a_call)
        return false;
    return jillWasSeenOnACallWithinCertainMillisecondsAgo(jill, 8000);
}
exports.wasJillSeenOnACallWithinTenSecondsAgo = wasJillSeenOnACallWithinTenSecondsAgo;
function jillWasSeenOnACallWithinCertainMillisecondsAgo(jill, milliseconds) {
    return new Date().getTime() - new Date(jill.last_seen_on_a_call).getTime() <= milliseconds;
}
exports.jillWasSeenOnACallWithinCertainMillisecondsAgo = jillWasSeenOnACallWithinCertainMillisecondsAgo;
function getPodIdsToConnect(selectedPods, currentPods) {
    return selectedPods
        .filter(selectedPod => !currentPods.some(currentPod => currentPod.id == selectedPod.id))
        .map(pod => pod.id);
}
exports.getPodIdsToConnect = getPodIdsToConnect;
function getPodIdsToDisconnect(selectedPods, currentPods) {
    return currentPods
        .filter(currentPod => !selectedPods.some(selectedPod => selectedPod.id === currentPod.id))
        .map(pod => pod.id);
}
exports.getPodIdsToDisconnect = getPodIdsToDisconnect;
function formatTime(time) {
    if (time === undefined || time === null)
        return 'unknown';
    return new Date(time).toLocaleString();
}
exports.formatTime = formatTime;
function makeWorkStatusTextHTML(workStatus) {
    switch (workStatus) {
        case 'CLOCKED_IN':
            return (0, lit_html_1.html) `
                <span style="color: green;">Clocked In ⏲</span>
            `;
        case 'CLOCKED_OUT':
            return (0, lit_html_1.html) `
                <span style="color: red;">Clocked Out ⏲</span>
            `;
        case 'ON_BREAK':
            return (0, lit_html_1.html) `
                <span style="color: #38c7d1;">On Break 🕔</span>
            `;
        case 'ON_LUNCH':
            return (0, lit_html_1.html) `
                <span style="color: #38c7d1;">On Lunch 🍟</span>
            `;
        case 'IN_TEAM_HUDDLE':
            return (0, lit_html_1.html) `
                <span style="color: #38c7d1;">In Huddle/1:1 🗓️</span>
            `;
        case 'IN_OFF_CALL_TRAINING':
            return (0, lit_html_1.html) `
                <span style="color: #38c7d1;">In Training 🎓</span>
            `;
        case 'IN_ADMIN_MODE':
            return (0, lit_html_1.html) `
                <span style="color: indigo;">In Admin Mode 👩</span>
            `;
        default:
            return (0, lit_html_1.html) ``;
    }
}
exports.makeWorkStatusTextHTML = makeWorkStatusTextHTML;
function makeScheduleStatusTextHTML(shifts) {
    const now = new Date();
    if ((0, is_user_scheduled_to_be_on_break_1.isUserScheduledToBeOnBreak)(shifts, now)) {
        return (0, lit_html_1.html) `
            <span style="color: #38c7d1;">Scheduled to be on break 🕔</span>
        `;
    }
    if ((0, is_user_scheduled_to_be_on_lunch_1.isUserScheduledToBeOnLunch)(shifts, now)) {
        return (0, lit_html_1.html) `
            <span style="color: #38c7d1;">Scheduled to be on lunch 🍟</span>
        `;
    }
    if ((0, is_user_scheduled_to_be_working_1.isUserScheduledToBeWorking)(shifts, now)) {
        return (0, lit_html_1.html) `
            <span style="color: green;">Scheduled to be Working</span>
        `;
    }
    return (0, lit_html_1.html) `
        <span style="color: red;">Scheduled to be Off</span>
    `;
}
exports.makeScheduleStatusTextHTML = makeScheduleStatusTextHTML;
function makeHTMLForChatStatus(last_seen_in_chat_mode, last_seen_actively_chatting_at) {
    const jillChatStatus = (0, make_chat_status_1.makeChatStatus)(last_seen_in_chat_mode, last_seen_actively_chatting_at);
    switch (jillChatStatus) {
        case make_chat_status_1.JillChatActivityStatus.InChatModeActivelyChatting:
        case make_chat_status_1.JillChatActivityStatus.InChatModeNotChatting:
            return (0, lit_html_1.html) `
                <span style="color: var(--jo-success)">${jillChatStatus}</span>
            `;
        default:
            return (0, lit_html_1.html) `
                <span style="color: var(--jo-danger)">${jillChatStatus}</span>
            `;
    }
}
exports.makeHTMLForChatStatus = makeHTMLForChatStatus;
