"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.swapItemsInArray = void 0;
/**
 * If Direction is 'Up' or 'Left' it will swap positions of the item at index with the next item at
 * index-1. If direction is 'Down' or 'Right' then the next index would be index+1.
 *
 * @param array Type any[] a mutable array.
 * @param index Type number.
 * @param direction Type string of 'Up', 'Down', 'Left', or 'Right'
 */
function swapItemsInArray(array, index, direction) {
    if (array.length < 1) {
        return;
    }
    const temp = array[index];
    array[index] = array[getNextIndex(direction, index, array.length)];
    array[getNextIndex(direction, index, array.length)] = temp;
}
exports.swapItemsInArray = swapItemsInArray;
function getNextIndex(direction, index, length) {
    const newIndex = direction === 'Up' || direction === 'Left' ? index - 1 : index + 1;
    if (newIndex >= length)
        return length - 1;
    if (newIndex < 0)
        return 0;
    return newIndex;
}
