"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getColumns = void 0;
const helper_utils_1 = require("./helper-utils");
function getColumns() {
    const columns = [
        {
            title: 'Jill',
            getCellData: (jillHighLevelStatses) => `${jillHighLevelStatses.firstName} ${jillHighLevelStatses.lastName}`,
            width: 2,
            sortFieldName: 'name',
        },
        {
            title: 'Total Calls',
            getCellData: (jillHighLevelStatses) => `${jillHighLevelStatses.highLevelStatses.highLevelStats.numCalls}`,
            width: 1,
            sortFieldName: 'numCalls',
        },
        {
            title: 'Total Calls Under 25 Seconds',
            getCellData: (jillHighLevelStatses) => `${jillHighLevelStatses.highLevelStatses.highLevelStats.numCallsUnder25Seconds}`,
            width: 1,
            sortFieldName: 'numCalls',
        },
        {
            title: 'Total Chats',
            getCellData: (jillHighLevelStatses) => `${jillHighLevelStatses.highLevelStatses.highLevelStats.numChats ?? 0}`,
            width: 1,
            sortFieldName: 'numCalls',
        },
        {
            title: 'Total Scheduled Appointments',
            getCellData: (jillHighLevelStatses) => `${jillHighLevelStatses.highLevelStatses.highLevelStats.numScheduledAppointments}`,
            width: 1,
            sortFieldName: 'numScheduledAppointments',
        },
        {
            title: 'Total Actual Call Duration',
            getCellData: (jillHighLevelStatses) => (0, helper_utils_1.getTotalActualCallDurationString)(jillHighLevelStatses),
            width: 1,
            sortFieldName: 'actualCallDuration',
        },
        {
            title: 'Total Billable Call Duration',
            getCellData: (jillHighLevelStatses) => (0, helper_utils_1.getTotalBillableCallDurationString)(jillHighLevelStatses),
            width: 1,
            sortFieldName: 'billableCallDuration',
        },
        {
            title: 'Average Efficiency per Day',
            getCellData: (jillHighLevelStatses) => `${jillHighLevelStatses.highLevelStatses.highLevelStats.averageEfficiencyPerDay.toFixed(2)}%`,
            width: 1,
            sortFieldName: 'averageEfficiencyPerDay',
        },
        {
            title: 'Average Billable Call Duration',
            getCellData: (jillHighLevelStatses) => (0, helper_utils_1.getAverageBillableCallCurationString)(jillHighLevelStatses),
            width: 1,
            sortFieldName: 'averageBillableCallDuration',
        },
        {
            title: 'Average Actual Call Duration',
            getCellData: (jillHighLevelStatses) => (0, helper_utils_1.getAverageActualCallDurationString)(jillHighLevelStatses),
            width: 1,
            sortFieldName: 'averageActualCallDuration',
        },
        {
            title: 'Average Time Between Calls',
            getCellData: (jillHighLevelStatses) => (0, helper_utils_1.getAverageTimeBetweenCallsString)(jillHighLevelStatses),
            width: 1,
            sortFieldName: 'averageTimeBetweenCalls',
        },
        {
            title: 'Average Action Item Time',
            getCellData: (jillHighLevelStatses) => (0, helper_utils_1.getAverageActionItemTimeString)(jillHighLevelStatses),
            width: 1,
            sortFieldName: 'averageActionItemTime',
        },
        {
            title: '# Flagged Action Items',
            getCellData: (jillHighLevelStatses) => jillHighLevelStatses.highLevelStatses.highLevelStats.numFlaggedActionItems,
            width: 1,
            sortFieldName: 'numFlaggedActionItems',
        },
    ];
    return columns;
}
exports.getColumns = getColumns;
