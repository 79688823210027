"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setJillUnavailable = exports.setPersonalAdminMode = void 0;
const graphql_1 = require("../../../services/graphql");
const error_handling_1 = require("../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function setPersonalAdminMode(jillId, shouldChangeToPersonalAdminMode) {
    try {
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($jillId: Int!, $shouldChangeToPersonalAdminMode: Boolean!) {
                    updateUsers(
                        input: {
                            id: $jillId
                            in_personal_admin_mode: $shouldChangeToPersonalAdminMode
                        }
                    ) {
                        id
                    }
                }
            `, {
            jillId,
            shouldChangeToPersonalAdminMode,
        });
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('startPersonalAdminMode', error);
    }
}
exports.setPersonalAdminMode = setPersonalAdminMode;
async function setJillUnavailable(jillId) {
    try {
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($jillId: Int!) {
                    updateUsers(input: {id: $jillId, is_available: false}) {
                        id
                    }
                }
            `, {
            jillId,
        });
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('setJillUnavailable', error);
    }
}
exports.setJillUnavailable = setJillUnavailable;
