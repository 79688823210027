"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.closeAllActionItemsInCategory = void 0;
const graphql_1 = require("../../services/graphql");
const error_handling_1 = require("../error-handling");
const mapped_env_variables_1 = require("../environment/mapped-env-variables");
async function closeAllActionItemsInCategory(companyId, actionItemStatusId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($companyId: Int!, $actionItemStatusId: Int) {
                    closeAllActionItemsForCompany(
                        companyId: $companyId
                        currentActionItemStatusTypeId: $actionItemStatusId
                    )
                }
            `, {
            companyId,
            actionItemStatusId,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('closeAllActionItemsInCategory', error);
    }
}
exports.closeAllActionItemsInCategory = closeAllActionItemsInCategory;
