"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../../services/utilities");
const replace_customer_name_tag_in_mesage_1 = require("../../../../utilities/chats/replace-customer-name-tag-in-message/replace-customer-name-tag-in-mesage");
const fetch_companies_1 = require("../../../../utilities/companies/fetch-companies");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
const build_try_execute_async_1 = require("../../../../utilities/error-handling/build-try-execute-async");
const convert_snake_to_camel_case_1 = require("../../../../utilities/strings/casings.ts/convert-snake-to-camel-case");
const convert_snake_to_title_case_1 = require("../../../../utilities/strings/casings.ts/convert-snake-to-title-case");
require("../../jo-button.element");
require("../../jo-input");
const constants_1 = require("./constants");
const helper_utils_1 = require("./helper-utils");
const queries_and_mutations_1 = require("./queries-and-mutations");
const styles_1 = require("./styles");
const common_1 = require("@augment-vir/common");
const executeAsync = (0, build_try_execute_async_1.buildtryExecuteAsync)(utilities_1.handleError);
class JOChatWidgetSettings extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(constants_1.InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        this.fetchAndSetCompanyInfo();
    }
    async fetchAndSetCompanyInfo() {
        (0, utilities_1.assertIsSet)(this.store.companyId);
        const companies = await executeAsync(fetch_companies_1.fetchCompaniesById, [this.store.companyId], constants_1.companyQueryShape);
        (0, common_1.assertLengthAtLeast)(companies, 1);
        const company = companies[0];
        (0, common_1.assertLengthAtLeast)(company.chat_widget_settings, 1);
        const companySettings = {
            ...company.chat_widget_settings[0],
            chat_key: company.chat_key,
            auto_responses: [...company.chat_widget_settings[0].auto_responses],
        };
        this.store.scriptSettings = { ...companySettings };
        this.store.autoResponses = companySettings.auto_responses;
    }
    async resetChatWidgetSettings() {
        await this.fetchAndSetCompanyInfo();
    }
    updateScriptSettingsInState(key, value) {
        if (this.store.scriptSettings)
            this.store.scriptSettings = {
                ...this.store.scriptSettings,
                [key]: value,
            };
    }
    async saveChatWidgetSettings() {
        this.store.saving = true;
        if (this.store.scriptSettings && this.store.companyId !== 'NOT_SET') {
            const updateResult = await (0, queries_and_mutations_1.updateChatWidgetSettings)(this.store.scriptSettings, this.store.companyId, this.store.autoResponses);
            (0, helper_utils_1.displaySaveResults)(updateResult);
        }
        else {
            throw new Error('Could not save chat widget settings: ' +
                { scriptSettings: this.store.scriptSettings, companyId: this.store.companyId });
        }
        this.store.saving = false;
    }
    createChatWidgetScript() {
        if (this.store.scriptSettings)
            return `<script defer="true" id="jillsOfficeChatWidgetScript" src="${mapped_env_variables_1.currentMappedEnvVariables.chatBotSourceFile}" data-chatkey="${this.store.scriptSettings?.chat_key}"></script>`;
        return '';
    }
    async copyButtonClicked() {
        navigator.clipboard.writeText(this.createChatWidgetScript());
    }
    createInputsForWidgetSettings(state) {
        const inputs = [
            {
                joInput: true,
                propertyName: 'title',
                type: 'TEXT_INPUT',
                breakRow: true,
            },
            {
                joInput: false,
                propertyName: 'primary_color',
                type: 'color',
            },
            {
                joInput: false,
                breakRow: true,
                propertyName: 'secondary_color',
                type: 'color',
            },
            {
                joInput: true,
                propertyName: 'font_family',
                selectOptions: constants_1.fontFamily,
                type: 'SELECT',
            },
            {
                joInput: true,
                propertyName: 'font_size',
                selectOptions: constants_1.fontSize,
                type: 'SELECT',
            },
        ];
        return (0, lit_html_1.html) `
            ${inputs.map(input => {
            if (!state.scriptSettings) {
                return (0, lit_html_1.html) ``;
            }
            if (input.joInput && input.type) {
                return this.createJoInput(input, state.scriptSettings);
            }
            return this.createCustomInput(input, state);
        })}
        `;
    }
    createCustomInput(input, state) {
        const inputId = (0, convert_snake_to_camel_case_1.convertSnakeToCamelCase)(input.propertyName);
        if (!state.scriptSettings)
            return (0, lit_html_1.html) ``;
        return (0, lit_html_1.html) `
            <div class="${(0, constants_1.cssName)('input-container')}">
                <label for="${inputId}">${(0, convert_snake_to_title_case_1.convertSnakeToTitleCase)(input.propertyName)}</label>
                <input
                    type=${input.type}
                    id=${inputId}
                    name=${inputId}
                    .value=${state.scriptSettings[input.propertyName]}
                    @input=${(e) => {
            this.updateScriptSettingsInState(input.propertyName, e.target.value);
        }}
                />
            </div>
            ${input.breakRow
            ? (0, lit_html_1.html) `
                      <div class="${(0, constants_1.cssName)('break-row')}"></div>
                  `
            : ``}
        `;
    }
    createJoInput(input, scriptSettings) {
        return (0, lit_html_1.html) `
            <jo-input
                .label=${(0, convert_snake_to_title_case_1.convertSnakeToTitleCase)(input.propertyName)}
                .type=${input.type}
                .selectOptions=${input.selectOptions}
                .inputValue=${scriptSettings[input.propertyName]}
                .selectedValue=${scriptSettings[input.propertyName]}
                @valueChanged=${(e) => {
            this.updateScriptSettingsInState(input.propertyName, e.detail.selectedValue);
        }}
                @inputchange=${(e) => {
            this.updateScriptSettingsInState(input.propertyName, e.detail.inputValue);
        }}
            ></jo-input>
            ${input.breakRow
            ? (0, lit_html_1.html) `
                      <div class="${(0, constants_1.cssName)('break-row')}"></div>
                  `
            : ``}
        `;
    }
    async displayAutoResponseDialog(title, index) {
        const isConfirmed = await (0, utilities_1.joPopUp)({
            title,
            htmlComponents: this.getHtmlAddResponseInput(index),
            confirmButtonText: 'Okay',
            includeCancelButton: true,
        });
        if (isConfirmed) {
            this.upsertMessageToAutoResponses(index);
        }
    }
    upsertMessageToAutoResponses(index) {
        const messages = this.store.autoResponses;
        if (this.store.newAutoMessageInput) {
            if (index == undefined) {
                this.createNewAutoResponse(messages);
            }
            else {
                this.updateExistingAutoResponse(index, messages);
            }
            this.store.newAutoMessageInput = '';
        }
    }
    updateExistingAutoResponse(index, messages) {
        this.store.autoResponses[index] = {
            ...messages[index],
            body: this.store.newAutoMessageInput,
        };
    }
    createNewAutoResponse(messages) {
        const position = messages.length;
        this.store.autoResponses = [
            ...messages,
            {
                body: this.store.newAutoMessageInput,
                position,
                created_at: new Date(),
            },
        ];
    }
    getHtmlAddResponseInput(index) {
        return (0, lit_html_1.html) `
            <jo-input
                .type=${'TEXT_INPUT'}
                .inputValue=${index != undefined
            ? this.store.autoResponses[index]?.body
            : this.store.newAutoMessageInput}
                @inputchange=${(e) => {
            this.store.newAutoMessageInput = e.detail.inputValue;
        }}
            ></jo-input>
        `;
    }
    createHtmlForAutoResponses() {
        const autoResponses = this.store.autoResponses;
        return (0, lit_html_1.html) `
            <h3>Custom Messages</h3>

            <div class="${(0, constants_1.cssName)('instructions')}">
                Use {customerName} to add the customers name to the message. Example: "Hi
                {customerName}!" will turn into "Hi Jill!"
            </div>

            <div class="${(0, constants_1.cssName)('message-inputs-container')} jo-global--hide-scrollbar">
                ${autoResponses.length === 0
            ? (0, lit_html_1.html) `
                          <div class="${(0, constants_1.cssName)('row')}">
                              Click the plus button to add custom messages.
                          </div>
                      `
            : autoResponses.map((message, index) => {
                return this.createHtmlForAutoResponse(index, message);
            })}
            </div>
        `;
    }
    createHtmlForAutoResponse(index, message) {
        return (0, lit_html_1.html) `
            <div class="${(0, constants_1.cssName)('row')}">
                <div
                    class="${(0, constants_1.cssName)('add-message')}"
                    @click=${async () => {
            await this.displayAutoResponseDialog('Edit Automated Response', index);
        }}
                >
                    ${message.body}
                </div>
                <span
                    title="Delete this message"
                    class="material-icons ${(0, constants_1.cssName)('delete-message-icon')}"
                    @click=${async () => {
            await this.removeAutoResponse(message);
        }}
                >
                    delete
                </span>
            </div>
        `;
    }
    async removeAutoResponse(messageToDelete) {
        const isConfirmed = await (0, utilities_1.joConfirm)('Delete Confirmation', 'Are you sure you want to delete this? Doing so will permanently delete this message.');
        if (isConfirmed) {
            const messages = this.removeMessageAndUpdatePositionOnUI(messageToDelete);
            if (messageToDelete.id != null) {
                await removeMessageAndUpdatePositionOnDatabase(messageToDelete.id, messages);
            }
            this.store.autoResponses = messages;
        }
    }
    removeMessageAndUpdatePositionOnUI(messageToDelete) {
        return this.store.autoResponses
            .filter(message => message !== messageToDelete)
            .map((message, index) => {
            return { ...message, position: index };
        });
    }
    addAutoResponseExamplesHtml() {
        const autoResponses = this.store.autoResponses;
        const scriptSettings = this.store.scriptSettings;
        return (0, lit_html_1.html) `
            ${autoResponses.length === 0 || !scriptSettings
            ? ''
            : autoResponses.map((message, index) => {
                return createDemoMessagesHtml(message, scriptSettings, index);
            })}

            <div class="${(0, constants_1.cssName)('example-first-chat')} ${(0, constants_1.cssName)('example-chat-common')}">
                Great! I'll connect you with a live agent shortly. In the meantime, is there
                anything else that you need help with today?
            </div>
        `;
    }
    render(state) {
        return !state.scriptSettings
            ? (0, lit_html_1.html) `
                  <h3>Error: No Default Settings Found</h3>
              `
            : (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

            ${styles_1.stylesForChatWidgetScriptGenerator}

            <div class="${(0, constants_1.cssName)('main-container')}">
                <div
                    class="${(0, constants_1.cssName)('card')} jo-global--card"
                >
                <h2
                    class="jo-global--card-title"
                >Customize Chat Widget</h2>

                <section class="${(0, constants_1.cssName)('row')}">
                    <div class="${(0, constants_1.cssName)('settings-container')}">
                        <div class="${(0, constants_1.cssName)('row')}">
                            ${this.createInputsForWidgetSettings(state)}
                        </div>
                        <div class="${(0, constants_1.cssName)('add-message-container')}">
                             ${this.createHtmlForAutoResponses()}
                            <div class="${(0, constants_1.cssName)('row')}">
                                <span
                                    title="Add additional auto-response message."
                                    class="material-icons ${(0, constants_1.cssName)('add-message-icon')}"
                                    @click=${async () => {
                this.store.newAutoMessageInput = '';
                await this.displayAutoResponseDialog('Add New Automated Response');
            }}
                                >add_circle</span>
                            </div>
                        </div>
                    </div>

                    <div class="${(0, constants_1.cssName)('example-container')}">
                        <div
                            class="${(0, constants_1.cssName)('example-demo')} jo-global--hide-scrollbar"
                            style="font-size: ${state.scriptSettings.font_size}; font-family: ${state.scriptSettings.font_family};"
                        >
                            <div
                                class="${(0, constants_1.cssName)('example-titlebar')}"
                                style="background-color: ${state.scriptSettings?.primary_color}; color: ${state.scriptSettings.secondary_color};"
                            >
                                <h4>${state.scriptSettings.title}</h4>
                            </div>


                            ${this.addAutoResponseExamplesHtml()}

                        </div>
                    </div>
                </section>

                <section
                    class="${(0, constants_1.cssName)('copy-code-container')}"
                >
                    <div class="${(0, constants_1.cssName)('code-container')}">${this.createChatWidgetScript()}</div>
                        <i
                            class="material-icons ${(0, constants_1.cssName)('copy-to-clipboard-icon')}"
                            @click="${async () => {
                await this.copyButtonClicked();
            }}"
                        >content_copy</i>

                    </div>
                </section>

                <div class=${(0, constants_1.cssName)('save-cancel-buttons-container')}>
                    <jo-button
                        .type=${'ACTION_BUTTON_1'}
                        .text=${state.saving === true ? 'Saving...' : 'Save'}
                        .disabled=${state.saving === true}
                        @joButtonClick=${() => this.saveChatWidgetSettings()}
                    ></jo-button>

                    <jo-button
                        style="padding-left: 1rem;"
                        .type=${'SECONDARY_BUTTON_1'}
                        .text=${'Cancel'}
                        @joButtonClick=${async () => await this.resetChatWidgetSettings()}
                    ></jo-button>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-chat-widget-settings', JOChatWidgetSettings);
function createDemoMessagesHtml(message, scriptSettings, index) {
    return (0, lit_html_1.html) `
        <div class="${(0, constants_1.cssName)('example-first-chat')} ${(0, constants_1.cssName)('example-chat-common')}">
            ${(0, replace_customer_name_tag_in_mesage_1.replaceCustomerNameTagInMessage)(message.body ?? '', 'Jill')}
        </div>

        <div
            class="${(0, constants_1.cssName)('example-second-chat')} ${(0, constants_1.cssName)('example-chat-common')}"
            style="background-color: ${scriptSettings.primary_color}; color: ${scriptSettings.secondary_color};"
        >
            Customer Response ${index + 1}
        </div>
    `;
}
async function removeMessageAndUpdatePositionOnDatabase(id, autoResponses) {
    if (await (0, queries_and_mutations_1.deleteAutoResponse)(id)) {
        if (await (0, queries_and_mutations_1.updateAutoResponsePositions)(autoResponses)) {
            return;
        }
    }
    (0, utilities_1.joAlert)('Error', 'Failed to delete auto response.');
}
