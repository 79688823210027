"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shouldHideUnreadActionItemIndicator = void 0;
const common_1 = require("@augment-vir/common");
const utilities_1 = require("../../../services/utilities");
function shouldHideUnreadActionItemIndicator(actionItem, user) {
    const userStatusEvents = [...actionItem.user_status_events].sort((eventA, eventB) => {
        return eventB.id - eventA.id;
    });
    if (user === 'NOT_SET' ||
        !user ||
        actionItemIsMarkedAsUnread(userStatusEvents) ||
        !actionItem.user_status_events ||
        !(0, common_1.isLengthAtLeast)(actionItem.action_item_notes, 1) ||
        !actionItem.action_item_notes[0].author) {
        return false;
    }
    if ((0, utilities_1.userIsJill)(user)) {
        return true;
    }
    if ((0, utilities_1.userIsJill)(actionItem.action_item_notes[0].author)) {
        return doesActionItemOpenEventMatchUserId(actionItem.user_status_events, user, actionItem.updated_at);
    }
    return true;
}
exports.shouldHideUnreadActionItemIndicator = shouldHideUnreadActionItemIndicator;
function actionItemIsMarkedAsUnread(userStatusEvents) {
    return ((0, common_1.isLengthAtLeast)(userStatusEvents, 1) &&
        userStatusEvents[0].type === 'MARKED_ACTION_ITEM_AS_UNREAD');
}
function doesActionItemOpenEventMatchUserId(userStatusEvents, user, lastUpdate) {
    return userStatusEvents.some(event => event.type === 'OPENED_ACTION_ITEM' &&
        event.user.id === user.id &&
        event.timestamp > lastUpdate);
}
