"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeDefaultEditableBusinessHours = exports.fetchCustomBusinessHoursForCompany = void 0;
const graphql_1 = require("../../../services/graphql");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
const add_data_to_error_1 = require("../../../services/logging/error-handling/add-data-to-error");
const types_for_business_hours_1 = require("../../../utilities/business-hours/types-for-business-hours");
async function fetchCustomBusinessHoursForCompany(companyId) {
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query ($companyId: Int!) {
                findBusiness_hours(filter: {company_id: {eq: $companyId}}) {
                    items {
                        id
                        day_of_week
                        open
                        close
                        created_at
                    }
                }
            }
        `, {
        companyId,
    });
    if (gqlResult.succeeded === false) {
        (0, add_data_to_error_1.addErrorDataAndThrow)(new Error('Failed to fetch custom business hours'), {
            extraData: { companyId },
        });
    }
    return gqlResult.value.data.findBusiness_hours.items;
}
exports.fetchCustomBusinessHoursForCompany = fetchCustomBusinessHoursForCompany;
function makeDefaultEditableBusinessHours() {
    const now = new Date();
    const editableBusinessHours = Object.values(types_for_business_hours_1.DayOfWeek).map((dayOfWeek, i) => {
        return {
            id: -1 - i,
            open: '06:00',
            close: '18:00',
            day_of_week: dayOfWeek,
            created_at: now.toISOString(),
            updated_at: now.toISOString(),
        };
    });
    return editableBusinessHours;
}
exports.makeDefaultEditableBusinessHours = makeDefaultEditableBusinessHours;
