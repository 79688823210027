"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchUserStatusEventsForCompany = void 0;
const graphql_1 = require("../../../../services/graphql");
const error_handling_1 = require("../../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function fetchUserStatusEventsForCompany(companyId, startDate, endDate) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!, $startDate: DateTime!, $endDate: DateTime!) {
                    findUser_status_events(
                        filter: {
                            and: [
                                {company_id: {eq: $companyId}}
                                {created_at: {ge: $startDate}}
                                {created_at: {lt: $endDate}}
                            ]
                        }
                        orderBy: {field: "timestamp", order: DESC}
                    ) {
                        items {
                            id
                            action_item {
                                id
                            }
                            company {
                                id
                                name
                            }
                            user {
                                id
                                first_name
                                last_name
                                auth_role
                            }
                            timestamp
                            type
                        }
                        total
                    }
                }
            `, {
            companyId,
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
        });
        if (gqlResult.succeeded === false) {
            return {
                succeeded: false,
                userMessage: 'Error fetchUserStatusEventsForCompany failed.',
                developerMessage: 'jo-company-user-activity-report -> fetchUserStatusEventsForCompany Query failed.',
            };
        }
        const result = gqlResult.value.data.findUser_status_events.items;
        return {
            succeeded: true,
            value: result,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchUserStatusEventsForCompany', error);
    }
}
exports.fetchUserStatusEventsForCompany = fetchUserStatusEventsForCompany;
