"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMinutesPackageSelectOptions = void 0;
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../../services/utilities");
const auth_rules_1 = require("../../../../utilities/auth-rules");
const dates_and_times_1 = require("../../../../utilities/dates-and-times/dates-and-times");
const get_start_of_billing_cycle_1 = require("../../../../utilities/dates-and-times/get-start-of-billing-cycle");
const get_start_of_next_billing_cycle_1 = require("../../../../utilities/dates-and-times/get-start-of-next-billing-cycle");
require("../../jo-input");
const mutations_1 = require("./mutations");
const queries_1 = require("./queries");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    companyId: 'NOT_SET',
    currentMinutesPackageForCompany: 'NOT_SET',
    selectedMinutesPackage: 'NOT_SET',
    allMinutesPackages: [],
    loading: false,
    saving: false,
    isMobileDevice: false,
    selectingNewMinutesPackage: false,
    minutesPackageFilter: 'NOT_SELECTED',
};
class JOBillingMinutesPackage extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        const previousCompanyId = this.store.companyId;
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        this.handleSetInitialInfo(previousCompanyId);
    }
    async handleSetInitialInfo(previousCompanyId) {
        if ((0, utilities_1.isNewCompanyId)(this.store.companyId, previousCompanyId)) {
            await this.fetchAndSetAllMinutesPackages();
        }
        this.store.selectedMinutesPackage = this.store.currentMinutesPackageForCompany;
    }
    async fetchAndSetAllMinutesPackages() {
        const allMinutesPackagesResult = await (0, queries_1.getAllMinutesPackages)();
        (0, utilities_1.assertSucceeded)(allMinutesPackagesResult, utilities_1.handleError, this, 'fetchAndSetAllMinutesPackages');
        this.store.allMinutesPackages = allMinutesPackagesResult.value;
    }
    async saveButtonClicked() {
        const shouldContinue = await (0, utilities_1.joConfirm)('Alert', "Are you sure you want to update this company's minutes package?");
        if (shouldContinue === false)
            return;
        await this.handleUpdateCurrentMinutesPackageAndInvoice();
    }
    async handleUpdateCurrentMinutesPackageAndInvoice() {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        (0, utilities_1.assertIsSet)(this.store.selectedMinutesPackage, utilities_1.handleError, 'this.store.selectedMinutesPackage');
        this.store.saving = true;
        const currentInvoiceResult = await (0, queries_1.getCurrentInvoiceForCompany)({
            companyId: this.store.companyId,
            date: new Date(),
        });
        (0, utilities_1.assertSucceeded)(currentInvoiceResult, utilities_1.handleError);
        const currentInvoice = currentInvoiceResult.value;
        if (currentInvoice === 'NOT_FOUND')
            await this.handleCreateNewInvoice(getBeginningOfTodayInMountainTime());
        else if (this.differentBillingCycleSelected())
            await this.handleChangeToPackageInDifferentBillingCycle(currentInvoice);
        else
            await this.handleChangeToNewMinutesPackageInSameBillingCycle(currentInvoice);
        this.store.saving = false;
    }
    async setNewCurrentMinutePackageForCompany() {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        (0, utilities_1.assertIsSet)(this.store.selectedMinutesPackage, utilities_1.handleError, 'this.store.selectedMinutesPackage');
        const result = await (0, mutations_1.setCurrentMinutesPackageForCompany)(this.store.companyId, this.store.selectedMinutesPackage.id);
        (0, utilities_1.assertSucceeded)(result, utilities_1.handleError, this, 'changeCurrentMinutesPackageForCompany');
    }
    differentBillingCycleSelected() {
        (0, utilities_1.assertIsSet)(this.store.selectedMinutesPackage, utilities_1.handleError, 'selectedMinutesPackage');
        (0, utilities_1.assertIsSet)(this.store.currentMinutesPackageForCompany, utilities_1.handleError, 'currentMinutesPackageForCompany');
        return (this.store.selectedMinutesPackage.billing_cycle_type !==
            this.store.currentMinutesPackageForCompany.billing_cycle_type);
    }
    async handleChangeToPackageInDifferentBillingCycle(currentInvoice) {
        (0, utilities_1.assertIsSet)(this.store.selectedMinutesPackage, utilities_1.handleError, 'selectedMinutesPackage');
        const shouldContinue = await (0, utilities_1.joConfirm)('Confirm', `You are about to change billing cycle type from ${currentInvoice.billing_cycle_type} to ` +
            `${this.store.selectedMinutesPackage.billing_cycle_type}. This will create a new invoice.` +
            ' Are you sure you want to continue?');
        if (shouldContinue === false)
            return;
        await this.setNewCurrentMinutePackageForCompany();
        await this.handleUpdateAndEndCurrentInvoice(currentInvoice);
        await this.handleSetNewEndDateOnCurrentCompanyLog();
        await this.createNewInvoiceAndUpdateUsage();
        await (0, utilities_1.joAlert)('Success!', 'The current invoice has been given a new end date, and a new invoice has been created');
        window.location.reload();
    }
    async createNewInvoiceAndUpdateUsage() {
        const newInvoice = await this.createNewInvoice(getBeginningOfTodayInMountainTime());
        const updateUsageOnNewInvoiceResult = await (0, mutations_1.calculateAndApplyUsageForInvoice)(newInvoice.id);
        (0, utilities_1.assertSucceeded)(updateUsageOnNewInvoiceResult, utilities_1.handleError);
    }
    async handleUpdateAndEndCurrentInvoice(currentInvoice) {
        const setEndDateOnCurrentInvoiceResult = await (0, mutations_1.setNewEndDateForInvoice)(currentInvoice.id, getBeginningOfTodayInMountainTime());
        (0, utilities_1.assertSucceeded)(setEndDateOnCurrentInvoiceResult, utilities_1.handleError);
        (0, utilities_1.assertIsSet)(currentInvoice.minutes_package, utilities_1.handleError, 'currentInvoice.minutes_package');
        await this.updateMonthlyFeeOrBaseFeeLineItemOnInvoice({
            billingCycleType: currentInvoice.billing_cycle_type,
            invoice: currentInvoice,
            minutesPackage: currentInvoice.minutes_package,
        });
        const updateUsageOnCurrentInvoiceResult = await (0, mutations_1.calculateAndApplyUsageForInvoice)(currentInvoice.id);
        (0, utilities_1.assertSucceeded)(updateUsageOnCurrentInvoiceResult, utilities_1.handleError);
    }
    async handleSetNewEndDateOnCurrentCompanyLog() {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'companyId');
        const currentCompanyLogResult = await (0, queries_1.getCurrentCompanyLogForCompany)({
            companyId: this.store.companyId,
            date: new Date(),
        });
        (0, utilities_1.assertSucceeded)(currentCompanyLogResult, utilities_1.handleError, this, 'handleSetNewEndDateOnCurrentCompanyLog');
        const currentCompanyLog = currentCompanyLogResult.value;
        if (currentCompanyLog !== 'NOT_FOUND') {
            await (0, mutations_1.setNewEndDateForCompanyLog)(currentCompanyLog.id, getBeginningOfTodayInMountainTime());
        }
    }
    async handleCreateNewInvoice(customStartDate) {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'companyId');
        (0, utilities_1.assertIsSet)(this.store.selectedMinutesPackage, utilities_1.handleError, 'selectedMinutesPackage');
        await this.setNewCurrentMinutePackageForCompany();
        await this.createNewInvoiceAndUpdateUsage();
        await (0, utilities_1.joAlert)('Success!', 'A new invoice has been created');
        window.location.reload();
    }
    async createNewInvoice(customStartDate) {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'companyId');
        (0, utilities_1.assertIsSet)(this.store.selectedMinutesPackage, utilities_1.handleError, 'selectedMinutesPackage');
        const createInvoiceResult = await (0, mutations_1.createInvoiceForCompany)({
            companyId: this.store.companyId,
            minutesPackage: this.store.selectedMinutesPackage,
            customStartDate,
        });
        (0, utilities_1.assertSucceeded)(createInvoiceResult, utilities_1.handleError);
        const newInvoice = createInvoiceResult.value;
        return newInvoice;
    }
    async areThereInvoicesWithBothBillingCyclesThisMonth() {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'companyId');
        const { startOfMonth, endOfMonth } = getStartAndEndOfCurrentMonth();
        const invoicesDuringCurrentMonthResult = await (0, queries_1.getInvoicesForCompanyInMonth)(this.store.companyId, startOfMonth);
        (0, utilities_1.assertSucceeded)(invoicesDuringCurrentMonthResult, utilities_1.handleError);
        const invoicesDuringCurrentMonth = invoicesDuringCurrentMonthResult.value;
        const monthlyInvoicesDuringMonth = invoicesDuringCurrentMonth.filter(v => v.billing_cycle_type === 'MONTHLY');
        const weeklyInvoicesDurintMonth = invoicesDuringCurrentMonth.filter(v => v.billing_cycle_type === 'WEEKLY');
        return weeklyInvoicesDurintMonth.length > 0 && monthlyInvoicesDuringMonth.length > 0;
    }
    async handleChangeToNewMinutesPackageInSameBillingCycle(currentInvoice) {
        (0, utilities_1.assertIsSet)(this.store.selectedMinutesPackage, utilities_1.handleError, 'selectedMinutesPackage');
        await this.setNewCurrentMinutePackageForCompany();
        const billingCycleType = this.store.selectedMinutesPackage.billing_cycle_type;
        const setNewMinutesPackageResult = await (0, mutations_1.setNewMinutesPackageOnInvoice)(currentInvoice.id, this.store.selectedMinutesPackage.id);
        (0, utilities_1.assertSucceeded)(setNewMinutesPackageResult);
        const updateUsageOnCurrentInvoiceResult = await (0, mutations_1.calculateAndApplyUsageForInvoice)(currentInvoice.id);
        (0, utilities_1.assertSucceeded)(updateUsageOnCurrentInvoiceResult, utilities_1.handleError, this, 'handleChangeToNewMinutesPackageInSameBillingCycle');
        await this.updateMonthlyFeeOrBaseFeeLineItemOnInvoice({
            billingCycleType,
            invoice: currentInvoice,
            minutesPackage: this.store.selectedMinutesPackage,
        });
        await (0, utilities_1.joAlert)('Success!', 'Minutes package updated');
        window.location.reload();
    }
    async updateMonthlyFeeOrBaseFeeLineItemOnInvoice(params) {
        const { invoice, minutesPackage, billingCycleType } = params;
        const regularFee = billingCycleType === 'WEEKLY' ? minutesPackage.base_fee : minutesPackage.recurring_fee;
        const regularFeeLineItem = getRegularFeeLineItem(invoice, billingCycleType);
        if (regularFeeLineItem) {
            const updateRegularFeeLineItemResult = await (0, mutations_1.updateLineItemAmount)(regularFeeLineItem?.id ?? 0, regularFee);
            (0, utilities_1.assertSucceeded)(updateRegularFeeLineItemResult, utilities_1.handleError, this, 'updateRegularFeeLineItemResult');
        }
    }
    render(state) {
        const isAllowedToUpdate = (0, utilities_1.isInAuthRole)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser);
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .jo-billing-minutes-package--package-detail-row {
                    display: grid;
                    grid-template-columns: ${state.isMobileDevice ? '75% 25%' : '1fr 1fr'};
                    padding-bottom: ${state.isMobileDevice ? '1rem' : '1.5rem'};
                    font-size: ${state.isMobileDevice ? 'calc(10px + 1vmin)' : ''};
                    align-items: center;
                    color: var(--jo-text-default);
                }

                .jo-billing-minutes-package--package-detail-row:hover {
                    background-color: var(--jo-primary-light);
                }
            </style>

            <jo-loading .loading=${state.loading}></jo-loading>

            <main class="w-full h-full box-border">
                <section class="jo-global--card-title">MINUTES PACKAGE</section>

                <br />

                <section ?hidden=${!isAllowedToUpdate} class=" flex justify-between">
                    <div>
                        <strong>Current Package:</strong>
                        ${state.currentMinutesPackageForCompany !== 'NOT_SET'
            ? state.currentMinutesPackageForCompany.name
            : ''}
                    </div>

                    <br />

                    <jo-button
                        ?hidden=${state.selectingNewMinutesPackage === true}
                        .type=${'ACTION_BUTTON_2'}
                        .text=${'Change Minutes Package'}
                        @joButtonClick=${() => (this.store.selectingNewMinutesPackage = true)}
                    >
                        Change Package
                    </jo-button>
                </section>

                <br />

                <section class="pb-4" ?hidden=${isAllowedToUpdate === false}>
                    ${state.selectingNewMinutesPackage === true
            ? (0, lit_html_1.html) `
                                  <jo-input
                                      .type=${'SELECT'}
                                      .label=${'Billing Cycle Type'}
                                      .selectOptions=${[
                'WEEKLY',
                'MONTHLY',
            ]}
                                      .defaultOption=${'Select billing cycle type...'}
                                      .disableDefaultOption=${true}
                                      @valueChanged=${(e) => {
                this.store.minutesPackageFilter = e.detail
                    .selectedValue;
            }}
                                  ></jo-input>
                              `
            : ''}
                    ${state.selectingNewMinutesPackage === true &&
            state.minutesPackageFilter !== 'NOT_SELECTED'
            ? (0, lit_html_1.html) `
                                  <div style="padding-top: 1rem;">
                                      <jo-input
                                          .readonly=${isAllowedToUpdate === false}
                                          .type=${'SELECT'}
                                          .label=${'Select New Minutes Package'}
                                          .selectOptions=${getMinutesPackageSelectOptions(state)}
                                          .selectedValue=${state.selectedMinutesPackage ===
                'NOT_SET'
                ? ''
                : state.selectedMinutesPackage.name}
                                          .defaultOption=${'Please select a minutes package...'}
                                          .disableDefaultOption=${true}
                                          @inputchange=${(e) => {
                this.store.selectedMinutesPackage =
                    this.store.allMinutesPackages.filter(minutesPackage => minutesPackage.name ===
                        e.detail.selectedValue)[0] ?? 'NOT_SET';
            }}
                                      ></jo-input>
                                  </div>
                              `
            : ''}

                    <div style="padding-top: 1rem; padding-bottom: 1rem;">
                        <jo-button
                            ?hidden=${state.selectingNewMinutesPackage === false}
                            .type=${'ACTION_BUTTON_2'}
                            .text=${'Cancel'}
                            @click=${() => {
            this.store.selectingNewMinutesPackage = false;
            this.store.minutesPackageFilter = 'NOT_SELECTED';
            this.store.selectedMinutesPackage =
                this.store.currentMinutesPackageForCompany;
        }}
                            >Cancel</jo-button
                        >
                    </div>
                </section>

                <section>
                    <div class="jo-billing-minutes-package--package-detail-row">
                        <div><strong>Name:</strong></div>
                        <div>
                            ${getMinutesPackageValue(state.selectedMinutesPackage, 'user_display_name')}
                        </div>
                    </div>

                    <div class="jo-billing-minutes-package--package-detail-row">
                        <div><strong>Billing Cycle Type:</strong></div>
                        <div>
                            ${getMinutesPackageValue(state.selectedMinutesPackage, 'billing_cycle_type')}
                        </div>
                    </div>

                    <div
                        class="jo-billing-minutes-package--package-detail-row"
                        ?hidden=${state.selectedMinutesPackage !== 'NOT_SET' &&
            state.selectedMinutesPackage.billing_cycle_type === 'WEEKLY'}
                    >
                        <div><strong>Recurring Fee:</strong></div>
                        <div>
                            ${getMinutesPackageValue(state.selectedMinutesPackage, 'recurring_fee')}
                        </div>
                    </div>

                    <div class="jo-billing-minutes-package--package-detail-row">
                        <div><strong>Number of Minutes Included:</strong></div>
                        <div>
                            ${getMinutesPackageValue(state.selectedMinutesPackage, 'num_minutes_included')}
                        </div>
                    </div>

                    <div class="jo-billing-minutes-package--package-detail-row">
                        <div><strong>Price Per Minute:</strong></div>
                        <div>
                            ${getMinutesPackageValue(state.selectedMinutesPackage, 'price_per_minute')}
                        </div>
                    </div>

                    <div
                        class="jo-billing-minutes-package--package-detail-row"
                        ?hidden=${state.selectedMinutesPackage !== 'NOT_SET' &&
            state.selectedMinutesPackage.billing_cycle_type === 'MONTHLY'}
                    >
                        <div><strong>Total Weekly Recurring Price:</strong></div>
                        <div>${getTotalWeeklyRecurringPrice(state)}</div>
                    </div>

                    <div
                        ?hidden=${state.isMobileDevice}
                        class="jo-billing-minutes-package--package-detail-row"
                    >
                        <div><strong>Maximum Billable Minutes Per Call:</strong></div>
                        <div>
                            ${getMinuesCalcOptionValue(state.selectedMinutesPackage, 'max_billable_milliseconds')}
                        </div>
                    </div>

                    <div
                        ?hidden=${state.isMobileDevice}
                        class="jo-billing-minutes-package--package-detail-row"
                    >
                        <div><strong>Max Billable Action Item Time Per Call:</strong></div>
                        <div>
                            ${getMinuesCalcOptionValue(state.selectedMinutesPackage, 'max_billable_action_item_milliseconds')}
                        </div>
                    </div>

                    <div
                        ?hidden=${state.isMobileDevice}
                        class="jo-billing-minutes-package--package-detail-row"
                    >
                        <div><strong>Minimum Time Call Needs To Be Billed:</strong></div>
                        <div>
                            ${getMinuesCalcOptionValue(state.selectedMinutesPackage, 'min_milliseconds_call_needs_to_be_billable')}
                        </div>
                    </div>

                    <div class="jo-billing-minutes-package--package-detail-row">
                        <div><strong>Minimum Time Billed Per Call:</strong></div>
                        <div>
                            ${getMinuesCalcOptionValue(state.selectedMinutesPackage, 'min_billable_milliseconds')}
                        </div>
                    </div>
                    <div
                        class="jo-billing-minutes-package--package-detail-row"
                        class="jo-billing-minutes-package--package-detail-row"
                        ?hidden=${shouldHideBasePlan(state)}
                    >
                            <strong>Base Plan:</strong>
                            ${getMinutesPackageValue(state.selectedMinutesPackage, 'base_fee')}

                        </div>
                        </div>
                    </div>
                </section>

                <br ?hidden=${state.isMobileDevice} /><br />

                <section ?hidden=${isAllowedToUpdate === false}>
                    <jo-button
                        .type=${'ACTION_BUTTON_1'}
                        .text=${state.saving === true ? 'Saving...' : 'Save'}
                        .disabled=${state.saving === true}
                        .inlineStyle=${'font-size: 0.9rem; padding: 0.5rem 0.75rem'}
                        @joButtonClick=${() => this.saveButtonClicked()}
                    ></jo-button>
                </section>
            </main>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-billing-minutes-package', JOBillingMinutesPackage);
function getRegularFeeLineItem(currentInvoice, billingCycleType) {
    return currentInvoice.invoice_line_items.find(lineItem => {
        if (billingCycleType === 'WEEKLY')
            return lineItem.description === 'Base Plan';
        return lineItem.description === 'Monthly Fee';
    });
}
function getBeginningOfTodayInMountainTime() {
    return (0, dates_and_times_1.addMountainTimeOffset)(new Date(getBeginningOfToday().toISOString()));
}
function getStartAndEndOfCurrentMonth() {
    return {
        startOfMonth: (0, get_start_of_billing_cycle_1.getStartOfBillingCycleInMountainTime)(new Date(), 'MONTHLY'),
        endOfMonth: (0, get_start_of_next_billing_cycle_1.getStartOfNextBillingCycleInMountainTime)(new Date(), 'MONTHLY'),
    };
}
function getMinutesPackageSelectOptions(state) {
    return state.allMinutesPackages
        .filter(minutesPackage => {
        if (state.minutesPackageFilter === 'WEEKLY') {
            return minutesPackage.billing_cycle_type === 'WEEKLY';
        }
        if (state.minutesPackageFilter === 'MONTHLY') {
            return minutesPackage.billing_cycle_type === 'MONTHLY';
        }
        return true;
    })
        .map(minutesPackage => minutesPackage.name)
        .sort();
}
exports.getMinutesPackageSelectOptions = getMinutesPackageSelectOptions;
function getTotalWeeklyRecurringPrice(state) {
    if (state.selectedMinutesPackage === 'NOT_SET' || state.selectedMinutesPackage === undefined) {
        return '';
    }
    const { price_per_minute, num_minutes_included, base_fee } = state.selectedMinutesPackage;
    const totalInCents = calculateWeeklyPackageTotalInCents(base_fee, price_per_minute, num_minutes_included);
    return (0, utilities_1.formatUSDCentsForDisplay)(totalInCents);
}
function calculateWeeklyPackageTotalInCents(base_fee, price_per_minute, num_minutes_included) {
    const totalInDollars = calculateWeeklyPackageTotalInDollars(base_fee, price_per_minute, num_minutes_included);
    const totalInCents = totalInDollars * 100;
    return totalInCents;
}
function calculateWeeklyPackageTotalInDollars(base_fee, price_per_minute, num_minutes_included) {
    const baseFeeInDollars = base_fee / 100;
    const pricePerMinuteInDollars = price_per_minute / 100;
    const totalInDollars = pricePerMinuteInDollars * num_minutes_included + baseFeeInDollars;
    return totalInDollars;
}
function getMinutesPackageValue(minutesPackage, field) {
    if (minutesPackage === 'NOT_SET')
        return '';
    const options = {
        num_minutes_included: minutesPackage.num_minutes_included,
        price_per_minute: (0, utilities_1.formatUSDCentsForDisplay)(minutesPackage.price_per_minute),
        recurring_fee: (0, utilities_1.formatUSDCentsForDisplay)(minutesPackage.recurring_fee),
        user_display_name: minutesPackage.user_display_name,
        base_fee: (0, utilities_1.formatUSDCentsForDisplay)(minutesPackage.base_fee),
        billing_cycle_type: titleCase(minutesPackage.billing_cycle_type),
    };
    return options[field] ?? 'NOT_FOUND';
}
function getMinuesCalcOptionValue(minutesPackage, field) {
    if (minutesPackage === 'NOT_SET')
        return '';
    return (0, utilities_1.millisecondsToHoursMinutesSeconds)(minutesPackage.minutes_calculation_option[field]);
}
function getBeginningOfToday() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
}
function titleCase(str) {
    return str.replace(/\w\S*/g, txt => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}
function shouldHideBasePlan(state) {
    if ((0, auth_rules_1.isCompanyUser)(state.authenticatedUser) ||
        state.selectedMinutesPackage === 'NOT_SET' ||
        state.selectedMinutesPackage.billing_cycle_type === 'MONTHLY') {
        return true;
    }
    return false;
}
