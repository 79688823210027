"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScheduledDownTimeAdapter = void 0;
class ScheduledDownTimeAdapter {
    constructor(scheduledDownTime) {
        if (!scheduledDownTime) {
            this._id = Math.floor(Math.random() * -1000000);
            this._startTime = new Date();
            this._endTime = new Date();
            this._mode = 'NEW';
        }
        else {
            this._id = scheduledDownTime.id;
            this._startTime = new Date(scheduledDownTime.start_time);
            this._endTime = new Date(scheduledDownTime.end_time);
            this._mode = 'EXISTING';
        }
    }
    get mode() {
        return this._mode;
    }
    get startTime() {
        return this._startTime;
    }
    get endTime() {
        return this._endTime;
    }
    get id() {
        return this._id;
    }
}
exports.ScheduledDownTimeAdapter = ScheduledDownTimeAdapter;
