"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOGoogleCalendarsForCompany = void 0;
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../../../services/utilities");
const css_utils_1 = require("../../../../../utilities/css-utils");
const define_custom_element_1 = require("../../../../../utilities/define-custom-element");
require("../../../jo-button.element");
require("../../../jo-input");
const helper_utils_1 = require("./helper-utils");
const mutations_1 = require("./mutations");
const queries_1 = require("./queries");
const InitialState = {
    companyId: 'NOT_SET',
    companyCalendars: [],
    authenticatedUser: 'NOT_SET',
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-google-calendars-for-company');
class JOGoogleCalendarsForCompany extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    set companyId(companyId) {
        this.store.companyId = companyId;
        if (companyId !== 'NOT_SET') {
            this.triggerTableReload();
        }
    }
    async fetchAndSetGoogleCalendars() {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        const googleCalendarResult = await (0, queries_1.fetchGoogleCalendarsForCompany)(this.store.companyId);
        (0, utilities_1.assertSucceeded)(googleCalendarResult);
        this.store.companyCalendars = googleCalendarResult.value;
    }
    async makeGoogleCalendarVisible(calendarRecordId) {
        if (this.store.companyId === 'NOT_SET') {
            return;
        }
        await (0, mutations_1.updateCalendarAttribute)(this.store.companyId, calendarRecordId, {
            type: 'VISIBILITY',
            value: true,
        });
    }
    async hideGoogleCalendar(calendarRecordId) {
        if (this.store.companyId === 'NOT_SET') {
            return;
        }
        await (0, mutations_1.updateCalendarAttribute)(this.store.companyId, calendarRecordId, {
            type: 'VISIBILITY',
            value: false,
        });
    }
    async giveAccessToDeleteEvents(calendarRecordId) {
        if (this.store.companyId === 'NOT_SET') {
            return;
        }
        await (0, mutations_1.updateCalendarAttribute)(this.store.companyId, calendarRecordId, {
            type: 'DELETE_EVENT',
            value: true,
        });
    }
    async removeAccessToDeleteEvents(calendarRecordId) {
        if (this.store.companyId === 'NOT_SET') {
            return;
        }
        await (0, mutations_1.updateCalendarAttribute)(this.store.companyId, calendarRecordId, {
            type: 'DELETE_EVENT',
            value: false,
        });
    }
    async giveAccessToRescheduleEvent(calendarRecordId) {
        if (this.store.companyId === 'NOT_SET') {
            return;
        }
        await (0, mutations_1.updateCalendarAttribute)(this.store.companyId, calendarRecordId, {
            type: 'RESCHEDULE_EVENT',
            value: true,
        });
    }
    async removeAccessToRescheduleEvent(calendarRecordId) {
        if (this.store.companyId === 'NOT_SET') {
            return;
        }
        await (0, mutations_1.updateCalendarAttribute)(this.store.companyId, calendarRecordId, {
            type: 'RESCHEDULE_EVENT',
            value: false,
        });
    }
    async triggerTableReload() {
        if (this.store.companyId === 'NOT_SET') {
            return;
        }
        await (0, mutations_1.syncGoogleCalendarsForCompany)(this.store.companyId);
        this.fetchAndSetGoogleCalendars();
    }
    getSortedGoogleCalendars() {
        return [...this.store.companyCalendars].sort((a, b) => {
            const nameA = a.google_calendar_name.toUpperCase();
            const nameB = b.google_calendar_name.toUpperCase();
            return nameA.localeCompare(nameB);
        });
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

            <style>
                .${cssName('main-container')} {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    padding-right: 2rem;
                    padding-left: 2rem;
                }
            </style>

            <div class="${cssName('main-container')}">
                ${(0, utilities_1.isUserAMemberOfJillsOffice)(state.authenticatedUser)
            ? (0, lit_html_1.html) `
                          <div class="jo-global--card">
                              <jo-table
                                  .tableName=${'Google Calendars'}
                                  .maxItemsPerPage=${10}
                                  .manuallySuppliedItems=${this.getSortedGoogleCalendars()}
                                  .totalItems=${state.companyCalendars.length}
                                  .columns=${(0, helper_utils_1.getColumnsToShowForJills)(this)}
                                  .showSearchBar=${false}
                                  .showReloadButton=${true}
                                  @triggerTableReload=${() => {
                this.triggerTableReload();
            }}
                              ></jo-table>
                          </div>
                      `
            : ''}
                ${(0, utilities_1.getIsCompanyOwner)(state.authenticatedUser, state.companyId)
            ? (0, lit_html_1.html) `
                          <div class="jo-global--card">
                              <jo-table
                                  .tableName=${'Google Calendars'}
                                  .maxItemsPerPage=${10}
                                  .manuallySuppliedItems=${this.getSortedGoogleCalendars()}
                                  .totalItems=${state.companyCalendars.length}
                                  .columns=${(0, helper_utils_1.getColumnsToShowForCompanyOwner)(this)}
                                  .showSearchBar=${false}
                                  .showReloadButton=${true}
                                  @triggerTableReload=${() => {
                this.triggerTableReload();
            }}
                              ></jo-table>
                          </div>
                      `
            : ''}
            </div>
        `;
    }
}
exports.JOGoogleCalendarsForCompany = JOGoogleCalendarsForCompany;
(0, define_custom_element_1.defineCustomElement)('jo-google-calendars-for-company', JOGoogleCalendarsForCompany);
