"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoCompanyCustomHours = void 0;
const element_vir_1 = require("element-vir");
const utilities_1 = require("../../../services/utilities");
const sort_business_hours_by_day_of_week_1 = require("../../../utilities/business-hours/sort-business-hours-by-day-of-week");
const mutations_for_company_custom_hours_1 = require("./mutations-for-company-custom-hours");
const queries_for_company_custom_hours_1 = require("./queries-for-company-custom-hours");
const common_1 = require("@augment-vir/common");
const jo_company_editable_business_hour_element_1 = require("./jo-company-editable-business-hour.element");
async function fetchBusinessHoursWithEdits(companyId) {
    const customBusinessHoursResult = await (0, queries_for_company_custom_hours_1.fetchCustomBusinessHoursForCompany)(companyId);
    return {
        originalBusinessHours: customBusinessHoursResult,
        editedBusinessHours: (0, common_1.copyThroughJson)(customBusinessHoursResult),
    };
}
exports.JoCompanyCustomHours = (0, element_vir_1.defineElement)()({
    tagName: 'jo-company-custom-hours',
    stateInit: {
        businessHours: (0, element_vir_1.asyncState)(),
    },
    styles: (0, element_vir_1.css) `
        .save-row {
            padding-top: 1rem;
            display: flex;
            gap: 1rem;
        }
    `,
    renderCallback({ state, updateState, inputs }) {
        /** Saved to an internal variable for type checking within callback purposes. */
        const businessHoursWithEdits = state.businessHours;
        function isReadyForEdits(input) {
            return (0, element_vir_1.isRenderReady)(input) && !!input;
        }
        updateState({
            businessHours: {
                async createPromise() {
                    if (inputs.companyId == undefined) {
                        return undefined;
                    }
                    return await fetchBusinessHoursWithEdits(inputs.companyId);
                },
                trigger: inputs.companyId,
            },
        });
        if (!isReadyForEdits(businessHoursWithEdits)) {
            return (0, element_vir_1.html) `
                Loading...
            `;
        }
        function updateTimeOnEditableBusinessHour({ businessHourToUpdate, field, value, }) {
            if (!isReadyForEdits(businessHoursWithEdits)) {
                return;
            }
            const updatedBusinessHour = {
                ...businessHourToUpdate,
                [field]: value,
            };
            const remainingBusinessHours = businessHoursWithEdits.editedBusinessHours.filter(editableBusinessHour => editableBusinessHour.id !== businessHourToUpdate.id);
            const updatedBusinessHours = [
                updatedBusinessHour,
                ...remainingBusinessHours,
            ];
            const orderedBusinessHours = (0, sort_business_hours_by_day_of_week_1.sortBusinessHoursByDayOfWeek)(updatedBusinessHours);
            updateState({
                businessHours: {
                    resolvedValue: {
                        ...businessHoursWithEdits,
                        editedBusinessHours: orderedBusinessHours,
                    },
                },
            });
        }
        async function deleteAllBusinessHours() {
            const shouldContinue = await (0, utilities_1.joConfirm)('Are you sure?', 'Are you sure you want to delete all custom hours for this company?');
            if (shouldContinue === false) {
                return;
            }
            (0, utilities_1.assertIsSet)(inputs.companyId);
            const deleteResult = await (0, mutations_for_company_custom_hours_1.deleteBusinessHoursForCompany)(inputs.companyId);
            (0, utilities_1.assertSucceeded)(deleteResult);
            updateState({
                businessHours: {
                    newPromise: fetchBusinessHoursWithEdits(inputs.companyId),
                },
            });
            await (0, utilities_1.joAlert)('Success', 'Custom hours deleted for company');
        }
        async function saveAllHourEdits() {
            if (inputs.companyId == undefined || !isReadyForEdits(businessHoursWithEdits)) {
                return;
            }
            const updateResult = await (0, mutations_for_company_custom_hours_1.setBusinessHoursForCompany)(inputs.companyId, businessHoursWithEdits.editedBusinessHours);
            (0, utilities_1.assertSucceeded)(updateResult);
            updateState({
                businessHours: {
                    newPromise: fetchBusinessHoursWithEdits(inputs.companyId),
                },
            });
            (0, utilities_1.joAlert)('Success', 'Custom hours saved for company');
        }
        const businessHourTemplates = businessHoursWithEdits.editedBusinessHours.map(businessHours => (0, element_vir_1.html) `
                    <${jo_company_editable_business_hour_element_1.JoCompanyEditableBusinessHour}
                        ${(0, element_vir_1.assign)(jo_company_editable_business_hour_element_1.JoCompanyEditableBusinessHour, {
            businessHours,
        })}
                        ${(0, element_vir_1.listen)(jo_company_editable_business_hour_element_1.JoCompanyEditableBusinessHour.events.hoursChange, event => {
            updateTimeOnEditableBusinessHour({
                businessHourToUpdate: businessHours,
                ...event.detail,
            });
        })}
                    ></${jo_company_editable_business_hour_element_1.JoCompanyEditableBusinessHour}>
                `);
        return (0, element_vir_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />

            ${(0, element_vir_1.renderIf)(!businessHoursWithEdits.editedBusinessHours.length, (0, element_vir_1.html) `
                    <h2>No Custom Hours Set</h2>

                    <jo-button
                        .text=${'Create Custom Hours'}
                        @joButtonClick=${() => {
            if (!isReadyForEdits(businessHoursWithEdits)) {
                return;
            }
            const newBusinessHours = (0, queries_for_company_custom_hours_1.makeDefaultEditableBusinessHours)();
            updateState({
                businessHours: {
                    resolvedValue: {
                        ...businessHoursWithEdits,
                        editedBusinessHours: newBusinessHours,
                    },
                },
            });
        }}
                    ></jo-button>
                `)}
            ${businessHourTemplates}
            ${(0, element_vir_1.renderIf)(!!businessHoursWithEdits.editedBusinessHours, (0, element_vir_1.html) `
                    <h4>Note: These hours are in Mountain Time Zone (MT)</h4>
                    <div class="save-row">
                        <jo-button
                            .text=${'Save'}
                            @joButtonClick=${async () => await saveAllHourEdits()}
                        ></jo-button>

                        ${businessHoursWithEdits.editedBusinessHours.some(editableHour => editableHour.id < 0)
            ? (0, element_vir_1.html) `
                                  <jo-button
                                      .text=${'Cancel'}
                                      .type=${'SECONDARY_BUTTON_1'}
                                      @joButtonClick=${() => {
                updateState({
                    businessHours: {
                        resolvedValue: {
                            ...businessHoursWithEdits,
                            editedBusinessHours: businessHoursWithEdits.originalBusinessHours,
                        },
                    },
                });
            }}
                                  ></jo-button>
                              `
            : (0, element_vir_1.html) `
                                  <jo-button
                                      .text=${'Delete All'}
                                      .type=${'DELETE_BUTTON_1'}
                                      @joButtonClick=${async () => await deleteAllBusinessHours()}
                                  ></jo-button>
                              `}
                    </div>
                `)}
        `;
    },
});
