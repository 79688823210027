"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addAllContactsFromCSVToCompany = exports.getContacts = void 0;
const graphql_1 = require("../../../../services/graphql");
const error_handling_1 = require("../../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function getContacts(offset, limit, searchText, companyId) {
    if (companyId === 'NOT_SET') {
        return {
            items: [],
            totalItems: 0,
        };
    }
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query (
                $offset: Int!
                $limit: Int!
                $ignoreSearch: Boolean!
                $companyId: Int!
                $searchText: String!
            ) {
                searchContacts(
                    page: {offset: $offset, limit: $limit}
                    ignoreSearch: $ignoreSearch
                    companyId: $companyId
                    searchText: $searchText
                    excludeEmptyContacts: true
                ) {
                    contacts {
                        id
                        addresses {
                            id
                            line_1
                            line_2
                            city
                            county
                            postal_code
                            province
                            state
                            zip_code
                        }
                        company_name
                        created_at
                        email
                        first_name
                        last_name
                        phone_number
                        phone_numbers(orderBy: {field: "created_at", order: ASC}) {
                            id
                            number
                            created_at
                        }
                        updated_at
                    }
                    total
                }
            }
        `, {
        offset,
        limit,
        ignoreSearch: searchText === '',
        companyId,
        searchText,
    });
    // TODO the jo-table getItems function should return a GQLResult
    if (gqlResult.succeeded === false) {
        alert(gqlResult.userMessage);
        throw new Error(gqlResult.developerMessage);
    }
    const contacts = gqlResult.value.data.searchContacts.contacts;
    return {
        items: contacts,
        totalItems: gqlResult.value.data.searchContacts.total,
    };
}
exports.getContacts = getContacts;
async function addAllContactsFromCSVToCompany(companyId, contactsToImport) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($companyId: Int!, $contactsToImport: [String!]!) {
                    createMultipleContactsForCompany(
                        companyId: $companyId
                        contactsToImport: $contactsToImport
                    )
                }
            `, {
            companyId,
            contactsToImport,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        const contactIds = gqlResult.value.data.createMultipleContactsForCompany;
        return {
            succeeded: true,
            value: contactIds,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('addAllContactsFromCSVToCompany', error);
    }
}
exports.addAllContactsFromCSVToCompany = addAllContactsFromCSVToCompany;
