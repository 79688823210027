"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultPriorityLevel = exports.QueueStatusBoxType = exports.QueueStatusBoxFlashLevel = void 0;
exports.QueueStatusBoxFlashLevel = {
    NONE: 'NONE',
    LOW: 'LOW',
    MEDIUM: 'MEDIUM',
    HIGH: 'HIGH',
};
exports.QueueStatusBoxType = {
    INCOMING_CALLS: 'INCOMING_CALLS',
    INCOMING_CHATS: 'INCOMING_CHATS',
};
exports.defaultPriorityLevel = {
    background_color: '#000000',
    flash_level: exports.QueueStatusBoxFlashLevel.NONE,
    text_color: '#000000',
    highest_pod_count: 0,
    total_queue_items: 0,
    queue_type: exports.QueueStatusBoxType.INCOMING_CALLS,
};
