"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeTriggerButtonHtmlForAdminModeForCompany = exports.buildFormDataObject = exports.buildAndSendAudioRecordingRequest = exports.uploadAudioRecording = exports.formatFileName = exports.getCalendarId = exports.getPhoneServiceId = exports.updateCompany = exports.areThereAnyFormErrors = exports.getPod = exports.getAccountManagerFromName = exports.fetchCompany = void 0;
const lit_html_1 = require("lit-html");
const utilities_1 = require("../../../services/utilities");
const is_in_admin_mode_1 = require("../../../utilities/admin-utils/is-in-admin-mode");
const set_admin_mode_for_user_1 = require("../../../utilities/admin-utils/set-admin-mode-for-user");
const error_handling_1 = require("../../../utilities/error-handling");
const user_status_event_mutations_1 = require("../../../utilities/logging-and-monitoring/user-status-event-mutations");
const update_work_status_1 = require("../../../utilities/update-work-status");
require("../jo-button.element");
const mutations_1 = require("./mutations");
const queries_1 = require("./queries");
async function fetchCompany(companyId, authenticatedUser) {
    try {
        const companyDetailsBaseInfoResult = await (0, queries_1.fetchCompanyDetailsBaseInfo)(companyId);
        if (companyDetailsBaseInfoResult.succeeded === false) {
            return companyDetailsBaseInfoResult;
        }
        const companyDetailsBillingInfoResult = await (0, queries_1.fetchCompanyDetailsBillingInfo)(companyId, authenticatedUser);
        if (companyDetailsBillingInfoResult.succeeded === false) {
            return companyDetailsBillingInfoResult;
        }
        const companyDetails = {
            ...companyDetailsBaseInfoResult.value,
            ...companyDetailsBillingInfoResult.value,
        };
        return {
            succeeded: true,
            value: companyDetails,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchCompany', error);
    }
}
exports.fetchCompany = fetchCompany;
function getAccountManagerFromName(accountManagerFullName, accountManagers) {
    if (accountManagers === 'NOT_SET') {
        return undefined;
    }
    const nameParts = accountManagerFullName.split(' ');
    const firstName = nameParts[0];
    const lastName = nameParts[1];
    return accountManagers.filter((accountManager) => {
        return firstName === accountManager.first_name && lastName === accountManager.last_name;
    })[0];
}
exports.getAccountManagerFromName = getAccountManagerFromName;
function getPod(podName, pods) {
    return pods === 'NOT_SET'
        ? undefined
        : pods.filter((pod) => pod.name === podName)[0];
}
exports.getPod = getPod;
function areThereAnyFormErrors(companyBillingInfoInputValues, companyContactInfoInputValues, companyJillnInfoInputValues) {
    if (companyBillingInfoInputValues.formErrors.length > 0 ||
        companyContactInfoInputValues.formErrors.length > 0 ||
        companyJillnInfoInputValues.formErrors.length > 0) {
        return true;
    }
    return false;
}
exports.areThereAnyFormErrors = areThereAnyFormErrors;
async function updateCompany(params) {
    try {
        const { authenticatedUser, company, accountManagerId, personalAdminId, phoneServiceIdToConnect, phoneServiceIdToDisconnect, calendarIdToConnect, calendarIdToDisconnect, podId, companyBillingInfoInputValues, companyContactInfoInputValues, companyJillnInfoInputValues, internalNotes, } = params;
        const upsertCompanyMainInfoResult = await (0, mutations_1.updateCompanyMainInfo)({
            company,
            accountManagerId,
            personalAdminId,
            phoneServiceIdToConnect,
            phoneServiceIdToDisconnect,
            calendarIdToConnect,
            calendarIdToDisconnect,
            podId,
            companyContactInfoInputValues,
            companyJillnInfoInputValues,
            internalNotes,
        });
        if (upsertCompanyMainInfoResult.succeeded === false) {
            return upsertCompanyMainInfoResult;
        }
        const updateOwnerPhoneNumberResult = await (0, mutations_1.updateOwnerPhoneNumber)(company, companyContactInfoInputValues.ownerPhoneNumber);
        if (updateOwnerPhoneNumberResult.succeeded === false) {
            return updateOwnerPhoneNumberResult;
        }
        const updateCompanyAddressResult = await (0, mutations_1.updateCompanyAddress)(company, companyContactInfoInputValues.addressLine1InputValue, companyContactInfoInputValues.addressLine2InputValue, companyContactInfoInputValues.cityInputValue, companyContactInfoInputValues.mailingStateInputValue, companyContactInfoInputValues.zipCodeInputValue);
        if (updateCompanyAddressResult.succeeded === false) {
            return updateCompanyAddressResult;
        }
        if ((0, utilities_1.isInAuthRole)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], authenticatedUser) === false) {
            return { succeeded: true };
        }
        const upsertCompanyBillingInfoResult = await (0, mutations_1.updateCompanyBillingInfo)(authenticatedUser, company.id, companyBillingInfoInputValues);
        if (upsertCompanyBillingInfoResult.succeeded === false) {
            return upsertCompanyBillingInfoResult;
        }
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('updateCompany', error);
    }
}
exports.updateCompany = updateCompany;
async function getPhoneServiceId(phoneServiceName, otherPhoneServiceName, phoneServices) {
    if (phoneServiceName === 'Other') {
        const newPhoneServiceId = await (0, mutations_1.createPhoneService)(otherPhoneServiceName);
        (0, utilities_1.assertSucceeded)(newPhoneServiceId, utilities_1.handleError);
        return newPhoneServiceId.value;
    }
    const phoneService = phoneServices.filter(phoneService => phoneService.name === phoneServiceName)[0];
    if (phoneService === undefined)
        return null;
    return phoneService.id;
}
exports.getPhoneServiceId = getPhoneServiceId;
async function getCalendarId(calendarName, otherCalendarName, otherCalendarUrl, calendars) {
    if (calendarName === 'Other') {
        const newCalendarId = await (0, mutations_1.createCalendar)(otherCalendarName, otherCalendarUrl);
        (0, utilities_1.assertSucceeded)(newCalendarId, utilities_1.handleError);
        return newCalendarId.value;
    }
    const calendar = calendars.filter(calendar => calendar.name === calendarName)[0];
    if (calendar === undefined)
        return null;
    return calendar.id;
}
exports.getCalendarId = getCalendarId;
function formatFileName(audioFile) {
    return new File([audioFile], removeIncompatibleCharactersForTwilio(audioFile.name), {
        type: audioFile.type,
    });
}
exports.formatFileName = formatFileName;
function removeIncompatibleCharactersForTwilio(fileName) {
    return fileName.replace(/\[|\]|\s/g, '');
}
async function uploadAudioRecording(companyId, audioFile, type) {
    try {
        const response = await buildAndSendAudioRecordingRequest(companyId, audioFile, type);
        if (response.status !== 200) {
            return {
                succeeded: false,
                userMessage: 'Error updating audio',
                developerMessage: `Error updating audio: ${response.text()}`,
            };
        }
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('updateVoicemailAudio', error);
    }
}
exports.uploadAudioRecording = uploadAudioRecording;
async function buildAndSendAudioRecordingRequest(companyId, audioFile, type) {
    try {
        const formData = buildFormDataObject(companyId, audioFile);
        const path = `/services/upload-${type.toLowerCase()}-audio-for-company`;
        const response = await (0, utilities_1.sendRouterRequest)(path, formData);
        return response;
    }
    catch (error) {
        throw new Error(`buildAndSendRequest error: ${error}`);
    }
}
exports.buildAndSendAudioRecordingRequest = buildAndSendAudioRecordingRequest;
function buildFormDataObject(companyId, audioFile) {
    const formData = new FormData();
    formData.append('companyId', companyId.toString());
    formData.append('file', audioFile);
    return formData;
}
exports.buildFormDataObject = buildFormDataObject;
function makeTriggerButtonHtmlForAdminModeForCompany(authenticatedUser, companyId) {
    if (companyId === 'NOT_SET' ||
        authenticatedUser === 'NOT_SET' ||
        authenticatedUser.is_personal_admin === false ||
        isInAdminModeForCompany(authenticatedUser, companyId)) {
        return (0, lit_html_1.html) ``;
    }
    if (authenticatedUser.in_personal_admin_mode === true) {
        return (0, lit_html_1.html) `
            <div style="padding: 1rem">
                <jo-button
                    .type=${'DELETE_BUTTON_1'}
                    .text=${'End Admin Mode for Company'}
                    @joButtonClick=${() => endAdminModeForCompany({
            userId: authenticatedUser.id,
            companyId,
        })}
                ></jo-button>
            </div>
        `;
    }
    return (0, lit_html_1.html) `
        <div style="padding: 1rem">
            <jo-button
                .type=${'ACTION_BUTTON_GREEN'}
                .text=${'Begin Admin Mode for Company'}
                @joButtonClick=${() => beginAdminModeForCompany({
        userId: authenticatedUser.id,
        companyId,
    })}
            ></jo-button>
        </div>
    `;
}
exports.makeTriggerButtonHtmlForAdminModeForCompany = makeTriggerButtonHtmlForAdminModeForCompany;
function isInAdminModeForCompany(authenticatedUser, companyId) {
    if (authenticatedUser.user_status_events === undefined ||
        authenticatedUser.user_status_events === null) {
        return false;
    }
    return ((0, is_in_admin_mode_1.isInAdminMode)(authenticatedUser) &&
        authenticatedUser?.user_status_events[0]?.company?.id !== companyId);
}
async function beginAdminModeForCompany(params) {
    try {
        const { companyId, userId } = params;
        await (0, user_status_event_mutations_1.createUserStatusEvent)({
            userId: userId,
            eventType: 'BEGAN_ADMIN_MODE',
            timestamp: new Date(),
            companyId: companyId,
        });
        await (0, update_work_status_1.updateWorkStatus)(userId, 'IN_ADMIN_MODE');
        await (0, set_admin_mode_for_user_1.setAdminModeForUser)({
            userId,
            shouldBeInAdminMode: true,
        });
    }
    catch (error) {
        (0, utilities_1.handleError)(`beginAdminModeForCompany error: ${error}`);
        throw new Error(`beginAdminModeForCompany error: ${error}`);
    }
}
async function endAdminModeForCompany(params) {
    try {
        const { companyId, userId } = params;
        await (0, user_status_event_mutations_1.createUserStatusEvent)({
            userId: userId,
            eventType: 'ENDED_ADMIN_MODE',
            timestamp: new Date(),
            companyId: companyId,
        });
        await (0, update_work_status_1.updateWorkStatus)(userId, 'CLOCKED_IN');
        await (0, set_admin_mode_for_user_1.setAdminModeForUser)({
            userId,
            shouldBeInAdminMode: false,
        });
    }
    catch (error) {
        (0, utilities_1.handleError)(`endAdminModeForCompany error: ${error}`);
        throw new Error(`endAdminModeForCompany error: ${error}`);
    }
}
