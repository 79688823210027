"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchCurrentMinutesPackageForCompany = exports.fetchReceptionistService = exports.fetchMinutesPackageForDate = exports.fetchCompanyLogForDate = exports.fetchCallsForCallLog = void 0;
const graphql_1 = require("../../../services/graphql");
const error_handling_1 = require("../../../utilities/error-handling");
const types_constants_1 = require("./types-constants");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
const callBackInitiatedEventDescription = 'CALLBACK_INITIATED';
async function fetchCallsForCallLog(params) {
    try {
        const findCallsResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                query ($limit: Int, $offset: Int!, $filter: CallsFilter!) {
                    findCalls(
                        filter: $filter
                        page: {limit: $limit, offset: $offset}
                        orderBy: {field: "created_at", order: DESC}
                    ) {
                        items {
                            id
                            billable_duration
                            created_at
                            destination_phone
                            duration
                            direction
                            duration_events(orderBy: {field: "created_at", order: ASC}) {
                                id
                                description
                                timestamp
                            }
                            new_direction
                            origin_phone
                            user {
                                id
                                first_name
                            }
                            twilio_parent_call_sid
                            twilio_reason_conference_ended
                            recording_url
                            was_personal_admin_call
                            marchex_recommendation
                            contact {
                                id
                                status
                            }
                        }
                        total
                    }
                }
            `, {
            limit: params.limit,
            offset: params.offset,
            filter: createCallsFilters(params),
        });
        if (findCallsResult.succeeded === false) {
            return findCallsResult;
        }
        const callsForPage = findCallsResult.value.data.findCalls.items;
        const totalCalls = findCallsResult.value.data.findCalls.total;
        return {
            succeeded: true,
            value: {
                callsForPage: filterCallsIfNeeded(callsForPage, params.callDirection),
                totalCalls,
            },
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchCallsForCallLog', error);
    }
}
exports.fetchCallsForCallLog = fetchCallsForCallLog;
async function fetchCompanyLogForDate(companyId, date) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!, $date: DateTime!) {
                    findCompany_logs(
                        filter: {
                            and: [
                                {company_id: {eq: $companyId}}
                                {start_date: {le: $date}}
                                {end_date: {gt: $date}}
                            ]
                        }
                    ) {
                        items {
                            id
                            billing_cycle_type
                            billable_duration
                            created_at
                            updated_at
                            start_date
                            end_date
                        }
                    }
                }
            `, {
            companyId,
            date,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        const companyLog = gqlResult.value.data.findCompany_logs.items[0];
        if (companyLog === undefined) {
            return {
                succeeded: true,
                value: undefined,
            };
        }
        return {
            succeeded: true,
            value: companyLog,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchCompanyLog', error);
    }
}
exports.fetchCompanyLogForDate = fetchCompanyLogForDate;
async function fetchMinutesPackageForDate(companyId, date) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!, $date: DateTime!) {
                    findInvoices(
                        filter: {
                            and: [
                                {company_id: {eq: $companyId}}
                                {start_date: {le: $date}}
                                {end_date: {ge: $date}}
                            ]
                        }
                    ) {
                        items {
                            id
                            minutes_package {
                                id
                                billing_cycle_type
                                type
                                name
                                user_display_name
                                num_minutes_included
                                minutes_calculation_option {
                                    id
                                    max_billable_action_item_milliseconds
                                    max_billable_milliseconds
                                    min_billable_milliseconds
                                    min_milliseconds_call_needs_to_be_billable
                                }
                            }
                        }
                    }
                }
            `, {
            companyId,
            date,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        const invoice = gqlResult.value.data.findInvoices.items[0];
        if (invoice === undefined) {
            return {
                succeeded: true,
                value: undefined,
            };
        }
        const minutesPackage = invoice.minutes_package;
        if (minutesPackage === null || minutesPackage === undefined) {
            return {
                succeeded: true,
                value: undefined,
            };
        }
        return {
            succeeded: true,
            value: minutesPackage,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchMinutesPackage', error);
    }
}
exports.fetchMinutesPackageForDate = fetchMinutesPackageForDate;
async function fetchReceptionistService(companyId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!) {
                    getCompanies(id: $companyId) {
                        id
                        receptionist_services {
                            id
                            minutes_per_month
                        }
                    }
                }
            `, {
            companyId,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        const company = gqlResult.value.data.getCompanies;
        const receptionistService = company.receptionist_services[0];
        if (receptionistService === undefined) {
            return {
                succeeded: true,
                value: undefined,
            };
        }
        return {
            succeeded: true,
            value: receptionistService,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchReceptionistService', error);
    }
}
exports.fetchReceptionistService = fetchReceptionistService;
async function fetchCurrentMinutesPackageForCompany(companyId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!) {
                    getCompanies(id: $companyId) {
                        id
                        current_minutes_package {
                            id
                            billing_cycle_type
                        }
                    }
                }
            `, {
            companyId,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const company = gqlResult.value.data.getCompanies;
        const currentMinutesPackage = company.current_minutes_package ?? undefined;
        return {
            succeeded: true,
            value: currentMinutesPackage,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchCurrentMinutesPackageForCompany', error);
    }
}
exports.fetchCurrentMinutesPackageForCompany = fetchCurrentMinutesPackageForCompany;
function createCallsFilters(params) {
    return {
        and: [
            { company_id: { eq: params.companyId } },
            { created_at: { ge: params.startDate.toISOString() } },
            { created_at: { lt: params.endDate.toISOString() } },
            { new_system: { eq: true } },
            createFilterForCallType(params.callType),
            createFilterForCallDirection(params.callDirection),
        ],
    };
}
function createFilterForCallType(callType) {
    if (callType === types_constants_1.CallTypeFilterOptions.Billable)
        return { billable_duration: { gt: 0 } };
    if (callType === types_constants_1.CallTypeFilterOptions.NonBillable)
        return { billable_duration: { eq: 0 } };
    return { billable_duration: { ne: null } };
}
function createFilterForCallDirection(callDirection) {
    if (callDirection === types_constants_1.CallDirectionFilterOptions.Incoming)
        return { direction: { eq: 0 } };
    if (callDirection === types_constants_1.CallDirectionFilterOptions.Outgoing ||
        callDirection === types_constants_1.CallDirectionFilterOptions.CallBack)
        return { direction: { eq: 1 } };
    return { id: { ne: null } };
}
function filterCallsIfNeeded(calls, callDirection) {
    if (callDirection === types_constants_1.CallDirectionFilterOptions.CallBack) {
        return calls.filter(call => call.duration_events.some(event => event.description === callBackInitiatedEventDescription));
    }
    if (callDirection === types_constants_1.CallDirectionFilterOptions.Outgoing) {
        return calls.filter(call => call.duration_events.every(event => event.description !== callBackInitiatedEventDescription));
    }
    return calls;
}
