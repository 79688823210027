"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getColumns = void 0;
const lit_html_1 = require("lit-html");
const utilities_1 = require("../../../services/utilities");
const auth_rules_1 = require("../../../utilities/auth-rules");
const css_utils_1 = require("../../../utilities/css-utils");
const friendy_date_1 = require("../../../utilities/dates-and-times/friendy-date");
const get_full_name_1 = require("../../../utilities/get-full-name");
const one_hundred_char_preview_1 = require("../../../utilities/strings/one-hundred-char-preview");
const helper_utils_1 = require("../jo-call-screen/jo-action-item-flag-card/helper-utils");
const common_1 = require("@augment-vir/common");
function getColumns(joActionItemsFlagTable, configArgs) {
    const columns = [
        {
            getCellData: (flag) => {
                if (configArgs?.forCSV) {
                    return '';
                }
                return (0, lit_html_1.html) `
                    <div class="flex items-center">
                        <jo-button
                            .type=${'ICON'}
                            .icon=${'remove_red_eye'}
                            .href=${`/action-item?actionItemId=${flag.action_item?.id}&companyId=${flag.action_item?.company?.id}&callScreenMode=ACTION_ITEM`}
                            .inlineStyle=${'font-size: 1.3rem'}
                        ></jo-button>
                    </div>
                `;
            },
            width: 0.5,
        },
        {
            title: 'Category',
            getCellData: (flag) => flag.title,
            width: 1,
            sortFieldName: 'CATEGORY',
        },
        {
            title: 'Description',
            getCellData: (flag) => (0, one_hundred_char_preview_1.oneHundredCharPreview)(flag.description),
            width: 1,
        },
        {
            title: 'Company',
            getCellData: (flag) => flag.action_item?.company?.name,
            width: 1,
            sortFieldName: 'COMPANY_NAME',
        },
        {
            title: 'Pod',
            getCellData: (flag) => flag.action_item?.company?.pod?.name,
            width: 1,
            sortFieldName: 'POD_NAME',
        },
        {
            title: 'Jill',
            getCellData: (flag) => (0, get_full_name_1.getFullName)(flag.user),
            width: 1,
            sortFieldName: 'JILL_NAME',
        },
        {
            title: 'Date',
            getCellData: (flag) => (0, friendy_date_1.friendlyDate)(flag.created_at),
            width: 1,
            sortFieldName: 'CREATED_AT',
        },
        {
            title: 'Flagged by',
            getCellData: (flag) => (0, get_full_name_1.getFullName)(flag.author),
            width: 1,
            sortFieldName: 'AUTHOR_NAME',
        },
        {
            title: 'Recording',
            getCellData: (flag) => {
                if (configArgs?.forCSV) {
                    return '';
                }
                const call = flag.action_item?.call;
                return call?.recording_url ? recordingButtonHTML(call) : '';
            },
            width: 0.5,
        },
        {
            title: 'Addressal Status',
            getCellData: (flag) => getAddressalStatusCellData(flag, joActionItemsFlagTable, configArgs),
            width: 1,
        },
        {
            title: 'Addressal Notes',
            getCellData: (flag) => getAddressalNotesCellData(flag, configArgs),
            width: 1,
        },
        {
            title: 'Mistake Addressal',
            getCellData: (flag) => getMistakeAddressalStatusCellData(flag, joActionItemsFlagTable, configArgs),
            width: 1,
        },
        {
            title: 'Account Manager',
            getCellData: (flag) => (0, helper_utils_1.fullName)(flag.action_item?.company?.account_manager),
            width: 1,
            sortFieldName: 'ACCOUNT_MANAGER',
        },
    ];
    return columns;
}
exports.getColumns = getColumns;
function getAddressalStatusCellData(flag, joActionItemsFlagTable, configArgs) {
    if ((0, common_1.isLengthAtLeast)(flag.addressals, 1)) {
        const addressal = flag.addressals[0];
        if (configArgs?.forCSV) {
            return `Addressed by ${(0, helper_utils_1.fullName)(addressal.user)} on ${(0, friendy_date_1.friendlyDate)(addressal.created_at)}`;
        }
        return addressalNotesHTML(addressal);
    }
    if (configArgs?.forCSV) {
        return 'Not addressed';
    }
    if ((0, auth_rules_1.isJillAtOrAboveAuthRole)(configArgs?.authenticatedUser, 'JILL_MANAGER')) {
        return markAsAddressedButtonHTML(joActionItemsFlagTable, flag);
    }
    return '';
}
function markAsAddressedButtonHTML(joActionItemsFlagTable, flag) {
    return (0, lit_html_1.html) `
        <div class="jo-global--center">
            <jo-button
                .type=${'ACTION_BUTTON_2'}
                .text=${'Mark as Addressed'}
                .inlineStyle=${'font-size: 0.8rem;'}
                @joButtonClick=${() => {
        joActionItemsFlagTable.markFlagAsAddressedClicked(flag);
    }}
            ></jo-button>
        </div>
    `;
}
function getAddressalNotesCellData(flag, configArgs) {
    const addressal = flag.addressals[0];
    if (!addressal)
        return '';
    if (configArgs?.forCSV) {
        return (0, one_hundred_char_preview_1.oneHundredCharPreview)(addressal.description);
    }
    return (0, lit_html_1.html) `
        <div style="color: green">${(0, one_hundred_char_preview_1.oneHundredCharPreview)(addressal.description)}</div>
    `;
}
const cssName = (0, css_utils_1.cssPrefixer)('jo-action-item-flags-table');
function recordingButtonHTML(call) {
    return (0, lit_html_1.html) `
        <div style="display: flex; align-items: center; justify-content: center;">
            <a class="${cssName('playCircle')}" @click=${() => (0, utilities_1.joAudioPopUp)(call.recording_url)}>
                <span class="material-icons">play_circle_outline</span>
            </a>
        </div>
    `;
}
function addressalNotesHTML(addressal) {
    return (0, lit_html_1.html) `
        <div class="jo-global--center" style="color: green">
            <span>
                Addressed by
                <strong>${(0, helper_utils_1.fullName)(addressal.user)}</strong>
                on ${(0, friendy_date_1.friendlyDate)(addressal.created_at)}
            </span>
        </div>
    `;
}
function getMistakeAddressalStatusCellData(flag, joActionItemsFlagTable, configArgs) {
    if ((0, common_1.isLengthAtLeast)(flag.mistakenly_flagged_action_items, 1)) {
        const mistake = flag.mistakenly_flagged_action_items[0];
        return getHtmlForMistakenlyFlaggedActionItem(mistake);
    }
    return getMistakenlyFlaggedActionItemButtonHtml(joActionItemsFlagTable, flag);
}
function getMistakenlyFlaggedActionItemButtonHtml(joActionItemsFlagTable, flag) {
    return (0, lit_html_1.html) `
        <div class="jo-global--center">
            <jo-button
                .type=${'DELETE_BUTTON_2'}
                .text=${'Mark as Mistake'}
                .inlineStyle=${'font-size: 0.8rem;'}
                @joButtonClick=${() => {
        joActionItemsFlagTable.markActionItemFlagAsMistake(flag);
    }}
            ></jo-button>
        </div>
    `;
}
function getHtmlForMistakenlyFlaggedActionItem(mistake) {
    return (0, lit_html_1.html) `
        <div>
            <strong>${(0, helper_utils_1.fullName)(mistake.user)}</strong>
            Marked this as a mistake on ${(0, friendy_date_1.friendlyDate)(mistake.created_at)}
            <br />
            <strong>Reason:</strong>
            ${mistake.description}
        </div>
    `;
}
