"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.searchCompanies = void 0;
const graphql_1 = require("../../../services/graphql");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function searchCompanies(searchText, limit) {
    const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query ($searchText: String!, $limit: Int!) {
                searchCompanies(
                    searchText: $searchText
                    page: {limit: $limit, offset: 0}
                    ignoreSearch: false
                    includeCanceled: true
                ) {
                    companies {
                        id
                        name
                    }
                }
            }
        `, {
        searchText,
        limit,
    });
    return gqlResult.data.searchCompanies.companies;
}
exports.searchCompanies = searchCompanies;
