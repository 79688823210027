"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.syncGoogleCalendarsForCompany = exports.updateCalendarAttribute = void 0;
const graphql_1 = require("../../../../../services/graphql");
const mapped_env_variables_1 = require("../../../../../utilities/environment/mapped-env-variables");
async function updateCalendarAttribute(companyId, calendarRecordId, attribute) {
    try {
        const input = getUpdateCompaniesInput(companyId, calendarRecordId, attribute);
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($input: UpdateCompaniesInput!) {
                    updateCompanies(input: $input) {
                        id
                    }
                }
            `, {
            input,
        });
    }
    catch (error) {
        throw new Error(`updateCalendarAttribute error: ${error}`);
    }
}
exports.updateCalendarAttribute = updateCalendarAttribute;
function getUpdateCompaniesInput(companyId, calendarRecordId, attribute) {
    try {
        const defaultUpdateCompanyProps = {
            id: companyId,
            calendar_connections: {
                update: {
                    id: calendarRecordId,
                    company: {
                        connect: {
                            id: companyId,
                        },
                    },
                },
            },
        };
        return {
            VISIBILITY: makeUpdateCompanyCalendarAccessProps(defaultUpdateCompanyProps, attribute),
            DELETE_EVENT: makeUpdateCompanyCalendarDeleteEventAccessProps(defaultUpdateCompanyProps, attribute),
            RESCHEDULE_EVENT: makeUpdateCompanyCalendarRescheduleEventAccessProps(defaultUpdateCompanyProps, attribute),
        }[attribute.type];
    }
    catch (error) {
        throw new Error(`getUpdateCompaniesInput error: ${error}`);
    }
}
function makeUpdateCompanyCalendarAccessProps(defaultUpdateCompanyProps, attribute) {
    return {
        ...defaultUpdateCompanyProps,
        calendar_connections: {
            update: {
                ...defaultUpdateCompanyProps.calendar_connections.update,
                is_visible: attribute.value,
            },
        },
    };
}
function makeUpdateCompanyCalendarRescheduleEventAccessProps(defaultUpdateCompanyProps, attribute) {
    return {
        ...defaultUpdateCompanyProps,
        calendar_connections: {
            update: {
                ...defaultUpdateCompanyProps.calendar_connections.update,
                can_reschedule_events: attribute.value,
            },
        },
    };
}
function makeUpdateCompanyCalendarDeleteEventAccessProps(defaultUpdateCompanyProps, attribute) {
    return {
        ...defaultUpdateCompanyProps,
        calendar_connections: {
            update: {
                ...defaultUpdateCompanyProps.calendar_connections.update,
                can_delete_events: attribute.value,
            },
        },
    };
}
async function syncGoogleCalendarsForCompany(companyId) {
    try {
        await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($companyId: Int!) {
                    syncGoogleCalendarsForCompany(companyId: $companyId)
                }
            `, {
            companyId,
        });
    }
    catch (error) {
        throw new Error(`syncGoogleCalendarsForCompany error: ${error}`);
    }
}
exports.syncGoogleCalendarsForCompany = syncGoogleCalendarsForCompany;
