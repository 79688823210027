"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logout = void 0;
const constants_1 = require("../services/constants");
const graphql_1 = require("../services/graphql");
const utilities_1 = require("../services/utilities");
const mapped_env_variables_1 = require("./environment/mapped-env-variables");
async function logout() {
    const logoutJWTResultPromise = (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
        mutation {
            logout(cookieType: JWT)
        }
    `);
    const logoutRAILSResultPromise = (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
        mutation {
            logout(cookieType: RAILS)
        }
    `);
    await Promise.all([
        logoutJWTResultPromise,
        logoutRAILSResultPromise,
    ]);
    // Because we are actually reloading the page on logout to ensure all state in memory is reset (most especially web sockets)
    // then we should not need to set the authenticated user to not set here
    // If we do this, there is some jankiness on the top bar and potentially elsewhere because the authenticated user is being reset
    // GlobalStore.authenticatedUser = 'NOT_SET';
    window.localStorage.removeItem(constants_1.csrfTokenName);
    window.localStorage.removeItem(constants_1.clientPublicKeyName);
    window.localStorage.removeItem(constants_1.clientPrivateKeyName);
    (0, utilities_1.removeAuthorizationHeaderInGraphqlPlayground)();
    return true;
}
exports.logout = logout;
