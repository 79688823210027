"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initiatePhoneNumberVerificationAndReturnValidationCode = void 0;
const graphql_1 = require("../../../../services/graphql");
const error_handling_1 = require("../../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function initiatePhoneNumberVerificationAndReturnValidationCode(companyId, companyName, phoneNumber) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($companyId: Int!, $companyName: String!, $phoneNumber: String!) {
                    initiatePhoneNumberVerificationAndReturnValidationCode(
                        companyId: $companyId
                        companyName: $companyName
                        businessNumber: $phoneNumber
                    )
                }
            `, {
            companyId,
            companyName,
            phoneNumber,
        });
        if (gqlResult.succeeded === false) {
            return {
                ...gqlResult,
                developerMessage: `initiatePhoneNumberVerificationAndReturnValidationCode error: ${gqlResult.developerMessage}`,
            };
        }
        return {
            succeeded: true,
            value: gqlResult.value.data.initiatePhoneNumberVerificationAndReturnValidationCode,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('initiatePhoneNumberVerificationAndReturnValidationCode', error);
    }
}
exports.initiatePhoneNumberVerificationAndReturnValidationCode = initiatePhoneNumberVerificationAndReturnValidationCode;
