"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    company: 'NOT_SET',
    inputValues: {
        call_screen_general_information_address_appear: false,
        call_screen_general_information_address_require: false,
        call_screen_general_information_city_appear: false,
        call_screen_general_information_city_require: false,
        call_screen_general_information_county_appear: false,
        call_screen_general_information_county_require: false,
        call_screen_general_information_email_appear: false,
        call_screen_general_information_email_require: false,
        call_screen_general_information_postal_code_appear: false,
        call_screen_general_information_postal_code_require: false,
        call_screen_general_information_province_appear: false,
        call_screen_general_information_province_require: false,
        call_screen_general_information_state_appear: false,
        call_screen_general_information_state_require: false,
        call_screen_general_information_where_did_you_hear_appear: false,
        call_screen_general_information_where_did_you_hear_require: false,
        call_screen_general_information_zip_code_appear: false,
        call_screen_general_information_zip_code_require: false,
    },
    isMobileDevice: false,
};
class JOCompanyCallInstructionsGeneralSetupGeneralInformation extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    set company(company) {
        if (company === this.store.company) {
            return;
        }
        this.store.company = company;
        if (company === 'NOT_SET') {
            this.store.inputValues = InitialState.inputValues;
        }
        else {
            this.inputValues = {
                call_screen_general_information_address_appear: company.call_screen_general_information_address_appear,
                call_screen_general_information_address_require: company.call_screen_general_information_address_require,
                call_screen_general_information_city_appear: company.call_screen_general_information_city_appear,
                call_screen_general_information_city_require: company.call_screen_general_information_city_require,
                call_screen_general_information_county_appear: company.call_screen_general_information_county_appear,
                call_screen_general_information_county_require: company.call_screen_general_information_county_require,
                call_screen_general_information_email_appear: company.call_screen_general_information_email_appear,
                call_screen_general_information_email_require: company.call_screen_general_information_email_require,
                call_screen_general_information_postal_code_appear: company.call_screen_general_information_postal_code_appear,
                call_screen_general_information_postal_code_require: company.call_screen_general_information_postal_code_require,
                call_screen_general_information_province_appear: company.call_screen_general_information_province_appear,
                call_screen_general_information_province_require: company.call_screen_general_information_province_require,
                call_screen_general_information_state_appear: company.call_screen_general_information_state_appear,
                call_screen_general_information_state_require: company.call_screen_general_information_state_require,
                call_screen_general_information_where_did_you_hear_appear: company.call_screen_general_information_where_did_you_hear_appear,
                call_screen_general_information_where_did_you_hear_require: company.call_screen_general_information_where_did_you_hear_require,
                call_screen_general_information_zip_code_appear: company.call_screen_general_information_zip_code_appear,
                call_screen_general_information_zip_code_require: company.call_screen_general_information_zip_code_require,
            };
        }
    }
    set inputValues(inputValues) {
        this.store.inputValues = inputValues;
        (0, utilities_1.raiseCustomEvent)(this, 'inputvalueschanged', this.store.inputValues);
    }
    render(state) {
        const isAllowedToUpdate = (0, utilities_1.isInAuthRole)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser);
        return (0, lit_html_1.html) `
            <style>
                .jo-company-call-instructions-general-setup-general-information--main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                }

                .jo-company-call-instructions-general-setup-general-information--row {
                    display: flex;
                    padding: ${state.isMobileDevice ? '0.2rem' : '0.5rem'};
                }

                .jo-company-call-instructions-general-setup-general-information--row-item-1 {
                    flex: 3;
                }

                .jo-company-call-instructions-general-setup-general-information--row-item-2 {
                    flex: 1;
                }

                .jo-company-call-instructions-general-setup-general-information--row-item-3 {
                    flex: 1;
                }

                .jo-company-call-instructions-general-setup-general-information--appear-text {
                    font-weight: bold;
                    color: var(--jo-primary);
                    font-size: ${state.isMobileDevice ? 'calc(12px + 1vmin)' : ''};
                }

                .jo-company-call-instructions-general-setup-general-information--require-text {
                    font-weight: bold;
                    color: var(--jo-primary);
                    font-size: ${state.isMobileDevice ? 'calc(12px + 1vmin)' : ''};
                }

                .jo-company-call-instructions-general-setup-general-information--row-text {
                    font-weight: bold;
                    color: var(--jo-text-default);
                    font-size: ${state.isMobileDevice ? 'calc(10px + 1vmin)' : ''};
                }

                .jo-global--card-title {
                    margin-bottom: ${state.isMobileDevice ? '0.5rem' : ''};
                }
            </style>

            <div
                class="jo-company-call-instructions-general-setup-general-information--main-container"
            >
                <div class="jo-global--card-title">GENERAL INFORMATION</div>

                <div class="jo-company-call-instructions-general-setup-general-information--row">
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-1"
                    ></div>
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-2 jo-company-call-instructions-general-setup-general-information--appear-text"
                    >
                        Appear
                    </div>
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-3 jo-company-call-instructions-general-setup-general-information--require-text"
                    >
                        Require
                    </div>
                </div>

                <div class="jo-company-call-instructions-general-setup-general-information--row">
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-1 jo-company-call-instructions-general-setup-general-information--row-text"
                    >
                        Email
                    </div>
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-2"
                    >
                        <input
                            ?disabled=${isAllowedToUpdate === false}
                            type="checkbox"
                            .checked=${state.inputValues
            .call_screen_general_information_email_appear}
                            @change=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                call_screen_general_information_email_appear: e.target.checked,
            };
        }}
                        />
                    </div>
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-3"
                    >
                        <input
                            ?disabled=${isAllowedToUpdate === false}
                            type="checkbox"
                            .checked=${state.inputValues
            .call_screen_general_information_email_require}
                            @change=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                call_screen_general_information_email_require: e.target.checked,
            };
        }}
                        />
                    </div>
                </div>

                <div class="jo-company-call-instructions-general-setup-general-information--row">
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-1 jo-company-call-instructions-general-setup-general-information--row-text"
                    >
                        Where did you hear about us?
                    </div>
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-2"
                    >
                        <input
                            ?disabled=${isAllowedToUpdate === false}
                            type="checkbox"
                            .checked=${state.inputValues
            .call_screen_general_information_where_did_you_hear_appear}
                            @change=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                call_screen_general_information_where_did_you_hear_appear: e.target.checked,
            };
        }}
                        />
                    </div>
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-3"
                    >
                        <input
                            ?disabled=${isAllowedToUpdate === false}
                            type="checkbox"
                            .checked=${state.inputValues
            .call_screen_general_information_where_did_you_hear_require}
                            @change=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                call_screen_general_information_where_did_you_hear_require: e.target.checked,
            };
        }}
                        />
                    </div>
                </div>

                <div class="jo-company-call-instructions-general-setup-general-information--row">
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-1 jo-company-call-instructions-general-setup-general-information--row-text"
                    >
                        Address
                    </div>
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-2"
                    >
                        <input
                            ?disabled=${isAllowedToUpdate === false}
                            type="checkbox"
                            .checked=${state.inputValues
            .call_screen_general_information_address_appear}
                            @change=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                call_screen_general_information_address_appear: e.target.checked,
            };
        }}
                        />
                    </div>
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-3"
                    >
                        <input
                            ?disabled=${isAllowedToUpdate === false}
                            type="checkbox"
                            .checked=${state.inputValues
            .call_screen_general_information_address_require}
                            @change=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                call_screen_general_information_address_require: e.target.checked,
            };
        }}
                        />
                    </div>
                </div>

                <div class="jo-company-call-instructions-general-setup-general-information--row">
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-1 jo-company-call-instructions-general-setup-general-information--row-text"
                    >
                        City
                    </div>
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-2"
                    >
                        <input
                            ?disabled=${isAllowedToUpdate === false}
                            type="checkbox"
                            .checked=${state.inputValues
            .call_screen_general_information_city_appear}
                            @change=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                call_screen_general_information_city_appear: e.target.checked,
            };
        }}
                        />
                    </div>
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-3"
                    >
                        <input
                            ?disabled=${isAllowedToUpdate === false}
                            type="checkbox"
                            .checked=${state.inputValues
            .call_screen_general_information_city_require}
                            @change=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                call_screen_general_information_city_require: e.target.checked,
            };
        }}
                        />
                    </div>
                </div>

                <div class="jo-company-call-instructions-general-setup-general-information--row">
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-1 jo-company-call-instructions-general-setup-general-information--row-text"
                    >
                        State
                    </div>
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-2"
                    >
                        <input
                            ?disabled=${isAllowedToUpdate === false}
                            type="checkbox"
                            .checked=${state.inputValues
            .call_screen_general_information_state_appear}
                            @change=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                call_screen_general_information_state_appear: e.target.checked,
            };
        }}
                        />
                    </div>
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-3"
                    >
                        <input
                            ?disabled=${isAllowedToUpdate === false}
                            type="checkbox"
                            .checked=${state.inputValues
            .call_screen_general_information_state_require}
                            @change=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                call_screen_general_information_state_require: e.target.checked,
            };
        }}
                        />
                    </div>
                </div>

                <div class="jo-company-call-instructions-general-setup-general-information--row">
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-1 jo-company-call-instructions-general-setup-general-information--row-text"
                    >
                        Zip
                    </div>
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-2"
                    >
                        <input
                            ?disabled=${isAllowedToUpdate === false}
                            type="checkbox"
                            .checked=${state.inputValues
            .call_screen_general_information_zip_code_appear}
                            @change=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                call_screen_general_information_zip_code_appear: e.target.checked,
            };
        }}
                        />
                    </div>
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-3"
                    >
                        <input
                            ?disabled=${isAllowedToUpdate === false}
                            type="checkbox"
                            .checked=${state.inputValues
            .call_screen_general_information_zip_code_require}
                            @change=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                call_screen_general_information_zip_code_require: e.target.checked,
            };
        }}
                        />
                    </div>
                </div>

                <div class="jo-company-call-instructions-general-setup-general-information--row">
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-1 jo-company-call-instructions-general-setup-general-information--row-text"
                    >
                        County
                    </div>
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-2"
                    >
                        <input
                            ?disabled=${isAllowedToUpdate === false}
                            type="checkbox"
                            .checked=${state.inputValues
            .call_screen_general_information_county_appear}
                            @change=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                call_screen_general_information_county_appear: e.target.checked,
            };
        }}
                        />
                    </div>
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-3"
                    >
                        <input
                            ?disabled=${isAllowedToUpdate === false}
                            type="checkbox"
                            .checked=${state.inputValues
            .call_screen_general_information_county_require}
                            @change=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                call_screen_general_information_county_require: e.target.checked,
            };
        }}
                        />
                    </div>
                </div>

                <div class="jo-company-call-instructions-general-setup-general-information--row">
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-1 jo-company-call-instructions-general-setup-general-information--row-text"
                    >
                        Province
                    </div>
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-2"
                    >
                        <input
                            ?disabled=${isAllowedToUpdate === false}
                            type="checkbox"
                            .checked=${state.inputValues
            .call_screen_general_information_province_appear}
                            @change=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                call_screen_general_information_province_appear: e.target.checked,
            };
        }}
                        />
                    </div>
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-3"
                    >
                        <input
                            ?disabled=${isAllowedToUpdate === false}
                            type="checkbox"
                            .checked=${state.inputValues
            .call_screen_general_information_province_require}
                            @change=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                call_screen_general_information_province_require: e.target.checked,
            };
        }}
                        />
                    </div>
                </div>

                <div class="jo-company-call-instructions-general-setup-general-information--row">
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-1 jo-company-call-instructions-general-setup-general-information--row-text"
                    >
                        Postal Code
                    </div>
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-2"
                    >
                        <input
                            ?disabled=${isAllowedToUpdate === false}
                            type="checkbox"
                            .checked=${state.inputValues
            .call_screen_general_information_postal_code_appear}
                            @change=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                call_screen_general_information_postal_code_appear: e.target.checked,
            };
        }}
                        />
                    </div>
                    <div
                        class="jo-company-call-instructions-general-setup-general-information--row-item-3"
                    >
                        <input
                            ?disabled=${isAllowedToUpdate === false}
                            type="checkbox"
                            .checked=${state.inputValues
            .call_screen_general_information_postal_code_require}
                            @change=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                call_screen_general_information_postal_code_require: e.target.checked,
            };
        }}
                        />
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-call-instructions-general-setup-general-information', JOCompanyCallInstructionsGeneralSetupGeneralInformation);
