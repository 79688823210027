"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const dequal_1 = require("dequal");
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const constants_1 = require("../../../services/constants");
const graphql_1 = require("../../../services/graphql");
const utilities_1 = require("../../../services/utilities");
const auth_rules_1 = require("../../../utilities/auth-rules");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const is_defined_1 = require("../../../utilities/is-defined");
const get_jill_profile_picture_1 = require("../../../utilities/profile-picture/get-jill-profile-picture");
require("../jo-button.element");
require("../jo-input");
require("../jo-user-shifts/jo-user-shifts");
require("./jo-user-contact-info");
require("./jo-user-jilln-info");
require("./jo-user-permissions");
const mutations_1 = require("./mutations");
const queries_1 = require("./queries");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    createNewPassword: false,
    editedShifts: [],
    formErrors: [],
    globalPods: [],
    isAvailable: false,
    loading: true,
    mode: 'EDIT',
    permissions: {
        can_delete_invoices: false,
        has_chat_access: false,
        can_view_incoming_queue_box: true,
    },
    saving: false,
    shifts: [],
    showAllFormErrors: false,
    subscriptions: {
        userAvailabilityChangedSubscription: 'NOT_SET',
        userPersonalAdminModeChangedSubscription: 'NOT_SET',
    },
    updatingProfilePicture: false,
    user: 'NOT_SET',
    userContactInfoInputValues: 'NOT_SET',
    userId: 'NOT_SET',
    userJillnInfoInputValues: 'NOT_SET',
};
class JOUserInfo extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    async connectedCallback() {
        await this.fetchAndSetGlobalPods();
    }
    set userId(userId) {
        const originalUserId = this.store.userId;
        this.cleanUpSubscriptionsIfNecessary(userId, originalUserId);
        this.store.userId = userId;
        this.fetchAndSetDataIfNecessary(this.store.userId, originalUserId);
    }
    cleanUpSubscriptionsIfNecessary(userId, originalUserId) {
        if (userId !== originalUserId) {
            this.cleanUpSubscriptions();
        }
    }
    fetchAndSetDataIfNecessary(userId, originalUserId) {
        if (userId !== 'NOT_SET' && userId !== originalUserId) {
            this.fetchAndSetData(userId);
        }
    }
    async fetchAndSetData(userId) {
        this.store.loading = true;
        await Promise.all([
            this.fetchAndSetGlobalPods(),
            this.fetchSetAndSubscribeToUser(userId),
        ]);
        this.store.loading = false;
    }
    set mode(mode) {
        this.store.mode = mode;
        if (mode === 'CREATE') {
            this.store.user = 'NEW_USER';
            this.store.createNewPassword = true;
            this.store.loading = false;
        }
    }
    cleanUpSubscriptions() {
        (0, utilities_1.closeSubscriptionsIfNecessary)(this.store.subscriptions);
        this.store.subscriptions = InitialState.subscriptions;
    }
    async fetchAndSetGlobalPods() {
        const globalPodsResult = await (0, queries_1.fetchGlobalPods)();
        (0, utilities_1.assertSucceeded)(globalPodsResult, utilities_1.handleError);
        this.store.globalPods = globalPodsResult.value;
    }
    async fetchSetAndSubscribeToUser(userId) {
        await this.fetchAndSetUser(userId);
        await this.subscribeToUser();
    }
    async fetchAndSetUser(userId) {
        const userResult = await (0, queries_1.fetchUser)(userId);
        (0, utilities_1.assertSucceeded)(userResult, utilities_1.handleError);
        this.setUser(userResult.value);
    }
    setUser(user) {
        this.store.user = user;
        this.store.shifts = user.shifts;
        this.store.permissions = getUserPermissions(user);
        this.store.editedShifts = [...user.shifts];
    }
    async subscribeToUser() {
        (0, utilities_1.assertIsSet)(this.store.userId, utilities_1.handleError, 'this.store.userId');
        await this.subscribeToUserAvailabilityChanged(this.store.userId);
        await this.subscribeToUserPersonalAdminModeChanged(this.store.userId);
    }
    async subscribeToUserAvailabilityChanged(userId) {
        const userAvailabilityChangedSubscription = await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
                subscription ($userId: Int!) {
                    userAvailabilityChanged(userId: $userId)
                }
            `, async () => {
            const fetchUserResult = await (0, queries_1.fetchUser)(userId);
            (0, utilities_1.assertSucceeded)(fetchUserResult, utilities_1.handleError);
            this.store.user = fetchUserResult.value;
        }, {
            userId,
        });
        this.store.subscriptions = {
            ...this.store.subscriptions,
            userAvailabilityChangedSubscription: userAvailabilityChangedSubscription ?? 'NOT_SET',
        };
    }
    async subscribeToUserPersonalAdminModeChanged(userId) {
        const userPersonalAdminModeChangedSubscription = await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
                subscription ($userId: Int!) {
                    userPersonalAdminModeChanged(userId: $userId)
                }
            `, async () => {
            const fetchUserResult = await (0, queries_1.fetchUser)(userId);
            (0, utilities_1.assertSucceeded)(fetchUserResult, utilities_1.handleError);
            this.store.user = fetchUserResult.value;
        }, {
            userId,
        });
        this.store.subscriptions = {
            ...this.store.subscriptions,
            userPersonalAdminModeChangedSubscription: userPersonalAdminModeChangedSubscription ?? 'NOT_SET',
        };
    }
    async saveButtonClicked() {
        (0, utilities_1.assertIsSet)(this.store.userContactInfoInputValues, utilities_1.handleError, 'this.store.userContactInfoInputValues');
        (0, utilities_1.assertIsSet)(this.store.userJillnInfoInputValues, utilities_1.handleError, 'this.store.userJillnInfoInputValues');
        if (this.areThereAnyFormErrors(this.store.userContactInfoInputValues, this.store.userJillnInfoInputValues) === true) {
            this.store.showAllFormErrors = true;
            (0, utilities_1.joAlert)('Notice', 'Please correct form errors');
            return;
        }
        const shouldContinue = await (0, utilities_1.joConfirm)('Alert', 'Are you sure you want to save User info?');
        if (shouldContinue === false) {
            return;
        }
        if (this.hasFullUpdatePriviliges()) {
            await this.handleUpsertUserInFull();
        }
        else {
            await this.handleUpdateJillPodsAndShiftHours();
        }
        await (0, utilities_1.joAlert)('Success', 'User info saved');
        window.location.href = '/users';
    }
    hasFullUpdatePriviliges() {
        (0, utilities_1.assertIsSet)(this.store.authenticatedUser, utilities_1.handleError, 'this.store.authenticatedUser');
        (0, utilities_1.assertIsSet)(this.store.userContactInfoInputValues, utilities_1.handleError, 'this.store.userContactInfoInputValues');
        return (this.store.userContactInfoInputValues.authRoleSelectedValue !== 'NOT_SET' &&
            isUserAtOrAboveAuthRoleOfOtherUser(this.store.authenticatedUser.auth_role, this.store.userContactInfoInputValues.authRoleSelectedValue) &&
            (0, auth_rules_1.isJillAtOrAboveAuthRole)(this.store.authenticatedUser, 'JILL_MANAGER'));
    }
    areThereAnyFormErrors(contactInfoInputValues, jillnInfoInputValues) {
        return (contactInfoInputValues.formErrors.length > 0 ||
            jillnInfoInputValues.formErrors.length > 0 ||
            contactInfoInputValues.passwordError !== '' ||
            contactInfoInputValues.passwordConfirmationError !== '');
    }
    async handleUpdateJillPodsAndShiftHours() {
        (0, utilities_1.assertIsSet)(this.store.user, utilities_1.handleError, 'this.store.user');
        if (this.store.user === 'NEW_USER') {
            return;
        }
        (0, utilities_1.assertIsSet)(this.store.userJillnInfoInputValues);
        (0, utilities_1.assertIsSet)(this.store.userContactInfoInputValues);
        this.store.saving = true;
        const podIdsForMutation = getPodIdsToConnectAndDisconnect(this.store.user, this.store.userJillnInfoInputValues.selectedPods, 'MAIN_PODS');
        const floatPodIdsForMutation = getPodIdsToConnectAndDisconnect(this.store.user, this.store.userJillnInfoInputValues.selectedFloatPods, 'FLOAT_PODS');
        const updateJillPodsResult = await (0, mutations_1.updateJillPods)(this.store.user.id, podIdsForMutation, floatPodIdsForMutation);
        (0, utilities_1.assertSucceeded)(updateJillPodsResult, utilities_1.handleError);
        const updateShiftHoursResult = await (0, mutations_1.updateJillShiftHours)(this.store.user.id, this.store.userContactInfoInputValues.shiftHoursInputValue);
        (0, utilities_1.assertSucceeded)(updateShiftHoursResult);
        await this.updateUserShiftsIfNecessary();
        this.store.saving = false;
    }
    async handleUpsertUserInFull() {
        (0, utilities_1.assertIsSet)(this.store.userContactInfoInputValues, utilities_1.handleError, 'this.store.userContactInfoInputValues');
        (0, utilities_1.assertIsSet)(this.store.userJillnInfoInputValues, utilities_1.handleError, 'this.store.userJillnInfoInputValues');
        const contactInfoInputValues = this.store.userContactInfoInputValues;
        const podIdsForMutation = getPodIdsToConnectAndDisconnect(this.store.user, this.store.userJillnInfoInputValues.selectedPods, 'MAIN_PODS');
        const floatPodIdsForMutation = getPodIdsToConnectAndDisconnect(this.store.user, this.store.userJillnInfoInputValues.selectedFloatPods, 'FLOAT_PODS');
        const upsertJillInput = this.makeUpsertJillInput({
            contactInfoInputValues,
            podIdsForMutation,
            floatPodIdsForMutation,
        });
        await this.upsertJillAndHandleResult(upsertJillInput);
    }
    async upsertJillAndHandleResult(upsertJillInput) {
        const upsertJillResult = await (0, mutations_1.upsertJill)(upsertJillInput);
        if (wasEmailAlreadyTaken(upsertJillResult)) {
            (0, utilities_1.joAlert)('Alert', 'This email has already been taken. Please use a different one');
            return;
        }
        (0, utilities_1.assertSucceeded)(upsertJillResult, utilities_1.handleError);
        this.store.userId = upsertJillResult.value;
        await this.updateUserShiftsIfNecessary();
    }
    makeUpsertJillInput(params) {
        const { contactInfoInputValues, podIdsForMutation, floatPodIdsForMutation } = params;
        let result = this.buildbaseUpsertUserInput(contactInfoInputValues, floatPodIdsForMutation, podIdsForMutation);
        if (this.store.createNewPassword === true &&
            (0, utilities_1.isAllowedToUpdateUser)(this.store.authenticatedUser, this.store.user)) {
            result = { ...result, number_of_invalid_mfa_codes: 0 };
        }
        if ((0, auth_rules_1.doesUserHaveAuthRole)(this.store.authenticatedUser, 'JILL_EXECUTIVE')) {
            return this.addUpsertPermissionsInputToUpserUsersInput(result);
        }
        return result;
    }
    buildbaseUpsertUserInput(contactInfoInputValues, podIdsForMutation, floatPodIdsForMutation) {
        (0, utilities_1.assertIsSet)(contactInfoInputValues.authRoleSelectedValue, utilities_1.handleError, 'jo-user > contactInfoInputValues.authRoleSelectedValue');
        (0, utilities_1.assertIsSet)(this.store.userContactInfoInputValues, utilities_1.handleError, 'jo-user > userContactInfoInputValues');
        (0, utilities_1.assertIsSet)(this.store.userJillnInfoInputValues, utilities_1.handleError, 'jo-user > userJillnInfoInputValues');
        const baseUpsertUserInput = {
            id: this.store.userId === 'NOT_SET' ? null : this.store.userId,
            email: contactInfoInputValues.emailInputValue,
            first_name: contactInfoInputValues.firstNameInputValue,
            float_pods: {
                connect: {
                    ids: floatPodIdsForMutation.podIdsToConnect,
                },
                disconnect: {
                    ids: floatPodIdsForMutation.podIdsToDisconnect,
                },
            },
            is_24_7: this.store.userJillnInfoInputValues.is_24_7,
            is_test_user: this.store.userJillnInfoInputValues.is_test_user,
            is_account_manager: this.store.userJillnInfoInputValues.isAccountManagerSelectedValue,
            is_active: this.store.userId === 'NOT_SET'
                ? true
                : this.store.userJillnInfoInputValues.isActiveSelectedValue,
            is_personal_admin: this.store.userJillnInfoInputValues.isPersonalAdminSelectedValue,
            last_name: contactInfoInputValues.lastNameInputValue,
            phone_number: contactInfoInputValues.phoneNumberInputValue,
            pods: {
                connect: {
                    ids: podIdsForMutation.podIdsToConnect,
                },
                disconnect: {
                    ids: podIdsForMutation.podIdsToDisconnect,
                },
            },
            profile_picture_management_privileges: this.store.userJillnInfoInputValues.profilePictureManagementPrivilege,
            shift_hours: contactInfoInputValues.shiftHoursInputValue,
            team_role: contactInfoInputValues.teamRoleSelectedValue,
            time_zone: contactInfoInputValues.timeZoneSelectedValue,
            user_token: '',
        };
        if (this.store.userContactInfoInputValues.authRoleSelectedValue !== 'JILL_EXECUTIVE') {
            return this.addAuthRolAndCryptedPasswordValues(baseUpsertUserInput);
        }
        else {
            return baseUpsertUserInput;
        }
    }
    addAuthRolAndCryptedPasswordValues(baseInfo) {
        (0, utilities_1.assertIsSet)(this.store.userContactInfoInputValues, utilities_1.handleError, 'jo-user > this.store.userContactInfoInputValues');
        (0, utilities_1.assertIsSet)(this.store.userContactInfoInputValues.authRoleSelectedValue, utilities_1.handleError, 'jo-user > this.store.userContactInfoInputValues.authRoleSelectedValue');
        return {
            ...baseInfo,
            auth_role: this.store.userContactInfoInputValues.authRoleSelectedValue,
            crypted_password: this.store.createNewPassword === false
                ? null
                : this.store.userContactInfoInputValues.passwordInputValue,
        };
    }
    addUpsertPermissionsInputToUpserUsersInput(result) {
        return {
            ...result,
            permissions: {
                upsert: {
                    id: getPermissionsId(this.store.user),
                    ...this.store.permissions,
                },
            },
        };
    }
    async handleUpdateProfilePicture(file) {
        if (this.store.user === 'NOT_SET' || this.store.user === 'NEW_USER') {
            return;
        }
        if (!file) {
            alert('file must be selected');
            return;
        }
        await this.updateProfilePicture(file, this.store.user.id);
        window.location.reload();
    }
    async updateProfilePicture(file, userId) {
        const formData = getFormDataForFile(file, userId);
        this.store.updatingProfilePicture = true;
        await (0, utilities_1.sendRouterRequest)('/services/upload-jill-profile-picture', formData);
        this.store.updatingProfilePicture = false;
    }
    async updateUserShiftsIfNecessary() {
        if ((0, dequal_1.dequal)(this.store.shifts, this.store.editedShifts)) {
            return;
        }
        await this.updateUserShifts();
    }
    async updateUserShifts() {
        try {
            (0, utilities_1.assertIsSet)(this.store.userId);
            await (0, mutations_1.tryChangeUserShifts)({
                userId: this.store.userId,
                previousShifts: this.store.shifts,
                newShifts: this.store.editedShifts,
            });
        }
        catch (error) {
            (0, utilities_1.handleError)(`updateUserShifts error: ${error}`);
        }
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .jo-user--main-container {
                    box-sizing: border-box;
                    font-family: sans-serif;
                    color: var(--jo-text-default);
                    font-weight: 200;
                    width: 100%;
                }

                .jo-user--info-container {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                }

                .jo-user--info-card-container {
                    box-sizing: border-box;
                    width: 49.25%;
                }

                .jo-user--save-cancel-buttons-container {
                    box-sizing: border-box;
                    padding-top: 2rem;
                    padding-bottom: 1rem;
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                    gap: 1rem;
                }

                .jo-user--profile-picture-container {
                    padding-bottom: 1rem;
                    display: flex;
                    justify-content: flex-start;
                }

                .jo-user--profile-picture-buttons-container {
                    padding-left: 3rem;
                }
            </style>

            <jo-loading
                .props=${{
            loading: state.loading,
        }}
            ></jo-loading>

            <div class="jo-user--main-container">
                <section
                    class="jo-user--profile-picture-container"
                    ?hidden=${state.user === 'NEW_USER'}
                >
                    <img src="${(0, get_jill_profile_picture_1.getJillProfilePictureUrl)(state.user)}" style="max-height: 15rem" />

                    <div
                        class="jo-user--profile-picture-buttons-container"
                        ?hidden=${!(0, utilities_1.hasPermissionToManageJillProfilePictures)(state.authenticatedUser)}
                    >
                        <div>
                            <input
                                type="file"
                                id="jill-profile-picture-input"
                                style="font-size: 0.9rem;"
                                accept="image/jpeg, image/png, image/jpg"
                            />
                        </div>

                        <br />
                        <br />

                        <div>
                            <jo-button
                                .text=${state.updatingProfilePicture === false
            ? 'Save'
            : 'Loading...'}
                                .disabled=${state.updatingProfilePicture === true}
                                .fontSize=${'0.9rem'}
                                @joButtonClick=${async () => {
            const profilePictureInput = this.querySelector('#jill-profile-picture-input');
            const file = profilePictureInput.files[0];
            if (file == null)
                throw new Error('file is null');
            await this.handleUpdateProfilePicture(file);
        }}
                            >
                                Update
                            </jo-button>
                        </div>
                    </div>
                </section>

                <section class="jo-user--info-container">
                    <div class="jo-user--info-card-container">
                        <jo-user-contact-info
                            .authenticatedUser=${state.authenticatedUser}
                            @usercontactinfoinputvalueschanged=${(e) => {
            this.store.userContactInfoInputValues = e.detail;
        }}
                            .user=${state.user}
                            .mode=${state.mode}
                            .showAllFormErrors=${state.showAllFormErrors}
                            @createnewpasswordselected=${() => (this.store.createNewPassword = true)}
                        ></jo-user-contact-info>
                    </div>

                    <div class="jo-user--info-card-container">
                        <jo-user-jilln-info
                            .authenticatedUser=${state.authenticatedUser}
                            @userjillninfoinputvalueschanged=${(e) => {
            this.store.userJillnInfoInputValues = e.detail;
        }}
                            .globalPods=${state.globalPods}
                            .user=${state.user}
                            .mode=${state.mode}
                            @cancelnewpasswordselected=${() => (this.store.createNewPassword = false)}
                        ></jo-user-jilln-info>
                    </div>

                    <div
                        class="jo-user--info-card-container jo-global--card"
                        style="margin-top: 1rem; height: 100%;"
                    >
                        <jo-user-shifts
                            .shifts=${state.editedShifts}
                            @shiftsChanged=${(e) => {
            this.store.editedShifts = e.detail;
        }}
                        ></jo-user-shifts>
                    </div>

                    <div
                        class="jo-user--info-card-container jo-global--card"
                        style="margin-top: 1rem; height: 100%;"
                    >
                        <jo-user-permissions
                            .permissions=${state.permissions}
                            .readonly=${(0, auth_rules_1.doesUserHaveAuthRole)(state.authenticatedUser, 'JILL_EXECUTIVE') === false}
                            @permissionsValuesChanged=${(e) => {
            this.store.permissions = e.detail;
        }}
                        ></jo-user-permissions>
                    </div>
                </section>

                <div
                    ?hidden=${!(0, auth_rules_1.isJillAtOrAboveAuthRole)(state.authenticatedUser, 'JILL_MANAGER')}
                    class="jo-user--save-cancel-buttons-container"
                >
                    <jo-button
                        .type=${'SECONDARY_BUTTON_1'}
                        .text=${'Cancel'}
                        .@joButtonClick=${() => {
            (0, utilities_1.navigate)('/users');
        }}
                    ></jo-button>

                    <jo-button
                        class="jo-global--slight-padding-right-1"
                        .type=${'ACTION_BUTTON_1'}
                        .text=${state.saving === true ? 'Saving...' : 'Save'}
                        .disabled=${state.saving === true}
                        @joButtonClick=${() => this.saveButtonClicked()}
                    ></jo-button>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-user', JOUserInfo);
function getUserPermissions(user) {
    return user.permissions ?? InitialState.permissions;
}
function wasEmailAlreadyTaken(upsertJillResult) {
    return (upsertJillResult.succeeded === false &&
        upsertJillResult.developerMessage.includes(constants_1.EMAIL_ALREADY_TAKEN));
}
function getPodIdsToConnectAndDisconnect(user, selectedPods, podsType) {
    const userCurrentPodIds = getCorrectPodIdsFromUser(user, podsType);
    const podIdsToConnect = selectedPods.map(pod => pod.id);
    const podIdsToDisconnect = userCurrentPodIds.filter(podId => podIdsToConnect.includes(podId) === false);
    const result = {
        podIdsToConnect,
        podIdsToDisconnect,
    };
    return result;
}
function getCorrectPodIdsFromUser(user, podsType) {
    if (user === 'NEW_USER' || user === 'NOT_SET')
        return [];
    return podsType === 'MAIN_PODS'
        ? user.pods.map(pod => pod.id)
        : user.float_pods.map(pod => pod.id);
}
function getFormDataForFile(file, userId) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('jillId', userId.toString()); // Note, the the formData requires it to be a string
    return formData;
}
function isUserAtOrAboveAuthRoleOfOtherUser(user1AuthRole, user2AuthRole) {
    return (auth_rules_1.jillAuthRolesOrderedByPrivilege.indexOf(user1AuthRole) >=
        auth_rules_1.jillAuthRolesOrderedByPrivilege.indexOf(user2AuthRole));
}
function getPermissionsId(user) {
    if (user === 'NOT_SET' || user === 'NEW_USER' || !(0, is_defined_1.isDefined)(user.permissions)) {
        return null;
    }
    return user.permissions.id;
}
