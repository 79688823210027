"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.zapierOauthAppInfo = void 0;
exports.zapierOauthAppInfo = {
    appName: 'Zapier',
    // this needs to be filled in later
    clientId: '12345',
    logoUrl: '/images/third-party-logos/zapier-logo.svg',
    scopeAccess: {
        write: {
            willBeAbleTo: ["schedule calls for Jill's Office"],
        },
    },
};
