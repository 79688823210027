"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const graphql_1 = require("../../services/graphql");
const reduxular_1 = require("reduxular");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../utilities/environment/mapped-env-variables");
const InitialState = {
    currentAuditDue: getStartOfCurrentQuarter(new Date()),
    nextAuditDue: getStartOfNextQuarter(new Date()),
    securityAudits: [],
    auditStatus: 'INCOMPLETE',
};
function RootReducer(state = InitialState, action) {
    if (action.type === 'SET_SECURITY_AUDITS') {
        return {
            ...state,
            securityAudits: action.securityAudits,
            auditStatus: action.securityAudits.filter((securityAudit) => {
                return (new Date(securityAudit.last_checked).getTime() <
                    new Date(state.currentAuditDue).getTime());
            }).length === 0
                ? 'COMPLETE'
                : 'INCOMPLETE',
        };
    }
    return state;
}
class JOSecurityAudit extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, RootReducer);
    }
    async connectedCallback() {
        await this.fetchAndSetSecurityAudits();
    }
    async fetchAndSetSecurityAudits() {
        this.store.dispatch({
            type: 'SET_SECURITY_AUDITS',
            securityAudits: await fetchSecurityAudits(),
        });
    }
    async auditCheckboxInputChanged(e) {
        await updateSecurityAudits(parseInt(e.target.id), e.target.checked);
        this.fetchAndSetSecurityAudits();
    }
    render(state) {
        return (0, lit_html_1.html) `
            <h3>Security Audits</h3>

            <p>
                <a href="https://gitlab.com/jillsoffice/jillsoffice-web/issues/45" target="_blank">
                    Click here for the detailed issue
                </a>
            </p>

            <div>
                Status:
                <span style="color: ${state.auditStatus === 'COMPLETE' ? 'green' : 'red'}">
                    ${state.auditStatus}
                </span>
            </div>

            <br />

            <div>Next audit due: ${state.nextAuditDue.toLocaleDateString()}</div>

            <br />

            ${state.securityAudits.map((securityAudit) => {
            return (0, lit_html_1.html) `
                    <div>
                        <input
                            id=${securityAudit.id.toString()}
                            type="checkbox"
                            .checked=${new Date(securityAudit.last_checked).getTime() >=
                new Date(state.currentAuditDue).getTime()}
                            @change=${(e) => this.auditCheckboxInputChanged(e)}
                        />
                        <span title=${securityAudit.description}>${securityAudit.title}</span>
                    </div>
                `;
        })}
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-security-audits', JOSecurityAudit);
async function fetchSecurityAudits() {
    const securityAuditsGQLResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
        query {
            findSecurity_audits(orderBy: {field: "id", order: ASC}) {
                items {
                    id
                    title
                    description
                    last_checked
                }
            }
        }
    `);
    if (securityAuditsGQLResult.data === null || securityAuditsGQLResult.data === undefined) {
        const message = 'Could not retrieve security audits';
        alert(message);
        throw new Error(message);
    }
    return securityAuditsGQLResult.data.findSecurity_audits.items;
}
async function updateSecurityAudits(id, checked) {
    const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($id: Int!, $lastChecked: DateTime!) {
                updateSecurity_audits(input: {id: $id, last_checked: $lastChecked}) {
                    id
                }
            }
        `, {
        id,
        lastChecked: checked === true ? new Date().toISOString() : new Date(0).toISOString(),
    });
    if (gqlResult.data === null) {
        const message = 'Could not change security audit';
        alert(message);
        throw new Error(message);
    }
}
function getStartOfCurrentQuarter(date) {
    // TODO there is probably a nice little math trick that I could use to get rid of monthToStartOfNextQuarter, but I can't think of it right now
    const monthToStartOfCurrentQuarter = {
        0: 0,
        1: -1,
        2: -2,
        3: 0,
        4: -1,
        5: -2,
        6: 0,
        7: -1,
        8: -2,
        9: 0,
        10: -1,
        11: -2,
    };
    const month = date.getMonth();
    const startOfCurrentQuarter = monthToStartOfCurrentQuarter[month];
    if (startOfCurrentQuarter == null)
        throw new Error('startOfCurrentQuarter is null');
    const nextQuarterMonth = month + startOfCurrentQuarter;
    return new Date(date.getFullYear(), nextQuarterMonth, 1);
}
function getStartOfNextQuarter(date) {
    // TODO there is probably a nice little math trick that I could use to get rid of monthToStartOfNextQuarter, but I can't think of it right now
    const monthToStartOfNextQuarter = {
        0: 3,
        1: 2,
        2: 1,
        3: 3,
        4: 2,
        5: 1,
        6: 3,
        7: 2,
        8: 1,
        9: 3,
        10: 2,
        11: 1,
    };
    const month = date.getMonth();
    const startOfNextQuarter = monthToStartOfNextQuarter[month];
    if (startOfNextQuarter == null)
        throw new Error('startOfNextQuarter is null');
    const nextQuarterMonth = month + startOfNextQuarter;
    return new Date(date.getFullYear(), nextQuarterMonth, 1);
}
