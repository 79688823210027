"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getChatLogTableColumns = void 0;
const lit_1 = require("lit");
const utilities_1 = require("../../../../services/utilities");
const calculate_chat_duration_1 = require("../../../../utilities/chats/chat-stats/calculate-chat-duration");
function getChatLogTableColumns(authenticatedUser) {
    const columns = [
        {
            title: 'Actions',
            getCellData: (chat) => {
                return actionButtonsHtml(chat, authenticatedUser);
            },
            width: 0.5,
        },
        {
            title: 'Id',
            getCellData: (chat) => {
                return chat.id;
            },
            width: 0.5,
        },
        {
            title: 'Date',
            getCellData: (chat) => {
                return new Date(chat.created_at).toLocaleDateString();
            },
            width: 1,
        },
        {
            title: 'Duration',
            getCellData: (chat) => {
                return (0, calculate_chat_duration_1.getChatDurationString)(chat.created_at, chat.duration_events, chat.status);
            },
            width: 1,
        },
        {
            title: 'Phone Number',
            getCellData: (chat) => {
                return chat.contact?.phone_numbers[0]?.number;
            },
            width: 1,
        },
        {
            title: 'Jill',
            getCellData: (chat) => {
                return chat.currently_chatting_user?.first_name;
            },
            width: 1,
        },
    ];
    return columns;
}
exports.getChatLogTableColumns = getChatLogTableColumns;
function actionButtonsHtml(chat, authenticatedUser) {
    const actionItemId = chat.action_items[0]?.id;
    const actionItemHref = (0, utilities_1.isUserAMemberOfJillsOffice)(authenticatedUser)
        ? `/action-item?actionItemId=${actionItemId}&companyId=${chat.company.id}&callScreenMode=ACTION_ITEM`
        : `/customer/action-item?actionItemId=${actionItemId}&customerActionItemMode=EDIT`;
    return (0, lit_1.html) `
        <style>
            .action-button-container {
                display: flex;
                flex-direction: row;
                gap: 1rem;
            }
        </style>
        <div class="action-button-container">
            <jo-chat-transcript
                .props=${{
        authenticatedUser,
        actionItemContact: chat.contact,
        chat,
        openButtonType: 'ICON',
        icon: 'forum',
    }}
            ></jo-chat-transcript>
            ${actionItemId != undefined
        ? (0, lit_1.html) `
                      <jo-button
                          class="jo-action-items--red-eye-button"
                          .type=${'ICON'}
                          .icon=${'remove_red_eye'}
                          .href=${actionItemHref}
                          .inlineStyle=${'font-size: 1.3rem'}
                          style="padding-right: 0.5rem"
                      ></jo-button>
                  `
        : ``}
        </div>
    `;
}
