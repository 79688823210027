"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
require("../jo-button.element");
require("../jo-input");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
require("./jo-zip-code-table.element");
const element_vir_1 = require("element-vir");
const jo_zip_code_table_element_1 = require("./jo-zip-code-table.element");
const zip_code_1 = require("../../../utilities/contact-address/zip-code");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    isMobileDevice: false,
    company: 'NOT_SET',
    inputValues: {
        call_screen_general_information_check_county: false,
        call_screen_general_information_check_zip_code: false,
        service_area_counties: [],
        service_area_zip_codes: [],
    },
    newZipCode: '',
};
class JOCompanyCallInstructionsGeneralSetupServiceArea extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    set company(company) {
        if (company === this.store.company) {
            return;
        }
        this.store.company = company;
        if (company === 'NOT_SET') {
            this.store.inputValues = InitialState.inputValues;
        }
        else {
            this.inputValues = {
                call_screen_general_information_check_county: company.call_screen_general_information_check_county,
                call_screen_general_information_check_zip_code: company.call_screen_general_information_check_zip_code,
                service_area_counties: company.service_area_counties === ''
                    ? []
                    : company.service_area_counties.split(','),
                service_area_zip_codes: company.service_area_zip_codes === ''
                    ? []
                    : company.service_area_zip_codes.split(','),
            };
        }
    }
    set inputValues(inputValues) {
        this.store.inputValues = inputValues;
        (0, utilities_1.raiseCustomEvent)(this, 'serviceareavaluechanged', this.store.inputValues);
    }
    addZipCode() {
        const newZipCode = this.store.newZipCode.trim();
        if (newZipCode) {
            if ((0, zip_code_1.isValidZipCode)(newZipCode)) {
                this.addNewZipCodes([newZipCode]);
                this.store.newZipCode = '';
            }
            else {
                (0, utilities_1.joAlert)('Cannot add zip code', 'Invalid zip code');
            }
        }
    }
    deleteZipCode(indexToDelete) {
        this.inputValues = {
            ...this.store.inputValues,
            service_area_zip_codes: this.store.inputValues.service_area_zip_codes.filter((_, index) => {
                return indexToDelete !== index;
            }),
        };
    }
    deleteAllZipCodes() {
        this.inputValues = {
            ...this.store.inputValues,
            service_area_zip_codes: [],
        };
    }
    addCounty() {
        this.inputValues = {
            ...this.store.inputValues,
            service_area_counties: [
                ...this.store.inputValues.service_area_counties,
                '',
            ],
        };
    }
    deleteCounty(indexToDelete) {
        this.inputValues = {
            ...this.store.inputValues,
            service_area_counties: this.store.inputValues.service_area_counties.filter((_, index) => {
                return indexToDelete !== index;
            }),
        };
    }
    deleteAllCounties() {
        this.inputValues = {
            ...this.store.inputValues,
            service_area_counties: [],
        };
    }
    async importZipCodeCSV() {
        const zipCodeCSV = await (0, utilities_1.uploadCSV)();
        const zipCodeCSVLines = zipCodeCSV.split('\n');
        const maybeHeader = zipCodeCSVLines[0];
        const isNotAHeader = isNaN(Number(maybeHeader));
        const zipCodeCSVLinesWithoutHeaders = zipCodeCSVLines.slice(1);
        const zipCodes = isNotAHeader
            ? zipCodeCSVLinesWithoutHeaders
            : zipCodeCSVLines;
        this.addNewZipCodes(zipCodes);
    }
    addNewZipCodes(newZipCodes) {
        const allZipCodes = new Set([
            ...this.store.inputValues.service_area_zip_codes,
            ...newZipCodes,
        ]);
        this.inputValues = {
            ...this.store.inputValues,
            service_area_zip_codes: Array.from(allZipCodes),
        };
    }
    async importCountyCSV() {
        const countyCSV = await (0, utilities_1.uploadCSV)();
        const countyCSVLines = countyCSV.split('\n');
        const countyCSVLinesWithoutHeaders = countyCSVLines.slice(1);
        const counties = countyCSVLinesWithoutHeaders;
        this.inputValues = {
            ...this.store.inputValues,
            service_area_counties: [
                ...this.store.inputValues.service_area_counties,
                ...counties,
            ],
        };
    }
    render(state) {
        const isAllowedToUpdate = (0, utilities_1.isInAuthRole)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser);
        return (0, element_vir_1.html) `
            <style>
                .jo-company-call-instructions-general-setup-service-area--main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                }

                .jo-company-call-instructions-general-setup-service-area--check-zip-code-container {
                    padding-bottom: ${state.isMobileDevice ? '0.5rem' : '1rem'};
                    padding-top: ${state.isMobileDevice ? '0.5rem' : '1rem'};
                    font-weight: bold;
                    font-size: ${state.isMobileDevice ? 'calc(10px + 1vmin)' : ''};
                    color: var(--jo-text-default);
                }

                .jo-company-call-instructions-general-setup-service-area--check-county-container {
                    padding-bottom: ${state.isMobileDevice ? '0.5rem' : '1rem'};
                    padding-top: ${state.isMobileDevice ? '0.5rem' : '1rem'};
                    font-weight: bold;
                    font-size: ${state.isMobileDevice ? 'calc(10px + 1vmin)' : ''};
                    color: var(--jo-text-default);
                }

                .jo-company-call-instructions-general-setup-service-area--break {
                    width: 100%;
                }

                .jo-company-call-instructions-general-setup-service-area--button-container {
                    padding-bottom: 1rem;
                }

                .jo-company-call-instructions-general-setup-service-area--row {
                    display: flex;
                    padding: 0.5rem;
                }

                .jo-company-call-instructions-general-setup-service-area--input-container {
                    flex: 1;
                }

                .jo-company-call-instructions-general-setup-service-area--delete-button-container {
                    padding-left: 1rem;
                }

                .jo-company-call-instructions-general-setup-service-area--add-new-zip-code {
                    display: flex;
                    gap: 8px;
                }

                .jo-company-call-instructions-general-setup-service-area--add-button-container {
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                    padding-left: 0.5rem;
                }
                .jo-company-call-instructions-general-setup-service-area--flexRow {
                    display: flex;
                    flex-direction: row;
                }
            </style>

            <div class="jo-company-call-instructions-general-setup-service-area--main-container">
                <div class="jo-global--card-title">SERVICE AREA</div>

                <div
                    class="jo-company-call-instructions-general-setup-service-area--check-zip-code-container"
                >
                    Check zipcode
                    <input
                        ?disabled=${isAllowedToUpdate === false}
                        type="checkbox"
                        .checked=${state.inputValues.call_screen_general_information_check_zip_code}
                        @change=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                call_screen_general_information_check_zip_code: e.target.checked,
            };
        }}
                    />
                </div>

                <div
                    class="jo-company-call-instructions-general-setup-service-area--button-container"
                    ?hidden=${isAllowedToUpdate === false}
                >
                    <div class="jo-company-call-instructions-general-setup-service-area--flexRow">
                        <jo-button
                            .disabled=${isAllowedToUpdate === false}
                            .type=${'ACTION_BUTTON_2'}
                            .text=${'Import CSV list'}
                            @joButtonClick=${() => this.importZipCodeCSV()}
                        ></jo-button>

                        <jo-button
                            style="margin-left: 1rem;"
                            .type=${'ACTION_BUTTON_2'}
                            .text=${'Delete All'}
                            @joButtonClick=${() => this.deleteAllZipCodes()}
                        ></jo-button>
                    </div>
                </div>

                <div
                    class="jo-company-call-instructions-general-setup-service-area--add-new-zip-code"
                    ?hidden=${isAllowedToUpdate === false}
                >
                    <jo-input
                        .readonly=${isAllowedToUpdate === false}
                        .type=${'TEXT_INPUT'}
                        .inputValue=${this.store.newZipCode}
                        @inputchange=${(event) => {
            this.store.newZipCode = event.detail.inputValue;
        }}
                    ></jo-input>
                    <jo-button
                        .text=${'Add zipcode'}
                        .type=${'ACTION_BUTTON_2'}
                        @joButtonClick=${() => this.addZipCode()}
                    ></jo-button>
                </div>
                <div>
                    <${jo_zip_code_table_element_1.JoZipCodeTable}
                        ${(0, element_vir_1.assign)(jo_zip_code_table_element_1.JoZipCodeTable, {
            zipCodes: state.inputValues.service_area_zip_codes,
            isAllowedToUpdate,
        })}
                        ${(0, element_vir_1.listen)(jo_zip_code_table_element_1.JoZipCodeTable.events.deleteZipCode, event => {
            this.deleteZipCode(state.inputValues.service_area_zip_codes.indexOf(event.detail));
        })}
                    ></${jo_zip_code_table_element_1.JoZipCodeTable}>
                </div>

                <hr class="jo-company-call-instructions-general-setup-service-area--break" />

                <div
                    class="jo-company-call-instructions-general-setup-service-area--check-county-container"
                >
                    Check county
                    <input
                        ?disabled=${isAllowedToUpdate === false}
                        type="checkbox"
                        .checked=${state.inputValues.call_screen_general_information_check_county}
                        @change=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                call_screen_general_information_check_county: e.target.checked,
            };
        }}
                    />
                </div>

                <div
                    class="jo-company-call-instructions-general-setup-service-area--button-container"
                    ?hidden=${isAllowedToUpdate === false}
                >
                    <div class="jo-company-call-instructions-general-setup-service-area--flexRow">
                        <jo-button
                            .type=${'ACTION_BUTTON_2'}
                            .text=${'Import CSV list'}
                            @joButtonClick=${() => this.importCountyCSV()}
                        ></jo-button>

                        <jo-button
                            style="margin-left: 1rem;"
                            .type=${'ACTION_BUTTON_2'}
                            .text=${'Delete All'}
                            @joButtonClick=${() => this.deleteAllCounties()}
                        ></jo-button>
                    </div>
                </div>

                <div>
                    ${state.inputValues.service_area_counties.map((county, outerIndex) => {
            return (0, element_vir_1.html) `
                                <div
                                    class="jo-company-call-instructions-general-setup-service-area--row"
                                >
                                    <div
                                        class="jo-company-call-instructions-general-setup-service-area--input-container"
                                    >
                                        <jo-input
                                            .readonly=${isAllowedToUpdate === false}
                                            .type=${'TEXT_INPUT'}
                                            .inputValue=${county}
                                            @inputchange=${(e) => {
                this.inputValues = {
                    ...this.store.inputValues,
                    service_area_counties: this.store.inputValues.service_area_counties.map((countyInputValue, innerIndex) => {
                        if (outerIndex === innerIndex) {
                            return e.detail.inputValue;
                        }
                        return countyInputValue;
                    }),
                };
            }}
                                        ></jo-input>
                                    </div>

                                    <div
                                        class="jo-company-call-instructions-general-setup-service-area--delete-button-container"
                                    >
                                        <jo-button
                                            ?hidden=${isAllowedToUpdate === false}
                                            .type=${'ICON'}
                                            .icon=${'delete'}
                                            @joButtonClick=${() => this.deleteCounty(outerIndex)}
                                        ></jo-button>
                                    </div>
                                </div>
                            `;
        })}
                </div>

                <div
                    class="jo-company-call-instructions-general-setup-service-area--add-button-container"
                    ?hidden=${isAllowedToUpdate === false}
                >
                    <jo-button
                        .text=${'Add county'}
                        .type=${'ACTION_BUTTON_2'}
                        @joButtonClick=${() => this.addCounty()}
                    ></jo-button>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-call-instructions-general-setup-service-area', JOCompanyCallInstructionsGeneralSetupServiceArea);
