"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountHelpTicketStatus = void 0;
var AccountHelpTicketStatus;
(function (AccountHelpTicketStatus) {
    AccountHelpTicketStatus["PENDING_REVIEW"] = "PENDING_REVIEW";
    AccountHelpTicketStatus["IN_REVIEW"] = "IN_REVIEW";
    AccountHelpTicketStatus["CLOSED_RESOLVED"] = "CLOSED_RESOLVED";
    AccountHelpTicketStatus["CLOSED_NO_RESOLUTION"] = "CLOSED_NO_RESOLUTION";
    AccountHelpTicketStatus["CLOSED_AS_MISTAKE"] = "CLOSED_AS_MISTAKE";
})(AccountHelpTicketStatus = exports.AccountHelpTicketStatus || (exports.AccountHelpTicketStatus = {}));
