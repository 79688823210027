"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getActionItemFlags = void 0;
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
const graphql_1 = require("../../../services/graphql");
const utilities_1 = require("../../../services/utilities");
const error_handling_1 = require("../../../utilities/error-handling");
const null_if_not_set_1 = require("../../../utilities/null-if-not-set");
async function getActionItemFlags(params) {
    try {
        const input = getInput(params);
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($input: SearchActionItemFlagsInput!) {
                    searchActionItemFlags(input: $input) {
                        total
                        action_item_flags {
                            id
                            addressals {
                                id
                                created_at
                                description
                                user {
                                    id
                                    first_name
                                    last_name
                                }
                            }
                            mistakenly_flagged_action_items {
                                id
                                created_at
                                description
                                user {
                                    id
                                    first_name
                                    last_name
                                }
                            }
                            action_item {
                                id
                                company {
                                    id
                                    name
                                    pod {
                                        id
                                        name
                                    }
                                    account_manager {
                                        id
                                        first_name
                                        last_name
                                    }
                                }
                                call {
                                    id
                                    recording_url
                                }
                            }
                            author {
                                id
                                auth_role # auth_role fragment for auth rules
                                first_name
                                last_name
                                email
                                companies {
                                    # companies fragment for auth rules
                                    id
                                }
                            }
                            created_at
                            description
                            title
                            updated_at
                            user {
                                id
                                auth_role # auth_role fragment for auth rules
                                first_name
                                last_name
                                email
                                companies {
                                    # companies fragment for auth rules
                                    id
                                }
                            }
                        }
                    }
                }
            `, {
            input,
        });
        if (gqlResult.succeeded === false) {
            return {
                succeeded: false,
                userMessage: 'Error getActionItemFlags failed.',
                developerMessage: 'getActionItemFlags -> searchActionItemFlags Query failed.',
            };
        }
        const result = gqlResult.value.data.searchActionItemFlags;
        return {
            succeeded: true,
            value: {
                items: result.action_item_flags,
                totalItems: result.total,
            },
        };
    }
    catch (error) {
        const joFailure = (0, error_handling_1.genericJOFailure)('getActionItemFlags', error);
        (0, utilities_1.handleError)(joFailure);
        throw error;
    }
}
exports.getActionItemFlags = getActionItemFlags;
function getInput(params) {
    return {
        page: {
            offset: params.offset,
            limit: params.limit,
        },
        teamId: params.teamId,
        userId: params.jillId,
        startDate: params.startDate?.toISOString(),
        endDate: params.endDate?.toISOString(),
        searchText: params.searchText,
        sortFieldName: (0, null_if_not_set_1.nullIfNotSet)(params.column?.sortFieldName),
        sortDirection: (0, null_if_not_set_1.nullIfNotSet)(params.column?.sortDirection),
        companyId: params.companyId,
        flagType: params.flagType,
    };
}
