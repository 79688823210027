"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RootReducer = void 0;
function RootReducer(state, action) {
    if (action.type === 'SET_JILL_HIGH_LEVEL_STATSES') {
        return {
            ...state,
            jillHighLevelStatses: action.jillHighLevelStatses,
            highLevelStatsForMonthsCollapsed: action.jillHighLevelStatses.highLevelStatses.highLevelStatsForMonths.map((highLevelStatsForMonth) => {
                return {
                    highLevelStatsForMonth,
                    collapsed: true,
                };
            }),
        };
    }
    if (action.type === 'TOGGLE_HIGH_LEVEL_STATS_FOR_MONTH_COLLAPSED') {
        return {
            ...state,
            highLevelStatsForMonthsCollapsed: state.highLevelStatsForMonthsCollapsed.map((highLevelStatsForMonthCollapsed) => {
                if (new Date(highLevelStatsForMonthCollapsed.highLevelStatsForMonth.dateInMonth).getTime() ===
                    new Date(action.highLevelStatsForMonth.dateInMonth).getTime()) {
                    return {
                        ...highLevelStatsForMonthCollapsed,
                        collapsed: !highLevelStatsForMonthCollapsed.collapsed,
                    };
                }
                return highLevelStatsForMonthCollapsed;
            }),
        };
    }
    return state;
}
exports.RootReducer = RootReducer;
