"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStylesForJOBillingMobile = void 0;
const lit_html_1 = require("lit-html");
const css_utils_1 = require("../../../../utilities/css-utils");
const cssName = (0, css_utils_1.cssPrefixer)('jo-billing-mobile');
function getStylesForJOBillingMobile() {
    return (0, lit_html_1.html) `
        <style>
            .${cssName('mainContainer')} {
                height: 100%;
                width: 100%;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
            }
            .${cssName('tableContainerMobile')} {
                box-sizing: border-box;
                background-color: white;
                border-radius: var(--jo-wide-border-radius-1);
                box-shadow: var(--jo-card-shadow-1);
                padding: 1rem;
                margin: 0.5rem;
            }

            .${cssName('paddedItem')} {
                padding-bottom: var(--padding-large);
            }

            .${cssName('saveRow')} {
                display: flex;
                align-items: center;
            }

            .${cssName('navBarContainer')} {
                display: flex;
                flex-direction: row;
                border-top: 1px solid black;
                border-bottom: 1px solid black;
                margin-bottom: 1rem;
            }
            .${cssName('navBarButton')} {
                display: flex;
                align-items: center;
                padding: 0.5rem;
                font-size: 0.8rem;
                width: 33.3%;
                justify-content: center;
            }
            .${cssName('active')} {
                background-color: var(--jo-primary);
                color: white;
            }
            .${cssName('mobileButtonContainer')} {
                display: flex;
                justify-content: center;
                margin-top: 2rem;
                margin-bottom: 4rem;
            }
            .${cssName('mobileButton')} {
                color: white;
                text-decoration: none;
                background-color: var(--jo-primary);
                padding: 0.5rem 1rem;
                border-radius: 5px;
            }
        </style>
    `;
}
exports.getStylesForJOBillingMobile = getStylesForJOBillingMobile;
