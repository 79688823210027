"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStartOfWeek = exports.getStartOfCurrentMonthForDateInMountainTime = exports.getStartOfWeekForDateInMountainTime = exports.getStartOfBillingCycleInMountainTime = void 0;
const dates_and_times_1 = require("./dates-and-times");
function getStartOfBillingCycleInMountainTime(anyDateInCycle, type) {
    try {
        if (type === 'MONTHLY') {
            return getStartOfCurrentMonthForDateInMountainTime(anyDateInCycle);
        }
        else if (type === 'WEEKLY') {
            return getStartOfWeek(anyDateInCycle);
        }
        else {
            throw new Error(`Unknown billing cycle type: ${type}`);
        }
    }
    catch (error) {
        throw new Error(`getStartOfBillingCycle error: ${error}`);
    }
}
exports.getStartOfBillingCycleInMountainTime = getStartOfBillingCycleInMountainTime;
function getStartOfWeekForDateInMountainTime(anyDateInWeek) {
    try {
        return (0, dates_and_times_1.addMountainTimeOffset)(getStartOfWeek(anyDateInWeek));
    }
    catch (error) {
        throw new Error(`getStartOfCurrentWeekForDateInMountainTime error: ${error}`);
    }
}
exports.getStartOfWeekForDateInMountainTime = getStartOfWeekForDateInMountainTime;
function getStartOfCurrentMonthForDateInMountainTime(date) {
    return (0, dates_and_times_1.addMountainTimeOffset)(new Date(date.getFullYear(), date.getMonth(), 1));
}
exports.getStartOfCurrentMonthForDateInMountainTime = getStartOfCurrentMonthForDateInMountainTime;
// NOTE: we are counting Monday as the start of the week
function getStartOfWeek(anyDateInWeek) {
    try {
        if (anyDateInWeek.getDay() === 1) {
            return new Date(anyDateInWeek.getFullYear(), anyDateInWeek.getMonth(), anyDateInWeek.getDate());
        }
        const numDaysToGoBack = isSunday(anyDateInWeek.getDay()) ? 6 : anyDateInWeek.getDay() - 1;
        const startOfWeek = new Date(anyDateInWeek.getFullYear(), anyDateInWeek.getMonth(), anyDateInWeek.getDate() - numDaysToGoBack);
        return startOfWeek;
    }
    catch (error) {
        throw new Error(`getStartOfCurrentWeek error: ${error}`);
    }
}
exports.getStartOfWeek = getStartOfWeek;
function isSunday(dayInWeek) {
    return dayInWeek === 0;
}
