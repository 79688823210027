"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../../services/utilities");
const css_utils_1 = require("../../../../utilities/css-utils");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
require("../../jo-button.element");
const publish_outbound_queue_item_updated_event_1 = require("../../jo-call-screen/utilities/publish-outbound-queue-item-updated-event");
const helper_utils_1 = require("../../jo-company/jo-company-contacts/helper-utils");
const queries_and_mutations_1 = require("../../jo-company/jo-company-contacts/queries-and-mutations");
require("../../jo-input");
const mutations_1 = require("./mutations");
const styles_1 = require("./styles");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    campaignName: '',
    companyId: 'NOT_SET',
    shouldHidePopUp: true,
    numCallAttempts: 1,
    numDaysBetweenAttempts: 0,
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-create-outbound-list');
class JOCreateOutboundList extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    updateCallAttemptsInStateWithoutNegativeNumbers(e) {
        const newValue = Number(e.detail.inputValue);
        if (newValue < 0) {
            this.store.numCallAttempts = newValue * -1;
            return;
        }
        this.store.numCallAttempts = newValue;
    }
    updateDaysBetweenAttemptsInStateWithoutNegativeNumbers(e) {
        const newValue = Number(e.detail.inputValue);
        if (newValue < 0) {
            this.store.numDaysBetweenAttempts = newValue * -1;
            return;
        }
        this.store.numDaysBetweenAttempts = newValue;
    }
    addCSVListClicked() {
        this.createOutboundListFromCSV();
        this.store.shouldHidePopUp = true;
    }
    async createOutboundListFromCSV() {
        const companyId = this.store.companyId;
        const numCallAttempts = this.store.numCallAttempts;
        const numDaysBetweenAttempts = this.store.numDaysBetweenAttempts;
        const campaignName = this.store.campaignName;
        (0, utilities_1.assertIsSet)(companyId, utilities_1.handleError);
        validateInput(numCallAttempts, campaignName);
        const { validCSVRecords, invalidCSVRecords } = (0, helper_utils_1.getValidAndInvalidCSVRecords)((0, helper_utils_1.preProcessContactsCSV)(await (0, utilities_1.uploadCSV)()));
        if (validCSVRecords.length === 0) {
            await (0, utilities_1.joAlert)('Failure', 'No valid records');
            return;
        }
        const addContactsFromCSVToCompanyResult = await (0, queries_and_mutations_1.addAllContactsFromCSVToCompany)(companyId, (0, helper_utils_1.removeSpecialCharactersFromCSV)(validCSVRecords));
        if (addContactsFromCSVToCompanyResult.succeeded === false) {
            (0, utilities_1.joAlert)('Failure', 'Something went wrong');
            return addContactsFromCSVToCompanyResult;
        }
        const contactIds = [...addContactsFromCSVToCompanyResult.value];
        await this.createOutboundQueueItems({
            companyId,
            numCallAttempts,
            numDaysBetweenAttempts,
            contactIds,
            campaignName,
        });
        await this.showConfirmationUIs(validCSVRecords, invalidCSVRecords);
        (0, utilities_1.raiseCustomEvent)(this, 'outboundListCreated');
    }
    async showConfirmationUIs(validCSVRecords, invalidCSVRecords) {
        await (0, utilities_1.joAlert)('Success', `Successfully created ${validCSVRecords.length} contacts`);
        this.clearInputBoxes();
        if (invalidCSVRecords.length > 0) {
            (0, helper_utils_1.addPopUpWindowForInvalidRecords)(invalidCSVRecords);
        }
    }
    clearInputBoxes() {
        this.store.campaignName = '';
        this.store.numCallAttempts = 1;
        this.store.numDaysBetweenAttempts = 0;
    }
    async createOutboundQueueItems(params) {
        await (0, mutations_1.createMultipleOutboundQueueItemsForCompany)(params);
        await (0, publish_outbound_queue_item_updated_event_1.publishOutboundQueueUpdatedMessage)();
        (0, utilities_1.raiseCustomEvent)(this, 'uploadFinish');
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

            ${styles_1.stylesForjoCreateOutboundList}

            <div class="${cssName('main-container')}">
                <jo-button
                    .type=${'ACTION_BUTTON_1'}
                    .text=${'Create Outbound List'}
                    @joButtonClick=${() => (this.store.shouldHidePopUp = false)}
                ></jo-button>

                <section ?hidden=${state.shouldHidePopUp} class="${cssName('popUp-background')}">
                    <div class="${cssName('popUp-container')}">
                        <i
                            @click=${() => (this.store.shouldHidePopUp = true)}
                            class="material-icons ${cssName('close')}"
                        >
                            close
                        </i>

                        <div class="${cssName('popUp-title')}">Add List to Outbound</div>

                        <jo-input
                            class="${cssName('popUp-input')}"
                            .label=${'Campaign Name'}
                            .type=${'TEXT_INPUT'}
                            .inputValue=${state.campaignName}
                            @inputchange=${(e) => {
            this.store.campaignName = e.detail.inputValue.trim();
        }}
                        ></jo-input>

                        <jo-input
                            class="${cssName('popUp-input')}"
                            .label=${'Number of Call Attempts'}
                            .type=${'NUMBER'}
                            .inputValue=${state.numCallAttempts}
                            @inputchange=${(e) => {
            this.updateCallAttemptsInStateWithoutNegativeNumbers(e);
        }}
                        ></jo-input>

                        <jo-input
                            class="${cssName('popUp-input')}"
                            .label=${'Number of Days Between Attempts'}
                            .type=${'NUMBER'}
                            .inputValue=${state.numDaysBetweenAttempts}
                            @inputchange=${(e) => {
            this.updateDaysBetweenAttemptsInStateWithoutNegativeNumbers(e);
        }}
                        ></jo-input>

                        <div class="${cssName('popUp-input')}">
                            <jo-button
                                .type=${'ACTION_BUTTON_1'}
                                .text=${'Add CSV List'}
                                @joButtonClick="${() => {
            this.addCSVListClicked();
        }}"
                            ></jo-button>
                        </div>
                    </div>
                </section>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-create-outbound-list', JOCreateOutboundList);
function validateInput(numCallAttempts, campaignName) {
    if (numCallAttempts === 0) {
        (0, utilities_1.joAlert)('Error', 'Number of Call Attempts Cannot Be 0');
        throw new Error('Number of Call Attempts Cannot Be 0');
    }
    if (campaignName.trim() === '') {
        (0, utilities_1.joAlert)('Error', 'Must have a campaign name for this list');
        throw new Error('Must have a campaign name for this list');
    }
}
