"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.upsertCompanySectionNotes = exports.fetchCompanySectionNotes = void 0;
const graphql_1 = require("../../../services/graphql");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function fetchCompanySectionNotes(companyId) {
    const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            query ($companyId: Int!) {
                company: getCompanies(id: $companyId) {
                    id
                    call_screen_section_notes(orderBy: {field: "id", order: DESC}) {
                        id
                        created_at
                        custom_questions_notes
                        pricing_notes
                        services_notes
                        transfer_notes
                    }
                }
            }
        `, {
        companyId,
    });
    if (result.succeeded === false) {
        return result;
    }
    const companyNotes = result.value.data.company;
    return {
        succeeded: true,
        value: companyNotes,
    };
}
exports.fetchCompanySectionNotes = fetchCompanySectionNotes;
async function upsertCompanySectionNotes(params) {
    try {
        const { companyId, callScreenSectionNotesId, transferNotes, customQuestionsNotes, pricingNotes, servicesNotes, } = params;
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation (
                    $callScreenSectionNotesId: Int
                    $companyId: Int!
                    $transferNotes: String!
                    $customQuestionsNotes: String!
                    $pricingNotes: String!
                    $servicesNotes: String!
                ) {
                    upsertCall_screen_section_notes(
                        input: {
                            id: $callScreenSectionNotesId
                            company: {connect: {id: $companyId}}
                            transfer_notes: $transferNotes
                            custom_questions_notes: $customQuestionsNotes
                            pricing_notes: $pricingNotes
                            services_notes: $servicesNotes
                        }
                    ) {
                        id
                    }
                }
            `, {
            callScreenSectionNotesId: callScreenSectionNotesId === -1 ? null : callScreenSectionNotesId,
            companyId,
            transferNotes,
            customQuestionsNotes,
            pricingNotes,
            servicesNotes,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        return { succeeded: true };
    }
    catch (error) {
        return genericJoFailure('upsertCompanySectionNotes', error);
    }
}
exports.upsertCompanySectionNotes = upsertCompanySectionNotes;
function genericJoFailure(arg0, error) {
    throw new Error('Function not implemented.');
}
