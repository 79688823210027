"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCompanyCurrentlyUsingChat = void 0;
const common_1 = require("@augment-vir/common");
function isCompanyCurrentlyUsingChat(companyStatusEvents) {
    if (companyStatusEvents.length === 0) {
        return false;
    }
    const sortedStatusEvents = getSortedCompanyStatusEvents(companyStatusEvents);
    if (isCompanyOnHold(sortedStatusEvents) || isCompanyCancelled(sortedStatusEvents)) {
        return false;
    }
    const chatStatusEvents = getChatCompanyStatusEvents(sortedStatusEvents);
    if (chatStatusEvents.length === 0) {
        return false;
    }
    (0, common_1.assertLengthAtLeast)(chatStatusEvents, 1);
    const mostRecentChatStatusEvent = chatStatusEvents[0];
    return mostRecentChatStatusEvent.type === 'STARTED_USING_CHAT';
}
exports.isCompanyCurrentlyUsingChat = isCompanyCurrentlyUsingChat;
function getChatCompanyStatusEvents(sortedStatusEvents) {
    return sortedStatusEvents.filter(statusEvent => statusEvent.type === 'STARTED_USING_CHAT' || statusEvent.type === 'STOPPED_USING_CHAT');
}
function getSortedCompanyStatusEvents(companyStatusEvents) {
    return [...companyStatusEvents].sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
}
function isCompanyOnHold(statusEvents) {
    (0, common_1.assertLengthAtLeast)(statusEvents, 1);
    const mostRecentStatusEvent = statusEvents[0];
    return mostRecentStatusEvent.type === 'WENT_ON_HOLD';
}
function isCompanyCancelled(statusEvents) {
    (0, common_1.assertLengthAtLeast)(statusEvents, 1);
    const mostRecentStatusEvent = statusEvents[0];
    return mostRecentStatusEvent.type === 'CANCELED';
}
