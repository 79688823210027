"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatAsDateAndTime = exports.formatAsUtcDateString = void 0;
const common_1 = require("@augment-vir/common");
/**
 * Same as Date.prototype.toDateString() but uses UTC time instead of local time.
 *
 * Example output: "Tue Dec 13 2022"
 */
function formatAsUtcDateString(date) {
    // this gives us a string of the format: "Tue, 13 Dec 2022 17:10:28 GMT"
    const utcStringWithTime = date.toUTCString();
    const [, dayNameOfTheWeek, dayNumberOfTheMonth, monthName, fullYear,] = (0, common_1.safeMatch)(utcStringWithTime, /(\w+),\s+(\d+)\s+(\w+)\s+(\d+)\s+[\d:]+\s+GMT/i);
    // this gives us a string of the format: "Tue Dec 13 2022"
    const utcDateString = `${dayNameOfTheWeek} ${monthName} ${dayNumberOfTheMonth} ${fullYear}`;
    return utcDateString;
}
exports.formatAsUtcDateString = formatAsUtcDateString;
function formatAsDateAndTime(possibleDate) {
    const date = possibleDate instanceof Date ? possibleDate : new Date(possibleDate);
    const localeDateString = date.toLocaleDateString('en');
    const timeString = date.toLocaleTimeString('en');
    return `${localeDateString} ${timeString}`;
}
exports.formatAsDateAndTime = formatAsDateAndTime;
