"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.authRoleOptions = exports.scheduleStatusOptions = exports.workStatusOptions = exports.doesJillMatchPodName = exports.doesJillNameIncludeText = exports.doesJillMatchTeamName = exports.extractTeamIdFromTeamName = exports.filterJillsByTeamName = exports.filterJillsByAuthRole = exports.filterJillsByScheduleStatus = exports.filterJillsByWorkStatus = exports.doesjillMatchOnlineStatus = exports.filterJillsByOnlineStatus = exports.filterJillsByChatStatus = exports.doesjillMatchCallStatus = exports.filterJillsByCallStatus = exports.doesjillMatchAvailabilityStatus = exports.filterJillsByAvailabilityStatus = exports.doesJillMatchSearchText = exports.filterJillsBySearchText = exports.filterJillsByPodName = exports.isJillInFilteredJills = exports.sortJills = exports.getManagerForTeam = exports.buildTeamName = exports.getTeamName = exports.defaultOrderByOptions = void 0;
const is_jill_likely_on_a_call_1 = require("../../../../utilities/is-jill-likely-on-a-call");
const last_seen_logged_in_1 = require("../../../../utilities/last-seen-logged-in");
const is_user_scheduled_to_be_on_break_1 = require("../../../../utilities/schedule-items/is-user-scheduled-to-be-on-break");
const is_user_scheduled_to_be_on_lunch_1 = require("../../../../utilities/schedule-items/is-user-scheduled-to-be-on-lunch");
const is_user_scheduled_to_be_working_1 = require("../../../../utilities/schedule-items/is-user-scheduled-to-be-working");
const make_chat_status_1 = require("../../jo-jill-pods/utils/make-chat-status");
exports.defaultOrderByOptions = [
    'Name',
    'Last Call Started At',
    'Last Call Completed At',
];
function getTeamName(team) {
    const manager = getManagerForTeam(team);
    const teamName = buildTeamName(team, manager);
    return teamName;
}
exports.getTeamName = getTeamName;
function buildTeamName(team, manager) {
    try {
        const managerName = getManagerName(manager);
        return `Team ${team.id} (${managerName})`;
    }
    catch (error) {
        throw new Error(`buildTeamName error: ${error}`);
    }
}
exports.buildTeamName = buildTeamName;
function getManagerName(manager) {
    return manager === null || manager === undefined
        ? 'No Manager'
        : `${manager?.first_name} ${manager?.last_name}`;
}
function getManagerForTeam(team) {
    return team.members.filter(member => member.team_role === 'MANAGER')[0];
}
exports.getManagerForTeam = getManagerForTeam;
function sortJills(jills, sortOption) {
    return [...jills].sort((a, b) => {
        if (sortOption === 'Last Call Completed At') {
            return diffByLastCallCompletedAtMostRecentFirst(a, b);
        }
        if (sortOption === 'Last Call Started At') {
            return diffByLastCallStartedAtMostRecentFirst(a, b);
        }
        if (sortOption === 'Name') {
            return a.first_name?.localeCompare(b.first_name ?? '') ?? 0;
        }
        return 0;
    });
}
exports.sortJills = sortJills;
function diffByLastCallStartedAtMostRecentFirst(a, b) {
    return getSortValueForCallTimeBetweenJills(a, b, 'last_call_started_at', 'DESC');
}
function diffByLastCallCompletedAtMostRecentFirst(a, b) {
    return getSortValueForCallTimeBetweenJills(a, b, 'last_call_completed_at', 'DESC');
}
function getSortValueForCallTimeBetweenJills(jillA, jillB, key, order) {
    const diff = order === 'ASC'
        ? new Date(jillA[key] ?? 0).getTime() - new Date(jillB[key] ?? 0).getTime()
        : new Date(jillB[key] ?? 0).getTime() - new Date(jillA[key] ?? 0).getTime();
    return diff < 0 ? -1 : diff > 0 ? 1 : 0;
}
function isJillInFilteredJills(jill, filteredJills) {
    return filteredJills.some(filteredJill => jill.id === filteredJill.id);
}
exports.isJillInFilteredJills = isJillInFilteredJills;
function filterJillsByPodName(jills, podName) {
    if (podName === '' || podName === 'All Pods') {
        return jills;
    }
    return jills.filter(jill => doesJillMatchPodName(jill, podName));
}
exports.filterJillsByPodName = filterJillsByPodName;
function filterJillsBySearchText(jills, searchText) {
    if (searchText === '') {
        return jills;
    }
    return jills.filter(jill => {
        return doesJillMatchSearchText(jill, searchText);
    });
}
exports.filterJillsBySearchText = filterJillsBySearchText;
function doesJillMatchSearchText(jill, searchText) {
    return doesJillNameIncludeText(jill, searchText) || doesJillMatchPodName(jill, searchText);
}
exports.doesJillMatchSearchText = doesJillMatchSearchText;
function filterJillsByAvailabilityStatus(jills, availabilityStatus) {
    const result = jills.filter(jill => {
        return doesjillMatchAvailabilityStatus(jill, availabilityStatus);
    });
    return result;
}
exports.filterJillsByAvailabilityStatus = filterJillsByAvailabilityStatus;
function doesjillMatchAvailabilityStatus(jill, availabilityStatus) {
    if (availabilityStatus === 'Available')
        return jill.is_available;
    else if (availabilityStatus === 'Unavailable')
        return !jill.is_available;
    else
        return true;
}
exports.doesjillMatchAvailabilityStatus = doesjillMatchAvailabilityStatus;
function filterJillsByCallStatus(jills, callStatus) {
    const result = jills.filter(jill => {
        return doesjillMatchCallStatus(jill, callStatus);
    });
    return result;
}
exports.filterJillsByCallStatus = filterJillsByCallStatus;
function doesjillMatchCallStatus(jill, callStatus) {
    if (callStatus === 'On a call')
        return (0, is_jill_likely_on_a_call_1.wasJillSeenOnACallWithinTenSecondsAgo)(jill);
    else if (callStatus === 'Not on a call')
        return !(0, is_jill_likely_on_a_call_1.wasJillSeenOnACallWithinTenSecondsAgo)(jill);
    else
        return true;
}
exports.doesjillMatchCallStatus = doesjillMatchCallStatus;
function filterJillsByChatStatus(jills, chatStatus) {
    const result = jills.filter(jill => {
        if (chatStatus === 'All')
            return true;
        const jillStatus = (0, make_chat_status_1.makeChatStatus)(jill.last_seen_in_chat_mode, jill.last_seen_actively_chatting_at);
        return chatStatus === jillStatus;
    });
    return result;
}
exports.filterJillsByChatStatus = filterJillsByChatStatus;
function filterJillsByOnlineStatus(jills, onlineStatus) {
    const result = jills.filter(jill => {
        return doesjillMatchOnlineStatus(jill, onlineStatus);
    });
    return result;
}
exports.filterJillsByOnlineStatus = filterJillsByOnlineStatus;
function doesjillMatchOnlineStatus(jill, onlineStatus) {
    if (onlineStatus === 'Online')
        return (0, last_seen_logged_in_1.wasJillSeenLoggedInWithinXMillisecondsAgo)(jill);
    else if (onlineStatus === 'Offline')
        return !(0, last_seen_logged_in_1.wasJillSeenLoggedInWithinXMillisecondsAgo)(jill);
    else
        return true;
}
exports.doesjillMatchOnlineStatus = doesjillMatchOnlineStatus;
function filterJillsByWorkStatus(jills, workStatus) {
    const result = jills.filter(jill => {
        return doesjillMatchWorkStatus(jill, workStatus);
    });
    return result;
}
exports.filterJillsByWorkStatus = filterJillsByWorkStatus;
function doesjillMatchWorkStatus(jill, workStatus) {
    if (workStatus === 'Clocked In')
        return jill.work_status === 'CLOCKED_IN';
    else if (workStatus === 'Clocked Out')
        return jill.work_status === 'CLOCKED_OUT';
    else if (workStatus === 'On Break')
        return jill.work_status === 'ON_BREAK';
    else if (workStatus === 'On Lunch')
        return jill.work_status === 'ON_LUNCH';
    else
        return true;
}
function filterJillsByScheduleStatus(jills, scheduleStatus) {
    const result = jills.filter(jill => doesjillMatchScheduleStatus(jill, scheduleStatus));
    return result;
}
exports.filterJillsByScheduleStatus = filterJillsByScheduleStatus;
function doesjillMatchScheduleStatus(jill, scheduleStatus) {
    if (scheduleStatus === 'All')
        return true;
    const now = new Date();
    if (scheduleStatus === `Scheduled to be On Break`) {
        return (0, is_user_scheduled_to_be_on_break_1.isUserScheduledToBeOnBreak)(jill.shifts, now);
    }
    if (scheduleStatus === `Scheduled to be On Lunch`) {
        return (0, is_user_scheduled_to_be_on_lunch_1.isUserScheduledToBeOnLunch)(jill.shifts, now);
    }
    const isScheduledToBeWorking = (0, is_user_scheduled_to_be_working_1.isUserScheduledToBeWorking)(jill.shifts, now);
    if (scheduleStatus === `Scheduled to be Working`) {
        return isScheduledToBeWorking;
    }
    return isScheduledToBeWorking === false;
}
function filterJillsByAuthRole(jills, selectedAuthRole) {
    if (selectedAuthRole === 'All')
        return jills;
    return jills.filter(jill => jill.auth_role === selectedAuthRole);
}
exports.filterJillsByAuthRole = filterJillsByAuthRole;
function filterJillsByTeamName(jills, selectedTeamName) {
    if (selectedTeamName === 'All Teams')
        return jills;
    return jills.filter(jill => {
        return doesJillMatchTeamName(jill, selectedTeamName);
    });
}
exports.filterJillsByTeamName = filterJillsByTeamName;
function extractTeamIdFromTeamName(selectedTeamName) {
    const teamNameFirstChop = chopOffEndingParentheses(selectedTeamName);
    if (!teamNameFirstChop)
        return null;
    const teamIdAsString = chopOffTheWordTeam(teamNameFirstChop);
    if (teamIdAsString == null)
        throw new Error('teamIdAsString is null');
    return parseInt(teamIdAsString);
}
exports.extractTeamIdFromTeamName = extractTeamIdFromTeamName;
function chopOffTheWordTeam(teamNameFirstChop) {
    return teamNameFirstChop.split(/Team\s/)[1];
}
function chopOffEndingParentheses(selectedTeamName) {
    return selectedTeamName.split(/\s\(.*\)/)[0];
}
function doesJillMatchTeamName(jill, selectedTeamName) {
    try {
        const teamId = extractTeamIdFromTeamName(selectedTeamName);
        if (!teamId)
            return true;
        return jill.team?.id === teamId;
    }
    catch (error) {
        throw new Error(`doesJillMatchTeamName error: ${error}`);
    }
}
exports.doesJillMatchTeamName = doesJillMatchTeamName;
function doesJillNameIncludeText(jill, searchText) {
    return (jill.first_name?.toLowerCase().includes(searchText.toLowerCase()) ||
        jill.last_name?.toLowerCase().includes(searchText.toLowerCase()) ||
        `${jill.first_name?.toLowerCase()} ${jill.last_name?.toLowerCase()}`.includes(searchText.toLowerCase()));
}
exports.doesJillNameIncludeText = doesJillNameIncludeText;
function doesJillMatchPodName(jill, searchText) {
    return (doAnyMainPodsForJillMatchSearchText(jill, searchText) ||
        doAnyFloadPodsForJillMatchSearchText(jill, searchText));
}
exports.doesJillMatchPodName = doesJillMatchPodName;
function doAnyFloadPodsForJillMatchSearchText(jill, searchText) {
    return jill.float_pods.some(floatPod => doesPodIncludeSearchText(floatPod, searchText));
}
function doAnyMainPodsForJillMatchSearchText(jill, searchText) {
    return jill.pods.some(pod => doesPodIncludeSearchText(pod, searchText));
}
function doesPodIncludeSearchText(pod, searchText) {
    return (pod !== null &&
        pod !== undefined &&
        pod.name !== null &&
        pod.name !== undefined &&
        pod.name.toLowerCase().includes(searchText.toLowerCase()));
}
exports.workStatusOptions = [
    'All',
    'Clocked In',
    'Clocked Out',
    'On Break',
    'On Lunch',
];
exports.scheduleStatusOptions = [
    'All',
    'Scheduled to be Working',
    'Scheduled to be On Break',
    'Scheduled to be On Lunch',
    'Scheduled to be Off',
];
exports.authRoleOptions = [
    'All',
    'JILL',
    'JILL_OUTBOUND',
    'JILL_MANAGER',
    'JILL_BUSINESS_DEVELOPMENT',
    'JILL_EXECUTIVE',
];
