"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergeSequentialInvoicesWithSameMinutesPackage = void 0;
const is_defined_1 = require("../../../../utilities/is-defined");
function mergeSequentialInvoicesWithSameMinutesPackage(result, invoice) {
    const previousInvoice = result.pop();
    addInvoicesAsNecessary(result, previousInvoice, invoice);
    return result;
}
exports.mergeSequentialInvoicesWithSameMinutesPackage = mergeSequentialInvoicesWithSameMinutesPackage;
function addInvoicesAsNecessary(result, previousInvoice, invoice) {
    if (!previousInvoice)
        addInvoice(result, invoice);
    else if (doInvoicesHaveSameMinutesPackage(previousInvoice, invoice))
        addMergedInvoice(result, previousInvoice, invoice);
    else
        addBothInvoices(result, previousInvoice, invoice);
}
function doInvoicesHaveSameMinutesPackage(previousInvoice, invoice) {
    return ((0, is_defined_1.isDefined)(previousInvoice.minutes_package?.id) &&
        previousInvoice.minutes_package?.id === invoice.minutes_package?.id);
}
function addMergedInvoice(result, previousInvoice, invoice) {
    result.push(mergeInvoices(previousInvoice, invoice));
}
function addInvoice(result, invoice) {
    result.push(invoice);
}
function addBothInvoices(result, previousInvoice, invoice) {
    result.push(previousInvoice);
    result.push(invoice);
}
function mergeInvoices(previousInvoice, invoice) {
    return {
        ...previousInvoice,
        end_date: invoice.end_date,
    };
}
