"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.constructUsableTicketUpdatesForLog = void 0;
const utilities_1 = require("../../../../services/utilities");
function constructUsableTicketUpdatesForLog(helpTicket) {
    const firstUpdate = buildFirstUpdate(helpTicket);
    const otherUpdates = buildRemainingUpdates(helpTicket);
    const rawUpdates = [
        firstUpdate,
        ...otherUpdates,
    ];
    return (0, utilities_1.sortItemsByTimeStamp)(rawUpdates, 'CREATED_AT', 'DESC');
}
exports.constructUsableTicketUpdatesForLog = constructUsableTicketUpdatesForLog;
function buildRemainingUpdates(helpTicket) {
    return helpTicket.updates.map(helpTicket => ({ ...helpTicket, original: false }));
}
function buildFirstUpdate(helpTicket) {
    return {
        user: helpTicket.user,
        created_at: helpTicket.created_at,
        status: helpTicket.current_status,
        comment: helpTicket.description,
        original: true,
    };
}
