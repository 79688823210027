"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergeWeeklyLineItemsOnInvoice = void 0;
const sort_by_id_1 = require("../data-structures/lists/sort-by-id");
function mergeWeeklyLineItemsOnInvoice(invoice) {
    return {
        ...invoice,
        invoice_line_items: (0, sort_by_id_1.sortById)(mergeWeeklyInvoiceLineItems(invoice.invoice_line_items || [])),
    };
}
exports.mergeWeeklyLineItemsOnInvoice = mergeWeeklyLineItemsOnInvoice;
function mergeWeeklyInvoiceLineItems(invoiceLineItems) {
    const lineItemsToBeMerged = getWeeklyLineItemsToBeMerged(invoiceLineItems);
    const lineItemsToKeep = getLineItemsToKeep(invoiceLineItems);
    if (lineItemsToBeMerged.length === 0)
        return invoiceLineItems;
    const mergedLineItem = mergeLineItemsIntoSingleMinutesPackageLineItem(lineItemsToBeMerged);
    return [
        ...lineItemsToKeep,
        mergedLineItem,
    ];
}
function mergeLineItemsIntoSingleMinutesPackageLineItem(lineItemsToBeMerged) {
    const initial = makeInitialMinutesPackageLineItem(lineItemsToBeMerged);
    return lineItemsToBeMerged.reduce((result, lineItem) => {
        return {
            ...result,
            amount: getAmount(result.amount) + getAmount(lineItem.amount),
        };
    }, initial);
}
function makeInitialMinutesPackageLineItem(lineItemsToBeMerged) {
    const initial = {
        id: -1,
        description: 'Minutes Package',
        amount: 0,
    };
    if (lineItemsToBeMerged[0]?.invoice) {
        return {
            ...initial,
            invoice: lineItemsToBeMerged[0].invoice,
        };
    }
    return initial;
}
function getAmount(value) {
    return value || 0;
}
function getLineItemsToKeep(invoiceLineItems) {
    return invoiceLineItems.filter(x => ![
        'Base Plan',
        'Bank of Minutes',
    ].includes(x.description || ''));
}
function getWeeklyLineItemsToBeMerged(invoiceLineItems) {
    return invoiceLineItems.filter(x => [
        'Base Plan',
        'Bank of Minutes',
    ].includes(x.description || ''));
}
