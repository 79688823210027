"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const store_1 = require("../../../services/store");
const utilities_1 = require("../../../services/utilities");
const get_company_status_events_1 = require("../../../utilities/company-status-events/get-company-status-events");
const is_company_currently_using_chat_1 = require("../../../utilities/company-status-events/is-company-currently-using-chat");
const is_company_currently_using_crewCal_1 = require("../../../utilities/company-status-events/is-company-currently-using-crewCal");
const user_status_event_mutations_1 = require("../../../utilities/logging-and-monitoring/user-status-event-mutations");
const logout_1 = require("../../../utilities/logout");
require("./jo-customer-sidebar-item");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const test_id_directive_1 = require("../../directives/test-id.directive");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    company: 'NOT_SET',
    companyId: 'NOT_SET',
    isMobileDevice: store_1.InitialGlobalState.isMobileDevice,
    selectedMainOption: 'NOT_SET',
    isEnrolledInCrewCal: false,
    isEnrolledInChat: false,
};
class JOCustomerSidebar extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    set companyId(companyId) {
        this.store.companyId = companyId;
        if (companyId !== 'NOT_SET') {
            this.fetchCompanyStatusEventsAndSetDependentSidebarItemsVisibility();
        }
    }
    async fetchCompanyStatusEventsAndSetDependentSidebarItemsVisibility() {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        const companyStatusEventResult = await (0, get_company_status_events_1.getCompanyStatusEvents)(this.store.companyId);
        (0, utilities_1.assertSucceeded)(companyStatusEventResult, utilities_1.handleError);
        this.setIsEnrolledInCrewCal(companyStatusEventResult.value);
        this.setIsEnrolledInChat(companyStatusEventResult.value);
    }
    setIsEnrolledInCrewCal(companyStatusEvents) {
        this.store.isEnrolledInCrewCal = (0, is_company_currently_using_crewCal_1.isCompanyCurrentlyUsingCrewCal)(companyStatusEvents);
    }
    setIsEnrolledInChat(companyStatusEvents) {
        this.store.isEnrolledInChat = (0, is_company_currently_using_chat_1.isCompanyCurrentlyUsingChat)(companyStatusEvents);
    }
    // TODO this is now copied in jo-top-bar.ts and jo-customer-sidebar.ts
    async logoutClick() {
        try {
            if (this.store.authenticatedUser !== 'NOT_SET' && this.store.companyId !== 'NOT_SET') {
                (0, user_status_event_mutations_1.createUserStatusEvent)({
                    userId: this.store.authenticatedUser.id,
                    eventType: 'LOGGED_OUT',
                    companyId: this.store.companyId,
                });
            }
            const logoutResult = await (0, logout_1.logout)();
            if (logoutResult === false) {
                throw new Error('Could not logout');
            }
            // we are doing this to ensure that all state stored in memory (mostly web sockets) is reset
            // there may be a better way to do this in the future...but this is simple and I do not think
            // provides a very poor UX. The alternative to ensure that web sockets are reset is to somehow reset
            // all websockets wherever they are stored on logout...this would be hard to do as websockets are stored
            // throughout multiple components, though we could also simply store all websockets in the global store,
            // but that might not be ideal either. So this is the tradeoff we are choosing for now
            window.location.assign('/login?sign_out=true'); // NOTE: the ?sign_out is for the ios and android device to remove userToken. It will only appear in the url momentarily
        }
        catch (error) {
            alert(error);
        }
    }
    render(state) {
        const shouldDisplayGoogleCalendarSideBarItem = (0, utilities_1.getIsCompanyOwner)(state.authenticatedUser, state.companyId) &&
            state.isEnrolledInCrewCal;
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    background-color: var(--jo-primary);
                    overflow-y: auto;
                }

                .logo-container {
                    padding: 1rem;
                }

                .logo {
                    ${state.isMobileDevice === true ? `width:50%;` : `width:100%;`};
                }

                .sidebar-item-group-title {
                    text-decoration: underline;
                    color: white;
                    padding: .5rem;
                }

                .sidebar-item-separator-container {
                    padding: .5rem;
                }

                .sidebar-item-separator {
                    border: solid 1px white;
                }

                .selected {
                    background-color: var(--jo-secondary);
                }

                .privacy-policy-link {
                    color: white;
                    padding: 2rem 1rem;
                }
            </style>

            <div
                class="main-container"
                @click=${() => (0, utilities_1.raiseSetterAction)(this, 'showSidebar', false)}
            >
                <div class="logo-container">
                    <img class="logo" src="../../images/jo-logo.png" />
                </div>

                <div>
                    <div class="sidebar-item-group-title">CALLS</div>

                    <jo-customer-sidebar-item
                        .props=${{
            href: '/',
            iconName: 'home',
            text: 'Dashboard',
            selected: this.store.selectedMainOption === 'DASHBOARD',
        }}
                        @click=${() => (this.store.selectedMainOption = 'DASHBOARD')}
                    ></jo-customer-sidebar-item>

                    <jo-customer-sidebar-item
                        ${(0, test_id_directive_1.testId)('jo-customer-sidebar-action-item-tab')}
                        .props=${{
            href: '/customer/action-items',
            iconName: 'textsms',
            text: 'Action Items',
            selected: this.store.selectedMainOption === 'ACTION_ITEMS',
        }}
                        @click=${() => (this.store.selectedMainOption = 'ACTION_ITEMS')}
                    ></jo-customer-sidebar-item>

                    <jo-customer-sidebar-item
                        ${(0, test_id_directive_1.testId)('jo-customer-sidebar-contacts-tab')}
                        .props=${{
            href: '/customer/contact-list',
            iconName: 'contact_page',
            text: 'Contacts',
            selected: this.store.selectedMainOption === 'CONTACTS',
        }}
                        @click=${() => (this.store.selectedMainOption = 'CONTACTS')}
                    ></jo-customer-sidebar-item>

                    <jo-customer-sidebar-item
                        ${(0, test_id_directive_1.testId)('jo-customer-sidebar-voicemail-tab')}
                        ?hidden=${(0, utilities_1.getIsCompanyOwner)(this.store.authenticatedUser, this.store.companyId) === false}
                        .props=${{
            href: '/customer/voicemail',
            iconName: 'voicemail',
            text: 'Voicemail',
            selected: this.store.selectedMainOption === 'VOICEMAIL',
        }}
                        @click=${() => (this.store.selectedMainOption = 'VOICEMAIL')}
                    ></jo-customer-sidebar-item>

                    <jo-customer-sidebar-item
                        ${(0, test_id_directive_1.testId)('jo-customer-sidebar-outbound-tab')}
                        ?hidden=${this.store.isMobileDevice}
                        .props=${{
            href: '/customer/outbound',
            iconName: 'outbound',
            text: 'Outbound',
            selected: this.store.selectedMainOption === 'OUTBOUND',
        }}
                        @click=${() => (this.store.selectedMainOption = 'OUTBOUND')}
                    ></jo-customer-sidebar-item>
                </div>

                <div class="sidebar-item-separator-container">
                    <hr class="sidebar-item-separator" />
                </div>

                <div class="sidebar-item-group">
                    <div class="sidebar-item-group-title">ADMIN</div>

                    <jo-customer-sidebar-item
                        ${(0, test_id_directive_1.testId)('jo-customer-sidebar-company-profile-tab')}
                        .props=${{
            href: '/customer/company-profile',
            iconName: 'business',
            text: 'Company Profile',
            selected: this.store.selectedMainOption === 'COMPANY_PROFILE',
        }}
                        @click=${() => (this.store.selectedMainOption = 'COMPANY_PROFILE')}
                    ></jo-customer-sidebar-item>

                    <jo-customer-sidebar-item
                        ${(0, test_id_directive_1.testId)('jo-customer-sidebar-users-tab')}
                        .props=${{
            href: '/customer/users',
            iconName: 'groups',
            text: 'Users',
            selected: this.store.selectedMainOption === 'USERS',
        }}
                        @click=${() => (this.store.selectedMainOption = 'USERS')}
                    ></jo-customer-sidebar-item>

                    <jo-customer-sidebar-item
                        ${(0, test_id_directive_1.testId)('jo-customer-sidebar-billing-tab')}
                        ?hidden=${(0, utilities_1.getIsCompanyOwner)(this.store.authenticatedUser, this.store.companyId) === false}
                        .props=${{
            href: '/customer/billing',
            iconName: 'payment',
            text: 'Billing',
            selected: this.store.selectedMainOption === 'BILLING',
        }}
                        @click=${() => (this.store.selectedMainOption = 'BILLING')}
                    ></jo-customer-sidebar-item>

                    <jo-customer-sidebar-item
                        ${(0, test_id_directive_1.testId)('jo-customer-sidebar-invoice-tab')}
                        ?hidden=${(0, utilities_1.getIsCompanyOwner)(this.store.authenticatedUser, this.store.companyId) === false}
                        .props=${{
            href: `customer/invoice?companyId=${state.companyId}`,
            iconName: 'payment',
            text: 'Invoice',
            selected: this.store.selectedMainOption === 'INVOICE',
        }}
                        @click=${() => (this.store.selectedMainOption = 'INVOICE')}
                    ></jo-customer-sidebar-item>

                    <jo-customer-sidebar-item
                        ${(0, test_id_directive_1.testId)('jo-customer-sidebar-call-log-tab')}
                        .props=${{
            href: '/customer/logs',
            iconName: 'payment',
            text: 'Logs',
            selected: this.store.selectedMainOption === 'LOGS',
        }}
                        @click=${() => (this.store.selectedMainOption = 'LOGS')}
                    ></jo-customer-sidebar-item>

                    ${shouldDisplayGoogleCalendarSideBarItem === true
            ? (0, lit_html_1.html) `
                              <jo-customer-sidebar-item
                                  ${(0, test_id_directive_1.testId)('jo-customer-sidebar-calendars-tab')}
                                  .props=${{
                href: '/customer/company-calendars',
                iconName: 'calendar_today',
                text: 'Calendars',
                selected: this.store.selectedMainOption === 'COMPANY_CALENDARS',
            }}
                                  @click=${() => (this.store.selectedMainOption = 'COMPANY_CALENDARS')}
                              ></jo-customer-sidebar-item>
                          `
            : ''}
                    ${state.isEnrolledInChat === true && state.isMobileDevice === false
            ? (0, lit_html_1.html) `
                              <jo-customer-sidebar-item
                                  ${(0, test_id_directive_1.testId)('jo-customer-sidebar-chat-widget-generator-tab')}
                                  .props=${{
                href: `/customer/chat-widget-script-generator?companyId=${state.companyId}`,
                iconName: 'code',
                text: 'Chat Widget Builder',
                selected: this.store.selectedMainOption === 'CHAT_WIDGET_GENERATOR',
            }}
                                  @click=${() => (this.store.selectedMainOption = 'CHAT_WIDGET_GENERATOR')}
                              ></jo-customer-sidebar-item>
                          `
            : ''}
                </div>

                <div class="sidebar-item-separator-container">
                    <hr class="sidebar-item-separator" />
                </div>

                <div class="sidebar-item-group">
                    <div class="sidebar-item-group-title">JILL HUB</div>

                    <jo-customer-sidebar-item
                        ${(0, test_id_directive_1.testId)('jo-customer-sidebar-partners-tab')}
                        .props=${{
            href: 'https://www.jillsoffice.com/partnerdiscounts',
            iconName: 'shopping_bag',
            text: 'Partner Discounts',
            selected: this.store.selectedMainOption === 'PARTNER_DISCOUNTS',
        }}
                        @click=${() => (this.store.selectedMainOption = 'PARTNER_DISCOUNTS')}
                    ></jo-customer-sidebar-item>

                    <jo-customer-sidebar-item
                        ${(0, test_id_directive_1.testId)('jo-customer-sidebar-connect-with-us-tab')}
                        .props=${{
            href: 'https://www.facebook.com/jillsoffice/',
            iconName: 'facebook',
            text: 'Connect with us',
        }}
                    ></jo-customer-sidebar-item>

                    <jo-customer-sidebar-item
                        ${(0, test_id_directive_1.testId)('jo-customer-sidebar-master-class-tab')}
                        .props=${{
            href: 'https://www.jillsoffice.com/accessmasterclasses',
            iconName: 'school',
            text: 'Master Class Library',
        }}
                    ></jo-customer-sidebar-item>
                </div>

                <div class="sidebar-item-separator-container">
                    <hr class="sidebar-item-separator" />
                </div>

                <div class="sidebar-item-group">
                    <div class="sidebar-item-group-title">HELP</div>

                    <jo-customer-sidebar-item
                        ${(0, test_id_directive_1.testId)('jo-customer-sidebar-tutorials-tab')}
                        .props=${{
            href: 'https://www.jillsoffice.com/jills-app',
            iconName: 'menu_book',
            text: 'Tutorials',
            selected: this.store.selectedMainOption === 'TRAINING',
        }}
                        @click=${() => (this.store.selectedMainOption = 'TRAINING')}
                    ></jo-customer-sidebar-item>

                    <jo-customer-sidebar-item
                        ${(0, test_id_directive_1.testId)('jo-customer-sidebar-contact-us-tab')}
                        .props=${{
            href: 'https://www.jillsoffice.com/contact',
            iconName: 'contact_support',
            text: 'Contact Us',
            selected: this.store.selectedMainOption === 'CONTACT_US',
        }}
                        @click=${() => (this.store.selectedMainOption = 'CONTACT_US')}
                    ></jo-customer-sidebar-item>
                </div>

                <div class="sidebar-item-separator-container">
                    <hr class="sidebar-item-separator" />
                </div>

                <div class="sidebar-item-group">
                    <a
                        ${(0, test_id_directive_1.testId)('jo-customer-sidebar-privacy-policy-tab')}
                        class="privacy-policy-link"
                        href="https://www.jillsoffice.com/privacy-policy"
                        target="_blank"
                    >
                        Privacy Policy
                    </a>
                </div>

                <br />

                <div class="sidebar-item-group">
                    <jo-customer-sidebar-item
                        ${(0, test_id_directive_1.testId)('jo-customer-sidebar-logout')}
                        .props=${{
            clickHandler: () => this.logoutClick(),
            iconName: 'logout',
            text: 'Log out',
        }}
                    ></jo-customer-sidebar-item>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-customer-sidebar', JOCustomerSidebar);
