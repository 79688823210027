"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeMinutesPackagesSummaryForCompany = void 0;
const common_1 = require("@augment-vir/common");
const is_invoices_in_date_range_1 = require("../../../../utilities/invoices/is-invoices-in-date-range");
const merge_sequential_invoices_with_same_minutes_package_1 = require("./merge-sequential-invoices-with-same-minutes-package");
const defaultResult = 'UNKNOWN';
const delimiter = ' ~~~ ';
function makeMinutesPackagesSummaryForCompany(params) {
    const { company, startDate, endDate, propertyToIncludeInSummary } = params;
    const invoicesInDateRange = findInvoiceThatMeetsCriteria(company, startDate, endDate);
    if (invoicesInDateRange.length === 0)
        return defaultResult;
    (0, common_1.assertLengthAtLeast)(invoicesInDateRange, 1, 'invoicesInDateRange');
    if (invoicesInDateRange.length === 1)
        return getMinutesPackageProperty(invoicesInDateRange[0], propertyToIncludeInSummary);
    return makeResultForMultipleInvoices(invoicesInDateRange, propertyToIncludeInSummary);
}
exports.makeMinutesPackagesSummaryForCompany = makeMinutesPackagesSummaryForCompany;
function findInvoiceThatMeetsCriteria(company, startDate, endDate) {
    if (!company.invoices)
        return [];
    return filterInvoicesInDateRange(company, startDate, endDate)
        .reduce(merge_sequential_invoices_with_same_minutes_package_1.mergeSequentialInvoicesWithSameMinutesPackage, [])
        .sort(getSortOrderByStartDate);
}
function filterInvoicesInDateRange(company, startDate, endDate) {
    return company.invoices?.filter(invoice => (0, is_invoices_in_date_range_1.isInvoiceInDateRange)(invoice, startDate, endDate));
}
function makeResultForMultipleInvoices(invoicesInDateRange, propertyToIncludeInSummary) {
    if (propertyToIncludeInSummary === 'price_per_minute') {
        const mostRecentInvoice = invoicesInDateRange[invoicesInDateRange.length - 1];
        return `${mostRecentInvoice?.minutes_package?.price_per_minute ?? defaultResult}`;
    }
    return invoicesInDateRange
        .map(invoice => makeMinutesPackageSummaryForInvoice(invoice, propertyToIncludeInSummary))
        .join(delimiter);
}
function makeMinutesPackageSummaryForInvoice(invoice, propertyToIncludeInSummary) {
    const startDateStr = new Date(invoice.start_date).toLocaleDateString();
    const endDateStr = new Date(invoice.end_date).toLocaleDateString();
    return `${getMinutesPackageProperty(invoice, propertyToIncludeInSummary)}: ${startDateStr}-${endDateStr}`;
}
function getMinutesPackageProperty(invoice, propertyToIncludeInSummary) {
    return invoice.minutes_package?.[propertyToIncludeInSummary] ?? defaultResult;
}
function getSortOrderByStartDate(a, b) {
    return new Date(a.start_date).getTime() - new Date(b.start_date).getTime();
}
