"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoCompanyCallLog = exports.JoCompanyCallLogStateInit = void 0;
const element_vir_1 = require("element-vir");
const lit_html_1 = require("lit-html");
const utilities_1 = require("../../../services/utilities");
const get_previous_date_1 = require("../../../utilities/dates-and-times/get-previous-date");
const get_start_of_billing_cycle_1 = require("../../../utilities/dates-and-times/get-start-of-billing-cycle");
const get_start_of_next_billing_cycle_1 = require("../../../utilities/dates-and-times/get-start-of-next-billing-cycle");
const round_to_x_decimal_places_1 = require("../../../utilities/math/round-to-x-decimal-places");
require("../jo-button.element");
require("../jo-input");
require("../jo-table");
const calculate_total_calls_wait_time_1 = require("./calculate-total-calls-wait-time");
const generate_quick_stats_html_1 = require("./generate-quick-stats-html");
const get_columns_for_call_logs_1 = require("./get-columns-for-call-logs");
const helper_utils_1 = require("./helper-utils");
require("./jo-company-call-log-mobile-template");
const queries_and_mutations_1 = require("./queries-and-mutations");
const types_constants_1 = require("./types-constants");
exports.JoCompanyCallLogStateInit = {
    averageWaitTime: 0,
    billingCycleType: 'MONTHLY',
    calls: [],
    companyLog: undefined,
    currentMinutesPackageForCompany: undefined,
    endOfBillingCycle: new Date(),
    fetchingCallsForPage: false,
    loading: true,
    minutesPackage: undefined,
    minutesUsedForCallType: 0,
    pageOffset: 0,
    receptionistService: undefined,
    selectedCallDirection: types_constants_1.CallDirectionFilterOptions.All,
    selectedCallType: types_constants_1.CallTypeFilterOptions.All,
    selectedDate: new Date(),
    startOfBillingCycle: new Date(),
    totalCallsForBillingCycle: 0,
};
exports.JoCompanyCallLog = (0, element_vir_1.defineElement)()({
    tagName: 'jo-company-call-log',
    styles: (0, element_vir_1.css) `
        :host {
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            background-color: white;
            padding: 1rem;
            color: var(--jo-blue-grey-1);
            font-size: 0.9rem;
        }

        .jo-company-call-log--header-container {
            display: flex;
            justify-content: space-between;
        }

        .jo-company-call-log--header-info {
            display: flex;
            align-items: center;
        }

        .jo-company-call-log--info-container {
            display: flex;
            margin: 1rem 0;
        }

        .jo-company-call-log--package-info-container h3 {
            font-weight: normal;
            margin: 0.5rem 0;
        }

        .jo-company-call-log--instructions-container {
            display: flex;
            flex-direction: column;
        }

        .jo-company-call-log--instructions-container h4 {
            font-weight: normal;
            margin: 0.5rem 0;
        }

        .jo-company-call-log--instructions-container p {
            padding-left: 2rem;
            margin: 0.3rem 0;
        }

        .mobile-font-size {
            font-size: calc(12px + 1vmin);
        }

        .jo-company-call-log--calls-table-container {
            padding-top: 2rem;
        }

        .jo-company-call-log--play-button-icon {
            color: var(--jo-tomato-2);
        }

        .jo-company-call-log--play-button-icon:hover {
            cursor: pointer;
        }

        .jo-company-call-log--filters-container {
            display: flex;
            flex-direction: row;
            gap: 5rem;
        }

        .jo-company-call-log--quick-stats-number {
            font-weight: bold;
        }

        @media screen and (max-width: 1600px) {
            .jo-company-call-log--info-container {
                flex-direction: column;
            }

            .jo-company-call-log--package-info-container {
                width: 100%;
            }

            .jo-company-call-log--instructions-container {
                margin-top: 1rem;
            }
        }
    `,
    stateInit: exports.JoCompanyCallLogStateInit,
    initCallback: ({ state, updateState, inputs }) => {
        handleFetchAndSetCallLogInfo(inputs.companyId, updateState, state).then(async () => {
            await getCalls(0, undefined, inputs.companyId, state, updateState);
        });
    },
    renderCallback: ({ state, updateState, inputs }) => {
        if (!inputs.authenticatedUser) {
            return (0, lit_html_1.html) ``;
        }
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

            <div class="jo-company-call-log--main-container">
                <section class="jo-company-call-log--header-container">
                    ${(0, element_vir_1.renderIf)(!inputs.isMobileDevice && state.loading, (0, lit_html_1.html) `
                            <div style="padding-left: 3rem;">
                                <jo-loading-text></jo-loading-text>
                            </div>
                        `)}
                </section>

                <section class="jo-company-call-log--filters-container">
                    <div>
                        <label>
                            Billing Cycle: ${state.startOfBillingCycle.toLocaleDateString()} -
                            ${(0, get_previous_date_1.getPreviousDate)(state.endOfBillingCycle).toLocaleDateString()}
                        </label>

                        <input
                            type="date"
                            class="jo-global--date-input"
                            .value=${getValueForDateBillingCycleInput(state.companyLog, state.selectedDate, state.billingCycleType)}
                            @change=${async (event) => {
            updateState({
                selectedDate: new Date(event.target.value.replace(/-/g, '/')),
            });
            await handleFetchAndSetCallLogInfo(inputs.companyId, updateState, state);
        }}
                        />
                    </div>
                    ${(0, element_vir_1.renderIf)((0, utilities_1.isUserAMemberOfJillsOffice)(inputs.authenticatedUser), (0, lit_html_1.html) `
                            <jo-input
                                .type=${'SELECT'}
                                .label=${'Call Direction'}
                                .selectOptions=${Object.values(types_constants_1.CallDirectionFilterOptions)}
                                .selectedValue=${state.selectedCallDirection}
                                @valueChanged=${async (event) => {
            updateState({
                selectedCallDirection: event.detail.selectedValue,
            });
            await handleFetchAndSetCallLogInfo(inputs.companyId, updateState, state);
        }}
                            ></jo-input>
                            <jo-input
                                .type=${'SELECT'}
                                .label=${'Call Type:'}
                                .selectOptions=${Object.values(types_constants_1.CallTypeFilterOptions)}
                                .selectedValue=${state.selectedCallType}
                                @valueChanged=${async (event) => {
            updateState({
                selectedCallType: event.detail.selectedValue,
            });
            await handleFetchAndSetCallLogInfo(inputs.companyId, updateState, state);
        }}
                            ></jo-input>
                        `)}
                </section>

                <section class="jo-company-call-log--info-container">
                    <div
                        class=${(0, element_vir_1.classMap)({
            'jo-company-call-log--package-info-container': true,
            'mobile-font-size': inputs.isMobileDevice,
        })}
                    >
                        ${(0, generate_quick_stats_html_1.generateQuickStatsHtml)(inputs.authenticatedUser, state)}
                    </div>

                    ${state.minutesPackage && state.receptionistService && !inputs.isMobileDevice
            ? (0, lit_html_1.html) `
                              <div class="jo-company-call-log--instructions-container">
                                  <h4>* Billable call time is calculated as follows:</h4>
                                  <p>
                                      - There are no charges for calls under
                                      ${(0, utilities_1.millisecondsToHoursMinutesSecondsLong)((0, helper_utils_1.getMinimumMillisecondsCallNeedsToBeBillable)(state.minutesPackage, state.selectedDate))}
                                  </p>
                                  <p>
                                      - After
                                      ${(0, utilities_1.millisecondsToHoursMinutesSecondsLong)((0, helper_utils_1.getMinimumMillisecondsCallNeedsToBeBillable)(state.minutesPackage, state.selectedDate))},
                                      calls are billed at
                                      ${(0, utilities_1.millisecondsToHoursMinutesSecondsLong)((0, helper_utils_1.getMiniumBillableMilliseconds)(state.minutesPackage, state.selectedDate))}
                                      minimum
                                  </p>
                                  <p>
                                      - After
                                      ${(0, utilities_1.millisecondsToHoursMinutesSecondsLong)((0, helper_utils_1.getMiniumBillableMilliseconds)(state.minutesPackage, state.selectedDate))},
                                      calls are billed in 15 second intervals
                                  </p>
                                  <p>
                                      - Billing per call is capped at
                                      ${(0, utilities_1.millisecondsToHoursMinutesSecondsLong)((0, helper_utils_1.getMaximumBillableMilliseconds)(state.minutesPackage, state.selectedDate))}
                                  </p>
                              </div>
                          `
            : ''}
                </section>

                <section class="jo-company-call-log--calls-table-container">
                    <jo-table
                        .tableName=${'Calls'}
                        .maxItemsPerPage=${100}
                        .columns=${(0, get_columns_for_call_logs_1.getColumnsForUserType)(inputs.authenticatedUser)}
                        .getItems=${async (offset, limit) => {
            return await getCalls(offset, limit, inputs.companyId, state);
        }}
                        .totalItems=${state.totalCallsForBillingCycle}
                        .showCSVButton=${true}
                        .showSearchBar=${false}
                        .isMobileDevice=${inputs.isMobileDevice}
                        .mobileTemplate=${(item) => (0, helper_utils_1.getMobileTemplate)(item)}
                        .mobileTableHeader=${getMobileTableHeader}
                    ></jo-table>
                </section>
            </div>
        `;
    },
});
async function handleFetchAndSetCallLogInfo(companyId, updateState, state) {
    if (!companyId)
        return;
    await fetchAndSetBaseInfo(companyId, state.selectedDate, updateState);
    setBillingCycleTypeAndStartAndEndDate(state, updateState);
    updateState({ loading: false });
}
async function fetchAndSetBaseInfo(companyId, selectedDate, updateState) {
    const [companyLogResult, minutesPackageResult, receptionistServiceResult, currentMinutesPackageResult,] = await Promise.all([
        (0, queries_and_mutations_1.fetchCompanyLogForDate)(companyId, selectedDate),
        (0, queries_and_mutations_1.fetchMinutesPackageForDate)(companyId, selectedDate),
        (0, queries_and_mutations_1.fetchReceptionistService)(companyId),
        (0, queries_and_mutations_1.fetchCurrentMinutesPackageForCompany)(companyId),
    ]);
    (0, utilities_1.assertSucceeded)(companyLogResult);
    (0, utilities_1.assertSucceeded)(minutesPackageResult);
    (0, utilities_1.assertSucceeded)(receptionistServiceResult);
    (0, utilities_1.assertSucceeded)(currentMinutesPackageResult);
    updateState({
        companyLog: companyLogResult.value,
        minutesPackage: minutesPackageResult.value,
        receptionistService: receptionistServiceResult.value,
        currentMinutesPackageForCompany: currentMinutesPackageResult.value,
    });
}
function setBillingCycleTypeAndStartAndEndDate(state, updateState) {
    if (state.companyLog) {
        updateState({
            billingCycleType: state.companyLog.billing_cycle_type,
            startOfBillingCycle: new Date(state.companyLog.start_date),
            endOfBillingCycle: new Date(state.companyLog.end_date),
        });
    }
    else if (state.minutesPackage) {
        updateState({
            billingCycleType: state.minutesPackage.billing_cycle_type,
            startOfBillingCycle: (0, get_start_of_billing_cycle_1.getStartOfBillingCycleInMountainTime)(state.selectedDate, state.billingCycleType),
            endOfBillingCycle: (0, get_start_of_next_billing_cycle_1.getStartOfNextBillingCycleInMountainTime)(state.selectedDate, state.billingCycleType),
        });
    }
    else if (state.currentMinutesPackageForCompany) {
        updateState({
            billingCycleType: state.currentMinutesPackageForCompany.billing_cycle_type,
            startOfBillingCycle: (0, get_start_of_billing_cycle_1.getStartOfBillingCycleInMountainTime)(state.selectedDate, state.billingCycleType),
            endOfBillingCycle: (0, get_start_of_next_billing_cycle_1.getStartOfNextBillingCycleInMountainTime)(state.selectedDate, state.billingCycleType),
        });
    }
    else {
        updateState({
            startOfBillingCycle: (0, get_start_of_billing_cycle_1.getStartOfBillingCycleInMountainTime)(state.selectedDate, 'MONTHLY'),
            endOfBillingCycle: (0, get_start_of_next_billing_cycle_1.getStartOfNextBillingCycleInMountainTime)(state.selectedDate, 'MONTHLY'),
        });
    }
}
async function getCalls(offset, limit, companyId, state, updateState) {
    if (!companyId) {
        return {
            items: [],
            totalItems: 0,
        };
    }
    const callsResult = await (0, queries_and_mutations_1.fetchCallsForCallLog)({
        companyId: companyId,
        startDate: state.startOfBillingCycle,
        endDate: state.endOfBillingCycle,
        offset,
        limit,
        callType: state.selectedCallType,
        callDirection: state.selectedCallDirection,
    });
    (0, utilities_1.assertSucceeded)(callsResult, utilities_1.handleError);
    const { totalCalls, callsForPage } = callsResult.value;
    if (updateState) {
        updateState({
            totalCallsForBillingCycle: totalCalls,
            minutesUsedForCallType: (0, helper_utils_1.getMinutesUsedForCallType)(callsForPage),
            calls: callsForPage,
        });
        calculateAndSetAverageWaitTime(callsForPage, updateState);
    }
    return {
        items: callsForPage,
        totalItems: totalCalls,
    };
}
function getValueForDateBillingCycleInput(companyLog, selectedDate, billingCycleType) {
    return (0, helper_utils_1.formatDateForDateInput)(companyLog
        ? new Date(companyLog.start_date)
        : (0, get_start_of_billing_cycle_1.getStartOfBillingCycleInMountainTime)(selectedDate, billingCycleType));
}
function getMobileTableHeader() {
    return (0, lit_html_1.html) `
        <style>
            .table-header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;
            }
            .table-cell {
                width: 25%;
                font-weight: bold;
                text-align: center;
            }
        </style>
        <div class="table-header">
            <div class="table-cell">Date</div>
            <div class="table-cell">Phone Number</div>
            <div class="table-cell">Call Duration</div>
            <div class="table-cell">Billable Duration</div>
        </div>
    `;
}
function calculateAndSetAverageWaitTime(calls, updateState) {
    const averageWaitTime = (0, calculate_total_calls_wait_time_1.calculateTotalWaitTime)(calls) / calls.length;
    updateState({
        averageWaitTime: (0, round_to_x_decimal_places_1.roundToXDecimal)(averageWaitTime, 2),
    });
}
