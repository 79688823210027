"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCustomerFromStipe = exports.getCustomerPaymentMethods = exports.fetchCompanyForPaymentDetails = void 0;
const graphql_1 = require("../../../services/graphql");
const error_handling_1 = require("../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function fetchCompanyForPaymentDetails(companyId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!) {
                    Companies__JOBillingPaymentInfo: getCompanies(id: $companyId) {
                        id
                        credit_card_key
                    }
                }
            `, {
            companyId,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const company = gqlResult.value.data.Companies__JOBillingPaymentInfo;
        return {
            succeeded: true,
            value: company,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchCompanyForPaymentDetails', error);
    }
}
exports.fetchCompanyForPaymentDetails = fetchCompanyForPaymentDetails;
async function getCustomerPaymentMethods(customerStripeId, companyId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($customerStripeId: String!, $companyId: Int!) {
                    PaymentMethods: fetchPaymentMethods(
                        customerStripeId: $customerStripeId
                        companyId: $companyId
                    ) {
                        id
                        last4
                        brand
                        exp_year
                        exp_month
                    }
                }
            `, {
            customerStripeId,
            companyId,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const paymentMethods = gqlResult.value.data.PaymentMethods;
        return {
            succeeded: true,
            value: paymentMethods,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('getCustomerPaymentMethods', error);
    }
}
exports.getCustomerPaymentMethods = getCustomerPaymentMethods;
async function getCustomerFromStipe(customerStripeId, companyId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($customerStripeId: String!, $companyId: Int!) {
                    StripeCustomer: getCustomerFromStripe(
                        customerStripeId: $customerStripeId
                        companyId: $companyId
                    ) {
                        id
                        default_source
                    }
                }
            `, {
            customerStripeId,
            companyId,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const stripeCustomer = gqlResult.value.data.StripeCustomer;
        return {
            succeeded: true,
            value: stripeCustomer,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('getCustomerFromStipe', error);
    }
}
exports.getCustomerFromStipe = getCustomerFromStipe;
