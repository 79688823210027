"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../../services/graphql");
require("./jo-company-call-instruction");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    companyId: 'NOT_SET',
    company: 'NOT_SET',
    loading: true,
};
class JOCompany extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    set companyId(companyId) {
        if (companyId === 'NOT_SET' || companyId === this.store.companyId) {
            return;
        }
        this.store.companyId = companyId;
        this.fetchAndSetCompany(companyId);
    }
    async fetchAndSetCompany(companyId) {
        this.store.loading = true;
        const companyResult = await fetchCompany(companyId);
        if (companyResult.succeeded === false) {
            (0, utilities_1.joAlert)('Error', companyResult.userMessage);
            throw new Error(companyResult.developerMessage);
        }
        this.store.company = companyResult.value;
        this.store.loading = false;
    }
    companyDetailsChanged(e) {
        this.store.company = e.detail.company;
    }
    render(state) {
        if (state.company === 'NOT_SET' || state.loading === true) {
            return (0, lit_html_1.html) `
                <div>Loading...</div>
            `;
        }
        return (0, lit_html_1.html) `
            <style>
                .jo-company--main-container {
                }
            </style>

            <div class="jo-company--main-container">
                <button @click=${() => (0, utilities_1.navigate)(`/companies`)}>Back to Companies</button>

                <h1>${state.company.name}</h1>

                <h2>Instructions</h2>

                <jo-company-call-instruction
                    .companyId=${state.companyId}
                ></jo-company-call-instruction>

                <br />
                <br />
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company', JOCompany);
async function fetchCompany(companyId) {
    try {
        const result = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!) {
                    getCompanies(id: $companyId) {
                        id
                        name
                    }
                }
            `, {
            companyId,
        });
        if (result.data.getCompanies === null || result.data.getCompanies === undefined) {
            return {
                succeeded: false,
                userMessage: 'Error getting company',
                developerMessage: 'There was an error in the getCompanies query',
            };
        }
        return {
            succeeded: true,
            value: result.data.getCompanies,
        };
    }
    catch (error) {
        return {
            succeeded: false,
            userMessage: 'Something went wrong',
            developerMessage: 'An error occured in the request when trying to fetch company',
        };
    }
}
