"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateAutoResponsePositions = exports.deleteAutoResponse = exports.updateChatWidgetSettings = void 0;
const graphql_1 = require("../../../../services/graphql");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function updateChatWidgetSettings(scriptSettings, companyId, additionalMessages) {
    const { ['chat_key']: removedProperty, ...inputSettings } = scriptSettings;
    const autoResponses = additionalMessages.map((message, index) => {
        if (message.position == undefined)
            throw new Error('autoResponse messages had undefined position');
        return {
            id: message.id,
            body: message.body,
            chat_widget_setting: {
                connect: {
                    id: scriptSettings.id,
                },
            },
            position: message.position,
        };
    });
    const updateChatWidgetSettingsInput = {
        ...inputSettings,
        company: {
            connect: {
                id: companyId,
            },
        },
        auto_responses: {
            upsertMany: autoResponses,
        },
    };
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($updateChatWidgetSettingsInput: UpdateChat_widget_settingsInput!) {
                updateChat_widget_settings(input: $updateChatWidgetSettingsInput) {
                    id
                }
            }
        `, {
        updateChatWidgetSettingsInput,
    });
    return gqlResult.succeeded;
}
exports.updateChatWidgetSettings = updateChatWidgetSettings;
async function deleteAutoResponse(autoResponseId) {
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($autoResponseId: Int!) {
                deleteChat_widget_auto_responses(input: {id: $autoResponseId}) {
                    id
                }
            }
        `, {
        autoResponseId,
    });
    return gqlResult.succeeded;
}
exports.deleteAutoResponse = deleteAutoResponse;
/**
 * @param autoResponses This function assumes that the incoming autoResponses are already sorted by
 *   position before getting passed in as a parameter.
 */
async function updateAutoResponsePositions(autoResponses) {
    //we want to filter out the resposes without an Id because those are responses that haven't been saved yet
    //and don't need their positions updated.
    const responsesWithNewPositions = autoResponses.filter(response => response.id);
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($responsesWithNewPositions: [UpdateChat_widget_auto_responsesInput]!) {
                updateManyChat_widget_auto_responses(inputs: $responsesWithNewPositions) {
                    id
                }
            }
        `, {
        responsesWithNewPositions,
    });
    return gqlResult.succeeded;
}
exports.updateAutoResponsePositions = updateAutoResponsePositions;
