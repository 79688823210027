"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCurrentCompanyLogForCompany = exports.getCurrentInvoiceForCompany = exports.getInvoicesForCompanyInMonth = exports.getAllMinutesPackages = void 0;
const graphql_1 = require("../../../../services/graphql");
const error_handling_1 = require("../../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function getAllMinutesPackages() {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
            query {
                findMinutes_packages(orderBy: {field: "id", order: DESC}) {
                    items {
                        id
                        base_fee
                        billing_cycle_type
                        name
                        num_minutes_included
                        price_per_minute
                        recurring_fee
                        type
                        user_display_name
                        minutes_calculation_option {
                            id
                            name
                            max_billable_action_item_milliseconds
                            max_billable_milliseconds
                            min_billable_milliseconds
                            min_milliseconds_call_needs_to_be_billable
                        }
                    }
                }
            }
        `);
        if (gqlResult.succeeded === false)
            return gqlResult;
        const minutesPackages = gqlResult.value.data.findMinutes_packages.items;
        return {
            succeeded: true,
            value: minutesPackages,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('getAllMinutesPackages', error);
    }
}
exports.getAllMinutesPackages = getAllMinutesPackages;
async function getInvoicesForCompanyInMonth(companyId, startOfMonth) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!, $startOfMonth: DateTime!) {
                    findInvoices(
                        filter: {
                            and: [{company_id: {eq: $companyId}}, {start_date: {ge: $startOfMonth}}]
                        }
                    ) {
                        items {
                            id
                            billing_cycle_type
                            invoice_line_items {
                                id
                                description
                                amount
                            }
                            minutes_package {
                                id
                                base_fee
                                recurring_fee
                                num_minutes_included
                                price_per_minute
                            }
                        }
                    }
                }
            `, {
            companyId,
            startOfMonth,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const invoices = gqlResult.value.data.findInvoices.items;
        return {
            succeeded: true,
            value: invoices,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('getMinutesPackageForCompany', error);
    }
}
exports.getInvoicesForCompanyInMonth = getInvoicesForCompanyInMonth;
async function getCurrentInvoiceForCompany(params) {
    try {
        const { companyId, date } = params;
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!, $date: DateTime!) {
                    findInvoices(
                        filter: {
                            and: [
                                {company_id: {eq: $companyId}}
                                {start_date: {le: $date}}
                                {end_date: {ge: $date}}
                            ]
                        }
                    ) {
                        items {
                            id
                            billing_cycle_type
                            minutes_package {
                                id
                                base_fee
                                recurring_fee
                                num_minutes_included
                                price_per_minute
                            }
                            invoice_line_items {
                                id
                                description
                                amount
                            }
                        }
                    }
                }
            `, {
            companyId,
            date,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const invoices = gqlResult.value.data.findInvoices.items;
        const invoice = invoices[0];
        if (!invoice) {
            return {
                succeeded: true,
                value: 'NOT_FOUND',
            };
        }
        return {
            succeeded: true,
            value: invoice,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('getCurrentInvoiceForCompany', error);
    }
}
exports.getCurrentInvoiceForCompany = getCurrentInvoiceForCompany;
async function getCurrentCompanyLogForCompany(params) {
    try {
        const { companyId, date } = params;
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!, $date: DateTime!) {
                    findCompany_logs(
                        filter: {
                            and: [
                                {company_id: {eq: $companyId}}
                                {start_date: {le: $date}}
                                {end_date: {ge: $date}}
                            ]
                        }
                    ) {
                        items {
                            id
                            billing_cycle_type
                            start_date
                            end_date
                            billable_duration
                        }
                    }
                }
            `, {
            companyId,
            date,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const companyLogs = gqlResult.value.data.findCompany_logs.items;
        return {
            succeeded: true,
            value: companyLogs[0] ?? 'NOT_FOUND',
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('getCurrentInvoiceForCompany', error);
    }
}
exports.getCurrentCompanyLogForCompany = getCurrentCompanyLogForCompany;
