"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isInputValuesEmpty = void 0;
function isInputValuesEmpty(inputValues) {
    if (inputValues.servicesInputValues.length === 0) {
        return true;
    }
    return false;
}
exports.isInputValuesEmpty = isInputValuesEmpty;
