"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoSearchCompanyInput = void 0;
const element_vir_1 = require("element-vir");
const jo_search_items_input_element_1 = require("../jo-search-items-input.element");
const search_companies_1 = require("./search-companies");
exports.JoSearchCompanyInput = (0, element_vir_1.defineElementNoInputs)({
    tagName: 'jo-search-company-input',
    events: {
        companySelected: (0, element_vir_1.defineElementEvent)(),
    },
    renderCallback({ dispatch, events }) {
        return (0, element_vir_1.html) `
            <${jo_search_items_input_element_1.JoSearchItemsInput}
                ${(0, element_vir_1.assign)(jo_search_items_input_element_1.JoSearchItemsInput, {
            searchItemsCallback: search_companies_1.searchCompanies,
            getItemDisplayTextCallback: (company) => company.name,
            limit: 20,
            label: 'Search Company',
        })}
                ${(0, element_vir_1.listen)(jo_search_items_input_element_1.JoSearchItemsInput.events.itemSelected, event => {
            dispatch(new events.companySelected(event.detail));
        })}
            ></${jo_search_items_input_element_1.JoSearchItemsInput}>
        `;
    },
});
