"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoSearchJillInput = void 0;
const element_vir_1 = require("element-vir");
const jo_search_items_input_element_1 = require("../jo-search-items-input.element");
const search_jills_1 = require("./search-jills");
const get_full_name_1 = require("../../../utilities/get-full-name");
exports.JoSearchJillInput = (0, element_vir_1.defineElementNoInputs)({
    tagName: 'jo-search-jill-input',
    events: {
        jillSelected: (0, element_vir_1.defineElementEvent)(),
    },
    renderCallback({ dispatch, events }) {
        return (0, element_vir_1.html) `
            <${jo_search_items_input_element_1.JoSearchItemsInput}
                ${(0, element_vir_1.assign)(jo_search_items_input_element_1.JoSearchItemsInput, {
            searchItemsCallback: search_jills_1.searchJills,
            getItemDisplayTextCallback: (jill) => (0, get_full_name_1.getFullName)(jill),
            limit: 20,
            label: 'Search Jill',
        })}
                ${(0, element_vir_1.listen)(jo_search_items_input_element_1.JoSearchItemsInput.events.itemSelected, event => {
            dispatch(new events.jillSelected(event.detail));
        })}
            ></${jo_search_items_input_element_1.JoSearchItemsInput}>
        `;
    },
});
