"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CallStatus = exports.CallDirectionFilterOptions = exports.CallTypeFilterOptions = void 0;
var CallTypeFilterOptions;
(function (CallTypeFilterOptions) {
    CallTypeFilterOptions["Billable"] = "Billable";
    CallTypeFilterOptions["NonBillable"] = "Non-Billable";
    CallTypeFilterOptions["All"] = "All";
})(CallTypeFilterOptions = exports.CallTypeFilterOptions || (exports.CallTypeFilterOptions = {}));
var CallDirectionFilterOptions;
(function (CallDirectionFilterOptions) {
    CallDirectionFilterOptions["Incoming"] = "Incoming";
    CallDirectionFilterOptions["Outgoing"] = "Outgoing";
    CallDirectionFilterOptions["CallBack"] = "CallBack";
    CallDirectionFilterOptions["All"] = "All";
})(CallDirectionFilterOptions = exports.CallDirectionFilterOptions || (exports.CallDirectionFilterOptions = {}));
var CallStatus;
(function (CallStatus) {
    CallStatus["BlockedByTwilio"] = "Blocked By Twilio";
    CallStatus["CallerBlocked"] = "Caller Blocked";
    CallStatus["CallerDisconnected"] = "Caller Disconnected";
    CallStatus["MarchexError"] = "Marchex Error";
})(CallStatus = exports.CallStatus || (exports.CallStatus = {}));
