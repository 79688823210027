"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.wasJillSeenLoggedInWithinXMillisecondsAgo = exports.updateLastSeenLoggedIn = exports.isLoggedIn = void 0;
const graphql_1 = require("../services/graphql");
const mapped_env_variables_1 = require("./environment/mapped-env-variables");
function isLoggedIn() {
    const privateKey = localStorage.getItem('jills-office-client-private-key');
    return privateKey !== null && privateKey !== undefined;
}
exports.isLoggedIn = isLoggedIn;
async function updateLastSeenLoggedIn(userId, date) {
    try {
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($userId: Int!, $date: DateTime!) {
                    updateUsers(input: {id: $userId, last_seen_logged_in: $date}) {
                        id
                    }
                }
            `, {
            userId: userId,
            date: date.toISOString(),
        });
    }
    catch (error) {
        throw new Error(`updateLastSeenLoggedIn error: ${error}`);
    }
}
exports.updateLastSeenLoggedIn = updateLastSeenLoggedIn;
function wasJillSeenLoggedInWithinXMillisecondsAgo(jill) {
    if (!jill.last_seen_logged_in)
        return false;
    return new Date().getTime() - new Date(jill.last_seen_logged_in).getTime() <= 15000;
}
exports.wasJillSeenLoggedInWithinXMillisecondsAgo = wasJillSeenLoggedInWithinXMillisecondsAgo;
