"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeGoogleCalendarAccess = void 0;
const graphql_1 = require("../../../../../services/graphql");
const utilities_1 = require("../../../../../services/utilities");
const queries_1 = require("./queries");
const mapped_env_variables_1 = require("../../../../../utilities/environment/mapped-env-variables");
async function removeGoogleCalendarAccess(companyId) {
    try {
        const googleCalendarResult = await (0, queries_1.fetchGoogleCalendarsForCompany)(companyId);
        (0, utilities_1.assertSucceeded)(googleCalendarResult);
        const companyCalendars = googleCalendarResult.value;
        const input = getUpdateCompaniesPayloadForRemovingCalendars(companyId, companyCalendars);
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($input: UpdateCompaniesInput!) {
                    updateCompanies(input: $input) {
                        id
                    }
                }
            `, {
            input,
        });
    }
    catch (error) {
        throw new Error(`removeGoogleCalendarAccess error: ${error}`);
    }
}
exports.removeGoogleCalendarAccess = removeGoogleCalendarAccess;
function getUpdateCompaniesPayloadForRemovingCalendars(companyId, companyCalendars) {
    const currentTime = new Date().toISOString();
    return {
        id: companyId,
        google_calendar_oauth_token: '',
        calendar_connections: {
            updateMany: companyCalendars.map(calendar => {
                return {
                    id: calendar.id,
                    is_visible: false,
                    archived_at: currentTime,
                    company: {
                        connect: {
                            id: companyId,
                        },
                    },
                };
            }),
        },
    };
}
