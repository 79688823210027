"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const form_error_manager_1 = require("../../../classes/form-error-manager");
const graphql_1 = require("../../../services/graphql");
const store_1 = require("../../../services/store");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
require("../jo-button.element");
require("../jo-loading");
require("./jo-company-call-instructions-general-setup-call-screen-info");
require("./jo-company-call-instructions-general-setup-general-information");
require("./jo-company-call-instructions-general-setup-service-area");
const InitialState = {
    authenticatedUser: store_1.InitialGlobalState.authenticatedUser,
    company: 'NOT_SET',
    companyId: 'NOT_SET',
    inputValues: {
        callScreenInfoInputValues: 'NOT_SET',
        generalInformationInputValues: 'NOT_SET',
        serviceAreaInputValues: 'NOT_SET',
    },
    isMobileDevice: false,
    loading: true,
    saving: false,
    formErrorManager: new form_error_manager_1.FormErrorManager(),
};
class JOCompanyCallInstructionsGeneralSetup extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        const originalCompanyId = this.store.companyId;
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        if (this.store.companyId !== originalCompanyId) {
            if (this.store.companyId !== 'NOT_SET') {
                (async () => {
                    this.store.loading = true;
                    await this.fetchAndSetCompany();
                    this.store.loading = false;
                })();
            }
        }
    }
    set company(company) {
        if (this.store.company === company) {
            return;
        }
        (0, utilities_1.raiseCustomEvent)(this, 'callinstructionsgeneralsetupcompanyabouttochange');
        this.store.company = company;
    }
    set inputValues(inputValues) {
        this.store.inputValues = inputValues;
        (0, utilities_1.raiseCustomEvent)(this, 'callinstructionsgeneralsetupinputvalueschanged', this.store.inputValues);
    }
    async fetchAndSetCompany() {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError);
        const result = await fetchCompany(this.store.companyId);
        (0, utilities_1.assertSucceeded)(result, utilities_1.handleError);
        const company = result.value;
        this.company = company;
    }
    async save() {
        if (this.store.formErrorManager.getFormErrors().length > 0) {
            (0, utilities_1.joAlert)('Alert', 'Please correct errors');
            return;
        }
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError);
        (0, utilities_1.assertIsSet)(this.store.inputValues.generalInformationInputValues, utilities_1.handleError);
        (0, utilities_1.assertIsSet)(this.store.inputValues.serviceAreaInputValues, utilities_1.handleError);
        (0, utilities_1.assertIsSet)(this.store.inputValues.callScreenInfoInputValues, utilities_1.handleError);
        this.store.saving = true;
        const generalInformationResult = await saveGeneralInformation(this.store.companyId, this.store.inputValues.generalInformationInputValues);
        const serviceAreaResult = await saveServiceArea(this.store.companyId, this.store.inputValues.serviceAreaInputValues);
        const callScreenInfoResult = await saveCallScreenInfo(this.store.companyId, this.store.inputValues.callScreenInfoInputValues);
        this.store.saving = false;
        (0, utilities_1.assertSucceeded)(generalInformationResult, utilities_1.handleError);
        (0, utilities_1.assertSucceeded)(serviceAreaResult, utilities_1.handleError);
        (0, utilities_1.assertSucceeded)(callScreenInfoResult, utilities_1.handleError);
        await this.fetchAndSetCompany();
        (0, utilities_1.joAlert)('Success', 'Company successfully saved');
    }
    cancelButtonClicked() {
        const originalCompany = this.store.company;
        this.company = 'NOT_SET';
        this.company = originalCompany;
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .jo-company-call-instructions-general-setup--main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    display: flex;
                }

                .jo-company-call-instructions-general-setup--general-info-and-service-area-container {
                    flex: 3;
                    padding-right: ${state.isMobileDevice ? '0' : '1rem'};
                }

                .jo-company-call-instructions-general-setup--call-screen-info-container {
                    flex: 5;
                    padding-left: 1rem;
                    padding-right: 1rem;
                }

                @media screen and (max-width: 1200px) {
                    .main-container {
                        flex-direction: column;
                    }

                    .call-screen-info-container {
                        padding: 1rem 0rem 0 0;
                    }
                }

                .jo-company-call-instructions-general-setup--major-buttons-container {
                    flex: 0.5;
                    padding: 1rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .jo-company-call-instructions-general-setup--major-button-container {
                    padding: 1rem;
                }

                .jo-company-call-instructions-general-setup--general-info-container {
                    padding-bottom: 1rem;
                }

                .jo-global--table-container {
                    padding: ${state.isMobileDevice ? '0.5rem' : '1rem'};
                }

                @media screen and (max-width: 1200px) {
                    .jo-company-call-instructions-general-setup--main-container {
                        flex-direction: column;
                    }

                    .jo-company-call-instructions-general-setup--call-screen-info-container {
                        padding: 1rem 0rem 0 0;
                    }
                }
            </style>

            <div class="jo-company-call-instructions-general-setup--main-container">
                <div
                    class="jo-company-call-instructions-general-setup--general-info-and-service-area-container"
                >
                    <div class="jo-company-call-instructions-general-setup--general-info-container">
                        <div class="jo-global--card">
                            <jo-company-call-instructions-general-setup-general-information
                                .authenticatedUser=${state.authenticatedUser}
                                .company=${state.company}
                                .isMobileDevice=${state.isMobileDevice}
                                @inputvalueschanged=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                generalInformationInputValues: e.detail,
            };
        }}
                            ></jo-company-call-instructions-general-setup-general-information>
                        </div>
                    </div>

                    <div>
                        <div class="jo-global--card">
                            <jo-company-call-instructions-general-setup-service-area
                                .authenticatedUser=${state.authenticatedUser}
                                .company=${state.company}
                                .isMobileDevice=${state.isMobileDevice}
                                @serviceareavaluechanged=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                serviceAreaInputValues: e.detail,
            };
        }}
                            ></jo-company-call-instructions-general-setup-service-area>
                        </div>
                    </div>
                </div>

                <div class="jo-company-call-instructions-general-setup--call-screen-info-container">
                    <div class="jo-global--card">
                        <jo-company-call-instructions-general-setup-call-screen-info
                            .authenticatedUser=${state.authenticatedUser}
                            .company=${state.company}
                            .isMobileDevice=${state.isMobileDevice}
                            @inputvalueschanged=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                callScreenInfoInputValues: e.detail,
            };
        }}
                            @inputErrorChangedforCallScreenInfo=${(e) => this.store.formErrorManager.addOrRemoveFormError(e.detail)}
                        ></jo-company-call-instructions-general-setup-call-screen-info>
                    </div>
                </div>

                ${(0, utilities_1.authorizedRender)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser, (0, lit_html_1.html) `
                        <div
                            class="jo-company-call-instructions-general-setup--major-buttons-container"
                        >
                            <div
                                class="jo-company-call-instructions-general-setup--major-button-container"
                            >
                                <jo-button
                                    .type=${'ACTION_BUTTON_1'}
                                    .text=${state.saving === true ? 'Saving...' : 'Save'}
                                    .disabled=${state.saving === true}
                                    @joButtonClick=${() => this.save()}
                                ></jo-button>
                            </div>

                            <div class="major-button-container">
                                <jo-button
                                    .type=${'SECONDARY_BUTTON_1'}
                                    .text=${'Cancel'}
                                    .disabled=${state.saving === true}
                                    @joButtonClick=${() => this.cancelButtonClicked()}
                                ></jo-button>
                            </div>
                        </div>
                    `)}
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-call-instructions-general-setup', JOCompanyCallInstructionsGeneralSetup);
async function fetchCompany(companyId) {
    const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            query ($companyId: Int!) {
                Companies__JOCompanyCallInstructionsGeneralSetup: getCompanies(id: $companyId) {
                    id
                    alerts
                    alerts_expiration_date
                    business_hours
                    call_screen_general_information_address_appear
                    call_screen_general_information_address_require
                    call_screen_general_information_check_county
                    call_screen_general_information_check_zip_code
                    call_screen_general_information_city_appear
                    call_screen_general_information_city_require
                    call_screen_general_information_county_appear
                    call_screen_general_information_county_require
                    call_screen_general_information_email_appear
                    call_screen_general_information_email_require
                    call_screen_general_information_postal_code_appear
                    call_screen_general_information_postal_code_require
                    call_screen_general_information_province_appear
                    call_screen_general_information_province_require
                    call_screen_general_information_state_appear
                    call_screen_general_information_state_require
                    call_screen_general_information_where_did_you_hear_appear
                    call_screen_general_information_where_did_you_hear_require
                    call_screen_general_information_zip_code_appear
                    call_screen_general_information_zip_code_require
                    default_estimate_duration
                    default_job_duration
                    description
                    directions
                    end_of_script_text
                    estimates_enabled
                    estimates_url
                    forms_enabled
                    forms_url
                    jobs_enabled
                    jobs_url
                    pricing_enabled
                    pricing_url
                    save_without_scheduling_enabled
                    save_without_scheduling_url
                    service_area_counties
                    service_area_zip_codes
                    service_area_description
                }
            }
        `, {
        companyId,
    });
    if (result.succeeded === false) {
        return result;
    }
    const company = result.value.data.Companies__JOCompanyCallInstructionsGeneralSetup;
    return {
        succeeded: true,
        value: company,
    };
}
async function saveGeneralInformation(companyId, inputValues) {
    const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            mutation (
                $companyId: Int!
                $addressAppear: Boolean!
                $addressRequire: Boolean!
                $cityAppear: Boolean!
                $cityRequire: Boolean!
                $countyAppear: Boolean!
                $countyRequire: Boolean!
                $emailAppear: Boolean!
                $emailRequire: Boolean!
                $postalCodeAppear: Boolean!
                $postalCodeRequire: Boolean!
                $provinceAppear: Boolean!
                $provinceRequire: Boolean!
                $stateAppear: Boolean!
                $stateRequire: Boolean!
                $whereDidYouHearAppear: Boolean!
                $whereDidYouHearRequire: Boolean!
                $zipCodeAppear: Boolean!
                $zipCodeRequire: Boolean!
            ) {
                updateCompanies(
                    input: {
                        id: $companyId
                        call_screen_general_information_address_appear: $addressAppear
                        call_screen_general_information_address_require: $addressRequire
                        call_screen_general_information_city_appear: $cityAppear
                        call_screen_general_information_city_require: $cityRequire
                        call_screen_general_information_county_appear: $countyAppear
                        call_screen_general_information_county_require: $countyRequire
                        call_screen_general_information_email_appear: $emailAppear
                        call_screen_general_information_email_require: $emailRequire
                        call_screen_general_information_postal_code_appear: $postalCodeAppear
                        call_screen_general_information_postal_code_require: $postalCodeRequire
                        call_screen_general_information_province_appear: $provinceAppear
                        call_screen_general_information_province_require: $provinceRequire
                        call_screen_general_information_state_appear: $stateAppear
                        call_screen_general_information_state_require: $stateRequire
                        call_screen_general_information_where_did_you_hear_appear: $whereDidYouHearAppear
                        call_screen_general_information_where_did_you_hear_require: $whereDidYouHearRequire
                        call_screen_general_information_zip_code_appear: $zipCodeAppear
                        call_screen_general_information_zip_code_require: $zipCodeRequire
                    }
                ) {
                    id
                }
            }
        `, {
        companyId,
        addressAppear: inputValues.call_screen_general_information_address_appear,
        addressRequire: inputValues.call_screen_general_information_address_require,
        cityAppear: inputValues.call_screen_general_information_city_appear,
        cityRequire: inputValues.call_screen_general_information_city_require,
        countyAppear: inputValues.call_screen_general_information_county_appear,
        countyRequire: inputValues.call_screen_general_information_county_require,
        emailAppear: inputValues.call_screen_general_information_email_appear,
        emailRequire: inputValues.call_screen_general_information_email_require,
        postalCodeAppear: inputValues.call_screen_general_information_postal_code_appear,
        postalCodeRequire: inputValues.call_screen_general_information_postal_code_require,
        provinceAppear: inputValues.call_screen_general_information_province_appear,
        provinceRequire: inputValues.call_screen_general_information_province_require,
        stateAppear: inputValues.call_screen_general_information_state_appear,
        stateRequire: inputValues.call_screen_general_information_state_require,
        whereDidYouHearAppear: inputValues.call_screen_general_information_where_did_you_hear_appear,
        whereDidYouHearRequire: inputValues.call_screen_general_information_where_did_you_hear_require,
        zipCodeAppear: inputValues.call_screen_general_information_zip_code_appear,
        zipCodeRequire: inputValues.call_screen_general_information_zip_code_require,
    });
    if (result.succeeded === false) {
        return result;
    }
    return {
        succeeded: true,
    };
}
async function saveServiceArea(companyId, inputValues) {
    const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            mutation (
                $companyId: Int!
                $checkCounty: Boolean!
                $checkZipCode: Boolean!
                $serviceAreaCounties: String!
                $serviceAreaZipCodes: String!
            ) {
                updateCompanies(
                    input: {
                        id: $companyId
                        call_screen_general_information_check_county: $checkCounty
                        call_screen_general_information_check_zip_code: $checkZipCode
                        service_area_counties: $serviceAreaCounties
                        service_area_zip_codes: $serviceAreaZipCodes
                    }
                ) {
                    id
                }
            }
        `, {
        companyId,
        checkCounty: inputValues.call_screen_general_information_check_county,
        checkZipCode: inputValues.call_screen_general_information_check_zip_code,
        serviceAreaCounties: inputValues.service_area_counties.join(','),
        serviceAreaZipCodes: inputValues.service_area_zip_codes.join(','),
    });
    if (result.succeeded === false) {
        return result;
    }
    return {
        succeeded: true,
    };
}
async function saveCallScreenInfo(companyId, inputValues) {
    const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            mutation (
                $companyId: Int!
                $alerts: String!
                $alertsExpirationDate: DateTime!
                $businessHours: String!
                $defaultEstimateDuration: Int!
                $defaultJobDuration: Int!
                $description: String!
                $directions: String!
                $endOfScriptText: String!
                $estimatesEnabled: Boolean!
                $estimatesURL: String!
                $formsEnabled: Boolean!
                $formsURL: String!
                $jobsEnabled: Boolean!
                $jobsURL: String!
                $pricingEnabled: Boolean!
                $pricingURL: String!
                $saveWithoutSchedulingEnabled: Boolean!
                $saveWithoutSchedulingURL: String!
                $serviceAreaDescription: String!
            ) {
                updateCompanies(
                    input: {
                        id: $companyId
                        alerts: $alerts
                        alerts_expiration_date: $alertsExpirationDate
                        business_hours: $businessHours
                        default_estimate_duration: $defaultEstimateDuration
                        default_job_duration: $defaultJobDuration
                        description: $description
                        directions: $directions
                        end_of_script_text: $endOfScriptText
                        estimates_enabled: $estimatesEnabled
                        estimates_url: $estimatesURL
                        forms_enabled: $formsEnabled
                        forms_url: $formsURL
                        jobs_enabled: $jobsEnabled
                        jobs_url: $jobsURL
                        pricing_enabled: $pricingEnabled
                        pricing_url: $pricingURL
                        save_without_scheduling_enabled: $saveWithoutSchedulingEnabled
                        save_without_scheduling_url: $saveWithoutSchedulingURL
                        service_area_description: $serviceAreaDescription
                    }
                ) {
                    id
                }
            }
        `, {
        companyId,
        alerts: inputValues.alerts,
        alertsExpirationDate: inputValues.alerts_expiration_date,
        businessHours: inputValues.businessHours,
        defaultEstimateDuration: inputValues.default_estimate_duration,
        defaultJobDuration: inputValues.default_job_duration,
        description: inputValues.description,
        directions: inputValues.directions,
        endOfScriptText: inputValues.end_of_script_text,
        estimatesEnabled: inputValues.estimates_enabled,
        estimatesURL: inputValues.estimates_url,
        formsEnabled: inputValues.forms_enabled,
        formsURL: inputValues.forms_url,
        jobsEnabled: inputValues.jobs_enabled,
        jobsURL: inputValues.jobs_url,
        pricingEnabled: inputValues.pricing_enabled,
        pricingURL: inputValues.pricing_url,
        saveWithoutSchedulingEnabled: inputValues.save_without_scheduling_enabled,
        saveWithoutSchedulingURL: inputValues.save_without_scheduling_url,
        serviceAreaDescription: inputValues.service_area_description,
    });
    if (result.succeeded === false) {
        return result;
    }
    return {
        succeeded: true,
    };
}
