"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../services/graphql");
require("./jo-table");
require("./jo-button.element");
const utilities_1 = require("../../services/utilities");
require("./jo-loading");
const auth_rules_1 = require("../../utilities/auth-rules");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const test_id_directive_1 = require("../directives/test-id.directive");
const mapped_env_variables_1 = require("../../utilities/environment/mapped-env-variables");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    jillCreatedSubscription: 'NOT_SET',
    jillUpdatedSubscription: 'NOT_SET',
    loading: false,
};
class JOUsers extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, (state, action) => {
            if (action.type === 'RENDER') {
                return state;
            }
            return state;
        });
        this.callGetUsers = (async (offset, limit, searchText, column) => {
            return await getUsers(offset, limit, searchText);
        }).bind(this);
    }
    async connectedCallback() {
        this.closeSubscriptionsIfNecessary();
        await this.createSubscriptionsIfNecessary();
    }
    closeSubscriptionsIfNecessary() {
        this.closeUserCreatedSubscriptionIfNecessary();
        this.closeUserUpdatedSubscriptionIfNecessary();
    }
    closeUserCreatedSubscriptionIfNecessary() {
        if (this.store.jillCreatedSubscription !== 'NOT_SET') {
            this.store.jillCreatedSubscription.close();
            this.store.jillCreatedSubscription = 'NOT_SET';
        }
    }
    closeUserUpdatedSubscriptionIfNecessary() {
        if (this.store.jillUpdatedSubscription !== 'NOT_SET') {
            this.store.jillUpdatedSubscription.close();
            this.store.jillUpdatedSubscription = 'NOT_SET';
        }
    }
    async createSubscriptionsIfNecessary() {
        await this.createUserCreatedSubscriptionIfNecessary();
        await this.createUserUpdatedSubscriptionIfNecessary();
    }
    async createUserCreatedSubscriptionIfNecessary() {
        if (this.store.jillCreatedSubscription === 'NOT_SET') {
            this.store.jillCreatedSubscription =
                (await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
                        subscription {
                            jillCreated
                        }
                    `, () => {
                    this.callGetUsers = this.callGetUsers.bind(this);
                    this.store.dispatch({
                        type: 'RENDER',
                    });
                })) ?? 'NOT_SET';
        }
    }
    async createUserUpdatedSubscriptionIfNecessary() {
        if (this.store.jillUpdatedSubscription === 'NOT_SET') {
            this.store.jillUpdatedSubscription =
                (await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
                        subscription {
                            jillUpdatedForTable
                        }
                    `, () => {
                    this.callGetUsers = this.callGetUsers.bind(this);
                    this.store.dispatch({
                        type: 'RENDER',
                    });
                })) ?? 'NOT_SET';
        }
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .jo-users--main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    font-family: sans-serif;
                }

                .jo-users--new-user-container {
                    padding-bottom: 2rem;
                }

                .jo-users--actions {
                    display: flex;
                }

                .jo-users--action {
                    padding-left: 0.25rem;
                }
            </style>

            <jo-loading
                .props=${{
            loading: state.loading,
        }}
            ></jo-loading>

            <div class="jo-users--main-container">
                <div
                    ?hidden=${(0, utilities_1.isInAuthRole)(['JILL_EXECUTIVE'], state.authenticatedUser) === false}
                    class="jo-users--new-user-container"
                >
                    <jo-button
                        ${(0, test_id_directive_1.testId)('new-jill-user-button')}
                        .type=${'ACTION_BUTTON_1'}
                        .text=${'New User'}
                        .href=${'/user/create'}
                    ></jo-button>
                </div>

                <div class="jo-global--card">
                    <jo-table
                        .tableName=${`Jill's Office Users`}
                        .maxItemsPerPage=${50}
                        .columns=${getColumns(state)}
                        .getItems=${this.callGetUsers}
                        .searchDebounce=${200}
                        @itemsfetched=${() => {
            if (this.store.loading === true) {
                this.store.loading = false;
            }
        }}
                    ></jo-table>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-users', JOUsers);
function getColumns(state) {
    const columns = [
        {
            title: 'Actions',
            getCellData: (user) => {
                return getHtmlForActionsColumn(user, state);
            },
            width: 1,
        },
        {
            title: '',
            getCellData: (user) => (0, lit_html_1.html) `
                <div style="display: grid; place-items: center">
                    <img style="max-height: 10rem;" src="${user.profile_picture_url}" />
                </div>
            `,
            width: 3,
        },
        {
            title: 'Name',
            getCellData: (user) => `${user.first_name} ${user.last_name}`,
            width: 3,
        },
        {
            title: 'Email',
            getCellData: (user) => user.email,
            width: 3,
        },
        {
            title: 'Role',
            getCellData: (user) => getRoleFriendlyDisplay(user.auth_role),
            width: 2,
        },
    ];
    return columns;
}
function getHtmlForActionsColumn(user, state) {
    return (0, lit_html_1.html) `
        <div class="jo-users--actions">
            <jo-button
                class="jo-users--action"
                .type=${'ICON'}
                .href=${`mailto:${user.email}`}
                .icon=${'email'}
            ></jo-button>

            ${isAllowedToViewIndidualJillPage(state)
        ? (0, lit_html_1.html) `
                      <jo-button
                          class="jo-users--action"
                          .type=${'ICON'}
                          .icon=${'phone'}
                      ></jo-button>

                      <jo-button
                          class="jo-users--action"
                          .type=${'ICON'}
                          .href=${`/user?userId=${user.id}`}
                          .icon=${'edit'}
                      ></jo-button>
                  `
        : ''}
        </div>
    `;
}
function isAllowedToViewIndidualJillPage(state) {
    return ((0, auth_rules_1.isJillAtOrAboveAuthRole)(state.authenticatedUser, 'JILL_MANAGER') ||
        (0, utilities_1.hasPermissionToManageJillProfilePictures)(state.authenticatedUser));
}
async function getUsers(offset, limit, searchText) {
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query ($offset: Int!, $limit: Int!, $ignoreSearch: Boolean!, $searchText: String!) {
                searchJillsOfficeUsers(
                    page: {offset: $offset, limit: $limit}
                    ignoreSearch: $ignoreSearch
                    searchText: $searchText
                ) {
                    users {
                        id
                        auth_role
                        email
                        first_name
                        last_name
                        title
                        profile_picture_url
                    }
                    total
                }
            }
        `, {
        offset,
        limit,
        ignoreSearch: searchText === '',
        searchText,
    });
    // TODO the jo-table getItems function should return a GQLResult
    if (gqlResult.succeeded === false) {
        alert(gqlResult.userMessage);
        throw new Error(gqlResult.developerMessage);
    }
    const users = gqlResult.value.data.searchJillsOfficeUsers.users;
    return {
        items: users,
        totalItems: gqlResult.value.data.searchJillsOfficeUsers.total,
    };
}
function getRoleFriendlyDisplay(role) {
    if (role === 'JILL') {
        return 'Jill';
    }
    if (role === 'JILL_OUTBOUND') {
        return 'Outbound Jill';
    }
    if (role === 'JILL_MANAGER') {
        return 'Jill Manager';
    }
    if (role === 'JILL_BUSINESS_DEVELOPMENT') {
        return 'Business Development';
    }
    if (role === 'JILL_EXECUTIVE') {
        return 'Executive Management';
    }
    return role;
}
