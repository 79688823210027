"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.populateMissingCompanyChatKeys = exports.createNewCalendar = exports.createNewPhoneService = exports.registerTwilioNumber = exports.createNewCompany = exports.createNewOrganization = exports.createNewUserAccount = void 0;
const graphql_1 = require("../../../services/graphql");
const error_handling_1 = require("../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function createNewUserAccount(inputValues) {
    try {
        if (!inputValues) {
            return {
                succeeded: false,
                userMessage: 'Error Creating Account',
                developerMessage: 'createNewUserAccount inputValues is not set.',
            };
        }
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($input: CreateUsersInput!) {
                    createUsers(input: $input) {
                        id
                    }
                }
            `, {
            input: {
                first_name: inputValues.first_name,
                last_name: inputValues.last_name,
                email: inputValues.email,
                phone_number: inputValues.phone_number,
                auth_role: 'COMPANY_OWNER',
                crypted_password: String(Math.random()),
                visible_on_call_screen: true,
                has_app_access: true,
                should_alert_action_items: true,
                should_receive_invoices: true,
                preferred_action_item_email_type: 'STANDARD',
                time_zone: inputValues.time_zone,
            },
        }, {
            retryOnFailedMutation: true,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const resultingUser = gqlResult.value.data.createUsers;
        return {
            succeeded: true,
            value: resultingUser,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('createNewUserAccount', error);
    }
}
exports.createNewUserAccount = createNewUserAccount;
async function createNewOrganization(ownerId) {
    try {
        if (!ownerId) {
            return {
                succeeded: false,
                userMessage: 'Error Creating Organization',
                developerMessage: 'createNewOrganization ownerId is not set.',
            };
        }
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($id: Int!) {
                    createOrganizations(input: {owner: {connect: {id: $id}}}) {
                        id
                    }
                }
            `, {
            id: ownerId,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const organizationId = gqlResult.value.data.createOrganizations.id;
        return {
            succeeded: true,
            value: organizationId,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('createNewOrganization', error);
    }
}
exports.createNewOrganization = createNewOrganization;
async function createNewCompany(inputValue) {
    try {
        if (!inputValue) {
            return {
                succeeded: false,
                userMessage: 'Error Creating Company',
                developerMessage: 'createNewCompany inputValue is not set.',
            };
        }
        const gqlAddressResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($addressInput: CreateAddressesInput!) {
                    createAddresses(input: $addressInput) {
                        id
                    }
                }
            `, {
            addressInput: {
                line_1: inputValue.address1,
                line_2: inputValue.address2,
                city: inputValue.city,
                state: inputValue.state,
                zip_code: inputValue.zipcode,
            },
        }, {
            retryOnFailedMutation: false,
        });
        if (gqlAddressResult.succeeded === false)
            return gqlAddressResult;
        const addressId = gqlAddressResult.value.data.createAddresses.id;
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($input: CreateCompaniesInput!) {
                    createCompanies(input: $input) {
                        id
                    }
                }
            `, {
            input: {
                name: inputValue.name,
                business_number: inputValue.business_number,
                phone_service: {
                    connect: {
                        id: inputValue.phone_serviceId,
                    },
                },
                address: {
                    connect: {
                        id: addressId,
                    },
                },
                activation_fee: inputValue.activation_fee,
                fax: inputValue.fax,
                public_email: inputValue.public_email,
                industry: inputValue.industry,
                timezone: inputValue.timezone,
                twilio_phone_number: inputValue.twilioNumber,
                users: {
                    connect: {
                        id: inputValue.userId,
                    },
                },
                pod: {
                    connect: {
                        id: inputValue.podId,
                    },
                },
                calendar: {
                    connect: {
                        id: inputValue.calendarId === -1 ? null : inputValue.calendarId,
                    },
                },
                call_instructions: {
                    createMany: [
                        {
                            title: 'Inbound Instructions',
                            type: 'INBOUND',
                            order: 0,
                        },
                        {
                            title: 'Outbound Instructions',
                            type: 'OUTBOUND',
                            order: 1,
                        },
                        {
                            title: 'FAQs',
                            type: 'OTHER',
                            order: 2,
                        },
                    ],
                },
                account_manager: {
                    connect: {
                        id: inputValue.account_managerId,
                    },
                },
                mailing_address: inputValue.mailing_address,
                niche: inputValue.niche,
                referral_source: inputValue.referral_sources,
                referral_tag: inputValue.referral_tags,
                website_url: inputValue.website_url,
                organization: {
                    connect: {
                        id: inputValue.organizationId,
                    },
                },
                current_minutes_package: {
                    connect: {
                        id: inputValue.minutes_packageId,
                    },
                },
            },
        }, {
            retryOnFailedMutation: false,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const company = gqlResult.value.data.createCompanies;
        return {
            succeeded: true,
            value: company,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('createNewOrganization', error);
    }
}
exports.createNewCompany = createNewCompany;
async function registerTwilioNumber(businessNumber, companyName, companyState) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($businessNumber: String!, $companyName: String!, $companyState: String!) {
                    registerTwilioPhoneNumberForCompany(
                        businessNumber: $businessNumber
                        companyName: $companyName
                        companyState: $companyState
                    )
                }
            `, {
            businessNumber,
            companyName,
            companyState,
        }, {
            retryOnFailedMutation: false,
        });
        const twilioNumber = gqlResult.value.data.registerTwilioPhoneNumberForCompany;
        if (gqlResult.succeeded === false)
            return gqlResult;
        return {
            succeeded: true,
            value: twilioNumber,
        };
    }
    catch (error) {
        return {
            succeeded: false,
            userMessage: 'Company Twilio Number Creation Failed',
            developerMessage: 'registerTwilioNumber() failed ' + error,
        };
    }
}
exports.registerTwilioNumber = registerTwilioNumber;
async function createNewPhoneService(otherPhoneServiceName) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($input: CreatePhone_servicesInput!) {
                    createPhone_services(input: $input) {
                        id
                    }
                }
            `, {
            input: {
                name: otherPhoneServiceName,
                type: 'OTHER',
            },
        });
        const returnedId = gqlResult.value.data.createPhone_services.id;
        if (gqlResult.succeeded === false)
            return gqlResult;
        return {
            succeeded: true,
            value: returnedId,
        };
    }
    catch (error) {
        return {
            succeeded: false,
            userMessage: 'createNewPhoneService Creation Failed',
            developerMessage: 'createNewPhoneService() failed ' + error,
        };
    }
}
exports.createNewPhoneService = createNewPhoneService;
async function createNewCalendar(otherCalendarName, otherCalendarURL) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($input: CreateCalendarsInput!) {
                    createCalendars(input: $input) {
                        id
                    }
                }
            `, {
            input: {
                name: otherCalendarName,
                type: 'OTHER',
                url: otherCalendarURL,
            },
        }, {
            retryOnFailedMutation: false,
        });
        const returnedId = gqlResult.value.data.createCalendars.id;
        if (gqlResult.succeeded === false)
            return gqlResult;
        return {
            succeeded: true,
            value: returnedId,
        };
    }
    catch (error) {
        return {
            succeeded: false,
            userMessage: 'createNewPhoneService Creation Failed',
            developerMessage: 'createNewPhoneService() failed ' + error,
        };
    }
}
exports.createNewCalendar = createNewCalendar;
async function populateMissingCompanyChatKeys() {
    await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
        mutation {
            populateMissingCompanyChatKeys
        }
    `);
}
exports.populateMissingCompanyChatKeys = populateMissingCompanyChatKeys;
