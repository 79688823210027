"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchGlobalConfig = void 0;
const graphql_1 = require("../../services/graphql");
const mapped_env_variables_1 = require("../environment/mapped-env-variables");
async function fetchGlobalConfig(args) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute(`
            query {
                getGlobal_configs(id:1) {
                    ${args.join(',')}
                }
            }
        `);
        return gqlResult.data.getGlobal_configs;
    }
    catch (error) {
        throw new Error(`fetchGlobalConfig error: ${error}`);
    }
}
exports.fetchGlobalConfig = fetchGlobalConfig;
