"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoCompanyChatLogMobileTemplate = void 0;
const element_vir_1 = require("element-vir");
const lit_html_1 = require("lit-html");
const utilities_1 = require("../../../../services/utilities");
const calculate_chat_duration_1 = require("../../../../utilities/chats/chat-stats/calculate-chat-duration");
const time_format_1 = require("../../../../utilities/date/time-format");
exports.JoCompanyChatLogMobileTemplate = (0, element_vir_1.defineElement)()({
    tagName: 'jo-company-chat-log-mobile-template',
    styles: (0, element_vir_1.css) `
            :host {
                height: 100%;
                width: 100%;
                box-sizing: border-box;
            }
            .jo-mobile--table-container {
                box-sizing: border-box;
                background-color: white;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
            }
            .row-data {
                padding: 0.5rem;
                font-size: 0.8rem;
                display: flex;
                flex-grow: 1;
                flex-basis: 3rem;
                justify-content: center;
                text-align: center;
            }
            .row-data-small {
                padding: 0.5rem;
                font-size: 0.8rem;
                display: flex;
                flex-grow: 1;
                flex-basis: 2rem;
                justify-content: center;
                text-align: center;
            }
            .table-link {
                text-decoration: none;
                color: var(--jo-primary);
            }
            .date-container {
                display: flex;
                flex-direction: column;
            }
        `,
    renderCallback: ({ state, updateState, inputs }) => {
        const phoneNumberNoDashes = inputs.item?.contact?.phone_numbers[0]?.number.replace(/-/g, '');
        const phoneUrl = `tel:+1${phoneNumberNoDashes}`;
        return (0, lit_html_1.html) `
                <link rel="stylesheet" href="/styles/jo-styles.css" />
                <link
                    href="https://fonts.googleapis.com/icon?family=Material+Icons"
                    rel="stylesheet"
                />

                <div class="main-container">
                    <div class="jo-mobile--table-container">
                        <div class="row-data-small">
                            <div class="date-container">
                                <div>${(0, utilities_1.makeDateTwoChar)(inputs.item?.created_at)}</div>
                                <div>
                                    ${(0, time_format_1.formatAs12HourTimeString)(new Date(inputs.item?.created_at))}
                                </div>
                            </div>
                        </div>
                        <a href=${phoneUrl} class="row-data table-link">
                            ${(0, utilities_1.formatE164PhoneNumberForDisplay)(inputs.item?.contact?.phone_numbers[0]?.number)}
                        </a>
                        <div class="row-data-small">
                            ${inputs.item
            ? (0, calculate_chat_duration_1.getChatDurationString)(inputs.item?.created_at, inputs.item?.duration_events, inputs.item.status)
            : '0 sec'}
                        </div>
                    </div>
                </div>
            `;
    },
});
