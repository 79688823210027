"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.moveActionItemsToNewStatusType = void 0;
const graphql_1 = require("../../../services/graphql");
const error_handling_1 = require("../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function moveActionItemsToNewStatusType(selectedActionItems, newStatusId, companyId) {
    try {
        const actionItemIds = selectedActionItems.map(a => a.id);
        const previousStatusTypeIds = getUniquePreviousStatusTypeIds(selectedActionItems);
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation (
                    $actionItemIds: [Int!]!
                    $newStatusId: Int!
                    $companyId: Int
                    $previousStatusTypeIds: [Int!]!
                ) {
                    moveActionItemsToNewStatusType(
                        input: {
                            action_item_ids: $actionItemIds
                            action_item_status_type_id: $newStatusId
                            company_id: $companyId
                            previous_status_type_ids: $previousStatusTypeIds
                        }
                    )
                }
            `, {
            actionItemIds,
            newStatusId,
            companyId,
            previousStatusTypeIds,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('moveActionItemsToNewStatusType', error);
    }
}
exports.moveActionItemsToNewStatusType = moveActionItemsToNewStatusType;
function getUniquePreviousStatusTypeIds(selectedActionItems) {
    return [...new Set(selectedActionItems.map(a => a.action_item_status_type.id))];
}
