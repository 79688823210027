"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../services/graphql");
const utilities_1 = require("../../services/utilities");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const test_id_directive_1 = require("../directives/test-id.directive");
const mapped_env_variables_1 = require("../../utilities/environment/mapped-env-variables");
require("./jo-table");
require("./jo-button.element");
const InitialState = {
    includeCanceledCompanies: false,
};
class JOCompanycompanies extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
        this.callGetCompanies = (async (offset, limit, searchText, column) => {
            return await getCompanies(offset, limit, searchText, column, this.store.includeCanceledCompanies);
        }).bind(this);
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .jo-companies--main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .jo-companies--actions {
                    display: flex;
                    align-items: center;
                }

                .jo-companies--action {
                    padding-right: 0.75rem;
                }

                .jo-companies--include-canceled-container {
                    padding-bottom: 1rem;
                }

                .jo-companies--canceled-icon {
                    color: red;
                    cursor: default;
                    font-size: 1.75rem;
                }
            </style>

            <div class="jo-companies--main-container" ${(0, test_id_directive_1.testId)('jo-companies-main-container')}>
                <div class="jo-companies--include-canceled-container">
                    <input
                        type="checkbox"
                        .checked=${state.includeCanceledCompanies}
                        @change=${(e) => {
            this.store.includeCanceledCompanies = e.target.checked;
            (0, utilities_1.refetchGetItems)(this, 'callGetCompanies');
        }}
                    />
                    Include Canceled Companies
                </div>

                <div class="jo-global--card">
                    <jo-table
                        style="font-size: 0.9rem"
                        .tableName=${'Companies'}
                        .maxItemsPerPage=${100}
                        .columns=${getColumns(state)}
                        .getItems=${this.callGetCompanies}
                        .searchDebounce=${200}
                        .showCSVButton=${true}
                        @itemsfetched=${() => (0, utilities_1.raiseCustomEvent)(this, 'loaded', true)}
                    ></jo-table>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-companies', JOCompanycompanies);
function getColumns(state) {
    const columns = [
        {
            title: 'Actions',
            getCellData: (company) => {
                return (0, lit_html_1.html) `
                    <div class="jo-companies--actions">
                        <jo-button
                            ${(0, test_id_directive_1.testId)('open-company-link')}
                            class="jo-companies--action"
                            .type=${'ICON'}
                            .href=${`/company/company-details?companyId=${company.id}`}
                            .icon=${'visibility'}
                        ></jo-button>

                        ${company.canceled
                    ? (0, lit_html_1.html) `
                                  <i class="material-icons jo-companies--canceled-icon">cancel</i>
                              `
                    : ''}
                    </div>
                `;
            },
            width: 0.5,
        },
        {
            title: 'Name',
            getCellData: (company) => company.name,
            width: 1,
            sortFieldName: 'NAME',
        },
        {
            title: 'Pod',
            getCellData: (company) => company.pod?.name || '',
            width: 1,
            sortFieldName: 'POD_NAME',
        },
        {
            title: 'Industry',
            getCellData: (company) => company.industry,
            width: 1,
            sortFieldName: 'INDUSTRY',
        },
        {
            title: 'Company Phone #',
            getCellData: (company) => (0, utilities_1.formatE164PhoneNumberForDisplay)(company.business_number),
            width: 1,
        },
        {
            title: 'Twilio Phone #',
            getCellData: (company) => (0, utilities_1.formatE164PhoneNumberForDisplay)(company.twilio_phone_number),
            width: 1,
        },
        {
            title: 'Owner Name',
            getCellData: (company) => (0, utilities_1.getUserFullName)(company.organization?.owner),
            width: 1,
            sortFieldName: 'OWNER_NAME',
        },
        {
            title: 'Owner Email',
            getCellData: (company) => company.organization?.owner?.email,
            width: 1,
        },
        {
            title: 'Owner Phone #',
            getCellData: (company) => (0, utilities_1.formatE164PhoneNumberForDisplay)(company.organization?.owner?.phone_number),
            width: 1,
        },
        {
            title: 'Account Manager',
            getCellData: (company) => `${company.account_manager?.first_name ?? ''} ${company.account_manager?.last_name ?? ''}`,
            width: 1,
        },
        {
            title: 'Date Added',
            getCellData: (company) => new Date(company.created_at).toLocaleDateString(),
            width: 0.5,
            sortFieldName: 'DATE_ADDED',
        },
    ];
    return columns;
}
async function getCompanies(offset, limit, searchText, column, includeCanceled) {
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query (
                $offset: Int!
                $limit: Int!
                $ignoreSearch: Boolean!
                $includeCanceled: Boolean
                $sortFieldName: COMPANY_SORT_FIELD_NAME
                $sortDirection: SortDirectionEnum
                $searchText: String
            ) {
                searchCompanies(
                    page: {offset: $offset, limit: $limit}
                    ignoreSearch: $ignoreSearch
                    includeCanceled: $includeCanceled
                    sortFieldName: $sortFieldName
                    sortDirection: $sortDirection
                    searchText: $searchText
                ) {
                    companies {
                        id
                        account_manager {
                            id
                            first_name
                            last_name
                        }
                        business_number
                        canceled
                        created_at
                        industry
                        name
                        organization {
                            id
                            owner {
                                id
                                first_name
                                last_name
                                phone_number
                                email
                            }
                        }
                        pod {
                            id
                            name
                        }
                        twilio_phone_number
                    }
                    total
                }
            }
        `, {
        offset,
        limit,
        ignoreSearch: searchText === '',
        includeCanceled,
        sortFieldName: column === 'NOT_SET' ? null : column.sortFieldName,
        sortDirection: column === 'NOT_SET' ? null : column.sortDirection,
        searchText,
    });
    // TODO the jo-table getItems function should return a GQLResult
    if (gqlResult.succeeded === false) {
        alert(gqlResult.userMessage);
        throw new Error(gqlResult.developerMessage);
    }
    const companies = gqlResult.value.data.searchCompanies.companies;
    return {
        items: companies,
        totalItems: gqlResult.value.data.searchCompanies.total,
    };
}
