"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const common_1 = require("@augment-vir/common");
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const test_id_directive_1 = require("../../directives/test-id.directive");
const css_utils_1 = require("../../../utilities/css-utils");
require("../jo-button.element");
require("../jo-input");
require("../jo-jill-pods/jo-jill-pods");
const mutations_1 = require("../jo-jill-pods/mutations");
const make_chat_status_1 = require("../jo-jill-pods/utils/make-chat-status");
require("../jo-loading-text");
const queries_1 = require("./queries");
const styles_1 = require("./styles");
const utils_1 = require("./utils/utils");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const create_element_bound_interval_1 = require("../../../utilities/timeouts/create-element-bound-interval");
const dates_and_times_1 = require("../../../utilities/dates-and-times/dates-and-times");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    allPods: [],
    jills: [],
    filteredJills: [],
    loading: true,
    searchText: '',
    selectedAuthRole: 'All',
    selectedPodName: 'All Pods',
    selectedAvailabilityStatus: 'All',
    selectedCallStatus: 'All',
    selectedChatStatus: 'All',
    selectedDropDownFloatPods: [],
    selectedDropDownMainPods: [],
    selectedOnlineStatus: 'All',
    selectedWorkStatus: 'All',
    selectedTeamName: 'All Teams',
    selectedSortOption: 'Name',
    selectedScheduleStatus: 'All',
    teams: [],
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-jills-pods');
class JOJillsPods extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    async connectedCallback() {
        await this.fetchAndSetInitialData();
        (0, create_element_bound_interval_1.createElementBoundInterval)({
            callback: () => {
                this.fetchAndSetJills();
            },
            element: this,
            intervalMs: 5 * dates_and_times_1.oneSecondInMilliseconds,
            intervalName: 'fetch jills',
        });
    }
    async fetchAndSetInitialData() {
        this.store.loading = true;
        await this.fetchAndSetPods();
        await this.fetchAndSetTeams();
        await this.fetchAndSetJills();
        this.store.loading = false;
    }
    async fetchAndSetPods() {
        const podsResult = await (0, queries_1.fetchAllPods)();
        (0, utilities_1.assertSucceeded)(podsResult, utilities_1.handleError);
        this.store.allPods = podsResult.value;
    }
    async fetchAndSetTeams() {
        const teamsWithMembers = await this.getAllTeamsWithMembers();
        this.store.teams = teamsWithMembers;
    }
    async getAllTeamsWithMembers() {
        const allTeams = await this.fetchTeams();
        const teamsWithMembers = allTeams.filter(team => team.members.length > 0);
        return teamsWithMembers;
    }
    async fetchTeams() {
        const teamsResult = await (0, queries_1.fetchTeams)();
        (0, utilities_1.assertSucceeded)(teamsResult, utilities_1.handleError, this, 'jo-jills-pods > fetchAndSetTeams');
        const teams = teamsResult.value;
        return teams;
    }
    async fetchAndSetJills() {
        const jillsResult = await (0, queries_1.fetchJills)();
        (0, utilities_1.assertSucceeded)(jillsResult);
        this.store.jills = jillsResult.value;
        this.sortAndFilterJills();
    }
    sortAndFilterJills() {
        this.sortJills();
        this.filterJills();
    }
    filterJills() {
        this.store.filteredJills = this.getFilteredJills();
    }
    getFilteredJills() {
        const filters = this.getFilterFunctions();
        const filteredJills = this.applyFilterFunctionsToJills(filters);
        return filteredJills;
    }
    getFilterFunctions() {
        return [
            [
                utils_1.filterJillsByTeamName,
                'selectedTeamName',
            ],
            [
                utils_1.filterJillsByPodName,
                'selectedPodName',
            ],
            [
                utils_1.filterJillsBySearchText,
                'searchText',
            ],
            [
                utils_1.filterJillsByAvailabilityStatus,
                'selectedAvailabilityStatus',
            ],
            [
                utils_1.filterJillsByCallStatus,
                'selectedCallStatus',
            ],
            [
                utils_1.filterJillsByChatStatus,
                'selectedChatStatus',
            ],
            [
                utils_1.filterJillsByOnlineStatus,
                'selectedOnlineStatus',
            ],
            [
                utils_1.filterJillsByWorkStatus,
                'selectedWorkStatus',
            ],
            [
                utils_1.filterJillsByScheduleStatus,
                'selectedScheduleStatus',
            ],
            [
                utils_1.filterJillsByAuthRole,
                'selectedAuthRole',
            ],
        ];
    }
    applyFilterFunctionsToJills(filters) {
        return filters.reduce((resultJills, filter) => {
            const [filterFn, stateKey,] = filter;
            return filterFn(resultJills, this.store[stateKey]);
        }, this.store.jills);
    }
    sortJills() {
        this.store.jills = (0, utils_1.sortJills)(this.store.jills, this.store.selectedSortOption);
    }
    resetFilters() {
        this.store.selectedAvailabilityStatus = InitialState.selectedAvailabilityStatus;
        this.store.selectedCallStatus = InitialState.selectedCallStatus;
        this.store.selectedOnlineStatus = InitialState.selectedOnlineStatus;
        this.store.selectedPodName = InitialState.selectedPodName;
        this.store.selectedSortOption = InitialState.selectedSortOption;
        this.store.selectedTeamName = InitialState.selectedTeamName;
        this.store.selectedWorkStatus = InitialState.selectedWorkStatus;
        this.store.selectedScheduleStatus = InitialState.selectedScheduleStatus;
        this.sortAndFilterJills();
    }
    addOrRemovePodFromSelectedDropDownPods(pod, type) {
        const selectedPods = this.getSelectedPodStateName(type);
        if (this.doesPodExistsInSelectedPods(selectedPods, pod)) {
            this.removePod(selectedPods, pod);
        }
        else {
            this.addPod(selectedPods, pod);
        }
    }
    getSelectedPodStateName(type) {
        return type === 'MAIN_PODS' ? 'selectedDropDownMainPods' : 'selectedDropDownFloatPods';
    }
    doesPodExistsInSelectedPods(selectedPods, pod) {
        return this.store[selectedPods].some(selectedPod => pod.id === selectedPod.id);
    }
    addPod(selectedPods, pod) {
        this.store[selectedPods] = [
            ...this.store[selectedPods],
            pod,
        ];
    }
    removePod(selectedPods, pod) {
        this.store[selectedPods] = this.store[selectedPods].filter(selectedPod => pod.id !== selectedPod.id);
    }
    clearSelectedDropDownPods() {
        this.store.selectedDropDownFloatPods = [];
        this.store.selectedDropDownMainPods = [];
    }
    getHtmlForMainDropDownPods(pod, state) {
        return (0, lit_html_1.html) `
            <div @click=${() => this.addOrRemovePodFromSelectedDropDownPods(pod, 'MAIN_PODS')}>
                <input
                    type="checkbox"
                    class="jo-global--pointer"
                    .checked=${state.selectedDropDownMainPods.some(selectedMainPod => selectedMainPod.id === pod.id)}
                />
                ${pod.name}
            </div>
        `;
    }
    getHtmlForFloatDropDownPods(pod, state) {
        return (0, lit_html_1.html) `
            <div @click=${() => this.addOrRemovePodFromSelectedDropDownPods(pod, 'FLOAT_PODS')}>
                <input
                    type="checkbox"
                    class="jo-global--pointer"
                    .checked=${state.selectedDropDownFloatPods.some(selectedFloatPod => selectedFloatPod.id === pod.id)}
                />
                ${pod.name}
            </div>
        `;
    }
    async applyPodSelectionToAllSelectedJills() {
        await this.updateFilteredJillsPods();
        (0, utilities_1.joAlert)('Success', 'Changes have been applied successfully.');
    }
    async updateFilteredJillsPods() {
        const promises = this.store.filteredJills.map(jill => {
            (0, mutations_1.updateJillPods)(jill, this.store.selectedDropDownMainPods, this.store.selectedDropDownFloatPods);
        });
        await Promise.all(promises);
    }
    render(state) {
        if (state.loading === true) {
            return (0, lit_html_1.html) `
                <jo-loading-text></jo-loading-text>
            `;
        }
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

            ${styles_1.stylesForJoJillsPods}

            <div class="${cssName('main-container')}">
                <section class="${cssName('filter-inputs-container')}">
                    <div class="${cssName('filter-container-top-row')}">
                        <div>
                            <button
                                ${(0, test_id_directive_1.testId)('jills-pods-reset-filter-button')}
                                @click=${() => {
            this.resetFilters();
        }}
                            >
                                Reset Filters
                            </button>
                        </div>

                        <div style="display: flex; align-items: center;">
                            <strong>Total: ${state.filteredJills.length}</strong>
                        </div>

                        <jo-input
                            .type=${'SEARCH'}
                            @searchTextChanged=${(e) => {
            this.store.searchText = e.detail.searchText;
            this.sortAndFilterJills();
        }}
                            .inputValue=${state.searchText}
                        ></jo-input>
                    </div>

                    <div class="${cssName('filter-container-bottom-row')}">
                        <jo-input
                            ${(0, test_id_directive_1.testId)('filter-by-pod')}
                            .type=${'SELECT'}
                            .label=${'Pod'}
                            .selectOptions=${[
            'All Pods',
            ...state.allPods.map(pod => pod.name),
        ]}
                            .selectedValue=${state.selectedPodName}
                            @valueChanged=${(e) => {
            this.store.selectedPodName = e.detail.selectedValue;
            this.sortAndFilterJills();
        }}
                        ></jo-input>

                        <jo-input
                            .type=${'SELECT'}
                            .label=${'Team'}
                            .selectOptions=${[
            'All Teams',
            ...state.teams.map(team => {
                return (0, utils_1.getTeamName)(team);
            }),
        ]}
                            .selectedValue=${state.selectedTeamName}
                            @valueChanged=${(e) => {
            this.store.selectedTeamName = e.detail.selectedValue;
            this.sortAndFilterJills();
        }}
                        ></jo-input>

                        <jo-input
                            ${(0, test_id_directive_1.testId)('filter-by-availability')}
                            .type=${'SELECT'}
                            .label=${'Availability'}
                            .selectOptions=${[
            'All',
            'Available',
            'Unavailable',
        ]}
                            .selectedValue=${state.selectedAvailabilityStatus}
                            @valueChanged=${(e) => {
            this.store.selectedAvailabilityStatus = e.detail
                .selectedValue;
            this.sortAndFilterJills();
        }}
                        ></jo-input>

                        <jo-input
                            ${(0, test_id_directive_1.testId)('filter-by-call-status')}
                            .type=${'SELECT'}
                            .label=${'Call Status'}
                            .selectOptions=${[
            'All',
            'On a call',
            'Not on a call',
        ]}
                            .selectedValue=${state.selectedCallStatus}
                            @valueChanged=${(e) => {
            this.store.selectedCallStatus = e.detail
                .selectedValue;
            this.sortAndFilterJills();
        }}
                        ></jo-input>

                        <jo-input
                            ${(0, test_id_directive_1.testId)('filter-by-online-status')}
                            .type=${'SELECT'}
                            .label=${'Online Status'}
                            .selectOptions=${[
            'All',
            'Online',
            'Offline',
        ]}
                            .selectedValue=${state.selectedOnlineStatus}
                            @valueChanged=${(e) => {
            this.store.selectedOnlineStatus = e.detail
                .selectedValue;
            this.sortAndFilterJills();
        }}
                        ></jo-input>

                        <jo-input
                            ${(0, test_id_directive_1.testId)('filter-by-work-status')}
                            .type=${'SELECT'}
                            .label=${'Work Status'}
                            .selectOptions=${utils_1.workStatusOptions}
                            .selectedValue=${state.selectedWorkStatus}
                            @valueChanged=${(e) => {
            this.store.selectedWorkStatus = e.detail
                .selectedValue;
            this.sortAndFilterJills();
        }}
                        ></jo-input>

                        <jo-input
                            .type=${'SELECT'}
                            .label=${'Schedule'}
                            .selectOptions=${utils_1.scheduleStatusOptions}
                            .selectedValue=${state.selectedScheduleStatus}
                            @valueChanged=${(e) => {
            this.store.selectedScheduleStatus = e.detail
                .selectedValue;
            this.sortAndFilterJills();
        }}
                        ></jo-input>

                        <jo-input
                            .type=${'SELECT'}
                            .label=${'Auth Role'}
                            .selectOptions=${utils_1.authRoleOptions}
                            .selectedValue=${state.selectedAuthRole}
                            @valueChanged=${(e) => {
            this.store.selectedAuthRole = e.detail
                .selectedValue;
            this.sortAndFilterJills();
        }}
                        ></jo-input>

                        <div class="${cssName('drop-down-container')}">
                            <jo-button .text=${'Update All Pods'}></jo-button>

                            <div class="${cssName('update-all-pods-container')} jo-global--card">
                                <div style="padding-bottom: 1rem;">
                                    <div class="${cssName('pod-title')}">Main Pods:</div>
                                    ${state.allPods.map(pod => {
            return this.getHtmlForMainDropDownPods(pod, state);
        })}
                                </div>

                                <div class="${cssName('pod-title')}">Float Pods:</div>
                                ${state.allPods.map(pod => {
            return this.getHtmlForFloatDropDownPods(pod, state);
        })}

                                <div class="${cssName('dropDown-buttons')}">
                                    <jo-button
                                        .text=${'Clear'}
                                        @click=${() => {
            this.clearSelectedDropDownPods();
        }}
                                    ></jo-button>

                                    <jo-button
                                        .text=${'Apply'}
                                        @click=${() => {
            this.applyPodSelectionToAllSelectedJills();
        }}
                                    ></jo-button>
                                </div>
                            </div>
                        </div>

                        <jo-input
                            .type=${'SELECT'}
                            .label=${'Chat Status'}
                            .selectOptions=${[
            'All',
            ...(0, common_1.getEnumTypedValues)(make_chat_status_1.JillChatActivityStatus),
        ]}
                            .selectedValue=${state.selectedChatStatus}
                            @valueChanged=${(e) => {
            this.store.selectedChatStatus = e.detail.selectedValue;
            this.sortAndFilterJills();
        }}
                        ></jo-input>

                        <jo-input
                            .type=${'SELECT'}
                            .label=${'Order By'}
                            .selectOptions=${utils_1.defaultOrderByOptions}
                            .selectedValue=${state.selectedSortOption}
                            @valueChanged=${(e) => {
            this.store.selectedSortOption = e.detail
                .selectedValue;
            this.sortAndFilterJills();
        }}
                        ></jo-input>
                    </div>
                </section>

                <section>
                    ${state.jills.map(jill => (0, lit_html_1.html) `
                            <div
                                style="padding-bottom: 2rem; ${(0, utils_1.isJillInFilteredJills)(jill, state.filteredJills)
            ? ''
            : 'display: none'}"
                            >
                                <jo-jill-pods
                                    .allPods=${state.allPods}
                                    .jill=${jill}
                                    @jillPodsUpdated=${(e) => this.fetchAndSetJills()}
                                ></jo-jill-pods>
                            </div>
                        `)}
                </section>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-jills-pods', JOJillsPods);
