"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const graphql_1 = require("../../services/graphql");
const reduxular_1 = require("reduxular");
require("./jo-security-audits");
require("./jo-button.element");
const utilities_1 = require("../../services/utilities");
const utilities_2 = require("../../services/utilities");
const error_handling_1 = require("../../utilities/error-handling");
const no_scroll_on_focus_1 = require("../../utilities/input-event-listeners/no-scroll-on-focus");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../utilities/environment/mapped-env-variables");
const InitialState = {
    areTestCallsAllowedDuringClosedHours: false,
    businessHours: [],
    cronJobs: {
        dailyReportCronJob: 'NOT_SET',
        monthlyInvoicingCronJob: 'NOT_SET',
        monthlyChargingCronJob: 'NOT_SET',
        weeklyInvoicingCronJob: 'NOT_SET',
        weeklyChargingCronJob: 'NOT_SET',
    },
    isIncomingQueueEnabled: false,
    monthlyCompanyReportCronJob: 'NOT_SET',
};
class JOControlPanel extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    async connectedCallback() {
        await this.fetchAndSetBusinessHours();
        await this.fetchAndSetInvoicingCronJobs();
        await this.fetchAndSetChargingCronJobs();
        await this.fetchAndSetDailyReportCronJob();
        await this.fetchAndSetMonthlyCompanyReportCronJob();
        await this.fetchAndSetIsIncomingQueueEnabled();
        await this.fetchAndSetAreTestCallsAllowedDuringBusinessHours();
    }
    async fetchAndSetBusinessHours() {
        this.store.businessHours = await fetchBusinessHours();
    }
    async fetchAndSetInvoicingCronJobs() {
        const monthlyInvoicingCronJob = await fetchInvoicingCronJob('MONTHLY');
        const weeklyInvoicingCronJob = await fetchInvoicingCronJob('WEEKLY');
        this.store.cronJobs = {
            ...this.store.cronJobs,
            monthlyInvoicingCronJob: monthlyInvoicingCronJob ?? 'NOT_SET',
            weeklyInvoicingCronJob: weeklyInvoicingCronJob ?? 'NOT_SET',
        };
    }
    async fetchAndSetChargingCronJobs() {
        const monthlyChargingCronJob = await fetchChargingCronJob('MONTHLY');
        const weeklyChargingCronJob = await fetchChargingCronJob('WEEKLY');
        this.store.cronJobs = {
            ...this.store.cronJobs,
            monthlyChargingCronJob: monthlyChargingCronJob ?? 'NOT_SET',
            weeklyChargingCronJob: weeklyChargingCronJob ?? 'NOT_SET',
        };
    }
    async fetchAndSetDailyReportCronJob() {
        const dailyReportCronJob = await fetchDailyReportCronJob();
        this.store.cronJobs = {
            ...this.store.cronJobs,
            dailyReportCronJob: dailyReportCronJob ?? 'NOT_SET',
        };
    }
    async fetchAndSetMonthlyCompanyReportCronJob() {
        const cronJob = await fetchMonthlyCompanyReportCronJob();
        if (cronJob == null) {
            throw new Error('cronjob is null');
        }
        this.store.monthlyCompanyReportCronJob = cronJob;
    }
    async fetchAndSetIsIncomingQueueEnabled() {
        const getIsIncomingQueueEnabledResult = await getIsIncomingQueueEnabled();
        (0, utilities_1.assertSucceeded)(getIsIncomingQueueEnabledResult, utilities_1.handleError);
        this.store.isIncomingQueueEnabled = getIsIncomingQueueEnabledResult.value;
    }
    async fetchAndSetAreTestCallsAllowedDuringBusinessHours() {
        const getAreTestCallsAllowedDuringClosedHoursResult = await getAreTestCallsDuringClosedHoursAllowed();
        (0, utilities_1.assertSucceeded)(getAreTestCallsAllowedDuringClosedHoursResult, utilities_1.handleError);
        this.store.areTestCallsAllowedDuringClosedHours =
            getAreTestCallsAllowedDuringClosedHoursResult.value;
    }
    async handleChangeProcessIncomingQueueEnabledStatus() {
        const changeIncomingQueueEnabledStatusResult = await changeIncomingQueueEnabledStatus(!this.store.isIncomingQueueEnabled);
        (0, utilities_1.assertSucceeded)(changeIncomingQueueEnabledStatusResult, utilities_1.handleError);
        await this.fetchAndSetIsIncomingQueueEnabled();
    }
    async setNewBusinessHours() {
        const newBusinessHours = this.store.businessHours.map((businessHour) => {
            const dayOfWeek = businessHour.day_of_week;
            const openInput = this.querySelector(`#${dayOfWeek}-open-input`);
            const closeInput = this.querySelector(`#${dayOfWeek}-close-input`);
            if (openInput === null || closeInput === null) {
                alert('Update failed');
                throw new Error('openInput or closeInput null');
            }
            const newOpen = openInput.value;
            const newClose = closeInput.value;
            const newBusinessHour = {
                ...businessHour,
                open: newOpen,
                close: newClose,
            };
            return newBusinessHour;
        });
        await updateBusinessHours(newBusinessHours);
        await this.fetchAndSetBusinessHours();
        alert('Update complete');
    }
    async setNewInvoicingCronJob(billingCycleType) {
        const idPrefix = billingCycleType.toLowerCase();
        const dayInput = this.querySelector(`#${idPrefix}-invoicing-cron-job-day-input`);
        const hourInput = this.querySelector(`#${idPrefix}-invoicing-cron-job-hour-input`);
        const minuteInput = this.querySelector(`#${idPrefix}-invoicing-cron-job-minute-input`);
        if (dayInput === null || hourInput === null || minuteInput === null) {
            alert('One of the inputs is null;');
            return;
        }
        const cronJob = billingCycleType === 'WEEKLY'
            ? this.store.cronJobs.weeklyInvoicingCronJob
            : this.store.cronJobs.monthlyInvoicingCronJob;
        if (cronJob === 'NOT_SET') {
            alert(`${billingCycleType} Invoicing cron job is not set`);
            return;
        }
        const cronJobType = billingCycleType === 'WEEKLY' ? 'INVOICING_WEEKLY' : 'INVOICING_MONTHLY';
        const updateCronJobResult = await updateCronJob({
            id: cronJob.id,
            type: cronJobType,
            day: parseInt(dayInput.value),
            hour: parseInt(hourInput.value),
            minute: parseInt(minuteInput.value),
        });
        (0, utilities_1.assertSucceeded)(updateCronJobResult);
        await this.fetchAndSetInvoicingCronJobs();
        alert('Update complete');
    }
    async setNewChargingCronJob(billingCycleType) {
        const idPrefix = billingCycleType.toLowerCase();
        const dayInput = this.querySelector(`#${idPrefix}-charging-cron-job-day-input`);
        const hourInput = this.querySelector(`#${idPrefix}-charging-cron-job-hour-input`);
        const minuteInput = this.querySelector(`#${idPrefix}-charging-cron-job-minute-input`);
        if (dayInput === null || hourInput === null || minuteInput === null) {
            alert('One of the inputs is null');
            return;
        }
        const cronJob = billingCycleType === 'WEEKLY'
            ? this.store.cronJobs.weeklyChargingCronJob
            : this.store.cronJobs.monthlyChargingCronJob;
        if (cronJob === 'NOT_SET') {
            (0, utilities_1.joAlert)('Alert', `${billingCycleType} Charging cron job is not set`);
            return;
        }
        const updateCronJobResult = await updateCronJob({
            id: cronJob.id,
            type: billingCycleType === 'WEEKLY' ? 'CHARGING_WEEKLY' : 'CHARGING_MONTHLY',
            day: parseInt(dayInput.value),
            hour: parseInt(hourInput.value),
            minute: parseInt(minuteInput.value),
        });
        (0, utilities_1.assertSucceeded)(updateCronJobResult);
        await this.fetchAndSetChargingCronJobs();
        alert('Update complete');
    }
    async setNewDailyReportCronJob() {
        const hourInput = this.querySelector(`#daily-report-cron-job-hour-input`);
        const minuteInput = this.querySelector(`#daily-report-cron-job-minute-input`);
        if (hourInput === null || minuteInput === null) {
            alert('Update failed');
            return;
        }
        if (this.store.cronJobs.dailyReportCronJob === 'NOT_SET') {
            const message = 'Daily report cron job is not set';
            alert(message);
            throw new Error(message);
        }
        const updateCronJobResult = await updateCronJob({
            id: this.store.cronJobs.dailyReportCronJob.id,
            type: 'DAILY_REPORT',
            hour: parseInt(hourInput.value),
            minute: parseInt(minuteInput.value),
        });
        (0, utilities_1.assertSucceeded)(updateCronJobResult);
        await this.fetchAndSetDailyReportCronJob();
        alert('Update complete');
    }
    async setNewMonthlyCompanyReportCronJob() {
        const hourInput = this.querySelector(`#monthly-company-report-cron-job-hour-input`);
        const minuteInput = this.querySelector(`#monthly-company-report-cron-job-minute-input`);
        if (hourInput === null || minuteInput === null) {
            alert('Update failed');
            return;
        }
        const newHour = parseInt(hourInput.value);
        const newMinute = parseInt(minuteInput.value);
        if (this.store.monthlyCompanyReportCronJob === 'NOT_SET') {
            const message = 'Monthly company report cron job is not set';
            alert(message);
            throw new Error(message);
        }
        const updateCronJobResult = await updateCronJob({
            id: this.store.monthlyCompanyReportCronJob.id,
            type: 'MONTHLY_COMPANY_REPORT',
            hour: parseInt(hourInput.value),
            minute: parseInt(minuteInput.value),
        });
        (0, utilities_1.assertSucceeded)(updateCronJobResult);
        await this.fetchAndSetMonthlyCompanyReportCronJob();
        alert('Update complete');
    }
    async resetAllUsersQueueStateAndAvailbilityClicked() {
        const shouldContinue = await (0, utilities_1.joConfirm)('WARNING', 'This should only be done if calls have stopped coming in. Are you sure you want to continue?');
        if (shouldContinue !== true) {
            return;
        }
        const result = await resetAllUsersQueueStateAndAvailability();
        (0, utilities_1.assertSucceeded)(result, utilities_1.handleError);
        (0, utilities_1.joAlert)('Success', `All users' queue state and availability have been reset. If calls are still not coming in, reach out to the dev team`);
    }
    async handleChangeAreTestCallsAllowedDuringClosedHours() {
        (0, utilities_1.assertIsSet)(this.store.areTestCallsAllowedDuringClosedHours, utilities_1.handleError, 'this.store.areTestCallsAllowedDuringClosedHours');
        const changeAreTestCallsAllowedDuringClosedHoursResult = await changeAreTestCallsAllowedDuringClosedHours(!this.store.areTestCallsAllowedDuringClosedHours);
        (0, utilities_1.assertSucceeded)(changeAreTestCallsAllowedDuringClosedHoursResult, utilities_1.handleError);
        this.fetchAndSetAreTestCallsAllowedDuringBusinessHours();
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .jo-control-panel--container {
                    padding: calc(25px + 1vmin);
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                }

                .flex-center {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            </style>

            <main class="jo-control-panel--container">
                <div class="jo-control-panel--business-hours">
                    ${state.businessHours.length === 0
            ? `Loading...`
            : (0, lit_html_1.html) `
                              <h3>Jill's Office Hours</h3>

                              <br />

                              ${state.businessHours.map((businessHoursForDayOfWeek) => {
                return (0, lit_html_1.html) `
                                          <h4>${businessHoursForDayOfWeek.day_of_week}</h4>
                                          <div>
                                              open:
                                              <input
                                                  id="${businessHoursForDayOfWeek.day_of_week}-open-input"
                                                  type="time"
                                                  value=${businessHoursForDayOfWeek.open}
                                              />

                                              close:
                                              <input
                                                  id="${businessHoursForDayOfWeek.day_of_week}-close-input"
                                                  type="time"
                                                  value=${businessHoursForDayOfWeek.close}
                                              />
                                          </div>
                                          <br />
                                      `;
            })}

                              <button @click=${() => this.setNewBusinessHours()}>Update</button>
                          `}
                </div>

                <section class="jo-control-panel--invoicing-cron-job">
                    <div>
                        ${state.cronJobs.monthlyInvoicingCronJob === 'NOT_SET'
            ? 'Loading...'
            : (0, lit_html_1.html) `
                                  <h3>Monthly Invoicing</h3>

                                  <br />

                                  <div>
                                      Status:
                                      <span
                                          style="color: ${state.cronJobs.monthlyInvoicingCronJob
                .status === 'SCHEDULED'
                ? 'green'
                : 'red'}"
                                      >
                                          ${state.cronJobs.monthlyInvoicingCronJob.status}
                                      </span>
                                  </div>

                                  <br />

                                  <div>Run invoicing every:</div>

                                  <br />

                                  <div>
                                      <div>Day (1-27)</div>
                                      <input
                                          id="monthly-invoicing-cron-job-day-input"
                                          type="number"
                                          min="1"
                                          max="27"
                                          @focus=${(e) => {
                (0, no_scroll_on_focus_1.noScrollOnFocus)(e);
            }}
                                          .value=${state.cronJobs.monthlyInvoicingCronJob.day.toString()}
                                      />
                                  </div>

                                  <br />

                                  <div>
                                      <div>Hour (0-23)</div>
                                      <input
                                          id="monthly-invoicing-cron-job-hour-input"
                                          min="0"
                                          max="23"
                                          @focus=${(e) => {
                (0, no_scroll_on_focus_1.noScrollOnFocus)(e);
            }}
                                          type="number"
                                          .value=${state.cronJobs.monthlyInvoicingCronJob.hour.toString()}
                                      />
                                  </div>

                                  <br />

                                  <div>
                                      <div>Minute (0-59)</div>
                                      <input
                                          id="monthly-invoicing-cron-job-minute-input"
                                          type="number"
                                          min="0"
                                          max="59"
                                          @focus=${(e) => {
                (0, no_scroll_on_focus_1.noScrollOnFocus)(e);
            }}
                                          .value=${state.cronJobs.monthlyInvoicingCronJob.minute.toString()}
                                      />
                                  </div>

                                  <br />

                                  <button @click=${() => this.setNewInvoicingCronJob('MONTHLY')}>
                                      Update
                                  </button>
                              `}
                    </div>

                    <br />
                    <br />
                    <br />
                    <br />

                    <div>
                        ${state.cronJobs.weeklyInvoicingCronJob === 'NOT_SET'
            ? 'Loading...'
            : (0, lit_html_1.html) `
                                  <h3>Weekly Invoicing</h3>

                                  <br />

                                  <div>
                                      Status:
                                      <span
                                          style="color: ${state.cronJobs.weeklyInvoicingCronJob
                .status === 'SCHEDULED'
                ? 'green'
                : 'red'}"
                                      >
                                          ${state.cronJobs.weeklyInvoicingCronJob.status}
                                      </span>
                                  </div>

                                  <br />

                                  <div>Run invoicing every:</div>

                                  <br />

                                  <div>
                                      <div>Day (1-7 SUN-SAT)</div>
                                      <input
                                          id="weekly-invoicing-cron-job-day-input"
                                          type="number"
                                          min="1"
                                          max="7"
                                          @focus=${(e) => {
                (0, no_scroll_on_focus_1.noScrollOnFocus)(e);
            }}
                                          .value=${state.cronJobs.weeklyInvoicingCronJob.day.toString()}
                                      />
                                  </div>

                                  <br />

                                  <div>
                                      <div>Hour (0-23)</div>
                                      <input
                                          id="weekly-invoicing-cron-job-hour-input"
                                          type="number"
                                          min="0"
                                          max="23"
                                          @focus=${(e) => {
                (0, no_scroll_on_focus_1.noScrollOnFocus)(e);
            }}
                                          .value=${state.cronJobs.weeklyInvoicingCronJob.hour.toString()}
                                      />
                                  </div>

                                  <br />

                                  <div>
                                      <div>Minute (0-59)</div>
                                      <input
                                          id="weekly-invoicing-cron-job-minute-input"
                                          type="number"
                                          min="0"
                                          max="59"
                                          @focus=${(e) => {
                (0, no_scroll_on_focus_1.noScrollOnFocus)(e);
            }}
                                          .value=${state.cronJobs.weeklyInvoicingCronJob.minute.toString()}
                                      />
                                  </div>

                                  <br />

                                  <button @click=${() => this.setNewInvoicingCronJob('WEEKLY')}>
                                      Update
                                  </button>
                              `}
                    </div>
                </section>

                <section class="jo-control-panel--charging-cron-job">
                    <div>
                        ${state.cronJobs.monthlyChargingCronJob === 'NOT_SET'
            ? 'Loading...'
            : (0, lit_html_1.html) `
                                  <h3>Monthly Charging</h3>

                                  <br />

                                  <div>
                                      Status:
                                      <span
                                          style="color: ${state.cronJobs.monthlyChargingCronJob
                .status === 'SCHEDULED'
                ? 'green'
                : 'red'}"
                                      >
                                          ${state.cronJobs.monthlyChargingCronJob.status}
                                      </span>
                                  </div>

                                  <br />

                                  <div>Run charging every other day starting:</div>

                                  <br />

                                  <div>
                                      <div>Day (1-27)</div>
                                      <input
                                          id="monthly-charging-cron-job-day-input"
                                          type="number"
                                          min="1"
                                          max="27"
                                          @focus=${(e) => {
                (0, no_scroll_on_focus_1.noScrollOnFocus)(e);
            }}
                                          .value=${state.cronJobs.monthlyChargingCronJob.day.toString()}
                                      />
                                  </div>

                                  <br />

                                  <div>
                                      <div>Hour (0-23)</div>
                                      <input
                                          id="monthly-charging-cron-job-hour-input"
                                          type="number"
                                          min="0"
                                          max="23"
                                          @focus=${(e) => {
                (0, no_scroll_on_focus_1.noScrollOnFocus)(e);
            }}
                                          .value=${state.cronJobs.monthlyChargingCronJob.hour.toString()}
                                      />
                                  </div>

                                  <br />

                                  <div>
                                      <div>Minute (0-59)</div>
                                      <input
                                          id="monthly-charging-cron-job-minute-input"
                                          type="number"
                                          min="0"
                                          max="59"
                                          @focus=${(e) => {
                (0, no_scroll_on_focus_1.noScrollOnFocus)(e);
            }}
                                          .value=${state.cronJobs.monthlyChargingCronJob.minute.toString()}
                                      />
                                  </div>

                                  <br />

                                  <button @click=${() => this.setNewChargingCronJob('MONTHLY')}>
                                      Update
                                  </button>
                              `}
                    </div>

                    <br />
                    <br />
                    <br />
                    <br />

                    <div>
                        ${state.cronJobs.weeklyChargingCronJob === 'NOT_SET'
            ? 'Loading...'
            : (0, lit_html_1.html) `
                                  <h3>Weekly Charging</h3>

                                  <br />

                                  <div>
                                      Status:
                                      <span
                                          style="color: ${state.cronJobs.weeklyChargingCronJob
                .status === 'SCHEDULED'
                ? 'green'
                : 'red'}"
                                      >
                                          ${state.cronJobs.weeklyChargingCronJob.status}
                                      </span>
                                  </div>

                                  <br />

                                  <div>Run charging every other day starting:</div>

                                  <br />

                                  <div>
                                      <div>Day (1-7 SUN-SAT)</div>
                                      <input
                                          id="weekly-charging-cron-job-day-input"
                                          type="number"
                                          min="1"
                                          max="7"
                                          @focus=${(e) => {
                (0, no_scroll_on_focus_1.noScrollOnFocus)(e);
            }}
                                          .value=${state.cronJobs.weeklyChargingCronJob.day.toString()}
                                      />
                                  </div>

                                  <br />

                                  <div>
                                      <div>Hour (0-23)</div>
                                      <input
                                          id="weekly-charging-cron-job-hour-input"
                                          type="number"
                                          min="0"
                                          max="23"
                                          @focus=${(e) => {
                (0, no_scroll_on_focus_1.noScrollOnFocus)(e);
            }}
                                          .value=${state.cronJobs.weeklyChargingCronJob.hour.toString()}
                                      />
                                  </div>

                                  <br />

                                  <div>
                                      <div>Minute (0-59)</div>
                                      <input
                                          id="weekly-charging-cron-job-minute-input"
                                          type="number"
                                          min="0"
                                          max="59"
                                          @focus=${(e) => {
                (0, no_scroll_on_focus_1.noScrollOnFocus)(e);
            }}
                                          .value=${state.cronJobs.weeklyChargingCronJob.minute.toString()}
                                      />
                                  </div>

                                  <br />

                                  <button @click=${() => this.setNewChargingCronJob('WEEKLY')}>
                                      Update
                                  </button>
                              `}
                    </div>
                </section>

                <div class="jo-control-panel--daily-report-cron-job">
                    ${state.cronJobs.dailyReportCronJob === 'NOT_SET'
            ? 'Loading...'
            : (0, lit_html_1.html) `
                              <h3>Daily Report</h3>

                              <br />

                              <div>
                                  Status:
                                  <span
                                      style="color: ${state.cronJobs.dailyReportCronJob.status ===
                'SCHEDULED'
                ? 'green'
                : 'red'}"
                                  >
                                      ${state.cronJobs.dailyReportCronJob.status}
                                  </span>
                              </div>

                              <br />

                              <div>Run the daily report every:</div>

                              <br />

                              <div>
                                  <div>Hour (0-23)</div>
                                  <input
                                      id="daily-report-cron-job-hour-input"
                                      type="number"
                                      min="0"
                                      max="23"
                                      @focus=${(e) => {
                (0, no_scroll_on_focus_1.noScrollOnFocus)(e);
            }}
                                      .value=${state.cronJobs.dailyReportCronJob.hour.toString()}
                                  />
                              </div>

                              <br />

                              <div>
                                  <div>Minute (0-59)</div>
                                  <input
                                      id="daily-report-cron-job-minute-input"
                                      type="number"
                                      min="0"
                                      max="59"
                                      @focus=${(e) => {
                (0, no_scroll_on_focus_1.noScrollOnFocus)(e);
            }}
                                      .value=${state.cronJobs.dailyReportCronJob.minute.toString()}
                                  />
                              </div>

                              <br />

                              <button @click=${() => this.setNewDailyReportCronJob()}>
                                  Update
                              </button>
                          `}
                </div>

                <div class="jo-control-panel--security-audits" style="padding-top: 2rem">
                    <jo-security-audits></jo-security-audits>
                </div>

                <div style="padding-top: 2rem">
                    <h3>Enable/Disable Process Incoming Queue Lambda</h3>
                    <h4>
                        This will stop calls from being sent to jills, and will allow the Process
                        Incoming Queue Lambda to quickly spin down. Calls will still come into the
                        queue.
                    </h4>
                    <jo-button
                        .text=${state.isIncomingQueueEnabled
            ? 'Turn Off Process Incoming Queue'
            : 'Turn On Process Incoming Queue'}
                        .type=${state.isIncomingQueueEnabled
            ? 'DELETE_BUTTON_1'
            : 'ACTION_BUTTON_GREEN'}
                        @joButtonClick=${() => this.handleChangeProcessIncomingQueueEnabledStatus()}
                    ></jo-button>
                </div>

                <div style="padding-top: 2rem">
                    <h3>Allow test calls during closed hours?</h3>
                    <p>
                        Enabling this will ONLY allow calls to come in during closed hours for pods
                        with the words "training" or "test" in their names
                    </p>
                    <jo-button
                        .text=${state.areTestCallsAllowedDuringClosedHours === false
            ? 'Enable Test Calls During Closed Hours'
            : 'Disable Test Calls During Closed Hours'}
                        .type=${state.areTestCallsAllowedDuringClosedHours === false
            ? 'ACTION_BUTTON_GREEN'
            : 'DELETE_BUTTON_1'}
                        @joButtonClick=${() => this.handleChangeAreTestCallsAllowedDuringClosedHours()}
                    ></jo-button>
                </div>

                <div class="flex-center">
                    <jo-button
                        .text=${'Reset all availability and queue state for all users'}
                        .type=${'DELETE_BUTTON_1'}
                        @joButtonClick=${() => this.resetAllUsersQueueStateAndAvailbilityClicked()}
                    ></jo-button>
                </div>

                <div>
                    <jo-button
                        .type=${'SECONDARY_BUTTON_1'}
                        .text=${'Manage Scheduled Down Times'}
                        @joButtonClick=${() => (0, utilities_2.navigate)(`/scheduled-down-times`)}
                    ></jo-button>
                </div>

                <div
                    class="jo-control-panel--monthly-company-report-cron-job"
                    style="padding-top: 2rem"
                >
                    <h3>Ensure Lambda Warmup Cron Job is Running</h3>

                    <button
                        @click=${async () => {
            await rescheduleLambdaWarmupCronJob();
            alert('Warmup cron jobs are set to go');
        }}
                    >
                        Reschedule Lamda Warmup Cron Job
                    </button>
                </div>
            </main>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-control-panel', JOControlPanel);
async function updateBusinessHours(businessHours) {
    await Promise.all(businessHours.map(async (businessHour) => {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                    mutation ($id: Int!, $open: String!, $close: String!) {
                        updateBusiness_hours(input: {id: $id, open: $open, close: $close}) {
                            id
                        }

                        rescheduleCronJobs(type: SET_JILLS_UNAVAILABLE)
                    }
                `, {
            id: businessHour.id,
            open: businessHour.open,
            close: businessHour.close,
        });
        if (!gqlResult.data?.updateBusiness_hours || !gqlResult.data?.rescheduleCronJobs) {
            alert('Update failed');
            throw new Error('Update failed');
        }
    }));
}
async function updateCronJob(params) {
    try {
        const { id, type, day, hour, minute } = params;
        const updateCronJobInput = {
            id,
            day: day ?? 0,
            hour,
            minute,
        };
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($updateCronJobInput: UpdateCron_jobsInput!, $type: CRON_JOB_TYPE!) {
                    updateCron_jobs(input: $updateCronJobInput) {
                        id
                    }

                    rescheduleCronJobs(type: $type)
                }
            `, {
            updateCronJobInput,
            type,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('updateInvoicingCronJob', error);
    }
}
async function fetchBusinessHours() {
    const businessHoursGQLResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
        query {
            findBusiness_hours(
                # The control panel is looking for Jill's Office business hours,
                # not a specific company's business hours.
                # So, filter out any business hours that are associated with a company.
                filter: {company_id: {eq: null}}
                orderBy: {field: "id", order: ASC}
            ) {
                items {
                    id
                    day_of_week
                    open
                    close
                }
            }
        }
    `);
    if (businessHoursGQLResult.data === null || businessHoursGQLResult.data === undefined) {
        throw new Error('There was a problem');
    }
    const businessHours = businessHoursGQLResult.data.findBusiness_hours.items;
    return businessHours;
}
async function fetchInvoicingCronJob(billingCycleType) {
    const invoicingCronJobGQLResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
            query ($type: String!) {
                findCron_jobs(filter: {type: {eq: $type}}) {
                    items {
                        id
                        day
                        hour
                        minute
                        status
                    }
                }
            }
        `, {
        type: billingCycleType === 'MONTHLY' ? 'INVOICING_MONTHLY' : 'INVOICING_WEEKLY',
    });
    if (invoicingCronJobGQLResult.data === null || invoicingCronJobGQLResult.data === undefined) {
        throw new Error('There was a problem');
    }
    return invoicingCronJobGQLResult.data.findCron_jobs.items[0];
}
async function fetchChargingCronJob(billingCycleType) {
    const chargingCronJobGQLResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
            query ($type: String!) {
                findCron_jobs(filter: {type: {eq: $type}}) {
                    items {
                        id
                        day
                        hour
                        minute
                        status
                    }
                }
            }
        `, {
        type: billingCycleType === 'MONTHLY' ? 'CHARGING_MONTHLY' : 'CHARGING_WEEKLY',
    });
    if (chargingCronJobGQLResult.data === null || chargingCronJobGQLResult.data === undefined) {
        throw new Error('There was a problem');
    }
    return chargingCronJobGQLResult.data.findCron_jobs.items[0];
}
async function fetchDailyReportCronJob() {
    const dailyReportCronJobGQLResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
        query {
            findCron_jobs(filter: {type: {eq: "DAILY_REPORT"}}) {
                items {
                    id
                    hour
                    minute
                    status
                }
            }
        }
    `);
    if (dailyReportCronJobGQLResult.data === null ||
        dailyReportCronJobGQLResult.data === undefined) {
        throw new Error('There was a problem');
    }
    return dailyReportCronJobGQLResult.data.findCron_jobs.items[0];
}
async function fetchMonthlyCompanyReportCronJob() {
    const monthlyCompanyReportCronJobGQLResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
        query {
            findCron_jobs(filter: {type: {eq: "MONTHLY_COMPANY_REPORT"}}) {
                items {
                    id
                    hour
                    minute
                    status
                }
            }
        }
    `);
    if (monthlyCompanyReportCronJobGQLResult.data === null ||
        monthlyCompanyReportCronJobGQLResult.data === undefined) {
        throw new Error('There was a problem');
    }
    return monthlyCompanyReportCronJobGQLResult.data.findCron_jobs.items[0];
}
async function resetAllUsersQueueStateAndAvailability() {
    try {
        const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
            mutation {
                resetAllUsersQueueStateAndAvailability
            }
        `);
        if (result.succeeded === false) {
            return result;
        }
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('resetAllUsersQueueStateAndAvailability', error);
    }
}
async function getIsIncomingQueueEnabled() {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
            query {
                getGlobal_configs(id: 1) {
                    id
                    process_incoming_queue_enabled
                }
            }
        `);
        if (gqlResult.succeeded === false) {
            return {
                ...gqlResult,
                developerMessage: `getIsIncomingQueueEnabled error: ${gqlResult}`,
            };
        }
        const globalConfig = gqlResult.value.data.getGlobal_configs;
        if (globalConfig === null || globalConfig === undefined) {
            return {
                succeeded: false,
                userMessage: 'There was an error',
                developerMessage: `getIsIncomingQueueEnabled error: no gloablConfig found`,
            };
        }
        return {
            succeeded: true,
            value: globalConfig.process_incoming_queue_enabled,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('getIsIncomingQueueEnabled', error);
    }
}
async function changeIncomingQueueEnabledStatus(enabled) {
    try {
        const updateIncomingQueueStatusResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($enabled: Boolean!) {
                    updateGlobal_configs(input: {id: 1, process_incoming_queue_enabled: $enabled}) {
                        id
                    }
                }
            `, {
            enabled,
        });
        if (updateIncomingQueueStatusResult.succeeded === false) {
            updateIncomingQueueStatusResult;
        }
        if (enabled === true) {
            const kickOffQueueResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation {
                    kickOffQueue
                }
            `);
            if (kickOffQueueResult.succeeded === false) {
                return kickOffQueueResult;
            }
        }
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('changeIncomingQueueEnabledStatus', error);
    }
}
async function getAreTestCallsDuringClosedHoursAllowed() {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
            query {
                getGlobal_configs(id: 1) {
                    id
                    allow_test_calls_during_closed_hours
                }
            }
        `);
        if (gqlResult.succeeded === false)
            return gqlResult;
        const globalConfig = gqlResult.value.data.getGlobal_configs;
        return {
            succeeded: true,
            value: globalConfig.allow_test_calls_during_closed_hours,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('getAreTestCallsDuringClosedHoursAllowed', error);
    }
}
async function changeAreTestCallsAllowedDuringClosedHours(allowTestCallsDuringClosedHours) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($allowTestCallsDuringClosedHours: Boolean!) {
                    updateGlobal_configs(
                        input: {
                            id: 1
                            allow_test_calls_during_closed_hours: $allowTestCallsDuringClosedHours
                        }
                    ) {
                        id
                    }
                }
            `, {
            allowTestCallsDuringClosedHours,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('changeAreTestCallsAllowedDuringClosedHours', error);
    }
}
async function rescheduleLambdaWarmupCronJob() {
    await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
        mutation {
            rescheduleCronJobs(type: WARM_LAMBDAS)
        }
    `);
}
