"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormErrorManager = void 0;
class FormErrorManager {
    constructor() {
        this.formErrors = [];
    }
    getFormErrors() {
        return this.formErrors;
    }
    addOrRemoveFormError(formError) {
        const formErrorsMinusErrorWithMatchingLabel = this.formErrors.filter(existingFormError => existingFormError.label !== formError.label);
        if (formError.errorMessage === '') {
            this.formErrors = formErrorsMinusErrorWithMatchingLabel;
        }
        else {
            this.formErrors = [
                ...formErrorsMinusErrorWithMatchingLabel,
                formError,
            ];
        }
    }
}
exports.FormErrorManager = FormErrorManager;
