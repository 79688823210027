"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStyles = void 0;
const lit_html_1 = require("lit-html");
function getStyles(state) {
    return (0, lit_html_1.html) `
        <style>
             .main-container {
                 height: 100%;
                 width: 100%;
                 box-sizing: border-box;
             }

             .jo-customer-action-item--col {
                 height: 100%;
                 box-sizing: border-box;
                 display: flex;
                 flex-wrap: wrap;
                 flex-direction: ${state.isMobileDevice ? `column` : `row`};
             }

             .jo-customer-action-item--container {
                 margin-bottom: 1rem;
                 margin-left: 1rem;
                 width: ${state.isMobileDevice ? `90%` : ``};
                 width: ${state.contactMode === 'NOT_SET' && state.isMobileDevice === false
        ? `50%`
        : ``}
             }

            .jo-customer-action-item--message-containers-container{
                ${state.isMobileDevice
        ? `/*Leave Empty*/`
        : `
                width: 45%;
                margin-bottom: 1rem;
                margin-left: 1rem;
                flex-wrap: wrap;
                height: 100%;
           `};
            }

             .jo-customer-action-item--contact-container {
                 margin-left: 0.5rem;
                 margin-right:0.5rem;
                 margin-bottom: 1rem;
             }

             .jo-customer-action-item--container-title {
                 margin-top: 1rem;
                 margin-left: 1rem;
                 margin-right: 1rem;
                 font-size: 1.2rem;
                 font-weight: bold;
                 font-family: sans-serif;
                 color: var(--jo-text-default);
             }

             .jo-cutomer-action-item--title-center {
                    text-align: center;
             }

             .jo-customer-action-item--container-messages {
                 margin: 1rem;
                 overflow-y: scroll;
                 overflow-x: hidden;
                 max-height: 20rem;
             }

             .jo-customer-action-item--new-customer-link {
                 float: right;
                 padding-bottom: 1rem;
                 padding-right: 1.5rem;
                 font-size: 0.8rem;
                 color: var(--jo-primary);
                 cursor: pointer;
             }

             .jo-customer-action-item--new-customer-link:hover {
                 color: var(--jo-secondary);
             }

             .jo-customer-action-item--search-bar {
                 margin: 1rem;
                 width: 90%;
             }

             .jo-customer-action-item--search-icon {
                 float: right;
                 padding-right: 1rem;
                 padding-top: 1rem;
             }

             .jo-customer-action-item--textarea {
                 width: 90%;
                 margin: 1rem;
                 height: 6rem;
             }

             .jo-customer-action-item--title{
                 width: 90%;
                 margin: 1rem;
             }

             .jo-customer-action-item--send-action-item-button-container {
                 padding: 1rem;
             }

             .jo-customer-action-item--send-action-item-buttons-container {
                 display: flex;
                 justify-content: space-between;
             }

             .jo-customer-action-item--handle-action-item-buttons-container {
                display: flex;
                justify-content: space-between;
                margin: 1rem;
             }

             .jo-customer-action-item--internal-notes {
                padding: 1rem;
                width: 95%
             }

             .jo-customer-action-item--internal-notes-buttons {
                display: flex;
                margin-top: 2rem;
                justify-content: flex-end;
             }

             .jo-customer-action-item--internal-note-cancel-button {
                padding-left: 1rem;
             }

             .jo-customer-action-item--top-action-buttons-container {
                display:flex;
                flex-direction:column;
                width: 100%;
             }

            .jo-customer-action-item--chat-transcript-container {
                width: 90%;
                padding: 1rem;
            }
            .jo-internal-notes-message{
                font-size: .8rem;
                margin-top: .4rem;
            }
        </style>
    `;
}
exports.getStyles = getStyles;
