"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.joCompanyLogsContainerPageStyles = void 0;
const element_vir_1 = require("element-vir");
exports.joCompanyLogsContainerPageStyles = (0, element_vir_1.css) `
    :host {
        display: block;
    }
    .card {
        box-sizing: border-box;
        background-color: white;
        border-radius: var(--jo-wide-border-radius-1);
        box-shadow: var(--jo-card-shadow-1);
        padding: 1rem;
    }
    .tabs-bar {
        display: flex;
        flex-direction: row;
        color: var(--jo-text-default);
        margin-bottom: 2rem;
    }
    .tab {
        cursor: pointer;
        padding: 0.5rem;
    }
    .tab:hover {
        background-color: var(--jo-accent-background-light);
    }
    .selected-tab {
        font-weight: bold;
        border-bottom: 1px solid var(--jo-text-default);
    }
`;
