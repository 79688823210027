"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTableColumns = void 0;
const lit_html_1 = require("lit-html");
const utilities_1 = require("../../../services/utilities");
function getTableColumns(state, store) {
    const columns = [
        {
            title: 'Actions',
            getCellData: (actionItem) => getActionsHTML(actionItem, state, store),
            width: 1,
        },
        {
            title: 'Company Name',
            getCellData: (actionItem) => actionItem.company?.name,
            width: 1,
        },
        {
            title: 'Timezone',
            getCellData: (actionItem) => actionItem.company?.timezone,
            width: 1,
        },
        {
            title: 'Pod',
            getCellData: (actionItem) => actionItem.company?.pod?.name,
            width: 1,
        },
        {
            title: 'Contact First Name',
            getCellData: (actionItem) => actionItem.contact?.first_name,
            width: 1,
        },
        {
            title: 'Contact Last Name',
            getCellData: (actionItem) => actionItem.contact?.last_name,
            width: 1,
        },
        {
            title: 'Contact Phone Number',
            getCellData: (actionItem) => actionItem.contact?.phone_number,
            width: 1,
        },
        {
            title: 'Title',
            getCellData: (actionItem) => actionItem.title,
            width: 1,
        },
        {
            title: 'Updated',
            getCellData: (actionItem) => new Date(actionItem.updated_at).toLocaleString(),
            width: 1,
        },
        {
            title: 'Created',
            getCellData: (actionItem) => new Date(actionItem.created_at).toLocaleString(),
            width: 1,
        },
    ];
    return columns;
}
exports.getTableColumns = getTableColumns;
function getActionsHTML(actionItem, state, store) {
    return (0, lit_html_1.html) `
        <div style="display: flex; align-items: center">
            <jo-button
                style="padding-right: 0.5rem"
                .type=${'ICON'}
                .icon=${'remove_red_eye'}
                .href=${`/action-item?actionItemId=${actionItem.id}&companyId=${actionItem.company?.id}&callScreenMode=ACTION_ITEM`}
            ></jo-button>

            <jo-button
                style="padding-right: 0.5rem"
                .type=${'ICON'}
                .icon=${'phone'}
                @joButtonClick=${() => (0, utilities_1.startOutgoingCallForActionItem)(actionItem, state.authenticatedUser)}
            ></jo-button>

            <div style="position: relative; display: flex; align-items: center;">
                <jo-button
                    .type=${'ICON'}
                    .icon=${'preview'}
                    @joButtonClick=${() => {
        if (store.selectedPreviewActionItemId === actionItem.id) {
            store.selectedPreviewActionItemId = 'NOT_SET';
        }
        else {
            store.selectedPreviewActionItemId = actionItem.id;
        }
    }}
                ></jo-button>

                <jo-fade-in-overlay
                    .child=${(0, lit_html_1.html) `
                        <div class="jo-global--action-item-preview-container">
                            <jo-action-item-preview
                                .props=${{
        actionItem,
        authenticatedUser: state.authenticatedUser,
    }}
                                @saved=${() => (store.selectedPreviewActionItemId = 'NOT_SET')}
                                @senttocompany=${() => (store.selectedPreviewActionItemId = 'NOT_SET')}
                            ></jo-action-item-preview>
                        </div>
                    `}
                    .showChild=${actionItem.id === state.selectedPreviewActionItemId}
                    @closeoverlay=${() => (store.selectedPreviewActionItemId = 'NOT_SET')}
                ></jo-fade-in-overlay>
            </div>
        </div>
    `;
}
