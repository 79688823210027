"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const user_status_event_mutations_1 = require("../../../utilities/logging-and-monitoring/user-status-event-mutations");
const helper_utils_1 = require("../jo-action-items/helper-utils");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
require("../jo-button.element");
require("../jo-input");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    actionItem: 'NOT_SET',
    selected: false,
};
class JOMobileActionItemCard extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    handleCheckboxClicked() {
        if (this.store.selected)
            (0, utilities_1.raiseCustomEvent)(this, 'deselected');
        else
            (0, utilities_1.raiseCustomEvent)(this, 'selected');
    }
    async navigateToActionItem() {
        await this.markActionItemAsOpened();
        if (this.store.actionItem === 'NOT_SET')
            return;
        (0, utilities_1.navigate)(`/customer/action-item?actionItemId=${this.store.actionItem.id}&customerActionItemMode=EDIT`);
    }
    async markActionItemAsOpened() {
        if (this.store.authenticatedUser !== 'NOT_SET' &&
            this.store.actionItem !== 'NOT_SET' &&
            this.store.actionItem &&
            this.store.actionItem.company) {
            await (0, user_status_event_mutations_1.createUserStatusEvent)({
                userId: this.store.authenticatedUser.id,
                eventType: 'OPENED_ACTION_ITEM',
                companyId: this.store.actionItem.company.id,
                actionItemId: this.store.actionItem.id,
            });
        }
        (0, utilities_1.raiseCustomEvent)(this, 'reRenderActionItems');
    }
    render(state) {
        if (state.actionItem === 'NOT_SET') {
            return (0, lit_html_1.html) ``;
        }
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    background-color: white;
                    border-radius: 10px;
                    padding: 1rem;
                    padding-top: 0.5rem;
                    box-shadow: 0 1px 5px rgb(0, 0, 0, 0.2);
                    font-size: 0.9rem;
                }

                .title {
                    display: flex;
                    justify-content: center;
                    padding-bottom: 1rem;
                    opacity: 0.5;
                    font-size: 0.8rem;
                }

                .top-row {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .date-and-checkbox-container {
                    display: flex;
                    align-items: center;
                    font-size: 0.8rem;
                }

                .checkbox-container {
                    padding: 0 1rem;
                    padding-top: 1rem;
                    margin-top: -1rem;
                    margin-right: -1rem;
                }

                .preview-text {
                    margin-top: 1rem;
                }
            </style>

            <div class="main-container">
                <section class="title">
                    <div
                        @click=${() => this.navigateToActionItem()}
                        ?hidden=${(0, helper_utils_1.shouldHideUnreadActionItemIndicator)(state.actionItem, state.authenticatedUser)}
                        style="padding-right: 1rem; color: var(--jo-danger)"
                    >
                        New
                    </div>
                    <strong @click=${() => this.navigateToActionItem()}>
                        <i><u>${state.actionItem.title || '(No title)'}</u></i>
                    </strong>
                </section>

                <section class="top-row">
                    <div @click=${() => this.navigateToActionItem()}>
                        <strong>${getContactName(state.actionItem.contact)}</strong>
                    </div>

                    <div class="date-and-checkbox-container">
                        <div @click=${() => this.navigateToActionItem()}>
                            ${formatTimestamp(state.actionItem.created_at)}
                        </div>

                        <div
                            class="checkbox-container"
                            @click=${() => this.handleCheckboxClicked()}
                        >
                            <input type="checkbox" .checked=${state.selected} />
                        </div>
                    </div>
                </section>

                <section class="preview-text" @click=${() => this.navigateToActionItem()}>
                    <div>${getActionItemPreviewText(state.actionItem)}</div>
                </section>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-mobile-action-item-card', JOMobileActionItemCard);
function getContactName(contact) {
    if (!contact)
        return '(No name)';
    if (!contact.first_name && !contact.last_name)
        return '(No name)';
    return contact.first_name + ' ' + contact.last_name;
}
function formatTimestamp(timestamp) {
    return new Date(timestamp).toLocaleString().replace(/:\d\d\s/, ' ');
}
function getActionItemPreviewText(actionItem) {
    const mostRecentNote = getMostRecentNoteForActionItem(actionItem);
    if (mostRecentNote === 'NOT_FOUND' || !mostRecentNote.body)
        return '';
    return truncateActionItemNoteBody(mostRecentNote.body);
}
function getMostRecentNoteForActionItem(actionItem) {
    const mostRecentNote = (0, utilities_1.sortItemsByTimeStamp)(actionItem.action_item_notes, 'CREATED_AT', 'DESC')[0];
    return mostRecentNote ?? 'NOT_FOUND';
}
function truncateActionItemNoteBody(noteBody) {
    if (noteBody === undefined)
        return '';
    const beginningOfNoteMaxLength = 95;
    return getBegnningOfNote(noteBody, beginningOfNoteMaxLength);
}
function getBegnningOfNote(noteBody, beginningOfNoteMaxLength) {
    const beginningOfNote = getRawBeginningOfNote(noteBody, beginningOfNoteMaxLength);
    if (beginningOfNote == null) {
        throw new Error('beginningOfNote is null');
    }
    if (beginningOfNote.length === beginningOfNoteMaxLength) {
        return beginningOfNote.trim() + '...';
    }
    return beginningOfNote;
}
function getRawBeginningOfNote(noteBody, endCharacterPosition) {
    return getNoteBodyMinusGeneratedStuff(noteBody)?.substring(0, endCharacterPosition);
}
function getNoteBodyMinusGeneratedStuff(noteBody) {
    return noteBody
        .split('Custom Fields')[0]
        ?.split('Selected Service')[0]
        ?.split('Service Line Items')[0];
}
