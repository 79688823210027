"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const graphql_1 = require("../../services/graphql");
const utilities_1 = require("../../services/utilities");
const reduxular_1 = require("reduxular");
const personal_admin_1 = require("../../utilities/personal-admin");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../utilities/environment/mapped-env-variables");
const common_1 = require("@augment-vir/common");
const InitialState = {
    jillId: 'NOT_SET',
    jill: 'NOT_SET',
    startDate: 'NOT_SET',
    endDate: 'NOT_SET',
    calls: [],
    fetchCallsAbortController: 'NOT_SET',
    loadingCalls: false,
    loading: false,
    authenticatedUser: 'NOT_SET',
};
class JOJillCard extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    set jillId(jillId) {
        this.store.jillId = jillId;
        if (jillId === 'NOT_SET') {
            return;
        }
        this.fetchSetAndSubscribeToJill();
    }
    set jill(jill) {
        // TODO keep an eye on this equality check, could be a source of strange issues
        if (jill === this.store.jill) {
            return;
        }
        this.store.jill = jill;
        if (jill === 'NOT_SET') {
            return;
        }
        this.subscribeToJill();
    }
    set startDate(startDate) {
        this.store.startDate = startDate;
        // this.fetchAndSetCalls();
    }
    set endDate(endDate) {
        this.store.endDate = endDate;
        // this.fetchAndSetCalls();
    }
    async fetchSetAndSubscribeToJill() {
        this.store.loading = true;
        const jillId = this.store.jillId;
        if (jillId === 'NOT_SET') {
            return;
        }
        const jill = await fetchJill(jillId);
        this.store.jill = jill;
        await this.subscribeToJill();
        this.store.loading = false;
    }
    async subscribeToJill() {
        this.store.loading = true;
        const jillId = this.store.jill === 'NOT_SET' ? 'NOT_SET' : this.store.jill.id;
        if (jillId === 'NOT_SET') {
            return;
        }
        await subscribeToJillIsAvailable(jillId, async () => {
            const jill = await fetchJill(jillId);
            this.store.jill = jill;
        });
        await subscribeToJillIsInPersonalAdminMode(jillId, async () => {
            const jill = await fetchJill(jillId);
            this.store.jill = jill;
        });
        await subscribeToJillDurationEvents(jillId, async () => {
            const jill = await fetchJill(jillId);
            this.store.jill = jill;
        });
        this.store.loading = false;
    }
    async voidJillClicked() {
        const confirmation = confirm('This user will be lost forever. Are you sure you want to do this?');
        if (confirmation === false) {
            return;
        }
        const jillId = this.store.jill === 'NOT_SET' ? 'NOT_SET' : this.store.jill.id;
        if (jillId === 'NOT_SET') {
            return;
        }
        const success = await voidJill(jillId);
        if (success === true) {
            alert('The user was voided successfully');
            window.location.reload(); // TODO a better way to do this is to raise an event and have the parent respond and refersh its list of jills
        }
        else {
            alert('There was a problem voiding the user');
        }
    }
    render(state) {
        if (state.jill === 'NOT_SET' || state.loading === true) {
            return (0, lit_html_1.html) `
                Loading...
            `;
        }
        const jillId = state.jill.id;
        const userIsJillExecutive = state.authenticatedUser !== 'NOT_SET' &&
            state.authenticatedUser.auth_role === 'JILL_EXECUTIVE';
        return (0, lit_html_1.html) `
            <style>
                .jo-jill-card--main-container {
                    cursor: default;
                    background-color: white;
                }

                .jo-jill-card--availability-button:hover {
                    cursor: pointer;
                    font-weight: bold;
                }

                .jo-jill-card--green {
                    color: green;
                }

                .jo-jill-card--red {
                    color: red;
                }

                .jo-jill-card--name:hover {
                    cursor: pointer;
                    color: var(--jo-primary);
                }

                .jo-jill-card--void-button:hover {
                    cursor: pointer;
                    background-color: var(--jo-accent-background-light);
                }

                .jo-jill-card--bold {
                    font-weight: bold;
                }
            </style>

            <div class="jo-jill-card--main-container">
                <div
                    class="jo-jill-card--name jo-jill-card--bold"
                    @click=${() => (0, utilities_1.navigate)(`/jill?jillId=${jillId}`)}
                >
                    ${state.jill.first_name} ${state.jill.last_name}
                </div>
                <br />
                <div>${state.jill.email}</div>

                <hr />

                <div class="jo-jill-card--bold">Pods</div>

                <br />

                <div>
                    ${state.jill.pods.map((pods) => {
            return (0, lit_html_1.html) `
                            <div>${pods.name}</div>
                        `;
        })}
                </div>

                <hr />

                <div>
                    ${state.jill.is_available === true
            ? (0, lit_html_1.html) `
                              <div
                                  class="jo-jill-card--availability-button jo-jill-card--green"
                                  @click=${() => {
                if (state.jill === 'NOT_SET') {
                    return;
                }
                toggleAvailability(jillId, state.jill.is_available);
            }}
                              >
                                  Available
                              </div>
                          `
            : (0, lit_html_1.html) `
                              <div
                                  class="jo-jill-card--availability-button jo-jill-card--red"
                                  @click=${() => {
                if (state.jill === 'NOT_SET') {
                    return;
                }
                toggleAvailability(jillId, state.jill.is_available);
            }}
                              >
                                  Unavailable
                              </div>
                          `}
                </div>

                <br />

                <div>
                    ${isInCall(state.jill)
            ? (0, lit_html_1.html) `
                              <div class="jo-jill-card--green">In call</div>
                          `
            : (0, lit_html_1.html) `
                              <div class="jo-jill-card--red">Not in call</div>
                          `}
                </div>

                ${(0, personal_admin_1.isInPersonalAdminMode)(state.jill)
            ? (0, lit_html_1.html) `
                          <br />

                          <div class="jo-jill-card--green">In Personal Admin Mode</div>
                      `
            : ''}

                <hr />

                <button
                    class="jo-jill-card--void-button"
                    @click=${() => this.voidJillClicked()}
                    ?hidden=${!userIsJillExecutive}
                >
                    Void Jill
                </button>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-jill-card', JOJillCard);
function isInCall(jill) {
    if (jill.duration_events === undefined ||
        jill.duration_events === null ||
        !(0, common_1.isLengthAtLeast)(jill.duration_events, 1) ||
        jill.duration_events[0].description === 'ACTION_ITEM_SAVED') {
        return false;
    }
    return true;
}
async function fetchJill(jillId) {
    const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query ($jillId: Int!) {
                getUsers(id: $jillId) {
                    id
                    email
                    first_name
                    last_name
                    is_available
                    pods {
                        name
                    }
                    in_personal_admin_mode
                }
            }
        `, {
        jillId,
    });
    if (gqlResult.data === null || gqlResult.data === undefined) {
        throw new Error(`There was a problem`);
    }
    const durationEvents = await (0, utilities_1.fetchDurationEventsForJill)(jillId);
    return {
        ...gqlResult.data.getUsers,
        duration_events: durationEvents,
    };
}
async function subscribeToJillIsAvailable(jillId, callback) {
    await (0, graphql_1.gqlSubscription)(`
        subscription {
            userAvailabilityChanged(userId: "${jillId}")
        }
    `, payload => {
        callback();
    });
}
async function subscribeToJillIsInPersonalAdminMode(jillId, callback) {
    await (0, graphql_1.gqlSubscription)(`
        subscription {
            userPersonalAdminModeChanged(userId: "${jillId}")
        }
    `, payload => {
        callback();
    });
}
// TODO we might want to change the way that we calculate whether or not a Jill is in a call
// TODO using this way with the duration events, a Jill will be indicated as in a call
// TODO even after she has transferred the call away...perhaps we need some more duration event types
// TODO to deal with this better. Putting in some events for transfers would probably be a good idea
async function subscribeToJillDurationEvents(jillId, callback) {
    // await gqlSubscription(`
    //     subscription {
    //         newDuration_events(filter: {
    //             user_id: {
    //                 eq: "${jillId}"
    //             }
    //         }) {
    //             id
    //         }
    //     }
    // `, (payload) => {
    //     callback();
    // });
}
async function toggleAvailability(jillId, currentAvailability) {
    if (currentAvailability === true) {
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($jillId: Int!, $available: Boolean!) {
                    updateUsers(input: {id: $jillId, is_available: $available}) {
                        id
                    }

                    kickOffQueue
                }
            `, {
            jillId,
            available: false,
        });
    }
    else {
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($jillId: Int!, $available: Boolean!, $availableSince: DateTime!) {
                    updateUsers(
                        input: {
                            id: $jillId
                            is_available: $available
                            available_since: $availableSince
                        }
                    ) {
                        id
                    }

                    kickOffQueue
                }
            `, {
            jillId,
            available: true,
            availableSince: new Date().toISOString(),
        });
    }
}
async function voidJill(jillId) {
    const { execute } = (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint);
    const gqlResult = await execute((0, graphql_1.gql) `
            mutation ($userId: Int!) {
                voidUser(userId: $userId)
            }
        `, {
        userId: jillId,
    });
    if (gqlResult.data === null || gqlResult.data === undefined) {
        throw new Error(`There was a problem`);
    }
    return gqlResult.data.voidUser;
}
