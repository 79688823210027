"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isChatDurationEvent = void 0;
function isChatDurationEvent(item, type) {
    if (!item)
        return false;
    if (type) {
        return isChatDurationEventType(item) && item.type === type;
    }
    return isChatDurationEventType(item);
}
exports.isChatDurationEvent = isChatDurationEvent;
function isChatDurationEventType(item) {
    return item.hasOwnProperty('chat');
}
