"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateTotalPurchasedMinutesForCompany = void 0;
const is_defined_1 = require("../is-defined");
function calculateTotalPurchasedMinutesForCompany(company) {
    const invoices = company.invoices;
    if (!(0, is_defined_1.isDefined)(invoices)) {
        return 0;
    }
    return invoices.reduce((total, invoice) => total + getNumMinutesPurchasedOnInvoice(invoice), 0);
}
exports.calculateTotalPurchasedMinutesForCompany = calculateTotalPurchasedMinutesForCompany;
function getNumMinutesPurchasedOnInvoice(invoice) {
    return invoice.minutes_package?.num_minutes_included ?? 0;
}
