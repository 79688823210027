"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.searchJills = void 0;
const graphql_1 = require("../../../services/graphql");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function searchJills(searchText, limit) {
    const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query ($searchText: String!, $limit: Int!) {
                searchJillsOfficeUsers(
                    searchText: $searchText
                    page: {limit: $limit, offset: 0}
                    ignoreSearch: false
                ) {
                    users {
                        id
                        first_name
                        last_name
                    }
                }
            }
        `, {
        searchText,
        limit,
    });
    // searchJillsOfficeUsers.users returns Users, we only need SearchedJill, which is a partial of Users
    return gqlResult.data.searchJillsOfficeUsers.users;
}
exports.searchJills = searchJills;
