"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOMobileActionItems = void 0;
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const close_all_action_items_in_category_1 = require("../../../utilities/action-items/close-all-action-items-in-category");
const fetch_personal_admin_1 = require("../../../utilities/action-items/fetch-personal-admin");
require("../jo-button.element");
require("../jo-input");
require("../jo-loading-text");
require("../jo-page-numbers");
require("./jo-mobile-action-item-card");
require("./jo-mobile-action-items-top-row");
const mutations_1 = require("./mutations");
const queries_1 = require("./queries");
const subscriptions_1 = require("./subscriptions");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    companyId: 'NOT_SET',
    actionItemsForPage: [],
    selectedActionItems: [],
    category: 'ASSIGNED_TO_COMPANY',
    searchText: '',
    personalAdmin: null,
    pageOffset: 0,
    pageLimit: 50,
    totalActionItems: 0,
    subscriptions: {
        actionItemCreated: 'NOT_SET',
        actionItemUpdated: 'NOT_SET',
        actionItemsMovedForcompany: 'NOT_SET',
    },
    loading: true,
};
class JOMobileActionItems extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        const previousCompanyId = this.store.companyId;
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        this.fetchAndSetDataIfNecessary(previousCompanyId);
    }
    fetchAndSetDataIfNecessary(previousCompanyId) {
        if (this.store.companyId !== 'NOT_SET' && previousCompanyId !== this.store.companyId) {
            this.fetchAndSetActionItems();
            this.fetchAndSetPersonalAdmin(this.store.companyId);
            this.createSubscriptions();
        }
    }
    async fetchAndSetActionItems() {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        this.store.loading = true;
        const result = await (0, queries_1.fetchActionItems)(this.store.getState());
        (0, utilities_1.assertSucceeded)(result, utilities_1.handleError);
        this.store.actionItemsForPage = result.value.action_items;
        this.store.totalActionItems = result.value.total;
        this.store.loading = false;
    }
    async fetchAndSetPersonalAdmin(companyId) {
        (0, utilities_1.assertIsSet)(companyId, utilities_1.handleError, 'companyId');
        const result = await (0, fetch_personal_admin_1.fetchPersonalAdmin)(companyId);
        (0, utilities_1.assertSucceeded)(result, utilities_1.handleError);
        this.store.personalAdmin = result.value;
    }
    async createSubscriptions() {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        const actionItemCreatedSubscription = await (0, subscriptions_1.makeActionItemCreatedSubscription)(this.store.companyId, this.fetchAndSetActionItems.bind(this));
        const actionItemUpdatedSubscription = await (0, subscriptions_1.makeActionItemUpdatedSubscription)(this.store.companyId, this.fetchAndSetActionItems.bind(this));
        const actionItemsMovedForCompanySubscription = await (0, subscriptions_1.makeActionItemsMovedForCompanySubscription)(this.store.companyId, this.fetchAndSetActionItems.bind(this));
        this.store.subscriptions = {
            actionItemCreated: actionItemCreatedSubscription ?? 'NOT_SET',
            actionItemUpdated: actionItemUpdatedSubscription ?? 'NOT_SET',
            actionItemsMovedForcompany: actionItemsMovedForCompanySubscription ?? 'NOT_SET',
        };
    }
    assignActionItemCreatedSubscription(subscription) {
        this.store.subscriptions = {
            ...this.store.subscriptions,
            actionItemCreated: subscription,
        };
    }
    addSelectedActionItem(actionItem) {
        this.store.selectedActionItems = [
            ...this.store.selectedActionItems,
            actionItem,
        ];
    }
    deselectActionItem(actionItem) {
        this.store.selectedActionItems = this.store.selectedActionItems.filter(ai => {
            return ai.id !== actionItem.id;
        });
    }
    newPageSelected(newPageIndex) {
        this.store.pageOffset = (newPageIndex * this.store.pageLimit) / this.store.pageLimit;
        this.fetchAndSetActionItems();
    }
    handleCategorySelected(category) {
        this.store.selectedActionItems = [];
        this.store.category = category;
        this.store.pageOffset = 0;
        this.fetchAndSetActionItems();
    }
    async moveActionItemsSelectionMade(e) {
        if (!this.shouldMoveActionItems(e))
            return;
        await this.handleMoveActionItems(e);
        this.store.selectedActionItems = [];
        this.fetchAndSetActionItems();
    }
    shouldMoveActionItems(e) {
        return e.detail.selectedValue || this.store.selectedActionItems.length === 0;
    }
    async handleMoveActionItems(e) {
        this.store.loading = true;
        await this.moveActionItems(e);
        this.store.loading = false;
    }
    async moveActionItems(e) {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        const selectedValue = e.detail.selectedValue;
        const statusIdToMoveTo = getActionItemStatusTypeIdToMoveTo(selectedValue);
        const result = await (0, mutations_1.moveActionItemsToNewStatusType)(this.store.selectedActionItems, statusIdToMoveTo, this.store.companyId);
        (0, utilities_1.assertSucceeded)(result, utilities_1.handleError);
    }
    async handleCloseAllActionItemsInCategory() {
        const shouldContinue = await (0, utilities_1.joConfirm)('Confirm', 'Are you sure you want to close all action items in this category?');
        if (!shouldContinue)
            return;
        this.store.loading = true;
        await this.closeAllActionItemsInCurrentCategory();
        this.store.loading = false;
    }
    async closeAllActionItemsInCurrentCategory() {
        if (this.store.category === 'CLOSED')
            return;
        const statusId = getStatusIdForCategory(this.store.category);
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        const result = await (0, close_all_action_items_in_category_1.closeAllActionItemsInCategory)(this.store.companyId, statusId);
        (0, utilities_1.assertSucceeded)(result, utilities_1.handleError);
        this.fetchAndSetActionItems();
    }
    async searchTextChanged(searchText) {
        this.store.searchText = searchText;
        this.fetchAndSetActionItems();
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    padding-bottom: 5rem;
                }

                .second-top-row {
                    padding-top: 2rem;
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                    display: flex;
                    align-items: center;
                }

                .move-action-item-select-container {
                    padding-top: 2rem;
                    display: flex;
                    justify-content: flex-end;
                    padding-right: 1rem;
                }

                .page-numbers {
                    padding-top: 2rem;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    padding-bottom: 1rem;
                }

                .action-item-card-container {
                    padding-bottom: 1rem;
                    width: 100vw;
                }

                .empty-action-items-text {
                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                    text-align: center;
                    color: var(--jo-accent-background-light);
                }
            </style>

            <div class="main-container">
                <section>
                    <jo-mobile-action-items-top-row
                        .props=${{
            category: state.category,
            companyId: state.companyId,
            hasPersonalAdmin: state.personalAdmin !== null && state.personalAdmin !== null,
        }}
                        @categorySelected=${(e) => {
            this.handleCategorySelected(e.detail);
        }}
                    ></jo-mobile-action-items-top-row>
                </section>

                <section class="second-top-row">
                    <jo-input
                        .type=${'SEARCH'}
                        .inputValue=${state.searchText}
                        @valueChanged=${(e) => {
            this.searchTextChanged(e.detail.inputValue);
        }}
                        .debounce=${200}
                    ></jo-input>

                    <jo-button
                        ?hidden=${shouldHideCloseAllButton(state)}
                        style="padding: 0 2rem;"
                        .type=${'ACTION_BUTTON_2'}
                        .text=${'Close All'}
                        @joButtonClick=${() => {
            this.handleCloseAllActionItemsInCategory();
        }}
                    ></jo-button>

                    ${state.selectedActionItems.length > 0
            ? (0, lit_html_1.html) `
                              <jo-input
                                  style="padding: 0 1rem;"
                                  .type=${'SELECT'}
                                  .defaultOption=${'Move To:'}
                                  .selectOptions=${getSelectOptions(state)}
                                  @inputchange=${(e) => {
                this.moveActionItemsSelectionMade(e);
            }}
                              ></jo-input>
                          `
            : ''}
                </section>

                <section style="height: 1.5rem">
                    ${state.loading
            ? (0, lit_html_1.html) `
                              <jo-loading-text></jo-loading-text>
                          `
            : ''}
                </section>

                <section
                    ?hidden=${state.loading ||
            state.actionItemsForPage.length > 0 ||
            this.store.authenticatedUser === 'NOT_SET'}
                >
                    <div class="empty-action-items-text">
                        <span>No action items to show</span>
                        <h3 class="material-icons">description</h3>
                    </div>
                </section>

                <section
                    ?hidden=${state.loading === false && state.actionItemsForPage.length === 0}
                >
                    ${state.actionItemsForPage.map(actionItem => (0, lit_html_1.html) `
                            <div class="action-item-card-container">
                                <jo-mobile-action-item-card
                                    .authenticatedUser=${state.authenticatedUser}
                                    .actionItem=${actionItem}
                                    .selected=${state.selectedActionItems.some(a => a.id === actionItem.id)}
                                    @selected=${() => this.addSelectedActionItem(actionItem)}
                                    @deselected=${() => this.deselectActionItem(actionItem)}
                                    @reRenderActionItems=${() => this.fetchAndSetActionItems()}
                                ></jo-mobile-action-item-card>
                            </div>
                        `)}
                </section>

                <section class="page-numbers">
                    <jo-page-numbers
                        .props=${{
            numPages: Math.ceil(state.totalActionItems / state.pageLimit),
            currentPage: state.pageOffset + 1,
        }}
                        @newPageNumberClicked=${(e) => this.newPageSelected(e.detail)}
                    ></jo-page-numbers>
                </section>
            </div>
        `;
    }
}
exports.JOMobileActionItems = JOMobileActionItems;
(0, define_custom_element_1.defineCustomElement)('jo-mobile-action-items', JOMobileActionItems);
function shouldHideCloseAllButton(state) {
    return (state.category === 'CLOSED' ||
        state.actionItemsForPage.length === 0 ||
        state.selectedActionItems.length > 0 ||
        state.loading === true);
}
function getActionItemStatusTypeIdToMoveTo(selectedCategoryToMoveTo) {
    if (selectedCategoryToMoveTo === 'Close')
        return 1;
    if (selectedCategoryToMoveTo === 'Assign to Me')
        return 2;
    if (selectedCategoryToMoveTo === 'Assign to Jill')
        return 3;
    if (selectedCategoryToMoveTo === 'Assign to Personal Admin')
        return 5;
    throw new Error('Whoops, we should not have gotten here');
}
function getSelectOptions(state) {
    const allOptions = [
        'Assign to Jill',
        'Close',
    ];
    if (state.category === 'ASSIGNED_TO_JILLS_OFFICE') {
        return addAdminOptionIfNecessary(['Close'], state);
    }
    if (state.category === 'CLOSED')
        return addAdminOptionIfNecessary([
            'Assign to Jill',
            'Assign to Me',
        ], state);
    return addAdminOptionIfNecessary(allOptions, state);
}
function addAdminOptionIfNecessary(existingOptions, state) {
    if (!state.personalAdmin)
        return existingOptions;
    return [
        ...existingOptions,
        'Assign to Personal Admin',
    ];
}
function getStatusIdForCategory(cateogy) {
    if (cateogy === 'ASSIGNED_TO_COMPANY')
        return 2;
    if (cateogy === 'ASSIGNED_TO_JILLS_OFFICE')
        return 3;
    if (cateogy === 'ASSIGNED_TO_PERSONAL_ADMIN')
        return 5;
    throw new Error('Whoops, this we should not have gotten here');
}
