"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidZipCode = void 0;
function isValidZipCode(input) {
    const numericInput = Number(input);
    if (isNaN(numericInput)) {
        return false;
    }
    if (input.length !== 5) {
        return false;
    }
    return true;
}
exports.isValidZipCode = isValidZipCode;
