"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateCompanyInternalNotesOnActionItem = exports.publishActionItemsMovedForCompany = exports.closeActionItem = exports.makeUpsertActionItemInput = exports.upsertActionItem = exports.getIdForUpsert = exports.upsertContact = void 0;
const graphql_1 = require("../../../../services/graphql");
const utilities_1 = require("../../../../services/utilities");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function upsertContact(contactInputValues, companyId) {
    try {
        const createContactInput = getUpsertContactInput(contactInputValues, companyId);
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($input: UpsertContactsInput!) {
                    upsertContacts(input: $input) {
                        id
                        first_name
                        last_name
                        email
                    }
                }
            `, {
            input: createContactInput,
        });
        const contactId = gqlResult.data.upsertContacts.id;
        return {
            succeeded: true,
            value: contactId,
        };
    }
    catch (error) {
        return {
            succeeded: false,
            userMessage: 'Error: contact save was unsuccessful',
            developerMessage: `saveNewContact Error: ${error}`,
        };
    }
}
exports.upsertContact = upsertContact;
function getUpsertContactInput(contactInputValues, companyId) {
    const result = {
        id: getIdForUpsert(contactInputValues.contactId),
        first_name: contactInputValues.firstNameInputValue,
        last_name: contactInputValues.lastNameInputValue,
        email: contactInputValues.emailInputValue,
        company_name: contactInputValues.businessNameInputValue,
        where_did_you_hear_about_us: contactInputValues.whereDidYouHearAboutUsInputValue,
        company: {
            connect: {
                id: companyId,
            },
        },
        phone_number: contactInputValues.phoneNumbersInputValues[0]?.phoneNumber,
        phone_numbers: {
            upsertMany: getUpsertManyPhoneNumbersInput(contactInputValues),
            disconnect: {
                ids: contactInputValues.phoneNumberIdsToDisconnect,
            },
        },
        addresses: {
            upsertMany: getUpsertManyAddressesInput(contactInputValues),
            disconnect: {
                ids: contactInputValues.addressIdsToDisconnect,
            },
        },
    };
    return result;
}
function getUpsertManyAddressesInput(contactInputValues) {
    return contactInputValues.addressesInputValues.map(addressInputValue => {
        return {
            id: getIdForUpsert(addressInputValue.addressId),
            city: addressInputValue.cityInputValue,
            county: addressInputValue.countyInputValue,
            line_1: addressInputValue.line1InputValue,
            line_2: addressInputValue.line2InputValue,
            occupant_type: 'Contact',
            postal_code: addressInputValue.postalCodeInputValue,
            province: addressInputValue.provinceInputValue,
            state: addressInputValue.stateInputValue,
            zip_code: addressInputValue.zipCodeInputValue,
        };
    });
}
function getUpsertManyPhoneNumbersInput(contactInputValues) {
    return contactInputValues.phoneNumbersInputValues.map(phoneNumberInputValue => {
        return {
            id: getIdForUpsert(phoneNumberInputValue.phoneNumberId),
            number: phoneNumberInputValue.phoneNumber,
        };
    });
}
function getIdForUpsert(id) {
    return id < 0 ? null : id;
}
exports.getIdForUpsert = getIdForUpsert;
async function upsertActionItem(upsertActionItemInput) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($upsertActionItemInput: UpsertAction_itemsInput!) {
                    upsertAction_items(input: $upsertActionItemInput) {
                        id
                    }
                }
            `, {
            upsertActionItemInput: upsertActionItemInput,
        });
        return {
            succeeded: true,
            value: gqlResult.data.upsertAction_items.id,
        };
    }
    catch (error) {
        return {
            succeeded: false,
            userMessage: 'Error: New ActionItem Save was Unsuccessful',
            developerMessage: `saveNewActionItem Error: ${error}`,
        };
    }
}
exports.upsertActionItem = upsertActionItem;
function makeUpsertActionItemInput(state, whoToSendTo) {
    (0, utilities_1.assertIsSet)(state.authenticatedUser, utilities_1.handleError, 'state.authenticatedUser');
    (0, utilities_1.assertIsSet)(state.companyId, utilities_1.handleError, 'state.companyId');
    (0, utilities_1.assertIsSet)(state.contactId, utilities_1.handleError, 'state.contactId');
    (0, utilities_1.assertIsSet)(state.actionItemId, utilities_1.handleError, 'state.actionItemId');
    const input = {
        id: getIdForUpsert(state.actionItemId),
        action_item_notes: {
            upsert: {
                author: {
                    connect: {
                        id: state.authenticatedUser.id,
                    },
                },
                body: state.newNoteText,
            },
        },
        title: state.title,
        action_item_status_type: {
            connect: {
                id: whoToSendTo === 'PERSONAL_ADMIN' ? 5 : 3,
            },
        },
        company: {
            connect: {
                id: state.companyId,
            },
        },
        contact: {
            connect: {
                id: state.contactId,
            },
        },
    };
    return input;
}
exports.makeUpsertActionItemInput = makeUpsertActionItemInput;
async function closeActionItem(actionItemId) {
    try {
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($actionItemId: Int!) {
                    updateAction_items(
                        input: {id: $actionItemId, action_item_status_type: {connect: {id: 1}}}
                    ) {
                        id
                    }
                }
            `, {
            actionItemId,
        });
    }
    catch (error) {
        throw new Error(`closeActionItem error: ${error}`);
    }
}
exports.closeActionItem = closeActionItem;
async function publishActionItemsMovedForCompany(companyId) {
    try {
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($companyId: Int!) {
                    publishActionItemsMovedForCompany(companyId: $companyId)
                }
            `, {
            companyId,
        });
    }
    catch (error) {
        throw new Error(`publishActionItemsMovedForCompany error: ${error}`);
    }
}
exports.publishActionItemsMovedForCompany = publishActionItemsMovedForCompany;
async function updateCompanyInternalNotesOnActionItem(actionItemId, internalNotes, companyId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($actionItemId: Int!, $internalNotes: String!, $companyId: Int!) {
                    updateAction_items(
                        input: {
                            id: $actionItemId
                            company_internal_notes: $internalNotes
                            company: {connect: {id: $companyId}}
                        }
                    ) {
                        id
                    }
                }
            `, { actionItemId, internalNotes, companyId });
        return {
            succeeded: true,
            value: gqlResult.data.updateAction_items.id,
        };
    }
    catch (error) {
        return {
            succeeded: false,
            userMessage: 'Error: Could not save internal notes.',
            developerMessage: `updateCompanyInternalNotesOnActionItem() Error: ${error}`,
        };
    }
}
exports.updateCompanyInternalNotesOnActionItem = updateCompanyInternalNotesOnActionItem;
