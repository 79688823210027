"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getQueueStatItemValue = exports.getCurrentPriorityLevelStyle = exports.getAnimationFlashForLevel = exports.getCurrentPriorityLevelStylesForCallQueue = void 0;
const lit_1 = require("lit");
const jo_queue_status_box_element_1 = require("./jo-queue-status-box.element");
const element_vir_1 = require("element-vir");
function getCurrentPriorityLevelStylesForCallQueue(queuePriorityLevels, totalQueueItems, highestPodCount) {
    const currentStyle = queuePriorityLevels.filter(priorityLevel => {
        const priorityLevelTotalQueueItems = priorityLevel?.total_queue_items ?? 0;
        const highest_pod_count = priorityLevel?.highest_pod_count ?? 0;
        if (priorityLevelTotalQueueItems >= totalQueueItems ||
            highest_pod_count >= highestPodCount) {
            return priorityLevel;
        }
    })[0];
    if (!currentStyle) {
        return (0, element_vir_1.css) `
            background-color: #e7b66b;
            color: black;
        `;
    }
    return (0, element_vir_1.css) `
        background-color: ${(0, lit_1.unsafeCSS)(currentStyle.background_color)};
        color: ${(0, lit_1.unsafeCSS)(currentStyle.text_color)};
        ${getAnimationFlashForLevel(currentStyle.flash_level)}
    `;
}
exports.getCurrentPriorityLevelStylesForCallQueue = getCurrentPriorityLevelStylesForCallQueue;
function getAnimationFlashForLevel(flashLevel) {
    switch (flashLevel) {
        case 'LOW':
            return (0, element_vir_1.css) `
                animation: LOW-BLINK 1.5s linear infinite;
            `;
        case 'MEDIUM':
            return (0, element_vir_1.css) `
                animation: HIGH-BLINK 1s linear infinite;
            `;
        case 'HIGH':
            return (0, element_vir_1.css) `
                animation: HIGH-BLINK 0.4s linear infinite;
            `;
        case 'NONE':
            return (0, element_vir_1.css) ``;
        default:
            throw new Error(`Animation not implmented for unknown Queue Status Box Flash Level '${flashLevel}'`);
    }
}
exports.getAnimationFlashForLevel = getAnimationFlashForLevel;
function getCurrentPriorityLevelStyle(queueStatusBoxType, queuePriorityLevels, queueStatItems) {
    if (queueStatusBoxType === 'INCOMING_CALLS') {
        const totalQueueItems = getQueueStatItemValue(queueStatItems, jo_queue_status_box_element_1.QueueStatusBoxStatNamesEnum.TOTAL_IN_QUEUE);
        const highestPodCount = getQueueStatItemValue(queueStatItems, jo_queue_status_box_element_1.QueueStatusBoxStatNamesEnum.HIGHEST_POD_COUNT);
        return getCurrentPriorityLevelStylesForCallQueue(queuePriorityLevels, totalQueueItems, highestPodCount);
    }
    else if (queueStatusBoxType === 'INCOMING_CHATS') {
        //TODO: ADD CHAT QUEUE STYLES HERE.
        //https://jillsoffice.atlassian.net/browse/JWA-2184
        throw new Error('Chat styles not implemented.');
    }
    else {
        throw new Error(`Styles not implmented for unknown queue status box type '${queueStatusBoxType}'`);
    }
}
exports.getCurrentPriorityLevelStyle = getCurrentPriorityLevelStyle;
function getQueueStatItemValue(queueStatItems, statName) {
    const indexOfItem = queueStatItems.findIndex(statItem => {
        return statItem.name === statName;
    });
    const item = queueStatItems[indexOfItem];
    if (item) {
        return item.value;
    }
    return 0;
}
exports.getQueueStatItemValue = getQueueStatItemValue;
