"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateUsageDiffInMillisecondsForCompany = void 0;
const is_defined_1 = require("../../is-defined");
const calculate_overage_usage_in_milliseconds_1 = require("./calculate-overage-usage-in-milliseconds");
const calculate_unused_milliseconds_1 = require("./calculate-unused-milliseconds");
function calculateUsageDiffInMillisecondsForCompany(company, usageType) {
    if (!(0, is_defined_1.isDefined)(company.company_logs)) {
        return 0;
    }
    let total = 0;
    for (const companyLog of company.company_logs)
        total += calculateUsageDiffForSpecificCompanyLog(companyLog, company.invoices ?? [], usageType);
    return total;
}
exports.calculateUsageDiffInMillisecondsForCompany = calculateUsageDiffInMillisecondsForCompany;
function calculateUsageDiffForSpecificCompanyLog(companyLog, invoices, usageType) {
    const matchingInvoice = findMatchingInvoice(companyLog, invoices);
    if (invoiceOrMinutesPackageDoNotExist(matchingInvoice))
        return 0;
    const diffCalculator = getDiffCalculator(usageType);
    const usageDiffInMilliseconds = diffCalculator(companyLog.billable_duration, matchingInvoice?.minutes_package?.num_minutes_included ?? 0);
    return usageDiffInMilliseconds;
}
function invoiceOrMinutesPackageDoNotExist(matchingInvoice) {
    return !matchingInvoice || !matchingInvoice.minutes_package;
}
function getDiffCalculator(usageType) {
    return usageType === 'OVERAGE'
        ? calculate_overage_usage_in_milliseconds_1.calculateOverageUsageInMilliseconds
        : calculate_unused_milliseconds_1.calculateUnusedMilliseconds;
}
function findMatchingInvoice(companyLog, invoices) {
    return invoices.find(invoice => (0, is_defined_1.isDefined)(invoice.start_date) && doesInvoiceMatchCompanyLog(invoice, companyLog));
}
function doesInvoiceMatchCompanyLog(invoice, companyLog) {
    return (invoice.start_date &&
        invoice.start_date === companyLog.start_date &&
        invoice.end_date &&
        invoice.end_date === companyLog.end_date &&
        invoice.billing_cycle_type &&
        invoice.billing_cycle_type === companyLog.billing_cycle_type);
}
