"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addPaymentMethodInStripe = exports.deletePaymentMethodInStripe = exports.updateDefaultPaymentInStripe = void 0;
const graphql_1 = require("../../../services/graphql");
const error_handling_1 = require("../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function updateDefaultPaymentInStripe(cardId, customerStripeId, companyId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($cardId: String!, $customerStripeId: String!, $companyId: Int!) {
                    updateDefaultPaymentMethodForCustomerInStripe(
                        customerStripeId: $customerStripeId
                        cardId: $cardId
                        companyId: $companyId
                    )
                }
            `, {
            cardId,
            customerStripeId,
            companyId,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        return {
            succeeded: true,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('updateDefaultPaymentInStripe', error);
    }
}
exports.updateDefaultPaymentInStripe = updateDefaultPaymentInStripe;
async function deletePaymentMethodInStripe(cardId, customerStripeId, companyId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($cardId: String!, $customerStripeId: String!, $companyId: Int!) {
                    deletePaymentMethodInStripe(
                        customerStripeId: $customerStripeId
                        cardId: $cardId
                        companyId: $companyId
                    )
                }
            `, {
            cardId,
            customerStripeId,
            companyId,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        return {
            succeeded: true,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('deletePaymentMethodInStripe', error);
    }
}
exports.deletePaymentMethodInStripe = deletePaymentMethodInStripe;
async function addPaymentMethodInStripe(cardDetails, customerStripeId, companyId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation (
                    $cardDetails: StripeCardDetails!
                    $customerStripeId: String!
                    $companyId: Int!
                ) {
                    addPaymentMethodInStripe(
                        customerStripeId: $customerStripeId
                        cardDetails: $cardDetails
                        companyId: $companyId
                    )
                }
            `, {
            cardDetails,
            customerStripeId,
            companyId,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        return {
            succeeded: true,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('addPaymentMethodInStripe', error);
    }
}
exports.addPaymentMethodInStripe = addPaymentMethodInStripe;
