"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const mutations_1 = require("./mutations");
const queries_1 = require("./queries");
require("./jo-contact");
require("../jo-button.element");
require("../jo-loading");
require("../jo-activity-log/jo-activity-log");
const twilio_1 = require("../../../services/twilio");
const css_utils_1 = require("../../../utilities/css-utils");
const ensure_type_1 = require("../../../utilities/types/ensure-type");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    contactId: 'NOT_SET',
    companyId: 'NOT_SET',
    contact: Symbol('NOT_SET'),
    inputValues: 'NOT_SET',
    isMobileDevice: false,
    loading: false,
    saving: false,
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-company-contact-save');
const cssNames = (classNames) => classNames.split(' ').map(cssName).join(' ');
class JOContactSave extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this);
    }
    set contactId(contactId) {
        if (this.store.contactId === contactId) {
            return;
        }
        this.store.contactId = contactId;
        if (this.store.contactId === 'NOT_SET') {
            this.contact = Symbol('NOT_SET');
            return;
        }
        (async () => {
            this.store.loading = true;
            await this.fetchAndSetContact();
            this.store.loading = false;
        })();
    }
    set contact(contact) {
        if (this.store.contact === contact) {
            return;
        }
        (0, utilities_1.raiseCustomEvent)(this, 'contactsavecontactabouttochange');
        this.store.contact = contact;
    }
    set inputValues(inputValues) {
        this.store.inputValues = inputValues;
        if (this.store.inputValues !== 'NOT_SET') {
            // TODO this is a sad hack, because it seems that the first form errors are being set
            // TODO after the first default render, which is sad because it is breaking
            // TODO the global snapshotting. This problem may go away once I can use .props on jo-input
            const { formErrors, ...inputValuesWithoutFormErrors } = this.store.inputValues;
            (0, utilities_1.raiseCustomEvent)(this, 'contactsaveinputvalueschanged', inputValuesWithoutFormErrors);
        }
        else {
            (0, utilities_1.raiseCustomEvent)(this, 'contactsaveinputvalueschanged', this.store.inputValues);
        }
    }
    async fetchAndSetContact() {
        (0, utilities_1.assertIsSet)(this.store.contactId, utilities_1.handleError, 'this.store.contactId');
        const contactResult = await (0, queries_1.fetchContact)(this.store.contactId);
        (0, utilities_1.assertSucceeded)(contactResult, utilities_1.handleError);
        const contact = contactResult.value;
        this.contact = contact;
    }
    async handleSaveContact() {
        await this.saveContact();
        (0, utilities_1.assertIsSet)(this.store.authenticatedUser, utilities_1.handleError, 'this.store.authenticatedUser');
        if ((0, utilities_1.getIsCompanyUser)(this.store.authenticatedUser, this.store.companyId)) {
            (0, utilities_1.navigate)(`customer/contact-list`);
        }
        else {
            (0, utilities_1.navigate)(`company/contact-list?companyId=${this.store.companyId}`);
        }
        (0, utilities_1.joAlert)('Success', 'Contact saved successfully');
    }
    async saveContact() {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        (0, utilities_1.assertIsSet)(this.store.inputValues, utilities_1.handleError, 'this.store.inputValues');
        this.store.saving = true;
        const contactResult = await (0, mutations_1.saveContact)(this.store.companyId, this.store.inputValues, 'NOT_SET', 'NOT_SET');
        (0, utilities_1.assertSucceeded)(contactResult, utilities_1.handleError);
        const contact = contactResult.value;
        this.contact = contact;
        this.navigateToContactPage(contact.id);
        this.store.saving = false;
    }
    navigateToContactPage(contactId) {
        const isCompanyUser = (0, utilities_1.getIsCompanyUser)(this.store.authenticatedUser, this.store.companyId);
        if (isCompanyUser) {
            (0, utilities_1.navigate)(`/customer/contact?companyId=${this.store.companyId}&contactId=${contactId}`, true);
        }
        (0, utilities_1.navigate)(`/company/contact?companyId=${this.store.companyId}&contactId=${contactId}`, true);
    }
    cancelButtonClicked() {
        const originalContact = this.store.contact;
        this.store.contact = Symbol('NOT_SET');
        this.contact = originalContact;
    }
    async handlePhoneIconClick(e) {
        if (this.store.isMobileDevice === true) {
            return;
        }
        else {
            await (0, twilio_1.handleDialPhoneNumberClickedOnJOContact)(e, this, this.saveContact.bind(this));
        }
    }
    render(state) {
        const isAllowedToUpdate = (0, utilities_1.isInAuthRole)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser);
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .${cssNames('main-container')} {
                    box-sizing: border-box;
                    height: 100%;
                    width: 100%;
                }

                .${cssNames('info-cards-container')} {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                }

                .${cssNames('contact-container')} {
                    box-sizing: border-box;
                    padding: ${state.isMobileDevice ? '0.2rem' : '1rem'};
                    background-color: white;
                    box-shadow: var(--jo-card-shadow-1);
                    border-radius: var(--jo-wide-border-radius-1);
                    flex: 1;
                }

                .${cssNames('buttons-container')} {
                    display: flex;
                    justify-content: ${state.isMobileDevice ? `center` : `flex-start`};
                    padding-top: ${state.isMobileDevice ? `1.5rem` : `1rem`};
                    padding-bottom: ${state.isMobileDevice ? `1rem` : `0rem`};
                }

                .${cssNames('activity-log-container')} {
                    box-sizing: border-box;
                    flex: 1;
                    padding-left: 1rem;
                }

                @media screen and (max-width: 1200px) {
                    .${cssNames('info-cards-container')} {
                        flex-direction: column;
                    }

                    .${cssNames('contact-container')},
                    .${cssNames('activity-log-container')} {
                        width: 100%;
                    }

                    .${cssNames('activity-log-container')} {
                        margin-top: 1rem;
                        padding-left: 0;
                    }
                }
                .${cssNames('mobile-bottom-filler')} {
                    height: 5rem;
                    width: 100%;
                }
            </style>

            <jo-loading
                .props=${{
            loading: state.loading,
        }}
            ></jo-loading>

            <div class="${cssNames('main-container')}">
                <div class="${cssNames('info-cards-container')}">
                    <div class="${cssNames('contact-container')}">
                        <jo-contact
                            .props=${{
            contact: state.contact,
            authenticatedUser: state.authenticatedUser,
            serviceAreaDescriptionAppear: false,
            isMobileDevice: state.isMobileDevice,
        }}
                            @inputvalueschanged=${(e) => (this.inputValues = e.detail)}
                            @contactchanged=${(e) => (this.store.contact = e.detail)}
                            @joContactDialPhoneNumberClicked=${async (e) => {
            await this.handlePhoneIconClick(e);
        }}
                        ></jo-contact>
                    </div>

                    <div
                        class="${cssNames('buttons-container')}"
                        ?hidden=${state.isMobileDevice === false}
                    >
                        <jo-button
                            class="jo-global--padding-right-1-rem"
                            .type=${'ACTION_BUTTON_1'}
                            .text=${state.saving === true ? 'Saving...' : 'Save'}
                            .disabled=${state.saving === true}
                            @joButtonClick=${() => this.handleSaveContact()}
                        ></jo-button>

                        <jo-button
                            .type=${'SECONDARY_BUTTON_1'}
                            .text=${'Cancel'}
                            @joButtonClick=${() => this.cancelButtonClicked()}
                        ></jo-button>
                    </div>

                    <div class="${cssNames('activity-log-container')}">
                        <jo-activity-log
                            ?hidden=${state.contactId === 'NOT_SET'}
                            .props=${(0, ensure_type_1.ensureType)({
            authenticatedUser: state.authenticatedUser,
            contact: state.contact,
            allowActionItemSelect: false,
            isMobileDevice: state.isMobileDevice,
        })}
                            @activitylogactionitemselected=${async (event) => (0, utilities_1.navigate)(`action-item?actionItemId=${event.detail.actionItemId}&companyId=${this.store.companyId}&callScreenMode=ACTION_ITEM`)}
                        ></jo-activity-log>
                    </div>
                </div>

                <div
                    class="${cssNames('buttons-container')}"
                    ?hidden=${state.isMobileDevice === true}
                >
                    <jo-button
                        class="jo-global--padding-right-1-rem"
                        .type=${'ACTION_BUTTON_1'}
                        .text=${state.saving === true ? 'Saving...' : 'Save'}
                        .disabled=${state.saving === true}
                        @joButtonClick=${() => this.handleSaveContact()}
                    ></jo-button>

                    <jo-button
                        .type=${'SECONDARY_BUTTON_1'}
                        .text=${'Cancel'}
                        @joButtonClick=${() => this.cancelButtonClicked()}
                    ></jo-button>
                </div>
                <div ?hidden=${!state.isMobileDevice} class="mobile-bottom-filler"></div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-contact-save', JOContactSave);
