"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateTotalTimeSpentAvailable = exports.eventsToEndAvailability = exports.calculateTimeSpentInOffCallTraining = exports.calculateTimeSpentInTeamHuddle = exports.calculateTimeSpentOnLunch = exports.calculateTimeSpentOnBreak = exports.calculateUserStatusEventDuration = void 0;
const diff_time_1 = require("../../../../utilities/dates-and-times/diff-time");
const is_user_status_event_1 = require("./is-user-status-event");
function calculateUserStatusEventDuration(items, userStatusEventTypes) {
    let lastEventStart = undefined;
    let totalTime = 0;
    for (const item of items) {
        if (!(0, is_user_status_event_1.isUserStatusEvent)(item)) {
            continue;
        }
        if (userStatusEventTypes.begin.has(item.type) && !lastEventStart) {
            lastEventStart = new Date(item.timestamp);
        }
        if (userStatusEventTypes.end.has(item.type) && lastEventStart) {
            totalTime += (0, diff_time_1.diffTime)(lastEventStart, item.timestamp);
            lastEventStart = undefined;
        }
    }
    return totalTime;
}
exports.calculateUserStatusEventDuration = calculateUserStatusEventDuration;
function calculateTimeSpentOnBreak(items) {
    return calculateUserStatusEventDuration(items, {
        begin: new Set(['WENT_ON_BREAK']),
        end: new Set(['RETURNED_FROM_BREAK']),
    });
}
exports.calculateTimeSpentOnBreak = calculateTimeSpentOnBreak;
function calculateTimeSpentOnLunch(items) {
    return calculateUserStatusEventDuration(items, {
        begin: new Set(['WENT_ON_LUNCH']),
        end: new Set(['RETURNED_FROM_LUNCH']),
    });
}
exports.calculateTimeSpentOnLunch = calculateTimeSpentOnLunch;
function calculateTimeSpentInTeamHuddle(items) {
    return calculateUserStatusEventDuration(items, {
        begin: new Set(['BEGAN_TEAM_HUDDLE']),
        end: new Set(['ENDED_TEAM_HUDDLE']),
    });
}
exports.calculateTimeSpentInTeamHuddle = calculateTimeSpentInTeamHuddle;
function calculateTimeSpentInOffCallTraining(items) {
    return calculateUserStatusEventDuration(items, {
        begin: new Set(['BEGAN_OFF_CALL_TRAINING']),
        end: new Set(['ENDED_OFF_CALL_TRAINING']),
    });
}
exports.calculateTimeSpentInOffCallTraining = calculateTimeSpentInOffCallTraining;
exports.eventsToEndAvailability = new Set([
    'AUTOMATICALLY_SET_UNAVAILABLE',
    'MANUALLY_WENT_UNAVAILABLE',
    'BEGAN_MANAGER_ONE_ON_ONE',
    'BEGAN_OFF_CALL_TRAINING',
    'BEGAN_TEAM_HUDDLE',
    'CLOCKED_OUT',
    'CALL_SENT_TO_USER',
    'WENT_ON_BREAK',
    'WENT_ON_LUNCH',
    'LOGGED_OUT',
]);
function calculateTotalTimeSpentAvailable(items) {
    return calculateUserStatusEventDuration(items, {
        begin: new Set([
            'MANUALLY_WENT_AVAILABLE',
            'AUTOMATICALLY_SET_AVAILABLE',
        ]),
        end: exports.eventsToEndAvailability,
    });
}
exports.calculateTotalTimeSpentAvailable = calculateTotalTimeSpentAvailable;
