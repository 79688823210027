"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeSpecialCharactersFromCSV = exports.isValidPhoneNumber = exports.isValidEmail = exports.isValidCSVRow = exports.getValidAndInvalidCSVRecords = exports.getCustomActionTemplate = exports.getMobileTemplate = exports.preProcessContactsCSV = exports.addPopUpWindowForInvalidRecords = void 0;
const lit_html_1 = require("lit-html");
const jo_company_contacts_upload_error_popup_1 = require("../jo-company-contacts-upload-error-popup");
function addPopUpWindowForInvalidRecords(invalidCSVRecords) {
    const newCSVUploadErrorPopUp = new jo_company_contacts_upload_error_popup_1.JOContactsUploadErrorPopUp(invalidCSVRecords);
    document.body.appendChild(newCSVUploadErrorPopUp);
    newCSVUploadErrorPopUp.addEventListener('cancel', () => {
        document.body.removeChild(newCSVUploadErrorPopUp);
    });
}
exports.addPopUpWindowForInvalidRecords = addPopUpWindowForInvalidRecords;
function preProcessContactsCSV(contactsCSV) {
    const contactsCSVLines = contactsCSV.split('\n');
    const contactsCSVFileContentWithoutHeader = contactsCSVLines.slice(1);
    return contactsCSVFileContentWithoutHeader;
}
exports.preProcessContactsCSV = preProcessContactsCSV;
function getMobileTemplate(item) {
    return (0, lit_html_1.html) `
        <jo-company-contacts-mobile-template .item=${item}></jo-company-contacts-mobile-template>
    `;
}
exports.getMobileTemplate = getMobileTemplate;
function getCustomActionTemplate(data) {
    return (0, lit_html_1.html) `
        <jo-company-contacts-custom-action-template
            .data=${data}
        ></jo-company-contacts-custom-action-template>
    `;
}
exports.getCustomActionTemplate = getCustomActionTemplate;
function getValidAndInvalidCSVRecords(contactsCSVFileContentWithoutHeader) {
    const invalidCSVRecords = [];
    const validCSVRecords = [];
    for (let row = 0; row < contactsCSVFileContentWithoutHeader.length; row++) {
        const csvLine = contactsCSVFileContentWithoutHeader[row];
        if (csvLine == null) {
            throw new Error('csvLine is null');
        }
        const csvLineValidationResult = isValidCSVRow(row, csvLine);
        if (csvLineValidationResult.result !== 'VALID') {
            invalidCSVRecords.push(csvLineValidationResult);
            continue;
        }
        validCSVRecords.push(csvLine);
    }
    return {
        validCSVRecords,
        invalidCSVRecords,
    };
}
exports.getValidAndInvalidCSVRecords = getValidAndInvalidCSVRecords;
function isValidCSVRow(row, csvFileRow) {
    if (csvFileRow.split(',').length !== 12) {
        return {
            result: 'INVALID',
            reason: 'INVALID_NUMBER_OF_FIELDS',
            rowNumber: row + 2,
            record: `Number of Fields provided: ${csvFileRow.split(',').length}`,
            csvRecord: csvFileRow,
        };
    }
    const [first_name, last_name, email, company_name, where_did_you_hear_about_us, phone_number_1, phone_number_2, address_line_1, address_line_2, address_city, address_state, address_zip_code,] = csvFileRow.split(',');
    if (email == null) {
        throw new Error('email is null');
    }
    if (!isValidEmail(email)) {
        return {
            result: 'INVALID',
            reason: 'INVALID_EMAIL',
            rowNumber: row + 2,
            record: email,
            csvRecord: csvFileRow,
        };
    }
    if (phone_number_1 == null) {
        throw new Error('phone_number_1 is null');
    }
    if (!isValidPhoneNumber(phone_number_1)) {
        return {
            result: 'INVALID',
            reason: 'INVALID_PHONE_NUMBER_1',
            rowNumber: row + 2,
            record: phone_number_1,
            csvRecord: csvFileRow,
        };
    }
    if (phone_number_2 == null) {
        throw new Error('phone_number_2 is null');
    }
    if (!isValidPhoneNumber(phone_number_2)) {
        return {
            result: 'INVALID',
            reason: 'INVALID_PHONE_NUMBER_2',
            rowNumber: row + 2,
            record: phone_number_2,
            csvRecord: csvFileRow,
        };
    }
    return {
        result: 'VALID',
    };
}
exports.isValidCSVRow = isValidCSVRow;
function isValidEmail(email) {
    const processedEmail = email.trim();
    if (processedEmail === '') {
        return true;
    }
    return /\S+@\S+\.\S+$/.test(processedEmail) === true;
}
exports.isValidEmail = isValidEmail;
function isValidPhoneNumber(phoneNumber) {
    if (phoneNumber === '') {
        return true;
    }
    const numberPattern = /\d+/g;
    const onlyNumbers = phoneNumber.match(numberPattern);
    if (!onlyNumbers || onlyNumbers?.length === 0) {
        return false;
    }
    if (onlyNumbers.join('').length !== 10) {
        return false;
    }
    return true;
}
exports.isValidPhoneNumber = isValidPhoneNumber;
function removeSpecialCharactersFromCSV(contactsCSVFileContentWithoutHeader) {
    return contactsCSVFileContentWithoutHeader.map(function (line) {
        return line.replace(/\'|\$|\!/g, '').toLowerCase();
    });
}
exports.removeSpecialCharactersFromCSV = removeSpecialCharactersFromCSV;
