"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeActionItemsMovedForCompanySubscription = exports.makeActionItemUpdatedSubscription = exports.makeActionItemCreatedSubscription = void 0;
const graphql_1 = require("../../../services/graphql");
async function makeActionItemCreatedSubscription(companyId, callback) {
    const subscription = await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
            subscription ($companyId: Int!) {
                actionItemCreatedForCompany(companyId: $companyId)
            }
        `, callback, {
        companyId,
    });
    return subscription;
}
exports.makeActionItemCreatedSubscription = makeActionItemCreatedSubscription;
async function makeActionItemUpdatedSubscription(companyId, callback) {
    const subscription = await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
            subscription ($companyId: Int!) {
                actionItemUpdatedForCompany(companyId: $companyId)
            }
        `, callback, {
        companyId,
    });
    return subscription;
}
exports.makeActionItemUpdatedSubscription = makeActionItemUpdatedSubscription;
async function makeActionItemsMovedForCompanySubscription(companyId, callback) {
    const subscription = await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
            subscription ($companyId: Int!) {
                actionItemsMovedForCompany(companyId: $companyId)
            }
        `, callback, {
        companyId,
    });
    return subscription;
}
exports.makeActionItemsMovedForCompanySubscription = makeActionItemsMovedForCompanySubscription;
