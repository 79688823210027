"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const graphql_1 = require("../../services/graphql");
require("./jo-team-card");
const reduxular_1 = require("reduxular");
require("./jo-team");
const utilities_1 = require("../../services/utilities");
const define_custom_element_1 = require("../../utilities/define-custom-element");
require("./jo-button.element");
require("./jo-action-item-flags-table/jo-action-item-flags-table");
require("./jo-loading-text");
const mapped_env_variables_1 = require("../../utilities/environment/mapped-env-variables");
const startOfToday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
const startOfTomorrow = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1);
const InitialState = {
    teams: [],
    loading: false,
    startDate: startOfToday.toISOString(),
    endDate: startOfTomorrow.toISOString(),
    authenticatedUser: 'NOT_SET',
};
class JOTeams extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    connectedCallback() {
        this.fetchAndSetTeams();
    }
    async fetchAndSetTeams() {
        this.store.loading = true;
        // this makes sure to update invalidate and update all teams on the page
        this.store.teams = [];
        const teams = await fetchTeams();
        this.store.teams = teams;
        this.store.loading = false;
    }
    async createNewTeam() {
        await createTeam();
        await this.fetchAndSetTeams();
    }
    startDateInputChanged(e) {
        const newStartDate = new Date(`${e.target.value}T00:00`).toISOString();
        this.store.startDate = newStartDate;
    }
    endDateInputChanged(e) {
        const newEndDate = new Date(`${e.target.value}T00:00`).toISOString();
        this.store.endDate = newEndDate;
    }
    render(state) {
        const userIsBusinessDevelopmentOrExecutive = state.authenticatedUser !== 'NOT_SET' &&
            (state.authenticatedUser.auth_role === 'JILL_BUSINESS_DEVELOPMENT' ||
                state.authenticatedUser.auth_role === 'JILL_EXECUTIVE');
        return (0, lit_html_1.html) `
            <style>
                .jo-teams--container {
                    padding: calc(25px + 1vmin);
                }

                .jo-teams--team-name:hover {
                    cursor: pointer;
                    color: var(--jo-primary);
                }

                .jo-teams--cards-container {
                    display: grid;
                    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
                }

                .jo-teams--card-container {
                    padding: calc(5px + 1vmin);
                    margin: calc(5px + 1vmin);
                    box-shadow: 0px 0px 1px black;
                    background-color: white;
                    box-shadow: var(--jo-card-shadow-1);
                }

                .jo-teams--date-input {
                    width: 15%;
                    margin: 20px;
                }

                .jo-teams--date-input-container {
                    display: flex;
                    flex-direction: row;
                }

                .jo-teams--flag-table-container {
                    padding-top: 3rem;
                }
            </style>

            <div class="jo-teams--container">
                <div ?hidden=${!state.loading}>
                    <jo-loading-text></jo-loading-text>
                </div>

                <div ?hidden=${state.loading}>
                    <h1>Teams</h1>

                    <div ?hidden=${!userIsBusinessDevelopmentOrExecutive}>
                        <jo-button
                            .text=${'Create New Team'}
                            @joButtonClick=${() => this.createNewTeam()}
                        ></jo-button>
                    </div>

                    <br />

                    <div class="jo-teams--cards-container">
                        ${state.teams.map((team) => {
            return (0, lit_html_1.html) `
                                <div class="jo-teams--card-container">
                                    <jo-team-card
                                        .teamId=${team.id}
                                        @teamchanged=${() => this.fetchAndSetTeams()}
                                        .authenticatedUser=${state.authenticatedUser}
                                    ></jo-team-card>
                                </div>
                            `;
        })}
                    </div>

                    <br />

                    <div class="jo-teams--date-input-container">
                        <div class="jo-teams--date-input">
                            <div>Start date</div>
                            <div>
                                <input
                                    type="date"
                                    class="jo-global--input"
                                    .value=${state.startDate.split('T')[0]}
                                    @change=${(e) => this.startDateInputChanged(e)}
                                />
                            </div>
                        </div>

                        <div class="jo-teams--date-input">
                            <div style="margin-left: 5%">End date</div>
                            <div>
                                <input
                                    type="date"
                                    class="jo-global--input"
                                    .value=${state.endDate.split('T')[0]}
                                    @change=${(e) => this.endDateInputChanged(e)}
                                />
                            </div>
                        </div>
                    </div>

                    <br />

                    <div>
                        ${state.teams.map((team) => {
            return (0, lit_html_1.html) `
                                <div
                                    class="jo-teams--team-name"
                                    @click=${() => (0, utilities_1.navigate)(`/team?teamId=${team.id}`)}
                                >
                                    <h2>Team ${team.id}</h2>
                                </div>
                                <hr />
                                <jo-team
                                    .showFlaggedActionItemsTable=${false}
                                    .teamId=${team.id}
                                    .showJillCards=${false}
                                    .showJillStats=${false}
                                    .showDatesAndCharts=${false}
                                    .statsStartDate=${state.startDate}
                                    .statsEndDate=${state.endDate}
                                    .authenticatedUser=${state.authenticatedUser}
                                ></jo-team>
                                <br />
                            `;
        })}
                    </div>

                    <section class="jo-teams--flag-table-container">
                        <div class="jo-global--card">
                            <jo-action-item-flags-table
                                .props=${{
            authenticatedUser: state.authenticatedUser,
            csvTitle: 'Flagged Action Items -- All Teams',
        }}
                            ></jo-action-item-flags-table>
                        </div>
                    </section>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-teams', JOTeams);
async function fetchTeams() {
    const { execute } = (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint);
    const gqlResult = await execute((0, graphql_1.gql) `
        query {
            findTeams {
                items {
                    id
                }
            }
        }
    `);
    if (gqlResult.data === null || gqlResult.data === undefined) {
        throw new Error(`There was a problem`);
    }
    return gqlResult.data.findTeams.items;
}
async function createTeam() {
    const { execute } = (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint);
    await execute((0, graphql_1.gql) `
            mutation ($createdAt: DateTime!, $updatedAt: DateTime!) {
                createTeams(input: {created_at: $createdAt, updated_at: $updatedAt}) {
                    id
                }
            }
        `, {
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
    });
}
