"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createOutboundAttempt = void 0;
const graphql_1 = require("../../services/graphql");
const mapped_env_variables_1 = require("../environment/mapped-env-variables");
async function createOutboundAttempt(outboundQueueItem, authenticatedUser) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($input: CreateOutbound_attemptsInput!) {
                    createOutbound_attempts(input: $input) {
                        id
                    }
                }
            `, {
            input: {
                company: {
                    connect: {
                        id: outboundQueueItem.company?.id,
                    },
                },
                contact: {
                    connect: {
                        id: outboundQueueItem.contact?.id,
                    },
                },
                outbound_queue_item: {
                    connect: {
                        id: outboundQueueItem.id,
                    },
                },
                user: {
                    connect: {
                        id: authenticatedUser.id,
                    },
                },
            },
        });
        const newlyCreatedOutoundAttemp = gqlResult.data.createOutbound_attempts;
        return newlyCreatedOutoundAttemp.id;
    }
    catch (error) {
        throw new Error(`createOutboundAttempt error: ${error}`);
    }
}
exports.createOutboundAttempt = createOutboundAttempt;
