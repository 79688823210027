"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateTimeSpentManuallyUnavailable = exports.eventsToEndManualUnavailbility = void 0;
const diff_time_1 = require("../../../../utilities/dates-and-times/diff-time");
const is_user_status_event_1 = require("./is-user-status-event");
exports.eventsToEndManualUnavailbility = [
    'AUTOMATICALLY_SET_AVAILABLE',
    'MANUALLY_WENT_AVAILABLE',
    'BEGAN_MANAGER_ONE_ON_ONE',
    'BEGAN_OFF_CALL_TRAINING',
    'BEGAN_TEAM_HUDDLE',
    'CLOCKED_OUT',
    'LOGGED_OUT',
    'WENT_ON_BREAK',
    'WENT_ON_LUNCH',
];
const eventsToStartManualUnavailbility = [
    'LOGGED_IN',
    'MANUALLY_WENT_UNAVAILABLE',
    'RETURNED_FROM_BREAK',
    'RETURNED_FROM_LUNCH',
    'ENDED_MANAGER_ONE_ON_ONE',
    'ENDED_OFF_CALL_TRAINING',
    'ENDED_TEAM_HUDDLE',
    'ENDED_ADMIN_MODE',
];
function calculateTimeSpentManuallyUnavailable(items) {
    let lastTimeWentManuallyUnvailableWithoutMatchingGoingAvailabileEvent = null;
    let timeSpentAvailable = 0;
    for (const item of items) {
        if (!(0, is_user_status_event_1.isUserStatusEvent)(item))
            continue;
        if (eventsToStartManualUnavailbility.includes(item.type)) {
            if (lastTimeWentManuallyUnvailableWithoutMatchingGoingAvailabileEvent === null) {
                lastTimeWentManuallyUnvailableWithoutMatchingGoingAvailabileEvent = new Date(item.timestamp);
            }
        }
        if (exports.eventsToEndManualUnavailbility.includes(item.type) &&
            lastTimeWentManuallyUnvailableWithoutMatchingGoingAvailabileEvent !== null) {
            timeSpentAvailable += (0, diff_time_1.diffTime)(lastTimeWentManuallyUnvailableWithoutMatchingGoingAvailabileEvent, item.timestamp);
            lastTimeWentManuallyUnvailableWithoutMatchingGoingAvailabileEvent = null;
        }
    }
    return timeSpentAvailable;
}
exports.calculateTimeSpentManuallyUnavailable = calculateTimeSpentManuallyUnavailable;
