"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createChatDurationEventText = void 0;
function createChatDurationEventText(chatDurationEvents, chatId) {
    const durationEventsForChat = sortDurationEventsForChatId(chatDurationEvents, chatId);
    const durationEventText = createDurationEventText(durationEventsForChat);
    return durationEventText;
}
exports.createChatDurationEventText = createChatDurationEventText;
function createDurationEventText(durationEventsForChat) {
    return durationEventsForChat
        .map(event => `${new Date(event.timestamp).toLocaleString()}: ${event.type}`)
        .join('\n\n');
}
function sortDurationEventsForChatId(chatDurationEvents, chatId) {
    return chatDurationEvents
        .filter(event => {
        if (event.chat?.id === chatId) {
            return event;
        }
    })
        .sort((a, b) => {
        return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
    });
}
