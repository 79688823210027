"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.wasScheduleItemActiveDuringDate = void 0;
function wasScheduleItemActiveDuringDate(date, scheduleItem) {
    try {
        if (!scheduleItem.created_at) {
            throw new Error(`Schedule item has no created_at value: ${scheduleItem}`);
        }
        const dateScheduleItemWasCreated = new Date(scheduleItem.created_at);
        if (!scheduleItem.archived_at) {
            return date.getTime() >= dateScheduleItemWasCreated.getTime();
        }
        const dateScheduleItemWasArchived = new Date(scheduleItem.archived_at);
        return (date.getTime() >= dateScheduleItemWasCreated.getTime() &&
            date.getTime() <= dateScheduleItemWasArchived.getTime());
    }
    catch (error) {
        throw new Error(`wasScheduleItemActiveDuringDate error: ${error}`);
    }
    return true;
}
exports.wasScheduleItemActiveDuringDate = wasScheduleItemActiveDuringDate;
