"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertSnakeToCamelCase = void 0;
function convertSnakeToCamelCase(snakeCaseString) {
    return snakeCaseString
        .toLowerCase()
        .trim()
        .replace(/_[^_]*/g, word => {
        return (word.trim().substring(1, 2).toUpperCase() + word.toLowerCase().trim().substring(2));
    });
}
exports.convertSnakeToCamelCase = convertSnakeToCamelCase;
