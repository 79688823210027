"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.triggerSaveSelectedChatOnJoCallScreen = exports.updateLastSeenInChatModeIfNeeded = exports.sendUserStatusEvent = void 0;
const utilities_1 = require("../../../../services/utilities");
const last_seen_in_chat_mode_1 = require("../../../../utilities/last-seen-in-chat-mode");
const user_status_event_mutations_1 = require("../../../../utilities/logging-and-monitoring/user-status-event-mutations");
const jo_call_screen_1 = require("../../jo-call-screen/jo-call-screen/jo-call-screen");
async function sendUserStatusEvent(chatMode, userId) {
    await (0, user_status_event_mutations_1.createUserStatusEvent)({
        userId: userId,
        eventType: getEventType(chatMode),
        timestamp: new Date(),
    });
}
exports.sendUserStatusEvent = sendUserStatusEvent;
function getEventType(chatMode) {
    switch (chatMode) {
        case 'STRICT':
            return 'IN_CHAT_STRICT_MODE';
        case 'FLEXIBLE':
            return 'IN_CHAT_FLEXIBLE_MODE';
        default:
            return 'ENDED_CHAT_MODE';
    }
}
async function updateLastSeenInChatModeIfNeeded(userId, chatMode) {
    if (chatMode !== 'NONE') {
        await (0, last_seen_in_chat_mode_1.updateLastSeenInChatMode)(userId, new Date());
    }
}
exports.updateLastSeenInChatModeIfNeeded = updateLastSeenInChatModeIfNeeded;
function triggerSaveSelectedChatOnJoCallScreen() {
    const callScreen = document.querySelector(jo_call_screen_1.joCallScreenTagName);
    (0, utilities_1.assertIsDefined)(callScreen, 'triggerSaveSelectedChat callScreen undefined', utilities_1.handleError);
    if (callScreen instanceof jo_call_screen_1.JOCallScreen) {
        callScreen.saveSelectedChatToSessionStorage();
    }
}
exports.triggerSaveSelectedChatOnJoCallScreen = triggerSaveSelectedChatOnJoCallScreen;
