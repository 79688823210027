"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getChatDurationString = exports.calculateChatDuration = void 0;
const dates_and_times_1 = require("../../dates-and-times/dates-and-times");
const diff_time_1 = require("../../dates-and-times/diff-time");
function calculateChatDuration(createdAt, duration_events) {
    const startTime = duration_events.find(event => {
        return event.type === 'ASSIGNED_TO_JILL';
    })?.timestamp ?? createdAt;
    const endTime = duration_events.find(event => {
        return event.type === 'ACTION_ITEM_SENT';
    })?.timestamp;
    return (0, diff_time_1.diffTime)(startTime, endTime);
}
exports.calculateChatDuration = calculateChatDuration;
function getChatDurationString(createdAt, duration_events, status) {
    if (status !== 'CLOSED') {
        return 'In Progress';
    }
    const duration = calculateChatDuration(createdAt, duration_events);
    return (0, dates_and_times_1.millisecondsToHoursMinutesSeconds)(duration);
}
exports.getChatDurationString = getChatDurationString;
