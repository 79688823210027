"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchMostRecentStatusEventForUser = void 0;
const graphql_1 = require("../../services/graphql");
const utilities_1 = require("../../services/utilities");
const mapped_env_variables_1 = require("../environment/mapped-env-variables");
async function fetchMostRecentStatusEventForUser(userId, type) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                query ($filter: User_status_eventsFilter!) {
                    findUser_status_events(
                        filter: $filter
                        page: {limit: 1, offset: 0}
                        orderBy: {field: "timestamp", order: DESC}
                    ) {
                        items {
                            id
                            type
                            timestamp
                            company {
                                id
                                name
                            }
                            user {
                                id
                                email
                            }
                        }
                    }
                }
            `, {
            filter: makeFilterForMostRecentStatusEventForUser(userId, type),
        });
        return gqlResult.data.findUser_status_events.items[0];
    }
    catch (error) {
        (0, utilities_1.handleError)(`fetchMostRecentStatusEventForUser error: ${error}`);
    }
}
exports.fetchMostRecentStatusEventForUser = fetchMostRecentStatusEventForUser;
function makeFilterForMostRecentStatusEventForUser(userId, type) {
    if (type !== undefined) {
        return {
            and: [
                {
                    user_id: {
                        eq: userId,
                    },
                },
                {
                    type: {
                        eq: type,
                    },
                },
            ],
        };
    }
    return {
        user_id: {
            eq: userId,
        },
    };
}
