"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uncancelCompany = exports.updateCompanyPaymentInfo = exports.fetchCompanyForPaymentInfo = void 0;
const graphql_1 = require("../../../../services/graphql");
const error_handling_1 = require("../../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function fetchCompanyForPaymentInfo(companyId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!) {
                    Companies__JOBillingPaymentInfo: getCompanies(id: $companyId) {
                        id
                        activation_fee
                        canceled
                        credit_card_key
                        invoice_email
                        on_hold
                        created_at
                        canceled_at
                    }
                }
            `, {
            companyId,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const company = gqlResult.value.data.Companies__JOBillingPaymentInfo;
        return {
            succeeded: true,
            value: company,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchCompanyForPaymentInfo', error);
    }
}
exports.fetchCompanyForPaymentInfo = fetchCompanyForPaymentInfo;
async function updateCompanyPaymentInfo(updateCompaniesInput) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($updateCompaniesInput: UpdateCompaniesInput!) {
                    updateCompanies(input: $updateCompaniesInput) {
                        id
                    }
                }
            `, {
            updateCompaniesInput,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('updateCompanyPaymentInfo', error);
    }
}
exports.updateCompanyPaymentInfo = updateCompanyPaymentInfo;
async function uncancelCompany(companyId) {
    await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($companyId: Int!) {
                uncancelCompany(companyId: $companyId)
            }
        `, {
        companyId,
    });
}
exports.uncancelCompany = uncancelCompany;
