"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.didFieldChange = exports.didAnyFieldsChange = void 0;
function didAnyFieldsChange(newProps, previousState, propsToCheck) {
    return propsToCheck.some(prop => didFieldChange(newProps, previousState, prop));
}
exports.didAnyFieldsChange = didAnyFieldsChange;
function didFieldChange(newProps, previousState, fieldName) {
    if (newProps[fieldName] === undefined)
        return false;
    const newValue = newProps[fieldName];
    const previousValue = previousState[fieldName];
    return newValue !== previousValue && newValue !== null && newValue !== 'NOT_SET';
}
exports.didFieldChange = didFieldChange;
