"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const utilities_1 = require("../../services/utilities");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../utilities/environment/mapped-env-variables");
class JOGraphqlPlayground extends HTMLElement {
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        // We set the auth header on first load
        (0, utilities_1.setAuthorizationHeaderInGraphqlPlayground)();
    }
    /**
     * https://jillsoffice.atlassian.net/browse/JWA-1980
     *
     * TODO This setInterval actually does not work... spend a bit of time trying to get it to work.
     * The idea here is to get the authentication header information set for us automatically so
     * that we do not have to keep refreshing every 5 minutes or so to keep the user authenticated.
     * We need to continuously set the auth header since our request headers are only good for a
     * certain length of time.
     */
    // setInterval(() => {
    //     setAuthorizationHeaderInGraphqlPlayground();
    // }, 60000);
    render() {
        (0, lit_html_1.render)((0, lit_html_1.html) `
                <iframe
                    style="width: 100%; height: 100%"
                    src="${mapped_env_variables_1.currentMappedEnvVariables.graphqlPlaygroundEndpoint}"
                ></iframe>
            `, this);
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-graphql-playground', JOGraphqlPlayground);
