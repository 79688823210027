"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.callSendResetPasswordEmail = void 0;
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../services/graphql");
const utilities_1 = require("../../services/utilities");
require("./jo-button.element");
require("./jo-input");
const store_1 = require("../../services/store");
const form_error_manager_1 = require("../../classes/form-error-manager");
const error_handling_1 = require("../../utilities/error-handling");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../utilities/environment/mapped-env-variables");
const InitialState = {
    email: '',
    isMobileDevice: store_1.InitialGlobalState.isMobileDevice,
    loading: true,
    showAllFormErrors: false,
    formErrorManager: new form_error_manager_1.FormErrorManager(),
};
class JOForgotPassword extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    async resetPasswordButtonClicked() {
        if (this.store.formErrorManager.getFormErrors().length > 0) {
            this.store.showAllFormErrors = true;
            (0, utilities_1.joAlert)('Alert', 'Please correct errors');
            return;
        }
        const resetPasswordSendLinkResult = await callSendResetPasswordEmail(this.store.email);
        (0, utilities_1.assertSucceeded)(resetPasswordSendLinkResult, utilities_1.handleError);
        (0, utilities_1.joAlert)('Message', 'Please check your email. If the email you have entered exists in our system, we have sent it a link to reset your password');
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .main-container {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: center;
                    background-color: var(--jo-primary);
                    width: 100%;
                    height: 100%;
                }

                .main-container-mobile {
                    max-width: 100%;
                    align-items: center;
                }

                .main-container-desktop {
                    align-items: center;
                }

                .container {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    background-color: white;
                    padding: calc(25px + 1vmin);
                    border-radius: 10px;
                }

                .container-mobile {
                    max-width: 80%;
                    box-shadow: 0px 2px 10px rgb(0, 0, 0, 0.2);
                }

                .container-desktop {
                    width: 50%;
                    box-shadow: 0px 3px 15px rgb(0, 0, 0, 0.4);
                }

                .jo-forgot-password--text-heading {
                    color: var(--jo-primary);
                    font-size: 30px;
                }

                .jo-forgot-password--input-container {
                    max-width: 500px;
                    width: 100%;
                    margin: 1.5rem auto 1.5rem auto;
                }

                .jo-forgot-password--button-container {
                    margin: 0 auto;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    align-items: center;
                }

                .jo-forgot-password--back-to-login {
                    margin: 1rem 0 0 auto;
                }

                .jo-forgot-password--back-to-login-link {
                    text-decoration: underline;
                    color: blue;
                    cursor: pointer;
                }

                @media (max-width: 768px) {
                    .jo-forgot-password--text-heading {
                        font-size: 20px;
                    }
                }
            </style>

            <div
                class="main-container ${state.isMobileDevice === true
            ? 'main-container-mobile'
            : 'main-container-desktop'}"
            >
                <div
                    style="${state.isMobileDevice === true
            ? 'margin-top: -1vh'
            : 'margin-top: -10vh;'}"
                >
                    <img src="../images/jo-logo.png" style="max-height: 15vh" />
                </div>

                <br />
                <div
                    class="container ${state.isMobileDevice === true
            ? 'container-mobile'
            : 'container-desktop'}"
                >
                    <div class="jo-forgot-password--text-heading">Send Reset Password Link</div>

                    <div class="jo-forgot-password--input-container">
                        <jo-input
                            .label=${'Email Address'}
                            .showLabel=${false}
                            .placeholder=${'Enter your Email*'}
                            .type=${'EMAIL'}
                            .fontSize=${'1.2rem'}
                            @inputErrorChanged=${(e) => this.store.formErrorManager.addOrRemoveFormError(e.detail)}
                            .inputValue=${state.email}
                            .required=${true}
                            .showErrorParentOverride=${state.showAllFormErrors}
                            @inputchange=${(e) => {
            this.store.email = e.detail.inputValue;
        }}
                            .showError=${state.showAllFormErrors}
                        ></jo-input>
                    </div>

                    <div class="jo-forgot-password--button-container">
                        <jo-button
                            .type=${'LOGIN_BUTTON'}
                            .text=${`Send Reset Password Link`}
                            @joButtonClick=${() => this.resetPasswordButtonClicked()}
                        ></jo-button>
                    </div>

                    <div class="jo-forgot-password--back-to-login">
                        <jo-button
                            .type=${'ACTION_BUTTON_1'}
                            .text=${'Back to Login'}
                            .href=${'/login'}
                        ></jo-button>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-send-forgot-password-email', JOForgotPassword);
async function callSendResetPasswordEmail(email) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($email: String!) {
                    sendResetPasswordEmail(email: $email)
                }
            `, {
            email: email,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        return {
            succeeded: true,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('callSendResetPasswordEmail', error);
    }
}
exports.callSendResetPasswordEmail = callSendResetPasswordEmail;
