"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../services/graphql");
const utilities_1 = require("../../services/utilities");
const css_utils_1 = require("../../utilities/css-utils");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../utilities/environment/mapped-env-variables");
require("./jo-button.element");
require("./jo-input");
require("./jo-table");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    minutesPackages: [],
    searchText: '',
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-minutes-packages');
const cssNames = (classNames) => classNames.split(' ').map(cssName).join(' ');
class JOMinutesPackages extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
        this.handleGetMinutesPackages = (async (offset, limit, searchText, column) => {
            return await getMinutesPackages(offset, limit, searchText, column);
        }).bind(this);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .${cssNames('main-container')} {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .${cssNames('table-container')} {
                    box-sizing: border-box;
                    font-size: 1rem;
                    padding: 1rem;
                    background-color: white;
                    box-shadow: var(--jo-card-shadow-1);
                    border-radius: var(--jo-wide-border-radius-1);
                }
            </style>

            <div class="${cssNames('main-container')}">
                <div class="${cssNames('table-container')}">
                    <jo-table
                        .tableName=${'Minutes Packages'}
                        .maxItemsPerPage=${50}
                        .getItems=${this.handleGetMinutesPackages}
                        .columns=${getMinutesPackagesColumns()}
                    ></jo-table>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-minutes-packages', JOMinutesPackages);
async function getMinutesPackages(offset, limit, searchText, column) {
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query (
                $filter: Minutes_packagesFilter!
                $limit: Int!
                $offset: Int!
                $orderBy: OrderByInput!
            ) {
                findMinutes_packages(
                    filter: $filter
                    page: {limit: $limit, offset: $offset}
                    orderBy: $orderBy
                ) {
                    items {
                        id
                        base_fee
                        billing_cycle_type
                        created_at
                        updated_at
                        name
                        num_minutes_included
                        price_per_minute
                        recurring_fee
                        type
                        user_display_name
                        minutes_calculation_option {
                            id
                            created_at
                            updated_at
                            name
                        }
                    }
                    total
                }
            }
        `, {
        filter: searchText === ''
            ? {
                name: {
                    ne: searchText,
                },
            }
            : {
                or: [
                    {
                        name: {
                            like: searchText,
                        },
                    },
                    {
                        user_display_name: {
                            like: searchText,
                        },
                    },
                    {
                        type: {
                            like: searchText,
                        },
                    },
                    {
                        num_minutes_included: {
                            eq: Number(searchText),
                        },
                    },
                ],
            },
        limit,
        offset,
        orderBy: column === 'NOT_SET'
            ? {
                field: 'id',
                order: 'DESC',
            }
            : {
                field: column.sortFieldName,
                order: column.sortDirection === 'NOT_SET' ? 'DESC' : column.sortDirection,
            },
    });
    if (gqlResult.succeeded === false) {
        alert('Something went wrong');
        throw new Error(`getMinutesPackages error: gqlResult failed: ${JSON.stringify(gqlResult, null, 3)}`);
    }
    const minutesPackages = gqlResult.value.data.findMinutes_packages.items;
    return {
        items: minutesPackages,
        totalItems: gqlResult.value.data.findMinutes_packages.total,
    };
}
function getMinutesPackagesColumns() {
    const columns = [
        {
            title: 'View',
            getCellData: (minutesPackage) => (0, lit_html_1.html) `
                <jo-button
                    class="red-eye-button"
                    .type=${'ICON'}
                    .icon=${'remove_red_eye'}
                    .href=${`/minutes-package?minutesPackageId=${minutesPackage.id}`}
                    .inlineStyle=${'font-size: 1.3rem'}
                ></jo-button>
            `,
            width: 0.3,
        },
        {
            title: 'Name',
            getCellData: (minutesPackage) => minutesPackage.name,
            width: 3,
            sortFieldName: 'name',
        },
        {
            title: 'Type',
            getCellData: (minutesPackage) => minutesPackage.type,
            width: 1,
            sortFieldName: 'type',
        },
        {
            title: 'Billing Cycle Type',
            getCellData: (minutesPackage) => minutesPackage.billing_cycle_type,
            width: 1,
            sortFieldName: 'billing_cycle_type',
        },
        {
            title: 'User Dispaly Name',
            getCellData: (minutesPackage) => minutesPackage.user_display_name,
            width: 2,
            sortFieldName: 'name',
        },
        {
            title: 'Minutes Included',
            getCellData: (minutesPackage) => minutesPackage.num_minutes_included,
            width: 1,
            sortFieldName: 'num_minutes_included',
        },
        {
            title: 'Base Plan',
            getCellData: (minutesPackage) => (0, utilities_1.formatUSDCentsForDisplay)(minutesPackage.base_fee),
            width: 1,
            sortFieldName: 'base_fee',
        },
        {
            title: 'Recurring Fee',
            getCellData: (minutesPackage) => (0, utilities_1.formatUSDCentsForDisplay)(minutesPackage.recurring_fee),
            width: 1,
            sortFieldName: 'recurring_fee',
        },
        {
            title: 'Price Per Minute',
            getCellData: (minutesPackage) => (0, utilities_1.formatUSDCentsForDisplay)(minutesPackage.price_per_minute),
            width: 1,
            sortFieldName: 'price_per_minute',
        },
        {
            title: 'Recurring Fee',
            getCellData: (minutesPackage) => (0, utilities_1.formatUSDCentsForDisplay)(minutesPackage.recurring_fee),
            width: 1,
            sortFieldName: 'recurring_fee',
        },
        {
            title: 'Minutes Calculation Option',
            getCellData: (minutesPackage) => minutesPackage.minutes_calculation_option.name,
            width: 2,
        },
    ];
    return columns;
}
