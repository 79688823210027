"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatScheduleItemForDisplay = void 0;
const camel_case_1 = require("../data-structures/strings/camel-case");
function formatScheduleItemForDisplay(scheduleItem, parentScheduleItem) {
    try {
        if (parentScheduleItem !== undefined &&
            parentScheduleItem.start_day_of_week === scheduleItem.start_day_of_week &&
            parentScheduleItem.end_day_of_week === scheduleItem.end_day_of_week) {
            return `${hourNumberToString(scheduleItem.start_hour, scheduleItem.start_minute)} to ${hourNumberToString(scheduleItem.end_hour, scheduleItem.end_minute)}`;
        }
        if (scheduleItem.start_day_of_week === scheduleItem.end_day_of_week) {
            return `${(0, camel_case_1.camelCase)(scheduleItem.start_day_of_week)}, ${hourNumberToString(scheduleItem.start_hour, scheduleItem.start_minute)} to ${hourNumberToString(scheduleItem.end_hour, scheduleItem.end_minute)}`;
        }
        return `${(0, camel_case_1.camelCase)(scheduleItem.start_day_of_week)}, ${hourNumberToString(scheduleItem.start_hour, scheduleItem.start_minute)} to ${(0, camel_case_1.camelCase)(scheduleItem.end_day_of_week)}, ${hourNumberToString(scheduleItem.end_hour, scheduleItem.end_minute)}`;
    }
    catch (error) {
        throw new Error(`makeShiftString error: ${error}, scheduleItem: ${JSON.stringify(scheduleItem)}`);
    }
}
exports.formatScheduleItemForDisplay = formatScheduleItemForDisplay;
function hourNumberToString(hourNumber, minute) {
    const hour = getHour(hourNumber);
    const meridiam = hourNumber < 12 ? 'am' : 'pm';
    if (minute === undefined || minute === 0) {
        return hour + meridiam;
    }
    return hour + ':' + getMinuteString(minute) + meridiam;
}
function getMinuteString(minute) {
    if (minute < 10)
        return '0' + minute;
    return minute.toString();
}
function getHour(hourNumber) {
    if (hourNumber === 0)
        return 12;
    if (hourNumber > 12)
        return hourNumber - 12;
    return hourNumber;
}
