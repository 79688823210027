"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isUserScheduledToBeWorking = void 0;
const is_date_in_schedule_item_1 = require("./is-date-in-schedule-item");
const is_user_scheduled_to_be_on_break_1 = require("./is-user-scheduled-to-be-on-break");
const is_user_scheduled_to_be_on_lunch_1 = require("./is-user-scheduled-to-be-on-lunch");
function isUserScheduledToBeWorking(shifts, date) {
    if ((0, is_user_scheduled_to_be_on_break_1.isUserScheduledToBeOnBreak)(shifts, date) || (0, is_user_scheduled_to_be_on_lunch_1.isUserScheduledToBeOnLunch)(shifts, date)) {
        return false;
    }
    const isScheduledToBeWorking = shifts.some(shift => (0, is_date_in_schedule_item_1.isDateInScheduleItem)(shift, date));
    return isScheduledToBeWorking;
}
exports.isUserScheduledToBeWorking = isUserScheduledToBeWorking;
