"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGlobalConfigArrayValues = void 0;
function getGlobalConfigArrayValues(globalConfig, key) {
    if (globalConfig === null) {
        return [];
    }
    return globalConfig[key].split('~');
}
exports.getGlobalConfigArrayValues = getGlobalConfigArrayValues;
