"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAllChatsWithMostRecentMessageSentByJill = void 0;
const is_defined_1 = require("../../../../../utilities/is-defined");
function getAllChatsWithMostRecentMessageSentByJill(chats) {
    try {
        if (!(0, is_defined_1.isDefined)(chats))
            throw new Error('chats is not defined');
        if (chats.length === 0) {
            return [];
        }
        const chatsWithSortedMessages = getChatsWithSortedMessages(chats);
        return filterMessagesSentByJill(chatsWithSortedMessages);
    }
    catch (error) {
        throw new Error(`getAllChatsWithMostRecentMessageSentByJill error: ${error}`);
    }
}
exports.getAllChatsWithMostRecentMessageSentByJill = getAllChatsWithMostRecentMessageSentByJill;
function filterMessagesSentByJill(chatsWithSortedMessages) {
    return chatsWithSortedMessages.filter(chat => {
        const mostRecentMessage = chat.messages[0];
        return mostRecentMessage && mostRecentMessage.source === 'JILL_DASHBOARD';
    });
}
function getChatsWithSortedMessages(chats) {
    return chats.map(chat => {
        const sortedMessages = getSortedMessages(chat.messages);
        return {
            ...chat,
            messages: [...sortedMessages],
        };
    });
}
function getSortedMessages(messages) {
    return [...messages].sort((a, b) => b.id - a.id);
}
