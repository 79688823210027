"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const css_utils_1 = require("../../../utilities/css-utils");
const update_item_in_list_of_items_1 = require("../../../utilities/data-structures/update-item-in-list-of-items");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
require("../jo-button.element");
require("../jo-input");
const helper_utils_1 = require("./helper-utils");
require("./jo-user-shift");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    shifts: null,
    editableShifts: [],
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-user-shifts');
class JOUserShifts extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    set shifts(shifts) {
        if (shifts === this.store.shifts) {
            return;
        }
        const shiftAdapters = shifts.map(shift => ({ ...shift, mode: 'READ' }));
        this.store.shifts = shiftAdapters;
        this.store.editableShifts = [...shiftAdapters];
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .${cssName('main-container')} {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .${cssName('shift-container')} {
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                }

                .${cssName('add-new-shift-button-container')} {
                    padding-top: 5rem;
                    display: flex;
                    gap: 2rem;
                    align-items: center;
                }
            </style>

            <div class="${cssName('main-container')}">
                <h2>Shifts</h2>

                ${state.editableShifts.map(shift => (0, lit_html_1.html) `
                        <div class="${cssName('shift-container')}">
                            <jo-user-shift
                                .shift=${shift}
                                .mode=${shift.mode}
                                @confirmClicked=${(e) => {
            this.shiftEditConfirmed(e.detail);
        }}
                                @cancelClicked=${(e) => {
            const shiftId = parseInt(e.detail);
            this.shiftEditCanceled(shiftId);
        }}
                                @deleteShiftClicked=${(e) => {
            const shiftId = parseInt(e.detail);
            this.deleteShiftClicked(shiftId);
        }}
                            ></jo-user-shift>
                        </div>
                    `)}

                <div class="${cssName('add-new-shift-button-container')}">
                    <jo-button
                        .text=${'ADD NEW SHIFT'}
                        @joButtonClick=${() => {
            this.store.editableShifts = [
                ...state.editableShifts,
                (0, helper_utils_1.makeNewDefaultShift)(),
            ];
        }}
                    ></jo-button>

                    <div style="color: gray">(Don't forget to save)</div>
                </div>
            </div>
        `;
    }
    shiftEditConfirmed(updatedShift) {
        this.store.editableShifts = (0, update_item_in_list_of_items_1.updateItemInListOfItems)(this.store.editableShifts, updatedShift);
        this.raiseShiftsChangedEvent();
    }
    raiseShiftsChangedEvent() {
        const shiftsToSendToParent = convertShiftAdaptersToShifts(this.store.editableShifts);
        (0, utilities_1.raiseCustomEvent)(this, 'shiftsChanged', shiftsToSendToParent);
    }
    shiftEditCanceled(shiftId) {
        const shiftToCancel = this.store.editableShifts.find(shift => shift.id === shiftId);
        if (shiftToCancel === undefined) {
            return;
        }
        if (shiftToCancel.id < 0) {
            this.store.editableShifts = this.store.editableShifts.filter(shift => shift.id !== shiftId);
        }
    }
    deleteShiftClicked(shiftId) {
        this.store.editableShifts = this.store.editableShifts.filter(shift => shift.id !== shiftId);
        this.raiseShiftsChangedEvent();
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-user-shifts', JOUserShifts);
function convertShiftAdaptersToShifts(shiftAdapters) {
    return shiftAdapters.map(shiftAdapter => {
        const { mode, ...shift } = shiftAdapter;
        return shift;
    });
}
