"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isUserStatusEvent = void 0;
function isUserStatusEvent(item, type) {
    if (!item)
        return false;
    if (item.hasOwnProperty('chat')) {
        return false;
    }
    if (type) {
        return item.hasOwnProperty('timestamp') && item.type === type;
    }
    return item.hasOwnProperty('timestamp');
}
exports.isUserStatusEvent = isUserStatusEvent;
