"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BATCH_SIZE = exports.InitialState = void 0;
const startOfToday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
const startOfTomorrow = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1);
exports.InitialState = {
    abortController: 'NOT_SET',
    allJillsHighLevelStatses: [],
    currentOffset: 0,
    includeTestCompaniesAndPods: false,
    loading: true,
    statsStartDate: startOfToday.toISOString(),
    previousStatsStartDate: startOfToday.toISOString(),
    statsEndDate: startOfTomorrow.toISOString(),
    previousStatsEndDate: startOfTomorrow.toISOString(),
    queryExecutor: null,
};
exports.BATCH_SIZE = 10;
