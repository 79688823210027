"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../../services/utilities");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
const crew_cal_1 = require("../../../../classes/crew-cal");
const css_utils_1 = require("../../../../utilities/css-utils");
const sync_company_with_crew_cal_1 = require("../../../../utilities/crew-cal/sync-company-with-crew-cal");
const get_crew_cal_iframe_URL_1 = require("../../../../utilities/crew-cal/get-crew-cal-iframe-URL");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    companyId: 'NOT_SET',
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-company-crew-cal-management');
class JOCompanyCrewCalManagement extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    set companyId(companyId) {
        this.store.companyId = companyId;
        if (companyId !== 'NOT_SET') {
            this.createCrewCalIframe();
        }
    }
    async createCrewCalIframe() {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        await (0, sync_company_with_crew_cal_1.syncCompanyWithCrewCal)(this.store.companyId);
        const iframeURLResult = await (0, get_crew_cal_iframe_URL_1.getIframeURL)(this.store.companyId, 'MANAGE');
        (0, utilities_1.assertSucceeded)(iframeURLResult, utilities_1.handleError);
        renderCrewCalIframe(iframeURLResult.value);
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .${cssName('main-container')} {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }
            </style>

            <div class="${cssName('main-container')}">
                <div id="crewcal-manage-iframe-container" class="jo-global--card"></div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-crew-cal-management', JOCompanyCrewCalManagement);
function renderCrewCalIframe(iframeURL) {
    const crewCal = new crew_cal_1.CrewCal('MANAGE', iframeURL);
    crewCal.render(document.getElementById('crewcal-manage-iframe-container'));
}
