"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../../services/utilities");
const css_utils_1 = require("../../../../utilities/css-utils");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
const build_try_execute_1 = require("../../../../utilities/error-handling/build-try-execute");
const build_try_execute_async_1 = require("../../../../utilities/error-handling/build-try-execute-async");
require("../../jo-button.element");
require("../../jo-input");
require("../jo-chat-strict-hours/jo-chat-strict-hours");
const InitialState = {
    authenticatedUser: 'NOT_SET',
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-chat-settings');
const executeSync = (0, build_try_execute_1.buildtryExecute)(utilities_1.handleError);
const executeAsync = (0, build_try_execute_async_1.buildtryExecuteAsync)(utilities_1.handleError);
class JOChatSettings extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .${cssName('main-container')} {
                    max-height: 100%;
                    max-width: 100%;
                    box-sizing: border-box;
                    margin: 1rem;
                }

                .${cssName('row')} {
                    display: flex;
                    flex-direction: row;
                    gap: 2rem;
                }

                .${cssName('foot-note')} {
                    font-size: 0.8rem;
                    color: var(--jo-text-default);
                }
            </style>

            <div class="${cssName('main-container')}">
                <div class="jo-global--card ${cssName('row')}">
                    <jo-chat-strict-hours
                        .authenticatedUser=${state.authenticatedUser}
                    ></jo-chat-strict-hours>

                    <div>
                        <!--TODO: We eventually want to automate this rather than a button See JWA-1753-->
                        <h3>Manually Recover Twilio Chat Conversations</h3>
                        <p class="${cssName('foot-note')}">
                            *Use this button if there has been down time recently*
                        </p>
                        <jo-button
                            .type=${'ACTION_BUTTON_1'}
                            .text=${'Click To Recover Chats Within Past 24 Hours'}
                            @joButtonClick=${async () => {
            const formData = new FormData();
            formData.append('numberHoursBackToRecoverFrom', '24');
            const result = await (0, utilities_1.sendRouterRequest)('/recover/twilioConversations', formData);
            displayConfirmation(result);
        }}
                        ></jo-button>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-chat-settings', JOChatSettings);
function displayConfirmation(result) {
    if (result.status === 200) {
        (0, utilities_1.joAlert)('Success', 'Twilio Chat Conversations Successfully Recovered');
    }
    else {
        (0, utilities_1.joAlert)('Error', 'Something went wrong. Status Code: ' + result.status);
    }
}
