"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchPods = exports.fetchCompanies = exports.fetchChatStats = void 0;
const graphql_1 = require("../../../services/graphql");
const is_company_currently_using_chat_1 = require("../../../utilities/company-status-events/is-company-currently-using-chat");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function fetchChatStats({ startDate, endDate, jillId, companyId, podId, includeTestCompanies, }) {
    const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
            query (
                $startDate: DateTime!
                $endDate: DateTime!
                $jillId: Int
                $companyId: Int
                $podId: Int
                $includeTestCompanies: Boolean
            ) {
                searchCompletedChats(
                    startTime: $startDate
                    endTime: $endDate
                    userId: $jillId
                    companyId: $companyId
                    podId: $podId
                    includeTestCompanies: $includeTestCompanies
                ) {
                    count
                    avgTimeTillAssignedToJill
                    avgTotalTime
                    avgTimeBetweenFirstAssignedAndCompleted
                    totalMessagesFromContacts
                    totalMessagesFromJills
                    avgNumMessagesFromContacts
                    avgNumMessagesFromJills
                    totalNewAppointments
                }
            }
        `, {
        startDate,
        endDate,
        jillId,
        companyId,
        podId,
        includeTestCompanies,
    });
    return gqlResult.data.searchCompletedChats;
}
exports.fetchChatStats = fetchChatStats;
async function fetchCompanies() {
    const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
        query {
            findCompanies(orderBy: {field: "name", order: ASC}) {
                items {
                    id
                    name
                    is_test_company
                    status_events {
                        id
                        type
                        timestamp
                    }
                }
            }
        }
    `);
    return gqlResult.data.findCompanies.items.filter(company => {
        return (0, is_company_currently_using_chat_1.isCompanyCurrentlyUsingChat)(company.status_events);
    });
}
exports.fetchCompanies = fetchCompanies;
async function fetchPods() {
    const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
        query {
            findPods {
                items {
                    id
                    name
                }
            }
        }
    `);
    return gqlResult.data.findPods.items;
}
exports.fetchPods = fetchPods;
