"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchCalendars = exports.fetchPersonalAdmins = exports.fetchAccountManagers = exports.fetchPhoneServices = exports.fetchPods = exports.fetchCompanyDetailsBillingInfo = exports.getAddressByOccupantIdFromCompanyId = exports.fetchCompanyDetailsBaseInfo = void 0;
const graphql_1 = require("../../../services/graphql");
const utilities_1 = require("../../../services/utilities");
const error_handling_1 = require("../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function fetchCompanyDetailsBaseInfo(companyId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!) {
                    Companies__JOCompanyDetails__Base_Info: getCompanies(id: $companyId) {
                        id
                        action_item_creation_email
                        business_number
                        callbacks_allowed
                        canceled
                        canceled_at
                        created_at
                        fax
                        hours_type
                        industry
                        internal_notes
                        invoice_email
                        is_test_company
                        is_vip
                        mailing_address
                        name
                        niche
                        preferred_communication
                        public_email
                        queue_audio_file_name
                        referral_source
                        referral_tag
                        same_caller_id
                        timezone
                        twilio_phone_number
                        verified_business_number
                        voicemail_audio_file_name
                        voicemail_text
                        website_url
                        uses_outbound_services
                        account_manager {
                            id
                            first_name
                            last_name
                        }
                        address {
                            id
                            line_1
                            line_2
                            city
                            state
                            zip_code
                        }
                        organization {
                            id
                            owner {
                                id
                                phone_number
                            }
                        }
                        personal_admin {
                            id
                            first_name
                            last_name
                        }
                        phone_service {
                            id
                            name
                            type
                        }
                        calendar {
                            id
                            name
                            type
                            url
                        }
                        chat_widget_settings {
                            id
                        }
                        pod {
                            id
                            name
                        }
                    }
                }
            `, {
            companyId,
        });
        if (gqlResult.succeeded === false) {
            return {
                ...gqlResult,
                developerMessage: `fetchCompanyDetailsBaseInfo error: ${gqlResult.developerMessage}`,
            };
        }
        const companyDetailsBaseInfo = gqlResult.value.data.Companies__JOCompanyDetails__Base_Info;
        if (companyDetailsBaseInfo.address === null ||
            companyDetailsBaseInfo.address === undefined) {
            const companyAddressFromOccupantIdResult = await getAddressByOccupantIdFromCompanyId(companyId);
            if (companyAddressFromOccupantIdResult.succeeded === false) {
                return {
                    ...companyAddressFromOccupantIdResult,
                    developerMessage: `fetchCompanyDetailsBaseInfo error: ${companyAddressFromOccupantIdResult.developerMessage}`,
                };
            }
            if (companyAddressFromOccupantIdResult.value === 'NOT_FOUND') {
                return {
                    succeeded: true,
                    value: companyDetailsBaseInfo,
                };
            }
            return {
                succeeded: true,
                value: {
                    ...companyDetailsBaseInfo,
                    address: companyAddressFromOccupantIdResult.value,
                },
            };
        }
        return {
            succeeded: true,
            value: companyDetailsBaseInfo,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchCompanyDetailsBaseInfo', error);
    }
}
exports.fetchCompanyDetailsBaseInfo = fetchCompanyDetailsBaseInfo;
/**
 * This function will be used only if the address cannot be found by address_id In the old system,
 * address_id on type companies is never used, rather occupant_id on the addresses type is used We
 * will not be doing that going forward, but this is for backward compatibility sake
 *
 * @param companyId
 */
async function getAddressByOccupantIdFromCompanyId(companyId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!) {
                    getCompanyAddressByOccupantId(companyId: $companyId) {
                        id
                        city
                        line_1
                        line_2
                        postal_code
                        state
                        zip_code
                    }
                }
            `, {
            companyId,
        });
        if (gqlResult.succeeded === false) {
            return {
                ...gqlResult,
                developerMessage: `getAddressByOccupantIdFromCompanyId error: ${gqlResult.developerMessage}`,
            };
        }
        const address = gqlResult.value.data.getCompanyAddressByOccupantId;
        if (address === null) {
            return {
                succeeded: true,
                value: 'NOT_FOUND',
            };
        }
        return {
            succeeded: true,
            value: address,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('getAddressByOccupantIdFromCompanyId', error);
    }
}
exports.getAddressByOccupantIdFromCompanyId = getAddressByOccupantIdFromCompanyId;
async function fetchCompanyDetailsBillingInfo(companyId, authenticatedUser) {
    try {
        if ((0, utilities_1.isInAuthRole)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], authenticatedUser) === false) {
            const companyDetailsBillingInfo = {
                id: companyId,
                canceled: false,
                credit_card_key: 'NOT_AUTHORIZED',
                on_hold: false,
                invoice_email: 'NOT_AUTHORIZED',
                status_events: [],
            };
            return {
                succeeded: true,
                value: companyDetailsBillingInfo,
            };
        }
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!) {
                    Companies__JOCompanyDetails__Billing_Info: getCompanies(id: $companyId) {
                        id
                        canceled
                        credit_card_key
                        invoice_email
                        on_hold
                        status_events {
                            id
                            type
                            timestamp
                        }
                    }
                }
            `, {
            companyId,
        });
        if (gqlResult.succeeded === false) {
            return {
                ...gqlResult,
                developerMessage: `fetchCompanyDetailsBillingInfo error: ${gqlResult.developerMessage}`,
            };
        }
        const companyDetailsBillingInfo = gqlResult.value.data.Companies__JOCompanyDetails__Billing_Info;
        return {
            succeeded: true,
            value: companyDetailsBillingInfo,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchCompanyDetailsBillingInfo', error);
    }
}
exports.fetchCompanyDetailsBillingInfo = fetchCompanyDetailsBillingInfo;
async function fetchPods() {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query {
                    findPods {
                        items {
                            id
                            name
                        }
                    }
                }
            `, {});
        if (gqlResult.succeeded === false) {
            return {
                ...gqlResult,
                developerMessage: `fetchPods error: ${gqlResult.developerMessage}`,
            };
        }
        return {
            succeeded: true,
            value: gqlResult.value.data.findPods.items,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchPods', error);
    }
}
exports.fetchPods = fetchPods;
async function fetchPhoneServices() {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query {
                    findPhone_services(filter: {type: {ne: "OTHER"}}) {
                        items {
                            id
                            name
                            type
                        }
                    }
                }
            `, {});
        if (gqlResult.succeeded === false) {
            return {
                ...gqlResult,
                developerMessage: `fetchPhoneServices error: ${gqlResult.developerMessage}`,
            };
        }
        return {
            succeeded: true,
            value: gqlResult.value.data.findPhone_services.items,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchPhoneServices', error);
    }
}
exports.fetchPhoneServices = fetchPhoneServices;
async function fetchAccountManagers() {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query {
                    findUsers(filter: {is_account_manager: {eq: true}}) {
                        items {
                            id
                            auth_role
                            first_name
                            last_name
                        }
                    }
                }
            `, {});
        if (gqlResult.succeeded === false) {
            return {
                ...gqlResult,
                developerMessage: `fetchAccountManagers error: ${gqlResult}`,
            };
        }
        return {
            succeeded: true,
            value: gqlResult.value.data.findUsers.items,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchAccountManagers', error);
    }
}
exports.fetchAccountManagers = fetchAccountManagers;
async function fetchPersonalAdmins() {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query {
                    findUsers(filter: {is_personal_admin: {eq: true}}) {
                        items {
                            id
                            auth_role
                            first_name
                            last_name
                        }
                    }
                }
            `, {});
        if (gqlResult.succeeded === false) {
            return {
                ...gqlResult,
                developerMessage: `fetchAccountManagers error: ${gqlResult}`,
            };
        }
        return {
            succeeded: true,
            value: gqlResult.value.data.findUsers.items,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchPersonalAdmins', error);
    }
}
exports.fetchPersonalAdmins = fetchPersonalAdmins;
async function fetchCalendars() {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query {
                findCalendars(filter: {type: {ne: "OTHER"}}) {
                    items {
                        id
                        type
                        name
                        url
                    }
                }
            }
        `);
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        return {
            succeeded: true,
            value: gqlResult.value.data.findCalendars.items,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchCalendars', error);
    }
}
exports.fetchCalendars = fetchCalendars;
