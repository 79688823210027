"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createColumnsForCSV = void 0;
const constants_1 = require("../../../../services/constants");
const make_string_safe_for_csv_1 = require("../../../../utilities/csv/make-string-safe-for-csv");
const helper_utils_1 = require("../jo-outbound-table/utils/helper-utils");
function createColumnsForCSV(numberOfCallAttemptsRequired) {
    const getValue = (val) => val || constants_1.CSV_EMPTY_COLUMN_STRING;
    const columns = [
        {
            header: 'Name',
            getter: (queueItem) => (0, make_string_safe_for_csv_1.makeStringSafeForCSV)(queueItem.contact?.first_name + ' ' + queueItem.contact?.last_name ||
                constants_1.CSV_EMPTY_COLUMN_STRING),
        },
        {
            header: 'Phone Number 1',
            getter: (queueItem) => {
                return getValue(queueItem.contact?.phone_numbers[0]?.number);
            },
        },
        {
            header: 'Phone Number 2',
            getter: (queueItem) => {
                const phoneNumbers = queueItem.contact?.phone_numbers;
                if (!phoneNumbers || phoneNumbers.length < 2) {
                    return constants_1.CSV_EMPTY_COLUMN_STRING;
                }
                const secondNumber = phoneNumbers[1]?.number;
                return secondNumber ? secondNumber : constants_1.CSV_EMPTY_COLUMN_STRING;
            },
        },
        {
            header: 'Email',
            getter: (queueItem) => getValue(queueItem.contact?.email),
        },
        {
            header: 'Address',
            getter: (queueItem) => getValue(getContactAddress(queueItem)),
        },
        {
            header: 'City',
            getter: (queueItem) => getValue(getContactCity(queueItem)),
        },
        {
            header: 'State',
            getter: (queueItem) => getValue(getContactState(queueItem)),
        },
        {
            header: 'Zip Code',
            getter: (queueItem) => getValue(getContactZipCode(queueItem)),
        },
        {
            header: 'Campaign Name',
            getter: (queueItem) => (0, make_string_safe_for_csv_1.makeStringSafeForCSV)(queueItem.campaign_name),
        },
        {
            header: 'Call Attempts Required',
            getter: (queueItem) => queueItem.num_attempts_to_make,
        },
        {
            header: 'Status',
            getter: (queueItem) => getQueueItemStatus(queueItem.status),
        },
        {
            header: 'Date of Next Attempt',
            getter: (queueItem) => getDateOfNextAttempt(queueItem),
        },
    ];
    for (let attemptNumber = 0; attemptNumber < numberOfCallAttemptsRequired; attemptNumber++) {
        columns.push({
            header: 'Attempt ' + (attemptNumber + 1),
            getter: (queueItem) => getActionItemNote(queueItem, attemptNumber),
        });
    }
    return columns;
}
exports.createColumnsForCSV = createColumnsForCSV;
function getActionItemNote(queueItem, attemptNumber) {
    const { formattedDate, note } = getActionItemNoteAndFormattedDateForCSV(queueItem, attemptNumber);
    if (formattedDate === constants_1.CSV_EMPTY_COLUMN_STRING) {
        return constants_1.CSV_EMPTY_COLUMN_STRING;
    }
    return (0, make_string_safe_for_csv_1.makeStringSafeForCSV)(note);
}
function getActionItemNoteAndFormattedDateForCSV(queueItem, attemptNumber) {
    const { createdAt, formattedDate } = getFormattedDateFromQueueItem(queueItem, attemptNumber);
    const note = getNoteFormattedNoteFromQueueItem(queueItem, attemptNumber, createdAt);
    return { formattedDate, note };
}
function getNoteFormattedNoteFromQueueItem(queueItem, attemptNumber, createdAt) {
    const noteTitle = getActionItemTitleForQueueItemAttempt(queueItem, attemptNumber);
    let noteBody = getActionItemNoteBodyFromQueueItemAttempt(queueItem, attemptNumber);
    if (noteBody) {
        noteBody = ' - ' + noteBody;
    }
    const note = noteTitle + ' - ' + createdAt + noteBody;
    return note;
}
function getActionItemNoteBodyFromQueueItemAttempt(queueItem, attemptNumber) {
    return queueItem.outbound_attempts[attemptNumber]?.action_item?.action_item_notes[0]?.body;
}
function getActionItemTitleForQueueItemAttempt(queueItem, attemptNumber) {
    return queueItem.outbound_attempts[attemptNumber]?.action_item?.title;
}
function getFormattedDateFromQueueItem(queueItem, attemptNumber) {
    const createdAt = getCreatedAtDateFromQueueItem(queueItem, attemptNumber);
    const formattedDate = createdAt
        ? new Date(createdAt).toLocaleDateString()
        : constants_1.CSV_EMPTY_COLUMN_STRING;
    return { createdAt, formattedDate };
}
function getCreatedAtDateFromQueueItem(queueItem, attemptNumber) {
    return queueItem.outbound_attempts[attemptNumber]?.action_item?.created_at;
}
function getContactAddress(queueItem) {
    const firstAddress = queueItem.contact?.addresses?.[0];
    return firstAddress
        ? (0, make_string_safe_for_csv_1.makeStringSafeForCSV)(firstAddress.line_1 + ' ' + firstAddress.line_2)
        : constants_1.CSV_EMPTY_COLUMN_STRING;
}
function getContactCity(queueItem) {
    const city = queueItem?.contact?.addresses[0]?.city;
    return city ? (0, make_string_safe_for_csv_1.makeStringSafeForCSV)(city) : constants_1.CSV_EMPTY_COLUMN_STRING;
}
function getContactState(queueItem) {
    const state = queueItem?.contact?.addresses[0]?.state;
    return state ? (0, make_string_safe_for_csv_1.makeStringSafeForCSV)(state) : constants_1.CSV_EMPTY_COLUMN_STRING;
}
function getContactZipCode(queueItem) {
    const zip_code = queueItem?.contact?.addresses[0]?.zip_code;
    return zip_code ? (0, make_string_safe_for_csv_1.makeStringSafeForCSV)(zip_code) : constants_1.CSV_EMPTY_COLUMN_STRING;
}
function getDateOfNextAttempt(queueItem) {
    if (queueItem.outbound_attempts.length === queueItem.num_attempts_to_make) {
        return constants_1.CSV_EMPTY_COLUMN_STRING;
    }
    return (0, helper_utils_1.getNextAttemptDateString)(queueItem);
}
function getQueueItemStatus(status) {
    switch (status) {
        case 'NOT_STARTED':
        case 'IN_PROGRESS':
            return 'In Progress';
        case 'COMPLETED':
        case 'MANUALLY_CLOSED':
            return 'Completed';
    }
}
