"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoGrantOauthAccess = void 0;
const element_vir_1 = require("element-vir");
const extract_oauth_info_from_url_1 = require("../../../utilities/url/extract-oauth-info-from-url");
const all_oauth_app_info_1 = require("../../../data/oauth/all-oauth-app-info");
const common_1 = require("@augment-vir/common");
exports.JoGrantOauthAccess = (0, element_vir_1.defineElement)()({
    tagName: 'jo-grant-oauth-access',
    styles: (0, element_vir_1.css) `
        :host {
            display: flex;
            flex-direction: column;
            padding: 64px;
            align-items: flex-start;
        }

        .error {
            color: red;
        }

        .faint {
            color: #999;
        }

        button {
            font: inherit;
            padding: 8px 16px;
            cursor: pointer;
            background-color: var(--jo-primary);
            border-radius: 2px;
            color: white;
            border: none;
            box-shadow: 0px 0px 1px rgb(0, 0, 0, 0.35);
        }

        button:hover {
            box-shadow: none;
            background-color: #f8b064;
        }

        p {
            margin-bottom: 32px;
        }
    `,
    renderCallback: ({ inputs }) => {
        try {
            const oauthUrlInfo = (0, extract_oauth_info_from_url_1.extractOauthInfoFromUrl)(inputs.url);
            const oauthAppInfo = oauthUrlInfo && (0, all_oauth_app_info_1.getOauthAppInfoFromClientId)(oauthUrlInfo.clientId);
            if (!oauthAppInfo) {
                throw new Error(`Invalid OAuth integration client id given: '${oauthUrlInfo.clientId}'`);
            }
            const chosenScope = oauthAppInfo.scopeAccess[oauthUrlInfo.requestedScope];
            if (!chosenScope) {
                throw new Error(`Invalid scope requested: '${oauthUrlInfo.requestedScope}'`);
            }
            return (0, element_vir_1.html) `
                <img src=${oauthAppInfo.logoUrl} />
            `;
        }
        catch (error) {
            return (0, element_vir_1.html) `
                <h1 class="error">OAuth Error</h1>
                <p>Sorry, this OAuth connection is invalid. Please contact support.</p>
                <p class="faint">Error: ${(0, common_1.extractErrorMessage)(error)}</p>
                <a href="/">
                    <button>Cancel</button>
                </a>
            `;
        }
    },
});
