"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateUserShifts = exports.tryChangeUserShifts = exports.updateJillShiftHours = exports.updateJillPods = exports.upsertJill = void 0;
const graphql_1 = require("../../../services/graphql");
const error_handling_1 = require("../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function upsertJill(input) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($input: UpsertUsersInput!) {
                    upsertUsers(input: $input) {
                        id
                    }
                }
            `, {
            input,
        });
        return {
            succeeded: true,
            value: gqlResult.data.upsertUsers.id,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('upsertJill', error);
    }
}
exports.upsertJill = upsertJill;
async function updateJillPods(jillId, podIdsForMutation, floatPodIdsForMutation) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation (
                    $jillId: Int!
                    $podIdsToConnect: [Int!]!
                    $podIdsToDisconnect: [Int!]!
                    $floatPodIdsToConnect: [Int!]!
                    $floatPodIdsToDisconnect: [Int!]!
                ) {
                    updateUsers(
                        input: {
                            id: $jillId
                            pods: {
                                connect: {ids: $podIdsToConnect}
                                disconnect: {ids: $podIdsToDisconnect}
                            }
                            float_pods: {
                                connect: {ids: $floatPodIdsToConnect}
                                disconnect: {ids: $floatPodIdsToDisconnect}
                            }
                        }
                    ) {
                        id
                    }
                }
            `, {
            jillId,
            podIdsToConnect: podIdsForMutation.podIdsToConnect,
            podIdsToDisconnect: podIdsForMutation.podIdsToDisconnect,
            floatPodIdsToConnect: floatPodIdsForMutation.podIdsToConnect,
            floatPodIdsToDisconnect: floatPodIdsForMutation.podIdsToDisconnect,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('updateJillPods', error);
    }
}
exports.updateJillPods = updateJillPods;
async function updateJillShiftHours(jillId, shiftHours) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($jillId: Int!, $shiftHours: String!) {
                    updateUsers(input: {id: $jillId, shift_hours: $shiftHours}) {
                        id
                    }
                }
            `, {
            jillId,
            shiftHours,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('updateJillShiftHours', error);
    }
}
exports.updateJillShiftHours = updateJillShiftHours;
async function tryChangeUserShifts(params) {
    try {
        await updateUserShifts(params);
        await archiveShiftsAndBreaks(params.previousShifts);
    }
    catch (error) {
        throw new Error(`tryChangeUserShifts error: ${error}`);
    }
}
exports.tryChangeUserShifts = tryChangeUserShifts;
async function updateUserShifts(params) {
    try {
        const { userId, newShifts, previousShifts } = params;
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($input: UpdateUsersInput!) {
                    updateUsers(input: $input) {
                        id
                    }
                }
            `, {
            input: makeUpdateUserShiftsInput(userId, previousShifts, newShifts),
        });
    }
    catch (error) {
        throw new Error(`updateUserShifts error: ${error}`);
    }
}
exports.updateUserShifts = updateUserShifts;
function makeUpdateUserShiftsInput(userId, previousShifts, newShifts) {
    const archivedAt = new Date();
    const lunchBreaksToArchive = previousShifts.map(shift => shift.lunch_breaks).flat();
    const scheduledBreaksToArchive = previousShifts.map(shift => shift.scheduled_breaks).flat();
    return {
        id: userId,
        shifts: {
            updateMany: makeArchiveManyInput(previousShifts, archivedAt),
            createMany: makeInputForCreateManyShifts(newShifts, userId),
        },
        lunch_breaks: {
            connect: {
                ids: getBreakIds(newShifts, 'lunch_breaks'),
            },
            updateMany: makeArchiveManyInput(lunchBreaksToArchive, archivedAt),
        },
        scheduled_breaks: {
            connect: {
                ids: getBreakIds(newShifts, 'scheduled_breaks'),
            },
            updateMany: makeArchiveManyInput(scheduledBreaksToArchive, archivedAt),
        },
    };
}
function makeArchiveManyInput(scheduleItems, archivedAt) {
    return scheduleItems.map(scheduleItem => ({
        id: scheduleItem.id,
        archived_at: archivedAt.toISOString(),
    }));
}
function getBreakIds(shifts, field) {
    return shifts
        .map(shift => shift[field].map(_break => _break.id))
        .flat();
}
function makeInputForCreateManyShifts(shifts, userId) {
    return shifts.map(shift => {
        const { lunch_breaks, scheduled_breaks, user, id, ...baseShift } = shift;
        return {
            ...baseShift,
            lunch_breaks: {
                createMany: makeInputForCreateManyBreaks(lunch_breaks, userId),
            },
            scheduled_breaks: {
                createMany: makeInputForCreateManyBreaks(scheduled_breaks, userId),
            },
        };
    });
}
function makeInputForCreateManyBreaks(breaks, userId) {
    return breaks.map(_break => {
        const { user, shift, id, ...baseBreak } = _break;
        return {
            ...baseBreak,
            user: {
                connect: {
                    id: userId,
                },
            },
        };
    });
}
async function archiveShiftsAndBreaks(shifts) {
    try {
        const now = new Date();
        await archiveShifts(shifts, now);
    }
    catch (error) {
        throw new Error(`archiveShiftsAndBreaks error: ${error}`);
    }
}
async function archiveShifts(shifts, date) {
    try {
        await Promise.all(shifts.map(async (shift) => {
            await archiveShift(shift, date);
        }));
    }
    catch (error) {
        throw new Error(`archiveShifts error: ${error}`);
    }
}
async function archiveShift(shift, date) {
    try {
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($input: UpdateUser_shiftsInput!) {
                    updateUser_shifts(input: $input) {
                        id
                    }
                }
            `, {
            input: makeArchiveShiftInput(shift, date),
        });
    }
    catch (error) {
        throw new Error(`archiveShift error: ${error}`);
    }
}
function makeArchiveShiftInput(shift, date) {
    return {
        id: shift.id,
        lunch_breaks: {
            updateMany: makeArchiveManyInput(shift.lunch_breaks, date),
        },
        scheduled_breaks: {
            updateMany: makeArchiveManyInput(shift.scheduled_breaks, date),
        },
    };
}
