"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.wasLikelyPartOfAPushedCall = void 0;
const is_chat_duration_event_1 = require("./is-chat-duration-event");
const is_user_status_event_1 = require("./is-user-status-event");
const requiredSequence = [
    'CALL_SENT_TO_USER',
    'INCOMING_CALL_RECEIVED_BY_USER',
    'INCOMING_CALL_LOADED_ON_CALL_SCREEN',
    'CALL_START',
];
function wasLikelyPartOfAPushedCall(callsAndStatusEvents, index) {
    const item = callsAndStatusEvents[index];
    if (!item || !(0, is_user_status_event_1.isUserStatusEvent)(item) || (0, is_chat_duration_event_1.isChatDurationEvent)(item))
        return false;
    let j = requiredSequence.indexOf(item.type);
    if (j === -1)
        return false;
    for (; j < requiredSequence.length; j++, index++) {
        const item = callsAndStatusEvents[index];
        const requiredSequenceEvent = requiredSequence[j];
        if (item == null) {
            throw new Error('item is null');
        }
        if (requiredSequenceEvent == null) {
            throw new Error('requiredSequenceEvent is null');
        }
        if ((0, is_chat_duration_event_1.isChatDurationEvent)(item) || isCall(item)) {
            return false;
        }
        const previousItem = callsAndStatusEvents[index - 1];
        if (previousItem == null) {
            continue;
        }
        if (item.type === 'INCOMING_CALL_LOADED_ON_CALL_SCREEN' &&
            (0, is_user_status_event_1.isUserStatusEvent)(previousItem, 'INCOMING_CALL_LOADED_ON_CALL_SCREEN')) {
            index--;
            continue;
        }
        if (item.type === 'INCOMING_CALL_LOADED_ON_CALL_SCREEN' &&
            (0, is_user_status_event_1.isUserStatusEvent)(previousItem, 'INCOMING_CALL_CANCELED_BEFORE_USER_ANSWERED')) {
            return false;
        }
        if (item.type === 'INCOMING_CALL_CANCELED_BEFORE_USER_ANSWERED' &&
            (requiredSequenceEvent === 'INCOMING_CALL_RECEIVED_BY_USER' ||
                requiredSequenceEvent === 'INCOMING_CALL_LOADED_ON_CALL_SCREEN')) {
            return false;
        }
        if (requiredSequenceEvent === 'CALL_START' &&
            !isCall(item) &&
            item.type !== 'INCOMING_CALL_CANCELED_BEFORE_USER_ANSWERED') {
            return true;
        }
        if (item.type !== requiredSequence[j]) {
            return false;
        }
    }
    return false;
}
exports.wasLikelyPartOfAPushedCall = wasLikelyPartOfAPushedCall;
function isCall(item) {
    return (0, is_user_status_event_1.isUserStatusEvent)(item) === false;
}
