"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOQuickTextPopUp = void 0;
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const test_id_directive_1 = require("../../directives/test-id.directive");
require("../jo-button.element");
const InitialState = {
    title: '',
    inputValue: '',
    textType: 'SHORT',
};
class JOQuickTextPopUp extends HTMLElement {
    constructor(title, textType) {
        super();
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
        this.store.title = title;
        this.store.textType = textType ?? 'SHORT';
    }
    buttonClicked(response) {
        if (response === true) {
            (0, utilities_1.assertIsSet)(this.store.inputValue);
            (0, utilities_1.raiseCustomEvent)(this, 'addNewQuickItem', this.store.inputValue);
        }
        else {
            this.dispatchEvent(new CustomEvent('cancel'));
        }
    }
    textInputChangedEvent(e) {
        this.store.inputValue = e.target.value;
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .main-container {
                    font-family: sans-serif;
                }

                .darken-screen {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.65);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: var(--z-index-3);
                }

                @media (max-width: 768px) {
                    .darken-screen {
                        align-items: flex-start;
                    }
                }

                .fade-in {
                    opacity: 0;
                    animation-name: fade-in;
                    animation-duration: 0.125s;
                    animation-fill-mode: forwards;
                }

                .window {
                    width: 35%;
                    background: white;
                    border-radius: 5px;
                    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
                }

                @media (max-width: 1280px) {
                    .window {
                        width: 50%;
                    }
                }

                @media (max-width: 768px) {
                    .window {
                        margin-top: 40%;
                        width: 75%;
                    }
                }

                .window-popup {
                    opacity: 0;
                    transform: scale(0.5);
                    animation-name: window-popup;
                    animation-duration: 0.15s;
                    animation-fill-mode: forwards;
                }

                .title-bar {
                    background: var(--jo-primary);
                    color: white;
                    font-weight: bold;
                    padding: 1.2em;
                    border-bottom: 1px solid var(--jo-accent-background-light);
                    box-sizing: border-box;
                    display: flex;
                    align-content: center;
                    justify-content: space-between;
                }

                .alertTitle {
                    font-weight: bold;
                }

                .close {
                    color: var(--jo-text-default);
                    transform: scale(1.5);
                    cursor: pointer;
                }

                .close:hover {
                    color: black;
                    transform: scale(1.65);
                }

                .input-container {
                    padding: 1.5rem;
                    border-bottom: 1px solid var(--jo-accent-background-light);
                    box-sizing: border-box;
                    margin-left: auto;
                    margin-right: auto;
                }

                .input-textarea {
                    width: 100%;
                    height: 6rem;
                    resize: none;
                }

                .buttons-row {
                    background: var(--jo-accent-background-light);
                    padding: 1rem;
                    display: flex;
                    justify-content: flex-end;
                }

                .mr1 {
                    margin-right: 1rem;
                }

                @keyframes fade-in {
                    to {
                        opacity: 1;
                    }
                }

                @keyframes window-popup {
                    to {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            </style>

            <div class="main-container">
                <div class="darken-screen fade-in">
                    <div class="window window-popup">
                        <div class="title-bar">
                            <div>${this.store.title}</div>
                        </div>
                        <div class="input-container">
                            ${state.textType === 'LONG'
            ? (0, lit_html_1.html) `
                                      <textarea
                                          id="textareabox"
                                          class="jo-global--text-area input-textarea"
                                          @input=${(e) => this.textInputChangedEvent(e)}
                                      ></textarea>
                                  `
            : (0, lit_html_1.html) `
                                      <jo-input
                                          ${(0, test_id_directive_1.testId)('quick-text-pop-up-input-box')}
                                          id="inputbox"
                                          @input=${(e) => this.textInputChangedEvent(e)}
                                          .type=${'TEXT_INPUT'}
                                      ></jo-input>
                                  `}
                        </div>
                        <div class="buttons-row">
                            <jo-button
                                class="mr1"
                                ${(0, test_id_directive_1.testId)('quick-text-add-button')}
                                .type=${'ALERT_BUTTON_ACCEPT'}
                                .text=${'Add'}
                                @joButtonClick=${() => this.buttonClicked(true)}
                            ></jo-button>
                            <jo-button
                                ${(0, test_id_directive_1.testId)('quick-text-cancel-button')}
                                .type=${'SECONDARY_BUTTON_1'}
                                .text=${'Cancel'}
                                @joButtonClick=${() => this.buttonClicked(false)}
                            ></jo-button>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}
exports.JOQuickTextPopUp = JOQuickTextPopUp;
(0, define_custom_element_1.defineCustomElement)('jo-quick-text-pop-up', JOQuickTextPopUp);
