"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchCallLogSummariesForCompany = exports.fetchTotalNumCompanyLogsForCompany = void 0;
const graphql_1 = require("../../../../services/graphql");
const error_handling_1 = require("../../../../utilities/error-handling");
const helper_utils_1 = require("./helper-utils");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function fetchTotalNumCompanyLogsForCompany(companyId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!) {
                    findCompany_logs(filter: {company_id: {eq: $companyId}}) {
                        total
                    }
                }
            `, {
            companyId,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const numCompanyLogs = gqlResult.value.data.findCompany_logs.total;
        return {
            succeeded: true,
            value: numCompanyLogs,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchTotalNumCompanyLogsForCompany', error);
    }
}
exports.fetchTotalNumCompanyLogsForCompany = fetchTotalNumCompanyLogsForCompany;
async function fetchCallLogSummariesForCompany(companyId, limit, offset) {
    try {
        const companyLogsResult = await fetchCompanyLogsForCompany(companyId, limit, offset);
        if (companyLogsResult.succeeded === false)
            return companyLogsResult;
        const companyLogs = companyLogsResult.value;
        const callLogSummariesResult = await getCallLogSummariesFromCompanyLogs(companyId, companyLogs);
        if (callLogSummariesResult.succeeded === false)
            return callLogSummariesResult;
        return {
            succeeded: true,
            value: callLogSummariesResult.value,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchCallLogSummariesForCompany', error);
    }
}
exports.fetchCallLogSummariesForCompany = fetchCallLogSummariesForCompany;
async function getCallLogSummariesFromCompanyLogs(companyId, companyLogs) {
    try {
        const callTotalsForMonthsResults = await fetchCallTotalsResultsForBillingCycles(companyId, companyLogs);
        const packagesForMonthsResults = await fetchPackagesResultsForMonths(companyId, companyLogs);
        const callLogSummariesResult = (0, helper_utils_1.buildCallLogSummaries)(companyLogs, callTotalsForMonthsResults, packagesForMonthsResults);
        if (callLogSummariesResult.succeeded === false)
            return callLogSummariesResult;
        return {
            succeeded: true,
            value: callLogSummariesResult.value,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('getCallLogSummariesFromCompanyLogs', error);
    }
}
async function fetchCallTotalsResultsForBillingCycles(companyId, companyLogs) {
    const callTotalsPromises = companyLogs.map(companyLog => getTotalNumBillableCallsForBillingCycleForCompany(companyId, companyLog));
    const callTotalsForMonthsResults = await Promise.all(callTotalsPromises);
    return callTotalsForMonthsResults;
}
async function fetchPackagesResultsForMonths(companyId, companyLogs) {
    const packagesForMonthsPromises = companyLogs.map(log => getPackageForCompanyForBillingCycle(companyId, log.start_date, log.end_date));
    const packagesForMonthsResults = await Promise.all(packagesForMonthsPromises);
    return packagesForMonthsResults;
}
async function getPackageForCompanyForBillingCycle(companyId, startDate, endDate) {
    const minutesPackageResult = await getMinutesPackageForCompanyForMonth(companyId, startDate, endDate);
    if (minutesPackageResult.succeeded === false)
        return minutesPackageResult;
    if (minutesPackageResult.value !== 'NOT_FOUND') {
        return {
            succeeded: true,
            value: minutesPackageResult.value,
        };
    }
    const receptionistServiceResult = await getReceptionistServiceForCompany(companyId);
    if (receptionistServiceResult.succeeded === false)
        return receptionistServiceResult;
    return {
        succeeded: true,
        value: receptionistServiceResult.value,
    };
}
async function getMinutesPackageForCompanyForMonth(companyId, startDate, endDate) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!, $startDate: DateTime!, $endDate: DateTime!) {
                    findInvoices(
                        filter: {
                            and: [
                                {company_id: {eq: $companyId}}
                                {start_date: {ge: $startDate}}
                                {end_date: {le: $endDate}}
                            ]
                        }
                    ) {
                        items {
                            id
                            minutes_package {
                                id
                                num_minutes_included
                            }
                        }
                    }
                }
            `, {
            companyId,
            startDate,
            endDate,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const invoice = gqlResult.value.data.findInvoices.items[0];
        if (invoice === undefined) {
            return {
                succeeded: true,
                value: 'NOT_FOUND',
            };
        }
        if (invoice.minutes_package === null || invoice.minutes_package === undefined) {
            return {
                succeeded: true,
                value: 'NOT_FOUND',
            };
        }
        return {
            succeeded: true,
            value: invoice.minutes_package,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('getMinutesPackageForCompanyForMonth', error);
    }
}
async function getReceptionistServiceForCompany(companyId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!) {
                    getCompanies(id: $companyId) {
                        id
                        receptionist_services {
                            id
                            minutes_per_month
                        }
                    }
                }
            `, {
            companyId,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const receptionistService = gqlResult.value.data.getCompanies.receptionist_services[0];
        if (receptionistService === undefined) {
            return {
                succeeded: true,
                value: 'NOT_FOUND',
            };
        }
        return {
            succeeded: true,
            value: receptionistService,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('getReceptionistServiceForCompany', error);
    }
}
async function fetchCompanyLogsForCompany(companyId, limit, offset) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!, $limit: Int!, $offset: Int!) {
                    findCompany_logs(
                        filter: {company_id: {eq: $companyId}}
                        page: {limit: $limit, offset: $offset}
                        orderBy: {field: "id", order: DESC}
                    ) {
                        items {
                            id
                            for_month
                            billable_duration
                            start_date
                            end_date
                        }
                    }
                }
            `, {
            companyId,
            limit,
            offset,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const companyLogs = gqlResult.value.data.findCompany_logs.items;
        return {
            succeeded: true,
            value: companyLogs,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchCompanyLogsForCompany', error);
    }
}
async function getTotalNumBillableCallsForBillingCycleForCompany(companyId, companyLog) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!, $startDate: DateTime!, $endDate: DateTime!) {
                    findCalls(
                        filter: {
                            and: [
                                {company_id: {eq: $companyId}}
                                {created_at: {ge: $startDate}}
                                {created_at: {lt: $endDate}}
                                {billable_duration: {gt: 0}}
                                {new_system: {eq: true}}
                            ]
                        }
                    ) {
                        total
                    }
                }
            `, {
            companyId,
            startDate: companyLog.start_date,
            endDate: companyLog.end_date,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const numCalls = gqlResult.value.data.findCalls.total;
        return {
            succeeded: true,
            value: numCalls,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('getTotalNumBillableCallsForMonthForCompany', error);
    }
}
