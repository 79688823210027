"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stylesForChatDashboard = void 0;
const lit_html_1 = require("lit-html");
const css_utils_1 = require("../../../../utilities/css-utils");
const cssName = (0, css_utils_1.cssPrefixer)('jo-chat-dashboard');
const cssNames = (classNames) => classNames.split(' ').map(cssName).join(' ');
exports.stylesForChatDashboard = (() => {
    return (0, lit_html_1.html) `
        <style>
            .${cssNames('main-container')} {
                height: 100%;
                width: 100%;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
            }

            .${cssNames('chat-sidebar')} {
                margin-right: 0.5rem;
                background-color: white;
                padding-left: 1rem;
                padding-right: 1rem;
                padding-top: 0.5rem;
                border-radius: var(--jo-wide-border-radius-1);
                box-shadow: var(--jo-card-shadow-1);
                flex: 1;
            }

            .${cssNames('icon-container')} {
                color: white;
                background-color: var(--jo-secondary);
                padding: 0.5rem 0.5rem 0.1rem 0.5rem;
                border-radius: var(--jo-wide-border-radius-1);
                box-shadow: var(--jo-card-shadow-1);
                cursor: pointer;
            }

            .${cssNames('icon-container')}:hover {
                background-color: var(--jo-secondary-light);
            }

            .${cssNames('call-screen-container')} {
                display: flex;
                align-items: center;
                flex: 5;
                width: 100%;
                height: 100%;
            }

            .${cssNames('call-screen')} {
                width: 100%;
                height: 100%;
            }

            .${cssNames('no-chat-selected')} {
                color: var(--jo-accent-background-dark);
                font-size: 2.5rem;
                text-align: center;
                width: 100%;
                display: flex;
                flex-direction: column;
            }

            .${cssNames('chat-mode-selector-container')} {
                display: flex;
                flex-direction: row;
                justify-content: center;
                gap: 6rem;
                width: 100%;
            }
            .${cssNames('chat-mode-selector')} {
                margin: 1rem;
                padding: 1rem;
                border-radius: var(--jo-wide-border-radius-1);
                box-shadow: var(--jo-card-shadow-1);
                background-color: var(--jo-primary);
                color: white;
                font-weight: bold;
                cursor: pointer;
            }
            .${cssNames('chat-mode-selector')}:hover {
                background-color: var(--jo-primary-light);
            }
            .${cssNames('jo-chat-dashboard--chat-mode-title')} {
                text-align: center;
                margin-top: 10rem;
            }
            .${cssNames('end-chat-mode-button')} {
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 0.5rem;
                border-radius: var(--jo-wide-border-radius-1);
                box-shadow: var(--jo-card-shadow-1);
                background-color: var(--jo-primary);
                color: white;
                font-weight: bold;
                cursor: pointer;
            }
            .${cssNames('end-chat-mode-button')}:hover {
                background-color: var(--jo-primary-light);
            }
        </style>
    `;
})();
