"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateTimeSpentInAdminModeForCompanies = void 0;
const diff_time_1 = require("../../../../utilities/dates-and-times/diff-time");
const is_user_status_event_1 = require("./is-user-status-event");
const otherEventsThatShouldEndAdminMode = [
    'CLOCKED_OUT',
    'LOGGED_OUT',
    'WENT_ON_BREAK',
    'WENT_ON_LUNCH',
    'BEGAN_TEAM_HUDDLE',
    'BEGAN_OFF_CALL_TRAINING',
];
function calculateTimeSpentInAdminModeForCompanies(items) {
    try {
        const results = {};
        for (const item of items) {
            if (item == null) {
                throw new Error('item is null');
            }
            if (!(0, is_user_status_event_1.isUserStatusEvent)(item))
                continue;
            if ((0, is_user_status_event_1.isUserStatusEvent)(item, 'BEGAN_ADMIN_MODE')) {
                if (item.company?.id === undefined) {
                    throw new Error(makeErrorMessage(item));
                }
                if (item.company.id in results) {
                    const company = results[item.company.id];
                    if (company == null) {
                        throw new Error('company is null');
                    }
                    company.latestTimeBeganAdminMode = new Date(item.timestamp);
                }
                else {
                    results[item.company.id] = {
                        latestTimeBeganAdminMode: new Date(item.timestamp),
                        totalTime: 0,
                        companyName: item.company.name,
                    };
                }
            }
            if (otherEventsThatShouldEndAdminMode.includes(item.type)) {
                calculateAndEndCurrentRunningAdminTimeForAllCompanies(results, item);
            }
            if ((0, is_user_status_event_1.isUserStatusEvent)(item, 'ENDED_ADMIN_MODE')) {
                if (item.company?.id === undefined) {
                    throw new Error(makeErrorMessage(item));
                }
                const company = results[item.company.id];
                if (company && company.latestTimeBeganAdminMode !== null) {
                    company.totalTime += (0, diff_time_1.diffTime)(company.latestTimeBeganAdminMode, item.timestamp);
                    company.latestTimeBeganAdminMode = null;
                }
            }
        }
        return buildFinalResult(results);
    }
    catch (error) {
        throw new Error(`calculateTimeSpentInAdminModeForCompanies error: ${error}`);
    }
}
exports.calculateTimeSpentInAdminModeForCompanies = calculateTimeSpentInAdminModeForCompanies;
function buildFinalResult(results) {
    return Object.keys(results).reduce((finalResult, companyId) => {
        const company = results[companyId];
        if (company == null) {
            throw new Error('company is null');
        }
        finalResult[companyId] = {
            name: company.companyName,
            totalTime: company.totalTime,
        };
        return finalResult;
    }, {});
}
function calculateAndEndCurrentRunningAdminTimeForAllCompanies(results, item) {
    for (const key in results) {
        const result = results[key];
        const latestTimeBeganAdminMode = result?.latestTimeBeganAdminMode;
        if (result == null || latestTimeBeganAdminMode == null) {
            return;
        }
        result.totalTime += (0, diff_time_1.diffTime)(latestTimeBeganAdminMode, new Date(item.timestamp));
        result.latestTimeBeganAdminMode = null;
    }
}
function makeErrorMessage(item) {
    return `calculateTimeSpentInAdminModeForCompanies error: company id not found for ${item.type} event: ${JSON.stringify(item)}`;
}
