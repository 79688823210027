"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOutboundScriptFromCompany = exports.fetchCompanies = exports.fetchOutboundQueueItems = void 0;
const common_1 = require("@augment-vir/common");
const graphql_1 = require("../../../../services/graphql");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function fetchOutboundQueueItems(searchArgs) {
    try {
        const finalSearchArgs = modifySearchArgsForUse(searchArgs);
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query (
                    $actionItemTitle: String
                    $campaignName: String
                    $companyId: Int
                    $companyName: String
                    $limit: Int!
                    $offset: Int
                    $podName: String
                    $timezone: String
                    $type: OUTBOUND_QUEUE_TABLE_TYPE
                ) {
                    searchOutboundQueueItems(
                        actionItemTitle: $actionItemTitle
                        campaignName: $campaignName
                        companyId: $companyId
                        companyName: $companyName
                        limit: $limit
                        offset: $offset
                        podName: $podName
                        timezone: $timezone
                        type: $type
                    ) {
                        total
                        campaign_names
                        outbound_queue_items {
                            id
                            campaign_name
                            closed_at
                            company {
                                id
                                name
                                pod {
                                    id
                                    name
                                }
                                timezone
                            }
                            contact {
                                id
                                first_name
                                last_name
                                phone_numbers(orderBy: {field: "id", order: ASC}) {
                                    id
                                    number
                                    created_at
                                }
                                addresses {
                                    line_1
                                    line_2
                                    city
                                    state
                                    zip_code
                                }
                            }
                            created_at
                            next_attempt_to_be_made_at
                            num_attempts_to_make
                            outbound_attempts {
                                id
                                call {
                                    id
                                }
                                action_item {
                                    id
                                    title
                                    created_at
                                    action_item_notes {
                                        id
                                        body
                                        created_at
                                    }
                                }
                            }
                            status
                            time_between_attempts
                        }
                    }
                }
            `, {
            ...finalSearchArgs,
        });
        if (gqlResult.succeeded === false) {
            throw new Error(gqlResult.developerMessage);
        }
        return gqlResult.value.data.searchOutboundQueueItems;
    }
    catch (error) {
        throw new Error('jo-outbound-table -> queries -> fetchOutboundQueueItems ' + error);
    }
}
exports.fetchOutboundQueueItems = fetchOutboundQueueItems;
function modifySearchArgsForUse(searchArgs) {
    const modifiedSearchArgs = {};
    for (const key in searchArgs) {
        if (searchArgs[key] === 'All') {
            modifiedSearchArgs[key] = undefined;
        }
        else if (key == 'companyId' &&
            searchArgs[key] === -1) {
            modifiedSearchArgs[key] = undefined;
        }
        else {
            modifiedSearchArgs[key] = searchArgs[key];
        }
    }
    return modifiedSearchArgs;
}
async function fetchCompanies() {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query {
                    findCompanies(filter: {and: [{is_test_company: {eq: false}}]}) {
                        items {
                            id
                            name
                            timezone
                        }
                    }
                }
            `, {});
        return gqlResult.data.findCompanies.items;
    }
    catch (error) {
        throw new Error(`jo-outbound-table -> queries -> fetchCompanies error: ${error}`);
    }
}
exports.fetchCompanies = fetchCompanies;
async function getOutboundScriptFromCompany(companyId) {
    try {
        const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!) {
                    company: getCompanies(id: $companyId) {
                        call_instructions(filter: {type: {eq: "OUTBOUND"}}) {
                            instructions
                        }
                    }
                }
            `, {
            companyId,
        });
        if (result.succeeded === false) {
            return result;
        }
        (0, common_1.assertLengthAtLeast)(result.value.data.company.call_instructions, 1);
        const instructions = result.value.data.company.call_instructions[0].instructions;
        return {
            succeeded: true,
            value: instructions,
        };
    }
    catch (error) {
        throw new Error("getOutboundScriptFromCompany -> company does not have a call instruction of Type: 'OUTBOUND' or something else went wrong.");
    }
}
exports.getOutboundScriptFromCompany = getOutboundScriptFromCompany;
