"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoAccountHelpTable = void 0;
const element_vir_1 = require("element-vir");
require("../jo-account-help-ticket-log/jo-account-help-ticket-log.element");
const jo_search_company_input_element_1 = require("../jo-search-company-input/jo-search-company-input.element");
const jo_search_jill_input_element_1 = require("../jo-search-jill-input/jo-search-jill-input.element");
const make_table_columns_1 = require("./make-table-columns");
const fetch_account_help_table_data_1 = require("./fetch-account-help-table-data");
const jo_account_help_ticket_log_element_1 = require("../jo-account-help-ticket-log/jo-account-help-ticket-log.element");
const jo_account_help_update_form_element_1 = require("../jo-account-help-update-form/jo-account-help-update-form.element");
const utilities_1 = require("../../../services/utilities");
const account_help_ticket_status_1 = require("../../../utilities/account-help-tickets/account-help-ticket-status");
const get_full_name_1 = require("../../../utilities/get-full-name");
const is_defined_1 = require("../../../utilities/is-defined");
exports.JoAccountHelpTable = (0, element_vir_1.defineElement)()({
    tagName: 'jo-account-help-table',
    styles: (0, element_vir_1.css) `
        .main-container {
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            font-size: 0.9rem;
        }

        .inputs-container {
            display: flex;
            gap: 2.5rem;
        }

        .deselect-icon {
            cursor: pointer;
            color: red;
        }

        .selected-item {
            font-size: 1.2rem;
            padding: 1rem;
            display: flex;
            align-items: center;
        }

        .table-container {
            padding-top: 1rem;
        }
    `,
    stateInit: {
        accountManagers: [],
        dateField: 'UPDATED_AT',
        endDate: undefined,
        selectedAccountHelpticketForHistory: undefined,
        selectedAccountHelpTicketForUpdate: undefined,
        selectedAccountManager: undefined,
        selectedCompany: undefined,
        selectedJill: undefined,
        selectedStatus: account_help_ticket_status_1.AccountHelpTicketStatus.PENDING_REVIEW,
        startDate: undefined,
        subscriptions: {
            accountHelpTicketCreated: undefined,
            accountHelpTicketUpdated: undefined,
        },
    },
    cleanupCallback: ({ state }) => {
        state.subscriptions.accountHelpTicketCreated?.close();
        state.subscriptions.accountHelpTicketUpdated?.close();
    },
    renderCallback({ state, updateState, inputs }) {
        async function searchAccountHelpTickets(offset, limit, searchText, column) {
            const { account_help_tickets, total } = await (0, fetch_account_help_table_data_1.fetchAccountHelpTickets)({
                accountManagerId: state.selectedAccountManager?.id,
                companyId: state.selectedCompany?.id ?? inputs.companyId,
                currentStatus: state.selectedStatus,
                dateField: state.dateField,
                endDate: state.endDate,
                limit,
                offset,
                searchText,
                sortDirection: getSortDirection(column),
                sortField: getSortField(column),
                startDate: state.startDate,
                userId: state.selectedJill?.id ?? inputs.userId,
            });
            return {
                items: account_help_tickets,
                totalItems: total,
            };
        }
        function statusSelected(e) {
            const selectedValue = e.detail.selectedValue;
            if (selectedValue === 'All') {
                updateState({
                    selectedStatus: undefined,
                });
            }
            else {
                updateState({
                    selectedStatus: e.detail.selectedValue,
                });
            }
        }
        function accountManagerSelected(selectedName) {
            if (selectedName === 'None') {
                updateState({
                    selectedAccountManager: undefined,
                });
                return;
            }
            const accountManager = state.accountManagers.find(accountManager => (0, get_full_name_1.getFullName)(accountManager) === selectedName);
            (0, utilities_1.assertIsDefined)(accountManager);
            updateState({
                selectedAccountManager: accountManager,
            });
        }
        return (0, element_vir_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <div class="main-container">
                <div class="inputs-container">
                    <jo-input
                        .type=${'DATE'}
                        .label=${'Start Date'}
                        @valueChanged=${(e) => {
            updateState({
                startDate: new Date(e.detail.inputValue),
            });
        }}
                    ></jo-input>

                    <jo-input
                        .type=${'DATE'}
                        .label=${'End Date'}
                        @valueChanged=${(e) => {
            updateState({
                endDate: new Date(e.detail.inputValue),
            });
        }}
                    ></jo-input>

                    <jo-input
                        .type=${'SELECT'}
                        .label=${'Date Field'}
                        .selectOptions=${[
            'UPDATED_AT',
            'CREATED_AT',
        ]}
                        @valueChanged=${(e) => {
            updateState({
                dateField: e.detail.selectedValue,
            });
        }}
                    ></jo-input>

                    <jo-input
                        .type=${'SELECT'}
                        .label=${'Status'}
                        .selectOptions=${[
            'All',
            ...Object.values(account_help_ticket_status_1.AccountHelpTicketStatus),
        ]}
                        .defaultOption=${'Select status...'}
                        .disableDefaultOption=${true}
                        @valueChanged=${(e) => {
            statusSelected(e);
        }}
                    ></jo-input>

                    <jo-input
                        .type=${'SELECT'}
                        .label=${'Account Manager'}
                        .selectOptions=${[
            'None',
            ...state.accountManagers.map(get_full_name_1.getFullName),
        ]}
                        .defaultOption=${'Select account manager...'}
                        .disableDefaultOption=${true}
                        @valueChanged=${(e) => {
            accountManagerSelected(e.detail.selectedValue);
        }}
                    ></jo-input>

                    ${(0, element_vir_1.renderIf)(inputs.companyId === undefined, (0, element_vir_1.html) `
                            <${jo_search_company_input_element_1.JoSearchCompanyInput}
                                ${(0, element_vir_1.listen)(jo_search_company_input_element_1.JoSearchCompanyInput.events.companySelected, event => {
            updateState({
                selectedCompany: event.detail,
            });
        })}
                            ></${jo_search_company_input_element_1.JoSearchCompanyInput}>
                        `)}
                    ${(0, element_vir_1.renderIf)(inputs.userId === undefined, (0, element_vir_1.html) `
                            <${jo_search_jill_input_element_1.JoSearchJillInput}
                                ${(0, element_vir_1.listen)(jo_search_jill_input_element_1.JoSearchJillInput.events.jillSelected, event => {
            updateState({
                selectedJill: event.detail,
            });
        })}
                            ></${jo_search_jill_input_element_1.JoSearchJillInput}>
                        `)}
                </div>

                ${(0, element_vir_1.renderIf)((0, is_defined_1.isDefined)(state.selectedCompany), (0, element_vir_1.html) `
                        <div class="selected-item">
                            Selected Company:&nbsp;
                            <strong>${state.selectedCompany?.name}</strong>
                            <span
                                class="material-icons deselect-icon"
                                @click=${() => {
            updateState({
                selectedCompany: undefined,
            });
        }}
                            >
                                clear
                            </span>
                        </div>
                    `)}
                ${(0, element_vir_1.renderIf)((0, is_defined_1.isDefined)(state.selectedJill), (0, element_vir_1.html) `
                        <div class="selected-item">
                            Selected Jill:&nbsp;
                            <strong>${(0, get_full_name_1.getFullName)(state.selectedJill)}</strong>
                            <span
                                class="material-icons deselect-icon"
                                @click=${() => {
            updateState({
                selectedJill: undefined,
            });
        }}
                            >
                                clear
                            </span>
                        </div>
                    `)}

                <div class="table-container">
                    <jo-table
                        .tableName=${'Account Help Tickets'}
                        .maxItemsPerPage=${15}
                        .getItems=${searchAccountHelpTickets}
                        .columns=${(0, make_table_columns_1.makeTableColumns)({
            viewHistoryClicked: (helpTicket) => {
                updateState({
                    selectedAccountHelpticketForHistory: helpTicket,
                });
            },
            updateTicketClicked: (helpTicket) => {
                updateState({
                    selectedAccountHelpTicketForUpdate: helpTicket,
                });
            },
        })}
                    ></jo-table>
                </div>

                <jo-fade-in-overlay
                    .props=${{
            child: (0, element_vir_1.html) `
                            <div class="jo-global--width-50-vw">
                                <${jo_account_help_update_form_element_1.JoAccountHelpUpdateForm}
                                    ${(0, element_vir_1.assign)(jo_account_help_update_form_element_1.JoAccountHelpUpdateForm, {
                accountHelpTicket: state.selectedAccountHelpTicketForUpdate,
            })}
                                    ${(0, element_vir_1.listen)(jo_account_help_update_form_element_1.JoAccountHelpUpdateForm.events.submitted, () => {
                updateState({
                    selectedAccountHelpTicketForUpdate: undefined,
                });
            })}
                                ></${jo_account_help_update_form_element_1.JoAccountHelpUpdateForm}>
                            </div>
                        `,
            showChild: (0, is_defined_1.isDefined)(state.selectedAccountHelpTicketForUpdate),
        }}
                    @closeoverlay=${() => {
            updateState({
                selectedAccountHelpTicketForUpdate: undefined,
            });
        }}
                ></jo-fade-in-overlay>

                <jo-fade-in-overlay
                    .props=${{
            child: (0, element_vir_1.html) `
                            <div class="jo-global--width-50-vw">
                                <div class="jo-global--card">
                                    <${jo_account_help_ticket_log_element_1.JoAccountHelpTicketLog}
                                        ${(0, element_vir_1.assign)(jo_account_help_ticket_log_element_1.JoAccountHelpTicketLog, {
                accountHelpTicket: state.selectedAccountHelpticketForHistory,
            })}
                                    ></${jo_account_help_ticket_log_element_1.JoAccountHelpTicketLog}>
                                </div>
                            </div>
                        `,
            showChild: (0, is_defined_1.isDefined)(state.selectedAccountHelpticketForHistory),
        }}
                    @closeoverlay=${() => {
            updateState({
                selectedAccountHelpticketForHistory: undefined,
            });
        }}
                ></jo-fade-in-overlay>
            </div>
        `;
    },
});
function getSortDirection(column) {
    if (column === 'NOT_SET' || column.sortDirection === undefined) {
        return undefined;
    }
    return column.sortDirection;
}
function getSortField(column) {
    if (column === 'NOT_SET' || column.sortFieldName === undefined) {
        return undefined;
    }
    return column.sortFieldName;
}
