"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoQueueBoxSettingsPriorityLevel = void 0;
const element_vir_1 = require("element-vir");
const utilities_1 = require("../../../../services/utilities");
const jo_queue_status_box_element_1 = require("../../../elements/jo-queue-status-box/jo-queue-status-box.element");
const constants_and_types_queue_status_box_settings_1 = require("./constants-and-types-queue-status-box-settings");
const jo_priority_level_customize_pop_up_element_1 = require("./jo-priority-level-customize-pop-up.element");
exports.JoQueueBoxSettingsPriorityLevel = (0, element_vir_1.defineElement)()({
    tagName: 'jo-queue-box-settings-priority-level',
    styles: (0, element_vir_1.css) `
            :host {
                display: flex;
                flex-direction: row;
                gap: 3rem;
                max-width: 40rem;
                background-color: var(--jo-accent-background-light);
            }

            .configs-container {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }

            .demo-container {
                background-color: var(--jo-primary);
                padding: 2rem;
                border-radius: 10px;
            }

            .buttons-container {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }
        `,
    events: {
        priorityLevelChange: (0, element_vir_1.defineElementEvent)(),
    },
    stateInit: {
        temporaryPriorityLevel: undefined,
    },
    renderCallback({ state, updateState, inputs, dispatch, events }) {
        return (0, element_vir_1.html) `
                <link rel="stylesheet" href="/styles/jo-styles.css" />
                <link
                    href="https://fonts.googleapis.com/icon?family=Material+Icons"
                    rel="stylesheet"
                />

                <div class="configs-container">
                    <div>Total Queue Items >= ${inputs.level.total_queue_items}</div>
                    <div>Highest Pod Count >= ${inputs.level.highest_pod_count}</div>
                    <div>BackgroundColor = ${inputs.level.background_color}</div>
                    <div>Textcolor = ${inputs.level.text_color}</div>
                </div>

                <div class="demo-container">
                    <${jo_queue_status_box_element_1.JoQueueStatusBox}
                        ${(0, element_vir_1.assign)(jo_queue_status_box_element_1.JoQueueStatusBox, {
            containerTitle: 'Pod Information Will Be Displayed Here',
            queueStatItems: [
                {
                    name: jo_queue_status_box_element_1.QueueStatusBoxStatNamesEnum.TOTAL_IN_QUEUE,
                    value: inputs.level.total_queue_items || 0,
                },
                {
                    name: jo_queue_status_box_element_1.QueueStatusBoxStatNamesEnum.WAITING,
                    value: 2,
                },
                {
                    name: jo_queue_status_box_element_1.QueueStatusBoxStatNamesEnum.CALLBACKS,
                    value: 3,
                },
                {
                    name: jo_queue_status_box_element_1.QueueStatusBoxStatNamesEnum.HIGHEST_POD_COUNT,
                    value: inputs.level.highest_pod_count || 0,
                },
            ],
            priorityLevelOverrides: inputs.queuePriorityLevels,
            renderClockIconIf: true,
            type: constants_and_types_queue_status_box_settings_1.QueueStatusBoxType.INCOMING_CALLS,
        })}
                    ></${jo_queue_status_box_element_1.JoQueueStatusBox}>
                </div>

                <div class="buttons-container">
                    <jo-button
                        .type=${'ICON'}
                        .icon=${'edit'}
                        @joButtonClick=${async () => {
            const shouldUpdate = await displayEditPriorityLevelPopUp(inputs.level, inputs.queuePriorityLevels, updateState);
            if (shouldUpdate && state.temporaryPriorityLevel) {
                dispatch(new events.priorityLevelChange(state.temporaryPriorityLevel));
            }
        }}
                    ></jo-button>

                    <jo-button
                        .type=${'ICON'}
                        .icon=${'delete'}
                        @joButtonClick=${() => {
            dispatch(new events.priorityLevelChange(undefined));
        }}
                    ></jo-button>
                </div>
            `;
    },
});
async function displayEditPriorityLevelPopUp(currentLevel, queuePriorityLevels, updateState) {
    return await (0, utilities_1.joPopUp)({
        title: 'Edit Priority Level',
        confirmButtonText: 'Save',
        includeCancelButton: true,
        htmlComponents: getEditPriorityLevelHtml(currentLevel, queuePriorityLevels, updateState),
    });
}
function getEditPriorityLevelHtml(level, queuePriorityLevels, updateState) {
    return (0, element_vir_1.html) `
        <${jo_priority_level_customize_pop_up_element_1.JoCustomizePriorityLevelPopUp}
            ${(0, element_vir_1.assign)(jo_priority_level_customize_pop_up_element_1.JoCustomizePriorityLevelPopUp, {
        priorityLevel: level,
        queuePriorityLevels,
    })}
            ${(0, element_vir_1.listen)(jo_priority_level_customize_pop_up_element_1.JoCustomizePriorityLevelPopUp.events.updatePriorityLevel, event => {
        updateState({
            temporaryPriorityLevel: event.detail,
        });
    })}
        ></${jo_priority_level_customize_pop_up_element_1.JoCustomizePriorityLevelPopUp}>
    `;
}
