"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const auth_rules_1 = require("../../../utilities/auth-rules");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const test_id_directive_1 = require("../../directives/test-id.directive");
require("../jo-button.element");
const jo_input_1 = require("../jo-input");
require("../jo-button.element");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    user: 'NOT_SET',
    createNewPassword: false,
    inputValues: {
        authRoleSelectedValue: 'NOT_SET',
        emailInputValue: '',
        firstNameInputValue: '',
        formErrors: [],
        lastNameInputValue: '',
        passwordError: '',
        passwordConfirmationError: '',
        passwordInputValue: '',
        phoneNumberInputValue: '',
        shiftHoursInputValue: '',
        teamRoleSelectedValue: 'NO_ROLE',
        timeZoneSelectedValue: '',
    },
    showAllFormErrors: false,
    loading: true,
    mode: 'EDIT',
};
class JOUserContactInfo extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    set user(user) {
        if (user === this.store.user) {
            return;
        }
        this.store.user = user;
        if (user === 'NOT_SET' || user === 'NEW_USER') {
            this.inputValues = InitialState.inputValues;
            this.store.loading = false;
        }
        else {
            this.inputValues = {
                authRoleSelectedValue: user.auth_role,
                emailInputValue: user.email,
                firstNameInputValue: user.first_name || '',
                formErrors: this.store.inputValues.formErrors,
                lastNameInputValue: user.last_name || '',
                passwordError: '',
                passwordConfirmationError: '',
                passwordInputValue: '',
                phoneNumberInputValue: user.phone_number || '',
                shiftHoursInputValue: user.shift_hours || '',
                teamRoleSelectedValue: user.team_role || 'NO_ROLE',
                timeZoneSelectedValue: user.time_zone || '',
            };
        }
        this.generateAndSetPasswordErrors();
        this.store.loading = false;
    }
    set inputValues(inputValues) {
        this.store.inputValues = inputValues;
        (0, utilities_1.raiseCustomEvent)(this, 'usercontactinfoinputvalueschanged', inputValues);
    }
    set mode(mode) {
        this.store.mode = mode;
        this.store.createNewPassword = mode === 'CREATE' ? true : false;
    }
    clearPasswordErrors() {
        const formErrorsMinusPasswordErrors = this.store.inputValues.formErrors.filter((formError) => {
            return formError.label.includes('Password') === false;
        });
        this.inputValues = {
            ...this.store.inputValues,
            formErrors: formErrorsMinusPasswordErrors,
        };
    }
    generateAndSetPasswordErrors() {
        if (this.store.createNewPassword === false) {
            this.clearPasswordErrors();
            return;
        }
        const passwordInput = this.querySelector('#password-input');
        (0, utilities_1.assertIsDefined)(passwordInput, 'passwordInput', utilities_1.handleError);
        const confirmationPasswordInput = this.querySelector('#password-confirmation-input');
        (0, utilities_1.assertIsDefined)(confirmationPasswordInput, 'confirmationPasswordInput', utilities_1.handleError);
        this.updatePasswordError(passwordInput);
        this.updatePasswordConfirmationError(passwordInput, confirmationPasswordInput);
    }
    updatePasswordError(passwordInput) {
        const passwordError = generatePasswordError(passwordInput.inputValue);
        this.inputValues = {
            ...this.store.inputValues,
            passwordError,
        };
    }
    updatePasswordConfirmationError(passwordInput, confirmationPasswordInput) {
        const passwordConfirmationError = generatePasswordConfirmationError(passwordInput.inputValue, confirmationPasswordInput.inputValue);
        this.inputValues = {
            ...this.store.inputValues,
            passwordConfirmationError,
        };
    }
    render(state) {
        const allowedToUpdateUser = (0, utilities_1.isAllowedToUpdateUser)(state.authenticatedUser, state.user);
        return (0, lit_html_1.html) `
            <style>
                .jo-user-contact-info--main-container {
                    box-sizing: border-box;
                    font-family: sans-serif;
                    color: var(--jo-text-default);

                    height: 100%;
                    width: 100%;

                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                }

                .jo-user-contact-info--contact-info-card {
                    box-sizing: border-box;
                    background-color: white;
                    box-shadow: 0 0 3px var(--jo-text-default);
                    border-radius: 7px;
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 1rem;
                    padding-bottom: 2rem;
                }

                .jo-user-contact-info--card-header {
                    margin-top: 0rem;
                    color: rgb(110, 110, 110);
                }

                .jo-user-contact-info--contact-info-card-row {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    padding-top: 1.5rem;
                    box-sizing: border-box;
                    width: 100%;
                }

                .jo-user-contact-info--input-container {
                    width: 80%;
                }

                .jo-user-contact-info--grid {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                }
            </style>

            <div class="jo-user-contact-info--main-container">
                <div class="jo-user-contact-info--contact-info-card">
                    ${state.user === 'NEW_USER'
            ? (0, lit_html_1.html) `
                              <h2 class="jo-user-contact-info--card-header">
                                  NEW USER CONTACT INFO:
                              </h2>
                          `
            : state.user !== 'NOT_SET'
                ? (0, lit_html_1.html) `
                              <h2 class="jo-user-contact-info--card-header">
                                  CONTACT INFO: ${state.user.first_name} ${state.user.last_name}
                              </h2>
                          `
                : (0, lit_html_1.html) `
                              <h2 class="jo-user-contact-info--card-header">Loading...</h2>
                          `}

                    <div class="jo-user-contact-info--contact-info-card-row">
                        <div class="jo-user-contact-info--input-container">
                            <jo-input
                                ${(0, test_id_directive_1.testId)('jo-user-first-name')}
                                .props=${{
            label: 'First Name',
            type: 'TEXT_INPUT',
            required: true,
            inputValue: state.inputValues.firstNameInputValue,
            readonly: allowedToUpdateUser === false,
            showRequiredIcon: true,
            requiredIconType: 'SUBTLE',
            showErrorParentOverride: state.showAllFormErrors,
        }}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                firstNameInputValue: e.detail.inputValue,
                formErrors: (0, jo_input_1.updateFormErrors)(this.store.inputValues.formErrors, e.detail.label, e.detail.errorMessage),
            };
        }}
                            ></jo-input>
                        </div>

                        <div class="jo-user-contact-info--input-container">
                            <jo-input
                                ${(0, test_id_directive_1.testId)('jo-user-last-name')}
                                .props=${{
            label: 'Last Name',
            type: 'TEXT_INPUT',
            required: true,
            inputValue: state.inputValues.lastNameInputValue,
            readonly: allowedToUpdateUser === false,
            showRequiredIcon: true,
            requiredIconType: 'SUBTLE',
            showErrorParentOverride: state.showAllFormErrors,
        }}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                lastNameInputValue: e.detail.inputValue,
                formErrors: (0, jo_input_1.updateFormErrors)(this.store.inputValues.formErrors, e.detail.label, e.detail.errorMessage),
            };
        }}
                            ></jo-input>
                        </div>
                    </div>

                    <div class="jo-user-contact-info--contact-info-card-row">
                        <div class="jo-user-contact-info--input-container">
                            <jo-input
                                ${(0, test_id_directive_1.testId)('jo-user-team-role')}
                                .props=${{
            label: 'Team Role',
            type: 'SELECT',
            selectedValue: getTeamRoleFriendlyDisplay(state.inputValues.teamRoleSelectedValue),
            selectOptions: [
                'Manager',
                'Member',
            ],
            defaultOption: 'Please select a Team Role...',
            readonly: allowedToUpdateUser === false,
            showErrorParentOverride: state.showAllFormErrors,
        }}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                teamRoleSelectedValue: convertTeamRoleFriendlyToEnum(e.detail.selectedValue),
                formErrors: (0, jo_input_1.updateFormErrors)(this.store.inputValues.formErrors, e.detail.label, e.detail.errorMessage),
            };
        }}
                            ></jo-input>
                        </div>

                        <div class="jo-user-contact-info--input-container">
                            <jo-input
                                ${(0, test_id_directive_1.testId)('jo-user-phone-number')}
                                .props=${{
            label: 'Phone Number',
            type: 'PHONE_NUMBER',
            inputValue: state.inputValues.phoneNumberInputValue,
            readonly: allowedToUpdateUser === false,
            showErrorParentOverride: state.showAllFormErrors,
        }}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                phoneNumberInputValue: e.detail.inputValue,
                formErrors: (0, jo_input_1.updateFormErrors)(this.store.inputValues.formErrors, e.detail.label, e.detail.errorMessage),
            };
        }}
                            ></jo-input>
                        </div>
                    </div>

                    <div class="jo-user-contact-info--contact-info-card-row">
                        <div class="jo-user-contact-info--input-container">
                            <jo-input
                                ${(0, test_id_directive_1.testId)('jo-user-email')}
                                .props=${{
            label: 'Email',
            type: 'EMAIL',
            required: true,
            inputValue: state.inputValues.emailInputValue,
            readonly: allowedToUpdateUser === false,
            showRequiredIcon: true,
            requiredIconType: 'SUBTLE',
            showErrorParentOverride: state.showAllFormErrors,
        }}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                emailInputValue: e.detail.inputValue,
                formErrors: (0, jo_input_1.updateFormErrors)(this.store.inputValues.formErrors, e.detail.label, e.detail.errorMessage),
            };
        }}
                            ></jo-input>
                        </div>

                        <div class="jo-user-contact-info--input-container">
                            <jo-input
                                ${(0, test_id_directive_1.testId)('jo-user-timezone')}
                                .label=${'Timezone'}
                                .type=${'TIMEZONE_SELECT'}
                                .readonly=${!allowedToUpdateUser}
                                .selectedValue=${state.inputValues.timeZoneSelectedValue}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                timeZoneSelectedValue: e.detail.selectedValue,
                formErrors: e.detail.formErrors,
            };
        }}
                                .showError=${state.showAllFormErrors}
                            ></jo-input>
                        </div>
                    </div>

                    <div class="jo-user-contact-info--contact-info-card-row">
                        <div class="jo-user-contact-info--input-container">
                            ${allowedToUpdateUser === true
            ? (0, lit_html_1.html) `
                                      <jo-input
                                          ${(0, test_id_directive_1.testId)('jo-user-auth-role')}
                                          .props=${{
                label: 'Auth Role',
                type: 'SELECT',
                required: true,
                selectedValue: state.inputValues.authRoleSelectedValue ===
                    'NOT_SET'
                    ? ''
                    : state.inputValues.authRoleSelectedValue,
                defaultOption: 'Please select an auth role...',
                selectOptions: getAuthRoleSelectOptions(state.authenticatedUser, state.user),
                showRequiredIcon: true,
                requiredIconType: 'SUBTLE',
                showErrorParentOverride: state.showAllFormErrors,
            }}
                                          @inputchange=${(e) => {
                this.inputValues = {
                    ...this.store.inputValues,
                    authRoleSelectedValue: e.detail
                        .selectedValue,
                    formErrors: (0, jo_input_1.updateFormErrors)(this.store.inputValues.formErrors, e.detail.label, e.detail.errorMessage),
                };
            }}
                                      ></jo-input>
                                  `
            : (0, lit_html_1.html) `
                                      <jo-input
                                          .props=${{
                label: 'Auth Role',
                type: 'TEXT_INPUT',
                inputValue: state.inputValues.authRoleSelectedValue,
                readonly: true,
            }}
                                      ></jo-input>
                                  `}
                        </div>
                    </div>

                    <div class="jo-user-contact-info--contact-info-card-row">
                        <div class="jo-user-contact-info--input-container">
                            <jo-input
                                ${(0, test_id_directive_1.testId)('jo-user-extra-info')}
                                .props=${{
            label: 'Extra Useful Info (for Jills Pods Page)',
            type: 'TEXT_AREA',
            inputValue: state.inputValues.shiftHoursInputValue,
            readonly: !(0, auth_rules_1.isJillAtOrAboveAuthRole)(state.authenticatedUser, 'JILL_MANAGER'),
            rows: 4,
        }}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                shiftHoursInputValue: e.detail.inputValue,
                formErrors: (0, jo_input_1.updateFormErrors)(this.store.inputValues.formErrors, e.detail.label, e.detail.errorMessage),
            };
        }}
                            ></jo-input>
                        </div>
                    </div>

                    ${state.createNewPassword === false && allowedToUpdateUser === true
            ? (0, lit_html_1.html) `
                              <div class="jo-user-contact-info--contact-info-card-row">
                                  <div>
                                      <jo-button
                                          .type=${'ACTION_BUTTON_2'}
                                          .text=${'Create New Password'}
                                          @joButtonClick=${() => {
                this.store.createNewPassword = true;
                (0, utilities_1.raiseCustomEvent)(this, 'createnewpasswordselected');
            }}
                                      ></jo-button>
                                  </div>
                              </div>
                          `
            : ''}
                    ${state.createNewPassword === true && allowedToUpdateUser === true
            ? (0, lit_html_1.html) `
                              <div class="jo-user-contact-info--contact-info-card-row">
                                  <jo-input
                                      ${(0, test_id_directive_1.testId)('jo-user-password-input')}
                                      id="password-input"
                                      .props=${{
                label: state.mode === 'CREATE'
                    ? 'Password (12 character min.)'
                    : 'New Password (12 character min.)',
                type: 'PASSWORD',
                inputValue: state.inputValues.passwordInputValue,
                required: true,
                showRequiredIcon: true,
                requiredIconType: 'SUBTLE',
                errorMessage: state.inputValues.passwordError,
                showErrorParentOverride: state.showAllFormErrors,
            }}
                                      @inputchange=${(e) => {
                this.inputValues = {
                    ...this.store.inputValues,
                    passwordInputValue: e.detail.inputValue,
                };
            }}
                                      @input=${() => this.generateAndSetPasswordErrors()}
                                  ></jo-input>

                                  <jo-input
                                      ${(0, test_id_directive_1.testId)('jo-user-password-input-confirm')}
                                      id="password-confirmation-input"
                                      .props=${{
                label: 'Confirm Password',
                type: 'PASSWORD',
                required: true,
                showRequiredIcon: true,
                requiredIconType: 'SUBTLE',
                errorMessage: state.inputValues.passwordConfirmationError,
                showErrorParentOverride: state.showAllFormErrors,
            }}
                                      @input=${() => this.generateAndSetPasswordErrors()}
                                  ></jo-input>
                              </div>
                          `
            : ''}

                    <div ?hidden=${state.mode === 'CREATE' || state.createNewPassword === false}>
                        <div class="jo-user-contact-info--contact-info-card-row">
                            <div></div>
                            <div>
                                <jo-button
                                    .type=${'ACTION_BUTTON_2'}
                                    .text=${'Cancel New Password'}
                                    @joButtonClick=${() => {
            this.store.createNewPassword = false;
            this.generateAndSetPasswordErrors();
            (0, utilities_1.raiseCustomEvent)(this, 'cancelnewpasswordselected');
        }}
                                ></jo-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-user-contact-info', JOUserContactInfo);
function convertTeamRoleFriendlyToEnum(value) {
    if (value === 'Manager')
        return 'MANAGER';
    if (value === 'Member')
        return 'MEMBER';
    return 'NO_ROLE';
}
function getTeamRoleFriendlyDisplay(value) {
    if (value === 'MANAGER')
        return 'Manager';
    if (value === 'MEMBER')
        return 'Member';
    return '';
}
function generatePasswordError(password) {
    if (password.length < 12)
        return 'Password must be at least 12 characters';
    return '';
}
function generatePasswordConfirmationError(password, confirmationPassword) {
    if (password !== confirmationPassword)
        return 'Passwords do not match';
    return '';
}
function getAuthRoleSelectOptions(authenticatedUser, user) {
    if (authenticatedUser === 'NOT_SET' || user === 'NOT_SET') {
        return [];
    }
    const authenticatedUserIsSelectedUser = user !== 'NEW_USER' && authenticatedUser.id === user.id;
    if (authenticatedUser.auth_role === 'JILL_MANAGER') {
        return [
            'JILL',
            'JILL_OUTBOUND',
            ...(authenticatedUserIsSelectedUser === true ? ['JILL_MANAGER'] : []),
        ];
    }
    if (authenticatedUser.auth_role === 'JILL_BUSINESS_DEVELOPMENT') {
        return [
            'JILL',
            'JILL_OUTBOUND',
            'JILL_MANAGER',
            ...(authenticatedUserIsSelectedUser === true
                ? ['JILL_BUSINESS_DEVELOPMENT']
                : []),
        ];
    }
    if (authenticatedUser.auth_role === 'JILL_EXECUTIVE') {
        return [
            'JILL',
            'JILL_OUTBOUND',
            'JILL_MANAGER',
            'JILL_BUSINESS_DEVELOPMENT',
            ...(authenticatedUserIsSelectedUser === true ? ['JILL_EXECUTIVE'] : []),
        ];
    }
    return [];
}
