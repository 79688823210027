"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoProfileSettingsPage = void 0;
const element_vir_1 = require("element-vir");
const helper_utils_for_profile_settings_1 = require("./helper-utils-for-profile-settings");
const ring_tone_options_1 = require("./ring-tone-options");
const make_letter_case_1 = require("../../../utilities/strings/make-letter-case");
exports.JoProfileSettingsPage = (0, element_vir_1.defineElement)()({
    tagName: 'jo-profile-settings-page',
    stateInit: {
        selectedRingtoneUrl: ring_tone_options_1.ringtones.default.Default,
        currentlyPlayingAudio: undefined,
        userSettingsId: undefined,
    },
    styles: (0, element_vir_1.css) `
        :host {
            display: block;
            padding: 2rem;
            color: var(--jo-grey-1);
        }

        .column {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        .ringtone-selection-container {
            align-items: center;
        }

        .ringtone-option-categories {
            display: flex;
            flex-direction: row;
            gap: 4rem;
        }

        .ringtone-option-row {
            text-align: center;
        }

        .category-name {
            font-weight: bold;
            text-decoration: underline;
        }

        .ringtone-item {
            cursor: pointer;
            padding-left: 1rem;
            padding-right: 1rem;
            padding-top: 0.3rem;
            padding-bottom: 0.3rem;
        }

        .ringtone-item:hover {
            background: var(--jo-primary-light);
        }

        .selected {
            background: var(--jo-primary-light);
        }

        .check-mark {
            color: var(--jo-accent);
        }

        .save-button {
            display: flex;
            justify-content: center;
            margin-top: 4rem;
        }
    `,
    renderCallback: ({ state, updateState, inputs }) => {
        if (!inputs.authenticatedUser)
            return '';
        if (!state.userSettingsId) {
            (0, helper_utils_for_profile_settings_1.fetchAndSetUserProfileSettings)(inputs.authenticatedUser?.id, updateState);
        }
        return (0, element_vir_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

            <div class="jo-global--card">
                <section class="ringtone-selection-container column">
                    <h2>Ringtone Selection:</h2>
                    <div class="ringtone-option-categories">
                        ${Object.values(ring_tone_options_1.ringtoneCategories).map(categoryName => {
            return (0, element_vir_1.html) `
                                <div class="ringtone-option-row column">
                                    <div class="category-name">${(0, make_letter_case_1.makeLetterCase)(categoryName)}</div>

                                    ${Object.entries(ring_tone_options_1.ringtones[categoryName]).map(([ringtoneName, ringtoneUrlForCard,]) => {
                return (0, helper_utils_for_profile_settings_1.createRingtoneSelectionCards)(updateState, ringtoneUrlForCard, state.selectedRingtoneUrl, ringtoneName, state.currentlyPlayingAudio);
            })}
                                </div>
                            `;
        })}
                    </div>
                </section>

                <div class="save-button">
                    <jo-button
                        id="jo-audio-popup-button"
                        .type=${'ACTION_BUTTON_1'}
                        .text=${'Update Settings'}
                        @joButtonClick=${async () => {
            await (0, helper_utils_for_profile_settings_1.updateSettingsButtonClick)(inputs.authenticatedUser?.id, state.selectedRingtoneUrl, state.userSettingsId, updateState);
        }}
                    ></jo-button>
                </div>
            </div>
        `;
    },
});
