"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractOauthInfoFromUrl = void 0;
const common_1 = require("@augment-vir/common");
const exampleOauthUrlInfo = {
    clientId: '',
    redirectUrl: '',
    requestedScope: '',
};
function extractOauthInfoFromUrl(inputUrl) {
    const urlToExtractDataFrom = (0, common_1.isRuntimeTypeOf)(inputUrl, 'string') ? new URL(inputUrl) : inputUrl;
    const maybeOathInfo = {
        clientId: urlToExtractDataFrom.searchParams.get('clientId') ?? undefined,
        redirectUrl: urlToExtractDataFrom.searchParams.get('redirect') ?? undefined,
        requestedScope: urlToExtractDataFrom.searchParams.get('scope') ?? undefined,
    };
    (0, common_1.assertMatchesObjectShape)(maybeOathInfo, exampleOauthUrlInfo);
    return maybeOathInfo;
}
exports.extractOauthInfoFromUrl = extractOauthInfoFromUrl;
