"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const stored_path_before_login_1 = require("../../data/local-storage/stored-path-before-login");
const authenticate_1 = require("../../services/auth/authenticate");
const constants_1 = require("../../services/constants");
const store_1 = require("../../services/store");
const utilities_1 = require("../../services/utilities");
const auth_rules_1 = require("../../utilities/auth-rules");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../utilities/environment/mapped-env-variables");
const user_status_event_mutations_1 = require("../../utilities/logging-and-monitoring/user-status-event-mutations");
const login_1 = require("../../utilities/login");
const generate_uuid_1 = require("../../utilities/strings/generate-uuid");
const test_id_directive_1 = require("../directives/test-id.directive");
require("./jo-button.element");
var UserLoginMode;
(function (UserLoginMode) {
    UserLoginMode["PasswordEntry"] = "PasswordEntry";
    UserLoginMode["MFACodeEntry"] = "MFACodeEntry";
})(UserLoginMode || (UserLoginMode = {}));
const InitialState = {
    emailInputValue: '',
    isMobileDevice: store_1.InitialGlobalState.isMobileDevice,
    passwordInputValue: '',
    user: undefined,
    twoFACode: undefined,
    deviceUuid: undefined,
    userLoginMode: UserLoginMode.PasswordEntry,
};
class JOLogin extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    async loginClick() {
        try {
            const deviceUuid = window.localStorage.getItem('deviceUuid') || (0, generate_uuid_1.generateUuid)();
            const loginResult = await (0, login_1.login)({
                email: this.store.emailInputValue,
                password: this.store.passwordInputValue,
                deviceUuid,
            });
            if (!loginResult.succeeded) {
                (0, utilities_1.joAlert)('Error', 'Login failed. Note: login is case sensitive.');
                return;
            }
            this.store.user = loginResult.user;
            this.store.deviceUuid = deviceUuid;
            if (!loginResult.userLoginIdentifiers.csrfToken) {
                this.store.userLoginMode = UserLoginMode.MFACodeEntry;
                return;
            }
            await this.performPostUserAuthSuccessActivities(this.store.user, loginResult.userLoginIdentifiers);
            return;
        }
        catch (error) {
            (0, utilities_1.joAlert)('Error', 'Login failed');
            throw new Error(error);
        }
    }
    async performPostUserAuthSuccessActivities(loggedInUser, userLoginIdentifiers) {
        storeCredentialsInLocalStorage(userLoginIdentifiers, this.store.deviceUuid);
        await (0, authenticate_1.authenticate)();
        this.clearLoginInputs();
        this.navigateAfterLogin(loggedInUser);
        const companyId = getCompanyIdIfItExists(loggedInUser);
        (0, user_status_event_mutations_1.createUserStatusEvent)({
            userId: loggedInUser.id,
            eventType: 'LOGGED_IN',
            companyId: companyId,
        });
    }
    async initiateMFACodeVerification(code, user) {
        const deviceUuid = (0, generate_uuid_1.generateUuid)();
        this.store.deviceUuid = deviceUuid;
        const userLoginIdentifiers = await (0, login_1.verifyMFACode)(code, user.id, deviceUuid);
        if (!userLoginIdentifiers) {
            (0, utilities_1.joAlert)('Invalid MFA Code', getInvalidMFACodesDetailedMessage(user.number_of_invalid_mfa_codes));
            this.store.user = undefined;
            this.store.twoFACode = undefined;
            this.store.deviceUuid = undefined;
            this.store.userLoginMode = UserLoginMode.PasswordEntry;
            return;
        }
        await this.performPostUserAuthSuccessActivities(user, userLoginIdentifiers);
        return;
    }
    clearLoginInputs() {
        this.store.emailInputValue = '';
        this.store.passwordInputValue = '';
    }
    navigateAfterLogin(user) {
        const savedPathBeforeLogin = stored_path_before_login_1.storedPathBeforeLogin.get();
        if (savedPathBeforeLogin) {
            stored_path_before_login_1.storedPathBeforeLogin.remove();
            (0, utilities_1.navigate)(savedPathBeforeLogin);
        }
        else if ((0, auth_rules_1.isCompanyUser)(user) && this.store.isMobileDevice) {
            navigateToCustomerActionItems(user);
        }
        else {
            (0, utilities_1.navigate)('/');
        }
    }
    inputKeydown(e) {
        if (e.keyCode === 13) {
            this.loginClick();
        }
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .jo-login--main-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    background-color: var(--jo-primary);
                    width: 100%;
                    height: 100%;
                }

                .jo-login--logo-container {
                    flex-basis: 25%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .jo-login--card {
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    flex-basis: 50%;
                    flex-shrink: 1;
                    justify-content: center;
                    align-items: center;
                    padding: 3vh 1rem;
                    border-radius: 10px;
                    background-color: white;
                    box-shadow: 0px 3px 15px rgb(0, 0, 0, 0.4);
                    gap: 1rem;
                }

                .jo-login--greeting-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .jo-login--text-heading {
                    color: var(--jo-primary);
                    font-size: min(6vh, 30px);
                }

                .jo-login--jill-image-container {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                }

                .jo-login--logo-image {
                    max-height: 15vh;
                }

                .jo-login--jill-image {
                    max-height: 15vh;
                }

                .jo-login--inputs-container {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 1vh;
                }

                .jo-login--input {
                    display: block;
                    padding: 8px 8px;
                    color: var(--jo-text-default);
                    background-color: #fff;
                    background-clip: padding-box;
                    border: 1px solid var(--jo-accent-background-dark);
                    border-radius: 4px;
                    font-size: 16px;
                    outline-color: orange;
                    width: 100%;
                }

                .jo-login--footer {
                    flex-basis: 25%;
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: column;
                    align-items: center;
                    gap: 2vh;
                }

                .jo-login--link {
                    text-decoration: none;
                    color: white;
                }

                .jo-login--link:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            </style>

            <main class="jo-login--main-container">
                <section class="jo-login--logo-container">
                    <img class="jo-login--logo-image" src="../images/jo-logo.png" />
                </section>

                <section
                    class="jo-login--card"
                    style="${state.isMobileDevice ? 'width: 95vw' : 'width: 50vw'}"
                >
                    <div class="jo-login--greeting-container">
                        <div class="jo-login--text-heading">Welcome!</div>

                        <div class="jo-login--jill-image-container">
                            <img class="jo-login--jill-image" src="../images/jill-waving.png" />
                        </div>

                        <div class="jo-login--text-heading">Let's get Jill'n!</div>
                    </div>

                    <div
                        class="jo-login--inputs-container"
                        style="${state.isMobileDevice ? 'width: 100%' : 'width: 35%'}"
                    >
                        ${state.userLoginMode === UserLoginMode.PasswordEntry
            ? (0, lit_html_1.html) `
                                  <input
                                      ${(0, test_id_directive_1.testId)('login-email-input')}
                                      class="jo-login--input"
                                      id="email-input"
                                      autocapitalize="none"
                                      type="text"
                                      placeholder="Email*"
                                      .value=${state.emailInputValue}
                                      @input=${(event) => (this.store.emailInputValue = event.target.value)}
                                      @keydown=${(event) => this.inputKeydown(event)}
                                  />

                                  <input
                                      ${(0, test_id_directive_1.testId)('login-password-input')}
                                      class="jo-login--input"
                                      id="password-input"
                                      type="password"
                                      autocapitalize="none"
                                      placeholder="Password*"
                                      .value=${state.passwordInputValue}
                                      @input=${(event) => (this.store.passwordInputValue = event.target.value)}
                                      @keydown=${(event) => this.inputKeydown(event)}
                                  />
                                  <div>(*case sensitive)</div>
                              `
            : ''}
                        ${state.userLoginMode === UserLoginMode.MFACodeEntry
            ? (0, lit_html_1.html) `
                                  <input
                                      class="jo-login--input"
                                      type="text"
                                      placeholder="MFA Code*"
                                      .value=${state.twoFACode || ''}
                                      @input=${(event) => (this.store.twoFACode = event.target.value)}
                                  />
                              `
            : ''}
                    </div>

                    <div class="jo-login--button-container">
                        ${state.userLoginMode === UserLoginMode.PasswordEntry
            ? (0, lit_html_1.html) `
                                  <jo-button
                                      ${(0, test_id_directive_1.testId)('login-button')}
                                      .type=${'LOGIN_BUTTON'}
                                      .text=${`Login to Jill's Office`}
                                      @joButtonClick=${() => this.loginClick()}
                                  ></jo-button>
                              `
            : ''}
                        ${state.userLoginMode === UserLoginMode.MFACodeEntry
            ? (0, lit_html_1.html) `
                                  <jo-button
                                      .type=${'LOGIN_BUTTON'}
                                      .text=${`Verify MFA Code`}
                                      @joButtonClick=${() => {
                if (state.twoFACode && state.user)
                    this.initiateMFACodeVerification(state.twoFACode, state.user);
            }}
                                  ></jo-button>
                              `
            : ''}
                    </div>
                </section>

                <section class="jo-login--footer">
                    <div></div>

                    <div>
                        <a
                            ${(0, test_id_directive_1.testId)('login-forgot-password')}
                            class="jo-login--link"
                            @click=${() => (0, utilities_1.navigate)(`/forgot-password`)}
                        >
                            Forgot Password?
                        </a>
                    </div>

                    <div>
                        <a
                            ${(0, test_id_directive_1.testId)('login-privacy-policy')}
                            class="jo-login--link"
                            href="https://www.jillsoffice.com/privacy-policy"
                            target="_blank"
                        >
                            Privacy Policy
                        </a>
                    </div>
                </section>
            </main>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-login', JOLogin);
function storeCredentialsInLocalStorage(userLoginIdentifiers, deviceUuid) {
    window.localStorage.setItem(constants_1.csrfTokenName, userLoginIdentifiers.csrfToken ?? '');
    window.localStorage.setItem(constants_1.clientPublicKeyName, userLoginIdentifiers.clientPublicKey);
    window.localStorage.setItem(constants_1.clientPrivateKeyName, userLoginIdentifiers.clientPrivateKey);
    window.localStorage.setItem('deviceUuid', deviceUuid || '');
}
function navigateToCustomerActionItems(user) {
    window.location.href = `${mapped_env_variables_1.currentMappedEnvVariables.urlOrigin}/customer/action-items?user_token=${user.id}`;
}
function getCompanyIdIfItExists(user) {
    const company = user.companies[0];
    if (company == null)
        return undefined;
    return company.id;
}
function getInvalidMFACodesDetailedMessage(currentInvalidMFAAttemtpsForUser) {
    if (currentInvalidMFAAttemtpsForUser == undefined || isNaN(currentInvalidMFAAttemtpsForUser))
        return 'Error with login. Please contact tech support.';
    if (currentInvalidMFAAttemtpsForUser >= 3)
        return 'Your account is locked. Please contact your manager.';
    return 'Invalid code. Please try again. Note that you only get 3 attempts.';
}
