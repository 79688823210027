"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isInPersonalAdminMode = exports.isPersonalAdmin = void 0;
function isPersonalAdmin(user) {
    if (user === 'NOT_SET')
        return false;
    return user.is_personal_admin;
}
exports.isPersonalAdmin = isPersonalAdmin;
function isInPersonalAdminMode(user) {
    if (user === 'NOT_SET')
        return false;
    return user.in_personal_admin_mode;
}
exports.isInPersonalAdminMode = isInPersonalAdminMode;
