"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const store_1 = require("../../../../services/store");
const utilities_1 = require("../../../../services/utilities");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
require("../../jo-company-services-pricing/jo-company-services-pricing");
const InitialState = {
    authenticatedUser: store_1.InitialGlobalState.authenticatedUser,
    isMobileDevice: false,
    companyId: 'NOT_SET',
};
class JOCustomerCallInstructionsServicePricing extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }
            </style>

            <div class="main-container">
                <jo-company-services-pricing
                    .authenticatedUser=${state.authenticatedUser}
                    .companyId=${state.companyId}
                    .isMobileDevice=${state.isMobileDevice}
                ></jo-company-services-pricing>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-customer-call-instructions-services-pricing', JOCustomerCallInstructionsServicePricing);
