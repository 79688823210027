"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatSidebarSubscriptionsManager = void 0;
class ChatSidebarSubscriptionsManager {
    constructor() {
        this.subscriptions = {};
    }
    async addSubscription(chatId, callbackToCreateSubscription) {
        if (!(chatId in this.subscriptions)) {
            const websocket = await callbackToCreateSubscription();
            this.subscriptions[chatId] = websocket;
        }
    }
    removeSubscription(chatId) {
        this.subscriptions[chatId]?.close();
        delete this.subscriptions[chatId];
    }
    removeAllSubscriptions() {
        for (const chatId in this.subscriptions) {
            this.subscriptions[chatId]?.close();
        }
        this.subscriptions = {};
    }
}
exports.ChatSidebarSubscriptionsManager = ChatSidebarSubscriptionsManager;
