"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../../services/utilities");
const is_company_currently_using_chat_1 = require("../../../../utilities/company-status-events/is-company-currently-using-chat");
const is_company_currently_using_crewCal_1 = require("../../../../utilities/company-status-events/is-company-currently-using-crewCal");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
const test_id_directive_1 = require("../../../directives/test-id.directive");
require("../../jo-button.element");
const InitialState = {
    company: 'NOT_SET',
    inputValues: {
        canceledSelectedValue: false,
        formErrors: [],
        invoiceEmailInputValue: '',
        onHoldSelectedValue: false,
        stripeIdInputValue: '',
    },
    loading: true,
    showAllFormErrors: false,
};
class JOCompanyBillingInfo extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    set company(company) {
        if (this.store.company === company) {
            return;
        }
        this.store.company = company;
        if (company === 'NOT_SET') {
            this.inputValues = InitialState.inputValues;
        }
        else {
            this.inputValues = {
                canceledSelectedValue: company.canceled,
                formErrors: [],
                invoiceEmailInputValue: company.invoice_email ?? '',
                onHoldSelectedValue: company.on_hold ?? '',
                stripeIdInputValue: company.credit_card_key ?? '',
            };
        }
        this.store.loading = false;
    }
    set inputValues(inputValues) {
        this.store.inputValues = inputValues;
        (0, utilities_1.raiseCustomEvent)(this, 'companybillinginfoinputvalueschanged', inputValues);
    }
    async handleOptOutOfCrewCal() {
        const shouldContinue = await (0, utilities_1.joConfirm)('Notice', 'Are you sure you want to opt out of CrewCal?');
        if (shouldContinue === false) {
            return;
        }
        (0, utilities_1.raiseCustomEvent)(this, 'optOutOfCrewCal');
    }
    async handleEnrollInChat() {
        const shouldContinue = await (0, utilities_1.joConfirm)('Notice', 'Are you sure you want to enroll in Chat?');
        if (shouldContinue === false) {
            return;
        }
        (0, utilities_1.raiseCustomEvent)(this, 'enrollInChat');
    }
    async handleOptOutOfChat() {
        const shouldContinue = await (0, utilities_1.joConfirm)('Notice', 'Are you sure you want to opt out of Chat?');
        if (shouldContinue === false) {
            return;
        }
        (0, utilities_1.raiseCustomEvent)(this, 'optOutOfChat');
    }
    async handleEnrollInCrewCal() {
        const shouldContinue = await (0, utilities_1.joConfirm)('Notice', 'Are you sure you want to enroll in CrewCal?');
        if (shouldContinue === false) {
            return;
        }
        (0, utilities_1.raiseCustomEvent)(this, 'enrollInCrewCal');
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .jo-company-billing-info--chat-widget-button {
                    padding-left: 1rem;
                    padding-top: 0.5rem;
                }
            </style>

            <div class="jo-company-billing-info--main-container">
                <div class="jo-company-details--info-card">
                    <div class="jo-company-details--info-card-header-container">
                        <h3 class="jo-company-details--info-card-header">BILLING INFO</h3>
                    </div>

                    <div class="jo-company-details--info-card-body-content">
                        <div class="jo-company-details--info-card-row">
                            <jo-input
                                .label=${'Stripe Customer ID'}
                                .type=${'TEXT_INPUT'}
                                .inputValue=${state.inputValues.stripeIdInputValue}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                stripeIdInputValue: e.detail.inputValue,
            };
        }}
                            ></jo-input>

                            <jo-input
                                .label=${'Invoice Email'}
                                .type=${'EMAIL'}
                                .inputValue=${state.inputValues.invoiceEmailInputValue}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                invoiceEmailInputValue: e.detail.inputValue,
                formErrors: e.detail.formErrors,
            };
        }}
                                .formError=${state.inputValues.formErrors}
                                .showError=${state.showAllFormErrors}
                            ></jo-input>
                        </div>

                        <div class="jo-company-details--info-card-row">
                            <div class="jo-company-details--info-card-row">
                                <div class="jo-global--align-self-end">
                                    ${isCompanyEnrolledInCrewCal(state.company)
            ? (0, lit_html_1.html) `
                                              <jo-button
                                                  .type=${'DELETE_BUTTON_2'}
                                                  .text=${'Opt out of CrewCal '}
                                                  ${(0, test_id_directive_1.testId)('opt-out-of-crewcal-button')}
                                                  @joButtonClick=${() => this.handleOptOutOfCrewCal()}
                                              ></jo-button>
                                          `
            : (0, lit_html_1.html) `
                                              <jo-button
                                                  .type=${'ACTION_BUTTON_GREEN_2'}
                                                  .text=${'Enroll in CrewCal'}
                                                  ${(0, test_id_directive_1.testId)('enroll-in-crewcal-button')}
                                                  @joButtonClick=${() => this.handleEnrollInCrewCal()}
                                              ></jo-button>
                                          `}
                                </div>
                            </div>
                        </div>

                        <div class="jo-company-details--info-card-row">
                            <div class="jo-company-details--info-card-row">
                                <div class="jo-global--align-self-end">
                                    ${isCompanyEnrolledInChatServices(state.company)
            ? (0, lit_html_1.html) `
                                              <jo-button
                                                  .type=${'DELETE_BUTTON_2'}
                                                  .text=${'Opt out of Chat '}
                                                  @joButtonClick=${() => this.handleOptOutOfChat()}
                                              ></jo-button>
                                          `
            : (0, lit_html_1.html) `
                                              <jo-button
                                                  ${(0, test_id_directive_1.testId)('company-enroll-in-chat-button')}
                                                  .type=${'ACTION_BUTTON_GREEN_2'}
                                                  .text=${'Enroll in Chat'}
                                                  @joButtonClick=${() => this.handleEnrollInChat()}
                                              ></jo-button>
                                          `}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-billing-info', JOCompanyBillingInfo);
function isCompanyEnrolledInCrewCal(company) {
    return company !== 'NOT_SET' && (0, is_company_currently_using_crewCal_1.isCompanyCurrentlyUsingCrewCal)(company.status_events) === true;
}
function isCompanyEnrolledInChatServices(company) {
    return company !== 'NOT_SET' && (0, is_company_currently_using_chat_1.isCompanyCurrentlyUsingChat)(company.status_events) === true;
}
