"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const store_1 = require("../../../services/store");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
require("../jo-button.element");
const InitialState = {
    mobileTopBarTitle: store_1.InitialGlobalState.mobileTopBarTitle,
};
class JOCustomerTopBar extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: calc(40px + 1vh);
                    width: 100%;
                    box-sizing: border-box;
                    display: flex;
                    align-content: center;
                    background-color: var(--jo-primary);
                    top: 0;
                    position: fixed;
                    overflow: hidden;
                    z-index: 10;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    text-align: center;
                }

                button {
                    transform: scale(2);
                }

                .left-section {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 1.5rem;
                    flex-basis: 30%;
                }

                .title-section {
                    color: white;
                    font-size: calc(1rem + 1vmin);
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-basis: 45%;
                    flex-grow: 1;
                }

                .right-section {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    flex-basis: 25%;
                }
            </style>

            <main class="main-container">
                <section class="left-section">
                    <jo-button
                        .type=${'ICON'}
                        .icon=${'arrow_back'}
                        .inlineStyle=${'color: white; font-size: 2rem'}
                        @joButtonClick=${() => {
            this.navigateBack();
        }}
                    ></jo-button>

                    <jo-button
                        .type=${'ICON'}
                        .icon=${'refresh'}
                        .inlineStyle=${'color: white; font-size: 2rem'}
                        @joButtonClick=${() => {
            window.location.reload();
        }}
                    ></jo-button>
                </section>

                <section class="title-section">
                    <div>${state.mobileTopBarTitle}</div>
                </section>

                <section class="right-section">
                    <jo-button
                        .type=${'ICON'}
                        .icon=${'menu'}
                        style="align-self: center;"
                        .inlineStyle=${'color: white; font-size: 2rem'}
                        @joButtonClick=${() => {
            (0, utilities_1.raiseSetterAction)(this, 'showSidebar', true);
        }}
                    ></jo-button>
                </section>
            </main>
        `;
    }
    navigateBack() {
        if (window.history.length > 1) {
            window.history.back();
        }
        else if (window.location.pathname === '/customer/action-item') {
            (0, utilities_1.navigate)('customer/action-items');
        }
        else if (window.location.pathname === '/customer/contact') {
            (0, utilities_1.navigate)('customer/contact-list');
        }
        else {
            (0, utilities_1.navigate)('/');
        }
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-customer-top-bar', JOCustomerTopBar);
