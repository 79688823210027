"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const html_sanitization_1 = require("../../../utilities/html-sanitization");
require("../jo-button.element");
require("../jo-input");
const queries_and_mutations_1 = require("./queries-and-mutations");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    companyId: 'NOT_SET',
    company: 'NOT_SET',
    isMobileDevice: false,
    loading: true,
    saving: false,
    currentImportantNotes: 'NOT_SET',
};
class JOCompanyCallSectionNotes extends HTMLElement {
    constructor() {
        super();
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
        this.transferNotesEditor = {};
        this.servicesNotesEditor = {};
        this.customQuestionNotesEditor = {};
        this.pricingNotesEditor = {};
    }
    set companyId(companyId) {
        const previousCompanyId = this.store.companyId;
        this.store.companyId = companyId;
        if (companyId !== previousCompanyId && companyId !== 'NOT_SET') {
            this.handleFetchAndSetSectionNotes();
        }
    }
    async handleFetchAndSetSectionNotes() {
        this.store.loading = true;
        await this.fetchAndSetCompanySectionNotes();
        await this.initiateSectionNotesTextEditors();
        this.store.loading = false;
    }
    async fetchAndSetCompanySectionNotes() {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError);
        const result = await (0, queries_and_mutations_1.fetchCompanySectionNotes)(this.store.companyId);
        (0, utilities_1.assertSucceeded)(result, utilities_1.handleError);
        const company = result.value;
        const callScreenSectionNotes = company.call_screen_section_notes[0] ?? makeGenericCallScreenSectionNotes();
        this.store.currentImportantNotes = {
            id: callScreenSectionNotes.id,
            transferNotes: callScreenSectionNotes.transfer_notes,
            servicesNotes: callScreenSectionNotes.services_notes,
            customQuestionNotes: callScreenSectionNotes.custom_questions_notes,
            pricingNotes: callScreenSectionNotes.pricing_notes,
        };
    }
    async initiateSectionNotesTextEditors() {
        this.setUpTextEditor('#transfers-text-editor', 'transferNotesEditor');
        this.setUpTextEditor('#services-text-editor', 'servicesNotesEditor');
        this.setUpTextEditor('#custom-questions-text-editor', 'customQuestionNotesEditor');
        this.setUpTextEditor('#pricing-text-editor', 'pricingNotesEditor');
        this.resetTextEditors();
    }
    setUpTextEditor(elementIdSelectorString, editorName) {
        window.tinymce.init({
            target: this.querySelector(elementIdSelectorString),
            plugins: [
                'link',
                'lists',
            ],
            default_link_target: '_blank',
            extended_valid_elements: 'a[href|target=_blank]',
            toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | table | link bullist numlist underline backcolor',
            menubar: false,
            setup: (editor) => (this[editorName] = editor),
            readonly: 0,
            height: '100%',
        });
    }
    async handleSaveError(joFaiure) {
        (0, utilities_1.joAlert)('Error', joFaiure.userMessage || 'There was an error');
        this.resetTextEditors();
        throw new Error(joFaiure.developerMessage);
    }
    async saveButtonClicked() {
        this.store.saving = true;
        const upsertResults = await this.upsertSectionNotes();
        this.store.saving = false;
        (0, utilities_1.assertSucceeded)(upsertResults);
        (0, utilities_1.joAlert)('Success', 'Updated successfully');
    }
    async upsertSectionNotes() {
        (0, utilities_1.assertIsSet)(this.store.companyId);
        (0, utilities_1.assertIsSet)(this.store.currentImportantNotes);
        const upsertResults = await (0, queries_and_mutations_1.upsertCompanySectionNotes)({
            companyId: this.store.companyId,
            callScreenSectionNotesId: this.store.currentImportantNotes.id,
            transferNotes: this.transferNotesEditor.getContent(),
            servicesNotes: this.servicesNotesEditor.getContent(),
            pricingNotes: this.pricingNotesEditor.getContent(),
            customQuestionsNotes: this.customQuestionNotesEditor.getContent(),
        });
        return upsertResults;
    }
    resetTextEditors() {
        if (this.store.currentImportantNotes !== 'NOT_SET') {
            this.transferNotesEditor.setContent(this.store.currentImportantNotes.transferNotes);
            this.pricingNotesEditor.setContent(this.store.currentImportantNotes.pricingNotes);
            this.customQuestionNotesEditor.setContent(this.store.currentImportantNotes.customQuestionNotes);
            this.servicesNotesEditor.setContent(this.store.currentImportantNotes.servicesNotes);
        }
        else {
            this.transferNotesEditor.setContent('');
            this.pricingNotesEditor.setContent('');
            this.customQuestionNotesEditor.setContent('');
            this.servicesNotesEditor.setContent('');
        }
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .jo-company-call-section-notes--main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }
                .jo-company-call-section-notes--label-title {
                    color: var(--jo-primary);
                    font-weight: bold;
                    font-size: ${state.isMobileDevice ? `0.8rem` : `1rem`};
                    padding: 1rem 1rem 0.5rem 1rem;
                }
                .jo-company-call-section-notes--text-area-container {
                    overflow: hidden;
                    padding: 0rem 1rem 0rem 1rem;
                    height: 250px;
                    margin-right: ${state.isMobileDevice ? '1rem' : '8rem'};
                }
                .jo-company-call-section-notes--row-spacing {
                    margin-bottom: 1rem;
                }
                .jo-company-call-section-notes--save-button-container {
                    display: flex;
                    align-items: center;
                    margin-top: 2rem;
                    margin-right: 2rem;
                }
                .jo-company-call-section-notes--text-editor {
                    height: 100px;
                }
                .jo-company-call-section-notes--save-button {
                    margin-right: 1rem;
                }
            </style>

            <div class="jo-company-call-section-notes--main-container">
                <div class="jo-global--card">
                    <div class="jo-global--card-title">CALL SECTION NOTES</div>
                    <div class="flex-col">
                        <section class="flex-row f1 jo-company-call-section-notes--row-spacing">
                            <div class="f4">
                                <div class="jo-company-call-section-notes--label-title">
                                    Services
                                </div>
                                <div class="jo-company-call-section-notes--text-area-container">
                                    <div
                                        id="services-text-editor"
                                        class="jo-company-call-section-notes--text-editor"
                                    >
                                        ${(0, html_sanitization_1.renderHTMLStringSafely)(this.store.currentImportantNotes !== 'NOT_SET'
            ? this.store.currentImportantNotes.servicesNotes
            : '')}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section class="flex-row f1 jo-company-call-section-notes--row-spacing">
                            <div class="f4">
                                <div class="jo-company-call-section-notes--label-title">
                                    Transfers
                                </div>
                                <div class="jo-company-call-section-notes--text-area-container">
                                    <div
                                        id="transfers-text-editor"
                                        class="jo-company-call-section-notes--text-editor"
                                    >
                                        ${(0, html_sanitization_1.renderHTMLStringSafely)(this.store.currentImportantNotes !== 'NOT_SET'
            ? this.store.currentImportantNotes.transferNotes
            : '')}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section class="flex-row f1 jo-company-call-section-notes--row-spacing">
                            <div class="f4">
                                <div class="jo-company-call-section-notes--label-title">
                                    Custom Questions
                                </div>
                                <div class="jo-company-call-section-notes--text-area-container">
                                    <div
                                        id="custom-questions-text-editor"
                                        class="jo-company-call-section-notes--text-editor"
                                    >
                                        ${(0, html_sanitization_1.renderHTMLStringSafely)(this.store.currentImportantNotes !== 'NOT_SET'
            ? this.store.currentImportantNotes
                .customQuestionNotes
            : '')}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section class="flex-row f1 jo-company-call-section-notes--row-spacing">
                            <div class="f4">
                                <div class="jo-company-call-section-notes--label-title">
                                    Pricing
                                </div>
                                <div class="jo-company-call-section-notes--text-area-container">
                                    <div
                                        id="pricing-text-editor"
                                        class="jo-company-call-section-notes--text-editor"
                                    >
                                        ${(0, html_sanitization_1.renderHTMLStringSafely)(this.store.currentImportantNotes !== 'NOT_SET'
            ? this.store.currentImportantNotes.pricingNotes
            : '')}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section class="flex-row f1 jo-company-call-section-notes--row-spacing">
                            <div class="f4"></div>
                            <div class="jo-company-call-section-notes--save-button-container">
                                <div class="jo-company-call-section-notes--save-button">
                                    <jo-button
                                        .type=${'ACTION_BUTTON_1'}
                                        .text=${state.saving === true ? 'Saving...' : 'Save'}
                                        .disabled=${state.saving === true}
                                        @joButtonClick=${() => this.saveButtonClicked()}
                                    ></jo-button>
                                </div>

                                <jo-button
                                    .type=${'SECONDARY_BUTTON_1'}
                                    .text=${'Cancel'}
                                    .disabled=${state.saving === true}
                                    @joButtonClick=${() => this.resetTextEditors()}
                                ></jo-button>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-call-section-notes', JOCompanyCallSectionNotes);
function getCallScreenSectionNotesFromResult(company) {
    if (company.call_screen_section_notes[0]) {
        return company.call_screen_section_notes[0];
    }
    return makeGenericCallScreenSectionNotes();
}
function makeGenericCallScreenSectionNotes() {
    return {
        id: -1,
        custom_questions_notes: '',
        pricing_notes: '',
        services_notes: '',
        transfer_notes: '',
    };
}
