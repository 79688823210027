"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHtmlToShowWhoEndedCall = void 0;
const lit_1 = require("lit");
function getHtmlToShowWhoEndedCall(direction, twilio_reason_conference_ended) {
    if (!twilio_reason_conference_ended)
        return (0, lit_1.html) `
            ${direction}
        `;
    return (0, lit_1.html) `
        <style>
            .ended-call-icon {
                font-size: 1.3rem;
                color: var(--jo-primary);
                cursor: help;
            }
        </style>

        <span
            title="${twilio_reason_conference_ended === 'CONFERENCE_ENDED_VIA_API'
        ? 'Jill'
        : 'Customer'} likely ended the call."
            class="material-icons ended-call-icon"
        >
            ${getIconToShowWhoEndedTheCall(twilio_reason_conference_ended)}
        </span>

        ${direction}
    `;
}
exports.getHtmlToShowWhoEndedCall = getHtmlToShowWhoEndedCall;
function getIconToShowWhoEndedTheCall(twilio_reason_conference_ended) {
    if (twilio_reason_conference_ended === 'CONFERENCE_ENDED_VIA_API')
        return `support_agent`;
    if (twilio_reason_conference_ended === 'LAST_PARTICIPANT_LEFT')
        return `voice_over_off`;
    return '';
}
