"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../services/graphql");
const utilities_1 = require("../../services/utilities");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../utilities/environment/mapped-env-variables");
require("./jo-button.element");
require("./jo-input");
require("./jo-table");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    minutesPackages: [],
    searchText: '',
};
class JOCallCalculationsPackages extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
        this.handleGetCallCalulationsPackages = (async (offset, limit, searchText) => {
            return await getCallCalculationsPackages(offset, limit, searchText);
        }).bind(this);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .table-container {
                    box-sizing: border-box;
                    font-size: 1rem;
                    padding: 1rem;
                    background-color: white;
                    box-shadow: var(--jo-card-shadow-1);
                    border-radius: var(--jo-wide-border-radius-1);
                }
            </style>

            <div class="main-container">
                <div class="table-container">
                    <jo-table
                        .tableName=${'Minutes Calculations Options'}
                        .maxItemsPerPage=${50}
                        .getItems=${this.handleGetCallCalulationsPackages}
                        .columns=${getMinutesPackagesColumns()}
                    ></jo-table>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-call-calculations-packages', JOCallCalculationsPackages);
async function getCallCalculationsPackages(offset, limit, searchText) {
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
            query ($filter: Minutes_calculation_optionsFilter!, $limit: Int!, $offset: Int!) {
                findMinutes_calculation_options(
                    filter: $filter
                    page: {limit: $limit, offset: $offset}
                    orderBy: {field: "id", order: ASC}
                ) {
                    items {
                        id
                        created_at
                        updated_at
                        name
                        max_billable_milliseconds
                        max_billable_action_item_milliseconds
                        min_billable_milliseconds
                        min_milliseconds_call_needs_to_be_billable
                    }
                    total
                }
            }
        `, {
        filter: searchText === ''
            ? {
                name: {
                    ne: searchText,
                },
            }
            : {
                name: {
                    like: searchText,
                },
            },
        limit,
        offset,
    });
    if (gqlResult.succeeded === false) {
        alert('Something went wrong');
        throw new Error('getMinutesPackages error: gqlResult failed');
    }
    const getCallCalculationsPackages = gqlResult.value.data.findMinutes_calculation_options.items;
    return {
        items: getCallCalculationsPackages,
        totalItems: gqlResult.value.data.findMinutes_calculation_options.total,
    };
}
function getMinutesPackagesColumns() {
    const columns = [
        {
            title: 'Name',
            getCellData: (callCalulationsPackage) => (0, lit_html_1.html) `
                <div style="padding: 1rem 0rem">${callCalulationsPackage.name}</div>
            `,
            width: 1,
        },
        {
            title: 'Max Total Billable Time Per Call',
            getCellData: (callCalulationsPackage) => (0, lit_html_1.html) `
                <div style="padding: 1rem 0rem">
                    ${(0, utilities_1.millisecondsToHoursMinutesSeconds)(callCalulationsPackage.max_billable_milliseconds)}
                </div>
            `,
            width: 1,
        },
        {
            title: 'Max Billable Action Item Time Per Call',
            getCellData: (callCalulationsPackage) => (0, lit_html_1.html) `
                <div style="padding: 1rem 0rem">
                    ${(0, utilities_1.millisecondsToHoursMinutesSeconds)(callCalulationsPackage.max_billable_action_item_milliseconds)}
                </div>
            `,
            width: 1,
        },
        {
            title: 'Minium Time Call Needs To Be Billable',
            getCellData: (callCalulationsPackage) => (0, lit_html_1.html) `
                <div style="padding: 1rem 0rem">
                    ${(0, utilities_1.millisecondsToHoursMinutesSeconds)(callCalulationsPackage.min_milliseconds_call_needs_to_be_billable)}
                </div>
            `,
            width: 1,
        },
        {
            title: 'Minimum Time Billed For Calls',
            getCellData: (callCalulationsPackage) => (0, lit_html_1.html) `
                <div style="padding: 1rem 0rem">
                    ${(0, utilities_1.millisecondsToHoursMinutesSeconds)(callCalulationsPackage.min_billable_milliseconds)}
                </div>
            `,
            width: 1,
        },
    ];
    return columns;
}
