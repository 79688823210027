"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../../services/utilities");
const is_line_item_allowed_to_be_edited_1 = require("../../../../utilities/invoice-line-items/is-line-item-allowed-to-be-edited");
require("../../jo-button.element");
require("../../jo-input");
const helper_utils_1 = require("./helper-utils");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    amountInputValue: '0.00',
    descriptionInputValue: '',
    invoiceLineItem: 'NOT_SET',
    mode: 'READ',
    isMobileDevice: false,
};
const templateOptions = [
    'Activation Fee',
    'Affiliate Payout - Thank you!',
    'Call Credit',
    'Late Fee',
    'Monthly Fee',
    'Past Due',
    'Proration',
    'Usage',
    'Other',
];
class JOInvoiceLineItem extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        this.handleSetInitialInputValues();
    }
    handleSetInitialInputValues() {
        if (this.store.invoiceLineItem !== 'NOT_SET') {
            this.store.amountInputValue = (0, utilities_1.convertCentsToDollars)(this.store.invoiceLineItem.amount || 0).toString();
            this.store.descriptionInputValue = this.store.invoiceLineItem.description || '';
        }
    }
    handleSaveButtonClicked() {
        if (this.isTryingToCreateLineItemThatIsNotAllowed()) {
            (0, utilities_1.joAlert)('Not Allowed', `Cannot create "${this.store.descriptionInputValue}" line item. These may only be generated by the backend`);
            return;
        }
        (0, utilities_1.assertIsSet)(this.store.invoiceLineItem, utilities_1.handleError, 'this.store.invoiceLineItem');
        (0, utilities_1.raiseCustomEvent)(this, 'invoiceLineItemSaveClicked', {
            invoiceLineItemId: this.store.invoiceLineItem.id,
            description: this.store.descriptionInputValue,
            amountInCents: (0, utilities_1.formatMoneyInUSDCents)(Number(this.store.amountInputValue)),
        });
    }
    isTryingToCreateLineItemThatIsNotAllowed() {
        return (this.store.mode === 'CREATE' &&
            !(0, is_line_item_allowed_to_be_edited_1.isLineItemAllowedToBeCreatedOrEdited)(this.store.descriptionInputValue));
    }
    handleCancelButtonClicked() {
        (0, utilities_1.raiseCustomEvent)(this, 'invoiceLineItemCancelClicked');
        this.store.mode = 'READ';
    }
    handleDeleteButtonClicked() {
        (0, utilities_1.assertIsSet)(this.store.invoiceLineItem, utilities_1.handleError, 'this.store.invoiceLineItem');
        (0, utilities_1.raiseCustomEvent)(this, 'invoiceLineItemDeleteClicked', {
            invoiceLineItemId: this.store.invoiceLineItem.id,
        });
    }
    render(state) {
        const isAllowedToUpdate = (0, utilities_1.isInAuthRole)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser);
        if (state.invoiceLineItem === 'NOT_SET') {
            return (0, lit_html_1.html) `
                <div>Loading...</div>
            `;
        }
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .jo-invoice-line-item--main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .jo-invoice-line-item--main-container input {
                    width: ${state.isMobileDevice ? `20rem` : `220px`};
                }

                .jo-invoice-line-item--main-container select {
                    width: ${state.isMobileDevice ? `20rem` : `220px`};
                }

                .jo-invoice-line-item--line-item-container {
                    display: flex;
                    align-items: center;
                    font-size: ${state.isMobileDevice ? `0.75rem` : `1rem`};
                    flex-direction: ${state.isMobileDevice && state.mode !== 'READ'
            ? `column`
            : `row`};
                }

                .jo-invoice-line-item--column {
                    flex: ${state.isMobileDevice ? `0 0 115px` : `0 0 250px`};
                    text-align: ${state.isMobileDevice ? `center` : `left`};
                }
                .jo-invoice-line-item--column-input {
                    flex: ${state.isMobileDevice ? `0 0 3rem` : `0 0 250px`};
                    text-align: ${state.isMobileDevice ? `center` : `left`};
                }

                .jo-invoice-line-item--buttons-container {
                    display: flex;
                    padding-left: ${state.isMobileDevice ? `1rem` : `0`};
                }

                .jo-invoice-line-item--button-padding {
                    padding: 0rem 0.5rem;
                }
            </style>

            <div class="jo-invoice-line-item--main-container">
                <div class="jo-invoice-line-item--line-item-container">
                    ${state.mode === 'READ'
            ? (0, lit_html_1.html) `
                              <div class="jo-invoice-line-item--column">
                                  ${state.invoiceLineItem.description}
                              </div>
                          `
            : (0, lit_html_1.html) `
                              <div class="jo-invoice-line-item--column-input">
                                  <jo-input
                                      .type=${'TEXT_INPUT'}
                                      .inputValue=${state.descriptionInputValue}
                                      @inputchange=${(e) => (this.store.descriptionInputValue = e.detail.inputValue)}
                                  ></jo-input>
                              </div>
                          `}
                    ${state.mode === 'READ'
            ? (0, lit_html_1.html) `
                              <div class="jo-invoice-line-item--column">
                                  ${(0, helper_utils_1.getAmountDueForInvoiceLineItemString)(state.invoiceLineItem)}
                              </div>
                          `
            : (0, lit_html_1.html) `
                              <div class="jo-invoice-line-item--column-input">
                                  <jo-input
                                      .type=${'NUMBER'}
                                      .inputValue=${state.amountInputValue}
                                      @inputchange=${(e) => (this.store.amountInputValue = e.detail.inputValue)}
                                  ></jo-input>
                              </div>
                          `}

                    <div
                        ?hidden=${isAllowedToUpdate === false}
                        class="jo-invoice-line-item--column-input jo-invoice-line-item--buttons-container"
                    >
                        <div
                            class="jo-invoice-line-item--button-padding"
                            ?hidden=${state.mode === 'READ'}
                        >
                            <jo-button
                                .text=${'Save'}
                                .type=${'ACTION_BUTTON_1'}
                                @joButtonClick=${() => this.handleSaveButtonClicked()}
                            ></jo-button>
                        </div>

                        <div
                            class="jo-invoice-line-item--button-padding"
                            ?hidden=${state.mode === 'READ'}
                        >
                            <jo-button
                                .text=${'Cancel'}
                                .type=${'SECONDARY_BUTTON_1'}
                                @joButtonClick=${() => this.handleCancelButtonClicked()}
                            ></jo-button>
                        </div>

                        <div
                            class="jo-invoice-line-item--button-padding"
                            ?hidden=${isLineItemAllowedToBeEdited(state) === false}
                        >
                            <span
                                class="material-icons jo-global--action-icon"
                                @click=${() => (this.store.mode = 'EDIT')}
                            >
                                edit
                            </span>
                        </div>

                        <div ?hidden=${isLineItemAllowedToBeEdited(state) === false}>
                            <span
                                class="material-icons jo-global--action-icon"
                                @click=${() => this.handleDeleteButtonClicked()}
                            >
                                delete
                            </span>
                        </div>
                    </div>
                </div>

                ${state.mode === 'CREATE'
            ? (0, lit_html_1.html) `
                          <div class="pt-4" ?hidden=${state.mode !== 'CREATE'}>
                              <jo-input
                                  .label=${'Template'}
                                  .type=${'SELECT'}
                                  .selectOptions=${templateOptions}
                                  .defaultOption=${'Select template...'}
                                  .disableDefaultOption=${true}
                                  @inputchange=${(e) => (this.store.descriptionInputValue = e.detail.selectedValue)}
                              ></jo-input>
                          </div>
                      `
            : ''}
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-invoice-line-item', JOInvoiceLineItem);
function isLineItemAllowedToBeEdited(state) {
    return (state.mode === 'READ' && (0, is_line_item_allowed_to_be_edited_1.isLineItemAllowedToBeCreatedOrEdited)(state.descriptionInputValue));
}
