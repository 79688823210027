"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../services/graphql");
const utilities_1 = require("../../services/utilities");
require("./jo-button.element");
require("./jo-input");
const store_1 = require("../../services/store");
const form_error_manager_1 = require("../../classes/form-error-manager");
const error_handling_1 = require("../../utilities/error-handling");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../utilities/environment/mapped-env-variables");
const InitialState = {
    jwtToken: 'NOT_SET',
    inputValues: {
        newPassword: '',
        confirmPassword: '',
    },
    isMobileDevice: store_1.InitialGlobalState.isMobileDevice,
    loading: true,
    showAllFormErrors: false,
    formErrorManager: new form_error_manager_1.FormErrorManager(),
};
class JOResetPassword extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    async saveButtonClicked() {
        if (this.store.formErrorManager.getFormErrors().length > 0) {
            this.store.showAllFormErrors = true;
            (0, utilities_1.joAlert)('Alert', 'Please correct errors');
            return;
        }
        (0, utilities_1.assertIsSet)(this.store.inputValues.newPassword, utilities_1.handleError, 'this.store.inputValues.newPassword');
        (0, utilities_1.assertIsSet)(this.store.inputValues.confirmPassword, utilities_1.handleError, 'this.store.inputValues.confirmPassword');
        const shouldContinue = await (0, utilities_1.joConfirm)('Notice', 'Are you sure you want to update your password?');
        if (shouldContinue === false) {
            return;
        }
        await this.handleUpdatePassword();
    }
    async handleUpdatePassword() {
        (0, utilities_1.assertIsSet)(this.store.inputValues.newPassword, utilities_1.handleError, 'this.store.inputValues.newPassword');
        const updatePasswordResult = await updatePassword(this.store.inputValues.newPassword, this.store.jwtToken);
        (0, utilities_1.assertSucceeded)(updatePasswordResult, utilities_1.handleError);
        if (updatePasswordResult.value === 'FAILED_LINK_EXPIRED') {
            await (0, utilities_1.joAlert)('Error', 'Your Link has Expired');
            return;
        }
        else if (updatePasswordResult.value === 'FAILED_PASSWORD_MATCHES_PREVIOUS_PASSWORD') {
            await (0, utilities_1.joAlert)('Error', 'Cannot use previous password, please enter a new one');
            return;
        }
        await (0, utilities_1.joAlert)('Success!', 'Password successfully updated');
        (0, utilities_1.navigate)(`/login`);
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .main-container {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: center;
                    background-color: var(--jo-primary);
                    width: 100%;
                    height: 100%;
                }

                .main-container-mobile {
                    max-width: 100%;
                    align-items: center;
                }

                .main-container-desktop {
                    align-items: center;
                }

                .container {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    background-color: white;
                    padding: calc(25px + 1vmin);
                    border-radius: 10px;
                }

                .container-mobile {
                    width: 100%;
                    box-shadow: 0px 2px 10px rgb(0, 0, 0, 0.2);
                }

                .container-desktop {
                    width: 50%;
                    box-shadow: 0px 3px 15px rgb(0, 0, 0, 0.4);
                }

                .jo-reset-password--info-card-header {
                    color: var(--jo-primary);
                    font-size: 30px;
                }

                @media (max-width: 500px) {
                    .jo-reset-password--info-card-header {
                        font-size: 20px;
                    }
                }

                .jo-reset-password--info-card-row-new {
                    max-width: 500px;
                    width: 100%;
                    margin: 2rem auto 1rem auto;
                }

                .jo-reset-password--info-card-row-confirm {
                    max-width: 500px;
                    width: 100%;
                    margin: 1rem auto 2rem auto;
                }

                .jo-reset-password--save-cancel-container {
                    margin: 0 auto;
                }
            </style>

            <div
                class="main-container ${state.isMobileDevice === true
            ? 'main-container-mobile'
            : 'main-container-desktop'}"
            >
                <div
                    style="${state.isMobileDevice === true
            ? 'margin-top: -1vh'
            : 'margin-top: -10vh;'}"
                >
                    <img src="../images/jo-logo.png" style="max-height: 15vh" />
                </div>

                <br />

                <div
                    class="container ${state.isMobileDevice === true
            ? 'container-mobile'
            : 'container-desktop'}"
                >
                    <div class="jo-reset-password--info-card-header">RESET PASSWORD</div>
                    <div class="jo-reset-password--info-card-row-new">
                        <jo-input
                            .label=${'New Password'}
                            .placeholder=${'New Password'}
                            .showLabel=${false}
                            .type=${'PASSWORD'}
                            .fontSize=${'1.2rem'}
                            .inputValue=${state.inputValues.newPassword}
                            @inputchange=${(e) => {
            this.store.inputValues = {
                ...this.store.inputValues,
                newPassword: e.detail.inputValue,
            };
        }}
                            @inputErrorChanged=${(e) => this.store.formErrorManager.addOrRemoveFormError(e.detail)}
                            .showError=${state.showAllFormErrors}
                        ></jo-input>
                    </div>

                    <div class="jo-reset-password--info-card-row-confirm">
                        <jo-input
                            .label=${'Confirm Password'}
                            .placeholder=${'Confirm Password'}
                            .showLabel=${false}
                            .type=${'PASSWORD_CONFIRM'}
                            .fontSize=${'1.2rem'}
                            @inputErrorChanged=${(e) => this.store.formErrorManager.addOrRemoveFormError(e.detail)}
                            .inputValue=${state.inputValues.confirmPassword}
                            .required=${true}
                            .showErrorParentOverride:
                            state.showAllFormErrors
                            .passwordToCompareTo=${state.inputValues.newPassword}
                            @inputchange=${(e) => {
            this.store.inputValues = {
                ...this.store.inputValues,
                confirmPassword: e.detail.inputValue,
            };
        }}
                            .showError=${state.showAllFormErrors}
                        ></jo-input>
                    </div>

                    <div class="jo-reset-password--save-cancel-container">
                        <jo-button
                            style="padding-right: 1rem"
                            .type=${'ACTION_BUTTON_1'}
                            .text=${'Save'}
                            @joButtonClick=${() => this.saveButtonClicked()}
                        ></jo-button>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-reset-password', JOResetPassword);
async function updatePassword(newPassword, encodedToken) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($newPassword: String!, $encodedToken: String!) {
                    resetPasswordFromLink(newPassword: $newPassword, encodedToken: $encodedToken)
                }
            `, {
            newPassword: newPassword,
            encodedToken: encodedToken,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        const resetPasswordFromLinkResult = gqlResult.value.data.resetPasswordFromLink;
        return {
            succeeded: true,
            value: resetPasswordFromLinkResult,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('updatePassword', error);
    }
}
