"use strict";
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _JOTableManager_offset, _JOTableManager_items, _JOTableManager_totalItems, _JOTableManager_maxItemsPerPage, _JOTableManager_columnsGetter, _JOTableManager_selectedColumn;
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOTableManager = void 0;
const utilities_1 = require("../services/utilities");
class JOTableManager {
    constructor(params) {
        _JOTableManager_offset.set(this, void 0);
        _JOTableManager_items.set(this, void 0);
        _JOTableManager_totalItems.set(this, void 0);
        _JOTableManager_maxItemsPerPage.set(this, void 0);
        _JOTableManager_columnsGetter.set(this, void 0);
        _JOTableManager_selectedColumn.set(this, void 0);
        __classPrivateFieldSet(this, _JOTableManager_offset, 0, "f");
        __classPrivateFieldSet(this, _JOTableManager_items, [], "f");
        __classPrivateFieldSet(this, _JOTableManager_totalItems, 0, "f");
        __classPrivateFieldSet(this, _JOTableManager_maxItemsPerPage, params?.maxItemsPerPage ?? 10, "f");
        __classPrivateFieldSet(this, _JOTableManager_columnsGetter, params?.columnsGetter ??
            function () {
                return [];
            }, "f");
        __classPrivateFieldSet(this, _JOTableManager_selectedColumn, null, "f");
    }
    getColumns(...args) {
        const columns = __classPrivateFieldGet(this, _JOTableManager_columnsGetter, "f").call(this, ...args);
        if (__classPrivateFieldGet(this, _JOTableManager_selectedColumn, "f")) {
            return updateColumnDirections(columns, __classPrivateFieldGet(this, _JOTableManager_selectedColumn, "f"));
        }
        return columns;
    }
    setOffset(newOffset) {
        __classPrivateFieldSet(this, _JOTableManager_offset, newOffset, "f");
        return this;
    }
    setItems(items) {
        __classPrivateFieldSet(this, _JOTableManager_items, items, "f");
        return this;
    }
    setTotalItems(totalItems) {
        __classPrivateFieldSet(this, _JOTableManager_totalItems, totalItems, "f");
        return this;
    }
    setSelectedColumn(column) {
        __classPrivateFieldSet(this, _JOTableManager_selectedColumn, column, "f");
        return this;
    }
    async downloadCSV(params) {
        const csv = await (0, utilities_1.generateCSVForJoTable)({
            columns: params.columns,
            getItems: params.itemsGetter,
            items: __classPrivateFieldGet(this, _JOTableManager_items, "f"),
            offset: __classPrivateFieldGet(this, _JOTableManager_offset, "f"),
            limit: __classPrivateFieldGet(this, _JOTableManager_maxItemsPerPage, "f"),
            searchString: params.searchText,
            selectedColumn: __classPrivateFieldGet(this, _JOTableManager_selectedColumn, "f") || 'NOT_SET',
        });
        if (csv === 'NOT_SET') {
            return;
        }
        (0, utilities_1.downloadCSV)(csv, params.fileName);
    }
    get() {
        return this;
    }
    get offset() {
        return __classPrivateFieldGet(this, _JOTableManager_offset, "f");
    }
    get items() {
        return __classPrivateFieldGet(this, _JOTableManager_items, "f");
    }
    get totalItems() {
        return __classPrivateFieldGet(this, _JOTableManager_totalItems, "f");
    }
    get maxItemsPerPage() {
        return __classPrivateFieldGet(this, _JOTableManager_maxItemsPerPage, "f");
    }
    get selectedColumn() {
        return __classPrivateFieldGet(this, _JOTableManager_selectedColumn, "f");
    }
}
exports.JOTableManager = JOTableManager;
_JOTableManager_offset = new WeakMap(), _JOTableManager_items = new WeakMap(), _JOTableManager_totalItems = new WeakMap(), _JOTableManager_maxItemsPerPage = new WeakMap(), _JOTableManager_columnsGetter = new WeakMap(), _JOTableManager_selectedColumn = new WeakMap();
function updateColumnDirections(columns, selectedColumn) {
    return columns.map(column => {
        if (column.title === selectedColumn.title) {
            return selectedColumn;
        }
        return resetSortDirection(column);
    });
}
function resetSortDirection(column) {
    return {
        ...column,
        sortDirection: 'NOT_SET',
    };
}
