"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../services/utilities");
const define_custom_element_1 = require("../../utilities/define-custom-element");
require("./jo-button.element");
require("./jo-input");
const InitialState = {
    actionItemIsSelected: false,
    allItemsChecked: false,
    columns: [],
    companyId: 'NOT_SET',
    currentPage: 1,
    isCompanyUser: false,
    item: {},
    maxItemsPerPage: 10,
    moveActionItemsSelectedOption: '',
    numPages: 1,
    showSearchBar: true,
    searchDebounce: 0,
    showCheckboxes: false,
    selectedActionItems: [],
    tableName: 'NOT_SET',
};
class JOMobileActionItemsTemplate extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    render(state) {
        const item = state.item;
        const actionItemIsSelected = state.selectedActionItems.some((actionItemInState) => {
            return actionItemInState.id === item.id;
        });
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .jo-mobile-action-items--table-container {
                    box-sizing: border-box;
                    width: 100%;
                    font-size: 0.9rem;
                    padding: 0.5rem;
                    background-color: white;
                    box-shadow: var(--jo-card-shadow-1);
                    border-radius: var(--jo-wide-border-radius-1);
                    margin: 0.25rem 0;
                }

                .jo-mobile-action-items--primary-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 0.25rem 1rem;
                    padding-right: 0.25rem;
                }

                .jo-table--search-container {
                    width: 50%;
                }

                .jo-toggle-select {
                    color: gray;
                    font-size: 0.8rem;
                }
                .jo-mobile-action-item--flex-box-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-end;
                }
                .jo-mobile-action-item--check-wrapper {
                    display: flex;
                    align-items: center;
                }

                .jo-mobile-action-item--customer-name {
                    font-weight: 900;
                }
                .jo-mobile-action-item--flex-row {
                    display: flex;
                    flex-direction: row;
                }
                .jo-mobile-action-item--modified-input select {
                    height: 15px;
                    width: 18px;
                    padding: 0;
                    margin-right: 0.5rem;
                    margin-top: 0.1rem;
                }

                .nav-clearance {
                    min-width: 100%;
                    display: block;
                    height: 5rem;
                }

                .jo-mobile-action-item--search-container-mobile {
                    width: 66%;
                }

                .jo-mobile-action-item--page-numbers-container {
                    box-sizing: border-box;
                    padding-top: 1rem;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                }

                .jo-mobile-action-item--page-number {
                    box-sizing: border-box;
                    height: 2rem;
                    width: 2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid var(--jo-accent-background-dark);
                    border-radius: 2px;
                    color: var(--jo-primary);
                    margin-left: -1px;
                }

                .jo-mobile-action-item--page-number-selected {
                    background-color: var(--jo-primary);
                    box-shadow: var(--jo-box-shadow-2);
                    border: 1px solid var(--jo-primary);
                    color: white;
                    transform: scale(1.05);
                    font-weight: bold;
                }
            </style>

            <div class="main-container">
                <div class="mapped-elements-wrapper">
                    <div class="jo-mobile-action-item--check-wrapper">
                        <div class="jo-mobile-action-items--table-container">
                            <div class="jo-mobile-action-items--primary-row">
                                <div
                                    class="jo-mobile-action-item--customer-name"
                                    @click=${() => {
            (0, utilities_1.navigate)(`/customer/action-item?actionItemId=${item.id}&customerActionItemMode=EDIT`);
        }}
                                >
                                    ${(0, utilities_1.getContactNameForDisplay)(item.contact)}
                                </div>
                                <div class="jo-mobile-action-item--flex-row">
                                    <div>${(0, utilities_1.makeDateTwoChar)(item.updated_at)}</div>
                                    <div class="checkbox-wrapper">
                                        <input
                                            type="checkbox"
                                            .checked=${actionItemIsSelected}
                                            @change=${(event) => {
            (0, utilities_1.raiseCustomEvent)(this, 'actionItemCheckboxSelected', { event, item });
        }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                class="jo-mobile-action-items--primary-row"
                                @click=${() => {
            (0, utilities_1.navigate)(`/customer/action-item?actionItemId=${item.id}&customerActionItemMode=EDIT`);
        }}
                            >
                                <div>${item.title}</div>
                            </div>
                            <div
                                class="jo-mobile-action-items--primary-row"
                                @click=${() => {
            (0, utilities_1.navigate)(`/customer/action-item?actionItemId=${item.id}&customerActionItemMode=EDIT`);
        }}
                            >
                                <div>
                                    ${truncateActionItemNoteBody(item.action_item_notes[0]?.body)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-mobile-action-items-template', JOMobileActionItemsTemplate);
function truncateActionItemNoteBody(noteBody) {
    if (noteBody === undefined)
        return '';
    const beginningOfNoteMaxLength = 95;
    const beginningOfNote = getBeginningOfNote(noteBody, beginningOfNoteMaxLength);
    if (beginningOfNote == null)
        throw new Error('beginningOfNote is null');
    if (beginningOfNote.length === beginningOfNoteMaxLength) {
        return beginningOfNote.trim() + '...';
    }
    return beginningOfNote;
}
function getBeginningOfNote(noteBody, endCharacterPosition) {
    return noteBody.split('Custom Fields')[0]?.substring(0, endCharacterPosition);
}
