"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stylesForChatSideBar = void 0;
const lit_html_1 = require("lit-html");
const css_utils_1 = require("../../../../utilities/css-utils");
const cssName = (0, css_utils_1.cssPrefixer)('jo-chat-sidebar');
const cssNames = (classNames) => classNames.split(' ').map(cssName).join(' ');
exports.stylesForChatSideBar = (() => {
    return (0, lit_html_1.html) `
        <style>
            .${cssNames('main-container')} {
                height: 100%;
                width: 100%;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
            }

            .${cssNames('upperSection')} {
                flex: 1;
                margin-bottom: 0.5rem;
                padding-bottom: 1rem;
                overflow: hidden;
            }

            .${cssNames('lowerSection')} {
                flex: 1;
                overflow: hidden;
            }

            .${cssNames('close-button-container')} {
                display: flex;
                flex-direction: flex-row;
            }

            .${cssNames('close-icon')} {
                color: var(--jo-text-default);
                text-align: right;
                padding-bottom: 0.5rem;
                cursor: pointer;
            }

            .${cssNames('upper-section-title')} {
                flex: 1;
                padding-top: 0.5rem;
            }

            .${cssNames('chat-card-container')} {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                padding: 0.5rem;
                overflow-y: auto;
                max-height: 90%;
                border-radius: var(--jo-wide-border-radius-1);
            }

            .${cssNames('chat-card')} {
                margin: 0.1rem;
            }

            .${cssNames('chat-card')}:hover {
                background-color: var(--jo-primary-light);
                cursor: pointer;
            }

            .${cssNames('selected-chat-card')} {
                border: 3px var(--jo-primary) solid;
            }

            .${cssNames('chat-alert')} {
                flex: 1;
                font-size: 0.7rem;
                color: var(--jo-danger);
            }

            .${cssNames('chat-card-title')} {
                display: flex;
                flex-direction: row;
                padding-bottom: 0.5rem;
            }

            .${cssNames('company-name')} {
                flex: 1;
                font-size: 0.7rem;
            }

            .${cssNames('date-time')} {
                flex: 1;
                font-style: italic;
                font-size: 0.7rem;
                text-align: right;
            }

            .${cssNames('contact-name')} {
                font-weight: bold;
                font-size: 0.9rem;
            }

            .${cssNames('chat-message')} {
                padding-top: 0.4rem;
                color: var(--jo-text-default);
                font-size: 0.9rem;
                text-align: center;
                max-width: 12rem;
                overflow: hidden;
            }

            .${cssNames('empty-chat-container')} {
                color: var(--jo-accent-background-dark);
                text-align: center;
                padding-top: 2rem;
            }

            .${cssNames('empty-chat-message')} {
                font-size: 1rem;
            }

            .${cssNames('release-button-container')} {
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
                display: flex;
                flex-direction: row;
                gap: 1rem;
            }

            .${cssNames('returned-alert')} {
                color: var(--jo-danger);
                padding-right: 0.5rem;
                animation: returned-alert-blink-animation 1s step-start 0s infinite;
            }

            .${cssNames('chat-duration-time')} {
                padding-bottom: 0.7rem;
            }

            .${cssNames('chat-duration-container')} {
                display: flex;
                flex-direction: column;
            }

            .${cssNames('chat-duration-container-title')} {
                font-size: 0.6rem;
            }

            .${cssNames('sms-icon')}.material-icons {
                padding-left: 0.4rem;
                font-size: 1.2rem;
            }

            @keyframes returned-alert-blink-animation {
                50% {
                    opacity: 0;
                }
            }
        </style>
    `;
})();
