"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../../services/graphql");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const jill_queries_gql_1 = require("../../../utilities/jill-utils/jill-queries.gql");
const queries_1 = require("../jo-jill/queries");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
require("../jo-loading-text");
require("../jo-table");
const constants_1 = require("./constants");
const get_columns_1 = require("./get-columns");
const helper_utils_1 = require("./helper-utils");
class JOJillHighLevelReport extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(constants_1.InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    async connectedCallback() {
        this.store.loading = true;
        await this.fetchAndSetAllJillHighLevelStats();
        this.store.loading = false;
    }
    async fetchAndSetAllJillHighLevelStats() {
        this.store.loading = true;
        if (this.store.abortController !== 'NOT_SET') {
            this.store.abortController.abort();
        }
        const { abortController, execute } = (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint);
        this.store.abortController = abortController;
        const jills = await (0, jill_queries_gql_1.fetchJills)();
        const results = [];
        for (let i = 0; i < jills.length; i += constants_1.BATCH_SIZE) {
            this.store.currentOffset = i;
            const jillBatch = jills.slice(i, i + constants_1.BATCH_SIZE);
            const promises = jillBatch.map(async (jill) => await (0, queries_1.fetchJillHighLevelStatses)(execute, jill.id, this.store.statsStartDate, this.store.statsEndDate, this.store.includeTestCompaniesAndPods));
            const promiseResults = await Promise.all(promises);
            for (const promiseResult of promiseResults)
                results.push(promiseResult);
        }
        const formattedResults = results.map(jillHighLevelStats => {
            const matchingJill = jills.find(jill => jill.id === jillHighLevelStats.jillId);
            return {
                ...jillHighLevelStats,
                firstName: matchingJill?.first_name ?? '',
                lastName: matchingJill?.last_name ?? '',
            };
        });
        formattedResults.sort((a, b) => a.firstName?.localeCompare(b.firstName ?? '') ?? 1);
        this.store.allJillsHighLevelStatses = formattedResults;
        this.store.loading = false;
    }
    async statsStartDateInputChanged(e) {
        const newStartDate = new Date(`${e.target.value}T00:00`).toISOString();
        this.store.statsStartDate = newStartDate;
        this.store.previousStatsStartDate = this.store.statsStartDate;
        this.fetchAndSetAllJillHighLevelStats();
    }
    async statsEndDateInputChanged(e) {
        const newEndDate = new Date(`${e.target.value}T00:00`).toISOString();
        this.store.statsEndDate = newEndDate;
        this.store.previousStatsEndDate = this.store.statsEndDate;
        this.fetchAndSetAllJillHighLevelStats();
    }
    async includeTestCompaniesAndPodsInputChanged(e) {
        this.store.includeTestCompaniesAndPods = e.target.checked;
        this.fetchAndSetAllJillHighLevelStats();
    }
    generateCSVFileName() {
        const startDate = (0, utilities_1.formateISODateForFileName)(this.store.statsStartDate);
        const endDate = (0, utilities_1.formateISODateForFileName)(this.store.statsEndDate);
        if (startDate == null)
            throw new Error('startDate is null');
        if (endDate == null)
            throw new Error('endDate is null');
        return `jill-high-level-report-${startDate}-through-${endDate}.csv`;
    }
    async csvButtonClicked() {
        const csv = await (0, utilities_1.generateCSVForJoTable)({
            columns: (0, get_columns_1.getColumns)(),
            getItems: 'NOT_SET',
            items: this.store.allJillsHighLevelStatses,
            offset: 0,
            limit: 10,
            searchString: '',
            selectedColumn: 'NOT_SET',
        });
        (0, utilities_1.downloadCSV)(csv, this.generateCSVFileName());
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .jo-jill-high-level-report--main-container {
                    font-family: sans-serif;
                }

                .jo-jill-high-level-report--dates-container {
                    display: flex;
                    padding-bottom: 2rem;
                }

                .jo-jill-high-level-report--date-container {
                    box-sizing: border-box;
                    padding-right: 2rem;
                }

                .jo-jill-high-level-report--checkbox {
                    margin-top: 1rem;
                }
            </style>

            <div class="jo-jill-high-level-report--main-container">
                <div class="jo-jill-high-level-report--dates-container">
                    <div class="jo-jill-high-level-report--date-container">
                        <label>Start Date</label>
                        <br />
                        <input
                            type="date"
                            class="jo-global--input"
                            .value=${state.statsStartDate.split('T')[0]}
                            @change=${(e) => this.statsStartDateInputChanged(e)}
                        />
                    </div>
                    <div class="jo-jill-high-level-report--date-container">
                        <label>End Date</label>
                        <br />
                        <input
                            type="date"
                            class="jo-global--input"
                            .value=${state.statsEndDate.split('T')[0]}
                            @change=${(e) => this.statsEndDateInputChanged(e)}
                        />
                    </div>

                    <div class="jo-jill-high-level-report--checkbox">
                        <input
                            type="checkbox"
                            .checked=${state.includeTestCompaniesAndPods}
                            @change=${async (e) => {
            await this.includeTestCompaniesAndPodsInputChanged(e);
        }}
                        />
                        &nbsp;Include Test Pods & Companies
                    </div>
                </div>

                ${state.loading === true
            ? (0, lit_html_1.html) `
                          <jo-loading-text
                              .text=${`Fetching Stats for Jills: ${this.store.currentOffset + 1} - ${this.store.currentOffset + constants_1.BATCH_SIZE}`}
                          ></jo-loading-text>
                      `
            : ''}

                <div class="jo-global--card" style="font-size: 0.9rem">
                    <jo-table
                        .tableName=${'Jill High Level Report'}
                        .maxItemsPerPage=${100}
                        .columns=${(0, get_columns_1.getColumns)()}
                        .areItemsSuppliedByParent=${true}
                        .items=${state.allJillsHighLevelStatses}
                        .totalItems=${state.allJillsHighLevelStatses.length}
                        .sortItems=${helper_utils_1.getSortedJillHighLevelStatses}
                        .showCSVButton=${true}
                        .showSearchBar=${false}
                        @downloadCSVClicked=${() => {
            this.csvButtonClicked();
        }}
                    ></jo-table>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-jill-high-level-report', JOJillHighLevelReport);
