"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
require("../jo-button.element");
require("../jo-input");
const InitialState = {
    item: {
        companyId: 'NOT_SET',
        due_date: 'NOT_SET',
        end_date: 'NOT_SET',
        for_month: 'december-2020',
        status: 'NOT_SET',
        id: 4,
        invoice_line_items: [],
        isCompanyUser: false,
        start_date: 'NOT_SET',
        stripe_id: 'NOT_SET',
    },
};
class JOBillingInvoiceHistoryMobileTemplate extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
        this.friendlyStartEndDates = (item) => {
            if (item.start_date && item.end_date) {
                return `${(0, utilities_1.makeDateTwoChar)(item.start_date)} - ${(0, utilities_1.makeDateTwoChar)(item.end_date)}`;
            }
            return item.for_month;
        };
        this.calculateAmountDue = (item) => {
            return item.invoice_line_items.reduce((curr, acc) => {
                return curr + acc.amount;
            }, 0);
        };
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .biling-invoice-history-mobile-template--main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }
                .biling-invoice-history-mobile-template--table-container {
                    box-sizing: border-box;
                    width: 100%;
                    font-size: 0.9rem;
                    padding: 0.5rem;
                    background-color: white;
                    box-shadow: var(--jo-card-shadow-1);
                    border-radius: var(--jo-wide-border-radius-1);
                    margin: 0.75rem 0;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }
            </style>

            <div class="biling-invoice-history-mobile-template--main-container">
                <div class="biling-invoice-history-mobile-template--table-container">
                    <div class="row-cell-1">${this.friendlyStartEndDates(state.item)}</div>
                    <div class="row-cell-2">
                        ${(0, utilities_1.formatUSDCentsForDisplay)(this.calculateAmountDue(state.item))}
                    </div>
                    <div class="row-cell-2">${state.item.status}</div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-billing-invoice-history-mobile-template', JOBillingInvoiceHistoryMobileTemplate);
