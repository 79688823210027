"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeDefaultLunchBreak = exports.makeDefaultScheduledBreak = exports.makeNewDefaultShift = exports.makeBreakString = exports.makeShiftString = exports.hourNumberToString = exports.makeDefaultNewScheduleItemValues = void 0;
function makeDefaultNewScheduleItemValues() {
    return {
        start_day_of_week: 'MONDAY',
        end_day_of_week: 'MONDAY',
        start_hour: 8,
        end_hour: 16,
        start_minute: 0,
        end_minute: 30,
    };
}
exports.makeDefaultNewScheduleItemValues = makeDefaultNewScheduleItemValues;
function hourNumberToString(hourNumber, minute) {
    const hour = getHour(hourNumber);
    const meridiam = hourNumber < 12 ? 'am' : 'pm';
    if (minute === undefined || minute === 0) {
        return hour + meridiam;
    }
    return hour + ':' + getMinuteString(minute) + meridiam;
}
exports.hourNumberToString = hourNumberToString;
function getMinuteString(minute) {
    if (minute < 10)
        return '0' + minute;
    return minute.toString();
}
function getHour(hourNumber) {
    if (hourNumber === 0)
        return 12;
    if (hourNumber > 12)
        return hourNumber - 12;
    return hourNumber;
}
function makeShiftString(scheduleItem) {
    try {
        if (scheduleItem.start_day_of_week === scheduleItem.end_day_of_week) {
            return `${camelCase(scheduleItem.start_day_of_week)}, ${hourNumberToString(scheduleItem.start_hour, scheduleItem.start_minute)} to ${hourNumberToString(scheduleItem.end_hour, scheduleItem.end_minute)}`;
        }
        return `${camelCase(scheduleItem.start_day_of_week)}, ${hourNumberToString(scheduleItem.start_hour, scheduleItem.start_minute)} to ${camelCase(scheduleItem.end_day_of_week)}, ${hourNumberToString(scheduleItem.end_hour, scheduleItem.end_minute)}`;
    }
    catch (error) {
        throw new Error(`makeShiftString error: ${error}, scheduleItem: ${JSON.stringify(scheduleItem)}`);
    }
}
exports.makeShiftString = makeShiftString;
function camelCase(string) {
    return string.split(' ').map(camelCaseWord).join('');
}
function camelCaseWord(word) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}
function makeBreakString(breakItem) {
    return `${hourNumberToString(breakItem.start_hour, breakItem.start_minute)} to ${hourNumberToString(breakItem.end_hour, breakItem.end_minute)}`;
}
exports.makeBreakString = makeBreakString;
let newShiftId = 0;
function makeNewDefaultShift() {
    return {
        id: --newShiftId,
        start_day_of_week: 'MONDAY',
        end_day_of_week: 'MONDAY',
        start_hour: 8,
        end_hour: 16,
        start_minute: 0,
        end_minute: 30,
        scheduled_breaks: [],
        lunch_breaks: [],
        mode: 'CREATE',
    };
}
exports.makeNewDefaultShift = makeNewDefaultShift;
let newScheduledBreakId = 0;
function makeDefaultScheduledBreak(shift) {
    return {
        id: --newScheduledBreakId,
        start_day_of_week: shift.start_day_of_week,
        start_hour: shift.start_hour + 2,
        start_minute: shift.start_minute,
        end_day_of_week: shift.end_day_of_week,
        end_hour: shift.start_hour + 2,
        end_minute: shift.start_minute + 15,
    };
}
exports.makeDefaultScheduledBreak = makeDefaultScheduledBreak;
let newLunchBreakId = 0;
function makeDefaultLunchBreak(shift) {
    return {
        id: --newLunchBreakId,
        start_day_of_week: shift.start_day_of_week,
        start_hour: shift.start_hour + 4,
        start_minute: shift.start_minute,
        end_day_of_week: shift.end_day_of_week,
        end_hour: shift.start_hour + 4,
        end_minute: shift.start_minute + 30,
    };
}
exports.makeDefaultLunchBreak = makeDefaultLunchBreak;
