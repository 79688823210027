"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
require("../jo-button.element");
const InitialState = {
    clickHandler: 'NOT_SET',
    href: '',
    iconName: '',
    text: '',
    selected: false,
};
class JOCustomerSidebarItem extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    handleClick() {
        if (this.store.clickHandler === 'NOT_SET') {
            this.shadow.querySelector('jo-button').anchorClick();
        }
        else {
            this.store.clickHandler();
        }
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .item {
                    padding: 0.5rem;
                    color: white;
                    cursor: pointer;
                    display: flex;
                    align-items: flex-end;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    background-color: ${state.selected ? 'var(--jo-secondary);' : ''};
                }

                .item:hover {
                    background-color: var(--jo-danger);
                }

                .item-icon {
                    padding-right: 0.5rem;
                }

                .item a {
                    text-decoration: none;
                    color: inherit;
                    font-size: 16px;
                }

                .item-button {
                    flex: 1;
                }

                .selected {
                    background-color: var(--jo-secondary);
                }
            </style>

            <div
                class="item ${state.selected === true ? 'selected' : ''}"
                @click=${() => this.handleClick()}
            >
                <span class="material-icons item-icon">${state.iconName}</span>
                ${state.clickHandler === 'NOT_SET'
            ? (0, lit_html_1.html) `
                          <jo-button
                              class="item-button"
                              .type=${'EMPTY'}
                              .href=${state.href}
                              .target=${'_blank'}
                              .text=${state.text}
                          ></jo-button>
                      `
            : (0, lit_html_1.html) `
                          <div>${state.text}</div>
                      `}
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-customer-sidebar-item', JOCustomerSidebarItem);
