"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateUserChatMode = void 0;
const graphql_1 = require("../../../../services/graphql");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function updateUserChatMode(userId, chatMode) {
    try {
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($userId: Int!, $chatMode: CHAT_MODE!) {
                    updateUsers(input: {id: $userId, chat_mode: $chatMode}) {
                        id
                    }
                }
            `, {
            userId,
            chatMode,
        });
    }
    catch (error) {
        throw new Error('Error updating user chat mode updateUserChatMode() ' + error);
    }
}
exports.updateUserChatMode = updateUserChatMode;
