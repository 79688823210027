"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../../services/graphql");
require("./jo-company-call-instruction-editor");
const utilities_1 = require("../../../services/utilities");
require("../jo-button.element");
const store_1 = require("../../../services/store");
const auth_rules_1 = require("../../../utilities/auth-rules");
const html_sanitization_1 = require("../../../utilities/html-sanitization");
const error_handling_1 = require("../../../utilities/error-handling");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
const InitialState = {
    authenticatedUser: store_1.InitialGlobalState.authenticatedUser,
    companyId: 'NOT_SET',
    callInstructionId: 'NOT_SET',
    callInstruction: 'NOT_SET',
};
class JOCompanyCallInstruction extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    set callInstructionId(callInstructionId) {
        if (this.store.callInstructionId === callInstructionId) {
            return;
        }
        this.store.callInstructionId = callInstructionId;
        if (this.store.callInstructionId === 'NOT_SET') {
            this.store.callInstruction = 'NOT_SET';
            return;
        }
        this.fetchAndSetCallInstruction();
    }
    async fetchAndSetCallInstruction() {
        (0, utilities_1.assertIsSet)(this.store.callInstructionId, utilities_1.handleError);
        const callInstructionResult = await fetchCallInstruction(this.store.callInstructionId);
        (0, utilities_1.assertSucceeded)(callInstructionResult, utilities_1.handleError);
        const callInstruction = callInstructionResult.value;
        this.store.callInstruction = callInstruction;
    }
    async callInstructionsEditorSaved(e) {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError);
        const saveResult = await createOrUpdateCallInstruction(this.store.companyId, this.store.callInstructionId, e.detail.title, e.detail.type, e.detail.instructions);
        (0, utilities_1.assertSucceeded)(saveResult, utilities_1.handleError);
        const callInstructionId = saveResult.value;
        this.store.callInstructionId = callInstructionId;
        (0, utilities_1.navigate)(`/company/call-instruction?companyId=${this.store.companyId}&callInstructionId=${callInstructionId}`, true);
        (0, utilities_1.joAlert)('Success', 'Save succeeded');
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .jo-company-call-instruction--main-container {
                    font-family: sans-serif;
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .jo-company-call-instruction--editors-container {
                    display: flex;
                    width: 100%;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    height: 100%;
                    box-sizing: border-box;
                }

                .jo-company-call-instruction--editor-container {
                    box-sizing: border-box;
                    width: 83%;
                    min-height: 600px;
                    max-height: 600px;
                    margin-bottom: 2rem;
                }
                .jo-company-call-instruction--mobile-view-container {
                    padding: 1rem;
                    width: 100%;
                }
            </style>

            ${(0, auth_rules_1.isCompanyUser)(this.store.authenticatedUser)
            ? (0, lit_html_1.html) `
                      <div
                          class="jo-global--card jo-company-call-instruction--mobile-view-container"
                      >
                          <h3>
                              Title:
                              ${state.callInstruction === 'NOT_SET'
                ? ''
                : state.callInstruction.title}
                          </h3>
                          <hr />
                          <div class="jo-company-call-instruction--htmlObjectContainer">
                              ${state.callInstruction === 'NOT_SET'
                ? ''
                : (0, html_sanitization_1.renderHTMLStringSafely)(state.callInstruction.instructions)}
                          </div>
                      </div>
                  `
            : (0, lit_html_1.html) `
                      <div class="jo-company-call-instruction--main-container">
                          <div class="jo-company-call-instruction--editors-container">
                              <div class="jo-company-call-instruction--editor-container">
                                  <jo-company-call-instruction-editor
                                      .theTitle=${state.callInstruction === 'NOT_SET'
                ? ''
                : state.callInstruction.title}
                                      .type=${state.callInstruction === 'NOT_SET'
                ? ''
                : state.callInstruction.type}
                                      .instructions=${state.callInstruction === 'NOT_SET'
                ? ''
                : state.callInstruction.instructions}
                                      @saved=${(e) => this.callInstructionsEditorSaved(e)}
                                  ></jo-company-call-instruction-editor>
                              </div>
                          </div>
                      </div>
                  `}
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-call-instruction', JOCompanyCallInstruction);
async function fetchCallInstruction(callInstructionId) {
    try {
        const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($callInstructionId: Int!) {
                    getCall_instructions(id: $callInstructionId) {
                        id
                        instructions
                        order
                        title
                        type
                    }
                }
            `, {
            callInstructionId,
        });
        if (result.succeeded === false) {
            return result;
        }
        const callInstruction = result.value.data.getCall_instructions;
        return {
            succeeded: true,
            value: callInstruction,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchCallInstruction', error);
    }
}
async function createOrUpdateCallInstruction(companyId, callInstructionId, title, type, instructions) {
    try {
        if (callInstructionId === 'NOT_SET') {
            const orderResult = await getNewCallInstructionOrder();
            if (orderResult.succeeded === false) {
                return orderResult;
            }
            const order = orderResult.value;
            const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                    mutation (
                        $createdAt: DateTime!
                        $updatedAt: DateTime!
                        $companyId: Int!
                        $title: String!
                        $type: CALL_INSTRUCTIONS_TYPE!
                        $instructions: String!
                        $order: Int!
                    ) {
                        createCall_instructions(
                            input: {
                                appear_in_call_screen: true
                                created_at: $createdAt
                                updated_at: $updatedAt
                                company: {connect: {id: $companyId}}
                                title: $title
                                type: $type
                                instructions: $instructions
                                order: $order
                            }
                        ) {
                            id
                            company {
                                # We need this here for subscriptions to work
                                id
                            }
                        }
                    }
                `, {
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
                companyId,
                title,
                type,
                instructions,
                order,
            });
            if (result.succeeded === false) {
                return result;
            }
            return {
                succeeded: true,
                value: result.value.data.createCall_instructions.id,
            };
        }
        else {
            const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                    mutation (
                        $callInstructionId: Int!
                        $title: String!
                        $instructions: String!
                        $type: CALL_INSTRUCTIONS_TYPE
                    ) {
                        updateCall_instructions(
                            input: {
                                id: $callInstructionId
                                title: $title
                                type: $type
                                instructions: $instructions
                            }
                        ) {
                            id
                            company {
                                # We need this here for subscriptions to work
                                id
                            }
                        }
                    }
                `, {
                callInstructionId,
                title,
                type,
                instructions,
            });
            if (result.succeeded === false) {
                return result;
            }
            return {
                succeeded: true,
                value: result.value.data.updateCall_instructions.id,
            };
        }
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('jo-call-screen createOrUpdateCallInstruction', error);
    }
}
async function getNewCallInstructionOrder() {
    try {
        const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query {
                findCall_instructions(orderBy: {field: "order", order: DESC}) {
                    items {
                        id
                        order
                    }
                }
            }
        `);
        if (result.succeeded === false) {
            return result;
        }
        const callInstructions = result.value.data.findCall_instructions.items;
        if (callInstructions.length === 0) {
            return {
                succeeded: true,
                value: 0,
            };
        }
        else {
            const callInstructionWithLargestOrder = callInstructions[0];
            if (callInstructionWithLargestOrder == null)
                throw new Error('callInstructionWithLargestOrder is null');
            return {
                succeeded: true,
                value: callInstructionWithLargestOrder.order + 1,
            };
        }
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('getNewCallInstructionOrder', error);
    }
}
