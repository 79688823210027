"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCompanyStatusEvents = void 0;
const graphql_1 = require("../../services/graphql");
const mapped_env_variables_1 = require("../environment/mapped-env-variables");
async function getCompanyStatusEvents(companyId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!) {
                    getCompanies(id: $companyId) {
                        id
                        status_events {
                            id
                            timestamp
                            type
                        }
                    }
                }
            `, {
            companyId,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const statusEvents = gqlResult.value.data.getCompanies.status_events;
        return {
            succeeded: true,
            value: statusEvents,
        };
    }
    catch (error) {
        throw new Error(`getCompanyStatusEvents error: ${error}`);
    }
}
exports.getCompanyStatusEvents = getCompanyStatusEvents;
