"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchGlobalPods = exports.fetchUser = void 0;
const graphql_1 = require("../../../services/graphql");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function fetchUser(userId) {
    try {
        const [userWithMainInfo, userWithShifts,] = await Promise.all([
            fetchUserWithMainInfo(userId),
            fetchUserWithShiftsOnly(userId),
        ]);
        const user = {
            ...userWithMainInfo,
            ...userWithShifts,
        };
        return {
            succeeded: true,
            value: user,
        };
    }
    catch (error) {
        return {
            succeeded: false,
            userMessage: 'Something went wrong getting User info',
            developerMessage: 'An error happened in the gql request for fetching user: ' + error,
        };
    }
}
exports.fetchUser = fetchUser;
async function fetchUserWithMainInfo(userId) {
    const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query ($userId: Int!) {
                getUsers(id: $userId) {
                    id
                    auth_role
                    email
                    first_name
                    float_pods {
                        id
                        name
                    }
                    is_24_7
                    is_test_user
                    is_account_manager
                    is_active
                    is_available
                    is_personal_admin
                    in_personal_admin_mode
                    last_name
                    number_of_invalid_mfa_codes
                    permissions {
                        can_delete_invoices
                        has_chat_access
                        can_view_incoming_queue_box
                    }
                    phone_number
                    pods {
                        id
                        name
                    }
                    profile_picture_url
                    profile_picture_management_privileges
                    shift_hours
                    team_role
                    time_zone
                    user_token
                }
            }
        `, {
        userId,
    });
    const user = gqlResult.data.getUsers;
    return user;
}
async function fetchUserWithShiftsOnly(userId) {
    const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query ($userId: Int!) {
                getUsers(id: $userId) {
                    id
                    shifts(filter: {archived_at: {eq: null}}, orderBy: {field: "id", order: ASC}) {
                        id
                        start_day_of_week
                        end_day_of_week
                        start_hour
                        end_hour
                        start_minute
                        end_minute
                        scheduled_breaks {
                            id
                            start_day_of_week
                            start_hour
                            start_minute
                            end_day_of_week
                            end_hour
                            end_minute
                        }
                        lunch_breaks {
                            id
                            start_day_of_week
                            start_hour
                            start_minute
                            end_day_of_week
                            end_hour
                            end_minute
                        }
                    }
                }
            }
        `, {
        userId,
    });
    const user = gqlResult.data.getUsers;
    return user;
}
async function fetchGlobalPods() {
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query {
                findPods(orderBy: {field: "name", order: ASC}) {
                    items {
                        id
                        name
                    }
                }
            }
        `, {});
    if (gqlResult.succeeded === false) {
        return gqlResult;
    }
    const pods = gqlResult.value.data.findPods.items;
    return {
        succeeded: true,
        value: pods,
    };
}
exports.fetchGlobalPods = fetchGlobalPods;
