"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setRoleFriendlyDisplay = void 0;
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../../services/graphql");
require("./jo-company-employee");
require("../jo-button.element");
require("../jo-table");
require("../jo-loading");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
require("./jo-company-employees-mobile-template");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    companyId: 'NOT_SET',
    isMobileDevice: false,
    loading: true,
    userCreatedSubscription: 'NOT_SET',
    userUpdatedSubscription: 'NOT_SET',
};
class JOCompanyEmployees extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
        this.callGetEmployees = (async (offset, limit, searchText) => {
            return await getEmployees(offset, limit, searchText, this.store.companyId);
        }).bind(this);
    }
    set companyId(companyId) {
        if (companyId === this.store.companyId) {
            return;
        }
        this.callGetEmployees = this.callGetEmployees.bind(this);
        this.store.companyId = companyId;
        if (this.store.companyId !== 'NOT_SET') {
            this.closeSubscriptionsIfNecessary();
            this.createSubscriptionsIfNecessary();
        }
    }
    closeSubscriptionsIfNecessary() {
        this.closeUserCreatedSubscriptionIfNecessary();
        this.closeUserUpdatedSubscriptionIfNecessary();
    }
    closeUserCreatedSubscriptionIfNecessary() {
        if (this.store.userCreatedSubscription !== 'NOT_SET' &&
            this.store.userCreatedSubscription.companyId !== this.store.companyId) {
            this.store.userCreatedSubscription.webSocket?.close();
            this.store.userCreatedSubscription = 'NOT_SET';
        }
    }
    closeUserUpdatedSubscriptionIfNecessary() {
        if (this.store.userUpdatedSubscription !== 'NOT_SET' &&
            this.store.userUpdatedSubscription.companyId !== this.store.companyId) {
            this.store.userUpdatedSubscription.webSocket?.close();
            this.store.userUpdatedSubscription = 'NOT_SET';
        }
    }
    async createSubscriptionsIfNecessary() {
        await this.createUserCreatedSubscriptionIfNecessary();
        await this.createUserUpdatedSubscriptionIfNecessary();
    }
    async createUserCreatedSubscriptionIfNecessary() {
        if (this.store.userCreatedSubscription === 'NOT_SET') {
            this.store.userCreatedSubscription = {
                companyId: this.store.companyId,
                webSocket: await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
                        subscription ($companyId: Int!) {
                            userCreatedForCompany(companyId: $companyId)
                        }
                    `, () => {
                    // We do not know if this.store.companyId will be set when this callback runs
                    // Therefore we store the companyId with the subscription, and we temporarily set this.store.companyId to the stored value
                    // TODO if we could send in the companyId to callGetActionItems somehow, we could get around this hack
                    const originalCompanyId = this.store.companyId;
                    const temporaryCompanyId = this.store.userCreatedSubscription === 'NOT_SET'
                        ? originalCompanyId
                        : this.store.userCreatedSubscription.companyId;
                    this.callGetEmployees = this.callGetEmployees.bind(this);
                    this.store.companyId = temporaryCompanyId;
                    this.store.companyId = originalCompanyId;
                }, {
                    companyId: this.store.companyId,
                }),
            };
        }
    }
    async createUserUpdatedSubscriptionIfNecessary() {
        if (this.store.userUpdatedSubscription === 'NOT_SET') {
            this.store.userUpdatedSubscription = {
                companyId: this.store.companyId,
                webSocket: await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
                        subscription ($companyId: Int!) {
                            userUpdatedForCompany(companyId: $companyId)
                        }
                    `, () => {
                    // We do not know if this.store.companyId will be set when this callback runs
                    // Therefore we store the companyId with the subscription, and we temporarily set this.store.companyId to the stored value
                    // TODO if we could send in the companyId to callGetActionItems somehow, we could get around this hack
                    const originalCompanyId = this.store.companyId;
                    const temporaryCompanyId = this.store.userUpdatedSubscription === 'NOT_SET'
                        ? originalCompanyId
                        : this.store.userUpdatedSubscription.companyId;
                    this.callGetEmployees = this.callGetEmployees.bind(this);
                    this.store.companyId = temporaryCompanyId;
                    this.store.companyId = originalCompanyId;
                }, {
                    companyId: this.store.companyId,
                }),
            };
        }
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .jo-company-employees--main-container {
                    height: 100%;
                    width: ${state.isMobileDevice ? '100vmin' : '100%'};
                    box-sizing: border-box;
                }

                .jo-company-employees--new-employee-container {
                    padding-bottom: 2rem;
                }

                .jo-company-employees--table-container {
                    box-shadow: border-box;
                    padding: ${state.isMobileDevice ? `0` : `1rem`};
                    background-color: white;
                    box-shadow: var(--jo-card-shadow-1);
                    border-radius: var(--jo-wide-border-radius-1);
                }

                .jo-company-employees--actions {
                    display: flex;
                }

                .jo-company-employees--action {
                    padding-left: 0.25rem;
                }
            </style>

            <jo-loading
                .props=${{
            loading: state.loading,
        }}
            ></jo-loading>

            <div class="jo-company-employees--main-container">
                ${(0, utilities_1.authorizedRender)([
            'JILL_MANAGER',
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser, (0, lit_html_1.html) `
                        <div class="jo-company-employees--new-employee-container">
                            <jo-button
                                .type=${'ACTION_BUTTON_1'}
                                .text=${'New Employee'}
                                .href=${`/company/employee/create?companyId=${state.companyId}`}
                            ></jo-button>
                        </div>
                    `)}

                <div class="jo-company-employees--table-container">
                    <jo-table
                        .tableName=${'Employees'}
                        .maxItemsPerPage=${50}
                        .columns=${getColumns(state)}
                        .getItems=${this.callGetEmployees}
                        .searchDebounce=${200}
                        .isMobileDevice=${state.isMobileDevice}
                        .mobileTemplate=${getMobileTemplate}
                        .showSearchBar=${!state.isMobileDevice}
                        .companyId=${state.companyId}
                        .isCompanyUser=${(0, utilities_1.getIsCompanyUser)(state.authenticatedUser, state.companyId)}
                        @itemsfetched=${() => {
            if (this.store.loading === true) {
                this.store.loading = false;
            }
        }}
                    ></jo-table>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-employees', JOCompanyEmployees);
function getMobileTemplate(item) {
    return (0, lit_html_1.html) `
        <jo-company-employees-mobile-template .item=${item}></jo-company-employees-mobile-template>
    `;
}
function getColumns(state) {
    const columns = [
        {
            title: 'Actions',
            getCellData: (employee) => {
                return (0, lit_html_1.html) `
                    <div class="jo-company-employees--actions">
                        <jo-button
                            class="jo-company-employees--action"
                            .type=${'ICON'}
                            .icon=${'email'}
                            .href=${`mailto:${employee.email}`}
                        ></jo-button>

                        <jo-button
                            class="jo-company-employees--action"
                            .type=${'ICON'}
                            .icon=${(0, utilities_1.getIsCompanyUser)(state.authenticatedUser, state.companyId)
                    ? 'remove_red_eye'
                    : 'edit'}
                            .href=${(0, utilities_1.getIsCompanyUser)(state.authenticatedUser, state.companyId)
                    ? `/customer/employee/edit?employeeId=${employee.id}&companyId=${state.companyId}`
                    : `/company/employee/edit?employeeId=${employee.id}&companyId=${state.companyId}`}
                        ></jo-button>
                    </div>
                `;
            },
            width: 1,
        },
        {
            title: 'Name',
            getCellData: (employee) => employee.first_name + ' ' + employee.last_name,
            width: 2,
        },
        {
            title: 'Role',
            getCellData: (employee) => setRoleFriendlyDisplay(employee.auth_role),
            width: 1,
        },
        {
            title: 'Email',
            getCellData: (employee) => employee.email,
            width: 2,
        },
        {
            title: 'Receives Action Item Emails?',
            getCellData: (employee) => employee.should_alert_action_items === true ? 'Yes' : 'No',
            width: 1,
        },
        {
            title: 'Can Receive Transfer Calls?',
            getCellData: (employe) => employe.can_receive_call_transfer === true ? 'Yes' : 'No',
            width: 1,
        },
        {
            title: 'Status',
            getCellData: (employee) => (employee.is_active === true ? 'Active' : 'Inactive'),
            width: 1,
        },
    ];
    return columns;
}
async function getEmployees(offset, limit, searchText, companyId) {
    if (companyId === 'NOT_SET') {
        return {
            items: [],
            totalItems: 0,
        };
    }
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query (
                $offset: Int!
                $limit: Int!
                $ignoreSearch: Boolean!
                $companyId: Int!
                $firstName: String!
            ) {
                searchEmployeeUsers(
                    page: {offset: $offset, limit: $limit}
                    ignoreSearch: $ignoreSearch
                    companyId: $companyId
                    firstName: $firstName
                ) {
                    users {
                        id
                        auth_role
                        can_receive_call_transfer
                        email
                        first_name
                        last_name
                        is_active
                        should_alert_action_items
                    }
                    total
                }
            }
        `, {
        offset,
        limit,
        ignoreSearch: searchText === '',
        companyId,
        firstName: searchText,
    });
    // TODO the jo-table getItems function should return a GQLResult
    if (gqlResult.succeeded === false) {
        alert(gqlResult.userMessage);
        throw new Error(gqlResult.developerMessage);
    }
    const employees = gqlResult.value.data.searchEmployeeUsers.users;
    return {
        items: employees,
        totalItems: gqlResult.value.data.searchEmployeeUsers.total,
    };
}
function setRoleFriendlyDisplay(role) {
    if (role === 'COMPANY_OWNER')
        return 'Owner';
    if (role === 'COMPANY_EMPLOYEE')
        return 'Employee';
    if (role === 'JILL')
        return 'Jill';
    return '';
}
exports.setRoleFriendlyDisplay = setRoleFriendlyDisplay;
