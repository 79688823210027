"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setBusinessHoursForCompany = exports.deleteBusinessHoursForCompany = void 0;
const graphql_1 = require("../../../services/graphql");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function deleteBusinessHoursForCompany(companyId) {
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($companyId: Int!) {
                deleteBusinessHoursForCompany(companyId: $companyId)
            }
        `, {
        companyId,
    });
    if (gqlResult.succeeded === false) {
        return gqlResult;
    }
    return { succeeded: true };
}
exports.deleteBusinessHoursForCompany = deleteBusinessHoursForCompany;
async function setBusinessHoursForCompany(companyId, businessHours) {
    const businessHoursGQLInput = businessHours.map(businessHour => ({
        id: businessHour.id,
        day_of_week: businessHour.day_of_week,
        open: businessHour.open,
        close: businessHour.close,
    }));
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($companyId: Int!, $businessHoursGQLInput: [BusinessHoursInput!]!) {
                setBusinessHoursForCompany(
                    companyId: $companyId
                    businessHours: $businessHoursGQLInput
                )
            }
        `, {
        companyId,
        businessHoursGQLInput,
    });
    if (gqlResult.succeeded === false) {
        return gqlResult;
    }
    return { succeeded: true };
}
exports.setBusinessHoursForCompany = setBusinessHoursForCompany;
