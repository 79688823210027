"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const form_error_manager_1 = require("../../classes/form-error-manager");
const graphql_1 = require("../../services/graphql");
const utilities_1 = require("../../services/utilities");
const css_utils_1 = require("../../utilities/css-utils");
const error_handling_1 = require("../../utilities/error-handling");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const test_id_directive_1 = require("../directives/test-id.directive");
const mapped_env_variables_1 = require("../../utilities/environment/mapped-env-variables");
require("./jo-button.element");
require("./jo-input");
const common_1 = require("@augment-vir/common");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    mode: 'EDIT',
    minutesPackageId: 'NOT_SET',
    minutesPackage: 'NOT_SET',
    minutesCalculationOptions: [],
    inputValues: {
        minutesCalculationOption: 'NOT_SET',
        baseFee: 0,
        billingCycleType: 'WEEKLY',
        name: '',
        numMinutesIncluded: 0,
        pricePerOverageMinute: 0,
        recurringFee: 0,
        type: 'CUSTOM',
        userDisplayName: '',
    },
    formErrorManager: new form_error_manager_1.FormErrorManager(),
    saving: false,
    showAllErrors: false,
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-minutes-package');
class JOMinutesPackage extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        const originalMinutesPackageId = this.store.minutesPackageId;
        if (typeof originalMinutesPackageId === 'number' &&
            props.minutesPackageId === originalMinutesPackageId) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        this.fetchAndSetData();
    }
    async fetchAndSetData() {
        await this.fetchAndSetMinutesCalculationOptions();
        if (this.store.mode === 'EDIT') {
            await this.fetchAndSetMinutesPackage();
        }
        this.setInputValues();
    }
    async fetchAndSetMinutesPackage() {
        (0, utilities_1.assertIsSet)(this.store.minutesPackageId, utilities_1.handleError, 'this.store.minutesPackageId');
        const getMinutesPackageResult = await getMinutesPackage(this.store.minutesPackageId);
        (0, utilities_1.assertSucceeded)(getMinutesPackageResult, utilities_1.handleError);
        this.store.minutesPackage = getMinutesPackageResult.value;
    }
    setInputValues() {
        if (this.store.mode === 'EDIT') {
            (0, utilities_1.assertIsSet)(this.store.minutesPackage, utilities_1.handleError, 'this.store.minutesPackage');
            const minutesPackage = this.store.minutesPackage;
            this.store.inputValues = {
                name: minutesPackage.name,
                baseFee: minutesPackage.base_fee,
                billingCycleType: minutesPackage.billing_cycle_type,
                numMinutesIncluded: minutesPackage.num_minutes_included,
                pricePerOverageMinute: minutesPackage.price_per_minute,
                recurringFee: minutesPackage.recurring_fee,
                type: minutesPackage.type,
                userDisplayName: minutesPackage.user_display_name,
                minutesCalculationOption: minutesPackage.minutes_calculation_option,
            };
        }
        if (this.store.mode === 'CREATE') {
            (0, common_1.assertLengthAtLeast)(this.store.minutesCalculationOptions, 1);
            this.store.inputValues = {
                ...this.store.inputValues,
                minutesCalculationOption: this.store.minutesCalculationOptions[0],
            };
        }
    }
    async saveButtonClicked() {
        if (this.store.formErrorManager.getFormErrors().length > 0) {
            (0, utilities_1.joAlert)('ERROR', 'Please correct errors');
            this.store.showAllErrors = true;
            return;
        }
        this.store.saving = true;
        if (this.store.mode === 'CREATE') {
            const createMinutesPackageResult = await createMinutesPackage(this.store.inputValues);
            (0, utilities_1.assertSucceeded)(createMinutesPackageResult, utilities_1.handleError);
        }
        if (this.store.mode === 'EDIT') {
            (0, utilities_1.assertIsSet)(this.store.minutesPackageId, utilities_1.handleError, 'this.store.minutesPackageId');
            const updateMinutesPackageResult = await updateMinutesPackage(this.store.minutesPackageId, this.store.inputValues);
            (0, utilities_1.assertSucceeded)(updateMinutesPackageResult, utilities_1.handleError);
        }
        (0, utilities_1.navigate)('/billing-packages');
        window.location.reload();
    }
    async fetchAndSetMinutesCalculationOptions() {
        const getMinutesCalculationOptionsResult = await getMinutesCalculationOptions();
        (0, utilities_1.assertSucceeded)(getMinutesCalculationOptionsResult, utilities_1.handleError);
        this.store.minutesCalculationOptions = getMinutesCalculationOptionsResult.value;
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .${cssName('main-container')} {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .${cssName('form-container')} {
                    box-sizing: border-box;
                    background-color: white;
                    box-shadow: var(--jo-card-shadow-1);
                    padding: 1rem;
                }

                .${cssName('grid-container')} {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                }

                .${cssName('left-column')} {
                    padding-right: 5rem;
                }

                .${cssName('row')} {
                    height: 5rem;
                }

                .${cssName('save-cancel-buttons-container')} {
                    padding-top: 1rem;
                    display: flex;
                }

                .${cssName('minutes-calculation-options-grid')} {
                    display: grid;
                    grid-template-columns: 3fr 2fr;
                }
            </style>

            <div class=${cssName('main-container')}>
                <div class=${cssName('form-container')}>
                    ${state.mode === 'CREATE'
            ? (0, lit_html_1.html) `
                              <h2>Create New Minutes Package</h2>
                          `
            : (0, lit_html_1.html) `
                              <h2>Edit Minutes Package</h2>
                          `}

                    <br />

                    <div class=${cssName('grid-container')}>
                        <div class=${cssName('left-column')}>
                            <div class=${cssName('row')}>
                                <jo-input
                                    .type=${'TEXT_INPUT'}
                                    .label=${'Name'}
                                    @inputErrorChanged=${(e) => this.store.formErrorManager.addOrRemoveFormError(e.detail)}
                                    .inputValue=${state.inputValues.name}
                                    .required=${true}
                                    @inputchange=${(e) => (this.store.inputValues = {
            ...this.store.inputValues,
            name: e.detail.inputValue,
        })}
                                    .showError=${state.showAllErrors}
                                ></jo-input>
                            </div>

                            <div class=${cssName('row')}>
                                <jo-input
                                    .type=${'TEXT_INPUT'}
                                    .label=${'User Display Name'}
                                    @inputErrorChanged=${(e) => this.store.formErrorManager.addOrRemoveFormError(e.detail)}
                                    .inputValue=${state.inputValues.userDisplayName}
                                    .required=${true}
                                    @inputchange=${(e) => (this.store.inputValues = {
            ...this.store.inputValues,
            userDisplayName: e.detail.inputValue,
        })}
                                    .showError=${state.showAllErrors}
                                ></jo-input>
                            </div>

                            <div class=${cssName('row')}>
                                <jo-input
                                    .type=${'TEXT_INPUT'}
                                    .label=${'Number of Minutes'}
                                    @inputErrorChanged=${(e) => this.store.formErrorManager.addOrRemoveFormError(e.detail)}
                                    .inputValue=${state.inputValues.numMinutesIncluded.toString()}
                                    .required=${true}
                                    .readonly=${state.mode === 'EDIT'}
                                    @inputchange=${(e) => (this.store.inputValues = {
            ...this.store.inputValues,
            numMinutesIncluded: Number(e.detail.inputValue),
        })}
                                    .showError=${state.showAllErrors}
                                ></jo-input>
                            </div>

                            <div class=${cssName('row')}>
                                <jo-input
                                    .type=${'TEXT_INPUT'}
                                    .label=${'Price Per Minute (in USD Cents)'}
                                    .required=${true}
                                    @inputErrorChanged=${(e) => this.store.formErrorManager.addOrRemoveFormError(e.detail)}
                                    .inputValue=${state.inputValues.pricePerOverageMinute.toString()}
                                    .readonly=${state.mode === 'EDIT'}
                                    @inputchange=${(e) => (this.store.inputValues = {
            ...this.store.inputValues,
            pricePerOverageMinute: Number(e.detail.inputValue),
        })}
                                    .showError=${state.showAllErrors}
                                ></jo-input>
                            </div>

                            <div class=${cssName('row')}>
                                <jo-input
                                    .type=${'TEXT_INPUT'}
                                    .label=${'Recurring Fee (in USD Cents)'}
                                    @inputErrorChanged=${(e) => this.store.formErrorManager.addOrRemoveFormError(e.detail)}
                                    .required=${true}
                                    .inputValue=${state.inputValues.recurringFee.toString()}
                                    .readonly=${state.mode === 'EDIT'}
                                    @inputchange=${(e) => (this.store.inputValues = {
            ...this.store.inputValues,
            recurringFee: Number(e.detail.inputValue),
        })}
                                    .showError=${state.showAllErrors}
                                ></jo-input>
                            </div>

                            <div class=${cssName('row')}>
                                <jo-input
                                    .type=${'SELECT'}
                                    .label=${'Type'}
                                    @inputErrorChanged=${(e) => this.store.formErrorManager.addOrRemoveFormError(e.detail)}
                                    .required=${true}
                                    .selectOptions=${[
            'CUSTOM',
            'LEGACY',
            'SIGNUP',
        ]}
                                    .selectedValue=${state.inputValues.type}
                                    @inputchange=${(e) => (this.store.inputValues = {
            ...this.store.inputValues,
            type: e.detail.selectedValue,
        })}
                                    .showError=${state.showAllErrors}
                                ></jo-input>
                            </div>
                        </div>

                        <div>
                            <div class=${cssName('row')}>
                                <jo-input
                                    .type=${'SELECT'}
                                    .label=${'Billing Cycle Type'}
                                    .selectOptions=${[
            'WEEKLY',
            'MONTHLY',
        ]}
                                    .selectedValue=${state.inputValues.billingCycleType}
                                    @valueChanged=${(e) => (this.store.inputValues = {
            ...this.store.inputValues,
            billingCycleType: e.detail.selectedValue,
        })}
                                    .readonly=${state.mode === 'EDIT'}
                                ></jo-input>
                            </div>

                            <div class=${cssName('row')}>
                                <jo-input
                                    .type=${'TEXT_INPUT'}
                                    .label=${'Base Fee (in USD Cents)'}
                                    .inputValue=${state.inputValues.baseFee.toString()}
                                    @valueChanged=${(e) => (this.store.inputValues = {
            ...this.store.inputValues,
            baseFee: Number(e.detail.inputValue),
        })}
                                    .readonly=${state.mode === 'EDIT'}
                                ></jo-input>
                            </div>

                            <div class=${cssName('row')}>
                                <jo-input
                                    .type=${'SELECT'}
                                    .label=${'Minutes Calculation Option'}
                                    @inputErrorChanged=${(e) => this.store.formErrorManager.addOrRemoveFormError(e.detail)}
                                    .defaultOption=${'Please select a minutes calculation option...'}
                                    .selectOptions=${state.minutesCalculationOptions.map(m => m.name)}
                                    .selectedValue=${state.inputValues.minutesCalculationOption ===
            'NOT_SET' ||
            state.inputValues.minutesCalculationOption === undefined
            ? ''
            : state.inputValues.minutesCalculationOption.name}
                                    .required=${true}
                                    .readonly=${state.mode === 'EDIT'}
                                    @inputchange=${(e) => {
            const selectedMinutesCalculationOption = this.store.minutesCalculationOptions.filter(m => m.name === e.detail.selectedValue)[0];
            if (selectedMinutesCalculationOption == null) {
                throw new Error('selectedMinutesCalculationOption is null');
            }
            this.store.inputValues = {
                ...this.store.inputValues,
                minutesCalculationOption: selectedMinutesCalculationOption,
            };
        }}
                                    .showError=${state.showAllErrors}
                                ></jo-input>
                            </div>

                            <div class=${cssName('minutes-calculation-options-grid')}>
                                <div>
                                    <div class=${cssName('row')}>
                                        Max Total Billable Time Per Call:
                                    </div>

                                    <div class=${cssName('row')}>
                                        Max Billable Action Item Time Per Call:
                                    </div>

                                    <div class=${cssName('row')}>
                                        Minium Billable Time Per Call:
                                    </div>

                                    <div class=${cssName('row')}>
                                        Minium Time Call Needs to Be Billable:
                                    </div>
                                </div>

                                <div>
                                    <div class=${cssName('row')}>
                                        <strong>
                                            ${convertMinutesCalculationsOptionTimeForDisplay(state.inputValues.minutesCalculationOption, 'max_billable_milliseconds')}
                                        </strong>
                                    </div>

                                    <div class=${cssName('row')}>
                                        <strong>
                                            ${convertMinutesCalculationsOptionTimeForDisplay(state.inputValues.minutesCalculationOption, 'max_billable_action_item_milliseconds')}
                                        </strong>
                                    </div>

                                    <div class=${cssName('row')}>
                                        <strong>
                                            ${convertMinutesCalculationsOptionTimeForDisplay(state.inputValues.minutesCalculationOption, 'min_billable_milliseconds')}
                                        </strong>
                                    </div>

                                    <div class=${cssName('row')}>
                                        <strong>
                                            ${convertMinutesCalculationsOptionTimeForDisplay(state.inputValues.minutesCalculationOption, 'min_milliseconds_call_needs_to_be_billable')}
                                        </strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class=${cssName('save-cancel-buttons-container')}>
                    <jo-button
                        ${(0, test_id_directive_1.testId)('save-minutes-packages-button')}
                        .type=${'ACTION_BUTTON_1'}
                        .text=${state.saving === true ? 'Saving...' : 'Save'}
                        .disabled=${state.saving === true}
                        @joButtonClick=${() => this.saveButtonClicked()}
                    ></jo-button>

                    <jo-button
                        style="padding-left: 1rem;"
                        .type=${'SECONDARY_BUTTON_1'}
                        .text=${'Cancel'}
                        @joButtonClick=${() => (0, utilities_1.navigate)('/billing-packages')}
                    ></jo-button>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-minutes-package', JOMinutesPackage);
async function getMinutesPackage(minutesPackageId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($minutesPackageId: Int!) {
                    getMinutes_packages(id: $minutesPackageId) {
                        id
                        base_fee
                        billing_cycle_type
                        name
                        num_minutes_included
                        price_per_minute
                        recurring_fee
                        type
                        user_display_name
                        minutes_calculation_option {
                            id
                            name
                            max_billable_milliseconds
                            max_billable_action_item_milliseconds
                            min_billable_milliseconds
                            min_milliseconds_call_needs_to_be_billable
                        }
                    }
                }
            `, {
            minutesPackageId,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const minutesPackage = gqlResult.value.data.getMinutes_packages;
        return {
            succeeded: true,
            value: minutesPackage,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('getMinutesPackage', error);
    }
}
async function getMinutesCalculationOptions() {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query {
                findMinutes_calculation_options {
                    items {
                        id
                        name
                        max_billable_action_item_milliseconds
                        max_billable_milliseconds
                        min_billable_milliseconds
                        min_milliseconds_call_needs_to_be_billable
                    }
                }
            }
        `);
        if (gqlResult.succeeded === false)
            return gqlResult;
        return {
            succeeded: true,
            value: gqlResult.value.data.findMinutes_calculation_options.items,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('getMinutesCalculationOptions', error);
    }
}
function convertMinutesCalculationsOptionTimeForDisplay(minutesCalculationOption, key) {
    if (minutesCalculationOption === 'NOT_SET' || minutesCalculationOption === undefined)
        return '';
    return (0, utilities_1.millisecondsToHoursMinutesSeconds)(minutesCalculationOption[key]);
}
async function createMinutesPackage(inputValues) {
    try {
        if (inputValues.minutesCalculationOption === 'NOT_SET') {
            return {
                succeeded: false,
                userMessage: 'Error updating package',
                developerMessage: 'inputValues.minutesCalculationOption is not set',
            };
        }
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($input: CreateMinutes_packagesInput!) {
                    createMinutes_packages(input: $input) {
                        id
                    }
                }
            `, {
            input: {
                name: inputValues.name,
                base_fee: inputValues.baseFee,
                billing_cycle_type: inputValues.billingCycleType,
                user_display_name: inputValues.userDisplayName,
                num_minutes_included: inputValues.numMinutesIncluded,
                price_per_minute: inputValues.pricePerOverageMinute,
                recurring_fee: inputValues.recurringFee,
                type: inputValues.type,
                minutes_calculation_option: {
                    connect: {
                        id: inputValues.minutesCalculationOption.id,
                    },
                },
            },
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('createMinutesPackage', error);
    }
}
async function updateMinutesPackage(minutesPackageId, inputValues) {
    try {
        if (inputValues.minutesCalculationOption === 'NOT_SET') {
            return {
                succeeded: false,
                userMessage: 'Error updating package',
                developerMessage: 'inputValues.minutesCalculationOption is not set',
            };
        }
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($input: UpdateMinutes_packagesInput!) {
                    updateMinutes_packages(input: $input) {
                        id
                    }
                }
            `, {
            input: {
                id: minutesPackageId,
                name: inputValues.name,
                user_display_name: inputValues.userDisplayName,
            },
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('updateMinutesPackage', error);
    }
}
