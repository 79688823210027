"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.daysOfTheWeek = void 0;
exports.daysOfTheWeek = [
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
    'SUNDAY',
];
