"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../../services/graphql");
const utilities_1 = require("../../../services/utilities");
require("../jo-loading");
const constants_1 = require("../../../services/constants");
const error_handling_1 = require("../../../utilities/error-handling");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    companyId: 'NOT_SET',
    isMobileDevice: false,
    loading: true,
    userForDashboard: 'NOT_SET',
};
class JOCustomerDashboard extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    set authenticatedUser(authenticatedUser) {
        if (authenticatedUser === this.store.authenticatedUser) {
            return;
        }
        this.store.authenticatedUser = authenticatedUser;
        if (this.store.authenticatedUser !== 'NOT_SET') {
            this.fetchAndSetUserForDashboard(this.store.authenticatedUser.id);
        }
    }
    async fetchAndSetUserForDashboard(userId) {
        this.store.loading = true;
        const userForDashboardResult = await fetchUserForDashboard(userId);
        (0, utilities_1.assertSucceeded)(userForDashboardResult, utilities_1.handleError);
        const userForDashboard = userForDashboardResult.value;
        this.store.userForDashboard = userForDashboard;
        this.store.loading = false;
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .action-cards-container {
                    display: flex;
                    justify-content: space-evenly;
                    flex-wrap: wrap;
                    padding-bottom: 2rem;
                }

                .icon-container {
                    box-sizing: border-box;
                    color: white;
                    background-color: var(--jo-primary);
                    border-radius: 50%;
                    font-size: 1.5rem;
                    width: 2rem;
                    height: 2rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .action-card-container {
                    padding-bottom: 2rem;
                    padding-right: 1rem;
                }

                .action-card {
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    width: 15rem;
                    justify-content: space-evenly;
                    background-color: white;
                    box-shadow: 0px 1px 3px rgb(0, 0, 0, 0.2);
                    border-radius: 2px;
                    padding: 2rem 1rem;
                    cursor: pointer;
                }

                .account-manager-card-container {
                    display: flex;
                    justify-content: ${state.isMobileDevice ? 'space-evenly' : 'flex-start'};
                    flex-wrap: wrap;
                    padding-bottom: 2rem;
                }

                @media screen and (max-width: 1000px) {
                    .account-manager-card-container {
                        justify-content: center;
                    }
                }

                .account-manager-card {
                    display: inline-block;
                    box-sizing: border-box;
                    background-color: white;
                    padding: 1rem;
                    box-shadow: 0px 1px 3px rgb(0, 0, 0, 0.2);
                    border-radius: 2px;
                    margin-bottom: 2rem;
                }
            </style>

            <div class="main-container">
                <jo-loading
                    .props=${{
            loading: state.loading,
        }}
                ></jo-loading>

                <div
                    style="display: flex; align-items: center; font-weight: bold; flex-direction: column;"
                >
                    <h2 style="margin: 30px 0 7px !important">
                        <i>
                            Hello,
                            ${state.userForDashboard !== 'NOT_SET'
            ? state.userForDashboard.first_name
            : ''}!
                        </i>
                    </h2>

                    <h3 style="margin: 5px 0 !important">
                        <i>How can your Jills help you today?</i>
                    </h3>
                </div>

                <br />
                <br />

                <div class="action-cards-container">
                    <div class="action-card-container">
                        <div
                            class="action-card"
                            @click=${() => (0, utilities_1.navigate)('customer/action-item?actionItemId=NOT_SET&customerActionItemMode=CREATE')}
                        >
                            <div>Call someone for me</div>
                            <div>
                                <span class="material-icons icon-container">phone</span>
                            </div>
                        </div>
                    </div>

                    <div class="action-card-container">
                        <div class="action-card" @click=${() => (0, utilities_1.navigate)('customer/action-items')}>
                            <div>View My Action Items</div>
                            <div>
                                <span class="material-icons icon-container">message</span>
                            </div>
                        </div>
                    </div>

                    <div class="action-card-container">
                        <div class="action-card" @click=${() => (0, utilities_1.navigate)('customer/logs')}>
                            <div>View My Logs</div>
                            <div>
                                <span class="material-icons icon-container">list</span>
                            </div>
                        </div>
                    </div>

                    <div
                        class="action-card-container"
                        ?hidden=${(0, utilities_1.getIsCompanyOwner)(state.authenticatedUser, state.companyId) ===
            false}
                    >
                        <div class="action-card" @click=${() => (0, utilities_1.navigate)('customer/voicemail')}>
                            <div>Listen to My Voicemail</div>
                            <div>
                                <span class="material-icons icon-container">voicemail</span>
                            </div>
                        </div>
                    </div>

                    <div class="action-card-container">
                        <div
                            class="action-card"
                            @click=${() => (location.href = 'https://www.jillsoffice.com/outbound-request')}
                        >
                            <div>Request an Outbound Calling Campaign</div>
                            <div>
                                <span class="material-icons icon-container">wifi_calling</span>
                            </div>
                        </div>
                    </div>

                    <div class="action-card-container">
                        <div
                            class="action-card"
                            @click=${() => (location.href = 'https://www.jillsoffice.com/accessmasterclasses')}
                        >
                            <div>Watch Master Classes</div>
                            <div>
                                <span class="material-icons icon-container">school</span>
                            </div>
                        </div>
                    </div>
                </div>

                ${getAccountManagerCardHTML(state.userForDashboard)}
                ${getPersonalAdminCardHTML(state.userForDashboard)}
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-customer-dashboard', JOCustomerDashboard);
async function fetchUserForDashboard(userId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($userId: Int!) {
                    Users__JOCompanyDashboard: getUsers(id: $userId) {
                        id
                        first_name
                        companies {
                            id
                            name
                            account_manager {
                                id
                                auth_role
                                email
                                first_name
                                last_name
                                phone_number
                                profile_picture_url
                            }
                            personal_admin {
                                id
                                first_name
                                last_name
                                profile_picture_url
                            }
                        }
                    }
                }
            `, {
            userId,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        const userForDashboard = gqlResult.value.data.Users__JOCompanyDashboard;
        return {
            succeeded: true,
            value: userForDashboard,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchUserForDashboard', error);
    }
}
function getAccountManagerCardHTML(userForDashboard) {
    if (userForDashboard === 'NOT_SET') {
        return (0, lit_html_1.html) ``;
    }
    const accountManager = userForDashboard.companies[0]?.account_manager;
    return generateAccountManagerHtml(accountManager);
}
function generateAccountManagerHtml(accountManager) {
    if (accountManager === null || accountManager === undefined) {
        return (0, lit_html_1.html) ``;
    }
    return (0, lit_html_1.html) `
        <div class="account-manager-card-container">
            <div class="account-manager-card">
                <div style="display: flex; flex-direction: column; align-items: center;">
                    <div style="font-weight: bold;">Your Account Manager</div>
                    <div><i>(aka Your Jill BFF)</i></div>
                </div>

                <br />

                <div
                    style="display: flex; align-items: center; flex-direction: column; justify-content: space-evenly;"
                >
                    <img
                        src=${accountManager.profile_picture_url !== ''
        ? accountManager.profile_picture_url
        : constants_1.defaultJillAvatarUrl}
                        style="max-height: 15rem;"
                    />
                    <div style="padding-left: 1rem;">
                        <div style="padding-bottom: 0.25rem;">
                            ${accountManager.first_name} ${accountManager.last_name}
                        </div>
                        <div style="padding-bottom: 0.25rem;">
                            ${(0, utilities_1.formatE164PhoneNumberForDisplay)(accountManager.phone_number)}
                        </div>
                        <div>${accountManager.email}</div>
                    </div>
                </div>
            </div>
        </div>
    `;
}
function getPersonalAdminCardHTML(userForDashboard) {
    if (userForDashboard === 'NOT_SET') {
        return (0, lit_html_1.html) ``;
    }
    const personalAdmin = userForDashboard.companies[0]?.personal_admin;
    return generatePersonalAdminHtml(personalAdmin);
}
function generatePersonalAdminHtml(personalAdmin) {
    if (personalAdmin === null || personalAdmin === undefined) {
        return (0, lit_html_1.html) ``;
    }
    return (0, lit_html_1.html) `
        <div class="account-manager-card-container">
            <div class="account-manager-card">
                <div style="display: flex; flex-direction: column; align-items: center;">
                    <div style="font-weight: bold;">Your Personal Admin</div>
                    <div><i>(aka Your WOW Sidekick!)</i></div>
                </div>

                <br />

                <div
                    style="display: flex; align-items: center; flex-direction: column; justify-content: space-evenly;"
                >
                    <img
                        src=${personalAdmin.profile_picture_url !== ''
        ? personalAdmin.profile_picture_url
        : constants_1.defaultJillAvatarUrl}
                        style="max-height: 15rem;"
                    />
                    <div style="padding-left: 1rem;">
                        <div style="padding-bottom: 0.25rem;">${personalAdmin.first_name}</div>
                    </div>
                </div>
            </div>
        </div>
    `;
}
