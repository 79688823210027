"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoCustomizePriorityLevelPopUp = exports.PriorityLevelCustomizeModesEnum = void 0;
const element_vir_1 = require("element-vir");
const constants_and_types_queue_status_box_settings_1 = require("./constants-and-types-queue-status-box-settings");
const check_if_level_key_value_is_already_used_1 = require("./check-if-level-key-value-is-already-used");
const convert_snake_to_title_case_1 = require("../../../../utilities/strings/casings.ts/convert-snake-to-title-case");
var PriorityLevelCustomizeModesEnum;
(function (PriorityLevelCustomizeModesEnum) {
    PriorityLevelCustomizeModesEnum[PriorityLevelCustomizeModesEnum["CREATE"] = 0] = "CREATE";
    PriorityLevelCustomizeModesEnum[PriorityLevelCustomizeModesEnum["EDIT"] = 1] = "EDIT";
})(PriorityLevelCustomizeModesEnum = exports.PriorityLevelCustomizeModesEnum || (exports.PriorityLevelCustomizeModesEnum = {}));
exports.JoCustomizePriorityLevelPopUp = (0, element_vir_1.defineElement)()({
    tagName: 'jo-customize-priority-level-pop-up',
    styles: (0, element_vir_1.css) `
        .create-priority-container {
            display: flex;
            flex-direction: column;
            color: var(--jo-text-default);
            gap: 0.5rem;
            margin-bottom: 1rem;
        }

        .create-priority-container h4 {
            margin-bottom: 0.5rem;
        }

        .flex-row {
            display: flex;
            flex-direction: row;
            gap: 1rem;
        }

        .number-input {
            padding-bottom: 5px;
        }

        .label-input {
            font-size: 0.9rem;
            padding-top: 6px;
        }

        .input-warning-label {
            color: var(--jo-danger);
            height: 10px;
            text-align: center;
        }
    `,
    events: {
        updatePriorityLevel: (0, element_vir_1.defineElementEvent)(),
    },
    stateInit: {
        temporaryPriorityLevel: undefined,
        inputsToShowWarningOn: [],
    },
    renderCallback({ state, updateState, inputs, events, dispatch }) {
        if (!state.temporaryPriorityLevel) {
            updateState({
                temporaryPriorityLevel: inputs.priorityLevel,
            });
        }
        return (0, element_vir_1.html) `
            <!--TODO: Duplicate Inputs here that could be extracted out but I'm going to wait until we make these inputs using element-vir https://jillsoffice.atlassian.net/browse/JWA-2180-->
            <div class="create-priority-container">
                <h4>Conditions</h4>
                <div>Display If</div>
                <div class="flex-row">
                    <div class="label-input">Total Queue Items >=</div>
                        <div>
                            <jo-input
                                class="number-input"
                                .type=${'NUMBER'}
                                .inputValue=${inputs.priorityLevel.total_queue_items}
                                @inputchange=${(event) => {
            validateTotalQueueItemsInput(event, 'total_queue_items');
            const updatedPriorityLevel = {
                ...state.temporaryPriorityLevel,
                total_queue_items: parseInt(event.detail.searchText),
            };
            updateAndDispatchChanges(updatedPriorityLevel);
        }}
                            ></jo-input>                          
                        </div>
                    </div>

                    <div class="input-warning-label">
                            ${getInputLabelWarningText('total_queue_items', state.inputsToShowWarningOn)}
                    </div>

                    <div>OR</div>
                    <div class="flex-row">
                        <div class="label-input">Highest Pod Count >=</div>
                        <div>
                            <jo-input
                                class="number-input"
                                .type=${'NUMBER'}
                                .inputValue=${inputs.priorityLevel.highest_pod_count}
                                @inputchange=${(event) => {
            validateTotalQueueItemsInput(event, 'highest_pod_count');
            const updatedPriorityLevel = {
                ...state.temporaryPriorityLevel,
                highest_pod_count: parseInt(event.detail.searchText),
            };
            updateAndDispatchChanges(updatedPriorityLevel);
        }}
                            ></jo-input>
                        </div>                         
                    </div>

                    <div class="input-warning-label">
                        ${getInputLabelWarningText('highest_pod_count', state.inputsToShowWarningOn)}
                    </div>

                    <h4>Style</h4>
                    <div class="flex-row">
                        <div class="label-input">Flash Level</div>
                        <jo-input
                            .type=${'SELECT'}
                            .selectOptions=${Object.keys(constants_and_types_queue_status_box_settings_1.QueueStatusBoxFlashLevel)}
                            .selectedValue=${constants_and_types_queue_status_box_settings_1.QueueStatusBoxFlashLevel[inputs.priorityLevel.flash_level || 'NONE']}
                            @valueChanged=${(event) => {
            const updatedPriorityLevel = {
                ...state.temporaryPriorityLevel,
                flash_level: event.detail.selectedValue,
            };
            updateAndDispatchChanges(updatedPriorityLevel);
        }}
                        ></jo-input>
                    </div>
                    <div class="flex-row">
                        <div class="label-input">Background Color</div>
                        <input
                            type="color"
                            .value=${inputs.priorityLevel.background_color}
                            @input=${(event) => {
            const updatedPriorityLevel = {
                ...state.temporaryPriorityLevel,
                background_color: event.target.value,
            };
            updateAndDispatchChanges(updatedPriorityLevel);
        }}
                        />
                    </div>
                    <div class="flex-row">
                        <div class="label-input">Text Color</div>
                        <input
                            type="color"
                            .value=${inputs.priorityLevel.text_color}
                            @input=${(event) => {
            const updatedPriorityLevel = {
                ...state.temporaryPriorityLevel,
                text_color: event.target.value,
            };
            updateAndDispatchChanges(updatedPriorityLevel);
        }}
                        />
                    </div>
                </div>
            </div>
        `;
        function validateTotalQueueItemsInput(event, key) {
            const result = (0, check_if_level_key_value_is_already_used_1.isPriorityLevelKeyValueAlreadyUsed)(parseInt(event.detail.searchText), key, inputs.priorityLevel, inputs.queuePriorityLevels);
            let newListOfInputs = state.inputsToShowWarningOn;
            if (result) {
                newListOfInputs.push(key);
            }
            else {
                newListOfInputs = newListOfInputs.filter(item => item != key);
            }
            updateState({
                inputsToShowWarningOn: newListOfInputs,
            });
        }
        function updateAndDispatchChanges(updatePriorityLevel) {
            updateState({
                temporaryPriorityLevel: updatePriorityLevel,
            });
            if (state.temporaryPriorityLevel)
                dispatch(new events.updatePriorityLevel(state.temporaryPriorityLevel));
        }
    },
});
function getInputLabelWarningText(inputKey, inputsToShowWarningOn) {
    if (inputsToShowWarningOn.includes(inputKey)) {
        return `This ${(0, convert_snake_to_title_case_1.convertSnakeToTitleCase)(inputKey)} amount is already being used`;
    }
    return '';
}
