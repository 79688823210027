"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const css_utils_1 = require("../../../utilities/css-utils");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
require("../jo-button.element");
require("../jo-input");
require("./jo-quick-text");
const common_1 = require("@augment-vir/common");
const InitialState = {
    authenticatedUser: 'NOT_SET',
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-quick-text-dashboard');
const cssNames = (classNames) => classNames.split(' ').map(cssName).join(' ');
class JOQuickTextDashboard extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .${cssNames('main-container')} {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    display: flex;
                    flex-wrap: wrap;
                }

                .${cssNames('sub-container')} {
                    width: 50%;
                }
            </style>

            <div class="${cssNames('main-container')}">
                <div class="${cssNames('sub-container')}">
                    <jo-quick-text
                        .props=${{
            authenticatedUser: state.authenticatedUser,
            title: 'Action Item Titles',
            globalConfigField: 'ai_quick_titles',
        }}
                    ></jo-quick-text>
                </div>

                <div class="${cssNames('sub-container')}">
                    <jo-quick-text
                        .props=${{
            authenticatedUser: state.authenticatedUser,
            title: 'Action Item Notes',
            textType: 'LONG',
            globalConfigField: 'ai_quick_notes',
        }}
                    ></jo-quick-text>
                </div>

                <div class="${cssNames('sub-container')}">
                    <jo-quick-text
                        .props=${{
            authenticatedUser: state.authenticatedUser,
            title: 'Flag AI Titles',
            globalConfigField: 'flag_quick_titles',
        }}
                    ></jo-quick-text>
                </div>

                <div class="${cssNames('sub-container')}">
                    <jo-quick-text
                        .props=${{
            authenticatedUser: state.authenticatedUser,
            title: 'Chat Notes',
            textType: 'LONG',
            globalConfigField: 'chat_quick_notes',
        }}
                    ></jo-quick-text>
                </div>

                <div class="${cssNames('sub-container')}">
                    <jo-quick-text
                        .props=${{
            authenticatedUser: state.authenticatedUser,
            title: 'Industries',
            globalConfigField: 'industries',
        }}
                    ></jo-quick-text>
                </div>

                <div class="${cssNames('sub-container')}">
                    <jo-quick-text
                        .props=${{
            authenticatedUser: state.authenticatedUser,
            title: 'Niches',
            globalConfigField: 'niches',
        }}
                    ></jo-quick-text>
                </div>

                <div class="${cssNames('sub-container')}">
                    <jo-quick-text
                        .props=${{
            authenticatedUser: state.authenticatedUser,
            title: 'Referral Sources',
            globalConfigField: 'referral_sources',
        }}
                    ></jo-quick-text>
                </div>

                <div class="${cssNames('sub-container')}">
                    <jo-quick-text
                        .props=${{
            authenticatedUser: state.authenticatedUser,
            title: 'Referral Tags',
            globalConfigField: 'referral_tags',
        }}
                    ></jo-quick-text>
                </div>

                <div class="${cssNames('sub-container')}">
                    <jo-quick-text
                        .props=${(0, common_1.ensureType)({
            authenticatedUser: state.authenticatedUser,
            title: 'Account Help Ticket Titles',
            globalConfigField: 'account_help_ticket_quick_titles',
        })}
                    ></jo-quick-text>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-quick-text-dashboard', JOQuickTextDashboard);
