"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const store_1 = require("../../services/store");
const utilities_1 = require("../../services/utilities");
const auth_rules_1 = require("../../utilities/auth-rules");
const paths_1 = require("../../utilities/routes/paths");
// We need to import this statically for now, because of something to do with the properties from the global store
// being bound before the call screen upgrades. It's a problem I've seen before multiple times, this will fix it for now
// TODO we probably want to rename the call screen to action-item
const element_vir_1 = require("element-vir");
const jo_company_logs_container_page_1 = require("../main-pages/billing-logs/logs-container-page/jo-company-logs-container-page");
const replace_not_set_with_undefined_1 = require("../../utilities/type-helpers/replace-not-set-with-undefined/replace-not-set-with-undefined");
const css_utils_1 = require("../../utilities/css-utils");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const ensure_type_1 = require("../../utilities/types/ensure-type");
require("./jo-call-screen/jo-call-screen/jo-call-screen");
const jo_account_help_table_element_1 = require("./jo-account-help-table/jo-account-help-table.element");
const is_defined_1 = require("../../utilities/is-defined");
const profile_settings_page_element_1 = require("../main-pages/profile-settings-page/profile-settings-page.element");
const jo_company_custom_hours_element_1 = require("./jo-company-custom-hours/jo-company-custom-hours.element");
const jo_queue_status_box_settings_page_element_1 = require("../main-pages/settings-pages/queue-status-box-settings-page/jo-queue-status-box-settings-page.element");
const InitialState = {
    authenticatedUser: store_1.InitialGlobalState.authenticatedUser,
    currentCallInformation: store_1.InitialGlobalState.currentCallInformation,
    route: store_1.InitialGlobalState.route,
    twilioDevice: store_1.InitialGlobalState.twilioDevice,
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-router-jill');
class JORouterJill extends HTMLElement {
    constructor() {
        super(...arguments);
        //NOTE: we cannot use shadow dom on this element because a function in twilio.ts is grabbing jo-call-screen by its id
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    render(state) {
        return (0, element_vir_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .jo-router-jill---main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .jo-router-jill---jo-home-container {
                    box-sizing: border-box;
                    height: 100%;
                    width: 100%;
                    padding: 1rem;
                }

                .${cssName('basic-padding')} {
                    padding: 2rem;
                }
            </style>

            <div class="jo-router-jill---main-container">
                ${window.customElements.get('jo-home')
            ? (0, element_vir_1.html) `
                          <div
                              class="jo-router-jill---jo-home-container"
                              ?hidden=${state.route.pathname !== '/'}
                          >
                              <jo-home
                                  .props=${{
                authenticatedUser: state.authenticatedUser,
            }}
                              ></jo-home>
                          </div>
                      `
            : ''}
                <jo-billing-report
                    ?hidden=${state.route.pathname !== '/billing-report'}
                ></jo-billing-report>
                <jo-control-panel
                    ?hidden=${state.route.pathname !== '/control-panel'}
                ></jo-control-panel>
                ${state.route.pathname === '/incoming-queue-report'
            ? (0, element_vir_1.html) `
                          <jo-incoming-queue-report></jo-incoming-queue-report>
                      `
            : ''}
                <jo-graphql-playground
                    ?hidden=${state.route.pathname !== '/graphql'}
                ></jo-graphql-playground>
                <jo-jills
                    ?hidden=${state.route.pathname !== '/jills'}
                    .authenticatedUser=${state.authenticatedUser}
                ></jo-jills>
                <jo-teams
                    ?hidden=${state.route.pathname !== '/teams'}
                    .authenticatedUser=${state.authenticatedUser}
                ></jo-teams>
                <jo-high-level-report
                    ?hidden=${state.route.pathname !== '/high-level-report'}
                ></jo-high-level-report>
                ${state.route.pathname !== '/action-item'
            ? ''
            : (0, element_vir_1.html) `
                          <jo-call-screen
                              id="jo-call-screen"
                              .props=${{
                actionItemId: state.route.actionItemId,
                authenticatedUser: state.authenticatedUser,
                callId: state.route.callId,
                callAnswered: state.currentCallInformation.callAnswered,
                callIncoming: state.currentCallInformation.callIncoming,
                canInitiateNewTransfer: state.currentCallInformation.canInitiateNewTransfer,
                callScreenMode: state.route.callScreenMode,
                chatId: 'NOT_SET',
                companyId: state.route.companyId,
                direction: state.currentCallInformation.direction,
                incomingCallType: state.currentCallInformation.incomingCallType,
                incomingConnection: state.currentCallInformation.incomingConnection,
                jillCallSid: state.currentCallInformation.jillCallSid,
                outboundAttemptId: state.route.outboundAttemptId,
                returnPath: state.currentCallInformation.returnPath,
                twilioDevice: state.twilioDevice,
                userCallSid: state.currentCallInformation.userCallSid,
            }}
                          ></jo-call-screen>
                      `}
                <div style="padding: 2rem" ?hidden=${state.route.pathname !== '/companies'}>
                    <jo-companies
                        @loaded=${(e) => (0, utilities_1.raiseCustomEvent)(this, 'jo-companies-loaded', e.detail)}
                    ></jo-companies>
                </div>
                <div
                    style="padding: 2rem"
                    ?hidden=${state.route.pathname !== '/personal-admin-dashboard'}
                >
                    <jo-personal-admin-dashboard
                        .authenticatedUser=${state.authenticatedUser}
                    ></jo-personal-admin-dashboard>
                </div>
                ${(0, element_vir_1.renderIf)(state.route.pathname === '/account-help', (0, element_vir_1.html) `
                        <div style="padding: 2rem">
                            <div class="jo-global--card">
                                <${jo_account_help_table_element_1.JoAccountHelpTable}></${jo_account_help_table_element_1.JoAccountHelpTable}>
                            </div>
                        </div>
                    `)}
                ${state.route.pathname === '/pods'
            ? (0, element_vir_1.html) `
                          <div style="padding: 2rem" ?hidden=${state.route.pathname !== '/pods'}>
                              <jo-pods .authenticatedUser=${state.authenticatedUser}></jo-pods>
                          </div>
                      `
            : ''}
                ${window.customElements.get('jo-jill')
            ? (0, element_vir_1.html) `
                          <jo-jill
                              ?hidden=${state.route.pathname !== '/jill'}
                              .authenticatedUser=${state.authenticatedUser}
                              .showCalls=${true}
                              .showFlagsTable=${true}
                              .showAccountHelpTable=${true}
                              .jillId=${state.route.jillId}
                          ></jo-jill>
                      `
            : ''}
                ${window.customElements.get('jo-chat-dashboard') &&
            (0, auth_rules_1.hasPermission)(state.authenticatedUser, 'has_chat_access')
            ? (0, element_vir_1.html) `
                          <jo-chat-dashboard
                              ?hidden=${state.route.pathname !== '/chat-dashboard'}
                              .props=${{
                authenticatedUser: state.authenticatedUser,
                twilioDevice: state.twilioDevice,
                pathname: state.route.pathname,
            }}
                          ></jo-chat-dashboard>
                      `
            : ''}
                ${window.customElements.get('jo-chat-settings') &&
            (0, auth_rules_1.isJillAtOrAboveAuthRole)(state.authenticatedUser, 'JILL_MANAGER')
            ? (0, element_vir_1.html) `
                          <jo-chat-settings
                              ?hidden=${state.route.pathname !== '/chat-settings'}
                              .authenticatedUser=${state.authenticatedUser}
                          ></jo-chat-settings>
                      `
            : ''}
                ${window.customElements.get('jo-team')
            ? (0, element_vir_1.html) `
                          <jo-team
                              ?hidden=${state.route.pathname !== '/team'}
                              .teamId=${state.route.teamId}
                              .authenticatedUser=${state.authenticatedUser}
                              .showFlaggedActionItemsTable=${state.route.pathname === '/team'}
                          ></jo-team>
                      `
            : ''}
                ${window.customElements.get('jo-register-company') &&
            state.route.pathname === '/register-company'
            ? (0, element_vir_1.html) `
                          <div style="padding: 2rem;">
                              <jo-register-company
                                  .props=${{
                authenticatedUser: state.authenticatedUser,
            }}
                              ></jo-register-company>
                          </div>
                      `
            : ''}
                ${window.customElements.get('jo-quick-text-dashboard') &&
            state.route.pathname === '/quick-text-dashboard'
            ? (0, element_vir_1.html) `
                          <jo-quick-text-dashboard
                              .props=${{
                authenticatedUser: state.authenticatedUser,
            }}
                          ></jo-quick-text-dashboard>
                      `
            : ''}
                ${window.customElements.get('jo-outbound-dashboard') &&
            state.route.pathname === '/outbound-dashboard'
            ? (0, element_vir_1.html) `
                          <jo-outbound-dashboard
                              .props=${{
                authenticatedUser: state.authenticatedUser,
            }}
                          ></jo-outbound-dashboard>
                      `
            : ''}
                ${window.customElements.get('jo-company-nav-bar')
            ? (0, element_vir_1.html) `
                          <div
                              style="padding: 2rem"
                              ?hidden=${state.route.pathname.includes('/company') === false}
                          >
                              <jo-company-nav-bar
                                  .authenticatedUser=${state.authenticatedUser}
                                  .companyId=${state.route.companyId}
                                  .selectedMainOption=${state.route.selectedCompanyNavBarMainOption}
                                  .selectedSecondaryOption=${state.route
                .selectedCompanyNavBarSecondaryOption}
                              ></jo-company-nav-bar>
                          </div>
                      `
            : ''}
                ${window.customElements.get('jo-company')
            ? (0, element_vir_1.html) `
                          <jo-company
                              ?hidden=${state.route.pathname !== '/company'}
                              .companyId=${state.route.companyId}
                          ></jo-company>
                      `
            : ''}
                ${window.customElements.get('jo-company-crew-cal-management')
            ? (0, element_vir_1.html) `
                          <jo-company-crew-cal-management
                              ?hidden=${state.route.pathname !==
                '/company/crew-cal/crew-cal-management'}
                              .companyId=${state.route.companyId}
                          ></jo-company-crew-cal-management>
                      `
            : ''}
                ${window.customElements.get('jo-google-calendars-for-company')
            ? (0, element_vir_1.html) `
                          <jo-google-calendars-for-company
                              ?hidden=${state.route.pathname !== '/company/crew-cal/calendars'}
                              .companyId=${state.route.companyId}
                              .authenticatedUser=${state.authenticatedUser}
                          ></jo-google-calendars-for-company>
                      `
            : ''}
                ${window.customElements.get('jo-company-details')
            ? (0, element_vir_1.html) `
                          <div
                              style="padding: 0rem 2rem 2rem 2rem"
                              ?hidden=${state.route.pathname !== '/company/company-details'}
                          >
                              <jo-company-details
                                  .props=${{
                authenticatedUser: state.authenticatedUser,
                companyId: state.route.companyId,
            }}
                              ></jo-company-details>
                          </div>
                      `
            : ''}
                ${window.customElements.get('jo-company-user-activity-report') &&
            state.route.pathname === paths_1.companyUserActivityRoutePath
            ? (0, element_vir_1.html) `
                          <jo-company-user-activity-report
                              .authenticatedUser=${state.authenticatedUser}
                              .companyId=${state.route.companyId}
                          ></jo-company-user-activity-report>
                      `
            : ''}
                ${window.customElements.get('jo-company-employees')
            ? (0, element_vir_1.html) `
                          <div
                              style="padding: 0rem 2rem 2rem 2rem"
                              ?hidden=${state.route.pathname !== '/company/employees'}
                          >
                              <jo-company-employees
                                  .authenticatedUser=${state.authenticatedUser}
                                  .companyId=${state.route.companyId}
                              ></jo-company-employees>
                          </div>
                      `
            : ''}
                ${window.customElements.get('jo-company-employee') &&
            state.route.pathname.includes('/company/employee/')
            ? (0, element_vir_1.html) `
                          <div style="padding: 0rem 2rem 2rem 2rem">
                              <jo-company-employee
                                  .props=${{
                authenticatedUser: state.authenticatedUser,
                companyId: state.route.companyId,
                employeeId: state.route.employeeId,
            }}
                              ></jo-company-employee>
                          </div>
                      `
            : ''}
                ${window.customElements.get('jo-users')
            ? (0, element_vir_1.html) `
                          <div style="padding: 2rem;" ?hidden=${state.route.pathname !== '/users'}>
                              <jo-users .authenticatedUser=${state.authenticatedUser}></jo-users>
                          </div>
                      `
            : ''}
                ${window.customElements.get('jo-user')
            ? (0, element_vir_1.html) `
                          ${(0, auth_rules_1.isJillAtOrAboveAuthRole)(state.authenticatedUser, 'JILL_MANAGER') ||
                (0, utilities_1.hasPermissionToManageJillProfilePictures)(state.authenticatedUser)
                ? (0, element_vir_1.html) `
                                    <div
                                        style="padding: 2rem;"
                                        ?hidden=${state.route.pathname !== '/user' &&
                    state.route.pathname !== '/user/create'}
                                    >
                                        <jo-user
                                            .authenticatedUser=${state.authenticatedUser}
                                            .userId=${state.route.userId}
                                            .mode=${state.route.userMode}
                                        ></jo-user>
                                    </div>
                                `
                : ''}
                      `
            : ''}
                ${window.customElements.get('jo-company-contacts')
            ? (0, element_vir_1.html) `
                          <div
                              style="padding: 0rem 2rem 2rem 2rem"
                              ?hidden=${state.route.pathname !== '/company/contact-list'}
                          >
                              <jo-company-contacts
                                  .authenticatedUser=${state.authenticatedUser}
                                  .companyId=${state.route.companyId}
                              ></jo-company-contacts>
                          </div>
                      `
            : ''}
                ${window.customElements.get('jo-action-items')
            ? (0, element_vir_1.html) `
                          <div
                              style="padding: 0rem 2rem 2rem 2rem"
                              ?hidden=${state.route.pathname !== '/company/action-items'}
                          >
                              <jo-action-items
                                  .authenticatedUser=${state.authenticatedUser}
                                  .companyId=${state.route.companyId}
                              ></jo-action-items>
                          </div>
                      `
            : ''}
                ${window.customElements.get('jo-company-call-instruction')
            ? (0, element_vir_1.html) `
                          <div
                              style="padding: 0rem 2rem 2rem 2rem"
                              ?hidden=${state.route.pathname !== '/company/call-instruction'}
                          >
                              <jo-company-call-instruction
                                  .companyId=${state.route.companyId}
                                  .callInstructionId=${state.route.callInstructionId}
                              ></jo-company-call-instruction>
                          </div>
                      `
            : ''}
                ${window.customElements.get('jo-jill-high-level-report')
            ? (0, element_vir_1.html) `
                          ${(0, utilities_1.authorizedRender)([
                'JILL_BUSINESS_DEVELOPMENT',
                'JILL_EXECUTIVE',
                'JILL_MANAGER',
            ], state.authenticatedUser, (0, element_vir_1.html) `
                                  <div
                                      style="padding: 2rem;"
                                      ?hidden=${state.route.pathname !== '/jill-high-level-report'}
                                  >
                                      <jo-jill-high-level-report
                                          ?hidden=${state.route.pathname !==
                '/jill-high-level-report'}
                                      ></jo-jill-high-level-report>
                                  </div>
                              `)}
                      `
            : ''}
                ${window.customElements.get('jo-contact-save') &&
            state.route.pathname === '/company/contact'
            ? (0, element_vir_1.html) `
                          <div style="padding: 0 2rem 2rem 2rem">
                              <jo-contact-save
                                  .authenticatedUser=${state.authenticatedUser}
                                  .companyId=${state.route.companyId}
                                  .contactId=${state.route.contactId}
                              ></jo-contact-save>
                          </div>
                      `
            : ''}
                ${window.customElements.get('jo-company-call-instructions-general-setup')
            ? (0, element_vir_1.html) `
                          <div
                              style="padding: 0rem 2rem 2rem 2rem"
                              ?hidden=${state.route.pathname !==
                '/company/call-instructions/general-setup'}
                          >
                              <jo-company-call-instructions-general-setup
                                  .props=${{
                authenticatedUser: state.authenticatedUser,
                companyId: state.route.companyId,
            }}
                              ></jo-company-call-instructions-general-setup>
                          </div>
                      `
            : ''}
                ${window.customElements.get('jo-company-call-instructions-pages')
            ? (0, element_vir_1.html) `
                          <div
                              style="padding: 0rem 2rem 2rem 2rem"
                              ?hidden=${state.route.pathname !==
                '/company/call-instructions/call-instruction-pages'}
                          >
                              <jo-company-call-instructions-pages
                                  .companyId=${state.route.companyId}
                              ></jo-company-call-instructions-pages>
                          </div>
                      `
            : ''}
                ${window.customElements.get('jo-company-services-pricing')
            ? (0, element_vir_1.html) `
                          <div
                              style="padding: 0rem 2rem 2rem 2rem"
                              ?hidden=${state.route.pathname !==
                '/company/call-instructions/services-pricing'}
                          >
                              <jo-company-services-pricing
                                  .companyId=${state.route.companyId}
                                  .authenticatedUser=${state.authenticatedUser}
                              ></jo-company-services-pricing>
                          </div>
                      `
            : ''}
                ${window.customElements.get('jo-company-call-section-notes')
            ? (0, element_vir_1.html) `
                          <div
                              style="padding: 0rem 2rem 2rem 2rem"
                              ?hidden=${state.route.pathname !==
                '/company/call-instructions/call-section-notes'}
                          >
                              <jo-company-call-section-notes
                                  .companyId=${state.route.companyId}
                                  .authenticatedUser=${state.authenticatedUser}
                              ></jo-company-call-section-notes>
                          </div>
                      `
            : ''}
                ${window.customElements.get('jo-company-transfers')
            ? (0, element_vir_1.html) `
                          <div
                              style="padding: 0rem 2rem 2rem 2rem"
                              ?hidden=${state.route.pathname !==
                '/company/call-instructions/transfers'}
                          >
                              <jo-company-transfers
                                  .companyId=${state.route.companyId}
                                  .authenticatedUser=${state.authenticatedUser}
                              ></jo-company-transfers>
                          </div>
                      `
            : ''}
                ${window.customElements.get('jo-company-quick-notes')
            ? (0, element_vir_1.html) `
                          <div
                              style="padding: 0rem 2rem 2rem 2rem"
                              ?hidden=${state.route.pathname !==
                '/company/call-instructions/quick-notes'}
                          >
                              <jo-company-quick-notes
                                  .companyId=${state.route.companyId}
                                  .authenticatedUser=${state.authenticatedUser}
                              ></jo-company-quick-notes>
                          </div>
                      `
            : ''}
                ${window.customElements.get('jo-chat-widget-settings') &&
            (0, auth_rules_1.isJillAtOrAboveAuthRole)(state.authenticatedUser, 'JILL_MANAGER') &&
            state.route.pathname === '/company/call-instructions/chat-widget-script-generator'
            ? (0, element_vir_1.html) `
                          <jo-chat-widget-settings
                              .props=${(0, ensure_type_1.ensureType)({
                authenticatedUser: state.authenticatedUser,
                companyId: state.route.companyId,
            })}
                          ></jo-chat-widget-settings>
                      `
            : ''}
                ${window.customElements.get('jo-billing')
            ? (0, element_vir_1.html) `
                          <div
                              style="padding: 0rem 2rem 2rem 2rem"
                              ?hidden=${state.route.pathname !== '/company/billing'}
                          >
                              <jo-billing
                                  .props=${{
                authenticatedUser: state.authenticatedUser,
                companyId: state.route.companyId,
            }}
                              ></jo-billing>
                          </div>
                      `
            : ''}
                ${window.customElements.get(jo_company_logs_container_page_1.JoCompanyLogsContainerPage.tagName) &&
            state.route.pathname === '/company/billing/logs'
            ? (0, element_vir_1.html) `
                          <div style="padding: 0rem 2rem 2rem 2rem">
                              <jo-company-logs-container-page
                                  ${(0, element_vir_1.assign)(jo_company_logs_container_page_1.JoCompanyLogsContainerPage, {
                authenticatedUser: (0, replace_not_set_with_undefined_1.replaceNotSetWithUndefined)(state.authenticatedUser),
                isMobileDevice: false,
                companyId: (0, replace_not_set_with_undefined_1.replaceNotSetWithUndefined)(state.route.companyId),
            })}
                              ></jo-company-logs-container-page>
                          </div>
                      `
            : ''}
                ${window.customElements.get('jo-yearly-report')
            ? (0, element_vir_1.html) `
                          <div style="padding: 2rem;">
                              <jo-yearly-report
                                  ?hidden=${state.route.pathname !== '/yearly-report'}
                              ></jo-yearly-report>
                          </div>
                      `
            : ''}
                ${window.customElements.get('jo-jills-pods') &&
            state.route.pathname === '/jills-pods'
            ? (0, element_vir_1.html) `
                          <div style="padding: 2rem;">
                              <jo-jills-pods
                                  .authenticatedUser=${state.authenticatedUser}
                              ></jo-jills-pods>
                          </div>
                      `
            : ''}
                ${window.customElements.get('jo-change-user-password')
            ? (0, element_vir_1.html) `
                          <div style="padding: 2rem;">
                              ${state.route.pathname === '/change-user-password'
                ? (0, element_vir_1.html) `
                                        <jo-change-user-password
                                            .authenticatedUser=${state.authenticatedUser}
                                        ></jo-change-user-password>
                                    `
                : ''}
                          </div>
                      `
            : ''}
                ${window.customElements.get('jo-billing-packages')
            ? (0, element_vir_1.html) `
                          <div
                              style="padding: 2rem;"
                              ?hidden=${state.route.pathname !== '/billing-packages'}
                          >
                              <jo-billing-packages
                                  .authenticatedUser=${state.authenticatedUser}
                              ></jo-billing-packages>
                          </div>
                      `
            : ''}
                ${window.customElements.get('jo-minutes-package')
            ? (0, element_vir_1.html) `
                          ${state.route.pathname.includes('/minutes-package')
                ? (0, element_vir_1.html) `
                                    <div style="padding: 2rem;">
                                        <jo-minutes-package
                                            .props=${{
                    authenticatedUser: state.authenticatedUser,
                    minutesPackageId: state.route.minutesPackageId,
                    mode: state.route.minutesPackageMode,
                }}
                                        ></jo-minutes-package>
                                    </div>
                                `
                : ''}
                      `
            : ''}
                ${window.customElements.get('jo-invoice')
            ? (0, element_vir_1.html) `
                          <div
                              style="padding: 0rem 2rem 2rem 2rem"
                              ?hidden=${state.route.pathname !== '/company/billing/invoice'}
                          >
                              <jo-invoice
                                  .props=${{
                authenticatedUser: state.authenticatedUser,
                companyId: state.route.companyId,
            }}
                              ></jo-invoice>
                          </div>
                      `
            : ''}
                ${window.customElements.get('jo-company-voicemails')
            ? (0, element_vir_1.html) `
                          <div
                              style="padding: 0rem 2rem 2rem 2rem"
                              ?hidden=${state.route.pathname !== '/company/voicemails'}
                          >
                              <jo-company-voicemails
                                  .props=${{
                authenticatedUser: state.authenticatedUser,
                companyId: state.route.companyId,
            }}
                              ></jo-company-voicemails>
                          </div>
                      `
            : ''}
                ${window.customElements.get('jo-chat-stats-overview')
            ? (0, element_vir_1.html) `
                          <div
                              class="${cssName('basic-padding')}"
                              ?hidden=${state.route.pathname !== '/chat-stats-overview'}
                          >
                              <jo-chat-stats-overview></jo-chat-stats-overview>
                          </div>
                      `
            : ''}
                ${window.customElements.get('jo-action-item-flags-table') &&
            state.route.pathname === '/company/flagged-action-items'
            ? (0, element_vir_1.html) `
                          <div style="padding: 0rem 2rem 2rem 2rem">
                              <div class="jo-global--card">
                                  <jo-action-item-flags-table
                                      .props=${{
                authenticatedUser: state.authenticatedUser,
                companyId: state.route.companyId,
                csvTitle: `Flagged Action Items -- Company <id: ${state.route.companyId}>`,
            }}
                                  ></jo-action-item-flags-table>
                              </div>
                          </div>
                      `
            : ''}
                ${(0, element_vir_1.renderIf)(state.route.pathname === '/company/account-help', (0, element_vir_1.html) `
                        <div style="padding: 0rem 2rem 2rem 2rem">
                            <div class="jo-global--card">
                                <${jo_account_help_table_element_1.JoAccountHelpTable}
                                    ${(0, element_vir_1.assign)(jo_account_help_table_element_1.JoAccountHelpTable, {
            companyId: (0, replace_not_set_with_undefined_1.replaceNotSetWithUndefined)(state.route.companyId),
        })}
                                ></${jo_account_help_table_element_1.JoAccountHelpTable}>
                            </div>
                        </div>
                    `)}
                ${window.customElements.get('jo-scheduled-down-times') &&
            state.route.pathname === '/scheduled-down-times'
            ? (0, element_vir_1.html) `
                          <div style="padding: 2rem">
                              <jo-scheduled-down-times
                                  .props=${{
                authenticatedUser: state.authenticatedUser,
            }}
                              ></jo-scheduled-down-times>
                          </div>
                      `
            : ''}
                ${(0, element_vir_1.renderIf)(state.route.pathname === '/company/custom-hours', (0, element_vir_1.html) `
                        <div style="padding: 2rem">
                            <${jo_company_custom_hours_element_1.JoCompanyCustomHours}
                                ${(0, element_vir_1.assign)(jo_company_custom_hours_element_1.JoCompanyCustomHours, {
            companyId: (0, replace_not_set_with_undefined_1.replaceNotSetWithUndefined)(state.route.companyId),
        })}
                            ></${jo_company_custom_hours_element_1.JoCompanyCustomHours}>
                        </div>
                    `)}
                ${(0, element_vir_1.renderIf)((0, is_defined_1.isDefined)(window.customElements.get('jo-profile-settings-page')) &&
            state.route.pathname === '/profile-settings', (0, element_vir_1.html) `
                        <jo-profile-settings-page
                            ${(0, element_vir_1.assign)(profile_settings_page_element_1.JoProfileSettingsPage, {
            authenticatedUser: (0, replace_not_set_with_undefined_1.replaceNotSetWithUndefined)(state.authenticatedUser),
        })}
                        ></jo-profile-settings-page>
                    `)}
                ${(0, element_vir_1.renderIf)((0, is_defined_1.isDefined)(window.customElements.get('jo-queue-status-box-settings-page')) &&
            state.route.pathname === '/queue-settings', (0, element_vir_1.html) `
                        <jo-queue-status-box-settings-page
                            ${(0, element_vir_1.assign)(jo_queue_status_box_settings_page_element_1.JoQueueStatusBoxSettingsPage, {
            authenticatedUser: (0, replace_not_set_with_undefined_1.replaceNotSetWithUndefined)(state.authenticatedUser),
        })}
                        ></jo-queue-status-box-settings-page>
                    `)}
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-router-jill', JORouterJill);
