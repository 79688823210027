"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.prependToList = void 0;
function prependToList(list, thingToPrepend) {
    return [
        thingToPrepend,
        ...list,
    ];
}
exports.prependToList = prependToList;
