"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateManuallyAssignedInvoiceStatus = exports.deleteInvoiceLineItem = exports.updateInvoiceLineItem = exports.createInvoiceLineItem = exports.fetchInvoice = void 0;
const graphql_1 = require("../../../../services/graphql");
const error_handling_1 = require("../../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function fetchInvoice(companyId, date) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!, $date: DateTime!) {
                    findInvoices(
                        filter: {
                            and: [
                                {company_id: {eq: $companyId}}
                                {start_date: {le: $date}}
                                {end_date: {gt: $date}}
                            ]
                        }
                    ) {
                        items {
                            id
                            billing_cycle_type
                            end_date
                            invoice_line_items {
                                id
                                amount
                                description
                            }
                            start_date
                            stripe_id
                            stripe_invoice {
                                totalCharged
                                totalPaid
                            }
                            status
                            manually_assigned_status
                        }
                    }
                }
            `, {
            companyId,
            date,
        });
        const invoice = gqlResult.data.findInvoices.items[0];
        if (invoice === undefined) {
            return {
                succeeded: true,
                value: 'NOT_FOUND',
            };
        }
        return {
            succeeded: true,
            value: invoice,
        };
    }
    catch (error) {
        return {
            succeeded: false,
            userMessage: 'Error getting invoice',
            developerMessage: `fetchInvoice error: ${error}`,
        };
    }
}
exports.fetchInvoice = fetchInvoice;
async function createInvoiceLineItem(invoiceId, description, amountInCents) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($invoiceId: Int!, $description: String!, $amountInCents: Int!) {
                    createInvoice_line_items(
                        input: {
                            invoice: {connect: {id: $invoiceId}}
                            description: $description
                            amount: $amountInCents
                        }
                    ) {
                        id
                    }
                }
            `, {
            invoiceId,
            description,
            amountInCents,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('createInvoiceLineItem', error);
    }
}
exports.createInvoiceLineItem = createInvoiceLineItem;
async function updateInvoiceLineItem(invoiceLineItemId, description, amountInCents) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($invoiceLineItemId: Int!, $description: String!, $amountInCents: Int!) {
                    updateInvoice_line_items(
                        input: {
                            id: $invoiceLineItemId
                            description: $description
                            amount: $amountInCents
                        }
                    ) {
                        id
                    }
                }
            `, {
            invoiceLineItemId,
            description,
            amountInCents,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('updateInvoiceLineItem', error);
    }
}
exports.updateInvoiceLineItem = updateInvoiceLineItem;
async function deleteInvoiceLineItem(invoiceLineItemId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($invoiceLineItemId: Int!) {
                    deleteInvoice_line_items(input: {id: $invoiceLineItemId}) {
                        id
                    }
                }
            `, {
            invoiceLineItemId,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        return { succeeded: true };
    }
    catch (error) {
        throw new Error('jo-billing -> jo-invoice -> queries-and-mutations.ts  deleteInvoiceLineItem() ' +
            error);
    }
}
exports.deleteInvoiceLineItem = deleteInvoiceLineItem;
async function updateManuallyAssignedInvoiceStatus(status, invoiceId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($status: MANUALLY_ASSIGNED_INVOICE_STATUS, $invoiceId: Int!) {
                    updateInvoices(input: {id: $invoiceId, manually_assigned_status: $status}) {
                        id
                    }
                }
            `, {
            status,
            invoiceId,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        return { succeeded: true };
    }
    catch (error) {
        throw new Error('jo-billing -> jo-invoice -> queries-and-mutations.ts  updateManuallyAssignedInvoiceStatus() ' +
            error);
    }
}
exports.updateManuallyAssignedInvoiceStatus = updateManuallyAssignedInvoiceStatus;
