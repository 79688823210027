"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    company: 'NOT_SET',
    inputValues: {
        alerts: '',
        alerts_expiration_date: new Date().toISOString(),
        businessHours: '',
        default_estimate_duration: 60,
        default_job_duration: 120,
        description: '',
        directions: '',
        end_of_script_text: '',
        estimates_enabled: false,
        estimates_url: '',
        forms_enabled: false,
        forms_url: '',
        jobs_enabled: false,
        jobs_url: '',
        pricing_enabled: false,
        pricing_url: '',
        save_without_scheduling_enabled: false,
        save_without_scheduling_url: '',
        service_area_description: '',
    },
    isMobileDevice: false,
};
class JOCompanyCallInstructionsGeneralSetupCallScreenInfo extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    set company(company) {
        if (company === this.store.company) {
            return;
        }
        this.store.company = company;
        if (company === 'NOT_SET') {
            this.store.inputValues = InitialState.inputValues;
        }
        else {
            const inputValues = {
                alerts: company.alerts || '',
                alerts_expiration_date: company.alerts_expiration_date || new Date().toISOString(),
                businessHours: company.business_hours,
                default_estimate_duration: company.default_estimate_duration || 60,
                default_job_duration: company.default_job_duration || 120,
                description: company.description || '',
                directions: company.directions,
                end_of_script_text: company.end_of_script_text,
                estimates_enabled: company.estimates_enabled,
                estimates_url: company.estimates_url,
                forms_enabled: company.forms_enabled,
                forms_url: company.forms_url,
                jobs_enabled: company.jobs_enabled,
                jobs_url: company.jobs_url,
                pricing_enabled: company.pricing_enabled,
                pricing_url: company.pricing_url,
                save_without_scheduling_enabled: company.save_without_scheduling_enabled,
                save_without_scheduling_url: company.save_without_scheduling_url,
                service_area_description: company.service_area_description,
            };
            this.store.inputValues = inputValues;
            this.raiseInputValuesChangedEvent(inputValues);
        }
    }
    raiseInputValuesChangedEvent(someNewInputValues) {
        const fullNewInputValues = {
            ...this.store.inputValues,
            ...someNewInputValues,
        };
        (0, utilities_1.raiseCustomEvent)(this, 'inputvalueschanged', fullNewInputValues);
        this.store.inputValues = fullNewInputValues;
    }
    render(state) {
        const isAllowedToUpdate = (0, utilities_1.isInAuthRole)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser);
        return (0, lit_html_1.html) `
            <style>
                .jo-company-call-instructions-general-setup-call-screen-info--main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 2rem;
                }

                .jo-company-call-instructions-general-setup-call-screen-info--row {
                    display: flex;
                    flex-direction: ${state.isMobileDevice ? 'column' : ''};
                    padding-top: ${state.isMobileDevice ? '0rem' : '1rem'};
                    padding-bottom: ${state.isMobileDevice ? '1.2rem' : '1rem'};
                    box-sizing: border-box;
                }

                .jo-company-call-instructions-general-setup-call-screen-info--row-item {
                    flex: 1;
                    padding-right: 1rem;
                    padding-top: ${state.isMobileDevice ? '0.5rem' : ''};
                    box-sizing: border-box;
                }

                .jo-company-call-instructions-general-setup-call-screen-info--row-text {
                    font-weight: bold;
                    color: var(--jo-text-default);
                    box-sizing: border-box;
                    font-size: ${state.isMobileDevice ? 'calc(10px + 1vmin)' : ''};
                }

                .jo-company-call-instructions-general-setup-call-screen-info--textarea {
                    width: 100%;
                    resize: none;
                    border-radius: var(--jo-narrow-border-radius-1);
                    height: 5rem;
                    padding: 0.5rem;
                    box-sizing: border-box;
                }

                .jo-company-call-instructions-general-setup-call-screen-info--textarea-row {
                    width: 100%;
                }

                .jo-company-call-instructions-general-setup-call-screen-info--expiration-date-container {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }
            </style>

            <div
                class="jo-company-call-instructions-general-setup-call-screen-info--main-container"
            >
                <div class="jo-global--card-title">CALL SCREEN INFO</div>

                <div class="jo-company-call-instructions-general-setup-call-screen-info--row">
                    <div
                        class="jo-company-call-instructions-general-setup-call-screen-info--row-item"
                    >
                        <span
                            class="jo-company-call-instructions-general-setup-call-screen-info--row-text"
                        >
                            Enable Estimates
                        </span>
                        <input
                            ?disabled=${isAllowedToUpdate === false}
                            type="checkbox"
                            .checked=${state.inputValues.estimates_enabled}
                            @change=${(e) => {
            this.raiseInputValuesChangedEvent({
                estimates_enabled: e.target.checked,
            });
        }}
                        />
                    </div>

                    <div
                        class="jo-company-call-instructions-general-setup-call-screen-info--row-item"
                    >
                        <span
                            class="jo-company-call-instructions-general-setup-call-screen-info--row-text"
                        >
                            Enable Jobs
                        </span>
                        <input
                            ?disabled=${isAllowedToUpdate === false}
                            type="checkbox"
                            .checked=${state.inputValues.jobs_enabled}
                            @change=${(e) => {
            this.raiseInputValuesChangedEvent({
                jobs_enabled: e.target.checked,
            });
        }}
                        />
                    </div>
                </div>

                <div class="jo-company-call-instructions-general-setup-call-screen-info--row">
                    <div
                        class="jo-company-call-instructions-general-setup-call-screen-info--row-item"
                    >
                        <div
                            class="jo-company-call-instructions-general-setup-call-screen-info--row-text"
                        >
                            <jo-input
                                .readonly=${isAllowedToUpdate === false}
                                .type=${'URL'}
                                .label=${'Estimates URL'}
                                .fontSize=${state.isMobileDevice ? 'calc(10px + 1vmin)' : ''}
                                .inputValue=${state.inputValues.estimates_url}
                                .allowPastingEvenIfNotYetSet=${true}
                                @valueChanged=${(e) => {
            this.raiseInputValuesChangedEvent({
                estimates_url: e.detail.inputValue,
            });
        }}
                                @inputErrorChanged=${(e) => (0, utilities_1.raiseCustomEvent)(this, 'inputErrorChangedforCallScreenInfo', e.detail)}
                                .showError=${true}
                            ></jo-input>
                        </div>
                    </div>

                    <div
                        class="jo-company-call-instructions-general-setup-call-screen-info--row-item"
                    >
                        <div
                            class="jo-company-call-instructions-general-setup-call-screen-info--row-text"
                        >
                            <jo-input
                                .readonly=${isAllowedToUpdate === false}
                                .type=${'URL'}
                                .label=${'Jobs URL'}
                                .fontSize=${state.isMobileDevice ? 'calc(10px + 1vmin)' : ''}
                                .inputValue=${state.inputValues.jobs_url}
                                .allowPastingEvenIfNotYetSet=${true}
                                @valueChanged=${(e) => {
            this.raiseInputValuesChangedEvent({
                jobs_url: e.detail.inputValue,
            });
        }}
                                @inputErrorChanged=${(e) => (0, utilities_1.raiseCustomEvent)(this, 'inputErrorChangedforCallScreenInfo', e.detail)}
                                .showError=${true}
                            ></jo-input>
                        </div>
                    </div>
                </div>

                <div class="jo-company-call-instructions-general-setup-call-screen-info--row">
                    <div
                        class="jo-company-call-instructions-general-setup-call-screen-info--row-item"
                    >
                        <span
                            class="jo-company-call-instructions-general-setup-call-screen-info--row-text"
                        >
                            Enable Save Without Scheduling
                        </span>
                        <input
                            ?disabled=${isAllowedToUpdate === false}
                            type="checkbox"
                            .checked=${state.inputValues.save_without_scheduling_enabled}
                            @change=${(e) => {
            this.raiseInputValuesChangedEvent({
                save_without_scheduling_enabled: e.target
                    .checked,
            });
        }}
                        />
                    </div>

                    <div
                        class="jo-company-call-instructions-general-setup-call-screen-info--row-item"
                    >
                        <span
                            class="jo-company-call-instructions-general-setup-call-screen-info--row-text"
                        >
                            Enable Pricing
                        </span>
                        <input
                            ?disabled=${isAllowedToUpdate === false}
                            type="checkbox"
                            .checked=${state.inputValues.pricing_enabled}
                            @change=${(e) => {
            this.raiseInputValuesChangedEvent({
                pricing_enabled: e.target.checked,
            });
        }}
                        />
                    </div>
                </div>

                <div class="jo-company-call-instructions-general-setup-call-screen-info--row">
                    <div
                        class="jo-company-call-instructions-general-setup-call-screen-info--row-item"
                    >
                        <div
                            class="jo-company-call-instructions-general-setup-call-screen-info--row-text"
                        >
                            <jo-input
                                .readonly=${isAllowedToUpdate === false}
                                .type=${'URL'}
                                .label=${'Save Without Scheduling URL'}
                                .inputValue=${state.inputValues.save_without_scheduling_url}
                                .allowPastingEvenIfNotYetSet=${true}
                                @valueChanged=${(e) => {
            this.raiseInputValuesChangedEvent({
                save_without_scheduling_url: e.detail.inputValue,
            });
        }}
                                @inputErrorChanged=${(e) => (0, utilities_1.raiseCustomEvent)(this, 'inputErrorChangedforCallScreenInfo', e.detail)}
                                .showError=${true}
                            ></jo-input>
                        </div>
                    </div>

                    <div
                        class="jo-company-call-instructions-general-setup-call-screen-info--row-item"
                    >
                        <div
                            class="jo-company-call-instructions-general-setup-call-screen-info--row-text"
                        >
                            <jo-input
                                .readonly=${isAllowedToUpdate === false}
                                .type=${'URL'}
                                .label=${'Pricing URL'}
                                .fontSize=${state.isMobileDevice ? 'calc(10px + 1vmin)' : ''}
                                .inputValue=${state.inputValues.pricing_url}
                                .allowPastingEvenIfNotYetSet=${true}
                                @valueChanged=${(e) => {
            this.raiseInputValuesChangedEvent({
                pricing_url: e.detail.inputValue,
            });
        }}
                                @inputErrorChanged=${(e) => (0, utilities_1.raiseCustomEvent)(this, 'inputErrorChangedforCallScreenInfo', e.detail)}
                                .showError=${true}
                            ></jo-input>
                        </div>
                    </div>
                </div>

                <div class="jo-company-call-instructions-general-setup-call-screen-info--row">
                    <div
                        class="jo-company-call-instructions-general-setup-call-screen-info--row-item"
                    >
                        <span
                            class="jo-company-call-instructions-general-setup-call-screen-info--row-text"
                        >
                            Enable Forms
                        </span>
                        <input
                            ?disabled=${isAllowedToUpdate === false}
                            type="checkbox"
                            .checked=${state.inputValues.forms_enabled}
                            @change=${(e) => {
            this.raiseInputValuesChangedEvent({
                forms_enabled: e.target.checked,
            });
        }}
                        />
                    </div>
                </div>
                <div class="jo-company-call-instructions-general-setup-call-screen-info--row">
                    <div
                        class="jo-company-call-instructions-general-setup-call-screen-info--row-item"
                    >
                        <div
                            class="jo-company-call-instructions-general-setup-call-screen-info--row-text"
                        >
                            <jo-input
                                .readonly=${isAllowedToUpdate === false}
                                .type=${'URL'}
                                .label=${'Forms URL'}
                                .inputValue=${state.inputValues.forms_url}
                                .allowPastingEvenIfNotYetSet=${true}
                                @valueChanged=${(e) => {
            this.raiseInputValuesChangedEvent({
                forms_url: e.detail.inputValue,
            });
        }}
                                @inputErrorChanged=${(e) => (0, utilities_1.raiseCustomEvent)(this, 'inputErrorChangedforCallScreenInfo', e.detail)}
                                .showError=${true}
                            ></jo-input>
                        </div>
                    </div>
                </div>

                <div class="jo-company-call-instructions-general-setup-call-screen-info--row">
                    <div
                        class="jo-company-call-instructions-general-setup-call-screen-info--row-item"
                    >
                        <div
                            class="jo-company-call-instructions-general-setup-call-screen-info--row-text"
                        >
                            <jo-input
                                .readonly=${isAllowedToUpdate === false}
                                .type=${'NUMBER'}
                                .label=${'Default Estimate Duration (in minutes)'}
                                .inputValue=${state.inputValues.default_estimate_duration}
                                @valueChanged=${(e) => {
            this.raiseInputValuesChangedEvent({
                default_estimate_duration: parseInt(e.detail.inputValue) || 60, // TODO clean this up and remove hard-coding
            });
        }}
                                @inputErrorChanged=${(e) => (0, utilities_1.raiseCustomEvent)(this, 'inputErrorChangedforCallScreenInfo', e.detail)}
                                .showError=${true}
                            ></jo-input>
                        </div>
                    </div>

                    <div
                        class="jo-company-call-instructions-general-setup-call-screen-info--row-item"
                    >
                        <div
                            class="jo-company-call-instructions-general-setup-call-screen-info--row-text"
                        >
                            <jo-input
                                .readonly=${isAllowedToUpdate === false}
                                .type=${'NUMBER'}
                                .label=${'Default Job Duration (in minutes)'}
                                .inputValue=${state.inputValues.default_job_duration}
                                @valueChanged=${(e) => {
            this.raiseInputValuesChangedEvent({
                default_job_duration: parseInt(e.detail.inputValue) || 120, // TODO clean this up and remove hard-coding
            });
        }}
                                @inputErrorChanged=${(e) => (0, utilities_1.raiseCustomEvent)(this, 'inputErrorChangedforCallScreenInfo', e.detail)}
                                .showError=${true}
                            ></jo-input>
                        </div>
                    </div>
                </div>

                <div class="jo-company-call-instructions-general-setup-call-screen-info--row">
                    <div
                        class="jo-company-call-instructions-general-setup-call-screen-info--textarea-row"
                    >
                        <div
                            class="jo-company-call-instructions-general-setup-call-screen-info--row-text"
                        >
                            Alerts
                        </div>

                        <div>
                            <textarea
                                ?disabled=${isAllowedToUpdate === false}
                                class="jo-company-call-instructions-general-setup-call-screen-info--textarea jo-global--input jo-global--is-disabled"
                                .value=${state.inputValues.alerts}
                                @input=${(e) => {
            this.raiseInputValuesChangedEvent({
                alerts: e.target.value,
            });
        }}
                            ></textarea>
                        </div>

                        <div
                            class="jo-company-call-instructions-general-setup-call-screen-info--expiration-date-container"
                        >
                            <span
                                class="jo-company-call-instructions-general-setup-call-screen-info--row-text"
                            >
                                Expiration Date:
                            </span>
                            <input
                                ?disabled=${isAllowedToUpdate === false}
                                class="jo-company-call-instructions-general-setup-call-screen-info--expiration-date jo-global--is-disabled"
                                type="date"
                                .valueAsDate=${new Date(state.inputValues.alerts_expiration_date)}
                                @change=${(e) => {
            const dateValueConvertedToLocalTimeZone = (0, utilities_1.convertDateInputDateStringToLocalTimeISOString)(e.target.value);
            this.raiseInputValuesChangedEvent({
                alerts_expiration_date: dateValueConvertedToLocalTimeZone,
            });
        }}
                            />
                        </div>
                    </div>
                </div>

                <div class="jo-company-call-instructions-general-setup-call-screen-info--row">
                    <div
                        class="jo-company-call-instructions-general-setup-call-screen-info--textarea-row"
                    >
                        <div
                            class="jo-company-call-instructions-general-setup-call-screen-info--row-text"
                        >
                            Brief Company Description
                        </div>
                        <div>
                            <textarea
                                ?disabled=${isAllowedToUpdate === false}
                                class="jo-company-call-instructions-general-setup-call-screen-info--textarea jo-global--input jo-global--is-disabled"
                                .value=${state.inputValues.description}
                                @input=${(e) => {
            this.raiseInputValuesChangedEvent({
                description: e.target.value,
            });
        }}
                            ></textarea>
                        </div>
                    </div>
                </div>

                <div class="jo-company-call-instructions-general-setup-call-screen-info--row">
                    <div
                        class="jo-company-call-instructions-general-setup-call-screen-info--textarea-row"
                    >
                        <div
                            class="jo-company-call-instructions-general-setup-call-screen-info--row-text"
                        >
                            Directions
                        </div>
                        <div>
                            <textarea
                                ?disabled=${isAllowedToUpdate === false}
                                class="jo-company-call-instructions-general-setup-call-screen-info--textarea jo-global--input jo-global--is-disabled"
                                .value=${state.inputValues.directions}
                                @input=${(e) => {
            this.raiseInputValuesChangedEvent({
                directions: e.target.value,
            });
        }}
                            ></textarea>
                        </div>
                    </div>
                </div>

                <div class="jo-company-call-instructions-general-setup-call-screen-info--row">
                    <div
                        class="jo-company-call-instructions-general-setup-call-screen-info--textarea-row"
                    >
                        <div
                            class="jo-company-call-instructions-general-setup-call-screen-info--row-text"
                        >
                            Business Hours
                        </div>
                        <div>
                            <textarea
                                ?disabled=${isAllowedToUpdate === false}
                                class="jo-company-call-instructions-general-setup-call-screen-info--textarea jo-global--input jo-global--is-disabled"
                                .value=${state.inputValues.businessHours}
                                @input=${(e) => {
            this.raiseInputValuesChangedEvent({
                businessHours: e.target.value,
            });
        }}
                            ></textarea>
                        </div>
                    </div>
                </div>

                <div class="jo-company-call-instructions-general-setup-call-screen-info--row">
                    <div
                        class="jo-company-call-instructions-general-setup-call-screen-info--textarea-row"
                    >
                        <div
                            class="jo-company-call-instructions-general-setup-call-screen-info--row-text"
                        >
                            End of Script
                        </div>
                        <div>
                            <textarea
                                ?disabled=${isAllowedToUpdate === false}
                                class="jo-company-call-instructions-general-setup-call-screen-info--textarea jo-global--input jo-global--is-disabled"
                                .value=${state.inputValues.end_of_script_text}
                                @input=${(e) => {
            this.raiseInputValuesChangedEvent({
                end_of_script_text: e.target.value,
            });
        }}
                            ></textarea>
                        </div>
                    </div>
                </div>

                <div class="jo-company-call-instructions-general-setup-call-screen-info--row">
                    <div
                        class="jo-company-call-instructions-general-setup-call-screen-info--textarea-row"
                    >
                        <div
                            class="jo-company-call-instructions-general-setup-call-screen-info--row-text"
                        >
                            Service Area Description
                        </div>
                        <div>
                            <textarea
                                ?disabled=${isAllowedToUpdate === false}
                                class="jo-company-call-instructions-general-setup-call-screen-info--textarea jo-global--input jo-global--is-disabled"
                                .value=${state.inputValues.service_area_description}
                                @input=${(e) => {
            this.raiseInputValuesChangedEvent({
                service_area_description: e.target
                    .value,
            });
        }}
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-call-instructions-general-setup-call-screen-info', JOCompanyCallInstructionsGeneralSetupCallScreenInfo);
