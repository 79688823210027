"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../../services/graphql");
const utilities_1 = require("../../../services/utilities");
const auth_rules_1 = require("../../../utilities/auth-rules");
const get_previous_date_1 = require("../../../utilities/dates-and-times/get-previous-date");
const error_handling_1 = require("../../../utilities/error-handling");
const delete_invoice_1 = require("../../../utilities/invoices/delete-invoice");
const constants_1 = require("../jo-billing-report/constants");
require("../jo-input");
require("../jo-table");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
require("./jo-billing-invoice-history-mobile-template");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    companyId: 'NOT_SET',
    isMobileDevice: false,
};
class JOBillingInvoiceHistory extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
        this.handleGetInvoices = (async (offset, limit) => {
            if (this.store.companyId === 'NOT_SET') {
                return {
                    items: [],
                    totalItems: 0,
                };
            }
            const gqlResults = await Promise.all([
                fetchInvoicesForCompany(this.store.companyId, offset, limit),
                fetchTotalNumInvoices(this.store.companyId),
            ]);
            const [invoicesResult, totalNumInvoicesResult,] = gqlResults;
            (0, utilities_1.assertSucceeded)(invoicesResult, utilities_1.handleError);
            (0, utilities_1.assertSucceeded)(totalNumInvoicesResult, utilities_1.handleError);
            const invoices = invoicesResult.value;
            const totalNumInvoices = totalNumInvoicesResult.value;
            return {
                items: invoices,
                totalItems: totalNumInvoices,
            };
        }).bind(this);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        this.triggerTableReload();
    }
    triggerTableReload() {
        this.handleGetInvoices = this.handleGetInvoices.bind(this);
        this.store.companyId = this.store.companyId;
    }
    getMobileTableHeader() {
        return (0, lit_html_1.html) `
            <style>
                .jo-billing-invoice-history--table-header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-end;
                }
                .jo-billing-invoice-history--table-cell-1 {
                    font-weight: bold;
                    font-size: 0.75rem;
                    padding: 0.5rem;
                }
                .jo-billing-invoice-history--table-cell-2 {
                    font-weight: bold;
                    font-size: 0.75rem;
                    padding: 0.5rem;
                }
            </style>
            <div class="jo-billing-invoice-history--table-header">
                <div class="jo-billing-invoice-history--table-cell-1">Billing Cycle Dates</div>
                <div class="jo-billing-invoice-history--table-cell-2">Amount Due</div>
                <div class="jo-billing-invoice-history--table-cell-2">Status</div>
            </div>
        `;
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .jo-billing-invoice-history--main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }
            </style>

            <div class="jo-billing-invoice-history--main-container">
                <div>
                    <jo-table
                        .tableName=${'Invoice History'}
                        .maxItemsPerPage=${10}
                        .getItems=${this.handleGetInvoices}
                        .columns=${getColumns(state, this)}
                        .showSearchBar=${false}
                        .mobileTemplate=${(item) => getMobileTemplate(item)}
                        .mobileTableHeader=${this.getMobileTableHeader}
                        .isMobileDevice=${state.isMobileDevice}
                        .companyId=${state.companyId}
                    ></jo-table>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-billing-invoice-history', JOBillingInvoiceHistory);
function getMobileTemplate(item) {
    return (0, lit_html_1.html) `
        <jo-billing-invoice-history-mobile-template
            .item=${item}
        ></jo-billing-invoice-history-mobile-template>
    `;
}
async function fetchInvoicesForCompany(companyId, offset, limit) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!, $offset: Int!, $limit: Int!) {
                    Invoices__JOBillingInvoiceHistory: findInvoices(
                        filter: {company_id: {eq: $companyId}}
                        page: {limit: $limit, offset: $offset}
                        orderBy: {field: "id", order: DESC}
                    ) {
                        items {
                            id
                            due_date
                            start_date
                            end_date
                            status
                            stripe_id
                            invoice_line_items {
                                description
                                amount
                            }
                            stripe_invoice {
                                totalCharged
                                totalPaid
                            }
                            manually_assigned_status
                        }
                    }
                }
            `, {
            companyId,
            limit: limit,
            offset: offset,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const invoices = gqlResult.value.data.Invoices__JOBillingInvoiceHistory.items;
        return {
            succeeded: true,
            value: invoices,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchInvoicesForCompany', error);
    }
}
async function fetchTotalNumInvoices(companyId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!) {
                    findInvoices(filter: {company_id: {eq: $companyId}}) {
                        total
                    }
                }
            `, {
            companyId,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        const total = gqlResult.value.data.findInvoices.total;
        return {
            succeeded: true,
            value: total,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchTotalNumInvoices', error);
    }
}
function getColumns(state, element) {
    const columns = makeBaseColumns(state);
    if ((0, auth_rules_1.hasPermission)(state.authenticatedUser, 'can_delete_invoices')) {
        return addColumnToDeleteInvoice(columns, element);
    }
    return columns;
}
function makeBaseColumns(state) {
    return [
        {
            title: 'Billing Cycle Dates',
            getCellData: (invoice) => {
                const startDateString = new Date(invoice.start_date).toLocaleDateString();
                const endDateString = (0, get_previous_date_1.getPreviousDate)(invoice.end_date).toLocaleDateString();
                return `${startDateString} - ${endDateString}`;
            },
            width: 1.5,
        },
        {
            title: 'Amount Due',
            getCellData: (invoice) => getAmoundDueForInvoiceInDollars(invoice),
            width: 1,
        },
        {
            title: 'Status',
            getCellData: (invoice) => getInvoiceStatus(invoice),
            width: 1,
        },
        {
            title: '',
            getCellData: (invoice) => getHTMLForViewInvoiceButton(invoice, state.companyId, state.authenticatedUser),
            width: 0.5,
        },
    ];
}
function addColumnToDeleteInvoice(columns, element) {
    return [
        ...columns,
        {
            title: '',
            getCellData: (invoice) => {
                return (0, lit_html_1.html) `
                    <jo-button
                        .type=${'ICON'}
                        .text=${'delete'}
                        .inlineStyle=${'color: red'}
                        @joButtonClick=${async () => {
                    await tryDeleteInvoice(invoice, element);
                }}
                    ></jo-button>
                `;
            },
            width: 0.5,
        },
    ];
}
async function tryDeleteInvoice(invoice, element) {
    const shouldContinue = await (0, utilities_1.joConfirm)('WAIT', 'Are you sure you want to delete this invoice?');
    if (shouldContinue) {
        await (0, delete_invoice_1.deleteInvoice)(invoice.id);
        (0, utilities_1.joAlert)('Success', 'Invoice deleted successfully');
        element.triggerTableReload();
    }
}
function getAmoundDueForInvoiceInDollars(invoice) {
    const totalInCents = getInvoiceTotalInCents(invoice);
    const totalInDollars = totalInCents / 100;
    return '$' + totalInDollars.toFixed(2);
}
function getInvoiceTotalInCents(invoice) {
    return invoice.invoice_line_items.reduce((sum, invoiceLineItem) => {
        return sum + (invoiceLineItem.amount ?? 0);
    }, 0);
}
function getInvoiceStatus(invoice) {
    const totalDue = getInvoiceTotalInCents(invoice);
    if (invoice.stripe_invoice === null ||
        invoice.stripe_invoice === undefined ||
        invoice.stripe_invoice.totalPaid !== totalDue) {
        if (invoice.manually_assigned_status)
            return constants_1.BillingReportStatus[invoice.manually_assigned_status];
        return constants_1.BillingReportStatus.UNPAID;
    }
    return constants_1.BillingReportStatus.PAID;
}
function getHTMLForViewInvoiceButton(invoice, companyId, authenticatedUser) {
    const forMonth = invoice.for_month ?? (0, utilities_1.getCurrentForMonth)();
    const invoicePath = getInvoicePath(companyId, forMonth, authenticatedUser);
    return (0, lit_html_1.html) `
        <div>
            <span
                class="material-icons jo-global--action-icon"
                @click=${() => (0, utilities_1.navigate)(invoicePath)}
            >
                remove_red_eye
            </span>
        </div>
    `;
}
function getInvoicePath(companyId, forMonth, authenticatedUser) {
    if ((0, auth_rules_1.isCompanyUser)(authenticatedUser)) {
        return `/customer/invoice?companyId=${companyId}&forMonth=${forMonth}`;
    }
    return `/company/billing/invoice?companyId=${companyId}&forMonth=${forMonth}`;
}
