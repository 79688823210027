"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../services/utilities");
const define_custom_element_1 = require("../../utilities/define-custom-element");
require("./jo-button.element");
require("./jo-input");
const InitialState = {
    numPages: 0,
    currentPage: 1,
};
class JOPageNumbers extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    render(state) {
        if (state.numPages <= 1) {
            return (0, lit_html_1.html) ``;
        }
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
            <style>
                .main-container {
                    box-sizing: border-box;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                }
                .page-number {
                    box-sizing: border-box;
                    height: 2rem;
                    width: 2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid var(--jo-accent-background-dark);
                    border-radius: 2px;
                    color: var(--jo-primary);
                    margin-left: -1px;
                    background-color: white;
                }

                .selected {
                    background-color: var(--jo-primary);
                    box-shadow: var(--jo-box-shadow-2);
                    border: 1px solid var(--jo-primary);
                    color: white;
                    transform: scale(1.05);
                    font-weight: bold;
                }
            </style>
            <div class="main-container">
                ${new Array(state.numPages).fill(0).map((_, index) => {
            const pageNumber = index + 1;
            return (0, lit_html_1.html) `
                        <div
                            class="page-number ${state.currentPage === pageNumber
                ? 'selected'
                : ''}"
                            @click=${() => (0, utilities_1.raiseCustomEvent)(this, 'newPageNumberClicked', index)}
                        >
                            ${pageNumber}
                        </div>
                    `;
        })}
            </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-page-numbers', JOPageNumbers);
