"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFirstValidPhoneNumberFromContact = void 0;
const is_defined_1 = require("../is-defined");
const defaultValue = 'UNKNOWN';
function getFirstValidPhoneNumberFromContact(contact) {
    if (!contact || contact === 'NOT_SET') {
        return defaultValue;
    }
    if (!contact.phone_numbers) {
        return contact.phone_number || defaultValue;
    }
    const phoneNumbers = sortPhoneNumbersByCreatedAt(contact.phone_numbers);
    const validPhoneNumber = phoneNumbers.find(isValidPhoneNumberEntity);
    return validPhoneNumber?.number ?? defaultValue;
}
exports.getFirstValidPhoneNumberFromContact = getFirstValidPhoneNumberFromContact;
function sortPhoneNumbersByCreatedAt(unorderedPhoneNumbers) {
    return [...unorderedPhoneNumbers].sort((a, b) => new Date(a?.created_at).getTime() - new Date(b?.created_at).getTime());
}
function isValidPhoneNumberEntity(phoneNumber) {
    return (0, is_defined_1.isDefined)(phoneNumber) && (0, is_defined_1.isDefined)(phoneNumber.number) && phoneNumber.number.length > 0;
}
