"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const utilities_1 = require("../../../../services/utilities");
const css_utils_1 = require("../../../../utilities/css-utils");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
const convert_snake_to_title_case_1 = require("../../../../utilities/strings/casings.ts/convert-snake-to-title-case");
require("../../jo-button.element");
require("../../jo-input");
const jo_chat_strict_hours_logic_manager_1 = require("./jo-chat-strict-hours-logic-manager");
const queries_and_mutations_1 = require("./queries-and-mutations");
const styles_1 = require("./styles");
const cssName = (0, css_utils_1.cssPrefixer)('jo-chat-strict-hours');
const InitialState = {
    authenticatedUser: 'NOT_SET',
    chatStrictHours: [],
    editableChatStrictHours: [],
};
const gateway = {
    fetchChatStrictHours: queries_and_mutations_1.fetchChatStrictHours,
    updateChatStrictHours: queries_and_mutations_1.updateChatStrictHours,
};
class JOChatStrictHours extends HTMLElement {
    constructor() {
        super(...arguments);
        this.renderCallback = () => (0, lit_html_1.render)(this.render(), this);
        this.logicManager = new jo_chat_strict_hours_logic_manager_1.JOChatStrictHoursLogicManager({
            initialState: InitialState,
            gateway,
            stateChangeCallback: this.renderCallback,
            errorHandler: utilities_1.handleError,
        });
    }
    async connectedCallback() {
        this.logicManager.fetchAndSetChatStrictHours();
    }
    updateButtonClicked() {
        this.logicManager.updateChatStrictHours();
        (0, utilities_1.joAlert)('Success', 'Chat Strict Mode Hours updated successfully');
    }
    render() {
        const state = this.logicManager.getState();
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

            ${styles_1.stylesForJoChatStrictHours}

            <div class="${cssName('main-container')}">
                <section class="${cssName('column')}">
                    <h3>Chat Strict Mode Hours</h3>

                    ${state.editableChatStrictHours.map(chatStrictHour => {
            return (0, lit_html_1.html) `
                            <div>
                                <h4>${(0, convert_snake_to_title_case_1.convertSnakeToTitleCase)(chatStrictHour.day_of_week)}</h4>
                                <div class="${cssName('time-input-container')}">
                                    Start:
                                    <input
                                        class="${cssName('time-input')}"
                                        type="time"
                                        value="${chatStrictHour.start_time}"
                                        @change=${(e) => {
                const value = e.target.value.toString();
                this.logicManager.selectNewTime({
                    dayOfWeek: chatStrictHour.day_of_week,
                    startTime: value,
                });
            }}
                                    />
                                </div>
                                <div>
                                    End:
                                    <input
                                        class="${cssName('time-input')}"
                                        type="time"
                                        value="${chatStrictHour.end_time}"
                                        @change=${(e) => {
                const value = e.target.value.toString();
                this.logicManager.selectNewTime({
                    dayOfWeek: chatStrictHour.day_of_week,
                    endTime: value,
                });
            }}
                                    />
                                </div>
                            </div>
                            <br />
                        `;
        })}

                    <jo-button
                        class="${cssName('update-button')}"
                        .type=${'ACTION_BUTTON_2'}
                        .text=${'Update'}
                        @joButtonClick=${() => this.updateButtonClicked()}
                    ></jo-button>
                </section>

                <section class="${cssName('column')}"></section>

                <section class="${cssName('column')}"></section>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-chat-strict-hours', JOChatStrictHours);
