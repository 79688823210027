"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOtherCalendarUrl = exports.getOtherCalendarName = exports.getSelectedCalendarName = exports.getOtherPhoneServiceName = exports.getSelectedPhoneServiceName = void 0;
function getSelectedPhoneServiceName(company) {
    if (!company.phone_service)
        return 'None';
    if (company.phone_service.type === 'OTHER') {
        return 'Other';
    }
    return company.phone_service?.name || 'NOT_SET';
}
exports.getSelectedPhoneServiceName = getSelectedPhoneServiceName;
function getOtherPhoneServiceName(company) {
    if (company.phone_service?.type === 'OTHER') {
        return company.phone_service.name;
    }
    return '';
}
exports.getOtherPhoneServiceName = getOtherPhoneServiceName;
function getSelectedCalendarName(company) {
    if (company.calendar?.type === 'OTHER') {
        return 'Other';
    }
    if (company.calendar === null) {
        return 'None';
    }
    return company.calendar?.name || 'NOT_SET';
}
exports.getSelectedCalendarName = getSelectedCalendarName;
function getOtherCalendarName(company) {
    if (company.calendar?.type === 'OTHER') {
        return company.calendar.name;
    }
    return '';
}
exports.getOtherCalendarName = getOtherCalendarName;
function getOtherCalendarUrl(company) {
    if (company.calendar?.type === 'OTHER') {
        return company.calendar.url;
    }
    return '';
}
exports.getOtherCalendarUrl = getOtherCalendarUrl;
