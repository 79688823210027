"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.manualStatusOptions = exports.defaultManualStatusOption = exports.InitialState = void 0;
exports.InitialState = {
    addingNewLineItem: false,
    authenticatedUser: 'NOT_SET',
    billingCycleType: 'MONTHLY',
    companyId: 'NOT_SET',
    endOfBillingCycle: new Date(),
    invoice: 'NOT_SET',
    invoiceLineItems: [],
    isMobileDevice: false,
    loading: false,
    newInvoiceLineItem: 'NOT_SET',
    selectedDate: new Date(),
    selectedStatus: 'NOT_SET',
    startOfBillingCycle: new Date(),
};
exports.defaultManualStatusOption = 'Select Status...';
exports.manualStatusOptions = [
    'PAID',
    'UNPAID',
];
