"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOCompanyServicesPricingServicesStyles = void 0;
const lit_html_1 = require("lit-html");
const css_utils_1 = require("../../../../utilities/css-utils");
const cssName = (0, css_utils_1.cssPrefixer)('jo-company-services-pricing-services');
exports.JOCompanyServicesPricingServicesStyles = (0, lit_html_1.html) `
    <style>
        .${cssName('main-container')} {
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            padding: 0.5rem;
        }

        .${cssName('row')} {
            display: flex;
            padding: 0.5rem 0rem;
            flex-wrap: wrap;
        }

        .${cssName('delete-icon-wrapper')} {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .${cssName('add-service-button-container')} {
            display: flex;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }

        .${cssName('add-button')} button {
            width: 100%;
        }

        .${cssName('price-minimum-input')} {
            width: 100%;
            box-sizing: border-box;
        }

        .${cssName('services-not-provided-container')} {
            display: flex;
            flex-direction: column;
            padding-top: 1rem;
        }

        .${cssName('services-not-provided-text')} {
            color: var(--jo-text-default);
            font-weight: bold;
            font-size: 0.9rem;
        }

        .${cssName('services-not-provided-textarea-container')} {
            padding: 1rem;
        }

        .${cssName('services-not-provided-textarea')} {
            box-sizing: border-box;
            resize: none;
            width: 100%;
            height: 5rem;
            padding: 0.5rem;
        }
        .${cssName('service-divider-border')} {
            border-bottom: 1px solid orange;
            height: 2px;
            width: 90%;
            margin: 1rem auto;
        }

        .${cssName('emptyIcon')} {
            padding-top: 2rem;
            font-size: 5rem;
        }

        .${cssName('arrow')} {
            cursor: pointer;
            color: var(--jo-primary);
            padding-left: 1rem;
        }

        .${cssName('arrow')}:hover {
            color: var(--jo-secondary-dark);
        }
    </style>
`;
