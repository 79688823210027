"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOHighLevelReport = void 0;
const element_vir_1 = require("element-vir");
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../services/graphql");
const utilities_1 = require("../../services/utilities");
require("./jo-button.element");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../utilities/environment/mapped-env-variables");
const extract_event_target_1 = require("../../utilities/event/extract-event-target");
const startOfToday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
const startOfTomorrow = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1);
const InitialState = {
    endDate: startOfTomorrow.toISOString(),
    fetchHighLevelStatsAbortController: 'NOT_SET',
    highLevelStatses: 'NOT_SET',
    highLevelStatsForMonthsCollapsed: [],
    includeTestCompaniesAndPods: false,
    loading: true,
    startDate: startOfToday.toISOString(),
};
function rootReducer(state = InitialState, action) {
    if (action.type === 'SET_HIGH_LEVEL_STATSES') {
        return {
            ...state,
            highLevelStatses: action.highLevelStatses,
            highLevelStatsForMonthsCollapsed: action.highLevelStatses.highLevelStatsForMonths.map((highLevelStatsForMonth) => {
                return {
                    highLevelStatsForMonth,
                    collapsed: true,
                };
            }),
        };
    }
    if (action.type === 'TOGGLE_HIGH_LEVEL_STATS_FOR_MONTH_COLLAPSED') {
        return {
            ...state,
            highLevelStatsForMonthsCollapsed: state.highLevelStatsForMonthsCollapsed.map((highLevelStatsForMonthCollapsed) => {
                if (new Date(highLevelStatsForMonthCollapsed.highLevelStatsForMonth.dateInMonth).getTime() ===
                    new Date(action.highLevelStatsForMonth.dateInMonth).getTime()) {
                    return {
                        ...highLevelStatsForMonthCollapsed,
                        collapsed: !highLevelStatsForMonthCollapsed.collapsed,
                    };
                }
                return highLevelStatsForMonthCollapsed;
            }),
        };
    }
    return state;
}
class JOHighLevelReport extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, rootReducer);
    }
    async connectedCallback() {
        await this.fetchAndSetHighLevelStats();
    }
    async fetchAndSetHighLevelStats() {
        this.store.loading = true;
        const currentFetchHighLevelStatsAbortController = this.store.getState().fetchHighLevelStatsAbortController;
        if (currentFetchHighLevelStatsAbortController !== 'NOT_SET') {
            currentFetchHighLevelStatsAbortController.abort();
        }
        const { abortController, execute } = (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint);
        this.store.fetchHighLevelStatsAbortController = abortController;
        const highLevelStatses = await fetchHighLevelStatses({
            execute,
            startDate: this.store.getState().startDate,
            endDate: this.store.getState().endDate,
            includeTestCompaniesAndPods: this.store.getState().includeTestCompaniesAndPods,
        });
        this.store.fetchHighLevelStatsAbortController = 'NOT_SET';
        this.store.dispatch({
            type: 'SET_HIGH_LEVEL_STATSES',
            highLevelStatses,
        });
        this.store.loading = false;
        this.loadCharts();
    }
    getDateValueFromEventTarget(event) {
        const inputElement = (0, extract_event_target_1.extractEventTarget)(event, HTMLInputElement);
        const newDate = new Date(`${inputElement.value}T00:00`).toISOString();
        return newDate;
    }
    loadCharts() {
        const highLevelStatses = this.store.highLevelStatses;
        if (highLevelStatses === 'NOT_SET') {
            return;
        }
        (0, utilities_1.loadChart)(this, highLevelStatses, 'chart-total-calls', 'Total calls', 'numCalls');
        (0, utilities_1.loadChart)(this, highLevelStatses, 'chart-total-actual-call-duration', 'Total actual call duration', 'actualCallDuration');
        (0, utilities_1.loadChart)(this, highLevelStatses, 'chart-total-billable-call-duration', 'Total billable call duration', 'billableCallDuration');
        (0, utilities_1.loadChart)(this, highLevelStatses, 'chart-average-efficiency-per-day', 'Average efficiency per day', 'averageEfficiencyPerDay');
        (0, utilities_1.loadChart)(this, highLevelStatses, 'chart-average-billable-call-duration', 'Average billable call duration', 'averageBillableCallDuration');
        (0, utilities_1.loadChart)(this, highLevelStatses, 'chart-average-actual-call-duration', 'Average actual call duration', 'averageActualCallDuration');
        (0, utilities_1.loadChart)(this, highLevelStatses, 'chart-average-time-between-calls', 'Average time between calls', 'averageTimeBetweenCalls');
        (0, utilities_1.loadChart)(this, highLevelStatses, 'chart-average-action-item-time', 'Average action item time', 'averageActionItemTime');
    }
    toggleHighLevelStatsForMonthCollapsed(highLevelStatsForMonth) {
        this.store.dispatch({
            type: 'TOGGLE_HIGH_LEVEL_STATS_FOR_MONTH_COLLAPSED',
            highLevelStatsForMonth,
        });
    }
    csvButtonClicked() {
        (0, utilities_1.assertIsSet)(this.store.highLevelStatses, utilities_1.handleError);
        const startDate = (0, utilities_1.formatISODateForFileName)(this.store.startDate);
        const endDate = (0, utilities_1.formatISODateForFileName)(this.store.endDate);
        if (startDate == null)
            throw new Error('startDate is null');
        if (endDate == null)
            throw new Error('endDate is null');
        const fileName = `jill-stats-${startDate}-through-${endDate}.csv`;
        (0, utilities_1.generateAndDownloadCSVForHighLevelStatses)(this.store.highLevelStatses, this.store.highLevelStatsForMonthsCollapsed, fileName);
    }
    render(state) {
        return (0, element_vir_1.html) `
            <style>
                .jo-high-level-report--container {
                    padding: calc(25px + 1vmin);
                }

                .jo-high-level-report--stats-container {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                }

                .jo-high-level-report--stats-header {
                    font-weight: bold;
                    padding-bottom: calc(5px + 1vmin);
                }

                .jo-high-level-report--stats-item {
                    padding-bottom: calc(5px + 1vmin);
                }

                .jo-high-level-report--bold {
                    font-weight: bold;
                }

                .jo-high-level-report--pointer {
                    cursor: pointer;
                }

                .jo-high-level-report--dates-and-charts {
                    display: grid;
                    grid-template-columns: 1fr 5fr;
                }

                .jo-high-level-report--charts {
                    display: flex;
                    overflow-x: scroll;
                }

                .jo-high-level-report--checkbox {
                    margin-top: 1rem;
                }
            </style>

            <div class="jo-high-level-report--container">
                <h2>High-level Report</h2>

                <div class="jo-high-level-report--dates-and-charts">
                    <div>
                        <div>Start date</div>
                        <div>
                            <input
                                type="date"
                                .value=${state.startDate.split('T')[0]}
                                ${(0, element_vir_1.listen)('input', async (event) => {
            this.store.startDate = this.getDateValueFromEventTarget(event);
        })}
                            />
                        </div>
                        <br />
                        <div>End date</div>
                        <div>
                            <input
                                type="date"
                                .value=${state.endDate.split('T')[0]}
                                ${(0, element_vir_1.listen)('input', async (event) => {
            this.store.endDate = this.getDateValueFromEventTarget(event);
        })}
                            />
                        </div>
                        <label>
                            <input
                                class="jo-high-level-report--checkbox"
                                type="checkbox"
                                .checked=${state.includeTestCompaniesAndPods}
                                ${(0, element_vir_1.listen)('input', event => {
            const inputElement = (0, extract_event_target_1.extractEventTarget)(event, HTMLInputElement);
            this.store.includeTestCompaniesAndPods = inputElement.checked;
        })}
                            />
                            &nbsp;Include Test Pods & Companies
                        </label>
                        <br />
                        <br />
                        <jo-button
                            .text=${'Submit'}
                            @joButtonClick=${async () => await this.fetchAndSetHighLevelStats()}
                        ></jo-button>
                    </div>

                    <div class="jo-high-level-report--charts">
                        <div id="chart-total-calls-div">
                            <canvas id="chart-total-calls"></canvas>
                        </div>

                        <div id="chart-total-actual-call-duration-div">
                            <canvas id="chart-total-actual-call-duration"></canvas>
                        </div>

                        <div id="chart-total-billable-call-duration-div">
                            <canvas id="chart-total-billable-call-duration"></canvas>
                        </div>

                        <div id="chart-average-efficiency-per-day-div">
                            <canvas id="chart-average-efficiency-per-day"></canvas>
                        </div>

                        <div id="chart-average-billable-call-duration-div">
                            <canvas id="chart-average-billable-call-duration"></canvas>
                        </div>

                        <div id="chart-average-actual-call-duration-div">
                            <canvas id="chart-average-actual-call-duration"></canvas>
                        </div>

                        <div id="chart-average-time-between-calls-div">
                            <canvas id="chart-average-time-between-calls"></canvas>
                        </div>

                        <div id="chart-average-action-item-time-div">
                            <canvas id="chart-average-action-item-time"></canvas>
                        </div>
                    </div>
                </div>

                <br />

                <div class="jo-global--high-level-statses-csv-button-container">
                    <jo-button
                        .text=${'Download as CSV'}
                        .type=${'ACTION_BUTTON_2'}
                        @joButtonClick=${() => this.csvButtonClicked()}
                    ></jo-button>
                </div>

                <div class="jo-high-level-report--stats-container">
                    ${state.loading === true || state.highLevelStatses === 'NOT_SET'
            ? (0, element_vir_1.html) `
                              Loading...
                          `
            : (0, element_vir_1.html) `
                              <div class="jo-high-level-report--stats-header">Date</div>
                              <div class="jo-high-level-report--stats-header">Total calls</div>
                              <div class="jo-high-level-report--stats-header">
                                  Total actual call duration
                              </div>
                              <div class="jo-high-level-report--stats-header">
                                  Total billable call duration
                              </div>
                              <div class="jo-high-level-report--stats-header">
                                  Average efficiency per day
                              </div>
                              <div class="jo-high-level-report--stats-header">
                                  Average billable call duration
                              </div>
                              <div class="jo-high-level-report--stats-header">
                                  Average actual call duration
                              </div>
                              <div class="jo-high-level-report--stats-header">
                                  Average time between calls
                              </div>
                              <div class="jo-high-level-report--stats-header">
                                  Average action item time
                              </div>

                              <div
                                  class="jo-high-level-report--stats-item jo-high-level-report--bold"
                              >
                                  Total
                              </div>
                              <div class="jo-high-level-report--stats-item">
                                  ${state.highLevelStatses.highLevelStats.numCalls}
                              </div>
                              <div class="jo-high-level-report--stats-item">
                                  ${(0, utilities_1.millisecondsToMinutes)(parseInt(state.highLevelStatses.highLevelStats.actualCallDuration))}
                                  (${(0, utilities_1.millisecondsToHoursMinutesSeconds)(parseInt(state.highLevelStatses.highLevelStats.actualCallDuration))})
                              </div>
                              <div class="jo-high-level-report--stats-item">
                                  ${(0, utilities_1.millisecondsToMinutes)(parseInt(state.highLevelStatses.highLevelStats
                .billableCallDuration))}
                                  (${(0, utilities_1.millisecondsToHoursMinutesSeconds)(parseInt(state.highLevelStatses.highLevelStats
                .billableCallDuration))})
                              </div>
                              <div class="jo-high-level-report--stats-item">
                                  ${state.highLevelStatses.highLevelStats.averageEfficiencyPerDay.toFixed(2)}%
                              </div>
                              <div class="jo-high-level-report--stats-item">
                                  ${(0, utilities_1.millisecondsToMinutes)(state.highLevelStatses.highLevelStats
                .averageBillableCallDuration)}
                                  (${(0, utilities_1.millisecondsToHoursMinutesSeconds)(state.highLevelStatses.highLevelStats
                .averageBillableCallDuration)})
                              </div>
                              <div class="jo-high-level-report--stats-item">
                                  ${(0, utilities_1.millisecondsToMinutes)(state.highLevelStatses.highLevelStats
                .averageActualCallDuration)}
                                  (${(0, utilities_1.millisecondsToHoursMinutesSeconds)(state.highLevelStatses.highLevelStats
                .averageActualCallDuration)})
                              </div>
                              <div class="jo-high-level-report--stats-item">
                                  ${(0, utilities_1.millisecondsToMinutes)(state.highLevelStatses.highLevelStats.averageTimeBetweenCalls)}
                                  (${(0, utilities_1.millisecondsToHoursMinutesSeconds)(state.highLevelStatses.highLevelStats.averageTimeBetweenCalls)})
                              </div>
                              <div class="jo-high-level-report--stats-item">
                                  ${(0, utilities_1.millisecondsToMinutes)(state.highLevelStatses.highLevelStats.averageActionItemTime)}
                                  (${(0, utilities_1.millisecondsToHoursMinutesSeconds)(state.highLevelStatses.highLevelStats.averageActionItemTime)})
                              </div>

                              ${state.highLevelStatses.highLevelStatsForMonths.map((highLevelStatsForMonth) => {
                return (0, element_vir_1.html) `
                                          <div
                                              class="jo-high-level-report--stats-item jo-high-level-report--bold jo-high-level-report--pointer"
                                              @click=${() => this.toggleHighLevelStatsForMonthCollapsed(highLevelStatsForMonth)}
                                          >
                                              ${new Date(highLevelStatsForMonth.dateInMonth).toLocaleString('en-us', {
                    month: 'long',
                    year: 'numeric',
                })}
                                          </div>
                                          <div class="jo-high-level-report--stats-item">
                                              ${highLevelStatsForMonth.highLevelStats.numCalls}
                                          </div>
                                          <div class="jo-high-level-report--stats-item">
                                              ${(0, utilities_1.millisecondsToMinutes)(parseInt(highLevelStatsForMonth.highLevelStats
                    .actualCallDuration))}
                                              (${(0, utilities_1.millisecondsToHoursMinutesSeconds)(parseInt(highLevelStatsForMonth.highLevelStats
                    .actualCallDuration))})
                                          </div>
                                          <div class="jo-high-level-report--stats-item">
                                              ${(0, utilities_1.millisecondsToMinutes)(parseInt(highLevelStatsForMonth.highLevelStats
                    .billableCallDuration))}
                                              (${(0, utilities_1.millisecondsToHoursMinutesSeconds)(parseInt(highLevelStatsForMonth.highLevelStats
                    .billableCallDuration))})
                                          </div>
                                          <div class="jo-high-level-report--stats-item">
                                              ${highLevelStatsForMonth.highLevelStats.averageEfficiencyPerDay.toFixed(2)}%
                                          </div>
                                          <div class="jo-high-level-report--stats-item">
                                              ${(0, utilities_1.millisecondsToMinutes)(highLevelStatsForMonth.highLevelStats
                    .averageBillableCallDuration)}
                                              (${(0, utilities_1.millisecondsToHoursMinutesSeconds)(highLevelStatsForMonth.highLevelStats
                    .averageBillableCallDuration)})
                                          </div>
                                          <div class="jo-high-level-report--stats-item">
                                              ${(0, utilities_1.millisecondsToMinutes)(highLevelStatsForMonth.highLevelStats
                    .averageActualCallDuration)}
                                              (${(0, utilities_1.millisecondsToHoursMinutesSeconds)(highLevelStatsForMonth.highLevelStats
                    .averageActualCallDuration)})
                                          </div>
                                          <div class="jo-high-level-report--stats-item">
                                              ${(0, utilities_1.millisecondsToMinutes)(highLevelStatsForMonth.highLevelStats
                    .averageTimeBetweenCalls)}
                                              (${(0, utilities_1.millisecondsToHoursMinutesSeconds)(highLevelStatsForMonth.highLevelStats
                    .averageTimeBetweenCalls)})
                                          </div>
                                          <div class="jo-high-level-report--stats-item">
                                              ${(0, utilities_1.millisecondsToMinutes)(highLevelStatsForMonth.highLevelStats
                    .averageActionItemTime)}
                                              (${(0, utilities_1.millisecondsToHoursMinutesSeconds)(highLevelStatsForMonth.highLevelStats
                    .averageActionItemTime)})
                                          </div>

                                          ${(() => {
                    const highLevelStatsForMonthCollapsed = state.highLevelStatsForMonthsCollapsed.find(highLevelStatsForMonthCollapsed => {
                        return (new Date(highLevelStatsForMonthCollapsed.highLevelStatsForMonth.dateInMonth).getTime() ===
                            new Date(highLevelStatsForMonth.dateInMonth).getTime());
                    });
                    if (highLevelStatsForMonthCollapsed?.collapsed ===
                        true) {
                        return '';
                    }
                    return highLevelStatsForMonth.highLevelStatsForDays.map((highLevelStatsForDay) => {
                        return (0, element_vir_1.html) `
                                                          <div
                                                              class="jo-high-level-report--stats-item"
                                                          >
                                                              ${new Date(highLevelStatsForDay.dateInDay).toLocaleDateString()}
                                                          </div>
                                                          <div
                                                              class="jo-high-level-report--stats-item"
                                                          >
                                                              ${highLevelStatsForDay.highLevelStats
                            .numCalls}
                                                          </div>
                                                          <div
                                                              class="jo-high-level-report--stats-item"
                                                          >
                                                              ${(0, utilities_1.millisecondsToMinutes)(parseInt(highLevelStatsForDay
                            .highLevelStats
                            .actualCallDuration))}
                                                              (${(0, utilities_1.millisecondsToHoursMinutesSeconds)(parseInt(highLevelStatsForDay
                            .highLevelStats
                            .actualCallDuration))})
                                                          </div>
                                                          <div
                                                              class="jo-high-level-report--stats-item"
                                                          >
                                                              ${(0, utilities_1.millisecondsToMinutes)(parseInt(highLevelStatsForDay
                            .highLevelStats
                            .billableCallDuration))}
                                                              (${(0, utilities_1.millisecondsToHoursMinutesSeconds)(parseInt(highLevelStatsForDay
                            .highLevelStats
                            .billableCallDuration))})
                                                          </div>
                                                          <div
                                                              class="jo-high-level-report--stats-item"
                                                          >
                                                              ${highLevelStatsForDay.highLevelStats.averageEfficiencyPerDay.toFixed(2)}%
                                                          </div>
                                                          <div
                                                              class="jo-high-level-report--stats-item"
                                                          >
                                                              ${(0, utilities_1.millisecondsToMinutes)(highLevelStatsForDay
                            .highLevelStats
                            .averageBillableCallDuration)}
                                                              (${(0, utilities_1.millisecondsToHoursMinutesSeconds)(highLevelStatsForDay
                            .highLevelStats
                            .averageBillableCallDuration)})
                                                          </div>
                                                          <div
                                                              class="jo-high-level-report--stats-item"
                                                          >
                                                              ${(0, utilities_1.millisecondsToMinutes)(highLevelStatsForDay
                            .highLevelStats
                            .averageActualCallDuration)}
                                                              (${(0, utilities_1.millisecondsToHoursMinutesSeconds)(highLevelStatsForDay
                            .highLevelStats
                            .averageActualCallDuration)})
                                                          </div>
                                                          <div
                                                              class="jo-high-level-report--stats-item"
                                                          >
                                                              ${(0, utilities_1.millisecondsToMinutes)(highLevelStatsForDay
                            .highLevelStats
                            .averageTimeBetweenCalls)}
                                                              (${(0, utilities_1.millisecondsToHoursMinutesSeconds)(highLevelStatsForDay
                            .highLevelStats
                            .averageTimeBetweenCalls)})
                                                          </div>
                                                          <div
                                                              class="jo-high-level-report--stats-item"
                                                          >
                                                              ${(0, utilities_1.millisecondsToMinutes)(highLevelStatsForDay
                            .highLevelStats
                            .averageActionItemTime)}
                                                              (${(0, utilities_1.millisecondsToHoursMinutesSeconds)(highLevelStatsForDay
                            .highLevelStats
                            .averageActionItemTime)})
                                                          </div>
                                                      `;
                    });
                })()}
                                      `;
            })}
                          `}
                </div>
            </div>
        `;
    }
}
exports.JOHighLevelReport = JOHighLevelReport;
(0, define_custom_element_1.defineCustomElement)('jo-high-level-report', JOHighLevelReport);
async function fetchHighLevelStatses(params) {
    const gqlResult = await params.execute((0, graphql_1.gql) `
            query ($startDate: String!, $endDate: String!, $includeTestCompaniesAndPods: Boolean) {
                highLevelStatses(
                    startDate: $startDate
                    endDate: $endDate
                    includeTestCompaniesAndPods: $includeTestCompaniesAndPods
                ) {
                    highLevelStats {
                        numCalls
                        actualCallDuration
                        billableCallDuration
                        averageActualCallDuration
                        averageBillableCallDuration
                        averageEfficiencyPerDay
                        averageTimeBetweenCalls
                        averageActionItemTime
                    }
                    highLevelStatsForMonths {
                        dateInMonth
                        highLevelStats {
                            numCalls
                            actualCallDuration
                            billableCallDuration
                            averageActualCallDuration
                            averageBillableCallDuration
                            averageEfficiencyPerDay
                            averageTimeBetweenCalls
                            averageActionItemTime
                        }
                        highLevelStatsForDays {
                            dateInDay
                            highLevelStats {
                                numCalls
                                actualCallDuration
                                billableCallDuration
                                averageActualCallDuration
                                averageBillableCallDuration
                                averageEfficiencyPerDay
                                averageTimeBetweenCalls
                                averageActionItemTime
                            }
                        }
                    }
                }
            }
        `, {
        startDate: params.startDate,
        endDate: params.endDate,
        includeTestCompaniesAndPods: params.includeTestCompaniesAndPods,
    });
    if (gqlResult.data === null || gqlResult.data === undefined) {
        throw new Error('There was a problem');
    }
    return gqlResult.data.highLevelStatses;
}
