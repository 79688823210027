"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stylesForChatWidgetScriptGenerator = void 0;
const lit_html_1 = require("lit-html");
const constants_1 = require("./constants");
exports.stylesForChatWidgetScriptGenerator = (() => {
    return (0, lit_html_1.html) `
        <style>
            .${(0, constants_1.cssName)('main-container')} {
                max-width: 100%;
                height: 100%;
                margin: 1rem;
                box-sizing: border-box;
            }
            .${(0, constants_1.cssName)('card')} {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 2rem;
            }

            .${(0, constants_1.cssName)('copy-code-container')} {
                display: flex;
                flex-direction: row;
                justify-content: center;
                padding-top: 2rem;
                padding-bottom: 3rem;
            }
            .${(0, constants_1.cssName)('code-container')} {
                color: var(--jo-text-default);
                border-radius: var(--jo-wide-border-radius-1);
                box-shadow: var(--jo-card-shadow-1);
                padding: 1rem;
                margin-left: 2rem;
                width: 50%;
                white-space: break-spaces;
            }
            .${(0, constants_1.cssName)('copy-to-clipboard-icon')} {
                margin-top: 1rem;
                color: var(--jo-text-default);
                cursor: pointer;
                padding: 1rem;
                font-size: 1.8rem;
            }
            .${(0, constants_1.cssName)('copy-to-clipboard-icon')}:active {
                color: var(--jo-primary);
            }

            .${(0, constants_1.cssName)('save-cancel-buttons-container')} {
                display: flex;
                flex-direction: row;
                padding: 2rem;
                justify-content: flex-end;
            }
            .${(0, constants_1.cssName)('row')} {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 1rem;
            }
            .${(0, constants_1.cssName)('break-row')} {
                flex-basis: 100%;
                height: 0;
            }
            .${(0, constants_1.cssName)('settings-container')} {
                display: flex;
                flex-direction: column;
                gap: 4rem;
                flex: 2;
            }
            .${(0, constants_1.cssName)('example-container')} {
                flex: 1;
            }
            .${(0, constants_1.cssName)('example-demo')} {
                border-radius: var(--jo-wide-border-radius-1);
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: center;
                max-height: 600px;
                overflow-y: auto;
                border: 1px solid var(--jo-accent-background-dark);
            }
            .${(0, constants_1.cssName)('input-container')} {
                display: flex;
                flex-direction: column;
                font-size: 0.8rem;
                align-items: center;
            }
            .${(0, constants_1.cssName)('example-titlebar')} {
                box-sizing: border-box;
                padding-left: 1rem;
                padding-right: 1rem;
                width: 100%;
                border-radius: var(--jo-wide-border-radius-1);
                text-align: center;
                font-size: 20px;
            }

            .${(0, constants_1.cssName)('example-chat-common')} {
                padding: 1rem;
                margin-right: 1rem;
                margin-left: 1rem;
                margin-top: 0.5rem;
                margin-bottom: 1.5rem;
                border-radius: var(--jo-wide-border-radius-1);
                background-color: var(--jo-accent-background-dark);
                align-self: flex-start;
            }

            .${(0, constants_1.cssName)('example-second-chat')} {
                align-self: flex-end;
            }

            .${(0, constants_1.cssName)('instructions')} {
                color: var(--jo-text-default);
                font-size: 0.9rem;
                align-self: center;
                padding-bottom: 1rem;
            }

            .${(0, constants_1.cssName)('add-message-container')} {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                text-align: center;
                color: var(--jo-text-default);
            }

            .${(0, constants_1.cssName)('message-inputs-container')} {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                max-height: 235px;
                overflow-y: auto;
            }

            .${(0, constants_1.cssName)('add-message')} {
                width: 24rem;
                padding: 0.3rem;
                border: 1px solid rgb(180, 180, 180);
                border-radius: 3px;
                font-size: 0.9rem;
            }

            .${(0, constants_1.cssName)('add-message-icon')} {
                color: var(--jo-primary);
            }

            .${(0, constants_1.cssName)('add-message-icon')}:hover {
                color: var(--jo-primary-light);
                cursor: pointer;
            }

            .${(0, constants_1.cssName)('delete-message-icon')} {
                color: var(--jo-secondary);
            }

            .${(0, constants_1.cssName)('delete-message-icon')}:hover {
                color: var(--jo-secondary-light);
                cursor: pointer;
            }
        </style>
    `;
})();
