"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateTotalChatsCompleted = void 0;
const is_chat_duration_event_1 = require("./is-chat-duration-event");
function calculateTotalChatsCompleted(callsAndStatusEvents) {
    return callsAndStatusEvents.filter((event) => {
        if ((0, is_chat_duration_event_1.isChatDurationEvent)(event, 'ACTION_ITEM_SENT')) {
            return event;
        }
    }).length;
}
exports.calculateTotalChatsCompleted = calculateTotalChatsCompleted;
