"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateJillPods = void 0;
const graphql_1 = require("../../../services/graphql");
const error_handling_1 = require("../../../utilities/error-handling");
const helper_utils_1 = require("./helper-utils");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function updateJillPods(jill, selectedMainPods, selectedFloatPods) {
    try {
        const mainPodIdsToConnect = (0, helper_utils_1.getPodIdsToConnect)(selectedMainPods, jill.pods);
        const mainPodIdsToDisconnect = (0, helper_utils_1.getPodIdsToDisconnect)(selectedMainPods, jill.pods);
        const floatPodIdsToConnect = (0, helper_utils_1.getPodIdsToConnect)(selectedFloatPods, jill.float_pods);
        const floatPodIdsToDisconnect = (0, helper_utils_1.getPodIdsToDisconnect)(selectedFloatPods, jill.float_pods);
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation (
                    $jillId: Int!
                    $mainPodIdsToConnect: [Int!]!
                    $mainPodIdsToDisconnect: [Int!]!
                    $floatPodIdsToConnect: [Int!]!
                    $floatPodIdsToDisconnect: [Int!]!
                ) {
                    updateUsers(
                        input: {
                            id: $jillId
                            pods: {
                                connect: {ids: $mainPodIdsToConnect}
                                disconnect: {ids: $mainPodIdsToDisconnect}
                            }
                            float_pods: {
                                connect: {ids: $floatPodIdsToConnect}
                                disconnect: {ids: $floatPodIdsToDisconnect}
                            }
                        }
                    ) {
                        id
                    }
                }
            `, {
            jillId: jill.id,
            mainPodIdsToConnect,
            mainPodIdsToDisconnect,
            floatPodIdsToConnect,
            floatPodIdsToDisconnect,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('updateJillPods', error);
    }
}
exports.updateJillPods = updateJillPods;
