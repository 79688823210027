"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateChatStrictHours = exports.fetchChatStrictHours = void 0;
const graphql_1 = require("../../../../services/graphql");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function fetchChatStrictHours() {
    try {
        const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                query {
                    findChat_strict_hours {
                        items {
                            id
                            created_at
                            day_of_week
                            start_time
                            end_time
                            updated_at
                        }
                    }
                }
            `, {});
        if (result.succeeded === false) {
            throw new Error('fetchChatStrictHours failed ' + result);
        }
        return result.value.data.findChat_strict_hours.items;
    }
    catch (error) {
        throw new Error('fetchChatStrictHours() error: ' + error);
    }
}
exports.fetchChatStrictHours = fetchChatStrictHours;
async function updateChatStrictHours(chatStrictHours) {
    try {
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($inputs: [UpsertChat_strict_hoursInput]!) {
                    upsertManyChat_strict_hours(inputs: $inputs) {
                        id
                    }
                }
            `, {
            inputs: chatStrictHours,
        });
    }
    catch (error) {
        throw new Error('updateChatStrictHours() error: ' + error);
    }
}
exports.updateChatStrictHours = updateChatStrictHours;
