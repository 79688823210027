"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isDateWithinXMillisecondsOfScheduleItem = void 0;
const is_date_in_schedule_item_1 = require("./is-date-in-schedule-item");
const is_date_within_x_milliseconds_of_end_date_1 = require("./is-date-within-x-milliseconds-of-end-date");
const is_date_within_x_milliseconds_of_start_date_1 = require("./is-date-within-x-milliseconds-of-start-date");
function isDateWithinXMillisecondsOfScheduleItem(date, scheduleItem, milliseconds) {
    return ((0, is_date_in_schedule_item_1.isDateInScheduleItem)(scheduleItem, date) ||
        (0, is_date_within_x_milliseconds_of_start_date_1.isDateWithinXMillisecondsOfStartDate)(date, scheduleItem, milliseconds) ||
        (0, is_date_within_x_milliseconds_of_end_date_1.isDateWithinXMillisecondsOfEndDate)(date, scheduleItem, milliseconds));
}
exports.isDateWithinXMillisecondsOfScheduleItem = isDateWithinXMillisecondsOfScheduleItem;
