"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateCallWaitTime = void 0;
const diff_time_1 = require("../../../utilities/dates-and-times/diff-time");
function calculateCallWaitTime(call) {
    try {
        const callStartDurationEvent = call?.duration_events?.find(durationEvent => durationEvent?.description === 'CALL_START');
        const waitTimeDescription = 'CALL_ADDED_TO_CALLBACK_QUEUE';
        if (!callStartDurationEvent) {
            const callAddedToCallbackEvent = call?.duration_events?.find(durationEvent => durationEvent?.description === waitTimeDescription);
            if (!callAddedToCallbackEvent) {
                return 0;
            }
            return (0, diff_time_1.diffTime)(call.created_at, callAddedToCallbackEvent?.timestamp);
        }
        const callStartDurationEventTimestampString = callStartDurationEvent?.timestamp;
        return (0, diff_time_1.diffTime)(callStartDurationEventTimestampString, call.created_at);
    }
    catch (error) {
        throw new Error(`calculateCallWaitTime error: ${error}`);
    }
}
exports.calculateCallWaitTime = calculateCallWaitTime;
