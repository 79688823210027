"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.moveActionItemsToNewStatusType = void 0;
const graphql_1 = require("../../../services/graphql");
const error_handling_1 = require("../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function moveActionItemsToNewStatusType(selectedActionItems, moveActionItemsSelectedOption, companyId) {
    try {
        const actionItemIds = selectedActionItems.map(actionItem => actionItem.id);
        const actionItemStatusTypeId = getActionItemStatusTypeIdFromStatusName(moveActionItemsSelectedOption);
        const previousStatusTypeIds = getUniquePreviousStatusTypeIds(selectedActionItems);
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation (
                    $actionItemIds: [Int!]!
                    $actionItemStatusTypeId: Int!
                    $companyId: Int
                    $previousStatusTypeIds: [Int!]!
                ) {
                    moveActionItemsToNewStatusType(
                        input: {
                            action_item_ids: $actionItemIds
                            action_item_status_type_id: $actionItemStatusTypeId
                            company_id: $companyId
                            previous_status_type_ids: $previousStatusTypeIds
                        }
                    )
                }
            `, {
            actionItemIds,
            actionItemStatusTypeId,
            companyId,
            previousStatusTypeIds,
        });
        if (gqlResult.data === null || gqlResult.data === undefined) {
            return {
                succeeded: false,
                userMessage: 'Something went wrong',
                developerMessage: 'The gql request for moveActionItemsToNewStatusType succeeded but the data came back incorrect',
            };
        }
        return {
            succeeded: true,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('moveActionItemsToNewStatusType', error);
    }
}
exports.moveActionItemsToNewStatusType = moveActionItemsToNewStatusType;
function getUniquePreviousStatusTypeIds(selectedActionItems) {
    return [...new Set(selectedActionItems.map(a => a.action_item_status_type.id))];
}
function getActionItemStatusTypeIdFromStatusName(moveActionItemsSelectedOption) {
    if (moveActionItemsSelectedOption === 'Close') {
        return 1;
    }
    if (moveActionItemsSelectedOption === 'Assign to Company') {
        return 2;
    }
    if (moveActionItemsSelectedOption === 'Assign to Jill') {
        return 3;
    }
    if (moveActionItemsSelectedOption === 'Assign to Personal Admin') {
        return 5;
    }
    throw new Error('we should not have gotten here --- unaccounted for moveActionItemsSelectedOption');
}
