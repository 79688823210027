"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearLoggingTags = exports.attachLoggingTag = void 0;
const web_client_interface_1 = require("../web-client-interface/web-client-interface");
const handle_error_1 = require("./error-handling/handle-error");
const clientAttachmentCallbacks = [
    attachTagToFirebaseClient,
    attachTagToSentryClient,
];
const emptyTags = {
    authRole: undefined,
    userId: undefined,
};
function attachTagToFirebaseClient(tags) {
    const firebaseClient = (0, web_client_interface_1.getWebClientInterface)().firebaseClient;
    firebaseClient.setUserProperties(firebaseClient.getAnalytics(), tags);
}
function attachTagToSentryClient(tags) {
    const sentryClient = (0, web_client_interface_1.getWebClientInterface)().sentryClient;
    sentryClient.setTags(tags);
}
function attachLoggingTag(tags) {
    try {
        clientAttachmentCallbacks.forEach(callback => callback(tags));
    }
    catch (error) {
        (0, handle_error_1.handleError)(error);
    }
}
exports.attachLoggingTag = attachLoggingTag;
function clearLoggingTags() {
    try {
        clientAttachmentCallbacks.forEach(callback => callback(emptyTags));
    }
    catch (error) {
        (0, handle_error_1.handleError)(error);
    }
}
exports.clearLoggingTags = clearLoggingTags;
