"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchGoogleCalendarsForCompany = void 0;
const graphql_1 = require("../../../../../services/graphql");
const mapped_env_variables_1 = require("../../../../../utilities/environment/mapped-env-variables");
async function fetchGoogleCalendarsForCompany(companyId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!) {
                    getCompanies(id: $companyId) {
                        id
                        calendar_connections(filter: {archived_at: {eq: null}}) {
                            id
                            google_calendar_id
                            google_calendar_name
                            is_visible
                            can_delete_events
                            can_reschedule_events
                        }
                    }
                }
            `, {
            companyId,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const calendars = gqlResult.value.data.getCompanies.calendar_connections;
        return {
            succeeded: true,
            value: calendars,
        };
    }
    catch (error) {
        throw new Error(`fetchGoogleCalendarsForCompany error: ${error}`);
    }
}
exports.fetchGoogleCalendarsForCompany = fetchGoogleCalendarsForCompany;
