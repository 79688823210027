"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../services/utilities");
const define_custom_element_1 = require("../../utilities/define-custom-element");
require("./jo-button.element");
require("./jo-input");
const InitialState = {
    actionItemIsSelected: false,
    allItemsChecked: false,
    columns: [],
    companyId: 'NOT_SET',
    currentPage: 1,
    isCompanyUser: false,
    items: {},
    maxItemsPerPage: 10,
    moveActionItemsSelectedOption: '',
    numPages: 1,
    showSearchBar: true,
    searchDebounce: 0,
    showCheckboxes: false,
    selectedActionItems: [],
    tableName: 'NOT_SET',
};
class JOMobileActionItemsSearchRow extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    handleMoveSelectedActionItems(e) {
        (0, utilities_1.raiseCustomEvent)(this, 'selectedActionItemsMoved', e.detail.selectedValue);
        this.store.allItemsChecked = false;
    }
    handleSelectAll(event) {
        this.store.allItemsChecked = event.target.checked;
        this.store.items.items.map((item) => (0, utilities_1.raiseCustomEvent)(this, 'actionItemCheckboxSelected', { event, item }));
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .jo-toggle-select {
                    color: gray;
                    font-size: 0.8rem;
                    padding: 0.1rem;
                    text-align: center;
                }
                .jo-mobile-action-item--search-container-mobile {
                    width: 66%;
                }
                .jo-mobile-action-item--flex-box-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-end;
                }

                .jo-mobile-action-item--modified-input select {
                    /* height: 15px;
                width: 18px; */
                    padding: 0;
                    margin-right: 0.5rem;
                    margin-top: 1.3rem;
                    width: 5.5rem;
                    height: 1.5rem;
                }
                .jo-mobile-action-item--flex-row {
                    display: flex;
                    flex-direction: row;
                }
                .jo-mobile-action-item--checkbox {
                    width: 1.2rem;
                    height: 1.2rem;
                }
            </style>

            <div class="main-container">
                <div class="jo-mobile-action-item--flex-box-row">
                    <div class="jo-mobile-action-item--flex-row">
                        <div class="jo-toggle-select">
                            <span>Select All</span>
                            <input
                                class="jo-mobile-action-item--checkbox"
                                type="checkbox"
                                @change=${(e) => this.handleSelectAll(e)}
                                .checked=${state.allItemsChecked}
                            />
                        </div>
                        <div>
                            <jo-input
                                class="jo-mobile-action-item--modified-input"
                                .type=${'SELECT'}
                                .selectOptions=${[
            'Move To:',
            'Close',
            'Assign to Jill',
        ]}
                                .defaultOption=${''}
                                .selectedValue=${state.moveActionItemsSelectedOption}
                                .clearSelectedValueOnSelectionMade=${true}
                                @inputAltered=${(e) => this.handleMoveSelectedActionItems(e)}
                            ></jo-input>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-mobile-action-items-search-row', JOMobileActionItemsSearchRow);
