"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.newQuickTextPopUp = void 0;
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const css_utils_1 = require("../../../utilities/css-utils");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
require("../jo-button.element");
require("../jo-input");
const jo_quick_text__pop_up_1 = require("./jo-quick-text--pop-up");
require("./jo-quick-text-item");
const queries_and_mutations_1 = require("./queries-and-mutations");
const test_id_directive_1 = require("../../directives/test-id.directive");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    companyId: undefined,
    companyColumnName: undefined,
    textList: [],
    title: '- Missing Title -',
    globalConfigField: undefined,
    shouldHideListItems: true,
    textType: 'SHORT',
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-quick-text');
const cssNames = (classNames) => classNames.split(' ').map(cssName).join(' ');
class JOQuickText extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        this.fetchAndSetTextList();
    }
    async fetchAndSetTextList() {
        let result;
        if (this.store.companyId && this.store.companyColumnName) {
            result = await (0, queries_and_mutations_1.fetchTextListForCompany)(this.store.companyId, this.store.companyColumnName);
        }
        else {
            (0, utilities_1.assertIsDefined)(this.store.globalConfigField);
            result = await (0, queries_and_mutations_1.fetchGlobalTextList)(this.store.globalConfigField);
        }
        (0, utilities_1.assertSucceeded)(result);
        this.store.textList = result.value;
    }
    removeTextItemClicked(textToRemove) {
        this.store.textList = this.store.textList.filter(text => {
            return text !== textToRemove;
        });
    }
    handleItemVisibiltyChange() {
        this.store.shouldHideListItems = !this.store.shouldHideListItems;
    }
    addItem(safeInput) {
        const newTextList = [
            ...this.store.textList,
            safeInput,
        ];
        this.store.textList = newTextList;
    }
    arrowClickEventHandler(textToMove, direction) {
        this.store.textList = this.updateTextListOrder(textToMove, direction);
    }
    updateTextListOrder(textToMove, direction) {
        var { futureIndex, currentIndex } = getIndexsToSwapp(this.store.textList, textToMove, direction);
        return swapArrayItems(this.store.textList, futureIndex, currentIndex);
    }
    cancelButtonClicked() {
        this.fetchAndSetTextList();
        (0, utilities_1.joAlert)('Changes Reset', 'Changes have been reset');
    }
    async saveButtonClicked() {
        if (this.store.companyId && this.store.companyColumnName) {
            this.displayUpdateResultMessage(await (0, queries_and_mutations_1.updateTextListForCompany)(this.store.companyId, this.store.textList, this.store.companyColumnName));
        }
        else {
            (0, utilities_1.assertIsDefined)(this.store.globalConfigField);
            this.displayUpdateResultMessage(await (0, queries_and_mutations_1.updateGlobalTextList)(this.store.globalConfigField, this.store.textList));
        }
    }
    displayUpdateResultMessage(result) {
        if (result.succeeded)
            (0, utilities_1.joAlert)('Update Successful', `${this.store.title} have been updated`);
        else {
            (0, utilities_1.joAlert)(`Updating ${this.store.title} Failed`, result.userMessage);
            console.error('displayUpdateResultMessage() Error ', result);
        }
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
            <style>
                .${cssNames('main-container')} {
                    display: flex;
                    height: auto;
                    width: 100%;
                    box-sizing: border-box;
                    flex-wrap: wrap;
                }
                .${cssNames('flex-container')} {
                    display: flex;
                }
                .${cssNames('flex-col')} {
                    flex-direction: column;
                }
                .${cssNames('flex-item-1')} {
                    flex:1;
                }
                .${cssNames('flex-item-2')} {
                    flex:2;
                }
                .${cssNames('padding-item-1')} {
                    padding: 1rem;
                }
                .${cssNames('containerCard')} {
                    margin: 1rem;
                    width: 100%;
                }
                .${cssNames('new-button')}{
                    margin-top: 1.5rem;
                    margin-right: 1rem;
                }
                .${cssNames('button-container')} {
                    float:right;
                }

            </style>

            <div class="${cssNames('main-container')}">
                <div class="jo-global--card ${cssNames('containerCard')}">
                    <div class="${cssNames('flex-container padding-item-1')}">
                        <h2 class="${cssNames('flex-item-2')}">Edit ${this.store.title}</h1>
                        <div
                            title="Expand"
                            class="${cssNames('button-container new-button')}"
                            ?hidden=${state.shouldHideListItems === false}
                        >
                            <jo-button
                                ${(0, test_id_directive_1.testId)('jo-quick-text-expand')}
                                class="${cssNames('flex-item-1')} "
                                .type=${'ICON'}
                                .text=${'expand_more'}
                                @click=${() => {
            this.handleItemVisibiltyChange();
        }}
                            ></jo-button>
                        </div>
                        <div
                            title="Collapse"
                            class="${cssNames('button-container new-button')}"
                            ?hidden=${state.shouldHideListItems}
                        >
                            <jo-button
                                ${(0, test_id_directive_1.testId)('jo-quick-text-collapse')}
                                class="${cssNames('flex-item-1')} "
                                .type=${'ICON'}
                                .text=${'expand_less'}
                                @click=${() => {
            this.handleItemVisibiltyChange();
        }}
                            ></jo-button>
                        </div>
                        <div title="Add New Item" class="${cssNames('button-container new-button')}">
                            <jo-button
                                ${(0, test_id_directive_1.testId)('jo-quick-text-add')}
                                class="${cssNames('flex-item-1')} "
                                .type=${'ICON'}
                                .text=${'add'}
                                @click=${() => {
            newQuickTextPopUp(state.title, state.textType, this);
        }}
                            ></jo-button>
                        </div>
                    </div>
                    <div
                        ${(0, test_id_directive_1.testId)('jo-quick-text-text-items-container')}
                    >
                        ${state.textList.map(text => (0, lit_html_1.html) `
                                <jo-quick-text-item
                                    ?hidden=${state.shouldHideListItems}
                                    .text=${text}
                                    @removeTextItemClicked=${(e) => {
            this.removeTextItemClicked(e.detail);
        }}
                                    @downArrowClicked=${(e) => {
            this.arrowClickEventHandler(e.detail, 'DOWN');
        }}
                                    @upArrowClicked=${(e) => {
            this.arrowClickEventHandler(e.detail, 'UP');
        }}
                                ></jo-quick-text-item>
                            `)}
                    </div>

                   <div class="${cssNames('flex-container')} button-container">
                        <jo-button
                            ${(0, test_id_directive_1.testId)('jo-quick-text-save')}
                            class="${cssNames('padding-item-1')}"
                            .type=${'ACTION_BUTTON_1'}
                            .text=${'Save'}
                            @joButtonClick=${async () => this.saveButtonClicked()}
                        ></jo-button>
                        <jo-button
                            class="${cssNames('padding-item-1')}"
                            .type=${'SECONDARY_BUTTON_1'}
                            .text=${'Cancel'}
                            @joButtonClick=${() => {
            this.cancelButtonClicked();
        }}
                        ></jo-button>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-quick-text', JOQuickText);
function getIndexsToSwapp(newTextList, textToMove, direction) {
    const currentIndex = newTextList.indexOf(textToMove);
    let futureIndex = direction === 'DOWN' ? currentIndex + 1 : currentIndex - 1;
    if (futureIndex < 0) {
        futureIndex = 0;
    }
    if (futureIndex >= newTextList.length) {
        futureIndex = newTextList.length - 1;
    }
    return { futureIndex, currentIndex };
}
async function newQuickTextPopUp(title, textType, joQuickText) {
    return new Promise(resolve => {
        const newQuickTextPopUp = new jo_quick_text__pop_up_1.JOQuickTextPopUp(title, textType);
        document.body.appendChild(newQuickTextPopUp);
        newQuickTextPopUp.addEventListener('addNewQuickItem', data => {
            const newInput = data.detail.toString();
            newInput.trim();
            const safeInput = newInput.replace(/~/g, '-');
            joQuickText.addItem(safeInput);
            document.body.removeChild(newQuickTextPopUp);
            resolve();
        });
        newQuickTextPopUp.addEventListener('cancel', () => {
            document.body.removeChild(newQuickTextPopUp);
            resolve();
        });
    });
}
exports.newQuickTextPopUp = newQuickTextPopUp;
function swapArrayItems(originalArray, futureIndex, currentIndex) {
    let newArray = [...originalArray];
    let tempItem = newArray[futureIndex];
    newArray[futureIndex] = newArray[currentIndex];
    newArray[currentIndex] = tempItem;
    return newArray;
}
