"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
require("../jo-button.element");
require("../jo-input");
const InitialState = {
    data: 'NOT_SET',
};
class JOCompanyContactsCustomActionTemplate extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .jo-action-items-mobile-button
                    .jo-button--main-container
                    .jo-global--action-button-1 {
                    border-radius: 50%;
                    font-size: 1.5rem;
                    margin-right: 2rem;
                    height: 30px;
                    width: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    font-weight: 900;
                    padding-bottom: 11px;
                }
            </style>

            <div class="jo-action-items-mobile-button-container">
                <jo-button
                    class="jo-action-items-mobile-button"
                    .type=${'ACTION_BUTTON_1'}
                    .text=${'+'}
                    .href=${state.data.isCompanyUser
            ? `/customer/contact?companyId=${state.data.companyId}`
            : `/company/contact?companyId=${state.data.companyId}`}
                ></jo-button>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-contacts-custom-action-template', JOCompanyContactsCustomActionTemplate);
