"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStripeTotalForInvoice = void 0;
function getStripeTotalForInvoice(invoice, field) {
    const stipeInvoice = invoice.stripe_invoice;
    if (!stipeInvoice) {
        return 0;
    }
    return stipeInvoice[field] ?? 0;
}
exports.getStripeTotalForInvoice = getStripeTotalForInvoice;
