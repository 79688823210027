"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const utilities_1 = require("../../../services/utilities");
const css_utils_1 = require("../../../utilities/css-utils");
const format_date_for_datetime_local_input_1 = require("../../../utilities/dates-and-times/format-date-for-datetime-local-input");
const get_start_of_date_1 = require("../../../utilities/dates-and-times/get-start-of-date");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const build_try_execute_async_1 = require("../../../utilities/error-handling/build-try-execute-async");
const state_manager_1 = require("../../../utilities/state-management/state-manager");
require("../jo-input");
require("../jo-loading-text");
require("./jo-chat-stats-simple-display");
const queries_1 = require("./queries");
const test_id_directive_1 = require("../../directives/test-id.directive");
const jill_queries_gql_1 = require("../../../utilities/jill-utils/jill-queries.gql");
const InitialState = {
    chatStats: {
        items: [],
        count: 0,
    },
    companies: [],
    endDate: (0, get_start_of_date_1.getStartOfTomorrow)(),
    includeTestCompanies: false,
    jills: [],
    loading: false,
    pods: [],
    selectedJill: undefined,
    selectedCompany: undefined,
    selectedPod: undefined,
    startDate: (0, utilities_1.getStartOfToday)(),
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-chat-stats-overview');
const executeAsync = (0, build_try_execute_async_1.buildtryExecuteAsync)(utilities_1.handleError);
const companyAllOption = 'All';
class JOChatStatsController extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = new state_manager_1.StateManager(InitialState, state => (0, lit_html_1.render)(this.render(state), this));
    }
    async connectedCallback() {
        await this.fetchAndSetData();
    }
    async fetchAndSetData() {
        this.store.updateState({ loading: true });
        const [jills, rawCompanies, pods, chatStats,] = await Promise.all([
            executeAsync(jill_queries_gql_1.fetchJills),
            executeAsync(queries_1.fetchCompanies),
            executeAsync(queries_1.fetchPods),
            this.fetchChatStats(),
        ]);
        const companies = this.removeTestCompaniesIfSpecified(rawCompanies);
        this.store.updateState({
            jills: jills.filter(user => user.permissions?.has_chat_access),
            companies,
            pods,
            chatStats,
            loading: false,
        });
    }
    removeTestCompaniesIfSpecified(companies) {
        const includeTestCompanies = this.store.getState().includeTestCompanies;
        if (!includeTestCompanies) {
            return companies?.filter(company => !company.is_test_company);
        }
        return companies;
    }
    async fetchAndSetChatStats() {
        this.store.updateState({ loading: true });
        const chatStats = await this.fetchChatStats();
        this.store.updateState({
            chatStats,
            loading: false,
        });
    }
    async fetchChatStats() {
        const state = this.store.getState();
        const chatStats = await executeAsync(() => (0, queries_1.fetchChatStats)({
            startDate: state.startDate,
            endDate: state.endDate,
            jillId: state.selectedJill?.id ?? null,
            companyId: state.selectedCompany?.id ?? null,
            podId: state.selectedPod?.id ?? null,
            includeTestCompanies: state.includeTestCompanies,
        }));
        return chatStats;
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .${cssName('main-container')} {
                    width: 100%;
                }

                .${cssName('filter-select-area')} {
                    display: flex;
                    gap: 1rem;
                }

                .${cssName('date-select-area')} {
                    display: flex;
                    padding-top: 3rem;
                    gap: 1rem;
                }

                .${cssName('loading-text-area')} {
                    height: 2rem;
                    display: flex;
                    align-items: center;
                }

                .${cssName('table-container')} {
                    padding-top: 3rem;
                }
            </style>

            <div class="${cssName('main-container')}">
                <div>
                    <h2>Chat Stats Overview</h2>
                </div>

                <div class=${cssName('filter-select-area')}>
                    <jo-input
                        ${(0, test_id_directive_1.testId)('chat-stats-jill-input')}
                        .type=${'SELECT'}
                        .label=${'Jill'}
                        .selectOptions=${generateJOSelectOptions(state.jills, jill => {
            return `${jill.id}: ${(0, utilities_1.getUserFullName)(jill)}`;
        })}
                        .selectedValue=${state.selectedJill?.id +
            ': ' +
            (0, utilities_1.getUserFullName)(state.selectedJill) ?? 'All'}
                        @valueChanged=${(event) => {
            const jillId = Number(event.detail.selectedValue.match(/^(\d+):/)?.[1]);
            if (isNaN(jillId)) {
                throw new Error(`Could not extract jill id from "${event.detail.selectedValue}"`);
            }
            const jill = state.jills.find(jill => jill.id === jillId);
            this.store.updateState({
                selectedJill: jill,
            });
            this.fetchAndSetChatStats();
        }}
                    ></jo-input>

                    <jo-input
                        ${(0, test_id_directive_1.testId)('chat-stats-company-input')}
                        .type=${'SELECT'}
                        .label=${'Company'}
                        .selectOptions=${generateJOSelectOptions(state.companies, company => company.name)}
                        .selectedValue=${state.selectedCompany?.name ?? companyAllOption}
                        @valueChanged=${(event) => {
            const company = findByName(state.companies, event.detail.selectedValue);
            this.store.updateState({
                selectedCompany: company,
            });
            this.fetchAndSetChatStats();
        }}
                    ></jo-input>

                    <jo-input
                        ${(0, test_id_directive_1.testId)('chat-stats-pod-input')}
                        .type=${'SELECT'}
                        .label=${'Pod'}
                        .selectOptions=${generateJOSelectOptions(state.pods, pod => pod.name ?? 'No Pod Name')}
                        .selectedValue=${state.selectedPod?.name ?? 'All'}
                        @valueChanged=${(event) => {
            const pod = findByName(state.pods, event.detail.selectedValue);
            this.store.updateState({
                selectedPod: pod,
            });
            this.fetchAndSetChatStats();
        }}
                    ></jo-input>
                </div>

                <div class=${cssName('date-select-area')}>
                    <div>
                        <div>Start Date</div>
                        <input
                            type="datetime-local"
                            class="jo-global--input"
                            .value=${(0, format_date_for_datetime_local_input_1.formatDateForDatetimeLocalInput)(state.startDate)}
                            @change=${(e) => {
            this.store.updateState({
                startDate: new Date(e.target.value),
            });
            this.fetchAndSetChatStats();
        }}
                        />
                    </div>

                    <div>
                        <div>End Date</div>
                        <input
                            type="datetime-local"
                            class="jo-global--input"
                            .value=${(0, format_date_for_datetime_local_input_1.formatDateForDatetimeLocalInput)(state.endDate)}
                            @change=${(e) => {
            this.store.updateState({
                endDate: new Date(e.target.value),
            });
            this.fetchAndSetChatStats();
        }}
                        />
                    </div>

                    <jo-input
                        .type=${'CHECKBOX'}
                        .label=${'Include Test Companies'}
                        .checkedValue=${state.includeTestCompanies}
                        @inputchange=${(e) => {
            this.store.updateState({
                includeTestCompanies: e.detail.checkedValue,
            });
            this.fetchAndSetData();
        }}
                    ></jo-input>
                </div>

                <div class=${cssName('loading-text-area')}>
                    ${state.loading
            ? (0, lit_html_1.html) `
                              <jo-loading-text></jo-loading-text>
                          `
            : ''}
                </div>

                <div class=${cssName('table-container')}>
                    <jo-chats-simple-display
                        .props=${{
            startDate: state.startDate,
            endDate: state.endDate,
            chatStats: state.chatStats,
            jill: state.selectedJill,
            company: state.selectedCompany,
            pod: state.selectedPod,
        }}
                    ></jo-chats-simple-display>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-chat-stats-overview', JOChatStatsController);
function findByName(list, name) {
    if (name === companyAllOption)
        return undefined;
    const item = list.find(company => company.name === name);
    if (!item) {
        throw new Error(`Could not map name "${name}" back to an item from ${list}.`);
    }
    return item;
}
function generateJOSelectOptions(items, getDisplayValue) {
    return [
        'All',
        ...items.map(item => getDisplayValue(item)),
    ];
}
