"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateWorkStatus = void 0;
const graphql_1 = require("../services/graphql");
const mapped_env_variables_1 = require("./environment/mapped-env-variables");
async function updateWorkStatus(userId, workStatus) {
    try {
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($userId: Int!, $workStatus: WORK_STATUS!) {
                    updateUsers(input: {id: $userId, work_status: $workStatus}) {
                        id
                    }
                }
            `, {
            userId,
            workStatus,
        });
    }
    catch (error) {
        throw new Error(`updateWorkStatus error: ${error.message}`);
    }
}
exports.updateWorkStatus = updateWorkStatus;
