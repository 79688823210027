"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const css_utils_1 = require("../../../utilities/css-utils");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const test_id_directive_1 = require("../../directives/test-id.directive");
require("../jo-button.element");
require("../jo-input");
const InitialState = {
    text: '',
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-quick-text-item');
const cssNames = (classNames) => classNames.split(' ').map(cssName).join(' ');
class JOQuickTextItem extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .${cssNames('itemCard')} {
                    padding-left: 1rem;
                    padding-right: 1rem;
                    padding-bottom: 0.5rem;
                    margin: 0.5rem;
                    width: 96%;
                }
                .${cssNames('deleteButton')} {
                    text-align: right;
                    color: var(--jo-danger);
                    cursor: pointer;
                    float: right;
                }
                .${cssNames('deleteButton')}:hover {
                    color: var(--jo-danger);
                }
                .${cssNames('arrow')} {
                    cursor: pointer;
                    color: var(--jo-primary);
                }
                .${cssNames('arrow')}:hover {
                    color: var(--jo-secondary-dark);
                }
                .${cssNames('flex-container')} {
                    display: flex;
                    flex-direction: row;
                }
                .${cssNames('flex-item-1')} {
                    flex: 1;
                }
                .${cssNames('flex-item-2')} {
                    flex: 5;
                }

                @media (max-width: 1200px) {
                    .${cssNames('flex-container')} {
                        display: flex;
                        flex-direction: row;
                        padding: 0.5rem;
                    }
                }
            </style>

            <div>
                <div class="jo-global--card ${cssNames('itemCard')}">
                    <div class="${cssNames('flex-container')}">
                        <div
                            ${(0, test_id_directive_1.testId)('jo-global-card-item-title')}
                            class="${cssNames('flex-item-2')}"
                        >
                            ${state.text}
                        </div>

                        <div class="${cssNames('flex-item-1')}">
                            <i
                                class="material-icons ${cssNames('arrow')}"
                                @click=${() => {
            (0, utilities_1.raiseCustomEvent)(this, 'downArrowClicked', state.text);
        }}
                            >
                                keyboard_arrow_down
                            </i>
                        </div>

                        <div class="${cssNames('flex-item-1')}">
                            <i
                                class="material-icons ${cssNames('arrow')}"
                                @click=${() => {
            (0, utilities_1.raiseCustomEvent)(this, 'upArrowClicked', state.text);
        }}
                            >
                                keyboard_arrow_up
                            </i>
                        </div>

                        <div class="${cssNames('flex-item-1')}">
                            <i
                                ${(0, test_id_directive_1.testId)('jo-quick-note-delete-button')}
                                class="material-icons ${cssNames('deleteButton')}"
                                @click=${() => {
            (0, utilities_1.raiseCustomEvent)(this, 'removeTextItemClicked', state.text);
        }}
                            >
                                clear
                            </i>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-quick-text-item', JOQuickTextItem);
