"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOOutboundTable = void 0;
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const jo_table_manager_1 = require("../../../../classes/jo-table-manager");
const graphql_1 = require("../../../../services/graphql");
const utilities_1 = require("../../../../services/utilities");
const auth_rules_1 = require("../../../../utilities/auth-rules");
const css_utils_1 = require("../../../../utilities/css-utils");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
const build_try_execute_async_1 = require("../../../../utilities/error-handling/build-try-execute-async");
const publish_outbound_queue_item_updated_event_1 = require("../../jo-call-screen/utilities/publish-outbound-queue-item-updated-event");
const queries_1 = require("../../jo-company-details/queries");
require("../jo-create-outbound-list/jo-create-outbound-list");
const queries_and_mutations_1 = require("../../jo-quick-text/queries-and-mutations");
const generate_csv_1 = require("../jo-outbound-csv/generate-csv");
const mutations_1 = require("./mutations");
const queries_2 = require("./queries");
const styles_1 = require("./styles");
const get_table_columns_1 = require("./utils/get-table-columns");
const get_table_name_1 = require("./utils/get-table-name");
const helper_utils_1 = require("./utils/helper-utils");
const common_1 = require("@augment-vir/common");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    actionItemTitleOptions: [],
    campaignNameSelectionOptions: [],
    companyId: 'NOT_SET',
    companySelectionOptions: [],
    enableButtons: true,
    outboundQueueItems: [],
    outboundQueueUpdateSubscription: 'NOT_SET',
    podSelectionOptions: [],
    selectedActionItemTitle: 'All',
    selectedCampaignName: 'All',
    selectedCompanyName: 'All',
    selectedPod: 'All',
    selectedTimeZone: 'All',
    selectedType: 'CURRENT',
    timeZoneSelectionOptions: [],
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-outbound-table');
const executeAsync = (0, build_try_execute_async_1.buildtryExecuteAsync)(utilities_1.handleError);
class JOOutboundTable extends HTMLElement {
    constructor() {
        super(...arguments);
        this.tableManager = new jo_table_manager_1.JOTableManager({
            maxItemsPerPage: 50,
            columnsGetter: get_table_columns_1.getTableColumns,
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    async connectedCallback() {
        if (!(0, auth_rules_1.isCompanyUser)(this.store.authenticatedUser)) {
            this.createSubscriptionsIfNecessary();
        }
        this.fetchAndSetData();
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    async fetchAndSetData() {
        await this.fetchAndSetOutboundQueueItems();
        await this.fetchAndSetSelectionOptionsForFilters();
    }
    async fetchAndSetOutboundQueueItems() {
        const searchOutboundQueueItemResult = await (0, queries_2.fetchOutboundQueueItems)({
            actionItemTitle: this.store.selectedActionItemTitle,
            campaignName: this.store.selectedCampaignName,
            companyId: this.getSelectedCompanyId(),
            companyName: this.store.selectedCompanyName,
            limit: this.tableManager.maxItemsPerPage,
            offset: this.tableManager.offset,
            podName: this.store.selectedPod,
            timezone: this.store.selectedTimeZone,
            type: this.store.selectedType,
        });
        this.store.outboundQueueItems = searchOutboundQueueItemResult.outbound_queue_items;
        this.tableManager.setItems(searchOutboundQueueItemResult.outbound_queue_items);
        this.tableManager.setTotalItems(searchOutboundQueueItemResult.total);
        this.store.campaignNameSelectionOptions = [
            'All',
            ...searchOutboundQueueItemResult.campaign_names,
        ];
        this.store.dispatch({
            type: 'RENDER',
        });
    }
    disconnectedCallback() {
        this.closeSubscriptionsIfNecessary();
    }
    createSubscriptionsIfNecessary() {
        if (this.store.outboundQueueUpdateSubscription === 'NOT_SET') {
            this.subscribeToOutboundQueueUpdateEvent();
        }
    }
    closeSubscriptionsIfNecessary() {
        if (this.store.outboundQueueUpdateSubscription !== 'NOT_SET') {
            this.store.outboundQueueUpdateSubscription.close();
            this.store.outboundQueueUpdateSubscription = 'NOT_SET';
        }
    }
    async fetchAndSetSelectionOptionsForFilters() {
        if (!(0, auth_rules_1.isCompanyUser)(this.store.authenticatedUser)) {
            await Promise.all([
                this.fetchAndSetCompanySelectionOptions(),
                this.fetchAndSetPodSelectionOptions(),
            ]);
            this.setTimeZoneSelectionOptions();
        }
        this.setActionItemTitleOptions();
    }
    async setActionItemTitleOptions() {
        const result = await (0, queries_and_mutations_1.fetchGlobalTextList)('ai_quick_titles');
        (0, utilities_1.assertSucceeded)(result);
        this.store.actionItemTitleOptions = result.value;
    }
    closeSubscription() {
        if (this.store.outboundQueueUpdateSubscription !== 'NOT_SET') {
            this.store.outboundQueueUpdateSubscription.close();
        }
    }
    async subscribeToOutboundQueueUpdateEvent() {
        this.store.outboundQueueUpdateSubscription =
            (await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
                    subscription {
                        outboundQueueUpdated
                    }
                `, () => {
                this.fetchAndSetOutboundQueueItems();
            })) ?? 'NOT_SET';
    }
    async fetchAndSetCompanySelectionOptions() {
        this.store.companySelectionOptions = await executeAsync(queries_2.fetchCompanies);
    }
    async fetchAndSetPodSelectionOptions() {
        const podsResult = await (0, queries_1.fetchPods)();
        (0, utilities_1.assertSucceeded)(podsResult, utilities_1.handleError);
        const podNames = (0, helper_utils_1.getPodNames)(podsResult);
        const uniquePodSelectionOptions = [
            'All',
            ...new Set(podNames),
        ];
        this.store.podSelectionOptions = uniquePodSelectionOptions;
    }
    setTimeZoneSelectionOptions() {
        const timeZoneSelectionOptions = this.store.companySelectionOptions.map(company => company.timezone);
        const uniqueTimeZoneSelectionOptions = [
            'All',
            ...new Set(timeZoneSelectionOptions),
        ];
        this.store.timeZoneSelectionOptions = uniqueTimeZoneSelectionOptions;
    }
    async confirmCloseAllForCompanyName() {
        const shouldContinue = await (0, utilities_1.joConfirm)('Notice', 'Are you sure you want to close all items in Outbound Queue for ' +
            this.store.selectedCompanyName +
            ' with the selected campaign name ' +
            this.store.selectedCampaignName +
            '?');
        if (!shouldContinue)
            return;
        this.closeAllQueueItemsForSelectedCompany();
    }
    async closeAllQueueItemsForSelectedCompany() {
        if (this.store.selectedCompanyName === 'All') {
            return;
        }
        const selectedCompanyId = this.getSelectedCompanyId();
        const result = await (0, mutations_1.closeQueueItemsForSelectedCompanyAndCampaignName)(selectedCompanyId, this.store.selectedCampaignName);
        if (result.succeeded) {
            await this.updateTableAndDisplaySuccessMessage();
        }
    }
    async updateTableAndDisplaySuccessMessage() {
        this.resetFilters();
        await (0, publish_outbound_queue_item_updated_event_1.publishOutboundQueueUpdatedMessage)();
        await (0, utilities_1.joAlert)('Success!', 'All Queue Items for ' + this.store.selectedCompanyName + ' have been closed.');
    }
    getSelectedCompanyId() {
        if (this.store.companyId !== 'NOT_SET') {
            return this.store.companyId;
        }
        const companyOptions = this.getSelectedCompanyOption();
        if (!(0, common_1.isLengthAtLeast)(companyOptions, 1)) {
            return -1;
        }
        return companyOptions[0].id;
    }
    getSelectedCompanyOption() {
        return this.store.companySelectionOptions.filter(option => {
            if (option.name === this.store.selectedCompanyName) {
                return option;
            }
        });
    }
    resetFilters() {
        this.store.selectedCampaignName = 'All';
        this.store.selectedTimeZone = 'All';
        this.store.selectedPod = 'All';
        this.store.selectedCompanyName = 'All';
        this.store.selectedType = 'CURRENT';
        this.fetchAndSetOutboundQueueItems();
    }
    getDataListOptionsForCompanyFilter() {
        return this.store.companySelectionOptions.map(option => {
            return (0, lit_html_1.html) `
                <option value=${option.name}></option>
            `;
        });
    }
    createCSV() {
        try {
            (0, generate_csv_1.generateAndDownloadCSV)(this.store.outboundQueueItems);
        }
        catch (error) {
            alert(error);
            throw new Error('Outbound table CSV generation failed: ' + error);
        }
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

            ${styles_1.stylesForjoOutboundTable}

            <div class="${cssName('main-container')}">
                <div class="jo-global--card">
                    <div class="${cssName('filter-row')}">
                        <jo-input
                            .type=${'SELECT'}
                            .label=${'Select Table Type'}
                            .selectOptions=${[
            'ALL',
            'CURRENT',
            'UPCOMING',
            'COMPLETED',
        ]}
                            .selectedValue=${state.selectedType}
                            @valueChanged=${(e) => {
            this.store.selectedType = e.detail
                .selectedValue;
            this.fetchAndSetData();
        }}
                        ></jo-input>

                        <jo-input
                            .type=${'SELECT'}
                            .label=${'Select Campaign Name'}
                            .selectOptions=${this.store.campaignNameSelectionOptions}
                            .selectedValue=${state.selectedCampaignName}
                            @valueChanged=${(e) => {
            this.store.selectedCampaignName = e.detail.selectedValue;
            this.fetchAndSetData();
        }}
                        ></jo-input>

                        <jo-input
                            .type=${'SELECT'}
                            .label=${'Select Action Item Title'}
                            .selectOptions=${[
            'All',
            ...this.store.actionItemTitleOptions,
        ]}
                            .selectedValue=${state.selectedActionItemTitle}
                            @valueChanged=${(e) => {
            this.store.selectedActionItemTitle = e.detail.selectedValue;
            this.fetchAndSetData();
        }}
                        ></jo-input>

                        <span
                            ?hidden=${(0, auth_rules_1.isCompanyUser)(this.store.authenticatedUser)}
                            title="Reset Filters"
                            class="material-icons ${cssName('reset-fitlers')} "
                            @click=${() => {
            this.resetFilters();
            this.fetchAndSetData();
        }}
                        >
                            refresh
                        </span>
                    </div>

                    <div class="${cssName('filter-row')}">
                        <jo-input
                            ?hidden=${(0, auth_rules_1.isCompanyUser)(this.store.authenticatedUser)}
                            .type=${'SELECT'}
                            .label=${'Select Pod'}
                            .selectOptions=${this.store.podSelectionOptions}
                            .selectedValue=${state.selectedPod}
                            @valueChanged=${(e) => {
            this.store.selectedPod = e.detail.selectedValue;
            this.fetchAndSetData();
        }}
                        ></jo-input>

                        <jo-input
                            ?hidden=${(0, auth_rules_1.isCompanyUser)(this.store.authenticatedUser)}
                            .type=${'SELECT'}
                            .label=${'Select Time Zone'}
                            .selectOptions=${state.timeZoneSelectionOptions}
                            .selectedValue=${state.selectedTimeZone}
                            @valueChanged=${(e) => {
            this.store.selectedTimeZone = e.detail.selectedValue;
            this.fetchAndSetData();
        }}
                        ></jo-input>

                        <div
                            class="${cssName('companies-filter-container')}"
                            ?hidden=${(0, auth_rules_1.isCompanyUser)(this.store.authenticatedUser)}
                        >
                            <label for="companiesFilter">Select Company</label>
                            <input
                                list="companyOptions"
                                name="companiesFilter"
                                id="companiesFilter"
                                value=${state.selectedCompanyName}
                                class="${cssName('companies-filter-input')} jo-global--input"
                                @focus="${(e) => {
            e.target.value = '';
        }}"
                                @change=${(e) => {
            this.store.selectedCompanyName = e.target.value;
            this.fetchAndSetData();
        }}
                            />
                        </div>
                        <datalist
                            id="companyOptions"
                            ?hidden=${(0, auth_rules_1.isCompanyUser)(this.store.authenticatedUser)}
                        >
                            <option value="All"></option>
                            ${this.getDataListOptionsForCompanyFilter()}
                        </datalist>

                        <jo-button
                            ?hidden=${this.store.selectedCampaignName === 'All'}
                            class="${cssName('filter-close-button')}"
                            .type=${'ACTION_BUTTON_2'}
                            .text=${'Download CSV'}
                            @joButtonClick=${async () => {
            this.createCSV();
        }}
                        ></jo-button>
                    </div>

                    <div
                        class="${cssName('filter-row')}"
                        ?hidden=${this.store.enableButtons === false}
                    >
                        ${state.selectedCompanyName === 'All'
            ? ``
            : (0, lit_html_1.html) `
                                  <jo-create-outbound-list
                                      class="${cssName('filter-button')}"
                                      .companyId=${this.getSelectedCompanyId()}
                                      .authenticatedUser=${state.authenticatedUser}
                                      @outboundListCreated=${() => {
                this.fetchAndSetData();
            }}
                                  ></jo-create-outbound-list>

                                  <jo-button
                                      class="${cssName('filter-close-button')}"
                                      .type=${'ACTION_BUTTON_1'}
                                      .text=${'Close All For Company'}
                                      @joButtonClick=${async () => {
                this.confirmCloseAllForCompanyName();
            }}
                                  ></jo-button>
                              `}
                    </div>

                    <jo-table
                        class="${cssName('outbound-queue-table')}"
                        .tableName=${(0, get_table_name_1.getTableName)(this.store.selectedType)}
                        .totalItems=${this.tableManager.totalItems}
                        .items=${this.store.outboundQueueItems}
                        .maxItemsPerPage=${this.tableManager.maxItemsPerPage}
                        .columns=${(0, get_table_columns_1.getTableColumns)(this.store.getState())}
                        .showSearchBar=${false}
                        @tablePageClicked=${(e) => {
            this.tableManager.setOffset(e.detail.newOffset);
            this.fetchAndSetOutboundQueueItems();
        }}
                    ></jo-table>
                </div>
            </div>
        `;
    }
}
exports.JOOutboundTable = JOOutboundTable;
(0, define_custom_element_1.defineCustomElement)('jo-outbound-table', JOOutboundTable);
