"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
require("../jo-button.element");
require("../jo-input");
require("./jo-personal-admin-action-items-table");
require("./jo-toggle-admin-mode");
const InitialState = {
    authenticatedUser: 'NOT_SET',
};
class JOPersonalAdminDashboard extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }
            </style>

            <div class="main-container">
                <section>
                    <h2>Welcome to Your Personal Admin Dashboard!</h2>
                </section>

                ${isUserInPersonalAdminMode(state.authenticatedUser)
            ? (0, lit_html_1.html) `
                          <section style="padding-top: 3rem;">
                              <jo-personal-admin-action-items-table
                                  .authenticatedUser=${state.authenticatedUser}
                              ></jo-personal-admin-action-items-table>
                          </section>
                      `
            : ''}
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-personal-admin-dashboard', JOPersonalAdminDashboard);
function isUserInPersonalAdminMode(user) {
    if (user === 'NOT_SET')
        return false;
    return user.in_personal_admin_mode;
}
