"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertUTCDateToMountainTime = exports.nowInMountainTime = void 0;
const lit_html_1 = require("lit-html");
const redux_1 = require("redux");
const graphql_1 = require("../../../services/graphql");
require("../jo-table");
const helper_utils_1 = require("./helper-utils");
const fetch_live_queue_1 = require("./queries/fetch-live-queue");
const fetch_incoming_queue_for_interval_1 = require("./queries/fetch-incoming-queue-for-interval");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const create_element_bound_interval_1 = require("../../../utilities/timeouts/create-element-bound-interval");
const dates_and_times_1 = require("../../../utilities/dates-and-times/dates-and-times");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
const replace_not_set_with_undefined_1 = require("../../../utilities/type-helpers/replace-not-set-with-undefined/replace-not-set-with-undefined");
const InitialState = {
    liveQueue: [],
    deadQueue: [],
    pods: [],
    selectedPodId: 'NOT_SET',
    statsForCurrentInterval: null,
    startDate: null,
    endDate: null,
    fetchingDeadQueue: false,
    showLoadingDeadQueue: false,
    incomingCallType: 'NOT_SET',
    deadQueueFetchAbortController: 'NOT_SET',
};
function RootReducer(state = InitialState, action) {
    switch (action.type) {
        case 'SET_FETCHING_DEAD_QUEUE':
            return { ...state, fetchingDeadQueue: action.fetchingDeadQueue };
        case 'SET_DEAD_QUEUE':
            return { ...state, deadQueue: action.deadQueue };
        case 'SET_LIVE_QUEUE':
            return { ...state, liveQueue: action.liveQueue };
        case 'ADD_TO_FRONT_OF_DEAD_QUEUE':
            return {
                ...state,
                deadQueue: [
                    ...action.incomingQueueItems,
                    ...state.deadQueue,
                ],
            };
        case 'ADD_TO_BACK_OF_DEAD_QUEUE':
            return {
                ...state,
                deadQueue: [
                    ...state.deadQueue,
                    ...action.incomingQueueItems,
                ],
            };
        case 'TRIM_DEAD_QUEUE':
            return {
                ...state,
                deadQueue: state.deadQueue.filter((incomingQueueItem) => {
                    return (new Date(incomingQueueItem.created_at).getTime() >=
                        new Date(state.startDate || 0).getTime() &&
                        new Date(incomingQueueItem.created_at).getTime() <
                            new Date(state.endDate || 0).getTime());
                }),
            };
        case 'SET_START_DATE':
            return { ...state, startDate: action.startDate };
        case 'SET_END_DATE':
            return { ...state, endDate: action.endDate };
        case 'SET_STATS_FOR_CURRENT_INTERVAL':
            return { ...state, statsForCurrentInterval: action.statsForCurrentInterval };
        case 'SET_PODS':
            return { ...state, pods: action.pods };
        case 'SET_SELECTED_POD_ID':
            return { ...state, selectedPodId: action.selectedPodId };
        case 'SET_INCOMING_CALL_TYPE':
            return { ...state, incomingCallType: action.incomingCallType };
        case 'SET_SHOW_LOADING_DEAD_QUEUE':
            return { ...state, showLoadingDeadQueue: action.showLoadingDeadQueue };
        case 'SET_DEAD_QUEUE_FETCH_ABORT_CONTROLLER':
            return {
                ...state,
                deadQueueFetchAbortController: action.deadQueueFetchAbortController,
            };
        default:
            return state;
    }
}
const Store = (0, redux_1.createStore)(RootReducer);
class JOIncomingQueueReport extends HTMLElement {
    constructor() {
        super();
        Store.subscribe(() => (0, lit_html_1.render)(this.render(Store.getState()), this));
    }
    connectedCallback() {
        setTimeout(async () => {
            Store.dispatch({
                type: 'RENDER',
            });
            const startOfToday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
            const startOfTomorrow = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1);
            Store.dispatch({
                type: 'SET_START_DATE',
                startDate: startOfToday.toISOString(),
            });
            Store.dispatch({
                type: 'SET_END_DATE',
                endDate: startOfTomorrow.toISOString(),
            });
            const pods = await getPods();
            Store.dispatch({
                type: 'SET_PODS',
                pods,
            });
            await fetchAndSetLiveQueue();
            Store.dispatch({
                type: 'SET_SHOW_LOADING_DEAD_QUEUE',
                showLoadingDeadQueue: true,
            });
            await fetchAndSetDeadQueue({
                refresh: true,
                deadQueueFetchType: 'POLL',
            });
            calculateAndSetStats();
            Store.dispatch({
                type: 'SET_SHOW_LOADING_DEAD_QUEUE',
                showLoadingDeadQueue: false,
            });
            this.setupIntervals();
            await fetchAndSetDeadQueue({
                refresh: false,
                deadQueueFetchType: 'POLL',
            });
            calculateAndSetStats();
        });
    }
    setupIntervals() {
        // This is to keep the time up to date
        (0, create_element_bound_interval_1.createElementBoundInterval)({
            callback: () => {
                Store.dispatch({
                    type: 'RENDER',
                });
            },
            element: this,
            intervalMs: dates_and_times_1.oneSecondInMilliseconds,
            intervalName: 'render interval',
        });
        (0, create_element_bound_interval_1.createElementBoundInterval)({
            callback: async () => {
                await fetchAndSetLiveQueue();
            },
            element: this,
            intervalMs: 5 * dates_and_times_1.oneSecondInMilliseconds,
            intervalName: 'polling for live queue',
        });
    }
    render(state) {
        const hoursOfDay = (0, helper_utils_1.getTotalHoursIntervalArray)();
        return (0, lit_html_1.html) `
            <style>
                .jo-incoming-queue-report--container {
                    padding: calc(25px + 1vmin);
                }

                .jo-incoming-queue-report--times-container {
                    display: grid;
                    grid-template-rows: repeat(17, 1fr);
                    row-gap: 0.25rem;
                }

                .jo-incoming-queue-report--stats-grid {
                    display: grid;
                    grid-template-columns: repeat(7, 1fr);
                    grid-template-rows: repeat(17, 1fr);
                    row-gap: 0.25rem;
                }
            </style>

            <div class="jo-incoming-queue-report--container">
                <jo-table
                    .tableName=${'Live Queue'}
                    .showSearchBar=${false}
                    .columns=${(0, helper_utils_1.getColumns)(state)}
                    .items=${state.liveQueue}
                    .totalItems=${state.liveQueue.length}
                ></jo-table>

                <div>
                    <h2>Queue Stats</h2>

                    <div>Start date</div>
                    <div>
                        <input
                            id="start-date-input"
                            type="date"
                            .value=${state.startDate?.split('T')[0] ??
            new Date().toISOString().split('T')[0]}
                            @change=${(e) => startDateInputChanged(e)}
                        />
                    </div>

                    <div>End date</div>
                    <div>
                        <input
                            id="end-date-input"
                            type="date"
                            .value=${state.endDate?.split('T')[0] ??
            new Date().toISOString().split('T')[0]}
                            @change=${(e) => endDateInputChanged(e)}
                        />
                    </div>

                    <br />

                    <div>Pod</div>
                    <select @change=${(e) => podsSelectChanged(e)}>
                        <option value="NOT_SET" ?selected=${state.selectedPodId === 'NOT_SET'}>
                            All Pods
                        </option>
                        ${state.pods.map(pod => {
            return (0, lit_html_1.html) `
                                <option
                                    value=${pod.id.toString()}
                                    ?selected=${state.selectedPodId === pod.id}
                                >
                                    ${pod.name}
                                </option>
                            `;
        })}
                    </select>

                    <br />
                    <br />

                    <div>Type</div>
                    <select @change=${(e) => typeSelectChanged(e)}>
                        <option value="NOT_SET" ?selected=${state.incomingCallType === 'NOT_SET'}>
                            All
                        </option>
                        <option value="USER">Incoming</option>
                        <option value="CALLBACK">Callback</option>
                        <option value="MISSED">Missed (no callback generated)</option>
                    </select>

                    <div ?hidden=${!state.showLoadingDeadQueue}>Loading...</div>

                    <div
                        style="display: ${state.showLoadingDeadQueue
            ? 'none'
            : 'grid'}; grid-template-columns: 1fr 10fr; grid-template-rows: 1fr"
                    >
                        <div class="jo-incoming-queue-report--times-container">
                            <br />
                            <div>12am - 1am</div>
                            <div>1am - 2am</div>
                            <div>2am - 3am</div>
                            <div>3am - 4am</div>
                            <div>4am - 5am</div>
                            <div>5am - 6am</div>
                            <div>6am - 7am</div>
                            <div>7am - 8am</div>
                            <div>8am - 9am</div>
                            <div>9am - 10am</div>
                            <div>10am - 11am</div>
                            <div>11am - 12pm</div>
                            <div>12pm - 1pm</div>
                            <div>1pm - 2pm</div>
                            <div>2pm - 3pm</div>
                            <div>3pm - 4pm</div>
                            <div>4pm - 5pm</div>
                            <div>5pm - 6pm</div>
                            <div>6pm - 7pm</div>
                            <div>7pm - 8pm</div>
                            <div>8pm - 9pm</div>
                            <div>9pm - 10am</div>
                            <div>10pm - 11pm</div>
                            <div>11pm - 12am</div>
                            <div><strong>Totals</strong></div>
                            <div><strong>6am - 6pm</strong></div>
                            <div><strong>6pm - 6am</strong></div>
                        </div>
                        <div class="jo-incoming-queue-report--stats-grid">
                            <div># Items</div>
                            <div>Minutes</div>
                            <div>Billable Minutes</div>
                            <div># Under 20 seconds</div>
                            <div># 20-40 seconds</div>
                            <div># Over 40 seconds</div>
                            <div>Average wait time seconds</div>

                            ${hoursOfDay.map(hour => {
            return (0, lit_html_1.html) `
                                    ${state.statsForCurrentInterval === null
                ? ''
                : (0, helper_utils_1.statsForHourTemplate)(state.statsForCurrentInterval, hour)}
                                `;
        })}
                            ${state.statsForCurrentInterval === null
            ? ''
            : (0, helper_utils_1.totalStatsForBusinessHoursIntervalTemplate)(state.statsForCurrentInterval, (0, helper_utils_1.getTotalHoursIntervalArray)())}
                            ${state.statsForCurrentInterval === null
            ? ''
            : (0, helper_utils_1.totalStatsForBusinessHoursIntervalTemplate)(state.statsForCurrentInterval, (0, helper_utils_1.getRegularBusinessHoursIntervalArray)())}
                            ${state.statsForCurrentInterval === null
            ? ''
            : (0, helper_utils_1.totalStatsForBusinessHoursIntervalTemplate)(state.statsForCurrentInterval, (0, helper_utils_1.getAfterHoursIntervalArray)())}
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-incoming-queue-report', JOIncomingQueueReport);
async function podsSelectChanged(e) {
    Store.dispatch({
        type: 'SET_SELECTED_POD_ID',
        selectedPodId: e.target.value === 'NOT_SET' ? 'NOT_SET' : parseInt(e.target.value),
    });
    Store.dispatch({
        type: 'SET_SHOW_LOADING_DEAD_QUEUE',
        showLoadingDeadQueue: true,
    });
    await fetchAndSetDeadQueue({
        refresh: true,
        deadQueueFetchType: 'MANUAL',
    });
    calculateAndSetStats();
    Store.dispatch({
        type: 'SET_SHOW_LOADING_DEAD_QUEUE',
        showLoadingDeadQueue: false,
    });
}
async function typeSelectChanged(e) {
    Store.dispatch({
        type: 'SET_INCOMING_CALL_TYPE',
        incomingCallType: e.target.value,
    });
    Store.dispatch({
        type: 'SET_SHOW_LOADING_DEAD_QUEUE',
        showLoadingDeadQueue: true,
    });
    await fetchAndSetDeadQueue({
        refresh: true,
        deadQueueFetchType: 'MANUAL',
    });
    calculateAndSetStats();
    Store.dispatch({
        type: 'SET_SHOW_LOADING_DEAD_QUEUE',
        showLoadingDeadQueue: false,
    });
}
async function startDateInputChanged(e) {
    const newStartDate = new Date(`${e.target.value}T00:00`).toISOString();
    Store.dispatch({
        type: 'SET_START_DATE',
        startDate: newStartDate,
    });
    Store.dispatch({
        type: 'SET_SHOW_LOADING_DEAD_QUEUE',
        showLoadingDeadQueue: true,
    });
    await fetchAndSetDeadQueue({
        refresh: true,
        deadQueueFetchType: 'MANUAL',
    });
    calculateAndSetStats();
    Store.dispatch({
        type: 'SET_SHOW_LOADING_DEAD_QUEUE',
        showLoadingDeadQueue: false,
    });
}
async function endDateInputChanged(e) {
    const newEndDate = new Date(`${e.target.value}T00:00`).toISOString();
    Store.dispatch({
        type: 'SET_END_DATE',
        endDate: newEndDate,
    });
    Store.dispatch({
        type: 'SET_SHOW_LOADING_DEAD_QUEUE',
        showLoadingDeadQueue: true,
    });
    await fetchAndSetDeadQueue({
        refresh: true,
        deadQueueFetchType: 'MANUAL',
    });
    calculateAndSetStats();
    Store.dispatch({
        type: 'SET_SHOW_LOADING_DEAD_QUEUE',
        showLoadingDeadQueue: false,
    });
}
async function fetchAndSetDeadQueue(params) {
    // TODO Perhaps not the best way to do this
    // TODO We should probably implement debouncing on the date inputs?
    // TODO If a request is occuring and you rest on the final date, it will not update immediately. This might be fine because 5 seconds later everything should level out
    // TODO Or perhaps we should use a fetch state instead of a boolean
    if (Store.getState().fetchingDeadQueue === true && params.deadQueueFetchType === 'POLL') {
        return;
    }
    const deadQueueFetchAbortController = Store.getState().deadQueueFetchAbortController;
    if (Store.getState().fetchingDeadQueue === true &&
        params.deadQueueFetchType === 'MANUAL' &&
        deadQueueFetchAbortController !== 'NOT_SET') {
        deadQueueFetchAbortController.abort();
    }
    Store.dispatch({
        type: 'SET_FETCHING_DEAD_QUEUE',
        fetchingDeadQueue: true,
    });
    Store.dispatch({
        type: 'TRIM_DEAD_QUEUE',
    });
    const state = Store.getState();
    if (state.startDate === null || state.endDate === null) {
        const message = `The end date is not set`;
        alert(message);
        throw new Error(message);
    }
    if (params.refresh) {
        const { abortController, execute } = (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint);
        Store.dispatch({
            type: 'SET_DEAD_QUEUE_FETCH_ABORT_CONTROLLER',
            deadQueueFetchAbortController: abortController,
        });
        const deadQueueItems = await (0, fetch_incoming_queue_for_interval_1.fetchIncomingQueueForInterval)({
            execute,
            startDate: state.startDate,
            endDate: state.endDate,
            incomingCallType: state.incomingCallType,
            podId: (0, replace_not_set_with_undefined_1.replaceNotSetWithUndefined)(state.selectedPodId),
        });
        Store.dispatch({
            type: 'SET_DEAD_QUEUE',
            deadQueue: deadQueueItems,
        });
    }
    else {
        const firstDeadQueueItem = state.deadQueue[0];
        const firstDateInDeadQueue = firstDeadQueueItem
            ? firstDeadQueueItem.created_at
            : state.startDate;
        const { abortController: newDeadQueueItemsInFrontAbortController, execute: newDeadQueueItemsInFrontExecute, } = (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint);
        Store.dispatch({
            type: 'SET_DEAD_QUEUE_FETCH_ABORT_CONTROLLER',
            deadQueueFetchAbortController: newDeadQueueItemsInFrontAbortController,
        });
        const newDeadQueueItemsInFront = await (0, fetch_incoming_queue_for_interval_1.fetchIncomingQueueForInterval)({
            execute: newDeadQueueItemsInFrontExecute,
            startDate: state.startDate,
            endDate: firstDateInDeadQueue,
            podId: (0, replace_not_set_with_undefined_1.replaceNotSetWithUndefined)(state.selectedPodId),
            incomingCallType: state.incomingCallType,
        });
        Store.dispatch({
            type: 'ADD_TO_FRONT_OF_DEAD_QUEUE',
            incomingQueueItems: newDeadQueueItemsInFront,
        });
        const lastDeadQueueItem = state.deadQueue[state.deadQueue.length - 1];
        // TODO This is a bit messy, but I think it will work. To make the created_at_gte not inclusive in this case, we just add 1 millisecond to it. Since milliseconds are the smallest time units, we should never miss any records
        const lastDateInDeadQueue = lastDeadQueueItem
            ? new Date(new Date(lastDeadQueueItem.created_at).getTime() + 1).toISOString()
            : new Date(new Date(state.endDate).getTime() + 1).toISOString();
        const { abortController: newDeadQueueItemsInBackAbortController, execute: newDeadQueueItemsInBackExecute, } = (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint);
        Store.dispatch({
            type: 'SET_DEAD_QUEUE_FETCH_ABORT_CONTROLLER',
            deadQueueFetchAbortController: newDeadQueueItemsInBackAbortController,
        });
        const newDeadQueueItemsInBack = await (0, fetch_incoming_queue_for_interval_1.fetchIncomingQueueForInterval)({
            execute: newDeadQueueItemsInBackExecute,
            startDate: lastDateInDeadQueue,
            endDate: state.endDate,
            podId: (0, replace_not_set_with_undefined_1.replaceNotSetWithUndefined)(state.selectedPodId),
            incomingCallType: state.incomingCallType,
        });
        Store.dispatch({
            type: 'ADD_TO_BACK_OF_DEAD_QUEUE',
            incomingQueueItems: newDeadQueueItemsInBack,
        });
    }
    Store.dispatch({
        type: 'SET_DEAD_QUEUE_FETCH_ABORT_CONTROLLER',
        deadQueueFetchAbortController: 'NOT_SET',
    });
    Store.dispatch({
        type: 'SET_FETCHING_DEAD_QUEUE',
        fetchingDeadQueue: false,
    });
}
function calculateAndSetStats() {
    const state = Store.getState();
    const statsForCurrentInterval = (0, helper_utils_1.calculateStats)(state.deadQueue);
    Store.dispatch({
        type: 'SET_STATS_FOR_CURRENT_INTERVAL',
        statsForCurrentInterval,
    });
}
// TODO perhaps all of these functions should return something, no side effects? We could create a mechanism that takes the returned functions and always executes them at the top level
// TODO each function could return a list of functions to execute, then once returned they are executed at the top level in order and the results are passed one to the next...I think this is monads
// TODO consider monadic stuff
async function fetchAndSetLiveQueue() {
    const liveQueue = await (0, fetch_live_queue_1.fetchLiveQueue)();
    Store.dispatch({
        type: 'SET_LIVE_QUEUE',
        liveQueue,
    });
}
// TODO it would probably be nice to curry this in the dateInMountainTime function
const dateTimeFormat = new Intl.DateTimeFormat('en-US', {
    timeZone: 'America/Denver',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
});
function nowInMountainTime() {
    const nowInMountainTimeDateString = dateTimeFormat.format(new Date());
    const nowInMountainTimeDate = new Date(nowInMountainTimeDateString);
    return nowInMountainTimeDate;
}
exports.nowInMountainTime = nowInMountainTime;
function convertUTCDateToMountainTime(date) {
    const dateInMountainTimeFormattedString = dateTimeFormat.format(date);
    const dateInMountainTimeFormatted = new Date(dateInMountainTimeFormattedString);
    const timeZoneDelta = date.getTime() - dateInMountainTimeFormatted.getTime();
    const dateInMountainTime = new Date(date.getTime() + timeZoneDelta);
    return dateInMountainTime;
}
exports.convertUTCDateToMountainTime = convertUTCDateToMountainTime;
async function getPods() {
    const result = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
        query {
            findPods {
                items {
                    id
                    name
                }
            }
        }
    `);
    if (result.data === undefined || result.data === null) {
        throw new Error('There was a problem');
    }
    return result.data.findPods.items;
}
