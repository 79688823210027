"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchLiveQueue = void 0;
const graphql_1 = require("../../../../services/graphql");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function fetchLiveQueue() {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query {
                fetchLiveQueue {
                    incoming_call_type
                    created_at
                    pod {
                        name
                    }
                    call {
                        origin_phone
                        destination_phone
                        company {
                            id
                            name
                            is_test_company
                        }
                    }
                }
            }
        `);
        if (gqlResult.data === undefined || gqlResult.data === null) {
            throw new Error('There was a problem');
        }
        return gqlResult.data.fetchLiveQueue;
    }
    catch (error) {
        throw new Error(`fetchLiveQueue Error:${error}`);
    }
}
exports.fetchLiveQueue = fetchLiveQueue;
