"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.verifyMFACode = exports.login = void 0;
const cryptography_1 = require("../services/cryptography");
const graphql_1 = require("../services/graphql");
const add_data_to_error_1 = require("../services/logging/error-handling/add-data-to-error");
const mapped_env_variables_1 = require("./environment/mapped-env-variables");
const logout_1 = require("./logout");
async function login(loginInfo) {
    try {
        const keyPair = await (0, cryptography_1.generateKeyPair)();
        const message = new Date().getTime().toString();
        const signature = await (0, cryptography_1.signMessage)(message, keyPair.privateKey);
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation (
                    $email: String
                    $password: String
                    $deviceUuid: String!
                    $clientPublicKey: String!
                    $milliseconds: String!
                    $millisecondsSignature: String!
                    $userToken: String
                ) {
                    login(
                        email: $email
                        password: $password
                        deviceUuid: $deviceUuid
                        clientPublicKey: $clientPublicKey
                        milliseconds: $milliseconds
                        millisecondsSignature: $millisecondsSignature
                        userToken: $userToken
                    ) {
                        user {
                            id
                            email
                            auth_role
                            number_of_invalid_mfa_codes
                            is_available
                            user_token
                            companies {
                                id
                            }
                        }
                        csrfToken
                    }
                }
            `, {
            email: loginInfo.email ?? null,
            password: loginInfo.password ?? null,
            deviceUuid: loginInfo.deviceUuid,
            clientPublicKey: keyPair.publicKey,
            milliseconds: message,
            millisecondsSignature: signature,
            userToken: null,
        });
        if (gqlResult.data === null) {
            await (0, logout_1.logout)();
            return {
                succeeded: false,
                error: new Error('Login failed, data returned was null'),
            };
        }
        return {
            succeeded: true,
            user: gqlResult.data.login.user,
            userLoginIdentifiers: {
                csrfToken: gqlResult.data.login.csrfToken,
                clientPublicKey: keyPair.publicKey,
                clientPrivateKey: keyPair.privateKey,
            },
        };
    }
    catch (error) {
        return {
            succeeded: false,
            error: error,
        };
    }
}
exports.login = login;
async function verifyMFACode(code, userId, deviceUuid) {
    const keyPair = await (0, cryptography_1.generateKeyPair)();
    const message = Date.now().toString();
    const signature = await (0, cryptography_1.signMessage)(message, keyPair.privateKey);
    const appVersion = RELEASE_VERSION;
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query (
                $userId: Int!
                $code: String!
                $clientPublicKey: String!
                $millisecondsSignature: String!
                $deviceUuid: String!
                $appVersion: String!
            ) {
                isMFACodeValid(
                    clientPublicKey: $clientPublicKey
                    millisecondsSignature: $millisecondsSignature
                    userId: $userId
                    code: $code
                    deviceUuid: $deviceUuid
                    appVersion: $appVersion
                ) {
                    result
                    csrfToken
                }
            }
        `, {
        clientPublicKey: keyPair.publicKey,
        millisecondsSignature: signature,
        userId,
        code,
        deviceUuid,
        appVersion,
    });
    if (gqlResult.succeeded === false) {
        (0, add_data_to_error_1.addErrorDataAndThrow)(new Error('Something went wrong while validating MFA code'), {
            extraData: gqlResult,
        });
    }
    const mfaValidationResult = gqlResult.value.data.isMFACodeValid;
    if (!mfaValidationResult.csrfToken) {
        return undefined;
    }
    return {
        csrfToken: mfaValidationResult.csrfToken,
        clientPublicKey: keyPair.publicKey,
        clientPrivateKey: keyPair.privateKey,
    };
}
exports.verifyMFACode = verifyMFACode;
