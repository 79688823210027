"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchCompaniesById = void 0;
const graphql_1 = require("../../services/graphql");
const typed_gql_query_1 = require("../gql/typed-gql-query/typed-gql-query");
const mapped_env_variables_1 = require("../environment/mapped-env-variables");
async function fetchCompaniesById(companyIds, queryShape) {
    const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, typed_gql_query_1.gql) `
            query ($companyIds: [Int!]) {
                findCompanies(
                    filter: {and: [
                        {id: {in: $companyIds}},
                    ]}
                    orderBy: {field: "id", order: DESC, in: $companyIds}
                ) {
                    items ${queryShape}
                }
            }
        `, {
        companyIds,
    });
    return gqlResult.data.findCompanies.items;
}
exports.fetchCompaniesById = fetchCompaniesById;
