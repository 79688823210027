"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertActionItemCategoryToStatusTypeEnum = exports.getActionItems = void 0;
const graphql_1 = require("../../../services/graphql");
const utilities_1 = require("../../../services/utilities");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function getActionItems(offset, limit, searchText, companyId, actionItemsCategory, column) {
    try {
        if (companyId === 'NOT_SET') {
            return {
                items: [],
                totalItems: 0,
            };
        }
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                query (
                    $offset: Int!
                    $limit: Int!
                    $ignoreSearch: Boolean!
                    $companyId: Int!
                    $actionItemStatusTypeNames: [ACTION_ITEM_STATUS_TYPE_NAME!]
                    $sortFieldName: ACTION_ITEM_SORT_FIELD_NAME
                    $sortDirection: SortDirectionEnum
                    $searchText: String
                ) {
                    searchActionItems(
                        page: {offset: $offset, limit: $limit}
                        ignoreSearch: $ignoreSearch
                        ignoreFollowupRequestDate: true
                        companyId: $companyId
                        actionItemStatusTypeNames: $actionItemStatusTypeNames
                        sortFieldName: $sortFieldName
                        sortDirection: $sortDirection
                        searchText: $searchText
                    ) {
                        action_items {
                            id
                            action_item_notes(orderBy: {field: "created_at", order: DESC}) {
                                id
                                author {
                                    id
                                    auth_role
                                    email
                                    first_name
                                }
                                body
                                created_at
                                call {
                                    id
                                    recording_url
                                }
                                action_item {
                                    id
                                }
                            }
                            action_item_status_type {
                                id
                                name
                                description
                                updated_at
                            }
                            call {
                                id
                                new_direction
                                origin_phone
                                destination_phone
                                recording_url
                            }
                            chat {
                                id
                            }
                            company {
                                id
                                timezone
                            }
                            contact {
                                id
                                company_name
                                first_name
                                last_name
                                phone_number
                                phone_numbers(orderBy: {field: "created_at", order: ASC}) {
                                    id
                                    number
                                    created_at
                                }
                            }
                            created_at
                            updated_at
                            urgent
                            user {
                                id
                                first_name
                                last_name
                            }
                            user_status_events {
                                id
                                type
                                timestamp
                                user {
                                    id
                                }
                            }
                            slug
                            title
                        }
                        total
                    }
                }
            `, {
            offset,
            limit,
            ignoreSearch: searchText === '',
            companyId,
            actionItemStatusTypeNames: getActionItemStatusTypeNamesForSearch(actionItemsCategory),
            sortFieldName: column === 'NOT_SET' ? null : column.sortFieldName,
            sortDirection: column === 'NOT_SET' ? null : column.sortDirection,
            searchText,
        });
        // TODO the jo-table getItems function should return a GQLResult
        if (gqlResult.succeeded === false) {
            alert(gqlResult.userMessage);
            throw new Error(gqlResult.developerMessage);
        }
        const actionItems = gqlResult.value.data.searchActionItems.action_items;
        return {
            items: (0, utilities_1.sortActionItemsForTable)(actionItems, 'DESC'),
            totalItems: gqlResult.value.data.searchActionItems.total,
        };
    }
    catch (error) {
        throw new Error(`getActionItems error: ${error}`);
    }
}
exports.getActionItems = getActionItems;
function getActionItemStatusTypeNamesForSearch(actionItemsCategory) {
    return actionItemsCategory === 'All Open Action Items'
        ? [
            'ASSIGNED_TO_COMPANY',
            'ASSIGNED_TO_JILLS_OFFICE',
            'ASSIGNED_TO_PERSONAL_ADMIN',
            'CALLBACK',
        ]
        : [convertActionItemCategoryToStatusTypeEnum(actionItemsCategory)];
}
function convertActionItemCategoryToStatusTypeEnum(actionItemStatusName) {
    if (actionItemStatusName === 'Assigned to Company') {
        return 'ASSIGNED_TO_COMPANY';
    }
    if (actionItemStatusName === 'Assigned to Jills Office') {
        return 'ASSIGNED_TO_JILLS_OFFICE';
    }
    if (actionItemStatusName === 'Callback') {
        return 'CALLBACK';
    }
    if (actionItemStatusName === 'Assigned to Personal Admin') {
        return 'ASSIGNED_TO_PERSONAL_ADMIN';
    }
    if (actionItemStatusName === 'Closed') {
        return 'CLOSED';
    }
    // TODO turn this into a funciton that returns type JOResult when jo-table is refactored to use JOResult
    throw new Error(`An action item status name was not accounted for: ${actionItemStatusName}`);
}
exports.convertActionItemCategoryToStatusTypeEnum = convertActionItemCategoryToStatusTypeEnum;
