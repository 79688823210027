"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assignBulkRecordingUrlsToCalls = exports.createCallRecordingsinfoFromCalls = void 0;
const graphql_1 = require("../../../services/graphql");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
function createCallRecordingsinfoFromCalls(calls) {
    return calls.map(call => {
        return {
            callId: call.id,
            callSid: call.twilio_parent_call_sid ?? '',
        };
    });
}
exports.createCallRecordingsinfoFromCalls = createCallRecordingsinfoFromCalls;
async function assignBulkRecordingUrlsToCalls(callRecordingsInfo) {
    try {
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($callRecordingsInfo: [CallRecordingInfo!]!) {
                    assignBulkRecordingUrlsToCalls(callRecordingsInfo: $callRecordingsInfo)
                }
            `, {
            callRecordingsInfo,
        });
        return { succeeded: true };
    }
    catch (error) {
        return {
            succeeded: false,
            userMessage: 'There was an error',
            developerMessage: `assignBulkRecordingUrlsToCalls error: ${error}`,
        };
    }
}
exports.assignBulkRecordingUrlsToCalls = assignBulkRecordingUrlsToCalls;
