"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../../services/utilities");
const auth_rules_1 = require("../../../../utilities/auth-rules");
const css_utils_1 = require("../../../../utilities/css-utils");
const get_recording_url_1 = require("../../../../utilities/get-recording-url");
require("../../jo-button.element");
const helper_utils_1 = require("./helper-utils");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
const test_id_directive_1 = require("../../../directives/test-id.directive");
const InitialState = {
    companyId: 'NOT_SET',
    company: 'NOT_SET',
    accountManagers: [],
    authenticatedUser: 'NOT_SET',
    phoneServices: [],
    calendars: [],
    personalAdmins: [],
    pods: [],
    showAllFormErrors: false,
    inputValues: {
        actionItemCreationEmailInputValue: '',
        callbacksAllowedInputValue: false,
        formErrors: [],
        sameCallerIdInputValue: false,
        selectedAccountManagerName: '',
        selectedPhoneServiceName: 'NOT_SET',
        otherPhoneServiceName: '',
        selectedCalendarName: 'NOT_SET',
        otherCalendarName: '',
        otherCalendarUrl: '',
        outboundServicesEnabled: false,
        preferredCommunication: 'NOT_SET',
        selectedPodName: '',
        selectedPersonalAdminName: '',
        twilioNumberInputValue: '',
        hoursType: 'TWENTY_FOUR_SEVEN',
        isTestCompany: false,
        isVip: false,
    },
    isMobileDevice: false,
    selectedQueueAudioFile: null,
    selectedVoicemailAudioFile: null,
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-company-jilln-info');
const cssNames = (classNames) => classNames.split(' ').map(cssName).join(' ');
class JOCompanyJillnInfo extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    set company(company) {
        if (this.store.company === company) {
            return;
        }
        this.store.company = company;
        if (company === 'NOT_SET') {
            this.inputValues = InitialState.inputValues;
        }
        else {
            this.inputValues = {
                selectedAccountManagerName: (0, utilities_1.getUserFullName)(company.account_manager),
                selectedPersonalAdminName: (0, utilities_1.getUserFullName)(company.personal_admin),
                actionItemCreationEmailInputValue: company.action_item_creation_email,
                callbacksAllowedInputValue: company.callbacks_allowed,
                formErrors: this.store.inputValues.formErrors,
                selectedPhoneServiceName: (0, helper_utils_1.getSelectedPhoneServiceName)(company),
                otherPhoneServiceName: (0, helper_utils_1.getOtherPhoneServiceName)(company),
                selectedCalendarName: (0, helper_utils_1.getSelectedCalendarName)(company),
                otherCalendarName: (0, helper_utils_1.getOtherCalendarName)(company),
                otherCalendarUrl: (0, helper_utils_1.getOtherCalendarUrl)(company),
                outboundServicesEnabled: company.uses_outbound_services,
                preferredCommunication: company.preferred_communication,
                selectedPodName: company.pod?.name || '',
                sameCallerIdInputValue: company.same_caller_id,
                twilioNumberInputValue: (0, utilities_1.formatPhoneNumberToE164)(company.twilio_phone_number),
                hoursType: company.hours_type,
                isTestCompany: company.is_test_company,
                isVip: company.is_vip,
            };
        }
    }
    set inputValues(inputValues) {
        this.store.inputValues = inputValues;
        (0, utilities_1.raiseCustomEvent)(this, 'companyjillninfoinputvalueschanged', inputValues);
    }
    render(state) {
        const isAllowedToUpdate = (0, utilities_1.isInAuthRole)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser);
        const hourTypes = [
            'TWENTY_FOUR_SEVEN',
            'AFTER_HOURS_AND_WEEKENDS',
            'BUSINESS_HOURS_ONLY',
        ];
        const communicationTypes = [
            'NOT_SET',
            'EMAIL',
            'PHONE_CALL',
            'TEXT',
        ];
        return (0, lit_html_1.html) `
            <style>
                .${cssNames('main-container')} {
                    box-sizing: border-box;
                    font-family: sans-serif;
                    height: 100%;
                    width: 100%;
                }

                .${cssNames('marginBottom')} {
                    margin-bottom: ${this.store.isMobileDevice ? `1rem` : ``};
                }

                .${cssNames('setWidth')} {
                    width: ${state.isMobileDevice ? `95%` : `85%`};
                }
            </style>

            <div class="${cssNames('main-container')}">
                <div class="jo-company-details--info-card">
                    <div class="jo-company-details--info-card-header-container">
                        <h3 class="jo-company-details--info-card-header">JILL'N INFO</h3>
                    </div>

                    <div class="jo-company-details--info-card-body-content">
                        <div class="jo-company-details--info-card-row">
                            <jo-input
                                ${(0, test_id_directive_1.testId)('jo-company-pod-input')}
                                ?hidden=${(0, auth_rules_1.isCompanyUser)(state.authenticatedUser) === true}
                                class="${cssNames('marginBottom')}"
                                .readonly=${isAllowedToUpdate === false}
                                .label=${'Pod'}
                                .type=${'SELECT'}
                                .selectedValue=${state.inputValues.selectedPodName}
                                .defaultOption=${'Please select a Pod...'}
                                .disableDefaultOption=${true}
                                .selectOptions=${state.pods.map((pod) => pod.name)}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                selectedPodName: e.detail.selectedValue,
                formErrors: e.detail.formErrors,
            };
        }}
                            ></jo-input>

                            <jo-input
                                .readonly=${isAllowedToUpdate === false}
                                .label=${'Account Manager'}
                                .type=${'SELECT'}
                                .selectedValue=${state.inputValues.selectedAccountManagerName}
                                .defaultOption=${'Please select an Account Manager...'}
                                .disableDefaultOption=${true}
                                .selectOptions=${state.accountManagers.map(manager => (0, utilities_1.getUserFullName)(manager))}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                selectedAccountManagerName: e.detail.selectedValue,
                formErrors: e.detail.formErrors,
            };
        }}
                            ></jo-input>
                        </div>

                        <div class="jo-company-details--info-card-row">
                            <jo-input
                                ${(0, test_id_directive_1.testId)('info-twilio-number-input')}
                                class="${cssNames('setWidth')}"
                                .readonly=${isAllowedToUpdate === false}
                                .label=${'Twilio (Jill Forwarding) Number'}
                                .type=${'PHONE_NUMBER'}
                                .inputValue=${state.inputValues.twilioNumberInputValue}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                twilioNumberInputValue: e.detail.inputValue,
                formErrors: e.detail.formErrors,
            };
        }}
                                .formErrors=${state.inputValues.formErrors}
                                .showError=${state.showAllFormErrors}
                            ></jo-input>

                            <jo-input
                                .readonly=${isAllowedToUpdate === false}
                                .label=${'Personal Admin'}
                                .type=${'SELECT'}
                                .selectedValue=${state.inputValues.selectedPersonalAdminName}
                                .defaultOption=${'Please select a Personal Admin...'}
                                .disableDefaultOption=${true}
                                .selectOptions=${getPersonalAdminSelectOptions(state)}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                selectedPersonalAdminName: e.detail.selectedValue,
                formErrors: e.detail.formErrors,
            };
        }}
                            ></jo-input>
                        </div>

                        <div class="jo-company-details--info-card-row" ?hidden=${true}>
                            <div class="jo-global--align-self-end">
                                Queue Audio:&nbsp;&nbsp;
                                <jo-button
                                    .disabled=${(0, utilities_1.isInAuthRole)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser) === false}
                                    .type=${'ACTION_BUTTON_2'}
                                    .text=${'Choose File'}
                                ></jo-button>
                            </div>
                        </div>

                        <div class="jo-company-details--info-card-row">
                            <jo-input
                                ${(0, test_id_directive_1.testId)('info-phone-service-input')}
                                .readonly=${isAllowedToUpdate === false}
                                .label=${'Phone Service'}
                                .type=${'SELECT'}
                                .selectedValue=${state.inputValues.selectedPhoneServiceName}
                                .defaultOption=${'Select Service...'}
                                .disableDefaultOption=${true}
                                .selectOptions=${[
            'None',
            ...state.phoneServices.map(p => p.name),
            'Other',
        ]}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                selectedPhoneServiceName: e.detail.selectedValue,
                formErrors: e.detail.formErrors,
            };
        }}
                            ></jo-input>

                            <div class="jo-global--align-self-end" ?hidden=${true}>
                                Queue Audio:&nbsp;&nbsp;
                                <jo-button
                                    .disabled=${isAllowedToUpdate === false}
                                    .type=${'ACTION_BUTTON_2'}
                                    .text=${'Choose File'}
                                ></jo-button>
                            </div>

                            <jo-input
                                ${(0, test_id_directive_1.testId)('company-info-phone-service-name-input')}
                                ?hidden=${!(this.inputValues.selectedPhoneServiceName === 'Other')}
                                .readonly=${(0, utilities_1.isInAuthRole)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser) === false}
                                .showRequiredIcon=${true}
                                .label=${'Phone Service Name'}
                                .inputValue=${state.inputValues.otherPhoneServiceName}
                                .requiredIconType=${'SUBTLE'}
                                .required=${this.inputValues.selectedPhoneServiceName === 'Other'}
                                .placeholder=${'Enter name of Phone Service'}
                                .type=${'TEXT_INPUT'}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                otherPhoneServiceName: e.detail.inputValue,
                formErrors: e.detail.formErrors,
            };
        }}
                                .showError=${state.showAllFormErrors}
                            ></jo-input>

                            <jo-input
                                ${(0, test_id_directive_1.testId)('company-info-calender-input')}
                                .readonly=${(0, utilities_1.isInAuthRole)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser) === false}
                                .label=${'Calendar'}
                                .type=${'SELECT'}
                                .selectedValue=${state.inputValues.selectedCalendarName}
                                .defaultOption=${'Select Calendar...'}
                                .disableDefaultOption=${true}
                                .selectOptions=${[
            'None',
            ...state.calendars.map(c => c.name),
            'Other',
        ]}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                selectedCalendarName: e.detail.selectedValue,
                formErrors: e.detail.formErrors,
            };
        }}
                            ></jo-input>
                        </div>

                        <div class="jo-company-details--info-card-row">
                            <jo-input
                                class="${cssNames('marginBottom')}"
                                .readonly=${isAllowedToUpdate === false}
                                .label=${'Action Item Creation Email'}
                                .type=${'EMAIL'}
                                .inputValue=${state.inputValues.actionItemCreationEmailInputValue}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                actionItemCreationEmailInputValue: e.detail.inputValue,
                formErrors: e.detail.formErrors,
            };
        }}
                                .formErrors=${state.inputValues.formErrors}
                                .showError=${state.showAllFormErrors}
                            ></jo-input>
                            <jo-input
                                .readonly=${(0, utilities_1.isInAuthRole)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser) === false}
                                .label=${'Preferred Communication'}
                                .type=${'SELECT'}
                                .selectedValue=${state.inputValues.preferredCommunication}
                                .selectOptions=${communicationTypes}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                preferredCommunication: e.detail
                    .selectedValue,
            };
        }}
                            ></jo-input>
                        </div>

                        <div class="jo-company-details--info-card-row">
                            <jo-input
                                .readonly=${(0, utilities_1.isInAuthRole)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser) === false}
                                .label=${'Hours Type'}
                                .type=${'SELECT'}
                                .selectedValue=${state.inputValues.hoursType}
                                .selectOptions=${hourTypes}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                hoursType: e.detail.selectedValue,
            };
        }}
                            ></jo-input>
                        </div>

                        <div class="jo-company-details--info-card-row">
                            <jo-input
                                ${(0, test_id_directive_1.testId)('company-info-calendar-name')}
                                ?hidden=${!(this.inputValues.selectedCalendarName === 'Other')}
                                .readonly=${(0, utilities_1.isInAuthRole)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser) === false}
                                .showRequiredIcon=${true}
                                .requiredIconType=${'SUBTLE'}
                                .required=${this.inputValues.selectedCalendarName === 'Other'}
                                .label=${'Calendar Name'}
                                .inputValue=${state.inputValues.otherCalendarName}
                                .placeholder=${'Enter name of the Calendar'}
                                .type=${'TEXT_INPUT'}
                                @valueChanged=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                otherCalendarName: e.detail.inputValue,
                formErrors: e.detail.formErrors,
            };
        }}
                                .showError=${state.showAllFormErrors}
                            ></jo-input>
                            <jo-input
                                ${(0, test_id_directive_1.testId)('company-info-calendar-url')}
                                ?hidden=${!(this.inputValues.selectedCalendarName === 'Other')}
                                .readonly=${(0, utilities_1.isInAuthRole)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser) === false}
                                .showRequiredIcon=${true}
                                .required=${this.inputValues.selectedCalendarName === 'Other'}
                                .requiredIconType=${'SUBTLE'}
                                .label=${'Calendar URL'}
                                .inputValue=${state.inputValues.otherCalendarUrl}
                                .placeholder=${'Enter Url of the Calendar'}
                                .type=${'URL'}
                                @valueChanged=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                otherCalendarUrl: e.detail.inputValue,
                formErrors: e.detail.formErrors,
            };
        }}
                                .showError=${state.showAllFormErrors}
                            ></jo-input>
                        </div>

                        <div class="jo-company-details--info-card-row">
                            <div>
                                <input
                                    ?disabled=${isAllowedToUpdate === false}
                                    type="checkbox"
                                    id="callbacks-allowd-checkbox"
                                    .checked=${state.inputValues.sameCallerIdInputValue}
                                    @change=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                sameCallerIdInputValue: e.target
                    .checked,
            };
        }}
                                />
                                Same Caller ID
                            </div>
                            <div class="jo-global--align-self-end">
                                <input
                                    ?disabled=${isAllowedToUpdate === false}
                                    type="checkbox"
                                    id="callbacks-allowd-checkbox"
                                    .checked=${state.inputValues.callbacksAllowedInputValue}
                                    @change=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                callbacksAllowedInputValue: e.target.checked,
            };
        }}
                                />
                                &nbsp;Callbacks Allowed
                            </div>
                        </div>

                        <div class="jo-company-details--info-card-row">
                            <div>
                                <input
                                    ?disabled=${(0, utilities_1.isInAuthRole)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser) === false}
                                    type="checkbox"
                                    .checked=${state.inputValues.outboundServicesEnabled}
                                    @change=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                outboundServicesEnabled: e.target
                    .checked,
            };
        }}
                                />
                                &nbsp;
                                <span>Outbound Services</span>
                            </div>
                            <div
                                ?hidden=${(0, auth_rules_1.isCompanyUser)(state.authenticatedUser) === true}
                                class="jo-global--align-self-end"
                            >
                                <input
                                    ?disabled=${isAllowedToUpdate === false}
                                    type="checkbox"
                                    .checked=${state.inputValues.isTestCompany}
                                    @change=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                isTestCompany: e.target.checked,
            };
        }}
                                />
                                &nbsp;Test Company
                            </div>
                            <div
                                ?hidden=${(0, auth_rules_1.isCompanyUser)(state.authenticatedUser) === true}
                                style="padding-top: 1rem"
                            >
                                <input
                                    ?disabled=${isAllowedToUpdate === false}
                                    type="checkbox"
                                    .checked=${state.inputValues.isVip}
                                    @change=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                isVip: e.target.checked,
            };
        }}
                                />
                                &nbsp;VIP
                            </div>
                        </div>

                        <section
                            class="jo-company-details--info-card-row"
                            ?hidden=${(0, auth_rules_1.isCompanyUser)(state.authenticatedUser) === true}
                        >
                            <div>
                                <div style="padding-bottom: 0.5rem;">
                                    Voicemail Audio:&nbsp;&nbsp;
                                </div>
                                <input
                                    id="voicemail-audio-input"
                                    type="file"
                                    accept="audio/*"
                                    @change=${(e) => {
            const file = e.target.files[0];
            (0, utilities_1.raiseCustomEvent)(this, 'voicemailAudioFileSelected', file);
        }}
                                    .disabled=${isAllowedToUpdate === false}
                                    ?hidden=${isAllowedToUpdate === false}
                                />
                            </div>

                            <div>
                                <audio
                                    id="voicemail-audio"
                                    ?hidden=${shouldHideAudioElement(state, 'voicemail_audio_file_name')}
                                    controls
                                    src="${getAudioUrl('VOICEMAIL', state.company, 'voicemail_audio_file_name')}"
                                ></audio>
                            </div>
                        </section>

                        <section
                            class="jo-company-details--info-card-row"
                            ?hidden=${(0, auth_rules_1.isCompanyUser)(state.authenticatedUser) === true}
                        >
                            <div>
                                <div style="padding-bottom: 0.5rem;">Queue Audio:&nbsp;&nbsp;</div>
                                <input
                                    id="queue-audio-input"
                                    type="file"
                                    accept="audio/*"
                                    @change=${(e) => {
            const file = e.target.files[0];
            (0, utilities_1.raiseCustomEvent)(this, 'queueAudioFileSelected', file);
        }}
                                    .disabled=${isAllowedToUpdate === false}
                                    ?hidden=${isAllowedToUpdate === false}
                                />
                            </div>

                            <div>
                                <audio
                                    id="queue-audio"
                                    ?hidden=${shouldHideAudioElement(state, 'queue_audio_file_name')}
                                    controls
                                    src="${getAudioUrl('QUEUE_AUDIO', state.company, 'queue_audio_file_name')}"
                                ></audio>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-jilln-info', JOCompanyJillnInfo);
function shouldHideAudioElement(state, fieldName) {
    const selectedAudioFileFieldName = fieldName === 'queue_audio_file_name'
        ? 'selectedQueueAudioFile'
        : 'selectedVoicemailAudioFile';
    return (state.company === 'NOT_SET' ||
        !state.company[fieldName] ||
        state[selectedAudioFileFieldName] !== null);
}
function getAudioUrl(type, company, fieldName) {
    if (company === 'NOT_SET')
        return '';
    const url = (0, get_recording_url_1.getRecordingUrl)({
        type,
        companyId: company.id,
        audioFileName: company[fieldName] ?? '',
    });
    return url;
}
function getPersonalAdminSelectOptions(state) {
    const personalAdminNames = state.personalAdmins.map(admin => (0, utilities_1.getUserFullName)(admin));
    return [
        ...personalAdminNames,
        'None',
    ];
}
