"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeChatStatus = exports.JillChatActivityStatus = void 0;
const dates_and_times_1 = require("../../../../utilities/dates-and-times/dates-and-times");
const diff_time_1 = require("../../../../utilities/dates-and-times/diff-time");
var JillChatActivityStatus;
(function (JillChatActivityStatus) {
    JillChatActivityStatus["NotInChatMode"] = "Not In Chat Mode";
    JillChatActivityStatus["InChatModeNotChatting"] = "In Chat Mode - Not Chatting";
    JillChatActivityStatus["InChatModeActivelyChatting"] = "In Chat Mode - Actively Chatting";
})(JillChatActivityStatus = exports.JillChatActivityStatus || (exports.JillChatActivityStatus = {}));
function makeChatStatus(lastSeenChatModeTimeStamp, lastSeenActivelyChattingTimeStamp) {
    const lastSeenInChatModeTimeDifference = calculateLastSeenTimeStampTimeDifference(lastSeenChatModeTimeStamp);
    const thirtySecondsInMilliSeconds = dates_and_times_1.oneSecondInMilliseconds * 30;
    if (lastSeenInChatModeTimeDifference > thirtySecondsInMilliSeconds) {
        return JillChatActivityStatus.NotInChatMode;
    }
    const lastSeenActivelyChattingTimeDifference = calculateLastSeenTimeStampTimeDifference(lastSeenActivelyChattingTimeStamp);
    if (lastSeenActivelyChattingTimeDifference > thirtySecondsInMilliSeconds) {
        return JillChatActivityStatus.InChatModeNotChatting;
    }
    return JillChatActivityStatus.InChatModeActivelyChatting;
}
exports.makeChatStatus = makeChatStatus;
function calculateLastSeenTimeStampTimeDifference(timeStamp) {
    if (!timeStamp) {
        return dates_and_times_1.oneMinuteInMilliseconds;
    }
    return (0, diff_time_1.diffTime)(new Date(), new Date(timeStamp));
}
