"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOChatStrictHoursLogicManager = void 0;
const constants_1 = require("../../../../utilities/chat-strict-hours/constants");
const remove_created_at_and_updated_at_1 = require("../../../../utilities/data-structures/objects/remove-created-at-and-updated-at");
const state_manager_1 = require("../../../../utilities/state-management/state-manager");
const defaultEditableChatStrictHours = [
    {
        id: null,
        day_of_week: 'MONDAY',
        start_time: '06:00',
        end_time: '18:00',
    },
    {
        id: null,
        day_of_week: 'TUESDAY',
        start_time: '06:00',
        end_time: '18:00',
    },
    {
        id: null,
        day_of_week: 'WEDNESDAY',
        start_time: '06:00',
        end_time: '18:00',
    },
    {
        id: null,
        day_of_week: 'THURSDAY',
        start_time: '06:00',
        end_time: '18:00',
    },
    {
        id: null,
        day_of_week: 'FRIDAY',
        start_time: '06:00',
        end_time: '18:00',
    },
    {
        id: null,
        day_of_week: 'SATURDAY',
        start_time: '08:00',
        end_time: '13:00',
    },
    {
        id: null,
        day_of_week: 'SUNDAY',
        start_time: '09:00',
        end_time: '12:00',
    },
];
const defaultStateChangeCallback = (state) => { };
const defaultErrorHandler = (error) => {
    throw new Error(error);
};
class JOChatStrictHoursLogicManager {
    constructor(params) {
        const { initialState, gateway, errorHandler, stateChangeCallback } = params;
        this.gateway = gateway;
        this.errorHandler = errorHandler ?? defaultErrorHandler;
        this.store = new state_manager_1.StateManager(initialState, stateChangeCallback ?? defaultStateChangeCallback);
    }
    getState() {
        return this.store.getState();
    }
    async fetchAndSetChatStrictHours() {
        try {
            await this.tryFetchAndSetChatStrictHours();
        }
        catch (error) {
            this.errorHandler(new Error(`fetchAndSetChatStrictHours error: ${error}`));
        }
    }
    async updateChatStrictHours() {
        try {
            await this.tryUpdateChatStrictHours();
        }
        catch (error) {
            this.errorHandler(new Error(`updateChatStrictHours error: ${error}`));
        }
    }
    async tryUpdateChatStrictHours() {
        await this.gateway.updateChatStrictHours(this.getState().editableChatStrictHours);
    }
    selectNewTime(params) {
        const { dayOfWeek, startTime, endTime } = params;
        const editableChatStrictHours = this.getState().editableChatStrictHours;
        const updatedEditableChatStrictHours = updateEditableChatStrictHours(editableChatStrictHours, dayOfWeek, startTime, endTime);
        this.store.updateState({
            editableChatStrictHours: updatedEditableChatStrictHours,
        });
    }
    async tryFetchAndSetChatStrictHours() {
        const fetchedChatStrictHours = await this.gateway.fetchChatStrictHours();
        if (areAnyChatStrictHoursIncomplete(fetchedChatStrictHours)) {
            await this.errorHandler(new Error(`invalid chat_strict_hour(s)`));
        }
        const chatStrictHours = formatChatStrictHours(fetchedChatStrictHours);
        const editableChatStrictHours = formatEditableChatStrictHours(chatStrictHours);
        this.store.updateState({
            chatStrictHours,
            editableChatStrictHours,
        });
    }
}
exports.JOChatStrictHoursLogicManager = JOChatStrictHoursLogicManager;
function formatEditableChatStrictHours(chatStrictHours) {
    return defaultEditableChatStrictHours.map((defaultEditableChatStrictHour, index) => {
        const matchingChatStrictHour = chatStrictHours.find(item => item.day_of_week === defaultEditableChatStrictHour.day_of_week);
        if (matchingChatStrictHour) {
            return (0, remove_created_at_and_updated_at_1.removeCreatedAtAndUpdatedAt)(matchingChatStrictHour);
        }
        return defaultEditableChatStrictHour;
    });
}
function updateEditableChatStrictHours(editableChatStrictHours, dayOfWeek, startTime, endTime) {
    return editableChatStrictHours.map(chatStrictHour => {
        if (chatStrictHour.day_of_week === dayOfWeek) {
            return {
                ...chatStrictHour,
                start_time: startTime ?? chatStrictHour.start_time,
                end_time: endTime ?? chatStrictHour.end_time,
            };
        }
        return chatStrictHour;
    });
}
function formatChatStrictHours(chatStrictHours) {
    return getUniqueChatStrictHoursByDayOfWeek(sortChatStrictHours(chatStrictHours));
}
function getUniqueChatStrictHoursByDayOfWeek(chatStrictHours) {
    const seenDays = {};
    const finalChatStrictHours = [];
    for (const chatStrictHour of chatStrictHours) {
        if (seenDays[chatStrictHour.day_of_week])
            continue;
        seenDays[chatStrictHour.day_of_week] = true;
        finalChatStrictHours.push(chatStrictHour);
    }
    return finalChatStrictHours;
}
function sortChatStrictHours(chatStrictHours) {
    return [...chatStrictHours].sort((a, b) => {
        if (a.day_of_week === b.day_of_week) {
            return a.id - b.id;
        }
        return constants_1.daysOfTheWeek.indexOf(a.day_of_week) - constants_1.daysOfTheWeek.indexOf(b.day_of_week);
    });
}
function areAnyChatStrictHoursIncomplete(chatStrictHours) {
    return chatStrictHours.some(strictHour => doesChatStrictHoursHaveAllProperties(strictHour) === false);
}
function doesChatStrictHoursHaveAllProperties(chatStrictHour) {
    const properties = [
        'id',
        'start_time',
        'end_time',
        'created_at',
        'updated_at',
        'day_of_week',
    ];
    return properties.every(keyName => keyName in chatStrictHour);
}
