"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchScheduledDownTimes = void 0;
const graphql_1 = require("../../../services/graphql");
const error_handling_1 = require("../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function fetchScheduledDownTimes() {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                query ($now: DateTime!) {
                    findScheduled_down_times(filter: {end_time: {ge: $now}}) {
                        items {
                            id
                            start_time
                            end_time
                        }
                    }
                }
            `, {
            now: new Date().toISOString(),
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const scheduledDownTimes = gqlResult.value.data.findScheduled_down_times.items;
        return {
            succeeded: true,
            value: scheduledDownTimes,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchScheduledDownTimes', error);
    }
}
exports.fetchScheduledDownTimes = fetchScheduledDownTimes;
