"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOActionItems = void 0;
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../../services/graphql");
const utilities_1 = require("../../../services/utilities");
require("../../../../styles/jo-styles.css");
const close_all_action_items_in_category_1 = require("../../../utilities/action-items/close-all-action-items-in-category");
const fetch_personal_admin_1 = require("../../../utilities/action-items/fetch-personal-admin");
const build_try_execute_async_1 = require("../../../utilities/error-handling/build-try-execute-async");
const fetch_global_text_list_1 = require("../../../utilities/global-configs/fetch-global-text-list");
const user_status_event_mutations_1 = require("../../../utilities/logging-and-monitoring/user-status-event-mutations");
require("../jo-action-item-preview");
require("../jo-button.element");
require("../jo-fade-in-overlay");
require("../jo-input");
require("../jo-mobile-action-items-search-row");
require("../jo-mobile-action-items-template");
require("../jo-mobile-action-items/jo-mobile-action-items");
require("../jo-mobile-table");
require("../jo-table");
const get_table_columns_1 = require("./get-table-columns");
const mutations_1 = require("./mutations");
const queries_1 = require("./queries");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const executeAsync = (0, build_try_execute_async_1.buildtryExecuteAsync)(utilities_1.handleError);
const InitialState = {
    actionItemCreatedSubscription: 'NOT_SET',
    actionItemUpdatedSubscription: 'NOT_SET',
    actionItemsMovedForCompanySubscription: 'NOT_SET',
    actionItems: [],
    areAllActionItemsSelected: false,
    authenticatedUser: 'NOT_SET',
    companyId: 'NOT_SET',
    isMobileDevice: false,
    moveActionItemsSelectedOption: '',
    personalAdmin: null,
    selectedActionItems: [],
    selectedCategory: getInitialSelectedCategory(),
    selectedPreviewActionItemId: 'NOT_SET',
    quickNotes: [],
};
// TODO when we search, we need to clear the selected action items
// TODO I believe once we implement search on action items, that this will work work automatically, just putting this hear to keep that in mind
class JOActionItems extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
        this.callGetActionItems = (async (offset, limit, searchText, column) => {
            return await (0, queries_1.getActionItems)(offset, limit, searchText, this.store.companyId, this.store.selectedCategory, column);
        }).bind(this);
    }
    triggerTableReload() {
        this.callGetActionItems = this.callGetActionItems.bind(this);
        this.store.companyId = this.store.companyId;
    }
    set companyId(companyId) {
        if (companyId === this.store.companyId) {
            return;
        }
        (async () => {
            this.callGetActionItems = this.callGetActionItems.bind(this);
            this.store.companyId = companyId;
        })();
        if (this.store.companyId !== 'NOT_SET') {
            this.fetchAndSetPersonalAdmin(companyId);
            this.closeSubscriptionsIfNecessary();
            this.createSubscriptionsIfNecessary();
            this.fetchAndSetQuickNotes();
        }
    }
    async fetchAndSetQuickNotes() {
        this.store.quickNotes = await executeAsync(fetch_global_text_list_1.fetchGlobalTextList, 'ai_quick_notes');
    }
    async fetchAndSetPersonalAdmin(companyId) {
        (0, utilities_1.assertIsSet)(companyId, utilities_1.handleError, 'companyId');
        const result = await (0, fetch_personal_admin_1.fetchPersonalAdmin)(companyId);
        (0, utilities_1.assertSucceeded)(result, utilities_1.handleError);
        this.store.personalAdmin = result.value;
    }
    set areAllActionItemsSelected(areAllActionItemsSelected) {
        if (areAllActionItemsSelected === false) {
            this.store.areAllActionItemsSelected = false;
            this.store.selectedActionItems = [];
        }
        else {
            this.store.areAllActionItemsSelected = true;
            this.store.selectedActionItems = this.store.actionItems;
        }
    }
    set selectedCategory(selectedCategory) {
        if (this.store.selectedCategory === selectedCategory) {
            return;
        }
        this.callGetActionItems = this.callGetActionItems.bind(this);
        this.store.selectedCategory = selectedCategory;
    }
    set moveActionItemsSelectedOption(moveActionItemsSelectedOption) {
        if (this.store.moveActionItemsSelectedOption === moveActionItemsSelectedOption) {
            return;
        }
        this.callGetActionItems = this.callGetActionItems.bind(this);
        this.store.moveActionItemsSelectedOption = moveActionItemsSelectedOption;
    }
    actionItemCheckboxSelected(e, selectedActionItem) {
        const isActionItemChecked = e.target.checked;
        const currentSelectedActionItems = this.store.selectedActionItems;
        if (isActionItemChecked === true) {
            this.store.selectedActionItems = [
                ...currentSelectedActionItems,
                selectedActionItem,
            ];
        }
        if (isActionItemChecked === false) {
            this.store.selectedActionItems = currentSelectedActionItems.filter((actionItem) => actionItem.id !== selectedActionItem.id);
        }
    }
    async moveSelectedActionItems(moveActionItemsSelectedOption) {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        createUserStatusEventsForMovingActionItems(this.store.selectedActionItems, this.store.companyId, moveActionItemsSelectedOption, this.store.authenticatedUser);
        await (0, mutations_1.moveActionItemsToNewStatusType)(this.store.selectedActionItems, moveActionItemsSelectedOption, this.store.companyId);
        this.areAllActionItemsSelected = false;
        this.store.moveActionItemsSelectedOption = '';
    }
    closeSubscriptionsIfNecessary() {
        this.closeActionItemCreatedSubscriptionIfNecessary();
        this.closeActionItemUpdatedSubscriptionIfNecessary();
    }
    closeActionItemCreatedSubscriptionIfNecessary() {
        if (this.store.actionItemCreatedSubscription !== 'NOT_SET' &&
            this.store.actionItemCreatedSubscription.companyId !== this.store.companyId) {
            this.store.actionItemCreatedSubscription.webSocket?.close();
            this.store.actionItemCreatedSubscription = 'NOT_SET';
        }
    }
    closeActionItemUpdatedSubscriptionIfNecessary() {
        if (this.store.actionItemUpdatedSubscription !== 'NOT_SET' &&
            this.store.actionItemUpdatedSubscription.companyId !== this.store.companyId) {
            this.store.actionItemUpdatedSubscription.webSocket?.close();
            this.store.actionItemUpdatedSubscription = 'NOT_SET';
        }
    }
    async createSubscriptionsIfNecessary() {
        await this.createActionItemCreatedSubscriptionIfNecessary();
        await this.createActionItemUpdatedSubscriptionIfNecessary();
        await this.createActionItemsMovedForCompanySubscriptionIfNecessary();
    }
    async createActionItemCreatedSubscriptionIfNecessary() {
        if (this.store.actionItemCreatedSubscription === 'NOT_SET') {
            this.store.actionItemCreatedSubscription = {
                companyId: this.store.companyId,
                webSocket: await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
                        subscription ($companyId: Int!) {
                            actionItemCreatedForCompany(companyId: $companyId)
                        }
                    `, () => {
                    this.handleStateAfterSubscriptionEvent();
                }, {
                    companyId: this.store.companyId,
                }),
            };
        }
    }
    handleStateAfterSubscriptionEvent() {
        // We do not know if this.store.companyId will be set when this callback runs
        // Therefore we store the companyId with the subscription, and we temporarily set this.store.companyId to the stored value
        // TODO if we could send in the companyId to callGetActionItems somehow, we could get around this hack
        const originalCompanyId = this.store.companyId;
        const temporaryCompanyId = this.store.actionItemCreatedSubscription === 'NOT_SET'
            ? originalCompanyId
            : this.store.actionItemCreatedSubscription.companyId;
        this.callGetActionItems = this.callGetActionItems.bind(this);
        this.store.companyId = temporaryCompanyId;
        this.store.companyId = originalCompanyId;
    }
    async createActionItemUpdatedSubscriptionIfNecessary() {
        if (this.store.actionItemUpdatedSubscription === 'NOT_SET') {
            this.store.actionItemUpdatedSubscription = {
                companyId: this.store.companyId,
                webSocket: await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
                        subscription ($companyId: Int!) {
                            actionItemUpdatedForCompany(companyId: $companyId)
                        }
                    `, () => {
                    this.handleStateAfterSubscriptionEvent();
                }, {
                    companyId: this.store.companyId,
                }),
            };
        }
    }
    async createActionItemsMovedForCompanySubscriptionIfNecessary() {
        if (this.store.actionItemsMovedForCompanySubscription === 'NOT_SET') {
            this.store.actionItemsMovedForCompanySubscription = {
                companyId: this.store.companyId,
                webSocket: await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
                        subscription ($companyId: Int!) {
                            actionItemsMovedForCompany(companyId: $companyId)
                        }
                    `, () => {
                    this.handleStateAfterSubscriptionEvent();
                }, {
                    companyId: this.store.companyId,
                }),
            };
        }
    }
    getMobileSelectedCategoryCSSClass(filter) {
        return `${filter === this.store.selectedCategory ? `selected` : ``} jo-action-items-mobile-top-filter-item`;
    }
    async handleMoveSelectedActionItems(e) {
        await this.moveSelectedActionItems(e.detail);
        this.triggerTableReload();
    }
    async handleCloseAllActionItemsInCategory() {
        const shouldContinue = await (0, utilities_1.joConfirm)('Confirm', 'Are you sure you want to close all action items in this category?');
        if (!shouldContinue)
            return;
        await this.closeAllActionItemsInCurrentCategory();
    }
    async closeAllActionItemsInCurrentCategory() {
        if (this.store.selectedCategory === 'Closed')
            return;
        const statusId = getStatusIdForCategory(this.store.selectedCategory);
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        const result = await (0, close_all_action_items_in_category_1.closeAllActionItemsInCategory)(this.store.companyId, statusId);
        (0, utilities_1.assertSucceeded)(result, utilities_1.handleError);
        this.triggerTableReload();
    }
    render(state) {
        if (state.isMobileDevice) {
            return (0, lit_html_1.html) `
                <jo-mobile-action-items
                    .props=${{
                authenticatedUser: state.authenticatedUser,
                companyId: state.companyId,
            }}
                ></jo-mobile-action-items>
            `;
        }
        const isCompanyUser = (0, utilities_1.getIsCompanyUser)(state.authenticatedUser, state.companyId);
        return (0, lit_html_1.html) `
            <style>
                .jo-action-items--main-container {
                    font-family: sans-serif;
                    width: ${state.isMobileDevice ? '100vmin' : '100%'};
                }

                .jo-action-items--actions-row {
                    padding-bottom: 2rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                @media screen and (max-width: 1200px) {
                    .jo-action-items--actions-row {
                        padding-bottom: 1rem;
                    }
                }

                .jo-action-items--actions-row-left-container {
                    display: flex;
                    gap: 1rem;
                }

                .jo-action-items--actions-row-right-container {
                    box-sizing: border-box;
                }

                .jo-action-items--actions {
                    display: flex;
                    flex-wrap: wrap;
                    width: auto;
                    flex-shrink: 1;
                }

                .jo-action-items--action {
                    box-sizing: border-box;
                    padding-right: 0.5rem;
                    padding-top: 0.2rem;
                }

                .jo-action-items--table-container {
                    font-size: 0.9rem;
                }

                .jo-action-items--urgent-icon {
                    color: red;
                }

                .jo-action-items--action-item-preview-main-container {
                    position: relative;
                    padding-top: 0.2rem;
                }

                .jo-action-items--red-eye-button {
                    padding-right: 0.5rem;
                    padding-top: 0.2rem;
                }

                .jo-action-items-mobile-top-filter-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    align-items: center;
                    width: 100%;
                }

                .jo-action-items-mobile-top-filter-item {
                    margin: 0 1rem;
                    padding-bottom: 0.25rem;
                    font-size: 0.8rem;
                    text-align: center;
                }
                .selected {
                    border-bottom: 2px solid var(--jo-secondary);
                }

                @media screen and (max-width: 1600px) {
                    .jo-action-items--action,
                    .jo-action-items--red-eye-button {
                        padding-right: 0.2rem;
                    }
                }

                .jo-action-items-mobile-button
                    .jo-button--main-container
                    .jo-global--action-button-1 {
                    border-radius: 50%;
                    font-size: 1.5rem;
                    margin-right: 1rem;
                    height: 30px;
                    width: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    font-weight: 900;
                    padding-bottom: 11px;
                }
            </style>

            <div class="jo-action-items--main-container">
                <div class="jo-action-items--actions-row">
                    ${!state.isMobileDevice
            ? (0, lit_html_1.html) `
                              <div class="jo-action-items--actions-row-left-container">
                                  <jo-button
                                      .type=${'ACTION_BUTTON_1'}
                                      .text=${'New Action Item'}
                                      .href=${isCompanyUser
                ? `/customer/action-item?actionItemId=NOT_SET&customerActionItemMode=CREATE`
                : `/action-item?actionItemId=NOT_SET&companyId=${state.companyId}&callScreenMode=ACTION_ITEM`}
                                  ></jo-button>
                                  <jo-input
                                      .type=${'SELECT'}
                                      .selectOptions=${getSelectCategories(state)}
                                      .selectedValue=${state.selectedCategory}
                                      @inputchange=${(e) => {
                this.selectedCategory = e.detail
                    .selectedValue;
                this.store.selectedActionItems = [];
            }}
                                  ></jo-input>
                              </div>
                          `
            : null}
                    ${state.isMobileDevice
            ? (0, lit_html_1.html) `
                              <div class="jo-action-items-mobile-top-filter-container">
                                  <div
                                      class=${this.getMobileSelectedCategoryCSSClass('Assigned to Company')}
                                      @click=${() => (this.selectedCategory = 'Assigned to Company')}
                                  >
                                      Assigned to Me
                                  </div>

                                  <div
                                      class=${this.getMobileSelectedCategoryCSSClass('Assigned to Jills Office')}
                                      @click=${() => {
                this.selectedCategory = 'Assigned to Jills Office';
            }}
                                  >
                                      Assigned to Jill
                                  </div>

                                  <div
                                      class=${this.getMobileSelectedCategoryCSSClass('Closed')}
                                      @click=${() => {
                this.selectedCategory = 'Closed';
            }}
                                  >
                                      Closed
                                  </div>

                                  <div class="jo-action-items-mobile-button-container">
                                      <jo-button
                                          class="jo-action-items-mobile-button"
                                          .type=${'ACTION_BUTTON_1'}
                                          .text=${'+'}
                                          .href=${isCompanyUser
                ? `/customer/action-item?actionItemId=NOT_SET&customerActionItemMode=CREATE`
                : `/action-item?actionItemId=NOT_SET&companyId=${state.companyId}&callScreenMode=ACTION_ITEM`}
                                      ></jo-button>
                                  </div>
                              </div>
                          `
            : null}

                    <div class="jo-action-items--actions-row-right-container">
                        <jo-input
                            ?hidden=${state.selectedActionItems.length === 0 ||
            state.isMobileDevice}
                            .label=${'Move Selected'}
                            .type=${'SELECT'}
                            .selectOptions=${getMoveSelectedOptions(state)}
                            .defaultOption=${'Please select action...'}
                            .selectedValue=${state.moveActionItemsSelectedOption}
                            @inputchange=${(e) => {
            this.moveSelectedActionItems(e.detail.selectedValue);
            this.moveActionItemsSelectedOption = e.detail
                .selectedValue;
        }}
                        ></jo-input>

                        <jo-button
                            ?hidden=${shouldHideCloseAllButton(state)}
                            style="padding: 0 2rem;"
                            .type=${'ACTION_BUTTON_2'}
                            .text=${'Close All'}
                            @joButtonClick=${() => {
            this.handleCloseAllActionItemsInCategory();
        }}
                        ></jo-button>
                    </div>
                </div>

                <div
                    class=${!state.isMobileDevice
            ? `jo-action-items--table-container jo-global--card`
            : ''}
                >
                    <jo-table
                        .tableName=${'Action Items'}
                        .maxItemsPerPage=${50}
                        .columns=${(0, get_table_columns_1.getColumns)(this, state)}
                        @itemsfetched=${(e) => {
            if (!(0, utilities_1.entityArraysEqual)(e.detail, this.store.actionItems)) {
                this.store.actionItems = e.detail;
                this.areAllActionItemsSelected = false;
            }
        }}
                        .getItems=${this.callGetActionItems}
                        .searchDebounce=${200}
                        .isMobileDevice=${state.isMobileDevice}
                        .isCompanyUser=${isCompanyUser}
                        .companyId=${state.companyId}
                        .selectedActionItems=${state.selectedActionItems}
                        .mobileTemplate=${(item) => getMobileTemplate(item, state)}
                        .customActionTemplate=${getCustomActionTemplate}
                        @actionItemCheckboxSelected=${(e) => {
            this.actionItemCheckboxSelected(e.detail.event, e.detail.item);
        }}
                        @selectedActionItemsMoved=${(e) => {
            this.handleMoveSelectedActionItems(e);
        }}
                    ></jo-table>
                </div>
            </div>
        `;
    }
}
exports.JOActionItems = JOActionItems;
(0, define_custom_element_1.defineCustomElement)('jo-action-items', JOActionItems);
function shouldHideCloseAllButton(state) {
    return (state.selectedActionItems.length > 0 ||
        state.selectedCategory === 'Closed' ||
        (0, utilities_1.isInAuthRole)(['JILL'], state.authenticatedUser));
}
function getSelectCategories(state) {
    const fullOptions = [
        'All Open Action Items',
        'Assigned to Company',
        'Assigned to Jills Office',
        'Callback',
        'Assigned to Personal Admin',
        'Closed',
    ];
    if (!state.personalAdmin) {
        const lessOptions = fullOptions.filter(option => option !== 'Assigned to Personal Admin');
        if ((0, utilities_1.shouldHideFromCompanyUser)(state.authenticatedUser)) {
            return lessOptions.filter(option => option !== 'Callback');
        }
    }
    if ((0, utilities_1.shouldHideFromCompanyUser)(state.authenticatedUser)) {
        return fullOptions.filter(option => option !== 'Callback');
    }
    return fullOptions;
}
function getMoveSelectedOptions(state) {
    const standardOptions = [
        'Close',
        'Assign to Company',
        'Assign to Jill',
    ];
    if (!state.personalAdmin) {
        return standardOptions;
    }
    return [
        ...standardOptions,
        'Assign to Personal Admin',
    ];
}
function getInitialSelectedCategory() {
    return (0, utilities_1.isMobileView)() ? 'Assigned to Company' : 'All Open Action Items';
}
function getMobileTemplate(item, state) {
    return (0, lit_html_1.html) `
        <jo-mobile-action-items-template
            .item=${item}
            .selectedActionItems=${state.selectedActionItems}
        ></jo-mobile-action-items-template>
    `;
}
function getCustomActionTemplate(items) {
    return (0, lit_html_1.html) `
        <jo-mobile-action-items-search-row .items=${items}></jo-mobile-action-items-search-row>
    `;
}
function getStatusIdForCategory(selectedCategory) {
    if (selectedCategory === 'Assigned to Company')
        return 2;
    if (selectedCategory === 'Assigned to Jills Office')
        return 3;
    return null;
}
function createUserStatusEventsForMovingActionItems(selectedActionItems, companyId, type, user) {
    if (user === 'NOT_SET') {
        return;
    }
    selectedActionItems.forEach(actionItem => {
        createUserStatusEventForActionItem(type, user, companyId, actionItem.id);
    });
}
async function createUserStatusEventForActionItem(type, user, companyId, actionItemId) {
    await (0, user_status_event_mutations_1.createUserStatusEvent)({
        userId: user.id,
        eventType: getEventType(type),
        companyId: companyId,
        actionItemId: actionItemId,
    });
}
function getEventType(type) {
    if (type === 'Assign to Jill') {
        return 'SENT_ACTION_ITEM_TO_JILL';
    }
    return 'SENT_ACTION_ITEM_TO_ADMIN';
}
