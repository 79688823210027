"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.aggregateTotalBillableDurationInMillisecondsForCompanies = void 0;
const calculate_sum_of_object_at_property_1 = require("../../data-structures/lists/calculate-sum-of-object-at-property");
const is_defined_1 = require("../../is-defined");
function aggregateTotalBillableDurationInMillisecondsForCompanies(companies) {
    const companyLogs = companies
        .map(c => c.company_logs)
        .flat()
        .filter(x => (0, is_defined_1.isDefined)(x));
    return (0, calculate_sum_of_object_at_property_1.calculateSumOfObjectsAtProperty)(companyLogs, 'billable_duration');
}
exports.aggregateTotalBillableDurationInMillisecondsForCompanies = aggregateTotalBillableDurationInMillisecondsForCompanies;
