"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTableColumns = void 0;
const utilities_1 = require("../../../../services/utilities");
function getTableColumns() {
    const columns = [
        {
            title: 'Time Stamp',
            getCellData: (statusEvent) => (0, utilities_1.formatDateForActivityLog)(statusEvent.timestamp),
            width: 1,
            sortFieldName: 'TIME_STAMP',
        },
        {
            title: 'User Name',
            getCellData: (statusEvent) => (0, utilities_1.getUserFullName)(statusEvent.user),
            width: 1,
            sortFieldName: 'NAME',
        },
        {
            title: 'Event',
            getCellData: (statusEvent) => statusEvent.type,
            width: 1,
            sortFieldName: 'EVENT',
        },
    ];
    return columns;
}
exports.getTableColumns = getTableColumns;
