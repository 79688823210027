"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateHelpTicket = void 0;
const graphql_1 = require("../../../services/graphql");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function updateHelpTicket({ accountHelpTicketId, comment, userId, status, }) {
    await createHelpTicketUpdate({ accountHelpTicketId, userId, comment, status });
    await updateCurrentStatusOfHelpTicket(accountHelpTicketId, status);
}
exports.updateHelpTicket = updateHelpTicket;
async function createHelpTicketUpdate({ accountHelpTicketId, comment, userId, status, }) {
    await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            mutation (
                $accountHelpTicketId: Int!
                $comment: String!
                $userId: Int!
                $status: ACCOUNT_HELP_TICKET_STATUS!
            ) {
                createAccount_help_ticket_updates(
                    input: {
                        account_help_ticket: {connect: {id: $accountHelpTicketId}}
                        user: {connect: {id: $userId}}
                        comment: $comment
                        status: $status
                    }
                ) {
                    id
                }
            }
        `, {
        accountHelpTicketId,
        userId,
        comment,
        status,
    });
}
async function updateCurrentStatusOfHelpTicket(accountHelpTicketId, status) {
    await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($accountHelpTicketId: Int!, $status: ACCOUNT_HELP_TICKET_STATUS!) {
                updateAccount_help_tickets(
                    input: {id: $accountHelpTicketId, current_status: $status}
                ) {
                    id
                }
            }
        `, {
        accountHelpTicketId,
        status,
    });
}
