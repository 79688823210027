"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
require("../jo-button.element");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const InitialState = {
    category: 'ASSIGNED_TO_COMPANY',
    companyId: 'NOT_SET',
    hasPersonalAdmin: false,
};
class JOMobileActionItemsTopRow extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    categorySelected(category) {
        (0, utilities_1.raiseCustomEvent)(this, 'categorySelected', category);
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                }

                .category {
                    box-sizing: border-box;
                    margin: 0 1rem;
                    padding-bottom: 0.25rem;
                    font-size: 0.8rem;
                    text-align: center;
                }

                .selected {
                    border-bottom: 2px solid var(--jo-secondary);
                }

                .new-action-item-button-container {
                    display: flex;
                    justify-content: flex-end;
                }
                .new-action-item-button .jo-button--main-container .jo-global--action-button-1 {
                    border-radius: 50%;
                    font-size: 1.5rem;
                    padding: 1px 9px 3px;
                    margin-right: 2rem;
                }
            </style>

            <div class="main-container">
                <div
                    class="category ${state.category === 'ASSIGNED_TO_COMPANY' ? 'selected' : ''}"
                    @click=${() => this.categorySelected('ASSIGNED_TO_COMPANY')}
                >
                    Assigned to Me
                </div>

                <div
                    class="category ${state.category === 'ASSIGNED_TO_JILLS_OFFICE'
            ? 'selected'
            : ''}"
                    @click=${() => this.categorySelected('ASSIGNED_TO_JILLS_OFFICE')}
                >
                    Assigned to Jill
                </div>

                <div
                    ?hidden=${state.hasPersonalAdmin === false}
                    class="category ${state.category === 'ASSIGNED_TO_PERSONAL_ADMIN'
            ? 'selected'
            : ''}"
                    @click=${() => this.categorySelected('ASSIGNED_TO_PERSONAL_ADMIN')}
                >
                    Assigned to Admin
                </div>

                <div
                    class="category ${state.category === 'CLOSED' ? 'selected' : ''}"
                    @click=${() => this.categorySelected('CLOSED')}
                >
                    Closed
                </div>

                <div class="new-action-item-button-container">
                    <jo-button
                        class="new-action-item-button"
                        .type=${'ACTION_BUTTON_1'}
                        .text=${'+'}
                        .href=${`/customer/action-item?actionId=NOT_SET&customerActionItemMode=CREATE`}
                    ></jo-button>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-mobile-action-items-top-row', JOMobileActionItemsTopRow);
