"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../../services/graphql");
const utilities_1 = require("../../../services/utilities");
const error_handling_1 = require("../../../utilities/error-handling");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    billingCycleEndDate: 'NOT_SET',
    billingCycleStartDate: 'NOT_SET',
    companyId: 'NOT_SET',
    currentBalanceUSDCents: 'NOT_SET',
    currentMinutesPackageForCompany: 'NOT_SET',
    isMobileDevice: false,
    millisecondsUsed: 'NOT_SET',
    minutesPackageName: 'NOT_SET',
    receptionistService: 'NOT_SET',
};
class JOBillingOverview extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
        this.fetchAndSetBillingOverviewInfo();
    }
    async fetchAndSetBillingOverviewInfo() {
        if (this.store.companyId === 'NOT_SET') {
            return;
        }
        const today = new Date();
        const results = await Promise.all([
            fetchBillingOverviewInfo(this.store.companyId, today),
            fetchMillisecondsUsed(this.store.companyId, today),
        ]);
        const billingOverviewInfoResult = results[0];
        (0, utilities_1.assertSucceeded)(billingOverviewInfoResult, utilities_1.handleError);
        const billingOverviewInfo = billingOverviewInfoResult.value;
        this.store.billingCycleStartDate = billingOverviewInfo.billingCycleStartDate;
        this.store.billingCycleEndDate = billingOverviewInfo.billingCycleEndDate;
        this.store.currentBalanceUSDCents = billingOverviewInfo.currentBalanceUSDCents;
        this.store.minutesPackageName = billingOverviewInfo.minutesPackageName;
        const millisecondsUsedResult = results[1];
        (0, utilities_1.assertSucceeded)(millisecondsUsedResult, utilities_1.handleError);
        this.store.millisecondsUsed = millisecondsUsedResult.value;
        this.fetchAndSetReceptionistService();
    }
    async fetchAndSetReceptionistService() {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        const receptionistServiceResult = await fetchReceptionistService(this.store.companyId);
        (0, utilities_1.assertSucceeded)(receptionistServiceResult, utilities_1.handleError);
        this.store.receptionistService = receptionistServiceResult.value;
    }
    render(state) {
        const currentBalance = state.currentBalanceUSDCents === 'NOT_SET'
            ? 'Loading...'
            : (0, utilities_1.formatUSDCentsForDisplay)(state.currentBalanceUSDCents);
        const minutesPackageName = formatMinutesPackageName(state);
        const minutesUsed = formatMinutesUsed(state);
        const billingCycleDates = formatBillingCycleDates(state);
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .jo-billing-overview--main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .jo-billing-overview--row {
                    display: flex;
                    padding-top: var(--padding-large);
                    padding-left: ${state.isMobileDevice ? '0rem' : 'var(--padding-large)'};
                }

                .jo-billing-overview--column {
                    flex: 1;
                    padding: ${state.isMobileDevice ? '0rem' : '0rem 0.5rem'};
                }

                .jo-billing-overview--column-title {
                    color: var(--jo-text-default);
                    font-weight: bold;
                    padding-bottom: var(--padding-large);
                    font-size: ${state.isMobileDevice ? `0.75rem` : '1rem'};
                }

                .jo-billing-overview--column-value {
                    color: var(--jo-primary);
                    font-weight: bold;
                    font-size: ${state.isMobileDevice ? `0.75rem` : '1rem'};
                    padding: 0.25rem;
                }
            </style>

            <div class="jo-billing-overview--main-container">
                <div class="jo-global--card-title">
                    ${state.isMobileDevice ? 'BILLING' : 'BILLING OVERVIEW'}
                </div>

                <div class="jo-billing-overview--row">
                    <div class="jo-billing-overview--column">
                        <div class="jo-billing-overview--column-title">Current Balance</div>
                        <div class="jo-billing-overview--column-value">${currentBalance}</div>
                    </div>

                    <div class="jo-billing-overview--column">
                        <div class="jo-billing-overview--column-title">
                            ${state.isMobileDevice ? 'Used' : 'Minutes Used'}
                        </div>
                        <div class="jo-billing-overview--column-value">${minutesUsed}</div>
                    </div>

                    <div class="jo-billing-overview--column">
                        <div class="jo-billing-overview--column-title">
                            ${state.isMobileDevice ? 'Package' : 'Minutes Package'}
                        </div>
                        <div class="jo-billing-overview--column-value">${minutesPackageName}</div>
                    </div>

                    <div class="jo-billing-overview--column">
                        <div class="jo-billing-overview--column-title">Billing Cycle</div>
                        <div class="jo-billing-overview--column-value">
                            ${state.isMobileDevice
            ? formatBillingCycleDatesForMobile(billingCycleDates)
            : billingCycleDates}
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-billing-overview', JOBillingOverview);
async function fetchBillingOverviewInfo(companyId, date) {
    try {
        const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!, $date: DateTime!) {
                    Companies__JOBillingOverviewInfo: getCompanies(id: $companyId) {
                        id
                        current_minutes_package {
                            id
                            name
                            user_display_name
                        }
                        invoices(filter: {start_date: {le: $date}, end_date: {ge: $date}}) {
                            id
                            end_date
                            invoice_line_items {
                                id
                                amount
                            }
                            minutes_package {
                                id
                                name
                                user_display_name
                            }
                            start_date
                        }
                    }
                }
            `, {
            companyId,
            date,
        });
        if (result.succeeded === false) {
            return result;
        }
        const company = result.value.data.Companies__JOBillingOverviewInfo;
        if (company === null) {
            return {
                succeeded: true,
                value: {
                    billingCycleEndDate: 'NOT_FOUND',
                    billingCycleStartDate: 'NOT_FOUND',
                    currentBalanceUSDCents: 'NOT_FOUND',
                    minutesPackageName: 'NOT_FOUND',
                },
            };
        }
        const billingCycleStartDate = company.invoices[0]?.start_date ?? 'NOT_FOUND';
        const billingCycleEndDate = company.invoices[0]?.end_date ?? 'NOT_FOUND';
        const currentBalanceUSDCents = company.invoices.reduce((invoiceBalance, invoice) => {
            return (invoiceBalance +
                invoice.invoice_line_items.reduce((lineItemBalance, lineItem) => {
                    return lineItemBalance + (lineItem.amount ?? 0);
                }, 0));
        }, 0);
        const minutesPackageName = company.current_minutes_package?.user_display_name ?? 'NOT_FOUND';
        return {
            succeeded: true,
            value: {
                billingCycleEndDate,
                billingCycleStartDate,
                currentBalanceUSDCents,
                minutesPackageName,
            },
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchBillingOverviewInfo', error);
    }
}
async function fetchMillisecondsUsed(companyId, date) {
    try {
        const result = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!, $date: DateTime!) {
                    Company_logs__JOBillingOverviewMinutesUsed: findCompany_logs(
                        filter: {
                            and: [
                                {company_id: {eq: $companyId}}
                                {start_date: {le: $date}}
                                {end_date: {ge: $date}}
                            ]
                        }
                    ) {
                        items {
                            id
                            billable_duration
                        }
                    }
                }
            `, {
            companyId,
            date,
        });
        if (result.succeeded === false) {
            return result;
        }
        const companyLog = result.value.data.Company_logs__JOBillingOverviewMinutesUsed.items[0];
        if (companyLog === undefined) {
            return {
                succeeded: true,
                value: 'NOT_FOUND',
            };
        }
        return {
            succeeded: true,
            value: companyLog.billable_duration,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchBillingOverviewInfo', error);
    }
}
async function fetchReceptionistService(companyId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                query ($companyId: Int!) {
                    findReceptionist_services(filter: {company_id: {eq: $companyId}}) {
                        items {
                            id
                            minutes_per_month
                            recurring_monthly_fee
                            price_per_minute
                        }
                    }
                }
            `, {
            companyId,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        const receptionistService = gqlResult.value.data.findReceptionist_services.items[0];
        return {
            succeeded: true,
            value: receptionistService == null ? 'NOT_FOUND' : receptionistService,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('fetchBillingOverviewInfo', error);
    }
}
function formatBillingCycleDates(state) {
    if (state.billingCycleStartDate === 'NOT_SET' || state.billingCycleEndDate === 'NOT_SET') {
        return 'Loading...';
    }
    if (state.billingCycleStartDate === 'NOT_FOUND' || state.billingCycleEndDate === 'NOT_FOUND') {
        return 'Unknown';
    }
    return `${new Date(state.billingCycleStartDate).toLocaleDateString()} - ${new Date(state.billingCycleEndDate).toLocaleDateString()}`;
}
function formatMinutesUsed(state) {
    if (state.millisecondsUsed === 'NOT_SET') {
        return 'Loading...';
    }
    if (state.millisecondsUsed === 'NOT_FOUND') {
        return 'Unknown';
    }
    return (0, utilities_1.millisecondsToHoursMinutesSeconds)(state.millisecondsUsed);
}
function formatMinutesPackageName(state) {
    if (state.minutesPackageName === 'NOT_SET') {
        return 'Loading...';
    }
    if (state.minutesPackageName === 'NOT_FOUND') {
        return 'Unknown';
    }
    return state.minutesPackageName;
}
function formatBillingCycleDatesForMobile(datesString) {
    const months = {
        '1': 'Jan',
        '2': 'Feb',
        '3': 'Mar',
        '4': 'Apr',
        '5': 'May',
        '6': 'Jun',
        '7': 'Jul',
        '8': 'Aug',
        '9': 'Sep',
        '10': 'Oct',
        '11': 'Nov',
        '12': 'Dec',
    };
    const dates = datesString.split('-');
    const friendlyDisplay = getMobileFriendlyDisplayForDates(dates, months);
    return friendlyDisplay;
}
function getMobileFriendlyDisplayForDates(dates, months) {
    return dates
        .map(date => {
        const [month, day,] = date.split('/');
        if (month == null)
            throw new Error('month is null');
        const monthName = months[month.trim()] || '';
        return `${monthName} ${day}`;
    })
        .join(' - ');
}
