"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTableColumns = void 0;
const utilities_1 = require("../../../../../services/utilities");
const auth_rules_1 = require("../../../../../utilities/auth-rules");
const helper_utils_1 = require("./helper-utils");
const html_generating_functions_for_columns_1 = require("./html-generating-functions-for-columns");
function getTableColumns(state) {
    const columns = [
        {
            title: 'Actions',
            getCellData: (queueItem) => (0, html_generating_functions_for_columns_1.getActionButtonsHtml)(queueItem, state.authenticatedUser),
            width: 0.5,
            sortFieldName: 'ACTIONS',
        },
        {
            title: 'Name',
            getCellData: (queueItem) => (0, utilities_1.getContactNameForDisplay)(queueItem.contact),
            width: 0.5,
            sortFieldName: 'NAME',
        },
        {
            title: 'Pod',
            getCellData: (queueItem) => (0, helper_utils_1.getPod)(queueItem),
            width: 0.5,
            sortFieldName: 'POD',
        },
        {
            title: 'Time Zone',
            getCellData: (queueItem) => (0, helper_utils_1.getTimeZone)(queueItem),
            width: 1,
            sortFieldName: 'TIME_ZONE',
        },
        {
            title: 'Company Name',
            getCellData: (queueItem) => (0, html_generating_functions_for_columns_1.getCompanyName)(queueItem),
            width: 1.5,
            sortFieldName: 'COMPANY_NAME',
        },
        {
            title: 'Campaign Name',
            getCellData: (queueItem) => queueItem.campaign_name,
            width: 1,
            sortFieldName: 'CAMPAIGN_NAME',
        },
        {
            title: 'Call Attempts',
            getCellData: (queueItem) => (0, html_generating_functions_for_columns_1.getCallAttemptsHtml)(queueItem, state),
            width: 1,
            sortFieldName: 'EVENT',
        },
        {
            title: 'Date of Next Attempt',
            getCellData: (queueItem) => (0, helper_utils_1.getNextAttemptDateString)(queueItem),
            width: 1,
            sortFieldName: 'EVENT',
        },
        {
            title: 'Status',
            getCellData: (queueItem) => (0, html_generating_functions_for_columns_1.getQueueItemStatus)(queueItem.status),
            width: 1,
            sortFieldName: 'STATUS',
        },
        {
            title: 'Close',
            getCellData: (queueItem) => (0, html_generating_functions_for_columns_1.makeCloseButtonHTML)(queueItem),
            width: 1,
            sortFieldName: 'Close',
        },
    ];
    if ((0, auth_rules_1.isCompanyUser)(state.authenticatedUser)) {
        return columns.filter(helper_utils_1.isColumnAllowedInReadonlyMode);
    }
    if (state.selectedType === 'ALL' || state.selectedType === 'COMPLETED') {
        return (0, helper_utils_1.removeCloseButtonColumn)(columns);
    }
    return columns;
}
exports.getTableColumns = getTableColumns;
