"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const repeat_js_1 = require("lit-html/directives/repeat.js");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../services/graphql");
const utilities_1 = require("../../services/utilities");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const mapped_env_variables_1 = require("../../utilities/environment/mapped-env-variables");
require("./jo-button.element");
require("./jo-loading");
require("./jo-pod");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    pods: [],
    loading: true,
    addingNewPod: false,
};
class JOPods extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    async connectedCallback() {
        this.store.loading = true;
        await this.fetchAndSetPods();
        this.store.loading = false;
    }
    addNewPodClicked() {
        this.store.addingNewPod = true;
    }
    podCreateCanceled() {
        this.store.addingNewPod = false;
    }
    async podChanged(e) {
        const podId = e.detail.podId;
        const newName = e.detail.newName;
        const type = e.detail.podType;
        await this.createOrUpdatePod(podId, newName, type);
    }
    async createOrUpdatePod(podId, newName, type) {
        if (podId === 'NEW_POD') {
            await this.createNewPod(newName, type);
        }
        else {
            await this.updatePod(podId, newName, type);
        }
    }
    async updatePod(podId, newName, type) {
        const updateResult = await updatePod(podId, newName, type);
        (0, utilities_1.assertSucceeded)(updateResult, utilities_1.handleError);
        (0, utilities_1.joAlert)('Pod Updated', `Pod: ${newName} was succesfully updated`);
        await this.fetchAndSetPods();
    }
    async createNewPod(newPodName, type) {
        const createPodResult = await createPod(newPodName, type);
        (0, utilities_1.assertSucceeded)(createPodResult, utilities_1.handleError);
        (0, utilities_1.joAlert)('Pod Created', `Pod: '${newPodName}' was successfully created`);
        await this.fetchAndSetPods();
        this.store.addingNewPod = false;
    }
    async fetchAndSetPods() {
        const podsResult = await fetchPods();
        (0, utilities_1.assertSucceeded)(podsResult, utilities_1.handleError);
        this.store.pods = podsResult.value;
    }
    async deletePodClicked(e) {
        const deleteConfirmed = await (0, utilities_1.joConfirm)('Please Confirm', 'Are you sure you want to delete this pod?');
        if (deleteConfirmed === true) {
            const deletePodResult = await deletePod(e.detail.podId);
            (0, utilities_1.assertSucceeded)(deletePodResult, utilities_1.handleError);
            if (deletePodResult.value === 'FAILED_COMPANIES_ASSIGNED_TO_POD') {
                await this.handleNotifyUserAboutCompaniesStillAttachedToPod(e.detail.podId, e.detail.podName);
                return;
            }
            (0, utilities_1.joAlert)('Success', 'The pod was deleted');
        }
        await this.fetchAndSetPods();
    }
    async handleNotifyUserAboutCompaniesStillAttachedToPod(podId, podName) {
        const companiesResult = await getCompaniesForPod(podId);
        (0, utilities_1.assertSucceeded)(companiesResult, utilities_1.handleError);
        const companies = companiesResult.value;
        (0, utilities_1.joAlert)('Error -- Companies still attached', `The following companies are still assigned to this pod (${podName}): 
                \n\n${companies.map(company => company.name).join(', ')}
                \n\nPlease assign them to a different pod before attempting to delete this pod`);
    }
    render(state) {
        return (0, lit_html_1.html) `
            <style>
                .jo-pods--main-container {
                    box-sizing: border-box;
                    width: 100%;
                    height: auto;

                    font-family: sans-serif;

                    background-color: white;
                    border-radius: 6px;
                    box-shadow: 0 0 4px rgb(0, 0, 0, 0.2);

                    padding: 1rem;
                }

                .jo-pods--upper-container {
                    margin-bottom: 4rem;
                }

                .jo-pods--header {
                    margin-top: 0;
                }
            </style>

            <jo-loading
                .props=${{
            loading: state.loading,
        }}
            ></jo-loading>

            <div class="jo-pods--main-container">
                <div class="jo-pods--upper-container">
                    <h1 class="jo-pods--header">Pods</h1>

                    <jo-button
                        data-test-id=${'jo-pods--newPodButton'}
                        .type=${'ACTION_BUTTON_1'}
                        .text=${'New Pod'}
                        @joButtonClick=${() => this.addNewPodClicked()}
                    ></jo-button>
                </div>

                ${state.addingNewPod === true
            ? (0, lit_html_1.html) `
                          <jo-pod
                              .authenticatedUser=${state.authenticatedUser}
                              .mode=${'CREATE'}
                              @podchanged=${(e) => this.podChanged(e)}
                              @podcanceled=${() => this.podCreateCanceled()}
                          ></jo-pod>
                      `
            : ''}
                ${(0, repeat_js_1.repeat)(state.pods, (pod) => pod.id, (pod) => {
            return (0, lit_html_1.html) `
                            <jo-pod
                                .authenticatedUser=${state.authenticatedUser}
                                .pod=${pod}
                                .mode=${'READ'}
                                @podchanged=${(e) => this.podChanged(e)}
                                @poddeleted=${(e) => this.deletePodClicked(e)}
                                @bulkusersremovedfrompod=${() => this.fetchAndSetPods()}
                            ></jo-pod>
                        `;
        })}
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-pods', JOPods);
async function createPod(newPodName, type) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation (
                    $name: String!
                    $createdAt: DateTime!
                    $updatedAt: DateTime!
                    $type: POD_TYPE
                ) {
                    createPods(
                        input: {
                            name: $name
                            created_at: $createdAt
                            updated_at: $updatedAt
                            type: $type
                        }
                    ) {
                        id
                    }
                }
            `, {
            name: newPodName,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            type,
        }, {
            retryOnFailedMutation: false,
        });
        if (gqlResult.data.createPods !== null && gqlResult.data.createPods.id !== null) {
            return { succeeded: true };
        }
        else {
            return {
                succeeded: false,
                userMessage: 'The pod was unabled to be created',
                developerMessage: 'The GraphQL execution succedded, but the createPods mutation failed',
            };
        }
    }
    catch (error) {
        return {
            succeeded: false,
            userMessage: 'Something went wrong',
            developerMessage: error,
        };
    }
}
async function updatePod(podId, newName, type) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($podId: Int!, $newName: String!, $type: POD_TYPE) {
                    updatePods(input: {id: $podId, name: $newName, type: $type}) {
                        id
                    }
                }
            `, {
            podId,
            newName,
            type,
        });
        if (gqlResult.data.updatePods.id === podId) {
            return { succeeded: true };
        }
        else {
            return {
                succeeded: false,
                userMessage: 'The pod name could not be changed',
                developerMessage: 'The GraphQL request succeeded, but the updatePods mutation failed',
            };
        }
    }
    catch (error) {
        return {
            succeeded: false,
            userMessage: 'Something went wrong',
            developerMessage: error,
        };
    }
}
async function fetchPods() {
    try {
        const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query {
                findPods(orderBy: {field: "name", order: ASC}) {
                    items {
                        id
                        name
                        type
                        users(
                            filter: {
                                auth_role: {
                                    in: [
                                        "JILL"
                                        "JILL_OUTBOUND"
                                        "JILL_MANAGER"
                                        "JILL_BUSINESS_DEVELOPMENT"
                                        "JILL_EXECUTIVE"
                                    ]
                                }
                            }
                        ) {
                            id
                            first_name
                            last_name
                        }
                        float_users(
                            filter: {
                                auth_role: {
                                    in: [
                                        "JILL"
                                        "JILL_OUTBOUND"
                                        "JILL_MANAGER"
                                        "JILL_BUSINESS_DEVELOPMENT"
                                        "JILL_EXECUTIVE"
                                    ]
                                }
                            }
                        ) {
                            id
                            first_name
                            last_name
                        }
                    }
                }
            }
        `);
        if (gqlResult.data.findPods === null || gqlResult.data.findPods.items === null) {
            return {
                succeeded: false,
                userMessage: 'Error getting pods',
                developerMessage: 'The gql request succeeded, but there was an error in the findPods query',
            };
        }
        return {
            succeeded: true,
            value: gqlResult.data.findPods.items,
        };
    }
    catch (error) {
        return {
            succeeded: false,
            userMessage: 'Something went wrong',
            developerMessage: error,
        };
    }
}
async function deletePod(podId) {
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($podId: Int!) {
                deletePod(podId: $podId)
            }
        `, {
        podId,
    });
    if (gqlResult.succeeded === false) {
        return gqlResult;
    }
    const deletePodResult = gqlResult.value.data.deletePod;
    return {
        succeeded: true,
        value: deletePodResult,
    };
}
async function getCompaniesForPod(podId) {
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query ($podId: Int!) {
                getCompaniesForPod(podId: $podId) {
                    id
                    name
                }
            }
        `, {
        podId,
    });
    if (gqlResult.succeeded === false) {
        return gqlResult;
    }
    const companies = gqlResult.value.data.getCompaniesForPod;
    return {
        succeeded: true,
        value: companies,
    };
}
