"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
require("../jo-button.element");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    companyId: 'NOT_SET',
    selectedOption: 'DASHBOARD',
};
class JOCustomerBottomBar extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: calc(45px + 1vh);
                    width: 100%;
                    box-sizing: border-box;
                    background-color: white;
                    bottom: 0;
                    position: fixed;
                }

                /* iphone X , XS, 11 Pro, 12 Mini */
                @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
                    .main-container {
                        height: calc(55px + 1vh);
                    }
                }

                /* iphone 12, 12 Pro */
                @media only screen and (min-device-width: 390px) and (max-device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
                    .main-container {
                        height: calc(55px + 1vh);
                    }
                }

                /* iphone XR, 11 */
                @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
                    .main-container {
                        height: calc(55px + 1vh);
                    }
                }

                /* iphone XS Max, 11 Pro Max */
                @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
                    .main-container {
                        height: calc(55px + 1vh);
                    }
                }

                /* iphone 12 Pro Max */
                @media only screen and (min-device-width: 428px) and (max-device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
                    .main-container {
                        height: calc(55px + 1vh);
                    }
                }

                .border-top {
                    border-top: 1px solid var(--jo-accent-background-light);
                    width: 97%;
                    margin: 0 auto;
                    border-width: 2px;
                }

                .border-right {
                    border-right: 1px solid var(--jo-accent-background-light);
                    height: 1.7rem;
                    margin: auto 0;
                }

                .nav-bar-elements {
                    display: flex;
                    padding-top: 0.5rem;
                }

                .selected {
                    color: var(--jo-secondary) !important;
                }

                .jo-company-nav-bar--item {
                    box-sizing: border-box;
                    color: white;
                    padding: 0rem 1rem;
                    cursor: pointer;
                    width: 20%;
                    flex-grow: 4;
                }

                .navbar-element-container > span {
                    vertical-align: text-bottom;
                }

                .navbar-element-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    color: grey;
                }

                .icon-description {
                    font-size: 0.6rem;
                }

                @media screen and (max-width: 430px) {
                    .icon-description {
                        font-size: 0.45rem;
                    }
                }

                @media screen and (max-width: 370px) {
                    .icon-description {
                        font-size: 0.35rem;
                    }
                }
            </style>

            <div class="main-container">
                <div class="border-top"></div>

                <div class="nav-bar-elements">
                    <div
                        class="jo-company-nav-bar--item"
                        @click=${() => {
            const navigateResult = (0, utilities_1.navigate)(`/`);
            if (navigateResult === true) {
                this.store.selectedOption = 'DASHBOARD';
            }
        }}
                    >
                        <div
                            class="navbar-element-container ${state.selectedOption === 'DASHBOARD'
            ? 'selected'
            : ''}"
                        >
                            <span class="material-icons">home</span>
                            <div class="icon-description">Dashboard</div>
                        </div>
                    </div>

                    <div class="border-right"></div>

                    <div
                        class="jo-company-nav-bar--item"
                        @click=${() => {
            const navigateResult = (0, utilities_1.navigate)(`/customer/action-items`);
            if (navigateResult === true) {
                this.store.selectedOption = 'ACTION_ITEMS';
            }
        }}
                    >
                        <div
                            class="navbar-element-container ${state.selectedOption ===
            'ACTION_ITEMS'
            ? 'selected'
            : ''}"
                        >
                            <span class="material-icons">question_answer</span>
                            <div class="icon-description">Action Items</div>
                        </div>
                    </div>

                    <div class="border-right"></div>

                    <div
                        class="jo-company-nav-bar--item"
                        @click=${() => {
            const navigateResult = (0, utilities_1.navigate)(`/customer/contact-list`);
            if (navigateResult === true) {
                this.store.selectedOption = 'CONTACTS';
            }
        }}
                    >
                        <div
                            class="navbar-element-container ${state.selectedOption === 'CONTACTS'
            ? 'selected'
            : ''}"
                        >
                            <span class="material-icons">supervised_user_circle</span>
                            <div class="icon-description">Contacts</div>
                        </div>
                    </div>

                    <div class="border-right"></div>

                    <div
                        class="jo-company-nav-bar--item"
                        ?hidden=${(0, utilities_1.getIsCompanyOwner)(this.store.authenticatedUser, this.store.companyId) === false}
                        @click=${() => {
            const navigateResult = (0, utilities_1.navigate)(`customer/voicemail`);
            if (navigateResult === true) {
                this.store.selectedOption = 'VOICEMAIL';
            }
        }}
                    >
                        <div
                            class="navbar-element-container ${state.selectedOption === 'VOICEMAIL'
            ? 'selected'
            : ''}"
                        >
                            <span class="material-icons">voicemail</span>
                            <div class="icon-description">Voicemails</div>
                        </div>
                    </div>

                    <div class="border-right"></div>

                    <div
                        class="jo-company-nav-bar--item"
                        ?hidden=${(0, utilities_1.getIsCompanyOwner)(this.store.authenticatedUser, this.store.companyId) === false}
                        @click=${() => {
            const navigateResult = (0, utilities_1.navigate)(`/customer/logs`);
            if (navigateResult === true) {
                this.store.selectedOption = 'LOGS';
            }
        }}
                    >
                        <div
                            class="navbar-element-container ${state.selectedOption === 'LOGS'
            ? 'selected'
            : ''}"
                        >
                            <span class="material-icons">call</span>
                            <div class="icon-description">Call Log</div>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-customer-bottom-bar', JOCustomerBottomBar);
