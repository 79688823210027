"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeCloseButtonColumn = exports.isColumnAllowedInReadonlyMode = exports.getPodNames = exports.getPodName = exports.getTimeZone = exports.getPod = exports.getCompanyNames = exports.isNextCallAttemptToday = exports.startCall = exports.getNextAttemptDateString = void 0;
const twilio_1 = require("../../../../../services/twilio");
const get_first_valid_phone_number_for_contact_1 = require("../../../../../utilities/contacts/get-first-valid-phone-number-for-contact");
const create_outbound_attempt_1 = require("../../../../../utilities/outbound-attempts/create-outbound-attempt");
const date_format_1 = require("../../../../../utilities/date/date-format");
function getNextAttemptDateString(queueItem) {
    const dateToUse = queueItem.next_attempt_to_be_made_at || queueItem.created_at;
    const dateString = (0, date_format_1.formatAsUtcDateString)(new Date(dateToUse));
    return dateString;
}
exports.getNextAttemptDateString = getNextAttemptDateString;
async function startCall(queueItem, authenticatedUser) {
    try {
        if (!queueItem.contact || !queueItem.company || authenticatedUser === 'NOT_SET') {
            return;
        }
        const newOutboundAttemptId = await (0, create_outbound_attempt_1.createOutboundAttempt)(queueItem, authenticatedUser);
        (0, twilio_1.startOutgoingCall)(undefined, queueItem.contact.id, queueItem.company.id, (0, get_first_valid_phone_number_for_contact_1.getFirstValidPhoneNumberFromContact)(queueItem.contact), authenticatedUser.id, newOutboundAttemptId);
    }
    catch (error) {
        throw new Error(`jo-outbound -> jo-outbound-table -> utils -> helper-utils.ts startOutgoingCall() error: ${error}`);
    }
}
exports.startCall = startCall;
function isNextCallAttemptToday(queueItem) {
    const today = new Date().getTime();
    const nextDate = new Date(getNextAttemptDateString(queueItem)).getTime();
    return today === nextDate;
}
exports.isNextCallAttemptToday = isNextCallAttemptToday;
function getCompanyNames(companySelectionOptions) {
    const companyNameSelectionOptions = companySelectionOptions.map(option => option.name);
    return [
        'All',
        ...companyNameSelectionOptions,
    ];
}
exports.getCompanyNames = getCompanyNames;
function getPod(queueItem) {
    return queueItem.company?.pod?.name ?? 'Unknown';
}
exports.getPod = getPod;
function getTimeZone(queueItem) {
    return queueItem.company?.timezone ?? 'Unknown';
}
exports.getTimeZone = getTimeZone;
function getPodName(pod) {
    return pod.name ?? '';
}
exports.getPodName = getPodName;
function getPodNames(podsResult) {
    return podsResult.value.map(pod => getPodName(pod));
}
exports.getPodNames = getPodNames;
const columnTitlesAllowedInReadonlyMode = [
    'Name',
    'Campaign Name',
    'Call Attempts',
    'Status',
    'Date of Next Attempt',
];
function isColumnAllowedInReadonlyMode(column) {
    if (column == undefined || column.title == undefined) {
        return false;
    }
    const title = column.title.toString();
    return columnTitlesAllowedInReadonlyMode.includes(title);
}
exports.isColumnAllowedInReadonlyMode = isColumnAllowedInReadonlyMode;
function removeCloseButtonColumn(columns) {
    return columns.filter(column => {
        if (column.title !== 'Close') {
            return column;
        }
    });
}
exports.removeCloseButtonColumn = removeCloseButtonColumn;
