"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoQueueStatusBoxSettingsPage = void 0;
const element_vir_1 = require("element-vir");
const utilities_1 = require("../../../../services/utilities");
const constants_and_types_queue_status_box_settings_1 = require("./constants-and-types-queue-status-box-settings");
const jo_priority_level_customize_pop_up_element_1 = require("./jo-priority-level-customize-pop-up.element");
const jo_queue_box_settings_priority_level_element_1 = require("./jo-queue-box-settings-priority-level.element");
const queue_status_priority_level_crud_operations_1 = require("./queue-status-priority-level-crud-operations");
exports.JoQueueStatusBoxSettingsPage = (0, element_vir_1.defineElement)()({
    tagName: 'jo-queue-status-box-settings-page',
    styles: (0, element_vir_1.css) `
        :host {
            display: block;
            padding: 2rem;
            color: var(--jo-text-default);
        }

        .priority-cards-container {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            gap: 2rem;
            padding-top: 2rem;
        }
    `,
    stateInit: {
        queuePriorityLevels: (0, element_vir_1.asyncState)(),
        temporaryPriorityLevel: constants_and_types_queue_status_box_settings_1.defaultPriorityLevel,
    },
    renderCallback({ state, updateState, inputs }) {
        if (!inputs.authenticatedUser)
            return '';
        updateState({
            queuePriorityLevels: {
                async createPromise() {
                    return await (0, queue_status_priority_level_crud_operations_1.fetchPriorityLevels)(constants_and_types_queue_status_box_settings_1.QueueStatusBoxType.INCOMING_CALLS);
                },
                trigger: inputs.authenticatedUser.id,
            },
        });
        const queuePriorityLevels = state.queuePriorityLevels;
        if (!(0, element_vir_1.isRenderReady)(queuePriorityLevels)) {
            return (0, element_vir_1.html) `
                <jo-loading .loading=${true}></jo-loading>
            `;
        }
        return (0, element_vir_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

            <div class="jo-global--card">
                <h2>Incoming Queue Status Box</h2>

                <jo-button
                    class="new-priority-level-button"
                    .type=${'ACTION_BUTTON_1'}
                    .text=${'Add New Priority Level'}
                    @joButtonClick=${async () => {
            await displayCreateNewPriorityLevelPopUp(queuePriorityLevels);
        }}
                ></jo-button>

                <div class="priority-cards-container">
                    ${queuePriorityLevels.map(level => {
            return (0, element_vir_1.html) `
                            <${jo_queue_box_settings_priority_level_element_1.JoQueueBoxSettingsPriorityLevel}
                                class="jo-global--card"
                                ${(0, element_vir_1.assign)(jo_queue_box_settings_priority_level_element_1.JoQueueBoxSettingsPriorityLevel, {
                level,
                queuePriorityLevels,
            })}
                                ${(0, element_vir_1.listen)(jo_queue_box_settings_priority_level_element_1.JoQueueBoxSettingsPriorityLevel.events.priorityLevelChange, async (event) => {
                if (event.detail) {
                    await (0, queue_status_priority_level_crud_operations_1.updatePriorityLevel)(event.detail);
                }
                else {
                    await (0, queue_status_priority_level_crud_operations_1.deletePriorityLevel)(level.id);
                }
                updateState({
                    queuePriorityLevels: {
                        newPromise: (0, queue_status_priority_level_crud_operations_1.fetchPriorityLevels)(constants_and_types_queue_status_box_settings_1.QueueStatusBoxType.INCOMING_CALLS),
                    },
                });
            })}
                            ></${jo_queue_box_settings_priority_level_element_1.JoQueueBoxSettingsPriorityLevel}>
                        `;
        })}
                </div>
            </div>
        `;
        async function displayCreateNewPriorityLevelPopUp(queuePriorityLevels) {
            updateState({
                temporaryPriorityLevel: constants_and_types_queue_status_box_settings_1.defaultPriorityLevel,
            });
            const shouldCreate = await (0, utilities_1.joPopUp)({
                title: 'Create New Priority Level',
                confirmButtonText: 'Create',
                includeCancelButton: true,
                htmlComponents: generateCreateNewPriorityLevelPopUpHtml(queuePriorityLevels),
            });
            if (shouldCreate) {
                await (0, queue_status_priority_level_crud_operations_1.createNewPriorityLevel)(state.temporaryPriorityLevel);
                updateState({
                    queuePriorityLevels: {
                        newPromise: (0, queue_status_priority_level_crud_operations_1.fetchPriorityLevels)(constants_and_types_queue_status_box_settings_1.QueueStatusBoxType.INCOMING_CALLS),
                    },
                });
            }
        }
        function generateCreateNewPriorityLevelPopUpHtml(queuePriorityLevels) {
            return (0, element_vir_1.html) `
                <${jo_priority_level_customize_pop_up_element_1.JoCustomizePriorityLevelPopUp}
                    ${(0, element_vir_1.assign)(jo_priority_level_customize_pop_up_element_1.JoCustomizePriorityLevelPopUp, {
                priorityLevel: state.temporaryPriorityLevel,
                queuePriorityLevels: queuePriorityLevels,
            })}
                    ${(0, element_vir_1.listen)(jo_priority_level_customize_pop_up_element_1.JoCustomizePriorityLevelPopUp.events.updatePriorityLevel, event => {
                updateState({
                    temporaryPriorityLevel: event.detail,
                });
            })}
                ></${jo_priority_level_customize_pop_up_element_1.JoCustomizePriorityLevelPopUp}>
            `;
        }
    },
});
