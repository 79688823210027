"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoCompanyLogsContainerPage = void 0;
const common_1 = require("@augment-vir/common");
const element_vir_1 = require("element-vir");
const class_map_js_1 = require("lit/directives/class-map.js");
require("../../../elements/jo-company-call-log/jo-company-call-log.element");
const jo_company_call_log_element_1 = require("../../../elements/jo-company-call-log/jo-company-call-log.element");
const jo_company_chat_log_1 = require("../chat-logs/jo-company-chat-log");
const get_company_log_container_page_styles_1 = require("./get-company-log-container-page-styles");
var TabTypeEnum;
(function (TabTypeEnum) {
    TabTypeEnum["CALL_LOG"] = "Call Log";
    TabTypeEnum["CHAT_LOG"] = "Chat Log";
})(TabTypeEnum || (TabTypeEnum = {}));
exports.JoCompanyLogsContainerPage = (0, element_vir_1.defineElement)()({
    tagName: 'jo-company-logs-container-page',
    styles: get_company_log_container_page_styles_1.joCompanyLogsContainerPageStyles,
    stateInit: {
        selectedTab: TabTypeEnum.CALL_LOG,
    },
    renderCallback: ({ state, updateState, inputs }) => {
        const tabBarTemplates = (0, common_1.getEnumTypedValues)(TabTypeEnum).map(tabType => {
            return (0, element_vir_1.html) `
                <div
                    class=${(0, class_map_js_1.classMap)({
                tab: true,
                'selected-tab': state.selectedTab === tabType,
            })}
                    ${(0, element_vir_1.listen)('click', () => {
                updateState({ selectedTab: tabType });
            })}
                >
                    ${tabType}
                </div>
            `;
        });
        return (0, element_vir_1.html) `
            <div class="card">
                <nav class="tabs-bar">${tabBarTemplates}</nav>
                ${createContentForTab(state.selectedTab, inputs)}
            </div>
        `;
    },
});
const tabCreatorByType = {
    [TabTypeEnum.CALL_LOG]: jo_company_call_log_element_1.JoCompanyCallLog,
    [TabTypeEnum.CHAT_LOG]: jo_company_chat_log_1.JoCompanyChatLog,
};
function createContentForTab(selectedTab, inputs) {
    const companyLogElement = tabCreatorByType[selectedTab];
    return (0, element_vir_1.html) `
        <${companyLogElement} ${(0, element_vir_1.assign)(companyLogElement, inputs)}></${companyLogElement}>
    `;
}
