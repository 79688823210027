"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getColumns = void 0;
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const graphql_1 = require("../../../../services/graphql");
const twilio_1 = require("../../../../services/twilio");
const utilities_1 = require("../../../../services/utilities");
const get_first_valid_phone_number_for_contact_1 = require("../../../../utilities/contacts/get-first-valid-phone-number-for-contact");
require("../../jo-button.element");
require("../../jo-loading");
require("../../jo-outbound/jo-create-outbound-list/jo-create-outbound-list");
require("../../jo-table");
require("../jo-company-contacts-custom-action-template");
require("../jo-company-contacts-mobile-template");
const helper_utils_1 = require("./helper-utils");
const queries_and_mutations_1 = require("./queries-and-mutations");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
const test_id_directive_1 = require("../../../directives/test-id.directive");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    companyId: 'NOT_SET',
    contactCreatedSubscription: 'NOT_SET',
    contactUpdatedSubscription: 'NOT_SET',
    loading: true,
    isMobileDevice: false,
};
class JOCompanyContacts extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
        this.callGetContacts = (async (offset, limit, searchText) => {
            return await (0, queries_and_mutations_1.getContacts)(offset, limit, searchText, this.store.companyId);
        }).bind(this);
    }
    set companyId(companyId) {
        if (this.store.companyId === companyId) {
            return;
        }
        this.callGetContacts = this.callGetContacts.bind(this);
        this.store.companyId = companyId;
        if (this.store.companyId !== 'NOT_SET') {
            this.closeSubscriptionsIfNecessary();
            this.createSubscriptionsIfNecessary();
        }
    }
    closeSubscriptionsIfNecessary() {
        this.closeContactCreatedSubscriptionIfNecessary();
        this.closeContactUpdatedSubscriptionIfNecessary();
    }
    closeContactCreatedSubscriptionIfNecessary() {
        if (this.store.contactCreatedSubscription !== 'NOT_SET' &&
            this.store.contactCreatedSubscription.companyId !== this.store.companyId) {
            this.store.contactCreatedSubscription.webSocket?.close();
            this.store.contactCreatedSubscription = 'NOT_SET';
        }
    }
    closeContactUpdatedSubscriptionIfNecessary() {
        if (this.store.contactUpdatedSubscription !== 'NOT_SET' &&
            this.store.contactUpdatedSubscription.companyId !== this.store.companyId) {
            this.store.contactUpdatedSubscription.webSocket?.close();
            this.store.contactUpdatedSubscription = 'NOT_SET';
        }
    }
    async createSubscriptionsIfNecessary() {
        await this.createContactCreatedSubscriptionIfNecessary();
        await this.createContactUpdatedSubscriptionIfNecessary();
    }
    async createContactCreatedSubscriptionIfNecessary() {
        if (this.store.contactCreatedSubscription === 'NOT_SET') {
            this.store.contactCreatedSubscription = {
                companyId: this.store.companyId,
                webSocket: await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
                        subscription ($companyId: Int!) {
                            contactCreatedForCompany(companyId: $companyId)
                        }
                    `, () => {
                    // We do not know if this.store.companyId will be set when this callback runs
                    // Therefore we store the companyId with the subscription, and we temporarily set this.store.companyId to the stored value
                    // TODO if we could send in the companyId to callGetActionItems somehow, we could get around this hack
                    const originalCompanyId = this.store.companyId;
                    const temporaryCompanyId = this.store.contactCreatedSubscription === 'NOT_SET'
                        ? originalCompanyId
                        : this.store.contactCreatedSubscription.companyId;
                    this.callGetContacts = this.callGetContacts.bind(this);
                    this.store.companyId = temporaryCompanyId;
                    this.store.companyId = originalCompanyId;
                }, {
                    companyId: this.store.companyId,
                }),
            };
        }
    }
    async createContactUpdatedSubscriptionIfNecessary() {
        if (this.store.contactUpdatedSubscription === 'NOT_SET') {
            this.store.contactUpdatedSubscription = {
                companyId: this.store.companyId,
                webSocket: await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
                        subscription ($companyId: Int!) {
                            contactUpdatedForCompany(companyId: $companyId)
                        }
                    `, () => {
                    // We do not know if this.store.companyId will be set when this callback runs
                    // Therefore we store the companyId with the subscription, and we temporarily set this.store.companyId to the stored value
                    // TODO if we could send in the companyId to callGetActionItems somehow, we could get around this hack
                    const originalCompanyId = this.store.companyId;
                    const temporaryCompanyId = this.store.contactCreatedSubscription === 'NOT_SET'
                        ? originalCompanyId
                        : this.store.contactCreatedSubscription.companyId;
                    this.callGetContacts = this.callGetContacts.bind(this);
                    this.store.companyId = temporaryCompanyId;
                    this.store.companyId = originalCompanyId;
                }, {
                    companyId: this.store.companyId,
                }),
            };
        }
    }
    triggerTableReload() {
        this.callGetContacts = this.callGetContacts.bind(this);
        this.store.companyId = this.store.companyId;
    }
    async importContactsFromCSV() {
        (0, utilities_1.assertIsSet)(this.store.companyId, utilities_1.handleError, 'this.store.companyId');
        const contactsCSV = await (0, utilities_1.uploadCSV)();
        const contactsCSVFileContentWithoutHeader = (0, helper_utils_1.preProcessContactsCSV)(contactsCSV);
        const { validCSVRecords, invalidCSVRecords } = (0, helper_utils_1.getValidAndInvalidCSVRecords)(contactsCSVFileContentWithoutHeader);
        if (validCSVRecords.length === 0) {
            await (0, utilities_1.joAlert)('Failure', 'No valid records');
            return;
        }
        const addAllContactsFromCSVToCompanyResult = await (0, queries_and_mutations_1.addAllContactsFromCSVToCompany)(this.store.companyId, (0, helper_utils_1.removeSpecialCharactersFromCSV)(validCSVRecords));
        if (addAllContactsFromCSVToCompanyResult.succeeded === false) {
            (0, utilities_1.joAlert)('Failure', 'Something went wrong');
            return addAllContactsFromCSVToCompanyResult;
        }
        this.triggerTableReload();
        await (0, utilities_1.joAlert)('Success', `Successfully created ${validCSVRecords.length} contacts`);
        if (invalidCSVRecords.length > 0) {
            (0, helper_utils_1.addPopUpWindowForInvalidRecords)(invalidCSVRecords);
        }
    }
    render(state) {
        const isCompanyUser = (0, utilities_1.getIsCompanyUser)(state.authenticatedUser, state.companyId);
        return (0, lit_html_1.html) `
            <style>
                .jo-company-contacts--main-container {
                    box-sizing: border-box;
                    height: 100%;
                    width: ${state.isMobileDevice ? '100vmin' : '100%'};
                    font-family: sans-serif;
                }

                .jo-company-contacts--new-contact-container {
                    padding: ${state.isMobileDevice ? '0' : '0 0 2rem 0'};
                }

                .jo-company-contacts--actions {
                    display: flex;
                }

                .jo-company-contacts--action {
                    padding-left: 0.25rem;
                }

                .add-button {
                    padding-right: 1rem;
                }

                .add-contacts-container {
                    display: flex;
                    padding-top: 1rem;
                }

                .download-sample-template {
                    display: flex;
                    color: var(--jo-text-default);
                }

                .download-sample-template a {
                    color: var(--jo-primary);
                }
            </style>

            <div class="jo-company-contacts--main-container">
                <div class="jo-company-contacts--new-contact-container">
                    <div class="add-contacts-container" .hidden=${state.isMobileDevice}>
                        <jo-button
                            class="add-button"
                            .type=${'ACTION_BUTTON_1'}
                            .text=${'New Contact'}
                            @joButtonClick=${() => (0, utilities_1.navigate)(isCompanyUser
            ? `/customer/contact?companyId=${state.companyId}`
            : `/company/contact?companyId=${state.companyId}`)}
                        ></jo-button>
                        <jo-button
                            class="add-button"
                            .type=${'ACTION_BUTTON_1'}
                            .text=${'Import Contacts from CSV'}
                            @joButtonClick=${() => this.importContactsFromCSV()}
                        ></jo-button>
                        <jo-create-outbound-list
                            class="add-button"
                            .companyId=${state.companyId}
                            .authenticatedUser=${state.authenticatedUser}
                            @uploadFinish=${() => {
            this.triggerTableReload();
        }}
                        ></jo-create-outbound-list>
                        <a
                            style="text-decoration: none;"
                            href="https://jillsoffice.s3.us-west-2.amazonaws.com/contacts.csv"
                            download
                        >
                            <jo-button
                                class="add-button"
                                .type=${'ACTION_BUTTON_1'}
                                .text=${'Download Sample CSV'}
                            ></jo-button>
                        </a>
                    </div>
                </div>

                <div class="${state.isMobileDevice === false ? 'jo-global--card' : ''}">
                    <jo-table
                        .tableName=${'Contacts'}
                        .maxItemsPerPage=${50}
                        .columns=${getColumns(state, isCompanyUser)}
                        .getItems=${state.companyId === 'NOT_SET'
            ? 'NOT_SET'
            : this.callGetContacts}
                        .searchDebounce=${200}
                        .isMobileDevice=${state.isMobileDevice}
                        .mobileTemplate=${helper_utils_1.getMobileTemplate}
                        .customActionTemplate=${helper_utils_1.getCustomActionTemplate}
                        .isCompanyUser=${isCompanyUser}
                        .companyId=${state.companyId}
                        .showCSVButton=${true}
                        @itemsfetched=${() => (this.store.loading = false)}
                    ></jo-table>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-contacts', JOCompanyContacts);
function getColumns(state, isCompanyUser) {
    const columns = [
        {
            title: 'Actions',
            getCellData: (contact) => {
                return (0, lit_html_1.html) `
                    <div class="jo-company-contacts--actions">
                        <jo-button
                            ?hidden=${isCompanyUser}
                            .type=${'ICON'}
                            .icon=${'phone'}
                            @click=${() => {
                    (0, utilities_1.assertIsSet)(state.companyId, utilities_1.handleError, 'state.companyId');
                    const phoneNumber = (0, get_first_valid_phone_number_for_contact_1.getFirstValidPhoneNumberFromContact)(contact);
                    (0, utilities_1.assertIsSet)(state.authenticatedUser, utilities_1.handleError, 'state.authenticatedUser');
                    (0, twilio_1.startOutgoingCall)(undefined, contact.id, state.companyId, phoneNumber, state.authenticatedUser.id);
                }}
                        ></jo-button>

                        <jo-button
                            class="jo-company-contacts--action"
                            .type=${'ICON'}
                            .icon=${'email'}
                            .href=${`mailto:${contact.email}`}
                        ></jo-button>

                        <jo-button
                            ${(0, test_id_directive_1.testId)('pencil-icon-edit-contact')}
                            class="jo-company-contacts--action"
                            .type=${'ICON'}
                            .icon=${'edit'}
                            .href=${isCompanyUser
                    ? `/customer/contact?companyId=${state.companyId}&contactId=${contact.id}`
                    : `/company/contact?companyId=${state.companyId}&contactId=${contact.id}`}
                        ></jo-button>
                    </div>
                `;
            },
            width: isCompanyUser ? 0.5 : 1,
        },
        {
            title: 'Name',
            getCellData: (contact) => contact.first_name + ' ' + contact.last_name,
            width: 1,
        },
        {
            title: 'Company Name',
            getCellData: (contact) => contact.company_name,
            width: 1,
        },
        {
            title: 'Email',
            getCellData: (contact) => contact.email,
            width: 1,
        },
        {
            title: 'Phone Number',
            getCellData: (contact) => (0, get_first_valid_phone_number_for_contact_1.getFirstValidPhoneNumberFromContact)(contact),
            width: 1,
        },
        {
            title: 'Date Added',
            getCellData: (contact) => new Date(contact.created_at).toLocaleDateString(),
            width: 1,
        },
    ];
    return columns;
}
exports.getColumns = getColumns;
