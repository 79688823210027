"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isInputValuesEmpty = exports.getMatchingServiceForSelectedServiceName = exports.getUpdatedServiceLineItemInputValue = void 0;
const utilities_1 = require("../../../../services/utilities");
function getUpdatedServiceLineItemInputValue(serviceLineItemInputValue, company, outerIndex, innerIndex, e) {
    if (outerIndex !== innerIndex) {
        return {
            ...serviceLineItemInputValue,
            formErrors: e.detail.formErrors,
        };
    }
    (0, utilities_1.assertIsSet)(company, utilities_1.handleError);
    const matchingService = getMatchingServiceForSelectedServiceName(company.services, e.detail.selectedValue);
    if (matchingService === undefined) {
        return {
            ...serviceLineItemInputValue,
            formErrors: e.detail.formErrors,
        };
    }
    return {
        ...serviceLineItemInputValue,
        service: {
            id: matchingService.id,
            name: matchingService.name,
        },
        formErrors: e.detail.formErrors,
    };
}
exports.getUpdatedServiceLineItemInputValue = getUpdatedServiceLineItemInputValue;
function getMatchingServiceForSelectedServiceName(services, selectedServiceName) {
    return services.find(service => {
        if ((0, utilities_1.trimExtraWhiteSpace)(service.name) === (0, utilities_1.trimExtraWhiteSpace)(selectedServiceName)) {
            return true;
        }
        else {
            return false;
        }
    });
}
exports.getMatchingServiceForSelectedServiceName = getMatchingServiceForSelectedServiceName;
function isInputValuesEmpty(inputValues) {
    if (inputValues.serviceLineItemsInputValues.length === 0) {
        return true;
    }
    return false;
}
exports.isInputValuesEmpty = isInputValuesEmpty;
