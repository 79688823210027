"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchAuthenticatedUser = exports.authenticate = void 0;
const common_1 = require("@augment-vir/common");
const stored_path_before_login_1 = require("../../data/local-storage/stored-path-before-login");
const is_in_admin_mode_1 = require("../../utilities/admin-utils/is-in-admin-mode");
const mapped_env_variables_1 = require("../../utilities/environment/mapped-env-variables");
const url_1 = require("../../utilities/url");
const fetch_most_recent_status_event_for_user_1 = require("../../utilities/user-status-events/fetch-most-recent-status-event-for-user");
const constants_1 = require("../constants");
const graphql_1 = require("../graphql");
const attach_log_tag_1 = require("../logging/attach-log-tag");
const handle_error_1 = require("../logging/error-handling/handle-error");
const send_log_1 = require("../logging/send-log");
const store_1 = require("../store");
const twilio_1 = require("../twilio");
const utilities_1 = require("../utilities");
class NotAuthorizedError extends Error {
    constructor() {
        super('Not authorized');
        this.name = 'NotAuthorizedError';
    }
}
async function authenticate() {
    try {
        const authenticatedUser = await fetchAuthenticatedUser();
        if (authenticatedUser === 'NOT_FOUND') {
            throw new NotAuthorizedError();
        }
        store_1.GlobalStore.authenticatedUser = authenticatedUser;
        if ((0, utilities_1.userIsJill)(store_1.GlobalStore.authenticatedUser) === true &&
            store_1.GlobalStore.twilioDevice === 'NOT_SET') {
            await (0, twilio_1.initializeTwilio)();
        }
        await createAuthenticatedUserStatusSubscriptions();
        store_1.GlobalStore.loading = false;
    }
    catch (error) {
        handleAuthenticateError((0, common_1.ensureError)(error));
    }
}
exports.authenticate = authenticate;
function handleAuthenticateError(authenticateError) {
    removeAuthStuffFromLocalStorage();
    if (authenticateError instanceof NotAuthorizedError) {
        send_log_1.sendLog.info('user not authenticated');
    }
    else {
        /**
         * Only record an error if it's not a NotAuthorizedError instance. We don't want
         * NotAuthorizedError errors (which are normal) polluting the error logs.
         */
        (0, handle_error_1.handleError)(authenticateError);
    }
    if (window.location.pathname !== '/reset-password') {
        try {
            stored_path_before_login_1.storedPathBeforeLogin.set((0, url_1.extractPostOriginUrlString)(window.location));
        }
        catch (error) {
            (0, handle_error_1.handleError)(error);
        }
        (0, utilities_1.navigate)('/login');
    }
    store_1.GlobalStore.loading = false;
}
function removeAuthStuffFromLocalStorage() {
    (0, attach_log_tag_1.clearLoggingTags)();
    window.localStorage.removeItem(constants_1.csrfTokenName);
    window.localStorage.removeItem(constants_1.clientPublicKeyName);
    window.localStorage.removeItem(constants_1.clientPrivateKeyName);
}
async function createAuthenticatedUserStatusSubscriptions() {
    try {
        if (store_1.GlobalStore.availabilitySubscriptionCreated === false) {
            await createUserAvailabilityChangedSubscription();
            store_1.GlobalStore.availabilitySubscriptionCreated = true;
        }
        if (store_1.GlobalStore.personalAdminModeSubscriptionCreated === false) {
            await createPersonalAdminModeChangedSubscription();
            store_1.GlobalStore.personalAdminModeSubscriptionCreated = true;
        }
        if (store_1.GlobalStore.workStatusChangedSubscriptionCreated === false) {
            await createWorkStatusChangedSubscription();
            store_1.GlobalStore.workStatusChangedSubscriptionCreated = true;
        }
    }
    catch (error) {
        (0, handle_error_1.handleError)(error);
    }
}
async function createUserAvailabilityChangedSubscription() {
    (0, utilities_1.assertIsSet)(store_1.GlobalStore.authenticatedUser, handle_error_1.handleError, 'GlobalStore.authenticatedUser');
    await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
            subscription ($userId: Int!) {
                userAvailabilityChanged(userId: $userId)
            }
        `, async () => {
        await authenticate();
    }, {
        userId: store_1.GlobalStore.authenticatedUser.id,
    });
}
async function createPersonalAdminModeChangedSubscription() {
    (0, utilities_1.assertIsSet)(store_1.GlobalStore.authenticatedUser, handle_error_1.handleError, 'GlobalStore.authenticatedUser');
    await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
            subscription ($userId: Int!) {
                userPersonalAdminModeChanged(userId: $userId)
            }
        `, async () => {
        await authenticate();
    }, {
        userId: store_1.GlobalStore.authenticatedUser.id,
    });
}
async function createWorkStatusChangedSubscription() {
    (0, utilities_1.assertIsSet)(store_1.GlobalStore.authenticatedUser, handle_error_1.handleError, 'GlobalStore.authenticatedUser');
    await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
            subscription ($userId: Int!) {
                userWorkStatusChanged(userId: $userId)
            }
        `, async () => {
        await authenticate();
    }, {
        userId: store_1.GlobalStore.authenticatedUser.id,
    });
}
async function fetchAuthenticatedUser() {
    const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
        query {
            authenticatedUser {
                id
                first_name
                last_name
                email
                auth_role
                is_24_7
                is_available
                companies {
                    id
                }
                chat_mode
                permissions {
                    can_delete_invoices
                    has_chat_access
                    can_view_incoming_queue_box
                }
                profile_picture_management_privileges
                profile_picture_url
                is_personal_admin
                in_personal_admin_mode
                work_status
            }
        }
    `);
    if (gqlResult.data.authenticatedUser === null) {
        return 'NOT_FOUND';
    }
    const currentUser = gqlResult.data.authenticatedUser;
    const logTags = { userId: currentUser.id, authRole: currentUser.auth_role };
    (0, attach_log_tag_1.attachLoggingTag)(logTags);
    if ((0, is_in_admin_mode_1.isInAdminMode)(currentUser)) {
        return addMostRecentStatusEventToUser(currentUser, 'BEGAN_ADMIN_MODE');
    }
    else if (currentUser.work_status === 'ON_BREAK') {
        return addMostRecentStatusEventToUser(currentUser, 'WENT_ON_BREAK');
    }
    else if (currentUser.work_status === 'ON_LUNCH') {
        return addMostRecentStatusEventToUser(currentUser, 'WENT_ON_LUNCH');
    }
    else if (currentUser.work_status === 'IN_TEAM_HUDDLE') {
        return addMostRecentStatusEventToUser(currentUser, 'BEGAN_TEAM_HUDDLE');
    }
    else if (currentUser.work_status === 'IN_OFF_CALL_TRAINING') {
        return addMostRecentStatusEventToUser(currentUser, 'BEGAN_OFF_CALL_TRAINING');
    }
    return currentUser;
}
exports.fetchAuthenticatedUser = fetchAuthenticatedUser;
async function addMostRecentStatusEventToUser(user, eventType) {
    const mostRecentStatusEvent = await (0, fetch_most_recent_status_event_for_user_1.fetchMostRecentStatusEventForUser)(user.id, eventType);
    if (mostRecentStatusEvent !== undefined) {
        return {
            ...user,
            user_status_events: [mostRecentStatusEvent],
        };
    }
    return user;
}
