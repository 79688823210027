"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stylesForjoOutboundTableCallAttemptsColumn = exports.stylesForjoOutboundTable = void 0;
const lit_html_1 = require("lit-html");
const css_utils_1 = require("../../../../utilities/css-utils");
const cssName = (0, css_utils_1.cssPrefixer)('jo-outbound-table');
exports.stylesForjoOutboundTable = (() => {
    return (0, lit_html_1.html) `
        <style>
            .${cssName('main-container')} {
                height: 100%;
                width: 100%;
                box-sizing: border-box;
                padding: 1rem;
            }

            .${cssName('filter-row')} {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 3rem;
                justify-content: center;
                padding: 1rem;
            }

            .${cssName('companies-filter-input')} {
                color: rgb(60, 60, 60);
                padding: 0.25rem;
                border: 1px solid rgb(180, 180, 180);
                border-radius: 3px;
                background-color: white;
                transition: 0.175s ease;
                box-sizing: border-box;
                height: 100%;
            }

            .${cssName('companies-filter-container')} {
                display: flex;
                flex-direction: column;
                font-family: sans-serif;
                font-size: 0.9rem;
            }

            .${cssName('filter-button')} {
                padding-top: 0.7rem;
            }

            .${cssName('filter-close-button')} {
                margin-right: 4rem;
                padding-top: 0.7rem;
            }

            .${cssName('outbound-queue-table')} {
                padding-top: 1rem;
                padding-bottom: 1rem;
            }

            .${cssName('reset-fitlers')}:hover {
                color: var(--jo-secondary);
            }

            .${cssName('reset-fitlers')} {
                cursor: pointer;
                padding-top: 1rem;
            }
        </style>
    `;
})();
exports.stylesForjoOutboundTableCallAttemptsColumn = (() => {
    return (0, lit_html_1.html) `
        <style>
            .jo-outbound-table-columns-attempt-circle {
                border: 2px dotted var(--jo-primary);
                border-radius: 50%;
                width: 18px;
                height: 17px;
                text-align: center;
                margin: 2px;
                cursor: help;
            }
            .jo-outbound-table-columns-completed-attempt-circle {
                border: 2px solid var(--jo-primary);
                color: white;
                background-color: var(--jo-primary);
                border-radius: 50%;
                width: 18px;
                height: 17px;
                text-align: center;
                margin: 2px;
                cursor: pointer;
                text-decoration: none;
            }
            .jo-outbound-table-columns-matching-filter {
                border: 2px solid var(--jo-secondary);
                color: white;
                background-color: var(--jo-secondary);
            }
            .jo-outbound-table-columns-completed-attempt-circle:hover {
                transform: scale(1.2);
            }
            .jo-outbound-table-columns-attempts-container {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                font-size: 0.9rem;
            }
        </style>
    `;
})();
