"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoHelpTicketStatus = void 0;
const element_vir_1 = require("element-vir");
const account_help_ticket_status_1 = require("../../../utilities/account-help-tickets/account-help-ticket-status");
const colorMap = {
    [account_help_ticket_status_1.AccountHelpTicketStatus.PENDING_REVIEW]: { backgroundColor: 'yellow', color: 'black' },
    [account_help_ticket_status_1.AccountHelpTicketStatus.IN_REVIEW]: { backgroundColor: 'violet', color: 'black' },
    [account_help_ticket_status_1.AccountHelpTicketStatus.CLOSED_RESOLVED]: { backgroundColor: 'green', color: 'white' },
    [account_help_ticket_status_1.AccountHelpTicketStatus.CLOSED_NO_RESOLUTION]: { backgroundColor: 'red', color: 'white' },
    [account_help_ticket_status_1.AccountHelpTicketStatus.CLOSED_AS_MISTAKE]: { backgroundColor: 'orange', color: 'black' },
};
exports.JoHelpTicketStatus = (0, element_vir_1.defineElement)()({
    tagName: 'jo-help-ticket-status',
    renderCallback({ inputs }) {
        const { color, backgroundColor } = colorMap[inputs.status];
        return (0, element_vir_1.html) `
            <span style="color: ${color}; background-color: ${backgroundColor}">
                ${inputs.status}
            </span>
        `;
    },
});
