"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchBillingReportCompanyIds = exports.fetchCompanies = void 0;
const graphql_1 = require("../../../../services/graphql");
const get_start_of_billing_cycle_1 = require("../../../../utilities/dates-and-times/get-start-of-billing-cycle");
const get_start_of_next_billing_cycle_1 = require("../../../../utilities/dates-and-times/get-start-of-next-billing-cycle");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function fetchCompanies(params) {
    const { billingCycleType, dateInStartBillingCycle, dateInEndBillingCycle, offset, limit } = params;
    const billingReportCompanyIds = await fetchBillingReportCompanyIds({
        billingCycleType,
        dateInStartBillingCycle,
        dateInEndBillingCycle,
    });
    if (billingCycleType === 'ALL') {
        return await fetchCompaniesForAllBillingCycles({
            billingReportCompanyIds,
            offset,
            limit,
            dateInStartBillingCycle,
            dateInEndBillingCycle,
        });
    }
    else {
        return await fetchCompaniesForBillingCycleType({
            billingCycleType,
            billingReportCompanyIds,
            offset,
            limit,
            dateInStartBillingCycle,
            dateInEndBillingCycle,
        });
    }
}
exports.fetchCompanies = fetchCompanies;
async function fetchCompaniesForBillingCycleType(params) {
    const { dateInStartBillingCycle, dateInEndBillingCycle, billingCycleType, billingReportCompanyIds, offset, limit, } = params;
    const startDate = (0, get_start_of_billing_cycle_1.getStartOfBillingCycleInMountainTime)(dateInStartBillingCycle, billingCycleType);
    const endDate = (0, get_start_of_next_billing_cycle_1.getStartOfNextBillingCycleInMountainTime)(dateInEndBillingCycle, billingCycleType);
    const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
            query (
                $billingReportCompanyIds: [Int!]!
                $offset: Int!
                $limit: Int!
                $startDate: DateTime!
                $endDate: DateTime!
                $billingCycleType: String!
            ) {
                findCompanies(
                    filter: {id: {in: $billingReportCompanyIds}}
                    orderBy: {field: "id", order: ASC}
                    page: {offset: $offset, limit: $limit}
                ) {
                    items {
                        id
                        account_manager {
                            id
                            first_name
                            last_name
                        }
                        calendar {
                            id
                            name
                        }
                        canceled
                        created_at
                        canceled_at
                        credit_card_key
                        company_logs(
                            filter: {
                                and: [
                                    {start_date: {ge: $startDate}}
                                    {end_date: {le: $endDate}}
                                    {billing_cycle_type: {eq: $billingCycleType}}
                                ]
                            }
                        ) {
                            id
                            billable_duration
                            start_date
                            end_date
                            billing_cycle_type
                        }
                        current_minutes_package {
                            id
                            billing_cycle_type
                        }
                        industry
                        invoices(
                            filter: {
                                and: [
                                    {start_date: {ge: $startDate}}
                                    {end_date: {le: $endDate}}
                                    {billing_cycle_type: {eq: $billingCycleType}}
                                ]
                            }
                            orderBy: {field: "id", order: DESC}
                        ) {
                            id
                            billing_cycle_type
                            start_date
                            end_date
                            invoice_line_items {
                                id
                                amount
                            }
                            minutes_package {
                                id
                                name
                                num_minutes_included
                                price_per_minute
                            }
                            stripe_id
                            stripe_invoice {
                                totalPaid
                                totalCharged
                            }
                            manually_assigned_status
                        }
                        is_test_company
                        is_vip
                        name
                        niche
                        organization {
                            id
                            owner {
                                id
                                phone_number
                                email
                            }
                        }
                        pod {
                            id
                            name
                        }
                        referral_source
                        referral_tag
                        preferred_communication
                    }
                }
            }
        `, {
        billingReportCompanyIds,
        offset,
        limit,
        startDate,
        endDate,
        billingCycleType,
    });
    return gqlResult.data.findCompanies.items;
}
async function fetchCompaniesForAllBillingCycles(params) {
    const { billingReportCompanyIds, dateInStartBillingCycle, dateInEndBillingCycle, offset, limit } = params;
    const startDate = (0, get_start_of_billing_cycle_1.getStartOfBillingCycleInMountainTime)(dateInStartBillingCycle, 'MONTHLY');
    const endDate = (0, get_start_of_next_billing_cycle_1.getStartOfNextBillingCycleInMountainTime)(dateInEndBillingCycle, 'MONTHLY');
    const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
            query (
                $billingReportCompanyIds: [Int!]!
                $offset: Int!
                $limit: Int!
                $startDate: DateTime!
                $endDate: DateTime!
            ) {
                findCompanies(
                    filter: {id: {in: $billingReportCompanyIds}}
                    orderBy: {field: "id", order: ASC}
                    page: {offset: $offset, limit: $limit}
                ) {
                    items {
                        id
                        account_manager {
                            id
                            first_name
                            last_name
                        }
                        calendar {
                            id
                            name
                        }
                        canceled
                        created_at
                        canceled_at
                        company_logs(
                            filter: {
                                and: [{start_date: {ge: $startDate}}, {end_date: {le: $endDate}}]
                            }
                        ) {
                            id
                            billable_duration
                            start_date
                            end_date
                            billing_cycle_type
                        }
                        credit_card_key
                        current_minutes_package {
                            id
                            billing_cycle_type
                        }
                        industry
                        invoices(
                            filter: {
                                and: [{end_date: {gt: $startDate}}, {end_date: {le: $endDate}}]
                            }
                            orderBy: {field: "id", order: DESC}
                        ) {
                            id
                            billing_cycle_type
                            start_date
                            end_date
                            invoice_line_items {
                                id
                                amount
                            }
                            minutes_package {
                                id
                                name
                                num_minutes_included
                                price_per_minute
                            }
                            stripe_id
                            stripe_invoice {
                                totalPaid
                                totalCharged
                            }
                            manually_assigned_status
                        }
                        is_test_company
                        is_vip
                        name
                        niche
                        organization {
                            id
                            owner {
                                id
                                phone_number
                                email
                            }
                        }
                        pod {
                            id
                            name
                        }
                        referral_source
                        referral_tag
                    }
                }
            }
        `, {
        billingReportCompanyIds,
        offset,
        limit,
        startDate,
        endDate,
    });
    return gqlResult.data.findCompanies.items;
}
async function fetchBillingReportCompanyIds(params) {
    const { billingCycleType, dateInStartBillingCycle, dateInEndBillingCycle } = params;
    const result = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
            query (
                $billingCycleType: BILLING_REPORT_CYCLE_TYPE!
                $dateInStartBillingCycle: DateTime!
                $dateInEndBillingCycle: DateTime!
            ) {
                billingReportCompanyIds(
                    billingCycleType: $billingCycleType
                    dateInStartBillingCycle: $dateInStartBillingCycle
                    dateInEndBillingCycle: $dateInEndBillingCycle
                )
            }
        `, {
        billingCycleType,
        dateInStartBillingCycle,
        dateInEndBillingCycle,
    });
    return result.data.billingReportCompanyIds;
}
exports.fetchBillingReportCompanyIds = fetchBillingReportCompanyIds;
