"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateQuickStatsHtml = void 0;
const lit_1 = require("lit");
const utilities_1 = require("../../../services/utilities");
const helper_utils_1 = require("./helper-utils");
function calculateAverageBillableCallTimeInMilliseconds(calls) {
    /** Note that some of these calls may still have 0 billable minutes. */
    const billableCalls = calls.filter(call => {
        const wasAnsweredByAJill = call.duration_events.some(durationEvent => durationEvent.description === 'CALL_START');
        return wasAnsweredByAJill;
    });
    const sumOfAllBillableCallMinutes = billableCalls.reduce((sum, call) => sum + call.billable_duration, 0);
    return sumOfAllBillableCallMinutes / billableCalls.length;
}
function generateQuickStatsHtml(authenticatedUser, state) {
    const quickStatsList = [
        {
            title: 'Minutes In Package',
            stat: (0, helper_utils_1.getNumMinutesInPackageText)(state.minutesPackage, state.receptionistService),
        },
        {
            title: 'Minutes Used From Package',
            stat: (0, helper_utils_1.getNumMinutesUsedText)(state.companyLog),
        },
        {
            title: 'Minutes Remaining In Package',
            stat: (0, helper_utils_1.getNumMinutesRemainingText)(state.minutesPackage, state.receptionistService, state.companyLog),
        },
        {
            title: 'Additional Minutes Used',
            stat: (0, helper_utils_1.getAdditionalMinutesUsedText)(state.minutesPackage, state.receptionistService, state.companyLog),
        },
        {
            title: `Minutes Used From ${state.selectedCallDirection} Calls`,
            stat: (0, helper_utils_1.millisecondsToMinutes)(state.minutesUsedForCallType),
            hideFromCompanyUser: true,
        },
        {
            title: 'Total Calls Shown',
            stat: state.totalCallsForBillingCycle,
        },
        {
            title: 'Average Wait Time',
            stat: (0, utilities_1.millisecondsToHoursMinutesSeconds)(state.averageWaitTime),
            hideFromCompanyUser: true,
        },
        {
            title: 'Average Billable Call Time',
            stat: (0, helper_utils_1.millisecondsToMinutes)(calculateAverageBillableCallTimeInMilliseconds(state.calls)) +
                ' minutes',
            hideFromCompanyUser: true,
        },
    ];
    return quickStatsList.map(quickStat => {
        if (quickStat.hideFromCompanyUser === true &&
            !(0, utilities_1.isUserAMemberOfJillsOffice)(authenticatedUser)) {
            return '';
        }
        return (0, lit_1.html) `
            <h3>
                ${quickStat.title}:
                <div class="jo-company-call-log--quick-stats-number">${quickStat.stat}</div>
            </h3>
        `;
    });
}
exports.generateQuickStatsHtml = generateQuickStatsHtml;
