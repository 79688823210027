"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../services/utilities");
const css_utils_1 = require("../../utilities/css-utils");
const define_custom_element_1 = require("../../utilities/define-custom-element");
const test_id_directive_1 = require("../directives/test-id.directive");
require("./jo-button.element");
require("./jo-call-calculations-packages");
require("./jo-input");
require("./jo-minutes-packages");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    loaded: false,
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-billing-packages');
class JOBillingPackages extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .${cssName('main-container')} {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                }

                .${cssName('child-container')} {
                    padding-bottom: 3rem;
                }
            </style>

            <jo-loading .loading=${state.loaded === false}></jo-loading>

            <div class=${cssName('main-container')}>
                <div class="${cssName('child-container')}">
                    <jo-button
                        ${(0, test_id_directive_1.testId)('crew-new-billing-packages-button')}
                        .type=${'ACTION_BUTTON_1'}
                        .text=${'Create New Minutes Package'}
                        .href=${`/minutes-package/create`}
                    ></jo-button>
                </div>

                <div class="${cssName('child-container')}">
                    <jo-minutes-packages
                        @itemsfetched=${() => (this.store.loaded = true)}
                    ></jo-minutes-packages>
                </div>

                <div class="${cssName('child-container')}">
                    <jo-call-calculations-packages></jo-call-calculations-packages>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-billing-packages', JOBillingPackages);
