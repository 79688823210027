"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoAccountHelpTicketLog = void 0;
const element_vir_1 = require("element-vir");
const construct_usable_ticket_updates_1 = require("./helper-utils/construct-usable-ticket-updates");
const text_utils_1 = require("./helper-utils/text-utils");
const get_full_name_1 = require("../../../utilities/get-full-name");
const date_format_1 = require("../../../utilities/date/date-format");
exports.JoAccountHelpTicketLog = (0, element_vir_1.defineElement)()({
    tagName: 'jo-account-help-ticket-log',
    styles: (0, element_vir_1.css) `
        :host {
            overflow-y: auto;
            max-height: 75vh;
        }

        .item {
            padding: 1.5rem;
            border: 1px solid lightGray;
            margin: 0.5rem;
        }

        .item:hover {
            background-color: lightGray;
        }

        .info-item {
            padding-top: 0.5rem;
        }

        .italic {
            font-style: italic;
        }
    `,
    renderCallback({ inputs }) {
        const accountHelpTicket = inputs.accountHelpTicket;
        if (accountHelpTicket === undefined) {
            return (0, element_vir_1.html) ``;
        }
        const orderedUpdates = (0, construct_usable_ticket_updates_1.constructUsableTicketUpdatesForLog)(accountHelpTicket);
        return (0, element_vir_1.html) `
            <h2>Account Help Ticket History</h2>

            ${orderedUpdates.map(helpTicketUpdate => (0, element_vir_1.html) `
                    <div class="item">
                        <div class="info-item italic">
                            ${(0, date_format_1.formatAsDateAndTime)(helpTicketUpdate.created_at)}
                        </div>
                        <div class="info-item">Jill: ${(0, get_full_name_1.getFullName)(helpTicketUpdate.user)}</div>
                        <div class="info-item">Action: ${(0, text_utils_1.makeActionTextHTML)(helpTicketUpdate)}</div>
                        <div class="info-item">
                            ${helpTicketUpdate.original ? 'Description:' : 'Comment:'}
                            "${helpTicketUpdate.comment}"
                        </div>
                    </div>
                `)}
        `;
    },
});
