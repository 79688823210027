"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterActivityLogItems = exports.filterStatusEvents = exports.getNumTimesJillWentToLunchOutsideOfScheduledTime = exports.makeTotalAdminTimeForAllCompaniesHTML = exports.calculateTimeSpentOnCalls = exports.calculateTimeSpentLoggedOut = exports.getCallbacksThatEndedWithinXMilliseconds = exports.getMissedCalls = exports.wasCallbackEndedWithinXMilliseconds = exports.combineCallsAndStatusEvents = exports.getTimeAfterCallString = exports.getColumns = void 0;
const lit_html_1 = require("lit-html");
const utilities_1 = require("../../../services/utilities");
const css_utils_1 = require("../../../utilities/css-utils");
const dates_and_times_1 = require("../../../utilities/dates-and-times/dates-and-times");
const day_of_week_to_number_1 = require("../../../utilities/schedule-items/day-of-week-to-number");
const format_schedule_item_for_display_1 = require("../../../utilities/schedule-items/format-schedule-item-for-display");
const was_schedule_item_active_during_date_1 = require("../../../utilities/schedule-items/was-schedule-item-active-during-date");
const do_shifts_contain_break_within_milliseconds_of_date_1 = require("../../../utilities/shifts/do-shifts-contain-break-within-milliseconds-of-date");
const convert_snake_to_title_case_1 = require("../../../utilities/strings/casings.ts/convert-snake-to-title-case");
const get_html_to_show_who_ended_call_1 = require("./get-html-to-show-who-ended-call");
const calculate_time_spent_in_admin_mode_for_companies_1 = require("./utils/calculate-time-spent-in-admin-mode-for-companies");
const create_chat_duration_event_text_1 = require("./utils/create-chat-duration-event-text");
const is_chat_duration_event_1 = require("./utils/is-chat-duration-event");
const is_user_status_event_1 = require("./utils/is-user-status-event");
const was_likely_part_of_a_pushed_call_1 = require("./utils/was-likely-part-of-a-pushed-call");
const was_likely_part_of_an_ignored_call_1 = require("./utils/was-likely-part-of-an-ignored-call");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
const common_1 = require("@augment-vir/common");
const cssName = (0, css_utils_1.cssPrefixer)('jo-jill');
function getColumns(state) {
    const columns = [
        {
            title: 'ID',
            getCellData: (item) => {
                if ((0, is_user_status_event_1.isUserStatusEvent)(item)) {
                    return '';
                }
                if ((0, is_chat_duration_event_1.isChatDurationEvent)(item, 'ACTION_ITEM_SENT')) {
                    return item.id;
                }
                if (isCallback(item) && wasCallbackEndedWithinXMilliseconds(item, 10000)) {
                    return (0, lit_html_1.html) `
                        <span style="color: red; text-shadow: 0 0 25px red;">${item.id}</span>
                    `;
                }
                return item.id;
            },
            width: 0.2,
        },
        {
            title: 'Date',
            getCellData: (item, index) => {
                if ((0, is_user_status_event_1.isUserStatusEvent)(item)) {
                    if (shouldHideActionItemStatusEvent(item)) {
                        return ``;
                    }
                    if ((0, was_likely_part_of_a_pushed_call_1.wasLikelyPartOfAPushedCall)(state.callsAndStatusEvents, index) ||
                        (0, was_likely_part_of_an_ignored_call_1.wasLikelyPartOfAnIgnoredCall)(state.callsAndStatusEvents, index)) {
                        return (0, lit_html_1.html) `
                            <span style="color:red; text-shadow: 0 0 25px red;">
                                ${new Date(item.timestamp).toLocaleString()}
                            </span>
                        `;
                    }
                    else {
                        return (0, lit_html_1.html) `
                            <span>${new Date(item.timestamp).toLocaleString()}</span>
                        `;
                    }
                }
                if ((0, is_chat_duration_event_1.isChatDurationEvent)(item, 'ACTION_ITEM_SENT')) {
                    return new Date(item.timestamp).toLocaleString();
                }
                if (isCallback(item) && wasCallbackEndedWithinXMilliseconds(item, 10000)) {
                    return (0, lit_html_1.html) `
                        <span style="color: red; text-shadow: 0 0 25px red;">
                            ${new Date(getTimestampForCall(item)).toLocaleString()}
                        </span>
                    `;
                }
                return new Date(getTimestampForCall(item)).toLocaleString();
            },
            width: 0.4,
        },
        {
            title: 'Company',
            getCellData: (item, index) => {
                if ((0, is_user_status_event_1.isUserStatusEvent)(item)) {
                    return makeUserStatusEventCellHtml(state, item, index);
                }
                if ((0, is_chat_duration_event_1.isChatDurationEvent)(item, 'ACTION_ITEM_SENT')) {
                    return item.chat.company?.name;
                }
                return item.company?.name;
            },
            width: 0.6,
        },
        {
            title: 'Phone Number',
            getCellData: (item, index) => {
                if ((0, is_chat_duration_event_1.isChatDurationEvent)(item, 'ACTION_ITEM_SENT')) {
                    return '';
                }
                if ((0, is_user_status_event_1.isUserStatusEvent)(item)) {
                    return makeHtmlForExtraUserEventInfoCell(state, item, index);
                }
                const call = item;
                return (0, utilities_1.formatE164PhoneNumberForDisplay)(call.new_direction === 'INCOMING' ? call.origin_phone : call.destination_phone);
            },
            width: 0.5,
        },
        {
            title: 'Direction',
            getCellData: (item) => {
                if ((0, is_chat_duration_event_1.isChatDurationEvent)(item, 'ACTION_ITEM_SENT')) {
                    return 'Chat';
                }
                if ((0, is_user_status_event_1.isUserStatusEvent)(item)) {
                    return '';
                }
                const call = item;
                if (isCallback(call)) {
                    return (0, get_html_to_show_who_ended_call_1.getHtmlToShowWhoEndedCall)('Outgoing (Callback)', call.twilio_reason_conference_ended);
                }
                return (0, get_html_to_show_who_ended_call_1.getHtmlToShowWhoEndedCall)(camelCase(call.new_direction), call.twilio_reason_conference_ended);
            },
            width: 0.5,
        },
        {
            title: 'Billable Duration',
            getCellData: (item) => {
                if ((0, is_user_status_event_1.isUserStatusEvent)(item) || (0, is_chat_duration_event_1.isChatDurationEvent)(item, 'ACTION_ITEM_SENT')) {
                    return '';
                }
                return getBillableDuration(item);
            },
            width: 0.5,
        },
        {
            title: 'Handle Duration',
            getCellData: (item) => {
                if ((0, is_chat_duration_event_1.isChatDurationEvent)(item, 'ACTION_ITEM_SENT')) {
                    return (0, utilities_1.millisecondsToHoursMinutesSeconds)(diffTime(item.chat.created_at, item.timestamp));
                }
                if ((0, is_user_status_event_1.isUserStatusEvent)(item)) {
                    return '';
                }
                return getActualDurationTextForCall(item);
            },
            width: 0.5,
        },
        {
            title: 'Action Item Time',
            getCellData: (item) => {
                if ((0, is_user_status_event_1.isUserStatusEvent)(item) || (0, is_chat_duration_event_1.isChatDurationEvent)(item, 'ACTION_ITEM_SENT')) {
                    return '';
                }
                return (0, utilities_1.millisecondsToHoursMinutesSeconds)((0, utilities_1.getActionItemTimeForCall)(item));
            },
            width: 0.5,
        },
        {
            title: 'Idle Time After Call',
            getCellData: (item) => {
                if ((0, is_user_status_event_1.isUserStatusEvent)(item) || (0, is_chat_duration_event_1.isChatDurationEvent)(item, 'ACTION_ITEM_SENT')) {
                    return '';
                }
                return getTimeAfterCallString(item, state.calls);
            },
            width: 0.4,
        },
        {
            title: 'Twilio Console',
            getCellData: (item) => {
                if ((0, is_user_status_event_1.isUserStatusEvent)(item) || (0, is_chat_duration_event_1.isChatDurationEvent)(item, 'ACTION_ITEM_SENT')) {
                    return '';
                }
                return (0, lit_html_1.html) `
                    <a
                        href="https://www.twilio.com/console/voice/calls/logs/${item.twilio_parent_call_sid}"
                        target="_blank"
                    >
                        Click here
                    </a>
                `;
            },
            width: 0.4,
        },
        {
            title: 'Recording',
            getCellData: (item) => {
                if ((0, is_chat_duration_event_1.isChatDurationEvent)(item, 'ACTION_ITEM_SENT')) {
                    return createChatTranscriptButton(state.authenticatedUser, item.chat.contact, item.chat);
                }
                if ((0, is_user_status_event_1.isUserStatusEvent)(item)) {
                    return '';
                }
                return item.recording_url ? createAudioButton(item) : '';
            },
            width: 0.2,
        },
        {
            title: 'Action Item',
            getCellData: (item) => {
                if (shouldHideActionItemLink(item)) {
                    return '';
                }
                if ((0, is_chat_duration_event_1.isChatDurationEvent)(item, 'ACTION_ITEM_SENT')) {
                    const actionItemId = getChatActionItemId(item);
                    if (actionItemId === -1) {
                        return '';
                    }
                    return createActionItemLink(actionItemId, item.chat.company.id);
                }
                const call = item;
                if (!call.company || !call.action_item)
                    return 'Not found';
                return createActionItemLink(call.action_item.id, call.company.id);
            },
            width: 0.4,
        },
        {
            title: 'Duration Events',
            getCellData(item) {
                if ((0, is_chat_duration_event_1.isChatDurationEvent)(item, 'ACTION_ITEM_SENT')) {
                    return createDurationEventsForChatItem(item.chat.id, [
                        ...state.chatDurationEvents,
                    ]);
                }
                if ((0, is_user_status_event_1.isUserStatusEvent)(item)) {
                    return '';
                }
                return makeDurationEventsCellHtml(item);
            },
            width: 0.4,
        },
    ];
    return columns;
}
exports.getColumns = getColumns;
function getChatActionItemId(item) {
    if (item.chat.action_items.length === 0) {
        return -1;
    }
    const actionItemId = item.chat.action_items[item.chat.action_items.length - 1]?.id || undefined;
    if (!actionItemId) {
        return -1;
    }
    else {
        return actionItemId;
    }
}
function createActionItemLink(actionItemId, companyId) {
    return (0, lit_html_1.html) `
        <a href="${getActionItemUrl(actionItemId, companyId)}" target="_blank">
            <i class="material-icons jo-global--action-icon">preview</i>
        </a>
    `;
}
function createAudioButton(item) {
    return (0, lit_html_1.html) `
        <div>
            <a class="jo-jill--playIcon" @click=${() => (0, utilities_1.joAudioPopUp)(item.recording_url)}>
                <span class="material-icons-outlined">play_circle_outline</span>
            </a>
        </div>
    `;
}
function createChatTranscriptButton(authUser, contact, chat) {
    return (0, lit_html_1.html) `
        <jo-chat-transcript
            .props=${{
        authenticatedUser: authUser,
        actionItemContact: contact,
        openButtonText: 'forum',
        openButtonType: 'ICON',
        chat,
    }}
        ></jo-chat-transcript>
    `;
}
function makeHtmlForExtraUserEventInfoCell(state, statusEvent, index) {
    if (state.jill !== 'NOT_SET' && (0, is_user_status_event_1.isUserStatusEvent)(statusEvent, 'WENT_ON_LUNCH')) {
        const tenMinutesInMilliseconds = 10 * 60 * 1000;
        const shiftsContainBreakWithinMillisecondsOfDate = (0, do_shifts_contain_break_within_milliseconds_of_date_1.doShiftsContainBreakWithinMillisecondsOfDate)({
            shifts: state.shifts,
            milliseconds: tenMinutesInMilliseconds,
            date: new Date(statusEvent.timestamp),
            searchType: 'LUNCH_BREAKS',
        });
        if (shiftsContainBreakWithinMillisecondsOfDate === false) {
            return makeHTMLForDidNotGoToLunchOnTimeText(state.shifts, statusEvent);
        }
    }
    if ((0, was_likely_part_of_an_ignored_call_1.wasLikelyPartOfAnIgnoredCall)(state.callsAndStatusEvents, index)) {
        return (0, lit_html_1.html) `
            <span style="color: red; text-shadow: 0 0 25px red;">Jill likely ignored call</span>
        `;
    }
    if ((0, was_likely_part_of_a_pushed_call_1.wasLikelyPartOfAPushedCall)(state.callsAndStatusEvents, index)) {
        return (0, lit_html_1.html) `
            <span style="color: red; text-shadow: 0 0 25px red;">Jill likely pushed call</span>
        `;
    }
    return (0, lit_html_1.html) ``;
}
function makeHTMLForDidNotGoToLunchOnTimeText(shifts, statusEvent) {
    const activeBreaksScheduledThatDayText = makeTextForActiveBreaksOnDayOfTimestamp(shifts, 'lunch_breaks', new Date(statusEvent.timestamp));
    return (0, lit_html_1.html) `
        <span
            style="color: red; text-shadow: 0 0 25px red;"
            class="jo-global--cursor-help"
            .title=${activeBreaksScheduledThatDayText}
        >
            Did not go to lunch at scheduled time
        </span>
    `;
}
function makeTextForActiveBreaksOnDayOfTimestamp(shifts, breakType, statusEventTimestamp) {
    const activeBreaksForDayAtTimeOfTimestamps = getActiveBreaksAtTimeOfTimestamp(shifts, breakType, statusEventTimestamp);
    const headerText = breakType.split('_').map(camelCase).join(' ') + ':\n\n';
    const activeBreaksScheduledThatDayText = headerText +
        activeBreaksForDayAtTimeOfTimestamps
            .map(scheduledBreak => {
            return (0, format_schedule_item_for_display_1.formatScheduleItemForDisplay)(scheduledBreak);
        })
            .join('\n');
    return activeBreaksScheduledThatDayText;
}
function getActiveBreaksAtTimeOfTimestamp(shifts, breakType, statusEventTimestamp) {
    return shifts
        .map(shift => shift[breakType])
        .flat()
        .filter(scheduledBreak => {
        return ((0, was_schedule_item_active_during_date_1.wasScheduleItemActiveDuringDate)(statusEventTimestamp, scheduledBreak) &&
            (0, day_of_week_to_number_1.dayOfWeekToNumber)(scheduledBreak.start_day_of_week) ===
                statusEventTimestamp.getDay());
    });
}
function makeUserStatusEventCellHtml(state, statusEvent, index) {
    if ((0, was_likely_part_of_an_ignored_call_1.wasLikelyPartOfAnIgnoredCall)(state.callsAndStatusEvents, index) ||
        (0, was_likely_part_of_a_pushed_call_1.wasLikelyPartOfAPushedCall)(state.callsAndStatusEvents, index)) {
        return (0, lit_html_1.html) `
            <span style="text-shadow: 0 0 25px red;">${makeStatusEventTextHTML(statusEvent)}</span>
        `;
    }
    return (0, lit_html_1.html) `
        <span>${makeStatusEventTextHTML(statusEvent)}</span>
    `;
}
function getActualDurationTextForCall(call) {
    if (isCallback(call)) {
        return (0, utilities_1.millisecondsToHoursMinutesSeconds)(getActualDurationInMillisecondsForCallback(call));
    }
    return (0, utilities_1.millisecondsToHoursMinutesSeconds)(call.duration || 0);
}
function getActualDurationInMillisecondsForCallback(call) {
    const actionItemSavedEvent = call.duration_events.find(d => d.description === 'ACTION_ITEM_SAVED');
    if (!actionItemSavedEvent)
        return 0;
    const callbackInitiatedEvent = call.duration_events.find(d => d.description === 'CALLBACK_INITIATED');
    if (!callbackInitiatedEvent)
        return 0;
    const milliseconds = calculateMillisecondsBetweenTimestamps(actionItemSavedEvent.timestamp, callbackInitiatedEvent.timestamp);
    return milliseconds;
}
function makeDurationEventsCellHtml(call) {
    const durationEventsText = makeDurationEventsText(call);
    const callbackEndedTooEarly = wasCallbackEndedWithinXMilliseconds(call, 10000);
    if (callbackEndedTooEarly) {
        return (0, lit_html_1.html) `
            <div
                class="jo-global--cursor-help"
                style="text-shadow: 0 0 25px red; color: red"
                title="${durationEventsText}"
            >
                Callback Less Than 10 Seconds (Hover)
            </div>
        `;
    }
    return (0, lit_html_1.html) `
        <div
            class="jo-global--cursor-help"
            style="${callbackEndedTooEarly ? 'text-shadow: 0 0 25px red; color: red;' : ''}"
            title="${durationEventsText}"
        >
            (Hover)
        </div>
    `;
}
function makeDurationEventsText(call) {
    return [...call.duration_events]
        .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
        .map(d => `${new Date(d.timestamp).toLocaleString()}: ${d.description}`)
        .join('\n\n');
}
function calculateMillisecondsBetweenTimestamps(timestampA, timestampB) {
    return Math.abs(new Date(timestampA).getTime() - new Date(timestampB).getTime());
}
function camelCase(word) {
    return word[0]?.toUpperCase() + word.slice(1).toLowerCase();
}
function isCallback(call) {
    return call.duration_events.some(d => d.description === 'CALLBACK_INITIATED');
}
function getTimestampForCall(call) {
    const callStartEvent = call.duration_events.find(d => d.description === 'CALL_START');
    if (callStartEvent !== undefined)
        return callStartEvent.timestamp;
    const anyDurationEvent = call.duration_events[0];
    if (anyDurationEvent !== undefined)
        return anyDurationEvent.timestamp;
    return call.created_at;
}
function getActionItemUrl(actionItemId, companyId) {
    return `${mapped_env_variables_1.currentMappedEnvVariables.urlOrigin}/action-item?actionItemId=${actionItemId}&companyId=${companyId}&callScreenMode=ACTION_ITEM`;
}
function getTimeAfterCallString(call, calls) {
    const timeAfterCall = (0, utilities_1.getIdleTimeAfterCall)(call, calls);
    if (timeAfterCall === 'N/A')
        return 'N/A';
    return (0, utilities_1.millisecondsToHoursMinutesSeconds)(timeAfterCall);
}
exports.getTimeAfterCallString = getTimeAfterCallString;
function combineCallsAndStatusEvents(calls, statusEvents, chatDurationEvents) {
    return [
        ...calls,
        ...statusEvents,
        ...chatDurationEvents,
    ].sort((a, b) => {
        return getTimeDiff(a, b);
    });
}
exports.combineCallsAndStatusEvents = combineCallsAndStatusEvents;
function getTimeDiff(a, b) {
    return new Date(getTimestamp(a)).getTime() - new Date(getTimestamp(b)).getTime();
}
function getTimestamp(item) {
    if (item.hasOwnProperty('chat')) {
        return item.timestamp;
    }
    if (item.hasOwnProperty('timestamp')) {
        return item.timestamp;
    }
    const call = item;
    return getTimestampForCall(call);
}
function getBillableDuration(call) {
    if (call.was_personal_admin_call) {
        return `${call.billable_duration} (Admin Call)`;
    }
    return (0, utilities_1.millisecondsToHoursMinutesSeconds)(call.billable_duration || 0);
}
function wasCallbackEndedWithinXMilliseconds(call, milliseconds) {
    const callbackInitiatedEvent = call.duration_events.find(d => d.description === 'CALLBACK_INITIATED');
    const callEndEvent = call.duration_events.find(d => d.description === 'CALL_END');
    if (!callbackInitiatedEvent || !callEndEvent) {
        return false;
    }
    const timeDiff = calculateMillisecondsBetweenTimestamps(callEndEvent.timestamp, callbackInitiatedEvent.timestamp);
    return timeDiff <= milliseconds;
}
exports.wasCallbackEndedWithinXMilliseconds = wasCallbackEndedWithinXMilliseconds;
function getMissedCalls(callsAndStatusEvents) {
    const callSentToUserEventsWithoutMatchingCall = callsAndStatusEvents.filter(isCallSentToUserEventWithoutSubsequentCallInLog);
    return callSentToUserEventsWithoutMatchingCall
        .filter(x => isCallDefinedOnUserStatusEvent(x))
        .map(x => x.call);
}
exports.getMissedCalls = getMissedCalls;
function isCallSentToUserEventWithoutSubsequentCallInLog(callOrStatusEvent, index, arr) {
    if (!(0, is_user_status_event_1.isUserStatusEvent)(callOrStatusEvent)) {
        return false;
    }
    const statusEvent = callOrStatusEvent;
    if (statusEvent.type !== 'CALL_SENT_TO_USER') {
        return false;
    }
    const nextItem = arr[index + 1];
    if (nextItem === undefined) {
        return false;
    }
    if ((0, is_user_status_event_1.isUserStatusEvent)(nextItem)) {
        return true;
    }
    return false;
}
function isCallDefinedOnUserStatusEvent(x) {
    const statusEvent = x;
    return statusEvent.call !== null && statusEvent.call !== undefined;
}
function getCallbacksThatEndedWithinXMilliseconds(calls) {
    return calls.filter(call => {
        return wasCallbackEndedWithinXMilliseconds(call, 10000);
    });
}
exports.getCallbacksThatEndedWithinXMilliseconds = getCallbacksThatEndedWithinXMilliseconds;
function diffTime(timestampA, timestampB) {
    return Math.abs(new Date(timestampA).getTime() - new Date(timestampB).getTime());
}
function calculateTimeSpentLoggedOut(callsAndStatusEvents) {
    let timeSpentLoggedOut = 0;
    let lastLoggedOutEvent = null;
    for (const item of callsAndStatusEvents) {
        if (!(0, is_user_status_event_1.isUserStatusEvent)(item))
            continue;
        if (item.type === 'LOGGED_OUT') {
            lastLoggedOutEvent = item;
            continue;
        }
        if (item.type === 'LOGGED_IN') {
            if (lastLoggedOutEvent !== null) {
                const timeSpentLoggedOutSinceLastEvent = diffTime(item.timestamp, lastLoggedOutEvent.timestamp);
                timeSpentLoggedOut += timeSpentLoggedOutSinceLastEvent;
            }
            lastLoggedOutEvent = null;
        }
    }
    return timeSpentLoggedOut;
}
exports.calculateTimeSpentLoggedOut = calculateTimeSpentLoggedOut;
function calculateTimeSpentOnCalls(calls) {
    return calls.reduce((sum, call) => sum + (call.duration ?? 0), 0);
    // TODO find out if they want to include time of callbacks
    return calls.reduce((sum, call) => sum + getDurationOfCall(call), 0);
}
exports.calculateTimeSpentOnCalls = calculateTimeSpentOnCalls;
function getDurationOfCall(call) {
    const callbackInitiatedEvent = call.duration_events.find(d => d.description === 'CALLBACK_INITIATED');
    if (callbackInitiatedEvent === undefined) {
        return call.duration ?? 0;
    }
    return calculateDurationOfCallback(call, callbackInitiatedEvent);
}
function calculateDurationOfCallback(call, callbackInitiatedEvent) {
    const actionItemSavedEvent = call.duration_events.find(d => d.description === 'ACTION_ITEM_SAVED');
    if (actionItemSavedEvent === undefined) {
        return 0;
    }
    const timeOnCall = diffTime(callbackInitiatedEvent.timestamp, actionItemSavedEvent.timestamp);
    return timeOnCall;
}
function makeStatusEventTextHTML(userStatusEvent) {
    if (!userStatusEvent) {
        return '';
    }
    switch (userStatusEvent.type) {
        case 'LOGGED_IN':
        case 'MANUALLY_WENT_AVAILABLE':
        case 'AUTOMATICALLY_SET_AVAILABLE':
            return convertUserStatusEventToColoredString(userStatusEvent.type, 'green');
        case 'LOGGED_OUT':
        case 'MANUALLY_WENT_UNAVAILABLE':
        case 'AUTOMATICALLY_SET_UNAVAILABLE':
            return convertUserStatusEventToColoredString(userStatusEvent.type, 'red');
        case 'RESOLVED_ACTION_ITEM':
            return convertUserStatusEventToColoredString(userStatusEvent.type, 'var(--jo-primary)');
        case 'CALL_SENT_TO_USER':
            return (0, lit_html_1.html) `
                ${addColor(`Call ${userStatusEvent.call?.id} sent to jill`, 'var(--jo-primary)')}
            `;
        case 'WENT_ON_BREAK':
        case 'RETURNED_FROM_BREAK':
            return (0, lit_html_1.html) `
                ${addColor(`${(0, convert_snake_to_title_case_1.convertSnakeToTitleCase)(userStatusEvent.type)} 🕔`, '#38c7d1')}
            `;
        case 'WENT_ON_LUNCH':
        case 'RETURNED_FROM_LUNCH':
            return (0, lit_html_1.html) `
                ${addColor(`${(0, convert_snake_to_title_case_1.convertSnakeToTitleCase)(userStatusEvent.type)} 🍟`, '#38c7d1')}
            `;
        case 'BEGAN_TEAM_HUDDLE':
        case 'ENDED_TEAM_HUDDLE':
            return (0, lit_html_1.html) `
                ${addColor(`${(0, convert_snake_to_title_case_1.convertSnakeToTitleCase)(userStatusEvent.type)} 📅`, '#38c7d1')}
            `;
        case 'BEGAN_OFF_CALL_TRAINING':
        case 'ENDED_OFF_CALL_TRAINING':
            return (0, lit_html_1.html) `
                ${addColor(`${(0, convert_snake_to_title_case_1.convertSnakeToTitleCase)(userStatusEvent.type)} 🎓`, '#38c7d1')}
            `;
        case 'CLOCKED_IN':
            return (0, lit_html_1.html) `
                ${addColor('Clocked in ⏲', 'green')}
            `;
        case 'CLOCKED_OUT':
            return (0, lit_html_1.html) `
                ${addColor('Clocked out ⏲', 'red')}
            `;
        case 'INCOMING_CALL_RECEIVED_BY_USER':
            return (0, lit_html_1.html) `
                ${addColor('Call received by jill', 'var(--jo-primary)')}
            `;
        case 'INCOMING_CALL_LOADED_ON_CALL_SCREEN':
            return (0, lit_html_1.html) `
                ${addColor('Call loaded on call screen', 'var(--jo-primary)')}
            `;
        case 'INCOMING_CALL_CANCELED_BEFORE_USER_ANSWERED':
            return (0, lit_html_1.html) `
                ${addColor(`Incoming call canceled before jill could answer`, 'hotpink')}
            `;
        case 'BEGAN_ADMIN_MODE':
        case 'ENDED_ADMIN_MODE':
            return (0, lit_html_1.html) `
                ${addColor((0, lit_html_1.html) `
                        ${(0, convert_snake_to_title_case_1.convertSnakeToTitleCase)(userStatusEvent.type)} for
                        ${userStatusEvent.company?.name ?? 'Unknown Company'} 👩
                    `, 'indigo')}
            `;
        case 'CREATED_ACTION_ITEM':
            if (shouldHideActionItemStatusEvent(userStatusEvent)) {
                return (0, lit_html_1.html) ``;
            }
            return (0, lit_html_1.html) `
                ${addColor('Created Action Item', 'var(--jo-primary)')}
            `;
        case 'IN_CHAT_FLEXIBLE_MODE':
            return (0, lit_html_1.html) `
                Started Chat Flexible Mode
            `;
        case 'IN_CHAT_STRICT_MODE':
            return (0, lit_html_1.html) `
                Started Chat Strict Mode
            `;
        default:
            return (0, convert_snake_to_title_case_1.convertSnakeToTitleCase)(userStatusEvent.type);
    }
}
function convertUserStatusEventToColoredString(name, color) {
    return (0, lit_html_1.html) `
        ${addColor(`${(0, convert_snake_to_title_case_1.convertSnakeToTitleCase)(name)}`, color)}
    `;
}
function addColor(text, color) {
    return (0, lit_html_1.html) `
        <span style="color: ${color}">${text}</span>
    `;
}
function makeTotalAdminTimeForAllCompaniesHTML(callsAndStatusEvents) {
    const results = (0, calculate_time_spent_in_admin_mode_for_companies_1.calculateTimeSpentInAdminModeForCompanies)(callsAndStatusEvents);
    return (0, lit_html_1.html) `
        <div class="jo-global--home-card-stat-line" style="margin-bottom: 0">
            Admin Mode Time:
            <u>${(0, utilities_1.millisecondsToHoursMinutesSeconds)(sumTotalTimeSpentInAdminMode(results))}</u>
        </div>
        <strong>
            <ul style="margin-top: 0">
                ${(0, common_1.mapObjectValues)(results, (_companyId, company) => {
        return (0, lit_html_1.html) `
                        <li class="jo-global--home-card-stat-line">
                            ${company.name}:
                            <u>${(0, utilities_1.millisecondsToHoursMinutesSeconds)(company.totalTime)}</u>
                        </li>
                    `;
    })}
            </ul>
        </strong>
    `;
}
exports.makeTotalAdminTimeForAllCompaniesHTML = makeTotalAdminTimeForAllCompaniesHTML;
function sumTotalTimeSpentInAdminMode(results) {
    return Object.values(results).reduce((total, curr) => {
        return total + curr.totalTime;
    }, 0);
}
function getNumTimesJillWentToLunchOutsideOfScheduledTime(state) {
    return state.statusEvents.reduce((total, statusEvent) => {
        if ((0, is_user_status_event_1.isUserStatusEvent)(statusEvent, 'WENT_ON_LUNCH') &&
            (0, do_shifts_contain_break_within_milliseconds_of_date_1.doShiftsContainBreakWithinMillisecondsOfDate)({
                shifts: state.shifts,
                milliseconds: 10 * dates_and_times_1.oneMinuteInMilliseconds,
                date: new Date(statusEvent.timestamp),
                searchType: 'LUNCH_BREAKS',
            })) {
            return total + 1;
        }
        return total;
    }, 0);
}
exports.getNumTimesJillWentToLunchOutsideOfScheduledTime = getNumTimesJillWentToLunchOutsideOfScheduledTime;
function shouldHideActionItemStatusEvent(item) {
    return item.type === 'CREATED_ACTION_ITEM' && (0, utilities_1.isInAuthRole)(['JILL'], item.user);
}
function shouldHideActionItemLink(item) {
    if ((0, is_user_status_event_1.isUserStatusEvent)(item)) {
        if ((item.type !== 'CREATED_ACTION_ITEM' && item.type !== 'RESOLVED_ACTION_ITEM') ||
            shouldHideActionItemStatusEvent(item)) {
            return true;
        }
    }
    return false;
}
function filterStatusEvents(statusEvents) {
    return statusEvents.filter(item => {
        if (!shouldHideActionItemStatusEvent(item)) {
            return item;
        }
    });
}
exports.filterStatusEvents = filterStatusEvents;
function createDurationEventsForChatItem(chatId, chatDurationEvents) {
    const durationEventText = (0, create_chat_duration_event_text_1.createChatDurationEventText)(chatDurationEvents, chatId);
    return (0, lit_html_1.html) `
        <div title="${durationEventText}">(Hover)</div>
    `;
}
function filterActivityLogItems(jillEvents) {
    return jillEvents.filter(event => {
        if ((0, is_chat_duration_event_1.isChatDurationEvent)(event)) {
            const chatEvent = event;
            if (chatEvent.type === 'ACTION_ITEM_SENT') {
                return event;
            }
        }
        else {
            return event;
        }
    });
}
exports.filterActivityLogItems = filterActivityLogItems;
