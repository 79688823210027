"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchPriorityLevels = exports.deletePriorityLevel = exports.updatePriorityLevel = exports.createNewPriorityLevel = void 0;
const common_1 = require("@augment-vir/common");
const graphql_1 = require("../../../../services/graphql");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
const handle_gql_result_failure_1 = require("../../../../utilities/error-handling/handle-gql-result-failure");
async function createNewPriorityLevel(newPriorityLevel) {
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($newPriorityLevel: CreateQueue_priority_levelsInput!) {
                createQueue_priority_levels(input: $newPriorityLevel) {
                    id
                }
            }
        `, {
        newPriorityLevel,
    });
    if (gqlResult.succeeded === false) {
        (0, handle_gql_result_failure_1.handleGqlResultFailure)(gqlResult);
    }
}
exports.createNewPriorityLevel = createNewPriorityLevel;
async function updatePriorityLevel(changedPriorityLevel) {
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($changedPriorityLevel: UpdateQueue_priority_levelsInput!) {
                updateQueue_priority_levels(input: $changedPriorityLevel) {
                    id
                }
            }
        `, {
        changedPriorityLevel,
    });
    if (gqlResult.succeeded === false) {
        (0, handle_gql_result_failure_1.handleGqlResultFailure)(gqlResult);
    }
}
exports.updatePriorityLevel = updatePriorityLevel;
async function deletePriorityLevel(id) {
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($id: Int!) {
                deleteQueue_priority_levels(input: {id: $id}) {
                    id
                }
            }
        `, {
        id,
    });
    if (gqlResult.succeeded === false) {
        (0, handle_gql_result_failure_1.handleGqlResultFailure)(gqlResult);
    }
}
exports.deletePriorityLevel = deletePriorityLevel;
async function fetchPriorityLevels(queueType) {
    const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
            query ($queueType: String) {
                findQueue_priority_levels(
                    filter: {queue_type: {eq: $queueType}}
                    orderBy: {field: "total_queue_items", order: ASC}
                ) {
                    items {
                        id
                        background_color
                        flash_level
                        highest_pod_count
                        text_color
                        total_queue_items
                        queue_type
                    }
                }
            }
        `, {
        queueType,
    });
    if (gqlResult.succeeded === false) {
        (0, handle_gql_result_failure_1.handleGqlResultFailure)(gqlResult);
        return [];
    }
    const result = gqlResult.value.data.findQueue_priority_levels.items;
    const final = result.filter(common_1.isTruthy);
    return final;
}
exports.fetchPriorityLevels = fetchPriorityLevels;
