"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoSearchItemsInput = void 0;
const element_vir_1 = require("element-vir");
const utilities_1 = require("../../services/utilities");
const debounceTime = 200;
exports.JoSearchItemsInput = (0, element_vir_1.defineElement)()({
    tagName: 'jo-search-items-input',
    stateInit: {
        searchText: '',
        items: [],
        debouncer: (0, utilities_1.createDebouncer)(),
    },
    events: {
        itemSelected: (0, element_vir_1.defineElementEvent)(),
    },
    styles: (0, element_vir_1.css) `
        .items-container {
            position: absolute;
            z-index: 3;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
            max-height: 400px;
            overflow-y: auto;
        }

        .item {
            border: 1px solid black;
            background-color: white;
            padding: 1rem;
        }

        .item:hover {
            background-color: var(--jo-selected-background);
            cursor: pointer;
        }
    `,
    renderCallback({ inputs, state, updateState, dispatch, events }) {
        async function searchAndSetItems() {
            if (state.searchText !== '') {
                updateState({
                    items: await inputs.searchItemsCallback(state.searchText, inputs.limit),
                });
            }
        }
        async function searchTextChanged(searchText) {
            updateState({
                searchText,
            });
            state.debouncer(debounceTime, async () => {
                await searchAndSetItems();
            });
        }
        function userClickedAway() {
            setTimeout(() => {
                updateState({
                    items: [],
                });
            }, 200);
        }
        return (0, element_vir_1.html) `
            <jo-input
                .type=${'TEXT_INPUT'}
                .label=${inputs.label}
                @valueChanged=${async (e) => {
            await searchTextChanged(e.detail.inputValue);
        }}
                @joInputBlur=${() => {
            userClickedAway();
        }}
            ></jo-input>

            <div class="items-container">
                ${state.items.map(item => (0, element_vir_1.html) `
                        <div
                            class="item"
                            @click=${() => {
            dispatch(new events.itemSelected(item));
        }}
                        >
                            ${inputs.getItemDisplayTextCallback(item)}
                        </div>
                    `)}
            </div>
        `;
    },
});
