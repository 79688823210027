"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateLastSeenInChatMode = exports.isUserInChatMode = void 0;
const graphql_1 = require("../services/graphql");
const store_1 = require("../services/store");
const mapped_env_variables_1 = require("./environment/mapped-env-variables");
function isUserInChatMode() {
    if (store_1.GlobalStore.authenticatedUser === 'NOT_SET') {
        return false;
    }
    switch (store_1.GlobalStore.authenticatedUser.chat_mode) {
        case 'NONE':
            return false;
        default:
            return true;
    }
}
exports.isUserInChatMode = isUserInChatMode;
async function updateLastSeenInChatMode(userId, date) {
    try {
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($userId: Int!, $date: DateTime!) {
                    updateUsers(input: {id: $userId, last_seen_in_chat_mode: $date}) {
                        id
                    }
                }
            `, {
            userId: userId,
            date: date.toISOString(),
        });
    }
    catch (error) {
        throw new Error(`updateLastSeenInChatMode error: ${error}`);
    }
}
exports.updateLastSeenInChatMode = updateLastSeenInChatMode;
