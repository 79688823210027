"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createMultipleOutboundQueueItemsForCompany = void 0;
const graphql_1 = require("../../../../services/graphql");
const mapped_env_variables_1 = require("../../../../utilities/environment/mapped-env-variables");
async function createMultipleOutboundQueueItemsForCompany(params) {
    try {
        await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation (
                    $companyId: Int!
                    $numCallAttempts: Int!
                    $numDaysBetweenAttempts: Int!
                    $contactIds: [Int!]!
                    $campaignName: String!
                ) {
                    createMultipleOutboundQueueItemsForCompany(
                        campaignName: $campaignName
                        companyId: $companyId
                        numCallAttempts: $numCallAttempts
                        numDaysBetweenAttempts: $numDaysBetweenAttempts
                        contactIds: $contactIds
                    )
                }
            `, {
            ...params,
        });
    }
    catch (error) {
        throw new Error('createMultipleOutboundQueueItemsForCompany Error: ' + error);
    }
}
exports.createMultipleOutboundQueueItemsForCompany = createMultipleOutboundQueueItemsForCompany;
