"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.doShiftsContainBreakWithinMillisecondsOfDate = void 0;
const is_date_within_x_milliseconds_of_end_date_1 = require("../schedule-items/is-date-within-x-milliseconds-of-end-date");
const is_date_within_x_milliseconds_of_schedule_item_1 = require("../schedule-items/is-date-within-x-milliseconds-of-schedule-item");
const is_date_within_x_milliseconds_of_start_date_1 = require("../schedule-items/is-date-within-x-milliseconds-of-start-date");
const was_schedule_item_active_during_date_1 = require("../schedule-items/was-schedule-item-active-during-date");
function doShiftsContainBreakWithinMillisecondsOfDate(params) {
    const { shifts, milliseconds, date, searchType, eventType } = params;
    const result = shifts.some(shift => doesShiftContainBrakThatFitsDescription(searchType, shift, date, milliseconds, eventType));
    return result;
}
exports.doShiftsContainBreakWithinMillisecondsOfDate = doShiftsContainBreakWithinMillisecondsOfDate;
function doesShiftContainBrakThatFitsDescription(searchType, shift, date, milliseconds, eventType) {
    const scheduleItems = searchType === 'SCHEDULED_BREAKS' ? shift.scheduled_breaks : shift.lunch_breaks;
    return scheduleItems.some((scheduleItem) => doesScheduleItemFitDescription(scheduleItem, date, milliseconds, eventType));
}
function doesScheduleItemFitDescription(scheduleItem, date, milliseconds, eventType) {
    if ((0, was_schedule_item_active_during_date_1.wasScheduleItemActiveDuringDate)(date, scheduleItem) === false) {
        return false;
    }
    if (eventType === 'BREAK_START') {
        return (0, is_date_within_x_milliseconds_of_start_date_1.isDateWithinXMillisecondsOfStartDate)(date, scheduleItem, milliseconds);
    }
    if (eventType === 'BREAK_END') {
        return (0, is_date_within_x_milliseconds_of_end_date_1.isDateWithinXMillisecondsOfEndDate)(date, scheduleItem, milliseconds);
    }
    return (0, is_date_within_x_milliseconds_of_schedule_item_1.isDateWithinXMillisecondsOfScheduleItem)(date, scheduleItem, milliseconds);
}
