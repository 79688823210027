"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const utilities_1 = require("../../../services/utilities");
const define_custom_element_1 = require("../../../utilities/define-custom-element");
require("../jo-button.element");
require("../jo-input");
const format_date_for_datetime_local_input_1 = require("../../../utilities/dates-and-times/format-date-for-datetime-local-input");
const InitialState = {
    mode: 'EXISTING',
    startTime: new Date(),
    endTime: new Date(),
    id: -1,
};
class JOScheduledDownTime extends HTMLElement {
    constructor() {
        super(...arguments);
        this.shadow = this.attachShadow({
            mode: 'open',
        });
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this.shadow), this, utilities_1.setPropsReducer);
    }
    set props(props) {
        if ((0, utilities_1.deepLeftCheck)(props, this.store.getState()) === true) {
            return;
        }
        this.store.dispatch({
            type: 'SET_PROPS',
            props,
        });
    }
    raiseDeleteDownTimeEvent() {
        (0, utilities_1.raiseCustomEvent)(this, 'DeleteDownTimeClicked', {
            id: this.store.id,
        });
    }
    raiseCancelDownTimeEvent() {
        (0, utilities_1.raiseCustomEvent)(this, 'CancelDownTimeClicked', {
            id: this.store.id,
        });
    }
    raiseSaveDownTimeEvent() {
        (0, utilities_1.raiseCustomEvent)(this, 'SaveDownTimeClicked', {
            id: this.store.id,
            mode: this.store.mode,
            startTime: this.store.startTime,
            endTime: this.store.endTime,
        });
    }
    render(state) {
        return (0, lit_html_1.html) `
            <link rel="stylesheet" href="/styles/jo-styles.css" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <style>
                .main-container {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    display: flex;
                }

                .section {
                    padding-right: 1rem;
                }

                .flex {
                    display: flex;
                    align-items: flex-end;
                }

                .button {
                    padding-right: 0.5rem;
                }
            </style>

            <div class="main-container">
                <section class="section">
                    <div>Start Time:</div>
                    <input
                        type="datetime-local"
                        class="jo-global--input"
                        .value=${(0, format_date_for_datetime_local_input_1.formatDateForDatetimeLocalInput)(state.startTime)}
                        @input=${(e) => {
            this.store.startTime = new Date(e.target.value);
        }}
                    />
                </section>

                <section class="section">
                    <div>End Time:</div>
                    <input
                        type="datetime-local"
                        class="jo-global--input"
                        .value=${(0, format_date_for_datetime_local_input_1.formatDateForDatetimeLocalInput)(state.endTime)}
                        @input=${(e) => {
            this.store.endTime = new Date(e.target.value);
        }}
                    />
                </section>

                <section class="section flex">
                    <jo-button
                        class="button"
                        .text=${state.mode == 'NEW' ? 'CREATE NEW' : 'Save'}
                        @joButtonClick=${() => {
            this.raiseSaveDownTimeEvent();
        }}
                    ></jo-button>

                    <jo-button
                        class="button"
                        ?hidden=${state.mode !== 'NEW'}
                        .type=${'SECONDARY_BUTTON_1'}
                        .text=${'Cancel'}
                        @joButtonClick=${() => {
            this.raiseCancelDownTimeEvent();
        }}
                    ></jo-button>

                    <jo-button
                        class="button"
                        ?hidden=${state.mode !== 'EXISTING'}
                        .type=${'DELETE_BUTTON_1'}
                        .text=${'Delete'}
                        @joButtonClick=${() => {
            this.raiseDeleteDownTimeEvent();
        }}
                    ></jo-button>
                </section>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-scheduled-down-time', JOScheduledDownTime);
