"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoZipCodeTable = void 0;
const element_vir_1 = require("element-vir");
require("../jo-table");
require("../jo-button.element");
exports.JoZipCodeTable = (0, element_vir_1.defineElement)()({
    tagName: 'jo-zip-code-table',
    events: {
        deleteZipCode: (0, element_vir_1.defineElementEvent)(),
    },
    styles: (0, element_vir_1.css) `
        jo-table {
            margin-top: 16px;
        }
    `,
    renderCallback({ inputs, dispatch, events }) {
        const sortedZipCodes = [...inputs.zipCodes].sort();
        const zipCodeTableColumns = [
            {
                getCellData: item => item,
                width: 1,
            },
            {
                getCellData: item => {
                    return (0, element_vir_1.html) `
                        <jo-button
                            ?hidden=${inputs.isAllowedToUpdate === false}
                            .type=${'ICON'}
                            .icon=${'delete'}
                            @joButtonClick=${() => {
                        dispatch(new events.deleteZipCode(item));
                    }}
                        ></jo-button>
                    `;
                },
                width: 1,
            },
        ];
        function getZipCodesPage(...[offset, limit, searchText,]) {
            const filteredItems = searchText
                ? sortedZipCodes.filter(item => item.includes(searchText))
                : sortedZipCodes;
            const currentPage = filteredItems.slice(offset, offset + limit);
            return {
                items: currentPage,
                totalItems: filteredItems.length,
            };
        }
        return sortedZipCodes.length
            ? (0, element_vir_1.html) `
                  <link rel="stylesheet" href="/styles/jo-styles.css" />
                  <link
                      href="https://fonts.googleapis.com/icon?family=Material+Icons"
                      rel="stylesheet"
                  />
                  <jo-table
                      .maxItemsPerPage=${20}
                      .columns=${zipCodeTableColumns}
                      .getItems=${getZipCodesPage}
                      .searchDebounce=${200}
                      .totalItems=${sortedZipCodes.length}
                  ></jo-table>
              `
            : (0, element_vir_1.html) ``;
    },
});
