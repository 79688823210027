"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.markVoicemailListened = exports.deleteVoicemail = void 0;
const graphql_1 = require("../../../services/graphql");
const error_handling_1 = require("../../../utilities/error-handling");
const mapped_env_variables_1 = require("../../../utilities/environment/mapped-env-variables");
async function deleteVoicemail(voicemailId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($voicemailId: Int!) {
                    updateVoicemails(input: {id: $voicemailId, archived: true}) {
                        id
                    }
                }
            `, {
            voicemailId,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        return {
            succeeded: true,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('deleteVoicemail', error);
    }
}
exports.deleteVoicemail = deleteVoicemail;
async function markVoicemailListened(voicemailId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlMediumContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($voicemailId: Int!) {
                    updateVoicemails(input: {id: $voicemailId, listened: true}) {
                        id
                    }
                }
            `, {
            voicemailId,
        });
        if (gqlResult.succeeded === false) {
            return gqlResult;
        }
        return {
            succeeded: true,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('markVoicemailListened', error);
    }
}
exports.markVoicemailListened = markVoicemailListened;
