"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isDateWithinXMillisecondsOfEndDate = void 0;
const get_end_date_for_schedule_item_1 = require("./get-end-date-for-schedule-item");
function isDateWithinXMillisecondsOfEndDate(date, scheduleItem, milliseconds) {
    const endDateDiff = getEndDateDiff(date, scheduleItem);
    return endDateDiff <= milliseconds;
}
exports.isDateWithinXMillisecondsOfEndDate = isDateWithinXMillisecondsOfEndDate;
function getEndDateDiff(date, scheduleItem) {
    const endDate = (0, get_end_date_for_schedule_item_1.getEndDateForScheduleItem)(date, scheduleItem);
    const endDateDiff = Math.abs(date.getTime() - endDate.getTime());
    return endDateDiff;
}
