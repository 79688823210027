"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_html_1 = require("lit-html");
const reduxular_1 = require("reduxular");
const constants_1 = require("../../../../services/constants");
const graphql_1 = require("../../../../services/graphql");
const utilities_1 = require("../../../../services/utilities");
const auth_rules_1 = require("../../../../utilities/auth-rules");
const css_utils_1 = require("../../../../utilities/css-utils");
const define_custom_element_1 = require("../../../../utilities/define-custom-element");
const get_global_config_array_values_1 = require("../../../../utilities/global-configs/get-global-config-array-values");
require("../../jo-button.element");
const helper_utils_1 = require("./helper-utils");
const queries_and_mutations_1 = require("./queries-and-mutations");
const element_vir_1 = require("element-vir");
const test_id_directive_1 = require("../../../directives/test-id.directive");
const InitialState = {
    authenticatedUser: 'NOT_SET',
    company: 'NOT_SET',
    globalConfig: null,
    inputValues: {
        addressLine1InputValue: '',
        addressLine2InputValue: '',
        cityInputValue: '',
        publicEmailInputValue: '',
        companyNameInputValue: '',
        companyNicheInputValue: '',
        companyPhoneNumberInputValue: '',
        faxInputValue: '',
        formErrors: [],
        industrySelectedValue: '',
        mailingAddressInputValue: '',
        mailingStateInputValue: '',
        ownerPhoneNumber: '',
        referralSource: '',
        referralTag: '',
        setCompanyPhoneAsCallerIdCheckBoxValue: false,
        timeZoneSelectedValue: '',
        websiteInputValue: '',
        zipCodeInputValue: '',
    },
    loading: true,
    phoneNumberVerificationStatus: 'NOT_STARTED',
    showAllFormErrors: false,
    validationCode: '',
    isMobileDevice: false,
};
const cssName = (0, css_utils_1.cssPrefixer)('jo-company-contact-info');
const cssNames = (classNames) => classNames.split(' ').map(cssName).join(' ');
class JOCompanyContactInfo extends HTMLElement {
    constructor() {
        super(...arguments);
        this.store = (0, reduxular_1.createObjectStore)(InitialState, (state) => (0, lit_html_1.render)(this.render(state), this), this);
    }
    set company(company) {
        if (company === this.store.company) {
            return;
        }
        this.store.company = company;
        if (company === 'NOT_SET') {
            this.inputValues = InitialState.inputValues;
        }
        else {
            this.inputValues = {
                addressLine1InputValue: company.address?.line_1 || '',
                addressLine2InputValue: company.address?.line_2 || '',
                cityInputValue: company.address?.city || '',
                publicEmailInputValue: company.public_email,
                companyNameInputValue: company.name,
                companyNicheInputValue: company.niche || '',
                companyPhoneNumberInputValue: (0, utilities_1.formatPhoneNumberToE164)(company.business_number || ''),
                faxInputValue: (0, utilities_1.formatPhoneNumberToE164)(company.fax),
                formErrors: this.store.inputValues.formErrors,
                industrySelectedValue: company.industry || '',
                mailingAddressInputValue: company.mailing_address || '',
                mailingStateInputValue: company.address?.state || '',
                ownerPhoneNumber: (0, utilities_1.formatPhoneNumberToE164)(company.organization?.owner?.phone_number || ''),
                referralSource: company.referral_source || '',
                referralTag: company.referral_tag || '',
                setCompanyPhoneAsCallerIdCheckBoxValue: false,
                timeZoneSelectedValue: company.timezone || '',
                websiteInputValue: company.website_url,
                zipCodeInputValue: company.address?.zip_code || '',
            };
        }
        this.store.loading = false;
    }
    set inputValues(inputValues) {
        this.store.inputValues = inputValues;
        (0, utilities_1.raiseCustomEvent)(this, 'companycontactinfoinputvalueschanged', inputValues);
    }
    async verifyPhoneNumberButtonClicked(company) {
        (0, utilities_1.assertIsSet)(company, utilities_1.handleError, 'company');
        (0, utilities_1.assertIsSet)(this.store.inputValues.companyNameInputValue, utilities_1.handleError, 'this.store.inputValues.companyNameInputValue');
        (0, utilities_1.assertIsSet)(this.store.inputValues.companyPhoneNumberInputValue, utilities_1.handleError, 'this.store.inputValues.companyPhoneNumberInputValue');
        await (0, graphql_1.gqlSubscription)((0, graphql_1.gql) `
                subscription ($phoneNumber: String!) {
                    businessNumberVerified(phoneNumber: $phoneNumber)
                }
            `, data => {
            this.store.validationCode = '';
            if (data.payload.data.businessNumberVerified === true) {
                this.store.phoneNumberVerificationStatus = 'SUCCEEDED';
                (0, utilities_1.raiseCustomEvent)(this, 'businessnumberverified');
            }
            else {
                this.store.phoneNumberVerificationStatus = 'FAILED';
            }
        }, {
            phoneNumber: this.store.inputValues.companyPhoneNumberInputValue,
        });
        const initiatePhoneNumberVerificationAndReturnValidationCodeResult = await (0, queries_and_mutations_1.initiatePhoneNumberVerificationAndReturnValidationCode)(company.id, this.store.inputValues.companyNameInputValue, this.store.inputValues.companyPhoneNumberInputValue);
        if (initiatePhoneNumberVerificationAndReturnValidationCodeResult.succeeded === false &&
            initiatePhoneNumberVerificationAndReturnValidationCodeResult.developerMessage.includes('Phone number is already verified')) {
            (0, utilities_1.joAlert)('Notice:', 'This phone number is already being used as a CallerId');
            return;
        }
        (0, utilities_1.assertSucceeded)(initiatePhoneNumberVerificationAndReturnValidationCodeResult, utilities_1.handleError);
        this.store.validationCode =
            initiatePhoneNumberVerificationAndReturnValidationCodeResult.value;
        (0, utilities_1.joAlert)('Note:', `You will soon receive an automated call to verify phone number. Please use the following verification code when prompted: ${this.store.validationCode}`);
    }
    shouldHideCancelCompanyTimeStamp() {
        if (this.store.company === 'NOT_SET') {
            return false;
        }
        return !this.store.company.canceled;
    }
    render(state) {
        const isAllowedToUpdate = (0, auth_rules_1.isJillAtOrAboveAuthRole)(state.authenticatedUser, 'JILL_BUSINESS_DEVELOPMENT');
        return (0, element_vir_1.html) `
            <style>
                .${cssNames('main-container')} {
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    font-family: sans-serif;
                    color: var(--jo-dark-grey-text-1);
                    font-weight: 200;
                }

                .${cssNames('verified')} {
                    padding: 0.5rem;
                    margin-top: 0.5rem;
                    width: 70%;
                    border-radius: 0.125rem;
                    line-height: 1.5rem;
                    color: var(--jo-green-success-text);
                    font-weight: bold;
                    background-color: var(--jo-green-success-background);
                }

                .${cssNames('verification-code')} {
                    margin-top: 0.5rem;
                    padding: 0.5rem;
                    width: 70%;
                    border-radius: 0.125rem;
                    line-height: 1.5rem;
                    color: var(--jo-accent);
                }

                .${cssNames('save-alert')} {
                    padding: 0.5rem;
                    margin-top: 0.5rem;
                    width: 70%;
                    border-radius: 0.125rem;
                    line-height: 1.5rem;
                    color: var(--jo-danger);
                    font-weight: bold;
                }

                .${cssNames('verification-error')} {
                    padding: 0.5rem;
                    width: 90%;
                    border-radius: 0.125rem;
                    margin-bottom: 0.5rem;
                    line-height: 1.5rem;
                    color: var(--jo-danger);
                    font-weight: bold;
                }

                .${cssNames('verify-phone')} {
                    display: flex;
                    align-items: center;
                }

                .${cssNames('setWidth')} {
                    width: ${state.isMobileDevice ? `95%` : `85%`};
                }

                .${cssNames('marginBottom')} {
                    margin-bottom: ${this.store.isMobileDevice ? `1rem` : ``};
                }

                .${cssNames('title')} {
                    padding-left: 1rem;
                    padding-right: 8rem;
                }

                .${cssNames('flex-row')} {
                    display: flex;
                    flex-direction: row;
                }

                .${cssNames('canceled-since-text-container')} {
                    color: var(--jo-danger);
                    font-weight: bold;
                    font-size: 1.1rem;
                    padding-bottom: 1rem;
                    padding-left: 10rem;
                }
            </style>

            <div class="${cssNames('main-container')}">
                <div class="jo-company-details--info-card">
                    <div class="">
                        <div
                            class="${cssNames('canceled-since-text-container')}"
                            ?hidden=${this.shouldHideCancelCompanyTimeStamp()}
                        >
                            Canceled Since:
                            ${this.store.company !== 'NOT_SET' &&
            this.store.company.canceled_at !== null
            ? (0, utilities_1.formatISODateForFileName)(this.store.company.canceled_at)
            : ''}
                        </div>

                        <div class="${cssNames('flex-row')}">
                            <h3
                                class="flex-1 jo-company-details--info-card-header ${cssNames('title')}"
                            >
                                CONTACT INFO
                            </h3>
                            <div>
                                Customer Since:
                                ${this.store.company !== 'NOT_SET'
            ? (0, utilities_1.formatISODateForFileName)(this.store.company.created_at)
            : 'Error'}
                            </div>
                        </div>
                    </div>

                    <div class="jo-company-details--info-card-body-content">
                        <div class="jo-company-details--info-card-row">
                            <jo-input
                                ${(0, test_id_directive_1.testId)('jo-company-details-company-name')}
                                class="${cssNames('marginBottom')}"
                                .readonly=${isAllowedToUpdate === false}
                                .label=${'Company Name'}
                                .type=${'TEXT_INPUT'}
                                .required=${true}
                                .showRequiredIcon=${true}
                                .inputValue=${state.inputValues.companyNameInputValue}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                companyNameInputValue: e.detail.inputValue,
                formErrors: e.detail.formErrors,
            };
        }}
                                .formErrors=${state.inputValues.formErrors}
                                .showError=${state.showAllFormErrors}
                            ></jo-input>

                            <div class="${cssNames('setWidth')}">
                                <jo-input
                                    .readonly=${isAllowedToUpdate === false}
                                    .label=${'Company Phone #'}
                                    .type=${'PHONE_NUMBER'}
                                    .inputValue=${state.inputValues.companyPhoneNumberInputValue}
                                    @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                companyPhoneNumberInputValue: e.detail.inputValue,
                formErrors: e.detail.formErrors,
            };
        }}
                                    .formErrors=${state.inputValues.formErrors}
                                    .showError=${state.showAllFormErrors}
                                ></jo-input>
                            </div>
                        </div>

                        <div class="jo-company-details--info-card-row">
                            <div>
                                <!--Empty spot for alignment-->
                            </div>

                            ${(0, helper_utils_1.isAllowedToVerifyPhoneNumber)(state) === false
            ? (0, element_vir_1.html) `
                                      <div class="${cssNames('save-alert')}">
                                          Save first to use as CallerID
                                      </div>
                                  `
            : (0, helper_utils_1.isPhoneNumberAlreadyVerified)(state) === true
                ? (0, element_vir_1.html) `
                                      <div class="${cssNames('verified')}">Verified</div>
                                  `
                : (0, element_vir_1.html) `
                                      <div>
                                          <small>
                                              Set this as CallerID for when we make outbound calls?
                                          </small>
                                          <input
                                              .disabled=${(0, utilities_1.isInAuthRole)([
                    'JILL_BUSINESS_DEVELOPMENT',
                    'JILL_EXECUTIVE',
                    'COMPANY_OWNER',
                ], state.authenticatedUser) === false}
                                              type="checkbox"
                                              id="set-callerid-checkbox"
                                              .checked=${state.inputValues
                    .setCompanyPhoneAsCallerIdCheckBoxValue}
                                              @change=${(e) => {
                    this.inputValues = {
                        ...this.store.inputValues,
                        setCompanyPhoneAsCallerIdCheckBoxValue: e.target.checked,
                    };
                }}
                                          />
                                      </div>
                                  `}
                        </div>

                        <div class="jo-company-details--info-card-row">
                            <jo-input
                                .readonly=${(0, utilities_1.isInAuthRole)([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ], state.authenticatedUser) === false}
                                .label=${'Public Email'}
                                .type=${'EMAIL'}
                                .inputValue=${state.inputValues.publicEmailInputValue}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                publicEmailInputValue: e.detail.inputValue,
                formErrors: e.detail.formErrors,
            };
        }}
                                .formErrors=${state.inputValues.formErrors}
                                .showError=${state.showAllFormErrors}
                            ></jo-input>

                            <div>
                                ${(0, element_vir_1.renderIf)((0, helper_utils_1.isNotAllowedToViewVerifyPhoneNumber)(state) === false, (0, element_vir_1.html) `
                                        <div>
                                            ${(0, element_vir_1.renderIf)(state.phoneNumberVerificationStatus === 'FAILED', (0, element_vir_1.html) `
                                                    <div class="${cssNames('verification-error')}">
                                                        Error verifying phone number, please try
                                                        again
                                                    </div>
                                                `)}

                                            <div class="${cssNames('verify-phone')}">
                                                Verify Phone Number:&nbsp;&nbsp;
                                                <jo-button
                                                    .type=${'ACTION_BUTTON_2'}
                                                    .text=${'Verify Number'}
                                                    @click=${() => this.verifyPhoneNumberButtonClicked(state.company)}
                                                ></jo-button>
                                            </div>
                                        </div>
                                    `)}

                                <div
                                    class="${cssNames('verification-code')}"
                                    ?hidden=${this.store.validationCode === ''}
                                >
                                    Verfication Code: ${this.store.validationCode}
                                </div>
                            </div>
                        </div>

                        <div class="jo-company-details--info-card-row">
                            <jo-input
                                class="${cssNames('setWidth marginBottom')}"
                                .readonly=${isAllowedToUpdate === false}
                                .label=${'Owner Phone #'}
                                .type=${'PHONE_NUMBER'}
                                .inputValue=${state.inputValues.ownerPhoneNumber}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                ownerPhoneNumber: e.detail.inputValue,
                formErrors: e.detail.formErrors,
            };
        }}
                                .formErrors=${state.inputValues.formErrors}
                                .showError=${state.showAllFormErrors}
                            ></jo-input>

                            <jo-input
                                .readonly=${isAllowedToUpdate === false}
                                .label=${'Company Website'}
                                .type=${'TEXT_INPUT'}
                                .inputValue=${state.inputValues.websiteInputValue}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                websiteInputValue: e.detail.inputValue,
                formErrors: e.detail.formErrors,
            };
        }}
                            ></jo-input>
                        </div>

                        <div class="jo-company-details--info-card-row">
                            <jo-input
                                class="${cssNames('marginBottom')}"
                                .readonly=${isAllowedToUpdate === false}
                                .label=${'Address 1'}
                                .type=${'TEXT_INPUT'}
                                .inputValue=${state.inputValues.addressLine1InputValue}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                addressLine1InputValue: e.detail.inputValue,
                formErrors: e.detail.formErrors,
            };
        }}
                            ></jo-input>

                            <jo-input
                                .readonly=${isAllowedToUpdate === false}
                                .label=${'Address 2'}
                                .type=${'TEXT_INPUT'}
                                .inputValue=${state.inputValues.addressLine2InputValue}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                addressLine2InputValue: e.detail.inputValue,
                formErrors: e.detail.formErrors,
            };
        }}
                            ></jo-input>
                        </div>

                        <div class="jo-company-details--info-card-row">
                            <jo-input
                                class="${cssNames('marginBottom')}"
                                .readonly=${isAllowedToUpdate === false}
                                .label=${'City'}
                                .type=${'TEXT_INPUT'}
                                .inputValue=${state.inputValues.cityInputValue}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                cityInputValue: e.detail.inputValue,
                formErrors: e.detail.formErrors,
            };
        }}
                            ></jo-input>

                            <jo-input
                                .readonly=${isAllowedToUpdate === false}
                                .label=${'State'}
                                .type=${'TEXT_INPUT'}
                                .inputValue=${state.inputValues.mailingStateInputValue}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                mailingStateInputValue: e.detail.inputValue,
                formErrors: e.detail.formErrors,
            };
        }}
                            ></jo-input>
                        </div>

                        <div class="jo-company-details--info-card-row">
                            <jo-input
                                class="${cssNames('marginBottom')}"
                                .readonly=${isAllowedToUpdate === false}
                                .label=${'Zip Code'}
                                .type=${'TEXT_INPUT'}
                                .inputValue=${state.inputValues.zipCodeInputValue}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                zipCodeInputValue: e.detail.inputValue,
                formErrors: e.detail.formErrors,
            };
        }}
                            ></jo-input>

                            <jo-input
                                .readonly=${isAllowedToUpdate === false}
                                .label=${'Timezone'}
                                .type=${'TIMEZONE_SELECT'}
                                .selectedValue=${state.inputValues.timeZoneSelectedValue}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                timeZoneSelectedValue: e.detail.selectedValue,
                formErrors: e.detail.formErrors,
            };
        }}
                                .showError=${state.showAllFormErrors}
                            ></jo-input>
                        </div>

                        <div class="jo-company-details--info-card-row">
                            <jo-input
                                ${(0, test_id_directive_1.testId)('jo-company-details-industry')}
                                class="${cssNames('marginBottom')}"
                                .readonly=${isAllowedToUpdate === false}
                                .label=${'Industry'}
                                .type=${'SELECT'}
                                .selectedValue=${state.inputValues.industrySelectedValue}
                                .defaultOption=${'Please select and industry...'}
                                .selectOptions=${constants_1.Industries}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                industrySelectedValue: e.detail.selectedValue,
                formErrors: e.detail.formErrors,
            };
        }}
                            ></jo-input>

                            <jo-input
                                class="${cssNames('setWidth')}"
                                .readonly=${isAllowedToUpdate === false}
                                .label=${'Fax'}
                                .type=${'PHONE_NUMBER'}
                                .inputValue=${state.inputValues.faxInputValue}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                faxInputValue: e.detail.inputValue,
                formErrors: e.detail.formErrors,
            };
        }}
                                .showError=${state.showAllFormErrors}
                            ></jo-input>
                        </div>

                        <div class="jo-company-details--info-card-row">
                            <jo-input
                                class="${cssNames('marginBottom')}"
                                .readonly=${isAllowedToUpdate === false}
                                .label=${'Niche'}
                                .type=${'SELECT'}
                                .selectOptions=${(0, get_global_config_array_values_1.getGlobalConfigArrayValues)(state.globalConfig, 'niches')}
                                .defaultOption=${'Select Niche...'}
                                .disableDefaultOption=${true}
                                .selectedValue=${state.inputValues.companyNicheInputValue}
                                @valueChanged=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                companyNicheInputValue: e.detail.selectedValue,
                formErrors: e.detail.formErrors,
            };
        }}
                                .formErrors=${state.inputValues.formErrors}
                                .showError=${state.showAllFormErrors}
                            ></jo-input>

                            <jo-input
                                class="${cssNames('marginBottom')}"
                                .readonly=${isAllowedToUpdate === false}
                                .label=${'Referral Source'}
                                .type=${'SELECT'}
                                .selectOptions=${(0, get_global_config_array_values_1.getGlobalConfigArrayValues)(state.globalConfig, 'referral_sources')}
                                .defaultOption=${'Select Referral Source...'}
                                .disableDefaultOption=${true}
                                .selectedValue=${state.inputValues.referralSource}
                                @valueChanged=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                referralSource: e.detail.selectedValue,
                formErrors: e.detail.formErrors,
            };
        }}
                                .formErrors=${state.inputValues.formErrors}
                                .showError=${state.showAllFormErrors}
                            ></jo-input>
                        </div>
                        <div class="jo-company-details--info-card-row">
                            <jo-input
                                .readonly=${isAllowedToUpdate === false}
                                .label=${'Mailing Address'}
                                .type=${'TEXT_AREA'}
                                .inputValue=${state.inputValues.mailingAddressInputValue}
                                @inputchange=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                mailingAddressInputValue: e.detail.inputValue,
                formErrors: e.detail.formErrors,
            };
        }}
                                .formErrors=${state.inputValues.formErrors}
                                .showError=${state.showAllFormErrors}
                            ></jo-input>

                            <jo-input
                                class="${cssNames('marginBottom')}"
                                .readonly=${isAllowedToUpdate === false}
                                .label=${'Referral Tag'}
                                .type=${'SELECT'}
                                .selectOptions=${(0, get_global_config_array_values_1.getGlobalConfigArrayValues)(state.globalConfig, 'referral_tags')}
                                .defaultOption=${'Select Referral Tag...'}
                                .disableDefaultOption=${true}
                                .selectedValue=${state.inputValues.referralTag}
                                @valueChanged=${(e) => {
            this.inputValues = {
                ...this.store.inputValues,
                referralTag: e.detail.selectedValue,
                formErrors: e.detail.formErrors,
            };
        }}
                                .formErrors=${state.inputValues.formErrors}
                                .showError=${state.showAllFormErrors}
                            ></jo-input>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}
(0, define_custom_element_1.defineCustomElement)('jo-company-contact-info', JOCompanyContactInfo);
