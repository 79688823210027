"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatDateForDateInput = void 0;
function formatDateForDateInput(date) {
    const localeDateString = date.toLocaleDateString();
    const [month, day, year,] = localeDateString.split('/');
    if (month == null) {
        throw new Error('month is null');
    }
    if (day == null) {
        throw new Error('day is null');
    }
    return year + '-' + formatMonthOrDayNumber(month) + '-' + formatMonthOrDayNumber(day);
}
exports.formatDateForDateInput = formatDateForDateInput;
function formatMonthOrDayNumber(monthOrDayNumber) {
    return String(monthOrDayNumber).length === 1
        ? `0${monthOrDayNumber}`
        : String(monthOrDayNumber);
}
